import { call, put, takeLatest } from 'redux-saga/effects'
import { toast } from '@leroy-merlin-br/backyard-react'

import * as service from 'lmcv/services/notification'

import { Constants, Actions } from '.'

function * getNotification () {
  try {
    const { data } = yield call(service.getNotification)

    if (data.length) {
      yield call(toast.primary, data[0].message, { variant: 'solid' })
      yield put(Actions.getSuccess(data))
    }
  } catch (e) {
    yield put(Actions.getFailure(e))
  }
}

function * deleteNotification () {
  try {
    yield call(service.deleteNotification, 'invite-accepted')
    yield put(Actions.deleteSuccess())
  } catch (e) {
    yield put(Actions.deleteFailure(e))
  }
}

export default function * root () {
  yield takeLatest(Constants.NOTIFICATION_GET, getNotification)
  yield takeLatest(Constants.NOTIFICATION_GET_SUCCESS, deleteNotification)
}
