import styled from '@emotion/styled'
import { css } from '@emotion/react'

const wrapperLogoStyles = ({ theme }) => css`
  label: wrapper-logo;

  svg {
    margin: 0;

    width: 60px;
    height: 60px;

    z-index: ${theme.zIndex.backdrop};
  }

  ${theme.media.giga} {
    svg {
      height: auto;
      margin-top: ${theme.spacings.peta};

      width: 112px;
    }
  }
`

export const WrapperLogo = styled('div')(wrapperLogoStyles)
