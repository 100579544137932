import type { PropsWithChildren, ReactNode } from 'react'

import * as S from './styled'

const Wrapper = ({
  children,
  ...rest
}: PropsWithChildren<ReactNode & object>) => (
  <S.Wrapper {...rest} data-testid="chat-modal" className="chat-modal">
    {children}
  </S.Wrapper>
)

export default Wrapper
