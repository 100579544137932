import { pushShippingCalculation } from '../../events/shipping-calculation'
import type { PageType } from '../../events/shipping-calculation'
import type { Modality, Shipping } from '../../types'
import { getCheapestModalityWithDeadline } from '../../utils'

interface CartChangeData {
  pageType: PageType
  shippings: Shipping[]
  zipCode: string
}

const useGTMShipping = () => {
  const handleShippingsChange = ({ pageType, shippings, zipCode }: CartChangeData) => {
    shippings.map((shipping) => {
      if (shipping.type === 'delivery') {
        shipping.shipments.map((shipment) => {
          const cheapestModality: Modality | null =
            getCheapestModalityWithDeadline(shipment.modalities)

          pushShippingCalculation({
            pageType: pageType,
            shipping: {
              city: undefined,
              stateCode: undefined,
              zipCode: zipCode ? zipCode.replace(/\D/g, '') : undefined,
              price: cheapestModality?.cost || 0,
              deliveryTime: cheapestModality?.deadline || undefined,
              seller: shipment.items[0].shopName,
              items: []
            }
          })
        })
      }
    })
  }

  return {
    handleShippingsChange
  }
}

export default useGTMShipping
