import { css } from '@emotion/react'
import styled from '@emotion/styled'

const buttonTriggerStyles = ({ theme }) => css`
  label: share-buttons__icon-share-trigger;

  align-items: center;
  background: ${theme.colors.white};
  border-radius: ${theme.borderRadius.circle};
  color: ${theme.colors.p500};
  font-size: ${theme.typography.headings.exa.fontSize} !important;
  justify-content: center;
  transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);

  &:hover {
    cursor: pointer;
    background: ${theme.colors.p500};
    color: ${theme.colors.n100};
  }
`

const buttonTriggerActiveStyles = ({ theme, isActive }) =>
  isActive &&
  css`
    label: share-buttons__icon-share-trigger--active;

    background: ${theme.colors.p500};
    color: ${theme.colors.n100};
    position: relative;
    z-index: ${theme.zIndex.modal};
  `
export const ButtonTrigger = styled('button')(buttonTriggerStyles, buttonTriggerActiveStyles)
