import { Stack } from '@leroy-merlin-br/backyard-react'

import { cheapestModalityOption } from 'catalog/utils/cheapest-modality-option'
import {
  workingHoursText,
  workingDaysText
} from 'catalog/utils/shipments-dictionary'

import { ShipmentPriceInfo } from './components/ShipmentPriceInfo'

const MainShipmentPrices = ({
  modalities = {},
  pickupInStore,
  isPickupEnabled
}) => {
  const { express = {}, ...shipments } = modalities

  const cheapest = shipments ? cheapestModalityOption(shipments) : {}
  const hasPickupInStore = Boolean(pickupInStore) && isPickupEnabled
  const hasCheapest = Boolean(Object.keys(cheapest).length)
  const hasExpress = Boolean(Object.keys(express).length)
  const isSameDayDelivery = hasExpress && express.expressDeliveryDay

  return (
    <Stack space="byte" alignY="center">
      {hasPickupInStore && (
        <ShipmentPriceInfo
        hasBadge
        cost={pickupInStore.cost}
        message={workingHoursText(pickupInStore.delayTime)}
        >
          Retirada na loja
        </ShipmentPriceInfo>
      )}

      {isSameDayDelivery && (
        <ShipmentPriceInfo
          cost={express.cost}
          isSameDayDelivery
          sameDayDeliveryKey={express.expressDeliveryDay}
        />
      )}

      {hasCheapest && (
        <ShipmentPriceInfo
          cost={cheapest.cost}
          message={workingDaysText(cheapest.deadline)}
        />
      )}

      {hasExpress && !isSameDayDelivery && (
        <ShipmentPriceInfo
          cost={express.cost}
          message={workingDaysText(express.deadline)}
        >
          Entrega expressa
        </ShipmentPriceInfo>
      )}
    </Stack>
  )
}

export default MainShipmentPrices
