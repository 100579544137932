import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { styleHelpers } from '@leroy-merlin-br/backyard-react'

import { StyleProps } from 'lmcv/components/CouponModal/types'

const titleStyles = ({ theme }: StyleProps) => css`
  label: title;

  h3 {
    margin-bottom: ${theme.spacings.bit};
  }

  margin-bottom: ${theme.spacings.giga};
`

const dotStyles = ({ theme }: StyleProps) => css`
  label: dot-element;

  width: 8px;
  height: 8px;

  border-radius: ${theme.borderRadius.circle};
  background: ${theme.colors.p500};
`

const avatarStyles = ({ theme }: StyleProps) => css`
  label: avatar;

  width: 48px;
  height: 48px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: ${theme.borderRadius.circle};

  background-color: ${theme.colors.n200};

  ${styleHelpers.shadowDouble(theme)};
`

const avatarImageStyle = ({ image }: { image?: string }) =>
  image &&
  css`
    label: avatar--with-image;

    background-image: url(${image});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  `

export const Title = styled('div')(titleStyles)
export const Avatar = styled('div')(avatarStyles, avatarImageStyle)
export const Dot = styled('div')(dotStyles)
