export const formatters = {
  date: date => {
    const [day, month, year] = date.split('/')

    return [year, month, day].join('-')
  },
  birthDate: data => data.replace(/(\d{4})-(\d{2})-(\d{2})/g, '$3/$2/$1'),
  cep: data => data.replace(/\D/g, '').replace(/(\d{5})(\d{3})(.?)/, '$1-$2'),
  toDecimal: value => value.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.'),
  toCurrency: value =>
    value.toLocaleString('pt-BR', { minimumFractionDigits: 2 })
}
