import { FunctionComponent } from 'react'
import { Stack } from '@backyard-ui/core'

import { ReviewSchema } from 'shared/schemas'

import { Comment } from '../../../../components'
import { Props } from './types'

const TITLE_BY_STARS = [
  { value: 5, title: 'Excelente' },
  { value: 4, title: 'Ótimo' },
  { value: 3, title: 'Bom' },
  { value: 2, title: 'Ruim' },
  { value: 1, title: 'Péssimo' }
]

const Item: FunctionComponent<Props> = ({ review }) => {
  const title = TITLE_BY_STARS.find(item => item.value === review.rating)?.title
  const defaultTitle = title || ''

  return (
    <Stack direction="column" align="flex-start" spacing="4">
      <ReviewSchema
        author={review.author || 'Anônimo'}
        ratingValue={review.rating}
        datePublished={review.date}
        title={review.title || defaultTitle}
        comment={review.text}
      />
      <Comment.Head
        author={review.author || 'Anônimo'}
        date={review.date}
        rating={review.rating}
      />
      <Comment.Title title={review.title || defaultTitle} />
      <Comment.Description text={review.text} />
      <Comment.Recommendation hasRecommendation={review.isRecommended} />
      <Comment.Media
        photos={review.photos}
        videos={review.videos}
        title={review.title}
      />
      <Comment.Actions
        reviewId={review.id}
        badFeedbackAmount={review.badFeedbackAmount}
        goodFeedbackAmount={review.goodFeedbackAmount}
      />
    </Stack>
  )
}

export default Item
