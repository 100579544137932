import PropTypes from 'prop-types'

import * as S from './styled'

const HeartIcon = ({ isActive, onClick }) => (
  <S.HeartIcon onClick={onClick} isActive={isActive} data-name="heart-icon">
    <svg viewBox="0 0 26 26">
      <path d="M12.97 23c-.196 0-.397-.041-.572-.139-4.01-2.232-6.868-4.635-8.995-7.563C1.69 12.939.906 10.58 1.009 8.085a7.409 7.409 0 0 1 2.12-4.909 7.287 7.287 0 0 1 4.89-2.169c1.85-.081 3.522.54 4.972 1.847l.05-.04c.395-.308.802-.627 1.261-.886 1.968-1.108 4.26-1.23 6.286-.333 2.112.934 3.684 2.877 4.204 5.198.487 2.17.12 4.376-1.119 6.743-1.262 2.41-3.193 4.564-5.901 6.587-1.007.751-2.08 1.425-3.117 2.077l-.953.6c-.186.12-.455.2-.731.2" />
    </svg>
  </S.HeartIcon>
)

HeartIcon.propTypes = {
  isActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func
}

export default HeartIcon
