import PropTypes from 'prop-types'
import { Heading, Icon } from '@leroy-merlin-br/backyard-react'
import { Cross } from '@leroy-merlin-br/backyard-icons'

import * as S from './styled'

const isPlural = plural => {
  if (!plural || !plural.length) return false

  return plural.length > 1 || plural[0].items?.length > 1
}

const Header = ({ plural, closeCardDrawer }) => (
  <S.Wrapper data-cy="mini-cart-title">
    <Icon
      as={Cross}
      size="giga"
      color="primary"
      aria-label="Fechar"
      onClick={closeCardDrawer}
      data-cy="minicart-button-close-drawer"
    />
    <Heading as="h2" size="tera" noMargin>
      {isPlural(plural) ? 'Produtos adicionados' : 'Produto adicionado'} ao
      carrinho
    </Heading>
  </S.Wrapper>
)

Header.propTypes = {
  plural: PropTypes.array,
  closeCardDrawer: PropTypes.func
}

export default Header
