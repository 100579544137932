import { css } from '@emotion/react'
import styled from '@emotion/styled'

const wrapperStyles = ({ theme }) => css`
  label: bank-slip-installments;

  display: flex;
  justify-content: center;
  align-items: center;

  iframe {
    width: 100%;
    height: 85vh;
    min-width: 320px;
    min-height: 512px;

    ${theme.media.giga} {
      min-width: 1024px;
      min-height: 520px;
    }
  }
`

export const Wrapper = styled('div')(wrapperStyles)
