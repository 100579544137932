import PropTypes from 'prop-types'

import Title from '../generic/Title'
import ListFoldersItems from '../generic/ListFoldersItems'

const StepOneMobile = ({ close, next, status, move, wishlists, addToList }) => (
  <div className={ `wishlist-drawer-${status}` }>
    <Title
      text="Selecione uma lista"
      close={ close }
      move={ move } />

    <div className="wishlist-drawer-lists">
      <ListFoldersItems
        title="Todas as listas"
        addToList={ addToList }
        items={ wishlists } />
    </div>

    <div className="wishlist-drawer-list-new">
      <button
        onClick={ next }
        className="button button-full wishlist-drawer-button no-shadow"
        type="button">
          Criar uma nova lista
      </button>
    </div>
  </div>
)

StepOneMobile.propTypes = {
  close: PropTypes.func.isRequired,
  next: PropTypes.func.isRequired,
  move: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired
}

export default StepOneMobile
