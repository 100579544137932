import { useMemo, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import {
  Card,
  Stack,
  Inline,
  Text,
  Radio,
  Button
} from '@leroy-merlin-br/backyard-react'
import { Popover } from 'user/components'
import { useCardRequestContext } from 'user/celebre/context/card-request-context'
import { BRAZIL_CODE } from 'user/celebre/constants'
import { Spinner } from 'user/celebre/components'
import { documentDateValidator } from 'user/utils'
import { useStateMachine } from 'little-state-machine'

import { NumberField, TextField, SelectField } from 'shared/components'

import * as validator from 'utils/validators/validators'

import * as S from './styled'
import { FORMS_NAMES, FORM_DEFAULT_VALUES } from '../../constants'

const AdditionalDataForm = () => {
  const {
    selectOptions,
    isLoadingSelectOptions,
    updateAction,
    activeStep,
    setActiveStep
  } = useCardRequestContext()

  const {
    actions,
    state: { complement: additionalData, apiErrors }
  } = useStateMachine({ updateAction })

  const {
    register,
    handleSubmit,
    control,
    setError,
    formState,
    watch,
    reset
  } = useForm({
    mode: 'onChange',
    defaultValues: FORM_DEFAULT_VALUES.complement
  })

  const { errors, isValid } = formState

  const hasErrors = useMemo(() => {
    return Object.keys(formState.errors).length > 0
  }, [formState])

  const onSubmit = data => {
    actions.updateAction({
      complement: data,
      apiErrors: { ...apiErrors, complement: [] }
    })

    setActiveStep(FORMS_NAMES.address)
  }

  useEffect(() => {
    if (additionalData) {
      reset(additionalData)
    }

    if (apiErrors.complement) {
      apiErrors.complement.forEach(({ code, message }) => {
        setError(code, { type: 'manual', message: message })
      })
    }
  }, [additionalData, apiErrors.complement, reset, setError])

  const isBrazilian = watch('birthData.country') === BRAZIL_CODE

  const isActive = activeStep === FORMS_NAMES.complement

  if (!isActive) {
    return
  }

  return (
    <Card
      title={
        <Popover
          list={{
            title: 'Os dados adicionais serão usados para:',
            items: ['Te identificar', 'Analisar crédito']
          }}
        >
          Avaliação de crédito
        </Popover>
      }
    >
      <Spinner isVisible={isLoadingSelectOptions} />

      <form onSubmit={handleSubmit(onSubmit)} data-cy="additional-data-form">
        <Stack space="mega">
          <div>
            <Text size="kilo" isBold noMargin>
              Gênero
            </Text>
            <Inline>
              {selectOptions.genders?.map(({ value, label }) => (
                <Radio
                  {...register('gender', {
                    required: true
                  })}
                  value={value}
                  key={value}
                >
                  {label}
                </Radio>
              ))}
            </Inline>
          </div>

          <SelectField
            name="maritalStatus"
            label="Estado civil"
            options={selectOptions.maritalStatus}
            control={control}
            state={Boolean(errors.maritalStatus) && 'invalid'}
            hint={errors.maritalStatus?.message}
          />

          <SelectField
            name="birthData.country"
            label="País de nascimento"
            options={selectOptions.nationalities}
            control={control}
            state={Boolean(errors.birthData?.country) && 'invalid'}
            hint={errors.birthData?.country?.message}
          />

          {isBrazilian && (
            <SelectField
              name="birthData.state"
              label="Estado de nascimento"
              options={selectOptions.states}
              control={control}
              state={Boolean(errors.birthData?.state) && 'invalid'}
              hint={errors.birthData?.state?.message}
            />
          )}

          <TextField
            name="birthData.city"
            label="Cidade de nascimento"
            rules={{
              required: 'Insira sua cidade de nascimento'
            }}
            control={control}
            state={Boolean(errors.birthData?.city) && 'invalid'}
            hint={errors.birthData?.city?.message}
          />

          <TextField
            name="motherName"
            label="Nome da mãe"
            rules={{
              required: 'Insira o nome da sua mãe',
              validate: value => validator.isValidName(value) || 'Nome inválido'
            }}
            control={control}
            state={Boolean(errors.motherName) && 'invalid'}
            hint={errors.motherName?.message}
          />

          <S.DocumentWrapper>
            <SelectField
              name="document.type"
              label="Tipo de documento"
              options={selectOptions.documents}
              control={control}
              state={Boolean(errors.document?.type) && 'invalid'}
              hint={errors.document?.type?.message}
            />

            <NumberField
              name="document.number"
              label="Número do documento"
              rules={{
                required: 'Insira seu número de documento'
              }}
              control={control}
              state={Boolean(errors.document?.number) && 'invalid'}
              hint={errors.document?.number?.message}
            />
          </S.DocumentWrapper>

          <NumberField
            name="document.emissionDate"
            label="Data de emissão do documento"
            rules={{
              required: 'Insira sua data de emissão de documento',
              validate: value => documentDateValidator(value)
            }}
            control={control}
            state={Boolean(errors.document?.emissionDate) && 'invalid'}
            hint={errors.document?.emissionDate?.message}
            format="##/##/####"
            valueType="formatted"
          />
        </Stack>

        <S.ButtonWrapper>
          <Button isStretch type="submit" isDisabled={!isValid || hasErrors}>
            Continuar
          </Button>
        </S.ButtonWrapper>
      </form>
    </Card>
  )
}

export default AdditionalDataForm
