import * as CouponUI from 'frontendCheckout/ui/v1/CouponAndVoucher'
import * as Icons from 'frontendCheckout/assets/icons'

type SelectableButtonProps = {
  isSelected: boolean
  isSelectable: boolean
  onClick: () => void
}

export const SelectableButton = ({
  isSelected,
  isSelectable,
  onClick
}: SelectableButtonProps) => {
  if (isSelected) {
    return (
      <CouponUI.Button
        size={'sm'}
        onClick={onClick}
        color={isSelectable ? 'actived' : 'disabled'}
      >
        Selecionado
        <Icons.Check />
      </CouponUI.Button>
    )
  } else {
    return (
      <CouponUI.Button
        size={'sm'}
        onClick={onClick}
        color={isSelectable ? 'default' : 'disabled'}
      >
        Selecionar
      </CouponUI.Button>
    )
  }
}
