import { Text, Card, useModal } from '@leroy-merlin-br/backyard-react'

import formatPriceToBRL from 'utils/formatPriceToBRL'

import { DiscountsInfoOpenButton } from 'checkout/components/DiscountsModal/components'

import { Installments } from '../Installments'
import * as S from './styled'

type TotalDiscountsByType = {
  stampedPrice: number
  promo: number
  employee: number
  'payment-method': number
  pix: number
  bank_slip: number
  credit_card: number
}

type Props = {
  subTotal: number
  discount: number
  total: number
  servicesSubTotal: number
  serviceSubTotal: number
  paymentMethods: string
  freight: number
  totalInstallments: { amount: number; value: string; totalPrice: string }
  totalDiscountsByType: TotalDiscountsByType
  firstCheapestPaymentMethod: string
  showDiscountsModal: boolean
}

const SummaryPricesCart = ({
  subTotal,
  discount,
  total,
  servicesSubTotal,
  paymentMethods,
  freight,
  totalInstallments,
  totalDiscountsByType,
  firstCheapestPaymentMethod,
  showDiscountsModal = true
}: Props) => {
  const hasFreight = freight !== null && freight >= 0
  const hasPaymentMethod = paymentMethods ?? false
  const paymentMethodsDetail =
    hasPaymentMethod && paymentMethods.replace('à vista', '').trim()
  const paymentMethodsInfo = hasPaymentMethod ? 'à vista' : ''

  function renderTotal () {
    return (
      <S.CartItemTotal hasPaymentMethod={paymentMethodsDetail}>
        <S.Footer>
          <Text size="giga" as="span" noMargin isBold>
            Total
          </Text>
          <Text size="giga" as="span" noMargin isBold data-testid="total-price">
            {formatPriceToBRL(total)} {paymentMethodsInfo}
          </Text>
        </S.Footer>

        {paymentMethodsDetail && (
          <S.PaymentMethodInformation>
            <Text size="mega" as="span" noMargin color="n800">
              {paymentMethodsDetail}
            </Text>
          </S.PaymentMethodInformation>
        )}

        {totalInstallments && (
          <S.InstallmentInformation>
            <Text as="span" size="kilo" data-testid="installments-info">
              <Installments
                isBranded={false}
                installments={totalInstallments}
                showOneInstallment
              />
            </Text>
          </S.InstallmentInformation>
        )}
      </S.CartItemTotal>
    )
  }

  return (
    <Card data-cy="cart-info" data-testid="summary-prices-cart">
      <S.CartItem>
        <Text size="mega" as="span" isBold noMargin>
          Subtotal
        </Text>
        <Text
          size="mega"
          as="span"
          noMargin
          isBold
          data-cy="summary-subtotal"
          data-testid="subtotal-price"
        >
          {formatPriceToBRL(subTotal)}
        </Text>
      </S.CartItem>

      {servicesSubTotal > 0 && (
        <S.CartItem>
          <Text size="mega" as="span" noMargin>
            Serviço de Instalação
          </Text>
          <Text
            size="mega"
            as="span"
            noMargin
            isBold
            data-testid="service-price"
          >
            {formatPriceToBRL(servicesSubTotal)}
          </Text>
        </S.CartItem>
      )}

      {hasFreight && (
        <S.CartItem>
          <Text size="mega" as="span" noMargin>
            Frete estimado
          </Text>
          {freight === 0 ? (
            <Text
              color="p500"
              size="mega"
              as="span"
              noMargin
              isBold
              data-testid="free-freight"
            >
              Grátis
            </Text>
          ) : (
            <Text
              size="mega"
              as="span"
              noMargin
              isBold
              data-testid="freight-price"
            >
              {formatPriceToBRL(freight)}
            </Text>
          )}
        </S.CartItem>
      )}

      {discount > 0 && (
        <S.CartItem>
          <S.WrapperDiscountInfoStyles>
            <Text size="mega" as="span" noMargin>
              Total de descontos
            </Text>
            {showDiscountsModal && (
              <DiscountsInfoOpenButton
                discount={discount}
                totalDiscountsByType={totalDiscountsByType}
                paymentMethods={paymentMethods}
                firstCheapestPaymentMethod={firstCheapestPaymentMethod}
              />
            )}
          </S.WrapperDiscountInfoStyles>
          <Text
            color="p500"
            size="mega"
            as="span"
            data-cy="summary-discount"
            noMargin
            isBold
            data-testid="discount-price"
          >
            - {formatPriceToBRL(discount)}
          </Text>
        </S.CartItem>
      )}

      {renderTotal()}
    </Card>
  )
}

export default SummaryPricesCart
