import { Provider } from 'react-redux'
import PropTypes from 'prop-types'
import { ModalProvider } from '@leroy-merlin-br/backyard-react'

import { configureStore } from 'lmcv/redux/store'
import { ConfigureInterceptor } from 'lmcv/services/interceptors'

import { App } from './containers/App'
import { Carousel } from './containers/Carousel'

ConfigureInterceptor()
const store = configureStore()

function LoyaltyCarousel ({ widget }) {
  return (
    <Provider store={store}>
      <ModalProvider>
        <App widget={widget}>
          <Carousel />
        </App>
      </ModalProvider>
    </Provider>
  )
}

LoyaltyCarousel.propTypes = {
  widget: PropTypes.shape({
    lazyLoadingUrl: PropTypes.string.isRequired
  }).isRequired
}

export default LoyaltyCarousel
