import { css } from '@emotion/react'
import styled from '@emotion/styled'

const titleRegionStyles = ({ theme }) => css`
  label: modal__title__region;

  color: ${theme.colors.p500};
`

export const TitleRegion = styled('span')(titleRegionStyles)
