type PriceToProps = {
  quantity: string
  un: string
  priceTo: string
}

export const PriceTo = ({ quantity, un, priceTo }: PriceToProps) => {
  return (
    <div className="text-lg font-bold">
      {quantity} {un} {priceTo}
    </div>
  )
}
