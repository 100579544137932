import { css } from '@emotion/react'
import styled from '@emotion/styled'

const shareButtonOverlayStyles = ({ theme }) => css`
  label: share-button__overlay;

  opacity: 0;
  position: fixed;
  transform: scale(-100%);
  transition: all .4s cubic-bezier(.075, .82, .165, 1);
  z-index: ${theme.zIndex.modal};
`

const shareButtonOverlayVisibleStyles = ({ theme, isVisible }) =>
  isVisible &&
  css`
  label: share-button__overlay--visible;
  background: ${theme.colors.black};
  height: 100vh;
  left: 0;
  opacity: .8;
  top: 0;
  transform: scale(1);
  width: 100vw;
`

export const ShareButtonOverlay = styled('div')(shareButtonOverlayStyles, shareButtonOverlayVisibleStyles)
