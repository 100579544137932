import { RecoveryContextProvider } from './context/recovery-context'
import { Routes } from './routes'

type AppProps = {
  isCaptchaEnabled: boolean | string
  captchaKey: string
}

const App = ({ isCaptchaEnabled, captchaKey }: AppProps) => (
  <RecoveryContextProvider
    isCaptchaEnabled={isCaptchaEnabled === 'true'}
    captchaKey={captchaKey}
  >
    <Routes />
  </RecoveryContextProvider>
)

export default App
