import PropTypes from 'prop-types'
import React from 'react'

import { useRunOnce } from 'scripts/react-components/shared/hooks'

import { useRecommendationProducts } from 'site/Modular/hooks'

import { useIntersectionObserver } from 'utils/hooks/use-intersection-observer'

import { filterProductsAdsByProducts } from './utils'
import Skeleton from './Skeleton'
import { useProductsAds } from './hooks'
import Carousel from '../Carousel'

const RetailMediaProductsCarousel = props => {
  const {
    title,
    noContentPadding,
    noTopSpacing,
    context,
    wrapper: Wrapper = React.Fragment,
    extra: { productId, categoryName, term }
  } = props

  const [ref, observerEntry] = useIntersectionObserver()

  const userInfo = JSON.parse(sessionStorage.getItem('userInfo') ?? '{}')

  const { result: productsAds, isFetching: isFetchingProductsAds } =
    useProductsAds({
      context,
      productId,
      categoryName,
      term,
      sessionId: userInfo.userTrackingId,
      userId: userInfo.id
    })

  const { result: products, isFetching: isFetchingProducts } =
    useRecommendationProducts(
      productsAds.map(({ product_sku: productSku }) => ({
        id: parseInt(productSku)
      }))
    )

  const isFetching = isFetchingProductsAds || isFetchingProducts

  const filteredProductsAds = filterProductsAdsByProducts({
    products,
    productsAds
  })

  useRunOnce(() => {
    filteredProductsAds.forEach(product => {
      navigator.sendBeacon(product.impression_url)
    })
  }, Boolean(filteredProductsAds.length))

  useRunOnce(() => {
    filteredProductsAds.forEach(product => {
      navigator.sendBeacon(product.view_url)
    })
  }, Boolean(filteredProductsAds.length) && Boolean(observerEntry?.isIntersecting))

  if (isFetching) {
    return (
      <Wrapper>
        <Skeleton />
      </Wrapper>
    )
  }

  if (products.length < 1) {
    return <></>
  }

  return (
    <Wrapper>
      <div ref={ref}>
        <Carousel
          partnerName="retail media"
          widget={{ title, products }}
          noTopSpacing={noTopSpacing}
          noContentPadding={noContentPadding}
          onProductClick={productId => {
            const product = filteredProductsAds.find(
              product => Number(product.product_sku) === productId
            )

            if (product) {
              navigator.sendBeacon(product.click_url)
            }
          }}
        />
      </div>
    </Wrapper>
  )
}

RetailMediaProductsCarousel.propTypes = {
  wrapper: PropTypes.func,
  title: PropTypes.string,
  context: PropTypes.string,
  extra: PropTypes.shape({
    productId: PropTypes.number,
    categoryName: PropTypes.string,
    term: PropTypes.string
  }),
  noTopSpacing: PropTypes.bool,
  noContentPadding: PropTypes.bool
}

export default RetailMediaProductsCarousel
