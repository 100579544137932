import { Badge, Text } from '@leroy-merlin-br/backyard-react'

import { AlertMessage } from 'shared/components/AlertMessage'
import { useUserResources } from 'shared/hooks'

import { useChatbot } from 'lmcv/hooks'

import { Timeline } from 'checkout/components/MyOrders/components/Timeline'
import { ShippingAddress } from 'checkout/components/MyOrders/components/ShippingAddress'
import { BuilderDisclaimer } from 'checkout/components/MyOrders/modules/messages/shipment'

import * as S from './styled'
import { LockerPinMessage } from './components'

const getAppearance = {
  Cancelado: 'critical',
  Devolvido: 'critical',
  'Problema na separação': 'warning',
  'Tentativa de entrega': 'warning',
  'Aguardando Separação': 'warning',
  'Em análise': 'warning'
}

const ShipmentContent = ({
  type,
  items,
  shippingAddress,
  deliveryDate,
  steps,
  hasCarrierTracking,
  trackingUrl,
  pinCode,
  delayTime
}) => {
  const { userResources } = useUserResources()
  const { openChatbot } = useChatbot()

  const message = deliveryDate && deliveryDate.message

  const orderInfo = {
    type,
    steps,
    hasRegionDriveThru: userResources?.regionDriveThru,
    delayTime
  }

  return (
    <S.Wrapper>
      {!!steps && <Timeline steps={steps} />}

      <AlertMessage type="info" tag="span">
        <Text as="p" size="kilo" noMargin isBold>
          Cuidado com golpes
        </Text>

        <Text as="p" size="kilo" noMargin>
          Não compartilhe seus dados de senha, cartões e dados bancários
          com ninguém.
        </Text>

        <Text as="p" size="kilo" noMargin>
          Em caso de dúvidas, utilize nossos canais digitais de atendimento:{' '}
          <S.Link href="/fale-conosco">Fale conosco</S.Link> e{' '}
          <S.CustomLink onClick={openChatbot}>LIA</S.CustomLink>.
        </Text>
      </AlertMessage>

      <BuilderDisclaimer {...orderInfo} />

      <S.LockerPinWrapper>
        <LockerPinMessage type={type} pinCode={pinCode} />
      </S.LockerPinWrapper>

      {items.map((item, index) => (
        <div key={index} className="item-box">
          <div className="item-thumb">
            <S.ProductImage
              canceled={item.status === 'Cancelado'}
              src={item.image}
              width="75"
              height="75"
            />
            <div>
              <a href={item.url}>
                <span>{item.name}</span>
              </a>
              <div className="margin-top-small small shop-name">
                <span className="color-default">
                  Vendido e entregue por{' '}
                  <strong>{item.shopName || 'Leroy Merlin'}</strong>
                </span>
              </div>
            </div>
          </div>

          <div className="items-infos">
            <div className="infos-box">
              <div className="item">
                <div className="item-title">Qtde.</div>
                <span>{item.quantity}</span>
              </div>
              <div className="item">
                <div className="item-title">Valor unit.</div>
                <span>
                  R$ {item.price.unit.integers}, {item.price.unit.decimals}
                </span>
              </div>
              <div className="item">
                <div className="item-title">Subtotal</div>
                <span>
                  R$ {item.price.subTotal.integers},{' '}
                  {item.price.subTotal.decimals}
                </span>
              </div>
              {item.status && (
                <div className="item">
                  <div className="item-title">Status</div>
                  <S.BadgeText>
                    <Badge
                      appearance={getAppearance[item.status] || 'primary'}
                      variant="subtle"
                    >
                      {item.status}
                    </Badge>
                  </S.BadgeText>
                </div>
              )}
            </div>
          </div>
        </div>
      ))}

      <ShippingAddress
        addressType={type}
        hasCarrierTracking={hasCarrierTracking}
        trackingUrl={trackingUrl}
        {...shippingAddress}
        observation={message}
      />
    </S.Wrapper>
  )
}

export default ShipmentContent
