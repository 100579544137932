import type { SVGAttributes } from 'react'
import { forwardRef } from 'react'

const PoolIcon = forwardRef<SVGSVGElement, SVGAttributes<SVGSVGElement>>((props, ref) => (
  <svg ref={ref} viewBox="0 0 94 90" {...props}>
    <path d="M22.3 17.6c-.6 1.7 1.2 3.4 3.8 3.4.8 0 2.5 1 3.7 2.3 2 2.1 2.2 3.5 2.4 18.2.3 14.3.5 16 2.1 16.3 1.4.3 1.7-.4 1.7-5v-5.3h25v5.2c0 4.6.3 5.3 2 5.3 1.9 0 2-.7 2-16.3 0-14.5-.2-16.5-2-19.5-3.2-5.3-12.7-8.1-11.8-3.6.2 1 1.4 1.9 2.8 2.1 3.2.4 7 4.7 7 7.9V31H36.3l-.6-4.1c-.6-4.7-2.9-7.6-7.4-9.5-4.2-1.8-5.2-1.8-6 .2zM61 39.5V44H36v-9h25v4.5z" />
    <path d="M19.8 59.1c-1 .5-1.8 1.7-1.8 2.5 0 1.3.8 1.4 3.8.9 3-.5 4.3-.1 7.1 2 4.6 3.3 9.9 3.3 15.1-.1 4.3-2.8 6.9-2.6 10.7.8 3.2 3 8.4 2.7 13.3-.7 3.6-2.5 4.5-2.7 7-1.8 2.2.8 3.2.8 3.9-.1 3.5-4.2-7.3-5.8-12.3-1.9-3.6 2.9-7.5 2.9-10.9.2-4.3-3.3-8.6-3.5-13-.5-4.7 3.3-7.6 3.3-12.3.1-3.9-2.6-7.7-3.1-10.6-1.4zM19.1 69.9c-.8.5-1.1 1.6-.8 2.4.4 1.2 1.5 1.4 4 1 2.7-.4 4.3.1 7.3 2.1 4.8 3.2 7.8 3.3 12.9.1 5.1-3.1 7.5-3.1 12.1 0 4.7 3.2 8.1 3.2 12.9-.1 3.1-2.1 4.6-2.5 7.6-2 4.4.7 6.2-1 3.4-3.1-3-2.1-8.7-1.6-12.5 1.2-4.2 3.1-5.8 3.1-10 0-4.5-3.3-9.6-3.3-14.4 0-4.6 3.1-6.4 3.1-10.6 0-3.5-2.6-9.2-3.3-11.9-1.6z" />
  </svg>
))

export default PoolIcon
