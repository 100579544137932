import { applyMiddleware, compose, createStore } from 'redux'
import createSagaMiddleware from 'redux-saga'
import createSagaMonitor from '@clarketm/saga-monitor'
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant'

import createRootReducer from './reducers'
import rootSaga from './sagas'

function configureStoreProd (initialState) {
  const sagaMiddleware = createSagaMiddleware()

  const middlewares = [sagaMiddleware]

  const store = createStore(
    createRootReducer(),
    initialState,
    compose(applyMiddleware(...middlewares))
  )

  sagaMiddleware.run(rootSaga)

  return store
}

function configureStoreDev (preloadedState) {
  const sagaMiddleware = createSagaMiddleware({
    sagaMonitor: createSagaMonitor({
      level: 'log',
      actionDispatch: true
    })
  })

  const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

  const middlewares = [reduxImmutableStateInvariant(), sagaMiddleware]

  const store = createStore(
    createRootReducer(),
    preloadedState,
    composeEnhancer(applyMiddleware(...middlewares))
  )

  sagaMiddleware.run(rootSaga)

  return store
}

export const configureStore =
  process.env.NODE_ENV === 'production' ? configureStoreProd : configureStoreDev
