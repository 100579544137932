import { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import { Text, Icon } from '@leroy-merlin-br/backyard-react'
import {
  ShoppingCartOutline,
  ShoppingCartStarsOutline,
  TrophyOutline,
  TicketDollarOutline,
  StarsOutline,
  FileAltOutline
} from '@backyard-ui/icons'

import { useIntersectionObserver } from 'utils/hooks/use-intersection-observer'

import ListSkeleton from './components/ListSkeleton'
import * as S from './styled'

const iconsDictionary = {
  cart: ShoppingCartOutline,
  cart_special: ShoppingCartStarsOutline,
  trophy: TrophyOutline,
  coupon_money: TicketDollarOutline,
  stars: StarsOutline
}

const BalanceList = ({ data, isLoading, hasNextPage, loadMore }) => {
  const [elementRef, observerEntry] = useIntersectionObserver()

  const getIcon = item => {
    const isManualEntry = `${item.title}`
      .toLowerCase()
      .includes('lançamento manual')

    const isPurchase = `${item.title}`.toLowerCase().includes('compra')

    if (isManualEntry) {
      return ShoppingCartOutline
    }

    if (isPurchase) {
      return ShoppingCartStarsOutline
    }

    const foundIcon = iconsDictionary[item.transaction_icon]

    return foundIcon || FileAltOutline
  }

  useEffect(() => {
    if (observerEntry?.isIntersecting && hasNextPage) {
      loadMore()
    }
  }, [observerEntry, hasNextPage, loadMore])

  const balanceItems = useMemo(() => {
    if (!data || !data.length) {
      return []
    }

    return data.map(balance => {
      const icon = getIcon(balance)
      const color = balance.transaction_type === 'credit' ? 'v500' : 'f500'
      const operator = balance.transaction_type === 'credit' ? '+' : '-'
      const date = dayjs(balance.date).format('ddd, DD/MM [às] HH:mm')

      return {
        ...balance,
        icon,
        color,
        operator,
        date
      }
    })
  }, [data])

  if (!data.length && !isLoading) {
    return (
      <S.WrapperEmptyHistory>
        <Text color="n900">Você não possui pontos</Text>
      </S.WrapperEmptyHistory>
    )
  }

  return (
    <S.List>
      {balanceItems.map(
        ({ color, date, icon, id, operator, points, title }, index) => (
          <S.ListItem key={id} ref={elementRef}>
            {balanceItems[index + 1] && (
              <S.Line
                startColor={color}
                endColor={balanceItems[index + 1].color}
              />
            )}

            <S.Circle color={color}>
              <Icon as={icon} />
            </S.Circle>

            <div>
              <Text size="kilo" color="n900" noMargin>
                {date}
              </Text>
              <Text size="kilo" color="n400" noMargin>
                {title}
              </Text>
            </div>

            <Text noMargin>
              <Text size="giga" color={color} as="span" noMargin isBold>
                {operator} {points}{' '}
              </Text>
              <Text size="kilo" color={color} as="span" noMargin>
                pts
              </Text>
            </Text>
          </S.ListItem>
        )
      )}

      {isLoading && <ListSkeleton />}
    </S.List>
  )
}

BalanceList.propTypes = {
  data: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  hasNextPage: PropTypes.bool.isRequired,
  loadMore: PropTypes.func.isRequired
}

export default BalanceList
