import { tv } from 'tailwind-variants'

export default tv({
  base: '',
  slots: {
    button: `
      flex

      p-0
      mt-2

      text-[14px]
      font-bold
      text-green-700

      bg-transparent

      hover:bg-transparent
      hover:text-green-800

      focus:text-green-800
      focus:bg-transparent
    `,
    label: `
      inline-block
      mb-0

      truncate
      text-gray-700
      hover:text-gray-800

      transition-colors
      duration-150

      w-full
      lg:max-w-[150px]
    `,
    count: `
      inline-flex
      ml-2

      px-2
      py-0.5

      bg-gray-100
      rounded-full

      text-sm
      text-center
      font-bold
    `
  },
  variants: {
    disabled: {
      true: {
        button: 'hidden'
      }
    }
  }
})
