import { Text, Visible } from '@leroy-merlin-br/backyard-react'

import { Icon } from './components/Icon'
import * as S from './styled'

const StampLeroyWarrant = ({ visibility }) => {
  const visibleOn =
    visibility === 'desktop' ? ['mega', 'giga', 'tera'] : ['untilKilo', 'kilo']

  return (
    <Visible when={visibleOn}>
      <S.Wrapper>
        <div>
          <Icon />
        </div>

        <S.Content>
          <Text size="kilo" noMargin isBold>
            Leroy Merlin Garante
          </Text>

          <Text size="kilo" noMargin>
            Garantimos a venda e entrega pela nossa loja parceira.{' '}
            <a target="_blank" rel="noopener" href="/leroy-merlin-garante">
              Saiba mais
            </a>
            .
          </Text>
        </S.Content>
      </S.Wrapper>
    </Visible>
  )
}

export default StampLeroyWarrant
