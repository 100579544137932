import {
  Col,
  Heading,
  Row,
  Select,
  Stack,
  TextField
} from '@leroy-merlin-br/backyard-react'
import {
  components,
  StylesConfig,
  ControlProps,
  OptionProps
} from 'react-select'
import CreatableSelect from 'react-select/creatable'
import AsyncCreatableSelect from 'react-select/async-creatable'

import {
  BankAccountTypeItems,
  BankAccountTypeLabels
} from 'lmcv/pages/CashbackPage/types/bank-account-type'

import { useFormContext } from '../../../../contexts/FormContext'
import * as S from '../../styles/form-styled'

export default function BankForm() {
  const {
    form: { getValues, register, setValue },
    formState: { errors },
    banks,
    handleGetAgenciesByBank,
    internalBankName,
    setInternalBankName,
    internalBankAgency,
    setInternalBankAgency
  } = useFormContext()

  const accountTypesOptions = BankAccountTypeItems.map((item, i) => ({
    value: item,
    label: BankAccountTypeLabels[item]
  }))

  const banksOptions = banks.map((bank, i) => ({
    sys_id: bank.sys_id,
    value: bank.nome_numero,
    label: bank.nome_numero
  }))

  const Input = (props: any) => {
    const { autoComplete = props.autoComplete } = props.selectProps

    return <components.Input {...props} autoComplete={autoComplete} />
  }

  const autoCompleteStyle: StylesConfig = {
    control: (styles: any, state: ControlProps) => ({
      ...styles,
      borderColor: '#EEEEF0',
      minHeight: '50px',
      height: '50px',
      boxShadow: state.isFocused ? null : null,
      borderRadius: 8,
      ':hover': {
        borderColor: '#41A017'
      }
    }),
    option: (styles: any, { isFocused, isSelected }: OptionProps) => ({
      ...styles,
      background: isFocused ? '#a8cf97' : isSelected ? '#41A017' : undefined,
      '&:active': {
        backgroundColor: '#41A017',
        color: '#FFF'
      }
    })
  }

  return (
    <S.Wrapper data-testid="bank-form-component">
      <Stack space="kilo">
        <Heading size="mega" as="h2" noMargin>
          Dados bancários
        </Heading>

        <Row>
          <Col size={{ giga: 6 }}>
            <S.AutoCompleteSelectWrapper>
              <TextField
                label="Banco"
                as={
                  (() => (
                    <S.Block>
                      <CreatableSelect
                        defaultInputValue={getValues('bankName')}
                        defaultValue={getValues('bankName')}
                        components={{ Input, IndicatorSeparator: () => null }}
                        placeholder="Digite seu banco"
                        noOptionsMessage={() => 'Nenhuma opção disponível'}
                        options={banksOptions}
                        styles={autoCompleteStyle}
                        className='bank-select'
                        classNamePrefix='bank-select'
                        formatCreateLabel={(userInput) =>
                          `Selecionar: ${userInput}`
                        }
                        onChange={(bank: any) => {
                          setValue('bankName', bank.value)

                          setInternalBankName(bank)
                        }}
                        value={internalBankName}
                        id='bank-select'
                      />
                    </S.Block>
                  )) as any
                }
                hint={errors.bankName?.message}
                {...register('bankName')}
              ></TextField>
            </S.AutoCompleteSelectWrapper>
          </Col>

          <Col size={{ untilKilo: 3, giga: 6 }}>
            <S.AutoCompleteSelectWrapper>
              <TextField
                label="Agência"
                as={
                  (() => (
                    <S.Block>
                      <AsyncCreatableSelect
                        isDisabled={!getValues('bankName')}
                        placeholder="Digite sua agencia"
                        noOptionsMessage={() => 'Nenhuma opção disponível'}
                        components={{ Input, IndicatorSeparator: () => null }}
                        loadOptions={handleGetAgenciesByBank}
                        loadingMessage={() => 'Buscando...'}
                        styles={autoCompleteStyle}
                        formatCreateLabel={(userInput) =>
                          `Selecionar: ${userInput}`
                        }
                        onChange={(bankAgency: any) => {
                          setValue('bankAgencyNumber', bankAgency.value)

                          setInternalBankAgency(bankAgency)
                        }}
                        value={internalBankAgency}
                        id="bank-agency-select"
                      />
                    </S.Block>
                  )) as any
                }
                hint={errors.bankAgencyNumber?.message}
                {...register('bankAgencyNumber')}
              />
            </S.AutoCompleteSelectWrapper>
          </Col>

          <Col size={{ giga: 6 }}>
            <S.FieldWrapper>
              <TextField
                label="Conta e dígito"
                placeholder="xxxxxxxx-x"
                hint={errors.bankAccountNumber?.message}
                {...register('bankAccountNumber')}
              />
            </S.FieldWrapper>
          </Col>

          <Col size={{ giga: 6 }}>
            <S.FieldWrapper>
              <Select
                label="Tipo de conta"
                {...register('bankAccountType')}
                hint={errors.bankAccountType?.message}
                options={accountTypesOptions}
              />
            </S.FieldWrapper>
          </Col>
        </Row>
      </Stack>
    </S.Wrapper>
  )
}
