import BaseTracking from './base-tracking'

class RichRelevancePurchaseCompleteTracking extends BaseTracking {
  static componentName = 'rich-relevance-purchase-complete-tracking'

  sendItems (data, R3_COMMON) {
    var R3_PURCHASED = new r3_purchased() // eslint-disable-line

    R3_PURCHASED.setOrderNumber(data.orderId)

    data.items.forEach((item) => {
      R3_PURCHASED.addItemIdPriceQuantity(item.itemId, item.price, item.quantity)
    })

    return R3_PURCHASED
  }
}

export default ($el) => new RichRelevancePurchaseCompleteTracking($el).init()

export { RichRelevancePurchaseCompleteTracking as Component }
