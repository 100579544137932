'use client'
import { Children, PropsWithChildren } from 'react'
import { tv, VariantProps } from 'tailwind-variants'

export interface ListItemProps {
  onClick?: () => void
}

export const listItem = tv({
  base: 'flex flex-col content-between gap-2 w-full p-4 border border-neutral-100 rounded-lg my-2',
  variants: {
    clickable: {
      default: '',
      click: 'cursor-pointer'
    },
    color: {
      default: 'bg-white',
      gray: 'bg-gray-100'
    }
  }
})

type ListItemVariants = VariantProps<typeof listItem>

export const ListItem = ({
  onClick,
  children,
  ...tvProps
}: PropsWithChildren<ListItemProps> & ListItemVariants) => {
  return (
    <div
      onClick={onClick}
      className={listItem({
        ...tvProps,
        clickable: onClick ? 'click' : 'default'
      })}
    >
      {children}
    </div>
  )
}
