import axios from 'axios'

export const getCompany = async fiscalId => {
  const response = await axios
    .get(`/api/boitata/v1/company/search?fiscalId=${fiscalId}`)
    .then(({ data }) => data.data)
    .catch(err => {
      throw err.response
    })

  return response
}
