import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { Theme } from 'scripts/react-components/themes/Theme'

const headerStyles = ({ theme }: Theme) => css`
  label: chatmodal_container-header;

  flex-shrink: 0;
  border-radius: ${theme.borderRadius.giga} ${theme.borderRadius.giga} 0 0;
  background: ${theme.colors.p800};
  color: ${theme.colors.white};
`

export const Header = styled('div')(headerStyles)
