import { ResponseType, request } from 'frontendCheckout/services/fetch'

import { endpoints } from './endpoints'

export type Points = {
  points: number
  expiration: string
}

export type Coupon = {
  title: string
  id: string
  expiration?: string
  code: string
}

export interface Voucher {
  title: string
  id: string
  value: number
}

export interface VoucherPercentage extends Voucher {
  expiration?: number
}
export interface VoucherMoney extends Voucher {
  points: number
  active: boolean
  expiration?: string
}

export type CouponAndVoucher = {
  totalOfItems: number
  points: Points
  vouchers: {
    money: VoucherMoney[]
    percentage: VoucherPercentage[]
  }
  coupons: Coupon[]
}

export type response = ResponseType<200, { data: CouponAndVoucher }>

export type status = response extends ResponseType<infer Code, unknown>
  ? Code
  : never

export type props = {
  cartId: string
}

export const getAvailableVouchersAndCoupons = ({ cartId }: props) => {
  return request<response>({
    url: endpoints.getAvailableVouchersAndCoupons(cartId),
    method: 'GET'
  })
}
