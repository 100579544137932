import { Alert, Text } from '@backyard-ui/core'

import DiscountTicket from './components/DiscountTicket'
import * as S from './styled'

// Usando strong com Tailwind por não ter alternativa no <Text />.
const CouponAlertMessage = () => {
  return (
    <S.CouponAlertMessage>
      <Alert.Root variant="neutral">
        <Alert.Icon children={<DiscountTicket />} />
        <Alert.Description>
          <Text size="md" asChild>
            <>
              <strong className="font-bold">
                Você tem cupons disponíveis?
              </strong>{' '}
              Siga com a compra e aplique seu cupom na etapa de pagamento!
            </>
          </Text>
        </Alert.Description>
      </Alert.Root>
    </S.CouponAlertMessage>
  )
}

export default CouponAlertMessage
