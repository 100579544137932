const isListPage = () => {
  const { hostname } = window.location

  return hostname.includes('lista') === 'lista'
}

const removeListSubdomain = (pathname = '') => {
  const { protocol, hostname } = window.location
  const formattedHostname = hostname.replace('lista.', '')

  if (isListPage()) {
    return `${protocol}//${formattedHostname}${pathname}`
  }
  return pathname
}

export { isListPage, removeListSubdomain }
