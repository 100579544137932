import { Fragment } from 'react'
import { Badge, CloseButton, Text } from '@leroy-merlin-br/backyard-react'

import { useUserResources } from 'shared/hooks'
import { AlertMessage } from 'shared/components/AlertMessage'

import * as S from './styled'

const StockInfoHeader = ({ lm, productName, hideStock, lastUpdate }) => {
  const { userResources } = useUserResources()

  const isDriveThru = userResources?.regionDriveThru
  const region = userResources?.regionName

  return (
    <Fragment>
      <S.Header>
        <CloseButton onClick={hideStock} />
        <S.TitleWrapper>
          <S.Title>Disponibilidade de estoque</S.Title>
          <S.Subtitle>
            Selecione a loja que deseja retirar este produto
          </S.Subtitle>
        </S.TitleWrapper>
      </S.Header>

      {lastUpdate && (
        <p className="update-datetime">Atualizado em {lastUpdate}</p>
      )}

      {isDriveThru && (
        <S.AlertMessaWrapper>
          <AlertMessage
            type="info"
            title={`Retiradas via Drive-Thru em ${region}`}
          >
            Devido o cenário atual, em nossas lojas de{' '}
            <Text size="kilo" as="strong" isBold noMargin>
              {region}
            </Text>{' '}
            as retiradas continuam funcionando através do{' '}
            <Text size="kilo" as="strong" isBold noMargin>
              drive-thru
            </Text>
            , tomando todas as medidas para a segurança de todas as pessoas.{' '}
            <S.Link href="https://www.leroymerlin.com.br/lojas">
              Saiba mais
            </S.Link>
          </AlertMessage>
        </S.AlertMessaWrapper>
      )}

      <S.ProductName>
        <Text as="div" color="p600" isBold noMargin>
          {productName}
        </Text>
      </S.ProductName>
      <Badge variant="subtle" appearance="info">
        Cód: {lm}
      </Badge>
    </Fragment>
  )
}

export default StockInfoHeader
