const CATEGORY_PAGE_REFERRER = 'category-page'

type ElementWithDataset = {
  dataset: {
    page: string
  }
}

const IS_CATEGORY =
  (document.querySelector('[data-page]') as unknown as ElementWithDataset)
    ?.dataset.page === 'category'

const IS_SIMPLEX = document.location.href.includes('indexa_id')

export { CATEGORY_PAGE_REFERRER, IS_CATEGORY, IS_SIMPLEX }
