const productSchema = (product) => {
  const { picture, url, name } = product

  const schema = {
    '@context': 'http://schema.org',
    '@type': 'ListItem',
    image: picture,
    url: url,
    name: name
  }

  return schema
}

export default productSchema
