import styled from '@emotion/styled'
import { css } from '@emotion/react'

import type { Theme } from 'scripts/react-components/themes/Theme'

const wrapperModalStyles = ({ theme }: Theme) => css`
  label: modal-discounts__wrapper;
  background: ${theme.colors.white};
  border-radius: ${theme.borderRadius.giga};
  padding: ${theme.spacings.mega};
  width: auto !important;

  ${theme.media.kilo} {
    width: 100% !important;
  }
`
const titleStyles = ({ theme }: Theme) => css`
  label: modal-discounts__text-title;
  font-weight: ${theme.font.weight.bold};
  font-size: ${theme.typography.text.giga.fontSize};
  color: ${theme.colors.black};
`

const wrapperContentsStyles = ({ theme }: Theme) => css`
  label: modal-discounts__contents;
  overflow: hidden;
  margin-block: ${theme.spacings.giga};
  border-radius: ${theme.borderRadius.mega};
  background-color: ${theme.colors.white};
`
const closeButtonStyles = ({ theme }: Theme) => css`
  label: modal-discounts__close-button;
  background: ${theme.colors.white};
  border-radius: ${theme.borderRadius.giga};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;

  svg {
    width: 40%;
    height: 40%;
  }

  &:hover {
    background: ${theme.colors.n100};
  }
`
const wrapperHeaderStyles = ({ theme }: Theme) => css`
  label: modal-discounts__header;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const WrapperModal = styled('div')(wrapperModalStyles)
export const WrapperContents = styled('div')(wrapperContentsStyles)
export const Title = styled('label')(titleStyles)
export const CloseButton = styled('label')(closeButtonStyles)
export const WrapperHeader = styled('label')(wrapperHeaderStyles)
