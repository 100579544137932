import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { Theme } from 'newCheckout/types/Theme'

const buttonWrapperStyles = ({ theme }: Theme) => css`
  label: employee-notification__button-wrapper;

  position: absolute;
  top: 0;
  right: 0;

  svg {
    color: ${theme.colors.white};
  }

  &:hover {
    svg {
      color: ${theme.colors.p700};
    }
  }
`

const wrapperStyles = ({ theme }: Theme) => css`
  label: employee-notification__wrapper;

  position: absolute;
  left: 0;
  right: 0;

  display: flex;
  justify-content: center;

  color: ${theme.colors.white};
  background-color: ${theme.colors.p700};

  padding: ${theme.spacings.giga} ${theme.spacings.mega};
`

export const Wrapper = styled('div')(wrapperStyles)
export const ButtonWrapper = styled('div')(buttonWrapperStyles)
