import { css } from '@emotion/react'
import styled from '@emotion/styled'

const thumbWrapperStyles = ({ theme }) => css`
  label: thumb-wrapper;

  display: flex;
  flex-direction: column;

  overflow: hidden;

  margin-bottom: ${theme.spacings.mega};

  width: 240px;
  height: 336px;
  border-radius: ${theme.borderRadius.tera};
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);

  ${theme.media.mega} {
    width: 340px;
  }

  ${theme.media.giga} {
    width: 369px;
  }
`

export const ThumbWrapper = styled('div')(thumbWrapperStyles)
