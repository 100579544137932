export const BASE_URL =
  ((window as any).env && (window as any).env.baseHost) || ''

export const endpoints = {
  products: (
    productId: number,
    type?: ('similarProducts' | 'accessoryProducts' | 'complementaryProducts')[]
  ) =>
    `${BASE_URL}/api/v3/crosses/${productId}/products${
      !!type && '?type[]=' + type[0]
    }`
}
