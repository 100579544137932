import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { Theme } from 'scripts/react-components/themes/Theme'

const cardWrapperStyles = ({ theme }: Theme) => css`
  label: menu-item-card__wrapper;

  display: flex;
  flex-direction: row;
  align-items: center;

  width: 100%;
  height: 122px;
  background-color: white;
  border: 1px solid ${theme.colors.n100};
  border-radius: ${theme.borderRadius.giga};
  box-shadow: 1px ${theme.colors.shadow};

  padding: ${theme.spacings.mega};

  ${theme.media.giga} {
    width: calc(50% - ${theme.spacings.kilo});
  }

  ${theme.media.tera} {
    width: 290px;
  }
`

export const CardWrapper = styled('div')(cardWrapperStyles)

const cardIconWrapperStyles = ({ theme }: Theme) => css`
  label: menu-item-card__icon;

  width: 64px;
  margin-right: ${theme.spacings.mega};

  & svg {
    width: 64px;
    height: 64px;
  }
`

export const IconWrapper = styled('div')(cardIconWrapperStyles)

const headingStyles = (
  { hasSmallTextOnDesktop, theme }: Theme & { hasSmallTextOnDesktop?: boolean }
) => css`
  ${theme.media.giga} {
    font-size: ${hasSmallTextOnDesktop ? '15px' : '22px'};
  }
`

export const Heading = styled('span')(headingStyles)

const textStyles = (
  { hasSmallTextOnDesktop, theme }: Theme & { hasSmallTextOnDesktop?: boolean }
) => css`
  ${theme.media.giga} {
    font-size: ${hasSmallTextOnDesktop ? '14px' : '16px'};
  }
`

export const Text = styled('span')(textStyles)
