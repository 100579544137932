import styled from '@emotion/styled'
import { css } from '@emotion/react'

const assetPath = window && window.env.assetHost

const baseStyles = ({ theme }) => css`
  label: header;

  position: relative;

  width: 100%;
  height: 107px;

  display: grid;
  grid-template-columns: 1fr 5fr;
  justify-items: center;
  align-items: center;

  margin-top: -${theme.spacings.mega};

  background-image: url(${assetPath}assets/images/lmcv/header/background-mobile.svg);
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;

  overflow: hidden;

  ${theme.media.mega} {
    height: 180px;

    display: flex;
    align-items: center;

    margin-top: -${theme.spacings.giga};

    background-image: url(${assetPath}assets/images/lmcv/header/background-desktop.svg);
  }

  ${theme.media.giga} {
    margin-top: -${theme.spacings.tera};
  }

  ${theme.media.tera} {
    height: 150px;
  }
`

const photoStyles = ({ theme, load }) => css`
  label: photo;

  position: relative;

  flex-shrink: 0;

  width: 70px;
  height: 70px;

  padding: 5px;

  margin: 0 10px;

  border: 1px solid ${load ? theme.colors.n400 : theme.colors.v600};
  border-radius: 50%;

  overflow: hidden;

  ${theme.media.kilo} {
    width: 100px;
    height: 100px;

    margin-left: 60px;
    margin-right: 95px;
  }

  ${theme.media.mega} {
    margin-left: 20px;
    margin-right: 40px;
    background-position: left center;
  }

  ${theme.media.giga} {
    margin-left: 20px;
    margin-right: 40px;
    background-position: left center;
  }

  ${theme.media.tera} {
    width: 90px;
    height: 90px;

    margin-left: 60px;
    margin-right: 95px;
  }
`

const photoImageStyles = ({ theme, avatar }) => css`
  label: photo-image;

  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 24px;
  color: ${theme.colors.n700};

  border-radius: ${theme.borderRadius.circle};

  background-color: ${theme.colors.n400};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  ${avatar ? `background-image: url(${avatar});` : ''}

  box-shadow: 0 2px 6px rgba(84, 60, 158, 0.426437);

  ${theme.media.mega} {
    font-size: 32px;
  }
`

const welcomeStyles = ({ theme }) => css`
  label: welcome;

  display: none;

  ${theme.media.mega} {
    display: block;
  }
`

const textWelcomeStyles = ({ theme }) => css`
  label: welcome__text;

  display: block;

  margin-bottom: 10px;

  color: ${theme.colors.v300};

  font-size: 14px;

  line-height: 17px;
  text-align: right;
`

const textSvgStyles = ({ theme }) => css`
  label: welcome__svg-text;

  display: block;

  width: 118px;

  path {
    fill: ${theme.colors.white};
  }
`

const ticketStyles = ({ theme }) => css`
  label: ticket;

  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  margin-top: -10px;
  margin-left: -30px;

  text-align: center;
  white-space: pre-wrap;

  width: auto;
  padding: ${theme.spacings.bit} ${theme.spacings.exa};

  ${theme.media.kilo} {
    margin-left: -10px;
  }

  ${theme.media.mega} {
    min-width: 175px;
    margin-top: 0;
    margin-left: ${theme.spacings.peta};

    border: 1px solid #b14ae7;
    border-radius: 24px;

    &:before,
    &:after {
      content: '';

      position: absolute;
      top: 50%;

      width: 22px;
      height: 22px;

      background: #8517af;

      transform: translateY(-50%);
    }

    &:before {
      left: -11px;

      border: 1px solid #b14ae7;
      border-radius: 50%;
    }

    &:after {
      left: -23px;
    }
  }

  ${theme.media.giga} {
    min-width: 245px;
    min-height: 75px;
  }
`

const ticketValueStyles = ({ theme }) => css`
  label: ticket__value;

  display: inline-block;
  color: ${theme.colors.white};

  font-size: 24px;
  font-weight: ${theme.font.weight.semibold};

  line-height: 32px;

  ${theme.media.giga} {
    font-size: 36px;
    line-height: 43px;
  }
`

const ticketHaveTextStyles = ({ theme }) => css`
  label: ticket__have-text;

  display: block;
  color: ${theme.colors.f300};

  font-size: 12px;

  line-height: 17px;

  text-align: left;

  ${theme.media.mega} {
    display: none;
  }
`

const expireInfoStyles = ({ theme }) => css`
  label: expire-info;

  width: max-content;

  p {
    font-size: 12px;
    line-height: 15px;
  }

  ${theme.media.mega} {
    margin-bottom: ${theme.spacings.bit};
  }
`

const pointsInfoStyles = ({ theme }) => css`
  label: points-info;

  margin-left: ${theme.spacings.peta};
  margin-top: -${theme.spacings.mega};

  a,
  span {
    font-size: 10px;
  }

  ${theme.media.mega} {
    margin-top: 0px;

    a,
    span {
      font-size: ${theme.typography.text.kilo.fontSize};
    }
  }
`
export const HeaderElement = styled('div')(baseStyles)
export const Photo = styled('div')(photoStyles)
export const PhotoImage = styled('div')(photoImageStyles)
export const Welcome = styled('div')(welcomeStyles)
export const TextWelcome = styled('span')(textWelcomeStyles)
export const TextSvg = styled('svg')(textSvgStyles)
export const Ticket = styled('div')(ticketStyles)
export const TicketValue = styled('span')(ticketValueStyles)
export const TicketHaveText = styled('span')(ticketHaveTextStyles)
export const ExpireInfo = styled('div')(expireInfoStyles)
export const PointsInfo = styled('div')(pointsInfoStyles)
