import { requestStore } from 'utils/request-store'

import instance from './api'
import { API_ENDPOINTS } from './constants'

type Phone = {
  type: string
  number: string
  default: boolean
  external_source: boolean
}

type Address = {
  nickname: string
  cep: string
  street: string
  number: string
  complement: string
  reference: string
  district: string
  city: string
  state: string
  hasPool: boolean
  hasGarden: boolean
  hasOwnership: boolean
  bathroomCount: number
  type: string
  default: boolean
  ibge_city_code: string
  postal_code_type: string
}

type EmailStatus = {
  status: string
  duration: number | null
}

export type UserProfile = {
  id: string
  name: string
  email: string
  emailVerification: [] | EmailStatus
  hasPassword: boolean
  cpf: string
  birthDate: string
  createdAt: string
  updatedAt: string
  occupation: string
  gender: string
  picture: string
  region: string
  regionSlug: string
  favoriteStore: string
  phones: Phone[]
  addresses: Address[]
  notifications: {
    emailNotifications: boolean
    emailOffers: boolean
    smsServices: boolean
    smsNotifications: boolean
    postalNotifications: boolean
    phoneNotifications: boolean
  }
  createdSource: string
  createdSourceClient: string | null
  preferences: {
    loyaltyProgram: boolean
    hasInterestInLoyaltyProgram: boolean
    loyaltyProgramCode: number
  }
  interests: {
    hasChildIndicator: boolean
    homeDecorKnowledgeLevel: string
    DIYRelationType: string
    interestAreaName: string[]
  }
}

export const getUserProfile = async () => {
  const response = await instance
    .get<{ data: UserProfile }>(API_ENDPOINTS.userProfile)
    .then(({ data }) => data)
    .catch(err => {
      throw err
    })

  return response
}

export const storedGetUserProfile = requestStore(getUserProfile)
