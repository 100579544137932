import { css } from '@emotion/react'
import styled from '@emotion/styled'

const wrapperStyles = ({ theme }) => css`
  label: wrapper;

  padding: ${theme.spacings.giga} 0;

  &:first-of-type {
    padding-top: ${theme.spacings.mega};
  }

  &:not(:last-of-type) {
    border-bottom: 1px solid ${theme.colors.n200};
  }
`

export const Wrapper = styled('div')(wrapperStyles)
