import { Stack, SimpleGrid, Spacer, Skeleton } from '@backyard-ui/core'

const HelpfulCommentsSkeleton = () => {
  const shellElements = () => (
    <>
      <Stack direction="column">
        <Skeleton width="257px" height="14px" radius="xl" />
        <Spacer axis="vertical" />
        <Skeleton width={'60%'} height="42px" radius="xl" />
        <Skeleton width={'70%'} height="14px" radius="xl" />

        <Spacer axis="vertical" size="4" />
        <Skeleton width={'95%'} height="14px" radius="xl" />
        <Skeleton width={'95%'} height="14px" radius="xl" />
        <Skeleton width={'40%'} height="14px" radius="xl" />
      </Stack>
    </>
  )

  return (
    <SimpleGrid gapY="10" columns={{ initial: 1, md: 2 }}>
      {shellElements()}
      {shellElements()}
    </SimpleGrid>
  )
}

export default HelpfulCommentsSkeleton
