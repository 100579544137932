import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { Theme } from 'newCheckout/types/Theme'

const titleStyles = ({ theme }: Theme) =>
  css`
    label: success-header__title;
  
    border-bottom: 1px solid ${theme.colors.n300};
    margin-bottom: ${theme.spacings.kilo} ;
  `
export const Title = styled('div')(titleStyles)
