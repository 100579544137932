import { fork } from 'redux-saga/effects'

import Cart from './modules/cart/sagas'
import Associated from './modules/associatedProducts/sagas'
import Coupon from './modules/coupon/sagas'
import CrossServices from './modules/crossServices/sagas'
import Summary from './modules/summary/sagas'

export default function * root () {
  yield fork(Cart)
  yield fork(Associated)
  yield fork(Coupon)
  yield fork(CrossServices)
  yield fork(Summary)
}
