import { toast } from '@leroy-merlin-br/backyard-react'

import { updateProject } from 'lmcv/services/customers'

export const deleteRelationship = (id, onClose) => {
  updateProject(id, { status: 'cancelled' })
    .then(() => {
      onClose()
      window.location = '/fidelidade'
    })
    .catch(() => {
      toast.critical('Algo inesperado aconteceu ao excluir o profissional', {
        variant: 'solid',
        content: 'Tente novamente mais tarde.'
      })
    })
}
