import { Fragment } from 'react'
import { Container, Stack, Card, Text, Spacer } from '@backyard-ui/core'
import { useInstantSearch } from 'react-instantsearch'

function NotFound () {
  const { indexUiState } = useInstantSearch()

  return (
    <Fragment>
      <Spacer axis="vertical" size="10" />
      <Container size="6xl" className="w-full">
        <Card.Root>
          <Card.Body>
            <Spacer axis="vertical" size="4" />
            <Stack direction="column" align="center" justify="center">
              <img
                src={`${window.env.defaultImagePath}/not-found-result.svg`}
                alt="Error Image"
              />
              <div>
                <Text align="center" size="3xl" weight="bold" asChild>
                  <span>Desculpe, não encontramos nenhum resultado para:</span>
                </Text>
                <Text align="center" color="neutral-700" asChild>
                  <span>"{indexUiState.query}"</span>
                </Text>
              </div>
            </Stack>
            <Spacer axis="vertical" size="4" />
          </Card.Body>
        </Card.Root>
      </Container>
      <Spacer axis="vertical" size="10" />
    </Fragment>
  )
}

export default NotFound
