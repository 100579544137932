import { Button } from '@leroy-merlin-br/backyard-react'

import * as S from './styled'
import { DownloadApp } from './Icons'
import {
  clickOnDownload
} from './BannerBehaviour'

const LINK_TO_DOWNLOAD_APP =
  'https://app.adjust.com/161mzw4q?deep_link=app%3A%2F%2Fbr.com.leroymerlin&campaign=default&fallback=https%3A%2F%2Fwww.leroymerlin.com.br'

const BannerComponentMobile = () => (
  <S.Wrapper>
    <S.WrapperIcon>
      <DownloadApp />
    </S.WrapperIcon>
    <S.WrapperText>
      <S.WrapperFirstLine>{'Vem para o app e ganhe'}</S.WrapperFirstLine>
      <S.WrapperSecondLine>{'Descontos exclusivos'}</S.WrapperSecondLine>
    </S.WrapperText>
    <S.WrapperButton>
      <Button
        onClick={clickOnDownload.bind(this, LINK_TO_DOWNLOAD_APP)}>
          {'Ir para o app'}
      </Button>
    </S.WrapperButton>
  </S.Wrapper>
)

export default BannerComponentMobile
