import { Text } from '@leroy-merlin-br/backyard-react'

import { AlertMessage } from 'shared/components/AlertMessage'

const InstallmentsCanceled = () => (
  <AlertMessage type="critical">
    <Text as="span" size="kilo" noMargin>
      Verificamos que o processo de pagamento não foi efetuado, por isso{' '}
      <Text as="strong" size="kilo" isBold noMargin>
        seu pedido foi cancelado
      </Text>
      .
    </Text>
  </AlertMessage>
)

export default InstallmentsCanceled
