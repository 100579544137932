import { history } from 'instantsearch.js/es/lib/routers'

import { createUrlContext } from './createUrlContext'
import { parseUrlContext } from './parseUrlContext'
import { normalizeUrlWithSponsorsParams } from './utils/sponsorsUrlParams'

export const getBoitataFacetsAndFilters = () => {
  const facets = {
    'vendido-por': 'boitataFacets.sellers'
  } as { [key: string]: string }

  const filters = Object.fromEntries(
    Object.entries(facets).map(([key, value]) => [value, key])
  )

  return { facets, filters }
}

const routerProxyCategory = (
  indexName: string,
  region: string,
  categoryFiltersLabel?: {},
  algoliaFacetsObject?: {}
) => ({
  router: history({
    createURL ({ location, routeState }) {
      const { href, sponsorsParams } = normalizeUrlWithSponsorsParams(location)
      let createdUrl = createUrlContext(
        { ...location, href },
        routeState,
        indexName,
        categoryFiltersLabel,
        region
      )

      if (sponsorsParams) {
        createdUrl += createdUrl.includes('?') ? '&' : '?'
        createdUrl += sponsorsParams
      }

      return createdUrl
    },
    parseURL ({ location }) {
      const { href } = normalizeUrlWithSponsorsParams(location)

      return parseUrlContext(
        { ...location, href },
        indexName,
        region,
        categoryFiltersLabel,
        algoliaFacetsObject
      )
    }
  })
})

export default routerProxyCategory
