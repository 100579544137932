import { css } from '@emotion/react'
import styled from '@emotion/styled'

import type { Theme } from 'scripts/react-components/themes/Theme'

const cartItemStyles = ({ theme }: Theme) => css`
  label: cart__item;

  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  padding: ${theme.spacings.kilo} ${theme.spacings.mega};

  border-bottom: 1px solid ${theme.colors.n100};
`

const footerStyles = () => css`
  label: footer;

  display: flex;
  align-items: center;
  justify-content: space-between;
`

const cartItemTotalStyles = ({ theme, hasPaymentMethod }) => css`
  label: cart__item;

  display: block;
  align-items: center;

  padding: ${hasPaymentMethod ? theme.spacings.byte : theme.spacings.kilo} ${theme.spacings.mega};
`

const paymentMethodInformation = ({ theme }: Theme) => css`
  label: cart__summary-payment_method_information
  align-items: center;

  margin-top: -${theme.spacings.bit};

  justify-content: flex-end;

  display: flex;
`

const installmentInformation = ({ theme }: Theme) => css`
  label: cart__summary-installment_information
  align-items: center;

  justify-content: flex-end;

  display: flex;
`

const wrapperDiscountInfoStyles = ({ theme }: Theme) => css`
  label: discounts-info__wrapper;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const CartItem = styled('div')(cartItemStyles)
export const CartItemTotal = styled('div')(cartItemTotalStyles)
export const PaymentMethodInformation = styled('footer')(paymentMethodInformation)
export const InstallmentInformation = styled('footer')(installmentInformation)
export const Footer = styled('footer')(footerStyles)
export const WrapperDiscountInfoStyles = styled('label')(wrapperDiscountInfoStyles)
