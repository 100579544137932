import $ from 'jquery'

import getBreakpoint from 'scripts/utils/get-breakpoint'
import BaseComponent from 'scripts/components/base-component'
import { Component as ProductsFilter } from 'scripts/components/entry-key/products-filter'

class FacetHighlight extends BaseComponent {
  static componentName = 'facet-highlight'

  static DOMEvents = {
    change: 'onOptionChange'
  }

  constructor (element, location = window.location) {
    super(element)
    this.location = location
  }

  init () {
    this.filters = this.getFilters()

    if (!this.isMobile()) {
      this.updateMinWidth()
    }

    this.updateSelected()
  }

  getFilters () {
    const options = this.$element.children('option[value]').toArray()

    return options.map((option) => {
      return new ProductsFilter($(option), { urlAttribute: 'value' }).init()
    })
  }

  onOptionChange (event) {
    const $option = this.$element.find('option:selected')

    const filter = this.filters.find(filter => filter.$element.is($option))
    filter.onElementClick(event)
  }

  updateMinWidth () {
    const $label = this.$element.next('.label')
    const width = $label.width() + 40

    this.$element.css('min-width', width)
  }

  isMobile () {
    return getBreakpoint() === 'mobile'
  }

  updateSelected () {
    const filter = this.filters.find(filter => filter.isFilterApplied())

    if (filter) {
      filter.$element.prop('selected', true)
      this.$element.closest('.field').addClass('active')
    }
  }
}

export default ($el) => new FacetHighlight($el).init()

export { FacetHighlight as Component }
