import { PropsWithChildren } from 'react'
import { VariantProps, tv } from 'tailwind-variants'

import { Component } from '../BaseComponent/BaseComponent'

type TriggerProps = { onClick: () => void }

export const buttonTrigger = tv({
  base: 'flex flex-row gap-2 text-lg font-semibold items-center px-4 h-11 rounded-lg text-left',
  variants: {
    color: {
      primary: 'text-white bg-gradient-to-br from-[#9A21AA] to-[#7808CF]',
      neutral: 'text-[#7808CF] bg-neutral-100'
    }
  },
  defaultVariants: {
    color: 'primary'
  }
})

type ButtonVariants = VariantProps<typeof buttonTrigger>

export const Trigger = ({
  children,
  onClick,
  ...tvProps
}: PropsWithChildren<TriggerProps> & ButtonVariants) => {
  return (
    <Component.Trigger onClick={onClick}>
      <div className={buttonTrigger(tvProps)} aria-label="triggerButton">
        {children}
      </div>
    </Component.Trigger>
  )
}
