import { Fragment } from 'react'
import {
  Heading,
  Icon,
  Inline,
  Stack,
  SubHeading,
  Text
} from '@leroy-merlin-br/backyard-react'
import { GiftOutline } from '@backyard-ui/icons'

import * as S from './styled'
import { CouponData } from '../../types'

type InfoProps = {
  couponData: CouponData
}

const Info = ({ couponData }: InfoProps) => {
  return (
    <Stack>
      {couponData.isShared && (
        <Heading size="tera" noMargin>
          <Fragment>
            <span>Cupom Compartilhável</span>
            <br />
          </Fragment>
        </Heading>
      )}

      {couponData.giftedBy && (
        <Inline alignY="center" space="bit">
          <Icon as={GiftOutline} size="kilo" color="secondary" />
          <Text size="kilo" color="v500" noMargin>
            Presente de {couponData.giftedBy}
          </Text>
        </Inline>
      )}

      {couponData.description && (
        <Fragment>
          <S.InfoItem>
            <SubHeading as="h3" size="mega" color="n800" noMargin>
              Descrição
            </SubHeading>

            <Text size="kilo" color="n500" noMargin>
              {couponData.description}
            </Text>
          </S.InfoItem>

          {couponData.link && (
            <Text size="kilo" as="span" color="p600" noMargin>
              <a href={couponData.link} target="_blank">
                Veja a página do produto
              </a>
            </Text>
          )}
        </Fragment>
      )}

      {couponData.rules && (
        <S.InfoItem>
          <SubHeading as="h3" size="mega" color="n800" noMargin>
            Regras de Utilização
          </SubHeading>
          <Text size="kilo" color="n500" noMargin>
            {couponData.rules}
          </Text>
        </S.InfoItem>
      )}
    </Stack>
  )
}

export default Info
