import { Visible, Text, Heading } from '@leroy-merlin-br/backyard-react'

import { useUserResources } from 'shared/hooks'

import * as S from './styled'

const ExclusiveSaleSiteMessage = ({ visibility }) => {
  const { userControls: { isAssistedSale }, isLoadingResources } = useUserResources()

  if (isAssistedSale || isLoadingResources) {
    return null
  }

  const visibleOn =
    visibility === 'desktop' ? ['mega', 'giga', 'tera'] : ['untilKilo', 'kilo']

  return (
    <Visible when={visibleOn}>
      <S.Wrapper>
        <Heading size="kilo" as="h5" noMargin>
          Vendido exclusivamente no site
        </Heading>

        <Text size="kilo" noMargin>
          Este produto é disponibilizado pela nossa loja parceira e pode ser
          comprado{' '}
          <Text size="kilo" as="strong" isBold noMargin>
            exclusivamente através do nosso site. Aproveite!
          </Text>
        </Text>
      </S.Wrapper>
    </Visible>
  )
}

export default ExclusiveSaleSiteMessage
