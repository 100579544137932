import { typeAttributesFilter } from './refinementFilters/typeAttributes'
import { typeBoitataFacetsFilter } from './refinementFilters/typeBoitataFacets'

interface refinementListProps {
  [key: string]: Array<string>
}

export const refinementListFilter = (
  routeStateRefinementList: refinementListProps,
  numberFilter: number,
  urlToConcatenate: string,
  categoryFiltersLabel = {},
  region?: string
) => {
  const entries = Object.entries(routeStateRefinementList)
  entries.map(([key, type]) => {
    if (key.includes('attributes')) {
      urlToConcatenate = typeAttributesFilter(
        key,
        type,
        urlToConcatenate,
        numberFilter,
        categoryFiltersLabel
      )
    } else {
      urlToConcatenate = typeBoitataFacetsFilter(
        key,
        type,
        urlToConcatenate,
        numberFilter,
        region
      )
    }
  })
  return urlToConcatenate
}
