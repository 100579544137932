import { tv } from 'tailwind-variants'

export const inputButton = tv({
    base: 'font-semibold cursor-pointer',
    variants: {
      color: {
        default: 'text-green-700',
        error: 'text-critical-700'
      }
    }
  })