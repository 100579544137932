import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { TextField } from 'shared/components'

const textFieldMarginStyles = ({ theme }) => css`
  label: field_margin;

  margin-bottom: ${theme.spacings.mega};

  ${theme.media.giga} {
    margin-bottom: 0;
  }
`

const buttonWrapperStyles = ({ theme }) => css`
  label: button-wrapper;

  width: 100%;

  margin-top: ${theme.spacings.mega};
`

export const TextFieldMargin = styled(TextField)(textFieldMarginStyles)
export const ButtonWrapper = styled('div')(buttonWrapperStyles)
