import PropTypes from 'prop-types'

import tryAddToCart from 'scripts/utils/cart/addToCart'

const AddProductsButton = ({ items }) => {
  /**
   * Send all products in the context to cart api.
   *
   */
  const sendProductsToCart = async items => {
    await tryAddToCart(prepareItems(items), 'wishlist')
    location.href = '/carrinho/'
  }

  /**
   * Decorate our products with the quantity and
   * return in the cart api accepted format.
   *
   */
  const prepareItems = items => {
    const products = items.map(item => ({
      id: item.id,
      skuId: item.skuId,
      quantity: 1
    }))

    return {
      type: 'delivery',
      products
    }
  }

  return (
    <button
      className="button button-full wishlist-drawer-button no-shadow"
      onClick={() => sendProductsToCart(items)}
    >
      Adicionar produtos ao carrinho
    </button>
  )
}

AddProductsButton.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      skuId: PropTypes.string
    })
  ).isRequired
}

export default AddProductsButton
