import { Stack, Card, Text } from '@leroy-merlin-br/backyard-react'

import { Sorters } from '../Sorters'
import * as S from './styled'

const SortersStack = () => {
  return (
    <S.Wrapper data-cy="sorter-filter">
      <Card target="sorter">
        <Stack space="kilo">
          <Text as="strong" size="giga" isBold noMargin>
            Ordenar por
          </Text>
          <Sorters />
        </Stack>
      </Card>
    </S.Wrapper>
  )
}

export default SortersStack
