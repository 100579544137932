import { css } from '@emotion/react'
import styled from '@emotion/styled'

const containerStyles = ({ theme }) => css`
  label: modules-error__container;

  background: ${theme.colors.y500};
  border-radius: ${theme.borderRadius.tera};

  position: relative;
  padding: ${theme.spacings.byte};
  max-width: 100%;

  ${theme.media.mega} {
    position: absolute;
    top: 16px;
    left: 16px;
    padding: ${theme.spacings.mega};
    max-width: 320px;
  }
`

export const Container = styled('div')(containerStyles)
