import { forwardRef } from 'react'
import PropTypes from 'prop-types'

import {
  buildImageUrl,
  buildSizes,
  buildSrcSet
} from 'shared/utils/image-resizing'

import { cloudflarePropTypes } from './prop-types'

const DEFAULT_BREAKPOINTS = {
  480: { width: 480, slot: 480 },
  768: { width: 768, slot: 768 },
  1180: { width: 1180, slot: 1180 }
}

const OptimizedImage = forwardRef(
  (
    {
      as,
      src,
      noSrcSet = false,
      breakpoints = DEFAULT_BREAKPOINTS,
      slot,
      width,
      height,
      fit,
      quality,
      dpr,
      gravity,
      anim,
      sharpen,
      blur,
      background,
      rotate,
      metadata,
      ...rest
    },
    ref
  ) => {
    const Tag = as || 'img'

    const options = {
      width,
      height,
      fit,
      quality,
      dpr,
      gravity,
      anim,
      sharpen,
      blur,
      background,
      rotate,
      metadata
    }

    const sizes = breakpoints ? Object.keys(breakpoints) : undefined
    const maxWidth = Number(sizes?.[sizes.length - 1])
    const defaultWidth = slot || width

    const srcSetBreakpoints = sizes?.reduce(
      (result, size) => ({
        [size]: breakpoints[size].width,
        ...result
      }),
      { [maxWidth + 1]: width }
    )

    const sizeBreakpoints = sizes?.reduce(
      (result, size) => ({
        [size]: breakpoints[size].slot,
        ...result
      }),
      {}
    )

    const srcProps = {
      width,
      height,
      src: buildImageUrl(src, options),
      ...(!noSrcSet && {
        srcSet: buildSrcSet(src, options, srcSetBreakpoints),
        sizes: buildSizes(sizeBreakpoints, defaultWidth)
      })
    }

    return (
      <Tag width={width} height={height} ref={ref} {...rest} {...srcProps} />
    )
  }
)

OptimizedImage.propTypes = {
  as: PropTypes.elementType,
  src: PropTypes.string.isRequired,
  noSrcSet: PropTypes.bool,
  breakpoints: PropTypes.objectOf(
    PropTypes.shape({
      width: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  ),
  slot: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  ...cloudflarePropTypes
}

OptimizedImage.Source = ({
  src,
  breakpoint,
  width,
  height,
  fit,
  quality,
  dpr,
  gravity,
  anim,
  sharpen,
  blur,
  background,
  rotate,
  metadata,
  ...rest
}) => {
  const options = {
    width,
    height,
    fit,
    quality,
    dpr,
    gravity,
    anim,
    sharpen,
    blur,
    background,
    rotate,
    metadata
  }

  return (
    <source
      media={`(max-width: ${breakpoint}px)`}
      srcSet={buildImageUrl(src, options)}
      width={width}
      height={height}
      {...rest}
    />
  )
}

OptimizedImage.Source.propTypes = {
  src: PropTypes.string.isRequired,
  breakpoint: PropTypes.number,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  ...cloudflarePropTypes
}

export default OptimizedImage
