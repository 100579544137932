const Pin = () => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.76742 0.5C9.34882 0.5 10.8372 1.05814 11.8604 2.0814C12.9767 3.19767 13.6279 4.59302 13.7209 6.0814C13.7209 6.9186 13.4418 7.75581 13.0698 8.5L11.2093 12.0349L8.8837 16.0349C8.69766 16.314 8.41859 16.5 8.04649 16.5C7.6744 16.5 7.39533 16.314 7.20929 16.0349L2.74417 8.40698C2.65115 8.22093 2.55812 8.03488 2.55812 7.94186C2.37208 7.2907 2.27905 6.73256 2.27905 6.0814C2.27905 3.01163 4.69766 0.593023 7.76742 0.5ZM10.5581 3.47674C9.90696 2.73256 8.97673 2.36047 7.95347 2.36047H7.86045C5.81394 2.45349 4.13952 4.03488 4.13952 6.0814C4.13952 6.45349 4.23254 6.9186 4.32557 7.2907C4.32557 7.33721 4.34882 7.38372 4.37208 7.43023C4.39533 7.47674 4.41859 7.52326 4.41859 7.56977L7.95347 13.7093L9.44184 11.1047L11.3023 7.66279L11.3023 7.66278C11.4884 7.19767 11.6744 6.73255 11.6744 6.17442C11.6744 5.15116 11.3023 4.22093 10.5581 3.47674ZM10.279 5.98837C10.279 7.29069 9.25577 8.31395 7.95345 8.31395C6.65112 8.31395 5.62787 7.29069 5.62787 5.98837C5.62787 4.68604 6.65112 3.66278 7.95345 3.66278C9.25577 3.66278 10.279 4.68604 10.279 5.98837ZM6.83717 5.98837C6.83717 6.63953 7.30229 7.10465 7.95345 7.10465C8.60461 7.10465 9.06973 6.63953 9.06973 5.98837C9.06973 5.3372 8.60461 4.87209 7.95345 4.87209C7.30229 4.87209 6.83717 5.3372 6.83717 5.98837Z"
      fill="#222020"
    />
  </svg>
)

export default Pin
