import styled from '@emotion/styled'
import { css } from '@emotion/react'

const wrapperstyles = ({ theme }) => css`
  label: section__wrapper;

  margin-top: ${theme.spacings.mega};
  order: 0;

  div[class*='card__body'] {
    overflow-y: unset;
  }

  ${theme.media.giga} {
    order: 1;
  }
`

const titleStyles = ({ theme }) => css`
  label: section__title;

  display: flex;

  flex-direction: column;
  margin-bottom: ${theme.spacings.mega};

  ${theme.media.kilo} {
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 0;
  }
`

export const Wrapper = styled('section')(wrapperstyles)
export const Title = styled('div')(titleStyles)
