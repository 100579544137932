import { PropsWithChildren } from 'react'
import { Alert } from '@backyard-ui/core'

import { Error as ErrorIcon } from 'frontendCheckout/assets/icons'

type ErrorProps = {}

export const Error = ({ children }: PropsWithChildren<ErrorProps>) => {
  return (
    <Alert.Root appearance="critical" variant="subtle">
      <Alert.Icon children={<ErrorIcon />} />
      <Alert.Description>
        <div className="text-critical-700">{children}</div>
      </Alert.Description>
    </Alert.Root>
  )
}
