import { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Badge, Text } from '@leroy-merlin-br/backyard-react'

import { SameDayDeliveryInfo } from 'shared/components'

import formatPriceToBRL from 'utils/formatPriceToBRL'

const ShipmentPriceInfo = ({
  children,
  cost,
  hasBadge = false,
  message = { prefix: '', main: '' },
  isSameDayDelivery = false,
  sameDayDeliveryKey
}) => {
  const PriceContent = ({ withBadge, value }) => {
    const price = formatPriceToBRL(value)

    if (withBadge) {
      return (
        <Badge variant="subtle" appearance="primary">
          {price}
        </Badge>
      )
    }
    return price
  }

  if (isSameDayDelivery) {
    return (
        <SameDayDeliveryInfo
          deliveryPrice={cost}
          deliveryKey={sameDayDeliveryKey}
          displayFullMessage={true}
        />
    )
  }

  return (
    <Text as="span" noMargin size="kilo">
      {children || 'Receba'} {message.prefix}{' '}
      <Text as="strong" isBold noMargin size="kilo">
        {message.main}*
      </Text>{' '}
      por{' '}
      <Text as="strong" isBold noMargin size="kilo">
        <PriceContent withBadge={hasBadge} value={cost} />
      </Text>
    </Text>
  )
}

export default ShipmentPriceInfo

ShipmentPriceInfo.propTypes = {
  hasBadge: PropTypes.bool,
  cost: PropTypes.number.isRequired,
  message: PropTypes.shape({
    prefix: PropTypes.string.isRequired,
    main: PropTypes.string.isRequired
  }),
  sameDayDeliveryKey: PropTypes.string,
  isSameDayDelivery: PropTypes.bool
}
