import { useStandaloneService } from 'frontendCheckout/hooks'
import { formatters } from 'frontendCheckout/utils'

type ShippingDisclaimerType = {
  isServicesEnabled: string | boolean
  hasShippingOptions: string | boolean
  services: string | unknown[]
}

const SHIPPING_DISCLAIMER_MESSAGES = {
  normal: (
    <>
      Se necessário entraremos em contato via <strong>ligação</strong>,{' '}
      <strong>SMS</strong> ou <strong>Whatsapp</strong> em seu celular principal
      para informar qualquer <strong>atualização do seu pedido</strong>.
    </>
  ),
  relatedService: (
    <>
      É importante informar o seu <b>número de celular</b> para que possamos
      confirmar o <b>agendamento do serviço via SMS</b>, além de enviar todas as{' '}
      <b>atualizações</b> do seu pedido via ligação, SMS ou WhatsApp.
    </>
  ),
  standAloneService: (
    <>
      Importante, preencha com atenção. Caso necessário, entraremos em contato
      com esse número para agendar o serviço. Via SMS, Whatsapp ou ligação.
    </>
  )
}

export const ShippingDisclaimer = (props: ShippingDisclaimerType) => {
  const isServicesEnabled = formatters.parseIfJSON(props.isServicesEnabled)
  const hasShippingOptions = formatters.parseIfJSON(props.hasShippingOptions)
  const services = formatters.parseIfJSON(props.services)

  const hasServices = isServicesEnabled && services?.length > 0

  const { isStandaloneServiceCart } = useStandaloneService({
    services: services,
    isCartWithoutProducts: !hasShippingOptions
  })

  const servicePhrase = isStandaloneServiceCart ? 'standAloneService' : 'relatedService'
  const shippingDisclaimerText = SHIPPING_DISCLAIMER_MESSAGES[hasServices ? servicePhrase : 'normal']

  return (
    <div className="mb-4 text-left" style={{ fontSize: '14px' }}>{shippingDisclaimerText}</div>
  )
}
