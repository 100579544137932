import { call, put, takeLatest, takeEvery } from 'redux-saga/effects'
import { toast } from '@leroy-merlin-br/backyard-react'

import * as service from 'lmcv/services/coupon'

import { Constants, Actions } from '.'

function * getById ({ id, category, active }) {
  try {
    const result = yield call(service.getCoupon, id, category, active)

    yield put(Actions.getComplete(result.data))
  } catch {
    yield put({
      type: Constants.COUPON_GET_COMPLETE,
      error: true
    })
  }
}

function * getAllCouponMoney ({ page, status, perPage }) {
  try {
    const { data } = yield call(
      service.getCouponsByType,
      'money',
      page,
      status,
      perPage
    )

    yield put(Actions.getMoneySuccess(data.data.vouchers))
  } catch (error) {
    yield put(Actions.getMoneyFailure(error.data))
  }
}

function * getAllCouponMoneyPaginated ({ page, status, perPage }) {
  try {
    const { data } = yield call(
      service.getCouponsByType,
      'money',
      page,
      status,
      perPage
    )

    yield put(Actions.getMoneyPaginatedSuccess(data.data.vouchers))
  } catch (error) {
    yield put(Actions.getMoneyPaginatedFailure(error.data))
  }
}

function * getAllCouponPercentage ({ page, status, perPage }) {
  try {
    const { data } = yield call(
      service.getCouponsByType,
      'advantages',
      page,
      status,
      perPage
    )

    yield put(Actions.getPercentageSuccess(data.data.vouchers))
  } catch (error) {
    yield put(Actions.getPercentageFailure(error.data))
  }
}

function * getAllCouponPercentagePaginated ({ page, status, perPage }) {
  try {
    const { data } = yield call(
      service.getCouponsByType,
      'advantages',
      page,
      status,
      perPage
    )

    yield put(Actions.getPercentagePaginatedSuccess(data.data.vouchers))
  } catch (error) {
    yield put(Actions.getPercentagePaginatedFailure(error.data))
  }
}

function * getAllCouponActivated ({ page, category, perPage }) {
  try {
    const { data } = yield call(
      service.getCouponsByType,
      category,
      page,
      'active',
      perPage
    )

    yield put(Actions.getActivatedSuccess(data.data.vouchers))
  } catch (error) {
    yield put(Actions.getActivatedFailure(error.data))
  }
}

function * activeById ({ id, category, active }) {
  try {
    const { data } = yield call(service.setCouponActive, id, category, active)

    yield put(Actions.activeSuccess(id, category, { isActive: true, activatedId: data?.voucher?.activatedId }))

    toast.primary('Cupom ativado com sucesso!', { variant: 'solid' })
  } catch (error) {
    yield put(Actions.activeFailure(id, category, error?.data))
  }
}

function * shareById ({ id, inhabitantId, callback }) {
  try {
    yield call(service.setShareCouponActive, id, inhabitantId)
    yield call(toast.primary, 'Cupom compartilhado', { variant: 'solid' })
    yield call(callback)
    yield put(Actions.shareSuccess())
  } catch (error) {
    yield call(toast.critical, 'Não foi possível compartilhar esse cupom', {
      content: 'Tente novamente mais tarde.',
      variant: 'solid'
    })
    yield call(callback)
    yield put(Actions.shareFailure(error?.data))
  }
}

export default function * root () {
  yield takeLatest(Constants.COUPON_GET, getById)
  yield takeEvery(Constants.COUPON_ACTIVE, activeById)
  yield takeLatest(Constants.COUPON_SHARE, shareById)
  yield takeLatest(Constants.COUPON_GET_MONEY, getAllCouponMoney)
  yield takeLatest(
    Constants.COUPON_GET_MONEY_PAGINATED,
    getAllCouponMoneyPaginated
  )
  yield takeLatest(Constants.COUPON_GET_PERCENTAGE, getAllCouponPercentage)
  yield takeLatest(
    Constants.COUPON_GET_PERCENTAGE_PAGINATED,
    getAllCouponPercentagePaginated
  )
  yield takeLatest(Constants.COUPON_GET_ACTIVATED, getAllCouponActivated)
  yield takeLatest(Constants.COUPON_ACTIVE_SUCCESS, getAllCouponActivated)
}
