import { useContext } from 'react'

import { Markdown } from 'shared/components/Markdown'

import { EntryKeyContext } from '../../context/EntryKeyContext'
import * as S from './styled'

const BottomContent = () => {
  const {
    pageInfo: { descriptiveText }
  } = useContext(EntryKeyContext)

  return (
    <S.Wrapper>
      <Markdown content={descriptiveText} type="small" />
    </S.Wrapper>
  )
}

export default BottomContent
