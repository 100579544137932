import { css } from '@emotion/react'
import styled from '@emotion/styled'

const spinnerWrapperStyles = ({ theme }) => css`
  label: spinner-wrapper;

  display: flex;

  height: 100%;
  width: 100%;

  position: absolute;
  top: 0;
  left: 0;

  align-items: center;
  justify-content: center;

  z-index: ${theme.zIndex.backdrop};
  background-color: ${theme.colors.white}AA;
  border-radius: ${theme.borderRadius.mega};
`

export const SpinnerWrapper = styled('div')(spinnerWrapperStyles)
