const CrossIcon = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      y="11.0925"
      width="15.6872"
      height="3.38172"
      rx="1.69086"
      transform="rotate(-45 0 11.0925)"
      fill="black"
    />
    <rect
      x="11.0928"
      y="13.4839"
      width="15.6872"
      height="3.38172"
      rx="1.69086"
      transform="rotate(-135 11.0928 13.4839)"
      fill="black"
    />
  </svg>
)

export default CrossIcon
