import $ from 'jquery'
import feedbackErrorsTemplate from 'templates/feedback-errors'

import emitter from 'scripts/utils/emitter'
import BaseComponent from 'scripts/components/base-component'
import { showSpinner, hideSpinner } from 'scripts/utils/spinner'

const DEFAULTS = {
  endpoint: '/hybris/carts/current',
  quantity: 1,
  messages: {
    errors: {
      fail: ['Erro interno, tente novamente mais tarde']
    }
  }
}

class AddToHybrisCart extends BaseComponent {
  static componentName = 'add-to-hybris-cart'

  static DOMEvents = {
    click: 'onButtonClick'
  }

  static emitterEvents = {
    'buybox:sellerChange': 'onBuyBoxChange'
  }

  constructor (element, options = {}) {
    super(element)

    this.options = $.extend({}, DEFAULTS, options)
    this.components = {}
  }

  init () {
    this.lm = this.$element.data('lm')
    this.skuId = this.$element.data('shop-offer-id')
    this.$stickyQuantity = $('[data-sticky-quantity]')
    this.buyBox = $('[data-component="buybox"]').data('buybox')

    this.setSkuIdFromBuyBox()
  }

  isOnBuyBoxContext () {
    return !!(this.buyBox && this.buyBox.sellerCheckedDefault)
  }

  setSkuIdFromBuyBox () {
    if (this.isOnBuyBoxContext()) {
      this.skuId = this.buyBox.getSellerInitialCheckedOption()
    }
  }

  onButtonClick (e) {
    if (e.target.hasAttribute('data-tooltip-message')) {
      return e.preventDefault()
    }

    showSpinner()

    this.setQuantity()
    this.addProduct()
  }

  setSkuId (id = this.skuId) {
    this.skuId = id
  }

  setQuantity () {
    const parent = this.$element.closest('[quantity-parent]').find('.quantity-selector')

    this.components.quantity = parent.data('quantity')

    if (this.components.quantity) {
      this.options.quantity = this.components.quantity.getValue()
    } else {
      this.options.quantity = this.$stickyQuantity.data('sticky-quantity')
    }
  }

  addProduct () {
    const data = {
      lm: this.lm,
      skuId: this.skuId,
      quantity: this.options.quantity
    }

    $.get(this.options.endpoint, data)
      .done(this.onAddProductSucess.bind(this))
      .fail(this.onAddProductFailure.bind(this))
      .always(hideSpinner)
  }

  onAddProductSucess (data) {
    this.redirectTo(data.redirect)
  }

  onAddProductFailure ({ responseJSON }) {
    let errors = this.options.messages.errors.fail

    if (responseJSON && responseJSON.errors) {
      errors = responseJSON.errors
    }

    emitter.emit('stickyFeedback:error', {
      title: 'Ops! Ocorreram os seguintes erros:',
      content: feedbackErrorsTemplate({ errors })
    })
  }

  redirectTo (url) {
    window.location.href = url
  }

  onBuyBoxChange ({ purchaseButtonAssistedSaleDisabled, shopOfferId: skuId }) {
    const shouldDisable = (purchaseButtonAssistedSaleDisabled === 'true')

    this.$element.attr('disabled', shouldDisable)
    this.$element.toggleClass('disabled', shouldDisable)

    this.setSkuId(skuId)
  }
}

export default ($el) => new AddToHybrisCart($el).init()

export { AddToHybrisCart as Component }
