import { Fragment, useContext, useEffect, useState } from 'react'
import { Visible } from '@leroy-merlin-br/backyard-react'

import emitter from 'scripts/utils/emitter'

import { HeaderContext } from '../../contexts/HeaderContext'
import { HybrisCart } from './components/HybrisCart'
import CartDrawer from './components/CartDrawer'
import * as S from './styled'
import { useCartDrawerContext } from '../../contexts/CartDrawerContext'

const CART_URL = `${window.env && window.env.baseHost}/carrinho`

const Cart = () => {
  const headerContext = useContext(HeaderContext)
  const [cartProductsCount, setProductsCount] = useState(0)

  const { toggleCartDrawer } = useCartDrawerContext()

  const {
    userResources: { cartItems, context, region },
    assistedSalesLinks
  } = headerContext

  const openCart = async event => {
    if (cartProductsCount !== 0) {
      event.preventDefault()
      toggleCartDrawer()
    }
  }

  const renderCartLink = () => {
    if (cartProductsCount) {
      return (
        <S.CartCount data-cy="minicart-count-header">
          {cartProductsCount}
        </S.CartCount>
      )
    }

    return null
  }

  useEffect(() => {
    emitter.on('cart:update', ({ count }) => {
      setProductsCount(count)
    })
  }, [])

  useEffect(() => {
    setProductsCount(cartItems)
  }, [cartItems])

  if (!context) {
    return (
      <section className="cart">
        <S.CartLink>
          <i className="glyph glyph-cart" />
          <Visible when={['giga', 'tera']}>
            <S.DesktopComplement>Meu Carrinho</S.DesktopComplement>
          </Visible>
        </S.CartLink>
      </section>
    )
  }

  if (context === 'assisted-sale') {
    return <HybrisCart assistedSalesLinks={assistedSalesLinks} />
  }

  return (
    <Fragment>
      <section>
        <S.CartLink
          href={CART_URL}
          onClick={openCart}
          title="Meu carrinho"
          data-cy="minicart-button-header"
        >
          {renderCartLink()}
          <i className="glyph glyph-cart" />
          <Visible when={['giga', 'tera']}>
            <S.DesktopComplement>Meu Carrinho</S.DesktopComplement>
          </Visible>
        </S.CartLink>
      </section>
      <CartDrawer />
    </Fragment>
  )
}

export default Cart
