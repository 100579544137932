import { datadogRum } from '@datadog/browser-rum'

import { sendToApp } from 'scripts/react-components/utils/app/tracker'

import request from 'catalog/utils/axios'

const STORAGE_KEY = '@app:dateLatestAppVersionUpdateAlert'

export const clickAlertNewVersionButton = (category: string, label: string) => {
  const dataObject = {
    event: 'update',
    'event-category': category,
    'event-action': 'clicou_botao',
    'event-label': label
  }
  sendToApp(dataObject)
}

export type checkOldAppModaProps = {
  canUpdateApp: boolean
  mustUpdateApp: boolean
  timeForNavigeteInOldApp: number
}

type getVersionResponse = {
  data: checkOldAppModaProps
}

export const loadVersionData = async () => {
  try {
    const resp = await request.get<getVersionResponse>(
      '/api/v3/app/update-app-version'
    )

    return resp.data.data
  } catch (error) {
    datadogRum.addError(error)
    return {
      canUpdateApp: false,
      mustUpdateApp: false,
      timeForNavigeteInOldApp: 0
    }
  }
}

const sumMinutesToDate = (date: Date, minutes: number) => {
  return new Date(date.setMinutes(minutes + date.getMinutes()))
}

export const verifyShouldAlertToUpdateApp = (
  versionData: checkOldAppModaProps
) => {
  const localStorageTimeNewApp = localStorage.getItem(STORAGE_KEY)

  if (localStorageTimeNewApp === null) {
    return true
  }

  const timeNow: Date = new Date()
  const timeLocalStorage = new Date(localStorageTimeNewApp)

  if (
    timeLocalStorage == 'Invalid Date' ||
    sumMinutesToDate(timeLocalStorage, versionData.timeForNavigeteInOldApp) <
      timeNow
  ) {
    return true
  }

  return false
}

export const defineTimeToAlertAgain = () => {
  const timeOfClick = new Date()
  localStorage.setItem(STORAGE_KEY, timeOfClick.toString())
}
