import cookies from 'js-cookie'
import axios from 'axios'

import { api } from './api'

const REGION = cookies.get('region')

export const getOptions = async () => {
  try {
    const { data } = await api.get<{ data: any }>('/account/options', {
      headers: { 'X-Region': REGION }
    })

    return data
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error.response
    } else {
      throw error
    }
  }
}

export const getInterests = async () => {
  try {
    const { data } = await api.get<{ data: any }>('/account/interests')

    return data
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error.response
    } else {
      throw error
    }
  }
}

export const updateInterests = async (payload: any) => {
  try {
    const { data } = await api.post<{ data: any }>(
      '/account/interests',
      payload
    )

    return data
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error.response
    } else {
      throw error
    }
  }
}
