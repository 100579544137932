import { css } from '@emotion/react'
import styled from '@emotion/styled'

const baseStyles = () => css`
  label: breadcrumb__list;

  display: flex;
  align-items: center;

  overflow-x: auto;
  white-space: nowrap;
`

export const List = styled('nav')(baseStyles)
