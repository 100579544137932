import { useContext, cloneElement } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import { onMenuClick } from 'scripts/utils/data-layer'

import { DEPARTMENT_ICONS } from 'shared/icons'

import { DropdownContext } from 'site/Header/contexts/DropdownContext'

import { useResize } from 'utils/hooks/use-resize'
import slugify from 'utils/slugify'

import ChildrenDesktop from './ChildrenDesktop'
import ChildrenMobile from './ChildrenMobile'

function Children ({ element }) {
  const context = useContext(DropdownContext)
  const isDepartament = element.name === 'Departamentos'
  const [isMobile] = useResize(992)

  const rootElement = element.name

  const onItemClick = (event, item) => {
    event.preventDefault()
    event.stopPropagation()

    onMenuClick({ rootElement, item, isMobile })

    const anchorElement = event.currentTarget.querySelector('a')
    const url = anchorElement.href

    window.location.href = url
  }

  const renderChildren = element => {
    if (!isMobile) {
      return <ChildrenDesktop item={element} rootElement={rootElement} />
    }

    return <ChildrenMobile item={element} rootElement={rootElement} />
  }

  const onHoverMenuItem = el => {
    const { setChildren, setItem, showChildrenShell } = context

    if (el.id && el.hasChildren) {
      if (!isMobile) {
        return {
          onMouseEnter: e => {
            showChildrenShell()
            setChildren(e, el.id)
          }
        }
      }
      return {
        onClick: e => {
          showChildrenShell()
          setChildren(e, el.id)
          setItem(e, el)
        }
      }
    }
  }

  const renderList = el => {
    const { ActivatedItem } = context

    const itemClasses = classnames('menu-element', {
      'is-active': ActivatedItem === el.name
    })
    const itemProps = onHoverMenuItem(el)

    const svgSlug = slugify(el.name)

    return (
      <li
        className={itemClasses}
        key={`item-${el.name}`}
        onClick={event => onItemClick(event, el)}
        {...itemProps}
      >
        {isDepartament && (
          <span className="menu-element-icon menu-element-icon-department">
            {Boolean(DEPARTMENT_ICONS[svgSlug]) && cloneElement(DEPARTMENT_ICONS[svgSlug], {
              width: 20,
              height: 20
            })}
          </span>
        )}
        {!isMobile || !el.hasChildren ? (
          <a className="menu-list-mobile-link" href={el.url}>
            <span>{el.name}</span>
          </a>
        ) : (
          <span>{el.name}</span>
        )}

        {renderChildren(el)}
      </li>
    )
  }

  return element.content.map(renderList)
}

Children.propTypes = {
  element: PropTypes.PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    url: PropTypes.string,
    hasChildren: PropTypes.bool
  }).isRequired
}

export default Children
