const HOME_DELIVERY = 'Entrega em domicílio'
const HOME_DELIVERY_SHIFT_LABEL = 'Turno'
const PICKUP_SHIFT_LABEL = 'Horário'

type ShiftProps = {
  type: string
  shift: string
}

const Shift = (props: ShiftProps) => {
  if (!props.type) {
    return null
  }

  const shiftLabel =
    props.type === HOME_DELIVERY
      ? HOME_DELIVERY_SHIFT_LABEL
      : PICKUP_SHIFT_LABEL

  return (
    <div className="title-shift color-default">
      <div className="small">{shiftLabel}</div>
      <strong>{props.shift}</strong>
    </div>
  )
}

export default Shift
