import request from 'checkout/utils/axios'

import { putServicesParams } from './types'

const putServices = async (params: putServicesParams) => {
  const response = await request
    .post('/api/v3/carts/services', { ...params })
    .then(({ data }) => data)
    .catch(err => {
      throw err.response
    })

  return response
}

export default putServices
