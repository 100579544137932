import { call, put, takeLatest } from 'redux-saga/effects'

import * as service from 'lmcv/services/userProfile'

import { Constants, Actions } from '.'

function * getProfile () {
  try {
    const user = yield call(service.getUserProfile)

    yield put(Actions.getSuccess(user))
  } catch (e) {
    yield put(Actions.getFailure(e))

    if (e.status === 401 || e.status === 404) {
      window.location = '/login'
    }
  }
}

export default function * root () {
  yield takeLatest(Constants.USER_PROFILE_GET, getProfile)
}
