import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { removeProductFromCart as apiRemoveProduct } from 'scripts/api/cart'
import emitter from 'scripts/utils/emitter'

import { useResize } from 'utils/hooks/use-resize'

import { Product } from 'checkout/components/MiniCart/Product'
import * as dataLayer from 'checkout/services/data-layer'

import { getItemDiscount } from 'frontendCheckout/tracker/utils'
import * as tracker from 'frontendCheckout/tracker'

const Products = ({
  product,
  type,
  services,
  availableServices,
  regionId,
  productRemovalIsDisabled,
  updateProduct = () => {},
  removeProductFromCart = () => {}
}) => {
  const [animationKeys, setAnimationKeys] = useState({
    hideProduct: false,
    animation: false,
    showDisclaimer: false,
    hideComponent: false
  })
  const [isMobile] = useResize()

  const removeProductAPI = useCallback(async (product) => {
    await apiRemoveProduct(product?.id)
      .then(({ status }) => {
        dataLayer.removeFromCart({
          category: product.categoryTree,
          price: product.price?.to,
          productBrand: product.brand,
          productId: product.productId,
          productName: product.name,
          quantity: product.quantity,
          regionId: product.regionId
        })

        const hasOffer = product.price?.from > product.price?.to
        const isByPaymentMethod =
          hasOffer && Boolean(product.discountByPaymentMethod)
        const discountType = isByPaymentMethod ? 'by-payment-method' : 'offer'
        const discount = getItemDiscount(
          product.price?.to,
          product.price?.from
        )
        const deliveryType = type === 'delivery' ? 'delivery' : 'pickup'

        tracker.pushRemoveFromCart({
          pageType: 'minicart',
          product: {
            id: product.productId,
            productId: null,
            name: product.name,
            price: product.price?.to,
            discount: hasOffer ? discount : 0,
            discountType: hasOffer ? discountType : undefined,
            brand: product.brand,
            categoryTree: product.categoryTree,
            seller: product.shopName,
            quantity: product.quantity
          },
          deliveryType,
          zipCode: ''
        })

        if (status === 204) {
          setAnimationKeys(keys => ({
            ...keys,
            animation: true
          }))

          setTimeout(() => {
            setAnimationKeys(keys => ({
              ...keys,
              hideProduct: true,
              animation: false,
              showDisclaimer: true
            }))
          }, 500)

          setTimeout(() => {
            setAnimationKeys(keys => ({
              ...keys,
              showDisclaimer: false,
              hideComponent: true
            }))

            removeProductFromCart({
              id: product?.id,
              updateTotal: true
            })
          }, 1500)
        }

        emitter.emit('cart:removed', {
          id: product?.id,
          uniqueProductsCount: 0
        })
      })
      .catch(err => {
        dataLayer.errorInMiniCartContext('clique_remover_item', 'Erro ao remover um item do carrinho')
        console.log('Error =>', err)
      })
  }, [removeProductFromCart, type])

  useEffect(() => {
    return () => {
      setAnimationKeys({
        hideProduct: false,
        animation: false,
        showDisclaimer: false,
        hideComponent: false
      })
    }
  }, [])

  if (animationKeys?.hideComponent) {
    return null
  }

  return (
    <Product
      animationKeys={animationKeys}
      key={product.id}
      isMobile={isMobile}
      product={{ ...product, type }}
      removeProduct={removeProductAPI}
      updateProduct={updateProduct}
      services={services}
      availableServices={availableServices}
      regionId={regionId}
      productRemovalIsDisabled={productRemovalIsDisabled}
    />
  )
}

Products.propTypes = {
  product: PropTypes.object,
  type: PropTypes.string,
  removeProductFromCart: PropTypes.func,
  updateProduct: PropTypes.func
}

export default Products
