import { useState } from 'react'

import emitter from 'scripts/utils/emitter'

import { Service } from '../Service'

const ServiceList = props => {
  const [productRemovalIsDisabled, setProductRemovalIsDisabled] =
    useState(false)

  emitter.on('cart:removing', function () {
    setProductRemovalIsDisabled(true)
  })

  emitter.on('cart:products-replace', function () {
    setProductRemovalIsDisabled(false)
  })

  return props.services
    .filter(service => !service.relatedProduct?.id)
    .sort((first, second) => first.serviceId - second.serviceId)
    .map(service => (
      <Service
        key={service?.id}
        {...props}
        service={service}
        productRemovalIsDisabled={productRemovalIsDisabled}
      />
    ))
}

export default ServiceList
