import { Stack, SimpleGrid, Spacer, Skeleton } from '@backyard-ui/core'

const ResumeSkeleton = () => (
  <>
    <Skeleton width="250px" height="30px" radius="xl" />
    <Spacer axis="vertical" size="4" />

    <SimpleGrid gapY="10" columns={{ initial: 1, md: 2 }}>
      {/* Rating Resume */}
      <Stack direction="column" spacing="6">
        <Stack direction="column" spacing="2.5">
          {Array(5)
            .fill(null)
            .map((_, index) => (
              <Stack align="center" key={index}>
                <Skeleton width="25px" height="25px" radius="full" />
                <Skeleton width="257px" height="14px" radius="xl" />
              </Stack>
            ))}
        </Stack>
      </Stack>

      {/* Star Rating */}
      <Stack direction="column" align={{ initial: 'center', md: 'flex-start' }}>
        <Skeleton width={'40%'} height="14px" radius="xl" />
        <div className="w-full">
          <Stack
            align="center"
            justify={{ initial: 'center', md: 'flex-start' }}
            spacing={4}
          >
            <Skeleton width="60px" height="60px" radius="full" />
            <Skeleton width={'40%'} height="14px" radius="xl" />
          </Stack>
        </div>
      </Stack>
    </SimpleGrid>
  </>
)

export default ResumeSkeleton
