import styled from '@emotion/styled'
import { css } from '@emotion/react'

const thumbWrapperStyles = ({ theme }) => css`
  label: thumb__wrapper;

  background-color: ${theme.colors.white};

  border-radius: ${theme.borderRadius.tera};

  box-shadow: 0 ${theme.spacings.bit} ${theme.spacings.byte} 0 rgba(0, 0, 0, .15);

  height: 300px;
  width: 300px;

  margin-bottom: ${theme.spacings.giga};

  transition: all .3s ease;

  &:hover {
    box-shadow: 0 ${theme.spacings.bit} ${theme.spacings.kilo} 0 rgba(0, 0, 0, .3);
  }

  ${theme.media.mega} {
    height: 336px;
    width: 336px;

    margin: 0 ${theme.spacings.byte} ${theme.spacings.giga};
  }
`

export const ThumbWrapper = styled('div')(thumbWrapperStyles)
