import $ from 'jquery'

import emitter from 'scripts/utils/emitter'
import { showSpinner, hideSpinner } from 'scripts/utils/spinner'
import BaseComponent from 'scripts/components/base-component'

const DEFAULTS = {
  shouldTrackSaldaoSolidario: false,
  successMessage: 'Agora você irá receber nossas novidades. :)'
}

class Newsletter extends BaseComponent {
  static componentName = 'newsletter'

  static DOMEvents = {
    submit: 'onFormSubmit'
  }

  constructor (element, options = {}) {
    super(element)

    this.options = $.extend({}, DEFAULTS, options)
  }

  init () {
    this.$name = this.$element.find('[name="name"]')
    this.$email = this.$element.find('[name="email"]')
  }

  getFormValidation () {
    if (!this.validation) {
      this.validation = this.$element.data('validation')
    }
  }

  onFormSubmit (e) {
    e.preventDefault()
    this.getFormValidation()

    if (this.validation.validateAll()) {
      this.sendForm()
    }
  }

  sendForm () {
    showSpinner()

    $.post(this.options.endpoint, this.$element.serialize())
      .done(this.onPostDone.bind(this))
      .fail(this.onPostFail.bind(this))
  }

  onPostDone (data) {
    hideSpinner()

    emitter.emit('stickyFeedback:success', {
      title: `Pronto, ${this.$name.val()}!`,
      content: this.options.successMessage
    })

    this.$element.trigger('reset')
    this.validation.setPristine(this.$element.find('.input'))

    if (this.options.shouldRedirect) {
      window.setTimeout(() => {
        window.location.href = this.options.redirectUrl
      }, 2000)
    }
  }

  onPostFail () {
    hideSpinner()

    emitter.emit('stickyFeedback:error', {
      title: 'Oops! Não conseguimos salvar seu email. :(',
      content: 'Por favor tente novamente.'
    })
  }
}

export default ($el) => new Newsletter($el, $el.data()).init()

export { Newsletter as Component }
