const isValid = value => {
  if (value.length > 40) {
    return false
  }

  if (value === '') {
    return false
  }

  return true
}

export default isValid
