import { css } from '@emotion/react'
import styled from '@emotion/styled'

const fieldWrapperStyles = () => css`
  label: field-wrapper;

  position: relative;
`

const spinnerWrapperStyles = ({ theme }) => css`
  label: spinner-wrapper;

  right: ${theme.spacings.kilo};
  top: 35px;
  position: absolute;
`

const buttonWrapperStyles = ({ theme }) => css`
  label: button-wrapper;

  margin-top: ${theme.spacings.giga};
`

const fieldMarginStyles = ({ theme }) => css`
  label: field-margin;

  margin-bottom: ${theme.spacings.mega};

  ${theme.media.giga} {
    margin-bottom: 0;
  }
`

const toggleWrapper = ({ theme }) => css`
  label: toggle-wrapper;

  display: flex;
  justify-content: space-between;
  align-items: center;

  cursor: pointer;

  padding-top: ${theme.spacings.byte};
  padding-bottom: ${theme.spacings.mega};

  margin-bottom: ${theme.spacings.mega};
  border-bottom: 1px solid ${theme.colors.n200};
`

export const FieldWrapper = styled('div')(fieldWrapperStyles)
export const SpinnerWrapper = styled('div')(spinnerWrapperStyles)
export const ButtonWrapper = styled('div')(buttonWrapperStyles)
export const FieldMargin = styled('div')(fieldMarginStyles)
export const ToggleWrapper = styled('div')(toggleWrapper)
