import styled from '@emotion/styled'
import { css } from '@emotion/react'

const linkStyles = ({ theme }) => css`
  label: link;

  display: flex;
  flex-direction: row;

  border-bottom: 1px solid #e5e5e5;
  padding-bottom: ${theme.spacings.mega};
  margin-bottom: ${theme.spacings.mega};
`
export const Link = styled('a')(linkStyles)

const imageStyles = ({ theme }) => css`
  label: image;

  width: 100px;
  height: 100px;
  margin-right: ${theme.spacings.mega};
`
export const Image = styled('img')(imageStyles)

const infoContainerStyles = () => css`
  label: info__container;
  display: flex;
  flex-direction: column;
`
export const InfoContainer = styled('div')(infoContainerStyles)

const titleStyles = ({ theme }) => css`
  label: title;

  color: #72bf44;
  font-size: ${theme.typography.text.mega.fontSize};
  font-weight: 500;
  margin-bottom: ${theme.spacings.byte};
`
export const Title = styled('h5')(titleStyles)

const infoStyles = ({ theme }) => css`
  label: info;

  color: #666;
  line-height: ${theme.typography.text.mega.lineHeight};
  font-size: 12px;
`
export const Info = styled('p')(infoStyles)
