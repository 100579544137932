import { css } from '@emotion/react'
import styled from '@emotion/styled'

const containerStyles = () => css`
  label: retail-media_banner-skeleton-container;

  align-items: center;
  justify-content: center;
  display: flex;
`

export const Container = styled('div')(containerStyles)
