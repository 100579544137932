import PropTypes from 'prop-types'

import { onCarouselItemClick } from 'scripts/utils/data-layer'

import { CategoryCarousel } from 'catalog/components/CategoryCarousel'

import { Widget } from '../components/Widget'

const Department = ({ widget }) => {
  const handleItemClick = (event, item) => {
    event.preventDefault()

    const { name, id, url } = item

    const carouselItem = {
      name,
      carouselTitle: widget.title,
      contentName: widget.contentName,
      id
    }

    onCarouselItemClick(carouselItem)

    window.location.href = url
  }

  return (
    <Widget title={widget.title} withBackground noContentPadding>
      <CategoryCarousel
        title={widget.title}
        elementsType={widget.elementsType}
        items={widget.items}
        onItemClick={handleItemClick}
      />
    </Widget>
  )
}

Department.propTypes = {
  widget: PropTypes.shape({
    title: PropTypes.string,
    contentName: PropTypes.string,
    elementsType: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        picture: PropTypes.string,
        url: PropTypes.string
      })
    ).isRequired
  })
}

export default Department
