import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { Theme } from 'scripts/react-components/themes/Theme'

const wrapperStyles = ({ theme }: Theme) => css`
  label: contact-preferences__wrapper;

  background-color: white;
  margin-top: -${theme.spacings.mega};
  padding: ${theme.spacings.tera};
  & svg {
    color: ${theme.colors.p700};
  }
  a {
    color: ${theme.colors.p700};
  }
`

export const Wrapper = styled('div')(wrapperStyles)

const checkboxWrapperStyles = ({ theme }: Theme) => css`
  label: contact-preferences__checkbox-wrapper;

  padding-top: ${theme.spacings.kilo};
  padding-bottom: ${theme.spacings.kilo};
`

export const CheckboxWrapper = styled('div')(checkboxWrapperStyles)

const buttonWrapperStyles = ({ theme }: Theme) => css`
  label: contact-preferences__button;

  margin-top: ${theme.spacings.giga};
`

export const ButtonWrapper = styled('div')(buttonWrapperStyles)
