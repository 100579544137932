import styled from '@emotion/styled'
import { css } from '@emotion/react'

const wrapperStyles = ({ theme }) => css`
  label: wrapper;

  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
  width: 120px;
  z-index: ${theme.zIndex.backdrop};
`

const containerStyles = ({ theme }) => css`
  label: container;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  color: ${theme.colors.p600};

  p {
    line-height: 19px;
    font-weight: ${theme.font.weight.semibold};
  }

  svg {
    width: 50px;
    height: 50px;

    color: ${theme.colors.p600};
  }
`

const containerTextStyles = ({ theme }) => css`
  label: container__text;

  margin-top: ${theme.spacings.kilo};
  width: 84px;
`

export const Wrapper = styled('div')(wrapperStyles)
export const Container = styled('div')(containerStyles)
export const WrapperText = styled('div')(containerTextStyles)
