import reactComponentLoader from 'scripts/react-component-loader'

import { GTMProductPageView } from 'shared/tracker/components'

import {
  GTMPaymentPage,
  GTMShippingPage
} from 'frontendCheckout/tracker/components'

const GTMShippingPageEntry = ({ services, totalDiscount }) => {
  const parsedServices = services ? JSON.parse(services) : []
  const parsedTotalDiscount = totalDiscount ? JSON.parse(totalDiscount) : 0

  return (
    <GTMShippingPage
      services={parsedServices}
      totalDiscount={parsedTotalDiscount}
    />
  )
}

const GTMPaymentPageEntry = ({ services, totalDiscount, freightCost }) => {
  const parsedServices = services ? JSON.parse(services) : []
  const parsedTotalDiscount = totalDiscount ? JSON.parse(totalDiscount) : 0

  return (
    <GTMPaymentPage
      services={parsedServices}
      totalDiscount={parsedTotalDiscount}
      freightCost={freightCost}
    />
  )
}

reactComponentLoader(GTMProductPageView, '[data-tracker-component-view-item]')
reactComponentLoader(GTMShippingPageEntry, '[data-tracker-component-shipping]')
reactComponentLoader(GTMPaymentPageEntry, '[data-tracker-component-payment]')
