import { useMyOrdersContext } from 'frontendMyOrders/context/MyOrdersContext'
import {
  PendingPaymentSaleOrders,
  SaleOrders
} from 'frontendMyOrders/pages/Home/components'

function Home () {
  const { plugins } = useMyOrdersContext()

  const { MarketplaceChat } = plugins

  return (
    <div>
      <h1>Lista de Pedidos</h1>
      <PendingPaymentSaleOrders />
      <SaleOrders />

      {MarketplaceChat && <MarketplaceChat />}
    </div>
  )
}

export default Home
