import { Stack } from '@leroy-merlin-br/backyard-react'

import { Shell } from 'shared/components/Shell'

import * as S from './styled'

const ListSkeleton = () => (
  <S.List>
    {Array(5)
      .fill()
      .map((item, index) => (
        <S.Item key={`${item}-${index}`}>
          <S.ItemCol>
            <Shell width={72} height={72} borderRadius="circle" />
            <S.Info>
              <Stack space="bit">
                <Shell width={130} height={20} borderRadius="kilo" />
                <Shell width={85} height={19} borderRadius="kilo" />
                <Shell width={75} height={16} borderRadius="kilo" />
              </Stack>
            </S.Info>
          </S.ItemCol>
          <S.ItemCol>
            <Stack space="bit">
              <Shell width={125} height={18} borderRadius="kilo" />
              <Shell width={85} height={18} borderRadius="kilo" />
            </Stack>
          </S.ItemCol>
          <S.ItemCol>
            <Shell width={170} height={18} borderRadius="kilo" />
          </S.ItemCol>
        </S.Item>
      ))}
  </S.List>
)

export default ListSkeleton
