import { Button, Inline } from '@leroy-merlin-br/backyard-react'

const LoadMore = ({ hasMoreContent, onClick, isLoading }) => {
  if (!hasMoreContent) {
    return null
  }

  return (
    <Inline alignX="center">
      <Button variant="outline" onClick={onClick} isLoading={isLoading}>
        Ver mais
      </Button>
    </Inline>
  )
}

export default LoadMore
