import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { Theme } from 'scripts/react-components/themes/Theme'

import { Box as ExportedBox } from 'shared/components/GenericCarousel/components/Navigation/Ellipsis/styled'

type StyleProps = {
  isTopSide: boolean
  isBottomSide: boolean
}

const IMAGE_SIZE = '69px'

const wrapperStyles = ({ theme }: Theme) => css`
  label: wrapper;

  margin-top: ${theme.spacings.byte};

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;

  ${theme.media.mega} {
    position: absolute;
    max-height: 544px;
    height: 544px;
    max-width: 81px;

    margin-top: 0;
    margin-right: ${theme.spacings.byte};
    padding-top: ${theme.spacings.peta};

    flex-direction: column;

    width: inherit;
  }

  .slick-slider {
    width: 100%;

    ${theme.media.mega} {
      margin: ${theme.spacings.byte} 0;

      max-width: 81px;
    }
  }

  .slick-slider .slick-current > div > div > img {
    opacity: 1;

    border: 4px solid ${theme.colors.p600} !important;
  }
`

const arrowStyles = ({ theme }: Theme) => css`
  label: wrapper__arrow;

  display: none !important;

  background-color: transparent;

  ${theme.media.mega} {
    display: flex !important;
  }
`

const buttonCarouselWrapperStyles = ({
  theme,
  isTopSide,
  isBottomSide
}: Theme & StyleProps) => css`
  label: wrapper__button-wrapper;

  display: flex;
  justify-content: center;

  text-align: center;

  ${theme.media.mega} {
    position: absolute;
    top: ${isTopSide ? '0' : 'unset'};
    bottom: ${isBottomSide ? '0' : 'unset'};

    height: ${theme.spacings.peta};
  }
`

const imageWrapperStyles = ({ theme }: Theme) => css`
  label: wrapper__image-wrapper;

  display: flex !important;

  justify-content: center;
  cursor: pointer;

  width: inherit;

  margin-bottom: ${theme.spacings.bit};

  & img {
    object-fit: cover;
    opacity: 0.4;

    height: ${IMAGE_SIZE};
    width: ${IMAGE_SIZE};

    border-radius: ${theme.borderRadius.circle};
  }
`

export const ButtonCarouselWrapper = styled('div')(buttonCarouselWrapperStyles)
export const ImageWrapper = styled('div')(imageWrapperStyles)
export const Wrapper = styled('div')(wrapperStyles)
export const Arrow = styled(ExportedBox)(arrowStyles)
