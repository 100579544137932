import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { StyleIsActiveProps, StyleProps } from '../types'

const assetPath = window && window.env.assetHost

const wrapperStyles = ({ theme }: StyleProps) => css`
  label: modal__wrapper;

  pointer-events: all;
  position: relative;
  bottom: 0;


  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;

  border-radius: ${theme.borderRadius.mega};

  ${theme.media.mega} {
    border-radius: unset;
    bottom: unset;

    grid-template-columns: 1fr 50%;
    grid-template-rows: 450px;
  }
`

const closeButtonStyles = ({ theme }: StyleProps) => css`
  label: modal__close-button;

  position: absolute;
  top: ${theme.spacings.mega};
  right: ${theme.spacings.mega};

  cursor: pointer;

  svg {
    width: 16px;
    height: 16px;
    path {
      stroke-width: 2px;
      stroke: ${theme.colors.black};
    }
  }
`

const sharedIconStyles = ({ theme, isActive }: StyleIsActiveProps) => css`
  label: modal__shared-icon;

  position: absolute;

  top: 10px;
  right: ${theme.spacings.kilo};

  svg {
    display: block;

    width: 14px;
    height: 14px;

    color: ${isActive ? theme.colors.f300 : theme.colors.v500};
  }
`

const asideStyles = ({ theme }: StyleProps) => css`
  label: modal__aside;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  background: ${theme.colors.white};
  border-top-left-radius: ${theme.borderRadius.mega};
  border-top-right-radius: ${theme.borderRadius.mega};

  ${theme.media.mega} {
    border-top-left-radius: ${theme.borderRadius.mega};
    border-bottom-left-radius: ${theme.borderRadius.mega};
    border-top-right-radius: unset;
  }
`

const contentStyles = ({ theme }: StyleProps) => css`
  label: modal__content;

  padding: ${theme.spacings.giga};

  background: ${theme.colors.white};

  box-shadow: inset 0px 4px 4px ${theme.colors.shadow},
    inset 0px -4px 4px ${theme.colors.shadow};

  ${theme.media.mega} {
    &::-webkit-scrollbar {
      display: none;
    }

    overflow-y: auto;
    background: ${theme.colors.n100};
    border-top-right-radius: ${theme.borderRadius.mega};
    border-bottom-right-radius: ${theme.borderRadius.mega};
    box-shadow: unset;
  }
`

const arrowIconStyles = ({ theme }: StyleProps) => css`
  label: scroll__button;

  position: absolute;
  right: ${theme.spacings.mega};
  width: ${theme.spacings.mega};
  height: ${theme.spacings.mega};

  background-image: url(${assetPath}assets/images/lmcv/icons/arrow.svg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: ${theme.spacings.mega};

`

const activeButtonStyles = ({ theme }: StyleProps) => css`
  label: active__button;

  padding-top: ${theme.spacings.mega};
  margin-top: ${theme.spacings.byte};

  border-top: 1px solid ${theme.colors.n200};

`

export const CloseButton = styled('div')(closeButtonStyles)
export const SharedIcon = styled('span')(sharedIconStyles)
export const Aside = styled('div')(asideStyles)
export const Content = styled('div')(contentStyles)
export const ArrowIcon = styled('div')(arrowIconStyles)
export const ActiveButton = styled('div')(activeButtonStyles)

const wrapperActiveStyles = ({ theme, isActive }: StyleIsActiveProps) =>
  isActive &&
  css`
    label: modal__wrapper--active;

    ${Aside} {
      background: linear-gradient(
        134.58deg,
        ${theme.colors.f600} -5.39%,
        ${theme.colors.v500} 100%
      );
    }

    ${SharedIcon} {
      svg {
        color: ${theme.colors.f300};
      }
    }

    ${Content} {
      box-shadow: unset;
    }

    ${CloseButton} {
      svg {
        path {
          stroke: ${theme.colors.white};
        }
      }
    }

    ${theme.media.mega} {
      ${CloseButton} {
        svg {
          path {
            stroke: ${theme.colors.black};
          }
        }
      }
    }
  `

export const Wrapper = styled('div')(wrapperStyles, wrapperActiveStyles)
