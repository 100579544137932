import { Component } from 'react'
import PropTypes from 'prop-types'

import RawHtml from 'site/utils/RawHtml'

class Description extends Component {
  constructor (props) {
    super(props)

    this.state = {
      isOpen: false,
      showToggleButton: true
    }
  }

  componentDidMount () {
    const showToggleButton =
      this.container.clientHeight < this.content.clientHeight
    this.setState({ showToggleButton })
  }

  setContentHeight = () => {
    if (this.state.isOpen) {
      this.container.style.height = `${this.content.clientHeight}px`
      return
    }

    this.container.style.height = ''
  }

  toggleHeight = () => {
    this.setState(
      prevState => ({ isOpen: !prevState.isOpen }),
      this.setContentHeight
    )
  }

  render () {
    const { isOpen, showToggleButton } = this.state
    const { description } = this.props

    return (
      <div>
        <div
          className="product-description-collapse"
          ref={ref => {
            this.container = ref
            return this.container
          }}
        >
          <RawHtml
            contentRef={ref => {
              this.content = ref
              return this.content
            }}
          >
            {description}
          </RawHtml>
        </div>

        {showToggleButton && (
          <a
            className="product-description-height-toggle"
            onClick={this.toggleHeight}
          >
            {this.state.isOpen ? 'Ocultar' : 'Ver Mais'}
          </a>
        )}

        {showToggleButton && !isOpen && (
          <div className="product-description-text-overlay" />
        )}
      </div>
    )
  }
}

Description.propTypes = {
  description: PropTypes.string
}

export default Description
