import { css, keyframes } from '@emotion/react'
import styled from '@emotion/styled'

const heartBeat = theme => keyframes`
  0% {
    transform: scale(.4);
  }

  33% {
    fill: ${theme.colors.r500};
    transform: scale(1.3);
  }

  100% {
    fill: ${theme.colors.r500};
  }
`

const baseStyles = ({ theme }) => css`
  label: heart-icon;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  & > svg {
    fill: ${theme.colors.n100};

    height: 30px;
    width: 30px;

    pointer-events: none;
  }
`

const activeStyles = ({ theme, isActive }) =>
  isActive &&
  css`
    label: heart-icon--active;

    & > svg {
      animation: ${heartBeat(theme)} 800ms ease;
      fill: ${theme.colors.r500};
    }
  `

export const HeartIcon = styled('i')(baseStyles, activeStyles)
