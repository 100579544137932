import { pushDataObject } from 'scripts/utils/data-layer'

import { userResourcesApi } from 'api'

import SalesTransformer from './SalesTransformer'

class SalesImpression {
  static transformTypes = {
    carousel_full_banner: SalesTransformer.transformToBannerPromotionData,
    single_informative: SalesTransformer.transformToBannerPromotionData,
    full_banner: SalesTransformer.transformToBannerPromotionData,
    two_banners: SalesTransformer.transformToBannerPromotionData,
    two_banners_wide: SalesTransformer.transformToBannerPromotionData,
    three_banners: SalesTransformer.transformToBannerPromotionData,
    three_informative: SalesTransformer.transformToBannerPromotionData,
    offers: SalesTransformer.transformToProductPromotionData,
    vouchers: SalesTransformer.transformToProductPromotionData,
    'recommended products': SalesTransformer.transformToProductPromotionData
  }

  /**
   *
   * @param {array} sales
   * @param {string} widgetType
   */
  constructor (sales, widgetType = null, widgetTitle) {
    this.sales = sales
    this.widgetType = widgetType
    this.hasSlots = this.sales.length > 1
    this.widgetTitle = widgetTitle
  }

  async getRegion () {
    const data = await userResourcesApi.storedGetUserResources()

    return data.region
  }

  async createSalesImpression () {
    const region = await this.getRegion()

    return {
      event: 'promotionImpression',
      dimension14: region,
      ecommerce: {
        promoView: {
          promotions: this.setPromotions()
        }
      }
    }
  }

  setPromotions () {
    return this.sales.map((sale, index) => {
      const position = this.hasSlots ? index + 1 : null
      const type = this.widgetType
      const title = this.widgetTitle

      return SalesImpression.transformTypes[type](sale, position, type, title)
    })
  }

  async sendImpressionToDataLayer () {
    const impressions = await this.createSalesImpression()
    pushDataObject(impressions, true)
  }
}

export default SalesImpression
