import { formatters } from 'utils/formatters'

export const schemas = {
  completeRegister: ({
    birthDate,
    gender,
    region,
    favoriteStore,
    preferences,
    ...rest
  }) => ({
    gender,
    birthDate: formatters.date(birthDate),
    favoriteStore,
    region,
    preferences,
    ...rest
  })
}
