export const SHOW_LINK_EXCHANGE_LIMIT = 1000

export const BIRTHDATE_POPOVER = {
  label: 'Data de nascimento',
  list: {
    title: 'Sua data de nascimento será usada para:',
    items: ['Envio de mimos no dia do seu aniversário :)']
  }
}

export const EMAIL_POPOVER = {
  label: 'E-mail',
  list: {
    title: 'Seu e-mail será usado para:',
    items: [
      'Te identificar',
      'Acessar sua conta',
      'Receber sua nota fiscal',
      'Receber o status do seu pedido'
    ]
  }
}

export const FAVORITE_STORE_POPOVER = {
  label: 'Loja favorita',
  list: {
    title: 'Sua loja favorita será usada para:',
    items: [
      'Envio de ofertas e conteúdo exclusivos da loja escolhida'
    ]
  }
}

export const PHONE_POPOVER = {
  label: 'Celular',
  list: {
    title: 'Seu celular será usado para:',
    items: [
      'Garantir mais segurança para sua conta',
      'Contato rápido para informar sobre seu pedido',
      'Envio de novidades e ofertas, se você aceitar :)'
    ]
  }
}
