import emitter from 'scripts/utils/emitter'

export const updateCartReference = data =>
  emitter.emit('mini-cart:saga-update-shipping', data)

export const serviceHasUpdated = data =>
  emitter.emit('cart:service-change', data)

export const updateCartShipping = (data = null) =>
  emitter.emit('cart:products-replace', data)

export const updateCartUniqueCount = (uniqueProductsCount) =>
  emitter.emit('cart:update', { count: uniqueProductsCount })
