import styled from '@emotion/styled'
import { css } from '@emotion/react'
import {
  UserInterface,
  Typography
} from 'frontendMyOrders/user-interface/typography'

type TypeProps =
  | 'neutral'
  | 'loyalty'
  | 'info'
  | 'success'
  | 'warning'
  | 'danger'

type SizeProps = 'xs' | '2xs'

type BadgeProps = {
  label: string
  type?: TypeProps
  size?: SizeProps
}

const typeStyles = {
  neutral: css`
    label: neutral-badge;
    background-color: var(--color-surface-neutral-strong);
  `,
  loyalty: css`
    label: loyalty-badge;
    background-color: var(--color-surface-loyalty-soft);
  `,
  info: css`
    label: info-badge;
    background-color: var(--color-surface-info-soft);
  `,
  success: css`
    label: success-badge;
    background-color: var(--color-surface-success-soft);
  `,
  warning: css`
    label: warning-badge;
    background-color: var(--color-surface-warning-soft);
  `,
  danger: css`
    label: danger-badge;
    background-color: var(--color-surface-danger-soft);
  `
}

const typeDotStyles = {
  neutral: css`
    background-color: var(--color-surface-neutral-inverse);
  `,
  loyalty: css`
    background-color: var(--color-surface-loyalty-default);
  `,
  info: css`
    background-color: var(--color-surface-info-default);
  `,
  success: css`
    background-color: var(--color-surface-success-default);
  `,
  warning: css`
    background-color: var(--color-surface-warning-default);
  `,
  danger: css`
    background-color: var(--color-surface-danger-default);
  `
}

const sizeStyles = {
  xs: css`
    padding: var(--desktop-space-inset-xs) var(--desktop-space-inset-sm);
  `,
  '2xs': css`
    padding: var(--desktop-space-inset-2xs) var(--desktop-space-inset-xs);
  `
}

const BadgeWrapper = styled.span<BadgeProps>`
  ${({ type }: { type: TypeProps }) => typeStyles[type]};
  ${({ size }: { size: SizeProps }) => sizeStyles[size]};
  border-radius: var(--border-radius-pill);
  width: fit-content;
  display: flex;
  align-items: center;
  gap: var(--desktop-space-inset-2xs);
`

const DotWrapper = styled.span<BadgeProps>`
  ${({ type }: { type: TypeProps }) => typeDotStyles[type]};
  width: var(--desktop-space-inset-xs);
  height: var(--desktop-space-inset-xs);
  border-radius: var(--border-radius-pill);
`

const Badge = ({ label, type = 'neutral', size = '2xs' }: BadgeProps) => {
  const BadgeStyled = BadgeWrapper.withComponent('div')
  const DotStyled = DotWrapper.withComponent('div')

  let appearance: Typography.AppearanceProps

  if (type === 'warning' || type === 'loyalty') {
    appearance = `${type}-strong`
  } else {
    appearance = `${type}-default`
  }

  return (
    <BadgeStyled type={type} size={size} data-testid="my-orders-badge">
      <DotStyled type={type} />
      <UserInterface appearance={appearance} type="strong">
        {label}
      </UserInterface>
    </BadgeStyled>
  )
}

export default Badge
