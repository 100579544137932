import { FieldErrors } from 'react-hook-form'

export const flattenFieldErrorObject = (
  object: FieldErrors,
  previousKey?: string
) => {
  let result: FieldErrors = {}

  for (const [name, value] of Object.entries(object)) {
    const isValueObject = typeof value === 'object'
    const isFieldErrorObject = isValueObject && value.message && value.type

    if (isValueObject && !isFieldErrorObject) {
      const tempObject = flattenFieldErrorObject(value as FieldErrors, name)

      result = {
        ...result,
        ...tempObject
      }
    } else {
      const keyName = previousKey ? `${previousKey}.${name}` : name
      result[keyName] = value as FieldErrors
    }
  }

  return result
}
