import { tv } from 'tailwind-variants'

const styles = tv({
  slots: {
    installmentItem: `
      h-12
      flex
      items-center
      px-3
      [&:nth-child(odd)]:bg-gray-100
    `
  }
})

export default styles
