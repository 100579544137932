import { useState } from 'react'
import { UseFormReturn } from 'react-hook-form'

export type UserInfo = {
  email: string
  emailConfirm: string
  fiscalId: string
  loyalty: {
    optInPro: boolean
    favoriteStore: string
    birthdate: string
    occupation: string
  },
  name: string
  optins: {
    general: boolean
    email: boolean
    sms: boolean
    whatsApp: boolean
  },
  password: string
  phone: string
  code?: string
}

const useNewsLetterOptin = (
  setValue: UseFormReturn<UserInfo>['setValue'],
  getValues: UseFormReturn['getValues']
) => {
  const [generalOptin, setGeneralOptin] = useState('unchecked')

  const handleOptinsToggle = (optinName: string, optionValue: boolean) => {
    const optinsObject = {
      email: getValues('optins.email'),
      sms: getValues('optins.sms'),
      whatsApp: getValues('optins.whatsApp'),
      [optinName]: optionValue
    }

    const optinsArray = Object.values(optinsObject)
    let generalOptinState = 'indeterminate'

    if (optinsArray.every(value => value)) {
      generalOptinState = 'checked'
    }

    if (optinsArray.every(value => !value)) {
      generalOptinState = 'unchecked'
    }

    setValue('optins.general', generalOptinState === 'checked')
    setGeneralOptin(generalOptinState)
  }

  const handleGeneralOptinToggle = (isChecked: boolean) => {
    const generalOptinState = isChecked ? 'checked' : 'unchecked'

    setGeneralOptin(generalOptinState)

    setValue('optins', {
      general: isChecked,
      email: isChecked,
      sms: isChecked,
      whatsApp: isChecked
    })
  }

  return {
    generalOptin,
    handleOptinsToggle,
    handleGeneralOptinToggle
  }
}

export default useNewsLetterOptin
