import { Text, Card } from '@leroy-merlin-br/backyard-react'
import PropTypes from 'prop-types'

import { sortByPaymentMethodLength, translatePaymentMethod } from 'scripts/utils/discount-by-payment-method'

import formatPriceToBRL from 'utils/formatPriceToBRL'

import * as S from './styled'

const TotalPaymentMethods = ({ paymentMethodsSummary }) => {
  if (!paymentMethodsSummary) {
    return
  }
  const sortedPaymentMethods = Object.entries(paymentMethodsSummary).sort(sortByPaymentMethodLength)
  return (
    <Card data-cy="summary-info-payment-methods">
      {sortedPaymentMethods.map(([paymentMethod, value]) =>
        <S.TotalPaymentMethods key={paymentMethod}>
          <Text size="mega" as="span" noMargin data-cy="summary-payment-method" >
            Total via {translatePaymentMethod(paymentMethod)}
          </Text>
          <Text
            size="mega"
            as="span"
            noMargin
            data-cy="summary-total-payment-method"
          >
            {formatPriceToBRL(value)}
          </Text>
        </S.TotalPaymentMethods>
      )}
    </Card>
  )
}

TotalPaymentMethods.propTypes = {
  paymentMethodsSummary: PropTypes.objectOf(PropTypes.number)
}

export default TotalPaymentMethods
