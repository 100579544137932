import { fork } from 'redux-saga/effects'

import UserProfile from './modules/userProfile/sagas'
import LoyaltyProfile from './modules/loyaltyProfile/sagas'
import Professional from './modules/professional/sagas'
import Coupon from './modules/coupon/sagas'
import Customers from './modules/customers/sagas'
import Module from './modules/module/sagas'
import Notification from './modules/notification/sagas'

export default function * root () {
  yield fork(UserProfile)
  yield fork(LoyaltyProfile)
  yield fork(Professional)
  yield fork(Coupon)
  yield fork(Customers)
  yield fork(Module)
  yield fork(Notification)
}
