import request from 'checkout/utils/axios'

const removeService = serviceId =>
  request
    .delete(`/api/v3/carts/services/${serviceId}`)
    .then(({ data, status }) => ({ data, status }))
    .catch(err => {
      throw err.response
    })

export default removeService
