import React, { PropsWithChildren } from 'react'

export type SelectableTextProp = {
  selected?: boolean
} & React.HTMLAttributes<HTMLDivElement>

export const SelectableText = ({
  children,
  selected,
  className,
  ...props
}: PropsWithChildren<SelectableTextProp>) => {
  return (
    <div
      className={`text-sm font-bold flex flex-col items-center gap-1 leading-4 cursor-pointer group ${
        selected ? 'selected' : ''
      }`}
      {...props}
    >
      <div>{children}</div>
      <div className={`w-4 border-b border-transparent ${selected ? 'border-green-700' : ''}`}></div>
    </div>
  )
}
