import { useState, useEffect, forwardRef, useImperativeHandle } from 'react'
import PropTypes from 'prop-types'

import AugmentedRealityFactory from 'scripts/AugmentedReality/AugmentedRealityFactory'

import { ContextVisibility } from 'shared/components/ContextVisibility'

const ButtonFactory = forwardRef(({ children, sku }, ref) => {
  const [isAvailable, setIsAvailable] = useState(false)

  const AR = AugmentedRealityFactory(sku)

  useImperativeHandle(ref, () => ({
    openAR: AR.openAR
  }))

  useEffect(() => {
    const startARService = async () => {
      const response = await AR.serviceIsAvailable()

      setIsAvailable(response)
    }

    startARService()
  }, [AR])

  if (!isAvailable) {
    return null
  }

  return <ContextVisibility when={['app']}>{children}</ContextVisibility>
})

ButtonFactory.propTypes = {
  sku: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.elementType])
    .isRequired
}

export default ButtonFactory
