const Icon = () => {
  return (
    <svg
      version="1.1"
      baseProfile="tiny"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 300 300"
      xmlSpace="preserve"
      width="50"
    >
      <circle fill="#7BB51C" cx="150" cy="150" r="149" />
      <path
        fill="#FFFFFF"
        d="M203.2,254.9l-56.9-35.7l-58.1,35.6v-73.5c-13-14.3-20.2-32.9-20.2-52.3c0-42.9,34.9-77.8,77.8-77.8c42.9,0,77.8,34.9,77.8,77.8c0,19.5-7.2,38.1-20.3,52.4V254.9z M145.7,54.9c-40.9,0-74.2,33.3-74.2,74.2c0,18.7,7,36.6,19.7,50.4l0.5,0.5v68.4l54.5-33.4l53.2,33.4v-68.5l0.6-0.4c12.7-13.7,19.7-31.6,19.7-50.3C219.9,88.2,186.6,54.9,145.7,54.9z M145.7,190.4c-34,0-61.6-27.6-61.6-61.6s27.6-61.6,61.6-61.6s61.6,27.6,61.6,61.6S179.6,190.4,145.7,190.4z M145.7,70.8c-32,0-58,26-58,58s26,58,58,58s58-26,58-58S177.6,70.8,145.7,70.8z"
      />
    </svg>
  )
}

export default Icon
