import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from '@leroy-merlin-br/backyard-react'

import { ProfileResponse } from '../../types'
import { getProfile } from '../../services'
import { getOptions, updateInterests } from '../../services/preferences'

export type LoyaltyData = {
  favoriteStore?: string
  occupation?: string
  optIn?: boolean
}

type SelectData = {
  label: string,
  value: string
}

export type InterestOptionData = {
  id: string,
  name: string
}

const ARCHITECT_PROFESSION_CODE = 7

export const usePreferences = () => {
  const [loyalty, setLoyalty] = useState<LoyaltyData>()
  const [interests, setInterests] = useState<string[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [occupations, setOccupations] = useState<SelectData[]>([])
  const [favoriteStores, setFavoriteStores] = useState<SelectData[]>([])
  const [interestsOptions, setInterestsOptions] = useState<InterestOptionData[]>([])
  const [disableProfessionField, setDisableProfessionField] = useState(false)

  const history = useHistory()

  const interestsShellOptions = Array.from({ length: 27 }, (_, index) => index + 1)

  useEffect(() => {
    (async () => {
      setLoading(true)

      try {
        const { data: options } = await getOptions()

        setOccupations(options.occupations.map((occupation: any) => ({
          label: occupation.name,
          value: occupation.id
        })))

        setFavoriteStores(options.favoriteStores.map((favoriteStore: any) => ({
          label: favoriteStore.name,
          value: favoriteStore.id
        })))

        setInterestsOptions(options.interests)
      } catch {
        history.push('/erro-interno')
      }

      try {
        const profile: ProfileResponse = await getProfile()

        if (profile?.data?.customer?.loyalty) {
          setLoyalty({
            favoriteStore: profile.data.customer.loyalty.favoriteStore,
            occupation: profile.data.customer.loyalty.profession,
            optIn: profile.data.customer.loyalty.active
          })

          const isArchitect = profile.data.customer.loyalty.status === ARCHITECT_PROFESSION_CODE
          setDisableProfessionField(isArchitect)
        }

        if (profile?.data?.interests?.interestAreaName?.length) {
          setInterests(profile.data.interests.interestAreaName)
        }

        setLoading(false)
      } catch {
        history.push('/erro-interno')
      }
    })()
  }, [])

  const handleUpdateInterests = async () => {
    setLoading(true)

    try {
      await updateInterests({
        loyalty,
        interests: {
          interestAreaName: interests
        }
      })

      toast.primary('Preferências atualizadas com sucesso!', { variant: 'solid' })
    } catch (error) {
      if (error?.data?.errors?.length) {
        const [{ code }] = error.data.errors

        if (code === 'loyalty.favoriteStore') {
          return toast.critical('O campo loja favorita é obrigatório!', {
            variant: 'solid'
          })
        }
      }

      toast.critical('Não foi possível atualizar as preferências!', {
        variant: 'solid'
      })
    } finally {
      setLoading(false)
    }
  }

  const checkIsSelected = (item: string) => (
    interests?.filter(interest => interest === item).length
  )

  const handleSelect = (item: string) => {
    if (loading) {
      return
    }

    if (interests?.filter(interest => interest === item).length) {
      setInterests(interests?.filter(interest => interest !== item))
    } else {
      setInterests([...(interests || []), item])
    }
  }

  return {
    loyalty,
    setLoyalty,
    loading,
    handleUpdateInterests,
    checkIsSelected,
    handleSelect,
    occupations,
    favoriteStores,
    interestsOptions,
    interestsShellOptions,
    disableProfessionField
  }
}
