import styled from '@emotion/styled'
import { css } from '@emotion/react'

const navigationWrapperStyles = ({ theme }) => css`
  label: navigation;

  position: relative;

  margin: ${theme.spacings.giga} 0px;

  display: flex;

  overflow: auto;

  max-width: 100%;

  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar,
  &::-webkit-scrollbar-track {
    display: none;
  }

  div[class$='tab__panel'] {
    display: none;
  }

  button {
    flex: 1 0 auto;
    width: max-content;
    padding: ${theme.spacings.kilo} 0px;
  }

  ${theme.media.mega} {
    margin: ${theme.spacings.giga} -${theme.spacings.kilo};

    div[class$='tablist-wrapper'] {
      padding: 0 ${theme.spacings.kilo};
    }
  }
`

export const NavigationWrapper = styled('nav')(navigationWrapperStyles)
