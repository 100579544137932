import { PropsWithChildren } from 'react'

type Props = {
  urlImage: string
  alt: string
}

export const Thumb = ({ urlImage, alt }: PropsWithChildren<Props>) => {
  return (
    <img
      src={urlImage}
      alt={alt}
      className="md:rounded-lg rounded-sm w-20 h-20 border border-gray-300"
    />
  )
}
