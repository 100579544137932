import styled from '@emotion/styled'
import { css } from '@emotion/react'

const wrapperStyles = ({ theme }) => css`
  margin: 0 auto;
  width: 85%;

  ${theme.media.mega} {
    width: 100%;
  }
`

export const Wrapper = styled('div')(wrapperStyles)
