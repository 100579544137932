import { FunctionComponent } from 'react'
import { Icon } from '@backyard-ui/core'
import { PlayCircle } from '@backyard-ui/icons'

import { Props } from './types'
import styles from './Item.styles'

const Item: FunctionComponent<Props> = ({
  size = 'md',
  onClick,
  alt,
  src,
  type
}) => (
  <div className={styles({ size }).base()} onClick={onClick} role="button">
    <img alt={alt} src={src} />
    {type === 'video' && (
      <div className={styles().playContainer()}>
        <Icon size="2xl" color="white" data-testid="play-icon">
          <PlayCircle />
        </Icon>
      </div>
    )}
  </div>
)

export default Item
