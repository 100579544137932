const DinersIcon = () => (
  <svg
    width="30"
    height="21"
    viewBox="0 0 30 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="30" height="21" rx="3.5" fill="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.1049 16.8535C10.7648 16.8687 8.01562 14.1797 8.01562 10.9058C8.01562 7.32775 10.7648 4.85286 14.1049 4.85352H14.1926H14.1953H15.6706C18.971 4.85286 21.9836 7.32643 21.9836 10.9058C21.9836 14.178 18.971 16.8535 15.6706 16.8535H14.1926H14.1049ZM14.1185 5.34998C11.0662 5.35097 8.59299 7.80932 8.59299 10.8431C8.59299 13.8761 11.0662 16.3341 14.1185 16.3345C17.1711 16.3345 19.6447 13.8761 19.6456 10.8431C19.6447 7.80899 17.1708 5.35064 14.1185 5.34998ZM12.8638 14.0909V7.59392C11.5503 8.09684 10.6189 9.3601 10.6156 10.8431C10.6189 12.3253 11.5506 13.5893 12.8638 14.0909ZM17.622 10.8431C17.6194 9.35944 16.6867 8.09453 15.3718 7.59326V14.0925C16.6867 13.5906 17.6194 12.327 17.6217 10.8431H17.622Z"
      fill="#26447C"
    />
  </svg>
)

export default DinersIcon
