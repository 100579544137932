import { datadogRum } from '@datadog/browser-rum'

import * as dataLayer from 'checkout/services/data-layer'
import * as services from 'checkout/services/cart'

type UpdateServiceQuantityProps = {
  id: number
  prevQuantity: number
  quantity: number
  regionId: string
}
export const updateServiceQuantity = async ({
  id,
  prevQuantity,
  quantity,
  regionId
}: UpdateServiceQuantityProps) => {
  if (prevQuantity === quantity) return

  try {
    const data = await services.putServices({
      override: true,
      services: [
        {
          id,
          skuId: null,
          quantity
        }
      ]
    })
    if (prevQuantity > quantity) {
      dataLayer.removeFromCart({
        category: data.serviceBankName,
        price: data.price?.to,
        productBrand: 'Serviço',
        productId: data.serviceId,
        productName: data.name,
        quantity: data.packagingCount,
        regionId
      })
    }

    if (prevQuantity < quantity) {
      dataLayer.addToCart({
        category: data.serviceBankName,
        price: data.price?.to,
        productBrand: 'Serviço',
        productId: data.serviceId,
        productName: data.name,
        quantity: data.packagingCount,
        regionId,
        shipmentType: 'delivery',
        storeId: 'Leroy Merlin',
        marketPlace: 'Leroy Merlin',
        relatedProduct: null
      })
    }
  } catch (error) {
    datadogRum.addError(error)
  }
}

type RemoveServiceFromCartProps = {
  id: string
  serviceId: number
  prevQuantity: number
  category: string
  price: number
  name: string
  regionId: string
}
export const removeServiceFromCart = async ({
  id,
  serviceId,
  category,
  name,
  price,
  regionId,
  prevQuantity
}: RemoveServiceFromCartProps) => {
  try {
    await services.removeService(id)

    dataLayer.removeFromCart({
      category,
      price,
      productBrand: 'Serviço',
      productId: serviceId,
      productName: name,
      quantity: prevQuantity,
      regionId
    })
  } catch (error) {
    datadogRum.addError(error)
  }
}
