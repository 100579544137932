import { ResponseType, request } from 'frontendCheckout/services/fetch'

import { endpoints } from './endpoints'

export type AppliedCoupon = {
  code: string
  engine: 'product'
  type: 'promo'
  discount: number
}

export type response =
  | ResponseType<200, { data: AppliedCoupon }>
  | ResponseType<202, { data: AppliedCoupon }>
  | ResponseType<404, null>
  | ResponseType<410, null>
  | ResponseType<418, null>
  | ResponseType<409, null>

export type status = response extends ResponseType<infer Code, unknown>
  ? Code
  : never

export type props = {
  couponCode: string
}

export const applyCoupon = ({ couponCode }: props) => {
  return request<response>({
    url: endpoints.applyCoupon(),
    method: 'POST',
    data: {
      couponCode
    },
    errors: [404, 410, 418, 409]
  })
}
