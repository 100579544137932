import styled from '@emotion/styled'
import { css } from '@emotion/react'

const wrapperStyles = ({ theme }) => css`
  label: wrapper-coupon-availability;

  padding-top: ${theme.spacings.mega};
  display: flex;
`

export const Wrapper = styled('div')(wrapperStyles)
