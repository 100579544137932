export const subtract = (value, step) => {
  const remainder = value % step

  if (!value) {
    return step
  }

  if (!remainder) {
    return value - step
  }

  if (remainder === value) {
    return step
  }

  return Math.floor(value / step) * step
}

export const sum = (value, step) => {
  const remainder = value % step

  if (!value) {
    return step
  }

  if (!remainder) {
    return value + step
  }

  return Math.ceil(value / step) * step
}
