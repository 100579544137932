import { Fragment, useContext } from 'react'
import LazyLoad from 'react-lazyload'
import {
  Button,
  Card,
  Container,
  Row,
  Col,
  Inline,
  Heading,
  Visible,
  Hidden,
  Stack,
  Text
} from '@leroy-merlin-br/backyard-react'
import { ChevronRight } from '@leroy-merlin-br/backyard-icons'

import { ShowMore } from 'shared/components/ShowMore'
import { Markdown } from 'shared/components/Markdown'

import { deliveryLimitHours } from 'site/utils/deliveryLimitHours'

import { EntryKeyContext } from '../../context/EntryKeyContext'
import { normalizePath } from '../../utils/buildUrl'
import { buildSearchMessage } from '../../utils/constants'
import * as S from './styled'

const IMAGE_SIZE = {
  height: 300,
  width: 300
}

const TopContent = () => {
  const {
    pageInfo: { name, filterTitle, description, contents, video },
    searchType,
    isOnMobileApp,
    query: { term }
  } = useContext(EntryKeyContext)

  const dictionary = buildSearchMessage(searchType, term?.value)

  const freight = deliveryLimitHours()
  const isFastDelivery = filterTitle?.includes('Entrega Rápida')

  const renderVideoThumb = () => {
    const { title, picture, url } = video

    return (
      <S.VideoThumb href={normalizePath(url)} title={title}>
        <LazyLoad height={IMAGE_SIZE.height} offset={200} once>
          <img
            width={IMAGE_SIZE.width}
            height={IMAGE_SIZE.height}
            src={picture}
            alt={title}
          />
        </LazyLoad>

        <S.VideoTitle>
          <Heading as="h4" size="tera" color="white" noMargin>
            {title}
          </Heading>

          <Text as="span" size="mega" color="p600" noMargin isBold>
            Assista ao vídeo
          </Text>
        </S.VideoTitle>
      </S.VideoThumb>
    )
  }

  const renderContentList = hasVideo => {
    const MAX_CONTENTS = hasVideo ? 6 : 9

    const slicedContents = contents.slice(0, MAX_CONTENTS)

    return (
      <Fragment>
        {slicedContents.map(content => (
          <Button
            key={content.id}
            as="a"
            href={normalizePath(content.url)}
            variant="outline"
            iconRight={ChevronRight}
          >
            {content.title}
          </Button>
        ))}
      </Fragment>
    )
  }

  const renderContent = () => {
    if (searchType === 'error') {
      return <span>Realize uma nova busca.</span>
    }

    const hasContentToDisplay = video || !!contents.length

    const CONTENT_SIZE = video ? 8 : 12

    const topContentDescription = searchType
      ? `<h3>${dictionary.description}</h3>`
      : description

    return (
      <ShowMore scrollToParent showFloatButton>
        <S.Wrapper data-cy="top-content-description">
          <Markdown content={topContentDescription} />

          {hasContentToDisplay && (
            <Stack space="mega">
              <Heading size="mega" as="h3" noMargin>
                Veja também essas dicas Leroy Merlin
              </Heading>

              <Row>
                {video && <Col size={{ giga: 4 }}>{renderVideoThumb()}</Col>}

                <Col size={{ giga: CONTENT_SIZE }}>
                  <S.ContentWrapper hasVideo={!!video}>
                    {!!contents.length && renderContentList(!!video)}
                  </S.ContentWrapper>
                </Col>
              </Row>
            </Stack>
          )}
        </S.Wrapper>
      </ShowMore>
    )
  }

  const renderTitle = size => (
    <Inline space="byte" alignX="flex-start">
      {searchType && dictionary.prefix && (
        <Heading size={size} as="h2" noMargin>
          {dictionary.prefix}
        </Heading>
      )}

      <Heading size={size} as="h1" noMargin>
        {searchType ? dictionary.term : name}{' '}
        {isFastDelivery
          ? `Com receba ${freight.deliveryForecast}`
          : filterTitle}
      </Heading>
    </Inline>
  )

  return (
    <Container data-cy="top-content">
      <S.CardWrapper>
        <Card>
          <S.TopContent type={searchType}>
            <Visible when={['kilo', 'untilKilo']}>
              {renderTitle('tera')}
            </Visible>

            <Hidden when={['kilo', 'untilKilo']}>{renderTitle('exa')}</Hidden>

            {!isOnMobileApp && description && renderContent()}
          </S.TopContent>
        </Card>
      </S.CardWrapper>
    </Container>
  )
}
export default TopContent
