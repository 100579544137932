import { PropsWithChildren } from 'react'

type GridRightProps = {}

export const GridRight = ({ children }: PropsWithChildren<GridRightProps>) => {
  return (
    <div className="bg-gray-100 flex-grow">
      <div className="max-w-[368px] h-full mr-auto">{children}</div>
    </div>
  )
}
