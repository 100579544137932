import styled from '@emotion/styled'
import { css } from '@emotion/react'

const wrapperListStyles = ({ theme }) => css`
  label: wrapper-list-styles;

  ul {
    padding: ${theme.spacings.kilo} ${theme.spacings.giga} 0;
  }
`

const wrapperSignOutStyles = ({ theme }) => css`
  label: wrapper-signout-styles;

  padding: ${theme.spacings.byte};
  border-top: 2px solid ${theme.colors.b800};
`

export const WrapperList = styled('div')(wrapperListStyles)
export const WrapperSignOut = styled('div')(wrapperSignOutStyles)
