export const parseIfJSON = (value: any) => {
  if (typeof value === 'string') {
    try {
      return JSON.parse(value)
    } catch (error) {
      return value
    }
  }

  return value
}
