import { css } from '@emotion/react'
import styled from '@emotion/styled'

const gridStyles = ({ theme }) => css`
  label: coupon-grid;

  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: ${theme.spacings.byte};
  align-items: flex-end;
`

const tagStyles = ({ theme, hasTag }) =>
  hasTag &&
  css`
    label: coupon-grid--with-tag;

    margin-bottom: ${theme.spacings.mega};
  `

export const Grid = styled('div')(gridStyles, tagStyles)
