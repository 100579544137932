import styled from '@emotion/styled'
import { css } from '@emotion/react'

const flagsWrapperStyles = ({ theme }) => css`
  label: flags__wrapper;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  position: absolute;
  bottom: 0;

  box-sizing: border-box;

  height: 20%;
  width: 100%;

  padding: ${theme.spacings.mega};
`

const flagsOffersStyles = ({ theme }) => css`
  label: flags-offer;

  background-color: ${theme.colors.white};

  border-radius: ${theme.borderRadius.circle};

  cursor: pointer;

  height: 32px;
  width: 32px;

  padding: ${theme.spacings.bit};

  position: relative;

  svg {
    color: ${theme.colors.success};

    font-size: ${theme.typography.headings.tera.fontSize};
  }
`

const badgeWrapperStyles = ({ theme }) => css`
  label: badge__wrapper;

  position: absolute;
  right: -${theme.spacings.mega};
  top: -${theme.spacings.mega};
`

export const FlagsWrapper = styled('div')(flagsWrapperStyles)
export const FlagsOffers = styled('div')(flagsOffersStyles)
export const BadgeWrapper = styled('div')(badgeWrapperStyles)
