import styled from '@emotion/styled'
import { css } from '@emotion/react'

const phoneNumberStyles = () => css`
  label: phone-number;

  &:hover {
    text-decoration: underline;
  }
`

export const PhoneNumber = styled('div')(phoneNumberStyles)
