import axios from 'axios'

export const signup = async data => {
  const response = await axios
    .post('api/v3/newsletter', data)
    .then(({ data }) => data)
    .catch(err => {
      throw err.response
    })

  return response
}
