import { Fragment, useEffect, useRef, useContext } from 'react'
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks
} from 'body-scroll-lock'
import { Stack, Card, Spinner } from '@leroy-merlin-br/backyard-react'

import { EntryKeyContext } from '../../context/EntryKeyContext'
import { Header, Footer } from './components'
import { Accordion, AppliedFilters, Facets, Sorters, FilterType } from '../'
import * as S from './styled'

const EntryKeyMobileFilters = () => {
  const {
    filters,
    filterUsedOptions,
    isMobileFilterActive,
    hasHighlightedFilters,
    isLoading,
    isLoadingProducts,
    hasError
  } = useContext(EntryKeyContext)

  const ref = useRef()

  const shouldRenderLoading = (isLoading || isLoadingProducts) && !hasError && isMobileFilterActive

  useEffect(() => {
    isMobileFilterActive
      ? disableBodyScroll(ref.current)
      : enableBodyScroll(ref.current)

    return clearAllBodyScrollLocks
  }, [isMobileFilterActive])

  return (
    <Fragment>
      {shouldRenderLoading && (
        <S.Overlay>
          <Spinner color="green" size="zetta" />
        </S.Overlay>
      )}

      <S.MobileFilters isOpen={isMobileFilterActive}>
        <Header />
        <S.FiltersContainer ref={ref}>
          <Stack space="bit">
            <Accordion title="Ordenar por">
              <Sorters />
            </Accordion>

            {filterUsedOptions?.length && (
              <S.Wrapper>
                <Card target="card">
                  <AppliedFilters />
                </Card>
              </S.Wrapper>
            )}

            {hasHighlightedFilters && (
              <Accordion title="Listar Produtos Com">
                <Facets />
              </Accordion>
            )}

            {filters.map((filter, index, array) => (
              <FilterType
                key={index}
                filterIndex={index}
                filterObject={filter}
                numberOfFilters={array.length}
              />
            ))}
          </Stack>
        </S.FiltersContainer>
        <Footer />
      </S.MobileFilters>
    </Fragment>
  )
}

export default EntryKeyMobileFilters
