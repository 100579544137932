import { Constants } from '.'

const initialState = {
  isRequest: true,
  payload: {},
  error: ''
}

export default function reducer (state = initialState, action = {}) {
  switch (action.type) {
    case Constants.PROFESSIONAL_GET:
      return {
        ...state,
        isRequest: true
      }

    case Constants.PROFESSIONAL_GET_SUCCESS:
      return {
        ...state,
        isRequest: false,
        payload: action.payload
      }

    case Constants.PROFESSIONAL_GET_FAILURE:
      return {
        ...state,
        isRequest: false,
        error: action.error
      }

    default:
      return state
  }
}
