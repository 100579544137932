import { Fragment, useContext, useEffect, useState } from 'react'
import {
  Button,
  Inline,
  Visible,
  Hidden,
  Stack,
  Text
} from '@leroy-merlin-br/backyard-react'

import { Quantity } from '../Quantity'
import { EntryKeyContext } from '../../context/EntryKeyContext'
import * as S from './styled'

const StockFilter = () => {
  const {
    filterByStock,
    query: { minimumStock }
  } = useContext(EntryKeyContext)

  const [inputQuantity, setInputQuantity] = useState(0)

  const onButtonClick = () => {
    filterByStock(inputQuantity)
  }

  const renderQuantity = () => (
    <Quantity
      isDecrementDisabled={inputQuantity <= 1}
      isIncrementDisabled={false}
      updateQuantity={setInputQuantity}
      quantity={inputQuantity}
      submit={onButtonClick}
    />
  )

  const renderButton = () => (
    <Button size="kilo" isStretch onClick={onButtonClick}>
      Aplicar
    </Button>
  )

  useEffect(() => {
    if (minimumStock) {
      setInputQuantity(parseFloat(minimumStock.value, 10))
    }
  }, [minimumStock])

  return (
    <S.Wrapper>
      <Stack space="bit">
        <Text size="kilo" noMargin isBold>
          Disponibilidade, mínimo de peças:
        </Text>

        <Hidden when={['giga']}>
          <Inline space="kilo" wrap="nowrap">
            {renderQuantity()}
            {renderButton()}
          </Inline>
        </Hidden>

        <Visible when={['giga']}>
          <Stack space="kilo">
            {renderQuantity()}
            {renderButton()}
          </Stack>
        </Visible>
      </Stack>
    </S.Wrapper>
  )
}

export default StockFilter
