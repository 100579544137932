import { Heading, Text, Icon } from '@leroy-merlin-br/backyard-react'
import { SVGAttributes } from 'react'

import * as S from './styled'

type MenuItemProps = {
  icon: React.ForwardRefExoticComponent<
    SVGAttributes<SVGSVGElement> & React.RefAttributes<SVGSVGElement>
  >
  title: string
  description: string
  onClick: () => void
  myAccountItemId: string
  hasSmallTextOnDesktop?: boolean
}

const MenuItemCard = ({
  icon,
  title,
  description,
  onClick,
  myAccountItemId,
  hasSmallTextOnDesktop
}: MenuItemProps) => (
  <>
    <S.CardWrapper
      onClick={onClick}
      data-gtm-element-id={myAccountItemId}
    >
      <S.IconWrapper>
        <Icon as={icon} />
      </S.IconWrapper>

      <div>
        <Heading size="giga" noMargin>
          <S.Heading hasSmallTextOnDesktop={hasSmallTextOnDesktop}>
            {title}
          </S.Heading>
        </Heading>

        <Text noMargin>
          <S.Text hasSmallTextOnDesktop={hasSmallTextOnDesktop}>
            {description}
          </S.Text>
        </Text>
      </div>
    </S.CardWrapper>
  </>
)

export default MenuItemCard
