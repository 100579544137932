import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { Theme } from 'scripts/react-components/themes/Theme'

export const Wrapper = styled('div')(
  ({ theme }: Theme) => css`
    padding: ${theme.spacings.peta};
    background-color: ${theme.colors.white};

    [class*='validation-hint'] {
      color: ${theme.colors.r500};
    }
  `
)

export const ContractCheckWrapper = styled('div')(
  ({ theme }: Theme) => css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacings.byte};
    padding: ${theme.spacings.mega};
    margin-bottom: ${theme.spacings.giga};
  `
)

export const ContractCheck = styled('div')(
  ({ theme }: Theme) => css`
    display: flex;
    align-items: center;

    a {
      color: ${theme.colors.b500};
      text-decoration: underline;
    }
  `
)
