import { useState, useEffect } from 'react'
import axios, { AxiosError } from 'axios'

import { userProfileApi } from 'api'

import { useUserResources } from '../useUserResources'

export const useUserProfile = () => {
  const [userProfile, setUserProfile] = useState<userProfileApi.UserProfile>()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<AxiosError>()

  const {
    userControls: { isAssistedSale },
    isLoadingResources
  } = useUserResources()

  const fetchUserProfile = async (clearRequest = false) => {
    setIsLoading(true)

    try {
      const { data } = await userProfileApi.storedGetUserProfile(clearRequest)
      setUserProfile(data)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        setError(error)
      } else {
        throw error
      }
    }

    setIsLoading(false)
  }

  const refresh = () => {
    fetchUserProfile(true)
  }

  useEffect(() => {
    if (isLoadingResources && !isAssistedSale) {
      fetchUserProfile()
    }
  }, [isAssistedSale, isLoadingResources])

  return { userProfile, isLoading, error, refresh }
}
