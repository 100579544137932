import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import {
  pageHasChanged,
  onScrollPage,
  onTouchStart,
  onTouchEnd,
  onTouchMove
} from './DragAndDropBehaviour'
import DragAndDropComponent from './DragAndDropComponent'

type DragAndDropContainerProps = {
  onClose: () => void
  displayComponent: boolean
  children: any
}

const DragAndDropContainer = ({ children, onClose, displayComponent }: DragAndDropContainerProps) => {
  const history = useHistory()
  const [isMoved, setMoved] = useState(false)
  const [positionComponent, setPositionComponent] = useState(0)
  const [positionAfterMove, setPositionAfterMove] = useState(0)

  useEffect(() => {
    pageHasChanged(displayComponent, () => {
      setMoved(true)
      onClose()
    })
  }, [history, displayComponent, onClose])

  useEffect(() => {
    onScrollPage(displayComponent, () => {
      setMoved(true)
      onClose()
    })
  }, [displayComponent, onClose])

  const touchStart = (e: React.TouchEvent<HTMLDivElement>) =>
    onTouchStart(e, setPositionComponent)

  const touchMove = (e: React.TouchEvent<HTMLDivElement>) =>
    onTouchMove(e, setPositionAfterMove, positionComponent)

  const touchEnd = () =>
    onTouchEnd(
      () => {
        setMoved(true)
        onClose()
      },
      positionAfterMove,
      () => {
        setPositionAfterMove(0)
        setPositionComponent(0)
      }
    )

  return (
    <DragAndDropComponent
      positionAfterMove={positionAfterMove}
      displayComponent={displayComponent}
      isMoved={isMoved}
      onTouchStart={touchStart}
      onTouchMove={touchMove}
      onTouchEnd={touchEnd}
      children={children}
    />
  )
}

export default DragAndDropContainer
