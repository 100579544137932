import { AppLayout } from 'user/components/AppLayout'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { ToastContainer } from '@leroy-merlin-br/backyard-react'

import { Error500 } from 'shared/page/Error500'

import { Success } from '../pages/Success'
import PrivateRoute from './components/PrivateRoute.js'
import { SignUp } from '../pages/SignUp/SignUp'
import { StepComponentSignUp } from '../pages/StepComponentSignUp'

function Routes ({ isNewHeaderEnabled, verifyCodeStepOnSignup }) {
  return (
    <BrowserRouter basename="/cadastre-se">
      <ToastContainer position="top-right" />
      <Switch>
        <AppLayout isNewHeaderEnabled={isNewHeaderEnabled}>
          <PrivateRoute exact
            path="/"
            component={verifyCodeStepOnSignup ? StepComponentSignUp : SignUp}
            verifyCodeStepOnSignup={verifyCodeStepOnSignup}
          />
          <Route exact path="/erro-interno" component={Error500} />
          <Route exact path="/sucesso" component={Success} />
        </AppLayout>
      </Switch>
    </BrowserRouter>
  )
}

export default Routes
