export const STANDARD_SHIPPING_TYPE = 'Entrega em domicílio'
export const SERVICE_SHIPPING_TYPE = 'Instalação de serviço'
export const PICKUP_IN_STORE_TYPE = 'Retirar na loja'
export const STORE_ADDRESS_LABEL = 'Endereço de retirada'
export const USER_ADDRESS_LABEL = 'Endereço de entrega'
export const INSTALLATION_ADDRESS_LABEL = 'Endereço de instalação'
export const OBSERVATION_LABEL = 'Observação'
export const READY_FOR_PICKUP = 'Pronto para retirada'
export const PICKUP_IN_STORE = 'Clique e retire'
export const PICKUP_IN_STORE_LOCKER = 'Clique e retire locker'
export const STORE_WITHDRAWAL = 'Retirada na Loja'
