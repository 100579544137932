import { Fragment, useMemo } from 'react'
import {
  Icon,
  Inline,
  Stack,
  Heading,
  Text
} from '@leroy-merlin-br/backyard-react'
import { StarsOutline, ChevronRight } from '@backyard-ui/icons'
import { Avatar } from 'user/components/Avatar'
import { SHOW_LINK_EXCHANGE_LIMIT } from 'user/constants'
import { buildUserMenu } from 'user/utils/buildUserMenu'

import { Ellipsis } from 'shared/components/Ellipsis'
import { useUserResources, useLoyaltyProfile } from 'shared/hooks'

import * as S from '../styled'

const LoggedIn = () => {
  const { userResources, userControls } = useUserResources()
  const { loyaltyPoints, loyaltyStatus } = useLoyaltyProfile()

  const isUserActiveOnLoyalty = useMemo(
    () => loyaltyStatus?.isActive(),
    [loyaltyStatus]
  )

  const isValidExchange = loyaltyPoints?.actual >= SHOW_LINK_EXCHANGE_LIMIT

  const shouldShowLandingPageLink =
    !isUserActiveOnLoyalty && userControls.isLoyaltyElegible

  const menuItems = buildUserMenu({
    isLoyaltyElegible: userControls.isLoyaltyElegible,
    showLogout: false
  })

  return (
    <Fragment>
      <S.WrapperLogin>
        <Inline space="kilo" alignY="center" noMargin>
          <Avatar maskColor="b900" />

          <Stack space="byte">
            {userResources?.isLegalUser && (
              <Text size="giga" noMargin>
                <Ellipsis clamp={2}>
                  {userResources?.name.toLowerCase()}
                </Ellipsis>
              </Text>
            )}

            {isUserActiveOnLoyalty && (
              <Heading size="tera" noMargin>
                <Fragment>
                  {loyaltyPoints.formatted}
                  <Text as="span" size="kilo" color="white" noMargin>
                    {' '}
                    pts
                  </Text>
                </Fragment>
              </Heading>
            )}

            {!isUserActiveOnLoyalty && !userResources?.isLegalUser && (
              <Heading size="mega" noMargin>
                <Fragment>
                  <Ellipsis clamp={2}>{userResources?.name}</Ellipsis>
                  {userControls.isLoyaltyElegible && (
                    <Text size="kilo" color="n300" noMargin>
                      0 pts
                    </Text>
                  )}
                </Fragment>
              </Heading>
            )}

            <Inline space="kilo" alignY="center" noMargin>
              {isUserActiveOnLoyalty && (
                <S.WrapperIcon>
                  <Inline space="byte" alignY="baseline">
                    <Text
                      as="a"
                      href={
                        isValidExchange ? '/fidelidade/troque' : '/fidelidade'
                      }
                      size="kilo"
                      color="v300"
                      noMargin
                      data-tracking="mobile-menu-link-loyalty"
                    >
                      {isValidExchange ? 'Usar pontos' : 'Junte pontos'}
                    </Text>
                    <Icon as={ChevronRight} size="byte" />
                  </Inline>
                </S.WrapperIcon>
              )}

              {shouldShowLandingPageLink && (
                <Inline space="byte" alignY="center" noMargin>
                  <Icon as={StarsOutline} color="primary" size="kilo" />
                  <Text
                    as="a"
                    href="/fidelidade/como-funciona"
                    size="kilo"
                    color="p500"
                    noMargin
                    data-tracking="mobile-menu-link-landing-page"
                  >
                    Comece a acumular pontos
                  </Text>
                </Inline>
              )}
            </Inline>
          </Stack>
        </Inline>
      </S.WrapperLogin>

      <S.WrapperList>
        <Stack>
          {menuItems.map(({ label, link, onClick, id }) => (
            <Text
              key={id}
              as="a"
              href={link}
              onClick={onClick}
              data-tracking={`mobile-menu-item-${id}`}
            >
              {label}
            </Text>
          ))}
        </Stack>
      </S.WrapperList>
    </Fragment>
  )
}

export default LoggedIn
