import BaseComponent from 'scripts/components/base-component'
import { default as tryAddToCart } from 'scripts/utils/cart/addToCart'

class AddToCart extends BaseComponent {
  static componentName = 'add-to-cart'

  static DOMEvents = {
    click: 'onElementClick'
  }

  static emitterEvents = {
    'quantity:change': 'changeQuantity',
    'buybox:sellerChange': 'changeButtonProps'
  }

  init () {
    let overrideParam = this.$element.data('override-product-quantity')

    if (overrideParam === undefined) {
      overrideParam = true
    }

    this.quantity = 1
    this.productCode = this.$element.data('product-code')
    this.shopOfferId = this.$element.data('shop-offer-id')
    this.storeId = this.$element.data('store-id')
    this.region = this.$element.data('region')
    this.override = overrideParam
  }

  changeQuantity (quantity) {
    this.quantity = quantity
  }

  changeButtonProps ({ shopOfferId, purchaseButtonEcommerceDisabled }) {
    this.shopOfferId = shopOfferId

    this.changeDisabled(purchaseButtonEcommerceDisabled)
  }

  changeDisabled (buttonState) {
    const shouldDisable = (buttonState === 'true')

    this.$element.attr('disabled', shouldDisable)
    this.$element.toggleClass('disabled', shouldDisable)
  }

  addDeliveryProductToCart () {
    const product = {
      id: this.productCode,
      quantity: this.quantity
    }

    if (this.shopOfferId) {
      product.skuId = this.shopOfferId
    }

    tryAddToCart({
      regionId: this.region,
      type: 'delivery',
      products: [product],
      override: this.override
    })
  }

  addPickupInStoreProductToCart () {
    tryAddToCart({
      regionId: this.region,
      storeId: this.storeId,
      type: 'pickupInStore',
      products: [{
        id: this.productCode,
        quantity: this.quantity
      }],
      override: false
    })
  }

  onElementClick (event) {
    event.preventDefault()

    if (this.$element.prop('disabled')) {
      return
    }

    this.storeId
      ? this.addPickupInStoreProductToCart()
      : this.addDeliveryProductToCart()
  }
}

export default ($el) => new AddToCart($el).init()

export { AddToCart as Component }
