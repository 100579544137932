import PropTypes from 'prop-types'

import * as S from './styled'

const BankSlipInstallments = ({ paymentUrl }) => (
  <S.Wrapper>
    <iframe allow="camera" src={paymentUrl}></iframe>
  </S.Wrapper>
)

BankSlipInstallments.propTypes = {
  paymentURL: PropTypes.string.isRequired
}

export default BankSlipInstallments
