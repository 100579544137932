import Base from './Base'

class Link extends Base {
  render () {
    return (
      <a {...this.getElementProps(this.props)} onClick={this.onClick}>
        {this.props.children}
      </a>
    )
  }
}

export default Link
