import { FunctionComponent } from 'react'
import { Stack, Text } from '@backyard-ui/core'

import { CheckIcon, CrossIcon } from './components'
import { Props } from './types'

const Recommendation: FunctionComponent<Props> = ({ hasRecommendation }) => (
  <Stack direction="row" spacing="3">
    <Text size="lg">Recomenda esse produto</Text>

    {hasRecommendation && (
      <Stack direction="row" spacing="2" align="center">
        <CheckIcon />
        <Text size="lg">Sim</Text>
      </Stack>
    )}

    {!hasRecommendation && (
      <Stack direction="row" spacing="2" align="center">
        <CrossIcon />
        <Text size="lg">Não</Text>
      </Stack>
    )}
  </Stack>
)

export default Recommendation
