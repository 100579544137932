import { css } from '@emotion/react'
import styled from '@emotion/styled'

const layoutStyles = ({ theme }) => css`
  label: layout;

  display: flex;
  flex-direction: column;
  min-height: 100vh;

  background-color: ${theme.colors.n100};

  overflow-x: hidden;

  ${theme.media.giga} {
    overflow-x: auto;
  }
`

export const Layout = styled('div')(layoutStyles)
