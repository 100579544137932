import { useContext } from 'react'
import PropTypes from 'prop-types'

import { HeartIcon } from 'shared/components/HeartIcon'

import { WishlistContext } from 'site/Wishlist/context'

const WishListButton = ({ isActive, product }) => {
  const wishlistValues = useContext(WishlistContext)

  const {
    onPressHeart,
    isOnWishlist
  } = wishlistValues

  const data = {
    inWishList: isActive,
    id: product.id,
    product
  }

  const wishlistAction = (event) => {
    event.stopPropagation()

    onPressHeart(data, 'products')
  }

  return (
    <button aria-label="Adicionar a Lista de Favoritos" className="wishlist-button" onClick={ wishlistAction }>
      <HeartIcon isActive={ isOnWishlist(product.id) } />
    </button>
  )
}

WishListButton.propTypes = {
  isActive: PropTypes.bool,
  product: PropTypes.shape({
    id: PropTypes.number.isRequired,
    price: PropTypes.shape({
      to: PropTypes.shape({
        integers: PropTypes.string,
        decimals: PropTypes.string
      }),
      from: PropTypes.shape({
        integers: PropTypes.string,
        decimals: PropTypes.string
      }),
      regionPrice: PropTypes.shape({
        integers: PropTypes.string,
        decimals: PropTypes.string
      })
    }),
    shortName: PropTypes.string.isRequired
  }).isRequired
}

export default WishListButton
