import styled from '@emotion/styled'
import { css } from '@emotion/react'

const listContainerStyle = ({ theme }) => css`
  label: list_container;

  display: grid;
  gap: ${theme.spacings.kilo};
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
`

export const ListContainer = styled('ul')(listContainerStyle)
