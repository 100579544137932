import { pushDataObject } from '../../data-layer'
import { sendToApp } from '../../send-to-app'
import { getItemTotalPrice } from '../../utils'

export type RemoveProductProps = {
  id: number
  productId?: number
  name: string
  price: number
  discount: number
  discountType: 'offer' | 'by-payment-method' | undefined
  brand: string
  categoryTree: string
  seller: string
  quantity: number
}

export type PushRemoveFromCartProps = {
  pageType: 'minicart' | 'cart'
  product: RemoveProductProps
  deliveryType: 'delivery' | 'pickup'
  zipCode: string
}

export const pushRemoveFromCart = (props: PushRemoveFromCartProps) => {
  const { pageType, product, deliveryType, zipCode } = props

  const isPickup = deliveryType === 'pickup'
  const category = product.categoryTree.split('/')
  const zipCodeFormatted = zipCode ? zipCode.replace('-', '') : undefined

  const dataLayerItems = [
    {
      id: product.id,
      bind_id: product.productId || undefined,
      name: product.name,
      price: product.price,
      discount: product.discount,
      discount_type: product.discountType,
      brand: product.brand,
      category: category[0] || undefined,
      category2: category[1] || undefined,
      category3: category[2] || undefined,
      category4: category[3] || undefined,
      category5: category[4] || undefined,
      variant: undefined,
      seller: product.seller || undefined,
      delivery_type: deliveryType,
      delivery_cep: isPickup ? undefined : zipCodeFormatted,
      quantity: product.quantity
    }
  ]

  const totalRemoved = getItemTotalPrice(product.price, product.quantity)

  pushDataObject({
    _clear: true,
    event: 'remove_from_cart',
    page_type: pageType,
    items: dataLayerItems,
    remove_from_cart: {
      value: totalRemoved
    }
  })

  sendToApp({
    event: 'remove_from_cart',
    properties: {
      page_type: pageType,
      items: dataLayerItems,
      remove_from_cart: {
        value: totalRemoved
      }
    }
  })
}
