const LoaderFolderItem = () => (
  <div className="wishlist-loader wishlist-loader-folder-item">
    <div className="wishlist-loader-item">
      <div className="wishlist-products-loader folder-item-image"></div>
    </div>
    <div className="wishlist-loader-item wishlist-loader-item-details">
      <div className="wishlist-loader-item">
        <div className="wishlist-products-loader folder-item-title"></div>
        <div className="wishlist-products-loader folder-item-title-sub"></div>
      </div>
      <div className="wishlist-loader-item">
        <div className="wishlist-products-loader folder-item-button"></div>
      </div>
    </div>
  </div>
)

export default LoaderFolderItem
