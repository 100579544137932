import { useContext } from 'react'
import { Icon } from '@leroy-merlin-br/backyard-react'
import { LeroyLogo, LeroyLogoFilled } from '@backyard-ui/icons'

import { HeaderContext } from 'site/Header/contexts/HeaderContext'

import { useResize } from 'utils/hooks/use-resize'

import * as S from './styled'

const LogoMarkup = ({ isMobile, isBlackWeekend }) =>
  isMobile && isBlackWeekend ? (
    <Icon as={LeroyLogoFilled} fill="#fff" />
  ) : (
    <Icon as={LeroyLogo} />
  )

const HeaderLogo = () => {
  const [isMobile] = useResize(992)
  const { isBlackWeekend } = useContext(HeaderContext)
  const logoProps = { isMobile, isBlackWeekend }

  return (
    <S.WrapperLogo>
      <a
        href={window.env && window.env.baseHost}
        title="Leroy Merlin - Loja de Construção, Acabamento, Decoração e Jardinagem"
        alt="Leroy Merlin - Loja de Construção, Acabamento, Decoração e Jardinagem"
      >
        <LogoMarkup {...logoProps} />
      </a>
    </S.WrapperLogo>
  )
}

export default HeaderLogo
