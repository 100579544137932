import { toast } from '@leroy-merlin-br/backyard-react'

import { MESSAGE_TYPE_DICTIONARY } from './constants'

type MessageType = 'profile' | 'points'

export const showErrorAlert = (messageType: MessageType) => {
  const innerText = MESSAGE_TYPE_DICTIONARY[messageType]

  toast.critical(`Algo inesperado aconteceu ao carregar ${innerText}`, {
    variant: 'solid',
    content: 'Tente novamente mais tarde.'
  })
}
