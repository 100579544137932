import { css } from '@emotion/react'
import styled from '@emotion/styled'

const baseStyles = ({ theme }) => css`
  label: header;

  width: 100%;
  height: 130px;

  z-index: 10;

  background: linear-gradient(
    90deg,
    ${theme.colors.white} 50%,
    #00263a 0,
    #00263a
  );

  &:after {
    content: '';

    position: absolute;
    top: 0;
    right: 0;

    z-index: -1;

    width: 50%;
    height: 34px;

    background: #001c2c;
  }
`

const brandStyles = ({ theme }) => css`
  label: brand;

  display: flex;
  align-items: flex-end;

  width: 100%;
  height: 130px;

  svg {
    display: block;
    margin-bottom: 18px;

    width: 90px;
    height: 60px;

    ${theme.media.mega} {
      width: 110px;
      height: 70px;
    }
  }

  background: ${theme.colors.white};
`

const navStyles = ({ theme }) => css`
  label: nav;

  position: absolute;
  top: -130px;
  left: 95px;
  overflow: hidden;

  width: 100%;
  height: 131px;

  transform: skew(45deg);
  background: #00263a;

  ${theme.media.mega} {
    left: 155px;
  }

  ${theme.media.giga} {
    top: -1px;
    left: -105px;
  }

  ${theme.media.tera} {
    left: -65px;
  }
`

const positionStyles = () => css`
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 34px;
`

const navBgStyles = () => css`
  label: nav__background;

  background: #001c2c;
`

const metaStyles = ({ theme }) => css`
  label: header__meta;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  padding: ${theme.spacings.byte} ${theme.spacings.byte};
  white-space: break-spaces;

  h6 {
    font-weight: ${theme.font.weight.regular};
  }
`

export const Header = styled('header')(baseStyles)
export const Brand = styled('div')(brandStyles)
export const Nav = styled('div')(navStyles)
export const NavBG = styled('div')(navBgStyles, positionStyles)
export const Meta = styled('div')(metaStyles, positionStyles)
