import { useState } from 'react'
import { Drawer, Button } from '@backyard-ui/core'

import { useResize } from 'utils/hooks/use-resize'

import { useMarketplaceChatService } from './hooks'
import { marketplaceChatFactory } from './factory'

const App = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const handleDrawer = (prev: boolean) => setIsDrawerOpen(prev)

  const [isMobile] = useResize()

  const { marketplaceChat } = useMarketplaceChatService()

  const presenter = marketplaceChat && marketplaceChatFactory(marketplaceChat)

  return (
    <Drawer.Root
      isOpen={isDrawerOpen}
      onOpenChange={handleDrawer}
      placement={isMobile ? 'bottom' : 'right'}
    >
      <Drawer.Trigger>
        Dúvidas?
      </Drawer.Trigger>

      <Drawer.Content>
        <Drawer.CloseButton />
        <Drawer.Title>Title</Drawer.Title>
        <Drawer.Description>
          {isDrawerOpen && presenter && (
            <div>
              {presenter.map(chat => (
                <div key={chat.message}>
                  <div>{chat.message}</div>
                  <div>{chat.from}</div>
                  <div>{chat.date}</div>
                </div>
              ))}
            </div>
          )}
        </Drawer.Description>

        <Drawer.Footer>
          Footer
        </Drawer.Footer>
      </Drawer.Content>
    </Drawer.Root>
  )
}

export default App
