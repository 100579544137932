import { ReactNode, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from '@leroy-merlin-br/backyard-react'
import { isServerError } from 'user/utils'

import * as dataLayer from 'scripts/utils/data-layer'

import { sendOTPCode } from '../../services'
import { OptionToRecovery, useRecoveryContext } from '../../context/recovery-context'
import { EmailIcon, SMSIcon } from '../icons'

export type Option = { title: string, icon: ReactNode }

export type Options = { [key: string]: Option }

export type OptionType = 'email' | 'phone'

export type OptionsToReceiveSecurityCodeStepProps = {
  onNextStep: () => void
}

type ServerErrorType = {
  message: string
  status: number
}

export function useOptionsToReceiveSecurityCodeStep ({ onNextStep }: OptionsToReceiveSecurityCodeStepProps) {
  const [selectedOption, setSelectedOption] = useState<OptionToRecovery>()

  const { contextData, updateData } = useRecoveryContext()

  const history = useHistory()

  const handleOptionSelectedClick = async (option: OptionToRecovery) => {
    setSelectedOption(option)

    updateData({
      ...contextData,
      selectedOption: option
    })

    dataLayer.recoveryOptionToReceiveCodeClick(option.type)

    try {
      await sendOTPCode(
        contextData.fiscalId,
        option.hash,
        option.type,
        'reset_password'
      )

      onNextStep()
    } catch (error) {
      const { status } = error as ServerErrorType

      const hasServerError = status && isServerError(status)

      if (hasServerError) {
        history.push('/erro-interno')
      } else if (status === 422) {
        toast.critical('Não foi possível enviar o código!', {
          content: 'Tente novamente mais tarde.'
        })
      } else if (status === 429) {
        toast.critical('Você atingiu o limite de tentativas!', {
          content: 'Tente novamente mais tarde.'
        })
      }
    } finally {
      setSelectedOption(undefined)
    }
  }

  const options: Options = useMemo(() => ({
    email: { title: 'E-mail', icon: <EmailIcon /> },
    phone: { title: 'SMS', icon: <SMSIcon /> }
  }), [])

  return {
    selectedOption,
    contextData,
    options,
    handleOptionSelectedClick
  }
}
