import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { Theme } from 'scripts/react-components/themes/Theme'

const wrapperStyles = ({ theme }: Theme) => css`
  label: wrapper;

  display: grid;
  justify-content: center;

  margin: ${theme.spacings.giga};

  ${theme.media.mega} {
    margin: ${theme.spacings.giga} 0 178px;
  }
`

export const Wrapper = styled('div')(wrapperStyles)
