import { datadogRum } from '@datadog/browser-rum'
import { useEffect, useState } from 'react'

import { getloyaltyCashback } from 'scripts/react-components/hpdp/services/product'

const useLoyaltyPoints = () => {
  const [userPoints, setUserPoints] = useState<number | null>(null)

  useEffect(() => {
    const fetchLoyaltyData = async () => {
      try {
        const data = await getloyaltyCashback()
        if (data && data.points) {
          setUserPoints(data.points.actual)
        }
      } catch (error) {
        datadogRum.addError(error)
      }
    }

    fetchLoyaltyData()
  }, [userPoints])

  return { userPoints }
}

export default useLoyaltyPoints
