'use client'
import { ReactNode, HTMLAttributes, PropsWithChildren } from 'react'
import { VariantProps } from 'tailwind-variants'
import { inputButton } from './InputButton.style'

type InputButtonVariants = VariantProps<typeof inputButton>

export const InputButton = ({
  children,
  onClick,
  ...tvProps
}: PropsWithChildren<{ onClick: () => void }> & InputButtonVariants) => {
  return (
    <div className={inputButton(tvProps)} onClick={onClick}>
      {children}
    </div>
  )
}
