import PropTypes from 'prop-types'
import { Icon, Text } from '@leroy-merlin-br/backyard-react'
import { ChevronRight } from '@backyard-ui/icons'

import * as S from './styled'

const SellersListButton = ({ openDrawer, price, unit }) => {
  return (
    <S.Button type="button" onClick={openDrawer}>
      <Text size="kilo" noMargin>
        Mais opções a partir de{' '}
        <Text size="kilo" color="n600" as="strong" noMargin isBold>
          R$ {price} /{unit}
        </Text>
      </Text>

      <Icon as={ChevronRight} size="kilo" color="primary" />
    </S.Button>
  )
}

SellersListButton.propTypes = {
  openDrawer: PropTypes.func.isRequired,
  price: PropTypes.string.isRequired,
  unit: PropTypes.string.isRequired
}

export default SellersListButton
