import { AxiosError, AxiosResponse } from 'axios'
import { useState, useEffect } from 'react'

import { getRegions } from 'lmcv/services/regions'

type Region = {
  region: string,
  regionName: string
}

type ErrorData = {
  message: string
}

export function useRegions () {
  const [data, setData] = useState<Region[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState<AxiosResponse<ErrorData>>()

  useEffect(() => {
    getRegions()
      .then((data: Region[]) => {
        setData(data)
      })
      .catch((err: AxiosError<ErrorData>) => {
        setError(err.response)
      })
      .finally(() => setIsLoading(false))
  }, [])

  return { data, isLoading, error }
}
