type PluralProps = {
  replacement?: string
  suffix?: string
  persist?: boolean
}

export function pluralize (count: number, word: string, options: PluralProps = {}) {
  const {
    replacement,
    suffix = 's',
    persist = false
  } = options

  if (count === 1 || persist) return `${count} ${word}`

  if (replacement) return `${count} ${replacement}`

  if (word.endsWith('ão')) return `${count} ${word.slice(0, -2)}ões`

  if (word.endsWith('r') || word.endsWith('z') || word.endsWith('s')) return `${count} ${word}es`

  if (word.endsWith('l')) return `${count} ${word.slice(0, -1)}is`

  return `${count} ${word}${suffix}`
}
