import UpdateNowButtonHelper from './UpdateNowButtonHelper'
import * as S from './styled'
import { MobilePhone } from './Icon'

type alertUpdateNewVersionProps = {
  setTimeToAlertAgain: () => void
}

const AlertUpdateNewVersionComponent = ({
  setTimeToAlertAgain
}: alertUpdateNewVersionProps) => {
  const textBody =
    'Mais rápida e com melhorias que tornam sua experiência ainda mais fácil.'

  const textTitle = 'Nova versão do app'

  return (
    <S.WrapperModal>
      <S.IconBody>
        <MobilePhone />
      </S.IconBody>
      <S.Title>{textTitle}</S.Title>
      <S.Wrapper />
      <S.TextBody>{textBody}</S.TextBody>
      <S.Wrapper />
      <UpdateNowButtonHelper category={'suggest_update'} />
      <S.Wrapper />
      <S.ButtonLater
        className="button-full"
        type="button"
        data-cy="reset-alert-new-version"
        onClick={() => setTimeToAlertAgain()}
      >
        Deixar para mais tarde
      </S.ButtonLater>
    </S.WrapperModal>
  )
}

export default AlertUpdateNewVersionComponent
