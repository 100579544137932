import { Button, Stack, Text, Select, toast } from '@leroy-merlin-br/backyard-react'
import { AxiosResponse } from 'axios'
import { ChangeEvent, FC, FormEvent, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { handlePhoneFormat, isServerError, removeNonNumericCharacters } from 'user/utils'

import { NumberField } from 'shared/components'

import * as validator from 'utils/validators/validators'

import { MobileLayout } from '../MobileLayout'
import * as S from '../MobileLayout/styled'
import { Layout } from '../Layout'
import { addPhone, removePhone } from '../../services'
import { Phone } from '../../types'

type FormValues = {
  phoneType: string
  phoneNumber: string
}

type PhoneNumberLayoutProps = {
  title: string
  edit?: Phone
  isDesktop?: boolean
}

const landline = {
  label: 'Telefone',
  value: 'landline'
}

const cellphone = {
  label: 'Celular',
  value: 'cellphone'
}

const PhoneNumberLayout: FC<PhoneNumberLayoutProps> = ({
  title,
  edit,
  isDesktop
}) => {
  const [fieldValue, setFieldValue] = useState(edit?.number)
  const [fieldType, setFieldType] = useState(edit?.type)

  const [loading, setLoading] = useState(false)

  const {
    control,
    formState: { errors, isValid },
    clearErrors
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      phoneType: '',
      phoneNumber: ''
    }
  })

  const history = useHistory()

  const newPhone = async () => {
    const rawPhoneNumber = removeNonNumericCharacters(fieldValue)

    const phoneNumberFull = `+55${rawPhoneNumber}`

    try {
      await addPhone(phoneNumberFull)

      toast.primary('Novo número de telefone salvo com sucesso!', {
        variant: isDesktop ? 'solid' : 'light'
      })

      history.push('/meus-contatos')
    } catch (error) {
      const { status } = error as AxiosResponse

      if (status === 422) {
        return toast.critical('Número de telefone inválido!', {
          variant: isDesktop ? 'solid' : 'light'
        })
      }

      const hasServerError = status && isServerError(status)

      if (hasServerError) {
        toast.critical('Não foi possível adicionar um novo telefone!', {
          variant: isDesktop ? 'solid' : 'light'
        })
      }
    } finally {
      setLoading(false)
    }
  }

  const updatePhone = async (phone: string) => {
    const phoneNumberFull = `+55${phone}`

    try {
      await removePhone(phoneNumberFull)

      await newPhone()
    } catch (error) {
      const { status } = error as AxiosResponse

      if (status === 422) {
        return toast.critical('Não foi possível atualizar o telefone!', {
          variant: isDesktop ? 'solid' : 'light'
        })
      }

      const hasServerError = status && isServerError(status)

      if (hasServerError) {
        toast.critical('Não foi possível atualizar o telefone!', {
          variant: isDesktop ? 'solid' : 'light'
        })
      }
    } finally {
      setLoading(false)
    }
  }

  const onSubmit = async (event: FormEvent<EventTarget>) => {
    event.preventDefault()

    setLoading(true)

    if (edit) {
      return updatePhone(edit.number)
    }

    await newPhone()
  }

  const isDisabled = !isValid || edit?.number === removeNonNumericCharacters(fieldValue)

  const renderComponent = () => (
    <>
      <Text>{title}</Text>

      <form onSubmit={onSubmit}>
        <Stack space="giga">
          <S.SelectWrapper>
            <Select
              data-gtm-element-id="phone-type-input"
              isDisabled={loading}
              placeholder="Selecione o tipo de telefone"
              label="Tipo"
              name="phoneType"
              options={[landline, cellphone]}
              rules={{
                required: 'Selecione o tipo de telefone'
              }}
              control={control}
              state={Boolean(errors.phoneType) && 'invalid'}
              hint={errors.phoneType?.message}
              onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                setFieldType(event.target.value)

                setFieldValue('')

                setTimeout(clearErrors, 0)
              }}
              value={fieldType}
            />
          </S.SelectWrapper>

          <NumberField
            isDisabled={loading}
            disabled={!fieldType}
            name="phoneNumber"
            label="Número de telefone"
            rules={{
              required: fieldType === 'cellphone' ? 'Insira seu celular' : 'Insira seu telefone',
              validate: (value: string) => {
                if (fieldType === 'cellphone') {
                  return validator.isCellPhone(value) && validator.isCellPhoneDigit(removeNonNumericCharacters(value)) || 'Celular inválido'
                }

                return validator.isPhone(value) || 'Telefone inválido'
              }
            }}
            control={control}
            state={Boolean(errors.phoneNumber) && 'invalid'}
            hint={errors.phoneNumber?.message}
            format={handlePhoneFormat}
            value={fieldValue}
            onChange={(event: ChangeEvent<HTMLSelectElement>) => {
              setFieldValue(event.target.value)
            }}
            maxLength={fieldType === 'cellphone' ? 15 : 14}
          />
        </Stack>

        {isDesktop ? (
          <div style={{ marginTop: 24, display: 'flex', width: '100%', justifyContent: 'space-between' }}>
            <Button
              isDisabled={loading}
              appearance="critical"
              variant="outline"
              size="kilo"
              onClick={() => history.push('/meus-contatos')}
            >
              Cancelar
            </Button>

            <Button
              data-gtm-element-id="click-save-new-phone-button"
              size="kilo"
              type="submit"
              isDisabled={isDisabled}
              isLoading={loading}
            >
              Salvar
            </Button>
          </div>
        ) : (
          <>
            <S.ButtonWrapper style={{ marginTop: 16, marginBottom: 8 }}>
              <Button
                data-gtm-element-id="click-save-new-phone-button"
                isStretch
                isLoading={loading}
                type="submit"
                isDisabled={isDisabled}
              >
                Salvar
              </Button>
            </S.ButtonWrapper>

            <Button
              isStretch
              isDisabled={loading}
              appearance="critical"
              variant="ghost"
              onClick={() => history.push('/meus-contatos')}
            >
              Cancelar
            </Button>
          </>
        )}
      </form>
    </>
  )

  if (isDesktop) {
    return (
      <Layout description="Contatos" backLink="/meus-contatos" maxWidth={376}>
        {renderComponent()}
      </Layout>
    )
  }

  return (
    <MobileLayout description="Contatos">
      {renderComponent()}
    </MobileLayout>
  )
}

export default PhoneNumberLayout
