import $ from 'jquery'

import tokenNotify from 'scripts/utils/account/token-notifier'

class SimpleSubmit {
  constructor (el, options) {
    this.$element = $(el)
    this.options = options
  }

  init () {
    if (this.options.notifyToken) {
      tokenNotify()
      location.href = this.options.action

      return
    }

    return this.$element.submit()
  }
}

export default ($el) => new SimpleSubmit($el, $el.data()).init()

export { SimpleSubmit as Component }
