import { useState, useEffect } from 'react'
import axios, { AxiosError } from 'axios'

import { userResourcesApi } from 'api'
import type { UserResources } from 'api/userResources'

const CONTEXT_ASSISTED_SALE = 'assisted-sale'
const CONTEXT_APP = 'app'

type UserControls = {
  isLoggedIn?: boolean
  isAssistedSale?: boolean
  isOnMobileApp?: boolean
  isLoyaltyElegible?: boolean
}

export const useUserResources = () => {
  const [userResources, setUserResources] = useState<UserResources>()
  const [userControls, setUserControls] = useState<UserControls>({})
  const [isLoadingResources, setIsLoadingResources] = useState(true)
  const [error, setError] = useState<AxiosError>()

  const fetchUserResources = async (clearRequest = false) => {
    setIsLoadingResources(true)

    try {
      const data = await userResourcesApi.storedGetUserResources(clearRequest)

      const isLoggedIn = data?.email?.length > 0
      const isAssistedSale = data?.context === CONTEXT_ASSISTED_SALE
      const isOnMobileApp = data?.context === CONTEXT_APP
      const isLoyaltyElegible = !isAssistedSale && !data?.isLegalUser

      setUserResources(data)

      setUserControls({
        isLoggedIn,
        isAssistedSale,
        isOnMobileApp,
        isLoyaltyElegible
      })
    } catch (error) {
      if (axios.isAxiosError(error)) {
        setError(error)
      } else {
        throw error
      }
    }

    setIsLoadingResources(false)
  }

  const refresh = () => {
    fetchUserResources(true)
  }

  useEffect(() => {
    fetchUserResources()
  }, [])

  return { userResources, userControls, isLoadingResources, error, refresh }
}
