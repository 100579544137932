import { css } from '@emotion/react'
import styled from '@emotion/styled'

const wrapperStyles = ({ theme }) => css`
label: sorter-select-stack;

[target='sorter'] {
  ${theme.media.mega} {
      padding: ${theme.spacings.mega} 0;
    }

    & > div {
      padding: 0 ${theme.spacings.mega};
      overflow-y: hidden;
    }
  }
`

export const Wrapper = styled('div')(wrapperStyles)
