import { AxiosError } from 'axios'

import { API_ENDPOINTS } from 'scripts/react-components/lmcv-pro/services/constants'

import { InvoiceStatusData } from '../types/invoice-status'
import request from '../../../services/axios'

export const createInvoiceService = () => {
  const getInvoiceStatus = async (): Promise<InvoiceStatusData | null> => {
    try {
      const { data } = await request
        .get(API_ENDPOINTS.invoice)
        .then((response) => response.data)

      return data
    } catch (error) {
      if ((error as AxiosError).response?.status === 404) return null
      throw error
    }
  }

  const send = (invoiceFile: File) => {
    const formData = new FormData()
    formData.append('invoiceFile', invoiceFile)

    return request
      .post(API_ENDPOINTS.invoice, formData)
      .then((response) => response.data)
  }

  return { getInvoiceStatus, send }
}
