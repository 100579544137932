import { dataLayer } from 'newCheckout/dataLayer'

import {
  Order,
  PaymentMethod,
  SummaryData,
  Shippings,
  Item,
  ShippingPickUpInStore,
  ShippingDelivery,
  Service,
  DatalayerProduct,
  DeliveryAddress
} from './type'

export const transaction = ({
  summaryData,
  order,
  paymentMethod,
  shippings,
  services,
  datalayerProducts,
  address
}: {
  summaryData: SummaryData
  order: Order
  paymentMethod: PaymentMethod
  shippings: Shippings
  services: Service[]
  datalayerProducts: DatalayerProduct[]
  address: DeliveryAddress
}) => {
  const installment =
    paymentMethod.type === ('credit_card' || 'token')
      ? paymentMethod.amount_installment
      : 1

  const items = shippings.reduce((items, shipping) => {
    return [
      ...items,
      ...shipping.items.map((item) => {
        const datalayerProduct = datalayerProducts.find(
          (datalayerProduct) => datalayerProduct.id === item.product_id
        )

        return {
          ...item,
          shipping: shipping,
          packagingPrice: (item.packaging * item.price_unit).toFixed(2),
          quantity: Math.round(item.quantity / item.packaging),
          brand: datalayerProduct?.brand ?? '',
          category: datalayerProduct?.category ?? ''
        }
      })
    ]
  }, [] as (Item & { shipping: ShippingPickUpInStore | ShippingDelivery; packagingPrice: string; category: string; brand: string })[])

  dataLayer.transaction({
    purchaseObject: {
      coupon: summaryData.coupon,
      freight: summaryData.freight,
      orderId: order._id,
      revenue: summaryData.totalPrice,
      installments: installment,
      paymentMethod: paymentMethod.type,
      region: order.region
    },
    products: items.map((item) => ({
      packagingPrice: item.packagingPrice,
      brand: item.brand,
      category: item.category,
      deliveryForecast:
        item.shipping.type === 'delivery' ? item.shipping.time : '',
      pickupStoreForecast:
        item.shipping.type === 'pickUpInStore'
          ? `em até ${item.shipping.time} horas`
          : '',
      deliveryMode:
        item.shipping.type === 'pickUpInStore'
          ? 'pickup-in-store'
          : item.shipping.type,
      discount: item.discountPrice,
      discountType: item.discountType,
      name: item.product.name,
      price: item.toPrice,
      id: item.product_id,
      quantity: item.quantity,
      storeName: item.shipping.storeName,
      storeId: item.store_id ?? '',
      marketplace:
        item.shipping.type === 'pickUpInStore' ? '' : item.marketPlace,
      withDiscount: item.discountPrice ? 'com-desconto' : 'sem-desconto'
    })),
    services: services.map((service) => ({
      name: service.name,
      id: service.serviceId,
      brand: 'Serviço',
      category: service.serviceBankName,
      quantity: service.quantity,
      price: service.price.to,
      shippementType: 'delivery',
      store: '',
      marketPlace: 'Leroy Merlin',
      withdrawalDate: '',
      shippementDate: service.relatedProduct
        ? '3 dias úteis após a entrega ou retirada do produto'
        : '3 dias úteis',
      hasDiscount: 'sem-desconto',
      discountValue: '',
      discountType: '',
      relatedProduct: service.relatedProduct?.name || ''
    }))
  })
}

const DISCOUNT_TYPE_DICTIONARY = {
  bank_slip: 'boleto',
  credit_card: 'cartão',
  token: 'cartão',
  pix: 'pix'
}

export const interaction = ({
  summaryData,
  paymentMethod
}: {
  summaryData: SummaryData
  paymentMethod: PaymentMethod
}) => {
  if (summaryData.PaymentDiscount > 0) {
    dataLayer.interaction({
      category: 'Desconto por forma de Pagamento',
      action: 'Compra sucesso',
      label:
        summaryData.PaymentDiscount +
        ' | ' +
        DISCOUNT_TYPE_DICTIONARY[paymentMethod.type]
    })
  }
}
