import styled from '@emotion/styled'
import { css } from '@emotion/react'

const wrapperStyles = ({ theme }) => css`
  label: header-wrapper;

  position: absolute;

  width: 100%;
  height: 197px;

  background: ${theme.colors.p700};
  padding-top: ${theme.spacings.tera};

  display: flex;
  justify-content: center;
  align-items: flex-start;
`

export const Wrapper = styled('div')(wrapperStyles)
