import { useContext, Fragment } from 'react'
import { Text, Icon, Inline } from '@leroy-merlin-br/backyard-react'
import { Cross } from '@leroy-merlin-br/backyard-icons'

import { deliveryLimitHours } from 'site/utils/deliveryLimitHours'

import { EntryKeyContext } from '../../context/EntryKeyContext'
import * as S from './styled'

const AppliedFilters = () => {
  const { onOptionClick, filterUsedOptions } = useContext(EntryKeyContext)
  const freight = deliveryLimitHours()

  const allFilterUsedOptions = filterUsedOptions.map(filter => {
    const isFastDelivery = filter.filterKey === 'entrega-rapida'
    const newLabel = isFastDelivery
      ? `Receba ${freight.deliveryForecast}`
      : filter.label

    return {
      ...filter,
      label: newLabel
    }
  })

  return (
    <Fragment>
      <Text as="strong" size="giga" isBold noMargin>
        Filtros selecionados
      </Text>

      <Inline alignY="center" alignX="flex-start" space="byte" wrap="wrap" data-cy="selected-filters">
        {allFilterUsedOptions.map((filter, index) => (
          <S.Tag key={index}>
            <S.Label>{filter.label}</S.Label>
            <S.ButtonClose
              type="button"
              onClick={event => {
                event.preventDefault()
                event.stopPropagation()

                onOptionClick({
                  name: filter.filterKey,
                  value: filter.query.replace(/\s/g, '_'),
                  type: 'filter',
                  dataLayerOptions: {
                    filter: 'Normal',
                    filterType: {
                      index: filter.filterTypeIndex,
                      value: filter.name
                    },
                    filterElement: {
                      index: filter.filterElementIndex,
                      value: filter.label
                    }
                  }
                })
              }}
            >
              <Icon as={Cross} size="byte" />
            </S.ButtonClose>
          </S.Tag>
        ))}
      </Inline>
    </Fragment>
  )
}

export default AppliedFilters
