import { useState } from 'react'

import { AlertMessage } from '.'

const AlertMessageContainer = ({ showCloseButton, message, ...props }) => {
  const [showAlertMessage, setShowAlertMessage] = useState(true)

  const onCloseFunction = () => setShowAlertMessage(false)

  return showAlertMessage ? (
    <AlertMessage {...props} onClose={showCloseButton && onCloseFunction}>
      {message}
    </AlertMessage>
  ) : null
}

export default AlertMessageContainer
