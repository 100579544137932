import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { Theme } from 'scripts/react-components/themes/Theme'

const separatorWrapperStyles = ({ theme }: Theme) => css`
  label: chat__messaging_separator__wrapper;

  /* remove rest of sibiling grid gap at inset chat body */
  margin: calc(22px - ${theme.spacings.mega}) auto 22px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
`

const lineThroughStyles = () => css`
  label: chat__messaging_separator__line-through;

  height: 1px;
  /* Non DS equivalent */
  background: #d9d9d9;
`

const childrenStyles = ({ theme }: Theme) => css`
  label: chat__messaging_separator__children;

  word-break: keep-all;
  white-space: nowrap;
  color: ${theme.colors.n800};
  font-size: 10px;
  vertical-align: middle;
`

export const SeparatorWrapper = styled('div')(separatorWrapperStyles)
export const LineThrough = styled('hr')(lineThroughStyles)
export const Children = styled('span')(childrenStyles)
