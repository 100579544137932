import {
  Text,
  Icon,
  Inline,
  Stack,
  Card,
  Tooltip
} from '@leroy-merlin-br/backyard-react'
import { InfoCircle } from '@leroy-merlin-br/backyard-icons'

import { AlertMessage } from 'shared/components'

import formatPriceToBRL from 'utils/formatPriceToBRL'

import * as S from './styled'
import { SummaryVisualType } from './type'

const Summary = ({
  summaryData,
  forecastTexts,
  paymentMethodText,
  hasServices,
  hasStandaloneService,
  isMobile
}: SummaryVisualType) => (
  <>
    <S.CardPadding>
      <Card>
        <S.Wrapper>
          <Inline>
            <Text size="mega" isBold>
              Resumo do Pedido
            </Text>
          </Inline>
          <S.ForecastPadding>
            <Stack space="bit">
              {forecastTexts.map((forecastText, index) => (
                <Inline key={index} alignX="space-between">
                  <Text size="kilo" noMargin>
                    {forecastText.title}
                  </Text>
                  <Text size="kilo" isBold align="right" noMargin>
                    {forecastText.text}
                  </Text>
                </Inline>
              ))}
            </Stack>
          </S.ForecastPadding>
          <Inline alignX="space-between">
            <Text size="kilo">Subtotal</Text>
            <Text size="kilo" isBold align="right">
              {formatPriceToBRL(summaryData.subtotal)}
            </Text>
          </Inline>
          {hasServices && (
            <>
              <Inline alignX="space-between">
                <Text size="kilo">Previsão de instalação</Text>
                <Inline space="bit">
                  <Text size="kilo" isBold align="right">
                    {hasStandaloneService
                      ? '1 a 3 dias úteis*'
                      : 'A partir de 3 dias úteis após a retirada dos produtos'}
                  </Text>
                  {hasStandaloneService && !isMobile && (
                    <Tooltip content="A data pode variar para cada serviço contratado. Para serviços atrelados ao produto, a data de instalação será de 3 dias úteis partir da entrega do seu produto. Para o serviço comprado separadamente, a data de instalação é de 3 dias úteis, após aprovação do pagamento">
                      <S.IconWrapper>
                        <Icon as={InfoCircle} size="kilo" />
                      </S.IconWrapper>
                    </Tooltip>
                  )}
                </Inline>
              </Inline>
              <Inline alignX="space-between">
                <Text size="kilo">Serviço de instalação</Text>
                <Text size="kilo" isBold align="right">
                  {formatPriceToBRL(summaryData.totalServices)}
                </Text>
              </Inline>
            </>
          )}
          {!!summaryData.cashback && (
            <Inline alignX="space-between">
              <Text size="kilo">Cashback</Text>
              <Text size="kilo" isBold align="right">
                {formatPriceToBRL(summaryData.cashback)}
              </Text>
            </Inline>
          )}
          <Inline alignX="space-between">
            <Text size="kilo">Desconto</Text>
            <Text size="kilo" isBold align="right">
              {formatPriceToBRL(summaryData.discount)}
            </Text>
          </Inline>
          <Inline alignX="space-between">
            <Text size="kilo">Frete</Text>
            <Text size="kilo" isBold align="right">
              {formatPriceToBRL(summaryData.freight)}
            </Text>
          </Inline>
          <Inline alignX="space-between">
            <Text size="kilo">Forma de pagamento</Text>
            <Text size="kilo" isBold align="right">
              {paymentMethodText}
            </Text>
          </Inline>
          {!!summaryData.PaymentDiscount && (
            <Inline alignX="space-between" data-cy="data-payment-discount">
              <Text size="kilo">Desconto por pagamento</Text>
              <Text size="kilo" isBold align="right">
                {formatPriceToBRL(summaryData.PaymentDiscount)}
              </Text>
            </Inline>
          )}
          <Inline alignX="space-between" data-cy="summary-total">
            <Text size="mega" isBold>
              Total
            </Text>
            <Text size="mega" isBold>
              {formatPriceToBRL(summaryData.totalPrice)}
            </Text>
          </Inline>
        </S.Wrapper>
      </Card>
    </S.CardPadding>
    {isMobile && (
      <AlertMessage type="warning">
        *A data de instalação poderá variar para cada serviço contratado. Para
        serviços atrelados ao produto, a data de instalação será de 1 a 3 dias
        úteis partir da entrega do seu produto.
      </AlertMessage>
    )}
  </>
)

export default Summary
