import {
  Text,
  Heading,
  Stack,
  Container,
  Row,
  Col,
  Button,
  Visible,
  Hidden,
  Inline,
  useBreakpoint,
  Icon
} from '@leroy-merlin-br/backyard-react'

import * as dataLayer from 'scripts/utils/data-layer'

import { LmcvBrand } from 'lmcv/components'

import * as S from './styled'

const HeroBanner = () => {
  const { breakpoint } = useBreakpoint()
  const isMobile = ['untilKilo', 'kilo'].includes(breakpoint)

  const queryParams = new URLSearchParams(location.search)
  const storeParam = queryParams.get('store')
  const associatePath = storeParam
    ? `/fidelidade/participar?store=${storeParam}`
    : '/fidelidade/participar'

  const assetsPath = window && window.env.assetHost

  return (
    <S.Section>
      <Container>
        <Row reverse="mega">
          <Col size={{ giga: 6 }} offset={{ giga: 1 }}>
            <Visible when={['untilKilo', 'kilo', 'mega']}>
              <Inline alignX="flex-end">
                <S.IconWrapper>
                  <Icon as={LmcvBrand} />
                </S.IconWrapper>
              </Inline>
            </Visible>
            <S.WrapperImage>
              <S.Image
                src={`${assetsPath}assets/images/lmcv/landing-page-2022/hero-banner-phone.png`}
              />
            </S.WrapperImage>
          </Col>
          <Col size={{ giga: 5 }}>
            <Stack space="giga">
              <Hidden when={['untilKilo', 'kilo', 'mega']}>
                <S.IconWrapper>
                  <Icon as={LmcvBrand} />
                </S.IconWrapper>
              </Hidden>
              <S.TitleWrapper>
                <Heading size="zetta" noMargin>
                  Transforme as suas compras em <span>pontos</span> e benefícios
                </Heading>
              </S.TitleWrapper>

              <S.SubtitleWrapper>
                <Text size="mega" color="n500" noMargin>
                  Receba cupons exclusivos e acumule pontos para trocar por
                  cashback. Cadastre-se em menos de 1 min. e comece a ganhar{' '}
                  <Text as="span" isBold color="n600">
                    1 ponto{' '}
                  </Text>
                  a cada{' '}
                  <Text as="span" isBold color="n600">
                    R$1,00{' '}
                  </Text>
                  em compras.
                </Text>
              </S.SubtitleWrapper>
            </Stack>
            <S.ButtonWrapper>
              <Button
                as="a"
                href={associatePath}
                isStretch={isMobile}
                onClick={dataLayer.loyaltyLandingPageHeroBannerButton}
              >
                Começar a aproveitar
              </Button>
            </S.ButtonWrapper>
          </Col>
        </Row>
      </Container>
    </S.Section>
  )
}

export default HeroBanner
