import { useState } from 'react'
import classNames from 'classnames'

import emitter from 'scripts/utils/emitter'

const Spinner = ({ dimension = 50, visible = false, customClass = {} }) => {
  const [spinnerVisible, setSpinnerVisible] = useState(visible)
  const standardClasses = {
    overlay: true,
    visible: spinnerVisible,
    'show-spinner': spinnerVisible
  }

  emitter.on('spinner:show', () => setSpinnerVisible(true))
  emitter.on('spinner:hide', () => setSpinnerVisible(false))
  emitter.on('spinner:toggle', () => setSpinnerVisible(!spinnerVisible))

  const classes = Object.keys(customClass).length ? customClass : standardClasses
  const spinnerClass = classNames({ ...classes })

  const svgProps = {
    className: 'spinner',
    width: dimension,
    height: dimension,
    viewBox: '0 0 66 66',
    xmlns: 'http://www.w3.org/2000/svg'
  }

  const circleProps = {
    className: 'path',
    fill: 'none',
    strokeWidth: 6,
    strokeLinecap: 'round',
    cx: 33,
    cy: 33,
    r: 30
  }

  return (
    <div className={spinnerClass}>
      <svg {...svgProps}>
        <circle {...circleProps} />
      </svg>
    </div>
  )
}

export const showSpinner = () => emitter.emit('spinner:show')
export const hideSpinner = () => emitter.emit('spinner:hide')
export const toggleSpinner = () => emitter.emit('spinner:toggle')

export default Spinner
