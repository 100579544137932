import { useBreakpoint, Text } from '@leroy-merlin-br/backyard-react'

import { TIMELINE_STEP_IMAGES, TIMELINE_STATUS } from 'checkout/constants/index'

import * as S from './styled'

const Timeline = ({ steps }) => {
  const { breakpoint } = useBreakpoint()
  const isMobile = ['untilKilo', 'kilo'].includes(breakpoint)

  const activeSteps = steps.filter(
    step => step.status !== TIMELINE_STATUS.inactive
  )

  return (
    <S.Wrapper>
      {steps.map((step, index) => {
        const { status, description, date, current } = step

        const isCanceled = status === TIMELINE_STATUS.canceled
        const isActive = status !== TIMELINE_STATUS.inactive
        const isLastStep = index + 1 === steps.length
        const isLastActive = index + 1 === activeSteps.length && !isCanceled && !isLastStep
        const isDark = status === TIMELINE_STATUS.partial

        const key = `${index}-${description.replace(' ', '-')}`
        const shouldShowDate = current && date
        const shouldShowStep = isActive || (!isActive && !isMobile)

        const imageIndex = isCanceled ? 'canceled' : index + 1
        const image = TIMELINE_STEP_IMAGES[imageIndex]

        return (
          shouldShowStep && (
            <S.Step
              key={key}
              isActive={isActive}
              isCurrent={current}
              isLastActive={isLastActive}
            >
              <S.Bullet status={status}>
                {isActive && <S.Icon image={image} isDark={isDark} />}
              </S.Bullet>

              {isActive && (
                <S.Description isDark={current}>
                  <Text as="span" size="kilo" noMargin>
                    {description}
                  </Text>
                  {shouldShowDate && (
                    <Text as="span" size="kilo" noMargin>
                      {date}
                    </Text>
                  )}
                </S.Description>
              )}
            </S.Step>
          )
        )
      })}
    </S.Wrapper>
  )
}

export default Timeline
