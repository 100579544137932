import qs from 'qs'

export const getServiceList = ({
  crossServices: {
    get: {
      payload
    }
  }
}) => ({
  serviceList: payload
})

export const combineServiceInfoById = ({
  crossServices: {
    post: {
      payload: postPayload
    },
    get: {
      payload: getPayload
    }
  }
}, id) => {
  let combineServicesData = {}

  const availableServices = getPayload.find(service => service?.productId === id)
  const serviceData = postPayload.find(service => service?.relatedProduct.productId === id)

  if (availableServices) {
    const service = availableServices.services?.[0]
    const price = service.price

    combineServicesData = {
      isAvailable: true,
      ...service,
      price: {
        ...price,
        from: price.to,
        total: price.to
      },
      serviceId: service.id
    }
  }

  if (serviceData) {
    combineServicesData = {
      isAdded: true,
      ...combineServicesData,
      ...serviceData
    }
  }

  return combineServicesData
}

export const filterServiceListById = ({
  crossServices: {
    get: {
      payload
    }
  }
}, id) => {
  const filteredList = payload.find(service => service.productId !== id)

  if (filteredList !== payload) {
    return filteredList
  } else {
    return false
  }
}

// update it when redux/saga are implemented to get data from store state
export const stringifyCartProductList = (_, { data, regionId }) => {
  const products = data.map(({ productId, skuId }) => ({ id: productId, skuId }))

  return qs.stringify(
    {
      regionId,
      products
    },
    {
      addQueryPrefix: true,
      arrayFormat: 'indices',
      encode: false,
      skipNulls: true
    }
  )
}
