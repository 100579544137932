import styled from '@emotion/styled'
import { css } from '@emotion/react'

const backgroundStyles = () => css`
  label: coupon__background;
  display: block;
  position: absolute;

  top: -13px;
  left: -11px;
  z-index: 1;
`

const contentStyles = ({ theme }) => css`
  label: coupon__content;

  display: block;
  position: absolute;

  width: 100%;
  height: 100%;

  top: 0px;
  left: 0px;
  z-index: 2;

  padding: ${theme.spacings.byte};
  padding-top: ${theme.spacings.mega};

  ${theme.media.giga} {
    padding: ${theme.spacings.mega};
    padding-bottom: ${theme.spacings.byte};
  }
`

const iconShareStyles = ({ theme }) => css`
  label: icon__share;

  display: block;
  cursor: pointer;
  position: absolute;
  top: ${theme.spacings.kilo};
  right: ${theme.spacings.mega};
  z-index: 3;

  svg {
    color: ${theme.colors.f600};
  }
`

const groupDescriptionStyles = () => css`
  label: icon__description;

  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 190px;
`

const pictureStyles = ({ theme }) => css`
  label: picture;

  position: relative;

  width: 84px;
  height: 84px;
  margin: 0 auto;
  margin-bottom: ${theme.spacings.byte};

  display: flex;
  align-items: center;
  justify-content: center;

  border: 3px solid ${theme.colors.n100};
  border-radius: ${theme.borderRadius.circle};
  cursor: pointer;

  & > img {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: ${theme.borderRadius.circle};
  }

  & > svg {
    display: block;
    width: 60%;
    height: 60%;
    color: ${theme.colors.v700};
    fill: ${theme.colors.v700};
  }
`

const pictureIconStyles = ({ theme }) => css`
  label: picture__icon;

  position: absolute;
  left: 0;
  bottom: 0;
  width: 29px;
  height: 29px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: ${theme.borderRadius.circle};
  border: 3px solid ${theme.colors.n100};
  background: ${theme.colors.white};

  transition: background ease-in 300ms;

  svg {
    color: ${theme.colors.n300};
  }
`

const titleStyles = ({ theme }) => css`
  label: title;

  margin-top: ${theme.spacings.bit};
  margin-bottom: ${theme.spacings.byte};

  p {
    line-height: ${theme.typography.text.kilo.lineHeight};
  }
`

const descriptionStyles = ({ theme }) => css`
  label: description;

  color: ${theme.colors.n400};

  p {
    line-height: ${theme.typography.subHeadings.kilo.lineHeight};
  }
`

const dividerStyles = ({ theme }) => css`
  label: divider;

  position: absolute;
  left: 18px;
  top: 206px;

  z-index: ${theme.zIndex.backdrop};

  width: calc(100% - 36px);

  border-bottom: 6px dotted ${theme.colors.n100};
`

const buttonWrapperStyles = ({ theme }) => css`
  label: button-wrapper;

  width: 100%;
  justify-content: flex-start;
  margin-top: 14px;
  padding: 0 ${theme.spacings.byte};

  ${theme.media.giga} {
    padding: 0px;
  }
`

const textFooterStyles = ({ theme }) => css`
  label: text__footer;

  display: flex;
  justify-content: center;

  svg {
    display: inline;
    position: relative;
    margin-right: ${theme.spacings.bit};
    top: 1px;
  }
`

const buttonIconStyles = ({ theme }) => css`
  label: button__icon;

  svg {
    margin-right: ${theme.spacings.byte};
  }
`

const baseStyles = ({ theme }) => css`
  label: base;

  transform: '${theme.media.giga}';

  position: relative;
  height: 300px;
  width: 150px;
  box-sizing: border-box;

  ${theme.media.giga} {
    width: 172px;
  }
`

const pictureShellWrapperStyles = ({ theme }) => css`
  label: shell;

  margin-bottom: ${theme.spacings.byte};
`

export const Background = styled('div')(backgroundStyles)
export const Content = styled('div')(contentStyles)
export const IconShare = styled('div')(iconShareStyles)
export const GroupDescription = styled('div')(groupDescriptionStyles)
export const Picture = styled('div')(pictureStyles)
export const PictureIcon = styled('div')(pictureIconStyles)
export const Title = styled('div')(titleStyles)
export const Description = styled('div')(descriptionStyles)
export const Divider = styled('div')(dividerStyles)
export const ButtonWrapper = styled('div')(buttonWrapperStyles)
export const TextFooter = styled('div')(descriptionStyles, textFooterStyles)
export const ButtonIcon = styled('div')(buttonIconStyles)
export const PictureShellWraper = styled('div')(pictureShellWrapperStyles)

const baseActiveStyles = ({ theme, isActive }) =>
  isActive &&
  css`
    label: base__active;

    ${IconShare} svg {
      color: ${theme.colors.white};
    }

    ${Picture} {
      border-color: ${theme.colors.f500};

      svg {
        color: ${theme.colors.white};
        fill: ${theme.colors.white};
      }
    }

    ${PictureIcon} {
      svg {
        color: ${theme.colors.f200};
      }

      background: ${theme.colors.f500};
      border: none;
    }

    ${Title} {
      color: ${theme.colors.white};
    }

    ${Description} {
      color: ${theme.colors.f400};
    }

    ${TextFooter} {
      color: ${theme.colors.f200};
    }

    ${ButtonWrapper} button {
      background: transparent;
      color: ${theme.colors.f400};
    }
  `

export const Wrapper = styled('div')(baseStyles, baseActiveStyles)
