import { Button, Dialog } from '@backyard-ui/core'

type Props = { showModal: boolean }

export const UnexpectedErrorModal = ({ showModal }: Props) => {
  return (
    <Dialog.Root isOpen={showModal} closeOnOverlayClick={false}>
      <Dialog.Content>
        <Dialog.Title>Ops!</Dialog.Title>
        <Dialog.Description>
          Você está sendo redirecionado para continuar sua navegação.
          Agradecemos sua compreensão. Precisa de ajuda? Estamos aqui para você.
        </Dialog.Description>

        <Dialog.Footer>
          <Dialog.Close>
            <Button onClick={() => window.location.reload()}>Ok</Button>
          </Dialog.Close>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog.Root>
  )
}
