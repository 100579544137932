import { ArrowDown, ArrowUp } from 'frontendCheckout/assets/icons'

type PriceProps = {
  title?: string
  totalPrice: string
  isOpen: boolean
  onCollapse: () => void
}

export const Price = ({
  title = 'Total com frete',
  totalPrice,
  isOpen,
  onCollapse
}: PriceProps) => {
  return (
    <div className="flex">
      <div className="flex items-center cursor-pointer" onClick={onCollapse} data-testid="btn-collapse">
        <div className="mr-4">{isOpen
          ? <ArrowUp />
          : <ArrowDown />
        }</div>

        <div className="flex flex-col">
          <p className="text-sm">{title}</p>
          <b className="text-base">{totalPrice}</b>
        </div>
      </div>
    </div>
  )
}
