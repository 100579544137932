const ComputerOutline = () => (
  <svg
    viewBox="0 0 73 72"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.40115 6.34363H14.2992C15.6292 6.34363 16.7104 7.42482 16.7104 8.75479V63.2452C16.7104 64.5751 15.6292 65.6563 14.2992 65.6563H3.40115C2.07119 65.6563 0.98999 64.5751 0.98999 63.2452V8.75479C0.98999 7.42482 2.07119 6.34363 3.40115 6.34363ZM4.09962 62.5467H13.6008H13.6103V9.45326H4.09962V62.5467ZM22.2789 15.5864H71.4303L71.4398 15.596C72.2914 15.596 72.9899 16.2849 72.9899 17.146V49.7828C72.9899 50.6344 72.301 51.3328 71.4398 51.3328H54.5617V55.8873H57.0494C57.901 55.8873 58.5994 56.5857 58.5994 57.4373C58.5994 58.2889 57.9105 58.9873 57.0494 58.9873H34.1147C33.2631 58.9873 32.5646 58.2889 32.5646 57.4373C32.5646 56.5857 33.2535 55.8873 34.1147 55.8873H36.6215V51.3328H22.2789C21.4274 51.3328 20.7289 50.6439 20.7289 49.7828V17.1364C20.7289 16.2849 21.4178 15.5864 22.2789 15.5864ZM39.7216 55.8873H51.4425V51.3328H39.7216V55.8873ZM23.8385 48.2232H69.8707H69.8802V18.696H23.8385V48.2232ZM8.85493 51.2754C10.1849 51.2754 11.2661 50.1942 11.2661 48.8643C11.2661 47.5343 10.1849 46.4531 8.85493 46.4531C7.52496 46.4531 6.44376 47.5343 6.44376 48.8643C6.44376 50.1942 7.52496 51.2754 8.85493 51.2754ZM10.4051 34.5121C10.4051 35.3637 9.7162 36.0622 8.85507 36.0622C8.00351 36.0622 7.30503 35.3733 7.30503 34.5121V15.9213C7.30503 15.0698 8.00351 14.3713 8.85507 14.3713C9.70663 14.3713 10.4051 15.0602 10.4051 15.9213V34.5121Z"
    />
  </svg>
)

export default ComputerOutline
