import { css } from '@emotion/react'
import styled from '@emotion/styled'

const wrapperStyles = ({ theme }) => css`
  label: modules-spinner__wrapper;

  display: flex;
  justify-content: center;

  margin: ${theme.spacings.zetta} auto;
`

export const Wrapper = styled('div')(wrapperStyles)
