import { Fragment, useEffect, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { AnimatePresence, motion } from 'framer-motion'

import { BeInspiredThumb } from 'shared/components/BeInspiredThumb'
import { ShapeThumb } from 'shared/constants/prop-types'
import { ContentsThumb } from 'shared/components/ContentsThumb'

import WishlistProviderMethods from 'site/Wishlist/provider'

const Content = ({ items, removeItem }) => {
  const [contents, setContents] = useState(items)
  const [show, setShow] = useState(Array.from(contents, () => true))

  const variants = useMemo(() => {
    return {
      initial: {
        opacity: 0
      },
      enter: {
        opacity: 1,
        transition: {
          duration: 0.3
        }
      },
      exit: {
        opacity: 0,
        transition: {
          duration: 0.3
        }
      }
    }
  }, [])

  const updateItemsToShow = itemIndex => {
    const showUpdated = [...show]
    showUpdated[itemIndex] = false
    setShow(showUpdated)
  }

  const handleRemove = async (e, item, index) => {
    if (e.target.dataset.name === 'heart-icon') {
      updateItemsToShow(index)
      removeItem(item)
    }
  }

  const renderContents = () => {
    const contentItem = (item, index) => (
      <li
        key={`content-thumb-${item.id}`}
        onClick={e => handleRemove(e, item, index)}
      >
        {item.type === 'article' ? (
          <ContentsThumb
            item={item}
            trackingAttributes={{
              category: 'wishlist',
              label: `adicionar_wishlist_${item.id}`,
              action: 'clicou'
            }}
          />
        ) : (
          <BeInspiredThumb
            item={item}
            trackingAttributes={{
              category: 'wishlist',
              label: `adicionar_wishlist_${item.id}`,
              action: 'clicou'
            }}
          />
        )}
      </li>
    )

    return (
      <Fragment>
        <AnimatePresence>
          {contents.map((item, index) => (
            <motion.div
              className={'wishlist-thumbs-list-item entered'}
              variants={variants}
              initial="initial"
              animate="enter"
              exit="exit"
              key={`tst-${item.id}`}
            >
              {contentItem(item, index)}
            </motion.div>
          ))}
        </AnimatePresence>
      </Fragment>
    )
  }

  const renderNoMatch = () => (
    <li className="wishlist-thumbs-list-not-found">
      <p className="message-main">
        Você ainda não possui nenhum produto nesta lista.
      </p>
    </li>
  )

  useEffect(() => {
    const updateItemsArray = () => {
      const notHasContents = show.every(item => item === false)

      if (notHasContents) {
        const contentsUpdated = []
        setContents(contentsUpdated)
      }
    }

    updateItemsArray()
  }, [show])

  return (
    <WishlistProviderMethods items={contents}>
      <ul className="wishlist-thumbs-list wishlist-thumbs-list-contents">
        {contents.length > 0 ? renderContents() : renderNoMatch()}
      </ul>
    </WishlistProviderMethods>
  )
}

Content.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({ ...ShapeThumb })).isRequired,
  removeItem: PropTypes.func.isRequired
}

export default Content
