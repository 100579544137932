import { formatters } from 'frontendCheckout/utils'

type CouponsProps = {
  amount?: number
}

export const textFormatter = (amount?: number) => {
  if (!amount) return

  const text = formatters.pluralize(amount, 'Disponível', {
    replacement: 'Disponíveis'
  })

  return amount <= 9 ? '0' + text : text
}

export const Coupons = ({ amount }: CouponsProps) => {
  return (
    <p className="text-sm">
      <span className="text-tertiary-600">{textFormatter(amount)}</span>
    </p>
  )
}
