import { Fragment, useState } from 'react'
import {
  Text,
  Heading,
  useModal,
  Stack,
  Button,
  Row,
  Col,
  Container,
  Card
} from '@leroy-merlin-br/backyard-react'

import { DocumentPro } from 'lmcv/components'
import handleModal from 'lmcv/pages/Customers/handleModal'

import * as S from './styled'

const JoinProfessional = ({ status, hasProfessionalRelationship }) => {
  const { setModal } = useModal()

  const [drawerVisibility, setDrawerVisibility] = useState(false)
  const isNotEligibleToEAD = status === 10 && hasProfessionalRelationship

  const showDrawer = () => {
    isNotEligibleToEAD ? handleModal(setModal) : setDrawerVisibility(true)
  }

  const closeDrawer = () => setDrawerVisibility(false)

  const onSubmit = () => document.location.reload()

  const onCatch = () => {
    closeDrawer()
    handleModal(setModal)
  }

  return (
    <Fragment>
      <Card title="Clientes">
        <S.Body>
          <S.Image
            src={`${window &&
              window.env.assetHost}assets/images/lmcvNew/locked_clients.png`}
          />
          <Stack space="kilo">
            <Heading size="tera" noMargin>
              Essa área é exclusiva para Profissionais
            </Heading>
            <Text size="kilo" noMargin>
              Para ter acesso a essa área você precisa enviar seus documentos
              para validação
            </Text>
          </Stack>

          <Container>
            <Row justify="center">
              <Col size={{ giga: 4 }}>
                <S.ButtonWrapper>
                  <Button
                    onClick={showDrawer}
                    appearance="primary"
                    isStretch
                    size="mega"
                  >
                    Enviar documentos
                  </Button>
                </S.ButtonWrapper>
              </Col>
            </Row>
          </Container>
        </S.Body>
      </Card>

      <DocumentPro
        open={drawerVisibility}
        onClose={closeDrawer}
        onCancel={closeDrawer}
        onFinish={onSubmit}
        onCatch={onCatch}
      />
    </Fragment>
  )
}

export default JoinProfessional
