import PropTypes from 'prop-types'

const Title = ({ close, text, move }) => (
  <div
    className="wishlist-drawer-title"
    onTouchMove={ move }
  >
    <button
      aria-label="Fechar"
      onClick={ close }
      className="glyph glyph-x close-button"
      type="button"
    />
    <h4>{ text }</h4>
  </div>
)

Title.propTypes = {
  close: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  move: PropTypes.func
}

export default Title
