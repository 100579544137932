import { Text } from '@leroy-merlin-br/backyard-react'
import { css } from '@emotion/react'
import styled from '@emotion/styled'

const STATUS_MAP = {
  ativo: 'p500',
  pendente: 'y500',
  finalizado: 'n500',
  concluído: 'n500'
}

const photoStyles = ({ theme }) => css`
  label: photo;

  height: 88px;
  margin: 0 auto;
  margin-bottom: ${theme.spacings.mega};
  width: 88px;
`

export const Photo = styled('div')(photoStyles)

const projectNameStyles = ({ theme }) => css`
  label: project__name;

  color: ${theme.colors.n400};
`

export const ProjectName = styled(Text)(projectNameStyles)

const projectStatuStyles = ({ theme, status }) => css`
  label: project__status;

  display: block;
  color: ${!status ? theme.colors.n800 : theme.colors[STATUS_MAP[status]]};
  position: relative;

  &::before {
    background: currentColor;
    border-radius: ${theme.borderRadius.circle};
    content: '';
    display: inline-block;
    height: 8px;
    margin-right: ${theme.spacings.byte};
    width: 8px;
  }
`

export const ProjectStatus = styled(Text)(projectStatuStyles)
