import { Fragment, useState, useRef, useEffect, useLayoutEffect } from 'react'
import {
  Inline,
  Text,
  Button,
  Icon,
  TextField
} from '@leroy-merlin-br/backyard-react'
import { Checkmark } from '@leroy-merlin-br/backyard-icons'

import mask from 'utils/maskingUtils'

import * as S from './styled'

const CustomPriceRange = ({
  onClick = () => {},
  onFocus = () => {},
  isDisabled = false
}) => {
  const [currentValues, setCurrentValues] = useState({ min: 0, max: 0 })
  const [hasErrors, setHasErrors] = useState({ min: false, max: false })

  const handleChange = ({ target }) => {
    const { value, name } = target
    const formattedValue = parseInt(value.replace(/\D/g, ''), 10)
    const finalValue = isNaN(formattedValue) ? 0 : formattedValue

    if (name) {
      setCurrentValues(previousObj => {
        const updatedValues = { ...previousObj, [name]: finalValue }

        validateValues(updatedValues, name)

        return updatedValues
      })
    }
  }

  const handleSubmit = event => {
    onClick(event, currentValues)

    setCurrentValues({ min: 0, max: 0 })
  }

  const validateValues = ({ min, max }, attr) => {
    const defaultState = { min: false, max: false }

    if (min !== 0 && max !== 0) {
      setHasErrors(prevState => {
        const state = Boolean(min >= max)

        return state ? { ...prevState, [attr]: state } : defaultState
      })
    } else {
      setHasErrors(defaultState)
    }
  }

  const validateField = field => {
    if (hasErrors[field]) {
      return 'invalid'
    }

    if (currentValues[field]) {
      return 'valid'
    }
  }

  useLayoutEffect(() => {
    onFocus(Boolean(currentValues.min || currentValues.max))
  }, [currentValues.min, currentValues.max, onFocus])

  return (
    <Fragment>
      <Text
        as="strong"
        size="kilo"
        color={isDisabled ? 'n300' : ''}
        isBold
        noMargin
      >
        Personalizado
      </Text>

      <S.MainWrapper>
        <Inline alignX="flex-start" alignY="center" space="byte" wrap="nowrap">
          <TextField
            name="min"
            onChange={handleChange}
            value={mask.maskedText('money', currentValues.min)}
            placeholder="R$ 0"
            isDisabled={isDisabled}
            state={validateField('min')}
          />

          <Text
            as="span"
            size="kilo"
            color={isDisabled ? 'n300' : 'n600'}
            noMargin
          >
            até
          </Text>

          <TextField
            name="max"
            onChange={handleChange}
            value={mask.maskedText('money', currentValues.max)}
            placeholder="R$ 0"
            isDisabled={isDisabled}
            state={validateField('max')}
          />

          <Button
            size="mega"
            onClick={handleSubmit}
            isDisabled={
              Boolean(!currentValues.min && !currentValues.max) ||
              hasErrors.min ||
              hasErrors.max
            }
          >
            <Icon as={Checkmark} size="giga" color="white" />
          </Button>
        </Inline>
      </S.MainWrapper>
    </Fragment>
  )
}

export default CustomPriceRange
