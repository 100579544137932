import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { styleHelpers } from '@leroy-merlin-br/backyard-react'

const wrapperStyles = ({ theme }) => css`
  label: collapse;

  margin-bottom: ${theme.spacings.kilo};
  padding: ${theme.spacings.giga} ${theme.spacings.mega};

  background: ${theme.colors.white};
  ${styleHelpers.shadowTriple(theme)};

  border-radius: ${theme.borderRadius.giga};

  &:last-of-type {
    margin: 0;

    border: none;
  }
`

const titleContainerStyles = ({ theme }) => css`
  label: collapse__title-container;

  display: flex;
  justify-content: space-between;
  align-items: center;

  ${theme.media.mega} {
    cursor: pointer;
  }

  svg {
    flex-shrink: 0;
    margin-left: ${theme.spacings.byte};
  }
`

const contentStyles = ({ theme }) => css`
  label: collapse__content;

  margin-top: ${theme.spacings.kilo};

  color: ${theme.colors.n600};
`

const contentContainerStyles = () => css`
  label: collapse__content-container;

  will-change: max-height;

  overflow: hidden;

  transition: max-height 455ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
`

export const Wrapper = styled('div')(wrapperStyles)
export const TitleContainer = styled('div')(titleContainerStyles)
export const Content = styled('div')(contentStyles)
export const ContentContainer = styled('div')(contentContainerStyles)
