export const sortByFilter = (
  sortBy: string,
  numberFilters: number,
  originalUrl: string
): string => {
  const descPrice = sortBy?.includes('_price_desc')
  const ascPrice = sortBy?.includes('_price_asc')

  if (!descPrice && !ascPrice) {
    return originalUrl
  }

  const newPriceOrder = descPrice ? 'melhor-preco' : 'menor-preco'

  const existingOrderPattern = /(melhor-preco|menor-preco)/

  if (existingOrderPattern.test(originalUrl)) {
    return originalUrl.replace(existingOrderPattern, newPriceOrder)
  }

  switch (numberFilters) {
    case 0:
      return `${originalUrl}/${newPriceOrder}`
    case 1:
      return `${originalUrl}?order=${newPriceOrder}`
    default:
      return `${originalUrl}&order=${newPriceOrder}`
  }
}
