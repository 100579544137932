import { useEffect, useRef } from 'react'

const useRunOnce = (callback: () => void, condition: boolean) => {
  const hasAlreadyRun = useRef(false)

  useEffect(() => {
    if (condition && !hasAlreadyRun.current) {
      callback()
      hasAlreadyRun.current = true
    }
  }, [callback, condition])
}

export default useRunOnce
