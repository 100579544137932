import { BrowserRouter, Route } from 'react-router-dom'

import reactComponentLoader from 'scripts/react-component-loader'

import { EntryKey } from './'

const EntryKeyApp = dataset => {
  const {
    isBlackWeekend,
    personalizationTrack = '{}',
    apiData = null,
    ...rest
  } = dataset

  return (
    <BrowserRouter>
      <Route
        component={props => (
          <EntryKey
            isBlackWeekend={Boolean(isBlackWeekend)}
            personalizationTrack={JSON.parse(personalizationTrack)}
            apiData={JSON.parse(apiData)}
            {...rest}
            {...props}
          />
        )}
      />
    </BrowserRouter>
  )
}

reactComponentLoader(EntryKeyApp, '[data-entry-key]')
