import { formatters } from 'utils/formatters'

import { LoyaltyStatus } from 'lmcv/utils/LoyaltyStatus'

import { Constants } from '.'

const initialState = {
  get: {
    isRequest: true,
    payload: {},
    error: {}
  },
  loyaltyStatus: {}
}

export default function reducer (state = initialState, action = {}) {
  switch (action.type) {
    case Constants.LOYALTY_PROFILE_GET:
      return {
        ...state,
        get: {
          ...state.get
        }
      }

    case Constants.LOYALTY_PROFILE_GET_SUCCESS: {
      const loyaltyStatus = LoyaltyStatus(action.payload.status)
      const loyaltyPoints = action.payload.points?.actual || 0
      const loyaltyPointsFormatted = formatters.toDecimal(loyaltyPoints)

      return {
        ...state,
        get: {
          ...state.get,
          isRequest: false,
          payload: action.payload
        },
        loyaltyStatus,
        loyaltyPointsFormatted
      }
    }

    case Constants.LOYALTY_PROFILE_GET_FAILURE:
      return {
        ...state,
        get: {
          ...state.get,
          isRequest: false,
          error: action.error
        }
      }

    case Constants.LOYALTY_PROFILE_SET_PREFERENCES:
      return {
        ...state,
        get: {
          ...state.get
        }
      }

    case Constants.LOYALTY_PROFILE_SET_PREFERENCES_SUCCESS:
      return {
        ...state,
        get: {
          ...state.get,
          payload: { ...state.get.payload, hasPreferences: true }
        }
      }

    case Constants.LOYALTY_PROFILE_SET_PREFERENCES_FAILURE:
      return {
        ...state,
        get: {
          ...state.get,
          error: action.error
        }
      }

    default:
      return state
  }
}
