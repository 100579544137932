import styled from '@emotion/styled'
import { css } from '@emotion/react'

const wrapperStyles = ({ theme }) => css`
  label: step;

  display: flex;

  margin-bottom: ${theme.spacings.zetta};

  position: relative;

  ${theme.media.giga} {
    margin-bottom: 110px;
  }

  & > div {
    z-index: ${theme.zIndex.backdrop};
  }
`

const imageStepStyles = ({ theme }) => css`
  label: how-to__step__image;

  ${theme.media.untilKilo} {
    max-width: 120px;
  }

  ${theme.media.kilo} {
    max-width: 190px;
  }

  ${theme.media.mega} {
    max-width: auto;
  }
`

const textWrapperStyles = ({ theme }) => css`
  label: how-to__text-wrapper;

  ${theme.media.giga} {
    p {
      width: 160px;
    }
  }
`

export const Wrapper = styled('div')(wrapperStyles)
export const ImageStep = styled('img')(imageStepStyles)

export const TextWrapper = styled('div')(textWrapperStyles)
