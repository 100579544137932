import pluralize from 'utils/pluralize'

const StockList = ({ selectStore, lastUpdated, stores, unit, title, isLocateOnStoreEnabled }) => (
  <div className="col-xs-12">
    {title &&
      <h2 className="heading-4">{title}</h2>
    }

    {lastUpdated &&
      <small className="small color-default">Atualizado em {lastUpdated}</small>
    }

    {stores &&
      <ul className="list list-colored">
        {stores.map((store) => (
          <li key={ store.code } className="list-item col-xs-12 padding-y">
            <div className="store-name">
              <i className="glyph glyph-location icon-location"></i>{store.name}
            </div>
            <small className="small color-primary margin-bottom">
              {store.totalStock} {unit} {pluralize(store.totalStock, 'available')}
            </small>

            {isLocateOnStoreEnabled && store.code && selectStore && <button
              type="button"
              onClick={() => selectStore(store)}
              className="button-hollow button-hollow-primary"
            >
              Localizar na Loja
            </button>}
          </li>
        ))}
      </ul>
    }
  </div>
)

export default StockList
