import { useContext } from 'react'
import { Stack, Card } from '@leroy-merlin-br/backyard-react'

import { useUserResources } from 'shared/hooks'

import { EntryKeyContext } from '../../context'
import { StockFilter, AppliedFilters, FilterType } from '../'
import * as S from './styled'

const Filters = () => {
  const { filters, filterUsedOptions } = useContext(EntryKeyContext)
  const { userControls: { isAssistedSale } } = useUserResources()

  return (
    <S.Wrapper>
      <Stack space="bit">
        {filterUsedOptions?.length && (
          <Card target="card">
            <AppliedFilters />
          </Card>
        )}

        {isAssistedSale && (
          <Card target="card">
            <StockFilter />
          </Card>
        )}

        {filters.map((filter, index, array) => (
          <FilterType
            key={index}
            filterIndex={index}
            filterObject={filter}
            numberOfFilters={array.length}
          />
        ))}
      </Stack>
    </S.Wrapper>
  )
}

export default Filters
