import { useEffect, useState } from 'react'
import { Provider } from 'react-redux'
import { Text, Inline } from '@leroy-merlin-br/backyard-react'

import { store, useAppSelector } from 'newCheckout/store'
import {
  getAvailableStores,
  changeShipment
} from 'newCheckout/store/pickupInStore'

import { RadioCard } from '../RadioCard'
import { SelectStoreDrawer } from '../Drawer'
import { Truck, Cart } from './icons'
import * as S from './Style'
import type { Store } from '../../../../types/PickUpInStore'

type OnChangeParameters = {
  type: 'pickup-in-store' | 'delivery'
  storeId?: string | null
  availableStores?: Store[]
}

type ChangeCartTypeType = {
  cartId: string
  type: string
  onChange: (params: OnChangeParameters) => void
  storeId: string
  cep: string
  cart: object
}

const ChangeCartType = ({
  cartId,
  type,
  onChange,
  storeId,
  cep,
  cart
}: ChangeCartTypeType) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const availableStores = useAppSelector(
    state => state.pickUpInStore.data.stores
  )
  const isDeliveryAvailable = useAppSelector(
    state => state.pickUpInStore.data.deliveryAvailable
  )
  const pickUpInStoreStatus = useAppSelector(
    state => state.pickUpInStore.status
  )

  useEffect(() => {
    store.dispatch(getAvailableStores({ cartId }))
  }, [cartId, cep, cart])

  if (availableStores.length === 0) return null

  const isDelivery = type === 'delivery'

  const deliveryColor = isDelivery ? 'white' : 'black'

  return (
    <>
      <SelectStoreDrawer
        isDrawerOpen={isDrawerOpen}
        overlayClick={() => setIsDrawerOpen(false)}
        stores={pickUpInStoreStatus === 'loading' ? [] : availableStores}
        onChange={(data) => {
          onChange(data)
          setIsDrawerOpen(false)
        }}
        storeId={storeId}
        cartId={cartId}
      />
      <S.Wrapper>
        <RadioCard
          size="giga"
          name="selectCartType"
          value="delivery"
          title={
            <Text
              size="mega"
              color={isDeliveryAvailable ? deliveryColor : 'n200'}
              noMargin
            >
              <Inline space="kilo" alignY="center">
                <Truck
                  color={isDeliveryAvailable ? deliveryColor : '#D3D2D6'}
                />
                <span>Receber em casa</span>
              </Inline>
            </Text>
          }
          id="Receber em casa"
          isChecked={isDelivery}
          onClick={async () => {
            if (!isDeliveryAvailable) return
            await store.dispatch(
              changeShipment({ cartId, storeId: null, type: 'delivery' })
            )
            onChange({ type: 'delivery' })
          }}
          disable={!isDeliveryAvailable}
        />
        <RadioCard
          name="selectCartType"
          value="pickupInStore"
          size="giga"
          title={
            <Text size="mega" color={isDelivery ? 'n900' : 'white'} noMargin>
              <Inline space="kilo" alignY="center">
                <Cart color={isDelivery ? 'black' : 'white'} />
                <span>Retirar na loja</span>
              </Inline>
            </Text>
          }
          id="Retirar na loja"
          isChecked={!isDelivery}
          onClick={() => {
            setIsDrawerOpen(true)
          }}
        />
      </S.Wrapper>
    </>
  )
}

const ChangeCartTypeWithStore = ({
  cartId,
  type,
  onChange,
  storeId,
  cep,
  cart
}: ChangeCartTypeType) => (
  <Provider store={store}>
    <ChangeCartType
      cartId={cartId}
      type={type}
      storeId={storeId}
      onChange={onChange}
      cep={cep}
      cart={cart}
    />
  </Provider>
)

export default ChangeCartTypeWithStore
