import { useState, useEffect, useCallback } from 'react'

import { api, apiTypes } from 'frontendCheckout/services/api'
import { cache } from 'frontendCheckout/utils/cache'

const emptyState: apiTypes.Cart.getSummary.Summary = {
  appliedVouchers: [],
  appliedCoupon: null,
  appliedMoneyVouchers: [],
  moneyVouchersTotal: 0,
  total: 0,
  subTotal: 0,
  serviceTotal: 0,
  freight: 0,
  discount: 0,
  promiseDates: [],
  discountsByPaymentMethods: {
    credit_card: {
      totalPriceWithoutDiscount: 0,
      totalPrice: 0
    },
    bank_slip: {
      totalPriceWithoutDiscount: 0,
      totalPrice: 0
    },
    token: {
      totalPriceWithoutDiscount: 0,
      totalPrice: 0
    },
    pix: {
      totalPriceWithoutDiscount: 0,
      totalPrice: 0
    }
  },
  totalDiscountByType: {
    employee: {
      cheapestPaymentMethods: 'credit_card',
      totalDiscount: 0,
      totalValue: 0,
      totalInstallments: 0,
      totalDiscountsByType: 0,
      firstCheapestPaymentMethod: 'credit_card'
    },
    promo: {
      cheapestPaymentMethods: 'credit_card',
      totalDiscount: 0,
      totalValue: 0,
      totalInstallments: 0,
      totalDiscountsByType: 0,
      firstCheapestPaymentMethod: 'credit_card'
    },
    'payment-method': {
      cheapestPaymentMethods: 'credit_card',
      totalDiscount: 0,
      totalValue: 0,
      totalInstallments: 0,
      totalDiscountsByType: 0,
      firstCheapestPaymentMethod: 'credit_card'
    },
    loyaltyProgram: {
      cheapestPaymentMethods: 'credit_card',
      totalDiscount: 0,
      totalValue: 0,
      totalInstallments: 0,
      totalDiscountsByType: 0,
      firstCheapestPaymentMethod: 'credit_card'
    }
  },
  creditCardInstallmentsByBrand: {
    branded: [],
    default: []
  },
  availableCashbackTotal: 0,
  isSameDayDelivery: false,
  errors: []
}

export const useSummary = () => {
  const [summary, setSummary] =
    useState<apiTypes.Cart.getSummary.Summary>(emptyState)
  const [isLoading, setIsLoading] = useState(false)
  const [cancelRequest, setCancelRequest] = useState<(() => void) | null>(null)
  const [status, setStatus] = useState(0)

  useEffect(() => {
    return () => {
      if (cancelRequest) cancelRequest()
    }
  }, [])

  const getSummary = useCallback(
    async ({ cartId, params }: apiTypes.Cart.getSummary.props) => {
      try {
        if (cancelRequest) cancelRequest()

        setIsLoading(true)

        const key = JSON.stringify({ key: 'GET_SUMMARY', cartId, params })

        const CACHE_DURATION_IN_SECONDS = 5

        if (cache.has(key) && !cache.isExpired(key, CACHE_DURATION_IN_SECONDS)) {
          const { value, status } = cache.get(key)
          setSummary({ ...value.data })
          setStatus(status)
          setIsLoading(false)
          return
        }

        const { cancel, response } = api.cart.getSummary({ cartId, params })
        setCancelRequest(() => cancel)

        const { value, status } = await response
        cache.set(key, { value, status })

        setSummary({ ...value.data })
        setStatus(status)

        setIsLoading(false)
      } catch (e) {
        setSummary(emptyState)
        setIsLoading(false)
        setStatus(-1)
      }
    },
    []
  )

  return { summary, getSummary, isLoading, status }
}
