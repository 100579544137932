export const buildText = ({ isAssistedSale, hidePrice }) => {
  const noPrice = hidePrice && isAssistedSale
  const unavaliableProduct = !hidePrice || !isAssistedSale

  return {
    title: noPrice ? 'Produto sem preço :(' : 'Produto indisponível',
    message: unavaliableProduct
      ? 'No momento, não temos esse produto disponível em nossos estoques.'
      : ''
  }
}
