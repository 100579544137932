import { Button, Text } from '@leroy-merlin-br/backyard-react'
import { Spacer } from '@backyard-ui/core'
import { FC, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { Shell } from 'shared/components'

import { AddressEmptyIcon } from '../../icons'
import { getProfile } from '../../services'
import { Address, ProfileResponse } from '../../types'
import { AddressCard } from '../AddressCard'
import * as S from './styled'

const Addresses: FC = () => {
  const history = useHistory()

  const [addresses, setAddresses] = useState<Address[]>([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    (async () => {
      const profile: ProfileResponse = await getProfile()

      if (profile.data.addresses.length > 0) {
        setAddresses(profile.data.addresses)
      }

      setLoading(false)
    })()
  }, [])

  return (
    <S.Wrapper isEmpty={!addresses.length}>
      {loading ? (
        <S.List>
          <Shell borderRadius="giga" height={214} width={287} />

          <Shell borderRadius="giga" height={214} width={287} />

          <Shell borderRadius="giga" height={214} width={287} />
        </S.List>
      ) : (
        <>
          {
            addresses.length ? (
              <S.List>
                {addresses.filter(address => address.main).map(address => (
                  <AddressCard data={address} />
                ))}

                {addresses.filter(address => !address.main).map(address => (
                  <AddressCard data={address} />
                ))}
              </S.List>
            ) : (
              <S.Empty>
                <AddressEmptyIcon width={180}/>
                <Spacer />
                <Text size="mega" color="p600" isBold noMargin>
                  Sem endereço cadastrado
                </Text>
              </S.Empty>
            )
          }
          <S.ButtonWrapper isEmpty={!addresses.length}>
            <Button onClick={() => history.push('/adicionar-endereco')}>
              Adicionar novo endereço
            </Button>
          </S.ButtonWrapper>
        </>
      )}
    </S.Wrapper>
  )
}

export default Addresses
