import { css } from '@emotion/react'
import styled from '@emotion/styled'

const inputsWrapperStyles = ({ theme }) => css`
  label: inputs-wrapper;

  margin: ${theme.spacings.giga} 0;
`

const regionSelectStyles = () => css`
  label: region-select;

  position: relative;
`

const wrapperStyles = () => css`
  label: wrapper;

  & > div {
    text-align: center;

    & > div:last-of-type {
      overflow-y: unset;
    }
  }
`

const titleStyles = ({ theme }) => css`
  label: title;

  font-size: ${theme.typography.headings.tera.fontSize};
  line-height: ${theme.typography.headings.tera.lineHeight};
  font-weight: ${theme.font.weight.bold};
`

const wrapperLocationInfoStyles = () => css`
  label: wrapper-location-info;

  display: flex;
  align-items: flex-start;
  justify-content: center;

  svg {
    align-self: center;
    width: 11px;
    height: 11px;
  }
`

const clearButtonStyles = ({ theme }) => css`
  label: city-input__clear-button;

  position: absolute;
  right: ${theme.spacings.mega};

  cursor: pointer;
`

export const RegionSelect = styled('div')(regionSelectStyles)
export const InputsWrapper = styled('div')(inputsWrapperStyles)
export const Wrapper = styled('div')(wrapperStyles)
export const Title = styled('strong')(titleStyles)
export const WrapperLocationInfo = styled('div')(wrapperLocationInfoStyles)
export const ClearButton = styled('button')(clearButtonStyles)
