import { useEffect, useState } from 'react'
import { debounce } from 'lodash'

import { handleSpinner } from 'site/Cart/services'

import { Quantity } from 'checkout/components/Quantity'

import { updateServiceQuantity } from './actions'

type changeServiceQuantityProps = {
  id: number
  prevQuantity: number
  quantity: number
  regionId: string
  callback: () => void
}

const changeServiceQuantity = debounce(
  async ({
    id,
    prevQuantity,
    quantity,
    regionId,
    callback
  }: changeServiceQuantityProps) => {
    handleSpinner({ action: 'show', useSpinner: true })
    await updateServiceQuantity({ id, prevQuantity, quantity, regionId })
    callback()
  },
  500
)

type ServiceQuantityProps = {
  id: number
  quantity: number
  regionId: string
  callback: () => void
}

export const ServiceQuantity = ({
  quantity,
  callback,
  id,
  regionId
}: ServiceQuantityProps) => {
  const [currentQuantity, setCurrentQuantity] = useState(quantity)

  useEffect(() => {
    setCurrentQuantity(quantity)
  }, [quantity])

  return (
    <Quantity
      handleInputChange={(value) => {
        const newQuantity = value || quantity
        setCurrentQuantity(newQuantity)
        changeServiceQuantity({
          id: id,
          prevQuantity: quantity,
          quantity: newQuantity,
          regionId,
          callback
        })
      }}
      updateQuantity={({ value }) => {
        if (currentQuantity + value <= 0) return

        setCurrentQuantity((currentQuantity) => currentQuantity + value)
        changeServiceQuantity({
          id: id,
          prevQuantity: quantity,
          quantity: currentQuantity + value,
          regionId,
          callback
        })
      }}
      packaging={1}
      packagingCount={currentQuantity}
      productMaxStock={Infinity}
      useFormatQuantity={currentQuantity}
    />
  )
}
