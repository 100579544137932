import styled from '@emotion/styled'
import { css } from '@emotion/react'

const moduleContentStyles = () => css`
  label: module__content;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`

const moduleAvatarStyles = ({ theme }) => css`
  label: module__avatar;

  align-items: center;
  background: ${theme.colors.n400};
  border-radius: ${theme.borderRadius.circle};
  color: ${theme.colors.n700};
  display: flex;
  font-size: ${theme.typography.headings.exa.fontSize};
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  margin-right: ${theme.spacings.giga};
  height: 75px;
  width: 75px;
`

const moduleWrapperStyles = () => css`
  label: module__wrapper;

  align-items: center;
  display: flex;
`

const dividerWrapperStyles = ({ theme }) => css`
  label: divider-wrapper;

  border-right: 1px solid ${theme.colors.n200};
  margin-right: ${theme.spacings.mega};
`

const textWrapperStyles = () => css`
  label: text__wrapper;

  display: block;
`

const statusWrapperStyles = ({ theme }) => css`
  label: status-wrapper;

  display: flex;
  align-items: center;

  &::before {
    background: ${theme.colors.p500};
    border-radius: ${theme.borderRadius.circle};
    content: '';
    display: inline-block;
    margin-right: ${theme.spacings.bit};
    height: ${theme.spacings.byte};
    width: ${theme.spacings.byte};
  }
`

const buttonIconStyles = ({ theme }) => css`
  label: button__icon;

  display: none;

  ${theme.media.mega} {
    color: ${theme.colors.p500};
    font-size: ${theme.typography.text.giga.fontSize};
    margin-left: auto;
    cursor: pointer;
    outline: 0;
    display: block;
  }
`
export const ModuleContent = styled('div')(moduleContentStyles)
export const ModuleAvatar = styled('span')(moduleAvatarStyles)
export const ModuleWrapper = styled('div')(moduleWrapperStyles)
export const DividerWrapper = styled('div')(dividerWrapperStyles)
export const TextWrapper = styled('div')(textWrapperStyles)
export const StatusWrapper = styled('div')(statusWrapperStyles)
export const ButtonIcon = styled('div')(buttonIconStyles)
