import { SVGProps, memo } from 'react'

export const EmailIcon = memo((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={29}
    height={23}
    fill="none"
    {...props}
  >
    <path
      fill="#222020"
      fillRule="evenodd"
      d="M4.296 22.45h20.408c2.37 0 4.296-1.876 4.296-4.181V4.679C29 2.371 27.073.495 24.704.495H4.296C1.927.496 0 2.372 0 4.678v13.59c0 2.306 1.927 4.182 4.296 4.182ZM2.148 4.678c0-1.153.964-2.09 2.148-2.09h20.408c1.184 0 2.148.937 2.148 2.09v13.59c0 1.154-.964 2.092-2.148 2.092H4.296c-1.184 0-2.148-.938-2.148-2.091V4.679Z"
      clipRule="evenodd"
    />
    <path
      fill="#222020"
      fillRule="evenodd"
      d="M23.705 18.537c-.237 0-.468-.082-.65-.23l-7.136-5.8-1.147.933a1.032 1.032 0 0 1-1.3 0l-1.147-.932-7.136 5.8a1.034 1.034 0 0 1-1.422-.117.952.952 0 0 1 .122-1.373l6.87-5.584-6.87-5.583a.952.952 0 0 1-.122-1.372 1.032 1.032 0 0 1 1.422-.118l8.933 7.261 8.933-7.26a1.03 1.03 0 0 1 .65-.23c.298 0 .58.126.771.346a.952.952 0 0 1-.121 1.373l-6.87 5.584 6.87 5.583c.425.346.48.962.122 1.372-.193.22-.474.347-.772.347Z"
      clipRule="evenodd"
    />
  </svg>
))

export const SMSIcon = memo((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={33}
    fill="none"
    {...props}
  >
    <path
      fill="#000"
      fillRule="evenodd"
      d="M6.533 3.167a3.867 3.867 0 0 0-3.866 3.866v8A3.867 3.867 0 0 0 6.533 18.9h.134v3.41a3.867 3.867 0 0 0 3.866 3.867h11.94c.275 0 .537.113.725.313l1.663 1.767c1.51 1.605 4.206.536 4.206-1.668V13.7A3.867 3.867 0 0 0 25.2 9.833h-.133v-2.8A3.867 3.867 0 0 0 21.2 3.167H6.533Zm16.134 6.666v-2.8c0-.81-.657-1.466-1.467-1.466H6.533c-.81 0-1.466.656-1.466 1.466v8c0 .81.656 1.467 1.466 1.467h.134v-2.8a3.867 3.867 0 0 1 3.866-3.867h12.134ZM9.067 13.7c0-.81.656-1.467 1.466-1.467H25.2c.81 0 1.467.657 1.467 1.467V26.59c0 .009-.001.014-.002.017v.002a.06.06 0 0 1-.02.012.058.058 0 0 1-.022.005l-.002-.001a.108.108 0 0 1-.012-.012l-1.663-1.766a3.396 3.396 0 0 0-2.473-1.07h-11.94c-.81 0-1.466-.656-1.466-1.466V13.7Z"
      clipRule="evenodd"
    />
  </svg>
))
