import {
  UserOutline,
  CalendarBlankOutline,
  MoneyOutline,
  LocationOutline,
  FileAltOutline
} from '@backyard-ui/icons'

import { BasicForm } from './components/BasicForm'
import { InvoiceForm } from './components/InvoiceForm'
import { AdditionalDataForm } from './components/AdditionalDataForm'
import { AddressForm } from './components/AddressForm'
import { IncomeForm } from './components/IncomeForm'

export const FORMS_NAMES = {
  basic: 'basic',
  complement: 'complement',
  address: 'address',
  income: 'income',
  invoice: 'invoice'
}

export const STEPS = [
  {
    icon: UserOutline,
    label: 'Básico',
    title: FORMS_NAMES.basic,
    Component: BasicForm
  },
  {
    icon: FileAltOutline,
    label: 'Complementar',
    title: FORMS_NAMES.complement,
    Component: AdditionalDataForm
  },
  {
    icon: LocationOutline,
    label: 'Endereço',
    title: FORMS_NAMES.address,
    Component: AddressForm
  },
  {
    icon: MoneyOutline,
    label: 'Renda',
    title: FORMS_NAMES.income,
    Component: IncomeForm
  },
  {
    icon: CalendarBlankOutline,
    label: 'Fatura',
    title: FORMS_NAMES.invoice,
    Component: InvoiceForm
  }
]

export const FORM_DEFAULT_VALUES = {
  basic: {
    fiscalId: '',
    email: '',
    name: '',
    phone: '',
    birthdate: '',
    postalCode: ''
  },
  complement: {
    gender: '',
    maritalStatus: '',
    birthData: {
      city: '',
      state: '',
      country: ''
    },
    document: {
      number: '',
      emissionDate: '',
      type: ''
    },
    motherName: ''
  },
  address: {
    address: {
      city: '',
      complement: '',
      district: '',
      state: '',
      street: '',
      streetNumber: '',
      postalCode: ''
    }
  },
  invoice: {
    dueDay: '',
    optins: {
      emergencyCredit: false,
      purchaseInsurance: false
    }
  },
  income: {
    equityValue: 0,
    occupation: {
      cap: '',
      income: ''
    }
  }
}
