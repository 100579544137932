export const formatPostalCode = postalCode => {
  return postalCode.replace(/\D/g, '').replace(/(\d{5})(\d{3})(.?)/, '$1-$2')
}

export const checkZipCodeState = (postalCode, isLoading = false, errors = null) => {
  const isNeutralZipCode = !postalCode || postalCode.length === 0
  const hasMinimumCharZipCode = postalCode.length < 8
  const isValidZipCode = postalCode && !errors
  const isZipCodeNotFound = errors?.invalid_cep || errors?.invalid_address

  if (isLoading) {
    return {
      state: null,
      hint: 'Buscando...'
    }
  }

  if (isNeutralZipCode) {
    return {
      state: null,
      hint: 'Ex: 00000-000'
    }
  }

  if (hasMinimumCharZipCode) {
    return {
      state: 'invalid',
      hint: 'Mínimo de 9 caracteres'
    }
  }

  if (isValidZipCode) {
    return {
      state: 'valid',
      hint: 'Cep válido'
    }
  }

  if (isZipCodeNotFound) {
    return {
      state: 'invalid',
      hint: 'CEP não encontrado'
    }
  }

  return {}
}
