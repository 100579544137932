export const cheapestModalityOption = (modalities) => {
  if (!modalities) return null

  const modalitiesArray = Object.keys(modalities)

  return modalitiesArray.reduce((cheapest, current, index) => {
    if (index === 0 || cheapest.cost > modalities[current].cost) {
      const cheaper = { ...modalities[current] }
      cheaper.modality = current

      return cheaper
    }

    return cheapest
  }, {})
}
