import styled from '@emotion/styled'
import { css } from '@emotion/react'

const thumbnailInfoStyles = ({ theme }) => css`
  label: thumbnail__info;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  padding: ${theme.spacings.kilo} ${theme.spacings.mega};

  position: absolute;
  top: 44px;

  max-height: 40%;
`

const titleLinkWrapper = ({ theme }) => css`
  label: title__link;

  height: inherit;
  margin-bottom: ${theme.spacings.mega};
`

export const ThumbnailInfo = styled('div')(thumbnailInfoStyles)
export const TitleLink = styled('a')(titleLinkWrapper)
