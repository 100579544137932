import { Text } from '@leroy-merlin-br/backyard-react'
import { useMyOrdersContext } from 'frontendMyOrders/context/MyOrdersContext'

import { Shift } from '../Shift'
import { ShippingInfo } from '../ShippingInfo'
import * as S from './styled'

type HeaderProps = {
  type: string
  quantity: number
  deliveryDate: {
    date: string
  }
  shift: string
  isStandaloneService: boolean
}

const Header = ({
  type,
  quantity,
  deliveryDate,
  shift,
  isStandaloneService
}: HeaderProps) => {
  const { plugins } = useMyOrdersContext()

  const { MarketplaceChat } = plugins

  return (
    <S.Wrapper>
      <S.Type>
        <Text as="strong" size="mega" isBold noMargin color="g700">
          {type}
        </Text>
      </S.Type>

      <S.Quantity>
        <Text size="kilo" noMargin>
          Itens
        </Text>
        <Text as="strong" size="kilo" isBold noMargin>
          {quantity}
        </Text>
      </S.Quantity>

      <S.Shipping>
        <ShippingInfo
          type={type}
          date={deliveryDate?.date}
          isStandaloneService={isStandaloneService}
        />
      </S.Shipping>

      {MarketplaceChat && <MarketplaceChat />}

      <S.Shift>{shift && <Shift type={type} shift={shift} />}</S.Shift>
    </S.Wrapper>
  )
}

export default Header
