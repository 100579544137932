import BaseTracking from './base-tracking'

class RichRelevanceProductTracking extends BaseTracking {
  static componentName = 'rich-relevance-product-tracking'

  sendItems (data, R3_COMMON) {
    if (data.categories) {
      data.categories.forEach((category) => {
        R3_COMMON.addCategoryHintId(category)
      })
    }

    const R3_ITEM = new r3_item() // eslint-disable-line
    R3_ITEM.setId(data.productId)
    R3_ITEM.setName(data.productName)

    return R3_ITEM
  }

  getDynamicExperienceItems (data) {
    const r3CommonObject = {}

    if (data.categories) {
      data.categories.forEach(category => {
        r3CommonObject.categoryId = category
      })
    }

    r3CommonObject.productId = data.productId
    r3CommonObject.fpb = data.productBrand

    return r3CommonObject
  }
}

export default ($el) => new RichRelevanceProductTracking($el).init()

export { RichRelevanceProductTracking as Component }
