export const ERRORS = {
  CEP: [
    'quotation_invalid_region',
    'invalid_cep',
    'invalid_address'
  ],
  SERVICE: [
    'service_unavailable_for_the_region'
  ]
}
