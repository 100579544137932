import { Card, Text } from '@leroy-merlin-br/backyard-react'

import { CreditCardIcons } from 'newCheckout/images/creditCard'

import * as S from './styled'
import { CreditCardVisualType } from './type'

const CreditCard = ({ paymentMethod }: CreditCardVisualType) => {
  const Icon = CreditCardIcons[paymentMethod.brand] || CreditCardIcons.default

  return (
    <Card>
      <Text size="giga" isBold>Dados de pagamento</Text>
      <S.Panel>
        <S.Logo><Icon /></S.Logo>
        <Text size="mega" isBold noMargin>{paymentMethod.brand.toUpperCase()} (Crédito)</Text>
        <Text color="n500" size="mega" noMargin>****{paymentMethod.last_digits_card_number}</Text>
        <S.Value>{paymentMethod.amount_installment} x R$ {paymentMethod.installment_value} </S.Value>
      </S.Panel>
    </Card>
  )
}

export default CreditCard
