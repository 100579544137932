import { FC } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import { SocialSignUp } from '../pages/SocialSignUp'
import { View } from '../types'

const Routes: FC<View> = (props) => (
  <BrowserRouter basename={`social/${props.provider}/cadastre-se`}>
    <Switch>
      <Route
        exact
        path="/"
        component={() => <SocialSignUp {...props} />}
      />
    </Switch>
  </BrowserRouter>
)

export default Routes
