import styled from '@emotion/styled'
import { css } from '@emotion/react'
import isPropValid from '@emotion/is-prop-valid'

import { type Theme } from 'scripts/react-components/themes/Theme'

type NavItemActive = {
  isActive: boolean
}

type NavItemHasSubmenu = {
  hasSubMenu: boolean
}

const navItemStyles = ({ theme }: Theme) => css`
  label: nav-item;

  margin-bottom: ${theme.spacings.bit};
`

const linkStyles = ({ theme }: Theme) => css`
  label: nav-item__link;

  position: relative;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: ${theme.spacings.byte};
  align-items: center;

  padding: ${theme.spacings.byte};
  padding-left: ${theme.spacings.kilo};

  font-size: ${theme.typography.text.mega.fontSize};
  line-height: ${theme.typography.text.mega.lineHeight};
  font-weight: ${theme.font.weight.regular};
  color: ${theme.colors.n700};

  transition: color ${theme.transition.leave} ease-in-out;

  border-radius: 0 ${theme.spacings.byte} ${theme.spacings.byte} 0;

  & a {
    display: flex;
    align-items: center;
  }

  & a > span {
    margin-left: ${theme.spacings.byte};
  }

  & a > svg {
    margin-top: 1px;
    width: 16px;
  }

  & > svg {
    label: nav-item__link-icon;

    margin-top: 1px;
    width: 16px;
  }
`

const linkActiveStyles = ({ theme, isActive }: Theme & NavItemActive) =>
  isActive &&
  css`
    label: nav-item__link--active;

    color: ${theme.colors.n900};
    background-color: ${theme.colors.background};
    font-weight: ${theme.font.weight.semibold};

    & a > span {
      font-weight: ${theme.font.weight.semibold};
      color: ${theme.colors.n900};
    }

    & a > svg {
      color: ${theme.colors.p600};
    }

    &::before {
      content: '';
      display: block;
      position: absolute;

      height: 100%;
      width: 2px;

      left: -${theme.spacings.bit};
      top: 0px;

      background-color: ${theme.colors.p600};
    }
  `

const linkWithSubMenuStyles = ({ theme, hasSubMenu }: Theme & NavItemHasSubmenu) =>
  hasSubMenu &&
  css`
    label: nav-item__link--with-submenu;

    grid-template-columns: auto 1fr auto;
    padding-right: ${theme.spacings.kilo};
  `

const dropdownButtonStyles = ({ theme }: Theme) => css`
  label: nav-item__dropdown-icon;

  align-items: center;
  justify-content: center;

  height: 20px;
  width: 20px;
  margin-left: ${theme.spacings.mega};

  cursor: pointer;

  svg {
    width: 8px;

    color: ${theme.colors.n900};
    margin: 0px;
  }
`

const subMenuStyles = ({ theme }: Theme) => css`
  label: nav-item__submenu;
  position: relative;

  & > a {
    padding-left: ${theme.spacings.exa};
  }

  &::before {
    content: '';
    display: block;
    position: absolute;

    height: calc(100% + 10px);
    width: 2px;

    left: 19px;
    top: -5px;

    background-color: ${theme.colors.n100};

    border-radius: ${theme.borderRadius.tera};

    z-index: 1;
  }
`

export const NavItem = styled('div')(navItemStyles)
export const Link = styled('a', {
  shouldForwardProp: (prop: string) => isPropValid(prop)
})(linkStyles, linkActiveStyles, linkWithSubMenuStyles)
export const DropdownButton = styled('button')(dropdownButtonStyles)
export const Submenu = styled('nav')(subMenuStyles)
export const LinkWrapper = styled('div')(
  linkActiveStyles,
  linkStyles,
  linkWithSubMenuStyles
)
