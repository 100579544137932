import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { styleHelpers } from '@leroy-merlin-br/backyard-react'

const wrapperStyles = ({ theme }) => css`
  label: buybox__wrapper;

  padding: 0 ${theme.spacings.byte};
  margin-top: ${theme.spacings.mega};

  ${theme.media.mega} {
    padding: 0;
  }
`

const optionLabelStyles = ({ theme }) => css`
  label: buybox__label;

  background: ${theme.colors.n100};

  cursor: pointer;

  color: ${theme.colors.n600};

  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: space-between;

  position: relative;

  padding: ${theme.spacings.kilo} ${theme.spacings.byte} ${theme.spacings.kilo}
    42px;

  transition: background ${theme.transition.leave} ease-in-out;

  &::before {
    content: '';

    position: absolute;
    left: ${theme.spacings.kilo};
    top: calc(${theme.spacings.mega} + 2px);

    height: 18px;
    width: 18px;

    background: ${theme.colors.white};
    box-shadow: 0 0 0 1px ${theme.colors.n400};

    border: ${theme.spacings.bit} solid;
    border-color: ${theme.colors.white};
    border-radius: ${theme.borderRadius.circle};

    transition: background 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  }
`

const optionLabelDisabledShippingStyles = ({ theme, isPostalCodeEnabled }) =>
  !isPostalCodeEnabled &&
  css`
    label: buybox__label--disabled-shipping;

    padding: ${theme.spacings.mega} ${theme.spacings.byte}
      ${theme.spacings.mega} 42px;

    &::before {
      top: unset;
    }
  `

const optionLabelSelectedStyles = ({ theme, isSelected }) =>
  isSelected &&
  css`
    label: buybox__label--selected;

    background: ${theme.colors.p100};
    color: ${theme.colors.n900};

    &:before {
      background: ${theme.colors.n800};
      border-color: ${theme.colors.p200};
      box-shadow: 0 0 0 1px ${theme.colors.n800};
    }
  `

const optionPriceValueStyles = ({ theme }) => css`
  label: buybox__price__value;

  ${styleHelpers.headingGiga(theme)};
  font-weight: ${theme.font.weight.bold};
`

const optionSellerStyles = () => css`
  label: buybox__seller;

  width: 88px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const outletBadgeStyles = ({ theme }) => css`
  label: buybox__badge--outlet;

  & > div {
    background-color: ${theme.colors.black};
    color: ${theme.colors.white};
  }

`

export const Wrapper = styled('section')(wrapperStyles)
export const OptionLabel = styled('label')(
  optionLabelStyles,
  optionLabelDisabledShippingStyles,
  optionLabelSelectedStyles
)
export const OptionPriceValue = styled('span')(optionPriceValueStyles)
export const OptionSeller = styled('div')(optionSellerStyles)
export const OutletBadgeWrapper = styled('div')(outletBadgeStyles)
