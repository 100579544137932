import qs from 'qs'

/**
 * Image Resizing is a feature from Cloudflare
 * The resizing and optimizations are made through a worker with query params passed in url
 * https://developers.cloudflare.com/images/image-resizing/resize-with-workers
 */

export const DEFAULT_BREAKPOINTS = {
  480: 480,
  768: 768,
  1180: 1180
}

export const ALLOWED_OPTIONS = [
  'width',
  'height',
  'dpr',
  'fit',
  'gravity',
  'quality',
  'format',
  'anim',
  'sharpen',
  'blur',
  'background',
  'rotate',
  'metadata'
]

export const isImageResizingEnabled = window?.env.feature.isImageResizingEnabled

const buildOptionsQuery = options =>
  qs.stringify(options, { filter: ALLOWED_OPTIONS })

export const buildImageUrl = (url, options) => {
  if (!isImageResizingEnabled) {
    return url
  }

  const optionsQuery = buildOptionsQuery(options)

  return `${url}?${optionsQuery}`
}

export const buildSrcSet = (
  url,
  options,
  sizes = DEFAULT_BREAKPOINTS
) => {
  if (!isImageResizingEnabled) {
    return url
  }

  const srcSet = Object.keys(sizes).reduce((query, size) => {
    const imageUrl = buildImageUrl(url, {
      ...options,
      width: sizes[size],
      height: undefined
    })

    return [...query, `${imageUrl} ${sizes[size]}w`]
  }, [])

  return srcSet.join(', ')
}

export const buildSizes = (sizes = DEFAULT_BREAKPOINTS, slot) => {
  if (!isImageResizingEnabled || !slot) {
    return
  }

  const getValue = value => (typeof value === 'number' ? `${value}px` : value)

  const breakpoints = Object.keys(sizes)

  const result = breakpoints.reduce((query, breakpoint) => {
    return [
      ...query,
      `(max-width: ${breakpoint}px) ${getValue(sizes[breakpoint])}`
    ]
  }, [])

  return `${result.join(', ')}, ${getValue(slot)}`
}
