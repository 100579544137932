import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { GenericCarousel } from 'shared/components/GenericCarousel'

const bulletStyles = ({ theme }) =>
  css`
    label: full-banner-carousel__bullet;

    .swiper-pagination-bullet-active {
      background: ${theme.colors.p500};
    }
  `

export const Carousel = styled(GenericCarousel)(bulletStyles)
