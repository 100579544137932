import styled from '@emotion/styled'
import { css } from '@emotion/react'

const dividingLineStyles = ({ theme }) => css`
  label: separating-edge;

  position: absolute;
  width: 100%;
  height: 1px;
  background-color: ${theme.colors.n100};
  left: 0;
`

export const DividingLine = styled('span')(dividingLineStyles)
