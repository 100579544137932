import { getBoitataFacetsAndFilters } from '../../../routerProxyCategory'

export const typeBoitataFacetsFilter = (
  key: string,
  type: Array<string>,
  urlToConcatenate: string,
  numberFilter: number
): string => {
  const { filters: FILTER_TO_RENDER } = getBoitataFacetsAndFilters()

  const typeFilter = FILTER_TO_RENDER[key].toLowerCase()

  type.reverse().forEach((value, index) => {
    const formattedValue = value.includes(' ')
      ? value.replace(/ /g, '_')
      : value

    const finalValue =
      formattedValue.charAt(0).toUpperCase() + formattedValue.slice(1)

    if (!urlToConcatenate.includes(finalValue)) {
      if (index === 0 && numberFilter === 0) {
        urlToConcatenate += '/' + typeFilter + '/' + finalValue
      } else if (numberFilter === 1) {
        urlToConcatenate += '?filter[' + typeFilter + ']=' + finalValue
      } else if (numberFilter > 1) {
        urlToConcatenate += '&filter[' + typeFilter + ']=' + finalValue
      }
    }
  })

  return urlToConcatenate
}
