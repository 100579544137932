import { combineReducers } from 'redux'

import { Reducer as UserProfile } from './modules/userProfile'
import { Reducer as LoyaltyProfile } from './modules/loyaltyProfile'
import { Reducer as Professional } from './modules/professional'
import { Reducer as Coupon } from './modules/coupon'
import { Reducer as Customers } from './modules/customers'
import { Reducer as Module } from './modules/module'
import { Reducer as Notification } from './modules/notification'

export default () =>
  combineReducers({
    userProfile: UserProfile,
    loyaltyProfile: LoyaltyProfile,
    professional: Professional,
    coupon: Coupon,
    customers: Customers,
    module: Module,
    notification: Notification
  })
