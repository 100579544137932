import styled from '@emotion/styled'
import { css } from '@emotion/react'

const drawerStyles = ({ theme }) => css`
  label: drawer;

  height: 100%;
  padding: ${theme.spacings.giga} ${theme.spacings.mega};
  overflow-x: hidden;
`
const lineStyles = ({ theme }) => css`
  label: drawer__line;
  width: 100%;
  border-top: 2px dashed ${theme.colors.n100};
`

export const Drawer = styled('div')(drawerStyles)
export const Line = styled('div')(lineStyles)
