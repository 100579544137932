import * as dataLayer from 'scripts/utils/data-layer'

const LoggedOutBox = () => {
  const redirectUrl = target => {
    const currentUrl = encodeURIComponent(window.location.href)
    window.location = `/${target}?redirect=${currentUrl}`
  }

  const goToSignUp = () => {
    dataLayer.accountPopoverSignupLink()
    redirectUrl('cadastre-se')
  }

  const sendDataLayerAndRedirect = () => {
    dataLayer.signInLoginClick()

    redirectUrl('login')
  }

  const socialLoginClick = (loginType, url) => {
    dataLayer.socialLoginButtonClick(loginType)

    redirectUrl(url)
  }

  return (
    <div className="my-account-box">
      <div className="user-social">
        <button
          type="button"
          onClick={() => socialLoginClick('sociallogin-facebook', 'login/facebook')}
          className="user-social-button user-social-button-facebook"
        >
          Iniciar sessão com o Facebook
        </button>
        <button
          type="button"
          onClick={() => socialLoginClick('sociallogin-google', 'login/google')}
          className="user-social-button user-social-button-google"
        >
          Iniciar sessão com o Google
        </button>

        <button
          type="button"
          onClick={() => socialLoginClick('sociallogin-apple', 'login/apple')}
          className="user-social-button user-social-button-apple"
        >
          Iniciar sessão com a Apple
        </button>
        <span className="user-social-spacer">ou</span>
      </div>

      <div className="user-login">
        <button
          type="button"
          onClick={sendDataLayerAndRedirect}
          className="user-login-button"
        >
          Entrar
        </button>
        <span className="user-register user-social-link">
          Cliente novo?{' '}
          <a
            onClick={() => {
              goToSignUp()
            }}
          >
            Cadastre-se
          </a>
        </span>
      </div>

      <div className="user-options">
        <a href="/minha-conta">Minha conta</a>
        <span className="spacing"></span>
        <a href="/minha-conta">Meus pedidos</a>
      </div>
    </div>
  )
}

export default LoggedOutBox
