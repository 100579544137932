import styled from '@emotion/styled'
import { css } from '@emotion/react'

const iconStyles = () => css`
  label: icon;

  display: block;
`

const Icon = styled('svg')(iconStyles)

export default Icon
