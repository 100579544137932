import styled from '@emotion/styled'
import { css } from '@emotion/react'

const titleWrapperStyles = ({ theme }) => css`
  label: card-title-wrapper;

  & > p:first-of-type {
    font-size: ${theme.typography.headings.giga.fontSize};
  }
`

const instructionWrapperStyles = ({ theme }) => css`
  label: instruction-wrapper;

  & > div:last-child {
    margin-top: ${theme.spacings.mega};
  }
`

const buttonsWrapperStyles = ({ theme }) => css`
  label: buttons-wrapper;

  display: flex;
  gap: ${theme.spacings.kilo};

  a {
    text-decoration: none;
  }
`

export const TitleWrapper = styled('div')(titleWrapperStyles)
export const InstructionWrapper = styled('div')(instructionWrapperStyles)
export const ButtonsWrapper = styled('div')(buttonsWrapperStyles)
