import React, { useEffect } from 'react'
import type { ReactNode } from 'react'
import { useInstantSearch, useSearchBox } from 'react-instantsearch'

import { useSearchParams } from 'shared/hooks'

import { NotFound } from './pages/not-found'
import { Loading } from './loading'
import { IS_ON_MOBILE_APP } from './constants'
import { IS_CATEGORY } from './utils/constants'

interface LayoutProps {
  children: ReactNode
}

export function Layout (props: LayoutProps) {
  const { children } = props

  const { status, results } = useInstantSearch()

  const { refine } = useSearchBox()

  const query = useSearchParams()

  const isFetching = status === 'stalled' || (!IS_CATEGORY && !results?.query)

  useEffect(() => {
    if (IS_ON_MOBILE_APP) {
      refine(query.term)
    }
  }, [query.term, refine])

  if (isFetching) {
    return <Loading />
  }

  if (!results.__isArtificial && results.nbHits === 0) {
    return <NotFound />
  }

  return <React.Fragment>{children}</React.Fragment>
}
