import { useState, createContext, useContext, useEffect, useMemo } from 'react'
import { getExtraInfo, getProfile } from 'user/celebre/services'
import { SELECTS_NAMES } from 'user/celebre/constants'
import { useModal } from '@leroy-merlin-br/backyard-react'
import { ErrorModal } from 'user/celebre/components'

import { FORMS_NAMES } from '../pages/CardRequest/constants'

export const CardRequestContext = createContext()

export const CardRequestContextProvider = ({ children }) => {
  const { setModal } = useModal()

  const [userData, setUserData] = useState()
  const [isLoadingUserData, setIsLoadingUserData] = useState(false)
  const [userDataError, setUserDataError] = useState()
  const [selectOptions, setSelectOptions] = useState({})
  const [isLoadingSelectOptions, setIsLoadingSelectOptions] = useState(true)
  const [showExitConfirm, setShowExitConfirm] = useState(true)
  const [activeStep, setActiveStep] = useState(FORMS_NAMES.basic)

  const parseOptions = values => {
    const options = values.map(({ code, cap, name, description }) => {
      const value = code || cap
      const label = name || description

      return { value, label }
    })

    return options
  }

  const beforeUnloadListener = event => {
    event.preventDefault()
    return (event.returnValue = 'Before unload')
  }

  useEffect(() => {
    if (showExitConfirm) {
      window.addEventListener('beforeunload', beforeUnloadListener)
    }

    return () => {
      window.removeEventListener('beforeunload', beforeUnloadListener)
    }
  }, [showExitConfirm])

  useEffect(() => {
    setIsLoadingUserData(true)

    getProfile()
      .then(({ data }) => setUserData(data))
      .catch(setUserDataError)
      .finally(() => setIsLoadingUserData(false))
  }, [])

  useEffect(() => {
    getExtraInfo()
      .then(({ data }) => {
        if (!data) return

        const newSelectOptions = SELECTS_NAMES.reduce((finalObject, name) => {
          finalObject[name] = parseOptions(data[name])

          return finalObject
        }, {})

        setSelectOptions(newSelectOptions)
      })
      .catch(() => {
        return setModal({
          children: ({ onClose }) => <ErrorModal onClose={onClose} />,
          isCentered: true,
          shouldCloseOnOverlayClick: false
        })
      })
      .finally(() => setIsLoadingSelectOptions(false))
  }, [setModal])

  const mainAddress = useMemo(() => {
    const addresses = userData?.addresses || []
    const foundAddress = addresses.find(({ main }) => main)

    return foundAddress
  }, [userData])

  const updateAction = (state, payload) => {
    return {
      ...state,
      ...payload
    }
  }

  const value = {
    userData,
    isLoadingUserData,
    userDataError,
    selectOptions,
    isLoadingSelectOptions,
    mainAddress,
    updateAction,
    setShowExitConfirm,
    activeStep,
    setActiveStep
  }

  return (
    <CardRequestContext.Provider value={value}>
      {children}
    </CardRequestContext.Provider>
  )
}

export const useCardRequestContext = () => useContext(CardRequestContext)
