import PropTypes from 'prop-types'

import * as S from './styled'

const Overlay = ({ isVisible, handleClick }) => (
  <S.ShareButtonOverlay
    isVisible={isVisible}
    onClick={handleClick}
  />
)

Overlay.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired
}

export default Overlay
