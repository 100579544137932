import { Fragment, useContext } from 'react'
import { Button, Text } from '@leroy-merlin-br/backyard-react'

import { HeaderContext } from 'site/Header/contexts/HeaderContext'
import { DropdownContext } from 'site/Header/contexts/DropdownContext'

import slugify from 'utils/slugify'

import MenuItem from './components/MenuItem'
import { redirectUrl } from '../../utils/redirect-url'
import * as S from './styled'

const FirstLevel = () => {
  const {
    dropdownElements,
    setParentActivated,
    toggleChildren,
    isBlackWeekend,
    isLogged
  } = useContext(HeaderContext)

  const dropdownContext = useContext(DropdownContext)

  const { setItem, ActivatedItem } = dropdownContext

  const trigger = itemName => {
    setParentActivated(itemName)
    ActivatedItem === null ? toggleChildren() : setItem()
  }

  return (
    <Fragment>
      <S.WrapperList>
        <ul>
          {dropdownElements &&
            dropdownElements?.map(item => (
              <MenuItem
                data={item}
                trigger={() => trigger(item.name)}
                key={slugify(item.name)}
                isBlackWeekend={isBlackWeekend}
              />
            ))}
          <li>
            <a href="https://www.leroymerlin.com.br/modular/dicasdeseguranca">
              <Text as="span" color="white">
                Dicas de segurança
              </Text>
            </a>
          </li>
        </ul>
      </S.WrapperList>
      {isLogged && (
        <S.WrapperSignOut>
          <Button
            size="kilo"
            variant="link"
            onClick={() => redirectUrl('logout')}
            data-tracking="mobile-menu-logout"
          >
            <Text color="white" noMargin>
              Sair
            </Text>
          </Button>
        </S.WrapperSignOut>
      )}
    </Fragment>
  )
}

export default FirstLevel
