import styled from '@emotion/styled'
import { css } from '@emotion/react'

const wrapperStyles = ({ theme }) => css`
  label: wrapper;

 & .close-button{
    color: ${theme.colors.n600};
    cursor: pointer;
    font-size: ${theme.iconSizes.giga};
    margin-left: auto;
 }

 & h3:first-of-type{
    display: flex;
    flex-direction: row-reverse;
 }
`

const spinnerWrapperStyles = () => css`
  label: spinner__wrapper;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const Wrapper = styled('div')(wrapperStyles)
export const SpinnerWrapper = styled('div')(spinnerWrapperStyles)
