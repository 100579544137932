export const pushDataObject = (
  dataObject: Record<string, unknown>,
  needReset = false
) => {
  const dataLayer = (window.dataLayer = window.dataLayer || [])

  if (needReset) {
    dataLayer.push(function (this: { reset: () => void }) {
      this.reset()
    })
  }

  dataLayer.push(dataObject)
}
