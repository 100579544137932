import { FunctionComponent } from 'react'
import { Text, Stack } from '@backyard-ui/core'
import { StarRating } from 'hpdp/components'

import { Props } from './types'
import styles from './StarResume.styles'

const StarResume: FunctionComponent<Props> = ({ generalScores }) => {
  const {
    statistics: { avgRating }
  } = generalScores

  return (
    <Stack
      direction="column"
      spacing={0}
      align={{ initial: 'center', md: 'flex-start' }}
    >
      <Text color="gray-600">Nota média do produto</Text>
      <Stack align="center" spacing={4}>
        <span className={styles().wrapper()}>{avgRating}</span>
        <StarRating color="dark" rating={parseFloat(avgRating)} />
      </Stack>
    </Stack>
  )
}

export default StarResume
