import axios from 'axios'
import { datadogRum } from '@datadog/browser-rum'

export type ResponseType<T, U> = {
  status: T
  value: U
}

type RequestMethods = 'GET' | 'POST' | 'PUT' | 'DELETE'

const cancelToken = axios.CancelToken
const instance = axios.create()

export const request = <T>({
  url,
  method,
  data,
  errors = [],
  params = {}
}: {
  url: string
  method: RequestMethods
  data?: object
  errors?: number[]
  params?: { [x: string]: any }
}) => {
  const source = cancelToken.source()

  const cancel = () => {
    source.cancel()
  }

  const response = async () => {
    try {
      const response = await instance({
        method,
        url: url,
        data,
        cancelToken: source.token,
        params
      })
      return {
        status: response.status,
        value: response.data
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status && errors.includes(error.response.status)) {
          return {
            status: error.response.status,
            value: error.response.data
          }
        }
      }
      datadogRum.addError(error)
      throw error
    }
  }

  return {
    cancel,
    response: response() as Promise<T>
  }
}
export const isRequestError = (error: unknown) => {
  return axios.isAxiosError(error)
}
