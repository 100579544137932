import { MutableRefObject, createRef, useEffect } from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import UUID from 'pure-uuid'

import { sendFeedback } from 'scripts/react-components/site/Chat/Services/Http'

import { Feedback } from '../../..'
import { TrailingLoading } from '../Icons'
import { Highlight, Message } from './Components'
import type { Messaging as MessagingType } from './types'
import { MessageTypeElement, scrollBottom } from './helpers'
import * as S from './styled'

const Messaging = ({
  messages,
  isWaitingResponse,
  onFeedbackSubmit
}: MessagingType) => {
  const wrapperRef = createRef() as MutableRefObject<HTMLElement>

  useEffect(() => {
    if (messages.length) {
      scrollBottom(wrapperRef)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages.length])

  const renderFeedbackForm = () => (
    <div data-testid="feedback-form">
      <Feedback.Assessment
        onSubmit={async (values) => {
          const promiseResponse = Promise

          sendFeedback(values).then((response) => {
            if (response.status === 202) {
              if (onFeedbackSubmit) onFeedbackSubmit()
              return promiseResponse.resolve()
            }

            promiseResponse.reject()
          })

          return promiseResponse
        }}
      />
    </div>
  )

  return (
    <S.MessagesWrapper ref={wrapperRef}>
      {messages?.map((data) => {
        if (data.componentContent && data.componentContent === 'feedback') {
          return (
            <div
              key={`chat-message-${new UUID(1)}`}
              data-testid="message-item-type-highlight"
            >
              <Highlight type="income" title="Conversa encerrada" message="" />
              {renderFeedbackForm()}
            </div>
          )
        }

        const messageType = data.type ?? 'income'
        const MessageElement = MessageTypeElement[messageType]

        return (
          <MessageElement
            {...data}
            key={`chat-message-${new UUID(1)}`}
            data-testid={`message-item-type-${messageType}`}
          />
        )
      })}

      {isWaitingResponse && (
        <Message
          type="income"
          message={renderToStaticMarkup(<TrailingLoading />)}
          data-testid="message-baloon-waiting"
        />
      )}

      <S.ScrollAnchor />
    </S.MessagesWrapper>
  )
}

export default Messaging
