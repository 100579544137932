import { createContext, useContext, useState, ReactNode } from 'react'

import { Optins, Phone } from '../types'

type MyAccountContactContextType = {
  contextData: FormValuesType
  updateData: (response: FormValuesType) => void
}

export type FormValuesType = {
  fiscalId: string
  identityCode: string
  deviceCode: string
  email: string
  mainCellphone: string
  phones: Phone[]
  optins: Optins | undefined
}

type MyAccountContactContextProviderProps = {
  children: ReactNode
}

const MyAccountContactContext = createContext<
  MyAccountContactContextType | Record<string, never>
>({})

const FORM_VALUES = {
  fiscalId: '',
  identityCode: '',
  deviceCode: '',
  email: '',
  mainCellphone: '',
  phones: [],
  optins: undefined
}

export const MyAccountContactContextProvider = ({
  children
}: MyAccountContactContextProviderProps) => {
  const [contextData, setContextData] = useState<FormValuesType>(FORM_VALUES)

  const value = {
    contextData,
    updateData: setContextData
  }

  return (
    <MyAccountContactContext.Provider value={value}>
      {children}
    </MyAccountContactContext.Provider>
  )
}

export const useMyAccountContactContext = () => useContext(MyAccountContactContext)
