import { Fragment } from 'react'
import classnames from 'classnames'
import { ModalProvider } from '@leroy-merlin-br/backyard-react'

import { SellersInvoiceModal } from './SellersInvoiceModal'

const PaymentInfo = ({
  orderId,
  payable,
  payments,
  totalValue,
  invoices,
  shouldShowInvoiceModal,
  shippingCost,
  discount,
  vouchers
}) => {
  const PaymentValue = ({ installments, value }) => {
    const { integers, decimals, hasInterestRate } = value

    if (installments && installments.value) {
      const interestRate = !hasInterestRate ? ' Sem juros' : ' Com juros'

      return (
        <Fragment>
          {installments.quantity}x R$ {installments.value.integers},
          {installments.value.decimals} {interestRate}
        </Fragment>
      )
    }

    return (
      <Fragment>
        R$ {integers},{decimals}
      </Fragment>
    )
  }

  const PaymentBankSlipLink = ({ url }) => {
    const linkClasses = classnames('underlined', {
      'color-default': !url
    })
    const linkProps = {
      className: linkClasses,
      href: url || null,
      title: url ? 'Link para o boleto' : 'Boleto sendo gerado',
      target: url ? '_blank' : null
    }

    return (
      <div className="bank-slip-link">
        <a {...linkProps}>ver boleto</a>
      </div>
    )
  }

  const PaymentCard = (payment, index) => {
    const { type, brand, mode } = payment
    const isBankSlip = type === 'bank_slip'
    const isBankSlipInstallments = type === 'bank_slip_installments'

    if (type) {
      const iconClass = classnames('payment-card', {
        'bank-slip': isBankSlip,
        'bank-slip-installments': isBankSlipInstallments,
        [`${type}`]: !isBankSlip && !isBankSlipInstallments
      })

      return (
        <div className="card-content" key={index}>
          <div className="bank-slip-info">
            <span className={iconClass} />
            <span>{PaymentValue(payment)}</span>
          </div>
          {isBankSlip && PaymentBankSlipLink(payment)}
        </div>
      )
    }

    return (
      <Fragment key={index}>
        <p>
          {' '}
          {mode} {brand} - {PaymentValue(payment)}{' '}
        </p>
      </Fragment>
    )
  }

  const expiringDate = () => {
    let date = new Date()
    date = new Date(date.setDate(date.getDate() + 1))
    return date.toLocaleDateString()
  }

  const shippingLabel = shippingCost
    ? `R$ ${shippingCost.integers},${shippingCost.decimals}`
    : 'Grátis'

  return (
    <div className="payment-order-info px-4 sm:px-8">
      <div className="payment-box">
        <div className="payment-box-title">
          <strong style={{ marginBottom: 10 }}>Informações de pagamento</strong>
          {payable && (
            <p>
              Para confirmarmos seu pedido, precisamos que você realize o
              pagamento até
              <strong> 02h00 </strong>do dia <strong>{expiringDate()}</strong>
            </p>
          )}
        </div>
        {payments.length > 0 && payments.map(PaymentCard)}
        {invoices?.length > 0 && (
          <ModalProvider>
            <SellersInvoiceModal
              saleOrderId={orderId}
              sellersInvoice={invoices}
              shouldShowInvoiceModal={shouldShowInvoiceModal}
            />
          </ModalProvider>
        )}
      </div>

      <div className="payment-box">
        <div className="payment-box-title">
          <strong>Informações gerais</strong>
        </div>

        <div style={{ marginBottom: 5 }}>
          <strong>Frete:</strong>
          <span> {shippingLabel}</span>
        </div>

        {discount && (
          <div className="color-primary" style={{ marginBottom: 5 }}>
            <strong>Desconto:</strong> - <PaymentValue value={discount} />
          </div>
        )}

        {vouchers && (
          <div className="color-primary" style={{ marginBottom: 5 }}>
            <strong>Desconto Fidelidade:</strong> - R$ {vouchers.integers},
            {vouchers.decimals}
          </div>
        )}

        <div>
          <strong>Total:</strong> <PaymentValue value={totalValue} />
        </div>
      </div>
    </div>
  )
}

export default PaymentInfo
