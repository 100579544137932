import { Heading } from '@leroy-merlin-br/backyard-react'

import * as S from './styled'

const Title = ({ children, as = 'h1', size = 'giga' }) => (
  <S.Wrapper>
    <Heading as={as} size={size} noMargin>
      {children}
    </Heading>
  </S.Wrapper>
)

export default Title
