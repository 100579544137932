import { useState, useEffect, useCallback } from 'react'

export const useResize = (media = 968) => {
  const getInitialMedia = useCallback(() => !window.matchMedia(`(min-width: ${media}px)`).matches, [media])

  const [isMobile, setMobile] = useState(getInitialMedia())

  useEffect(() => {
    const handleResize = () => {
      setMobile(getInitialMedia())
    }

    handleResize()

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [getInitialMedia])

  return [isMobile]
}
