import { css } from '@emotion/react'
import styled from '@emotion/styled'

const wrapperStyles = ({ theme }) => css`
  label: facets-bar;

  display: flex;
  align-items: center;
  justify-content: center;

  background: ${theme.colors.n100};

  color: ${theme.colors.black};

  width: 100%;

  margin-bottom: ${theme.spacings.mega};
`

const contentStyles = ({ theme }) => css`
  label: facets-bar__content;

  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-gap: ${theme.spacings.byte};
  justify-content: space-between;
  align-items: center;

  padding: ${theme.spacings.giga} 0px;
`

export const Content = styled('div')(contentStyles)
export const Wrapper = styled('div')(wrapperStyles)
