import { Fragment } from 'react'

import * as S from './styled'

const Installments = ({ isBranded, installments, showOneInstallment = false }) => {
  const hasInstallments = parseInt(installments?.amount || 0) > 1
  const hasTotalPrice = parseInt(installments?.totalPrice || 0) > 0

  if (!hasInstallments && !showOneInstallment) {
    return null
  }

  return (
    <Fragment>
      {isBranded
        ? (
            <S.Installments data-testid = "is-branded">
              ou{' '}
              <S.Installments isBold>
                  {installments.amount}x{' '}
              </S.Installments>
              de R$ {installments.value} sem juros no cartão{' '}
              <S.Installments isBold>
                  Celebre!
              </S.Installments>
            </S.Installments>
          )
        : (
            <S.Installments>
              {hasTotalPrice
                ? `ou R$ ${installments.totalPrice} em até ${installments.amount}x de R$ ${installments.value} sem juros`
                : `ou ${installments.amount}x de R$ ${installments.value} sem juros`
              }
            </S.Installments>
          )}
    </Fragment>
  )
}

export default Installments
