import { css } from '@emotion/react'
import styled from '@emotion/styled'

const buttonStyles = ({ theme }) => css`
  label: sellers-list-button;

  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  padding: ${theme.spacings.mega} ${theme.spacings.byte};

  background: ${theme.colors.n100};

  color: ${theme.colors.n600};

  cursor: pointer;

  transition: background-color ${theme.transition.leave} ease-in-out;

  &:hover {
    background-color: ${theme.colors.p100};
  }
`

export const Button = styled('button')(buttonStyles)
