import { createContext, useContext, useState, ReactNode } from 'react'

type RecoveryContextType = {
  contextData: FormValuesType
  updateData: (response: FormValuesType) => void
  isCaptchaEnabled: boolean
  captchaKey: string
}

export type OptionToRecovery = {
  contact: string
  hash: string
  type: string
}

export type FormValuesType = {
  fiscalId: string
  code: string
  optionsToRecovery: OptionToRecovery[]
  selectedOption: OptionToRecovery | undefined
}

type RecoveryContextProviderProps = {
  children: ReactNode
  isCaptchaEnabled: boolean
  captchaKey: string
}

const RecoveryContext = createContext<
  RecoveryContextType | Record<string, never>
>({})

const FORM_VALUES = {
  fiscalId: '',
  code: '',
  optionsToRecovery: [],
  selectedOption: undefined
}

export const RecoveryContextProvider = ({
  children,
  isCaptchaEnabled,
  captchaKey
}: RecoveryContextProviderProps) => {
  const [contextData, setContextData] = useState<FormValuesType>(FORM_VALUES)

  const value = {
    contextData,
    updateData: setContextData,
    isCaptchaEnabled,
    captchaKey
  }

  return (
    <RecoveryContext.Provider value={value}>
      {children}
    </RecoveryContext.Provider>
  )
}

export const useRecoveryContext = () => useContext(RecoveryContext)
