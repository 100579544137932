import $ from 'jquery'

import emitter from 'scripts/utils/emitter'
import BaseComponent from 'scripts/components/base-component'

class Recovery extends BaseComponent {
  static componentName = 'recovery'

  static DOMEvents = {
    'click [data-button-submit]': 'onSubmit'
  }

  static emitterEvents = {
    'recaptcha:success': 'submitForm'
  }

  constructor (element, location = window.location) {
    super(element)
    this.location = location
    this.allowClick = true
  }

  init () {
    this.getFormValidation()
    this.submitButton = this.$element.find('button')
  }

  getFormValidation () {
    if (this.validation) {
      return
    }

    this.validation = this.$element.data('validation')
  }

  onSubmit (e) {
    e.preventDefault()

    if (this.$element.data('recaptchaDisabled') === 1 && this.allowClick) {
      this.submitButton.prop('disabled', true)
      this.allowClick = false
      return this.submitForm()
    }

    emitter.emit('recaptcha:execute')
  }

  submitForm () {
    this.$element.submit()
  }
}

export default $el => new Recovery($el).init()

export { Recovery as Component }
