import { toast } from '@leroy-merlin-br/backyard-react'
import { useForm } from 'react-hook-form'
import { ClipboardEvent, KeyboardEvent, useEffect, useMemo, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { isServerError, hasExactlySixDigits } from 'user/utils'

import { sendOTPCode, sendOTPVerify } from '../../services'
import { useRecoveryContext } from '../../context/recovery-context'

export type FormValues = {
  securityCode: string[]
}

export type SecurityCodeStepProps = {
  onNextStep: () => void
}

export type Messages = { [key: string]: string }

export type ServerErrorType = {
  message: string
  status: number
}

export function useSecurityCodeStep ({ onNextStep }: SecurityCodeStepProps) {
  const [isLoading, setIsLoading] = useState(false)
  const [fieldState, setFieldState] = useState<string>()

  const { contextData, updateData } = useRecoveryContext()

  const history = useHistory()

  const { control, setValue, getValues } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: { securityCode: [] }
  })

  const [securityCodeRef] = useState([
    useRef<HTMLInputElement>(),
    useRef<HTMLInputElement>(),
    useRef<HTMLInputElement>(),
    useRef<HTMLInputElement>(),
    useRef<HTMLInputElement>(),
    useRef<HTMLInputElement>()
  ])

  const [counter, setCounter] = useState(30)

  const handlePasteAllSecurityCode = ({ clipboardData }: ClipboardEvent<HTMLInputElement>) => {
    const securityCode = String(clipboardData.getData('Text'))

    if (!hasExactlySixDigits(securityCode)) {
      return
    }

    for (let index = 0; index <= securityCodeRef.length; index++) {
      setValue(
        `securityCode[${index}]` as `securityCode.${typeof index}`,
        String(clipboardData.getData('Text'))[index]
      )

      if (index === securityCodeRef.length) {
        securityCodeRef[5]?.current?.focus()
        securityCodeRef[5]?.current?.setSelectionRange(1, 1, 'forward')
      }
    }
  }

  const onSendCodeToVerify = async (code: string) => {
    setIsLoading(true)

    try {
      const { data } = await sendOTPVerify(contextData.fiscalId, code, 'reset_password')

      if (data.status === 'InvalidCode') {
        toast.critical(data.message, {
          content: 'Insira o código correto para prosseguir.'
        })

        setFieldState('invalid')
      } else if (data.status === 'ExpiredCode') {
        toast.critical(data.message, {
          content: 'Clique em reenviar código para gerar um novo.'
        })

        setFieldState('invalid')
      } else if (data.status === 'Valid') {
        updateData({ ...contextData, code })

        setFieldState('valid')

        onNextStep()
      } else {
        toast.primary('Não foi possível validar o código', {
          content: 'Tente novamente mais tarde.'
        })
      }
    } catch (error) {
      const { status } = error as ServerErrorType

      const hasServerError = status && isServerError(status)

      setFieldState('invalid')

      if (hasServerError) {
        history.push('/erro-interno')
      } else if (status === 429) {
        toast.critical('Você atingiu o limite de tentativas!', {
          content: 'Tente novamente mais tarde.'
        })
      }
    } finally {
      setIsLoading(false)
    }
  }

  const onRensendCode = async () => {
    if (isLoading) {
      return
    }

    setIsLoading(true)

    try {
      await sendOTPCode(
        contextData.fiscalId,
        contextData.selectedOption?.hash as string,
        contextData.selectedOption?.type as string,
        'reset_password'
      )

      toast.primary('Código reenviado!', {
        content: 'Insira o novo código.'
      })

      setCounter(30)

      setTimeout(() => securityCodeRef[0]?.current?.focus(), 0)
    } catch (error) {
      const { status } = error as ServerErrorType

      const hasServerError = status && isServerError(status)

      if (hasServerError) {
        history.push('/erro-interno')
      } else if (status === 429) {
        toast.critical('Você atingiu o limite de tentativas!', {
          content: 'Tente novamente mais tarde.'
        })
      }
    } finally {
      setIsLoading(false)
    }
  }

  const handleKeyUp = async (event: KeyboardEvent<HTMLInputElement>, index: number) => {
    const { key, currentTarget } = event

    const isLastInput = index === securityCodeRef.length - 1

    if (key === 'ArrowLeft' || key === 'ArrowRight') {
      const arrows = {
        ArrowLeft: -1,
        ArrowRight: 1
      }

      securityCodeRef[index + arrows[key]]?.current?.focus()

      securityCodeRef[index + arrows[key]]?.current?.setSelectionRange(0, 1, 'forward')
    } else if (key === 'Backspace' && !currentTarget.value) {
      securityCodeRef[index - 1]?.current?.focus()
      securityCodeRef[index - 1]?.current?.setSelectionRange(0, 1, 'forward')
    } else if (key !== 'Backspace' && currentTarget.value) {
      if (isLastInput) {
        const code = getValues('securityCode').join('')

        code.length === 6 && await onSendCodeToVerify(code)
      } else {
        securityCodeRef[index + 1]?.current?.focus()
        securityCodeRef[index + 1]?.current?.setSelectionRange(0, 1, 'forward')
      }
    }
  }

  const messages: Messages = useMemo(() => ({
    email: 'e-mail no endereço',
    phone: 'SMS no telefone'
  }), [])

  const description = useMemo(() => (
    <>
      {'Digite o código de 6 dígitos recebido por '}
      <span data-cy={`selected-${contextData.selectedOption?.type}`}>
        {messages[contextData.selectedOption?.type as string]}
      </span>
      {` ${contextData.selectedOption?.contact}`}
    </>
  ), [contextData.selectedOption, messages])

  useEffect(() => {
    securityCodeRef[0]?.current?.focus()
  }, [setValue, securityCodeRef])

  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(prev => prev - 1), 1000)
  }, [counter])

  return {
    isLoading,
    description,
    securityCodeRef,
    handlePasteAllSecurityCode,
    control,
    fieldState,
    counter,
    handleKeyUp,
    onRensendCode
  }
}
