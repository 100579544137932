import { css } from '@emotion/react'
import styled from '@emotion/styled'

const cartItemStyles = ({ theme }) => css`
  label: cart__item;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: ${theme.spacings.kilo} ${theme.spacings.mega};

  border-bottom: 1px solid ${theme.colors.n100};
`

const footerStyles = ({ theme }) => css`
  label: footer;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: ${theme.spacings.mega};
`

const buttonWrapperStyles = ({ theme }) => css`
  label: button-wrapper;

  margin-top: ${theme.spacings.giga};
`

export const CartItem = styled('div')(cartItemStyles)
export const Footer = styled('footer')(footerStyles)
export const ButtonWrapper = styled('div')(buttonWrapperStyles)

const wrapperStyles = ({ theme }) => css`
  label: wrapper;

  margin: ${theme.spacings.mega} 0;

  ${theme.media.giga} {
    margin: ${theme.spacings.mega} 0 0 ${theme.spacings.exa};
    position: sticky;
    top: ${theme.spacings.bit};
  }

  div[class*='card'] {
    padding: 0px;
  }
`

const wrapperScanAndGoStyles = ({ theme, context }) =>
  context === 'scanAndGo' &&
  css`
    label: wrapper--scan-and-go;

    margin: 0;

    ${theme.media.giga} {
      margin: 0;
    }

    ${CartInfo} {
      border-top: 1px solid ${theme.colors.n100};
    }
  `

const TotalPaymentMethodsStyles = ({ theme }) => css`
  label: total__payments;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: ${theme.spacings.kilo} ${theme.spacings.mega};

  border-bottom: 1px solid ${theme.colors.n100};
  color: ${theme.colors.p600};
`
const paymentMethodSummaryWrapperStyles = ({ theme }) => css`
  label: payment-method-summary-wrapper;

  margin-top: ${theme.spacings.byte};
`

export const Wrapper = styled('section')(wrapperStyles, wrapperScanAndGoStyles)
export const TotalPaymentMethods = styled('div')(TotalPaymentMethodsStyles)
export const PaymentMethodSummaryWrapper = styled('div')(paymentMethodSummaryWrapperStyles)
