export const NOTIFICATION_GET = '@NOTIFICATION_GET'
export const NOTIFICATION_GET_SUCCESS = '@NOTIFICATION_GET_SUCCESS'
export const NOTIFICATION_GET_FAILURE = '@NOTIFICATION_GET_FAILURE'

export const NOTIFICATION_DELETE = '@NOTIFICATION_DELETE'
export const NOTIFICATION_DELETE_SUCCESS = '@NOTIFICATION_DELETE_SUCCESS'
export const NOTIFICATION_DELETE_FAILURE = '@NOTIFICATION_GET_FAILURE'

export default {
  NOTIFICATION_GET,
  NOTIFICATION_GET_SUCCESS,
  NOTIFICATION_GET_FAILURE,
  NOTIFICATION_DELETE,
  NOTIFICATION_DELETE_SUCCESS,
  NOTIFICATION_DELETE_FAILURE
}
