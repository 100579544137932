import { useEffect, useMemo } from 'react'
import { toast } from '@leroy-merlin-br/backyard-react'

import { useUserProfile } from 'shared/hooks/useUserProfile'

import { loadScript } from 'utils/load-script'

import { ChatbotData, KloeChat, UserDataContext, WindowChatbot } from './types'

export const useChatbot = (chatbotData: ChatbotData) => {
  const { userProfile, isLoading: isLoadingUserProfile } = useUserProfile()
  const userDataContext = useMemo<UserDataContext | undefined>(() => {
    if (userProfile) {
      return {
        name: userProfile.name,
        email: userProfile.email,
        telefone: userProfile.phones?.[0]?.number || ''
      }
    }
  }, [userProfile])

  const getIsChatbotInserted = () => {
    return Boolean((window as WindowChatbot).kloe)
  }

  const insertChatbotWidget = async (
    chatbotData: ChatbotData,
    userDataContextParam?: UserDataContext
  ) => {
    ;(window as WindowChatbot).kloeChatOptions = {
      host: chatbotData.url,
      integrationID: chatbotData.token,
      context: userDataContextParam,
      onLoad: (instance: KloeChat) => {
        if (chatbotData.floatingButton) {
          instance.show()
        }
      }
    }

    const chatbotFullUrl =
      chatbotData.url + '/widget/' + chatbotData.token + '.js'

    try {
      await loadScript(chatbotFullUrl)
    } catch (error) {
      console.error('Erro ao carregar o chatbot')
    }
  }

  const openChatbot = () => {
    const isChatbotInserted = getIsChatbotInserted()

    if (isChatbotInserted) {
      ;(window as WindowChatbot).kloe.open()
    } else {
      toast.warning('O Chat está em carregamento', {
        variant: 'solid',
        content: ' aguarde um momento e tente novamente.'
      })
    }
  }

  useEffect(() => {
    const isChatbotInserted = getIsChatbotInserted()
    const shouldLoad = !isChatbotInserted && chatbotData
    const shouldUpdate =
      isChatbotInserted && !isLoadingUserProfile && userDataContext

    if (shouldLoad) {
      insertChatbotWidget(chatbotData, userDataContext)
    }

    if (shouldUpdate) {
      ;(window as WindowChatbot).kloe.updateContext(userDataContext)
    }
  }, [chatbotData, isLoadingUserProfile, userDataContext])

  return { openChatbot }
}
