import { Component, Fragment } from 'react'
import {
  Badge,
  CloseButton,
  Inline,
  Heading,
  Hidden,
  Visible,
  Stack,
  Text
} from '@leroy-merlin-br/backyard-react'

import emitter from 'scripts/utils/emitter'
import tryAddToCart from 'scripts/utils/cart/addToCart'

import { GardenDrawer } from 'shared/components/GardenDrawer'

import { SellersListItem } from '../SellersListItem'
import * as S from './styled'

class SellersList extends Component {
  constructor (props) {
    super(props)

    this.state = {
      isDrawerOpen: false
    }
  }

  componentDidMount () {
    emitter.on('buybox:drawer-open', this.toggleDrawer)
  }

  toggleDrawer = () => {
    this.setState(prevState => ({
      isDrawerOpen: !prevState.isDrawerOpen
    }))
  }

  pickupInStoreClick = () => {
    this.toggleDrawer()

    emitter.emit('showStock')
  }

  ecommerceClick = id => {
    const product = {
      id: this.props.productId,
      quantity: 1
    }

    if (id) {
      product.skuId = id
    }

    tryAddToCart({
      regionId: this.props.regionId,
      type: 'delivery',
      products: [product],
      override: false
    })

    this.toggleDrawer()
  }

  renderSkusList (skus) {
    const productId = this.props.productId

    return skus.map(({ id, shop, price, purchaseButtons, limitQuantity }) => (
      <SellersListItem
        key={id}
        productId={productId}
        id={id}
        shop={shop}
        price={price}
        purchaseButtons={purchaseButtons}
        limitQuantity={limitQuantity}
        pickupInStoreClick={this.pickupInStoreClick}
        closeDrawer={this.toggleDrawer}
      />
    ))
  }

  render () {
    return (
      <Fragment>
        <GardenDrawer
          isOpen={this.state.isDrawerOpen}
          overlayClick={this.toggleDrawer}
          className="sellers-list"
        >
          <S.Wrapper>
            <Stack space="mega">
              <Inline space="bit" alignX="flex-start" alignY="center" wrap="nowrap">
                <CloseButton label="Fechar" onClick={this.toggleDrawer} />

                <S.Title>
                  Compare os preços entre nossas lojas parceiras
                </S.Title>
              </Inline>

              <S.ProductContainer>
                <Inline
                  space="giga"
                  alignX="flex-start"
                  alignY="flex-start"
                  wrap="nowrap"
                >
                  <S.ProductImage
                    alt={this.props.productName}
                    src={this.props.productImg}
                  />

                  <Stack space="byte">
                    <div>
                      <Visible when={['untilKilo', 'kilo']}>
                        <Heading as="h3" size="kilo" noMargin>
                          {this.props.productName}
                        </Heading>
                      </Visible>

                      <Hidden when={['untilKilo', 'kilo']}>
                        <Heading as="h3" size="tera" noMargin>
                          {this.props.productName}
                        </Heading>
                      </Hidden>
                    </div>

                    <Badge appearance="info">Cód. {this.props.productId}</Badge>
                  </Stack>
                </Inline>
              </S.ProductContainer>
              <Stack space="byte">
                <Hidden when={['untilKilo', 'kilo']}>
                  <S.SkusHeader>
                    <Text as="span" color="n600" size="giga" noMargin isBold>
                      Lojas
                    </Text>

                    <Text as="span" color="n600" size="giga" noMargin isBold>
                      Preço do produto
                    </Text>

                    <Text as="span" color="n600" size="giga" noMargin isBold>
                      Opções de compra
                    </Text>
                  </S.SkusHeader>
                </Hidden>

                {this.renderSkusList(this.props.skus)}
              </Stack>
            </Stack>
          </S.Wrapper>
        </GardenDrawer>
      </Fragment>
    )
  }
}

export default SellersList
