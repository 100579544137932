import { SignupContextProvider } from 'user/signup/context/signup-context'

import { Routes } from './routes'

const App = ({ isCaptchaEnabled, captchaKey, isNewHeaderEnabled, verifyCodeStepOnSignup, abtestRunning }) => (
  <SignupContextProvider
    isCaptchaEnabled={isCaptchaEnabled === 'true'}
    captchaKey={captchaKey}
  >
    <Routes isNewHeaderEnabled={isNewHeaderEnabled} verifyCodeStepOnSignup={verifyCodeStepOnSignup && abtestRunning}/>
  </SignupContextProvider>
)

export default App
