import type { SVGAttributes } from 'react'
import { forwardRef } from 'react'

const AudioVideoTvIcon = forwardRef<SVGSVGElement, SVGAttributes<SVGSVGElement>>((props, ref) => (
  <svg ref={ref} viewBox="0 0 144 136" {...props}>
    <path d="M63 38 47.5 54.9l-10.5.3-10.5.3v32l10.2.3 10.2.3 15.7 16.4C71.3 113.6 79 121 79.7 121c1.1 0 1.3-9.4 1.3-50 0-39.3-.3-50-1.2-50-.7 0-8.3 7.7-16.8 17zm14.5 33c0 23.7-.2 43-.5 42.9-.3-.1-6.8-6.8-14.5-14.9l-14-14.8-9.2-.1L30 84V59h18.9l14.2-15.5C71 35 77.4 28 77.4 28c.1 0 .1 19.3.1 43zM107.2 70.2c.3 21.7.5 25.3 1.8 25.3s1.5-3.6 1.8-25.3C111 45.4 111 45 109 45s-2 .4-1.8 25.2zM92.9 49.7v20.8c-.1 18 .1 20 1.6 20s1.7-2 1.6-19.8c0-10.8 0-20.2-.1-20.7 0-1.1-3-1.4-3.1-.3z" />
  </svg>
))

export default AudioVideoTvIcon
