import { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Text } from '@leroy-merlin-br/backyard-react'

import { Avatar } from 'lmcv/components'

import * as S from './styled'

function Header ({ customerName, customerPhoto, projectStatus, projectName }) {
  const status = projectStatus.toLowerCase() || 'indefinido'

  const COLOR_MAP = {
    ativo: 'p500',
    pendente: 'y500',
    finalizado: 'n500',
    concluído: 'n500',
    indefinido: 'n800'
  }

  return (
    <Fragment>
      <S.Photo>
        <Avatar name={customerName} image={customerPhoto} big />
      </S.Photo>

      <Text size="giga">{customerName}</Text>
      <Text color="n400">{projectName}</Text>
      <S.ProjectStatusWrapper color={COLOR_MAP[status]}>
        <Text color={COLOR_MAP[status]} noMargin>
          {projectStatus}
        </Text>
      </S.ProjectStatusWrapper>
    </Fragment>
  )
}

Header.propTypes = {
  customerName: PropTypes.string,
  customerPhoto: PropTypes.string,
  projectName: PropTypes.string.isRequired,
  projectStatus: PropTypes.string.isRequired
}

Header.defaultProps = {
  customerName: 'Nome do usuário'
}

export default Header
