import axios from 'axios'
import Cookies from 'js-cookie'

import emitter from 'scripts/utils/emitter'
import BaseComponent from 'scripts/components/base-component'

class BaseTracking extends BaseComponent {
  async init () {
    await this.loadRichRelevanceScript()
    this.callRichRelevance()

    if (this.getDynamicExperienceItems) {
      this.setDynamicExperienceObject()
      await this.executeDynamicExperienceScript()
    }
  }

  async getPlacements () {
    const modularElement = document.querySelector('[data-modular-content-id]')

    if (modularElement) {
      const screenSize = window.screen.width
      const webDevice = screenSize >= 768 ? 'desktop' : 'mobile'
      const isOnMobileApp = Boolean(
        navigator.userAgent.match('Boitata-App') ||
          document.cookie.match('is_on_mobile_app=true') ||
          window.location.search.match('isOnMobileApp=true')
      )
      const device = isOnMobileApp ? 'app' : webDevice

      const modularId = modularElement.dataset.modularContentId
      const baseUrl = window.env && window.env.baseHost
      const endpoint = `${baseUrl}/api/boitata/v1/modularContents/${modularId}/recommendedProducts?device=${device}`

      return await axios.get(endpoint)
    }
  }

  getData () {
    return this.$element.data()
  }

  loadRichRelevanceScript () {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script')

      script.src = '//media.richrelevance.com/rrserver/js/1.2/p13n.js'
      script.onload = resolve
      script.onerror = reject

      document.body.appendChild(script)
    })
  }

  executeDynamicExperienceScript () {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script')

      script.src =
        'https://cdn.richrelevance.com/dashboard/applications/clientjs-dev/client.js'
      script.onload = resolve
      script.onerror = reject

      document.body.appendChild(script)
    })
  }

  getDataFromCookie () {
    return {
      userId: Cookies.get('user_id') || '',
      userTrackingId: Cookies.get('user_tracking_id') || ''
    }
  }

  async callRichRelevance () {
    const R3_COMMON = new r3_common() // eslint-disable-line
    const data = this.getData()

    if (!data) {
      return
    }

    R3_COMMON.setApiKey(data.apiKey)
    R3_COMMON.setBaseUrl(data.baseUrl)

    const dataFromCookie = this.getDataFromCookie()

    R3_COMMON.setSessionId(dataFromCookie.userTrackingId)
    R3_COMMON.setClickthruServer(
      window.location.protocol + '//' + window.location.host
    )
    R3_COMMON.setRegionId(data.regionId)

    if (dataFromCookie.userId) {
      R3_COMMON.setUserId(dataFromCookie.userId)
    }

    try {
      const { data = [] } = (await this.getPlacements()) || {}

      data.forEach(component => {
        R3_COMMON.addPlacementType(component.data.placement)
      })
    } catch (err) {
      if (err.response?.status !== 404) {
        console.error(err)
      }
    }

    this.sendItems(data, R3_COMMON)

    RR.jsonCallback = function () {
      emitter.emit('recommendation', RR.data.JSON.placements)
    }

    rr_flush_onload()
    r3()
  }

  setDynamicExperienceObject () {
    let tempObject = {}
    const r3CommonObject = {}
    const data = this.getData()

    if (!data || !data.apiClientKey) {
      return
    }

    const dataFromCookie = this.getDataFromCookie()

    r3CommonObject.apiKey = data.apiKey
    r3CommonObject.apiClientKey = data.apiClientKey
    r3CommonObject.baseUrl = data.baseUrl
    r3CommonObject.sessionId = dataFromCookie.userTrackingId
    r3CommonObject.placements = data.placementType
    r3CommonObject.pref = data.requestOrigin
    r3CommonObject.rid = data.regionId

    if (dataFromCookie.userId) {
      r3CommonObject.userId = dataFromCookie.userId
    }

    tempObject = this.getDynamicExperienceItems(data)

    window.R3_COMMON = {
      ...r3CommonObject,
      ...tempObject
    }
  }
}

export default BaseTracking
