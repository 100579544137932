import BaseComponent from 'scripts/components/base-component'

class PaymentMethod extends BaseComponent {
  static componentName = 'payment-method-breadcrumb'

  static DOMEvents = {
    'click [data-breadcrumb-link]': 'historyBack'
  }

  historyBack (e) {
    e.preventDefault()

    history.back()
  }
}

export default $el => new PaymentMethod($el)

export { PaymentMethod as Component }
