import { css } from '@emotion/react'
import styled from '@emotion/styled'

const linkSellerInvoice = () => css`
  label: link-seller-invoice;

  & > p {
    cursor: pointer;
  }
`

export const LinkSellerInvoice = styled('div')(linkSellerInvoice)

const buttonStyle = () => css`
  label: button-modal-seller-invoice;

  position: relative;
  display: inline-flex;
  width: 100%;
  flex-direction: column;
  -webkit-box-pack: end;
  justify-content: flex-end;

  button {
    justify-content: end;
  }
`

export const ButtonStyle = styled('div')(buttonStyle)
