import { useContext } from 'react'
import PropTypes from 'prop-types'

import { onMenuClick } from 'scripts/utils/data-layer'

import { DropdownContext } from 'site/Header/contexts/DropdownContext'

import ShellSubElement from '../shell/menuSubElement'

const ChildrenDesktop = ({ item, rootElement }) => {
  const context = useContext(DropdownContext)
  const {
    childrenElements,
    childrenLoading
  } = context

  const shellsQuantity = Math.floor(Math.random() * (15 - 10) + 5)

  const parent = item.name

  if (!item.hasChildren) {
    return null
  }

  const onItemClick = (event, item) => {
    event.preventDefault()
    event.stopPropagation()

    onMenuClick({ rootElement, parent, item })

    const anchorElement = event.currentTarget.querySelector('a')
    const url = anchorElement.href

    window.location.href = url
  }

  const renderElements = () => (
    childrenElements[item.id] && childrenElements[item.id].map((childItem) => {
      return (
        <li className="menu-element" key={`${childItem.url}-${childItem.name}`} onClick={event => onItemClick(event, childItem)}>
          <span>
            <a href={ childItem.url }>
              { childItem.name }
            </a>
          </span>
        </li>
      )
    })
  )

  return (
    <ul className="menu-sub-element">
      {
        item.hasChildren && !childrenLoading
          ? renderElements()
          : <ShellSubElement items={ shellsQuantity } />
      }
    </ul>
  )
}

ChildrenDesktop.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    url: PropTypes.string,
    hasChildren: PropTypes.bool
  }).isRequired
}

export default ChildrenDesktop
