import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { Theme } from 'newCheckout/types/Theme'

const wrapperStyles = ({ theme }: Theme) => css`
  label: success__wrapper;

  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${theme.spacings.tera} ${theme.spacings.byte} ${theme.spacings.tera} ${theme.spacings.byte};
  background-color: ${theme.colors.n100};
  gap: ${theme.spacings.kilo};

  p {
    color: ${theme.colors.n900};
  }

  font-family: "Leroy Merlin Sans", sans-serif;

  ${theme.media.giga} {
    flex-direction: row;
    gap: ${theme.spacings.giga};
  }
`
export const Wrapper = styled('div')(wrapperStyles)

const greenLineStyles = ({ theme }: Theme) => css`
  label: success__green-lines;

  position: absolute;
  width: 100%;
  height: 113px;
  top: 0;
  left: 0;
  background-color: ${theme.colors.p600};
`
export const GreenLine = styled('div')(greenLineStyles)

const contentStyles = ({ theme }: Theme) => css`
  label: success__content;
  
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: ${theme.spacings.kilo};

  ${theme.media.giga} {
    width: 760px;
  }
`
export const Content = styled('div')(contentStyles)

const summaryStyles = ({ theme }: Theme) => css`
  label: summary;
  
  z-index: 1;

  ${theme.media.giga} {
    position: sticky;
    align-self: flex-start;
    top: ${theme.spacings.kilo};
    width: 367px;
    min-width: 367px;
    max-width: 367px;
  }
`
export const Summary = styled('div')(summaryStyles)
