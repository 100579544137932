const SoundOutline = () => (
  <svg
    viewBox="0 0 73 72"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M44.7742 0.0961339C44.227 -0.115066 43.5934 0.028934 43.1902 0.460933L20.9566 24.5473H7.43023C6.63343 24.5473 5.99023 25.1905 5.99023 25.9873V46.7521C5.99023 47.5489 6.63343 48.1921 7.43023 48.1921H20.6398L43.2094 71.5584C43.4878 71.8464 43.8622 72 44.2462 72C44.4286 72 44.611 71.9616 44.7838 71.8944C45.331 71.6736 45.6862 71.1456 45.6862 70.56V1.44013C45.6862 0.844933 45.3214 0.316934 44.7742 0.0961339ZM42.8062 66.9984L22.291 45.7537C22.0222 45.4753 21.6478 45.3121 21.2542 45.3121H8.87023V27.4273H21.5902C21.9934 27.4273 22.3774 27.2641 22.6462 26.9665L42.8062 5.12653V66.9984ZM55.1037 20.2943C54.3069 20.2943 53.6637 20.9375 53.6637 21.7343V49.1134C53.6637 49.9102 54.3069 50.5534 55.1037 50.5534C55.9005 50.5534 56.5437 49.9102 56.5437 49.1134V21.7343C56.5437 20.9375 55.9005 20.2943 55.1037 20.2943ZM64.0509 18.4319C64.0509 17.6351 64.6941 16.9919 65.4909 16.9919C66.2877 16.9919 66.9309 17.6351 66.9309 18.4319V52.4159C66.9309 53.2127 66.2877 53.8559 65.4909 53.8559C64.6941 53.8559 64.0509 53.2127 64.0509 52.4159V18.4319Z"
    />
  </svg>
)

export default SoundOutline
