import produce from 'immer'

import { Constants } from './'

const initialState = {
  get: {
    payload: [],
    error: {}
  },
  post: {
    payload: [],
    error: {}
  }
}

const reducer = (state = initialState, action = {}) =>
  produce(state, draft => {
    switch (action.type) {
      case Constants.CROSS_SERVICE_LIST_UPDATE:
        draft.get.payload = [...state.get.payload, ...action.data]
        break

      case Constants.CROSS_SERVICE_LIST_CLEAR:
        draft.get.payload = []
        break

      case Constants.CROSS_SERVICE_GET_LIST_ERROR:
        draft.get.error = action.error
        break

      case Constants.CROSS_SERVICE_POST_QUANTITY_ERROR:
        draft.post.error = action.error
        break

      case Constants.CROSS_SERVICE_LIST_UPDATE_AFTER_REMOVE_ITEM:
        draft.get.payload = [...action.data]
        break

      case Constants.CROSS_SERVICE_POST_OVERRIDE:
        draft.post.payload = action.data
        break

      case Constants.CROSS_SERVICE_QUANTITY_UPDATE:
        draft.post.payload = [...action.data]
        break

      default:
        return draft
    }
  })

export default reducer
