import { Stack } from '@backyard-ui/core'

import styles from './ReviewSkeleton.styles'
import { ResumeSkeleton, HelpfulCommentsSkeleton } from './components'

const ReviewsSkeleton = () => (
  <Stack direction="column" spacing="16" data-testid="reviews-skeleton">
    <div className={styles().resume()}>
      <ResumeSkeleton />
    </div>

    <div className={styles().wrapper()}>
      <HelpfulCommentsSkeleton />
    </div>
  </Stack>
)

export default ReviewsSkeleton
