import { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Badge } from '@leroy-merlin-br/backyard-react'
import { Text, Button } from '@backyard-ui/core'
import {
  ChevronDoubleDownOutline,
  ChevronDoubleUpOutline
} from '@backyard-ui/icons'

import { isPickup } from 'checkout/components/MyOrders/modules/messages/shipment'

const WAITING_ORDER_PICKUP = 'Aguardando retirada'
const SEPARATION_PROBLEM = 'Problema na separação'
const DELIVERY_ATTEMPT = 'Tentativa de entrega'
const CANCELLED = 'Cancelado'

const Trigger = ({
  id,
  shipments,
  totalValue,
  date,
  fiscalId,
  isOpen,
  isOrderToPay,
  payable,
  paymentUrl,
  serviceCount = 0,
  isOrderToPickUp = false,
  hasSeparationProblem = false,
  hasDeliveryAttempt = false,
  hasCancelled = false
}) => {
  const pickupsCount = shipments.filter(shipment =>
    isPickup(shipment.type)
  ).length
  const shipmentCount = shipments.length - pickupsCount
  const SHIPMENT_LABEL = shipmentCount === 1 ? 'entrega' : 'entregas'
  const PICKUP_LABEL = pickupsCount === 1 ? 'retirada' : 'retiradas'
  const SERVICE_LABEL = serviceCount > 1 ? 'serviços' : 'serviço'

  const PaymentOrderButton = () => {
    if (!payable || fiscalId) {
      return (
        <div className="order-item color-default">
          <p className="info">
            O pagamento desse pedido é possível apenas na Loja física
          </p>
        </div>
      )
    }
    return (
      <div>
        <Button asChild>
          <a onClick={e => e.stopPropagation()} href={paymentUrl}>
            Pagar
          </a>
        </Button>
      </div>
    )
  }

  const orderBadge = () => {
    if (isOrderToPickUp) {
      return <Badge appearance="warning">{WAITING_ORDER_PICKUP}</Badge>
    }
    if (hasSeparationProblem) {
      return <Badge appearance="warning">{SEPARATION_PROBLEM}</Badge>
    }
    if (hasDeliveryAttempt) {
      return <Badge appearance="warning">{DELIVERY_ATTEMPT}</Badge>
    }
    if (hasCancelled) {
      return <Badge appearance="critical">{CANCELLED}</Badge>
    }

    return (
      <Badge appearance={isOpen ? 'info' : 'primary'} variant="subtle">
        {shipmentCount > 0 && (
          <Fragment>
            {shipmentCount} {SHIPMENT_LABEL}
          </Fragment>
        )}
        {pickupsCount > 0 && (
          <Fragment>
            {shipmentCount > 0 && serviceCount === 0 ? ' e ' : ''}
            {shipmentCount > 0 && serviceCount > 0 ? ', ' : ''}
            {pickupsCount} {PICKUP_LABEL}
          </Fragment>
        )}
        {serviceCount > 0 && (
          <Fragment>
            {' '}
            {shipmentCount > 0 || pickupsCount > 0 ? ' e ' : ''}
            {serviceCount} {SERVICE_LABEL}
          </Fragment>
        )}
      </Badge>
    )
  }

  const Chevron = isOpen ? ChevronDoubleUpOutline : ChevronDoubleDownOutline

  return (
    <div
      className="flex flex-row justify-between items-center flex-wrap sm:grid sm:grid-cols-5 sm:justify-items-start h-full w-full gap-2 sm:gap-0"
      data-section={id}
    >
      <div className="sm:justify-self-start">
        <Text size="sm" color="gray-600">
          Número do pedido{' '}
        </Text>
        <Text weight="bold" color="gray-900">
          {id}
        </Text>
      </div>

      <div>
        <Text size="sm" color="gray-600">
          Total
        </Text>
        <Text weight="bold" color="gray-900">
          R$ {totalValue.integers},{totalValue.decimals}
        </Text>
      </div>

      <div className="hidden sm:block">
        <Text size="sm" color="gray-600">
          Data do pedido
        </Text>
        <Text weight="bold" color="gray-900">
          {date}
        </Text>
      </div>

      <div>{orderBadge()}</div>

      <div className="sm:justify-self-end flex flex-row gap-8 items-center">
        {isOrderToPay && (
          <div>
            <PaymentOrderButton />
          </div>
        )}

        <Chevron width={16} height={16} color="#158110" />
      </div>
    </div>
  )
}

Trigger.propTypes = {
  id: PropTypes.string,
  shipments: PropTypes.array,
  totalValue: PropTypes.object,
  date: PropTypes.string,
  isOpen: PropTypes.bool,
  payable: PropTypes.bool,
  paymentUrl: PropTypes.string
}

export default Trigger
