import { Dispatch, SetStateAction } from 'react'

export const pageHasChanged = (
  displayComponent: boolean,
  callback: () => void
) => {
  const close = () => {
    if (displayComponent) {
      callback()
    }
  }
  window.addEventListener('beforeunload', close)

  return () => {
    window.removeEventListener('beforeunload', close)
  }
}

export const onScrollPage = (
  displayComponent: boolean,
  callback: () => void
) => {
  const close = () => {
    const scrolledPixels = window.scrollY
    if (displayComponent && scrolledPixels > 500) {
      const bannerCookies = document.getElementById('onetrust-banner-sdk')

      if (!bannerCookies || bannerCookies?.style.visibility === 'hidden') {
        callback()
      }
    }
  }

  window.addEventListener('scroll', close)

  return () => {
    window.removeEventListener('scroll', close)
  }
}

export const onTouchStart = (
  e: React.TouchEvent<HTMLDivElement>,
  setPosition: Dispatch<SetStateAction<number>>
) => {
  e.stopPropagation()
  const startPosition = e.touches[0].clientY
  setPosition(startPosition)
}

export const onTouchEnd = (
  callback: () => void,
  positionAfterMove: number,
  refreshComponent: () => void
) => {
  if (positionAfterMove > 30) {
    callback()
    return
  }
  refreshComponent()
}

export const onTouchMove = (
  e: React.TouchEvent<HTMLDivElement>,
  setPositionAfterMove: Dispatch<SetStateAction<number>>,
  positionComponent: number
) => {
  const newPosition = e.touches[0].clientY - positionComponent
  if (newPosition > 0) {
    setPositionAfterMove(newPosition)
  }
}
