export const UPDATE_SUMMARY = '@UPDATE_SUMMARY'
export const UPDATE_SUMMARY_SUCCESS = '@UPDATE_SUMMARY_SUCCESS'
export const UPDATE_SUMMARY_FAILURE = '@UPDATE_SUMMARY_FAILURE'
export const COUPONS_REMOVE_FAILURE = '@COUPONS_REMOVE_FAILURE'

export default {
  UPDATE_SUMMARY,
  UPDATE_SUMMARY_SUCCESS,
  UPDATE_SUMMARY_FAILURE
}
