import axios from 'axios'

import { ENDPOINT } from './constants'

export const getResource = async (categoryId, resource = '') => {
  const endpoint = `${ENDPOINT}/categories/${categoryId}/${resource}`
  const result = await axios.get(endpoint)

  return result
}
