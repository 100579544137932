import * as validator from 'utils/validators/validators'

import { removeNonNumericCharacters } from '../removeNonNumericCharacters'

interface FieldRule {
  condition: (value: string) => boolean
  message: string
}

interface FieldError {
  type: string;
  message?: string;
}

export const phoneValidation: (
  value: string
) => FieldError = (value: string): FieldError => {
  const rawVal = removeNonNumericCharacters(value)

  const rules: FieldRule[] = [
    {
      condition: (value: string) => !value.length,
      message: 'Insira seu Celular'
    },
    {
      condition: (value: string) => value.length >= 3 && !validator.isCellPhoneDigit(value),
      message: 'O telefone deve ser um celular'
    },
    {
      condition: (value: string) => !validator.isCellPhone(value),
      message: 'Celular inválido'
    }
  ]

  const fieldError: { type: string, message?: string } = {
    type: 'manual'
  }

  for (const rule of rules) {
    if (rule.condition(rawVal)) {
      fieldError.message = rule.message

      break
    }
  }

  return fieldError
}
