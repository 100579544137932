import { Popover } from '@leroy-merlin-br/backyard-react'
import { PropsWithChildren, useEffect, useState } from 'react'

import { TooltipType } from './types'
import * as S from './styled'

const Tooltip = ({ children, hideIn }: PropsWithChildren<TooltipType>) => {
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    setTimeout(() => setVisible(true), 1000)
    if (hideIn) {
      setTimeout(() => setVisible(false), hideIn)
    }

    return () => {
      setVisible(false)
    }
  }, [hideIn, setVisible])

  return (
    <Popover
      visible={visible}
      trigger={undefined}
      interactive
      animation
      onHide={(instance) => {
        const box = instance.popper
        box.classList.add('to-hide')
        setTimeout(() => {
          requestAnimationFrame(instance.unmount)
        }, 2000)
      }}
      placement="left"
      content={
        <S.PopoverContent data-testid="tooltip-content">
          <S.LabelPopover>Olá, como posso ajudar?</S.LabelPopover>
        </S.PopoverContent>
      }
    >
      {children}
    </Popover>
  )
}

export default Tooltip
