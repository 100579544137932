import { Inline, Text, Stack, Badge } from '@leroy-merlin-br/backyard-react'

import { RadioCard } from '../../RadioCard'

import * as S from './Style'

export const StoreCard = ({
  storeName,
  openingHours,
  radioName,
  value,
  isChecked,
  onClick,
  delayTime,
  address
}: {
  storeName: string
  openingHours: string[]
  radioName: string
  value: string
  isChecked: boolean
  delayTime: number
  address: {
    street: string
    number: number
    city: string
    state: string
    cep: string
  }
  onClick: () => void
}) => (
  <div>
    <RadioCard
      name={radioName}
      value={value}
      isChecked={isChecked}
      onClick={onClick}
      size="giga"
      title={
        <S.StoreCardTitle>
          <Text
            size="mega"
            isBold
            color={isChecked ? 'white' : 'p600'}
            noMargin
            data-cy={storeName}
          >
            {storeName}
          </Text>
          <Badge variant={isChecked ? 'subtle' : undefined}>
            retire grátis
          </Badge>
        </S.StoreCardTitle>
      }
      content={
        <Stack space="kilo">
          <div>
            <Text
              isBold
              size="kilo"
              color={isChecked ? 'white' : 'n900'}
              noMargin
            >
              a partir de {delayTime}h
            </Text>
            <Text size="kilo" color={isChecked ? 'white' : 'n600'} noMargin>
              após a confirmação do pagamento
            </Text>
          </div>
          <div>
            <Text size="kilo" color={isChecked ? 'white' : 'n600'} noMargin>
              Horário de retirada:
            </Text>
            {openingHours.map((openingHour, index) => (
              <Text key={index} size="kilo" color={isChecked ? 'white' : 'n600'} noMargin>
                {openingHour}
              </Text>
            ))}
          </div>
          <Text
            isBold
            size="kilo"
            color={isChecked ? 'white' : 'n900'}
            noMargin
          >
            Endereço: {address.street}, {address.number} • {address.city} •{' '}
            {address.state} • CEP {address.cep}
          </Text>
        </Stack>
      }
    />
  </div>
)
