import { FunctionComponent, useState } from 'react'
import { Button, Hidden, Stack, Text, Visible } from '@backyard-ui/core'
import { datadogRum } from '@datadog/browser-rum'
import type { FeedbackType, FeedbackVoteValue } from 'hpdp/services/reviews'
import { postReviewFeedback } from 'hpdp/services/reviews'

import { Props } from './types'

const Actions: FunctionComponent<Props> = ({
  reviewId,
  badFeedbackAmount,
  goodFeedbackAmount
}) => {
  const [badAmount, setBadAmount] = useState(badFeedbackAmount)
  const [goodAmount, setGoodAmount] = useState(goodFeedbackAmount)
  const [helpfulClicked, setHelpfulClicked] = useState(false)
  const [reportClicked, setReportClicked] = useState(false)

  const sendFeedback = async (
    type: FeedbackType,
    voteValue?: FeedbackVoteValue
  ) => {
    try {
      await postReviewFeedback(reviewId, type, voteValue)

      if (type === 'inappropriate') {
        setReportClicked(true)
      } else {
        if (voteValue === 'positive') {
          setGoodAmount(goodAmount + 1)
        } else {
          setBadAmount(badAmount + 1)
        }

        setHelpfulClicked(true)
      }
    } catch (e) {
      datadogRum.addError(e)
    }
  }

  return (
    <Stack
      spacing="4"
      direction={{ initial: 'column', md: 'row' }}
      align={{ initial: 'flex-start', md: 'center' }}
    >
      <Text size="lg">Útil?</Text>

      <Stack spacing="4" direction="row" align="center" isWrap>
        <Button
          size="xs"
          variant="outline"
          appearance="neutral"
          onClick={() => sendFeedback('helpfulness', 'positive')}
          isDisabled={helpfulClicked}
          data-testid="positive-button"
        >
          {`Sim \u22C5 ${goodAmount}`}
        </Button>

        <Button
          size="xs"
          variant="outline"
          appearance="neutral"
          onClick={() => sendFeedback('helpfulness', 'negative')}
          isDisabled={helpfulClicked}
          data-testid="negative-button"
        >
          {`Não \u22C5 ${badAmount}`}
        </Button>

        {!reportClicked && (
          <Button
            size="xs"
            variant="outline"
            appearance="neutral"
            onClick={() => sendFeedback('inappropriate')}
            data-testid="report-button"
          >
            <Hidden above="md">Inapropriado</Hidden>
            <Visible above="md">Conteúdo inapropriado</Visible>
          </Button>
        )}

        {reportClicked && (
          <Text size="sm" color="gray-800" weight="bold">
            Denunciado
          </Text>
        )}
      </Stack>
    </Stack>
  )
}

export default Actions
