import { Heading, Text } from '@backyard-ui/core'

import { normalizePath } from 'scripts/react-components/site/EntryKey/utils/buildUrl'

import { VideoTagSEO } from '../../../page'

interface VideoThumbProps {
  video: VideoTagSEO
}

export const IMAGE_SIZE = {
  height: 300,
  width: 300
}
const VideoThumb = ({ video }: VideoThumbProps) => {
  const { title, picture, url } = video

  return (
    <a
      title={title}
      href={normalizePath(url)}
      className="relative flex w-full mb-6 lg:mb-0"
    >
      <img
        loading="lazy"
        width={IMAGE_SIZE.width}
        height={IMAGE_SIZE.height}
        src={picture}
        alt={title}
        className="rounded-xl w-full h-full"
      />
      <div className="absolute inset-0 bg-black bg-opacity-50 rounded-xl" />

      <div className="absolute z-10 bottom-3 left-3">
        <Heading asChild size="xl" color="white">
          <h4>{title}</h4>
        </Heading>

        <Text asChild size="lg" weight="bold" color="green-600">
          <span>Assista ao vídeo</span>
        </Text>
      </div>
    </a>
  )
}

export default VideoThumb
