import { Button } from '@leroy-merlin-br/backyard-react'

import * as S from './styled'
import { DownloadApp } from './Icons'
import ModalContainer from './Modal/ModalContainer'
import {
  modalOpeningTracking,
  setTimeToDisplayAgain
} from './BannerBehaviour'
import { DragAndDrop } from '../DragAndDrop'

type BannerComponentAppProps = {
  displayComponent: boolean
  datePromotion: string
}

const BannerComponentApp = ({
  datePromotion,
  displayComponent
}: BannerComponentAppProps) => {
  const { openModal } = ModalContainer({ datePromotion })
  const contextFunction = () => {
    modalOpeningTracking()
    openModal()
  }

  return (
    <DragAndDrop
      displayComponent={displayComponent}
      onClose={setTimeToDisplayAgain}
    >
      <S.Wrapper>
        <S.WrapperIcon>
          <DownloadApp />
        </S.WrapperIcon>
        <S.WrapperText>
          <S.WrapperFirstLine>{'Saiba como ganhar '}</S.WrapperFirstLine>
          <S.WrapperSecondLine>{'R$ 10 de desconto'}</S.WrapperSecondLine>
        </S.WrapperText>
        <S.WrapperButton>
          <Button onClick={contextFunction}>{'Saber mais'}</Button>
        </S.WrapperButton>
      </S.Wrapper>
    </DragAndDrop>
  )
}

export default BannerComponentApp
