export const associatedData = ({
  associated: {
    blackList,
    get: { payload },
    put: { error }
  }
}) => ({
  blackList,
  ...payload,
  error
})

export const putStatusProductById = ({
  associated: {
    put: {
      statusIDs
    }
  }
}, id) => {
  return statusIDs[id] || {}
}

export const getAssociatedProductById = ({
  associated: {
    get: {
      payload
    }
  }
}, id) => {
  return payload?.products.find(product => product?.id === id)
}
