import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { styleHelpers } from '@leroy-merlin-br/backyard-react'

const THUMB_SIZE = '25px'

const wrapperStyles = ({ theme }) => css`
  label: range-slider;

  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: ${theme.spacings.giga};
`

const rangeStyles = ({ theme }) => css`
  label: range-slider__slider;

  appearance: none;
  width: 100%;
  height: 5px;
  margin: ${theme.spacings.giga} 0;
  outline: none;
  border-radius: ${theme.borderRadius.mega};
  background: linear-gradient(
    90deg,
    ${theme.colors.p400} 50%,
    ${theme.colors.p600} 100%
  );
  transition: all 200ms linear;
  overflow-x: visible;

  &::-webkit-slider-thumb {
    appearance: none;
    width: ${THUMB_SIZE};
    height: ${THUMB_SIZE};
    border-radius: ${theme.borderRadius.circle};
    border-color: transparent;
    background: ${theme.colors.p500};
    cursor: grab;
    transition: all 100ms linear;
    ${styleHelpers.shadowSingle(theme)};
  }

  &::-moz-range-thumb {
    appearance: none;
    width: ${THUMB_SIZE};
    height: ${THUMB_SIZE};
    border-radius: ${theme.borderRadius.circle};
    border-color: transparent;
    background: ${theme.colors.p500};
    cursor: grab;
    transition: all 100ms linear;
    ${styleHelpers.shadowSingle(theme)};
  }

  &::-ms-track {
    width: 100%;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }

  &:active {
    cursor: grabbing;

    &::-webkit-slider-thumb {
      transform: scale(1.1);
    }
  }
`

const marksStyles = ({ theme }) => css`
  label: range-slider__marks;

  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0;

  & > li {
    ${styleHelpers.subHeadingMega(theme)};

    display: block;
    font-weight: ${theme.font.weight.bold};
    width: ${THUMB_SIZE};
    text-align: center;
    margin: 0;
  }
`

export const Wrapper = styled('div')(wrapperStyles)
export const Range = styled('input')(rangeStyles)
export const Marks = styled('ul')(marksStyles)
