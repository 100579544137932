import styled from '@emotion/styled'
import { css } from '@emotion/react'

const wrapperStyle = () => css`
  display: flex;
  width: 100%;
  gap: 8px;

  &:not(:last-child) .divider {
    display: block;
  }

  &:last-child .divider {
    display: none;
  }
`
export const Wrapper = styled('div')(wrapperStyle)

const flexColumnStyle = () => css`
  display: flex;
  flex-direction: column;
  max-width: 250px;
  flex: 1 1 0;
  gap: 16px;
  justify-content: space-around;
`

export const FlexColumn = styled('div')(flexColumnStyle)

const imageWrapperStyle = () => css`
  flex: 0 1 auto;
`

export const ImageWrapper = styled('div')(imageWrapperStyle)

const flexRowStyle = () => css`
  display: flex;
  gap: 8px;
  width: 100%;
`
export const FlexRow = styled('div')(flexRowStyle)
