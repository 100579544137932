export default {
  MENU_ITEMS: [
    {
      id: 0,
      name: 'Produtos'
    },
    {
      id: 1,
      name: 'Inspiração'
    }
  ],
  DEFAULT_NODES: [
    {
      id: '1',
      name: 'Home',
      url: '/'
    },
    {
      id: '2',
      name: 'Minhas listas de favoritos',
      url: '/minhas-listas-de-desejos'
    }
  ]
}
