import PropTypes from 'prop-types'

const ShellSubElement = ({ items }) => {
  const shells = Array(items).fill('')
  return (
    shells.map((item, index) => (
      <span className="shell-menu-item-loader" key={ `shell-${index}` }></span>
    ))
  )
}

ShellSubElement.propTypes = {
  items: PropTypes.number.isRequired
}

export default ShellSubElement
