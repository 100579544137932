import { FunctionComponent } from 'react'

import { Props } from './types'
import styles from './RatingBar.styles'

const RatingBar: FunctionComponent<Props> = ({ percentage, size = 'md' }) => (
  <div className={styles({ size }).base()} data-testid="rating-bar">
    <span className={styles().fill()} style={{ width: `${percentage}%` }} data-testid="fill-span"/>
  </div>
)

export default RatingBar
