export const PRICE_URL_TEXT = 'preco'

export interface PriceRangeProps {
  [key: string]: string
}

export const priceRangeFilter = (
  routeStatePage: PriceRangeProps,
  numberFilter: number,
  urlToConcatenate: string
): string => {
  const ranges = Object.values(routeStatePage)[0].split(':')
  const range = !ranges[0] ? ranges[1] : ranges.join('-')

  if (urlToConcatenate.includes(PRICE_URL_TEXT)) {
    let [origin, search = ''] = urlToConcatenate.split('?')

    if (search.includes(PRICE_URL_TEXT)) {
      const searchParams = new URLSearchParams(search)

      searchParams.set(`filter[${PRICE_URL_TEXT}]`, range)
      search = decodeURI(searchParams.toString())

      return [origin, search].join('?')
    }

    origin = origin
      .split('/')
      .map((item, idx, arr) => {
        if (idx === arr.length - 1) {
          return range
        }

        return item
      })
      .join('/')

    if (search) {
      return [origin, search].join('?')
    }

    return origin
  }

  if (numberFilter === 0) {
    urlToConcatenate += `/${PRICE_URL_TEXT}/` + range
  } else if (numberFilter === 1) {
    urlToConcatenate += `?filter[${PRICE_URL_TEXT}]=` + range
  } else if (numberFilter > 1) {
    urlToConcatenate += `&filter[${PRICE_URL_TEXT}]=` + range
  }

  return urlToConcatenate
}
