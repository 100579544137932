import { pushDataObject } from 'scripts/utils/data-layer'

import SalesTransformer from './SalesTransformer'

class SalesClick {
  static transformTypes = {
    offers: SalesTransformer.transformToProductPromotionClickData,
    full_banner: SalesTransformer.transformToBannerPromotionData,
    carousel_full_banner: SalesTransformer.transformToBannerPromotionData,
    two_banners: SalesTransformer.transformToBannerPromotionData,
    two_banners_wide: SalesTransformer.transformToBannerPromotionData,
    three_banners: SalesTransformer.transformToBannerPromotionData
  }

  constructor (widgetProps, widgetType = 'banner', position = 0, title = '') {
    this.widgetProps = widgetProps
    this.position = position
    this.widgetType = widgetType
    this.parentWidgetTitle = title

    this.sendClickToDatalayer = this.sendClickToDatalayer.bind(this)
    this.dataObject = this.createSalesClickProductData()
  }

  createSalesClickProductData () {
    return {
      event: 'promotionClick',
      ecommerce: {
        promoClick: {
          promotions: [
            this.setDataObject()
          ]
        }
      }
    }
  }

  isTransformerReference (transformer) {
    return transformer && typeof transformer === 'function'
  }

  setDataObject () {
    const {
      widgetProps,
      position,
      widgetType,
      parentWidgetTitle
    } = this

    const transformer = SalesClick.transformTypes[widgetType]

    return this.isTransformerReference(transformer) && transformer(widgetProps, position, widgetType, parentWidgetTitle)
  }

  sendClickToDatalayer () {
    pushDataObject(this.dataObject)
  }
}

export default SalesClick
