import { useEffect, useRef, useState } from 'react'
import DOMPurify from 'dompurify'

import { errorOnCartPage } from 'scripts/utils/data-layer'

import { AlertMessage } from 'shared/components/AlertMessage'

import * as S from './styled'
import { ERRORS } from '../../services/constants'

const checkIfAllKeywordsInErrorMessage = (keywords, errorMessage) => {
  for (const keyword of keywords) {
    if (!errorMessage.includes(keyword)) {
      return false
    }
  }
  return true
}

const translateErrorMessage = (errorMessage) => {
  const errorTranslatorTable = {
    'Problema de estoque': ['produto', 'disponível'],
    'CEP Inválido': ['momento', 'encontramos', 'CEP'],
    'Região Inválida': ['CEP', 'outra região'],
    'Erro ao atualizar serviço': ['serviço', 'disponível'],
    'Estoque insuficiente': ['quantidade', 'produtos', 'precisa'],
    'Não foi possível calcular o frete': ['calcular', 'frete', 'possível'],
    'CEP inconsistente entre base e Correio': ['localizar', 'CEP']
  }

  for (const [key, keywords] of Object.entries(errorTranslatorTable)) {
    if (checkIfAllKeywordsInErrorMessage(keywords, errorMessage)) {
      return key
    }
  }

  return 'Outros'
}

const Error = ({ errors = {} }) => {
  const [keys, setKeyErrors] = useState([])

  useEffect(() => {
    const errorKeys = Object.keys(errors).filter(
      key => !ERRORS.CEP.includes(key) && !ERRORS.SERVICE.includes(key)
    )

    setKeyErrors(errorKeys)
  }, [errors])

  if (!keys.length) {
    return null
  }

  if (!Object.keys(errors).length) {
    return null
  }

  for (const [key, error] of Object.entries(errors)) {
    error.map((message, _) => {
      errorOnCartPage('validacao_cep', translateErrorMessage(message))
    })
  }

  return (
    <S.Wrapper>
      {keys.map(key => {
        const hasNoErrors = !errors?.[key]

        if (hasNoErrors) {
          return null
        }

        if (key === 'invalid_zip_code') {
          return (<AlertMessage
            key="error-key-message-teste"
            type="critical"
          >
            Infelizmente, não conseguimos localizar seu CEP. Para verificar qual é o seu CEP,{' '}
            <a href="http://www.buscacep.correios.com.br/" target="_blank" rel="noopener noreferrer"> clique aqui </a>
          </AlertMessage>)
        }

        return errors[key].map((message, indexMessage) => (
          <AlertMessage
            key={`error-key-message-${indexMessage}`}
            type="critical"
          >
            <span
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(message) }}
            ></span>
          </AlertMessage>
        ))
      })}
    </S.Wrapper>
  )
}

export default Error
