import { Card, Text } from '@leroy-merlin-br/backyard-react'

interface CashbackErrorProps {
  title?: string
  description?: string
}

export default function CashbackError({
  title = 'Algo errado...',
  description = 'Um erro inesperado aconteceu. Tente mais tarde.'
}: CashbackErrorProps) {
  return (
    <Card title={title}>
      <Text size="kilo" color="n400">
        {description}
      </Text>
    </Card>
  )
}
