import { memo } from 'react'
import { Text } from '@leroy-merlin-br/backyard-react'

import { AlertMessage } from 'shared/components/AlertMessage'

import * as S from './styled'

const CouponAvailable = () => (
  <S.Wrapper>
    <AlertMessage type="info">
      Possui um{' '}
      <Text as="strong" size="kilo" noMargin isBold>
        cupom de desconto
      </Text>{' '}
      ou{' '}
      <Text as="strong" size="kilo" noMargin isBold>
        cashback fidelidade
      </Text>
      ? Aplique na etapa de pagamento.
    </AlertMessage>
  </S.Wrapper>
)

export default memo(CouponAvailable)
