import PropTypes from 'prop-types'

import slugify from 'utils/slugify'

import { GooglePublisherTag } from 'catalog/components/GooglePublisherTag'

import * as S from './styled'

const FooterAd = ({ categoryName }) => {
  const position = 'atf'

  const id = `${categoryName}-ad-${position}`

  const adUnitPath = '/22100203354/atf_rodape_categorias'

  const categorySlug = slugify(categoryName)

  const sizes = [
    { viewport: [0, 0], slot: [300, 250] },
    { viewport: [768, 0], slot: [728, 90] }
  ]

  const targeting = {
    category: categorySlug
  }

  return (
    <S.FooterAd>
      <GooglePublisherTag
        id={id}
        path={adUnitPath}
        sizes={sizes}
        targeting={targeting}
      />
    </S.FooterAd>
  )
}

FooterAd.propTypes = {
  categoryName: PropTypes.string.isRequired
}

export default FooterAd
