import axios from 'axios'

export const getBreadcrumb = ({ basehost, context }) => {
  const baseUrl = basehost ? `${basehost}/api/boitata/v1` : '/api/boitata/v1'

  const endpoint = `${baseUrl}/breadcrumbs/${context.type}/${context.id}`

  const response = axios
    .get(endpoint)
    .then(({ data }) => data)
    .catch(error => {
      throw error.response
    })

  return response
}
