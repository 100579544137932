import { css } from '@emotion/react'
import styled from '@emotion/styled'

const storeInfoStyles = ({ theme }) => css`
  label: store-info;

  display: grid;
  grid-template-columns: auto 1fr;

  > svg {
    margin-top: ${theme.spacings.bit};
    margin-right: ${theme.spacings.byte};
    color: ${theme.colors.p600};
  }
`
const storeNameStyles = ({ theme }) => css`
  label: store-info__store-name;

  font-size: ${theme.typography.text.mega.fontSize};
  line-height: ${theme.typography.text.mega.lineHeight};
`

const stockQuantityStyles = ({ theme }) => css`
  label: store-info__stock-quantity;

  color: ${theme.colors.n400};
  margin-bottom: ${theme.spacings.bit};
`

const inventoryDisclaimerStyles = ({ theme }) => css`
  label: store-info__store-disclaimer;

  margin-bottom: ${theme.spacings.byte};
`

const buttonsStyles = ({ theme }) => css`
  label: store-info__buttons;

  display: flex;
  flex-direction: column;

  margin-top: ${theme.spacings.byte};

  button:not(:first-of-type) {
    margin-left: 0;
    margin-top: ${theme.spacings.kilo};
  }

  ${theme.media.mega} {
    flex-direction: row;

    button:not(:first-of-type) {
      margin-left: ${theme.spacings.byte};
      margin-top: 0;
    }
  }
`

const pickupDisclaimerStyles = ({ theme }) => css`
  label: store-info__disclaimer;

  border-radius: ${theme.borderRadius.mega};
  background-color: ${theme.colors.n100};
  color: ${theme.colors.n900};
  margin-top: ${theme.spacings.giga};
  padding: ${theme.spacings.byte} ${theme.spacings.mega};
`

const pickupDisclaimerListStyles = ({ theme }) => css`
  label: disclaimer__list;

  li:first-child {
    font-weight: ${theme.font.weight.semibold};
  }
`

export const StoreInfo = styled('div')(storeInfoStyles)
export const StoreName = styled('h5')(storeNameStyles)
export const StockQuantity = styled('div')(stockQuantityStyles)
export const InventoryDisclaimer = styled('p')(inventoryDisclaimerStyles)
export const Buttons = styled('div')(buttonsStyles)
export const PickupDisclaimer = styled('div')(pickupDisclaimerStyles)
export const PickupDisclaimerList = styled('ul')(pickupDisclaimerListStyles)
