import { pushDataObject } from './push-data-object'

export type ConciergeItemList = {
  products: string[]
}

export const pushConciergeItemList = ({ products }: ConciergeItemList) => {
  const dataObject = {
    event: 'concierge_send_item_list',
    items: products?.join('|') || undefined
  }

  pushDataObject(dataObject)
}
