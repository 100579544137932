import styled from '@emotion/styled'
import { css } from '@emotion/react'

const messageWrapperStyles = ({ theme }) => css`
  label: warning__message;

  padding: ${theme.spacings.giga};
  display: flex;
  justify-content: center;
`

const wrapperStyles = ({ theme }) => css`
  label: warning__wrapper;

  padding: ${theme.spacings.tera} ${theme.spacings.mega} ${theme.spacings.exa};
  background: ${theme.colors.background};
`

const imageWrapperStyles = () => css`
  label: warning__image;

  display: flex;
  justify-content: center;
  & > img {
    width: 100%;
    height: 100%;
    max-width: 450px;
  }
`

export const Wrapper = styled('div')(wrapperStyles)
export const MessageWrapper = styled('div')(messageWrapperStyles)
export const ImageWrapper = styled('div')(imageWrapperStyles)
