import { css } from '@emotion/react'
import styled from '@emotion/styled'

const filterLabelStyles = ({ theme }) => css`
  label: filter-label;

  display: flex;
  align-items: center;

  text-transform: capitalize;

  svg {
    width: 22px;
    height: 22px;

    margin: 0 ${theme.spacings.byte};
  }

`

export const FilterLabel = styled('div')(filterLabelStyles)
