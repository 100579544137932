import styled from '@emotion/styled'
import { css } from '@emotion/react'

const backgroundStyles = () => css`
  label: coupon__background;
  display: block;
  position: absolute;

  top: -13px;
  left: -11px;
  z-index: 1;
`

export const Background = styled('div')(backgroundStyles)
