export const removeCurrencyFormat = value => {
  const newValue = `${value}`
    .replace(/[^0-9]+/g, '')
    .replace(/^(.*?)(.{2})$/, '$1.$2')

  return Number(newValue)
}

export const formatPriceToBRL = value =>
  parseFloat(value).toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  })
