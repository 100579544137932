import { Fragment, useState, useEffect, useCallback, useMemo } from 'react'
import {
  Text,
  Stack,
  TextField,
  useModal
} from '@leroy-merlin-br/backyard-react'

import emitter from 'scripts/utils/emitter'
import { eventLogGtmProductPageRecontextualization } from 'scripts/utils/data-layer'
import { sendEventToAlgolia } from 'scripts/react-components/shared/analytics/algolia.tracker'

import { useUserResources } from 'shared/hooks'
import { AlertMessage } from 'shared/components/AlertMessage'

import { formatters } from 'utils/formatters'

import { getRegionFromPostalCode } from 'catalog/services/region'
import { getShipments } from 'catalog/services/shipments'
import { ShipmentsOptions } from 'catalog/pages/Product/components/ShipmentsOptions'
import { RegionSelect } from 'catalog/pages/Product/components/RegionSelect'
import { useShipmentsDataLayer } from 'catalog/hooks/use-shipments-data-layer'
import { POSTAL_CODE_STATUS } from 'catalog/utils/constants'
import { pushProductPageShipmentsError } from 'catalog/utils/shipments-data-layer'

import { ErrorNotification } from './components/ErrorNotification'
import { MainShipmentPrices } from './components/MainShipmentPrices'
import * as S from './styled'

const PostalCode = ({
  productId,
  purchaseButtons,
  skus,
  isLespaceProduct,
  isVef
}) => {
  const [status, setStatus] = useState(POSTAL_CODE_STATUS.idle)
  const [postalCode, setPostalCode] = useState('')
  const [shipments, setShipments] = useState([])
  const [errorStatus, setErrorStatus] = useState()
  const [regionData, setRegionData] = useState()

  const { setModal } = useModal()
  const { userResources } = useUserResources()

  const parsedSkus = useMemo(() => JSON.parse(skus), [skus])
  const buttons = useMemo(() => JSON.parse(purchaseButtons), [purchaseButtons])
  const isDisabled = useMemo(() => !buttons.ecommerce.enabled, [buttons])
  const isPickupEnabled = useMemo(
    () => buttons.pickupInStore.enabled,
    [buttons]
  )

  const pushShipmentsToDataLayer = useShipmentsDataLayer(
    postalCode,
    regionData,
    shipments,
    errorStatus,
    parsedSkus
  )

  const fetchShipments = useCallback(
    (zipCode, region) => {
      const fetch = async () => {
        try {
          const shipments = await getShipments(productId, zipCode, region)

          setShipments(shipments)
          setStatus(POSTAL_CODE_STATUS.success)
        } catch (error) {
          setErrorStatus(error.status)
          setStatus(POSTAL_CODE_STATUS.shipments_error)
          pushProductPageShipmentsError(error)
        }
      }

      fetch()
    },
    [productId]
  )

  const onPostalCodeChange = e => {
    e.stopPropagation()

    const inputPostalCode = formatters.cep(e.target.value)

    if (inputPostalCode.length <= 9) {
      setPostalCode(inputPostalCode)
      setStatus(POSTAL_CODE_STATUS.invalid_cep)
    }

    if (inputPostalCode.length === 0) {
      setStatus(POSTAL_CODE_STATUS.idle)
      setErrorStatus(0)
      setShipments([])
    }
  }

  const closeRegionSelectModal = useCallback(() => {
    setStatus(POSTAL_CODE_STATUS.idle)
    setShipments([])
    setErrorStatus(0)
    setPostalCode('')
  }, [])

  const showRegionSelectModal = useCallback(
    ({ postalCode, region }) => {
      setModal({
        onClose: closeRegionSelectModal,
        children: ({ onClose }) => (
          <RegionSelect
            onClose={onClose}
            postalCode={postalCode}
            region={region}
          />
        )
      })
    },
    [closeRegionSelectModal, setModal]
  )

  useEffect(() => {
    if (status.state === 'valid') {
      pushShipmentsToDataLayer()
    }
  }, [pushShipmentsToDataLayer, status])

  useEffect(() => {
    if (shipments || errorStatus) {
      emitter.emit('shipments:change', {
        shipments,
        shipmentsErrorStatus: errorStatus,
        status: status,
        postalCode: postalCode,
        city: regionData?.city,
        stateCode: regionData?.stateCode
      })
    }
  }, [shipments, errorStatus, postalCode, regionData, status])

  useEffect(() => {
    if (!isDisabled && userResources?.zipCode) {
      const setPostalCodeFromUserResources = async () => {
        const response = await getRegionFromPostalCode(userResources?.zipCode)

        if (response.region === userResources?.region) {
          setPostalCode(userResources?.zipCode)
        }
      }

      setPostalCodeFromUserResources()
    }
  }, [isDisabled, userResources, fetchShipments])

  useEffect(() => {
    if (postalCode.length <= 8) {
      return
    }

    setErrorStatus(0)
    setShipments([])
    setStatus(POSTAL_CODE_STATUS.loading)

    const getRegionAndShipments = async () => {
      try {
        const result = await getRegionFromPostalCode(postalCode)

        if (result.region === userResources?.region) {
          setRegionData(result)
          fetchShipments(postalCode, result.region)

          return
        }

        setStatus(POSTAL_CODE_STATUS.idle)

        showRegionSelectModal({ postalCode, region: result })
        eventLogGtmProductPageRecontextualization()
      } catch (error) {
        setStatus(POSTAL_CODE_STATUS.invalid_cep)
        pushProductPageShipmentsError(error)
      }
    }

    getRegionAndShipments()
  }, [postalCode, fetchShipments, userResources?.region, showRegionSelectModal])

  if (isLespaceProduct) {
    return (
      <AlertMessage type="info">
        Esse produto faz parte da{' '}
        <Text as="strong" size="kilo" isBold noMargin>
          gama exclusiva da loja L’Espace.{' '}
        </Text>
        Para consultar o preço do frete, adicione o produto ao carrinho!
      </AlertMessage>
    )
  }

  if (isVef) {
    return (
      <AlertMessage type="info">
        Para consultar o preço do frete para esse produto, adicione ao carrinho!
      </AlertMessage>
    )
  }

  return (
    <S.PostalCode>
      <S.InputGrid>
        <TextField
          label="Calcule seu frete"
          name="postal-code"
          placeholder="CEP"
          onChange={onPostalCodeChange}
          value={postalCode}
          state={status.state}
          hint={status.hint}
          isDisabled={isDisabled}
          size="kilo"
          onFocus={() => sendEventToAlgolia()}
        />

        <S.Link
          href="http://www.buscacep.correios.com.br/sistemas/buscacep/"
          target="_blank"
          rel="noopener noreferrer"
          as={isDisabled ? 'span' : 'a'}
          isDisabled={isDisabled}
          onClick={() => sendEventToAlgolia()}
        >
          Não sei meu CEP
        </S.Link>
      </S.InputGrid>

      <Stack space="kilo">
        {shipments.length === 1 && parsedSkus.length === 1 && (
          <Fragment>
            <S.Separator />
            <MainShipmentPrices
              {...shipments[0]}
              isPickupEnabled={isPickupEnabled}
            />
          </Fragment>
        )}

        {shipments.length > 0 && regionData && (
          <Fragment>
            {parsedSkus.length > 1 && <S.Separator />}
            <ShipmentsOptions
              shipments={shipments}
              skus={parsedSkus}
              region={regionData}
              onClick={() => sendEventToAlgolia()}
            />
          </Fragment>
        )}
      </Stack>

      {(shipments.length > 0 || errorStatus > 0 || isDisabled) && (
        <S.Separator />
      )}

      {shipments.length > 0 && (
        <Text size="kilo" noMargin color="n600">
          <Text as="strong" size="kilo" isBold noMargin>
            *Atenção!{' '}
          </Text>
          O prazo e valores de frete
          <Text as="strong" size="kilo" isBold noMargin>
            {' '}
            podem variar{' '}
          </Text>
          conforme a quantidade e disponibilidade dos itens adicionados ao seu
          carrinho. Os tempos de entrega e retirada se{' '}
          <Text as="strong" size="kilo" isBold noMargin>
            iniciam após a confirmação do seu pagamento
          </Text>
          .
        </Text>
      )}

      {errorStatus > 0 && <ErrorNotification errorStatus={errorStatus} />}

      {isDisabled && (
        <Text size="kilo" color="n900" noMargin>
          <Text as="strong" size="kilo" isBold noMargin>
            Atenção!{' '}
          </Text>
          Este produto não está disponível para entrega e por isso a calculadora
          de frete está desativada.
        </Text>
      )}
    </S.PostalCode>
  )
}

export default PostalCode
