import qs from 'qs'
import { flatten } from 'lodash'

export const getCartRegion = ({
  cart: {
    region
  }
}) => region

export const getShippings = (state) => state.cart.shippings

export const stringifiedProductsId = ({ cart }) => {
  const ids = flatten(
    Object.keys(cart.shippings)
      .map(shippingType =>
        cart.shippings[shippingType].products.map(product =>
          ({ id: product.productId, skuId: product.skuId }))
      )
  )

  return qs.stringify(
    {
      regionId: cart.region,
      products: ids
    },
    {
      addQueryPrefix: true,
      arrayFormat: 'indices',
      encode: false,
      skipNulls: true
    }
  )
}
