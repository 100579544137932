import { useEffect, useState } from 'react'
import { datadogRum } from '@datadog/browser-rum'

import * as recommendationRepository from 'scripts/api/recommendation'

const useRecommendationProducts = (products = []) => {
  const productsString = JSON.stringify(products)

  const [result, setResult] = useState([])
  const [isFetching, setIsFetching] = useState(false)

  useEffect(() => {
    const products = JSON.parse(productsString)

    setResult([])

    if (products.length) {
      setIsFetching(true)
      ;(async () => {
        try {
          const { data: result, status } =
            await recommendationRepository.getProducts(products)

          if (status === 200) {
            setResult(result)
          }
        } catch (e) {
          datadogRum.addError(e)
        } finally {
          setIsFetching(false)
        }
      })()
    }
  }, [productsString])

  return { result, isFetching }
}

export default useRecommendationProducts
