import { Constants } from '.'

export const getCustomers = () => ({
  type: Constants.CUSTOMERS_GET
})

export const getSuccess = payload => ({
  type: Constants.CUSTOMERS_GET_SUCCESS,
  payload
})

export const getFailure = error => ({
  type: Constants.CUSTOMERS_GET_FAILURE,
  error
})
