import PropTypes from 'prop-types'
import Cookies from 'js-cookie'
import { Minimal } from '@leroy-merlin-br/backpack-header'

import { Header } from '../Header'
import { Footer } from '../Footer'
import * as S from './styled'

const AppLayout = ({ children, isNewHeaderEnabled }) => {
  const isOnMobileApp = Cookies.get('is_on_mobile_app') || false

  const HeaderComponent = isNewHeaderEnabled ? Minimal : Header

  return (
    <S.Layout>
      {!isOnMobileApp && <HeaderComponent />}
      {children}
      {!isOnMobileApp && <Footer />}
    </S.Layout>
  )
}

AppLayout.propTypes = {
  children: PropTypes.node
}

export default AppLayout
