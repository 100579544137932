import DropdownProviderMethods from 'site/Header/providers/DropdownProviderMethods'

import { User } from './components/User'
import SubLevels from './components/SubLevels'
import { FirstLevel } from './components/FirstLevel'

const MobileMenu = () => {
  return (
    <div className="menu-list-mobile" data-mobile-menu>
      <User />
      <DropdownProviderMethods>
        <FirstLevel />
        <SubLevels />
      </DropdownProviderMethods>
    </div>
  )
}

export default MobileMenu
