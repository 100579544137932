import pluralize from 'utils/pluralize'

export const WITHIN_23_HOURS = '23'

export const shipmentsDictionary = {
  today: 'Entrega',
  express: 'Entrega',
  marketplace_express: 'Entrega',
  pickupInStore: 'Retirada na loja',
  scheduled: 'Entrega agendada',
  lespace_ad_scheduled: 'Agende para entrega',
  marketplace_scheduled: 'Agende para entrega',
  standard: 'Entrega'
}

const LABELS_WITHOUT_DEADLINE = [
  'scheduled',
  'lespace_ad_scheduled',
  'marketplace_scheduled'
]

export const getShipmentMessage = (modality, deadline) => {
  const label = deadline === 0 ? 'today' : modality
  const timeType = modality === 'pickupInStore' ? 'hours' : 'days'

  const getDeadlineMessage = () => {
    if (deadline === undefined || deadline === null) {
      return workingHoursText(WITHIN_23_HOURS)
    }

    if (!LABELS_WITHOUT_DEADLINE.includes(label)) {
      return timeType === 'hours'
        ? workingHoursText(deadline)
        : workingDaysText(deadline)
    }

    return ''
  }

  return {
    label: shipmentsDictionary[label],
    deadlineMessage: getDeadlineMessage()
  }
}

const prefixDictionary = {
  today: 'no',
  schedule: 'em até'
}

const buildMainMessage = (number, type) =>
  `${number} ${pluralize(number, type)}`

export const workingHoursText = workingHours => ({
  prefix: prefixDictionary.schedule,
  main: buildMainMessage(workingHours, 'hours')
})

export const workingDaysText = workingDays => {
  const isToday = workingDays === 0
  const prefix = prefixDictionary[isToday ? 'today' : 'schedule']
  const main = isToday
    ? 'mesmo dia'
    : buildMainMessage(workingDays, 'shipmentDeadline')

  return { prefix, main }
}
