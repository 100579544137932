import styled from '@emotion/styled'
import { css } from '@emotion/react'

const headerStyles = () => css`
  label: header;

  position: relative;
  z-index: 50;
`
export const Header = styled('div')(headerStyles)
