import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { Theme } from 'newCheckout/types/Theme'

const indentTextStyles = ({ theme }:Theme) => css`
  label: indent;
  
  margin-left: ${theme.spacings.kilo};
`
export const IndentText = styled('div')(indentTextStyles)
