import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { Theme } from 'scripts/react-components/themes/Theme'

const footerStyles = ({ theme }: Theme) => css`
  label: chatmodal_container-footer;

  padding: ${theme.spacings.kilo};
  padding-bottom: ${theme.spacings.tera};
`

export const Footer = styled('div')(footerStyles)
