import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { Theme } from 'scripts/react-components/themes/Theme'

import { MessageType } from '../../types'

type Type = { type: MessageType }

const messageWrapperStyle = ({ type }: Type) => css`
  label: chat-message-wrapper;

  display: flex;
  flex-direction: ${type === 'outcome' ? 'row-reverse' : 'row'};
  scroll-snap-align: end;
  padding-bottom: 5px;
`

const baloonsContainerStyle = () => css`
  label: baloons-container;

  padding: 0 11px;
  display: grid;
  grid-gap: 6px;
`

const baloonStyle = ({ theme }: Theme) => css`
  label: baloon;

  border-radius: ${theme.borderRadius.mega};
  background: ${theme.colors.p600};
  padding: ${theme.spacings.kilo};
  position: relative;

  color: ${theme.colors.white};
  font-size: ${theme.typography.text.kilo.fontSize};
  font-feature-settings: 'clig' off, 'liga' off;
  line-height: 120%;
  white-space: pre-line;
  word-break: break-word;

  a {
    cursor: pointer;
    color: ${theme.colors.n800};
    text-decoration: underline;
  }
`

const baloonOutcomeStyle = ({ theme, type }: Theme & Type) =>
  type === 'outcome' &&
  css`
    label: baloon--outcome;

    color: ${theme.colors.n900};
    background: ${theme.colors.white};

    &::before {
      color: ${theme.colors.n900};
      background-color: ${theme.colors.white};
      fill: ${theme.colors.p100};
      left: auto;
      right: -5px;
    }
  `

export const MessageWrapper = styled('div')(messageWrapperStyle)
export const BaloonsContainer = styled('div')(baloonsContainerStyle)
export const Baloon = styled('div')(baloonStyle, baloonOutcomeStyle)
