import { tv } from 'tailwind-variants'

const styles = tv({
  base: `
    grid
    gap-2
    list-inside
  `,
  variants: {
    ordered: {
      true: 'list-decimal',
      false: 'list-disc'
    }
  }
})

export default styles
