import { Text, Heading } from '@leroy-merlin-br/backyard-react'

import { ShippingItemUI } from 'frontendCheckout/ui/v1/ShippingItem'
import { numberToPrice } from 'frontendCheckout/utils/formatters'

type ShippingServiceProps = {
  service: string
}

export const ShippingService = ({ service }: ShippingServiceProps) => {
  const serviceParsed = JSON.parse(service)

  return (
    <ShippingItemUI.Root>
      <ShippingItemUI.InstalaLogo />
      <div className="flex-grow">
        <ShippingItemUI.Badge label="Serviço de Instalação" />
        <Heading size="kilo" color="g600" as="h3" noMargin>
          {serviceParsed.name}
        </Heading>

        <Text size="kilo" color="n400" noMargin>
          Serviço de Instalação
        </Text>

        {serviceParsed?.relatedProduct?.productId && (
          <>
            <Text size="kilo" color="n400" noMargin>
              Para o produto
            </Text>
            <Text size="kilo" isBold color="n900" noMargin>
              {serviceParsed.relatedProduct.name}
            </Text>
            <Text size="kilo" color="n400" noMargin>
              Cód. do produto: {serviceParsed.relatedProduct.productId}
            </Text>
          </>
        )}

        <ShippingItemUI.ServicePriceTag>
          <div className="flex items-baseline">
            <Text size="kilo" color="n400" noMargin>
              Valor:
            </Text>{' '}
            <Text size="kilo" isBold color="n900" noMargin>
              {numberToPrice(serviceParsed.price.to)}
            </Text>
          </div>

          <div className="flex items-baseline">
            <Text size="kilo" color="n400" noMargin>
              Qtde:
            </Text>{' '}
            <Text size="kilo" isBold color="n900" noMargin>
              {serviceParsed.quantity}
            </Text>
          </div>

          <div className="flex items-baseline">
            <Text size="kilo" color="n400" noMargin>
              Total:
            </Text>{' '}
            <Text size="kilo" isBold color="n900" noMargin>
              {numberToPrice(serviceParsed.price.total)}
            </Text>
          </div>
        </ShippingItemUI.ServicePriceTag>
      </div>
    </ShippingItemUI.Root>
  )
}
