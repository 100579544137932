import { css } from '@emotion/react'
import styled from '@emotion/styled'

const baseStyles = ({ theme }) => css`
  label: price-tag;

  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;

  background-color: ${theme.colors.white};
  border-top: 4px solid ${theme.colors.p600};
  margin: 0 10px;

  ${theme.media.mega} {
    margin: 0;
  }
`

const promotionalStyles = ({ theme, isPromotional }) =>
  isPromotional &&
  css`
    label: price-tag--promotional;

    border: none;
    padding-bottom: ${theme.spacings.bit};

    & ${ToPriceContainer} {
      label: price-tag__to-price--promotional;

      color: ${theme.colors.r600};
      background-color: ${theme.colors.y500};
      padding: ${theme.spacings.kilo} 0 ${theme.spacings.kilo} ${theme.spacings.mega};
      margin-bottom: ${theme.spacings.byte};
    }
  `

const outletStyles = ({ theme, isOutlet }) =>
  isOutlet &&
  css`
    label: price-tag--black-weekend;

    border: none;
    padding-bottom: ${theme.spacings.bit};

    & ${ToPriceContainer} {
      label: price-tag__to-price--black-weekend;

      color: ${theme.colors.p500};
      background-color: ${theme.colors.black};
      padding: ${theme.spacings.kilo} 0 ${theme.spacings.kilo} ${theme.spacings.mega};
      margin-bottom: ${theme.spacings.byte};
    }
  `

const unavailableStyles = ({ theme, isUnavailable }) =>
  isUnavailable &&
  css`
    label: price-tag--sold-out;

    border-color: ${theme.colors.n600};
  `

const mainWrapperStyles = () => css`
  label: price-tag__main;

  flex: 1;
`

const toPriceContainerStyles = ({ theme }) => css`
  label: price-tag__to-price;

  width: 100%;
  flex-wrap: nowrap;
  font-size: ${theme.typography.headings.tera.fontSize};
  font-weight: ${theme.font.weight.bold};
`

const toPriceIntegerStyles = () => css`
  label: to-price__integer;

  font-size: 30px;
`

const priceUnitStyles = ({ theme }) => css`
  label: to-price__unit;

  font-size: ${theme.typography.text.mega.fontSize};
`

const containerStyles = () => css`
  label: price-tag__container;

  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;

  width: 100%;
`

const shopNameStyles = ({ theme }) => css`
  label: price-tag__shop-name;

  margin: ${theme.spacings.bit} 0;
`

const warningNotificationStyle = ({ theme }) =>
  css`
    label: price-tag__warning;

    background-color: ${theme.colors.y100};
    border: 1px solid ${theme.colors.y500};
    border-radius: ${theme.borderRadius.mega};
    color: ${theme.colors.n800};
    padding: ${theme.spacings.kilo};
  `

const packagingPriceStyles = ({ theme }) => css`
  label: price-tag__packaging-price;

  width: 100%;
  background: ${theme.colors.p200};
  margin: ${theme.spacings.bit} 0;
  padding: ${theme.spacings.byte} ${theme.spacings.kilo};
`

const dotStyles = ({ theme }) => css`
  label: price-tag__packaging-price-dot;

  &::after {
    content: '';

    display: inline-block;

    width: ${theme.spacings.bit};
    height: ${theme.spacings.bit};

    background-color: ${theme.colors.n900};

    vertical-align: middle;

    border-radius: ${theme.borderRadius.circle};
  }
`

const outletBadgeStyles = ({ theme }) => css`
  label: buybox__badge--outlet;

  & > div {
    background-color: ${theme.colors.black};
    color: ${theme.colors.white};
  }
`

export const PriceTag = styled('div')(
  baseStyles,
  promotionalStyles,
  outletStyles,
  unavailableStyles
)
export const MainWrapper = styled('div')(mainWrapperStyles)
export const Dot = styled('span')(dotStyles)
export const ToPriceContainer = styled('div')(toPriceContainerStyles)
export const ToPriceInteger = styled('span')(toPriceIntegerStyles)
export const PriceUnit = styled('span')(priceUnitStyles)
export const Container = styled('div')(containerStyles)
export const ShopName = styled('div')(shopNameStyles)
export const WarningNotification = styled('div')(warningNotificationStyle)
export const PackagingPrice = styled('div')(packagingPriceStyles)
export const OutletBadgeWrapper = styled('div')(outletBadgeStyles)
