import styled from '@emotion/styled'
import { css } from '@emotion/react'

const wrapperStyles = ({ theme }) => css`
  label: history_wrapper;

  padding-bottom: ${theme.spacings.byte};

  h1 {
    font-weight: ${theme.font.weight.regular};
    margin-bottom: ${theme.spacings.byte};
  }
`

const wrapperBottomStyles = ({ theme, hasTerms }) =>
  hasTerms &&
  css`
    label: history_wrapper--bottom;

    border-bottom: 1px solid ${theme.colors.n100};
    margin-bottom: 20px;
  `

const linkWrapperStyles = ({ theme }) => css`
  label: history_link-wrapper;

  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: ${theme.spacings.byte};

  padding: 0 ${theme.spacings.byte};

  a {
    text-transform: capitalize;
    color: ${theme.colors.black};
    overflow: hidden;
    width: 100%;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-word;
  }

  ${theme.media.kilo} {
    a {
      -webkit-line-clamp: 1;
    }
  }

  a:hover {
    text-decoration: underline;
  }

  svg {
    flex-shrink: 0;
  }
`

const linkWrapperWithSearchResults = ({ theme, hasTerms }) =>
  hasTerms &&
  css`
    label: history_link-wrapper--with-search-results;

    a {
      -webkit-line-clamp: 2;
    }

    ${theme.media.giga} {
      a {
        width: 200px;
      }
    }
  `

export const Wrapper = styled('div')(wrapperStyles, wrapperBottomStyles)
export const LinkWrapper = styled('div')(linkWrapperStyles, linkWrapperWithSearchResults)
