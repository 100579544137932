import { useEffect } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'

import { Actions } from 'lmcv/redux/modules/coupon'
import { CouponsCard } from 'lmcv/components/CouponsCard'

import { useFilterVoucherByUrl } from '../../hooks/useFilterVoucherByUrl'

const ExchangePage = () => {
  const dispatch = useDispatch()
  const { money, activated, moneyPaginated } = useSelector(
    state => state.coupon,
    shallowEqual
  )

  const { filterVoucherByUrl } = useFilterVoucherByUrl('money')

  useEffect(() => {
    filterVoucherByUrl()
  }, [filterVoucherByUrl])

  useEffect(() => {
    dispatch(Actions.getMoney(1, 'available', 15))
    dispatch(Actions.getActivated(1, 'money', 15))
  }, [dispatch])

  const getVouchers = () => Actions.getMoney(1, 'available', 15)
  const getActivated = () => Actions.getActivated(1, 'money', 15)
  const getPaginated = (pagination, type) =>
    Actions.getMoneyPaginated(pagination, type, 15)

  return (
    <CouponsCard
      title="Cashback em pontos"
      vouchers={money}
      activated={activated}
      paginated={moneyPaginated}
      getVouchers={getVouchers}
      getActivated={getActivated}
      getPaginated={getPaginated}
    />
  )
}

export default ExchangePage
