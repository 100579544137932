import { WhatsAppLogo } from './Icon'
import * as S from './styled'

const WhatsApp = ({ pageType }) => {
  const isProductPage = pageType === 'product'
  const defaultText = 'Ol%C3%A1,+desejo+comprar+um+produto'
  const productUrl = document.location.href
  const productText = `Ol%C3%A1,+desejo+comprar+esse+produto:+${productUrl}`
  const text = isProductPage ? productText : defaultText

  return (
    <S.Wrapper
      href={`https://api.whatsapp.com/send?phone=551140071380&text=${text}`}
      rel="noopener noreferrer"
      target="_blank"
    >
      <WhatsAppLogo />
    </S.Wrapper>
  )
}

export default WhatsApp
