import request from './axios'

const SIGNUP_ENDPOINT = `${
  window.env && window.env.baseHost
}/api/v2/loyaltyProgram/documentation`

export const sendDocument = async data => {
  return (
    await request.post(SIGNUP_ENDPOINT, data, {
      headers: { 'content-type': 'multipart/form-data' }
    })
  ).data
}
