import { useEffect, useMemo, useState } from 'react'
import { Icon } from '@leroy-merlin-br/backyard-react'
import { UserOutline, BuildingOutline } from '@backyard-ui/icons'

import { useUserResources, useLoyaltyProfile } from 'shared/hooks'

import * as S from './styled'

const Avatar = ({ isSmall, maskColor = 'white' }) => {
  const { userResources, userControls } = useUserResources()
  const { loyaltyStatus } = useLoyaltyProfile()

  const isUserActiveOnLoyalty = useMemo(() => loyaltyStatus?.isActive(), [
    loyaltyStatus
  ])

  const renderAvatarInner = () => {
    if (!userControls.isLoggedIn) {
      return <Icon as={UserOutline} />
    }

    if (userResources?.isLegalUser) {
      return <Icon as={BuildingOutline} />
    }

    return <span>{userResources?.name?.[0]}</span>
  }

  const [isImageValid, setIsImageValid] = useState(!!userResources?.picture)

  useEffect(() => setIsImageValid(!!userResources?.picture), [userResources])

  return (
    <S.Wrapper
      isOnLoyalty={isUserActiveOnLoyalty}
      maskColor={maskColor}
      isSmall={isSmall}
    >
      {isImageValid ? (
        <S.UserImage
          onError={() => setIsImageValid(false)}
          src={userResources?.picture}
          alt={`Imagem de perfil de ${userResources?.name}`}
        />
      ) : (
        <S.UserIcon>{renderAvatarInner()}</S.UserIcon>
      )}
    </S.Wrapper>
  )
}

export default Avatar
