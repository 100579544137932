import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { Theme } from 'scripts/react-components/themes/Theme'

const wrapperStyles = ({ theme }: Theme) => css`
  label: wrapper;

  margin-top: ${theme.spacings.byte};

  width: 350px;

  h4 {
    margin-top: ${theme.spacings.byte};
    line-height: 18px;
    font-weight: 400;
  }

  & button[type='submit'] {
    margin-top: ${theme.spacings.bit};
    margin-bottom: ${theme.spacings.giga};
  }
`

export const Wrapper = styled('div')(wrapperStyles)
