import * as S from './styled'

const PriceTagLabel = ({
  discount,
  isOutlet,
  hasFromPrice,
  featureInstallmentDiscount
}) => {
  const showDiscount = discount > 0

  if (isOutlet) {
    return (
      <S.PriceTagLabel>
        <span>BLACK FRIDAY</span>

        {showDiscount && <span>-{discount}%</span>}
      </S.PriceTagLabel>
    )
  }

  if (featureInstallmentDiscount) {
    return <></>
  }

  if (hasFromPrice) {
    return (
      <S.PriceTagLabel
        isPromotional
        data-testid="price-tag-label"
        data-cy="offer-tag"
      >
        <span>OFERTA</span>

        {showDiscount && <span>-{discount}%</span>}
      </S.PriceTagLabel>
    )
  }
}

export default PriceTagLabel
