import isPropValid from '@emotion/is-prop-valid'
import { css } from '@emotion/react'
import styled from '@emotion/styled'

const sizesDictionary = {
  kilo: '40px',
  mega: '60px',
  giga: '80px',
  tera: '90px'
}

const responsiveSizesStyles = ({ theme, sizes }) => {
  let styles = ''

  for (const key in sizes) {
    styles += `
      ${theme.media[key]} {
        label: thumbnail--screen-${key};

        height: ${sizesDictionary[sizes[key]] || sizes[key]};
        min-height: ${sizesDictionary[sizes[key]] || sizes[key]};
        width: ${sizesDictionary[sizes[key]] || sizes[key]};
        min-width: ${sizesDictionary[sizes[key]] || sizes[key]};
      }
    `
  }

  return css`
    ${styles}
  `
}

const wrapperStyles = ({ theme }) => css`
  label: thumbnail;

  height: ${sizesDictionary.kilo};
  min-height: ${sizesDictionary.kilo};
  width: ${sizesDictionary.kilo};
  min-width: ${sizesDictionary.kilo};

  border: 1px solid ${theme.colors.n300};
  border-radius: ${theme.borderRadius.giga};
`

const borderStyles = ({ noBorder }) =>
  noBorder &&
  css`
    label: thumbnail--borderless;

    border: none;
  `

const imageStyles = ({ theme }) => css`
  label: thumnail__image;

  height: 100%;
  width: 100%;

  border-radius: ${theme.borderRadius.giga};
`

export const Wrapper = styled('div', {
  shouldForwardProp: prop =>
    isPropValid(prop) && prop !== 'sizes'
})(
  wrapperStyles,
  borderStyles,
  responsiveSizesStyles
)
export const Image = styled('img')(imageStyles)
