import type { PRODUCT_DISCOUNT_TYPE } from 'newCheckout/dataLayer/transaction'

import type { DataLayerItemObject, ServiceObject } from '../components/types'
import type {
  DATA_LAYER_DISCOUNT_TYPE,
  BuildTrackerItems,
  DataLayerItems,
  TrackerItem
} from './types'

export const buildTrackerItems = (props: BuildTrackerItems) => {
  const { shippings = [], services = [], zipCode, region } = props

  const trackerItems: TrackerItem[] = []

  const city = region?.city ? region.city : undefined
  const stateCode = region?.stateCode ? region.stateCode : undefined

  shippings.map((shipping) => {
    const isPickup = shipping?.type === 'pickupInStore'
    const storeName = isPickup ? shipping.storeName : undefined
    const deliveryType = isPickup ? 'pickup-in-store' : 'delivery'

    shipping.shipments.map((shipment) => {
      const modality = shipment.modalities.find(
        (modality) => modality.type === 'standard'
      )

      const deadline = modality ? modality.deadline : undefined
      const zipCodeFormatted = zipCode ? zipCode.replace('-', '') : undefined

      shipment.items.map((item) => {
        const hasOffer = Boolean(item.price.discount)
        const isByPaymentMethod =
          hasOffer && Boolean(item.discountByPaymentMethod)
        const discountType = isByPaymentMethod ? 'by-payment-method' : 'offer'

        trackerItems.push({
          id: item.productId,
          productId: undefined,
          name: item.name,
          brand: item.brand,
          categoryTree: item.categoryTree,
          seller: item.shopName,
          quantity: item.packagingCount,
          price: item.price.to,
          discount: hasOffer ? item.price.discount : undefined,
          discountType: hasOffer ? discountType : undefined,
          variant: undefined,
          delivery: {
            type: deliveryType,
            deadline: isPickup ? undefined : deadline,
            city: isPickup ? undefined : city,
            stateCode: isPickup ? undefined : stateCode,
            cep: isPickup ? undefined : zipCodeFormatted,
            storeName: isPickup ? storeName : undefined,
            storeAvailableTime: isPickup ? undefined : undefined
          }
        })
      })
    })
  })

  services.map((service) => {
    trackerItems.push({
      id: service.serviceId,
      productId: service.relatedProduct.productId,
      name: service.name,
      brand: 'Leroy Merlin',
      categoryTree: 'serviço',
      seller: 'Leroy Merlin',
      quantity: service.quantity,
      price: service.price.to,
      discount: undefined,
      discountType: undefined,
      variant: undefined,
      delivery: {
        type: 'delivery',
        deadline: undefined,
        city: undefined,
        stateCode: undefined,
        cep: undefined,
        storeName: undefined,
        storeAvailableTime: undefined
      }
    })
  })

  return trackerItems
}

export const getDataLayerItems = (items: TrackerItem[]): DataLayerItems[] => {
  return items.map((item) => {
    const category = item.categoryTree.split('/')

    return {
      id: item.id,
      bind_id: item.productId,
      name: item.name,
      price: item.price,
      discount: item.discount,
      discount_type: item.discountType,
      brand: item.brand,
      category: category[0] || undefined,
      category2: category[1] || undefined,
      category3: category[2] || undefined,
      category4: category[3] || undefined,
      category5: category[4] || undefined,
      variant: undefined,
      seller: item.seller,
      delivery_type: item.delivery.type,
      delivery_time: item.delivery.deadline,
      delivery_city: item.delivery.city,
      delivery_state_code: item.delivery.stateCode,
      delivery_cep: item.delivery.cep,
      pickup_store: item.delivery.storeName,
      pickup_available_time: item.delivery.storeAvailableTime,
      quantity: item.quantity
    }
  })
}

export const getDataLayerProducts = (items: DataLayerItemObject[]) => {
  return items.map((item) => {
    const dimensions = item.dimensions[0]
    const category = item.productCategory ? item.productCategory.split('/') : []

    const deliveryType =
      dimensions.deliveryMode === 'delivery' ? 'delivery' : 'pickup-in-store'

    const discountType = getDiscountType(dimensions.discountType)

    return {
      name: item.itemName,
      id: item.productId,
      bind_id: undefined,
      type: 'product',
      price: item.price,
      brand: item.brand,
      category: category.length > 0 ? category[0] : undefined,
      category2: category.length > 0 ? category[1] : undefined,
      category3: category.length > 0 ? category[2] : undefined,
      category4: category.length > 0 ? category[3] : undefined,
      category5: category.length > 0 ? category[4] : undefined,
      variant: undefined,
      seller: dimensions.marketplace,
      delivery_type: deliveryType,
      discount: dimensions.discount ? dimensions.discount : undefined,
      discount_type: discountType,
      quantity: item.packagingCount
    }
  })
}

export const getDataLayerServices = (services: ServiceObject[]) => {
  return services.map((service) => ({
    name: service.name,
    id: service.serviceId,
    bind_id: service.relatedProduct.productId,
    type: 'service',
    price: service.price.to,
    brand: 'Leroy Merlin',
    category: 'serviço',
    category2: undefined,
    category3: undefined,
    category4: undefined,
    category5: undefined,
    variant: undefined,
    seller: 'Leroy Merlin',
    delivery_type: undefined,
    discount: undefined,
    discount_type: undefined,
    quantity: service.quantity
  }))
}

export const getShippingType = (items: string[]) => {
  if (items.length === 0) return undefined

  const isAllDelivery = items.every((item) => item === 'delivery')
  const isAllPickup = items.every((item) => item !== 'delivery')

  const isHybrid = !isAllDelivery && !isAllPickup

  const shippingType = items[0] === 'delivery' ? 'delivery' : 'pickup-in-store'

  return isHybrid ? 'hybrid' : shippingType
}

export const getCartType = (items: string[]) => {
  if (items.length === 0) return undefined

  const isAll1P = items.every((item) => item === 'Leroy Merlin')
  const isAll3P = items.every((item) => item !== 'Leroy Merlin')

  const isMixed = !isAll1P && !isAll3P
  const cartType = isAll1P ? '1P' : '3P'

  return isMixed ? '1P & 3P' : cartType
}

export const getItemTotalPrice = (price: number, quantity: number) => {
  return parseFloat((price * quantity).toFixed(2))
}

export const getItemDiscount = (toPrice: number, fromPrice?: number) => {
  if (!fromPrice) return 0

  return parseFloat((fromPrice - toPrice).toFixed(2))
}

export const getDiscountType = (type: PRODUCT_DISCOUNT_TYPE) => {
  const dataLayerDiscountType: Record<string, DATA_LAYER_DISCOUNT_TYPE> = {
    stampedPrice: 'stamped',
    employee: 'employee',
    loyaltyProgram: 'loyalty',
    'payment-method': 'by-payment-method',
    promo: 'linx',
    '': undefined
  }

  return dataLayerDiscountType[type] ? dataLayerDiscountType[type] : undefined
}
