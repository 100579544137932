import styled from '@emotion/styled'
import { css } from '@emotion/react'

const photoStyles = ({ theme }) => css`
  label: photo;

  height: 88px;
  margin: 0 auto;
  margin-bottom: ${theme.spacings.mega};
  width: 88px;
`

export const Photo = styled('div')(photoStyles)

const projectStatusWrapperStyles = ({ theme, color }) => css`
  label: project__status;

  display: flex;
  position: relative;
  align-items: baseline;
  justify-content: center;

  &::before {
    border-radius: ${theme.borderRadius.circle};
    background: ${theme.colors[color]};
    content: '';
    display: inline-block;
    height: 8px;
    margin-right: ${theme.spacings.byte};
    width: 8px;
  }
`

export const ProjectStatusWrapper = styled('div')(projectStatusWrapperStyles)
