import { tv } from 'tailwind-variants'

export default tv({
  base: '',
  slots: {
    button: `
      fixed

      inset-x-0
      bottom-8

      z-50

      duration-75
      
      ease-linear
      transition-[transform,opacity]
      will-change-transform
    `,
    breadcrumb: `
      w-full
      ease-linear
    `
  },
  variants: {
    animation: {
      up: {
        button: 'transform-none opacity-100'
      },
      down: {
        button: 'translate-y-2 opacity-0',
        breadcrumb: 'bg-white sticky top-0 z-50 py-4 px-2 shadow-xs'
      }
    }
  }
})
