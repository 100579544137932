import { ArrowRight, Coupon as CouponIcon } from 'frontendCheckout/assets/icons'

type ButtonTextAddedCuponProps = { quantity: number }

export const ButtonTextAddedCupon = ({
  quantity
}: ButtonTextAddedCuponProps) => {
  const text = quantity === 1 ? '1 cupom aplicado' : `${quantity} cupons aplicados`

  return (
    <>
      <CouponIcon />
      <span>{text}</span>
      <span className="ml-auto text-sm font-normal items-center flex gap-2 color-white">
        <ArrowRight />
      </span>
    </>
  )
}
