import styled from '@emotion/styled'
import { css } from '@emotion/react'

const sectionStyles = ({ theme }) => css`
  label: faq;

  padding: ${theme.spacings.peta} ${theme.spacings.giga} ${theme.spacings.zetta};

  ${theme.media.giga} {
    padding: 140px 0 ${theme.spacings.zetta};
  }

  background: ${theme.colors.n100};
`

const titleWrapperStyles = ({ theme }) => css`
  label: faq__title-wrapper;

  h1 {
    font-weight: ${theme.font.weight.regular};
  }
`

export const Section = styled('section')(sectionStyles)
export const TitleWrapper = styled('div')(titleWrapperStyles)
