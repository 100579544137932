'use client'

import { Skeleton, Spinner } from '@backyard-ui/core'

export const Loading = () => {
  return (
    <div className="absolute z-50 mt-[25%] ml-[45%]">
      <Spinner size="lg" />
    </div>
  )
}
