import { api } from './api'

export const getProfile = async () => {
  try {
    const { data } = await api.get('/account/profile')
    return data
  } catch (error) {
    throw error.response
  }
}

export const getExtraInfo = async () => {
  try {
    const { data } = await api.get('/celebre/info')
    return data
  } catch (error) {
    throw error.response
  }
}

export const sendUserBasicData = async payload => {
  try {
    const { data } = await api.post('/celebre/eligibility/check', payload)
    return data
  } catch (error) {
    throw error.response
  }
}

export const sendCardRequest = async payload => {
  try {
    const { data } = await api.post('/celebre/proposal', payload)
    return data
  } catch (error) {
    throw error.response
  }
}
