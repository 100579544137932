import {
  useMemo,
  useEffect,
  useCallback,
  useReducer,
  useRef,
  useState
} from 'react'
import PropTypes from 'prop-types'
import { useHistory, useLocation } from 'react-router'
import {
  Container,
  Col,
  Row,
  Stack,
  Visible,
  ToastContainer,
  Hidden
} from '@leroy-merlin-br/backyard-react'
import { datadogRum } from '@datadog/browser-rum'
import startCase from 'lodash/startCase'

import emitter from 'scripts/utils/emitter'

import { useUserResources } from 'shared/hooks'

import RecommendationCarousel from 'site/Modular/modules/RecommendationCarousel'
import WishlistProviderMethods from 'site/Wishlist/provider'

import { tracker } from 'utils/app'

import {
  HeadTags,
  NoResultsSearch,
  EntryKeySkeleton,
  TopContent,
  BreadcrumbBar,
  FacetsBar,
  Filters,
  Products,
  BottomContent,
  FiltersMobile,
  SortersStack as Sorters,
  RetailMediaBanner,
  RetailMediaProductsCarousel
} from './components'
import { EntryKeyContextProvider } from './context/EntryKeyContext'
import { buildPath, urlToObject, removeFilterQueries } from './utils/buildUrl'
import {
  onFilterDataLayer,
  onSorterDataLayer,
  onProductImpressionDataLayer
} from './utils/data-layer'
import { getResource } from './services'
import { reducer, initializeState, Constants } from './reducer'
import { STATUS_TO_REDIRECT } from './utils/constants'

const EntryKey = ({
  apiData,
  isOnMobileApp,
  isAssistedSale,
  isBlackWeekend,
  apiDomain,
  categoryId,
  categoryName,
  type,
  url,
  searchType: propSearchType,
  pageType,
  personalizationTrack,
  isNewProductThumb
}) => {
  const [state, dispatch] = useReducer(
    reducer,
    { propSearchType, url, initialData: apiData },
    initializeState
  )

  const [placement, setPlacement] = useState()

  const {
    filters,
    isLoading,
    isLoadingProducts,
    hasError,
    hasHighlightedFilters,
    products,
    metaTags,
    metadata,
    query,
    baseUrl,
    isMobileFilterActive,
    isInitialRender
  } = state

  const { userResources } = useUserResources()

  const history = useHistory()
  const location = useLocation()

  const breadcrumbRef = useRef()
  const shouldSendFilterDataLayer = useRef(false)
  const previousClick = useRef()
  const userHasInteracted = useRef()

  const entryKeyName = useMemo(() => startCase(baseUrl), [baseUrl])
  const isMappedSearch = useMemo(() => type === 'mappedSearch', [type])
  const isSearch = useMemo(
    () => type === 'search' || isMappedSearch,
    [isMappedSearch, type]
  )

  const filterUsedOptions = useMemo(() => {
    const selectedOptions = []
    const usedFilters = Object.values(filters).filter(obj => obj.selected)
    usedFilters.forEach(filter => {
      const indexUsedFilter = filters.indexOf(filter)

      filter.options.forEach((option, optIndex) => {
        selectedOptions.push({
          name: filter.name,
          filterKey: filter.filterKey,
          label: option.label,
          query: option.query,
          filterTypeIndex: indexUsedFilter,
          filterElementIndex: optIndex
        })
      })
    })

    return selectedOptions
  }, [filters])

  const setUrl = useCallback(
    (newQuery, state) => {
      history.push({ ...buildPath(baseUrl, newQuery, isMappedSearch) }, state)
    },
    [baseUrl, isMappedSearch, history]
  )

  const handleInteraction = useCallback(
    ({ newQuery, resetPage = true, loadOnlyProducts }) => {
      if (!userHasInteracted.current) {
        userHasInteracted.current = true
      }

      if (resetPage) {
        delete newQuery.page
      }

      setUrl(newQuery)
      dispatch({
        type: Constants.UPDATE_QUERY,
        payload: { newQuery, loadOnlyProducts }
      })
    },
    [setUrl]
  )

  const getProducts = useCallback(async () => {
    try {
      const data = await getResource({
        apiDomain,
        categoryId,
        type,
        query
      })

      dispatch({
        type: Constants.GET_PRODUCTS_SUCCESS,
        payload: { data, isSearch }
      })
    } catch (error) {
      if (String(error.status).includes(STATUS_TO_REDIRECT)) {
        const newQuery = {}

        setUrl(newQuery, { redirect: true })
      } else {
        dispatch({ type: Constants.GET_PRODCUTS_FAILURE })
      }
    }
  }, [apiDomain, categoryId, type, query, isSearch, setUrl])

  const onOptionClick = useCallback(
    filteringData => {
      scrollToTop()

      let newQuery

      if (Array.isArray(filteringData)) {
        newQuery = filteringData.reduce(
          (acc, curr) => buildQuery(curr, acc),
          query
        )
      } else {
        newQuery = buildQuery(filteringData, query)
      }

      handleInteraction({ newQuery })

      shouldSendFilterDataLayer.current = true
    },
    [scrollToTop, query, handleInteraction]
  )

  const buildQuery = ({ name, value, type, dataLayerOptions }, query = {}) => {
    const isParamAlreadySet = query[name] && query[name].value === value

    previousClick.current = { isParamAlreadySet, ...dataLayerOptions }

    const formedQuery = { ...query }

    if (isParamAlreadySet) {
      delete formedQuery[name]
    } else {
      formedQuery[name] = { value, type }
    }

    return formedQuery
  }

  const filterByStock = useCallback(
    quantity => {
      const newQuery = {
        ...query,
        minimumStock: { value: quantity, type: 'other' },
        stockOnStore: { value: true, type: 'other' },
        stockOnPlatform: { value: true, type: 'other' }
      }
      handleInteraction({ newQuery, loadOnlyProducts: true })
    },
    [query, handleInteraction]
  )

  const setPage = useCallback(
    page => {
      scrollToTop()

      const newQuery = { ...query, page: { value: page, type: 'other' } }
      handleInteraction({ newQuery, resetPage: false, loadOnlyProducts: true })
    },
    [scrollToTop, query, handleInteraction]
  )

  const cleanFilters = useCallback(() => {
    const newQuery = removeFilterQueries(query)

    handleInteraction({ newQuery })

    shouldSendFilterDataLayer.current = false
  }, [handleInteraction, query])

  const setQueryFromUrl = useCallback(() => {
    const newQuery = {
      ...urlToObject({ location, url })
    }

    const payload = { newQuery, resetPage: false }

    if (apiData) {
      payload.resetLoad = true
    }

    dispatch({
      type: Constants.UPDATE_QUERY,
      payload
    })
  }, [location, url, apiData])

  const scrollToTop = useCallback(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  const toggleMobileFilter = useCallback(() => {
    dispatch({ type: Constants.TOGGLE_MOBILE_FILTER })
  }, [])

  useEffect(() => tracker.viewListing(), [])

  useEffect(() => {
    const {
      action,
      location: { state }
    } = history

    if (action === 'POP' || state?.redirect) {
      setQueryFromUrl()
      shouldSendFilterDataLayer.current = false
    }
  }, [history, setQueryFromUrl])

  useEffect(() => {
    if (userHasInteracted.current || (!apiData && !isInitialRender)) {
      getProducts()
    }
  }, [query, getProducts, apiData, isInitialRender])

  useEffect(() => {
    if (products.length && !isLoading && !isLoadingProducts) {
      const term = isSearch && query.term ? query.term.value : baseUrl

      try {
        tracker.viewItemList({
          items: products.map((product) => ({
            item_id: product.id,
            item_name: product.name,
            discountPercentage: product.offer?.value,
            item_brand: product.brand,
            categoryTree: product.categoryTree,
            price: product.price?.to,
            item_list_name: `${type}: ${product.category}`
          }))
        })
      } catch (e) {
        datadogRum.addError(e)
      }

      onProductImpressionDataLayer({
        products,
        region: userResources?.region,
        gaTrack: metaTags?.gaTrack,
        type,
        term,
        page: metadata.page
      })

      if (shouldSendFilterDataLayer.current) {
        if (previousClick.current.sorterType) {
          onSorterDataLayer({
            gaTrack: metaTags?.gaTrack,
            ...previousClick.current
          })
        }

        if (previousClick.current.filter) {
          onFilterDataLayer({
            gaTrack: metaTags?.gaTrack,
            pageType,
            ...previousClick.current
          })
        }
      }
    }
  }, [
    products,
    userResources,
    metaTags,
    type,
    baseUrl,
    query,
    isLoading,
    isLoadingProducts,
    isSearch,
    metadata,
    pageType
  ])

  useEffect(() => {
    const placementName = window.RR?.data?.JSON?.placements[0].name

    if (placementName) {
      setPlacement(placementName)
    } else {
      emitter.on('recommendation', data => {
        if (data.length > 0) {
          setPlacement(data[0].name)
        }
      })
    }
  }, [])

  const entryKeyContextParams = {
    ...state,
    name: entryKeyName,
    isBlackWeekend,
    filterUsedOptions,
    isMappedSearch,
    type,
    isSearch,
    apiDomain,
    categoryId,
    setPage,
    onOptionClick,
    toggleMobileFilter,
    cleanFilters,
    scrollToTop,
    filterByStock,
    profile: userResources,
    pageType,
    personalizationTrack,
    isNewProductThumb,
    isOnMobileApp,
    isAssistedSale
  }

  if (isLoading && !isMobileFilterActive) {
    return <EntryKeySkeleton />
  }

  if (hasError) {
    return (
      <EntryKeyContextProvider value={entryKeyContextParams}>
        <BreadcrumbBar ref={breadcrumbRef} />
        <NoResultsSearch isSearch={isSearch} />
      </EntryKeyContextProvider>
    )
  }

  return (
    <EntryKeyContextProvider value={entryKeyContextParams}>
      <ToastContainer />

      <HeadTags />

      <BreadcrumbBar ref={breadcrumbRef} />
      <Stack space="giga">
        {window.env.newTail.enabled && (
          <RetailMediaBanner
            isSearch={isSearch}
            term={query.term}
            categoryName={categoryName}
          />
        )}
        <TopContent />

        {placement && (
          <Container>
            <RecommendationCarousel
              data={{ placement }}
              withBackground={false}
              noContentPadding={false}
              noTopSpacing
            />
          </Container>
        )}

        <Visible when={['giga', 'tera']}>
          {hasHighlightedFilters && <FacetsBar />}
        </Visible>
      </Stack>

      <Container>
        <Row align="flex-start">
          <Visible when={['giga', 'tera']}>
            <Col size={{ giga: 3 }}>
              <Stack space="mega">
                <Sorters />
                <Filters />
              </Stack>
            </Col>
          </Visible>
          <Col size={{ giga: 9, mega: 8, kilo: 4 }}>
            <Stack space="mega">
              <WishlistProviderMethods>
                <Products />
              </WishlistProviderMethods>
              {window.env.newTail.enabled && (
                <RetailMediaProductsCarousel
                  isSearch={isSearch}
                  term={query.term}
                  categoryName={categoryName}
                />
              )}
            </Stack>
          </Col>
        </Row>

        <BottomContent />
      </Container>

      <Hidden when={['giga', 'tera']}>
        <FiltersMobile />
      </Hidden>
    </EntryKeyContextProvider>
  )
}

export default EntryKey

EntryKey.propTypes = {
  isBlackWeekend: PropTypes.bool,
  url: PropTypes.string,
  type: PropTypes.oneOf(['entryKey', 'search', 'mappedSearch', 'imageSearch']),
  apiDomain: PropTypes.string,
  categoryId: PropTypes.string,
  categoryName: PropTypes.string,
  pageType: PropTypes.string,
  personalizationTrack: PropTypes.shape({
    apiKey: PropTypes.string,
    baseUrl: PropTypes.string,
    categoryId: PropTypes.shape({
      $oid: PropTypes.string
    }),
    categoryName: PropTypes.string,
    placement: PropTypes.string,
    products: PropTypes.array,
    regionId: PropTypes.string,
    track: PropTypes.string
  })
}
