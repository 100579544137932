import { css } from '@emotion/react'
import styled from '@emotion/styled'

const resendCodeText = () => css`
  label: security-code-wrapper;

  p {
    font-size: 14px;
  }
`

export const ResendCodeText = styled('div')(resendCodeText)
