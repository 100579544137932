import { css } from '@emotion/react'
import styled from '@emotion/styled'

const wrapperStyles = ({ theme }) => css`
  label: widget__wrapper;

  position: relative;
  display: block;
  width: 100%;
  margin-top: ${theme.spacings.tera};

  & > div {
    position: relative;
    margin: 0 auto;
    padding: ${theme.spacings.mega};
  }

  ${theme.media.kilo} {
    & > div {
      padding: 0 ${theme.spacings.mega};
    }
  }

  ${theme.media.mega} {
    display: block;
  }

  ${theme.media.giga} {
    & > div {
      padding: 0;
      max-width: 1180px;
    }
  }
`

const fullStyles = ({ full }) =>
  full &&
  css`
    label: widget__wrapper--full;

    & > div {
      padding: 0;
    }
  `

const noTopSpacingStyles = ({ noTopSpacing }) =>
  noTopSpacing &&
  css`
    label: widget__wrapper--no-top-spacing;

    margin-top: 0;
  `

const noBottomSpacingStyles = ({ noBottomSpacing }) =>
  noBottomSpacing &&
  css`
    label: widget__wrapper--no-bottom-spacing;

    margin-bottom: 0;
  `

const withBackgroundStyles = ({ theme, withBackground }) =>
  withBackground &&
  css`
    label: widget__wrapper--with-background;

    & > div {
      background: ${theme.colors.white};
    }

    ${theme.media.kilo} {
      & > div {
        padding: ${theme.spacings.mega};
      }
    }
  `

const withSideBackgroundColorsStyles = ({ sideBackgroundColors }) =>
  sideBackgroundColors &&
  css`
    label: widget__wrapper--with-background-colors;

    background-color: ${sideBackgroundColors.left};
    background: linear-gradient(
      90deg,
      ${sideBackgroundColors.left} 50%,
      ${sideBackgroundColors.right} 50%
    );
  `

const noContentPaddingStyles = ({ theme, noContentPadding }) =>
  noContentPadding &&
  css`
    label: widget__wrapper--no-content-padding;

    & > div {
      padding-left: 0;
      padding-right: 0;
    }

    & h2 {
      margin-left: ${theme.spacings.mega};
    }
  `

export const Wrapper = styled('section')(
  wrapperStyles,
  fullStyles,
  noTopSpacingStyles,
  noBottomSpacingStyles,
  withBackgroundStyles,
  withSideBackgroundColorsStyles,
  noContentPaddingStyles
)
