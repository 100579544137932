const CheckIcon = () => (
  <svg
    width="16"
    height="11"
    viewBox="0 0 16 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.71964 10.5582C4.86864 10.6982 5.04573 10.8093 5.24073 10.8851C5.43573 10.961 5.64481 11 5.85597 11C6.06713 11 6.27621 10.961 6.47121 10.8851C6.66621 10.8093 6.8433 10.6982 6.99231 10.5582L15.5684 2.53111C15.8525 2.2458 16.0071 1.86844 15.9998 1.47852C15.9924 1.08861 15.8236 0.716583 15.529 0.440827C15.2344 0.165072 14.8369 0.0071141 14.4203 0.000234482C14.0038 -0.00664514 13.6006 0.13809 13.2958 0.403946L5.85597 7.36541L2.70425 4.41747C2.39942 4.15162 1.99624 4.00688 1.57966 4.01376C1.16307 4.02064 0.765598 4.1786 0.470981 4.45435C0.176363 4.73011 0.00760072 5.10213 0.000250521 5.49205C-0.00709968 5.88197 0.147536 6.25933 0.431577 6.54464L4.71964 10.5582Z"
      fill="black"
    />
  </svg>
)

export default CheckIcon
