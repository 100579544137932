import {
  Stack,
  Container,
  Grid,
  SimpleGrid,
  Skeleton,
  Hidden
} from '@backyard-ui/core'

export function Loading () {
  return (
    <Stack direction="column" align="center">
      <div />
      <Container size="6xl" className="w-full h-11 md:h-10">
        <Skeleton width="100%" height="100%" />
      </Container>
      <Container size="6xl" className="w-full">
        <Grid.Root>
          <Hidden below="lg">
            <Grid.Item
              size={{
                initial: 12,
                md: 3
              }}
            >
              <Stack direction="column">
                <Skeleton width="100%" height="112px" />
                <Skeleton width="100%" height="250px" />
              </Stack>
            </Grid.Item>
          </Hidden>
          <Grid.Item
            size={{
              initial: 12,
              lg: 9
            }}
          >
                <SimpleGrid
                  columns={{
                    initial: 2,
                    sm: 3,
                    md: 4
                  }}
                >
                  <Skeleton width="100%" height="358px" />
                  <Skeleton width="100%" height="358px" />
                  <Skeleton width="100%" height="358px" />
                  <Skeleton width="100%" height="358px" />
                  <Skeleton width="100%" height="358px" />
                  <Skeleton width="100%" height="358px" />
                  <Skeleton width="100%" height="358px" />
                  <Skeleton width="100%" height="358px" />
                </SimpleGrid>
          </Grid.Item>
        </Grid.Root>
      </Container>
    </Stack>
  )
}
