import { Suspense } from 'react'
import PropTypes from 'prop-types'

import LoaderProducts from './loaders/Products'
import LoaderContents from './loaders/Contents'
import Product from './Product'
import Content from './Content'
import AddProductsButton from '../lists/AddProductsButton'

const getComponent = (index, items, removeWishlistItem) => {
  const componentsDictionary = {
    0: {
      component: () => <Product items={items} removeItem={removeWishlistItem} />,
      fallback: () => <LoaderProducts />
    },
    1: {
      component: () => <Content items={items} removeItem={removeWishlistItem} />,
      fallback: () => <LoaderContents />
    }
  }

  return (
    componentsDictionary[index] && (
      <Suspense fallback={componentsDictionary[index].fallback()}>
        {componentsDictionary[index].component()}
      </Suspense>
    )
  )
}

const Details = ({ activedItem, items, removeWishlistItem }) => {
  return (
    <div className="wishlist-page-details">
      <div className="container">
        <div className="wishlist-details">
          {getComponent(activedItem, items, removeWishlistItem)}
          {activedItem === 0 && <AddProductsButton items={items} />}
        </div>
      </div>
    </div>
  )
}

Details.propTypes = {
  activedItem: PropTypes.number.isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired
}

export default Details
