import axios from 'axios'

const buildSignupHeaders = async ({ recaptchaToken, redirectTo, csrfToken }) => {
  const headers = {
    'X-CSRF-TOKEN': csrfToken
  }

  if (redirectTo) {
    headers['Redirect-URL'] = redirectTo
  }

  if (recaptchaToken) {
    headers['G-Recaptcha'] = recaptchaToken
  }

  if (window.fpPromise) {
    const fingerprint = await window.fpPromise

    if (fingerprint?.get) {
      const fpConfigs = await fingerprint.get()

      if (fpConfigs?.requestId && fpConfigs?.visitorId) {
        headers['X-FingerPrint-RequestID'] = fpConfigs.requestId
        headers['X-FingerPrint-VisitorID'] = fpConfigs.visitorId
      }
    }
  }

  return headers
}

export const signup = async ({
  data,
  redirectTo,
  recaptchaToken,
  csrfToken
}) => {
  const response = await axios
    .post('/api/boitata/v1/signup', data, {
      headers: await buildSignupHeaders({ recaptchaToken, redirectTo, csrfToken })
    })
    .then(({ data }) => data)
    .catch(err => {
      throw err.response
    })

  return response
}

export const signupCheckForm = async (userInfo) => {
  const response = await axios
    .post('/api/v3/signup/check', userInfo)
    .then(({ data }) => data)
    .catch(err => {
      throw err.response
    })

  return response
}

export const signupCompany = async ({ data, recaptchaToken, csrfToken }) => {
  const response = await axios
    .post('/api/boitata/v1/company/signup', data, {
      headers: await buildSignupHeaders({ recaptchaToken, csrfToken })
    })
    .then(({ data }) => data)
    .catch(err => {
      throw err.response
    })

  return response
}
