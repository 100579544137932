import { css } from '@emotion/react'
import styled from '@emotion/styled'

const buttonStyles = ({ theme }) => css`
  label: button-wrapper;

  margin-top: ${theme.spacings.giga};
`

const hintWrapperStyles = ({ theme }) => css`
  label: hint-wrapper;

  margin-top: ${theme.spacings.byte};
`

const toppingsWrapperStyles = ({ theme }) => css`
  label: toppings-wrapper;

  padding-left: ${theme.spacings.byte};

  position: relative;

  &:after {
    content: '';
    position: absolute;

    height: 100%;
    width: 1px;

    left: 0;
    top: 0;

    background-color: ${theme.colors.n300};
  }
`
export const ButtonWrapper = styled('div')(buttonStyles)
export const HintWrapper = styled('div')(hintWrapperStyles)
export const ToppingsWrapper = styled('div')(toppingsWrapperStyles)
