import {
  BathtubOutline,
  BeddingOutline,
  AirConditionerOutline,
  KitchenOutline,
  FrameOutline,
  FridgeOutline,
  DoorHingeOutline,
  DrillOutline,
  PendantOutline,
  FenceOutline,
  BroomOutline,
  ShovelOutline,
  PlugOutline,
  FaucetOutline,
  WoodOutline,
  CouchOutline,
  HangerOutline,
  PawOutline,
  PaintBucketOutline,
  FloorOutline,
  DoorOutline,
  CameraSecurityOutline,
  MixerOutline
} from '@backyard-ui/icons'

import { ComputerOutline } from './ComputerOutline'
import { HouseholdItemsOutline } from './HouseholdItemsOutline'
import { SoundOutline } from './SoundOutline'

export default {
  'audio-video-e-tvs': <SoundOutline />,
  'ar-e-ventilacao': <AirConditionerOutline />,
  banheiros: <BathtubOutline />,
  'cama-mesa-e-banho': <BeddingOutline />,
  'cozinhas-e-areas-de-servico': <KitchenOutline />,
  decoracao: <FrameOutline />,
  eletrodomesticos: <FridgeOutline />,
  eletroportateis: <MixerOutline />,
  eletros: <FridgeOutline />,
  ferragens: <DoorHingeOutline />,
  ferramentas: <DrillOutline />,
  iluminacao: <PendantOutline />,
  informatica: <ComputerOutline />,
  'jardim-e-lazer': <FenceOutline />,
  'jardim-e-varanda': <FenceOutline />,
  'limpeza-da-casa': <BroomOutline />,
  'limpeza-e-manutencao': <BroomOutline />,
  madeiras: <WoodOutline />,
  'marcenaria-e-madeiras': <WoodOutline />,
  'materiais-de-construcao': <ShovelOutline />,
  'materiais-eletricos': <PlugOutline />,
  'materiais-hidraulicos': <FaucetOutline />,
  moveis: <CouchOutline />,
  organizacao: <HangerOutline />,
  'organizacao-da-casa': <HangerOutline />,
  'pet-shop': <PawOutline />,
  'pintura-e-acessorios': <PaintBucketOutline />,
  'pisos-e-revestimentos': <FloorOutline />,
  'portas-janelas-e-portoes': <DoorOutline />,
  'seguranca-e-comunicacao': <CameraSecurityOutline />,
  'utilidades-domesticas': <HouseholdItemsOutline />
}
