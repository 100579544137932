import { css } from '@emotion/react'
import styled from '@emotion/styled'

export const wrapperStyles = ({ theme }) => css`
  label: accordion__wrapper;

  [target="card"] {
    ${theme.media.mega} {
      padding: ${theme.spacings.mega} 0;

      & > div {
        padding: 0 ${theme.spacings.bit} 0 ${theme.spacings.mega};
        overflow-y: hidden;
      }
    }
  }
`

const headerStyles = ({ theme }) => css`
  label: accordion__header;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: ${theme.spacings.bit};

  cursor: pointer;

  ${theme.media.mega} {
    padding-right: 26px;
  }

  & > svg {
    margin-left: ${theme.spacings.byte};
    color: ${theme.colors.n600};
  }
`

const contentStyles = () => css`
  label: accordion__content;

  max-height: 0;
  transition: 0.6s ease;
  overflow: hidden;
`

const contentOpenStyles = ({ theme, isOpen, openHeight }) =>
  isOpen &&
  css`
    label: accordion__content--open;

    margin-top: ${theme.spacings.kilo};
    max-height: ${openHeight}px;
  `

export const Wrapper = styled('div')(wrapperStyles)
export const Header = styled('div')(headerStyles)
export const Content = styled('div')(contentStyles, contentOpenStyles)
