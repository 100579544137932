type ImageProps = {
  img: string
}

export const Image = ({ img }: ImageProps) => {
  return (
    <img
      src={img}
      className="md:rounded-lg rounded-sm md:min-w-20 md:min-h-20 md:w-20 md:h-20 min-w-12 min-h-12 w-12 h-12 border border-gray-300"
    />
  )
}
