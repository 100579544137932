import { css } from '@emotion/react'
import styled from '@emotion/styled'

const ITEM_WIDTH_MOBILE = '169px'
const ITEM_WIDTH_DESKTOP = '206px'

const itemStyles = ({ theme }) => css`
  label: wrapper;

  height: 100%;
  width: ${ITEM_WIDTH_MOBILE};

  ${theme.media.mega} {
    width: ${ITEM_WIDTH_DESKTOP};
  }
`

export const customGenericCarouselStyles = ({ theme }) => css`
    label: custom-generic-carousel;

    .swiper-wrapper {
      display: grid;
      grid-auto-flow: column;
      grid-auto-columns: ${ITEM_WIDTH_MOBILE};
      gap: 5px;

      ${theme.media.mega} {
        grid-auto-columns: ${ITEM_WIDTH_DESKTOP};
      }
    }

  `

export const WrapperItem = styled.div(itemStyles)
