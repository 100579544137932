import { Heading, Container, Col, Row } from '@leroy-merlin-br/backyard-react'

import { Step } from './components/Step'
import * as S from './styled'

const HowTo = () => (
  <Container>
    <S.TitleWrapper>
      <Heading size="exa" noMargin>
        Como funciona
      </Heading>
    </S.TitleWrapper>
    <S.Section>
      <Row>
        <Col
          size={{
            giga: 5,
            tera: 4
          }}
        >
          <Step
            image="signup.png"
            stepLabel="1° PASSO"
            title="Cadastre-se"
            subtitle="Realize seu cadastro, informe seus dados e preferências"
          />
        </Col>
        <Col
          size={{
            giga: 5,
            tera: 4
          }}
          offset={{ giga: 1, tera: 2 }}
        >
          <Step
            image="active-coupon-step.png"
            stepLabel="2° PASSO"
            title="Ative os cupons"
            subtitle="Acesse a área do Leroy Merlin Com Você no site ou app e ative seus cupons de desconto."
          />
        </Col>
        <Col
          size={{
            giga: 5,
            tera: 4
          }}
          offset={{ tera: 1 }}
        >
          <Step
            image="woman-with-cart.png"
            stepLabel="3° PASSO"
            title="Identifique-se"
            subtitle="Na hora da compra na loja, site ou app informe seu CPF para acumular pontos (R$1,00 = 1 ponto)."
          />
        </Col>
        <Col size={{ giga: 5 }} offset={{ giga: 1, tera: 2 }}>
          <Step
            image="woman-with-phone.png"
            stepLabel="4° PASSO"
            title="Aproveite"
            subtitle="Troque seus pontos por cashback e economize nas suas compras."
          />
        </Col>
      </Row>
    </S.Section>
  </Container>
)

export default HowTo
