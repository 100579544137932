import { css } from '@emotion/react'
import styled from '@emotion/styled'

const navigationStyles = ({ theme }) => css`
  label: generic-carousel__navigation;

  display: none;

  position: absolute;
  top: 50%;
  left: ${theme.spacings.byte};
  right: ${theme.spacings.byte};
  transform: translateY(-50%);
  z-index: 1;

  pointer-events: none;

  ${theme.media.tera} {
    left: -${theme.spacings.mega};
    right: -${theme.spacings.mega};
  }

  ${theme.media.mega} {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`

const boxStyles = ({ theme }) => css`
  label: navigation__box;

  display: flex;
  justify-content: center;
  align-items: center;

  width: ${theme.spacings.exa};
  height: ${theme.spacings.exa};

  opacity: 1;
  background-color: ${theme.colors.white};
  border-radius: ${theme.borderRadius.circle};

  user-select: none;
  pointer-events: visible;

  box-shadow: 0px 0px 0px 1px ${theme.colors.shadow},
    0px -2px 11px ${theme.colors.n800}19, 0px 8px 8px ${theme.colors.n800}11;

  transition: all 600ms cubic-bezier(0.19, 1, 0.22, 1);

  &:not(.swiper-button-disabled) {
    cursor: pointer;

    &:hover {
      background-color: ${theme.colors.white};
      svg {
        fill: ${theme.colors.black};
      }
    }
  }

  &.swiper-button-disabled {
    visibility: hidden;
  }
`

const boxIconStyles = ({ side = 'left' }) => {
  const margin = {
    left: '0 2px 0 0',
    right: '0 0 0 2px'
  }

  return css`
    label: ${'navigation__box--' + side};

    & > svg {
      margin: ${margin[side]};
    }
  `
}

export const Navigation = styled('div')(navigationStyles)

export const Box = styled('div')(boxStyles, boxIconStyles)
