import { css } from '@emotion/react'
import styled from '@emotion/styled'

const containerStyles = ({ theme, noContentPadding, inline }) => {
  const paddingStyles =
    !noContentPadding &&
    css`
      padding: ${theme.spacings.mega} 0;
    `

  return css`
    label: retail-media_banner-container;

    display: flex;

    flex-direction: ${inline ? 'row' : 'column'};
    ${paddingStyles}
  `
}

const bannerContainerStyles = () => css`
  label: retail-media_banner-banner-container;

  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`

const bannerWrapperStyles = () => css`
  label: retail-media_banner-banner-wrapper;

  max-width: 728px;
`

const textWrapperStyles = ({ theme }) => css`
  label: retail-media_banner-text-wrapper;

  padding-right: ${theme.spacings.mega};
`

export const Container = styled('div')(containerStyles)
export const BannerContainer = styled('div')(bannerContainerStyles)
export const BannerWrapper = styled('div')(bannerWrapperStyles)
export const TextWrapper = styled('div')(textWrapperStyles)
