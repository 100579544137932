const Truck = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.136 4.65595V4.36795C11.136 3.21595 10.272 2.35195 9.12 2.35195H8.06399C7.77599 1.67995 7.104 1.19995 6.24 1.19995H1.82399C0.671994 1.19995 0 2.15995 0 3.02395V8.78395C0 9.16795 0.384003 9.55195 0.768003 9.55195H1.34399C1.53599 10.3199 2.304 10.8 3.072 10.8C3.84 10.8 4.512 10.3199 4.8 9.55195H7.488C7.68 10.3199 8.44799 10.8 9.21599 10.8C9.98399 10.8 10.656 10.3199 10.944 9.55195H11.232C11.616 9.55195 12 9.26395 12 8.78395V6.09595C12 5.51995 11.616 4.94395 11.136 4.65595ZM7.488 6.76795C7.872 6.76795 8.25599 6.47995 8.25599 5.99995V3.69595H9.12C9.504 3.69595 9.69599 3.98395 9.69599 4.27195V5.32795C9.69599 5.71195 10.08 6.09595 10.464 6.09595V7.63195C10.176 7.34395 9.69599 7.15195 9.21599 7.15195C8.63999 7.15195 8.064 7.43995 7.68 8.01595H4.608C4.32 7.53595 3.744 7.15195 3.072 7.15195C2.496 7.15195 1.91999 7.43995 1.53599 7.91995V2.92795C1.53599 2.92795 1.536 2.54395 1.92 2.54395H6.33599C6.62399 2.54395 6.81599 2.73595 6.81599 2.92795V5.99995C6.71999 6.47995 7.104 6.76795 7.488 6.76795ZM3.36 8.97595C3.36 9.16795 3.264 9.26395 3.072 9.26395C2.88 9.26395 2.78399 9.16795 2.78399 8.97595C2.78399 8.78395 2.88 8.68795 3.072 8.68795C3.264 8.68795 3.36 8.78395 3.36 8.97595ZM9.50399 8.97595C9.50399 9.16795 9.40799 9.26395 9.21599 9.26395C9.02399 9.26395 8.928 9.16795 8.928 8.97595C8.928 8.78395 9.02399 8.68795 9.21599 8.68795C9.40799 8.68795 9.50399 8.78395 9.50399 8.97595Z"
      fill="#222020"
    />
  </svg>
)

export default Truck
