import React from 'react'

import { renderTemplate } from '../render-template'

const EditPhonePJ = ({
  _id,
  index,
  email,
  name,
  phone,
  default: isDefault
}) => (
  <div className="hidden">
    <div className="container-fluid" data-component="validation">
      <div className="modal-title">
        {_id ? 'Alterar contato' : 'Adicionar contato'}
      </div>
      <fieldset className="row">
        <input type="hidden" name="index" value={index} />
        {_id && (
          <input type="hidden" name={`employees[${index}][_id]`} value={_id} />
        )}
        {email && (
          <input
            type="hidden"
            name={`employees[${index}][email]`}
            value={email}
          />
        )}
        <div className="col-xs-12">
          <div className="field" data-validation="field">
            <input
              className="input"
              type="text"
              name={`employees[${index}][name]`}
              id={`employees[${index}][name]`}
              data-required
              data-validate="required"
              value={name}
            />
            <label className="label" htmlFor={`employees[${index}][name]`}>
              Nome
            </label>
          </div>
        </div>
        <div className="col-xs-12">
          <div className="field" data-validation="field">
            <input
              className="input"
              type="text"
              name={`employees[${index}][phone]`}
              id={`employees[${index}][phone]`}
              data-required
              data-validate="minlength"
              data-minlength="14"
              data-phone-mask
              value={phone}
            />
            <label className="label" htmlFor={`employees[${index}][phone]`}>
              Número do telefone
            </label>
            <div className="helper">Ex: (11) 99999-9999</div>
          </div>
        </div>
        <div className="col-xs-12">
          <div className="field" data-validation="field">
            <input
              type="checkbox"
              name={`employees[${index}][default]`}
              id={`employees[${index}][default]`}
              value="1"
              checked={isDefault === 'true' || index === 0}
            />
            <label className="label" htmlFor={`employees[${index}][default]`}>
              Este é o contato principal
            </label>
          </div>
        </div>
        <div className="col-xs-12 modal-footer align-right">
          <button
            className="button button-text"
            type="button"
            data-trigger="close"
          >
            Cancelar
          </button>
          <button
            className="button button-primary"
            type="button"
            data-trigger="submit"
          >
            Salvar contato
          </button>
        </div>
      </fieldset>
    </div>
  </div>
)

const render = (props = {}) => renderTemplate(<EditPhonePJ {...props} />)

export default render
