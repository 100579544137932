import PropTypes from 'prop-types'
import {
  Text,
  Icon,
  Button,
  CloseButton,
  useBreakpoint
} from '@leroy-merlin-br/backyard-react'
import {
  InfoCircleOutline,
  AlertTriangleOutline,
  TimesCircleOutline,
  CheckmarkCircleOutline
} from '@backyard-ui/icons'

import * as S from './styled'

const icons = {
  info: InfoCircleOutline,
  warning: AlertTriangleOutline,
  success: CheckmarkCircleOutline,
  critical: TimesCircleOutline
}

const AlertMessage = ({
  children,
  customIcon = null,
  onButtonClick,
  onClose,
  type = 'info',
  tag = 'p',
  title = '',
  isStretch = true,
  noTopMargin = false,
  buttonText = ''
}) => {
  const isTypeValid = Object.keys(icons).includes(type)

  const { breakpoint } = useBreakpoint()
  const isMobile = ['untilKilo', 'kilo'].includes(breakpoint)

  if (!isTypeValid) {
    return null
  }

  return (
    <S.AlertMessage
      type={type}
      isStretch={isStretch}
      hasTopMargin={!noTopMargin}
    >
      <S.IconWrapper>
        <Icon as={customIcon || icons[type]} size="mega" />
      </S.IconWrapper>

      <S.MessageWrapper>
        {title && (
          <Text as="strong" size="kilo" color="n900" isBold noMargin>
            {title}
          </Text>
        )}
        {children && (
          <Text as={tag} size="kilo" color="n900" noMargin>
            {children}
          </Text>
        )}
      </S.MessageWrapper>

      {onButtonClick && buttonText && (
        <S.ButtonWrapper>
          <Button
            size="kilo"
            appearance={type === 'success' ? 'primary' : type}
            isStretch={isMobile}
            onClick={onButtonClick}
          >
            {buttonText}
          </Button>
        </S.ButtonWrapper>
      )}

      {onClose && (
        <S.CloseButtonWrapper>
          <CloseButton onClick={onClose} size="kilo" />
        </S.CloseButtonWrapper>
      )}
    </S.AlertMessage>
  )
}

AlertMessage.propTypes = {
  children: PropTypes.node,
  onButtonClick: PropTypes.func,
  onClose: PropTypes.func,
  type: PropTypes.oneOf(Object.keys(icons)),
  title: PropTypes.string,
  isStretch: PropTypes.bool,
  buttonText: PropTypes.string
}

export default AlertMessage
