import { ModalProvider } from '@leroy-merlin-br/backyard-react'

import reactComponentLoader from 'scripts/react-component-loader'

import CheckOldAppModalContainer from './CheckOldAppModalContainer'

const CheckOldAppModalProvider = () => (
    <ModalProvider>
        <CheckOldAppModalContainer />
    </ModalProvider>
)

reactComponentLoader(CheckOldAppModalProvider, '[data-check-old-app-modal]')
