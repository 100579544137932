import { Text, Inline } from '@leroy-merlin-br/backyard-react'

import { AlertMessage } from 'shared/components/AlertMessage'

import * as S from './styled'
import { AntiFraudVisualType } from './type'

const AntiFraud = ({  }: AntiFraudVisualType) => (
  <AlertMessage type="info" tag="span">
    <Text as="p" size="kilo" noMargin isBold>
      Cuidado com golpes, veja nossas dicas para garantir sua segurança
    </Text>

    <S.IndentText>
      <Inline space="bit">
        <Text size="kilo" noMargin>
          1.
        </Text>
        <Text size="kilo" noMargin>
          Não enviamos cobranças de frete após a conclusão dos pedidos;
        </Text>
      </Inline>
      <Inline space="bit">
        <Text size="kilo" noMargin>
          2.
        </Text>
        <Text size="kilo" noMargin>
          Não enviamos códigos de Whatsapp ou SMS para confirmar ou acessar
          contas;
        </Text>
      </Inline>
      <Inline space="bit">
        <Text size="kilo" noMargin>
          3.
        </Text>
        <Text size="kilo" noMargin>
          Não compartilhe seus dados de senha, cartões e dados bancários com
          ninguém.
        </Text>
      </Inline>
    </S.IndentText>
  </AlertMessage>
)

export default AntiFraud
