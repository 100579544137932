import { createAsyncThunk, createSlice, Dispatch } from '@reduxjs/toolkit'

import { AsyncRequest } from 'newCheckout/types/AsyncRequest'
import { Store } from 'newCheckout/types/PickUpInStore'
import { cartApi } from 'newCheckout/api'

const initialState: AsyncRequest<
  { stores: Store[]; deliveryAvailable: boolean },
  string
> = {
  status: 'init',
  error: '',
  requestCode: null,
  data: { stores: [], deliveryAvailable: false }
}

const getAvailableStores = createAsyncThunk(
  'PickUpInStore/getAvailableStores',
  async (params: cartApi.getAvailableStoresType) => {
    return await cartApi.getAvailableStores(params)
  }
)

const changeShipment = createAsyncThunk(
  'PickUpInStore/changeShipment',
  async (params: cartApi.ChangeShipmentType) => {
    return await cartApi.changeShipment(params)
  }
)

export const pickUpInStoreSlice = createSlice({
  name: 'Shipment',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getAvailableStores.pending, state => {
      state.status = 'loading'
      state.error = ''
    })
    builder.addCase(getAvailableStores.fulfilled, (state, action) => {
      state.status = 'done'
      state.requestCode = action.payload.status
      state.data = action.payload.data.data
    })
    builder.addCase(getAvailableStores.rejected, (state, action) => {
      state.status = 'error'
      state.requestCode = null
      state.error = 'rejected'
    })

    builder.addCase(changeShipment.pending, state => {
      state.error = ''
    })
    builder.addCase(changeShipment.fulfilled, (state, action) => {
      state.requestCode = action.payload.status
    })
    builder.addCase(changeShipment.rejected, state => {
      state.status = 'error'
      state.requestCode = null
      state.error = 'rejected'
    })
  }
})

export { getAvailableStores, changeShipment }

export default pickUpInStoreSlice.reducer
