import { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { Button, Card, Stack, Text } from '@leroy-merlin-br/backyard-react'
import { Popover } from 'user/components'
import { useCardRequestContext } from 'user/celebre/context/card-request-context'
import { Spinner } from 'user/celebre/components'
import { useStateMachine } from 'little-state-machine'

import { CurrencyField, ComboBoxField } from 'shared/components'

import { FORMS_NAMES, FORM_DEFAULT_VALUES } from '../../constants'

const IncomeForm = () => {
  const {
    selectOptions,
    isLoadingSelectOptions,
    updateAction,
    activeStep,
    setActiveStep
  } = useCardRequestContext()

  const {
    actions,
    state: { income: incomeData, apiErrors }
  } = useStateMachine({ updateAction })

  const { handleSubmit, control, setError, reset, formState } = useForm({
    mode: 'onChange',
    defaultValues: FORM_DEFAULT_VALUES.income
  })

  const { errors, isValid } = formState

  const hasErrors = useMemo(() => {
    return Object.keys(formState.errors).length > 0
  }, [formState])

  const onSubmit = data => {
    if (!isValid) {
      return
    }

    actions.updateAction({
      income: data,
      apiErrors: { ...apiErrors, income: [] }
    })

    setActiveStep(FORMS_NAMES.invoice)
  }

  useEffect(() => {
    if (incomeData) {
      reset(incomeData)
    }

    if (apiErrors.income) {
      apiErrors.income.forEach(({ code, message }) => {
        setError(code, { type: 'manual', message: message })
      })
    }
  }, [apiErrors.income, incomeData, reset, setError])

  const isActive = activeStep === FORMS_NAMES.income

  if (!isActive) {
    return
  }

  return (
    <Card
      title={
        <Popover
          list={{
            title: 'Os dados sobre renda e profissão serão usados para:',
            items: ['Analisar crédito']
          }}
        >
          Renda e profissão
        </Popover>
      }
    >
      <Spinner isVisible={isLoadingSelectOptions} />

      <form onSubmit={handleSubmit(onSubmit)} data-cy="income-data-form">
        <Stack space="mega">
          <CurrencyField
            name="occupation.income"
            label="Renda mensal"
            rules={{
              required: 'Insira sua renda mensal'
            }}
            control={control}
            state={Boolean(errors.occupation?.income) && 'invalid'}
            hint={errors.occupation?.income?.message}
          />

          <CurrencyField
            name="equityValue"
            label="Patrimônio - opcional"
            control={control}
            state={Boolean(errors.equityValue) && 'invalid'}
            hint={errors.equityValue?.message}
          />

          <ComboBoxField
            name="occupation.cap"
            label="Profissão"
            options={selectOptions.occupations}
            rules={{
              required: 'Escolha uma profissão'
            }}
            control={control}
            state={Boolean(errors.occupation?.cap) && 'invalid'}
            hint={errors.occupation?.cap?.message}
            hasAutoFilter
          />

          <Text size="kilo" color="n500" noMargin>
            *Ao continuar, estou ciente que meus dados serão compartilhados com
            o Banco CETELEM e autorizo a disponibilizarem o meu histórico de
            crédito.
          </Text>

          <Button isStretch type="submit" isDisabled={!isValid || hasErrors}>
            Continuar
          </Button>
        </Stack>
      </form>
    </Card>
  )
}

export default IncomeForm
