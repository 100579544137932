import { useEffect } from 'react'
import { useModal } from '@leroy-merlin-br/backyard-react'

import { LocationForm } from '../../'

const LocationFormModal = () => {
  const { setModal } = useModal()

  useEffect(() => {
    setModal({
      shouldCloseOnOverlayClick: false,
      children: () => <LocationForm showCloseButton={false} />
    })
  }, [setModal])

  return null
}

export default LocationFormModal
