import {
  Heading,
  Row,
  Stack
} from '@leroy-merlin-br/backyard-react'

import * as S from '../../styles/form-styled'
import AddressForm from './components/AddressForm'
import General from './components/General'
import Representative from './components/Representative'

export default function CompanyForm() {
  return (
    <S.Wrapper data-testid="company-form-component">
      <Stack space="kilo">
        <Heading size="mega" as="h2" noMargin>
          Dados da empresa
        </Heading>

        <Row>
          <General />
          <AddressForm />
          <Representative />
        </Row>
      </Stack>
    </S.Wrapper>
  )
}
