import { Fragment } from 'react'
import PropTypes from 'prop-types'

import { AlertMessage } from 'shared/components/AlertMessage'
import { OptimizedImage } from 'shared/components/OptimizedImage'

import { ProductDetails } from './Details'
import * as S from './styled'

const IMAGE_SIZE = { width: 82, height: 82 }

const Product = ({
  animationKeys: {
    hideProduct = false,
    animation = false,
    showDisclaimer = false
  },
  product,
  isMobile,
  removeProduct,
  updateProduct,
  services,
  regionId,
  availableServices,
  productRemovalIsDisabled
}) => {
  const { productUrl, thumbUrl, name } = product

  return (
    <S.Wrapper fadeIn={product?.fadeIn} data-cy="minicart-product">
      {!hideProduct && (
        <Fragment>
          <S.Line key={'productLine'} />
          <S.AnimatedWrapper key={'animatedWrapper'} fadeOut={animation}>
            <a href={productUrl}>
              <OptimizedImage
                as={S.ProductImg}
                src={thumbUrl}
                alt={name}
                width={IMAGE_SIZE.width}
                height={IMAGE_SIZE.height}
                noSrcSet
              />
            </a>
            <ProductDetails
              isMobile={isMobile}
              {...product}
              regionId={regionId}
              removeProduct={removeProduct}
              updateProduct={updateProduct}
              services={services}
              availableServices={availableServices}
              productRemovalIsDisabled={productRemovalIsDisabled}
            />
          </S.AnimatedWrapper>
        </Fragment>
      )}

      {showDisclaimer && (
        <AlertMessage type="success">
          Produto removido com sucesso.
        </AlertMessage>
      )}
    </S.Wrapper>
  )
}

Product.propTypes = {
  animationKeys: PropTypes.shape({
    hideProduct: PropTypes.bool,
    animation: PropTypes.bool,
    showDisclaimer: PropTypes.bool
  }),
  isMobile: PropTypes.bool,
  removeProduct: PropTypes.func,
  updateProduct: PropTypes.func,
  services: PropTypes.array,
  regionId: PropTypes.string,
  availableServices: PropTypes.array,
  product: PropTypes.shape({
    brand: PropTypes.string,
    categoryTree: PropTypes.string,
    externalId: PropTypes.number,
    id: PropTypes.string,
    name: PropTypes.string,
    packaging: PropTypes.number,
    packagingCount: PropTypes.number,
    packagingUnitLabels: PropTypes.shape({
      singular: PropTypes.string,
      plural: PropTypes.string
    }),
    price: PropTypes.shape({
      discount: PropTypes.number,
      from: PropTypes.number,
      to: PropTypes.number,
      total: PropTypes.number
    }),
    productId: PropTypes.number,
    productUrl: PropTypes.string,
    quantity: PropTypes.number,
    shopName: PropTypes.string,
    skuId: PropTypes.string,
    storeId: PropTypes.string,
    thumbUrl: PropTypes.string,
    translatedUnit: PropTypes.string,
    unit: PropTypes.string
  })
}

export default Product
