import execute from './execute'

export type RemoveFromCart = {
  regionId: string
  productName: string
  productId: string
  productPrice: number
  productBrand: string
  productCategory: string
  productQuantity: string
}

export const dataLayerRemoveFromCart = ({
  regionId,
  productName,
  productId,
  productPrice,
  productBrand,
  productCategory,
  productQuantity
}: RemoveFromCart) => {
  const dataObject = {
    event: 'removeFromCart',
    dimension14: regionId,
    ecommerce: {
      remove: {
        products: [
          {
            name: productName,
            id: productId,
            price: productPrice.toFixed(2),
            brand: productBrand,
            category: productCategory || null,
            quantity: productQuantity
          }
        ]
      }
    }
  }

  return dataObject
}

const removeFromCart = (removeFromCartProps: RemoveFromCart) => {
  execute(dataLayerRemoveFromCart(removeFromCartProps))
}

export default removeFromCart
