import {
  Button,
  Checkbox,
  Heading,
  Inline,
  Stack
} from '@leroy-merlin-br/backyard-react'
import { AttachmentForm } from './components/AttachmentForm'
import { BankForm } from './components/BankForm'
import { CompanyForm } from './components/CompanyForm'
import { useFormContext } from '../../contexts/FormContext'
import * as S from './styles/styled'
import * as FormS from './styles/form-styled'
import { Spinner } from '@leroy-merlin-br/backyard-react'
import { ARCHITECT_CONTRACT_FILE_URL } from 'scripts/constants/loyalty/cashback'

export default function Content() {
  const {
    onSubmit,
    legalEntity,
    submitting,
    companyFormIsDisabled,
    form: { register, handleSubmit },
    formState: { errors }
  } = useFormContext()

  const showForm = Boolean(legalEntity) || !companyFormIsDisabled

  const contractUrl = ARCHITECT_CONTRACT_FILE_URL

  return (
    <S.Wrapper data-testid="form-content-component">
      <Stack space="mega">
        <Heading size="tera" as="h1" noMargin>
          Cadastro
        </Heading>

        <form>
          <Stack space="mega">
            <CompanyForm />

            {showForm && (
              <>
                <BankForm />
                <AttachmentForm />

                <S.ContractCheckWrapper>
                  <S.ContractCheck>
                    <Checkbox crossOrigin="" {...register('contractCheck')} data-testid="contract-check" />

                    <span>
                      Declaro que li e concordo com o{' '}
                      <a href={contractUrl} target="_blank">
                        Código de Conduta da Leroy Merlin
                      </a>
                    </span>
                  </S.ContractCheck>
                  <FormS.Error>{errors.contractCheck?.message}</FormS.Error>
                </S.ContractCheckWrapper>

                <Inline alignX="center">
                  <Button
                    type="button"
                    onClick={handleSubmit(onSubmit, console.warn)}
                    disabled={submitting}
                    data-testid="submit-button"
                  >
                    {submitting ? (
                      <Spinner
                        size="giga"
                        appearance="info"
                        data-testid="submitting-spinner"
                      />
                    ) : (
                      <span>Salvar e Finalizar</span>
                    )}
                  </Button>
                </Inline>
              </>
            )}
          </Stack>
        </form>
      </Stack>
    </S.Wrapper>
  )
}
