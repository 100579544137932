import { Button } from '@leroy-merlin-br/backyard-react'

import { clickAlertNewVersionButton } from '../CheckOldAppModalBehaviour'

const location = window.location
const { protocol, hostname } = location

type mustUpdateOrAlertUpdateProps = {
  category: string
}

const UpdateNowButtonHelper = ({ category }: mustUpdateOrAlertUpdateProps) => {
  const clickedUpdateNow = (event: React.MouseEvent) => {
    clickAlertNewVersionButton(category, 'atualizar_agora')
    event.preventDefault()
    window.location.replace(`${protocol}//${hostname}/instalar-app`)
  }

  return (
    <Button
      isStretch
      data-cy="update-app-to-new-version"
      onClick={(eventClick: React.MouseEvent) => clickedUpdateNow(eventClick)}
    >
      Atualizar agora
    </Button>
  )
}

export default UpdateNowButtonHelper
