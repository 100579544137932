import { memo } from 'react'
import PropTypes from 'prop-types'
import {
  Text,
  Hidden,
  Inline,
  Stack,
  Heading,
  Icon
} from '@leroy-merlin-br/backyard-react'
import { ShoppingCartStarsOutline } from '@backyard-ui/icons'

import { Shell } from 'shared/components/Shell'

import { formatters } from 'utils/formatters'
import { formatDate } from 'utils/date'

import * as S from './styled'
import { PointsTooltip } from '../PointsTooltip'

const Points = ({ user, isLoading, isLoyaltyPro }) => {
  const {
    points: {
      actual,
      season: { expireAt, toExpire }
    }
  } = user

  const hasBalanceToExpire = toExpire > 0

  if (isLoading) {
    return <Shell height={95} borderRadius="mega" />
  }

  return (
    <S.PointsWrapper>
      <S.Points isLoyaltyPro={isLoyaltyPro}>
        <Hidden when={['untilKilo', 'kilo']}>
          <Text color="n900" isBold size="giga" noMargin>
            Seu saldo
            <Hidden when={['untilKilo']}> total é de:</Hidden>
            <Heading
              as="span"
              size="exa"
              color="v500"
              align="center"
              noMargin
              isBold
            >
              {formatters.toDecimal(actual)}{' '}
              <Text as="span" size="kilo" color="v500" noMargin>
                {actual === 1 ? 'pt' : 'pts'}
              </Text>
            </Heading>
          </Text>
        </Hidden>

        <Stack alignX="center" space="bit">
          {hasBalanceToExpire && (
            <>
              <S.Ticket>
                <Text size="kilo" noMargin isBold>
                  Expiração de pontos
                </Text>
                <S.TicketValue>
                  <Stack space="bit" alignX="center">
                    <Heading color="v500" size="tera" noMargin isBold>
                      {formatters.toDecimal(toExpire)}
                      <Text as="span" color="black" size="kilo" noMargin>
                        {actual === 1 ? 'pt' : 'pts'}
                      </Text>
                    </Heading>
                    <Text color="black" noMargin isBold>
                      {formatDate(expireAt, 'DD/MM/YYYY')}
                    </Text>
                  </Stack>
                </S.TicketValue>
              </S.Ticket>

              <div>
                <Text
                  as="a"
                  size="kilo"
                  noMargin
                  color="v500"
                  href="/modular/programa-fidelidade"
                  isBold
                >
                  clique aqui{' '}
                </Text>
                <Text as="span" size="kilo" noMargin color="black">
                  e saiba mais sobre expiração de pontos
                </Text>
              </div>
            </>
          )}

          {isLoyaltyPro && (
            <Inline alignY="center" space="bit">
              <Icon
                color="secondary"
                as={ShoppingCartStarsOutline}
                size="kilo"
              />
              <Text size="kilo" color="n600" noMargin>
                Bônus de clientes
              </Text>
              <PointsTooltip />
            </Inline>
          )}
        </Stack>
      </S.Points>
    </S.PointsWrapper>
  )
}

Points.propTypes = {
  user: PropTypes.shape({
    points: PropTypes.shape({
      actual: PropTypes.number
    })
  }).isRequired
}

export default memo(Points)
