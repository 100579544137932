import styled from '@emotion/styled'
import { css } from '@emotion/react'

const shipmentItemStyle = () => css`
  display: flex;
  align-items: stretch;
  flex-direction: row;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`
export const ShipmentItem = styled('div')(shipmentItemStyle)

const productsWrapperStyle = () => css`
  padding: 16px 0;
`

export const ProductsWrapper = styled('div')(productsWrapperStyle)

const dividerStyle = () => css`
  border: none;
  border-top: 1px solid #DEDAD2;
  margin: 16px 0;
`

export const Divider = styled('div')(dividerStyle)
