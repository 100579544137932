import { toast } from '@leroy-merlin-br/backyard-react'
import { AxiosResponse } from 'axios'
import { useCallback, useEffect, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { isServerError, removeNonNumericCharacters } from 'user/utils'

import mask from 'utils/maskingUtils'

import { VerifyLayout } from '../../components/VerifyLayout'
import { useMyAccountContactContext } from '../../context/my-account-contact-context'
import {
  sendCodeAuth,
  sendCodeAuthIdentity,
  updateMainContact
} from '../../services'

function useQuery () {
  const { search } = useLocation()

  return useMemo(() => new URLSearchParams(search), [search])
}

const CheckPhone = () => {
  const history = useHistory()
  const { contextData, updateData } = useMyAccountContactContext()

  const query = useQuery()

  const origin = query.get('origin')
  const mainCellphone = query.get('mainCellphone')
  const email = query.get('email')
  const newMainCellphone = query.get('newMainCellphone')
  const identityCode = query.get('identityCode')
  const fiscalId = query.get('fiscalId')

  const actions = {
    editEmail: {
      text: () => (
        <>
          Para sua segurança, digite o código de 6 dígitos recebido em <strong>seu telefone</strong> {mask.maskedText('phone', mainCellphone)}
        </>
      ),
      onSuccess: (identityCode: string) => {
        updateData({ ...contextData, identityCode })

        history.push({
          pathname: '/editar-email',
          search: `?origin=${origin}&identityCode=${identityCode}&email=${email}&fiscalId=${fiscalId}`
        })
      }
    },
    editMainCell: {
      text: () => (
        <>
          Para sua segurança, digite o código de 6 dígitos recebido em <strong>seu telefone</strong> {newMainCellphone}
          {' '}<a
                onClick={() => history.push('editar-celular-principal')}
                data-gtm-element-id="click-edit-phone-validation-code-button"
              >editar</a>
        </>
      ),
      onSuccess: async (deviceCode: string) => {
        try {
          await updateMainContact({
            phone: `+55${removeNonNumericCharacters(newMainCellphone)}`,
            identityCode,
            deviceCode
          })

          updateData({ ...contextData, mainCellphone })

          toast.primary('Celular principal alterado com sucesso!', {
            variant: 'light'
          })
        } catch (error) {
          const { status } = error as AxiosResponse

          if (status === 422) {
            return toast.critical('Não foi possível trocar o celular principal!', {
              variant: 'light'
            })
          }

          const hasServerError = status && isServerError(status)

          if (hasServerError) {
            toast.critical('Não foi possível trocar o celular principal!', {
              variant: 'light'
            })
          }
        } finally {
          history.push('/meus-contatos')
        }
      }
    }
  }

  const handleEditEmailAndSendCodeIdentity = useCallback(async () => {
    try {
      await sendCodeAuthIdentity({
        type: 'phone',
        context: 'confirm_identity'
      })
    } catch (error) {
      const { status } = error as AxiosResponse

      if (status === 422) {
        return toast.critical('Não foi possível enviar o código de segurança!', {
          variant: 'light'
        })
      }

      const hasServerError = status && isServerError(status)

      if (hasServerError) {
        toast.critical('Não foi possível enviar o código de segurança!', {
          variant: 'light'
        })
      }
    }
  }, [])

  const handleEditMainCellAndSendCode = useCallback(async () => {
    try {
      await sendCodeAuth({
        type: 'phone',
        contact: removeNonNumericCharacters(newMainCellphone),
        context: 'confirm_device'
      })
    } catch (error) {
      const { status } = error as AxiosResponse

      if (status === 422) {
        return toast.critical('Não foi possível enviar o código de segurança!', {
          variant: 'light'
        })
      }

      const hasServerError = status && isServerError(status)

      if (hasServerError) {
        toast.critical('Não foi possível enviar o código de segurança!', {
          variant: 'light'
        })
      }
    }
  }, [newMainCellphone])

  useEffect(() => {
    (async () => {
      if (origin === 'editEmail') {
        await handleEditEmailAndSendCodeIdentity()
      } else if (origin === 'editMainCell') {
        await handleEditMainCellAndSendCode()
      }
    })()
  }, [handleEditEmailAndSendCodeIdentity, handleEditMainCellAndSendCode, origin])

  return (
    <VerifyLayout
      infoText={actions[origin].text()}
      onSuccess={actions[origin].onSuccess}
      onRensendCode={
        origin === 'editEmail'
          ? handleEditEmailAndSendCodeIdentity
          : handleEditMainCellAndSendCode
      }
      payload={{
        fiscalId,
        context: origin === 'editEmail' ? 'confirm_identity' : 'confirm_device'
      }}
    />
  )
}

export default CheckPhone
