import { TextField as Input } from '@leroy-merlin-br/backyard-react'
import { useController } from 'react-hook-form'

const TextField = props => {
  const { field } = useController(props)
  const { onChange, ref } = field

  return <Input {...field} {...props} ref={ref} onChange={onChange} />
}

export default TextField
