import { Hidden, Visible } from '@leroy-merlin-br/backyard-react'
import { useLocation } from 'react-router-dom'

import { PhoneNumberLayout } from '../../components/PhoneNumberLayout'
import { Phone } from '../../types'

const title = 'Editar telefone'

type Location = {
  state: { phone: Phone }
}

const EditPhoneNumber = () => {
  const { state }: Location = useLocation()

  return (
    <>
      <Visible when={['untilKilo', 'kilo']}>
        <PhoneNumberLayout title={title} edit={state?.phone} />
      </Visible>
      <Hidden when={['untilKilo', 'kilo']}>
        <PhoneNumberLayout
          isDesktop
          title={title}
          edit={state?.phone}
        />
      </Hidden>
    </>
  )
}

export default EditPhoneNumber
