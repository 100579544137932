import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { Theme } from 'scripts/react-components/themes/Theme'

import { ContainerStyledProps, GradientStyledProps } from './types'

const iconWrapperStyles = ({ theme }: Theme) => css`
  label: variant-carousel-navigation-button__icon-wrapper;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  pointer-events: auto;

  width: ${theme.spacings.tera};

  svg {
    width: ${theme.spacings.kilo};
    fill: ${theme.colors.n900};
  }
`

const containerStyles = ({ mode, theme }: ContainerStyledProps) => {
  const baseStyles = css`
    label: variant-carousel-navigation-button__container;

    pointer-events: none;
    position: absolute;
    top: 0;

    display: flex;

    height: 100%;
    width: ${theme.spacings.zetta};
    z-index: 1;

    &.swiper-button-disabled {
      display: none;
    }
  `

  const modeStyles = {
    prev: css`
      left: 0;
      background: linear-gradient(
        to left,
        transparent,
        ${theme.colors.white} 45%,
        ${theme.colors.white} 100%
      );
    `,
    next: css`
      right: 0;
      justify-content: flex-end;
      background: linear-gradient(
        to right,
        transparent,
        ${theme.colors.white} 45%,
        ${theme.colors.white} 100%
      );
    `
  }

  return css`
    ${modeStyles[mode]}
    ${baseStyles}
  `
}

export const IconWrapper = styled.div(iconWrapperStyles)
export const Container = styled.div(containerStyles)
