import type { SVGAttributes } from 'react'
import { forwardRef } from 'react'

const PreferencesIcon = forwardRef<SVGSVGElement, SVGAttributes<SVGSVGElement>>((props, ref) => (
  <svg
    ref={ref}
    viewBox="0 0 331.000000 303.000000"
    preserveAspectRatio="xMidYMid meet"
    {...props}
  >
    <g
      transform="translate(0.000000,303.000000) scale(0.100000,-0.100000)"
      fill="#000000"
      stroke="none"
    >
      <path d="M1015 2531 c-42 -18 -50 -40 -53 -152 l-4 -109 -105 0 c-97 0 -106
      -2 -128 -25 -17 -16 -25 -35 -25 -60 0 -25 8 -44 25 -60 23 -23 31 -25 130
      -25 l105 0 0 -90 c0 -57 5 -104 15 -125 23 -58 90 -70 135 -25 18 18 20 34 20
      130 l0 110 110 0 c104 0 112 1 135 25 35 34 34 88 -1 120 -25 23 -34 25 -135
      25 l-109 0 0 109 c0 89 -3 113 -17 130 -20 24 -68 35 -98 22z"/>
      <path d="M1943 2210 c-35 -14 -46 -40 -138 -307 l-87 -251 -211 -73 c-307
      -105 -315 -108 -337 -136 -25 -32 -25 -63 0 -95 15 -20 76 -44 277 -113 142
      -48 263 -94 269 -102 7 -7 51 -128 99 -268 52 -151 95 -261 106 -271 27 -24
      84 -23 109 2 10 10 60 136 109 278 50 143 94 262 98 266 4 4 123 46 263 94
      267 91 300 109 300 161 0 56 -40 78 -310 170 -140 48 -256 88 -257 89 -2 1
      -43 119 -92 261 -49 142 -98 266 -108 276 -23 23 -62 31 -90 19z m97 -515 c28
      -82 59 -158 68 -167 9 -9 89 -42 176 -72 88 -31 161 -57 163 -59 2 -2 -69 -30
      -157 -62 -120 -43 -166 -64 -183 -85 -13 -15 -47 -94 -76 -176 -29 -82 -54
      -150 -56 -152 -1 -1 -19 46 -40 105 -77 222 -72 211 -123 237 -26 12 -107 47
      -179 77 -77 30 -128 56 -120 60 6 4 80 30 162 59 83 28 157 58 167 67 9 8 42
      89 74 179 33 94 61 159 65 152 4 -7 30 -80 59 -163z"/>
      <path d="M899 1221 l-29 -29 0 -171 0 -171 -169 0 c-168 0 -170 0 -200 -26
      -40 -33 -43 -83 -6 -119 24 -25 27 -25 200 -25 l175 0 0 -166 c0 -166 5 -195
      39 -221 27 -21 79 -15 106 12 25 24 25 27 25 200 l0 175 174 0 c158 0 176 2
      198 20 14 11 28 31 30 44 7 36 -17 85 -47 96 -15 6 -100 10 -191 10 l-164 0 0
      175 c0 173 0 176 -25 200 -34 35 -78 33 -116 -4z"/>
    </g>
  </svg>
))

export default PreferencesIcon
