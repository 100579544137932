const HouseholdItemsOutline = () => (
  <svg
    viewBox="0 0 71.13 65.83"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m49.38,30.04l5.7-.71c2.36-.3,4.5-1.52,5.95-3.41l9.35-12.16c1.1-1.43.97-3.46-.31-4.74l-6.91-6.91c-1.49-1.49-3.85-1.64-5.52-.36l-11.74,9.03c-1.89,1.45-3.11,3.59-3.41,5.96l-.71,5.7c-.04.36-.22.69-.49.93l-5.39,4.79-5.09-4.52c-.36-.33-.53-.75-.5-1.24.34-6.22-1.36-11.35-5.05-15.25C18.67.19,8.57,0,8.47,0,5.47,0,3.29,1.15,2.23,2.23,1.15,3.29,0,5.47,0,8.47c0,.1.19,10.2,7.15,16.79,3.9,3.69,9.03,5.39,15.27,5.05.46-.03.88.14,1.18.49l4.11,4.64-19.04,16.91c-2.4,2.04-3.36,5.41-2.17,8.6.72,1.95,2.28,3.54,4.21,4.32,3.24,1.29,6.71.33,8.75-2.08l16.45-18.53,16.44,18.51c1.44,1.7,3.55,2.67,5.8,2.67,2.03,0,4.01-.81,5.43-2.23,1.46-1.46,2.26-3.4,2.26-5.46,0-2.22-.97-4.33-2.66-5.79l-19.08-16.93,4.35-4.9c.24-.27.57-.44.93-.49Zm-23.53-1.21c-.91-1.04-2.22-1.59-3.6-1.5-5.37.29-9.75-1.13-13.03-4.23C3.26,17.45,2.99,8.56,2.99,8.47c0-2.23.84-3.62,1.33-4.1.02-.02.03-.03.05-.05.47-.5,1.87-1.33,4.1-1.33.09,0,8.99.27,14.62,6.22,3.1,3.28,4.53,7.66,4.23,13.02-.09,1.37.45,2.69,1.49,3.64l4.84,4.29-3.71,3.29-4.1-4.63Zm35.36,25.78c1.04.9,1.64,2.19,1.64,3.54,0,1.28-.48,2.43-1.39,3.34-.86.86-2.07,1.36-3.31,1.36-1.35,0-2.67-.61-3.52-1.62,0-.01-.02-.02-.03-.03l-16.69-18.79,4.2-4.73,19.09,16.94Zm-20.33-20.06h-.01s-23.67,26.67-23.67,26.67c-1.28,1.5-3.43,2.07-5.43,1.24-1.13-.47-2.04-1.41-2.46-2.56-.74-1.96-.17-4.02,1.33-5.29l26.17-23.24s.03-.03.05-.05l6.43-5.71c.81-.72,1.34-1.72,1.47-2.8l.71-5.7c.2-1.57,1.01-2.99,2.26-3.95l11.87-9.13s.01,0,.02,0l.64-.52c.4-.32,1-.24,1.32.15.28.35.26.77,0,1.09h0l-11.42,10.46c-.61.56-.65,1.5-.09,2.11.3.32.7.49,1.1.49.36,0,.72-.13,1.01-.39l11.15-10.22.31-.29s.04-.02.06-.04c.37-.26.87-.24,1.2.09.34.34.34.81.05,1.16h0l-10.51,11.49c-.41.44-.57,1.1-.31,1.65.29.6.84.89,1.38.89.4,0,.81-.16,1.1-.49l10.46-11.42h0c.34-.29.83-.32,1.18-.02.39.34.48.89.15,1.28l-.93,1.12-8.81,11.45c-.96,1.25-2.39,2.07-3.95,2.26l-5.7.71s0,0,0,0c-.95,0-1.85.41-2.48,1.11l-5.66,6.37Z"
      />
    </g>
  </svg>
)

export default HouseholdItemsOutline
