import { useContext } from 'react'
import PropTypes from 'prop-types'
import { Text, Heading } from '@leroy-merlin-br/backyard-react'

import { Ellipsis } from 'shared/components/Ellipsis'
import { ShapeThumb } from 'shared/constants/prop-types'
import { HeartIcon } from 'shared/components/HeartIcon'
import { formatDate } from 'shared/utils/date'

import { WishlistContext } from 'site/Wishlist/context'

import * as S from './styled'

const ThumbInfo = ({
  data: { id, inWishList, publishDate, title = '', url = '' }
}) => {
  const wishlistValues = useContext(WishlistContext)

  const { onPressHeart, isOnWishlist } = wishlistValues

  const wishlistAction = () => onPressHeart({ inWishList, id }, 'contents')

  return (
    <S.InfoWrapper>
      <S.LinkTitle href={url} title={title}>
        <Heading as="h4" size="giga" color="n900" noMargin>
          <Ellipsis clamp={2}>{title}</Ellipsis>
        </Heading>
      </S.LinkTitle>

      <S.AuthorWrapper>
        <Text align="center" as="span" size="kilo" color="n400" noMargin>
          {formatDate(publishDate)}
        </Text>
        <HeartIcon onClick={wishlistAction} isActive={isOnWishlist(id)} />
      </S.AuthorWrapper>
    </S.InfoWrapper>
  )
}

ThumbInfo.propTypes = {
  data: PropTypes.shape({ ...ShapeThumb }).isRequired
}

export default ThumbInfo
