import $ from 'jquery'

import BaseComponent from 'scripts/components/base-component'

class ScrollPayment extends BaseComponent {
  static componentName = 'scroll-payment'

  static DOMEvents = {
    'click [data-scroll-payment-listener]': 'onElementClick'
  }

  onElementClick (element) {
    element.currentTarget.scrollIntoView({ inline: 'center', block: 'nearest' })
  }
}

export default $el => new ScrollPayment($el)

export { ScrollPayment as Component }
