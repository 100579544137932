import { css } from '@emotion/react'
import styled from '@emotion/styled'

const baseStyles = ({ theme }) => css`
  label: filters-mobile;

  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  height: 100%;
  width: 100%;

  background: ${theme.colors.n100};

  display: flex;
  flex-flow: column nowrap;

  transform: translateX(100%);

  transition: transform ${theme.transition.leave} ease;

  z-index: ${theme.zIndex.backdrop};
`
const openStyles = ({ isOpen }) =>
  isOpen &&
  css`
    label: filters-mobile--open;

    transform: translateX(0);
  `

const filtersContainerStyles = ({ theme }) => css`
  label: filters-mobile__container;

  overflow-y: auto;
  padding: ${theme.spacings.mega} 0;
`

const overlayStyles = ({ theme }) => css`
  label: overlay;

  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${theme.colors.overlay};

  z-index: ${theme.zIndex.modal};
`

const wrapperStyles = ({ theme }) => css`
  label: filters-mobile__wrapper;

  [target='card'] {
    ${theme.media.mega} {
      padding: ${theme.spacings.mega} 0;

      & > div {
        padding: 0 ${theme.spacings.mega};
        overflow-y: hidden;
      }
    }
  }
`

export const MobileFilters = styled('div')(baseStyles, openStyles)
export const FiltersContainer = styled('div')(filtersContainerStyles)
export const Overlay = styled('div')(overlayStyles)
export const Wrapper = styled('div')(wrapperStyles)
