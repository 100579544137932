import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { Theme } from 'newCheckout/types/Theme'

const panelStyles = ({ theme }: Theme) => css`
  label: success-payment-data-creditcard__panel;

  display:  flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: ${theme.spacings.mega};

  padding: ${theme.spacings.mega} ${theme.spacings.giga};

  background-color: ${theme.colors.n100};
  border-radius: ${theme.borderRadius.giga};
`
export const Panel = styled('div')(panelStyles)

const logoStyles = ({ theme }: Theme) => css`
  label: success-payment-data-creditcard__logo;

  width: 36px;
  height: 24px;

  svg {
    width: 36px;
    height: 24px;

    border: 1px solid ${theme.colors.n200};
    border-radius: ${theme.borderRadius.mega};
  }
`
export const Logo = styled('div')(logoStyles)

const valueStyles = ({ theme }: Theme) => css`
  label: success-payment-data-creditcard__value;

  width: 100%;
  align-self: center;

  ${theme.media.kilo} {
    width: auto;
    margin-left: auto;
  }
`
export const Value = styled('div')(valueStyles)
