import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { AsyncRequest } from 'newCheckout/types/AsyncRequest'
import { ProductRef } from 'newCheckout/types/Services'
import { servicesApi } from 'newCheckout/api'

const initialState: AsyncRequest<ProductRef[], string | null> = {
  status: 'init',
  error: null,
  requestCode: null,
  data: []
}

const checkServices = createAsyncThunk(
  'Services/checkServices',
  async (checkServices: servicesApi.checkServicesType, thunkAPI) => {
    return await servicesApi.checkServices(checkServices)
  }
)

const addService = createAsyncThunk(
  'Services/addService',
  async (addService: servicesApi.addServiceType, thunkAPI) => {
    return await servicesApi.addServices(addService)
  }
)

const changeServiceQuantity = createAsyncThunk(
  'Services/changeServiceQuantity',
  async (changeServiceQuantity: servicesApi.changeServiceType, thunkAPI) => {
    return await servicesApi.changeServiceQuantity(changeServiceQuantity)
  }
)

const removeService = createAsyncThunk(
  'Services/removeService',
  async (removeService: servicesApi.removeServiceType, thunkAPI) => {
    return await servicesApi.removeService(removeService)
  }
)

export const serviceSlice = createSlice({
  name: 'Services',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(checkServices.pending, state => {
      state.status = 'loading'
      state.error = null
    })
    builder.addCase(checkServices.fulfilled, (state, action) => {
      state.status = 'done'
      state.requestCode = action.payload.status
      state.data = action.payload.data.data
    })
    builder.addCase(checkServices.rejected, (state, action) => {
      state.status = 'error'
      state.requestCode = null
    })

    builder.addCase(addService.pending, state => {})
    builder.addCase(addService.fulfilled, (state, action) => {})
    builder.addCase(addService.rejected, (state, action) => {})

    builder.addCase(changeServiceQuantity.pending, state => {})
    builder.addCase(changeServiceQuantity.fulfilled, (state, action) => {})
    builder.addCase(changeServiceQuantity.rejected, (state, action) => {})

    builder.addCase(removeService.pending, state => {})
    builder.addCase(removeService.fulfilled, (state, action) => {})
    builder.addCase(removeService.rejected, (state, action) => {})
  }
})

export { checkServices, addService, changeServiceQuantity, removeService }

export default serviceSlice.reducer
