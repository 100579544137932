import { Row, Col, Stack } from '@leroy-merlin-br/backyard-react'

import { Shell } from 'shared/components/Shell'

const FormSkeleton = () => {
  return (
    <Stack space="mega">
      <Row>
        <Col size={{ giga: 6 }}>
          <Shell height={34} borderRadius="tera" />
        </Col>
        <Col size={{ giga: 6 }}>
          <Shell height={34} borderRadius="tera" />
        </Col>
      </Row>

      <Shell height={34} borderRadius="tera" />

      <Row>
        <Col size={{ giga: 6 }}>
          <Shell height={34} borderRadius="tera" />
        </Col>
        <Col size={{ giga: 6 }}>
          <Shell height={34} borderRadius="tera" />
        </Col>
      </Row>

      <Shell height={34} borderRadius="tera" />

      <Shell height={34} borderRadius="tera" />
    </Stack>
  )
}

export default FormSkeleton
