import emitter from 'scripts/utils/emitter'

const EVENT_PREFIX = 'stickyFeedback'

/**
 * Show success message to user.
 * @param {String} key
 */
export const success = (message, title = 'Sucesso:') => {
  emitter.emit(`${EVENT_PREFIX}:success`, {
    title: title,
    content: message
  })
}

/**
 * Show error message to user.
 * @param {String} key
 */
export const error = (message, title = 'Ops! Ocorreu o seguinte erro:') => {
  emitter.emit(`${EVENT_PREFIX}:error`, {
    title: title,
    content: message
  })
}
