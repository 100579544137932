import { ArrowRight, Coupon as CouponIcon } from 'frontendCheckout/assets/icons'

type ButtonTextDefaultProps = {}

export const ButtonTextDefault = ({}: ButtonTextDefaultProps) => {
  return (
    <>
      <CouponIcon />
      <span>Tem cupom disponível?</span>
      <span className="ml-auto text-sm font-normal items-center flex gap-2 color-white">
        Aplique aqui
        <ArrowRight />
      </span>
    </>
  )
}
