import { PropsWithChildren } from 'react'

import { Component, componentType } from '../BaseComponent/BaseComponent'

type ContentProps = {}

export const Content = ({ children }: PropsWithChildren<ContentProps>) => {
  return (
    <Component.Content>
      <div aria-label={componentType}>{children}</div>
    </Component.Content>
  )
}
