import { Markdown } from 'shared/components/Markdown'

interface MiddleContentProps {
  middleDescription: string
}

const MiddleContent = ({ middleDescription }: MiddleContentProps) => {
  return (
    <div>
      <Markdown content={middleDescription} type="small" />
    </div>
  )
}

export default MiddleContent
