import { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import axios from 'axios'
import { Visible, Button, Stack, Text } from '@leroy-merlin-br/backyard-react'

import emitter from 'scripts/utils/emitter'

import { GardenDrawer } from 'shared/components/GardenDrawer'

import mask from 'utils/maskingUtils'
import { showSpinner, hideSpinner } from 'utils/Spinner'

import * as S from './styled'

const HybrisCart = ({ assistedSalesLinks }) => {
  const { base, cartNew, saleOrder, quote } = assistedSalesLinks

  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [carts, setCarts] = useState([])

  const getCartsInfo = async () => {
    try {
      return await axios.get('/hybris/carts')
    } catch {
      hideSpinner()

      emitter.emit('stickyFeedback:error', {
        title: 'Ops! Ocorreu o seguinte erro:',
        content: 'Tente novamente mais tarde.'
      })
    }
  }

  const onTriggerClick = async event => {
    event.preventDefault()

    showSpinner()
    const {
      data: { carts }
    } = await getCartsInfo()

    setCarts(carts)
    setIsDrawerOpen(true)
    hideSpinner()
  }

  const onCloseDrawer = () => {
    setIsDrawerOpen(false)
  }

  const renderItens = ({ code, cpf, price, customerName, typeName, url }) => {
    if (typeName === 'Carrinho') {
      return (
        <S.Item href={`${url}${code}`} key={code}>
          <S.DescriptionWrapper>
            <S.Key>Carrinho:</S.Key>
            <S.Value>{code}</S.Value>
            {price && (
              <S.Price>
                R$ {price.integers},{price.decimals}
              </S.Price>
            )}
          </S.DescriptionWrapper>
        </S.Item>
      )
    }

    return (
      <S.Item href={`${url}${code}`} key={code}>
        <S.DescriptionWrapper>
          <S.Key>Pedido:</S.Key>
          <S.Value>{code}</S.Value>
          {price && (
            <S.Price>
              R$ {price.integers},{price.decimals}
            </S.Price>
          )}
        </S.DescriptionWrapper>
        <S.DescriptionWrapper>
          <S.Key>Cliente:</S.Key>
          <S.Value>{customerName}</S.Value>
        </S.DescriptionWrapper>
        <S.DescriptionWrapper>
          <S.Key>CPF:</S.Key>
          <S.Value>{mask.maskedText('cpf', cpf)}</S.Value>
        </S.DescriptionWrapper>
      </S.Item>
    )
  }

  const drawerClass = classnames('wishlist-drawer', 'va-menu', {
    'is-opened': isDrawerOpen
  })

  return (
    <section>
      <a href="#" onClick={event => onTriggerClick(event)}>
        <i className="glyph glyph-cart" />
        <Visible when={['giga', 'tera']}>
          <span className="content">Ver Carrinhos</span>
        </Visible>
      </a>

      <GardenDrawer
        isOpen={isDrawerOpen}
        overlayClick={onCloseDrawer}
        className={drawerClass}
      >
        <S.Wrapper>
          <Stack space="kilo">
            <Button as="a" href={`${base}${cartNew}`} size="kilo" isStretch>
              Iniciar novo atendimento
            </Button>

            {carts.length > 0 && (
              <Fragment>
                <Text color="n800" size="giga" noMargin isBold>
                  Últimos Atendimentos
                </Text>
                {carts.map(renderItens)}
              </Fragment>
            )}

            <Button as="a" href={`${base}${saleOrder}`} size="kilo" isStretch>
              Buscar Pedidos
            </Button>
            <Button as="a" href={`${base}${quote}`} size="kilo" isStretch>
              Buscar Orçamentos
            </Button>
          </Stack>
        </S.Wrapper>
      </GardenDrawer>
    </section>
  )
}

HybrisCart.propTypes = {
  assistedSalesLinks: PropTypes.shape({
    base: PropTypes.string,
    cartNew: PropTypes.string,
    saleOrder: PropTypes.string,
    quote: PropTypes.string
  })
}

export default HybrisCart
