import { ModalProvider } from '@leroy-merlin-br/backyard-react'

import reactComponentLoader from 'scripts/react-component-loader'

import { Payment } from 'checkout/pages/Payment'
import { UserInvalid } from 'checkout/components/Disclaimers'
import { CouponAvailable } from 'checkout/components/CouponAvailable'
import { BankSlipInstallments } from 'checkout/components/BankSlipInstallments'
import { Pix } from 'checkout/pages/Success/Pix'
import { DiscountsInfoOpenButton } from 'checkout/components/DiscountsModal/components'

import { useStandaloneService } from 'frontendCheckout/hooks'

import { CouponAlertMessage } from '../shared/components'
import { CheckoutModal } from './components/CheckoutModal'

const PixApp = (props) => {
  const shippingInformation = JSON.parse(props.shippingInformation)

  return <Pix {...props} shippingInformation={shippingInformation} />
}

const Modal = (props) => {
  return (
    <ModalProvider>
      <CheckoutModal {...props} />
    </ModalProvider>
  )
}

const DiscountInfoLabelModalProvider = (props) => {
  const totalDiscountsByType = JSON.parse(props.totalDiscountsByType)

  return (
    <ModalProvider>
      <DiscountsInfoOpenButton
        {...props}
        totalDiscountsByType={totalDiscountsByType}
      />
    </ModalProvider>
  )
}

const CouponAlertMessageProvider = (props) => {
  const { services, hasShippingOptions } = props

  const { isStandaloneServiceCart } = useStandaloneService({
    services: JSON.parse(services),
    isCartWithoutProducts: !JSON.parse(hasShippingOptions)
  })

  return !isStandaloneServiceCart ? <CouponAlertMessage /> : <></>
}

const OldPaymentCouponProvider = (props) => {
  const { services, hasShippingOptions } = props

  const { isStandaloneServiceCart } = useStandaloneService({
    services: JSON.parse(services),
    isCartWithoutProducts: !JSON.parse(hasShippingOptions)
  })

  return !isStandaloneServiceCart ? <Payment {...props} /> : <></>
}

reactComponentLoader(PixApp, '[data-success-pix]')
reactComponentLoader(Modal, '[data-checkout-modal]')
reactComponentLoader(OldPaymentCouponProvider, '[data-checkout-payment]')
reactComponentLoader(UserInvalid, '[data-checkout-disclaimer-user-invalid]')
reactComponentLoader(CouponAvailable, '[data-checkout-coupon-available]')
reactComponentLoader(
  BankSlipInstallments,
  '[data-checkout-bank-slip-installments]'
)
reactComponentLoader(
  DiscountInfoLabelModalProvider,
  '[data-discount-info-label]'
)
reactComponentLoader(CouponAlertMessageProvider, '[data-coupon-alert-message]')
