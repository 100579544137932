import styled from '@emotion/styled'
import { css } from '@emotion/react'

const priceWrapperstyles = ({ theme }) => css`
  label: product__price-info-wrapper;

  display: flex;
  align-items: flex-start;
  flex-direction: 'row';
  margin-top: ${theme.spacings.mega};
`

const priceWrapperMobilestyles = ({ theme, isMobile }) =>
  isMobile && css`
  flex-direction: ${'column'};
  margin-top: ${theme.spacings.bit};
`

const priceInfostyles = ({ theme }) => css`
  label: product__price-info;

  display: flex;
  flex-direction: column;
  margin-bottom: 0;

`
const priceInfoMobilestyles = ({ theme, isMobile }) =>
  isMobile && css`
  margin-bottom: ${theme.spacings.byte};
`

const unitPricestyles = ({ theme, decoration }) => css`
  label: product__unit-price;

  color: ${theme.colors.n400};
  font-size: ${theme.typography.text.kilo.fontSize};
  height: 20px;
  line-height: ${theme.typography.text.kilo.lineHeight};
  text-align: left;
  text-decoration: ${decoration ? 'line-through' : 'unset'};
`

const totalPricestyles = ({ theme }) => css`
  label: product__total-price;

  font-size: ${theme.typography.subHeadings.mega.fontSize};
  height: 20px;
  line-height: ${theme.typography.text.kilo.lineHeight};
  font-weight: ${theme.font.weight.bold};
`

const priceTagStyles = ({ theme }) =>
  css`
    label: price-tag__label;

    display: flex;
    margin-left: auto;
    margin-top: auto;
    justify-content: flex-end;
    padding: ${theme.spacings.bit};

    color: ${theme.colors.white};
    font-size: ${theme.typography.text.kilo.fontSize};
    font-weight: ${theme.font.weight.semibold};
    background-color: ${theme.colors.p600};
  `

const priceTagMobileStyles = ({ theme, isMobile }) =>
  isMobile && css`
    margin-left: initial;
    margin-top: initial;
    margin-bottom: ${theme.spacings.byte};
`

const changeItemTypeDesktopWrapperStyles = ({ theme }) => css`
  label: product__item-type-desktop-wrapper;

  margin-left: auto;
`

const changeItemTypeMobileWrapperStyles = ({ theme }) => css`
  label: product__item-type-mobile-wrapper;

  margin-top: ${theme.spacings.mega};
  margin-bottom: ${theme.spacings.giga};
`

const installmentWrapperStyles = () => css`
  label: product__installment-wrapper;
  height: 20px;
  flex-direction: 'row';
`

export const PriceWrapper = styled('div')(priceWrapperstyles, priceWrapperMobilestyles)
export const PriceInfo = styled('div')(priceInfostyles, priceInfoMobilestyles)
export const UnitPrice = styled('span')(unitPricestyles)
export const TotalPrice = styled('span')(totalPricestyles)
export const PriceTag = styled('div')(priceTagStyles, priceTagMobileStyles)
export const ChangeItemTypeDesktopWrapper = styled('span')(changeItemTypeDesktopWrapperStyles)
export const ChangeItemTypeMobileWrapper = styled('span')(changeItemTypeMobileWrapperStyles)
export const InstallmentWrapper = styled('div')(installmentWrapperStyles)
