import { Fragment, useEffect, useMemo, useState } from 'react'
import type { FunctionComponent } from 'react'
import {
  Root,
  TopBar,
  Container,
  Burger,
  Logo,
  Searchbar,
  Actions,
  Navigation,
  Skeleton
} from '@leroy-merlin-br/backpack-header'

import emitter from 'scripts/utils/emitter'
import { AlgoliaConfigProps } from 'scripts/react-components/entry-key/routes/routes'
import { useAnalyticsTags } from 'scripts/react-components/entry-key/hooks/useAnalyticsTags'

import type { UserResources } from 'api/userResources'

import { EmployeeNotification } from 'shared/components'
import { useLoyaltyProfile, useUserResources } from 'shared/hooks'
import {
  insightsConfig,
  searchClient,
  indexName,
  retailMediaIndexName
} from 'shared/contexts/AlgoliaInstantSearch/lib'

import { useCartDrawerContext } from '../Header/contexts/CartDrawerContext'
import { handleUserRecontext } from './utils/handleUserRecontext'
import useGetCategoriesTree from './hooks/useGetCategoriesTree'
import { locationRepository, navigationRepository } from './repositories'

type LocationContext = {
  region: string
  regionName: string
  locationSource: 'user' | 'cloudflare'
}

export interface NewHeaderProps {
  /**
   * Feature flip to black-friday
   */
  isBlackWeekend: boolean

  /**
   * Feature flip to enable/disable social provider
   */
  socialLoginProviders: Array<'google' | 'facebook' | 'apple'>

  /**
   *
   */
  routing: boolean

  /**
   * Render Fragment or InstantSearch Context
   */
  element: FunctionComponent<{ algolia: AlgoliaConfigProps }>

  /**
   *
   */
  algolia: AlgoliaConfigProps
}

function NewHeader (props: NewHeaderProps) {
  const {
    isBlackWeekend = false,
    socialLoginProviders,
    routing,
    element: Element,
    algolia
  } = props

  const analyticsTags = useAnalyticsTags()

  const { userResources, userControls } = useUserResources()
  const {
    loyaltyProfile,
    isLoading: isLoadingLoyalty,
    loyaltyStatus
  } = useLoyaltyProfile()
  const { toggleCartDrawer } = useCartDrawerContext()
  const { data: categories } = useGetCategoriesTree()
  const [cartItems, setCartItems] = useState<UserResources['cartItems']>(null)
  const [locationContext, setLocationContext] = useState<
    LocationContext | undefined
  >()

  const theme = isBlackWeekend ? 'black-weekend' : 'default'

  const user = useMemo(() => {
    return {
      email: userResources?.email ?? '',
      name: userResources?.name ?? '',
      picture: userResources?.picture ?? '',

      loyaltyPoints: loyaltyProfile?.points.actual ?? 0,

      isLegalUser: Boolean(userResources?.isLegalUser),
      isAuthenticated: Boolean(userControls?.isLoggedIn),
      isAssistedSale: Boolean(userControls?.isAssistedSale),
      isLoyaltyMember: loyaltyStatus?.isActive() || false,
      canJoinLoyalty: Boolean(userControls?.isLoyaltyElegible)
    }
  }, [userResources, userControls, loyaltyProfile, loyaltyStatus])

  useEffect(() => {
    setCartItems(userResources?.cartItems || null)

    if (userResources) {
      setLocationContext({
        region: userResources.region,
        regionName: userResources.selectedRegionName,
        locationSource: userResources.locationSource || 'user'
      })
    }
  }, [userResources])

  useEffect(() => {
    emitter.on('cart:update', ({ count }) => setCartItems(count || null))
    emitter.on('cart:region-changed', data => {
      setLocationContext(data)
    })
  }, [])

  const shouldShowShell = !locationContext || !categories || isLoadingLoyalty

  if (shouldShowShell) {
    return <Skeleton theme={theme} />
  }

  return (
    <Fragment>
      <Root
        user={user}
        locationContext={locationContext}
        cartItems={cartItems ? Number(cartItems) : 0}
        theme={theme}
        routing={routing}
      >
        <TopBar
          handleLocationRecontext={handleUserRecontext}
          locationRepository={locationRepository}
        />
        <Container>
          <Burger />
          <Logo />
          <Element algolia={algolia}>
            <Searchbar
              algolia={{
                insightsClient: insightsConfig.insightsClient,
                searchClient,
                indexName,
                retailMediaIndexName,
                queries: [{ params: { analyticsTags }, indexName }]
              }}
            />
          </Element>
          <Actions
            onClickCart={toggleCartDrawer}
            providers={{
              disable: socialLoginProviders
            }}
          />
        </Container>

        <Navigation
          data={categories.data.results}
          navigationRepository={navigationRepository}
        />
      </Root>

      <EmployeeNotification />
    </Fragment>
  )
}

export default NewHeader
