import { pushDataObject } from 'scripts/utils/data-layer'

type OnFilterDataLayerProps = {
  searchParam: string
  filterType: { index: number; value: string }
  filterElement: { index: number; value: string | number | number[] }
}

export type OnSorterDataLayerProps = {
  isParamAlreadySet: boolean
  sorterType: string
}

export const GA_TRACK = document
  .querySelector('meta[name="ga-track"]')
  ?.getAttribute('content')

export const TYPE = String(
  document.querySelector('[data-type]')?.getAttribute('data-type')
)

export const PAGE_TYPE = String(
  document.querySelector('[data-type]')?.getAttribute('data-page-type')
)

export const onFilterDataLayer = ({
  filterType,
  filterElement,
  searchParam
}: OnFilterDataLayerProps) => {
  const url = window.location.href

  const hasFilterType =
    filterType && typeof filterType.index === 'number' && filterType.value
  const hasFilterElement =
    filterElement &&
    typeof filterElement.index === 'number' &&
    filterElement.value

  const data = {
    event: 'clickFilter-Facet',
    filter: 'Normal',
    filterAction: !url.includes(searchParam),
    filterType: hasFilterType && `${filterType.index + 1}:${filterType.value}`,
    filterElement:
      hasFilterElement && `${filterElement.index + 1}:${filterElement.value}`,
    'ga-trackAtualizado': GA_TRACK,
    filterPagetype: PAGE_TYPE
  }

  pushDataObject(data)
}

export const onSorterDataLayer = ({
  sorterType,
  isParamAlreadySet
}: OnSorterDataLayerProps) => {
  const data = {
    event: 'refineList',
    sorterAction: !isParamAlreadySet,
    tipoOrdenacao: sorterType,
    'ga-trackAtualizado': GA_TRACK
  }

  pushDataObject(data)
}
