import { addToCartEvent as algoliaAddToCartEvent } from 'scripts/react-components/shared/analytics/algolia.tracker'

export const formatPriceToDataLayer = (price, multiplier = null) => {
  const integers = price?.integers.toString().replace('.', '')

  if (!integers || !price?.decimals) {
    return null
  }

  let formattedPrice = `${integers}.${price?.decimals}`

  if (multiplier) {
    const multiplied =
      Number.parseFloat(formattedPrice, 2) * Number.parseFloat(multiplier)

    formattedPrice = multiplied.toFixed(2)
  }

  return formattedPrice
}

/**
 * @param  {products} array An array with products items
 * @param  {list} string A string with carousel name
 * @return {object} Data Layer products object
 */

export const setProductImpressionItems = (products = [], list = null) =>
  products.map((product, index) => ({
    id: product.id,
    brand: product.brand,
    category: product.categoryTree,
    name: product.name,
    list: list,
    price: formatPriceToDataLayer(product.price?.to),
    position: index + 1
  }))

/**
 * @param  {product} object An object with products details
 * @return {object} Data Layer products object
 */

export const setProductView = ({
  productName,
  productId,
  productBrand,
  toPriceIntegers,
  toPriceDecimals,
  category,
  shopName = null
}) => {
  const price = {
    integers: toPriceIntegers,
    decimals: toPriceDecimals
  }

  return {
    ecommerce: {
      detail: {
        products: [
          {
            name: productName,
            id: productId,
            price: formatPriceToDataLayer(price),
            brand: productBrand,
            category: category,
            dimension15: shopName
          }
        ]
      }
    }
  }
}

/**
 * @param {products} any An object or array with products details
 * @param {auxiliaryProperties} object An object with auxiliary details
 */

export const addToCartDataLayer = async (
  products,
  auxiliaryProperties = {}
) => {
  const { regionId = '', sourceName = '' } = auxiliaryProperties

  const isMultipleProductView = ['kazaplan', 'wishlist'].includes(sourceName)

  const productList = isMultipleProductView
    ? products.map(shapeDatalayerProduct)
    : [shapeDatalayerProduct(products)]

  const dataObject = {
    event: 'addToCart',
    dimension14: regionId,
    ecommerce: {
      currencyCode: 'BRL',
      add: {
        products: [...productList]
      }
    },
    source: sourceName,
    addtocart_method: sourceName
  }

  algoliaAddToCartEvent()

  pushDataObject(dataObject)
}

export const checkShippingType = (productList = []) => {
  const itsAllDelivery = productList.every(
    product => product.dimension8 === 'delivery'
  )
  const itsAllPickupInStore = productList.every(
    product => product.dimension8 === 'pickup-in-store'
  )

  if (itsAllDelivery) {
    return 'delivery'
  }

  if (itsAllPickupInStore) {
    return 'pickup-in-store'
  }

  return 'hybrid'
}

export const errorOnCartPage = (errorActionType, errorLabel) => {
  const errorData = {
    event: 'erros',
    'event-category': 'erros_carrinho',
    'event-action': errorActionType,
    'event-label': errorLabel
  }

  pushDataObject(errorData)
}

export const zipCodeRecontextualizing = productRetrievalType => {
  const recontextData = {
    event: 'erros',
    'event-category': 'recontextualizacao_carrinho',
    'event-action': 'recontextualizacao',
    'event-label': productRetrievalType
  }

  pushDataObject(recontextData)
}

/**
 * @param {product} object An object with products details
 * @returns {object} restructured product details
 */

const shapeDatalayerProduct = ({
  productName,
  productId,
  price,
  productBrand,
  category,
  dimension8,
  dimension9,
  dimension15,
  quantity = 1
}) => ({
  name: productName,
  id: productId,
  price: parseFloat(price).toFixed(2),
  brand: productBrand,
  category: category,
  quantity,
  dimension8,
  dimension9: dimension8 === 'pickup-in-store' ? dimension9 : '',
  dimension15: dimension8 === 'delivery' ? dimension15 : ''
})

/**
 * @param  {product} object An object with products details
 * @return {object} Data Layer products object
 */

export const cartCepToDataLayer = products => ({
  event: 'cep-carrinho',
  products
})

/**
 * @param  {user} object An object with user login information
 */

export const dispatchLoginToDataLayer = ({ user }) => ({
  event: 'login',
  userId: user?.id,
  dimension24: user?.favoriteStore?.slug
})

/**
 * @param  {productObj} object An object with product clicked information
 */

export const onProductClick = (productObj, { list, position }) => {
  const { categoryTree, category } = productObj

  const dataObject = {
    event: 'productClick',
    ecommerce: {
      click: {
        actionField: { list: list },
        products: [
          {
            name: productObj.name,
            id: productObj.id,
            price: formatPriceToDataLayer(productObj?.price?.to),
            brand: productObj.brand,
            ...(categoryTree
              ? { category: productObj.categoryTree }
              : category),
            position: position
          }
        ]
      }
    }
  }

  pushDataObject(dataObject)
}

/**
 * @param  {menuItem} object An object with clicked menu's item information
 */

export const onMenuClick = menuItem => {
  const { rootElement, parent, item, isMobile } = menuItem

  const menuTreeArray = [rootElement, parent, item.name]
  const filteredMenuTreeArray = menuTreeArray.filter(Boolean)

  const menuName = filteredMenuTreeArray.join(' - ')

  const element = isMobile ? 'data-mobile-menu' : 'data-desktop-menu'

  const dataObject = {
    event: 'menuInteraction',
    element,
    menuName,
    menuId: item.id,
    originMenuName: rootElement || item.name
  }

  pushDataObject(dataObject)
}

/**
 * @param  {carouselItem} object An object with clicked carousel's item information
 */

export const onCarouselItemClick = carouselItem => {
  const { name, carouselTitle, contentName, id } = carouselItem

  const dataObject = {
    event: 'carouselInteraction',
    element: carouselTitle,
    iconsCarouselName: `${contentName} - ${name}`,
    departmentId: id
  }

  pushDataObject(dataObject)
}

/**
 * @param  {crossProduct} object An object with clicked cross' item information
 */

export const onCrossItemClick = crossProduct => {
  const {
    crossTitle,
    productName,
    productId,
    page,
    crossIndex = 0,
    source,
    productIndex,
    productsIds = [],
    originProductId = null
  } = crossProduct

  const normalizeIndex = index => index + 1

  const dataObject = {
    event: 'crossSellingClick',
    crossSellingList: crossTitle,
    crossSellingProduct: productName,
    crossSellingId: productId,
    crossOriginProductId: originProductId,
    crossSellingPage: page,
    crossSource: source,
    crossPosition: normalizeIndex(crossIndex),
    crossProductList: productsIds.join(','),
    crossSellingProductPosition: normalizeIndex(productIndex)
  }

  pushDataObject(dataObject)
}

/**
 * @param  {regionName} string A string with region name
 * @return {object} Data Layer products object
 */

export const showLocationPopper = ({ regionName }) => {
  const dataLayerObject = {
    event: 'localizacao-automatica',
    dimension60: regionName
  }

  pushDataObject(dataLayerObject)
}

/**
 * @param  {regionName} string A string with region name
 * @return {object} Data Layer products object
 */

export const confirmLocationAction = ({ regionName }) => {
  const dataLayerObject = {
    event: 'localizacao-automatica-continuar',
    dimension60: regionName
  }

  pushDataObject(dataLayerObject)
}

/**
 * @param  {regionName} string A string with region name
 * @return {object} Data Layer products object
 */

export const changeLocationAction = ({ regionName }) => {
  const dataLayerObject = {
    event: 'localizacao-automatica-alterar',
    dimension60: regionName
  }

  pushDataObject(dataLayerObject)
}

/**
 * @param  {action} string A string with the name of the action
 * @param  {regionName} string A string with region name
 * @param  {zipCode} string A string with zip code
 * @return {object} Data Layer products object
 */

export const locationChangeSelectionAction = ({
  action = '',
  regionName = '',
  zipCode = ''
}) => {
  const DIMENSION_DICTIONARY = {
    'auto-location': { dimension60: 'Selecionou localização automática' },
    'selected-region': { dimension61: regionName },
    'zip-code': { dimension62: zipCode }
  }

  const dataLayerObject = {
    event: 'localizacao-automatica-alterada',
    ...DIMENSION_DICTIONARY[action]
  }

  pushDataObject(dataLayerObject)
}

/**
 * @param  {videoPosition} string A String with the position of the video: Carrossel || Descricao
 */

export const videoClickedAction = ({ videoPosition = '' }) => {
  const dataLayerObject = {
    event: 'product-video',
    videoStart: 'Sim',
    videoPosition
  }

  pushDataObject(dataLayerObject)
}

/**
 * Data layer enviado ao clicar para dar zoom na imagem do produto no carrossel de mídias
 * @param {productId} number LM do produto
 */
export const midiasCarouselSuperZoomClick = productId => {
  const dataLayerObject = {
    event: 'click_super_zoom',
    eventCategory: 'midias_carousel',
    eventLabel: 'Fotos do produto',
    eventValue: `${productId}`
  }

  pushDataObject(dataLayerObject)
}

/**
 * Data layer enviado ao clicar para ir ao slide anterior no carrossel de mídias
 * @param {productId} number LM do produto
 */
export const midiasCarouselPrevClick = productId => {
  const dataLayerObject = {
    event: 'click_prev',
    eventCategory: 'midias_carousel',
    eventLabel: 'Slide anterior',
    eventValue: `${productId}`
  }

  pushDataObject(dataLayerObject)
}

/**
 * Data layer enviado ao clicar para ir ao slide seguinte no carrossel de mídias
 * @param {productId} number LM do produto
 */
export const midiasCarouselNextClick = productId => {
  const dataLayerObject = {
    event: 'click_next',
    eventCategory: 'midias_carousel',
    eventLabel: 'Slide seguinte',
    eventValue: `${productId}`
  }

  pushDataObject(dataLayerObject)
}

/**
 * Data layer enviado ao clicar no botão de wishlist no carrossel de mídias
 * @param {productId} number LM do produto
 */
export const midiasCarouselWishlistButtonClick = productId => {
  const dataLayerObject = {
    event: 'click_wishlist_button',
    eventCategory: 'midias_carousel',
    eventLabel: 'Lista de desejos',
    eventValue: `${productId}`
  }

  pushDataObject(dataLayerObject)
}

/**
 * Data layer enviado ao clicar para exibir a imagem 3D do produto no carrossel de mídias
 * @param {productId} number LM do produto
 */
export const midiasCarouselSketchfabStart = productId => {
  const dataLayerObject = {
    event: 'start_3d',
    eventCategory: 'midias_carousel',
    eventLabel: 'Imagem 3D do produto',
    eventValue: `${productId}`
  }

  pushDataObject(dataLayerObject)
}

/**
 * Data layer enviado ao clicar no carrossel de navegação para ir ao slide da imagem 3D do produto no carrossel de mídias
 * @param {productId} number LM do produto
 */
export const midiasCarouselSketchfabClick = productId => {
  const dataLayerObject = {
    event: 'click_3d',
    eventCategory: 'midias_carousel',
    eventLabel: 'Imagem 3D do produto',
    eventValue: `${productId}`
  }

  pushDataObject(dataLayerObject)
}

/**
 * Data layer enviado ao clicar no botão para ir ao slide da imagem 3D do produto no carrossel de mídias
 * @param {productId} number LM do produto
 */
export const midiasCarouselSketchfabButtonClick = productId => {
  const dataLayerObject = {
    event: 'click_3d_button',
    eventCategory: 'midias_carousel',
    eventLabel: 'Imagem 3D do produto',
    eventValue: `${productId}`
  }

  pushDataObject(dataLayerObject)
}

/**
 * Data layer enviado ao dar play no video do carrossel de mídias
 * @param {videoId} number ID do vídeo
 */
export const midiasCarouselVideoStart = videoId => {
  const dataLayerObject = {
    event: 'start_video',
    eventCategory: 'midias_carousel',
    eventLabel: 'Video do produto',
    eventValue: `${videoId}`,
    videoStart: 'Sim',
    videoPosition: 'Carrossel'
  }

  pushDataObject(dataLayerObject)
}

/**
 * Data layer enviado ao clicar no carrossel de navegação para ir ao slide de vídeo do produto no carrossel de mídias
 * @param {productId} number LM do produto
 */
export const midiasCarouselVideoClick = productId => {
  const dataLayerObject = {
    event: 'click_video',
    eventCategory: 'midias_carousel',
    eventLabel: 'Video do produto',
    eventValue: `${productId}`
  }

  pushDataObject(dataLayerObject)
}

/**
 * Data layer enviado ao clicar no botão para ir ao slide de vídeo do produto no carrossel de mídias
 * @param {productId} number LM do produto
 */
export const midiasCarouselVideoButtonClick = productId => {
  const dataLayerObject = {
    event: 'click_video_button',
    eventCategory: 'midias_carousel',
    eventLabel: 'Video do produto',
    eventValue: `${productId}`
  }

  pushDataObject(dataLayerObject)
}

export const access500Page = () => {
  const access500PageDataLayer = {
    event: 'error-pageview',
    virtualpage: '/erro-500'
  }

  pushDataObject(access500PageDataLayer)
}

export const goHomeFrom500Page = () => {
  const goHomeFrom500PageDataLayer = {
    event: 'interaction',
    'event-category': 'Erro 500',
    'event-action': 'Clicou Voltar para a Home',
    'event-label': 'Clicou Voltar para a Home'
  }

  pushDataObject(goHomeFrom500PageDataLayer)
}

/**
 * @param  {statusError} int A number that represents a status code error
 */

export const couponWithError = ({ statusError = 0 }) => {
  const errorResponse = {
    404: 'cupom-inválido',
    410: 'cupom-expirou',
    418: 'cupom-error-regras',
    409: 'cupom-error-conflito-descontos'
  }
  const eventLabel = errorResponse[statusError] ?? 'cupom-error-outros'
  const dataLayerObject = {
    event: 'erros',
    'event-category': 'erros_pagamento',
    'event-action': 'clique_aplicar_cupom',
    'event-label': eventLabel
  }
  pushDataObject(dataLayerObject)
}

export const signupFormPageRender = type => {
  const dataLayerObject = {
    event: 'virtual-pageview',
    virtualpage: `/cadastre_se/${type}`,
    sign_up_type: type
  }

  pushDataObject(dataLayerObject)
}

export const eventLogGtmProductPageRecontextualization = () => {
  const dataLayerObject = {
    event: 'erros',
    'event-category': 'recontextualizacao_pdp',
    'event-action': 'recontextualizacao',
    'event-label': 'undefined'
  }

  pushDataObject(dataLayerObject)
}

export const signUpSuccessButtonClick = () => {
  const dataLayerObject = {
    event: 'signup_interactions',
    'event-category': 'interacoes_cadastro',
    'event-action': 'clique_em_continuar',
    'event-label': 'pagina_de_sucesso_no_cadastro'
  }

  pushDataObject(dataLayerObject)
}

export const clickOnSignupPageLoginLink = () => {
  const dataLayerObject = {
    event: 'login_interactions',
    'event-category': 'interacoes_login',
    'event-action': 'clique_em_login',
    'event-label': 'pagina_de_cadastro'
  }

  pushDataObject(dataLayerObject)
}

export const loyaltyLandingPageHeroBannerButton = () => {
  const dataLayerObject = {
    event: 'login_interactions',
    'event-category': 'interacoes_login',
    'event-action': 'clique_em_login',
    'event-label': 'pagina_de_fidelidade'
  }

  pushDataObject(dataLayerObject)
}

export const pushDataLayerForErrorLogin = error => {
  const dataLayerObject = {
    event: 'erros',
    'event-category': 'erros_login',
    'event-action': 'clique_para_entrar',
    'event-label': error
  }

  pushDataObject(dataLayerObject)
}

export const accountPopoverSignupLink = () => {
  const dataLayerObject = {
    event: 'signup_interactions',
    'event-category': 'interacoes_cadastro',
    'event-action': 'clique_em_cadastrar',
    'event-label': 'modal_minha_conta'
  }

  pushDataObject(dataLayerObject)
}

export const wishlistLoginButtonClick = () => {
  const dataLayerObject = {
    event: 'login_interactions',
    'event-category': 'interacoes_login',
    'event-action': 'clique_em_login',
    'event-label': 'minhas_listas_de_desejo'
  }

  pushDataObject(dataLayerObject)
}

export const pushDataLayerSyncRegisterWithError = (provider, error) => {
  const dataLayerObject = {
    event: 'erros',
    'event-category': 'erros_sync_sociallogin',
    'event-action': 'clique_entrar',
    login_type: provider,
    'event-label': error
  }

  pushDataObject(dataLayerObject)
}

export const pushDataLayerSyncRegister = provider => {
  const dataLayerObject = {
    event: 'sign_up',
    method: 'signup-social',
    login_type: provider,
    sign_up_type: 'pf',
    optins: 'op7-ok',
    'event-category': 'singup',
    'event-action': 'clique_cadastrar',
    'event-label': 'sucesso'
  }

  pushDataObject(dataLayerObject)
}

export const socialLoginButtonClick = loginType => {
  const dataLayerObject = {
    event: 'virtual-pageview',
    virtualpage: `/login/${loginType}`
  }

  pushDataObject(dataLayerObject)
}

export const recoveryFiscalIdSendButtonClick = () => {
  const dataLayerObject = {
    event: 'user_forgot_interactions',
    'event-category': 'recuperacao_de_senha',
    'event-action': 'click_enviar',
    'event-label': 'enviar'
  }

  pushDataObject(dataLayerObject)
}

export const recoveryLinkToSignupPageClick = () => {
  const dataLayerObject = {
    event: 'user_forgot_interactions',
    'event-category': 'recuperacao_de_senha',
    'event-action': 'clicou_novo_cadastro',
    'event-label': 'novo_cadastro'
  }

  pushDataObject(dataLayerObject)
}

export const recoveryPasswordSaveButtonClick = () => {
  const dataLayerObject = {
    event: 'user_forgot_interactions',
    'event-category': 'recuperacao_de_senha',
    'event-action': 'click_salvar',
    'event-label': 'salvar_nova_senha'
  }

  pushDataObject(dataLayerObject)
}

export const recoveryOptionToReceiveCodeClick = option => {
  const optionsData = {
    email: {
      'event-action': 'click_option_email',
      'event-label': 'email'
    },
    phone: {
      'event-action': 'click_sms',
      'event-label': 'sms'
    }
  }

  const dataLayerObject = {
    event: 'user_forgot_interactions',
    'event-category': 'recuperacao_de_senha',
    ...optionsData[option]
  }

  pushDataObject(dataLayerObject)
}

export const recoveryBackToLoginLinkClick = () => {
  const dataLayerObject = {
    event: 'user_forgot_interactions',
    'event-category': 'recuperacao_de_senha',
    'event-action': 'click_voltar_login',
    'event-label': 'voltar_login'
  }

  pushDataObject(dataLayerObject)
}

export const recoveryChangeEmailLinkClick = () => {
  const dataLayerObject = {
    event: 'user_forgot_interactions',
    'event-category': 'recuperacao_de_senha',
    'event-action': 'clique_alterar_email_telefone',
    'event-label': 'email_telefone'
  }

  pushDataObject(dataLayerObject)
}

export const recoveryResenCodeClick = () => {
  const dataLayerObject = {
    event: 'user_forgot_interactions',
    'event-category': 'recuperacao_de_senha',
    'event-action': 'click_reenviar_token',
    'event-label': 'reenviar_token'
  }

  pushDataObject(dataLayerObject)
}

/**
 * @param  {dataObject} object An object with dataLayer information
 * @param  {needReset}  boolean A parameter that will reset data layer if necessary
 */

export const pushDataObject = (dataObject, needReset = false) => {
  window.dataLayer = window.dataLayer || []

  if (needReset) {
    window.dataLayer.push(() => {
      this.reset()
    })
  }

  window.dataLayer.push(dataObject)
}

export const signupSuccessPageRender = type => {
  const dataLayerObject = {
    event: 'virtual-pageview',
    virtualpage: `/cadastre_se/${type}/sucesso`,
    sign_up_type: type
  }

  pushDataObject(dataLayerObject)
}

export const signInLoginClick = () => {
  const dataLayerObject = {
    event: 'login_interactions',
    'event-category': 'interacoes_login',
    'event-action': 'clique_em_login',
    'event-label': 'modal_minha_conta'
  }

  pushDataObject(dataLayerObject)
}
