import { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

import { newsArticleSchema } from 'shared/constants/contentSchema'
import { ShapeThumb } from 'shared/constants/prop-types'

import { ThumbInfo, ThumbPicture } from './components'
import * as S from './styled'

const ContentsThumb = ({ item }) => (
  <Fragment>
    <Helmet>
      <script type="application/ld+json">
        {`${JSON.stringify(newsArticleSchema(item))}`}
      </script>
    </Helmet>

    <S.ThumbWrapper>
      <ThumbPicture data={item} />
      <ThumbInfo data={item} />
    </S.ThumbWrapper>
  </Fragment>
)

ContentsThumb.propTypes = {
  item: PropTypes.shape({ ...ShapeThumb }).isRequired
}

export default ContentsThumb
