import { PaymentMethod } from '../../type'
import { SummaryType } from './type'
import Visual from './Visual'

const paymentTypeText = (paymentMethod: PaymentMethod) => {
  switch (paymentMethod.type) {
    case 'credit_card':
    case 'token':
      return `${paymentMethod.amount_installment} x no Cartão de crédito`
    case 'bank_slip':
      return '1 x no Boleto bancário'
    case 'pix':
      return '1 x no Pix'
  }
}

const Summary = ({
  shippings,
  summaryData,
  paymentMethod,
  hasServices,
  hasStandaloneService,
  isMobile
}: SummaryType) => {
  const forecastTexts = shippings.map(shipping => {
    switch (shipping.type) {
      case 'delivery':
        return { title: 'Previsão de entrega', text: `${shipping.time}` }
      case 'pickUpInStore':
        return {
          title: 'Previsão de retirada',
          text: `em até ${shipping.time} horas`
        }
    }
  })

  return (
    <Visual
      hasServices={hasServices}
      hasStandaloneService={hasStandaloneService}
      summaryData={summaryData}
      forecastTexts={forecastTexts}
      paymentMethodText={paymentTypeText(paymentMethod)}
      isMobile={isMobile}
    />
  )
}

export default Summary
