import $ from 'jquery'

import BaseComponent from 'scripts/components/base-component'

class Region extends BaseComponent {
  static componentName = 'region'

  static DOMEvents = {
    change: 'onRegionChange'
  }

  onRegionChange (e) {
    window.location.href = e.target.value
  }
}

export default ($el) => new Region($el)

export { Region as Component }
