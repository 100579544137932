import { FunctionComponent } from 'react'
import { Stack } from '@leroy-merlin-br/backyard-react'

import { useVariants } from './hooks'
import * as S from './styled'
import { Props } from './types'
import { Variant } from './containers'

const Variants: FunctionComponent<Props> = props => {
  const { productId } = props

  const variants = useVariants({
    productId
  })

  const hasVariants = variants.length > 0

  if (!hasVariants) {
    return null
  }

  return (
    <S.Container>
      <Stack space="mega">
        {variants.map(variant => (
          <Variant
            variant={variant}
            productId={productId}
            key={variant.characteristicId}
          />
        ))}
      </Stack>
    </S.Container>
  )
}

export default Variants
