import styled from '@emotion/styled'
import { css } from '@emotion/react'

const wrapperStyles = () => css`
  label: wrapper;

  position: relative;
  height: calc(100vh - 66px);
  min-height: 600px;

  & > div {
    position: absolute !important;
  }
`

export const Wrapper = styled('div')(wrapperStyles)
