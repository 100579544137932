import Cookies from 'js-cookie'

import instance from './axios'

export function ConfigureInterceptor () {
  instance.interceptors.request.use(
    config => {
      const TOKEN = Cookies.get('api_token')

      if (TOKEN) {
        config.headers.Authorization = `Bearer ${TOKEN}`
      }

      return config
    },
    error => {
      Promise.reject(error)
    }
  )

  instance.interceptors.response.use(
    response => response,
    error => Promise.reject(error)
  )
}
