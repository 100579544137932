import { FunctionComponent } from 'react'
import { Dialog, IconButton } from '@backyard-ui/core'
import { Swiper, SwiperSlide } from 'swiper/react'
import { ChevronLeft, ChevronRight } from '@backyard-ui/icons'

import { YoutubeVideo } from './components'
import { Props } from './types'
import styles from './Gallery.styles'

const Gallery: FunctionComponent<Props> = ({
  isOpen,
  onOpenChange,
  initialSlide,
  title,
  items
}) => (
  <Dialog.Root
    isCentered
    isOpen={isOpen}
    onOpenChange={onOpenChange}
    size="2xl"
  >
    <Dialog.Content>
      <Dialog.CloseButton data-testid="close-button" />
      <Dialog.Title>{title}</Dialog.Title>
      <Dialog.Description>
        <Swiper
          pagination
          className={styles().swiper()}
          grabCursor
          threshold={2}
          navigation={{
            prevEl: '[data-navigation-mode="prev"]',
            nextEl: '[data-navigation-mode="next"]'
          }}
          initialSlide={initialSlide}
        >
          {items.map(({ thumb, alt, type, youtubeId }, i) => {
            if (type === 'video' && youtubeId) {
              return (
                <SwiperSlide
                  className={styles().swiperSlide()}
                  key={`video-slide-${i}`}
                >
                  {({ isActive }) => (
                    <YoutubeVideo
                      isActive={isActive}
                      youtubeId={youtubeId}
                      isInitialSlide={initialSlide === i}
                    />
                  )}
                </SwiperSlide>
              )
            }

            return (
              <SwiperSlide
                className={styles().swiperSlide()}
                key={`photo-slide-${i}`}
              >
                <img src={thumb} alt={alt} />
              </SwiperSlide>
            )
          })}
          <div className={styles().prevButtonWrapper()}>
            <IconButton
              icon={<ChevronLeft />}
              size="sm"
              variant="subtle"
              appearance="primary"
              data-navigation-mode="prev"
            />
          </div>
          <div className={styles().nextButtonWrapper()}>
            <IconButton
              icon={<ChevronRight />}
              size="sm"
              appearance="primary"
              variant="subtle"
              data-navigation-mode="next"
            />
          </div>
        </Swiper>
      </Dialog.Description>
    </Dialog.Content>
  </Dialog.Root>
)

export default Gallery
