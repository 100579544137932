import { createContext } from '@backyard-ui/utils'

import type { RouterProps } from './routes'

const { Provider: ServerProvider, useContext: useServerContext } =
  createContext<RouterProps>({
    name: '@backyard-ui/core/ServerContext'
  })

export { ServerProvider, useServerContext }
