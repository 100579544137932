import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Stack, Icon, Text } from '@leroy-merlin-br/backyard-react'
import {
  AlertTriangle,
  CheckmarkCircle,
  TimesCircle,
  MinusCircleOutline,
  PlusCircleOutline
} from '@backyard-ui/icons'

import { COLOR_MAP } from './constants'
import * as S from './styled'

const icons = {
  warning: AlertTriangle,
  success: CheckmarkCircle,
  critical: TimesCircle
}

const Counter = React.forwardRef((props, ref) => {
  const {
    size = 'mega',
    state = 'default',
    label = '',
    hint = '',
    quantity,
    isDisabled = false,
    isDecrementDisabled = false,
    isIncrementDisabled = false,
    onIncrement,
    onDecrement,
    onChange
  } = props

  const [value, SetValue] = useState(quantity)

  useEffect(() => {
    SetValue(Number(quantity))
  }, [quantity])

  return (
    <Stack space="bit">
      {label && (
        <Text
          data-testid="label"
          size="mega"
          color={isDisabled ? 'n600' : 'n900'}
          noMargin
          bold
        >
          {label}
        </Text>
      )}

      <S.Wrapper size={size} state={state} isDisabled={isDisabled}>
        <S.Button
          data-testid="circle-minus-button"
          disabled={isDisabled || isDecrementDisabled}
          onClick={onDecrement}
        >
          <S.IconWrapper isDisabled={isDisabled || isDecrementDisabled}>
            <Icon as={MinusCircleOutline} size="giga" />
          </S.IconWrapper>
        </S.Button>

        <S.Input
          ref={ref}
          data-testid="input"
          type="number"
          step="any"
          placeholder="0"
          value={value.toString()}
          disabled={isDisabled}
          onChange={({ target: { value } }) => onChange(Number(value))}
          onClick={({ target }) => target.select()}
        />

        <S.Button
          data-testid="circle-plus-button"
          disabled={isDisabled || isIncrementDisabled}
          onClick={onIncrement}
        >
          <S.IconWrapper isDisabled={isDisabled || isIncrementDisabled}>
            <Icon as={PlusCircleOutline} size="giga" />
          </S.IconWrapper>
        </S.Button>
      </S.Wrapper>

      {hint && (
        <S.HintWrapper>
          {state !== 'default' && (
            <S.HintIconWrapper state={state}>
              <Icon as={icons[state]} size="mega" />
            </S.HintIconWrapper>
          )}
          <Text
            data-testid="hint"
            as="span"
            size="kilo"
            color={isDisabled ? 'n300' : COLOR_MAP[state]}
            noMargin
          >
            {hint}
          </Text>
        </S.HintWrapper>
      )}
    </Stack>
  )
})

export default Counter

Counter.propTypes = {
  size: PropTypes.oneOf(['mega', 'kilo', 'giga']),
  state: PropTypes.oneOf(['default', 'warning', 'success', 'critical']),
  label: PropTypes.string,
  hint: PropTypes.string,
  quantity: PropTypes.number.isRequired,
  isDisabled: PropTypes.bool,
  isDecrementDisabled: PropTypes.bool,
  isIncrementDisabled: PropTypes.bool,
  onIncrement: PropTypes.func.isRequired,
  onDecrement: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
}
