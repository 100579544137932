import PropTypes from 'prop-types'
import { Text } from '@leroy-merlin-br/backyard-react'

import { formatDate } from 'utils/date'

const ProjectDate = ({ title, data }) => (
  <Text>
    {title}
    <br />
    <strong>{formatDate(data, 'DD [de] MMMM [de] YYYY')}</strong>
  </Text>
)

ProjectDate.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.string.isRequired
}

export default ProjectDate
