import DOMPurify from 'dompurify'

import { Message as MessageType } from '../../types'
import Suggestions from '../Suggestions'
import * as S from './styled'

interface MessageProps extends MessageType {
  message: string
}

const Message = ({ message, options, type, ...rest }: MessageProps) => {
  return (
    <S.MessageWrapper type={type} {...rest}>
      <S.BaloonsContainer>
        <S.Baloon
          type={type}
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(message) }}
        />
        {options && <Suggestions options={options} />}
      </S.BaloonsContainer>
    </S.MessageWrapper>
  )
}

export default Message
