import Routes from './Routes'
import { HomeProps } from './pages/Home/Home'

const App = (props: HomeProps) => {
  return (
    <Routes {...props} />
  )
}

export default App
