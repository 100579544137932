import { useState, useEffect } from 'react'

import emitter from 'scripts/utils/emitter'

import { Drawer } from 'lmcv/components'
import * as service from 'lmcv/services/document'

import { Form } from './components/Form'

const DocumentPro = ({
  open = false,
  classOccupation = null,
  onClose = null,
  onFinish = null,
  onCancel = null,
  onCatch = null
}) => {
  const [occupation, setOccupation] = useState(classOccupation)
  const [visible, setVisibility] = useState(open)

  const [actions, setActions] = useState({
    onClose,
    onFinish,
    onCancel
  })

  const onSubmit = async data => {
    try {
      await service.sendDocument(data)

      actions?.onFinish && actions.onFinish()
    } catch (error) {
      onCatch && onCatch()
    }
  }

  useEffect(() => {
    emitter.on('loyalty-pro:open', events => {
      const { occupation, onClose, onFinish, onCancel } = events

      setOccupation(occupation)

      setActions({
        onClose,
        onFinish,
        onCancel
      })

      setVisibility(true)
    })

    emitter.on('loyalty-pro:close', () => setVisibility(false))
  }, [])

  useEffect(() => {
    setVisibility(open)
  }, [open])

  useEffect(() => {
    setOccupation(classOccupation)
  }, [classOccupation])

  return (
    <Drawer
      dataCy="document-pro-drawer"
      visible={visible}
      title="Envio de documento de classe"
      onClose={actions.onClose}
    >
      <Form
        onSubmit={onSubmit}
        onCancel={actions.onCancel}
        occupation={occupation}
      />
    </Drawer>
  )
}

export default DocumentPro
