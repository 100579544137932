import reactComponentLoader from 'scripts/react-component-loader'

import Cart from 'frontendCheckout/pages/cart'
import { CouponsAndVoucher } from 'frontendCheckout/pages/payment/components/CouponsAndVoucher/CouponsAndVoucher'
import { ShippingService } from 'frontendCheckout/pages/shipping/components/ShippingService/ShippingService'
import { ShippingProduct } from 'frontendCheckout/pages/shipping/components/ShippingProduct/ShippingProduct'
import { ShippingDisclaimer } from 'frontendCheckout/pages/shipping/components/ShippingDisclaimer/ShippingDisclaimer'

reactComponentLoader(Cart, '[data-checkout-cart]')
reactComponentLoader(CouponsAndVoucher, '[data-coupon-and-voucher]')
reactComponentLoader(ShippingService, '[data-shipping-service]')
reactComponentLoader(ShippingProduct, '[data-shipping-product]')
reactComponentLoader(ShippingDisclaimer, '[data-shipping-disclaimer]')
