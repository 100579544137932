import { useState } from 'react'

export function useStep (initialStep: number) {
  const [step, setStep] = useState(initialStep)

  const nextStep = () => {
    setStep(prevStep => prevStep + 1)
  }

  const prevStep = () => {
    setStep(prevStep => prevStep - 1)
  }

  return { prevStep, nextStep, step, setStep }
}
