import { Store } from 'newCheckout/types/PickUpInStore'

import * as S from './Style'
import { StoreCard } from './Visual'

type OnChangeParameters = {
  storeId?: string
}

const SelectStores = ({
  stores,
  onChange,
  storeId
}: {
  stores: Store[]
  onChange: (params: OnChangeParameters) => void
  storeId?: string
}) => {
  return (
    <S.Container>
      {stores.map(store => (
        <StoreCard
          isChecked={!!storeId && storeId === store.storeId}
          onClick={async () => {
            onChange({ storeId: store.storeId })
          }}
          radioName="select-store"
          value={store.storeId}
          {...store}
          key={store.storeId}
        />
      ))}
    </S.Container>
  )
}

export default SelectStores
