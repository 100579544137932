import PropTypes from 'prop-types'
import { Text } from '@leroy-merlin-br/backyard-react'

import { Installments } from 'shared/components/Installments'

import formatPriceToBRL from 'utils/formatPriceToBRL'

import * as S from './styled'

const PriceTag = ({
  price,
  installments,
  brand
}) => {
  let {
    to_price: toPrice,
    from_price: fromPrice,
    suggested_price: suggestedPrice,
    payment_methods_discount: payMethods
  } = price
  if (suggestedPrice) {
    fromPrice = suggestedPrice
  }
  const isBranded = Boolean(brand)

  return (
    <div>
      {fromPrice && (
        <S.Container>
          <Text
            as="strong"
            size="kilo"
            color="n400"
            noMargin
            decoration="line-through"
            data-cy="associated-price-from-price"
          >
            1 un {formatPriceToBRL(fromPrice)}
          </Text>
        </S.Container>
      )}
      <S.Container>
        <Text
          as="strong"
          size="kilo"
          noMargin
          isBold
          data-cy="associated-price-to-price"
        >
          1 un {formatPriceToBRL(toPrice)} {payMethods}
        </Text>
      </S.Container>
      {
        installments &&
        <S.Container>
          <Installments
            isBranded={isBranded}
            installments={installments}
          />
        </S.Container>
      }
    </div>
  )
}

PriceTag.propTypes = {
  price: PropTypes.shape({
    to_price: PropTypes.number,
    from_price: PropTypes.number,
    suggested_price: PropTypes.number
  }),
  installments: PropTypes.object,
  brand: PropTypes.string
}

export default PriceTag
