import $ from 'jquery'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

import emitter from 'scripts/utils/emitter'
import BaseComponent from 'scripts/components/base-component'

const DEFAULTS = {
  target: 'data-overlay-target',
  closeSelector: '[data-overlay-close]'
}

class OverlaySpace extends BaseComponent {
  static componentName = 'overlay-space'

  static DOMEvents = {
    click: 'show'
  }

  static emitterEvents = {
    'overlay-space:hide': 'hide'
  }

  constructor (element, options = {}) {
    super(element)
    this.options = $.extend({}, DEFAULTS, options)
    this.overlayRef = null
  }

  init () {
    const id = this.$element.data('overlay-space-id')
    this.$target = $(`[${this.options.target}=${id}]`)
  }

  show (event) {
    event.preventDefault()
    emitter.emit('navigation-trigger:hide')

    disableBodyScroll(this.$target[0])

    this.$target.addClass('visible')

    this._bindHideEvents()
  }

  hide () {
    enableBodyScroll(this.$target[0])
    this.$target.removeClass('visible')
  }

  _bindHideEvents () {
    $(window).one('keyup', this._onKeyUp.bind(this))
    $(this.options.closeSelector).one('click', this.hide.bind(this))
  }

  _onKeyUp (event) {
    if (event.keyCode === 27) {
      this.hide(event)
    }
  }
}

export default ($el) => new OverlaySpace($el).init()

export { OverlaySpace as Component }
