import { algoliaStringFormatter } from '../../../utils/algoliaStringFormatter'

export const typeAttributesFilter = (
  key: string,
  type: Array<string>,
  urlToConcatenate: string,
  numberFilter: number,
  categoryFiltersLabel: { [key: string]: string }
): string => {
  const keyString = key.split('attributes.')

  let categoryAttributes = localStorage.getItem(
    '@algolia/categories-attributes'
  )

  if (categoryAttributes) {
    categoryAttributes = JSON.parse(categoryAttributes)
  }

  const typeFilter = algoliaStringFormatter(keyString[1])

  const isValidFilter = Object.keys(categoryFiltersLabel).includes(typeFilter)

  if (isValidFilter) {
    type.reverse().forEach((value, index) => {
      const formattedValue = value.includes(' ')
        ? value.replaceAll(/ /g, '_')
        : value

      let finalValue =
        formattedValue.charAt(0).toUpperCase() + formattedValue.slice(1)

      if (!urlToConcatenate.includes(finalValue)) {
        if (categoryAttributes?.prefix) {
          finalValue = `${categoryAttributes.prefix}_${finalValue}`
        }

        if (categoryAttributes?.sufix) {
          finalValue = `${finalValue}_${categoryAttributes.sufix}`
        }

        if (index === 0 && numberFilter === 0) {
          urlToConcatenate += '/' + typeFilter + '/' + finalValue
        } else if (numberFilter === 1) {
          urlToConcatenate += '?filter[' + typeFilter + ']=' + finalValue
        } else if (numberFilter > 1) {
          urlToConcatenate += '&filter[' + typeFilter + ']=' + finalValue
        }
      }
    })
  }

  return urlToConcatenate
}
