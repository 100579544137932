import { PropsWithChildren } from 'react'
import { tv } from 'tailwind-variants'

export interface ButtonProps extends React.HTMLAttributes<HTMLDivElement> {
  colorOnActive: 'white' | 'purple' | 'green'
  isActive: boolean
}

const button = tv({
  base: 'flex justify-center items-center w-full rounded-3xl font-semibold duration-700 cursor-pointer',
  variants: {
    color: {
      white: '',
      purple: 'bg-purple-100 text-[#6C0CAE]',
      green: 'bg-green-100 text-[#0A601B]'
    }
  }
})

export const Button = ({
  children,
  onClick,
  colorOnActive,
  isActive
}: PropsWithChildren<ButtonProps>) => {
  return (
    <div
      className={button({ color: isActive ? colorOnActive : 'white' })}
      onClick={onClick}
    >
      {children}
    </div>
  )
}
