import { Text } from '@leroy-merlin-br/backyard-react'

import { AlertMessage } from 'shared/components/AlertMessage'

const CancelledAfterDeliveryAddressNotFound = () => (
  <AlertMessage type="critical">
    Seu pedido foi{' '}
    <Text as="strong" size="kilo" isBold noMargin>
      cancelado
    </Text>
    , pois não encontramos o endereço que você informou.
  </AlertMessage>
)

export default CancelledAfterDeliveryAddressNotFound
