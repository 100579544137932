import { Dialog } from '@leroy-merlin-br/backyard-react'

import { ModalContentError } from './components/ModalContentError'

const handleModal = setModal => {
  return setModal({
    children: ({ onClose }) => (
      <Dialog onClose={onClose} title=" ">
        <ModalContentError
          title="Um projeto de cada vez..."
          text="Você tem um projeto em andamento com seu profissional agora. Aguarde até que seu projeto seja concluído para enviar sua documentação."
          onClose={onClose}
        />
      </Dialog>
    )
  })
}

export default handleModal
