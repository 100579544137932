import { Container, Heading, Stack } from '@leroy-merlin-br/backyard-react'

import * as S from './styled'
import { FaqList } from '../../components/FaqList'

const Faq = () => (
  <S.Section>
    <Container>
      <Stack space="tera">
        <S.TitleWrapper>
          <Heading noMargin size="exa">
            Perguntas frequentes
          </Heading>
        </S.TitleWrapper>
        <div>
          <FaqList />
        </div>
      </Stack>
    </Container>
  </S.Section>
)

export default Faq
