import { Shell } from 'shared/components/Shell'

import * as S from './styled'

const ProductShell = () => (
  <S.Wrapper>
    {Array(8)
      .fill()
      .map((item, index) => (
        <Shell key={`${item}-${index}`} height={374} />
      ))}
  </S.Wrapper>
)

export default ProductShell
