import { css } from '@emotion/react'
import styled from '@emotion/styled'

const buttonStyles = ({ theme }) => css`
  label: button-wrapper;

  margin-top: ${theme.spacings.giga};
`

const documentWrapperStyles = ({ theme }) => css`
  label: document-wrapper;

  display: grid;
  grid-template-columns: 120px auto;
  grid-column-gap: ${theme.spacings.byte};

  ${theme.media.giga} {
    grid-column-gap: ${theme.spacings.kilo};
  }
`
export const ButtonWrapper = styled('div')(buttonStyles)
export const DocumentWrapper = styled('div')(documentWrapperStyles)
