import { css } from '@emotion/react'
import styled from '@emotion/styled'

const buttonSocialIconStyles = ({ theme }) => css`
  label: share-button__icon;

  align-items: center;
  border-radius: ${theme.borderRadius.circle};
  display: flex;
  font-size: ${theme.typography.headings.mega.fontSize} !important;
  height: ${theme.typography.headings.exa.fontSize};
  justify-content: center;
  width: ${theme.typography.headings.exa.fontSize};

  &.share-button-twitter {
    background: rgb(8, 160, 233);
    color: ${theme.colors.white};
  }

  &.share-button-facebook {
    background: rgb(59, 89, 152);
    color: ${theme.colors.white};
  }
`

export const ButtonSocialIcon = styled('i')(buttonSocialIconStyles)
