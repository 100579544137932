import { ArrowRight, Coupon as CouponIcon } from 'frontendCheckout/assets/icons'

type ButtonTextEmptyProps = { quantity: number }

export const ButtonTextEmpty = ({ quantity }: ButtonTextEmptyProps) => {
  return (
    <>
      <CouponIcon />
      <span>Cupons</span>
      <span className="ml-auto text-sm font-normal items-center flex gap-2 color-white">
        {quantity} {quantity > 1 ? 'disponíveis' : 'disponivel'}
        <ArrowRight />
      </span>
    </>
  )
}
