import { useCallback, useMemo } from 'react'
import { useSortBy } from 'react-instantsearch'
import { Card, Text, Select } from '@backyard-ui/core'

import { IS_CATEGORY } from 'scripts/react-components/entry-key/utils/constants'

import { useServerContext } from 'entry-key/routes/routes.context'
import { onSorterDataLayer } from 'entry-key/analytics/data-layer'

type Option = {
  value: string
  label: string
}

const PLACEHOLDER = 'Selecionar Ordem'

const COMMON_SORT = (indexName: string) => [
  { label: 'Mais relevantes', value: `${indexName}` },
  { label: 'Maior preço', value: `${indexName}_price_desc` },
  { label: 'Menor preço', value: `${indexName}_price_asc` }
]

const DEFAULT_SORT = (indexName: string) => [
  ...COMMON_SORT(indexName),
  { label: 'Mais vendidos', value: `${indexName}_most_sales` },
  { label: 'Novidades', value: `${indexName}_novelty` }
]

const sortByOptions = (indexName: string) => {
  return IS_CATEGORY ? COMMON_SORT(indexName) : DEFAULT_SORT(indexName)
}

const SortBy = () => {
  const { algolia } = useServerContext()

  const { options, refine, currentRefinement } = useSortBy({
    items: sortByOptions(algolia.indexName)
  })
  const findSelectedOption = useCallback(
    (searchValue: string) => options.find(opt => opt.value === searchValue),
    [options]
  )

  const selectedOption = useMemo(() => {
    if (!currentRefinement) {
      return undefined
    }

    return findSelectedOption(currentRefinement)
  }, [currentRefinement, findSelectedOption])

  const handleChange = (value: string) => {
    const option = value ? (findSelectedOption(value) as Option) : options[0]

    const url = new URL(window.location.href)
    onSorterDataLayer({
      isParamAlreadySet:
        String(url).includes('melhor-preco') ||
        String(url).includes('menor-preco') ||
        url.searchParams.has('sortBy'),
      sorterType: option.label
    })

    refine(value || algolia.indexName)
  }

  return (
    <Card.Root>
      <Card.Header>
        <Text weight="bold" asChild>
          <span>Ordenar por</span>
        </Text>
      </Card.Header>

      <Card.Body>
        <Select.Root
          onValueChange={handleChange}
          value={currentRefinement || algolia.indexName}
        >
          <Select.Trigger>
            <Select.Value placeholder={selectedOption?.label ?? PLACEHOLDER} />
          </Select.Trigger>

          <Select.Content>
            {options.map(({ value, label }) => {
              return (
                <Select.Item key={value} value={value} textValue={value}>
                  {label}
                </Select.Item>
              )
            })}
          </Select.Content>
        </Select.Root>
      </Card.Body>
    </Card.Root>
  )
}

export default SortBy
