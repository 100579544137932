import { Component } from 'react'
import { isEqual } from 'lodash'

import Confirm from 'garden/src/js/components/confirm'

import emitter from 'scripts/utils/emitter'
import * as wishlistsRepository from 'scripts/api/wishlists'
import WishlistsService from 'scripts/api/wishlists/wishlists'

import { userResourcesApi } from 'api'

import AddToWishlist from 'site/Wishlist/components/drawer/lists/AddToWishlist'

import { tracker } from 'utils/app'
import { hideSpinner, showSpinner } from 'utils/Spinner'

import { WishlistProvider } from './context'

const CONTEXT_ASSISTED_SALE = 'assisted-sale'

class WishlistProviderMethods extends Component {
  constructor (props) {
    super(props)

    this.state = {
      type: null,
      id: null,
      product: null,
      isLogged: false,
      isAssistedSale: false,
      savedItems: [],
      showDrawer: false,
      wishlists: []
    }
  }

  async componentDidMount () {
    await this.setIsLogged()
  }

  componentDidUpdate (prevProps, prevState) {
    const updatedSavedItems = !isEqual(
      this.state.savedItems.length,
      prevState.savedItems.length
    )

    if (updatedSavedItems) {
      WishlistsService.setStorage(this.state.savedItems)
    }
  }

  isLoggedIn (data) {
    return data && data.email && data.email.length > 0
  }

  isAssistedSale (data) {
    return data?.context === CONTEXT_ASSISTED_SALE
  }

  setIsLogged = async () => {
    const userResourcesApiRequest = await userResourcesApi.storedGetUserResources()

    this.setState(
      {
        isLogged: this.isLoggedIn(userResourcesApiRequest),
        isAssistedSale: this.isAssistedSale(userResourcesApiRequest)
      },
      () => this.getProductsInWishlist()
    )
  }

  isOnWishlist = id => {
    const { savedItems } = this.state

    return savedItems.some(itemId => String(itemId) === String(id))
  }

  getProductsInWishlist = async () => {
    const data = await WishlistsService.get(this.state.isLogged, this.state.isAssistedSale)

    if (data) {
      this.setState({
        savedItems: [...data]
      })
    }
  }

  removeSaved = id => {
    const items = [...this.state.savedItems]
    const index = items.findIndex(item => String(item) === String(id))

    if (index !== -1) {
      items.splice(index, 1)
      this.setState({ savedItems: items })
    }
  }

  sendAdd = async (action, successMessage, errorMessage) => {
    const { id, savedItems } = this.state
    showSpinner()

    try {
      const lists = await action

      if (lists.status !== 204) {
        hideSpinner()
        throw new Error('Cannot add to wishlists')
      }

      this.setState({
        showDrawer: false,
        savedItems: [...savedItems, id]
      })
      hideSpinner()
      this.emitSuccessMessage(successMessage)
    } catch (err) {
      hideSpinner()
      this.emitErrorsMessage(errorMessage)
    }
  }

  sendCreate = async (action, successMessage, errorMessage) => {
    const { id, savedItems } = this.state

    try {
      const lists = await action
      const isNotCreated = lists.status !== 201 && lists.status !== 200

      if (isNotCreated) {
        hideSpinner()
        throw new Error(`Cannot create ${listData.get('wishlist-name')}`)
      }

      this.setState({
        showDrawer: false,
        savedItems: [...savedItems, id]
      })
      hideSpinner()
      this.emitSuccessMessage(successMessage)
    } catch (err) {
      hideSpinner()
      this.emitErrorsMessage(errorMessage)
    }
  }

  sendRemove = async (action, successMessage, errorMessage) => {
    try {
      const lists = await action

      if (lists.status !== 204) {
        throw new Error('Cannot remove from wishlists')
      }

      this.emitSuccessMessage(successMessage)
    } catch (err) {
      this.emitErrorsMessage(errorMessage)
    }
  }

  redirectToLogin = () => {
    const options = {
      textMessage:
        'Para utilizar as listas de favoritos é necessário efetuar login, deseja realizar esta operação?',
      textConfirmButton: 'Sim',
      textCancelButton: 'Não'
    }

    const callback = value => {
      if (value) {
        const currentUrl = encodeURIComponent(window.location.href)
        window.location = `/login?redirect=${currentUrl}`
      }
    }

    return new Confirm(callback, options)
  }

  productAdd = list => {
    tracker.addToWishlist(this.state.product)

    this.sendAdd(
      wishlistsRepository.addProduct(list.slug, {
        id: this.state.id
      }),
      `Produto adicionado a "${list.name}".`,
      'Não conseguimos adicionar esse produto, tente novamente.'
    )
  }

  productRemove = id => {
    this.sendRemove(
      wishlistsRepository.removeProductFromLists(id),
      'Produto deletado da lista de favoritos!',
      'Não foi possível deletar o produto. Tente novamente.'
    )
    this.removeSaved(id)
  }

  contentAdd = list => {
    this.sendAdd(
      wishlistsRepository.addContent(list.slug, this.state.id),
      `Conteúdo adicionado a "${list.name}"`,
      'Não conseguimos adicionar esse conteúdo, tente novamente.'
    )
  }

  contentRemove = id => {
    this.sendRemove(
      wishlistsRepository.removeContentFromLists(id),
      'Conteúdo deletado da lista de favoritos!',
      'Não foi possível deletar o conteúdo. Tente novamente.'
    )
    this.removeSaved(id)
  }

  handleOpen = async id => {
    showSpinner()

    try {
      const lists = await wishlistsRepository.lists()
      if (lists.status !== 200) {
        hideSpinner()
        throw new Error(
          `Cannot get wishlists, API returns status: ${lists.status}`
        )
      }

      this.setState(
        {
          showDrawer: true,
          id: id,
          wishlists: lists.data.data
        },
        hideSpinner
      )
    } catch (err) {
      hideSpinner()
    }
  }

  handleClose = () => {
    this.setState({ showDrawer: false })
  }

  handleRemove = (id, typeName) => {
    if (typeName === 'contents') {
      return this.contentRemove(id)
    }

    return this.productRemove(id)
  }

  handleAdd = selectedList => {
    if (this.state.type.name === 'contents') {
      return this.contentAdd(selectedList)
    }

    return this.productAdd(selectedList)
  }

  handleNewList = listData => {
    const { type, id } = this.state

    const hasProduct = type.name === 'products'

    const getType = () => {
      if (hasProduct) {
        return {
          products: [{ id }]
        }
      }
      return { contents: [id] }
    }

    const formatData = {
      name: listData.get('wishlist-name'),
      background_image: listData.get('thumb-wishlist'),
      ...getType()
    }

    showSpinner()

    if (hasProduct) {
      tracker.addToWishlist(this.state.product)
    }

    this.sendCreate(
      wishlistsRepository.createList(formatData),
      'Lista de favoritos criada com sucesso!',
      'Não conseguimos criar sua lista, tente novamente.'
    )
  }

  onPressHeart = (data, type) => {
    const { isLogged } = this.state
    const { id, product = null } = data
    const isChecked = this.isOnWishlist(id)

    if (!isLogged) {
      return this.redirectToLogin()
    }

    this.setState({
      type: { name: type },
      id,
      product
    })

    isChecked ? this.handleRemove(id, type) : this.handleOpen(id)
  }

  emitSuccessMessage = success => {
    emitter.emit('stickyFeedback:success', {
      title: 'Sucesso:',
      content: success
    })
  }

  emitErrorsMessage = error => {
    emitter.emit('stickyFeedback:error', {
      title: 'Ops! Ocorreu o seguinte erro:',
      content: error
    })
  }

  render () {
    const { showDrawer, wishlists, savedItems } = this.state

    const { isOnWishlist, onPressHeart } = this

    const contextValue = {
      savedItems,
      isOnWishlist,
      onPressHeart
    }

    const AddToWishlistProps = {
      isOpenedDrawer: showDrawer,
      closeDrawer: this.handleClose,
      createWishlist: this.handleNewList,
      isNewList: false,
      wishlists: wishlists,
      addToList: this.handleAdd
    }

    return (
      <WishlistProvider value={contextValue}>
        <AddToWishlist {...AddToWishlistProps} />
        {this.props.children}
      </WishlistProvider>
    )
  }
}

export const withWishlistProviderMethods = WrappedComponent => props =>
  (
    <WishlistProviderMethods>
      <WrappedComponent {...props} />
    </WishlistProviderMethods>
  )

export default WishlistProviderMethods
