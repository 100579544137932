import BaseTracking from './base-tracking'

class RichRelevanceCategoryTracking extends BaseTracking {
  static componentName = 'rich-relevance-category-tracking'

  getData () {
    return this.$element.data()
  }

  sendItems (data, R3_COMMON) {
    if (data.products) {
      data.products.forEach(productId => {
        R3_COMMON.addItemId(productId)
      })
    }

    var R3_CATEGORY = new r3_category() // eslint-disable-line
    R3_CATEGORY.setId(data.categoryId)

    if (data.categoryName) {
      R3_CATEGORY.setName(data.categoryName)
    }

    return R3_CATEGORY
  }
}

export default $el => new RichRelevanceCategoryTracking($el).init()

export { RichRelevanceCategoryTracking as Component }
