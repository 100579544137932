import { Text, Heading } from '@leroy-merlin-br/backyard-react'

import { AntiFraud } from '../../AntiFraud'
import { ContentHeader } from '../../ContentHeader'
import * as S from './styled'
import { BankSlipErrorVisualType } from './type'

const BankSlipError = ({ orderId, user }: BankSlipErrorVisualType) => (
  <ContentHeader
    title="Atenção! Estamos gerando seu boleto."
    content={
      <>
        <Text>
          Número do pedido:{' '}
          <Text as="span" isBold>
            {orderId}
          </Text>
        </Text>
        <Text>
          Vamos enviar o{' '}
          <Text as="span" isBold>
            boleto bancário
          </Text>{' '}
          no seu email{' '}
          <Text as="span" isBold>
            {user.email}
          </Text>
          . Verifique sua caixa de Spam, caso não receba nosso e-mail.
        </Text>
        <Text>
          Após a{' '}
          <Text as="span" isBold>
            aprovação do pagamento
          </Text>
          , vamos iniciar o processo de separação do seu pedido,{' '}
          <Text as="span" isBold>
            aguarde nosso e-mail de confirmação
          </Text>
          . Esse processo pode levar{' '}
          <Text as="span" isBold>
            até 3 dias úteis
          </Text>
          .
        </Text>
        <AntiFraud />
      </>
    }
  />
)

export default BankSlipError
