import { Stack } from '@backyard-ui/core'
import { CrossOutline } from '@backyard-ui/icons'
import { Button, Icon } from '@leroy-merlin-br/backyard-react'

import { LeroyConciergeLogo } from '../Icons/LeroyConciergeLogo'
import * as S from './styled'

interface HeaderProps {
  onClose: () => void
}

const Header = ({ onClose }: HeaderProps) => (
  <S.Header justify="space-between" align="center">
    <LeroyConciergeLogo data-testid="leroy-concierge-logo" />

    <Stack spacing="2" align="center">
      <S.Beta>Beta</S.Beta>

      <Button
        as="a"
        size="kilo"
        type="button"
        variant="link"
        onClick={onClose}
        data-testid="close-button"
      >
        <Icon as={CrossOutline} />
      </Button>
    </Stack>
  </S.Header>
)

export default Header
