import PropTypes from 'prop-types'

const SCREEN_SIZE = window.innerWidth

const FoldersItem = ({ item, addToList }) => (
  <div className="folder-item" onClick={ () => SCREEN_SIZE <= 768 && addToList(item) }>
    <div className="no-wishlist-folder-image folder-item-image">
      { !!item.cover.list &&
        <img
          className="wishlist-mosaic-image"
          src={item.cover.list}
        />
      }
    </div>
    <div className="folder-item-infos">
      <span className="folder-item-name">
        <span className="folder-item-name-main">{ item.name }</span>
        <span className="folder-item-name-sub">
          {
            item.products === 1
              ? `${item.products} Produto,`
              : `${item.products} Produtos,`
          }
          {
            item.contents === 1
              ? ` ${item.contents} Conteúdo`
              : ` ${item.contents} Conteúdos`
          }
        </span>
      </span>

      <button
        onClick={() => addToList(item)}
        className="button-hollow folder-item-button">
          Adicionar
      </button>
    </div>
  </div>
)

FoldersItem.propTypes = {
  item: PropTypes.object.isRequired,
  addToList: PropTypes.func.isRequired
}

export default FoldersItem
