import { Text } from '@leroy-merlin-br/backyard-react'
import PropTypes from 'prop-types'

import { AlertMessage } from 'shared/components/AlertMessage'

const Withdraw = ({ storeName = '' }) => (
  <AlertMessage type="warning">
    <Text as="span" size="kilo" noMargin>
      Seu pedido já está{' '}
      <Text as="strong" size="kilo" isBold noMargin>
        disponível para retirada{' '}
      </Text>
      na loja{' '}
      <Text as="strong" size="kilo" isBold noMargin>
        {storeName}
      </Text>
      .
    </Text>
  </AlertMessage>
)

Withdraw.propTypes = {
  storeName: PropTypes.string
}

export default Withdraw
