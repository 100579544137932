import { useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { PasswordField } from 'user/signup/components'
import {
  Stack,
  Button,
  Text,
  toast,
  PasswordField as PasswordBase
} from '@leroy-merlin-br/backyard-react'
import { useForm, Controller } from 'react-hook-form'
import { isServerError } from 'user/utils'

import * as dataLayer from 'scripts/utils/data-layer'

import { Layout } from '../Layout'
import { ServerErrorType } from '../FiscalIdValidateStep/FiscalIdValidateStep'
import { sendNewPassword } from '../../services'
import { useRecoveryContext } from '../../context/recovery-context'

type FormValues = {
  password: string
  passwordConfirmation: string
}

export type PasswordResetProps = {
  onNextStep: () => void
}

function useQuery () {
  const { search } = useLocation()

  return useMemo(() => new URLSearchParams(search), [search])
}

const PasswordReset: React.FC<PasswordResetProps> = ({ onNextStep }) => {
  const query = useQuery()

  const [isLoading, setIsLoading] = useState(false)

  const history = useHistory()

  const {
    control,
    getValues,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: { password: '', passwordConfirmation: '' }
  })

  const { contextData } = useRecoveryContext()
  const { fiscalId, code } = contextData

  const onSubmit = async (formData: FormValues) => {
    const { password, passwordConfirmation } = formData

    const payload = {
      fiscalId,
      code,
      password,
      password_confirmation: passwordConfirmation
    }

    try {
      setIsLoading(true)

      await sendNewPassword(payload)

      toast.primary('Senha criada com sucesso!', {
        variant: 'light'
      })

      dataLayer.recoveryPasswordSaveButtonClick()

      window.location.href = query.get('redirect') ?? '/login'
    } catch (error) {
      const { status } = error as ServerErrorType
      const { data } = error as ServerErrorType

      const passwordResetError = status === 422

      if (passwordResetError) {
        const [errorMessage] = data?.errors?.password || ['Não foi possível criar senha, tente novamente.']

        toast.critical(errorMessage, {
          variant: 'solid'
        })
      }

      const hasServerError = status && isServerError(status)

      if (hasServerError) {
        history.push('/erro-interno')
      }
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Layout description="Digite sua nova senha">
      <form onSubmit={handleSubmit(onSubmit)} data-cy="password-reset-form">
        <Stack space="mega">
          <PasswordField
            name="password"
            label="Nova senha"
            control={control}
            autoComplete="new-password"
            state={Boolean(errors.password) && 'invalid'}
            hint={errors.password?.message}
          />

          <Controller
            control={control}
            name="passwordConfirmation"
            rules={{
              required: 'Insira sua senha',
              validate: value =>
                value === getValues('password') || 'As senhas não conferem'
            }}
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { error }
            }) => (
              <PasswordBase
                name={name}
                label="Confirmar nova senha"
                ref={ref}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                state={error ? 'invalid' : undefined}
                hint={error?.message}
              />
            )}
          />

          <Button
            type="submit"
            onClick={onNextStep}
            isDisabled={!isValid}
            isLoading={isLoading}
            isStretch
            // Esse id está sendo utilizado para o tagueamento
            id="password-reset-form-submit-button"
          >
            Salvar
          </Button>
        </Stack>
      </form>

      <Stack alignX="center">
        <a
          href="/login"
          target="_blank"
          // Esse id está sendo utilizado para o tagueamento
          id="password-reset-step-login-link"
          onClick={dataLayer.recoveryBackToLoginLinkClick}
        >
          <Text size="mega" color="p600" noMargin>
            Voltar ao login
          </Text>
        </a>
      </Stack>
    </Layout>
  )
}

export default PasswordReset
