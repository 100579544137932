import { useRef } from 'react'
import {
  IconButton,
  Inline,
  Popover,
  Icon
} from '@leroy-merlin-br/backyard-react'
import { ShareOutline } from '@backyard-ui/icons'

import socialMediaList from './utils/social-media-list'
import * as S from './styled'

const ShareButton = () => {
  const buttonRef = useRef()

  const pageUrl = window.location.href

  const renderButtons = () => {
    return (
      <Inline space="bit" wrap="nowrap">
        {socialMediaList.map(({ name, icon, url, color }) => (
          <a key={name} href={`${url}${pageUrl}`} title={name} target="_blank">
            <S.IconWrapper>
              <Icon as={icon} fill={color} />
            </S.IconWrapper>
          </a>
        ))}
      </Inline>
    )
  }

  return (
    <S.ShareButton data-testid="share-button">
      <Popover
        title="Compartilhar"
        content={renderButtons()}
        placement="left-start"
        trigger="click"
        interactive
        onUntrigger={() => buttonRef.current.blur()}
      >
        <IconButton
          ref={buttonRef}
          variant="ghost"
          size="kilo"
          label="compartilhar"
        >
          <Icon as={ShareOutline} />
        </IconButton>
      </Popover>
    </S.ShareButton>
  )
}

export default ShareButton
