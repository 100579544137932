'use client'
import { RadioGroup, TextField } from '@backyard-ui/core'

import * as CouponUI from 'frontendCheckout/ui/v1/CouponAndVoucher'
import { Time, Voucher } from 'frontendCheckout/assets/icons'

type CouponProps = {
  applyCouponClick: (code: string) => void
  couponClick: (code: string) => void
  applyCouponErrorMessage: string
  concludeButtonClick: () => void
  couponInput: string
  setCouponInput: (code: string) => void
  couponList: { title: string; id: string; expiration?: string; code: string }[]
  appliedCouponCode: string | null
  isLoading: boolean
}

export const Coupon = ({
  applyCouponClick,
  couponClick,
  applyCouponErrorMessage,
  concludeButtonClick,
  couponInput,
  setCouponInput,
  couponList,
  appliedCouponCode,
  isLoading
}: CouponProps) => {
  return (
    <>
      <div className="my-6">
        <TextField.Root size="md">
          <TextField.Input
            placeholder="Código de cupom"
            value={couponInput}
            onChange={(e) => setCouponInput(e.target.value)}
            isInvalid={Boolean(applyCouponErrorMessage)}
            isDisabled={isLoading}
          />

          <TextField.Slot>
            <CouponUI.InputButton
              onClick={() => applyCouponClick(couponInput)}
              color={applyCouponErrorMessage ? 'error' : 'default'}
            >
              Aplicar
            </CouponUI.InputButton>
          </TextField.Slot>
        </TextField.Root>
        <CouponUI.Message color="error">
          {applyCouponErrorMessage}
        </CouponUI.Message>
      </div>
      <CouponUI.Box>
        {!isLoading && (
          <RadioGroup.Root value={appliedCouponCode || undefined}>
            {couponList.map((coupon, key) => (
              <CouponUI.ListItem
                onClick={() => couponClick(coupon.code)}
                key={key}
                color={appliedCouponCode === coupon.code ? 'gray' : 'default'}
              >
                <div className="flex flex-row justify-between">
                  <div className="items-center flex gap-1 text-lg">
                    <div className="text-primary-600">
                      <Voucher />
                    </div>
                    {coupon.title}
                  </div>

                  <RadioGroup.Item value={coupon.code}></RadioGroup.Item>
                </div>
                <div className="text-sm text-gray-500 flex gap-1 items-center w-full mt-0.5">
                  {coupon.expiration && (
                    <>
                      <Time />
                      <span>`Valido até {coupon.expiration}. `</span>
                    </>
                  )}
                  CÓD.: {coupon.code}
                </div>
              </CouponUI.ListItem>
            ))}
          </RadioGroup.Root>
        )}
        {isLoading && <CouponUI.Loading />}
        {couponList.length <= 0 && !isLoading && (
          <CouponUI.NotFound
            titleMessage="Nenhum cupom encontrado"
            detailMessage="Não existem cupons disponíveis com o código digitado."
          />
        )}
      </CouponUI.Box>
      <CouponUI.Button color="green" onClick={concludeButtonClick}>
        Concluído
      </CouponUI.Button>
    </>
  )
}
