import { FunctionComponent } from 'react'
import { Button, AlertDialog } from '@backyard-ui/core'

import { VoltageDialogProps } from './types'

const VoltageDialog: FunctionComponent<VoltageDialogProps> = ({
  dialogOpen,
  productVoltage,
  selectedOption,
  setDialogOpen,
  submit
}) => (
  <AlertDialog.Root
    isOpen={dialogOpen}
    onOpenChange={setDialogOpen}
    size="sm"
    isCentered
  >
    <AlertDialog.Content>
      <AlertDialog.Title>Confirme a voltagem</AlertDialog.Title>
      <AlertDialog.Description>
        O produto selecionado é de <b>{productVoltage}</b>, tem certeza que é a
        voltagem desejada?
      </AlertDialog.Description>
      <AlertDialog.Footer>
        <AlertDialog.Cancel>
          <Button
            appearance="critical"
            variant="ghost"
            onClick={() => setDialogOpen(false)}
          >
            Não, cancelar
          </Button>
        </AlertDialog.Cancel>
        <AlertDialog.Action>
          <Button onClick={() => submit(selectedOption)}>Sim, confirmar</Button>
        </AlertDialog.Action>
      </AlertDialog.Footer>
    </AlertDialog.Content>
  </AlertDialog.Root>
)

export default VoltageDialog
