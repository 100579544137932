import { useMediaQuery } from '@backyard-ui/hooks'
import { Button, Checkbox, Col, Row, toast } from '@leroy-merlin-br/backyard-react'
import { isEqual } from 'lodash'
import { ChangeEvent, FC, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link, useHistory } from 'react-router-dom'
import { InputLoading } from 'user/components'
import { removeNonNumericCharacters } from 'user/utils'

import { NumberField, SelectField, TextField } from 'shared/components'

import * as validator from 'utils/validators/validators'

import { addAddress, getAddressByZipcode } from '../../services/addresses'
import { Address } from '../../types'
import * as S from './styled'

export type AddressFormProps = {
  data?: Address
  loading: boolean
}

const AddressForm: FC<AddressFormProps> = ({ data }) => {
  const [loadingCep, setLoadingCep] = useState(false)
  const [cepNotFound, setCepNotFound] = useState(false)
  const [addressFound, setAddressFound] = useState(!!data)
  const [emptyCep, setEmptyCep] = useState(true)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const isDesktop = useMediaQuery('md')

  const history = useHistory()

  const defaultValues: Address = data || {
    city: '',
    complement: '',
    district: '',
    ibgeCityCode: '',
    nickname: '',
    number: '',
    reference: '',
    state: '',
    street: '',
    postalCode: {
      description: '',
      number: '',
      type: ''
    },
    main: false,
    hash: ''
  }

  const {
    control,
    formState: { errors, isValid },
    getValues,
    handleSubmit,
    register,
    reset
  } = useForm({
    mode: 'onChange',
    defaultValues: { ...defaultValues }
  })

  const handleCepChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    const cep = removeNonNumericCharacters(value)

    if (cep.length > 7) {
      searchCep(cep)

      setCepNotFound(false)
    } else {
      setEmptyCep(true)

      setCepNotFound(true)
    }
  }

  const searchCep = async (cep: string) => {
    setLoadingCep(true)

    reset({
      ...getValues(),
      ...defaultValues,
      postalCode: {
        ...defaultValues.postalCode,
        number: cep
      }
    })

    try {
      const data: any = await getAddressByZipcode(cep)

      if (data.erro) {
        setCepNotFound(true)

        setAddressFound(false)
      } else {
        reset({
          ...defaultValues,
          street: data.street,
          city: data.city,
          district: data.district,
          state: data.state,
          ibgeCityCode: data.ibgeCode,
          postalCode: {
            description: data.description,
            number: cep,
            type: data.type
          }
        })

        setCepNotFound(false)

        setAddressFound(true)
      }
    } catch (error) {
      setCepNotFound(true)

      setAddressFound(false)
    } finally {
      setEmptyCep(false)
    }

    setLoadingCep(false)
  }

  const onFormSubmit = async (values: Address) => {
    const addressIsNotEdited = isEqual(data, values)

    if (isEqual(data, values)) {
      toast.primary('Endereço atualizado com sucesso!', {
        variant: isDesktop ? 'solid' : 'light'
      })

      setIsSubmitting(false)

      return history.push('/enderecos')
    }

    values.postalCode.number = removeNonNumericCharacters(values.postalCode.number)

    setIsSubmitting(true)

    try {
      await addAddress(values)

      toast.primary(data ? 'Endereço atualizado com sucesso!' : 'Novo endereço cadastrado com sucesso!', {
        variant: isDesktop ? 'solid' : 'light'
      })

      history.push('/enderecos')
    } catch (error) {

      const addressExists = error?.data?.errors?.filter(({ code }) => code === 'address')

      const [validationError] = addressExists || [{
        message: data ? 'Não foi possível atualizar o endereço!' : 'Não foi possível cadastrar o novo endereço!'
      }]

      toast.critical(validationError.message, {
        variant: isDesktop ? 'solid' : 'light'
      })
    } finally {
      setIsSubmitting(false)
    }
  }

  useEffect(() => {
    if (data) {
      reset(data)

      setEmptyCep(false)

      setAddressFound(true)
    }
  }, [data, reset])

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <Row>
        <Col size={{ giga: 4 }}>
          <S.WrapperTextField>
            <InputLoading isLoading={loadingCep}>
              <NumberField
                disabled={isSubmitting}
                control={control}
                name="postalCode.number"
                label={
                  <S.WrapperComplement>
                    CEP
                  </S.WrapperComplement>
                }
                rules={{
                  required: 'Insira seu CEP',
                  validate: (value: string | undefined) =>
                    validator.isCep(value) || 'CEP inválido'
                }}
                onChange={handleCepChange}
                state={
                  (cepNotFound ||
                    Boolean(errors?.postalCode?.number)) &&
                  'invalid'
                }
                hint={
                  cepNotFound
                    ? 'CEP não encontrado'
                    : errors?.postalCode?.number?.message
                }
                format="#####-###"
              />
            </InputLoading>
          </S.WrapperTextField>
        </Col>

        <Col size={{ giga: 4 }}>
          <S.WrapperTextField>
            <TextField
              isDisabled={emptyCep || isSubmitting || cepNotFound}
              control={control}
              label={
                <S.WrapperComplement>
                  Nome do endereço <span>- Opcional</span>
                </S.WrapperComplement>
              }
              name="nickname"
              rules={{
                maxLength: {
                  value: 120,
                  message: 'Limite de 120 caracteres'
                }
              }}
              state={Boolean(errors.nickname) && 'invalid'}
              hint={errors.nickname?.message || 'Ex: Casa, trabalho, casa de praia...'}
            />
          </S.WrapperTextField>
        </Col>
      </Row>

      <Row>
        <Col size={{ giga: 8 }}>
          <S.WrapperTextField>
            <TextField
              isDisabled={emptyCep || isSubmitting || addressFound || cepNotFound}
              control={control}
              label={
                <S.WrapperComplement>
                  Endereço
                </S.WrapperComplement>
              }
              name="street"
              rules={{
                required: 'Insira sua rua/avenida',
                maxLength: {
                  value: 120,
                  message: 'Limite de 120 caracteres'
                }
              }}
              state={Boolean(errors.street) && 'invalid'}
              hint={errors.street?.message}
            />
          </S.WrapperTextField>
        </Col>

        <Col size={{ giga: 4 }}>
          <S.WrapperTextField>
            <TextField
              isDisabled={emptyCep || isSubmitting || cepNotFound}
              control={control}
              label={
                <S.WrapperComplement>
                  Número
                </S.WrapperComplement>
              }
              name="number"
              rules={{
                required: 'Insira o número',
                maxLength: {
                  value: 30,
                  message: 'Limite de 30 caracteres'
                }
              }}
              state={Boolean(errors.number) && 'invalid'}
              hint={errors.number?.message}
            />
          </S.WrapperTextField>
        </Col>
      </Row>

      <Row>
        <Col size={{ giga: 4 }}>
          <S.WrapperTextField>
            <TextField
              isDisabled={emptyCep || isSubmitting || cepNotFound}
              control={control}
              label={
                <S.WrapperComplement>
                  Complemento <span>- Opcional</span>
                </S.WrapperComplement>
              }
              name="complement"
            />
          </S.WrapperTextField>
        </Col>

        <Col size={{ giga: 4 }}>
          <S.WrapperTextField>
            <TextField
              isDisabled={emptyCep || isSubmitting || addressFound || cepNotFound}
              control={control}
              label={
                <S.WrapperComplement>
                  Bairro
                </S.WrapperComplement>
              }
              name="district"
              rules={{
                required: 'Insira seu bairro',
                maxLength: {
                  value: 100,
                  message: 'Limite de 100 caracteres'
                }
              }}
              state={Boolean(errors.district) && 'invalid'}
              hint={errors.district?.message}
            />
          </S.WrapperTextField>
        </Col>

        <Col size={{ giga: 4 }}>
          <S.WrapperTextField>
            <TextField
              isDisabled={emptyCep || isSubmitting || addressFound || cepNotFound}
              control={control}
              label={
                <S.WrapperComplement>
                  Cidade
                </S.WrapperComplement>
              }
              name="city"
              rules={{
                required: 'Insira sua cidade',
                maxLength: {
                  value: 60,
                  message: 'Limite de 60 caracteres'
                }
              }}
              state={Boolean(errors.city) && 'invalid'}
              hint={errors.city?.message}
            />
          </S.WrapperTextField>
        </Col>
      </Row>

      <Row>
        <Col size={{ giga: 4 }}>
          <S.WrapperTextField>
            <SelectField
              isDisabled={emptyCep || isSubmitting || addressFound || cepNotFound}
              control={control}
              label={
                <S.WrapperComplement>
                  Estado
                </S.WrapperComplement>
              }
              name="state"
              rules={{
                required: 'Insira seu estado'
              }}
              state={Boolean(errors.state) && 'invalid'}
              hint={errors.state?.message}
            >
              <option value="AC">Acre</option>
              <option value="AL">Alagoas</option>
              <option value="AP">Amapá</option>
              <option value="AM">Amazonas</option>
              <option value="BA">Bahia</option>
              <option value="CE">Ceará</option>
              <option value="DF">Distrito Federal</option>
              <option value="ES">Espírito Santo</option>
              <option value="GO">Goiás</option>
              <option value="MA">Maranhão</option>
              <option value="MT">Mato Grosso</option>
              <option value="MS">Mato Grosso do Sul</option>
              <option value="MG">Minas Gerais</option>
              <option value="PA">Pará</option>
              <option value="PB">Paraíba</option>
              <option value="PR">Paraná</option>
              <option value="PE">Pernambuco</option>
              <option value="PI">Piauí</option>
              <option value="RJ">Rio de Janeiro</option>
              <option value="RN">Rio Grande do Norte</option>
              <option value="RS">Rio Grande do Sul</option>
              <option value="RO">Rondônia</option>
              <option value="RR">Roraima</option>
              <option value="SC">Santa Catarina</option>
              <option value="SP">São Paulo</option>
              <option value="SE">Sergipe</option>
              <option value="TO">Tocantins</option>
              <option value="EX">Estrangeiro</option>
            </SelectField>
          </S.WrapperTextField>
        </Col>

        <Col size={{ giga: 8 }}>
          <S.WrapperTextField>
            <TextField
              isDisabled={emptyCep || isSubmitting || cepNotFound}
              control={control}
              label={
                <S.WrapperComplement>
                  Referência <span>- Opcional</span>
                </S.WrapperComplement>
              }
              name="reference"
              hint="Informe o ponto de referência próximo de sua residência"
            />
          </S.WrapperTextField>
        </Col>
      </Row>

      <Row>
        <Col>
          <S.WrapperTextField>
            <Checkbox
              {...register('main')}
              isDisabled={emptyCep || isSubmitting || data?.main || cepNotFound}
            >
              Este é o meu endereço principal
            </Checkbox>
          </S.WrapperTextField>
        </Col>
      </Row>

      <S.WrapperButtons>
        {isDesktop ? (
          <>
            <Link to="/enderecos">
              <Button
                isDisabled={isSubmitting}
                appearance="critical"
                variant="outline"
                size="mega"
                type="button"
              >
                Cancelar
              </Button>
            </Link>

            <Button
              isDisabled={!isValid  || cepNotFound}
              isLoading={isSubmitting}
              size="mega"
              type="submit"
            >
              Salvar
            </Button>
          </>
        ) : (
          <Button
            isStretch
            isDisabled={!isValid || cepNotFound}
            isLoading={isSubmitting}
            type="submit"
            size="mega"
          >
            Salvar
          </Button>
        )}
      </S.WrapperButtons>
    </form>
  )
}

export default AddressForm
