import { Text, Icon } from '@leroy-merlin-br/backyard-react'

import { TruckDelivery } from 'shared/icons/TruckDelivery'

import formatPriceToBRL from 'utils/formatPriceToBRL'

import * as S from './styled'

const DELIVERY_MESSAGE = {
  today: 'Receba hoje',
  tomorrow: 'Receba amanhã'
}

const SameDayDeliveryInfo = ({
  deliveryPrice,
  showPrice = true,
  labelSize = 'kilo',
  deliveryKey,
  displayFullMessage = false
}) => (
  <>
    <S.Wrapper deliveryDay={deliveryKey}>
      <Text size={labelSize} noMargin>
        Entrega Expressa:
        {showPrice && (
          <Text size={labelSize} as="strong" isBold noMargin>
            {' '}{formatPriceToBRL(deliveryPrice)}
          </Text>
        )}
      </Text>

      <div>
        <S.DeliveryTag deliveryDay={deliveryKey}>
          <Text size={labelSize} as="strong" isBold noMargin>
            {DELIVERY_MESSAGE[deliveryKey]}
          </Text>
          <Icon as={TruckDelivery} />
        </S.DeliveryTag>
      </div>

    </S.Wrapper>

    <S.Wrapper deliveryDay={deliveryKey}>
      {displayFullMessage && deliveryKey === 'today' && (
        <Text noMargin size="kilo" as="span">
          Compre e confirme o pagamento até às 12h para receber ainda hoje.
        </Text>
      )}
    </S.Wrapper>
  </>
)

export default SameDayDeliveryInfo
