import styled from '@emotion/styled'
import { css } from '@emotion/react'

const wrapperStyles = ({ theme }) => css`
  label: wrapper;

  margin-top: ${theme.spacings.mega};
  margin-bottom: ${theme.spacings.mega};

  text-align: justify;
`

export const Wrapper = styled('div')(wrapperStyles)
