import { useState } from 'react'

import emitter from 'scripts/utils/emitter'

import ProductContainer from 'checkout/containers/MiniCart/Product'

const List = props => {
  const [productRemovalIsDisabled, setProductRemovalIsDisabled] = useState(false)

  emitter.on('cart:removing', function () {
    setProductRemovalIsDisabled(true)
  })

  emitter.on('cart:products-replace', function () {
    setProductRemovalIsDisabled(false)
  })

  return props.shippings.map(({ items, type }) => items.map(product => (
    <ProductContainer
      key={product?.id}
      {...props}
      type={type}
      product={product}
      productRemovalIsDisabled={productRemovalIsDisabled}
    />
  )))
}

export default List
