import { css } from '@emotion/react'
import styled from '@emotion/styled'

const baseStyles = css`
  label: generic-carousel;

  position: relative;
  width: 100%;

  .swiper-slide {
    width: auto;
  }
`

const offsetStyles = ({ theme, hasOffset }) =>
  hasOffset &&
  css`
    label: generic-carousel--with-offset;

    padding-left: ${theme.spacings.mega};
  `

export const GenericCarousel = styled('div')(baseStyles, offsetStyles)
