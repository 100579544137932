import { CheckmarkCircle, TimesCircle } from '@backyard-ui/icons'
import { Icon } from '@leroy-merlin-br/backyard-react'

import * as S from './styled'

const ICON_MAP = {
  primary: CheckmarkCircle,
  critical: TimesCircle
}

const CardIcon = ({ appearance }) => {
  const icon = ICON_MAP[appearance]

  return (
    <S.Wrapper isCritical={appearance === 'critical'}>
      <S.CardIcon>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="75"
          height="75"
          fill="none"
          viewBox="0 0 75 75"
        >
          <path
            fill="currentColor"
            fillRule="evenodd"
            d="M9.938 11.025h55.125A9.94 9.94 0 0175 21v33.038a9.937 9.937 0 01-9.937 9.937H9.938A9.938 9.938 0 010 54.037V21a9.937 9.937 0 019.938-9.975zm55.125 6.862H9.938A3.112 3.112 0 006.825 21v4.238h61.35V21a3.112 3.112 0 00-3.112-3.113zm-55.125 39.3h55.125a3.15 3.15 0 003.112-3.15V32.064H6.825v21.974a3.15 3.15 0 003.113 3.15zM12.6 41.026h8.963a3.413 3.413 0 110 6.788H12.6a3.41 3.41 0 01-3.77-3.394 3.413 3.413 0 013.77-3.394zm26.625 0h-8.963a3.413 3.413 0 100 6.788h8.963a3.411 3.411 0 002.64-5.93 3.413 3.413 0 00-2.64-.858zm25.912 6.563H46.762l9.188-7.35 9.187 7.35z"
            clipRule="evenodd"
          ></path>
        </svg>
      </S.CardIcon>

      <S.SideIcon>
        <Icon as={icon} color={appearance} />
      </S.SideIcon>
    </S.Wrapper>
  )
}

export default CardIcon
