export const formatPrice = price => {
  if (!price || !price.to) {
    return null
  }

  const { integers, decimals } = price.to

  const formattedIntegers = integers.replace('.', '')

  const priceWithDecimals = `${formattedIntegers}.${decimals}`

  const hasOnlyZeros = decimals?.split('').every(item => item === '0')

  return hasOnlyZeros ? formattedIntegers : priceWithDecimals
}
