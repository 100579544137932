import React from 'react'

import { renderTemplate } from '../render-template'

const EditPassword = ({ _token }) => (
  <div className="hidden" data-component="modal">
    <div className="container-fluid" data-component="validation">
      <div className="modal-title">Alterar senha</div>
      <fieldset className="row">
        <input type="hidden" name="_token" value={_token} />
        <div className="col-xs-12">
          <div className="field" data-validation="field">
            <input
              id="current-password"
              className="input"
              type="password"
              name="current-password"
              data-required
              data-validate="required"
              data-validate-success="default"
              data-validate-warning="Campo obrigatório."
              data-validate-target="[data-helper='current_password']"
            />
            <label className="label" htmlFor="current-password">
              Senha atual
            </label>
            <div
              data-helper="current_password"
              aria-describedby="current_password"
            ></div>
          </div>
        </div>
        <div className="col-xs-12">
          <div className="field" data-validation="field">
            <input
              id="new-password"
              className="input"
              type="password"
              name="new-password"
              data-required
              data-minlength="8"
              data-validate-warning="Para a sua segurança, sua senha deve combinar letras e números, ter no mínimo 8 caracteres e não apresentar nenhuma sequência de números. ex: 123."
              data-validate-target="[data-helper='new-password']"
              data-validate="required minlength password"
            />
            <label className="label" htmlFor="new-password">
              Nova senha
            </label>
            <div
              data-helper="new-password"
              data-target="new-password"
              className="helper"
              aria-describedby="password"
            >
              Para a sua segurança, sua senha deve combinar letras e números,
              ter no mínimo 8 caracteres e não apresentar nenhuma sequência de
              números. ex: 123.
            </div>
          </div>
        </div>
        <div className="col-xs-12">
          <div className="field" data-validation="field">
            <input
              id="confirm-password"
              className="input"
              type="password"
              name="confirm-password"
              data-required
              data-validate="required confirm"
              data-confirm="new-password"
              data-validate-warning="Senha não confere. Confirme que a senha digitada acima está correta."
              data-validate-target="[data-helper='confirm_password']"
            />
            <label className="label" htmlFor="confirm-password">
              Confirme sua nova senha
            </label>
            <div
              data-helper="confirm_password"
              aria-describedby="confirm_password"
            ></div>
          </div>
        </div>
        <div className="col-xs-12 modal-footer align-right">
          <button
            className="button button-text"
            type="button"
            data-trigger="close"
          >
            Cancelar
          </button>
          <button
            className="button button-primary"
            type="button"
            data-trigger="submit"
          >
            Salvar senha
          </button>
        </div>
      </fieldset>
    </div>
  </div>
)

const render = (props = {}) => renderTemplate(<EditPassword {...props} />)

export default render
