import { Button } from '@leroy-merlin-br/backyard-react'

import * as S from './styled'
import SpeechBubbleIcon from './SpeechBubbleIcon'
import {
  openSearch,
  setTimeToDisplayAgain
} from './SearchInterestServiceBehaviour'
import { DragAndDrop } from '../DragAndDrop'

type SearchInterestServiceComponentProps = {
  displayComponent: boolean
}

const SearchInterestServiceComponent = ({
  displayComponent
}: SearchInterestServiceComponentProps) => (
  <DragAndDrop
    displayComponent={displayComponent}
    onClose={setTimeToDisplayAgain}
  >
    <S.Wrapper>
      <S.WrapperIcon>
        <SpeechBubbleIcon />
      </S.WrapperIcon>
      <S.WrapperText>
        <S.WrapperTextPromotion>Só vai levar 2 minutos,</S.WrapperTextPromotion>
        <S.WrapperTextCall>Participe da nossa pesquisa</S.WrapperTextCall>
      </S.WrapperText>
      <S.WrapperButton>
        <Button size="kilo" onClick={openSearch}>
          Participar
        </Button>
      </S.WrapperButton>
    </S.Wrapper>
  </DragAndDrop>
)

export default SearchInterestServiceComponent
