import PropTypes from 'prop-types'
import { Text, Visible, Button, Stack } from '@leroy-merlin-br/backyard-react'

import tryAddToCart from 'scripts/utils/cart/addToCart'

import { useUserResources } from 'shared/hooks'

import * as S from './styled'

const SellersListItem = ({
  productId,
  id,
  shop,
  price,
  purchaseButtons,
  limitQuantity,
  pickupInStoreClick,
  closeDrawer
}) => {
  const { userResources } = useUserResources()

  const ecommerceClick = () => {
    const product = {
      id: productId,
      quantity: 1
    }

    if (id) {
      product.skuId = id
    }

    tryAddToCart({
      regionId: userResources?.region,
      type: 'delivery',
      products: [product],
      override: false
    })
    // TODO: pro dataLayer que acontece no tryAddToCart ser disparado, o componente Stockinfo precisa ser renderizado

    closeDrawer()
  }

  return (
    <S.Wrapper>
      {limitQuantity && <div>{limitQuantity}</div>}

      <S.Info>
        <S.Logo src={shop.logo} />

        <div>
          <S.Label>{shop.name}</S.Label>

          {shop.name !== 'Leroy Merlin' && (
            <Text size="kilo" color="n400" as="span" noMargin>
              Loja parceira
            </Text>
          )}
        </div>
      </S.Info>

      <div>
        <Visible when={['untilKilo', 'kilo']}>
          <Text size="kilo" as="div" noMargin>
            Preço do produto
          </Text>
        </Visible>

        <S.Label>
          R$ {price.to.integers},{price.to.decimals}
        </S.Label>

        <Text color="n400" size="kilo" noMargin>
          10x s/juros
        </Text>

        <Text color="n400" size="kilo" noMargin>
          no cartão{' '}
          <Text size="kilo" as="strong" noMargin isBold>
            Celebre!
          </Text>
        </Text>
      </div>

      <Stack space="byte">
        {purchaseButtons.ecommerce && (
          <Button size="kilo" onClick={ecommerceClick} isStretch>
            Comprar e receber
          </Button>
        )}

        {purchaseButtons.pickupInStore && (
          <Button
            variant="outline"
            size="kilo"
            onClick={pickupInStoreClick}
            isStretch
          >
            Retirar na loja
          </Button>
        )}
      </Stack>
    </S.Wrapper>
  )
}

SellersListItem.propTypes = {
  productId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  shop: PropTypes.shape({
    logo: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  }),
  price: PropTypes.shape({
    to: PropTypes.shape({
      integers: PropTypes.string.isRequired,
      decimals: PropTypes.string.isRequired
    })
  }),
  purchaseButtons: PropTypes.shape({
    ecommerce: PropTypes.bool.isRequired,
    pickupInStore: PropTypes.bool.isRequired
  }),
  limitQuantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  pickupInStoreClick: PropTypes.func.isRequired,
  closeDrawer: PropTypes.func.isRequired
}

export default SellersListItem
