import {
  Counter as CounterBase,
  CounterProps,
} from 'frontendCheckout/ui/mobile'

export const Counter = ({ onChangeValue, onMinus, onPlus, value }: CounterProps) => {
  return (
    <div className="flex flex-row min-w-[80px] justify-around items-center">
      <CounterBase
        onChangeValue={onChangeValue}
        onMinus={onMinus}
        onPlus={onPlus}
        value={value}
      />
    </div>
  )
}
