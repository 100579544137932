import $ from 'jquery'
import * as analyticsTracker from '@leroy-merlin-br/backpack-analytics'
import jwtDecode from 'jwt-decode'

import emitter from 'scripts/utils/emitter'
import BaseComponent from 'scripts/components/base-component'
import { tracker } from 'scripts/react-components/utils/app'
import { showSpinner, hideSpinner } from 'scripts/utils/spinner'
import tokenNotify from 'scripts/utils/account/token-notifier'
import {
  pushDataLayerForErrorLogin,
  dispatchLoginToDataLayer,
  pushDataObject
} from 'scripts/utils/data-layer'
import reactComponentLoader from 'scripts/react-component-loader'
import OneTapSignUp from 'scripts/react-components/user/one-tap-sign-up'

class SignIn extends BaseComponent {
  static componentName = 'sign-in'

  static DOMEvents = {
    submit: 'onSubmit'
  }

  static emitterEvents = {
    'recaptcha:success': 'submitForm'
  }

  constructor (element, location = window.location) {
    super(element)
    this.location = location
    this.allowSubmit = true
  }

  init () {
    this.$feedbackError = this.$element.find('[data-feedback-error]')
    this.$feedbackWarning = this.$element.find('[data-feedback-warning]')
    this.getFormValidation()
    this.submitButton = this.$element.find('button')
    this.checkAndSetRedirect()

    this.isEnableGoogleOneTap = $('[data-drawer-sign-up]').data('is-enable-google-one-tap')

    if (this.isEnableGoogleOneTap) {
      this.googleClientId = $('[data-drawer-sign-up]').data('google-client-id')
      this.googleOneTapLogin()
      this.googleUser = undefined
    }
  }

  googleOneTapLogin () {
    const script = document.createElement('script')

    script.type = 'text/javascript'
    script.src = 'https://accounts.google.com/gsi/client'

    document.head.appendChild(script)

    const initGoogle = setInterval(() => {
      if (window.google) {
        google.accounts.id.initialize({
          client_id: this.googleClientId,
          callback: handleCredentialResponse
        })
        google.accounts.id.prompt()

        clearInterval(initGoogle)
      }
    }, 400)

    const handleCredentialResponse = (response) => {
      const api = `${window.env.baseHost}/social/google-one-tap/login`

      const credentialDecoded = jwtDecode(response.credential)

      this.googleUser = {
        name: credentialDecoded.name,
        email: credentialDecoded.email,
        token: response.credential
      }

      const xhrConfig = {
        data: {
          token: response.credential
        },
        method: 'POST',
        dataType: 'json'
      }

      $.ajax(api, xhrConfig).done(this.handleSocialRegisterDone.bind(this))
    }
  }

  handleSocialRegisterDone (response) {
    if (response.userNotRegistered) {
      return this.openRegisterDrawer(response.credential)
    }

    if (sessionStorage.getItem('redirect')) {
      this.location.href = sessionStorage.getItem('redirect')

      return
    }

    return this.location.reload()
  }

  openRegisterDrawer () {
    const { name, email, token } = this.googleUser

    $('[data-drawer-sign-up]').attr({
      'data-name': name,
      'data-email': email,
      'data-token': token
    })

    reactComponentLoader(OneTapSignUp, '[data-drawer-sign-up]')
  }

  checkAndSetRedirect () {
    const currentUrl = window.location.href

    if (currentUrl.includes('redirect=')) {
      const [, redirect] = currentUrl.split('redirect=')

      sessionStorage.setItem('redirect', decodeURIComponent(redirect))
    }
  }

  getFormValidation () {
    if (this.validation) {
      return
    }

    this.validation = this.$element.data('validation')
  }

  onSubmit (e) {
    e.preventDefault()

    showSpinner()

    if (this.validation.validateAll()) {
      if (this.$element.data('recaptchaDisabled') === 1 && this.allowSubmit) {
        this.submitButton.prop('disabled', true)
        this.allowSubmit = false
        return this.submitForm()
      }

      emitter.emit('recaptcha:execute', 'login')
    } else {
      hideSpinner()
    }
  }

  submitForm () {
    const onErrorRequest = (response) => {
      emitter.emit('recaptcha:submit-error')

      const errorData = this.getLabelError(response).join()

      analyticsTracker.pushLogin({
        eventName: 'login_failure',
        loginType: 'default',
        previousPage: ''
      })

      pushDataLayerForErrorLogin(errorData)

      this.onSubmitWarning(response)
    }

    const requestSubmit = (headers) => {
      const action = this.$element.attr('action')
      const data = this.$element.serialize()

      const xhrConfig = {
        data,
        method: 'POST',
        dataType: 'json',
        xhrFields: {
          withCredentials: true
        }
      }

      if (headers) {
        xhrConfig.beforeSend = function (request) {
          request.setRequestHeader('X-FingerPrint-RequestID', headers.requestID)
          request.setRequestHeader('X-FingerPrint-VisitorID', headers.visitorID)
        }
      }

      $.ajax(action, xhrConfig)
        .done(this.onSubmitSuccess.bind(this))
        .fail(onErrorRequest)
        .always(() => {
          setTimeout(() => {
            this.allowSubmit = true
            this.submitButton.prop('disabled', false)
          }, 1000)
        })
    }

    if (window.fpPromise) {
      try {
        window.fpPromise
          .then(fp => fp.get())
          .then(result => {
            requestSubmit({
              requestID: result.requestId,
              visitorID: result.visitorId
            })
          })
      } catch {
        onErrorRequest()
      }
    } else {
      requestSubmit()
    }
  }

  onSubmitSuccess (response) {
    const { status, errors, hybris, user, redirectUrl } = response
    const dataObject = dispatchLoginToDataLayer(response)

    if (status === 'error') {
      emitter.emit('recaptcha:submit-error')
      return this.displayError(errors)
    }

    if (hybris) {
      return this.requestHybrisCookie(hybris, redirectUrl)
    }

    analyticsTracker.pushLogin({
      eventName: 'login_success',
      loginType: 'default',
      previousPage: redirectUrl,
      userId: user?.id
    })

    tokenNotify()
    pushDataObject(dataObject)
    tracker.login('site')

    this.$feedbackError.addClass('hidden')
    this.$feedbackWarning.addClass('hidden')
    this.location.replace(redirectUrl || this.location.href)
  }

  requestHybrisCookie (response, redirect) {
    const { token, url } = response

    const xhrConfig = {
      crossDomain: true,
      headers: {
        Authorization: token
      },
      xhrFields: {
        withCredentials: true
      }
    }

    $.ajax(url, xhrConfig)
      .fail(() => {
        const error = new Error('Problema no login', arguments)
      })
      .always(this.onHybrisCookieResponse.bind(this, redirect))
  }

  onHybrisCookieResponse (redirect) {
    this.location.replace(redirect)
  }

  onSubmitWarning (response) {
    let errors

    if (response.responseJSON && response.responseJSON.errors) {
      errors = response.responseJSON.errors
    } else {
      errors = {
        error: ['Por favor, tente novamente mais tarde.']
      }
    }

    if (errors.assistedSale) {
      this.$feedbackError.addClass('hidden')

      const [errorMessage] = errors.assistedSale

      this.onLdapError(errorMessage)

      return
    }

    this.$feedbackWarning.addClass('hidden')

    this.displayError(errors)
  }

  onLdapError (errorMessage) {
    hideSpinner()

    const $title = this.$feedbackWarning.find('.feedback-warning-title')

    $title.html('Ops! Vimos que está tentando entrar com seu LDAP.')

    const $response = this.$feedbackWarning.find('.feedback-warning-response')

    $response.html(errorMessage)

    this.$feedbackWarning.removeClass('hidden')
  }

  displayError (errors) {
    hideSpinner()
    const $response = this.$feedbackError.find('.response')

    $response.html('')

    $.each(errors, (key, val) => {
      val.map(message => $response.append(`${message}<br>`))
    })

    this.$feedbackError.removeClass('hidden')
  }

  getLabelError (response) {
    const errorMapped = {
      'Nome de usuário ou senha incorretos.': 'invalid_credentials'
    }

    const errors = response?.responseJSON?.errors

    const error = []

    $.each(errors, (key, val) => {
      error.push(val.map(error => errorMapped[error] ?? 'others'))
    })

    return error !== [] ? error : 'others'
  }
}

export default $el => new SignIn($el).init()

export { SignIn as Component }
