import * as S from './styled'
import { ChangeItemTypeButtonVisualType } from './type'

const ChangeItemTypeButton = ({
  OnDeliveryClick,
  OnPickUpClick,
  type,
  isDisabled
}: ChangeItemTypeButtonVisualType) => (
  <S.Wrapper isDisabled={isDisabled}>
    <S.Button isDisabled={isDisabled} onClick={OnDeliveryClick} selected={type === 'delivery'}>
      Receber
    </S.Button>
    <S.Button isDisabled={isDisabled} onClick={OnPickUpClick} selected={type === 'pickupInStore'}>
      Retirar
    </S.Button>
  </S.Wrapper>
)

export default ChangeItemTypeButton
