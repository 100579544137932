import type { SVGAttributes } from 'react'
import { forwardRef } from 'react'

const ContactsIcon = forwardRef<SVGSVGElement, SVGAttributes<SVGSVGElement>>((props, ref) => (
  <svg ref={ref} viewBox="0 0 32 32" {...props}>
    <path
      d="M22.25 29c-3.95 0-8.78-2.25-12.9-6.37a22.16 22.16 0 01-5.93-9.79C2.51 9.31 3.07 6.3 5 4.37a5.56 5.56 0 011.74-1.21 2.76 2.76 0 013.16 1l3 4.26a2.36 2.36 0 01-.53 3.24l-.62.46a1.34 1.34 0 00-.43 1.11 10 10 0 002.86 4.63 10.1 10.1 0 004.67 2.87 1.38 1.38 0 001.08-.45l.44-.6a2.35 2.35 0 013.24-.54l4.28 3a2.76 2.76 0 01.94 3.18 5.33 5.33 0 01-1.2 1.68 7.4 7.4 0 01-5.38 2zM7.48 5a3.74 3.74 0 00-1.06.76C5 7.19 4.63 9.53 5.36 12.36a20.2 20.2 0 005.4 8.87c5.36 5.36 12.44 7.35 15.45 4.34a3.9 3.9 0 00.76-1 .79.79 0 00-.23-.8l-4.29-3a.36.36 0 00-.48.09l-.44.6a3.29 3.29 0 01-3 1.24 11.79 11.79 0 01-5.73-3.43 11.74 11.74 0 01-3.42-5.69 3.31 3.31 0 011.22-3.07l.62-.46a.36.36 0 00.07-.48l-3-4.26A.75.75 0 007.48 5zm20.41 19.9z"
    />
  </svg>
))

export default ContactsIcon
