import { Text } from '@leroy-merlin-br/backyard-react'

import * as S from './styled.js'

const SoldOutProductMessage = () => (
  <S.SoldOutProductMessage>
     <Text as="p" size="kilo" isBold color="n600" noMargin>Produto Indisponível</Text>
  </S.SoldOutProductMessage>
)

export default SoldOutProductMessage
