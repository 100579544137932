import styled from '@emotion/styled'
import { css } from '@emotion/react'

const contentWrapperStyles = ({ theme }) => css`
  label: modal;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  padding: ${theme.spacings.exa};

  svg {
    color: ${theme.colors.n200};
    width: 110px;
    height: 97px;
  }
`

export const ContentWrapper = styled('div')(contentWrapperStyles)
