import reactComponentLoader from 'scripts/react-component-loader'

import { AppProvider } from 'lmcv/components'

import App from './App'

const AppWithProvider = props => (
  <AppProvider>
    <App {...props} />
  </AppProvider>
)

reactComponentLoader(AppWithProvider, '[data-lmcv-dashboard]')
