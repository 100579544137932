import styled from '@emotion/styled'
import { css, keyframes } from '@emotion/react'

const slide = keyframes`
  from {
    background-position: 70% 50%;
  }

  to {
    background-position: -100% 50%;
  }
`

const loadingStyles = ({ theme, isLoading }) =>
  isLoading &&
  css`
    background: ${theme.colors.n100};
    background-image: linear-gradient(
      90deg,
      ${theme.colors.n100} 0,
      ${theme.colors.white} 20%,
      ${theme.colors.n100} 40%,
      ${theme.colors.n100}
    );
    background-repeat: no-repeat;
    background-size: 200%;

    animation: ${slide} 2s ease infinite;
  `

export const Ad = styled('div')(loadingStyles)
