import { Dialog, Spinner } from '@leroy-merlin-br/backyard-react'
import { useEffect, useState } from 'react'

import emitter from 'scripts/utils/emitter'

import { getStockRequest } from 'shared/services/hybris/stock'
import { useUserResources } from 'shared/hooks'

import ContentStockAssistedSale from 'site/Product/components/StockAssistedSale/ContentStockAssistedSale.js'
import SapStockList from 'site/Product/components/StockAssistedSale/SapStockList.js'

import * as S from './styled'

const StockInfo = ({ id, onClose }) => {
  const [apiData, setApiData] = useState(null)
  const { userResources } = useUserResources()

  useEffect(() => {
    if (!id || !userResources?.storeSlug) return

    const { request, cancelRequest } = getStockRequest({
      id,
      storeSlug: userResources?.storeSlug
    })

    const fetchStock = async () => {
      try {
        const { data } = await request()
        setApiData(data)
      } catch (error) {
        if (error.message) {
          emitter.emit('stickyFeedback:error', {
            title: 'Ops! Ocorreu o seguinte erro:',
            content: error
          })
          onClose()
        }
      }
    }

    fetchStock()

    return () => {
      cancelRequest()
    }
  }, [id, onClose, userResources])

  return (
    <Dialog>
      {!apiData ? (
        <S.SpinnerWrapper>
          <Spinner appearance="primary" size="zetta" />
        </S.SpinnerWrapper>
      ) : (
        <S.Wrapper>
          <ContentStockAssistedSale
            store={apiData.stocks.store}
            warehouses={apiData.stocks.warehouses}
            unit={apiData.stocks.unit}
            hideStock={onClose}
          />

          <SapStockList
            stores={apiData.stocks.otherStores}
            unit={apiData.unit}
          />
        </S.Wrapper>
      )}
    </Dialog>
  )
}
export default StockInfo
