import { Card, Text } from '@leroy-merlin-br/backyard-react'

const NoMatch = () => (
  <Card title="Não encontramos a página solicitada">
    <Text size="kilo" color="n400">
      Verifique se a URL está correta e tente novamente.
    </Text>
  </Card>
)

export default NoMatch
