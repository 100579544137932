import { BrowserRouter, Route } from 'react-router-dom'
import { ToastContainer } from '@leroy-merlin-br/backyard-react'

import { Error500 } from 'shared/page/Error500'

import Recovery from '../Recovery'

const Routes = () => {
  return (
    <BrowserRouter>
      <ToastContainer position="top-right" />
      <Route exact path="/user/forgot" component={Recovery}></Route>
      <Route exact path="/erro-interno" component={Error500} />
    </BrowserRouter>
  )
}

export default Routes
