import { pushDataObject } from '../../data-layer'
import type { DataLayerItem } from '../../types'
import { getItemsJoinedByPipe } from '../../utils'

export type PushViewItemProps = {
  item: DataLayerItem
}

export const pushViewItem = ({ item }: PushViewItemProps) => {
  const category = item.categoryTree.split('/')
  const variants = getItemsJoinedByPipe(item.variants ? item.variants : [])

  pushDataObject({
    _clear: true,
    event: 'view_item',
    items: [
      {
        id: item.id,
        bind_id: item.bindId ? item.bindId : undefined,
        name: item.name,
        type: item.type,
        price: item.price,
        discount: item.discount ? item.discount : undefined,
        discount_type: item.discountType ? item.discountType : undefined,
        brand: item.brand,
        category: category[0] ? category[0] : undefined,
        category2: category[1] ? category[1] : undefined,
        category3: category[2] ? category[2] : undefined,
        category4: category[3] ? category[3] : undefined,
        category5: category[4] ? category[4] : undefined,
        variant: variants,
        seller: item.seller,
        quantity: item.quantity
      }
    ]
  })
}
