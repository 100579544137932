import { call, put, takeLatest } from 'redux-saga/effects'
import { toast } from '@leroy-merlin-br/backyard-react'

import * as loyaltyRepository from 'scripts/api/loyalty'

import { history } from 'lmcv/routes'
import * as service from 'lmcv/services/loyaltyProfile'
import { Constants as CouponContants } from 'lmcv/redux/modules/coupon'

import { Constants, Actions } from '.'

function * getProfile () {
  try {
    const user = yield call(service.getProfileLoyalty)

    yield put(Actions.getSuccess(user.data))
  } catch (e) {
    yield put(Actions.getFailure(e))
    yield call(history.push, '/fidelidade/faca-parte')
  }
}

function * updateProfile () {
  try {
    const user = yield call(service.getProfileLoyalty)

    yield put(Actions.getSuccess(user.data))
  } catch (e) {
    yield put(Actions.getFailure(e))

    window.location.reload()
  }
}

function * setProfilePreferences ({ payload }) {
  // mudar nome
  try {
    yield call(loyaltyRepository.setProfilePreferences, payload)
    yield call(toast.primary, 'As alterações foram salvas com sucesso!', {
      variant: 'solid'
    })

    yield put(Actions.setProfilePreferencesSuccess())
  } catch (e) {
    yield call(
      toast.critical,
      'Algo inesperado aconteceu ao salvar as alterações.',
      {
        content: 'Tente novamente mais tarde.',
        variant: 'solid'
      }
    )
    yield put(Actions.setProfilePreferencesFailure(e))
  }
}

export default function * root () {
  yield takeLatest(Constants.LOYALTY_PROFILE_GET, getProfile)
  yield takeLatest(CouponContants.COUPON_ACTIVE_SUCCESS, updateProfile)
  yield takeLatest(
    Constants.LOYALTY_PROFILE_SET_PREFERENCES,
    setProfilePreferences
  )
}
