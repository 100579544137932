import { Fragment } from 'react'
import { Container } from '@leroy-merlin-br/backyard-react'

import { Shell as ShellPart } from 'shared/components/Shell'

import * as S from '../styled'

const Shell = () => (
  <Fragment>
    <S.Wrapper>
      <Container isFluid>
        <S.BreadcrumbBar>
          <ShellPart width={320} height={16} borderRadius="kilo" />
        </S.BreadcrumbBar>
      </Container>
    </S.Wrapper>

    <S.Wrapper>
      <Container isFluid>
        <ShellPart height={219} borderRadius="kilo" />
      </Container>
    </S.Wrapper>
  </Fragment>
)

export default Shell
