import isNumber from 'lodash/isNumber'

import { pluralize } from 'lmcv/utils/pluralize'

export const formatter = {
  days: days => {
    if (isNumber(days)) {
      return days === 0
        ? 'Expira hoje'
        : `Expira em ${days} ${pluralize('dia', days > 1)}`
    }

    return ''
  }
}
