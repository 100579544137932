import { Fragment, useRef } from 'react'
import PropTypes from 'prop-types'
import {
  Dialog,
  Heading,
  CloseButton,
  Text
} from '@leroy-merlin-br/backyard-react'

import { useBodyScrollLock } from 'utils/hooks/use-body-scroll-lock'

import { ShipmentOption } from '../ShipmentOption'
import * as S from './styled'

const ModalContent = ({ onClose, regionName, shipments, skus }) => {
  const elementRef = useRef()

  useBodyScrollLock(elementRef)

  return (
    <Dialog
      addons={
        <Fragment>
          <div>
            <Heading as="h3" size="giga" noMargin>
              Formas de frete para <S.TitleRegion>{regionName}</S.TitleRegion>
            </Heading>
            <Text as="div" size="kilo" color="n400" noMargin>
              Confira todas as opções de entrega disponíveis.
            </Text>
          </div>
          <CloseButton onClick={onClose} />
        </Fragment>
      }
      onClose={onClose}
    >
      {skus.map(sku => <ShipmentOption key={sku.id} sku={sku} shipments={shipments} />)}
      <Text size="kilo" noMargin color="n600">
        <Text as="strong" size="kilo" isBold noMargin>*Atenção! </Text>
        O prazo e valores de frete
        <Text as="strong" size="kilo" isBold noMargin> podem variar </Text>
        conforme a quantidade e disponibilidade dos itens adicionados ao seu
        carrinho. Os tempos de entrega e retirada se
        {' '}
        <Text as="strong" size="kilo" isBold noMargin>
          iniciam após a confirmação do seu pagamento
        </Text>.
      </Text>
    </Dialog>
  )
}

ModalContent.propTypes = {
  onClose: PropTypes.func.isRequired,
  regionName: PropTypes.string.isRequired,
  shipments: PropTypes.arrayOf(
    PropTypes.shape({
      skuId: PropTypes.string,
      modalities: PropTypes.objectOf(
        PropTypes.shape({
          cost: PropTypes.number.isRequired,
          quotePrice: PropTypes.number.isRequired,
          deadline: PropTypes.number.isRequired
        })
      )
    })
  ).isRequired,
  skus: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      shop: PropTypes.shape({
        name: PropTypes.string
      })
    })
  ).isRequired
}

export default ModalContent
