import { FunctionComponent } from 'react'
import { useId } from '@leroy-merlin-br/backyard-react'
import UUID from 'pure-uuid'
import { useTheme } from '@emotion/react'
import { Theme } from '@leroy-merlin-br/backyard-theme'

import { Props, FillColor } from './types'
import { lerp } from './utils'

const StarIcon: FunctionComponent<Props> = ({
  amount = 1,
  color = 'primary'
}) => {
  const gradientId = useId(`percentageGradient-${new UUID(1)}`)
  const theme = useTheme() as Theme

  const fillColor: FillColor = {
    dark: theme.colors.n900,
    primary: theme.colors.p500
  }

  const currentColor = fillColor[color]

  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="star-icon-svg"
    >
      <defs>
        <linearGradient
          id={gradientId}
          x1={`${lerp(-1, 99.99, amount)}%`}
          y1="0%"
          x2="100%"
          y2="0%"
        >
          <stop offset="0%" stopColor={currentColor} />
          <stop offset="1%" stopColor={theme.colors.n200} />
        </linearGradient>
      </defs>
      <path
        d="M4.15813 15.3657C3.83665 15.3695 3.52211 15.2755 3.25926 15.097C2.98432 14.9152 2.77904 14.6512 2.67507 14.3457C2.57111 14.0403 2.57428 13.7104 2.68402 13.4068L4.04127 9.50631L0.787346 7.77277C0.489959 7.61728 0.25708 7.36789 0.127782 7.06645C-0.00151553 6.765 -0.0193439 6.42985 0.0772532 6.11723C0.160151 5.80143 0.354326 5.52324 0.626542 5.33033C0.898759 5.13741 1.23205 5.04176 1.56936 5.05976H5.32672L6.54018 1.15061C6.63731 0.824717 6.84133 0.537944 7.12167 0.333243C7.40201 0.128542 7.74357 0.0169341 8.09522 0.0151401C8.44491 0.0144357 8.78546 0.122998 9.0658 0.324574C9.34614 0.526149 9.55123 0.809887 9.65031 1.13328L10.8728 5.00776H14.4683C14.7977 5.00552 15.1187 5.10784 15.3819 5.29894C15.645 5.49004 15.8357 5.7593 15.9244 6.06522C16.0164 6.36989 16.002 6.69524 15.8834 6.99124C15.7649 7.28723 15.5487 7.53746 15.2683 7.70342L12.284 9.50631L13.5874 13.2248C13.7064 13.5513 13.7121 13.9064 13.6034 14.2363C13.4948 14.5663 13.2777 14.8531 12.9851 15.0537C12.6981 15.2598 12.3498 15.3711 11.9919 15.3711C11.634 15.3711 11.2857 15.2598 10.9986 15.0537L7.98735 12.8607L5.06601 15.097C4.79856 15.2731 4.48198 15.3668 4.15813 15.3657Z"
        fill={`url(#${gradientId})`}
      />
    </svg>
  )
}

export default StarIcon
