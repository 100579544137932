import React from 'react'
import PropTypes from 'prop-types'

import { FormatPrice, PrefixPrice } from 'utils/Price'

import * as S from './styled'

const Prices = ({
  packaging,
  packagingCount,
  translatedUnit,
  quantity,
  price: { to, total },
  productQuantity,
  isAdded
}) => {
  const serviceQuantity = isAdded ? quantity : productQuantity

  return (
    <S.PriceInfo>
      <S.UnitPrice>
        <PrefixPrice packaging={packaging} packagingCount={1} />
        <FormatPrice quantity={packaging} unit={translatedUnit} price={to} />
      </S.UnitPrice>
      <S.TotalPrice>
        <PrefixPrice packaging={quantity} packagingCount={packagingCount} />
        <FormatPrice quantity={serviceQuantity} unit={translatedUnit} price={total} />
      </S.TotalPrice>
    </S.PriceInfo>
  )
}

Prices.propTypes = {
  packaging: PropTypes.number.isRequired,
  packagingCount: PropTypes.number.isRequired,
  translatedUnit: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
  price: PropTypes.shape({
    to: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired
  }).isRequired,
  packagingUnitLabels: PropTypes.shape({
    singular: PropTypes.string.isRequired,
    plural: PropTypes.string.isRequired
  }).isRequired
}

Prices.defaultProps = {
  packagingUnitLabels: {
    singular: '',
    plural: ''
  }
}

export default Prices
