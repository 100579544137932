import $ from 'jquery'
import productsTemplate from 'templates/entry-key/product-thumb.js'
import facetsTemplate from 'templates/entry-key/facets.js'
import facetsOverlayTemplate from 'templates/entry-key/facets-overlay.js'
import highlightedFacetsTemplate from 'templates/entry-key/highlighted-facets.js'

import componentLoader from 'scripts/component-loader'
import BaseComponent from 'scripts/components/base-component'

class ResultsUpdater extends BaseComponent {
  static emitterEvents = {
    'entryKey:update:products': 'onUpdateProducts',
    'entryKey:update:facets': 'onUpdateFacets',
    'entryKey:update:facetsOverlay': 'onUpdateFacetsOverlay',
    'entryKey:update:highlightedFacets': 'onUpdateHighlightedFacets'
  }

  init () {
    this.$productsContainer = this.$element.find('[data-products-container]')
    this.$facetsContainer = this.$element.find('[data-facets-container]')
    this.$facetsOverlayContainer = this.$element.find('[data-facets-overlay-container]')
    this.$highlightedFacetsContainer = this.$element.find('[data-highlighted-facets-container]')
    this.$highlightedWrapper = this.$element.find('[data-highlighted-wrapper]')
    this.placeholder = this.$element.data('thumb-placeholder')
    this.bazaarvoiceEnabled = !!this.$element.data('bazaarvoice-enabled')

    this.setupHighlighted()
  }

  setupHighlighted () {
    const hasHighlight = this.$highlightedFacetsContainer.find('select').length
    this.toggleHighlightedWrapper(hasHighlight)
  }

  onUpdateProducts ({ products, assistedSale }) {
    const storeSlug = this.$element.data('store-slug')
    const maxLimit = false
    const placeholder = this.placeholder
    const bazaarvoiceEnabled = this.bazaarvoiceEnabled

    this.$productsContainer.html(productsTemplate({
      products,
      assistedSale,
      storeSlug,
      bazaarvoiceEnabled,
      maxLimit,
      placeholder
    }))

    this.loadComponents(this.$productsContainer)

    this.$productsContainer.find('[data-lazy]').lazyload()
  }

  onUpdateFacets ({ facets, origin, assistedSale, ignoreExclusive }) {
    this.$facetsContainer.html(facetsTemplate({ facets, origin, assistedSale, ignoreExclusive }))

    this.loadComponents(this.$facetsContainer)
  }

  onUpdateFacetsOverlay ({ facets, origin, ignoreExclusive }) {
    this.$facetsOverlayContainer.html(facetsOverlayTemplate({ facets, origin, ignoreExclusive }))

    this.loadComponents(this.$facetsOverlayContainer)
  }

  onUpdateHighlightedFacets ({ facets, origin, assistedSale }) {
    const highlightedFacets = facets.filter(facet => facet.highlight)
    const template = highlightedFacetsTemplate({ highlightedFacets, origin, assistedSale })

    this.toggleHighlightedWrapper(highlightedFacets.length)
    this.$highlightedFacetsContainer.html(template)
    this.loadComponents(this.$highlightedFacetsContainer)
  }

  toggleHighlightedWrapper (shouldDisplay) {
    this.$highlightedWrapper.toggleClass('hidden', !shouldDisplay)
  }

  loadComponents ($element) {
    componentLoader($element.find('[data-component]'))
  }
}

export default ($el) => new ResultsUpdater($el).init()

export { ResultsUpdater as Component }
