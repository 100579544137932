import PropTypes from 'prop-types'

const FeedbackErrorsTemplate = ({ errors }) => {
  return (
    <ul>
      {errors.map((error, index) => (
        <li key={`error-item-${index}`}>
          {error}
        </li>
      ))}
    </ul>
  )
}

FeedbackErrorsTemplate.propTypes = {
  errors: PropTypes.array.isRequired
}

export default FeedbackErrorsTemplate
