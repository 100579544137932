import { Minus, Plus } from 'frontendCheckout/assets/icons'

export type CounterProps = {
  onMinus: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  onPlus: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  value: string | number
  onChangeValue: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export const Counter = ({
  onMinus,
  onPlus,
  value,
  onChangeValue
}: CounterProps) => {
  return (
    <>
      <div className="text-green-700 text-xl leading-4 cursor-pointer relative">
        <Minus />
        <div
          data-testid="minus-button"
          onClick={onMinus}
          className="absolute w-6 h-6 -translate-y-3 -translate-x-2"
        />
      </div>
      <input
        data-testid="input-value"
        value={value}
        onChange={onChangeValue}
        type="text"
        className="text-sm text-center w-16 outline-none"
      />
      <div className="text-green-700 text-xl leading-4 cursor-pointer relative">
        <Plus />
        <div
          data-testid="plus-button"
          onClick={onPlus}
          className="absolute w-6 h-6 -translate-y-4 -translate-x-2"
        />
      </div>
    </>
  )
}
