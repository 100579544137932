import { Icon } from '@leroy-merlin-br/backyard-react'
import { CrossOutline } from '@backyard-ui/icons'
import { ChevronDownSolid } from '@leroy-merlin-br/backyard-icons'

import * as S from './styled'

const Select = ({ children, name, value, placeholder, onChange }) => (
  <S.SelectContainer isSelected={value}>
    <S.IconContainer>
      {value && (
        <S.ClearButton onClick={() => onChange(1, '')}>
          <Icon as={CrossOutline} />
        </S.ClearButton>
      )}
      {!value && <Icon as={ChevronDownSolid} size="byte" />}
    </S.IconContainer>

    <S.Select
      name={name}
      value={value}
      onChange={event =>
        onChange(event.target.selectedIndex, event.target.value)
      }
    >
      {!value && (
        <option key={placeholder} value="" disabled>
          {placeholder}
        </option>
      )}
      {children}
    </S.Select>
  </S.SelectContainer>
)

export default Select
