import { useState, useLayoutEffect } from 'react'
import {
  Stack,
  Container,
  Grid,
  Spacer,
  Hidden,
  Button,
  Flex
} from '@backyard-ui/core'
import { FilterOutline } from '@backyard-ui/icons'

import { useUserResources } from 'shared/hooks'

import WishlistProviderMethods from 'site/Wishlist/provider'

import { useEntryStore } from '../../Entry.store'
import { Header } from '../../ui/Header'
import { Aside } from '../../ui/Aside'
import { RetailMediaBanner } from '../../ui/RetailMediaBanner'
import { RetailMediaProductsCarousel } from '../../ui/RetailMediaProductsCarousel'
import { Content } from '../../ui/Content'
import styles from './page.styles'

function EntryPage () {
  const { userResources } = useUserResources()
  const store = useEntryStore(state => state.ui)
  const [scroll, setScroll] = useState(false)

  const handleScroll = () => {
    if (window.scrollY > 215) {
      setScroll(true)
    } else {
      setScroll(false)
    }
  }

  useLayoutEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  if (!userResources?.region) {
    return null
  }

  return (
    <Stack direction="column">
      <div />
      <Container size={{ initial: '3xl', lg: '6xl' }} className="w-full">
        <Stack direction="column">
          {window.env.newTail.enabled && <RetailMediaBanner />}
          <Grid.Root isGrow>
            <Grid.Item
              size={{
                initial: 12,
                md: 0,
                lg: 3
              }}
            >
              <Aside />
            </Grid.Item>
            <Grid.Item
              size={{
                initial: 12,
                md: 9
              }}
            >
              <Stack direction="column">
                <Header />
                <WishlistProviderMethods>
                  <Content region={userResources.region} />
                </WishlistProviderMethods>
                {window.env.newTail.enabled && <RetailMediaProductsCarousel />}
              </Stack>
            </Grid.Item>
          </Grid.Root>
        </Stack>
        <Spacer />
      </Container>

      {!store.isFiltersOpened && (
        <Hidden above="lg">
          <Flex
            justify="center"
            className={styles().button({
              animation: scroll ? 'up' : 'down'
            })}
          >
            <Button iconLeft={<FilterOutline />} onClick={store.toggleFilters}>
              Filtros
            </Button>
          </Flex>
        </Hidden>
      )}
    </Stack>
  )
}

export default EntryPage
