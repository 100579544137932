import { css } from '@emotion/react'
import styled from '@emotion/styled'

const COLOR_MAP = {
  info: {
    background: 'b100',
    icon: 'b600'
  },
  warning: {
    background: 'y100',
    icon: 'y600'
  },
  success: {
    background: 'g100',
    icon: 'g600'
  },
  critical: {
    background: 'r100',
    icon: 'r600'
  }
}

const baseStyles = ({ theme, type = 'info' }) => {
  const { background, icon } = COLOR_MAP[type]

  return css`
    label: alert-message;

    display: grid;
    grid-template-columns: auto 1fr auto;
    grid-template-areas:
      'icon   message close'
      'button button  button';

    background: ${theme.colors[background]};

    border-radius: ${theme.borderRadius.giga};
    box-shadow: 0px 0px 0px 1px rgba(186, 182, 188, 0.07),
      0px 0px 1px ${theme.colors.shadow}, 0px 2px 2px rgba(161, 155, 162, 0.07);

    padding: ${theme.spacings.kilo};

    ${IconWrapper} {
      color: ${theme.colors[icon]};
    }

    ${theme.media.kilo} {
      grid-template-columns: auto 1fr auto auto;
      grid-template-areas: 'icon message button close';
    }

    strong {
      font-weight: ${theme.font.weight.bold};
    }
  `
}

const stretchStyles = ({ isStretch }) =>
  isStretch &&
  css`
    label: alert-message--stretch;

    width: 100%;
  `

const topSpacingStyles = ({ theme, hasTopMargin }) =>
  hasTopMargin &&
  css`
    label: alert-message--top-spacing;

    margin-top: ${theme.spacings.byte};
  `

const iconWrapperStyle = ({ theme }) => css`
  label: alert-message__icon-wrapper;

  grid-area: icon;

  margin-top: 3px;
  margin-right: ${theme.spacings.byte};
`

const messageWrapperStyles = () => css`
  label: alert-message__message-wrapper;

  grid-area: message;
`

const buttonWrapperStyles = ({ theme }) => css`
  label: alert-message__button-wrapper;

  grid-area: button;

  width: 100%;
  margin-top: ${theme.spacings.kilo};

  & > button {
    white-space: nowrap;
  }

  ${theme.media.kilo} {
    margin-top: 0;
    margin-left: ${theme.spacings.mega};
  }
`

const closeButtonWrapperStyle = ({ theme }) => css`
  label: alert-message__close-button-wrapper;

  grid-area: close;

  margin-left: ${theme.spacings.byte};

  svg {
    width: ${theme.iconSizes.kilo};
    height: ${theme.iconSizes.kilo};
  }
`

export const AlertMessage = styled('div')(
  baseStyles,
  stretchStyles,
  topSpacingStyles
)
export const IconWrapper = styled('div')(iconWrapperStyle)
export const MessageWrapper = styled('div')(messageWrapperStyles)
export const ButtonWrapper = styled('div')(buttonWrapperStyles)
export const CloseButtonWrapper = styled('div')(closeButtonWrapperStyle)
