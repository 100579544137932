import { pushDataObject } from 'scripts/utils/data-layer'

const dataLayerFields = {
  fiscalId: 'f1',
  name: 'f2',
  birthdate: 'f3',
  favoriteStore: 'f4',
  email: 'f5',
  emailConfirm: 'f6',
  phone: 'f7',
  password: 'f8',
  occupation: 'f9'
}

const dataLayerFieldsPJ = {
  fiscalId: 'f1',
  email: 'f5',
  emailConfirm: 'f6',
  password: 'f8'
}

const dataLayerOptins = {
  optInPro: 'op1',
  general: 'op2',
  email: 'op3',
  sms: 'op4',
  whatsApp: 'op5'
}

const dataLayerOptinsPJ = {
  email: 'op3'
}

export const onSubmitFormSuccess = (signupObj) => {
  const { signupType, optinsSelected } = signupObj

  const dataObject = {
    event: 'sign_up',
    method: 'signup-form',
    sign_up_type: signupType,
    optins: optinsSelected,
    login_type: 'leroy',
    'event-category': 'singup',
    'event-action': 'clique_cadastrar',
    'event-label': 'sucesso'
  }

  pushDataObject(dataObject)
}

export const onSubmitFormWithFieldsErrors = (signupObj) => {
  const { signupType, fieldsStatus, optinsSelected } = signupObj

  const dataObject = {
    event: 'sign_up_err',
    method: 'signup-form',
    sign_up_type: signupType,
    fields: fieldsStatus,
    optins: optinsSelected,
    login_type: 'leroy',
    'event-category': 'erros_singup',
    'event-action': 'clique_cadastrar',
    'event-label': fieldsStatus
  }

  pushDataObject(dataObject)
}

export const fieldsStatusFormatter = (invalidFields) => {
  let fieldsStatus = ''

  invalidFields = { ...invalidFields, ...invalidFields?.loyalty }

  for (const field in dataLayerFields) {
    if (dataLayerFields[field] !== undefined) {
      let code = ''
      code = dataLayerFields[field] + '-'
      code += !(field in invalidFields) ? 'ok' : 'er'
      fieldsStatus += code + ','
    }
  }

  return fieldsStatus.slice(0, -1)
}

export const fieldsStatusFormatterPJ = (invalidFields) => {
  let fieldsStatus = ''

  invalidFields = { ...invalidFields, ...invalidFields?.loyalty }

  for (const field in dataLayerFieldsPJ) {
    if (dataLayerFieldsPJ[field] !== undefined) {
      let code = ''
      code = dataLayerFieldsPJ[field] + '-'
      code += !(field in invalidFields) ? 'ok' : 'er'
      fieldsStatus += code + ','
    }
  }

  return fieldsStatus.slice(0, -1)
}

export const optinsSelectedFormatter = (values) => {
  let fieldsStatus = ''

  values = { ...values.loyalty, ...values.optins }

  for (const optins in dataLayerOptins) {
    if (dataLayerOptins[optins] !== undefined) {
      let code = ''
      code = dataLayerOptins[optins] + '-'
      code += values[optins] ? 'ok' : 'no'
      fieldsStatus += code + ','
    }
  }
  fieldsStatus += 'op6-' + (values.occupation !== '' ? 'ok' : 'no')
  return fieldsStatus
}

export const optinsSelectedFormatterPJ = (values) => {
  let fieldsStatus = ''
  values = { ...values.optins }

  for (const optins in dataLayerOptinsPJ) {
    if (dataLayerOptins[optins] !== undefined) {
      let code = ''
      code = dataLayerOptins[optins] + '-'
      code += values[optins] ? 'ok' : 'no'
      fieldsStatus += code + ','
    }
  }

  return fieldsStatus.slice(0, -1)
}
