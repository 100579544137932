import PropTypes from 'prop-types'

const RawHtml = (props) => {
  return (
    <div ref={props.contentRef} dangerouslySetInnerHTML={{ __html: props.children }} />
  )
}

RawHtml.propTypes = {
  children: PropTypes.string,
  contentRef: PropTypes.func
}

export default RawHtml
