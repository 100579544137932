import { css } from '@emotion/react'
import styled from '@emotion/styled'

const facetsStyles = ({ theme }) => css`
  label: facets;

  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  grid-row-gap: ${theme.spacings.mega};

  ${theme.media.giga} {
    grid-template-columns: repeat(2, 1fr);
  }

  ${theme.media.tera} {
    grid-template-columns: repeat(3, 1fr);
  }
`

const selectWrapperStyles = ({ theme }) => css`
  label: select__wrapper;

  display: grid;
  grid-template-columns: 1fr 12px;
  grid-gap: ${theme.spacings.kilo};
  align-items: center;
  justify-items: left;

  & > svg {
    color: ${theme.colors.n300};

    width: 12px;
    height: 12px;
  }

  &:last-of-type > svg {
    display: none;
  }

  ${theme.media.giga} {
    grid-template-columns: 1fr 36px;
    grid-gap: 0;
    justify-items: center;

    & > svg {
      width: 20px;
      height: 20px;
    }
  }
`

export const Facets = styled('div')(facetsStyles)
export const SelectWrapper = styled('div')(selectWrapperStyles)
