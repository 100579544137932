const formatPriceToBRL = price => {
  if (isNaN(price)) {
    return ''
  }

  const formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  })

  return formatter.format(parseFloat(price))
}

export default formatPriceToBRL
