import { Select } from '@leroy-merlin-br/backyard-react'
import { useController } from 'react-hook-form'

const SelectField = props => {
  const { field } = useController(props)
  const { onChange, ref } = field

  return <Select {...field} {...props} ref={ref} onChange={onChange} />
}

export default SelectField
