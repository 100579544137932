import { css } from '@emotion/react'
import styled from '@emotion/styled'

const dragAreaStyles = ({ width, theme }) => css`
  label: drag--area;

  display: flex;
  align-items: center;
  position: absolute;

  top: 0;
  left: 0;
  padding-left: ${theme.spacings.giga};

  width: ${width}px;
  height: 100%;

  z-index: 1;

  &:hover {
    cursor: grab;
  }
`

const wrapperStyles = ({ theme }) => css`
  label: wrapper;

  position: relative;
  overflow: hidden;

  padding-left: ${theme.spacings.giga};
  padding-top: 70px;
  padding-bottom: 50px;
`

const barStyles = ({ theme }) => css`
  label: bar;

  background: ${theme.colors.n100};

  position: relative;
  list-style: none;

  width: 100%;
  height: 8px;

  margin: 0;
  border-radius: 10px;

  &:before {
    content: 'R$';
    position: absolute;

    top: 50%;
    left: -${theme.spacings.giga};
    text-align: center;

    color: ${theme.colors.p500};
    font-size: 10px;
    font-weight: 600;
    line-height: ${theme.typography.subHeadings.kilo.lineHeight};

    transform: translateY(-50%);
  }
`

const barProgressStyles = ({ theme, progress, value }) => css`
  label: bar__progress;

  background: ${theme.colors.p500};

  display: block;
  position: absolute;

  left: 0;
  top: 50%;

  width: ${progress}%;
  height: 100%;
  border-radius: ${theme.borderRadius.giga};
  transform: translateY(-50%);

  &:after {
    content: '${value}';
    position: absolute;

    top: ${theme.spacings.mega};
    right: -10px;

    color: ${theme.colors.p500};
    font-size: ${theme.typography.text.mega.fontSize};
    font-weight: 600;
    line-height: ${theme.typography.headings.tera.lineHeight};
    text-align: center;
  }
`

const ballonStyles = ({ theme }) => css`
  label: ballon;

  background: ${theme.colors.v600};

  display: block;
  position: relative;

  top: -${theme.spacings.tera};
  padding: ${theme.spacings.byte} ${theme.spacings.mega};

  color: ${theme.colors.white};
  font-size: ${theme.typography.subHeadings.kilo.fontSize};
  font-weight: 600;
  line-height: 14px;
  text-align: center;

  border-radius: 20px;
  box-shadow: 0 2px 4px 2px rgba(148, 148, 148, 0.22);
  user-select: none;

  &:before,
  &:after {
    content: '';

    display: block;
    position: absolute;

    left: 0;
    right: 0;
    margin: 0 auto;
  }

  &:after {
    background: ${theme.colors.v600};

    bottom: -${theme.spacings.bit};

    width: 10px;
    height: 10px;

    border-radius: ${theme.borderRadius.kilo};
    transform: rotate(45deg);
  }
`

const ballonIconStyles = () => css`
  label: ballon__icon;

  position: absolute;

  top: -${theme.spacings.bit};
  right: -${theme.spacings.bit};
`

const ballonLoadingStyles = ({ theme, isLoading }) =>
  isLoading &&
  css`
    label: ballon__loading;

    background: ${theme.colors.n100};

    width: 85px;
    height: 24px;

    box-shadow: none;

    &:after {
      background: ${theme.colors.n100};
    }
  `

const ballonStylesActive = ({ theme, isGoal }) =>
  isGoal &&
  css`
    label: ballon--active;

    background: ${theme.colors.white};
    color: ${theme.colors.n300};

    border: 1px solid ${theme.colors.n100};
    box-shadow: none;
    box-sizing: content-box;

    &:before {
      background: ${theme.colors.white};

      bottom: -1px;
      width: 9px;

      height: 1px;
    }

    &:after {
      z-index: -1;
      background: ${theme.colors.white};
      border: 1px solid ${theme.colors.n100};
    }
  `

const labelStyles = ({ theme }) => css`
  label: label;

  display: block;
  position: relative;
  text-align: center;
  user-select: none;

  top: ${theme.spacings.tera};

  color: ${theme.colors.n300};
  font-size: ${theme.typography.subHeadings.kilo.fontSize};
  font-weight: 600;
  line-height: 14px;

  &:before {
    content: '';

    position: absolute;

    top: -${theme.spacings.tera};
    left: 0;
    right: 0%;
    margin: 0 auto;

    width: 0;
    height: 24px;

    border: 1px dashed ${theme.colors.n100};
  }
`

const itemStyles = ({ theme, toLeft }) => css`
  label: item;

  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  text-align: center;

  top: 50%;
  left: calc(${toLeft}% - 50px);
  min-width: 110px;
  margin: 0;

  z-index: 1;

  transform: translateY(-50%);

  &:after {
    content: '';

    background: ${theme.colors.white};

    display: block;
    position: absolute;

    top: 50%;
    left: 50%;

    z-index: 1;

    width: 20px;
    height: 20px;

    border-radius: 50px;
    border: 4px solid ${theme.colors.n100};
    transform: translate3d(-50%, -50%, 0);
  }
`

const itemStylesActive = ({ theme, isGoal }) =>
  isGoal &&
  css`
    label: item--active;

    &:after {
      background-color: ${theme.colors.p500};
      background-image: url(${window && window.env.assetHost}assets/images/lmcv/icons/icon-check.svg);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 8px;

      border-color: ${theme.colors.p500};
    }
  `

const expirationLabelStyles = ({ theme }) => css`
  label: expiration-label;

  display: grid;
  align-items: center;
  justify-content: start;
  grid-template-columns: auto auto;
  grid-column-gap: ${theme.spacings.bit};

  margin-bottom: ${theme.spacings.mega};
`

export const DragArea = styled('div')(dragAreaStyles)
export const Wrapper = styled('div')(wrapperStyles)
export const Bar = styled('ul')(barStyles)
export const BarProgress = styled('div')(barProgressStyles)
export const BallonIcon = styled('div')(ballonIconStyles)
export const Ballon = styled('div')(ballonStyles, ballonLoadingStyles)
export const Label = styled('span')(labelStyles)
export const ExpirationLabel = styled('div')(expirationLabelStyles)

export const Item = styled.li`
  ${itemStyles}

  ${Ballon} {
    ${ballonStylesActive};
  }

  ${itemStylesActive};
`
