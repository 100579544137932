import { Heading, Text } from '@leroy-merlin-br/backyard-react'
import { Fragment } from 'react'

import { formatters } from 'utils/formatters'

import { Customer } from '../../types'
import * as S from './styled'

type ConcludeProps = {
  customerSelected?: Customer
  price: number
  loyaltyBalance: string
}

const Conclude = ({
  customerSelected,
  price,
  loyaltyBalance
}: ConcludeProps) => (
  <Fragment>
    {customerSelected && (
      <S.TitleWrapper>
        <Heading size="giga" as="h3">
          Tem certeza que deseja compartilhar esse cupom com{' '}
          {customerSelected.customerName} por{' '}
          <span>{formatters.toDecimal(price)} pts?</span>
        </Heading>
      </S.TitleWrapper>
    )}

    <Text size="kilo" color="n500">
      Você gastará{' '}
      <Text size="kilo" color="n500" noMargin as="strong" isBold>
        {formatters.toDecimal(price)} pontos
      </Text>{' '}
      para enviar esse cupom, seu cliente poderá ver o cupom enviado na seção{' '}
      <Text size="kilo" color="n500" noMargin as="strong" isBold>
        Minhas vantagens
      </Text>
      , e avisaremos que esse cupom foi enviado por você.
    </Text>

    <Text size="kilo" color="n500">
      Seu saldo atual é de{' '}
      <Text size="kilo" color="n500" noMargin as="strong" isBold>
        {loyaltyBalance} pts.
      </Text>
    </Text>
  </Fragment>
)

export default Conclude
