import { useEffect, useCallback } from 'react'
import {
  Dialog,
  Container,
  Row,
  Col,
  Button,
  useModal,
  ButtonGroup,
  Icon
} from '@leroy-merlin-br/backyard-react'

import emitter from 'scripts/utils/emitter'

import WhatsAppIcon from './Icon/Whatsapp'

const DefaultDialog = ({ onClose, title, description }) => (
  <Dialog
    data-cy="alert-modal"
    onClose={onClose}
    title={title}
    footer={
      <Container>
        <Row justify="center">
          <Col size={{ giga: 4 }}>
            <Button
              type="button"
              appearance="primary"
              onClick={onClose}
              isStretch
              data-cy="alert-modal-button"
            >
              Ok
            </Button>
          </Col>
        </Row>
      </Container>
    }
  >
    {description}
  </Dialog>
)

const WhatsAppDialog = ({ onClose, title, description }) => (
  <Dialog
    data-cy="alert-modal"
    onClose={onClose}
    title={title}
    footer={
      <ButtonGroup align="flex-end" label="">
        <Button as="a"
          appearance="critical"
          onClick={onClose}
          variant="ghost"
          size="mega"
          data-cy="close-modal-button"
        >
          Cancelar
        </Button>
        <Button as="a" size="mega" onClick={onClose}
          href="https://api.whatsapp.com/send?phone=551140071380&text=Ol%C3%A1,+desejo+comprar+um+produto"
          rel="noopener noreferrer"
          target="_blank"
          iconRight={WhatsAppIcon}
        >
          Comprar por Whatsapp
        </Button>
      </ButtonGroup>
    }
  >
    {description}
  </Dialog>
)

const getDialogByType = (type) => {
  switch (type) {
    case 'whatsapp':
      return WhatsAppDialog
    default:
      return DefaultDialog
  }
}

const AlertModal = () => {
  const { setModal } = useModal()

  const showModal = useCallback(
    ({ title = ' ', description, type }) => {
      const DialogComponent = getDialogByType(type)
      setModal({
        children: ({ onClose }) => (
          <DialogComponent onClose={onClose} title={title} description={description} />
        ),
        isCentered: true
      })
    },
    [setModal]
  )

  useEffect(() => {
    emitter.on('alertModal', showModal)
  }, [showModal])

  return null
}

export default AlertModal
