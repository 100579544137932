import PropTypes from 'prop-types'
import { Text, Heading } from '@leroy-merlin-br/backyard-react'

import { Ellipsis } from 'shared/components/Ellipsis'
import { ShapeThumb } from 'shared/constants/prop-types'
import { formatDate } from 'shared/utils/date'

import * as S from './styled'

const ThumbInfo = ({ data: { publishDate, title, url } }) => (
  <S.ThumbnailInfo>
    <S.TitleLink href={url} title={title}>
      <Heading as="h4" size="giga" color="white" noMargin>
        <Ellipsis clamp={2}>{title}</Ellipsis>
      </Heading>
    </S.TitleLink>

    <Text align="center" as="span" size="kilo" color="white" noMargin>
      {formatDate(publishDate)}
    </Text>
  </S.ThumbnailInfo>
)

ThumbInfo.propTypes = {
  data: PropTypes.shape({ ...ShapeThumb }).isRequired
}

export default ThumbInfo
