import $ from 'jquery'

import BaseComponent from 'scripts/components/base-component'

const DEFAULTS = {
  activeSelectorClass: 'tab-selector-active',
  activeTabClass: 'tab-active'
}

class Tabs extends BaseComponent {
  static DOMEvents = {
    'click [data-tabs-nav] [data-tab-selector]': 'onTabSelectorClick'
  }

  constructor ($element, options) {
    super($element)

    this.options = $.extend({}, DEFAULTS, options)
  }

  init () {
    this.setupElements()
    this.setFirstTimeIndex()
  }

  setupElements () {
    this.$tabs = this.$element.find('[data-tab]')
    this.$selectors = this.$element.find('[data-tab-selector]')
  }

  setFirstTimeIndex () {
    const { activeTabClass } = this.options

    this.activeTabIndex = this.$tabs.filter(`.${activeTabClass}`).data('tab')
  }

  onTabSelectorClick (event) {
    const tabIndex = $(event.target).closest('[data-tab-selector]').data('tabSelector')

    this.setActiveTab(tabIndex)
  }

  setActiveTab (index) {
    if (this.isIndexValid(index)) {
      const { activeTabClass, activeSelectorClass } = this.options

      this.updateActive(index, this.filterTabByIndex, activeTabClass)
      this.updateActive(index, this.filterSelectorByIndex, activeSelectorClass)

      this.activeTabIndex = index
    }
  }

  isIndexValid (index) {
    return index !== this.activeTabIndex && this.filterTabByIndex(index).length
  }

  filterTabByIndex (index) {
    return this.$tabs.filter(`[data-tab="${index}"]`)
  }

  filterSelectorByIndex (index) {
    return this.$selectors.filter(`[data-tab-selector="${index}"]`)
  }

  updateActive (nextIndex, filterFunction, activeClass) {
    filterFunction.call(this, this.activeTabIndex).removeClass(activeClass)
    filterFunction.call(this, nextIndex).addClass(activeClass)
  }
}

export default ($el) => new Tabs($el, $el.data()).init()

export { Tabs as Component }
