import styled from '@emotion/styled'
import { css } from '@emotion/react'

const wrapperStyles = ({ theme }) => css`
  label: wrapper;

  svg {
    width: ${theme.iconSizes.kilo};
    margin-top: 1px;
  }
`

export const Wrapper = styled('div')(wrapperStyles)
