import type { SVGAttributes } from 'react'
import { forwardRef } from 'react'

const AddressEmptyIcon = forwardRef<SVGSVGElement, SVGAttributes<SVGSVGElement>>((props, ref) => (
  <svg ref={ref} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 180 180" {...props}>
    <defs>
      <clipPath id="clippath">
        <path
          d="M135.52 83.11c-1.17 0-2.31-.16-3.11-1.31-1.58-2.26-3.98-4.29-6.68-1.99-1.56 1.32-.39 4.05-1.73 5.36-1.18 1.16-2.69.97-3.3 2.89-.89 2.83 1.92 5.31 2.82 7.76.76 2.05-.95 2.84-1.71 4.44-.59 1.23-.29 2.56.36 3.73 1.13 2.08 3.64 3.75 3.06 6.45-.49 2.25-.94 4.25 1.05 5.95 1.36 1.17 3.88 2.23 3.69 4.39-.07.76-.6 1.42-.74 2.17-.23 1.19.54 2.35 1.44 3.17 2.04 1.86 3.84 3.62 5.37 5.97.65 1 3.27 3.26 2.22 4.63 1.9-2.49 3.91-4.9 5.64-7.52 1.42-2.15 3.56-5.3 2.1-7.95-.45-.82-1.3-1.44-1.53-2.35-.42-1.65 1.2-3.17 1.8-4.58.94-2.2.25-4.76-1.41-6.41-.79-.78-1.64-1.64-1.76-2.74-.13-1.2.63-2.33.97-3.44.32-1.02.51-2.11.63-3.18.08-.75.11-1.52-.06-2.26-.34-1.4-1.59-2.17-2.53-3.17-2.22-2.36-1.05-5.14-2.02-7.88-.87-2.46-2.76-2.13-4.57-2.14Z"
          className="cls-2"
        />
      </clipPath>
      <clipPath id="clippath-1">
        <path
          d="M149.93 100.32c.85.16 1.7.21 2.44-.52 1.46-1.42 3.48-2.56 5.12-.52.95 1.18-.28 2.99.51 4.13.7 1 1.82 1.07 1.99 2.55.25 2.18-2.13 3.59-3.12 5.24-.83 1.38.29 2.19.63 3.45.26.98-.15 1.89-.78 2.66-1.11 1.35-3.16 2.22-3.11 4.25.04 1.7.09 3.21-1.58 4.17-1.15.66-3.12 1.08-3.28 2.68-.06.56.24 1.11.24 1.68 0 .9-.72 1.63-1.48 2.1-1.74 1.07-3.28 2.1-4.72 3.58-.61.64-2.82 1.91-2.26 3.05-1.03-2.07-2.16-4.1-3.05-6.24-.73-1.76-1.85-4.34-.43-6.06.44-.54 1.14-.86 1.43-1.49.53-1.14-.43-2.47-.67-3.57-.38-1.73.48-3.48 1.91-4.45.68-.46 1.42-.96 1.65-1.75.26-.86-.13-1.78-.23-2.63-.09-.79-.08-1.6-.02-2.39.04-.56.13-1.12.36-1.63.44-.97 1.46-1.36 2.27-1.95 1.94-1.41 1.47-3.58 2.56-5.44.97-1.67 2.3-1.16 3.61-.92Z"
          className="cls-11"
        />
      </clipPath>
      <style>
        {
          '.cls-2{fill:#1d7e8e}.cls-3{fill:#ef5132}.cls-10,.cls-11,.cls-12,.cls-13,.cls-15,.cls-17,.cls-18,.cls-19,.cls-2,.cls-20,.cls-21,.cls-22,.cls-3,.cls-6,.cls-7,.cls-8{stroke-width:0}.cls-24{stroke:#1d7e8e}.cls-24,.cls-25,.cls-27{fill:none}.cls-24,.cls-25{stroke-width:5px}.cls-24,.cls-25,.cls-27{stroke-miterlimit:10}.cls-6{fill:#d5eff7}.cls-11{fill:#00a09d}.cls-7{fill:#f8a17a}.cls-25{stroke:#00a09d}.cls-8{fill:#b8d1ce}.cls-10{fill:#d74034}.cls-12{fill:#061f33}.cls-13{fill:#94624f}.cls-15{fill:#e5785c}.cls-17{fill:#66bbc4}.cls-27{stroke:#151614}.cls-18{fill:#66a5af}.cls-19{fill:#8ed2cc}.cls-20{fill:#9d786a}.cls-21{fill:#73b8d4}.cls-22{fill:#0b2d4e}'
        }
      </style>
    </defs>
    <g id="Layer_2" data-name="Layer 2">
      <circle
        cx={89.19}
        cy={97.27}
        r={63.33}
        style={{
          fill: '#ebf6f1',
          strokeWidth: 0
        }}
      />
      <path
        d="M0 155.84h180V180H0z"
        style={{
          fill: '#cfaf9b',
          strokeWidth: 0
        }}
      />
      <path
        d="M135.52 83.11c-1.17 0-2.31-.16-3.11-1.31-1.58-2.26-3.98-4.29-6.68-1.99-1.56 1.32-.39 4.05-1.73 5.36-1.18 1.16-2.69.97-3.3 2.89-.89 2.83 1.92 5.31 2.82 7.76.76 2.05-.95 2.84-1.71 4.44-.59 1.23-.29 2.56.36 3.73 1.13 2.08 3.64 3.75 3.06 6.45-.49 2.25-.94 4.25 1.05 5.95 1.36 1.17 3.88 2.23 3.69 4.39-.07.76-.6 1.42-.74 2.17-.23 1.19.54 2.35 1.44 3.17 2.04 1.86 3.84 3.62 5.37 5.97.65 1 3.27 3.26 2.22 4.63 1.9-2.49 3.91-4.9 5.64-7.52 1.42-2.15 3.56-5.3 2.1-7.95-.45-.82-1.3-1.44-1.53-2.35-.42-1.65 1.2-3.17 1.8-4.58.94-2.2.25-4.76-1.41-6.41-.79-.78-1.64-1.64-1.76-2.74-.13-1.2.63-2.33.97-3.44.32-1.02.51-2.11.63-3.18.08-.75.11-1.52-.06-2.26-.34-1.4-1.59-2.17-2.53-3.17-2.22-2.36-1.05-5.14-2.02-7.88-.87-2.46-2.76-2.13-4.57-2.14Z"
        style={{
          fill: '#1d7e8e'
        }}
      />
      <g
        style={{
          clipPath: 'url(#clippath)'
        }}
      >
        <path
          d="M138.49 139.54c.82-9.44.07-18.95-1.68-28.25-1.66-8.87-3.85-17.77-7.16-26.17-1.09-2.78-2.32-5.5-3.72-8.14"
          className="cls-25"
        />
        <path
          d="M118.49 89.63c4.65 1.64 9.08 3.89 13.15 6.67 1.03.7 1.61.92 2.47 1.83M136.31 108.23a39.11 39.11 0 0 0-14.76-5.7M138.07 120.41c-4.15-3.6-9.21-6.14-14.57-7.31M133.87 97.81c.32-4.99 1.57-9.93 3.65-14.48M136.25 107.82c2.11-4.51 5.15-8.58 8.89-11.88M138.35 120.76c.29-1.39.8-2.74 1.51-3.98.82-1.44 1.9-2.71 2.99-3.96.79-.9 1.59-1.78 2.4-2.65"
          className="cls-25"
        />
      </g>
      <path
        d="M149.93 100.32c.85.16 1.7.21 2.44-.52 1.46-1.42 3.48-2.56 5.12-.52.95 1.18-.28 2.99.51 4.13.7 1 1.82 1.07 1.99 2.55.25 2.18-2.13 3.59-3.12 5.24-.83 1.38.29 2.19.63 3.45.26.98-.15 1.89-.78 2.66-1.11 1.35-3.16 2.22-3.11 4.25.04 1.7.09 3.21-1.58 4.17-1.15.66-3.12 1.08-3.28 2.68-.06.56.24 1.11.24 1.68 0 .9-.72 1.63-1.48 2.1-1.74 1.07-3.28 2.1-4.72 3.58-.61.64-2.82 1.91-2.26 3.05-1.03-2.07-2.16-4.1-3.05-6.24-.73-1.76-1.85-4.34-.43-6.06.44-.54 1.14-.86 1.43-1.49.53-1.14-.43-2.47-.67-3.57-.38-1.73.48-3.48 1.91-4.45.68-.46 1.42-.96 1.65-1.75.26-.86-.13-1.78-.23-2.63-.09-.79-.08-1.6-.02-2.39.04-.56.13-1.12.36-1.63.44-.97 1.46-1.36 2.27-1.95 1.94-1.41 1.47-3.58 2.56-5.44.97-1.67 2.3-1.16 3.61-.92Z"
        style={{
          fill: '#00a09d'
        }}
      />
      <g
        style={{
          clipPath: 'url(#clippath-1)'
        }}
      >
        <path
          d="M139.96 140.84c.71-6.96 2.57-13.76 5.13-20.26 2.43-6.2 5.25-12.36 8.81-18 1.18-1.86 2.45-3.67 3.82-5.39"
          className="cls-24"
        />
        <path
          d="M161.38 107.4c-3.6.55-7.12 1.56-10.46 3.02-.85.37-1.29.45-2.04.98M145.88 118.43c3.63-1.47 7.57-2.19 11.49-2.09M142.92 127.02c3.51-2.03 7.53-3.18 11.58-3.29M149.09 111.21c.46-3.67.24-7.42-.64-11.01M145.98 118.14c-.91-3.56-2.55-6.94-4.8-9.85M142.67 127.23c-.02-1.05-.2-2.1-.54-3.09-.4-1.16-1-2.23-1.62-3.28-.45-.76-.91-1.51-1.38-2.26"
          className="cls-24"
        />
      </g>
      <path
        d="M131.68 138.42h17.41l-3.06 17.42h-11.29l-3.06-17.42z"
        className="cls-20"
      />
      <path d="M151.22 138.42h-21.66v-4.89h21.66z" className="cls-13" />
      <path
        d="M148.68 140.73h-16.59l-.41-2.31h17.41l-.41 2.31z"
        style={{
          fill: '#231f20',
          opacity: 0.1,
          strokeWidth: 0
        }}
      />
      <path
        d="M21.45 145.28h24.13v2.2H21.45zM23.3 109.99h20.81v3.57H23.3z"
        className="cls-13"
      />
      <path d="M17.23 107.56h32.93v2.43H17.23z" className="cls-20" />
      <path
        d="M49.23 155.9H46.6l-5.11-42.34h2.63l5.11 42.34zM18.17 155.9h2.63l5.11-42.34h-2.63l-5.11 42.34z"
        className="cls-13"
      />
      <path d="M32.77 109.99h1.87v45.91h-1.87z" className="cls-20" />
      <path d="m35.35 92.64.01-1.25" className="cls-27" />
      <path d="M32.99 92.75h4.76v13.59h-4.76z" className="cls-10" />
      <path d="M37.74 92.75h-4.75l.39-.44h3.96l.4.44z" className="cls-3" />
      <path d="m41.49 94.91.01-1.25" className="cls-27" />
      <path d="M39.12 95.02h4.76v11.32h-4.76z" className="cls-10" />
      <path d="M43.88 95.02h-4.76l.4-.44h3.96l.4.44z" className="cls-3" />
      <path
        d="M31.39 106.34h13.99c0 .65-.53 1.18-1.18 1.18H32.56c-.65 0-1.18-.53-1.18-1.18Z"
        className="cls-22"
      />
      <path
        d="M21.8 94.62h9.02v8.41a4.511 4.511 0 0 1-9.02 0v-8.41Z"
        className="cls-11"
      />
      <path
        d="M31.45 97.54c-2.16 0-3.92 1.78-3.92 3.98s1.75 3.98 3.92 3.98 3.92-1.78 3.92-3.98-1.75-3.98-3.92-3.98Zm0 6.54c-1.39 0-2.52-1.15-2.52-2.56s1.13-2.56 2.52-2.56 2.52 1.15 2.52 2.56-1.13 2.56-2.52 2.56Z"
        className="cls-11"
      />
      <path
        d="M23.15 102.64a.49.49 0 0 1-.49-.49v-6.29a.49.49 0 0 1 .98 0v6.29c0 .27-.22.49-.49.49Z"
        style={{
          fill: '#fff',
          opacity: 0.2,
          strokeWidth: 0
        }}
      />
      <path
        d="M28.74 94.62v10.64a2.29 2.29 0 0 1-2.29 2.29h2.08a2.29 2.29 0 0 0 2.29-2.29V94.62h-2.08Z"
        className="cls-2"
      />
      <path
        d="M72.37 139.75h32.6c9.04 0 16.34-7.4 16.21-16.45l.02-45.32c-.13-8.86-7.35-15.97-16.21-15.97H73.03c-8.86 0-16.08 7.11-16.21 15.97l-.66 45.32c-.13 9.04 7.16 16.45 16.21 16.45Z"
        className="cls-17"
      />
      <path
        d="M72.37 139.98h32.6c9.04 0 16.34-7.4 16.21-16.45 0 0 .12-25.84.01-31.85-14.7 20.3-49.83 20.51-64.57.23-.13 7.89-.46 31.62-.46 31.62-.13 9.04 7.16 16.45 16.21 16.45Z"
        className="cls-18"
      />
      <rect
        width={49.63}
        height={12.58}
        x={64.08}
        y={112.67}
        className="cls-17"
        rx={6.29}
        ry={6.29}
        transform="rotate(180 88.9 118.96)"
      />
      <path
        d="M65.84 112.67h46.23c.94 0 1.7.76 1.7 1.7H64.14c0-.94.76-1.7 1.7-1.7Z"
        className="cls-19"
      />
      <path
        d="M65.78 125.25h46.23c.94 0 1.7-.76 1.7-1.7H64.08c0 .94.76 1.7 1.7 1.7Z"
        className="cls-18"
      />
      <path d="M125.05 147.68H53.32v-6.35h71.73z" className="cls-13" />
      <path
        d="M117.16 149.15c0 2.25 1.82 4.07 4.07 4.07s4.07-1.82 4.07-4.07-1.82-4.07-4.07-4.07-4.07 1.82-4.07 4.07Z"
        className="cls-13"
      />
      <circle cx={121.23} cy={153.23} r={2.5} className="cls-13" />
      <circle
        cx={56.94}
        cy={149.26}
        r={4.07}
        className="cls-13"
        transform="rotate(-13.28 56.933 149.286)"
      />
      <path
        d="M54.44 153.34a2.5 2.5 0 0 0 5 0 2.5 2.5 0 0 0-5 0Z"
        className="cls-13"
      />
      <path
        d="M58.13 92.56c.19.85 3.77 16.67 3.94 17.91.17 1.24-.06 5.16-.05 4.46 0-.7-.05 1.48 1.31 1.26.68-.11 5.89-1.79 11.17-2.62 5.3-.84 10.68-.83 11.2-.92 1.04-.19 1.41-.75 1.76-2.36.35-1.61-1.99-8.11-2.36-10.22-.37-2.11-.55-10.01-.79-11.04-.32-1.37-1.64-1.8-2.76-1.64-.64.09-7.26 1.48-12.43 2.18-5.1.69-8.75.69-9.28.77-1.08.15-1.89 1.38-1.7 2.23Z"
        className="cls-11"
      />
      <path
        d="M58.22 92.55c.19.85 3.77 16.67 3.94 17.91.17 1.24-.06 5.16-.05 4.46 0-.7-.05 1.48 1.31 1.26.68-.11 5.89-1.79 11.17-2.62 5.3-.84 10.68-.83 11.2-.92 1.04-.19 1.41-.75 1.76-2.36.16-.73-.24-2.47-.75-4.35-11.11 5.67-25.43 4.32-25.58-15.68-.69.03-1.13.05-1.29.07-1.08.15-1.89 1.38-1.7 2.23Z"
        className="cls-2"
      />
      <path
        d="M51.79 100.88h7.37c2.72 0 4.93 2.21 4.93 4.93v19.66h49.63v-19.66c0-2.72 2.21-4.93 4.93-4.93h7.37c2.72 0 4.93 2.21 4.93 4.93v19.66l-1.13 14.28H47.99l-1.13-14.28v-19.66c0-2.72 2.21-4.93 4.93-4.93Z"
        className="cls-17"
      />
      <path
        d="M52.41 144.06h73.09a4.31 4.31 0 0 0 4.31-4.31h-81.7a4.31 4.31 0 0 0 4.31 4.31Z"
        className="cls-18"
      />
      <path
        d="M121.09 77.99c-.13-8.86-8.81-20.28-26.63-20.28H82.9c-17.41 0-26.07 11.32-26.07 20.28 16.95-4.43 11.94 6.92 32.07 6.92s17.04-10.05 32.19-6.92Z"
        className="cls-19"
      />
      <circle
        cx={124.71}
        cy={106.57}
        r={11.03}
        className="cls-17"
        transform="rotate(-2.42 124.69 106.602)"
      />
      <circle
        cx={124.71}
        cy={106.57}
        r={5.51}
        className="cls-19"
        transform="rotate(-64.84 124.717 106.576)"
      />
      <circle cx={53.09} cy={106.57} r={11.03} className="cls-17" />
      <circle
        cx={53.09}
        cy={106.57}
        r={5.51}
        className="cls-19"
        transform="rotate(-50.65 53.099 106.572)"
      />
      <path
        d="M87.03 71.31a1.931 1.931 0 1 0 3.862-.002 1.931 1.931 0 0 0-3.862.002Z"
        className="cls-18"
      />
      <path
        d="M112.19 152.88c-2.71-.13-5.41.31-8.09.74-.85.14-1.74.61-1.1 1.62.35.55 1.07.72 1.73.79 2.8.32 5.67-.03 8.48-.03"
        className="cls-22"
      />
      <path
        d="M132.12 149.13c.41 2.51-.77 6.14-3.51 6.88-1.11.3-2.44.19-3.58.18-1.39 0-2.78-.02-4.17-.03l-8.19-.06c-1.8-.01-3.71-.06-5.21-1.05-1.78-1.18-2.67-3.36-1.49-5.23 1.02-1.61 2.93-2.6 4.74-2.99 3.05-.66 6.21.21 9.24-.33.49-.09.97-.21 1.46-.39 2.73-.99 5.45-2.36 8.24-.74 1.34.78 2.22 2.23 2.47 3.76Z"
        className="cls-12"
      />
      <path
        d="M109.51 143.72c-.46-.48-.74-.58-1.01.11-.32.79-.53 1.65-.52 2.51-1.14.24-2.22.76-3.13 1.49-.7-.5-1.54-.81-2.37-1-.72-.16-.8.12-.66.77.17.8.44 1.57.71 2.35.17.48.32.97.48 1.46.12.5.36.98.64 1.34.8.99 2.38 1.57 3.61 1.73 1.39.18 2.71-.17 3.65-1.23.84-.95 1.41-2.24 1.63-3.49.12-.71.07-1.46-.18-2.15-.28-.77-.83-1.33-1.31-1.97-.49-.66-.97-1.32-1.53-1.92ZM130.07 147.13c2.62 2.49 2.35 5.37.9 7.91-1.61 2.82-4.25 4.73-7.2 5.97-3.48 1.47-7.47 2.22-11.19 1.34-.75-.18-1.57-.54-1.79-1.28-.65-2.18 2.75-2.14 3.97-2.25 2.5-.21 5.14-.24 7.57-1.05 2.66-.89 4.56-2.79 5.89-5.22"
        className="cls-22"
      />
      <path
        d="M131.96 151.15c0-1.17-.41-2.34-1.14-3.26-1.85-2.33-5.46-2.4-7.95-1.14-2.85 1.45-3.3 5.29-2.19 7.99.05.12-1 .09-1.09.09-.75.05-2.31-.09-2.61.83-.2.62.21 1.45.83 1.66.26.08.54.08.81.08h5.86c1.53 0 2.68-.28 3.6-1.58.48-.68.84-1.44 1.34-2.09"
        className="cls-22"
      />
      <path
        d="M109.22 152.01c.11.29-.07.62-.4.75s-.69 0-.8-.28c-.11-.29.07-.62.4-.75s.69 0 .8.28Z"
        className="cls-12"
      />
      <path
        d="M111.97 146.78c.09.17.21.32.32.48a1.35 1.35 0 0 1 .24.55c.05.19.07.39.1.58.03.19.05.39.06.59.02.39 0 .79-.07 1.18-.06.39-.17.77-.31 1.15-.15.37-.32.73-.54 1.06-.22.33-.46.65-.74.92-.28.28-.59.53-.93.73-.17.1-.35.19-.54.24-.19.05-.39.07-.59.03.39 0 .74-.19 1.03-.42.15-.11.29-.24.43-.37.07-.07.14-.13.2-.2.07-.07.13-.14.19-.21.24-.29.47-.59.66-.91.19-.32.36-.66.49-1.01.27-.7.42-1.44.43-2.2 0-.19 0-.37-.01-.57 0-.19-.02-.38-.04-.57-.01-.09-.02-.19-.05-.27-.02-.09-.06-.17-.1-.26-.08-.17-.18-.35-.23-.53Z"
        className="cls-21"
      />
      <path
        d="M100.39 114.71c-.74.36-1.52.66-2.29.93a48.522 48.522 0 0 1-10.7 2.43c-3.81.42-7.84.34-11.22-1.48-5.59-3.02-4.91-8.32-4.9-13.73 0-.84.68-1.51 1.51-1.51h25.07c1.35 0 2.85-.19 4.18 0 .76.11 1.39.16 1.73 1.02.24.6.09 1.93.2 2.62.4 2.55.97 5.78-.94 7.84-.74.8-1.66 1.4-2.65 1.89Z"
        className="cls-22"
      />
      <path
        d="M75.06 97.46c-.11-.25-.22-.5-.34-.76a37.687 37.687 0 0 1-2.66-8.55c-.2-1.06-.39-2.22-1.2-2.93-1.12-.98-3.31-.86-4.69-.67-.65.09-1.33.31-1.72.84-.65.87-.58 2.83-.61 3.88-.16 6.36 4.88 11.63 8.97 15.92.79.83 1.65 1.66 2.74 2 1.09.33 2.48-.01 2.96-1.05.35-.76.14-1.66-.14-2.45-.48-1.36-1.2-2.61-2.01-3.79-.55-.8-.92-1.58-1.29-2.42ZM99.79 97.46c.11-.25.22-.5.34-.76 1.22-2.73 2.12-5.61 2.66-8.55.2-1.06.39-2.22 1.2-2.93 1.12-.98 3.31-.86 4.69-.67.65.09 1.33.31 1.72.84.65.87.58 2.83.61 3.88.16 6.36-4.88 11.63-8.97 15.92-.79.83-1.65 1.66-2.74 2-1.09.33-2.48-.01-2.96-1.05-.35-.76-.14-1.66.14-2.45.48-1.36 1.2-2.61 2.01-3.79.55-.8.92-1.58 1.29-2.42Z"
        className="cls-7"
      />
      <path
        d="M67.47 76.94c-.39.98-.77 1.97-1.13 2.96-1.26 3.39-4.27 9.15-.88 12.27.38.35 1.31.33 1.78.24 1.36-.27 2.05-1.54 2.72-2.62 2-3.24 3.46-6.79 5.39-10.08 1.39-2.37 2.1-5.2 2.77-7.84.44-1.75 1.01-4.25-.46-5.7-.6-.59-1.47-.89-2.31-.79-.5.06-.98.25-1.44.47-.39.19-.77.41-1.1.69-.82.7-1.21 1.71-1.7 2.64-.57 1.09-1.16 2.18-1.7 3.29-.7 1.46-1.34 2.95-1.94 4.46Z"
        className="cls-7"
      />
      <path
        d="M69 71.59c1.87-3.43 4.31-6.52 8.06-7.78 1.07-.36 2.4-.45 3.21.35.5.5.69 1.25.65 1.96-.06 1.03-.53 2-.76 3-.63 2.65-1.59 5.24-2.86 7.65-.27.52-2.27 6.18-2.11 6.32-1.32-1.14-3.2-2.57-4.77-3.32-.42-.2-4.24-2.17-4.35-1.92.89-2 1.81-4.19 2.94-6.27Z"
        className="cls-10"
      />
      <path
        d="M107.81 76.94c.39.98.77 1.97 1.13 2.96 1.26 3.39 4.27 9.15.88 12.27-.38.35-1.31.33-1.78.24-1.36-.27-2.05-1.54-2.72-2.62-2-3.24-3.46-6.79-5.39-10.08-1.39-2.37-2.1-5.2-2.77-7.84-.44-1.75-1.01-4.25.46-5.7.6-.59 1.47-.89 2.31-.79.5.06.98.25 1.44.47.39.19.77.41 1.1.69.82.7 1.21 1.71 1.7 2.64.57 1.09 1.16 2.18 1.7 3.29.7 1.46 1.34 2.95 1.94 4.46Z"
        className="cls-7"
      />
      <path
        d="M106.27 71.59c-1.87-3.43-4.31-6.52-8.06-7.78-1.07-.36-2.4-.45-3.21.35-.5.5-.69 1.25-.65 1.96.06 1.03.53 2 .76 3 .63 2.65 1.59 5.24 2.86 7.65.27.52 2.27 6.18 2.11 6.32 1.32-1.14 3.2-2.57 4.77-3.32.42-.2 4.24-2.17 4.35-1.92-.89-2-1.81-4.19-2.94-6.27Z"
        className="cls-10"
      />
      <path
        d="M100.94 97.95c-.02-.1-.05-.2-.07-.31-.88-3.83-1.01-7.79-.9-11.7.11-3.84.96-7.62 1.07-11.46.13-4.43-.64-10.72-6.19-11.24-2.29-.21-4.6-.46-6.91-.49h-.5c-2.31.03-4.62.28-6.91.49-5.54.51-6.32 6.81-6.19 11.24.11 3.84.97 7.62 1.07 11.46.11 3.92-.02 7.87-.9 11.7-.02.1-.05.2-.07.31-.17.72-.45 1.45-.32 2.18.28 1.57 4.08 2.34 5.36 2.68 2.38.63 4.85.91 7.3 1.1.22.02.44.03.66.05v.03c.08 0 .17-.01.25-.02.08 0 .17.01.25.02v-.03c.22-.02.44-.03.66-.05 2.45-.18 4.92-.46 7.3-1.1 1.28-.34 5.07-1.1 5.36-2.68.13-.73-.15-1.46-.32-2.18Z"
        className="cls-3"
      />
      <path
        d="M84.13 66.94c3.42 2.26 10.09.51 8.2-3.97-.23-.54-.51-1.07-.66-1.64-.25-.94-.23-1.91-.2-2.87.03-1.12.04-2.24.1-3.36-1.25-.23-2.27-.35-3.6-.3-1.1.04-2.2-.06-3.3 0-.09 0-.18.01-.25.06-.06.05-.07.13-.08.21-.15 1.51 0 3.04-.12 4.55-.06.85-.18 1.81-.57 2.6-.59 1.21-1.48 2.33-.63 3.64.28.43.66.79 1.1 1.08Z"
        className="cls-7"
      />
      <path
        d="M91.62 61.13c-.2-.88-.15-1.79-.12-2.68.03-1.12.04-2.24.1-3.36-1.25-.23-2.27-.35-3.6-.3-1.1.04-2.2-.06-3.3 0-.09 0-.18.01-.25.06-.06.05-.07.13-.08.21-.15 1.51 0 3.04-.12 4.55-.03.43-.09.87-.18 1.29.17.13.35.26.53.38 1.36.89 3.19 1.18 4.81.78.83-.21 1.54-.51 2.21-.93Z"
        className="cls-15"
      />
      <path
        d="m69.28 83.61-.31.74h37.23l-.37-.83-36.55.09z"
        className="cls-8"
      />
      <path
        d="M103.63 107.88H71.52l-2.3-23.9c-.04-.43.29-.8.71-.8h35.28c.42 0 .76.37.71.8l-2.3 23.9Z"
        className="cls-8"
      />
      <path
        d="M103.88 108.56H71.27l-2.34-23.9c-.04-.43.29-.8.73-.8h35.83c.43 0 .77.37.73.8l-2.34 23.9Z"
        style={{
          fill: '#fff',
          strokeWidth: 0
        }}
      />
      <path
        d="M71.27 108.56h32.59v1.45H71.27zM89.84 96.2c0 1.25-1.01 2.26-2.26 2.26s-2.26-1.01-2.26-2.26 1.01-2.26 2.26-2.26 2.26 1.01 2.26 2.26Z"
        className="cls-8"
      />
      <path
        d="M85.8 113.79c-1.18.42-2.37.83-3.56 1.21-1.21.39-8.18 1.51-6.8 4.19.27.53.93.7 1.51.81 3.35.62 6.77.34 10.15-.07"
        className="cls-7"
      />
      <path
        d="M80.76 120.47c-1.44.41-2.99.62-4.34.93-.61.14-1.22.28-1.84.38-.98.16-1.97.21-2.96.26-.72.04-1.44.08-2.16.11-.39.02-.8.04-1.16-.13-.43-.2-.7-.61-.95-1.01-.5-.79-1.09-1.53-1.51-2.36-.27-.55-.56-1.08-.26-1.68.15-.29.38-.54.62-.76.31-.29.63-.57 1-.77 1.21-.69 2.48-.39 3.76-.13 1.35.27 2.71.59 4.09.67 1.49.09 2.97-.1 4.45-.25"
        className="cls-6"
      />
      <path
        d="M75.7 122.31c.23-.02.46-.03.68-.04.54-.03 1.21-.1 1.62-.48.06-.06.12-.13.16-.21.08-.2-.03-.44-.2-.57-.17-.13-.4-.18-.61-.21.25.04.63-.89.67-1.06.12-.47.17-1.02.13-1.5-.04-.46-.16-.89-.46-1.24-.15-.18-.69-.73-.97-.65.24-.07.36-.37.29-.61s-.29-.41-.53-.49c-.24-.08-.5-.08-.75-.07-1.32.02-2.66.11-3.97-.15a12.31 12.31 0 0 0-3.43-.2c-.88.07-1.75.31-2.56.66-.22.1-.43.22-.63.36-2.65 1.89-.66 5.22 1.65 6.32 1.71.81 3.47.59 5.28.43 1.21-.11 2.41-.2 3.62-.29Z"
        className="cls-10"
      />
      <path
        d="M93.77 56.26c-1.04 1.25-2.49 2.08-3.93 2.83-.35.19-.71.37-1.1.46-1.19.28-2.4-.37-3.39-1.08-1.37-.99-2.62-2.15-3.69-3.46-1.08-1.31-1.69-2.75-2.39-4.3-.71-1.56-.87-3.12-.72-4.82.15-1.75-.82-3.31-.38-5.08.28-1.12.96-2.14 1.9-2.82.51-.37 1.1-.65 1.73-.72 1.41-.15 2.61.8 4 .87 1.4.07 2.75-.45 4.1-.75 1.18-.27 2.47-.42 3.64-.02 1.3.45 1.93 1.44 2.65 2.59.62.99 1.54 1.72 2.28 2.6.99 1.17 1.3 2.66.99 4.19-.27 1.29-.77 2.61-1.42 3.76-.57 1-1.19 2.05-1.87 2.99-.51.72-1.07 1.25-1.67 1.88-.26.27-.48.58-.72.87Z"
        className="cls-22"
      />
      <path
        d="M95.04 50.27c-.02-1.43-.23-2.79-.61-3.92-1.64-4.86-7.73-6-11.36-2.47-4.04 3.94-2.39 13.25 1.94 16.48a4.84 4.84 0 0 0 4.12.77c4.21-1.17 5.98-6.39 5.91-10.87Z"
        className="cls-7"
      />
      <path
        d="M96.04 46.33c-1.84-4.85-8.72-5.99-12.81-2.45-.96.83-1.98 2.13-2.42 3.32-.48 1.28-.35 2.69-.22 4.04.1-1.12.63-2.13 1.36-2.96.36-.41.77-.78 1.2-1.13.15-.13 1.32-1.18 1.2-1.33.41.54.82 1.07 1.46 1.34.8.34 1.62.53 2.47.72 1.49.32 2.65.74 3.73 1.85 1 1.03 1.72 2.31 2.2 3.65.15.42.41 1.08.97.89.13-.05 1.28-.9 1.29-.96.2-1.03.29-2.07.27-3.08-.02-1.43-.26-2.79-.69-3.92Z"
        className="cls-15"
      />
      <path
        d="M92.75 49.3c.37.61.6 1.29.82 1.96.35 1.07.63 2.21 1.09 3.23 1.55-.93 2.56-3.13 3.27-4.72.62-1.41.8-2.53.27-4.01-.34-.96-1-1.81-1.78-2.54-2.25-2.1-5.61-3.18-8.86-2.96-1.14.08-2.07.48-3.14.79-.98.29-1.74.47-2.46 1.28-.64.72-1.06 1.62-1.38 2.53-.53 1.48-.83 3.01-.71 4.59.07 1.01.08 2.01 1.08 2.55-.02-.6-.03-1.2-.05-1.8 0-.29-.02-.58.03-.86.04-.25.13-.5.22-.74.27-.74.51-1.32 1.02-1.93.66-.79 1.53-1.51 1.72-2.52 1.56 1.52 3.41 2.47 5.46 3.18 1.3.45 2.63.71 3.4 1.95 0 0 .01.02.02.02Z"
        className="cls-22"
      />
      <path
        d="M95.07 50.4c1.86-1.04 2.46 1.54 2.15 2.83-.13.53-.33 1.05-.66 1.5-.44.59-1.7 1.58-2.44.95-.34-.29-.37-.79-.39-1.23-.04-1.19.08-3.34 1.34-4.05ZM81.63 50.4c-1.86-1.04-2.46 1.54-2.15 2.83.13.53.33 1.05.66 1.5.44.59 1.7 1.58 2.44.95.34-.29.37-.79.39-1.23.04-1.19-.08-3.34-1.34-4.05Z"
        className="cls-7"
      />
      <path
        d="M78.98 46.19c-.02-.76-.52-1.4-.9-2.06-.6-1.02-.82-2.23-.69-3.41.15-1.37.98-2.65 2.17-3.35.87-.51 1.93-.69 2.93-.4.92.26 1.66.96 2.55 1.21 1.99.55 4.04-1.08 5.93-1.47 1.88-.39 3.89.24 4.82 2.13.37.74.53 1.61 1.02 2.27.81 1.12 2.36 1.48 3.01 2.71 1.05 2.01-1.04 4.94-2.1 6.5"
        style={{
          stroke: '#0b2d4e',
          strokeWidth: 3,
          strokeMiterlimit: 10,
          fill: 'none'
        }}
      />
      <path
        d="M83.69 40.26c1.55.1 3.05-.23 4.57-.54.76-.14 1.54-.21 2.31-.23.39-.01.78 0 1.16 0 .39.02.78.04 1.17.12.78.16 1.51.58 2 1.24.24.34.31.77.34 1.15.03.39.02.77.1 1.13.07.37.26.69.49.99.23.3.49.6.66.96.17.36.25.75.27 1.14.02.39 0 .77-.04 1.16 0-.38.01-.77-.03-1.15-.04-.38-.13-.76-.31-1.08-.18-.33-.44-.61-.68-.92-.24-.3-.47-.65-.55-1.05-.1-.39-.1-.79-.12-1.16-.03-.37-.1-.74-.31-1.04a2 2 0 0 0-.37-.4c-.14-.12-.29-.23-.45-.33a3.19 3.19 0 0 0-1.04-.39c-.36-.08-.74-.1-1.13-.12-.38-.02-.76-.02-1.15-.02-.76.01-1.53.09-2.29.2-.75.12-1.52.28-2.28.35-.77.07-1.55.09-2.31 0Z"
        className="cls-21"
      />
      <path
        d="M84.37 120.67c1.58-.19 6.32-.91 7.88-1.08 7.83-.84 15.57-2.12 22.83-5.35 1.67-.75 3.44-1.97 4.37-3.57 2.46-4.21-1.72-8.54-6.1-7.56-7.32 1.64-13.3 4.69-20.37 7.19-1.18.42-3.3 1.31-4.5 1.76-1.03.38-4.47 1.41-5.51 1.78"
        className="cls-22"
      />
      <path
        d="M87.31 136.07c.38 1.16.78 2.31 1.2 3.44.43 1.16 3.74 7.15.86 7.7-.57.11-1.09-.29-1.52-.67-2.48-2.19-4.3-4.97-6.02-7.8"
        className="cls-7"
      />
      <path
        d="M85.23 143.91c.54 1.35 1.31 2.66 1.88 3.87.26.55.51 1.1.81 1.63.47.84 1.02 1.63 1.57 2.42.4.57.8 1.15 1.2 1.72.22.31.45.64.79.81.4.21.89.17 1.34.12.9-.09 1.82-.08 2.71-.26.58-.12 1.16-.22 1.44-.81.14-.29.18-.61.22-.93.04-.41.06-.82 0-1.22-.19-1.33-1.19-2.13-2.15-2.95-1.02-.87-2.07-1.72-2.96-2.72-.96-1.08-1.7-2.33-2.47-3.55"
        className="cls-6"
      />
      <path
        d="M86.84 148.87c-.12-.18-.25-.37-.38-.55-.3-.43-.65-.98-.6-1.53 0-.08.02-.17.07-.25.11-.18.36-.24.56-.18.2.06.37.2.52.34-.18-.17.31-1.01.41-1.15.29-.37.67-.74 1.07-.99.37-.24.78-.41 1.22-.39.23.01.97.09 1.07.35-.09-.23.07-.5.29-.59.23-.09.49-.02.69.11s.36.33.5.53c.77 1.02 1.51 2.1 2.48 2.95.86.75 1.56 1.54 2.2 2.5.47.72.81 1.52 1.02 2.35.06.23.09.46.1.7.13 3.16-3.6 3.63-5.81 2.51-1.64-.83-2.52-2.3-3.48-3.78-.64-.99-1.28-1.96-1.94-2.94Z"
        className="cls-10"
      />
      <path
        d="M82.91 141.27c-.8-1.32-3.08-5.37-3.88-6.66-4.03-6.49-7.68-13.17-9.55-20.64-.43-1.72-.55-3.8.12-5.48 1.75-4.4 7.55-3.79 9.42.14 3.12 6.57 4.37 12.97 6.67 19.86.39 1.15.97 3.31 1.34 4.49.32 1.01 1.6 4.26 1.93 5.27"
        className="cls-22"
      />
    </g>
    <path
      id="Layer_1"
      d="M0 0h180v180H0z"
      data-name="Layer 1"
      style={{
        fill: 'none',
        strokeWidth: 0
      }}
      transform="rotate(90 90 90)"
    />
  </svg>
))

export default AddressEmptyIcon
