import { Text } from '@leroy-merlin-br/backyard-react'

import { AlertMessage } from 'shared/components/AlertMessage'

const DeliveryAttemptByAddress = () => (
  <AlertMessage type="warning">
    <Text as="strong" size="kilo" isBold noMargin>
      Tentamos entregar seu pedido
    </Text>
    , mas infelizmente não foi possível acessar o local. Faremos uma nova
    tentativa no{' '}
    <Text as="strong" size="kilo" isBold noMargin>
      próximo dia útil
    </Text>
    .
  </AlertMessage>
)

export default DeliveryAttemptByAddress
