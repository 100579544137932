import { useState, Fragment } from 'react'
import PropTypes from 'prop-types'
import { TextField, Button } from '@leroy-merlin-br/backyard-react'

import Tag from 'checkout/components/Tag/Tag'

import * as S from './styled'

const ENTER_KEY = 'Enter'

const Coupon = ({
  handleCoupon,
  tagData = null,
  isDisabled = false,
  hasLoading = true,
  hasError = false,
  discountEnginePromoEnabled
}) => {
  const [currentValue, setNewValue] = useState('')

  const handleEvent = event => {
    event.preventDefault()
    handleCoupon(currentValue)
  }

  const handleKeyPress = event => {
    if (event.key === ENTER_KEY) {
      handleEvent(event)
    }
  }

  const getState = () => {
    if (!hasError && tagData) {
      return 'valid'
    }

    if (hasError && !tagData) {
      return 'invalid'
    }

    return ''
  }

  if (!discountEnginePromoEnabled) {
    return null
  }

  return (
    <Fragment>
      <S.Grid hasTag={tagData}>
        <TextField
          value={currentValue}
          label="Cupom de desconto"
          placeholder="Digite seu cupom"
          isDisabled={isDisabled}
          state={getState()}
          onKeyPress={handleKeyPress}
          onChange={({ target: { value } }) => setNewValue(value)}
        />
        <Button
          appearance="primary"
          variant="outline"
          isDisabled={isDisabled}
          isLoading={hasLoading}
          onClick={handleEvent}
        >
          Aplicar
        </Button>
      </S.Grid>
      {tagData && (
        <Tag {...tagData} onDelete={() => handleCoupon(currentValue)} />
      )}
    </Fragment>
  )
}

Coupon.propTypes = {
  handleCoupon: PropTypes.func,
  isDisabled: PropTypes.bool,
  hasLoading: PropTypes.bool,
  hasError: PropTypes.bool
}

export default Coupon
