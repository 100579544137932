import { useEffect, useState } from 'react'
import { datadogRum } from '@datadog/browser-rum'
import {
  type GetProductVariantsData,
  getProductVariants
} from 'hpdp/services/product'

import emitter from 'scripts/utils/emitter'

import { UseVariantsProps } from './types'

const useVariants = (props: UseVariantsProps) => {
  const { productId } = props

  const [variants, setVariants] = useState<GetProductVariantsData>([])

  useEffect(() => {
    ;(async () => {
      try {
        const response = await getProductVariants(productId)
        setVariants(response)

        const currentVariants = response.reduce<string[]>((acc, current) => {
          const variant = current.types.find(
            variant => variant.id === productId
          )

          if (variant) {
            acc.push(variant.label)
          }

          return acc
        }, [])

        emitter.emit('pdp:variant:onLoad', currentVariants)
      } catch (e) {
        datadogRum.addError(e)

        emitter.emit('pdp:variant:onLoad', [])
      }
    })()
  }, [productId])

  return variants
}

export default useVariants
