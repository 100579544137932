import styled from '@emotion/styled'
import { css } from '@emotion/react'

const wrapperStyles = () => css`
  label: lmcv-drawer-wrapper;

  display: flex;
  justify-content: flex-end;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1001;
  height: 100%;
  overflow: hidden;
  visibility: hidden;
  pointer-events: none;
  transition: visibility 300ms;
`

const wrapperVisibleStyles = ({ visible }) =>
  visible &&
  css`
    label: lmcv-drawer-wrapper__visible;

    visibility: visible;
    pointer-events: auto;
    transition-delay: 0s;

    ${BackDrop} {
      opacity: 1;
      pointer-events: auto;
      transition-delay: 0s;
    }

    ${Aside} {
      transform: none;
    }
  `

const backDropStyles = () => css`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
  pointer-events: none;
  background: rgba(0, 0, 0, 0.25);
  transition: opacity 300ms;
  will-change: opacity;
`

const asideStyles = ({ theme }) => css`
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  width: 100%;
  height: 90%;
  max-width: 100%;
  border-radius: 35px 35px 0 0;
  pointer-events: auto;
  flex-direction: column;
  background: ${theme.colors.white};
  transform: translate3d(0, 100%, 0);
  transition: transform 300ms;
  will-change: transform;

  ${theme.media.mega} {
    position: relative;
    width: 520px;
    height: 100%;
    border-radius: 0;
    transform: translate3d(100%, 0, 0);
    overflow-y: auto;
    overflow-x: hidden;
  }
`

const headerStyles = ({ theme }) => css`
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  border-bottom: 1px solid ${theme.colors.n300};
  margin-bottom: 24px;

  ${theme.media.mega} {
    flex-direction: row;
  }

  & > button {
    padding: 20px 10px 10px 20px;
    margin-right: 12px;

    ${theme.media.mega} {
      flex-direction: row;
      margin: 1px 0 12px;
    }

    & > svg {
      fill: ${theme.colors.p500};
    }
  }
`

const titleStyles = ({ theme }) => css`
  display: block;
  margin: 0;
  width: 100%;
  color: ${theme.colors.n800};
  font-size: ${theme.spacings.mega};
  font-weight: ${theme.font.weight.bold};
  line-height: 20px;
  text-align: left;
  padding: 20px 10px 10px 20px;

  ${theme.media.mega} {
    font-size: 18px;
    border-bottom: none;
    padding: ${theme.spacings.tera} ${theme.spacings.giga};
  }
`

const contentStyles = ({ theme }) => css`
  padding: 0 20px 20px;
  overflow-y: auto;
  overflow-x: hidden;

  ${theme.media.mega} {
    padding: 0 ${theme.spacings.giga} ${theme.spacings.tera};
    overflow: initial;
  }
`
export const Wrapper = styled('div')(wrapperStyles, wrapperVisibleStyles)
export const BackDrop = styled('div')(backDropStyles)
export const Aside = styled('aside')(asideStyles)
export const Header = styled('header')(headerStyles)
export const Title = styled('span')(titleStyles)
export const Content = styled('div')(contentStyles)
