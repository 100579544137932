function calculatePrice (packaging, price) {
  return (packaging * price).toFixed(2)
}

export const handleProducts = ({
  products,
  services,
  storeId,
  shipmentType
}) =>
  products?.map(product => {
    const resolve = {
      name: product.name,
      id: product.productId,
      price: parseFloat(
        calculatePrice(product.packaging, product.price.to)
      ).toFixed(2),
      brand: product.brand,
      category: product.categoryTree || null,
      quantity: product.packagingCount,
      dimension8: shipmentType,
      dimension9: shipmentType === 'pickup-in-store' ? storeId : '',
      dimension15: shipmentType === 'delivery' ? product.shopName : '',
      dimension31:
        services?.find(service => service.relatedProduct.id === product.id)
          ?.relatedProduct.name || null
    }

    return resolve
  })
