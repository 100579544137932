import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { styleHelpers } from '@leroy-merlin-br/backyard-react'

const sellerStyles = ({ theme }) => css`
  label: modal__seller;

  padding: ${theme.spacings.mega} 0;
  display: grid;
  grid-gap: ${theme.spacings.byte};

  &:not(:last-of-type) {
    border-bottom: 1px solid ${theme.colors.n100};
  }
`

const sellerNameStyles = ({ theme }) => css`
  label: seller__name;

  ${styleHelpers.headingKilo(theme)};
  font-weight: ${theme.font.weight.semibold};
`

export const Seller = styled('div')(sellerStyles)
export const SellerName = styled('span')(sellerNameStyles)
