import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { Theme } from 'scripts/react-components/themes/Theme'

const containerStyles = ({ theme }: Theme) => css`
  label: variants-carousel__container;

  position: relative;
  overflow: hidden;

  .swiper-slide {
    width: auto;
  }

  .swiper-container {
    z-index: ${theme.zIndex.default};
  }
`

export const Container = styled('div')(containerStyles)
