import styled from '@emotion/styled'
import { css } from '@emotion/react'

const assetsPath = window && window.env.assetHost

const sectionStyles = ({ theme }) => css`
  label: how-to;

  padding: ${theme.spacings.zetta} ${theme.spacings.giga} ${theme.spacings.giga};
  background: ${theme.colors.white};

  div[class*='Backyard-Row'] {
    div[class*='Backyard-Col'] {
      &:not(:last-of-type) {
        &:after {
          content: '';
          position: absolute;

          left: 55px;
          top: 200px;

          height: 230px;

          transform: translate(-50%, -50%);
          border-left: 4px dotted ${theme.colors.v500};
        }

        ${theme.media.kilo} {
          &:after {
            left: 90px;
            height: 200px;
          }
        }

        ${theme.media.giga} {
          &:after {
            content: unset;
          }
        }
      }

      &:nth-of-type(3) img {
        transform: scale(1.12);
        margin-left: -${theme.spacings.kilo};
        margin-right: ${theme.spacings.kilo};
      }
    }
  }

  ${theme.media.tera} {
    background-image: url(${assetsPath}assets/images/lmcv/landing-page-2022/dots.png);
    background-repeat: no-repeat;
    background-position: 46% 30%;
  }
`

const titleWrapperStyles = ({ theme }) => css`
  label: how-to__title-wrapper;

  padding: ${theme.spacings.tera} ${theme.spacings.tera} 0;

  h1 {
    font-weight: ${theme.font.weight.regular};
  }

  ${theme.media.mega} {
    padding-top: ${theme.spacings.zetta};
  }
`

export const Section = styled('section')(sectionStyles)
export const TitleWrapper = styled('div')(titleWrapperStyles)
