const HiperIcon = () => (
  <svg
    width="30"
    height="21"
    viewBox="0 0 30 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="30" height="21" rx="3.5" fill="white" />
    <path
      d="M5 7.08276V13.1284H6.3737V10.5996H8.62976V13.1284H9.99654V7.08276H8.62976V9.40776H6.3737V7.08276H5Z"
      fill="#F37421"
    />
    <path
      d="M21.8229 11.37C21.8402 11.2698 21.8679 11.066 21.8679 10.8311C21.8679 9.7463 21.3281 8.64081 19.9094 8.64081C18.3834 8.64081 17.6914 9.87067 17.6914 10.9831C17.6914 12.3546 18.5461 13.2182 20.0374 13.2182C20.6291 13.2182 21.1793 13.1284 21.6291 12.9488L21.4492 12.0264C21.0824 12.1438 20.7018 12.206 20.2347 12.206C19.598 12.206 19.0409 11.9366 18.9959 11.3735H21.8229V11.37ZM18.9821 10.4372C19.0167 10.071 19.252 9.54939 19.8264 9.54939C20.4561 9.54939 20.598 10.1056 20.598 10.4372H18.9821Z"
      fill="#F37421"
    />
    <path
      d="M22.3041 13.1285H23.6709V10.9037C23.6709 10.7966 23.6813 10.6895 23.6986 10.5997C23.7886 10.1782 24.1311 9.90876 24.6329 9.90876C24.7851 9.90876 24.9028 9.92603 24.9996 9.9433V8.66162C24.8993 8.64435 24.837 8.64435 24.7124 8.64435C24.2903 8.64435 23.7505 8.91381 23.5256 9.54947H23.491L23.446 8.74108H22.2695C22.2868 9.11764 22.3041 9.53911 22.3041 10.1851V13.1285V13.1285Z"
      fill="#F37421"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.3841 6.84088C11.7716 6.84088 12.0865 7.15526 12.0865 7.54218C12.0865 7.9291 11.7716 8.24348 11.3841 8.24348C10.9965 8.24348 10.6816 7.9291 10.6816 7.54218C10.6816 7.15526 10.9965 6.84088 11.3841 6.84088Z"
      fill="#FFE700"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.2699 12.1057H14.955C15.6436 12.1057 15.955 11.667 15.955 11.2075C15.955 10.748 15.9204 9.71164 15.0726 9.71164C14.0969 9.71164 14.2526 11.0002 14.2595 11.6739C14.256 11.819 14.2664 11.9641 14.2699 12.1057ZM10.6816 8.73742H12.0865V11.211C12.0865 11.6704 12.3425 12.1092 12.9066 12.1092C12.91 10.9968 12.9066 9.84982 12.8685 8.73742H14.0415C14.0657 8.95161 14.0899 9.1658 14.1107 9.37999C14.6643 8.27449 16.4117 8.51632 17 9.45254C17.6055 10.4164 17.8131 13.1801 14.955 13.1801H14.2906C14.2976 13.7398 14.2976 14.3029 14.2976 14.866H12.8927C12.8927 14.3271 12.8962 13.7605 12.8996 13.1801C11.3806 13.1732 10.6816 12.2128 10.6816 11.211V8.73742V8.73742Z"
      fill="#F37421"
    />
  </svg>
)

export default HiperIcon
