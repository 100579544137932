import { Fragment, useCallback } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { Icon } from '@leroy-merlin-br/backyard-react'
import { ClockOutline } from '@backyard-ui/icons'

import { Coupon as CouponBase } from 'lmcv/components'
import { formatter } from 'lmcv/containers/Coupon/helpers'
import { useCouponModal } from 'lmcv/hooks'

function Coupon ({
  id,
  type,
  thumbnail,
  shortDescription,
  remainingDays,
  isActive,
  ...props
}) {
  const {
    get: { user }
  } = useSelector(state => state.module, shallowEqual)

  const { openModal } = useCouponModal()

  const showModal = useCallback(() => {
    if (hasAccess) {
      openModal('CouponModalCommon', {
        id: id,
        category: type,
        active: isActive
      })
    }
  }, [hasAccess, id, isActive, openModal, type])

  const remainingEl = (
    <Fragment>
      <Icon as={ClockOutline} size="kilo" />
      {formatter.days(remainingDays)}
    </Fragment>
  )

  const hasAccess = user.isAuthenticated && user.isMember

  const couponProps = {
    id,
    type,
    image: thumbnail,
    description: shortDescription,
    category: 'percentage',
    footerText: {
      default: remainingEl,
      active: remainingEl
    },
    isDisabled: !hasAccess,
    isActive,
    onClickCoupon: showModal,
    ...props
  }

  return <CouponBase {...couponProps} />
}

export default Coupon
