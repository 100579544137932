import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
dayjs.extend(customParseFormat)

export const documentDateValidator = (
  value: string
): string | boolean | undefined => {
  if (!value) return

  const date = dayjs(value, 'DD/MM/YYYY', true)
  const today = dayjs()

  if (!date.isValid()) {
    return 'Data de emissão inválida'
  }

  if (date.isAfter(today)) {
    return 'Data de emissão não pode ser posterior a data atual'
  }

  return true
}
