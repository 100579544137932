import axios from 'axios'

/**
 * @param {{id:number,storeSlug:string,cancelTokenSource:import("axios").CancelTokenStatic}} param
 * @returns
 */

export const getStockRequest = ({ id, storeSlug }) => {
  const url = `/hybris/stock/${id}/${storeSlug}/real-time`
  const cancelTokenSource = axios.CancelToken.source()

  const cancelRequest = () => cancelTokenSource.cancel()

  const request = async () => {
    return await axios.get(url, {
      cancelToken: cancelTokenSource.token
    })
  }

  return { cancelRequest, request }
}
