import { FunctionComponent } from 'react'
import { Text } from '@backyard-ui/core'

import { Ellipsis } from 'scripts/react-components/shared/components'

import { Props } from './types'

const Description: FunctionComponent<Props> = ({
  text,
  hasEllipsis = false
}) => (
  <Text size="lg" color="gray-600">
    {hasEllipsis ? <Ellipsis clamp="2">{text}</Ellipsis> : text}
  </Text>
)

export default Description
