import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { Theme } from 'scripts/react-components/themes/Theme'

const popoverContentAreaStyles = ({ theme }: Theme) => css`
  label: popover-content-area;

  position: relative;
  width: 145px;
  color: ${theme.colors.n800};
  font-size: ${theme.typography.text.kilo.fontSize};
  font-weight: ${theme.font.weight.regular};
  line-height: 50%;
`

const labelButtonStyles = ({ theme }: Theme) => css`
  label: popover-tooltip-text;

  display: flex;
  justify-content: center;
  color: #070707;
  font-size: ${theme.typography.text.kilo.fontSize};
  font-weight: ${theme.font.weight.regular};
`

export const PopoverContent = styled('div')(popoverContentAreaStyles)
export const LabelPopover = styled('span')(labelButtonStyles)
