import styled from '@emotion/styled'
import { css } from '@emotion/react'

const wrapperStyles = ({ theme }) => css`
  label: wrapper;

  margin-right: ${theme.spacings.mega};
`

const titleStyles = ({ theme }) => css`
  label: title;

  margin-bottom: ${theme.spacings.giga};
  font-size: ${theme.typography.headings.mega.fontSize};
  line-height: 28px;
  font-weight: ${theme.font.weight.regular};
`

const linkStyles = ({ theme }) => css`
  label: link;

  word-wrap: break-word;
  width: 100%;
  display: list-item;
  color: ${theme.colors.black};
  padding: ${theme.spacings.byte};
  font-size: ${theme.typography.text.mega.fontSize};
  font-weight: 500;
  margin: 3px 0;
  border-bottom: 1px solid ${theme.colors.n100};

  & > strong {
    color: ${theme.colors.p500};
    display: block;
    font-weight: ${theme.font.weight.regular};
    max-width: 570px;
    word-wrap: break-word;

    :hover {
      text-decoration: underline;
    }
  }
`

export const Wrapper = styled('div')(wrapperStyles)
export const Title = styled('span')(titleStyles)
export const Link = styled('a')(linkStyles)
