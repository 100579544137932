import styled from '@emotion/styled'
import { css } from '@emotion/react'

const linkStyles = ({ theme }) => css`
  label: link;

  display: flex;
  align-items: center;

  padding: ${theme.spacings.mega} 0;

  font-weight: ${theme.font.weight.regular};

  color: ${theme.colors.white};

  svg {
    margin-left: 10px;

    width: 10px;
    height: 10px;

    stroke: ${theme.colors.white};
  }

  &:hover {
    color: ${theme.colors.p500};

    svg {
      fill: ${theme.colors.p500};
    }
  }
`

const linkIsHighLightedStyles = ({ isHighLighted, theme }) =>
  isHighLighted &&
  css`
    label: link--is-highlighted;

    background: ${theme.colors.p500};

    padding: ${theme.spacings.byte} ${theme.spacings.byte} ${theme.spacings.kilo};

    font-weight: ${theme.font.weight.regular};

    &:hover {
      color: ${theme.colors.b900};

      svg {
        stroke: ${theme.colors.b900};
        fill: ${theme.colors.b900};
      }
    }
  `

export const Link = styled('a')(linkStyles, linkIsHighLightedStyles)
