import styled from '@emotion/styled'
import { css } from '@emotion/react'

const baseStyles = ({ theme }) => css`
  label: form__block;

  box-sizing: border-box;
  padding-left: ${theme.spacings.kilo};
  padding-right: ${theme.spacings.kilo};
  width: 100%;
`

export const Wrapper = styled('div')(baseStyles)
