import * as S from './styled'

const Thanks = () => {
  return (
    <S.ThanksWrapper data-testid="thanks-wrapper">
      <S.Title>Agradecemos sua colaboração!</S.Title>
      <S.OrderedList>
        <li>
          Para solicitar uma nova ajuda, você pode fechar a conversa atual e, em
          seguida, clicar na assistente virtual novamente.
        </li>
      </S.OrderedList>
    </S.ThanksWrapper>
  )
}

export default Thanks
