import { useContext } from 'react'

import { EntryKeyContext } from '../../context/EntryKeyContext'
import * as S from './styled'

const CleanFiltersButton = ({ variant }) => {
  const {
    cleanFilters,
    query
  } = useContext(EntryKeyContext)

  const hasFiltersSelected = query => {
    const queryArray = Object.entries(query)

    return queryArray.some(item => item[1].type === 'filter')
  }

  return (
    <S.Button
      variant={variant}
      size="mega"
      appearance="primary"
      isDisabled={!hasFiltersSelected(query)}
      onClick={cleanFilters}
    >
      Limpar filtros
    </S.Button>
  )
}

export default CleanFiltersButton
