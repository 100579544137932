import { forwardRef } from 'react'
import PropTypes from 'prop-types'

import WishlistItem from './WishlistItem'
import WishlistAddButton from './WishlistAddButton'
import shapePropTypes from './shapePropTypes'

const WishlistCollection = forwardRef(
  ({ wishlists, onEdit, onCreate, onRemove }, ref) => (
    <div className="container">
      <ul className="wishlist-collection">
        <WishlistAddButton onCreate={onCreate} />
        {wishlists &&
          wishlists.map((item, index) => (
            <WishlistItem
              ref={ref}
              onEdit={onEdit}
              onRemove={onRemove}
              item={item}
              key={`item-${index}`}
            />
          ))}
      </ul>
    </div>
  )
)

WishlistCollection.propTypes = {
  wishlists: PropTypes.arrayOf(PropTypes.shape({ ...shapePropTypes }))
    .isRequired,
  onCreate: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired
}

export default WishlistCollection
