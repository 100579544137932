import { useState, useEffect } from 'react'
import Cookies from 'js-cookie'
import { datadogRum } from '@datadog/browser-rum'
import type { Hit, BaseHit } from 'instantsearch.js/es/types'
import type { ProductReviewRating } from 'hpdp/services/reviews'
import { getProductsReviewsRating } from 'hpdp/services/reviews'

const FEATURE_FLIP_NEW_REVIEWS_SECTION =
  window.env.feature.isNewReviewsSectionEnabled &&
  Cookies.get('new_reviews_ab_testing') === 'true'

interface UseRatingProps {
  hits: Array<Hit<BaseHit>>
}

export function useRating (props: UseRatingProps) {
  const { hits } = props

  const [data, setData] = useState<ProductReviewRating[]>([])
  const [isLoading, setisLoading] = useState(false)

  useEffect(() => {
    if (!FEATURE_FLIP_NEW_REVIEWS_SECTION) {
      return
    }

    const getReviews = async () => {
      const ids = hits.map(hit => Number(hit.id))

      try {
        setisLoading(true)

        const response = await getProductsReviewsRating(ids)
        setData(response)
      } catch (e) {
        datadogRum.addError(e)
      } finally {
        setisLoading(false)
      }
    }

    if (hits.length >= 1) {
      getReviews()
    }
  }, [hits])

  return {
    data,
    isLoading
  }
}
