import { Constants } from '.'

export const get = url => ({
  type: Constants.MODULE_GET,
  url
})

export const getSuccess = payload => ({
  type: Constants.MODULE_GET_SUCCESS,
  payload
})

export const getFailure = error => ({
  type: Constants.MODULE_GET_FAILURE,
  error
})
