import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { toast } from '@leroy-merlin-br/backyard-react'
import { Cashback } from '../../../types/api/cashback'
import {
  InvoiceStatus,
  InvoiceStatusData,
  invoiceStatusMap
} from '../../../types/invoice-status'
import { createCashbackService } from '../../../services/cashback.service'
import { createInvoiceService } from '../../../services/invoice.service'
import { Redeem } from '../components/Redeem'
import { StatusStepper } from '../components/StatusStepper'

export interface IRedeemrovider {
  fetchCashback: () => void
  cashback: Cashback | null | undefined
  loadingCashback: boolean

  fetchInvoiceStatus: () => void
  invoiceStatus: InvoiceStatus | undefined
  invoiceStatusData: InvoiceStatusData | null | undefined
  setInvoiceStatusData: Dispatch<
    SetStateAction<InvoiceStatusData | null | undefined>
  >

  setComponent: Dispatch<SetStateAction<CashbackRedeemComponent | undefined>>
  componentToRender: JSX.Element
}

interface RedeemroviderProps {
  children: JSX.Element | JSX.Element[]
}

type CashbackRedeemComponent = 'REDEEM' | 'STATUS_STEPPER'

const RedeemContext = createContext<IRedeemrovider | undefined>(undefined)

const RedeemProvider = (props: RedeemroviderProps) => {
  const cashbackService = createCashbackService()
  const invoiceService = createInvoiceService()

  const [component, setComponent] = useState<CashbackRedeemComponent>()

  const [invoiceStatusData, setInvoiceStatusData] =
    useState<InvoiceStatusData | null>()

  const [loadingCashback, setLoadingCashback] = useState(false)
  const [cashback, setCashback] = useState<Cashback | null>()

  const components: { [key in CashbackRedeemComponent]: JSX.Element } = {
    REDEEM: (
      <RedeemProvider>
        <Redeem />
      </RedeemProvider>
    ),
    STATUS_STEPPER: <StatusStepper />
  }

  useEffect(() => {
    fetchInvoiceStatus()
  }, [])

  useEffect(() => {
    const goToRedeem = !invoiceStatusData || invoiceStatusData?.skipStatusStep
    setComponent(goToRedeem ? 'REDEEM' : 'STATUS_STEPPER')
  }, [invoiceStatusData])

  const fetchInvoiceStatus = () => {
    invoiceService
      .getInvoiceStatus()
      .then(setInvoiceStatusData)
      .catch(console.error)
  }

  const fetchCashback = () => {
    setLoadingCashback(true)
    cashbackService
      .getCashback()
      .then(setCashback)
      .catch(() => {
        toast.critical(
          'Erro ao carregar os dados do cashback. Tente mais tarde.',
          {
            variant: 'solid'
          }
        )
      })
      .finally(() => setLoadingCashback(false))
  }

  const invoiceStatus = invoiceStatusData
    ? invoiceStatusMap.get(invoiceStatusData?.status?.label)
    : 'UNKNOWN'

  const componentToRender = components[component!]

  const returnValue = useMemo(
    () => ({
      fetchCashback,
      cashback,
      loadingCashback,

      fetchInvoiceStatus,
      invoiceStatus,
      invoiceStatusData,
      setInvoiceStatusData,

      setComponent,
      componentToRender
    }),
    [
      fetchCashback,
      cashback,

      loadingCashback,
      invoiceStatus,
      invoiceStatusData,

      componentToRender
    ]
  )

  return (
    <RedeemContext.Provider value={returnValue}>
      {props.children}
    </RedeemContext.Provider>
  )
}

export default RedeemProvider

export const useRedeemContext = () => useContext(RedeemContext)!
