import styled from '@emotion/styled'
import { css } from '@emotion/react'

const wrapperStyles = ({ theme }) => css`
  label: wrapper;

  margin-bottom: ${theme.spacings.kilo};
`

const mainStyles = () => css`
  label: main;

  overflow: hidden;
`

const imageBoxStyles = () => css`
  label: image-box;

  max-width: 400px;

  margin: 0 auto;
`

const imageStyles = () => css`
  label: image;

  display: block;
  width: 100%;
`

const termWrapperStyles = ({ theme }) => css`
  label: term-wrapper;

  & > h2 {
    font-weight: ${theme.font.weight.regular};
  }

  margin-top: ${theme.spacings.mega};

  ${theme.media.mega} {
    margin-top: ${theme.spacings.byte};
  }
`

export const Wrapper = styled('div')(wrapperStyles)
export const Main = styled('div')(mainStyles)
export const Box = styled('div')(imageBoxStyles)
export const Image = styled('img')(imageStyles)
export const TermWrapper = styled('div')(termWrapperStyles)
