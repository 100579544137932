import { Fragment } from 'react'
import {
  Card,
  Text,
  Icon,
  Inline,
  Stack,
  TextField,
  Button
} from '@leroy-merlin-br/backyard-react'
import { InfoCircleOutline } from '@backyard-ui/icons'

import { SeparatingEdge } from '../'
import { copyQRCode } from '../../utils'
import * as S from './styled'

const stepByStep = [
  'Acesse seu app de pagamentos',
  'Escolha pagar via PIX',
  'Escaneie o seguinte código'
]

const QRCodeCardMobile = ({ emvQrCode = '' }) => (
  <Card target="card" shadow="triple">
    <Stack space="mega">
      <Fragment>
        <Text color="black" size="mega" isBold>
          Copie o código QR para pagar
        </Text>

        <SeparatingEdge />
      </Fragment>

      <S.InstructionWrapper>
        {stepByStep.map((step, index) => (
          <Inline key={index} space="mega">
            <Text as="span" color="black" size="mega" isBold noMargin>
              {index + 1}
            </Text>
            <Text color="black" size="mega" noMargin>
              {step}
            </Text>
          </Inline>
        ))}
      </S.InstructionWrapper>

      <TextField name="qr-code" size="kilo" value={emvQrCode} readOnly />

      <S.ButtonsWrapper>
        <Button
          size="mega"
          onClick={copyQRCode}
          isDisabled={!emvQrCode}
          isStretch
        >
          Copiar código
        </Button>

        <Button
          as="a"
          size="mega"
          variant="ghost"
          href="/meus-pedidos"
          isStretch
        >
          Meus pedidos
        </Button>
      </S.ButtonsWrapper>

      <SeparatingEdge />

      <Inline space="byte" alignY="center">
        <Icon as={InfoCircleOutline} size="kilo" />
        <Fragment>
          <Text as="span" color="black" size="kilo" noMargin>
            Seu pedido já foi registrado pela Leroy Merlin e{' '}
          </Text>
          <Text as="span" color="black" size="kilo" isBold noMargin>
            será processado após a confirmação de pagamento.
          </Text>
        </Fragment>
      </Inline>
    </Stack>
  </Card>
)

export default QRCodeCardMobile
