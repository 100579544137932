import PropTypes from 'prop-types'

export default {
  path: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  targeting: PropTypes.object,
  sizes: PropTypes.arrayOf(
    PropTypes.shape({
      viewport: PropTypes.arrayOf(PropTypes.number).isRequired,
      slot: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.number),
        PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number))
      ]).isRequired
    })
  ).isRequired,
  fetchMarginPercent: PropTypes.number
}
