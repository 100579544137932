'use client'

import { VariantProps } from 'tailwind-variants'

import { ButtonProps } from './Interface'
import { button } from './Button.style'

type ButtonVariants = VariantProps<typeof button>

export const Button = ({
  children,
  onClick,
  ...tvProps
}: ButtonProps & ButtonVariants) => {
  return (
    <div onClick={onClick} className={button(tvProps)}>
      {children}
    </div>
  )
}
