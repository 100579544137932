import { Heading, Stack, Text } from '@leroy-merlin-br/backyard-react'
import { CardIcon, BaseModal } from 'user/celebre/components'

const RequestDoneModal = ({ onClose }) => (
  <BaseModal onClose={onClose}>
    <CardIcon appearance="primary" />

    <Stack space="giga">
      <Heading as="h3" size="tera" noMargin>
        Você já pediu seu Celebre, agora é só aguardar :)
      </Heading>

      <Text size="mega" noMargin>
        Ainda estamos realizando as análises para a liberação do seu cartão. Mas
        não se preocupe, você poderá realizar uma{' '}
        <Text size="mega" as="strong" isBold noMargin>
          nova solicitação após 24 horas{' '}
        </Text>
        do seu último pedido.
      </Text>
    </Stack>
  </BaseModal>
)

export default RequestDoneModal
