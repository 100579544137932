export default time => {
  const distance = time.getTime() - new Date(Date.now()).getTime()

  const days = Math.floor(distance / (1000 * 60 * 60 * 24))
  const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
  const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
  const seconds = Math.floor((distance % (1000 * 60)) / 1000)

  const parsedTime = {
    days: days,
    hours: hours,
    minutes: minutes,
    seconds: seconds
  }

  return parsedTime
}
