import { tv } from 'tailwind-variants'

const styles = tv({
  slots: {
    dotList: `
      grid
      gap-2
      list-disc
      list-inside
    `
  }
})

export default styles
