import styled from '@emotion/styled'
import { css } from '@emotion/react'

const gradientWrapperStyles = () => css`
  label: linear-gradient;

  position: absolute;
  width: 0px;
  height: 0px;
`

export const GradientWrapper = styled('svg')(gradientWrapperStyles)
