import { css, keyframes } from '@emotion/react'
import styled from '@emotion/styled'

const animationFadeOut = keyframes`
  from {
    opacity: 1;
  }

  75% {
    opacity: 0.75;
    height: 75%;
  }

  50% {
    opacity: 0.5;
    height: 50%;
  }
  to {
    opacity: 0;
  }
`

const animationFadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

const fadeOut = ({ fadeOut }) => fadeOut && css`
  animation: ${animationFadeOut} 1s ease-in-out;
`

const fadeIn = ({ fadeIn }) => fadeIn && css`
  animation: ${animationFadeIn} 1s ease-in-out;
`

const wrapperStyles = () => css`
  width: 100%;
`

const lineStyles = ({ theme }) => css`
  margin: ${theme.spacings.kilo} 0 ${theme.spacings.mega};
  width: 100%;
  border-top: 1px solid ${theme.colors.n100};
`

const animatedWrapperStyles = ({ theme }) => css`
  margin: 0 0 ${theme.spacings.kilo};
  display: flex;
  gap: ${theme.spacings.kilo};
`

const serviceImgStyles = ({ theme }) => css`
  border: 1px solid ${theme.colors.n400};
  border-radius: ${theme.borderRadius.giga};
  width: 82px;
  height: 82px;
  margin-right: ${theme.spacings.kilo};
`

const priceStyles = ({ theme }) => css`
  label: details__price;

  display: block;
  margin-bottom: 1px;
`

const headerStyles = ({ theme }) => css`
  label: details__header;

  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;

  .glyph-trash-can {
    font-size: ${theme.typography.text.mega.fontSize};
  }
`

const detailStyles = css`
  label: details__product-info;

  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
`

const anchorTitleStyles = ({ theme }) => css`
  label: details__anchor;

  font-size: ${theme.typography.text.kilo.fontSize};
  line-height: ${theme.typography.text.kilo.lineHeight};
  font-weight: ${theme.font.weight.semibold};
  text-align: left;
  display: block;
  margin-bottom: ${theme.spacings.bit};
  color: ${theme.colors.p600};
`

const contentStyles = css`
  label: details__content;

  display: flex;
  line-height: 0;
  justify-content: space-between;
  align-items: stretch;
  margin-bottom: 3px;
`

const footerStyles = css`
  label: details__footer;

  display: flex;
  justify-content: space-between;
  align-items: center;
  align-items: flex-end;
`

const badgesStyles = css`
  label: details__badges;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

const badgeWrapperStyles = ({ theme }) => css`
  label: services-badge;

  margin: ${theme.spacings.bit} 0 ${theme.spacings.byte};

  & strong {
    font-weight: ${theme.font.weight.bold};
  }

  & img.service-badge-icon {
    width: 40px;
    height: 40px;
  }
`

const serviceImageStyles = ({ theme }) => css`
  label: product__service-image;

  border-radius: ${theme.borderRadius.mega};
  margin-right: ${theme.spacings.kilo};

  width: 82px;

  svg {
    width: 82px;
  }
`

export const ServiceImage = styled('a')(serviceImageStyles)
export const BadgeWrapper = styled('div')(badgeWrapperStyles)
export const Header = styled('div')(headerStyles)
export const Price = styled('div')(priceStyles)
export const Detail = styled('div')(detailStyles)
export const AnchorTitle = styled('a')(anchorTitleStyles)
export const Content = styled('div')(contentStyles)
export const Footer = styled('div')(footerStyles)
export const Badges = styled('div')(badgesStyles)
export const Wrapper = styled('div')(wrapperStyles, fadeIn)
export const Line = styled('div')(lineStyles)
export const AnimatedWrapper = styled('div')(animatedWrapperStyles, fadeOut)
export const ServiceImg = styled('img')(serviceImgStyles)
