import { Fragment } from 'react'
import { Text } from '@leroy-merlin-br/backyard-react'

const Installments = ({ isBranded, installments }) => {
  const hasInstallments = parseInt(installments?.amount || 0) > 1
  const hasInstallmentValue = parseInt(installments?.totalValue || 0) > 0

  if (!hasInstallments && !hasInstallmentValue) {
    return null
  }

  return (
    <Fragment>
      {isBranded
        ? (
            <Text color="n400" size="kilo" noMargin data-testid="branded-installments">
              ou em até{' '}
              <Text as="strong" isBold size="kilo" noMargin>
                {installments.amount}x{' '}
              </Text>
              s/ juros no cartão{' '}
              <Text as="strong" size="kilo" isBold noMargin>
                Celebre!
              </Text>
            </Text>
          )
        : (
          <Text color="n400" as="strong" isBold size="kilo" noMargin data-cy="installment-price-tag">
              <Text as="strong" size="kilo" isBold noMargin>
                <Text as="strong" color="black" size="kilo" isBold noMargin>
                  R$ {installments.totalValue}{' '}
                </Text>
                em até{' '}
              </Text>
              <Text as="strong" color="black" size="kilo" isBold noMargin>
                {installments.amount}x de R$ {installments.value} sem juros
              </Text>
          </Text>
          )}
    </Fragment>
  )
}

export default Installments
