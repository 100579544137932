import {
  call,
  delay,
  put,
  takeLatest,
  takeEvery,
  select
} from 'redux-saga/effects'

import { errorInMiniCartContext } from 'scripts/react-components/checkout/services/data-layer'

import { dataLayer } from 'site/Cart/services'

import * as services from 'checkout/services/associated-products'
import { updateCartShipping, updateCartUniqueCount } from 'checkout/services/emitters'

import { Actions, Constants, Selectors } from './'

function * getAssociatedProducts () {
  try {
    const data = yield call(services.getAssociateds)

    if (data) {
      yield put(Actions.updateAssociateds(data))
    }
  } catch (error) {
    yield put(Actions.getAssociatedsError(error?.data))
  }
}

function * addAssociatedToCart ({ id, regionId, sourceName }) {
  try {
    yield put(Actions.startAddingProduct(id))

    const {
      data: { total, subTotal, discountTotal, shippings, paymentMethodsSummary, uniqueProductsCount }
    } = yield call(services.putAssociatedProduct, {
      type: 'delivery',
      products: [{ id, quantity: 1 }]
    })

    yield put(Actions.successAddingProduct(id))

    const type = shippings?.[0]?.type
    const ref = shippings?.[0].items?.[0]
    ref.fadeIn = true

    yield call(updateCartShipping, { shippings, subTotal, discountTotal, total, paymentMethodsSummary })
    yield call(updateCartUniqueCount, uniqueProductsCount)

    const {
      brand,
      categoryTree,
      name,
      price: { to_price: toPrice },
      shopName
    } = yield select(Selectors.getAssociatedProductById, id)

    const dataLayerObject = {
      brand,
      categoryTree,
      id,
      name,
      price: { to: toPrice },
      type,
      quantity: 1,
      shopName,
      regionId,
      sourceName
    }

    yield call(dataLayer.addProduct, dataLayerObject)
  } catch (error) {
    yield call(errorInMiniCartContext, 'clique_adicionar_item', 'Erro ao adicionar produto associado')
    yield put(Actions.errorAddingProduct(error?.data, id))
  }
}

function * timerToResetProductError () {
  yield delay(6000)
  yield put(Actions.resetPutProductError())
}

export default function * root () {
  yield takeLatest(Constants.ASSOCIATED_GET_PRODUCTS, getAssociatedProducts)

  yield takeLatest(Constants.ASSOCIATED_PUT_PRODUCTS_ERROR, timerToResetProductError)

  yield takeEvery(Constants.ASSOCIATED_PUT_PRODUCTS, addAssociatedToCart)
}
