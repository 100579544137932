import { Fragment } from 'react'
import { Card, Inline, Stack, Text } from '@leroy-merlin-br/backyard-react'

import { SeparatingEdge } from '../'
import { AppStoreBadge, GooglePlayBadge } from './'

const DownloadAppsCard = () => {
  const linkAppStore =
    'https://apps.apple.com/br/app/leroy-merlin-brasil/id1470425305'
  const linkGooglePlay =
    'https://play.google.com/store/apps/details?id=br.com.leroymerlin'

  return (
    <Card target="card" shadow="triple">
      <Stack space="giga">
        <Fragment>
          <Text size="mega" color="n900" isBold>
            Baixe o app para acompanhar seu pedido
          </Text>

          <SeparatingEdge />
        </Fragment>

        <Inline space="bit" alignX="space-between">
          <a
            href={linkAppStore}
            target="_blank"
            rel="download app in app store"
          >
            <AppStoreBadge />
          </a>
          <a
            href={linkGooglePlay}
            target="_blank"
            rel="download app in google play"
          >
            <GooglePlayBadge />
          </a>
        </Inline>
      </Stack>
    </Card>
  )
}

export default DownloadAppsCard
