import { FunctionComponent } from 'react'
import { Stack, Dialog, Spacer, Text } from '@backyard-ui/core'

import { Comment } from '../../../../components'
import { Props } from './types'
import styles from './ReviewModal.styles'

const ReviewModal: FunctionComponent<Props> = ({ itemRate }) => (
  <Dialog.Root size="2xl" isCentered closeOnOverlayClick hasScroll>
    <Dialog.Trigger>
      <Text size="xl" color="green-600" weight="bold">
        Exibir avaliação completa
      </Text>
    </Dialog.Trigger>

    <Dialog.Content>
      <Dialog.CloseButton />
      <Dialog.Title>
        <Spacer size="5" />
        <Stack direction="column" spacing={{ initial: 4, md: 9 }}>
          <Comment.Head
            author={itemRate.author}
            date={itemRate.date}
            rating={itemRate.rating}
          />
          <Comment.Title title={itemRate.title} />
        </Stack>
      </Dialog.Title>

      <Dialog.Description>
        <div className={styles().description()}>
          <Stack direction="column" spacing="6">
            <Comment.Description text={itemRate.text} />
            <Comment.Media
              photos={itemRate.photos}
              videos={itemRate.videos}
              title={itemRate.title}
              size="sm"
            />
          </Stack>
        </div>
      </Dialog.Description>

      <Dialog.Footer>
        <div className={styles().footer()}>
          <Stack direction="column" spacing={{ initial: 4, md: 6 }}>
            <Comment.Recommendation
              hasRecommendation={itemRate.isRecommended}
            />
            <Comment.Actions
              reviewId={itemRate.id}
              badFeedbackAmount={itemRate.badFeedbackAmount}
              goodFeedbackAmount={itemRate.goodFeedbackAmount}
            />
          </Stack>
        </div>
      </Dialog.Footer>
    </Dialog.Content>
  </Dialog.Root>
)

export default ReviewModal
