import * as S from './styled'

export const WhatsAppLogo = () => (
  <S.Icon fill="none" viewBox="0 0 31 30" xmlns="http://www.w3.org/2000/S.Icon">
    <path
      d="m2.8 27.368 1.7903-6.5191c-1.1008-1.9074-1.6875-4.0744-1.6814-6.2897 0-6.9417 5.6613-12.586 12.617-12.586 3.375 0 6.5443 1.3099 8.9213 3.6881 2.3831 2.3783 3.6956 5.5413 3.6895 8.9034 0 6.9417-5.6613 12.586-12.617 12.586h-.006c-2.1109 0-4.1855-.5312-6.0303-1.5332L2.7999 27.368zm6.998-4.0322.38107.2294c1.6089.9537 3.4536 1.4548 5.3347 1.4608h.006c5.7762 0 10.482-4.6902 10.482-10.461 0-2.7947-1.0887-5.4205-3.0666-7.4004-1.9778-1.9799-4.6149-3.0664-7.4153-3.0664-5.7822 0-10.488 4.6901-10.488 10.461 0 1.9738.55041 3.8994 1.6028 5.5654l.24798.3984-1.0524 3.8511 3.9677-1.0383z"
      fill="#fff"
    />
    <path
      d="m3.2356 26.928 1.7298-6.2958c-1.0645-1.8411-1.627-3.9356-1.627-6.0724 0-6.7002 5.4677-12.151 12.175-12.151 3.2601 0 6.3145 1.2676 8.6129 3.5614s3.5625 5.3481 3.5625 8.5956c0 6.7002-5.4677 12.151-12.175 12.151h-.006c-2.0383 0-4.0404-.5131-5.8186-1.4789l-6.4536 1.6902z"
      fill="url(#whatsapp-disclaimer-gradient-1)"
    />
    <path
      d="m2.8 27.368 1.7903-6.5191c-1.1008-1.9074-1.6875-4.0744-1.6814-6.2897 0-6.9417 5.6613-12.586 12.617-12.586 3.375 0 6.5443 1.3099 8.9213 3.6881 2.3831 2.3783 3.6956 5.5413 3.6895 8.9034 0 6.9417-5.6613 12.586-12.617 12.586h-.006c-2.1109 0-4.1855-.5312-6.0303-1.5332L2.7999 27.368zm6.998-4.0322.38107.2294c1.6089.9537 3.4536 1.4548 5.3347 1.4608h.006c5.7762 0 10.482-4.6902 10.482-10.461 0-2.7947-1.0887-5.4205-3.0666-7.4004-1.9778-1.9799-4.6149-3.0664-7.4153-3.0664-5.7822 0-10.488 4.6901-10.488 10.461 0 1.9738.55041 3.8994 1.6028 5.5654l.24798.3984-1.0524 3.8511 3.9677-1.0383z"
      fill="url(#whatsapp-disclaimer-gradient-2)"
    />
    <path
      d="M12.368 9.2898c-.2358-.52515-.4838-.53722-.7076-.54326-.1815-.00603-.3932-.00603-.6049-.00603s-.5504.07847-.8407.39235c-.29031.31388-1.1008 1.0745-1.1008 2.6258 0 1.5452 1.1311 3.0422 1.2883 3.2535.1573.2113 2.1835 3.4889 5.3831 4.7505 2.6613 1.0503 3.2056.839 3.7802.7847.5807-.0543 1.8629-.7606 2.1291-1.497.26-.7364.26-1.3642.1814-1.497s-.2903-.2112-.6048-.3682c-.3145-.1569-1.8629-.9175-2.1533-1.0261-.2903-.1026-.502-.157-.7076.1569-.2117.3139-.8165 1.0201-.998 1.2314s-.369.2354-.6835.0785c-.3145-.157-1.3306-.489-2.5342-1.5634-.9375-.833-1.5726-1.8652-1.7541-2.1791-.1814-.3139-.0181-.4829.1391-.6398.1391-.1388.3145-.3682.4718-.5493s.2117-.3139.3145-.5252c.1028-.2112.0545-.3923-.0242-.5493-.0846-.1509-.6955-1.7082-.9738-2.3299z"
      clipRule="evenodd"
      fill="#fff"
      fillRule="evenodd"
    />
    <defs>
      <linearGradient
        id="whatsapp-disclaimer-gradient-1"
        x1="15.466"
        x2="15.466"
        y1="26.927"
        y2="2.4084"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#20B038" offset="0" />
        <stop stopColor="#60D66A" offset="1" />
      </linearGradient>
      <linearGradient id="whatsapp-disclaimer-gradient-2" x1="15.466" x2="15.466" y1="27.367" y2="1.9686">
        <stop stopColor="#F9F9F9" offset="0" />
        <stop stopColor="#fff" offset="1" />
      </linearGradient>
    </defs>
  </S.Icon>
)
