
import {
  Button,
  Inline
} from '@leroy-merlin-br/backyard-react'
import { useContext } from 'react'
import { FilterOutline } from '@backyard-ui/icons'

import { EntryKeyContext } from 'scripts/react-components/site/EntryKey/context'

import * as S from './styled'
import { ExpressDeliveryFilter } from '../ExpressDeliveryFilter'

const WrapperApp = () => {
  const {
    filters,
    toggleMobileFilter
  } = useContext(EntryKeyContext)

  const expressDeliveryFilter = filters.find(item => item.filterKey === 'entrega-rapida')

  return (
    <S.Wrapper>
      <Inline alignX="space-between" alignY="center">
        <ExpressDeliveryFilter filter={expressDeliveryFilter} />
        <S.FilterButtonWrapper data-testid="filter-button-app">
          <Button
            variant="ghost"
            size="mega"
            label="filtrar"
            onClick={toggleMobileFilter}
            iconRight={FilterOutline}
          >
            Filtro
          </Button>
        </S.FilterButtonWrapper>
      </Inline>
    </S.Wrapper>
  )
}

export default WrapperApp
