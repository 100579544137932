import { useState } from 'react'
import {
  Button,
  Heading,
  Inline,
  Stack,
  Text,
  TextField
} from '@leroy-merlin-br/backyard-react'

import { AlertMessage } from 'shared/components'

import * as batchApi from 'site/Product/services/batch'

import * as S from './styled'

const BatchSearch = ({ lm, storeSlug }) => {
  const [storesData, setStoresData] = useState()
  const [batchesData, setBatchesData] = useState()
  const [isBatchesVisible, setIsBatchesVisible] = useState(false)
  const [errors, setErrors] = useState()
  const [batchNumber, setBatchNumber] = useState('')
  const [isLoading, setIsLoading] = useState('')

  const storeErrors = errors => {
    const newErrors = Array.isArray(errors) ? errors : Object.values(errors)
    setErrors(newErrors)
  }

  const fetchStoresByBatch = async event => {
    event.preventDefault()

    setIsLoading(true)

    try {
      const data = await batchApi.getStoresByBatch(lm, storeSlug, batchNumber)

      setStoresData(data)
    } catch (error) {
      storeErrors(error?.data?.errors || ['Erro desconhecido'])
    } finally {
      setIsLoading(false)
    }
  }

  const fetchBatchesByStore = async () => {
    setIsLoading(true)
    setErrors()

    try {
      const data = await batchApi.getBatchesByStore(lm, storeSlug)

      setBatchesData(data)
      setIsBatchesVisible(true)
    } catch (error) {
      storeErrors(error?.data?.errors || ['Erro desconhecido'])
    } finally {
      setIsLoading(false)
    }
  }

  const onBatchNumberChange = event => {
    setStoresData()
    setBatchesData()
    setIsBatchesVisible(false)
    setErrors()
    setBatchNumber(event.target.value)
  }

  const onSeeMoreBatchesClick = async () => {
    if (!isBatchesVisible) {
      batchesData ? setIsBatchesVisible(true) : fetchBatchesByStore()
    } else {
      setIsBatchesVisible(false)
    }
  }

  const hasStoresData = storesData?.stores?.length > 0
  const hasBatchesData = batchesData?.batches?.length > 0
  const hasErrors = errors?.length > 0

  return (
    <Stack space="tera">
      {hasErrors > 0 && (
        <AlertMessage
          title="Ops! Alguma coisa está errada."
          type="critical"
          tag="ul"
        >
          {errors.map((error, index) => (
            <li key={index}>{error}.</li>
          ))}
        </AlertMessage>
      )}

      <Stack space="mega">
        <form onSubmit={fetchStoresByBatch}>
          <S.SearchWrapper>
            <TextField
              type="text"
              name="batch-search"
              id="batch-search"
              required
              label="Buscar Lote"
              value={batchNumber}
              onChange={onBatchNumberChange}
            />
            <Button
              type="submit"
              isDisabled={!batchNumber}
              isLoading={isLoading}
            >
              Pesquisar
            </Button>
          </S.SearchWrapper>
        </form>

        {hasStoresData && (
          <S.TableWrapper>
            <table>
              <thead>
                <tr>
                  <td>
                    <Text
                      align="start"
                      size="kilo"
                      noMargin
                      color="n900"
                      isBold
                    >
                      Loja
                    </Text>
                  </td>
                  <td>
                    <Text align="end" size="kilo" noMargin color="n900" isBold>
                      Quantidade
                    </Text>
                  </td>
                </tr>
              </thead>
              <tbody>
                {storesData.stores.map(({ storeName, quantity }, key) => (
                  <tr key={key}>
                    <td>
                      <Text
                        align="start"
                        size="kilo"
                        noMargin
                        color={key === 0 ? 'p600' : 'n900'}
                      >
                        {storeName}
                      </Text>
                    </td>
                    <td>
                      <Text
                        align="end"
                        size="kilo"
                        noMargin
                        color={key === 0 ? 'p600' : 'n900'}
                      >
                        {quantity} {storesData.unit}
                      </Text>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </S.TableWrapper>
        )}
      </Stack>

      {hasBatchesData && isBatchesVisible && (
        <Stack space="mega">
          <Heading size="kilo" noMargin>
            Mais lotes na sua loja
          </Heading>
          <S.TableWrapper>
            <table>
              <thead>
                <tr>
                  <td>
                    <Text
                      align="start"
                      size="kilo"
                      noMargin
                      color="n900"
                      isBold
                    >
                      Número de lote
                    </Text>
                  </td>
                  <td>
                    <Text align="end" size="kilo" noMargin color="n900" isBold>
                      Quantidade
                    </Text>
                  </td>
                </tr>
              </thead>
              <tbody>
                {batchesData.batches.map(({ batchId, quantity }, key) => (
                  <tr key={key}>
                    <td>
                      <Text align="start" size="kilo" noMargin color="n900">
                        {batchId}
                      </Text>
                    </td>
                    <td>
                      <Text align="end" size="kilo" noMargin color="n900">
                        {quantity} {batchesData.unit}
                      </Text>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </S.TableWrapper>
        </Stack>
      )}

      {hasStoresData && (
        <Inline alignX="center">
          <Button
            variant="link"
            size="kilo"
            onClick={onSeeMoreBatchesClick}
            isLoading={isLoading}
          >
            {isBatchesVisible ? 'Ocultar' : 'Mostrar'} lotes de sua loja
          </Button>
        </Inline>
      )}
    </Stack>
  )
}

export default BatchSearch
