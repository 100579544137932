import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Stack, Inline, Text } from '@leroy-merlin-br/backyard-react'

import formatPriceToBRL from 'utils/formatPriceToBRL'

import TotalPaymentMethods from './TotalPaymentMethods'

const Summary = ({
  subTotal,
  discount,
  total,
  servicesSubTotal,
  paymentMethodsSummary
}) => {
  const hasDiscount = useMemo(() => {
    return discount > 0
  }, [discount])

  const hasSubtotal = useMemo(() => {
    return Math.floor(subTotal) !== Math.floor(total) && subTotal > 0
  }, [subTotal, total])

  const hasServicesSubtotal = useMemo(() => {
    return servicesSubTotal > 0
  }, [servicesSubTotal])

  return (
    <Stack space="kilo" data-cy="summary-info">
      {hasSubtotal && (
        <Inline alignX="space-between" alignY="center">
          <Text as="strong" size="giga" noMargin isBold>
            Subtotal:
          </Text>
          <Text as="strong" size="giga" noMargin isBold>
            {formatPriceToBRL(subTotal)}
          </Text>
        </Inline>
      )}

      {hasDiscount && (
        <Inline alignX="space-between" alignY="center">
          <Text as="strong" size="giga" noMargin isBold>
            Desconto:
          </Text>
          <Text as="strong" size="giga" color="p500" noMargin isBold>
            -{formatPriceToBRL(discount)}
          </Text>
        </Inline>
      )}

      {hasServicesSubtotal && (
        <Inline alignX="space-between" alignY="center">
          <Text as="strong" size="giga" noMargin isBold>
            Serviço de Instalação:
          </Text>
          <Text as="strong" size="giga" noMargin isBold>
            {formatPriceToBRL(servicesSubTotal)}
          </Text>
        </Inline>
      )}

      <Inline alignX="space-between" alignY="center">
        <Text as="strong" size="giga" noMargin isBold>
          Total:
        </Text>
        <Text as="strong" size="giga" noMargin isBold>
          {formatPriceToBRL(total)}
        </Text>
      </Inline>

      {paymentMethodsSummary && (
        <TotalPaymentMethods
          paymentMethodsSummary={paymentMethodsSummary} />
      )}
    </Stack>
  )
}

Summary.propTypes = {
  discount: PropTypes.number,
  subTotal: PropTypes.number,
  total: PropTypes.number,
  servicesSubTotal: PropTypes.number
}

export default Summary
