const ListCover = ({ children }) => {
  return (
    <div className="wishlist-page-header">
      <div className="container">
        <div className="wishlist-header">{children}</div>
      </div>
    </div>
  )
}

export default ListCover
