import { useHistory } from 'react-router-dom'

import { Layout } from '../../components/Layout'
import { MenuItemCard } from '../../components/MenuItemCard'
import * as S from './styled'
import { ItemsMyAccount, getItemsMyAccount } from '../../constants'

export interface HomeProps {
  itemsMyAccount: ItemsMyAccount
  isCaptchaEnabled: boolean
  captchaKey: string
}

const Home = ({ itemsMyAccount }: HomeProps) => {
  const history = useHistory()

  const redirectToRoute = (route: string, reload?: boolean) => {
    history.push(route)

    if (reload) {
      window.location.reload()
    }
  }

  return (
    <Layout description="Meu Perfil">
      <S.Wrapper>
        {getItemsMyAccount(itemsMyAccount).map((item, index) => (
          <MenuItemCard
            key={index}
            icon={item.icon}
            title={item.title}
            description={item.description}
            myAccountItemId={item.id}
            onClick={() => redirectToRoute(item.route, item.outsideOfReact)}
            hasSmallTextOnDesktop={item.hasSmallTextOnDesktop}
          />
        ))}
      </S.Wrapper>
    </Layout>
  )
}

export default Home
