import { memo } from 'react'
import PropTypes from 'prop-types'
import { Badge, Text } from '@leroy-merlin-br/backyard-react'

import formatPriceToBRL from 'utils/formatPriceToBRL'

import { getShipmentMessage } from 'catalog/utils/shipments-dictionary'

const ShipmentPrice = ({ modality, cost, deadline, errorStatus }) => {
  const { deadlineMessage } = getShipmentMessage(modality, deadline)

  if (errorStatus || !modality) {
    return <Text size="kilo" color="r600" as="span" noMargin>Confira o frete no carrinho.</Text>
  }

  const valueElement = cost === 0
    ? <Badge appearance="critical">Frete grátis</Badge>
    : <Text size="kilo" as="span" noMargin> + {formatPriceToBRL(cost)}</Text>

  return (
    <Text size="kilo" as="span" noMargin>
      {valueElement}
      {' - '}{deadlineMessage.prefix}{' '}
      <Text size="kilo" as="strong" isBold noMargin>
        {deadlineMessage.main}
      </Text>
    </Text>
  )
}

ShipmentPrice.propTypes = {
  modality: PropTypes.string,
  cost: PropTypes.number,
  deadline: PropTypes.number,
  errorStatus: PropTypes.number.isRequired
}

export default memo(ShipmentPrice)
