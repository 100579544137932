import styled from '@emotion/styled'
import { css } from '@emotion/react'

import {
  StyleIsActiveProps,
  StyleProps
} from 'lmcv/components/CouponModal/types'

const wrapperStyles = ({ theme }: StyleProps) => css`
  label: wrapper;

  padding: ${theme.spacings.giga};

  border-bottom-left-radius: ${theme.borderRadius.mega};
  border-bottom-right-radius: ${theme.borderRadius.mega};

  background: ${theme.colors.white};

  ${theme.media.mega} {
    border-radius: 0px;
    background: unset;
  }
`

const wrapperActiveStyles = ({ theme, isActive }: StyleIsActiveProps) =>
  isActive &&
  css`
    label: wrapper--active;

    background-image: linear-gradient(
      134.58deg,
      ${theme.colors.f600} -5.39%,
      ${theme.colors.v500} 100%
    );

    ${theme.media.mega} {
      background-image: unset;
    }
  `

export const Wrapper = styled('div')(wrapperStyles, wrapperActiveStyles)
