import type { TopBarProps } from '@leroy-merlin-br/backpack-header'

import { buildQueryString } from 'site/utils/buildQueryString'

export const handleUserRecontext: TopBarProps['handleLocation'] = async (
  params
) => {
  const { protocol, hostname, pathname, href: currentUrl } = window.location

  const baseURL = `${protocol}//${hostname}`

  const queryParams =
    params.contextBy === 'coordinates'
      ? {
          lat: params.latitude,
          long: params.longitude
        }
      : {
          zipCodeId: params.zipCodeRangeId,
          zipCode: params.zipCode
        }

  const queryString = buildQueryString(queryParams)

  const previousUrl =
    pathname === '/localizacao' ? '' : `&previousUrl=${currentUrl}`

  const url = `${baseURL}/localizacao/store/?${queryString}${previousUrl}`

  location.replace(url)
}
