import { Fragment, useState, useEffect } from 'react'
import { Heading } from '@leroy-merlin-br/backyard-react'
import { Card } from '@backyard-ui/core'

import emitter from 'scripts/utils/emitter'

import Loader from 'site/Loader'

import * as orderServices from 'checkout/services/order'

import { Order } from './components/Order'

const DEFAULTS = {
  messages: {
    error: {
      title: 'Ops! Ocorreu o seguinte erro:'
    }
  }
}

const MyOrders = ({ fiscalId, saleOrderId, errors }) => {
  const [orders, setOrders] = useState([])
  const [ordersToPay, setOrdersToPay] = useState([])
  const [loading, setLoading] = useState(true)
  const [showErrorMsg, setShowErrorMsg] = useState(false)

  const onGetSuccess = ([dataOrders, dataOrdersToPay]) => {
    setOrders(dataOrders)
    setOrdersToPay(dataOrdersToPay)
    setLoading(false)
    setShowErrorMsg(false)
  }

  useEffect(() => {
    if (saleOrderId && orders.length) {
      const element = document.querySelector(
        '[data-section="' + saleOrderId + '"]'
      )

      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }, [orders, saleOrderId])

  useEffect(() => {
    Promise.all([
      orderServices.getOrders(fiscalId),
      orderServices.getOrdersToPay(fiscalId)
    ])
      .then(onGetSuccess)
      .catch(() => {
        setLoading(false)
        setShowErrorMsg(true)
      })
  }, [fiscalId])

  if (errors) {
    emitter.emit('stickyFeedback:error', {
      title: DEFAULTS.messages.error.title,
      content: errors
    })
  }

  const allOrders = [...orders, ...ordersToPay]
  const isOrderExists = allOrders.some(order => order.id === saleOrderId)

  if (saleOrderId && allOrders.length > 0 && !isOrderExists) {
    emitter.emit('stickyFeedback:error', {
      title: 'Ops! ocorreu o seguinte erro:',
      content:
        'Pedido não encontrado. Verifique se o login realizado no aplicativo corresponte ao CPF informado ao assessor.'
    })
  }

  if (loading) {
    return <Loader />
  }

  if (showErrorMsg) {
    return (
      <div className="error-wrapper color-default">
        <span>
          No momento, não foi possível consultar os seus pedidos. Tente
          novamente mais tarde.
        </span>
      </div>
    )
  }

  const shouldNotShowOrders = !orders.length && !ordersToPay.length
  const shouldRenderOrders = orders.length > 0
  const shouldRenderOrdersToPay = ordersToPay.length > 0

  return (
    <Fragment>
      {shouldNotShowOrders && (
        <div className="error-wrapper color-default">
          <span>Você ainda não fez nenhum pedido, que tal aproveitar?</span>
        </div>
      )}

      {shouldRenderOrdersToPay && (
        <>
          <Heading as="h2" size="giga">
            Finalizar pedidos de Loja
          </Heading>

          {ordersToPay.map((order, index) => (
            <div key={index} className="pb-4">
              <Card.Root size="xs" shadow="none" hasBorder>
                <Card.Body>
                  <Order
                    order={order}
                    saleOrderId={saleOrderId}
                    fiscalId={fiscalId}
                    isOrderToPay={true}
                  />
                </Card.Body>
              </Card.Root>
            </div>
          ))}
        </>
      )}

      {shouldRenderOrders && (
        <Fragment>
          <Heading as="h2" size="giga">
            Pedidos
          </Heading>

          {orders.map((order, index) => (
            <div key={index} className="pb-4">
              <Card.Root size="xs" shadow="none" hasBorder>
                <Card.Body>
                  <Order
                    order={order}
                    saleOrderId={saleOrderId}
                    fiscalId={fiscalId}
                  />
                </Card.Body>
              </Card.Root>
            </div>
          ))}
        </Fragment>
      )}
    </Fragment>
  )
}

export default MyOrders
