import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { Theme } from 'scripts/react-components/themes/Theme'

const securityCode = ({ theme }: Theme) => css`
  label: security-code-wrapper;

  p {
    margin-bottom: ${theme.spacings.tera};
  }
`

export const SecurityCode = styled('div')(securityCode)

const securityCodeInput = () => css`
  label: security-code-input;

  align: center;
`

export const SecurityCodeInput = styled(securityCodeInput)

const resendCodeWrapper = ({ theme }: Theme) => css`
  label: security-resend-code;

  margin-top: ${theme.spacings.giga};
`

export const ResendCodeWrapper = styled('div')(resendCodeWrapper)
