import { Button, Inline, Stack, Heading } from '@leroy-merlin-br/backyard-react'
import { Avatar } from 'user/components/Avatar'

import { redirectUrl } from '../../../utils/redirect-url'
import * as S from '../styled'

const LoggedOut = () => (
  <S.WrapperLogout>
    <Inline space="mega" alignY="center">
      <Avatar maskColor="b900" />

      <Stack space="kilo">
        <Heading size="mega" color="white" noMargin>
          Olá!
        </Heading>

        <Inline space="byte">
          <Button size="kilo" onClick={() => redirectUrl('login')}>
            Entrar
          </Button>
          <Button
            size="kilo"
            variant="outline"
            onClick={() => redirectUrl('cadastre-se')}
          >
            Criar conta
          </Button>
        </Inline>
      </Stack>
    </Inline>
  </S.WrapperLogout>
)

export default LoggedOut
