export const LocationFormInitialState = {
  suggestions: [],
  cityInput: '',
  zipCodeInput: '',
  zipCodeId: '',
  currentOption: '',
  inputError: { city: false, zipcode: false },
  showSuggestion: false,
  showSecondStep: false
}

export const LocationFormReducer = (state, action) => {
  switch (action.type) {
    case 'SET_CITY_INPUT':
      return {
        ...state,
        cityInput: action.cityInput
      }

    case 'CLOSE_SUGGESTIONS':
      return {
        ...state,
        suggestions: [],
        showSuggestion: false,
        showSecondStep:
          'showSecondStep' in action
            ? action.showSecondStep
            : state.showSecondStep
      }

    case 'ON_ZIPCODE_CHANGE':
      return {
        ...state,
        showSecondStep: false,
        zipCodeInput: action.zipCodeInput,
        inputError: {
          city: false,
          zipcode: false
        }
      }

    case 'SELECT_REGION': {
      const region = `${action.regionData.city} - ${action.regionData.state}`

      return {
        ...state,
        showSecondStep: true,
        showSuggestion: false,
        cityInput: region,
        currentOption: region,
        zipCodeInput: action.zipCodeInput
          ? action.zipCodeInput
          : state.zipCodeInput,
        zipCodeId: action.regionData.id
      }
    }

    case 'CLEAR_ALL':
      return {
        ...state,
        cityInput: '',
        currentOption: '',
        showSecondStep: false,
        zipCodeId: '',
        zipCodeInput: ''
      }

    case 'GET_SUGGESTIONS_SUCCESS':
      return {
        ...state,
        suggestions: action.suggestions,
        showSuggestion: true,
        inputError: {
          ...state.inputError,
          city: false
        }
      }

    case 'GET_SUGGESTIONS_ERROR':
      return {
        ...state,
        suggestions: [],
        showSuggestion: false,
        showSecondStep: false,
        inputError: {
          ...state.inputError,
          city: true
        },
        zipCodeId: '',
        zipCodeInput: ''
      }

    case 'GET_ZIPCODE_ERROR':
      return {
        ...state,
        zipCodeId: '',
        cityInput: '',
        currentOption: '',
        showSecondStep: false,
        inputError: {
          ...state.inputError,
          zipcode: true
        }
      }
    default:
      throw new Error(`No action found for: ${action?.type}`)
  }
}
