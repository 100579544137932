import { Text, Stack, useBreakpoint } from '@leroy-merlin-br/backyard-react'
import PropTypes from 'prop-types'

import { ExpressDeliveryTag } from 'scripts/react-components/shared/components/NewProductThumb/components'

import { OptimizedImage } from 'shared/components/OptimizedImage'

import ButtonComponent from './Button'
import * as S from './styled'
import PriceTag from './PriceTag'

const IMAGE_SIZE = { width: 82, height: 82 }

const Product = ({
  onAddToCartClick,
  onProductNameClick,
  id,
  pictures,
  price,
  name,
  url,
  installments,
  shopName,
  brand,
  isExpressDelivery,
  expressDeliveryDay,
  isSoldOut
}) => {
  return (
    <S.Wrapper>
      <OptimizedImage
        as={S.Img}
        src={pictures[0]}
        alt={name}
        loading="lazy"
        height={IMAGE_SIZE.height}
        width={IMAGE_SIZE.width}
        noSrcSet
      />

      <S.Content>
        <Stack space="bit">
          <S.ProductTitle>
            <Text
              as="span"
              itemProp="name"
              onClick={onProductNameClick}
              size="kilo"
              align="left"
              noMargin
              isBold
            >
              <a href={url} itemProp="name">
                {name}
              </a>
            </Text>
          </S.ProductTitle>
          <S.Container>
            <Text
              as="strong"
              size="kilo"
              noMargin
            >
              Vendido e entregue por {shopName}
            </Text>
          </S.Container>
          <S.WrapperContent>
            <div>
              {isExpressDelivery && (
                <ExpressDeliveryTag
                  isSoldOut={isSoldOut}
                  deliveryDate={expressDeliveryDay}
                />
              )}
              <PriceTag
                price={price}
                installments={installments}
                brand={brand}
              />
            </div>
            <ButtonComponent onAddToCartClick={onAddToCartClick} id={id} />
          </S.WrapperContent>
        </Stack>
      </S.Content>
    </S.Wrapper>
  )
}

Product.propTypes = {
  onAddToCartClick: PropTypes.func.isRequired,
  onProductNameClick: PropTypes.func.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  pictures: PropTypes.array.isRequired,
  price: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  unit: PropTypes.string.isRequired,
  installments: PropTypes.object,
  shopName: PropTypes.string,
  brand: PropTypes.string
}

export default Product
