/**
 * Component responsible to handle emitter events in order to style valid/invalid elements.
 */
import $ from 'jquery'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { isValid as isValidCpf } from '@fnando/cpf'

import Validation from 'garden/src/js/components/validation'
import gardenEmitter from 'garden/src/js/utils/emitter'

import emitter from 'scripts/utils/emitter'
import { validateNumberByState } from 'scripts/react-components/utils/account/factoryAddressRules.js'

dayjs.extend(customParseFormat)

Validation.prototype.rules.fullName = (field) => /^([\wÀ-ú']+\s)+[\wÀ-ú']+$/.test(field.value.trim())

Validation.prototype.rules.birthdate = (field) => {
  const now = dayjs()
  const birthdate = dayjs(field.value, 'DD/MM/YYYY', true)

  if (!birthdate.isValid()) {
    return false
  }

  return now.isAfter(birthdate)
}

Validation.prototype.rules.isMinAge = (field) => {
  const inputValue = field.value.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$3-$2-$1')
  const birthdate = dayjs(inputValue)
  const now = dayjs()
  return (now.diff(birthdate, 'year') >= 18)
}

Validation.prototype.rules.isCpf = (field) => {
  return isValidCpf(field.value)
}

Validation.prototype.rules.password = (field) => {
  const regexWord = /([a-zA-Z])/g
  const regexNumber = /([0-9])/g
  const regexSequential = /012|123|234|345|456|567|678|789/g

  if (field.value.match(regexWord) && field.value.match(regexNumber) && !field.value.match(regexSequential)) {
    return true
  }

  return false
}

Validation.prototype.rules.addressNumber = (field) => {
  const stateFieldId = $(field).data('state-field-id')
  const stateField = document.getElementById(stateFieldId)

  const stateFieldValue = stateField && $(stateField).val()
  const numberFieldValue = $(field).val()

  const isNumberFieldRequired = $(field).prop('required')
  const isEmptyAndNotRequired = !isNumberFieldRequired && !numberFieldValue

  if (isEmptyAndNotRequired) return true

  return validateNumberByState(numberFieldValue, stateFieldValue)
}

Validation.prototype.rules.zipcodedata = (field) => {
  const zipcodeDataError = $(field).data('zipcode-data-error')

  return !zipcodeDataError
}

/**
 * Styles the parent (Usually a fieldset) with invalid class.
 */

const validationError = (input, errorName, customMessage) => {
  const $input = (input instanceof $) ? input : $(input)

  $input
    .closest('[data-validation="field"]')
    .removeClass('valid')
    .addClass('invalid')

  const target = $input.data('validate-target')
  const message = customMessage || $input.data('validate-warning')

  if (target && message) {
    $(target).html(message)
  }
}

/**
 * Styles the parent (Usually a fieldset) with valid class.
 */

const validationSuccess = (field) => {
  const { validateSuccess } = field.dataset

  $(field).closest('[data-validation="field"]').removeClass('invalid')

  if (validateSuccess === 'default') return

  $(field).closest('[data-validation="field"]').addClass('valid')
}

/**
 * Remove validation styles from the parent (Usually a fieldset).
 */
const validationPrestine = (field) => {
  const $field = $(field).closest('[data-validation="field"]')

  $field.removeClass('valid invalid')
}

const validationResetMessage = field => {
  const $field = field instanceof $ ? field : $(field)

  const defaultWarning = $field.data('validate-warning')
  const target = $field.data('validate-target')

  if (target && defaultWarning) {
    $(target).html(defaultWarning)
  }
}

gardenEmitter.on('validation:error', validationError)
gardenEmitter.on('validation:success', validationSuccess)
gardenEmitter.on('validation:pristine', validationPrestine)
gardenEmitter.on('validation:resetMessage', validationResetMessage)

emitter.on('validation:error', validationError)
emitter.on('validation:success', validationSuccess)
emitter.on('validation:pristine', validationPrestine)
emitter.on('validation:resetMessage', validationResetMessage)

export default ($el) => $el.validation()
