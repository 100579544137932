import { useRef } from 'react'

import * as S from './components/styled'
import Button from './components/Button'
import ButtonFactory from './ButtonFactory'

export const AugmentedRealityFullButton = ({ sku }) => {
  const factoryRef = useRef()

  return (
    <ButtonFactory sku={sku} ref={factoryRef}>
      <S.FullButtonContainer>
        <Button
          text={'Veja na sua casa em RA'}
          onClick={() => factoryRef.current.openAR()}
          full
        />
      </S.FullButtonContainer>
    </ButtonFactory>
  )
}

export const AugmentedRealityMiniButton = ({ sku }) => {
  const factoryRef = useRef()

  return (
    <ButtonFactory sku={sku} ref={factoryRef}>
      <S.MiniButtonContainer>
        <Button
          text={'RA'}
          onClick={() => factoryRef.current.openAR()}
        />
      </S.MiniButtonContainer>
    </ButtonFactory>
  )
}
