import $ from 'jquery'

import BaseComponent from 'scripts/components/base-component'
import { onCrossItemClick } from 'scripts/utils/data-layer'

class CrossSelling extends BaseComponent {
  static componentName = 'cross-selling'

  static DOMEvents = {
    'click a': 'onElementClick'
  }

  sendToDataLayer (item) {
    const dataObject = {
      page: 'PDP',
      source: 'own source',
      ...item
    }

    onCrossItemClick(dataObject)
  }

  redirect (url) {
    window.location.href = url
  }

  onElementClick (event) {
    event.preventDefault()

    const originProductId = this.$element.attr('data-cross-product-id')

    const anchorElement = $(event.target).closest('a')

    const cross = anchorElement.closest('[data-cross-item]')
    const crossIndex = cross.index()
    const crossTitle = cross.find('[data-cross-title]').text()

    const products = cross.find('.product-thumb')
    const productsIds = $.map(products, (product) => product.dataset.productId)

    const product = anchorElement.closest('.product-thumb')
    const productIndex = product.index()
    const productName = product.attr('title')
    const productId = product.attr('data-product-id')

    const productHref = anchorElement.attr('href')

    const item = {
      crossIndex,
      crossTitle,
      productsIds,
      productIndex,
      productName,
      productId,
      originProductId
    }

    this.sendToDataLayer(item)

    this.redirect(productHref)
  }
}

export default $el => new CrossSelling($el)

export { CrossSelling as Component }
