import $ from 'jquery'

import { Component as Feedback } from 'scripts/components/feedback'

const DEFAULTS = {
  timeOut: 4000
}

class StickyFeedback extends Feedback {
  static emitterEvents = {
    'stickyFeedback:success': 'success',
    'stickyFeedback:error': 'error'
  }

  constructor (element, options = {}) {
    super(element, $.extend({}, DEFAULTS, options))
  }

  show (feedback = {}) {
    super.show(feedback)
    setTimeout(this.hide.bind(this), !feedback.timeOut ? this.options.timeOut : feedback.timeOut)
  }

  success (feedback) {
    feedback.type = 'success'
    this.show(feedback)
  }

  error (feedback) {
    feedback.type = 'error'
    this.show(feedback)
  }
}

export default ($el) => new StickyFeedback($el).init()

export { StickyFeedback as Component }
