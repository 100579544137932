import styled from '@emotion/styled'
import { css } from '@emotion/react'

const installmentsStyles = ({ theme, isBold }) => css`
    color: ${theme.colors.n900};
    font-size: ${theme.typography.text.kilo.fontSize};
    font-weight: ${isBold
        ? theme.font.weight.bold
        : theme.font.weight.regular
    };
`
const priceTagStyles = ({ theme, isBold }) => css`
    color: ${theme.colors.n400};
    font-size: ${theme.typography.text.kilo.fontSize};
    font-weight: ${isBold
        ? theme.font.weight.bold
        : theme.font.weight.regular
    };
`
export const Installments = styled('label')(installmentsStyles)
export const PriceTagStyles = styled('label')(priceTagStyles)
