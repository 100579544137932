import { css } from '@emotion/react'
import styled from '@emotion/styled'

const contactStyles = ({ theme }) => css`
  label: contact;

  width: 100%;

  padding: ${theme.spacings.kilo} ${theme.spacings.mega};

  box-shadow: 0px 0px 0px 1px ${theme.colors.n300}11,
    0px 4px 4px ${theme.colors.shadow}, 0px 8px 8px ${theme.colors.shadow};
  border-radius: ${theme.borderRadius.giga};

  &:first-of-type {
    margin-right: ${theme.spacings.mega};
  }

  ${theme.media.mega} {
    display: inline-block;
    min-width: 152px;
  }
`

const contactsContainerStyles = ({ theme }) => css`
  label: contacts--container;

  padding: 0;
  border-radius: 16px;

  margin: ${theme.spacings.zetta} 0 ${theme.spacings.giga} 0;

  ${theme.media.mega} {
    border: 1px solid ${theme.colors.n100};
    box-sizing: border-box;

    padding: ${theme.spacings.peta};
    margin: ${theme.spacings.tera} 0 ${theme.spacings.giga} 0;
  }
`

const containerFlexStyles = ({ theme }) => css`
  label: container--flex;

  display: flex;
  flex-direction: column;

  ${theme.media.giga} {
    justify-content: space-between;
    flex-direction: row;

    margin: 20px 0 0 0;
  }
`

const sectionStyles = ({ theme }) => css`
  label: section;

  padding: 20px;

  ${theme.media.giga} {
    float: left;
    width: 100%;

    background: white;

    padding: 50px 97px 70px 97px;

    box-sizing: border-box;
  }
`

const separatorStyles = ({ theme }) => css`
  label: separator;

  height: 1px;
  width: 30%;

  background: ${theme.colors.n100};

  margin: ${theme.spacings.mega} 0;

  ${theme.media.giga} {
    width: 1px;
    height: auto;

    margin: 0;
  }
`

const containerAssistantStyles = ({ theme }) => css`
  label: container--assistant;

  min-width: 280px;

  ${theme.media.giga} {
    &:first-of-type {
      margin: ${theme.spacings.mega} 0 0 0;
    }
  }
`

const buttonChatBotStyles = ({ theme }) => css`
  label: button--chatbot;

  width: 90%;
  height: 55px;

  margin: 10px 0 0 0;

  cursor: pointer;
  outline: 0;

  background-image: url(${window && window.env.assetHost}assets/images/lmcv/ajuda/lia.svg);

  background-size: contain;
  background-repeat: no-repeat;

  ${theme.media.giga} {
    width: 305px;
  }
`
const contactWrapperStyles = () => css`
  label: contact-wrapper;

  display: flex;
  justify-content: space-between;
`

const cardWrapperStyles = () => css`
  label: card-wrapper;

  div[class*='card__body'] {
    overflow-y: visible;
  }
`

export const Contact = styled('div')(contactStyles)
export const ContactsContainer = styled('article')(contactsContainerStyles)
export const ContainerFlex = styled('div')(containerFlexStyles)
export const Section = styled('section')(sectionStyles)
export const Separator = styled('div')(separatorStyles)
export const ContainerAssistant = styled('div')(containerAssistantStyles)
export const ButtonChatBot = styled('button')(buttonChatBotStyles)
export const ContactWrapper = styled('div')(contactWrapperStyles)
export const CardWrapper = styled('div')(cardWrapperStyles)
