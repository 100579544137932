import { Button, Text } from '@leroy-merlin-br/backyard-react'
import { FC, useEffect, useState } from 'react'
import { useAccessDataContext } from 'user/my-account/context/my-account-access-data-context'
import { getProfile } from 'user/my-account/services'
import { ShowToastParams, showToast } from 'user/my-account/utils/toast'
import { ProfileResponse } from 'user/my-account/types'

import { Shell } from 'shared/components'

import { formatDate } from 'utils/date'

import { Layout } from '../../Layout'
import * as S from './styled'

export type PasswordChangeConfirmationProps = {
  onNextStep: () => void
}

const PasswordChangeConfirmation: FC<PasswordChangeConfirmationProps> = ({ onNextStep }) => {
  const { contextData, updateData } = useAccessDataContext()

  const [loading, setLoading] = useState(true)
  const [dataLastChangePassword, setData] = useState<string>('')

  useEffect(() => {
    (async () => {
      try {
        const profile: ProfileResponse = await getProfile()

        if (profile.data) {
          const fiscalId = { fiscalId: profile.data.fiscalId }

          const dataLastChange = profile.data.authentication?.lastPasswordChangeOn ?? ''

          if (dataLastChange) {
            setData(formatDate(dataLastChange, 'DD/MM/YYYY'))
          }

          updateData({ ...contextData, ...fiscalId })
        }
      } catch {
        const showToastParams: ShowToastParams = {
          type: 'critical',
          title: 'Não foi possível obter informações do perfil',
          content: 'Tente novamente mais tarde.'
        }

        showToast(showToastParams)
      }
      setLoading(false)
    })()
  }, [updateData])

  if (loading) {
    return (
      <Layout>
        <S.LastChangePasswordShell>
          <Shell height={24} width={320} />
          <Shell height={24} width={100} />
        </S.LastChangePasswordShell>
        <S.ChangePasswordInfoShell>
          <Shell height={24} width={340} />
          <Shell height={24} width={240} />
        </S.ChangePasswordInfoShell>
        <S.ChangePasswordButtonShell>
          <Shell height={48} width={379} />
        </S.ChangePasswordButtonShell>
      </Layout>
    )
  }

  return (
    <Layout>
      {dataLastChangePassword && (
        <>
          <Text noMargin data-testid="last-change-password">
            Sua senha foi alterada pela última vez em:
          </Text>
          <Text isBold>
            {dataLastChangePassword}
          </Text>
        </>
      )}
      <Text noMargin>
        Mantenha sua senha sempre atualizada.
        Clique abaixo para alterar sua senha
      </Text>
      <S.ConfirmationButton>
        <Button isStretch onClick={onNextStep}>
          Alterar Senha
        </Button>
      </S.ConfirmationButton>
    </Layout>
  )
}

export default PasswordChangeConfirmation
