import * as wishlistRepository from './'

class WishlistsService {
  static isInitialLoad = true
  static fetchPromise = null

  static async get (isLogged, isAssistedSale) {
    if (this.isInitialLoad) {
      this.clearStorage()
      this.isInitialLoad = false
    }

    const dataFromStorage = this.getStorage()

    if (dataFromStorage) {
      return dataFromStorage
    }

    if (this.fetchPromise) {
      return this.fetchPromise
    }

    if (isLogged && !isAssistedSale) {
      return this.fetch()
    }
  }

  static async fetch () {
    this.fetchPromise = wishlistRepository
      .ids()
      .then(({ data }) => {
        const items = [...data.products, ...data.contents]

        this.setStorage(items)
        return items
      })
      .catch(() => {
        this.setStorage([])
        return []
      })
      .finally(() => {
        this.fetchPromise = null
      })

    return this.fetchPromise
  }

  static clearStorage () {
    window.sessionStorage.removeItem('wishlists')
  }

  static setStorage (items) {
    sessionStorage.setItem('wishlists', JSON.stringify(items))
  }

  static getStorage () {
    const storageData = sessionStorage.getItem('wishlists')

    return storageData ? JSON.parse(storageData) : null
  }
}

export default WishlistsService
