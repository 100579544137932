export const LmcvBrand = () => (
    <svg width="74" height="36" viewBox="0 0 74 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_185_1701)">
      <path d="M0.0985107 11.4944V7.70898H0.949594V10.7711H2.54485V11.4944H0.0985107Z" fill="black"/>
      <path d="M3.38757 11.4944V7.70898H5.80134V8.4323H4.23866V9.24912H5.59671V9.97243H4.23866V10.7735H5.93247V11.4968L3.38757 11.4944Z" fill="black"/>
      <path d="M9.58659 11.4944H8.57932L7.95374 10.2208H7.55702V11.4952H6.70593V7.70904H8.06984C8.24846 7.70768 8.42623 7.73364 8.59686 7.786C8.75584 7.83549 8.90482 7.91224 9.03702 8.01276C9.17026 8.11581 9.27558 8.25003 9.34354 8.40338C9.42003 8.57592 9.45792 8.76282 9.45462 8.95124C9.46129 9.1853 9.40354 9.41674 9.28758 9.62076C9.176 9.80747 9.01245 9.95832 8.81652 10.0552L9.58659 11.4944ZM7.55702 8.43235V9.49414H7.99133C8.38109 9.49414 8.57598 9.31511 8.57598 8.95704C8.58205 8.88433 8.57187 8.81118 8.54618 8.74281C8.52048 8.67444 8.4799 8.61252 8.42731 8.56145C8.31431 8.47032 8.17104 8.42428 8.02557 8.43235H7.55702Z" fill="black"/>
      <path d="M12.7745 11.0557C12.4811 11.3961 12.0868 11.5663 11.5918 11.5663C11.0968 11.5663 10.7026 11.3961 10.4092 11.0557C10.1163 10.7148 9.96985 10.2287 9.96985 9.59752C9.96985 8.96634 10.1163 8.48276 10.4092 8.14676C10.7015 7.80745 11.0957 7.63752 11.5918 7.63696C12.088 7.63641 12.4822 7.80634 12.7745 8.14676C13.0668 8.48607 13.213 8.96965 13.213 9.59752C13.213 10.2254 13.0668 10.7114 12.7745 11.0557ZM11.5918 10.824C11.6954 10.8255 11.7978 10.8028 11.8908 10.7578C11.9839 10.7078 12.0642 10.6373 12.1255 10.5517C12.2043 10.4362 12.2589 10.3062 12.2859 10.1694C12.3264 9.98147 12.3454 9.78964 12.3427 9.59752C12.3454 9.40676 12.3263 9.21631 12.2859 9.02979C12.2588 8.89324 12.2043 8.76349 12.1255 8.64827C12.0638 8.56231 11.9822 8.49223 11.8875 8.44386C11.7944 8.39908 11.692 8.37668 11.5885 8.37848C11.4794 8.37562 11.3714 8.4007 11.275 8.4513C11.1786 8.5019 11.097 8.5763 11.0381 8.66731C10.9033 8.85931 10.8362 9.1691 10.8368 9.59669C10.834 9.78873 10.8525 9.9805 10.8919 10.1685C10.9182 10.3052 10.9719 10.4352 11.0498 10.5509C11.1113 10.6369 11.1922 10.7075 11.2861 10.757C11.3812 10.803 11.486 10.826 11.5918 10.824Z" fill="black"/>
      <path d="M14.8241 11.4943V10.1701L13.5087 7.70972H14.4725L15.2534 9.28958L16.021 7.70972H16.9848L15.6752 10.1693V11.4934L14.8241 11.4943Z" fill="black"/>
      <path d="M18.545 11.4944V7.70898H19.4571L20.4911 9.61243L21.5351 7.70898H22.443V11.4944H21.5886V9.19036L20.8369 10.6568H20.1503L19.3986 9.19036V11.4944H18.545Z" fill="black"/>
      <path d="M23.3175 11.4944V7.70898H25.7313V8.4323H24.1661V9.24912H25.5241V9.97243H24.1661V10.7735H25.8599V11.4968L23.3175 11.4944Z" fill="black"/>
      <path d="M29.514 11.4944H28.5067L27.8819 10.2208H27.4844V11.4952H26.6333V7.70904H27.9947C28.1733 7.70768 28.3511 7.73364 28.5217 7.786C28.6807 7.83549 28.8297 7.91224 28.9619 8.01276C29.0951 8.11581 29.2004 8.25003 29.2684 8.40338C29.3449 8.57592 29.3828 8.76282 29.3795 8.95124C29.3861 9.1853 29.3284 9.41674 29.2124 9.62076C29.1022 9.80676 28.9405 9.95755 28.7464 10.0552L29.514 11.4944ZM27.4844 8.43235V9.49414H27.9195C28.3093 9.49414 28.5042 9.31511 28.5042 8.95704C28.5103 8.88433 28.5001 8.81118 28.4744 8.74281C28.4487 8.67444 28.4081 8.61252 28.3555 8.56145C28.2427 8.47052 28.0998 8.42449 27.9546 8.43235H27.4844Z" fill="black"/>
      <path d="M30.0828 11.4944V7.70898H30.9338V10.7711H32.5291V11.4944H30.0828Z" fill="black"/>
      <path d="M33.3768 11.4944V7.70898H34.2279V11.4944H33.3768Z" fill="black"/>
      <path d="M35.1031 11.4944V7.70898H35.8949L37.3491 9.9865V7.70898H38.2027V11.4944H37.4109L35.9542 9.21188V11.4944H35.1031Z" fill="black"/>
      <path d="M9.54561 22.426C9.47748 22.6753 9.38605 22.9177 9.2725 23.1501C8.53667 24.6605 6.90132 25.7008 5.04965 25.7008C2.19656 25.7008 -5.33024e-05 23.7485 -5.33024e-05 19.9855C-0.0102937 19.1559 0.120723 18.3305 0.387486 17.5441C0.409202 17.4837 0.433423 17.4241 0.455974 17.3645C0.466831 17.338 0.476019 17.3099 0.486877 17.2817C0.497735 17.2536 0.514439 17.2188 0.528638 17.1874C0.587103 17.0508 0.648908 16.9176 0.715725 16.7901L0.768344 16.6975C1.65033 15.1085 3.21302 14.2478 5.04965 14.2478C7.47595 14.2478 9.05451 15.7557 9.41198 17.2553C9.42367 17.3041 9.43369 17.3521 9.44288 17.4001L8.1341 17.7708L6.99988 18.0919C6.69169 17.1973 6.01433 16.5055 4.94692 16.5055C3.65652 16.5055 2.84636 17.6219 2.69184 19.3673C2.67403 19.5653 2.66512 19.7708 2.66512 19.9838C2.66337 20.3587 2.69411 20.733 2.75699 21.1027C3.00755 22.551 3.78263 23.4415 4.94358 23.4415C6.05274 23.4415 6.79191 22.8514 7.18112 21.9568L8.60767 22.239L9.54561 22.426ZM38.1634 18.399V25.4335H35.5559V18.9477C35.5559 17.4423 34.714 16.7306 33.7084 16.7306C32.7028 16.7306 31.8199 17.463 31.8199 19.0296V25.4368H29.2132V18.9477C29.2132 17.4423 28.3922 16.7306 27.3858 16.7306C26.3593 16.7306 25.4765 17.4837 25.4765 19.0478V25.4351H22.8698V16.767H21.7698V14.5135H25.4155V15.7341C25.7507 15.2788 26.1887 14.9074 26.6945 14.6496C27.2003 14.3918 27.76 14.2548 28.3288 14.2495C29.4981 14.2495 30.5454 14.7377 31.0791 15.8359C31.7364 14.8594 32.8857 14.2495 34.2204 14.2495C36.2524 14.2495 38.1617 15.5513 38.1617 18.399H38.1634ZM15.7254 23.4431C14.247 23.4431 13.4461 22.2431 13.4461 19.9855C13.4461 17.7071 14.247 16.5096 15.7254 16.5096C17.2037 16.5096 18.0038 17.7096 18.0038 19.9855C18.0038 22.2431 17.2029 23.4431 15.7254 23.4431ZM15.7254 14.2495C12.8105 14.2495 10.7784 16.1612 10.7784 19.9855C10.7784 23.7684 12.8105 25.7008 15.7254 25.7008C18.6603 25.7008 20.6723 23.7684 20.6723 19.9855C20.6723 16.1612 18.6603 14.2495 15.7254 14.2495Z" fill="black"/>
      <path d="M69.0296 29.3652C69.6166 29.366 70.2008 29.2869 70.766 29.1302C71.9646 28.7702 72.7029 28.1429 73.2199 27.019C73.8296 25.6949 73.9716 24.1018 73.9716 22.6394V20.2394C73.9716 18.1978 74.0944 16.1222 73.9933 14.0855C73.9604 13.3693 73.7739 12.6682 73.4462 12.0289C73.1796 11.521 72.8331 11.0584 72.4198 10.6585C71.9153 10.1644 71.383 9.69906 70.8253 9.2648L59.0948 0.337626C58.8179 0.126092 58.4789 0.00986419 58.1292 0.0065918H58.1025C57.7531 0.00980233 57.4144 0.126046 57.1378 0.337626L45.4106 9.26811C44.8527 9.70236 44.32 10.1677 43.8153 10.6618C43.4025 11.062 43.0563 11.5246 42.7897 12.0322C42.462 12.6715 42.2753 13.3726 42.2418 14.0888C42.1416 16.1255 42.2635 18.2011 42.2635 20.2427V22.6427C42.2635 24.1051 42.403 25.6974 43.0152 27.0223C43.5314 28.1462 44.268 28.7735 45.4682 29.1335C46.0338 29.2901 46.6183 29.3692 47.2055 29.3685L69.0296 29.3652Z" fill="url(#paint0_linear_185_1701)"/>
      <path d="M60.0503 13.051L63.752 9.38232L67.4545 13.051H60.0503Z" fill="#7AB829"/>
      <path d="M66.164 21.916C65.774 22.8106 65.0356 23.4007 63.9265 23.4007C62.4899 23.4007 61.648 22.0376 61.648 19.943C61.648 17.8484 62.4899 16.4672 63.9265 16.4672C64.9939 16.4672 65.6712 17.1582 65.9794 18.0537L68.4191 17.3593C68.1318 15.8134 66.5307 14.207 64.0259 14.207C61.1728 14.207 58.9761 16.2818 58.9761 19.943C58.9761 23.7061 61.1728 25.6583 64.0259 25.6583C66.1607 25.6583 68.0082 24.2754 68.5218 22.3836L66.164 21.916ZM56.007 14.4677H58.6137L54.5295 25.3919H51.6555L48.3079 16.444H46.8572V14.471H50.239L53.1346 22.7088L56.007 14.4677Z" fill="white"/>
      </g>
      <defs>
      <linearGradient id="paint0_linear_185_1701" x1="68.3974" y1="31.5641" x2="48.0348" y2="7.0731" gradientUnits="userSpaceOnUse">
      <stop stopColor="#7808CF"/>
      <stop offset="1" stopColor="#9A21AA"/>
      </linearGradient>
      <clipPath id="clip0_185_1701">
      <rect width="74" height="36" fill="white"/>
      </clipPath>
      </defs>
    </svg>
)
