import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Card as BaseCard } from '@leroy-merlin-br/backyard-react'

const cardStyles = ({ theme }) => css`
  label: products--card;

  @media (max-width: ${theme.breakpoints.kilo}rem) {
    & > div {
      padding: 0 ${theme.spacings.byte};
    }
  }
`

const spacingWrapperStyles = ({ theme }) => css`
  label: products--spacing-wrapper;

  margin-bottom: ${theme.spacings.mega};
`

const productListStyles = ({ theme, itemsPerRow }) => css`
  label: products--product-list;

  display: grid;
  grid-gap: ${theme.spacings.byte};
  grid-template-columns: repeat(2, 1fr);
  justify-items: center;

  ${theme.media.mega} {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: ${theme.spacings.mega};
  }

  ${theme.media.giga} {
    grid-template-columns: repeat(${itemsPerRow}, 1fr);
    grid-gap: ${theme.spacings.mega};
  }
`

const paginationWrapper = ({ theme }) => css`
  label: products--pagination;

  align-items: center;
  display: flex;
  justify-content: center;
  padding-top: ${theme.spacings.giga};
`

export const Card = styled(BaseCard)(cardStyles)
export const SpacingWrapper = styled('div')(spacingWrapperStyles)
export const ProductList = styled('div')(productListStyles)
export const PaginationWrapper = styled('div')(paginationWrapper)
