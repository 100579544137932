import { concierge as Api } from 'api'

import { getUserInfo } from './Storage'
import { StreamSubscribe } from './types'
import { AssessmentFormData } from '../Components/Feedback/Assessment/types'

let currentRound = 0 as number

export const sendChatMessage = async (
  prompt: string,
  subscribe: () => StreamSubscribe
) => {
  const currentLocation = window.location.href
  const userInfo = await getUserInfo()

  await Api.chat(
    {
      prompt,
      sessionId: (userInfo?.userTrackingId ?? '0').toString(),
      currentUrl: currentLocation,
      interactions: getNextInteractionRound(),
      region: userInfo?.region ?? 'outros',
      zipCode: userInfo?.zipCode ?? ''
    },
    {
      streamSubscribe: subscribe()
    }
  )
}

const getNextInteractionRound = (): number => {
  currentRound = currentRound + 1

  return currentRound
}

export const sendFeedback = async (data: AssessmentFormData) => {
  const userInfo = await getUserInfo()
  const feedbackData = {
    scale: data.scale,
    sessionId: (userInfo?.userTrackingId ?? '0').toString(),
    details: data?.details?.length ? data?.details : undefined
  }

  return Api.feedback(feedbackData)
}

export const { cancelRequest } = Api
