import { Stack, Heading } from '@leroy-merlin-br/backyard-react'
import { useEffect, FunctionComponent, useState } from 'react'
import { datadogRum } from '@datadog/browser-rum'
import { getProductReviewsSummary } from 'hpdp/services/reviews'
import type { GetProductReviewsSummaryData } from 'hpdp/services/reviews'

import { Comments, HelpfulComments, Resume } from './containers'
import { Props } from './types'
import { ReviewsSkeleton } from './components/ReviewsSkeleton'
import { EmptyState } from './components/EmptyState'
import styles from './Reviews.styles'

const Reviews: FunctionComponent<Props> = ({ productId }) => {
  const [summaryResponse, setSummaryResponse] =
    useState<GetProductReviewsSummaryData>({})
  const [isLoading, setIsLoading] = useState(true)

  const { badRate, generalScores, goodRate } = summaryResponse

  useEffect(() => {
    const doAsync = async () => {
      try {
        const response = await getProductReviewsSummary(productId)
        setSummaryResponse(response)
      } catch (e) {
        datadogRum.addError(e)
      } finally {
        setIsLoading(false)
      }
    }

    doAsync()
  }, [productId])

  const isDataNotAvailable = !generalScores

  if (isLoading) {
    return (
      <div className={styles().base()}>
        <div className={styles().wrapper()}>
          <ReviewsSkeleton />
        </div>
      </div>
    )
  }

  if (isDataNotAvailable) {
    return (
      <div className={styles().base()}>
        <div className={styles().wrapper()}>
          <Heading size="tera">Avaliações</Heading>
          <EmptyState />
        </div>
      </div>
    )
  }

  return (
    <div className={styles().base()}>
      <div className={styles().wrapper()}>
        <Heading size="tera">Avaliações</Heading>

        <Stack space="zetta">
          <Resume generalScores={generalScores} />
          <HelpfulComments badRate={badRate} goodRate={goodRate} />
          <Comments productId={productId} />
        </Stack>
      </div>
    </div>
  )
}

export default Reviews
