/**
 * @param {HTMLElement} DOMRef
 * @return {Number}
 */
export default (DOMRef) => {
  if (!DOMRef) {
    return
  }

  const { clientHeight } = DOMRef
  const { marginTop, marginBottom, borderTopWidth, borderBottomWidth } = getComputedStyle(DOMRef)

  return (
    clientHeight +
    parseInt(marginTop, 10) +
    parseInt(marginBottom, 10) +
    parseInt(borderTopWidth, 10) +
    parseInt(borderBottomWidth, 10)
  )
}
