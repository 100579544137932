import { tv } from 'tailwind-variants'

const styles = tv({
  base: `
    w-full
    h-full
  `
})

export default styles
