import { SummaryDesktop as Summary, SummaryValues, Separator, CouponsSection } from 'frontendCheckout/ui/desktop'
import { formatters } from 'frontendCheckout/utils'

type SummaryDesktopProps = {
  couponsAvailable: number
}

export const SummaryDesktop = ({ couponsAvailable }: SummaryDesktopProps) => {
  return (
    <Summary.root>
      <div>
        <Summary.main>
          <Summary.title title='Resumo do seu pedido' />
          <SummaryValues.root>
            <SummaryValues.item name='Subtotal' value={formatters.numberToPrice(9000.12)} />
            <SummaryValues.item name='Instalação' value={formatters.numberToPrice(250)} />
            <SummaryValues.item name='Frete' value={formatters.numberToPrice(50)} />
            <SummaryValues.item name='Cupom' value='-10%' colorName="tertiary" />
            <SummaryValues.item name='Leroy Merlin Com Você' value={formatters.numberToPrice(-100)} colorName="tertiary" />
          </SummaryValues.root>

          <Separator />
          <Summary.price numberInstallments={10} totalPrice={formatters.numberToPrice(9000.12)}/>
          <Separator />
        </Summary.main>

        <CouponsSection.root noMargin>
          <CouponsSection.main>
            <CouponsSection.icon />
            <CouponsSection.text>Benefícios e cashback</CouponsSection.text>
            <CouponsSection.coupons amount={couponsAvailable} />
          </CouponsSection.main>
          <CouponsSection.button onClick={() => {}} />
        </CouponsSection.root>

        <Separator />
      </div>

      <div>
        <Summary.installments />

        <Summary.button onConfirm={() => {}} >
          Confirmar
        </Summary.button>
      </div>
    </Summary.root>
  )
}
