import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { Theme } from 'scripts/react-components/themes/Theme'

const wrapperStyles = ({ theme }: Theme) => css`
  label: mobile-layout-wrapper;

  background-color: white;
  margin-top: -${theme.spacings.mega};
  padding: ${theme.spacings.giga} ${theme.spacings.giga};
  min-width: 375px;
  min-height: 500px;

  & h1 {
    margin-top: ${theme.spacings.kilo};
  }

  & div > svg {
    color: ${theme.colors.p700};
  }

  a {
    color: ${theme.colors.p700};
  }
`

export const Wrapper = styled('div')(wrapperStyles)

const buttonWrapperStyles = ({ theme }: Theme) => css`
  label: mobile-layout-mobile-wrapper;
  & button {
    margin-top: ${theme.spacings.giga};
  }
`

export const ButtonWrapper = styled('div')(buttonWrapperStyles)

const selectWrapperStyles = ({ theme }: Theme) => css`
  label: mobile-layout-select;
  margin-bottom: ${theme.spacings.mega};
`

export const SelectWrapper = styled('div')(selectWrapperStyles)

const securityCodeStyles = ({ theme }: Theme) => css`
  label: mobile-layout-check-code;
  display: flex;
  gap: ${theme.spacings.mega};
  margin-top: ${theme.spacings.bit};
  margin-bottom: ${theme.spacings.byte};
  justify-content: center;
`

export const SecurityCode = styled('div')(securityCodeStyles)

const securityCodeFieldStyles = () => css`
  label: mobile-layout-check-code__field;
  width: 40px;
  input {
    text-align: center;
  }
`

export const SecurityCodeField = styled('div')(securityCodeFieldStyles)

const resendCodeWrapperStyles = ({ theme }: Theme) => css`
  label: mobile-layout-check-code__resend-code;
  font-size: 14px;
  text-align: center;
  user-select: none;
  span {
    width: ${theme.spacings.mega};
    display: inline-block;
  }
`

export const ResendCodeWrapper = styled('div')(resendCodeWrapperStyles)
