import React, { forwardRef } from 'react'

import * as S from '../../styled'

const Item = forwardRef(({ value, children, isActive, ...rest }, ref) => {
  return (
    <S.Option
      ref={ref}
      role="option"
      id={value}
      aria-selected={isActive}
      isActive={isActive}
      {...rest}
    >
      {children}
    </S.Option>
  )
})

export default Item
