import { tv } from 'tailwind-variants'

export const button = tv({
  base: 'w-full  justify-center text-center flex gap-2 items-center px-4 rounded-lg font-semibold cursor-pointer mb-2',
  variants: {
    color: {
      default: 'text-white bg-purple-600',
      green: 'text-white bg-green-600',
      actived: 'text-tertiary-300 bg-tertiary-100',
      disabled: 'text-white bg-gray-200 cursor-not-allowed'
    },
    size: {
      lg: 'text-lg  h-11',
      sm: 'text-sm  h-8'
    }
  },
  defaultVariants: {
    size: 'lg',
    color: 'default'
  }
})
