export const getLinks = (loyaltyStatus, cashbackEnabled) => {
  const loyaltyProRelationship =
    localStorage.getItem('@loyalty:proRelationship') === 'true'

  const showClientsMenu = loyaltyStatus.isProEAD() && loyaltyProRelationship

  const links = [
    {
      id: 'home',
      label: 'Início',
      path: '/'
    },
    {
      id: 'advantages',
      label: 'Vantagens',
      path: '/minhas-vantagens'
    },
    {
      id: 'exchange',
      label: 'Troque',
      path: '/troque'
    },
    {
      id: 'points',
      label: 'Pontos',
      path: '/meus-pontos'
    },
    {
      id: 'faq',
      label: 'Dúvidas',
      path: '/duvidas'
    }
  ]

  if (cashbackEnabled) {
    links.push({
      id: 'cashback',
      label: 'Cashback',
      path: '/cashback'
    })
  }

  if (showClientsMenu) {
    links.splice(3, 0, {
      id: 'customers',
      label: 'Clientes',
      path: '/clientes'
    })
  }

  return links
}
