import { useContext } from 'react'
import { Button, ButtonGroup, Text, Stack } from '@leroy-merlin-br/backyard-react'

import { useUserResources } from 'shared/hooks'

import { EntryKeyContext } from '../../../../context/EntryKeyContext'
import { CleanFiltersButton } from '../../../CleanFiltersButton'
import { StockFilter } from '../../../StockFilter'
import * as S from './styled'

const Footer = () => {
  const {
    metadata: { totalCount },
    toggleMobileFilter,
    scrollToTop
  } = useContext(EntryKeyContext)

  const handleFilterClick = () => {
    toggleMobileFilter()
    scrollToTop()
  }
  const { userControls: { isAssistedSale } } = useUserResources()

  const totalProducts = () => {
    const label =
      totalCount === 1 ? 'Produto encontrado' : 'Produtos encontrados'
    const value = totalCount?.toLocaleString('pt-BR')

    return { label, value }
  }

  const { label, value } = totalProducts()

  return (
    <S.Footer>
      <Stack space="giga" alignX="center">
         {isAssistedSale && <StockFilter />}

        <S.TotalProducts>
          <Text size="mega" as="span" isBold noMargin>
            {value}{' '}
          </Text>
          <Text size="mega" as="span" isBold noMargin>
            {label}
          </Text>
        </S.TotalProducts>

        <ButtonGroup label="botões de filtros" align="center" inlineMobile>
          <CleanFiltersButton variant="outline" />
          <Button size="mega" onClick={handleFilterClick}>
            Aplicar filtros
          </Button>
        </ButtonGroup>
      </Stack>
    </S.Footer>
  )
}

export default Footer
