import { Heading, Stack } from '@leroy-merlin-br/backyard-react'

import { AlertMessage } from 'shared/components/AlertMessage'

import * as S from './styled'
import { Product } from './components'

const AssociatedProducts = ({
  onAddToCartClick,
  onProductNameClick,
  hasError,
  products,
  title
}) => {
  if (!products.length) return null

  return (
    <Stack space="kilo">
      <Heading as="h2" size="kilo" noMargin>
        {title}
      </Heading>

      {hasError && (
        <S.AnimateNotification>
          <AlertMessage type="critical">
            Tivemos um erro ao adicionar o produto, mas você pode tentar
            novamente.
          </AlertMessage>
        </S.AnimateNotification>
      )}

      <S.StackBordered space="kilo">
        {products.map((product, index) => (
          <Product
            key={product.id}
            {...product}
            onAddToCartClick={() => onAddToCartClick(product, index)}
            onProductNameClick={event =>
              onProductNameClick(event, product, index)
            }
          />
        ))}
      </S.StackBordered>
    </Stack>
  )
}

export default AssociatedProducts
