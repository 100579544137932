const openScreen = (screen, args) => {
  if (!window.ReactNativeWebView || !window.ReactNativeWebView.postMessage) {
    return
  }

  window.ReactNativeWebView.postMessage(
    JSON.stringify({ type: screen, ...args })
  )
}

const openSignIn = () => {
  openScreen('signIn')
}

export default {
  openSignIn
}
