import {
  Button,
  Row,
  Col,
  Text,
  Stack,
  Heading
} from '@leroy-merlin-br/backyard-react'

import { RangeSlider } from 'lmcv/components'

import WizardStep from '../components/WizardStep'
import * as Ilustrations from '../components/Ilustrations'

const WeeksCount = ({
  step,
  errors,
  register,
  hasError,
  prevStep,
  isLoading,
  customerData
}) => {
  const { projectWeeks } = customerData
  const isDisabled = +projectWeeks <= 0 || +projectWeeks > 12

  return (
    <WizardStep step={2} currentStep={step}>
      <Ilustrations.Time />

      <Heading as="h3" size="mega" align="center">
        Adicione um tempo para esse projeto
      </Heading>

      <RangeSlider
        {...register('projectWeeks', { min: 1, max: 12, required: true })}
        step={1}
        errors={errors}
        defaultValue={projectWeeks}
      />

      <Stack space="kilo">
        <Row>
          <Col size={{ untilKilo: 2 }}>
            <Button
              type="button"
              variant="outline"
              onClick={prevStep}
              isStretch
            >
              Voltar
            </Button>
          </Col>
          <Col size={{ untilKilo: 2 }}>
            <Button
              type="submit"
              isLoading={isLoading}
              isDisabled={isDisabled}
              isStretch
            >
              Próxima etapa
            </Button>
          </Col>
        </Row>

        {hasError && (
          <Text size="kilo" color="r500" align="center">
            Não possível efetuar o cadastro, por favor tente novamente.
          </Text>
        )}
      </Stack>
    </WizardStep>
  )
}

export default WeeksCount
