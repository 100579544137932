import { css } from '@emotion/react'
import styled from '@emotion/styled'

const baseStyles = ({ theme }) => css`
  label: toggle-button;

  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;

  ${theme.media.giga} {
    width: auto;
  }
`

export const SwitchFilter = styled('div')(baseStyles)
