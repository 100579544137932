import { css } from '@emotion/react'
import styled from '@emotion/styled'

const SIDEBAR_WIDTH = 172

const wrapperStyles = ({ theme }) => css`
  label: wrapper;

  display: flex;
  column-gap: ${theme.spacings.giga};

  padding: 0 ${theme.spacings.kilo} ${theme.spacings.tera};

  & > div:first-of-type {
    display: none;
  }

  & > div:last-of-type {
    width: 100%;
  }

  ${theme.media.giga} {
    & > div:first-of-type {
      display: block;
      width: ${SIDEBAR_WIDTH}px;
      flex-shrink: 0;
    }

    & > div:last-of-type {
      width: 100%;
    }
  }
`

export const Wrapper = styled('div')(wrapperStyles)
