import { useState, useEffect, useRef } from 'react'
import { Card, Icon, Text } from '@leroy-merlin-br/backyard-react'
import { ChevronDownSolid, ChevronUp } from '@leroy-merlin-br/backyard-icons'

import * as S from './styled'

const EXTRA_HEIGHT = 50

const Accordion = ({
  title,
  children,
  defaultOpen = false,
  isDisabled = false
}) => {
  const [isOpen, setIsOpen] = useState(defaultOpen)
  const [openHeight, setIsOpenHeight] = useState(0)

  const ref = useRef()

  useEffect(() => {
    updateHeight()
  })

  const updateHeight = () =>
    setIsOpenHeight(ref?.current?.scrollHeight + EXTRA_HEIGHT)

  const handleClick = () => !isDisabled && setIsOpen(prev => !prev)

  return (
    <S.Wrapper>
      <Card target="card">
        <S.Header onClick={handleClick}>
          <Text as="strong" size="giga" isBold noMargin>
            {title ?? ''}
          </Text>
          <Icon as={!isOpen ? ChevronDownSolid : ChevronUp} size="byte" />
        </S.Header>

        <S.Content ref={ref} isOpen={isOpen} openHeight={openHeight}>
          {children}
        </S.Content>
      </Card>
    </S.Wrapper>
  )
}

export default Accordion
