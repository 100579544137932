import { FunctionComponent } from 'react'
import { Text } from '@backyard-ui/core'

import { Props } from './types'
import { generatePageRange } from './utils'

const PageIndicator: FunctionComponent<Props> = ({
  currentPage,
  pageSize,
  totalReviewsCount
}) => (
  <Text asChild>
    <span>
      {`${generatePageRange(
        currentPage,
        pageSize,
        totalReviewsCount
      )} de ${totalReviewsCount} avaliações`}
    </span>
  </Text>
)

export default PageIndicator
