const MasterCardIcon = () => (
  <svg
    width="30"
    height="21"
    viewBox="0 0 30 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="30" height="21" rx="3.5" fill="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.8644 8.29095C16.6644 7.91279 16.4215 7.56113 16.1425 7.24154C15.8553 6.91263 15.53 6.61845 15.1731 6.36535C14.2758 5.72906 13.1812 5.35352 12 5.35352C8.96732 5.35352 6.5 7.82084 6.5 10.8535C6.5 13.8862 8.96732 16.3535 12 16.3535C13.1812 16.3535 14.2758 15.9779 15.1731 15.3417C15.5301 15.0886 15.8553 14.7942 16.1425 14.4654C16.4215 14.1458 16.6644 13.7941 16.8644 13.4161C17.2694 12.6502 17.5 11.7784 17.5 10.8536C17.5 9.92843 17.2694 9.0567 16.8644 8.29095Z"
      fill="#EB001B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.8644 8.29095C22.6644 7.91279 22.4215 7.56113 22.1425 7.24154C21.8553 6.91263 21.53 6.61845 21.1731 6.36535C20.2758 5.72906 19.1812 5.35352 18 5.35352C14.9673 5.35352 12.5 7.82084 12.5 10.8535C12.5 13.8862 14.9673 16.3535 18 16.3535C19.1812 16.3535 20.2758 15.9779 21.1731 15.3417C21.5301 15.0886 21.8553 14.7942 22.1425 14.4654C22.4215 14.1458 22.6644 13.7941 22.8644 13.4161C23.2694 12.6502 23.5 11.7784 23.5 10.8536C23.5 9.92843 23.2694 9.0567 22.8644 8.29095Z"
      fill="#F79E1B"
    />
    <path
      d="M16.1425 7.24158C16.4215 7.56117 16.6644 7.91282 16.8644 8.29099C17.2694 9.05674 17.5 9.92847 17.5 10.8536C17.5 11.7785 17.2694 12.6503 16.8644 13.4161C16.6644 13.7941 16.4215 14.1459 16.1425 14.4655C15.8553 14.7943 15.5301 15.0887 15.1731 15.3417C15.1157 15.3824 15.0575 15.422 14.9986 15.4605C13.4953 14.4778 12.5 12.7797 12.5 10.8536C12.5 8.92741 13.4953 7.22932 14.9986 6.24658C15.0575 6.2851 15.1157 6.32471 15.1731 6.36538C15.53 6.61849 15.8553 6.91267 16.1425 7.24158Z"
      fill="#FF5F00"
    />
  </svg>
)

export default MasterCardIcon
