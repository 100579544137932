import styled from '@emotion/styled'
import { css } from '@emotion/react'

const instructionWrapperStyles = ({ theme }) => css`
  label: instruction-wrapper;

  & > div {
    margin-bottom: ${theme.spacings.bit};
  }
`

const buttonsWrapperStyles = ({ theme }) => css`
  label: buttons-wrapper;

  display: flex;
  flex-direction: column;

  gap: ${theme.spacings.kilo};

  a {
    box-sizing: border-box;
    text-decoration: none;
  }
`

export const InstructionWrapper = styled('div')(instructionWrapperStyles)
export const ButtonsWrapper = styled('div')(buttonsWrapperStyles)
