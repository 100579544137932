import { Constants } from './'

export const getList = (data) => ({
  type: Constants.CROSS_SERVICE_GET_LIST,
  data
})

export const clearServices = () => ({
  type: Constants.CROSS_SERVICE_LIST_CLEAR
})

export const listError = error => ({
  type: Constants.CROSS_SERVICE_GET_LIST_ERROR,
  error
})

export const updateList = data => ({
  type: Constants.CROSS_SERVICE_LIST_UPDATE,
  data
})

export const removeService = id => ({
  type: Constants.CROSS_SERVICE_REMOVE_ITEM,
  id
})

export const serviceRemovedUpdateList = data => ({
  type: Constants.CROSS_SERVICE_LIST_UPDATE_AFTER_REMOVE_ITEM,
  data
})

export const servicePostQuantity = data => ({
  type: Constants.CROSS_SERVICE_POST_QUANTITY,
  data
})

export const quantityError = error => ({
  type: Constants.CROSS_SERVICE_POST_QUANTITY_ERROR,
  error
})

export const serviceUpdateQuantity = data => ({
  type: Constants.CROSS_SERVICE_QUANTITY_UPDATE,
  data
})

export const serviceOverridePostPayload = data => ({
  type: Constants.CROSS_SERVICE_POST_OVERRIDE,
  data
})

export const serviceDelete = id => ({
  type: Constants.CROSS_SERVICE_DELETE,
  id
})
