import { useState, useEffect, memo } from 'react'

import * as dataLayer from 'scripts/utils/data-layer'

import * as S from './styled'

const SCRIPT_YOUTUBE_PLAYER_ID = 'SCRIPT_YOUTUBE_PLAYER_ID'

type Props = {
  alt: string
  videoId: string
  thumbUrl: string
  index: number
  isCurrentSlide?: boolean
}

const YoutubePlayer = ({
  alt,
  videoId,
  thumbUrl,
  index,
  isCurrentSlide
}: Props) => {
  const [clicked, setClicked] = useState(false)
  const [youtubeVideoPlayer, setYoutubeVideoPlayer] = useState<YT.Player>()

  const implementYoutubePlayer = (videoId: string) => {
    const hasYoutubeScriptInWindow = Boolean(
      document.getElementById(SCRIPT_YOUTUBE_PLAYER_ID)
    )

    if (!hasYoutubeScriptInWindow) {
      importYoutubeScript().then(() => implementYoutubePlayer(videoId))
    } else {
      const carousel = document.getElementById('current-carousel')
      const currentSlide = carousel?.querySelector(`[data-index="${index}"]`)
      const videoElement = currentSlide?.querySelector(`[id="${videoId}"]`)

      const ytPlayer = new YT.Player(videoElement as HTMLElement, {
        height: '375',
        width: '100%',
        videoId,
        events: {
          onReady: (event) => {
            setYoutubeVideoPlayer(ytPlayer)
            event.target.playVideo()
          }
        }
      })
    }
  }

  const importYoutubeScript = () => {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script')
      script.setAttribute('id', SCRIPT_YOUTUBE_PLAYER_ID)
      script.setAttribute('data-testid', 'youtube-script-id')
      script.addEventListener('load', (e) => {
        YT.ready(() => {
          setClicked(true)
          resolve(YT)
        })
      })

      script.src = 'https://www.youtube.com/iframe_api'
      const firstScriptTag = document.getElementsByTagName('script')[0]
      firstScriptTag.parentNode?.insertBefore(script, firstScriptTag)
    })
  }

  useEffect(() => {
    if (!isCurrentSlide && youtubeVideoPlayer) {
      youtubeVideoPlayer.pauseVideo()
    }
  }, [isCurrentSlide, youtubeVideoPlayer])

  return (
    <S.YoutubePlayer clicked={clicked}>
      <S.Thumb
        id={videoId}
        data-testid={videoId}
        onClick={() => implementYoutubePlayer(videoId)}
      >
        <img
          src={thumbUrl}
          alt={alt}
          onClick={() => {
            dataLayer.midiasCarouselVideoStart(videoId)
          }}
        />
      </S.Thumb>
    </S.YoutubePlayer>
  )
}

export default memo(YoutubePlayer)
