import { useContext } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { ChevronLeft } from '@leroy-merlin-br/backyard-icons'

import { onMenuClick } from 'scripts/utils/data-layer'

import { Ellipsis } from 'shared/components/Ellipsis'

import { DropdownContext } from 'site/Header/contexts/DropdownContext'
import { HeaderContext } from 'site/Header/contexts/HeaderContext'

import ShellSubElement from '../shell/menuSubElement'

const ChildrenMobile = ({ item, rootElement }) => {
  const headerContext = useContext(HeaderContext)
  const dropdownContext = useContext(DropdownContext)
  const { activatedParent, toggleChildren } = headerContext
  const {
    setItem,
    ActivatedItem,
    childrenElements,
    childrenLoading
  } = dropdownContext

  const parent = item.name

  const isNotDepartament = activatedParent !== 'Departamentos'

  const subElementsClasses = classnames('menu-sub-element', {
    'is-active': ActivatedItem === item.name
  })

  const trigger = () => {
    ActivatedItem === null ? toggleChildren() : setItem()
  }

  const preventPropagation = event => {
    event.stopPropagation()
  }

  const onItemClick = (event, item) => {
    event.preventDefault()
    event.stopPropagation()

    onMenuClick({ rootElement, parent, item, isMobile: true })

    const anchorElement = event.currentTarget.querySelector('a')
    const url = anchorElement.href

    window.location.href = url
  }

  const renderElements = () =>
    childrenElements[item.id] &&
    childrenElements[item.id].map(childItem => {
      return (
        <li
          className="menu-element"
          key={`${childItem.url}-${childItem.name}`}
          onClick={event => onItemClick(event, childItem)}
        >
          <span>
            <a href={childItem.url}>
              <Ellipsis itemProp="name">{childItem.name}</Ellipsis>
            </a>
          </span>
        </li>
      )
    })

  return (
    <ul className={subElementsClasses} onClick={preventPropagation}>
      {isNotDepartament && (
        <span
          className="menu-list-mobile-categories-title menu-element-icon"
          onClick={trigger}
        >
          <ChevronLeft width={25} height={25} />
          <span>{activatedParent}</span>
        </span>
      )}
      {childrenLoading ? <ShellSubElement items={15} /> : renderElements()}
    </ul>
  )
}

ChildrenMobile.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    url: PropTypes.string,
    hasChildren: PropTypes.bool
  }).isRequired
}

export default ChildrenMobile
