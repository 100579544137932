import { useMemo, useRef, FunctionComponent } from 'react'
import querystring from 'querystring'

import styles from './YoutubeVideo.styles'
import { Props } from './types'

const YoutubeVideo: FunctionComponent<Props> = ({
  isActive,
  youtubeId,
  isInitialSlide
}) => {
  const ref = useRef<HTMLIFrameElement>(null)

  useMemo(() => {
    if (!isActive) {
      ref.current?.contentWindow?.postMessage(
        JSON.stringify({ event: 'command', func: 'pauseVideo', args: [] }),
        '*'
      )
    }
  }, [isActive])

  return (
    <iframe
      ref={ref}
      className={styles()}
      src={`https://www.youtube.com/embed/${youtubeId}?${querystring.stringify({
        enablejsapi: 1,
        autoplay: isInitialSlide ? 1 : 0
      })}`}
      allow="autoplay"
    />
  )
}

export default YoutubeVideo
