import styled from '@emotion/styled'
import { css } from '@emotion/react'

const containerStyles = ({ theme }) => css`
  label: container;

  margin: ${theme.spacings.mega} 0;
`

export const Container = styled('div')(containerStyles)
