import styled from '@emotion/styled'
import { css } from '@emotion/react'

import type { Theme } from 'scripts/react-components/themes/Theme'

const wrapperStyles = ({ theme }: Theme) => css`
  label: promotion__wrapper;
  background-color: ${theme.colors.p200};
  color: ${theme.colors.n900};
  width: 100%;
  height: 77px;
  padding: ${theme.spacings.mega};
  box-sizing: border-box;
  display: flex;
`
const wrapperIcon = () => css`
  label: promotion__icon;
  max-width: 40px;
`
const wrapperFirstLine = ({ theme }: Theme) => css`
  label: promotion__text-promotion;
  font-size: ${theme.typography.text.kilo.fontSize};
  line-height: ${theme.typography.text.kilo.lineHeight};
  font-weight:  ${theme.font.weight.regular};
`
const wrapperSecondLine = ({ theme }: Theme) => css`
  label: promotion__text-call;
  font-size: ${theme.typography.text.mega.fontSize};
  line-height: ${theme.typography.text.mega.lineHeight};
  font-weight: ${theme.font.weight.semibold};
`
const wrapperButton = () => css`
  label: promotion__button;
  margin-top: 5px;
  margin-left: auto;

  button {
    padding: 8px 8px;
    font-size: 14px;
    line-height: unset;
  }
`
const wrapperText = ({ theme }: Theme) => css`
  label: promotion__text;
  padding-left: ${theme.spacings.byte};
`

export const Wrapper = styled('div')(wrapperStyles)
export const WrapperText = styled('div')(wrapperText)
export const WrapperButton = styled('div')(wrapperButton)
export const WrapperFirstLine = styled('div')(wrapperFirstLine)
export const WrapperSecondLine = styled('div')(wrapperSecondLine)
export const WrapperIcon = styled('div')(wrapperIcon)
