import { useContext } from 'react'
import { Icon } from '@leroy-merlin-br/backyard-react'
import { Plus } from '@backyard-ui/icons'

import { EntryKeyContext } from '../../context/EntryKeyContext'
import { replacer } from '../../utils/buildUrl'
import { Select } from './components'
import * as S from './styled'

const Facets = () => {
  const { filters, onOptionClick, query } = useContext(EntryKeyContext)

  const facetFiltersList = filters.filter(filter => Boolean(filter.highlight))

  const createHandleChange = (filterKey, filterTypeIndex) => {
    const fixedParams = {
      name: filterKey,
      type: 'filter'
    }

    const fixedDataLayerOptions = {
      filter: 'Facet',
      filterType: { index: filterTypeIndex, value: filterKey }
    }

    return (selectedIndex, selectedValue) => {
      const value =
        selectedValue === ''
          ? query[filterKey].value
          : replacer(selectedValue, ' ', '_')

      onOptionClick({
        ...fixedParams,
        value,
        dataLayerOptions: {
          ...fixedDataLayerOptions,
          filterElement: { index: selectedIndex - 1, value }
        }
      })
    }
  }

  return (
    <S.Facets>
      {facetFiltersList.map((filter, index) => (
        <S.SelectWrapper
          key={filter.filterKey}
          title={filter.selected ? filter.options[0].query : filter.name}
        >
          <Select
            name={filter.filterKey}
            placeholder={filter.name}
            onChange={createHandleChange(filter.filterKey, index)}
            value={filter.selected ? filter.options[0].query : ''}
          >
            {filter.options.map(option => (
              <option key={option.query} value={option.query}>
                {option.label} ({option.quantity})
              </option>
            ))}
          </Select>

          <Icon as={Plus} />
        </S.SelectWrapper>
      ))}
    </S.Facets>
  )
}

export default Facets
