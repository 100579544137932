import { Fragment, useState, useCallback, useEffect } from 'react'
import {
  Text,
  Icon,
  Inline,
  PasswordField as PasswordBase,
  Stack
} from '@leroy-merlin-br/backyard-react'
import { Checkmark } from '@backyard-ui/icons'
import { Controller } from 'react-hook-form'
import { useImmer } from 'use-immer'

import * as validator from 'utils/validators/validators'

import * as S from './styled'

const PasswordField = ({ control, name, recovery = false, ...props }) => {
  const [showChecklist, setShowChecklist] = useState(false)

  const toggleChecklist = () => {
    setTimeout(() => setShowChecklist(prev => !prev), 200)
  }

  const [rules, setRules] = useImmer([
    {
      message: 'De 8 a 20 caracteres',
      regex: value => validator.isRange(value),
      isValid: false
    },
    {
      message: 'Ao menos uma letra',
      regex: value => validator.isAlphabetic(value),
      isValid: false
    },
    {
      message: 'Ao menos um número',
      regex: value => validator.isNumberPassword(value),
      isValid: false
    },
    {
      message: 'Caracteres fora da sequência',
      regex: value => Boolean(value) && !validator.hasSequential(value),
      isValid: false
    },
    ...(recovery
      ? [
          {
            message: 'Sua senha não pode ser igual a anterior',
            regex: value => value.length > 0,
            isValid: false
          }
        ]
      : [])
  ])

  const handlePassword = useCallback(
    value => {
      const validate = rules
        .map((validation, index) => {
          setRules(draft => {
            draft[index].isValid = validation.regex(value)
          })

          return validation.regex(value)
        })
        .filter(Boolean)

      if (value === '') {
        return 'Insira sua senha'
      }

      return validate.length === rules.length
    },
    [rules, setRules]
  )

  return (
    <Controller
      name={name}
      rules={{
        validate: value =>
          handlePassword(value) || 'Sua senha não atende aos requisitos'
      }}
      control={control}
      render={({ field: { onChange, value, ref, onBlur } }) => (
        <Fragment>
          <PasswordBase
            name={name}
            ref={ref}
            value={value}
            onChange={onChange}
            {...props}
            onFocus={toggleChecklist}
            onBlur={event => {
              onBlur(event)
              toggleChecklist()
            }}
          />

          <S.CheckListWrapper isVisible={showChecklist}>
            <Stack space="byte">
              <Text size="kilo" isBold noMargin>
                Sua senha deve ter:
              </Text>
              {rules.map(({ message, isValid }) => (
                <Fragment key={message}>
                  <Inline alignY="center" alignX="flex-start" space="byte">
                    <Icon
                      as={Checkmark}
                      size="kilo"
                      color={isValid ? 'primary' : 'info'}
                    />
                    <Text
                      size="kilo"
                      color={isValid ? 'p600' : 'n900'}
                      noMargin
                    >
                      {message}
                    </Text>
                  </Inline>
                </Fragment>
              ))}
            </Stack>
          </S.CheckListWrapper>
        </Fragment>
      )}
    />
  )
}

export default PasswordField
