import request from 'checkout/utils/axios'

const ASSOCIATED_ORIGIN = 'minicart'

const putAssociatedProduct = async params => {
  const response = await request
    .post('/api/v1/cart', { ...params, origin: ASSOCIATED_ORIGIN })
    .then(({ data }) => data)
    .catch(err => {
      throw err.response
    })

  return response
}

export default putAssociatedProduct
