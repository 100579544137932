import styled from '@emotion/styled'
import { css } from '@emotion/react'

const simpleRowStyles = ({ theme }) => css`
  label: mosaic--simple-row;

  border-radius: ${theme.borderRadius.giga};
  border: 1px solid ${theme.colors.n100};
  display: flex;
  flex-direction: row;
  height: 128px;
  justify-content: space-between;
  overflow: hidden;
  position: relative;
  width: 100%;
`

export const SimpleRow = styled('div')(simpleRowStyles)
