import { addToCartDataLayer } from 'scripts/utils/data-layer'

export const pushDataObject = dataObject => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push(dataObject)
}

export const sendKazaplanCartToDatalayer = data => {
  if (!data) {
    return
  }

  const shippings = data.shippings[0]
  const regionId = data.regionId || ''
  const productsAddedToCart = shippings?.items || []
  const typeProduct = shippings?.type || ''

  const productsParams = productsAddedToCart.map(product => ({
    productName: product.name,
    productId: product.productId,
    price: product.price.to,
    productBrand: product.brand,
    category: product.categoryTree,
    quantity: product.quantity,
    dimension8: typeProduct,
    dimension9: product.shopName,
    dimension15: product.shopName
  }))

  addToCartDataLayer(productsParams, { regionId, sourceName: 'kazaplan' })
}

export const onDiscountByPaymentMethodViewedDataLayer = (
  paymentMethodsDiscount,
  discount,
  isOutlet = false
) => {
  const paymentMethodsName = paymentMethodsDiscount.match(/boleto|cartão|pix/gi)
  const tagData =
    paymentMethodsName === null
      ? discount + '% | ' + 'todos'
      : discount + '% | ' + paymentMethodsName.join(' ou ')

  const eventCategory = isOutlet
    ? 'Desconto por forma de Pagamento - Black Friday'
    : 'Desconto por forma de Pagamento'

  const dataObject = {
    event: 'non-interaction',
    'event-category': eventCategory,
    'event-action': 'Visualizou desconto na página de produto',
    'event-label': tagData
  }

  pushDataObject(dataObject)
}
