import axios from 'axios'

import { api } from './api'
import {
  ProfileResponse,
  SendCodeIdentityPayload,
  SendCodePayload,
  UpdateMainContact,
  VerifyCodePayload
} from '../types'

export const addPhone = async (phone: string) => {
  try {
    const { data } = await api.post<{ data: ProfileResponse }>('/account/phones', {
      phone
    })

    return data
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error.response
    } else {
      throw error
    }
  }
}

export const removePhone = async (phone: string) => {
  try {
    const { data } = await api.delete<{ data: ProfileResponse }>(
     `/account/phones?phone=${encodeURIComponent(phone)}`
    )

    return data
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error.response
    } else {
      throw error
    }
  }
}

export const sendCodeAuthIdentity = async (payload: SendCodeIdentityPayload) => {
  try {
    const { data } = await api.post(
      '/otp/send-code-identity',
      payload
    )

    return data
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error.response
    } else {
      throw error
    }
  }
}

export const sendCodeAuth = async (payload: SendCodePayload) => {
  try {
    const { data } = await api.post(
      '/otp/send-code-auth',
      payload
    )

    return data
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error.response
    } else {
      throw error
    }
  }
}

export const verifyCode = async (payload: VerifyCodePayload) => {
  try {
    const { data } = await api.post(
      '/otp/verify',
      payload
    )

    return data
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error.response
    } else {
      throw error
    }
  }
}

export const updateMainContact = async (payload: UpdateMainContact) => {
  try {
    const { data } = await api.post(
      '/account/update-main-contact',
      payload
    )

    return data
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error.response
    } else {
      throw error
    }
  }
}
