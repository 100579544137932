export const paginationFilter = (
  routeStatePage: string,
  numberFilter: number,
  urlToConcatenate: string
): string => {
  if (urlToConcatenate.includes('page=')) {
    return urlToConcatenate
  }

  if (numberFilter <= 1) {
    urlToConcatenate += '?page=' + routeStatePage
  } else if (numberFilter > 1) {
    urlToConcatenate += '&page=' + routeStatePage
  }

  return urlToConcatenate
}
