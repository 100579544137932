import { css } from '@emotion/react'
import styled from '@emotion/styled'

const postalCodeStyles = ({ theme }) =>
  css`
    label: postal-code;
  
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  
    position: relative;
  
    margin-top: ${theme.spacings.giga};
  
    width: 100%;

    ${theme.media.kilo} {
      border: 1px solid ${theme.colors.p100};
      border-radius: ${theme.borderRadius.giga};
      padding: ${theme.spacings.mega};
    }
  `
export const PostalCode = styled('div')(postalCodeStyles)

const linkStyles = ({ theme, isDisabled }) => css`
  label: postal-code__link;

  color: ${isDisabled ? theme.colors.n300 : theme.colors.p600};
  font-size: ${theme.typography.text.kilo.fontSize};
  font-weight: ${theme.font.weight.bold};

  &:hover {
    text-decoration: underline;
  }
`

export const Link = styled('a')(linkStyles)

const separatorStyles = ({ theme }) => css`
  label: postal-code__separator;

  background-color: ${theme.colors.p100};
  margin: ${theme.spacings.kilo} 0;
`

export const Separator = styled('hr')(separatorStyles)

const inputGridStyles = ({ theme }) => css`
  label: input-grid;

  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  gap: ${theme.spacings.mega};
`

export const InputGrid = styled('div')(inputGridStyles)
