import PropTypes from 'prop-types'
import { Text, IconButton, Badge } from '@leroy-merlin-br/backyard-react'

import * as cartRepository from 'scripts/api/cart'

import { handleSpinner } from 'site/Cart/services'
import { ServiceQuantity } from 'site/Cart/components/Product/StandaloneService/Quantity'
import { removeServiceFromCart } from 'site/Cart/components/Product/StandaloneService/actions'

import { FormatPrice, PrefixPrice } from 'utils/Price'

import ServiceImage from 'checkout/components/CrossServices/Installation/Icons/SquareBadge'

import * as S from './styled'

const IMAGE_SIZE = { width: 82, height: 82 }

const Service = ({ service, updateCartInfo, regionId }) => {
  const {
    productUrl,
    thumbUrl,
    name,
    price,
    packaging,
    packagingCount,
    quantity,
    id
  } = service
  const { from: priceFrom, to: priceTo, total: priceTotal } = price

  const serviceChangeCallback = async () => {
    const response = await cartRepository.getCart()
    updateCartInfo(response.data)
  }

  const removeService = async () => {
    handleSpinner({ useSpinner: true, action: 'show' })
    await removeServiceFromCart({
      category: service.categoryTree,
      name: service.name,
      id: service.id,
      prevQuantity: service.quantity,
      price: service.price,
      regionId: regionId
    })
    handleSpinner({ useSpinner: false })
    const response = await cartRepository.getCart()
    updateCartInfo(response.data)
  }

  return (
    <S.Wrapper data-cy="minicart-service">
      <S.Line key={'productLine'} />
      <S.AnimatedWrapper key={'animatedWrapper'} fadeOut={false}>
        <S.ServiceImage href={productUrl}>
          <ServiceImage />
        </S.ServiceImage>
        <S.Detail>
          <div>
            <Badge appearance="warning">Serviço de instalação</Badge>
          </div>
          <S.Header>
            <S.AnchorTitle href={productUrl} title={name} data-testid="name">
              {name}
            </S.AnchorTitle>

            <IconButton
              appearance="critical"
              variant="ghost"
              size="kilo"
              onClick={removeService}
              data-cy="button-remove-item-minicart"
              data-testid="button-remove-item-minicart"
            >
              <i className="glyph glyph-trash-can" />
            </IconButton>
          </S.Header>

          <S.Content>
            <div>
              {priceFrom && priceFrom !== priceTo && (
                <S.Price>
                  <Text
                    as="span"
                    size="kilo"
                    decoration="line-through"
                    align="left"
                    color="n400"
                    data-cy="from"
                    data-testid="from"
                  >
                    <FormatPrice quantity={1} unit={'un'} price={priceFrom} />
                  </Text>
                </S.Price>
              )}

              {priceTo && (
                <S.Price>
                  <Text
                    as="span"
                    size="kilo"
                    align="left"
                    color="n400"
                    data-cy="to"
                    data-testid="to"
                  >
                    <FormatPrice quantity={1} unit={'un'} price={priceTo} />
                  </Text>
                </S.Price>
              )}

              <Text
                as="strong"
                size="mega"
                color="n800"
                isBold
                data-cy="total"
                data-testid="total"
              >
                <PrefixPrice
                  packaging={packaging}
                  packagingCount={packagingCount}
                />
                <FormatPrice
                  quantity={quantity}
                  unit={'un'}
                  price={priceTotal}
                />
              </Text>
            </div>
          </S.Content>

          <S.Footer>
            <ServiceQuantity
              id={id}
              regionId={regionId}
              quantity={quantity}
              callback={serviceChangeCallback}
            />
          </S.Footer>
        </S.Detail>
      </S.AnimatedWrapper>
    </S.Wrapper>
  )
}

Service.propTypes = {
  updateCartInfo: PropTypes.func,
  regionId: PropTypes.string,
  service: PropTypes.shape({
    brand: PropTypes.string,
    categoryTree: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
    packaging: PropTypes.number,
    packagingCount: PropTypes.number,
    price: PropTypes.shape({
      discount: PropTypes.number,
      from: PropTypes.number,
      to: PropTypes.number,
      total: PropTypes.number
    }),
    quantity: PropTypes.number,
    storeId: PropTypes.string
  })
}

export default Service
