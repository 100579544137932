import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { Theme } from 'scripts/react-components/themes/Theme'

type ExtendedTipTheme = Theme & { tooltipFade: boolean }

const chatButtonWrapper = () => css`
  label: wrapper;

  position: relative;
`

const chatButtonTooltip = ({ tooltipFade }: ExtendedTipTheme) =>
  tooltipFade &&
  css`
    & > [id^='tippy-'] {
      animation: fadeIn 2s;
      animation-timing-function: ease-in-out;
      transition: opacity 2s;
      opacity: 1;
    }
    & > [id^='tippy-'].to-hide {
      opacity: 0;
    }

    @keyframes fadeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  `

const chatButton = ({ theme }: Theme) => css`
  label: button;
  position: fixed;
  right: 30px;
  bottom: 0;
  z-index: 50;
  border-radius: ${theme.borderRadius.circle};
  width: 52px;
  height: 52px;
  background-color: ${theme.colors.p600};
  justify-content: center;
  align-items: center;
  padding: ${theme.spacings.kilo};
  margin-left: ${theme.spacings.giga};
  margin-bottom: ${theme.spacings.tera};
  cursor: pointer;

  &:hover {
    background-color: ${theme.colors.p800};
  }

  svg {
    fill: ${theme.colors.white};
  }
`
export const ChatButtonWrapper = styled('div')(
  chatButtonWrapper,
  chatButtonTooltip
)
export const ChatButton = styled('div')(chatButton)
