import { Text } from '@leroy-merlin-br/backyard-react'
import PropTypes from 'prop-types'

import { AlertMessage } from 'shared/components/AlertMessage'

const ViewInstallments = ({ onButtonClick }) => (
  <AlertMessage
    type="info"
    buttonText="Minhas parcelas"
    onButtonClick={onButtonClick}
  >
    <Text as="span" size="kilo" noMargin>
      Para verificar suas{' '}
      <Text as="strong" size="kilo" isBold noMargin>
        parcelas futuras{' '}
      </Text>
      você só precisa acessar a plataforma da nossa parceira BoletoFlex.
    </Text>
  </AlertMessage>
)

ViewInstallments.propTypes = {
  onButtonClick: PropTypes.func
}

export default ViewInstallments
