import type { SVGAttributes } from 'react'
import { forwardRef } from 'react'

const CellphoneIcon = forwardRef<SVGSVGElement, SVGAttributes<SVGSVGElement>>((props, ref) => (
  <svg ref={ref} viewBox="0 0 32 32"{...props}>
    <path d="M20.5 5h-9a2 2 0 00-2 2v18a2 2 0 002 2h9a2 2 0 002-2V7a2 2 0 00-2-2zm-9 2h9v14h-9zm0 18v-3h9v3z"/>
    <path d="M16.5 23h-1a.5.5 0 000 1h1a.5.5 0 000-1z"/>
  </svg>
))

export default CellphoneIcon
