import { PureComponent } from 'react'

import emitter from 'scripts/utils/emitter'

import { Button as TrackedClickButton } from 'site/Analytics'

class StockInfoButton extends PureComponent {
  constructor (props) {
    super(props)

    this.state = {
      disabled: this.props.disabled === 'true'
    }
  }

  componentDidMount () {
    emitter.on('buybox:sellerChange', this.toggleButtonState)
  }

  toggleButtonState = ({ consultButtonEcommerceStockDisabled }) => {
    this.setState({
      disabled: consultButtonEcommerceStockDisabled === 'true'
    })
  }

  render () {
    const { fetchStock, trackingInfo: { lm, region }, isLocateOnStoreEnabled } = this.props

    return (
      <TrackedClickButton
        data-cy="stock-info-button"
        className="button-hollow button-hollow-primary"
        data-stock-info
        onClick={fetchStock}
        eventCategory="PagProduto"
        eventAction={`viu_estoque_${region}`}
        eventLabel="estoque"
        eventValue={lm}
        disabled={this.state.disabled}>
        {isLocateOnStoreEnabled ? 'Estoque e Localização na Loja' : 'Estoque na Loja física'}
      </TrackedClickButton>
    )
  }
}

export default StockInfoButton
