import PropTypes from 'prop-types'

import { ButtonSocial } from '../ButtonSocial'
import * as S from './styled'

const SocialButtonList = ({
  isActive,
  pageURL,
  socialMediaList = []
}) => (
  <S.ShareButtonList isActive={isActive}>
    {
      socialMediaList.map((button, index) => (
        <S.ShareButtonListItem key={`${index}-${button.name}`}>
          <ButtonSocial
            name={button.name}
            url={`${button.url}${pageURL}`}
          />
        </S.ShareButtonListItem>
      ))
    }
  </S.ShareButtonList>
)

SocialButtonList.propTypes = {
  isActive: PropTypes.bool.isRequired,
  socialMediaList: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired
    })
  ),
  pageURL: PropTypes.string.isRequired
}

export default SocialButtonList
