import { useState, useCallback, useEffect } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { Stack } from '@leroy-merlin-br/backyard-react'

import * as dataLayer from 'scripts/utils/data-layer'

import { AlertMessage } from 'shared/components/AlertMessage'

import { Actions as CouponActions } from 'checkout/redux/modules/coupon'
import { ConditionsOfUse } from 'checkout/components/ConditionsUse'
import { Coupon } from 'checkout/components/Coupon'
import { PriceTag } from 'checkout/components/Icons'

import * as Utils from './utils'

const CouponContainer = ({
  hasService = false,
  discountEnginePromoEnabled
}) => {
  const [propsCoupon, setPropsCoupon] = useState({})
  const { data, isRequest, error } = useSelector(
    state => state.coupon,
    shallowEqual
  )

  const dispatch = useDispatch()

  const handleSetCoupon = useCallback(
    data => dispatch(CouponActions.setCoupon(data)),
    [dispatch]
  )

  const handleApplyCoupon = useCallback(
    code => dispatch(CouponActions.applyCoupon(code)),
    [dispatch]
  )

  const handleRemoveCoupon = useCallback(
    code => dispatch(CouponActions.removeCoupon(code)),
    [dispatch]
  )

  useEffect(() => {
    if (error) {
      dataLayer.couponWithError({ statusError: error })
    }
    if (data) {
      return setPropsCoupon({
        handleCoupon: () => handleRemoveCoupon(data.code),
        isDisabled: true,
        tagData: {
          label: data.code,
          type: Utils.isLoyalty(data) ? 'loyalty' : 'success',
          icon: PriceTag
        }
      })
    }

    setPropsCoupon({
      handleCoupon: code => handleApplyCoupon(code)
    })
  }, [data, handleRemoveCoupon, handleApplyCoupon, error])

  useEffect(() => {
    const couponAppliedInTwig = localStorage.getItem('@checkout:coupon')

    if (couponAppliedInTwig) {
      handleSetCoupon({ code: couponAppliedInTwig, type: 'product' })
    }
  }, [handleSetCoupon])

  return (
    <Stack space="mega">
      <Coupon
        hasLoading={isRequest}
        hasError={Boolean(error)}
        hasService={hasService}
        discountEnginePromoEnabled={discountEnginePromoEnabled}
        {...propsCoupon}
      />

      {error && (
        <AlertMessage type="critical">
          {Utils.getMessageByStatus(error)}
        </AlertMessage>
      )}

      <ConditionsOfUse
        hasService={hasService}
        discountEnginePromoEnabled={discountEnginePromoEnabled}
      />
    </Stack>
  )
}

export default CouponContainer
