export default [
  null,
  'Materiais',
  'Carpintaria e madeira',
  'Eletricidade',
  'Ferramentas',
  'Carpetes e tapetes',
  'Cerâmica',
  'Sanitário',
  'Encanamento',
  'Jardim',
  'Ferragens',
  'Pintura',
  'Decoração',
  'Iluminação',
  'Organização',
  'Cozinhas'
]
