import { Dialog, Text } from '@leroy-merlin-br/backyard-react'

type ModalErrorProps = {
  onClose: () => void
}

const ModalError = ({ onClose }: ModalErrorProps) => {
  return (
    <Dialog
      title="Ops, houve algum problema ao trazer as informações do seu cupom"
      onClose={onClose}
    >
      <Text size="kilo" color="n400">
        Tente novamente, mais tarde.
      </Text>
    </Dialog>
  )
}

export default ModalError
