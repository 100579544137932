import { Fragment, useContext, useState, useEffect } from 'react'
import { Visible, Hidden } from '@leroy-merlin-br/backyard-react'

import { OptionFilterItem } from '../'
import { EntryKeyContext } from '../../../../context'
import * as S from './styled'

const INITIAL_VISIBLE_OPTIONS = 4
const SCROLL_WIDTH = 13.5
const PADDING_RIGHT = 8

const FilterOptions = ({
  filterKey,
  selected,
  numberOptions = INITIAL_VISIBLE_OPTIONS,
  options = [],
  isDisabled = false,
  optionClick,
  customizableClickOption = () => {}
}) => {
  const [itemSpacing, setItemSpacing] = useState(PADDING_RIGHT)

  useEffect(() => {
    if (options.length <= numberOptions) {
      setItemSpacing(prev => prev + SCROLL_WIDTH)
    }
  }, [numberOptions, options.length])

  const {
    query: filtersQuery,
    isMappedSearch,
    onOptionClick,
    baseUrl
  } = useContext(EntryKeyContext)

  const handleFilter = (event, filterElementIndex, option) => {
    const { query, label } = option

    if (!optionClick) {
      return customizableClickOption(event, option)
    }

    event.preventDefault()
    event.stopPropagation()

    const optionClickObject = {
      ...optionClick,
      value: query.replace(/\s/g, '_'),
      dataLayerOptions: {
        ...optionClick.dataLayerOptions,
        filterElement: { index: filterElementIndex, value: label }
      }
    }

    onOptionClick(optionClickObject)
  }

  const renderOptionFilter = (option, index, hasSpacing = false) => {
    const { query, label: optionLabel, quantity } = option

    return (
      <OptionFilterItem
        key={query}
        filterKey={filterKey}
        query={query}
        optionLabel={optionLabel}
        quantity={quantity}
        selected={selected}
        onFilterClick={event => handleFilter(event, index, option)}
        filtersQuery={filtersQuery}
        url={baseUrl}
        isMappedSearch={isMappedSearch}
        rightSpacing={hasSpacing ? itemSpacing : 0}
        isDisabled={isDisabled}
      />
    )
  }

  return (
    <Fragment>
      <Visible when={['giga', 'tera']}>
        <S.Content fixedHeight={itemSpacing === PADDING_RIGHT}>
          {options.map((option, index) =>
            renderOptionFilter(option, index, true)
          )}
        </S.Content>
      </Visible>

      <Hidden when={['giga', 'tera']}>
        <S.Content>
          {options.map((option, index) => renderOptionFilter(option, index))}
        </S.Content>
      </Hidden>
    </Fragment>
  )
}

export default FilterOptions
