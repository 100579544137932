import { Icon } from '../Icon'

const PhoneIcon = (props) => (
  <Icon {...props} fill="none" viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg">
    <path d="m13.489 12.154c-0.0218-0.0109-0.149-0.0836-0.3125-0.1781l-0.2103-0.1203c-0.2933-0.1678-0.6475-0.3704-0.6908-0.3993-0.2289-0.1562-0.5595-0.2107-0.7848 0.0218-0.0278 0.0279-0.0663 0.0749-0.1138 0.1329l-0.0461 0.0561-0.0144 0.0177c-0.1098 0.1347-0.241 0.2956-0.3489 0.3965-0.0945 0.0872-0.5196-0.0036-1.457-0.9847-1.0282-1.0755-1.0428-1.3807-0.95922-1.4679 0.12353-0.12717 0.28703-0.2834 0.40694-0.39241l0.06322-0.05992 2e-5 -3e-5 7e-5 -6e-5c0.04219-0.03994 0.07431-0.07035 0.08928-0.08532 0.14171-0.14897 0.19984-0.46871 0.02544-0.76665-0.13444-0.22891-0.65401-1.1009-0.74484-1.2426-0.10901-0.17077-0.31612-0.22527-0.55593-0.1417-0.17077 0.05813-0.76302 0.29067-1.1155 0.76665-1.1627 1.5696 1.5406 4.5526 1.5696 4.5817 0.85022 0.9011 2.4307 1.8094 3.6625 1.8094 0.3379 0 0.6467-0.069 0.9119-0.2216 0.3852-0.2253 0.6177-0.7558 0.7558-1.0755l0.0291-0.0618c0.1053-0.2361 0.0436-0.465-0.1599-0.5849z" fill="#41A017"/>
    <path d="m13.489 12.154c-0.0218-0.0109-0.149-0.0836-0.3125-0.1781l-0.2103-0.1203c-0.2933-0.1678-0.6475-0.3704-0.6908-0.3993-0.2289-0.1562-0.5595-0.2107-0.7848 0.0218-0.0278 0.0279-0.0663 0.0749-0.1138 0.1329l-0.0461 0.0561-0.0144 0.0177c-0.1098 0.1347-0.241 0.2956-0.3489 0.3965-0.0945 0.0872-0.5196-0.0036-1.457-0.9847-1.0282-1.0755-1.0428-1.3807-0.95922-1.4679 0.12353-0.12717 0.28703-0.2834 0.40694-0.39241l0.06322-0.05992 2e-5 -3e-5 7e-5 -6e-5c0.04219-0.03994 0.07431-0.07035 0.08928-0.08532 0.14171-0.14897 0.19984-0.46871 0.02544-0.76665-0.13444-0.22891-0.65401-1.1009-0.74484-1.2426-0.10901-0.17077-0.31612-0.22527-0.55593-0.1417-0.17077 0.05813-0.76302 0.29067-1.1155 0.76665-1.1627 1.5696 1.5406 4.5526 1.5696 4.5817 0.85022 0.9011 2.4307 1.8094 3.6625 1.8094 0.3379 0 0.6467-0.069 0.9119-0.2216 0.3852-0.2253 0.6177-0.7558 0.7558-1.0755l0.0291-0.0618c0.1053-0.2361 0.0436-0.465-0.1599-0.5849z"/>
    <rect x="2.5646" y="3" width="15" height="15" rx="7.5" stroke="#41A017"/>
  </Icon>
)

export default PhoneIcon
