import { useMemo } from 'react'
import { Stack } from '@leroy-merlin-br/backyard-react'
import { Card } from '@backyard-ui/core'
import PropTypes from 'prop-types'

import { Timeline } from 'checkout/components/MyOrders/components/Timeline'
import ShipmentContent from 'checkout/components/MyOrders/components/Shipment/Content'
import PaymentInfo from 'checkout/components/MyOrders/components/Payment/Info'
import { ServiceContent } from 'checkout/components/MyOrders/components/Service'
import {
  DeliveryAttemptBuilder,
  DisclaimerBuilder
} from 'checkout/components/MyOrders/components/DisclaimerBuilder'
import { isOnlinePickup } from 'checkout/components/MyOrders/modules/messages/shipment'
import { shouldShowInvoiceModal } from 'checkout/components/MyOrders/utils/shouldShowInvoiceModal'

import { Header } from '../Header'

const Content = ({
  id,
  date,
  discount,
  vouchers,
  payable,
  payments,
  shipments,
  shippingCost,
  invoices,
  steps,
  totalValue,
  services,
  isOrderToPickUp = false,
  hasSeparationProblem = false
}) => {
  const hasServices = services?.items.length > 0
  const hasSteps = steps && Object.entries(steps) > 0
  const hasShipments = shipments?.length > 0
  const shipmentSteps = hasShipments ? shipments[0]?.steps : []
  const currentStep = shipmentSteps.find(step => step.current)

  const storeName = useMemo(() => {
    if (!hasShipments) return ''

    return shipments.reduce((accumulator, shipment) => {
      if (isOnlinePickup(shipment.type)) {
        accumulator = shipment.shippingAddress.storeName
      }

      return accumulator
    }, '')
  }, [hasShipments, shipments])

  const listServices = services?.items.map(service => ({
    ...service,
    isStandaloneService: !service.relatedProduct.id
  }))

  return (
    <Stack space="kilo">
      <DisclaimerBuilder
        orderId={id}
        orderDate={date}
        currentStep={currentStep}
        payment={payments[0]}
        storeName={storeName}
        isOrderToPickUp={isOrderToPickUp}
        hasSeparationProblem={hasSeparationProblem}
      />

      {hasSteps && <Timeline steps={steps} />}

      {hasShipments &&
        shipments.map((shipment, index) => (
          <Stack key={index} space="kilo">
            <DeliveryAttemptBuilder
              slug={shipment.steps.find(step => step.current)?.slug}
            />

            <div className="sm:p-4">
              <Card.Root size="xs" shadow="none" hasBorder>
                <Card.Body>
                  <Header
                    {...shipment}
                    quantity={shipment.items.length}
                  />
                  <ShipmentContent {...shipment} />
                </Card.Body>
              </Card.Root>
            </div>
          </Stack>
        ))}

      {hasServices &&
        listServices.map((service, index) => (
          <div className="sm:p-4" key={index}>
            <Card.Root size="xs" shadow="none" hasBorder>
              <Card.Body>
                <Header
                  {...service}
                  type="Serviços"
                  isStandaloneService={!service.relatedProduct?.id}
                />
                <ServiceContent
                  {...service}
                  address={services.installationAddress}
                />
              </Card.Body>
            </Card.Root>
          </div>
        ))}

      <PaymentInfo
        orderId={id}
        payable={payable}
        payments={payments}
        totalValue={totalValue}
        shippingCost={shippingCost}
        discount={discount}
        vouchers={vouchers}
        invoices={invoices}
        shouldShowInvoiceModal={shouldShowInvoiceModal(shipments)}
      />
    </Stack>
  )
}

Content.propTypes = {
  date: PropTypes.string,
  totalValue: PropTypes.object,
  discount: PropTypes.object,
  vouchers: PropTypes.object,
  shippingCost: PropTypes.object,
  shipments: PropTypes.array,
  payments: PropTypes.array,
  steps: PropTypes.array,
  invoice: PropTypes.object
}

export default Content
