import Cookies from 'js-cookie'

export default function tokenNotify () {
  if (!window.ReactNativeWebView || !window.ReactNativeWebView.postMessage) {
    return
  }

  const token = Cookies.get('api_token') || null
  const message = JSON.stringify({ type: 'tokenReceived', token: token })

  return window.ReactNativeWebView.postMessage(message)
}
