import { datadogRum } from '@datadog/browser-rum'
import { useEffect, useState } from 'react'
import { getSaleOrder, Order } from 'frontendMyOrders/services/sale-order'

const useSaleOrder = () => {
  const [orders, setOrders] = useState<Order[] | null>(null)

  useEffect(() => {
    const fetchSaleOrder = async () => {
      try {
        const data = await getSaleOrder()
        if (data) {
          setOrders(data)
        }
      } catch (error) {
        datadogRum.addError(error)
      }
    }

    fetchSaleOrder()
  }, [setOrders])

  return { orders }
}

export default useSaleOrder
