import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { Theme } from 'scripts/react-components/themes/Theme'

const wrapperStyles = ({ theme }: Theme) => css`
  label: contacts-desktop-card-wrapper;

  border-bottom: 1px solid ${theme.colors.n100};

  display: flex;
  align-items: center;
  justify-content: space-between;

  width: auto;
  height: 110px;

  & span[class*='WrapperIcon'] > svg {
    width: 14px;
    height: 14px;
    color: ${theme.colors.p700};
  }
`

export const Wrapper = styled('div')(wrapperStyles)

const actionRemoveLinkStyles = ({ theme }: Theme) => css`
  margin-right: ${theme.spacings.giga};
`

export const ActionRemoveLink = styled('div')(actionRemoveLinkStyles)

const actionShowHideLinkStyles = () => css`
  user-select: none;
`

export const ActionShowHideLink = styled('a')(actionShowHideLinkStyles)

const flexStyles = ({ theme }: Theme) => css`
  label: contacts-desktop__flex;

  display: flex;

`
export const Flex = styled('div')(flexStyles)

const labelStyles = ({ theme }: Theme) => css`
  label: contacts-desktop__label;

  width: 250px;

`
export const Label = styled('div')(labelStyles)

const editableDataStyles = ({ theme }: Theme) => css`
  label: contacts-desktop__editable-data;

  display: flex;
  flex: 1;
  justify-content: space-between;
  position: relative;

  input {
    position: relative;
  }

  span {
    position: absolute;
    bottom: -20px;
  }

  div[class*='input__field'] {
    width: 100%;
  }
`

export const EditableData = styled('form')(editableDataStyles)

const buttonWrapperStyles = ({ theme }: Theme) => css`
  label: contacts-desktop__button;

  display: flex;
  align-items: center;
  white-space: nowrap;
`

export const ButtonWrapper = styled('div')(buttonWrapperStyles)

const cancelWrapperStyles = ({ theme }: Theme) => css`
  label: contacts-desktop__cancel;

  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    position: relative;
    padding: 0 ${theme.spacings.mega};
  }
`

export const CancelWrapper = styled('div')(cancelWrapperStyles)

const textWrapperStyles = ({ theme }: Theme) => css`
  label: contacts-desktop__text-wrapper;

  left: 13px;
  position: relative;
  bottom: -2px;
`

export const TextWrapper = styled('div')(textWrapperStyles)

const actionButtonWrapperStyles = ({ theme }: Theme) => css`
  label: contacts-desktop__button;

  display: flex;
  justify-content: end;

  margin-top: ${theme.spacings.giga};
  margin-bottom: ${theme.spacings.byte}
`

export const ActionButtonWrapper = styled('div')(actionButtonWrapperStyles)
