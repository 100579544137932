import { useEffect, useMemo, useState } from 'react'
import { Text } from '@leroy-merlin-br/backyard-react'
import { getProductServices, type ProductService } from 'hpdp/services/product'

import emitter from 'scripts/utils/emitter'

import { LogoInstala } from './LogoInstala'
import * as S from './styled'

interface ServiceLabelProps {
  productId: number
  regionId: string
  isServicesEnabled?: boolean
  hasCrossServices?: boolean
  shopName?: string
}

const LEROY_STORE_SHOP_NAME = 'Leroy Merlin'

const ServiceLabel = (props: ServiceLabelProps) => {
  const { productId, regionId, shopName, hasCrossServices, isServicesEnabled } =
    props
  const [services, setServices] = useState<ProductService[]>()
  const [isLeroyStore, setIsLeroyStore] = useState(
    shopName === LEROY_STORE_SHOP_NAME
  )

  const hasServices = services && services.length > 0

  const shouldRenderCrossServicesBadge = useMemo(
    () => isServicesEnabled && hasCrossServices && isLeroyStore,
    [isLeroyStore, hasCrossServices, isServicesEnabled]
  )

  useEffect(() => {
    if (shouldRenderCrossServicesBadge && !hasServices) {
      const fetchProductServices = async () => {
        const data = await getProductServices(productId, regionId)
        setServices(data)
      }

      fetchProductServices()
    }
  }, [productId, regionId, shouldRenderCrossServicesBadge, hasServices])

  useEffect(() => {
    emitter.on('buybox:sellerChange', data => {
      if (data?.shopName) {
        setIsLeroyStore(data.shopName === LEROY_STORE_SHOP_NAME)
      }
    })
  }, [])

  if (!shouldRenderCrossServicesBadge || !hasServices) {
    return null
  }

  const servicePrice = parseFloat(`${services[0].price.to}`)

  return (
    <S.Wrapper>
      <S.Logo>
        <LogoInstala />
      </S.Logo>

      <div>
        <Text as="p" size="mega" noMargin isBold>
          Instalamos para você!
        </Text>
        <Text as="p" size="kilo" noMargin>
          Consulte o preço ao adicionar no carrinho
        </Text>
        <S.HiddenPrice>{servicePrice}</S.HiddenPrice>
      </div>
    </S.Wrapper>
  )
}

export default ServiceLabel
