import styled from '@emotion/styled'
import { css } from '@emotion/react'

const DEFAULT_TRANSITION_VALUES = '225ms ease-in-out'

const formStyles = ({ theme }) => css`
  label: form;

  margin: ${theme.spacings.byte} auto 0;
  width: 100%;

  ${theme.media.mega} {
    width: 85%;
  }
`

const radioContainerStyles = ({ theme }) => css`
  label: radio__container;

  align-items: center;
  display: flex;

  div {
    margin-right: ${theme.spacings.mega};
  }
`

const checkBoxStyles = ({ theme }) => css`
  label: checkBox;

  width: 100%;
  height: 100%;

  text-align: center;

  border-radius: ${theme.borderRadius.mega};

  color: ${theme.colors.p600};

  background: ${theme.colors.white};

  appearance: none;

  cursor: pointer;

  border: 1px solid ${theme.colors.white};

  transition: all ${DEFAULT_TRANSITION_VALUES};

  outline: 0;

  &:checked {
    background: ${theme.colors.p600};
  }

  &:checked ~ div svg {
    fill: ${theme.colors.white};
  }

  &:checked ~ div {
    color: ${theme.colors.white};
  }

  & ~ div svg {
    fill: ${theme.colors.p600};
  }

  &:checked ~ span {
    opacity: 1;
  }

  ${theme.media.mega} {
    &:hover {
      border-color: ${theme.colors.p600};
    }
  }
`

const checkBoxWrapperStyles = ({ theme }) => css`
  label: checkBox__wrapper;

  position: relative;

  width: 136px;
  height: 136px;

  padding: 0 ${theme.spacings.bit};
  margin: 0 0 ${theme.spacings.kilo} 0;

  display: block;

  ${theme.media.mega} {
    width: 100%;
    height: 100%;
  }
`

const checkBoxContentStyles = ({ theme }) => css`
  label: checkBox__content;

  position: absolute;

  top: 0;
  bottom: 0;

  left: 0;
  right: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  text-align: center;

  pointer-events: none;

  color: ${theme.colors.p600};

  width: 80%;
  margin: auto;
`

const checkBoxListStyles = ({ theme }) => css`
  label: checkBox__list;

  display: grid;
  gap: ${theme.spacings.bit};
  grid-template-columns: repeat(2, 1fr);

  margin: 0;

  ${theme.media.mega} {
    grid-template-columns: repeat(4, 1fr);
  }
`

const checkBoxItemStyles = ({ theme }) => css`
  label: checkBox__item;

  margin: 0;

  ${theme.media.mega} {
    height: 20vh;
    margin: 0 0 ${theme.spacings.mega} 0;
  }
`

const checkBoxIconStyles = ({ theme }) => css`
  label: checkBox__icon;

  svg {
    width: 50px;
    height: 50px;
  }
  margin: 0 0 ${theme.spacings.bit} 0;
`

const checkBoxSuccessIconStyles = ({ theme }) => css`
  label: checkBox__success;

  display: block;

  position: absolute;

  top: ${theme.spacings.kilo};
  right: ${theme.spacings.mega};

  opacity: 0;

  transition: opacity ${DEFAULT_TRANSITION_VALUES};

  svg {
    fill: ${theme.colors.white};
  }
`

const buttonSubmitStyles = () => css`
  label: button_submit;

  display: flex;
  justify-content: center;
`

export const Form = styled('form')(formStyles)
export const RadioContainer = styled('div')(radioContainerStyles)
export const CheckBox = styled('input')(checkBoxStyles)
export const CheckBoxWrapper = styled('label')(checkBoxWrapperStyles)
export const CheckBoxContent = styled('div')(checkBoxContentStyles)
export const CheckBoxList = styled('ul')(checkBoxListStyles)
export const CheckBoxItem = styled('li')(checkBoxItemStyles)
export const CheckBoxIcon = styled('div')(checkBoxIconStyles)
export const CheckBoxSuccessIcon = styled('span')(checkBoxSuccessIconStyles)
export const ButtonSubmit = styled('div')(buttonSubmitStyles)
