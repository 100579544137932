import { useInstantSearch } from 'react-instantsearch'
import { Heading, Card, Skeleton, Stack, Grid } from '@backyard-ui/core'

import { IS_CATEGORY } from 'scripts/react-components/entry-key/utils/constants'
import { Markdown, ShowMore } from 'scripts/react-components/shared/components'

import * as S from './styled'
import { VideoThumb } from './VideoThumb'
import { ContentList } from './ContentList'
import { ContentTagSEO, VideoTagSEO } from '../../page'

interface TopContentProps {
  topDescription?: string
  categoryTitle?: string | null
  contents?: ContentTagSEO[]
  videos?: VideoTagSEO[]
}

const TopContent = ({
  videos,
  contents,
  topDescription,
  categoryTitle
}: TopContentProps) => {
  const { indexUiState } = useInstantSearch()

  const query = indexUiState?.query || ''
  const topContentTitle = query.trim() === '' ? 'Produtos Recomendados' : query

  const hasContentToDisplay = !!videos?.length || !!contents?.length
  const CONTENT_SIZE = videos?.length ? 8 : 12

  const title = () => {
    if (IS_CATEGORY) {
      return categoryTitle?.length ? (
        categoryTitle
      ) : (
        <Skeleton width="200px" height="29px" />
      )
    }

    return topContentTitle
  }

  return (
    <Card.Root>
      <Card.Body>
        <Heading>
          <div className="capitalize">{title()}</div>
        </Heading>
        {topDescription && (
          <ShowMore scrollToParent showFloatButton>
            <Markdown content={topDescription} type="medium" />

            {hasContentToDisplay && (
              <Stack spacing={5} direction="column">
                <Heading size="xl" asChild>
                  <h3>Veja também essas dicas Leroy Merlin</h3>
                </Heading>

                <Grid.Root>
                  {videos?.length && (
                    <Grid.Item size={{ initial: 12, lg: 4 }}>
                      <VideoThumb video={videos[0]} />
                    </Grid.Item>
                  )}

                  {!!contents?.length && (
                    <Grid.Item size={{ initial: 12, lg: CONTENT_SIZE }}>
                      <S.ContentWrapper hasVideo={!!videos?.length}>
                        <ContentList
                          contents={contents}
                          hasVideo={!!videos?.length}
                        />
                      </S.ContentWrapper>
                    </Grid.Item>
                  )}
                </Grid.Root>
              </Stack>
            )}
          </ShowMore>
        )}
      </Card.Body>
    </Card.Root>
  )
}

export default TopContent
