import { default as tryAddToCartKazaplan } from 'catalog/utils/addToCartKazaplan'
import { sendKazaplanCartToDatalayer } from 'catalog/utils/data-layer'

export const setupKazaplan = (
  node,
  { profile, appId, partnerKey, env, environment, token }
) => {
  window.kzpAsyncInit = () => {
    KZP.init(node, {
      appId,
      locale: 'pt-BR',
      partnerKey,
      env,
      environment,
      user: {
        token
      },
      customerShopId: profile.regionId,
      onEvent: function (app, name, status, data) {
        if (name === 'addToCart' && data.length) {
          addToCart(data, app)
        }
      }
    })
  }
}

const addToCart = (data, app) => {
  const products = data.map(product => ({
    id: Number(product.references.sku),
    quantity: product.quantity
  }))

  tryAddToCartKazaplan({
    type: 'delivery',
    products,
    override: false
  }).then(resolve => {
    const { data, error, successful } = resolve

    if (error) {
      return
    }

    sendKazaplanCartToDatalayer(data)

    const response = successful?.map(current => current.toString()) || []

    app.addToCartCallback(response, null)
  })
}
