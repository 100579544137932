import { css } from '@emotion/react'
import styled from '@emotion/styled'

const totalPaymentMethodsStyles = ({ theme }) => css`
  label: total__payments;

  display: flex;

  justify-content: space-between;

  color: ${theme.colors.p600};
`
const paymentMethodSummaryWrapperStyles = ({ theme }) => css`
  label: payment-method__summary-wrapper;

  margin-top: ${theme.spacings.byte};
`
export const PaymentMethodSummaryWrapper = styled('div')(paymentMethodSummaryWrapperStyles)
export const TotalPaymentMethods = styled('div')(totalPaymentMethodsStyles)
