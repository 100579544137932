import type { Message } from './Components/ChatModal/Components/Messaging/types'
import {
  welcome,
  incomeHelpMessage,
  endMessage,
  feedbackSubmitedMessage
} from './Components/ChatModal/Components/Messaging/messages.mock'

const getInactivityMessage = (
  continueCallback: VoidFunction,
  exitCallback: VoidFunction
): Message => ({
  type: 'income',
  message: 'Posso te ajudar em algo mais?',
  options: [
    {
      command: continueCallback,
      label: 'Sim, por gentileza'
    },
    {
      command: exitCallback,
      label: 'Não, talvez mais tarde'
    }
  ]
})

export default {
  welcome,
  incomeHelpMessage,
  endMessage,
  feedbackSubmitedMessage,
  getInactivityMessage
}
