import $ from 'jquery'

import emitter from 'scripts/utils/emitter'

const LOGGED_SELECTOR = '[data-user-logged]'
const NOT_LOGGED_SELECTOR = '[data-user-not-logged]'

export const ifLogged = ($el) => {
  emitter.on('user-resources:done', (user) => {
    if (user.email !== '') {
      $el.find(NOT_LOGGED_SELECTOR).toggle(false)
      $el.find('[data-username]').text(user.name)
      $el.find('[data-usermail]').text(user.email)
      $el.find(LOGGED_SELECTOR).toggle(true)
    }
  })
}

export default ($el) => ifLogged($el)
