import { Heading, Card, Visible, Hidden } from '@leroy-merlin-br/backyard-react'

import { ContextVisibility } from 'shared/components/ContextVisibility'

import { Desktop, Mobile } from './Icon'
import * as S from './styled'

const EmptyCart = () => (
  <S.Wrapper>
    <Card>
      <Heading as="h2" size="tera" data-testid="empty-cart-title" data-cy="empty-cart-title">
        Você não possui itens adicionados ao seu carrinho.
      </Heading>
      <div>
        <ContextVisibility when={['ecommerce']}>
          <Hidden when={['untilKilo', 'kilo']}>
            <Desktop />
          </Hidden>
        </ContextVisibility>

        <ContextVisibility when={['app', 'ecommerce']}>
          <Visible when={['untilKilo', 'kilo']}>
            <Mobile />
          </Visible>
        </ContextVisibility>
      </div>
    </Card>
  </S.Wrapper>
)

export default EmptyCart
