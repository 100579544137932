import {
  forwardRef,
  Fragment,
  useCallback,
  useEffect,
  useState,
  useRef
} from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import { Text, SubHeading } from '@leroy-merlin-br/backyard-react'

import { useResize } from 'utils/hooks/use-resize'

import * as S from './styled'

const FileName = ({ drag, image, onRemove, hasError }) => {
  const [isMobile] = useResize()

  if (drag) {
    return <S.FileLabel>Solte aqui a imagem</S.FileLabel>
  }

  if (!image) {
    return (
      <S.FileLabel hasError={hasError} ellipsis={false}>
        {isMobile ? 'Foto ou upload de imagem' : 'Clique ou arraste uma imagem'}
      </S.FileLabel>
    )
  }

  return (
    <Fragment>
      <S.FileLabel hasError={hasError} check={true}>
        {image}
      </S.FileLabel>
      <S.ButtonClose
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 386.667 386.667"
        onClick={onRemove}
        width="14"
        height="14"
      >
        <path d="m386.667 45.564-45.564-45.564-147.77 147.769-147.769-147.769-45.564 45.564 147.769 147.769-147.769 147.77 45.564 45.564 147.769-147.769 147.769 147.769 45.564-45.564-147.768-147.77z" />
      </S.ButtonClose>
    </Fragment>
  )
}

const Image = ({
  label,
  forwardedRef,
  watch,
  errors,
  setImage,
  setValue,
  name,
  formFieldName = 'image',
  setValueAsFile,
  accept
}) => {
  const [onDrag, setOnDrag] = useState(false)
  const dropRef = useRef()
  const hasError = !isEmpty(errors[formFieldName])

  const preventDefaults = (event) => {
    event.preventDefault()
    event.stopPropagation()

    event.type === 'dragenter' && setOnDrag(true)
    event.type === 'drop' && setOnDrag(false)
  }

  const handleFile = useCallback(
    (event) => {
      const files = event.target.files || event.dataTransfer.files

      const hasFile = files?.[0]?.name

      if (hasFile) {
        setImage && setImage(files[0])
        setValue(formFieldName, setValueAsFile ? files[0] : files[0].name)
      }
    },
    [setImage, setValue]
  )

  const removeFile = (event) => {
    event.preventDefault()
    event.stopPropagation()

    setImage && setImage(null)
    setValue(formFieldName, setValueAsFile ? null : '')
  }

  useEffect(() => {
    const currentDragRef = dropRef.current

    ;['dragenter', 'dragover', 'dragleave', 'drop'].forEach((eventName) => {
      currentDragRef.addEventListener(eventName, preventDefaults, false)
    })

    currentDragRef.addEventListener('drop', handleFile, false)

    return () => {
      ;['dragenter', 'dragover', 'dragleave', 'drop'].forEach((eventName) => {
        currentDragRef.removeEventListener(eventName, preventDefaults, false)
      })

      currentDragRef.removeEventListener('drop', handleFile, false)
    }
  }, [handleFile])

  return (
    <div>
      <SubHeading as="label" size="kilo" htmlFor={name}>
        {label}
      </SubHeading>

      <S.FileDragField as="label" htmlFor="image-uploader" ref={dropRef}>
        <FileName
          drag={onDrag}
          image={
            setValueAsFile ? watch(formFieldName)?.name : watch(formFieldName)
          }
          onRemove={removeFile}
          hasError={hasError}
        />

        <input
          ref={forwardedRef}
          type="hidden"
          id="image"
          name={name}
          accept={accept}
        />

        <S.File
          type="file"
          id="image-uploader"
          name="image-uploader"
          onChange={handleFile}
          accept={accept}
        />
      </S.FileDragField>

      <S.Message>
        <Text size="kilo" color={errors[formFieldName] ? 'r700' : 'g700'}>
          {errors[formFieldName]?.message}
        </Text>
      </S.Message>
    </div>
  )
}

Image.propTypes = {
  label: PropTypes.string.isRequired,
  forwardedRef: PropTypes.func.isRequired,
  watch: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  setImage: PropTypes.func,
  setValue: PropTypes.func.isRequired,
  formFieldName: PropTypes.string,
  setValueAsFile: PropTypes.bool,
  accept: PropTypes.string
}

export default forwardRef((props, ref) => (
  <Image {...props} forwardedRef={ref} />
))
