import { datadogRum } from '@datadog/browser-rum'
import Cookies from 'js-cookie'

import request from 'catalog/utils/axios'

const SEARCH_INTEREST_SERVICE_STORAGE_DISPLAY_AGAIN =
  '@app:dateLatestCloseSearchInterestService'
const SEARCH_INTEREST_SERVICE_STORAGE_ALREADY_DONE =
  '@app:userAlreadyDoneSearchInterestService'

const CURRENT_SEARCH = '@app:currentSearch'

const sumMinutesToDate = (date: Date, minutes: number) => {
  return new Date(date.setMinutes(minutes + date.getMinutes()))
}

const isUserLogged = () => {
  const userId = Cookies.get('user_id')
  return !!userId
}

export const verifyShouldDisplaySearchAgain = async (): Promise<boolean> => {
  if (
    !isUserLogged()
  ) {
    return false
  }

  const dataSearch = await loadSearchData()
  setCurrentSearch(dataSearch?.searchLink ?? null)

  const dateNow = new Date()

  const localStorageLastCloseSearchInterestService =
    localStorage.getItem(SEARCH_INTEREST_SERVICE_STORAGE_DISPLAY_AGAIN) || ''

  const timeLocalStorage: Date | string = new Date(
    localStorageLastCloseSearchInterestService
  )

  const localStorageAlreadyDoneSearchInterestService = localStorage.getItem(
    SEARCH_INTEREST_SERVICE_STORAGE_ALREADY_DONE + getCurrentSearch()
  )

  if (
    dataSearch?.isSearchEnabled &&
    localStorageAlreadyDoneSearchInterestService !== 'true' &&
    dataSearch?.isOnMobileApp &&
    (timeLocalStorage == 'Invalid Date' ||
      sumMinutesToDate(timeLocalStorage, dataSearch.timeToShowSearchAgain) <
        dateNow)
  ) {
    return true
  }

  return false
}

const setCurrentSearch = (search: null | string) => {
  if (search) {
    localStorage.setItem(CURRENT_SEARCH, search)
  }
}

const getCurrentSearch = () => {
  return localStorage.getItem(CURRENT_SEARCH)
}

export const setTimeToDisplayAgain = () => {
  const timeOfClick = new Date()
  localStorage.setItem(
    SEARCH_INTEREST_SERVICE_STORAGE_DISPLAY_AGAIN,
    timeOfClick.toString()
  )
}

export const openSearch = (
  event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
) => {
  event.preventDefault()
  const currentLink = getCurrentSearch()
  if (currentLink) {
    window.open(currentLink)
    localStorage.setItem(
      SEARCH_INTEREST_SERVICE_STORAGE_ALREADY_DONE + currentLink,
      'true'
    )
    return
  }

  datadogRum.addError('Current link is not defined')
}

type Search = {
  searchLink: string
  isSearchEnabled: boolean
  isOnMobileApp: boolean
  timeToShowSearchAgain: number
}

type GetSearchResponse = {
  data: Search
}

const loadSearchData = async () => {
  try {
    const resp = await request.get<GetSearchResponse>('/api/v3/app/get-config-search')
    return resp.data.data
  } catch (error) {
    datadogRum.addError(error)
    return null
  }
}
