import { Hidden, Text, Badge } from '@leroy-merlin-br/backyard-react'

import { ImageThumbnail } from 'shared/components'

import formatPriceToBRL from 'utils/formatPriceToBRL'

import * as S from './styled'
import { ServiceVisual } from './type'

const Service = ({
  name,
  Component,
  quantity,
  priceUnit,
  totalPrice
}: ServiceVisual) => (
  <>
    <S.ItemInfo>
      <S.WhiteBackgroundProduct>
        <S.ResponsiveInline space="byte">
          <ImageThumbnail
            alt={name}
            src={''}
            Component={Component}
            responsiveSizes={{
              untilKilo: '48px',
              kilo: '48px',
              mega: '48px',
              giga: '48px',
              tera: '48px'
            }}
            noBorder
          />
          <div>
            <Badge>Serviço de Instalação</Badge>
            <Text as="div" color="black" noMargin>
              {name}
            </Text>
            <Hidden when={['mega', 'giga', 'tera']}>
              <S.ItemPrice>
                <div>
                  <Text size="kilo" noMargin>
                    {formatPriceToBRL(priceUnit)}
                  </Text>
                  <Text as="div" isBold noMargin>
                    {formatPriceToBRL(totalPrice)}
                  </Text>
                </div>
              </S.ItemPrice>
            </Hidden>
          </div>
        </S.ResponsiveInline>
      </S.WhiteBackgroundProduct>
    </S.ItemInfo>
    <S.ItemQuantity>
      <S.WhiteBackgroundQuantity>
        <Text isBold noMargin>
          {quantity}
        </Text>
      </S.WhiteBackgroundQuantity>
    </S.ItemQuantity>
    <Hidden when={['untilKilo', 'kilo']}>
      <S.ItemPrice>
        <S.WhiteBackgroundPrice>
          <Text size="kilo" noMargin>
            {formatPriceToBRL(priceUnit)}
          </Text>
          <Text as="div" isBold noMargin>
            {formatPriceToBRL(totalPrice)}
          </Text>
        </S.WhiteBackgroundPrice>
      </S.ItemPrice>
    </Hidden>
  </>
)

export default Service
