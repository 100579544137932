import { Constants } from './'

export const resetAssociated = () => ({
  type: Constants.ASSOCIATED_RESET
})

export const updateAssociatedsBlackList = blackList => ({
  type: Constants.ASSOCIATED_UPDATE_BLACKLIST,
  blackList
})

export const getAssociateds = () => ({
  type: Constants.ASSOCIATED_GET_PRODUCTS
})

export const updateAssociateds = payload => ({
  type: Constants.ASSOCIATED_GET_PRODUCTS_UPDATE,
  payload
})

export const getAssociatedsError = error => ({
  type: Constants.ASSOCIATED_GET_PRODUCTS_ERROR,
  error
})

export const startAddingProduct = id => ({
  type: Constants.ASSOCIATED_PUT_PRODUCTS_START,
  id
})

export const successAddingProduct = id => ({
  type: Constants.ASSOCIATED_PUT_PRODUCTS_SUCCESS,
  id
})

export const errorAddingProduct = (error, id) => ({
  type: Constants.ASSOCIATED_PUT_PRODUCTS_ERROR,
  error,
  id
})

export const resetPutProductError = () => ({
  type: Constants.ASSOCIATED_PUT_PRODUCTS_RESET
})

export const addAssociatedToCart = payload => ({
  type: Constants.ASSOCIATED_PUT_PRODUCTS,
  ...payload
})
