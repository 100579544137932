import StockList from './StockList'

const SapStockList = ({ selectStore, stores, unit, isLocateOnStoreEnabled }) => (
  <StockList
    stores={stores}
    unit={unit}
    selectStore={selectStore}
    isLocateOnStoreEnabled={isLocateOnStoreEnabled}
  />
)

export default SapStockList
