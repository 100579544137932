import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { Theme } from 'scripts/react-components/themes/Theme'

const suggestionsWrapperStyle = ({ theme }: Theme) => css`
  label: suggestions-options-wrapper;

  display: grid;
  grid-gap: 6px;
  width: max-content;
`

export const SuggestionsWrapper = styled('div')(suggestionsWrapperStyle)
