import reactComponentLoader from 'scripts/react-component-loader'

import App from './App'
import { parsedItemsNewMyAccount } from './utils'

export type AppProps = {
  itemsMyAccount: string
  isCaptchaEnabled: boolean
  captchaKey: string
}

const AppWithProps = ({ itemsMyAccount, isCaptchaEnabled, captchaKey }: AppProps) => {
  const itemsMenu = parsedItemsNewMyAccount(itemsMyAccount)

  return (
    <App
      itemsMyAccount={itemsMenu}
      isCaptchaEnabled={isCaptchaEnabled}
      captchaKey={captchaKey}
    />
  )
}

reactComponentLoader(AppWithProps, '[data-page-my-account]')
