import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { Theme } from '@leroy-merlin-br/backyard-theme'

export type ThemeObject = { theme: Theme }

const messageWrapperStyle = ({ theme }: ThemeObject) => css`
  label: chat-body__inset--wrapper;

  background: transparent;
  display: flex;
  gap: ${theme.spacings.bit};
  flex-direction: column;
  justify-content: end;
  height: 101.2%;
`

const scrollAnchorStyle = () => css`
  label: anchor;

  overflow-anchor: auto;
  height: 1px;
`

export const MessagesWrapper = styled('div')(messageWrapperStyle)
export const ScrollAnchor = styled('div')(scrollAnchorStyle)
