import React from 'react'

import Typography, { TypographyProps } from '../Typography'

type NumeralsType = Omit<TypographyProps, 'variant'> & {
  size?: 'lg' | 'xl'
  type?: 'default' | 'strong'
}

function Numerals ({ size = 'lg', type, children, ...rest }: NumeralsType) {
  if (React.isValidElement(children)) {
    throw new Error(
      'Numerals component does not accept React elements as children'
    )
  }

  return (
    <Typography variant={`numerals-${size}`} type={type} {...rest}>
      {children}
    </Typography>
  )
}

export default Numerals
