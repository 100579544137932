import { css } from '@emotion/react'
import styled from '@emotion/styled'

const wrapperStyles = ({ theme }) => css`
  label: wrapper;

  & > div {
    max-width: 1180px;

    & > h1 {
      label: page-title;

      margin-top: ${theme.spacings.mega};
      margin-bottom: ${theme.spacings.exa};
    }
  }
`

const wrapperWithShaddowStyles = ({ theme, withShaddow }) =>
  withShaddow &&
  css`
    label: wrapper--with-shaddow;

    & > div {
      box-shadow: 0 4px 4px -2px ${theme.colors.shadow};

      ${theme.media.mega} {
        box-shadow: unset;
      }
    }
  `

const breadcrumbBarStyles = ({ theme }) => css`
  label: breadcrumb-bar;

  width: 100%;

  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: ${theme.spacings.mega};

  align-items: center;
  justify-content: space-between;

  padding: ${theme.spacings.kilo} 0;
`

const breadcrumbBarSpacedStyles = ({ theme, spaced }) =>
  spaced &&
  css`
    label: breadcrumb-bar--spaced;

    padding: ${theme.spacings.kilo} ${theme.spacings.giga};

    ${theme.media.mega} {
      padding: ${theme.spacings.kilo} ${theme.spacings.tera};
    }
  `

export const Wrapper = styled('div')(wrapperStyles, wrapperWithShaddowStyles)
export const BreadcrumbBar = styled('div')(
  breadcrumbBarStyles,
  breadcrumbBarSpacedStyles
)
