import { datadogRum } from '@datadog/browser-rum'

import emitter from 'scripts/utils/emitter'
import * as cartRepository from 'scripts/api/cart'
import { addToCartDataLayer } from 'scripts/utils/data-layer'

import { cartEventApp } from 'utils/app'

import { treatError } from './errors'

export default async (data, type) => {
  try {
    const response = await cartRepository.putProductsInCart(data)
    const shippingType = data.type

    emitter.emit('cart:update', { count: response.data.uniqueProductsCount })
    emitter.emit('cart:products-added', response.data)
    emitter.emit('product:data-layer', {
      storeName:
      response.data.shippings.find((shipping) => shipping.type === shippingType)?.items[0]
        .storeId || '',
      shippingType:
        shippingType === 'pickupInStore' ? 'pickup-in-store' : 'delivery'
    })

    const productsAddedToCart = response.data.shippings[0]?.items || []

    const isWishList = type === 'wishlist'

    if (isWishList) {
      const productsParams = productsAddedToCart.map((product) => ({
        productName: product.name,
        productId: product.productId,
        price: product.price.total,
        productBrand: product.brand,
        category: product.categoryTree,
        quantity: 1,
        dimension8: product.storeId ? 'pickup-in-store' : 'delivery',
        dimension9: product.storeId || '',
        dimension15: product.shopName
      }))

      addToCartDataLayer(productsParams, { regionId: response.data.regionId, sourceName: type })
    }

    cartEventApp.updateCart(response.data.id, response.data.uniqueProductsCount)
  } catch (error) {
    const errorService = new Error(error.message)
    errorService.name = 'Error when adding products to cart'
    errorService.cause = error
    datadogRum.addError(errorService)

    treatError(error, data.type)
  }
}
