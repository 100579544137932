import styled from '@emotion/styled'
import { css } from '@emotion/react'

import type { Theme } from 'scripts/react-components/themes/Theme'

const couponAlertMessageStyles = ({ theme }: Theme) => css`
  label: coupon-alert-message;

  margin: ${theme.spacings.mega} 0;

  > div {
    & span {
      margin-right: ${theme.spacings.byte};
    }

    & div {
      display: inline;
      white-space: normal;
    }

    & p {
      color: ${theme.colors.n900};
    }

    display: flex;
    border-radius: ${theme.borderRadius.giga};
    padding: ${theme.spacings.kilo};
    background-color: ${theme.colors.v100};
  }
`

export const CouponAlertMessage = styled('div')(couponAlertMessageStyles)
