import { useEffect, useState } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { Icon } from '@leroy-merlin-br/backyard-react'
import { Cross } from '@leroy-merlin-br/backyard-icons'

import { GardenDrawer } from 'shared/components/GardenDrawer'

import * as S from './styled'
import { SocialSignUp } from '../social-sing-up/pages/SocialSignUp'
import { View } from '../social-sing-up/types'

const App = ({ name, email, token }: View) => {
  const [toggleDrawer, setToggleDrawer] = useState(false)

  useEffect(() => {
    setTimeout(() => setToggleDrawer(true), 400)
  }, [])

  return (
    <GardenDrawer
      isOpen={toggleDrawer}
      overlayClick={() => setToggleDrawer(false)}
      className="stock-info-drawer"
    >
      <BrowserRouter basename="/login">
        <S.Header>
          <span onClick={() => setToggleDrawer(false)}>
            <Icon
              as={Cross}
              size="giga"
              color="primary"
              aria-label="Fechar"
            />
          </span>
        </S.Header>

        <S.Wrapper>
          <SocialSignUp
            email={email}
            name={name}
            provider="google-one-tap"
            token={token}
            center={false}
          />
        </S.Wrapper>
      </BrowserRouter>
    </GardenDrawer>
  )
}

export default App
