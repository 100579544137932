const Whatsapp = (props) => (<svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 16L1.13058 11.8926C0.435426 10.6908 0.0649319 9.32541 0.0687514 7.92964C0.0687514 3.55598 3.64383 0 8.03628 0C10.1676 0 12.169 0.825291 13.6701 2.32375C15.175 3.8222 16.0038 5.81507 16 7.93344C16 12.3071 12.4249 15.8631 8.03246 15.8631H8.02864C6.69563 15.8631 5.38553 15.5284 4.22057 14.8971L0 16ZM4.41919 13.4595L4.65982 13.604C5.67581 14.2049 6.84077 14.5206 8.02864 14.5244H8.03246C11.6801 14.5244 14.6517 11.5693 14.6517 7.93344C14.6517 6.17257 13.9642 4.51818 12.7152 3.27074C11.4662 2.02329 9.8009 1.33872 8.03246 1.33872C4.38099 1.33872 1.4094 4.2938 1.4094 7.92964C1.4094 9.17328 1.75698 10.3865 2.42158 11.4362L2.57818 11.6872L1.91358 14.1136L4.41919 13.4595Z" fill="white" />
    <path d="M0.275085 15.7225L1.36747 11.7557C0.695233 10.5958 0.340017 9.27606 0.340017 7.92974C0.340017 3.7082 3.79287 0.273926 8.02872 0.273926C10.0874 0.273926 12.0163 1.07259 13.4677 2.5178C14.9191 3.96301 15.7174 5.88743 15.7174 7.93354C15.7174 12.1551 12.2646 15.5893 8.02872 15.5893H8.0249C6.73772 15.5893 5.47346 15.2661 4.35052 14.6576L0.275085 15.7225Z" fill="url(#paint0_linear_2187_3776)" />
    <path d="M0 16L1.13058 11.8926C0.435426 10.6908 0.0649319 9.32541 0.0687514 7.92964C0.0687514 3.55598 3.64383 0 8.03628 0C10.1676 0 12.169 0.825291 13.6701 2.32375C15.175 3.8222 16.0038 5.81507 16 7.93344C16 12.3071 12.4249 15.8631 8.03246 15.8631H8.02864C6.69563 15.8631 5.38553 15.5284 4.22057 14.8971L0 16ZM4.41919 13.4595L4.65982 13.604C5.67581 14.2049 6.84077 14.5206 8.02864 14.5244H8.03246C11.6801 14.5244 14.6517 11.5693 14.6517 7.93344C14.6517 6.17257 13.9642 4.51818 12.7152 3.27074C11.4662 2.02329 9.8009 1.33872 8.03246 1.33872C4.38099 1.33872 1.4094 4.2938 1.4094 7.92964C1.4094 9.17328 1.75698 10.3865 2.42158 11.4362L2.57818 11.6872L1.91358 14.1136L4.41919 13.4595Z" fill="url(#paint1_linear_2187_3776)" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.0424 4.60952C5.89343 4.27864 5.73683 4.27103 5.59551 4.26723C5.48092 4.26343 5.34724 4.26343 5.21356 4.26343C5.07987 4.26343 4.86598 4.31287 4.68264 4.51063C4.4993 4.7084 3.98749 5.1876 3.98749 6.16502C3.98749 7.13863 4.70174 8.08182 4.80105 8.21493C4.90036 8.34804 6.1799 10.4132 8.20043 11.208C9.88102 11.8698 10.2248 11.7367 10.5876 11.7024C10.9543 11.6682 11.7641 11.2232 11.9321 10.7593C12.0964 10.2953 12.0964 9.89973 12.0467 9.81606C11.997 9.73239 11.8634 9.68295 11.6647 9.58407C11.4661 9.48519 10.4883 9.00599 10.305 8.93753C10.1217 8.87288 9.98797 8.83865 9.85811 9.03641C9.72442 9.23418 9.34247 9.67915 9.22788 9.81226C9.1133 9.94537 8.99489 9.96058 8.79628 9.8617C8.59766 9.76282 7.95598 9.55365 7.19589 8.87668C6.60387 8.35184 6.20282 7.7015 6.08823 7.50373C5.97364 7.30597 6.07677 7.19948 6.17608 7.1006C6.26393 7.01312 6.37469 6.8686 6.474 6.75451C6.57331 6.64041 6.60769 6.55674 6.67262 6.42363C6.73755 6.29052 6.70699 6.17642 6.65734 6.07754C6.60387 5.98246 6.21809 5.00124 6.0424 4.60952Z" fill="white" />
    <defs>
        <linearGradient id="paint0_linear_2187_3776" x1="7.9988" y1="15.7222" x2="7.9988" y2="0.273865" gradientUnits="userSpaceOnUse">
            <stop stop-color="#20B038" />
            <stop offset="1" stop-color="#60D66A" />
        </linearGradient>
        <linearGradient id="paint1_linear_2187_3776" x1="7.99877" y1="15.999" x2="7.99877" y2="-0.00332785" gradientUnits="userSpaceOnUse">
            <stop stop-color="#F9F9F9" />
            <stop offset="1" stop-color="white" />
        </linearGradient>
    </defs>
</svg>
)

export default Whatsapp
