import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { Theme } from 'scripts/react-components/themes/Theme'

export const ActionButtons = styled('div')(
  ({ theme }: Theme) => css`
    display: flex;
    justify-content: space-between;
    gap: ${theme.spacings.mega};
  `
)
