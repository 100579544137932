import { css } from '@emotion/react'
import styled from '@emotion/styled'

const checkboxWrapperStyles = ({ theme }) => css`
  label: checkbox__wrapper;

  display: block;
  padding-bottom: ${theme.spacings.byte};

  & > div label {
    padding-left: 0px;
    padding-right: 26px;

    & svg,
    :before {
      left: auto;
      right: 0;
    }
  }
`

const rightSpacingCheckboxWrapperStyles = ({ rightSpacing }) =>
  rightSpacing &&
  css`
    label: checkbox__wrapper--max-width;

    max-width: calc(100% - ${rightSpacing || 0}px);
  `

const isDisabledCheckboxWrapperStyles = ({ theme, isDisabled }) =>
  isDisabled &&
  css`
    label: checkbox__wrapper--disabled;

    pointer-events: none;
    cursor: default;

    & div label:before {
      border: 1px solid ${theme.colors.n300};
      background-color: ${theme.colors.n100};
    }
  `

export const CheckboxWrapper = styled('a')(
  checkboxWrapperStyles,
  rightSpacingCheckboxWrapperStyles,
  isDisabledCheckboxWrapperStyles
)
