import $ from 'jquery'

import BaseComponent from 'scripts/components/base-component'

const DEFAULTS = {
  hiddenClass: 'hidden',
  successClass: '',
  errorClass: 'feedback-error'
}

class Feedback extends BaseComponent {
  static componentName = 'feedback'

  constructor (element, options = {}) {
    super(element)
    this.options = $.extend({}, DEFAULTS, options)
  }

  init () {
    this.$title = this.$element.find('[data-feedback="title"]')
    this.$content = this.$element.find('[data-feedback="content"]')
  }

  show (feedback = {}) {
    this.$element.removeClass(this.options.hiddenClass)

    if (feedback.title) {
      this.$title.html(feedback.title)
    }

    if (feedback.content) {
      this.$content.html(feedback.content)
    }

    if (feedback.type === 'success') {
      this.$element.addClass(this.options.successClass)
      this.$element.removeClass(this.options.errorClass)
    }

    if (feedback.type === 'error') {
      this.$element.addClass(this.options.errorClass)
      this.$element.removeClass(this.options.successClass)
    }
  }

  hide () {
    this.$element.addClass(this.options.hiddenClass)
  }

  success (feedback) {
    feedback.type = 'success'

    this.show(feedback)
  }

  error (feedback) {
    feedback.type = 'error'

    this.show(feedback)
  }
}

export default ($el) => new Feedback($el).init()

export { Feedback as Component }
