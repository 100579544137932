export const buildDataLayerObject = ({
  type,
  storeId,
  cart,
  region
}) => {
  const currentShipments =
    cart &&
    cart?.shippings?.reduce((resolve, shipping) => {
      return [...shipping.shipments, ...resolve]
    }, [])

  const currentProducts =
    currentShipments &&
    currentShipments.reduce((resolve, shipment) => {
      return [...shipment.items, ...resolve]
    }, [])

  return {
    regionId: region?.id || null,
    products: currentProducts,
    services: cart?.services,
    storeId,
    shipmentType: type
  }
}
