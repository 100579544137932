import Modal from 'garden/src/js/components/modal'

const Alert = ({ title, message, textButton, callback = null }) => {
  const Message = `<div>
    <div class="green-line-alert">
      <h1>${title}</h1>
      <p>${message}</p>
      <div>
        <button class="button button-primary">
          ${textButton}
        </button>
      </div>
    </div>
  </div>`

  const modal = new Modal(Message)
  modal.init()

  modal.$modal.on('click', 'button', () => {
    modal.destroy()
    if (callback) {
      callback()
    }
  })

  modal.show()
}

export default Alert
