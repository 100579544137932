import React from 'react'

import { renderTemplate } from '../render-template'

function OptionComponent ({ facet, option, origin }) {
  const query = `filter[${facet.name}]=${option.name}`

  return (
    <option
      value={query}
      data-query={query}
      data-suffix={option.suffix}
      data-exclusive-url={facet.isUrl}
      data-facet={facet.readableName.toLowerCase()}
      data-origin={origin}
    >
      {option.label} ({option.quantity})
    </option>
  )
}

function HighlightedFacet ({ facet, origin }) {
  return (
    <div className="field field-select">
      <select
        className="select"
        id={`facet-${facet.name}`}
        name={`facet-${facet.cleanName}`}
        data-component="form entry-key/facet-highlight"
      >
        <option disabled />
        {facet.options.map(option => (
          <OptionComponent key={option.name} facet={facet} option={option} origin={origin} />
        ))}
      </select>
      <label className="label" htmlFor={`facet-${facet.name}`}>
        <span className="hidden-mobile align-nowrap">{facet.readableName}</span>
        <span className="hidden-small">
          {facet.readableName.substr(0, 26) + '...'}
        </span>
      </label>
    </div>
  )
}

function AssistedSale ({ assistedSale, origin }) {
  return (
    <div className="stock-filter margin-bottom margin-top mobile">
      <div className="row">
        <div className="col-xs-12">
          <p className="no-margin-top">
            <strong>Disponibilidade de estoque</strong>
          </p>
        </div>
        <div className="col-xs-3">
          <div className="filter-label">Minímo de:</div>
        </div>
        <div className="col-xs-3">
          <input
            className="input stock-quantity"
            pattern="\d+(\.\d+)?"
            name="stock-quantity"
            value={assistedSale.minimumStock}
            data-stock-quantity
          />
        </div>
        <div className="col-xs-3">
          <div className="filter-label">peças</div>
        </div>
        <div className="col-xs-3">
          <button
            className="button button-primary button-full button-thinner no-shadow"
            data-component="entry-key/products-filter"
            data-facet="minimumStock"
            data-origin={origin}
            data-query={`minimumStock=${assistedSale.minimumStock}`}
            data-stock-trigger
          >
            ok
          </button>
        </div>
      </div>
    </div>
  )
}

function HighlightedFacets ({ highlightedFacets, origin, assistedSale }) {
  return (
    <div>
      {highlightedFacets.map(facet => (
        <HighlightedFacet key={facet.name} facet={facet} origin={origin} />
      ))}
      {assistedSale && (
        <AssistedSale assistedSale={assistedSale} origin={origin} />
      )}
    </div>
  )
}

const render = (props = {}) => renderTemplate(<HighlightedFacets {...props} />)

export default render
