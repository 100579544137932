import execute from './execute'

export type AddToCart = {
  regionId: string
  productName: string
  productId: string
  productPrice: number
  productBrand: string
  productCategory: string
  shipmentType: 'pickupInStore' | 'delivery'
  store: string
  marketPlace: string
  productQuantity: string
  relatedProduct: string | null
}

export const dataLayerAddToCart = ({
  regionId,
  productName,
  productId,
  productPrice,
  productBrand,
  productCategory,
  shipmentType,
  store,
  marketPlace,
  productQuantity,
  relatedProduct = null
}: AddToCart) => {
  const dataObject = {
    event: 'addToCart',
    dimension14: regionId,
    ecommerce: {
      currencyCode: 'BRL',
      add: {
        products: [
          {
            name: productName,
            id: productId,
            price: productPrice.toFixed(2),
            brand: productBrand,
            category: productCategory,
            quantity: productQuantity,
            dimension8: shipmentType,
            dimension9: store,
            dimension15: marketPlace,
            dimension31: relatedProduct
          }
        ]
      }
    }
  }

  return dataObject
}

const addToCart = (addToCartProps: AddToCart) => {
  execute(dataLayerAddToCart(addToCartProps))
}

export default addToCart
