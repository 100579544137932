import { css } from '@emotion/react'
import styled from '@emotion/styled'

const wrapperIconStyles = () => css`
  label: wrapper;

  cursor: pointer;
`

const dividerStyles = ({ theme }) => css`
  label: divider;
  margin: ${theme.spacings.bit} 0;

  width: 15px;
  height: 2px;

  background: ${theme.colors.n200};
`

export const WrapperIcon = styled('span')(wrapperIconStyles)
export const Divider = styled('div')(dividerStyles)
