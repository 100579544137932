import transaction from './transaction'
import interaction from './interaction'
import addToCart from './addToCart'
import removeFromCart from './removeFromCart'
import changeCartType from './changeCartType'

const DataLayer = {
  transaction,
  interaction,
  addToCart,
  removeFromCart,
  changeCartType
}

export default DataLayer
