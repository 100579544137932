import React, { useEffect } from 'react'
import { createRoot } from 'react-dom/client'
import { ThemeProvider } from '@emotion/react'
import { light } from '@leroy-merlin-br/backyard-theme'
import { datadogRum } from '@datadog/browser-rum'

export default (ComponentToRender, selector, parent) => {
  const elementParent = parent || document
  const selectorElements = Array.from(elementParent.querySelectorAll(selector))

  if (!selectorElements.length) {
    return
  }

  selectorElements.forEach((el) => {
    try {
      el.setAttribute('data-rcl-status', 'loading')

      const { props: rawProps = '{}', ...dataset } = el.dataset
      const props = JSON.parse(rawProps)
      const root = createRoot(el)

      const Wrapper = () => {
        useEffect(() => {
          el.setAttribute('data-rcl-status', 'success')
        }, [])

        return (
          <ThemeProvider theme={light}>
            <ComponentToRender {...dataset} {...props} />
          </ThemeProvider>
        )
      }

      root.render(<Wrapper />)
    } catch (e) {
      el.setAttribute('data-rcl-status', 'failure')
      datadogRum.addError(e)

      throw e
    }
  })
}
