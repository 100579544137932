import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { Theme } from 'scripts/react-components/themes/Theme'

export const Wrapper = styled('div')(
  ({ theme }: Theme) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0 92px;

    ${theme.media.mega} {
      margin: 20px 72px 92px;
    }
  `
)

export const ItemWrapper = styled('div')(
  ({ theme }: Theme) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${theme.spacings.mega};
    position: relative;
  `
)

export const ItemLabel = styled('span')(
  ({ theme }: Theme) => css`
    font-weight: ${theme.font.weight.bold};
    position: absolute;
    top: 74px;
    width: 12rem;
    text-align: center;
  `
)

export const Line = styled('div')(
  ({ theme }: Theme) => css`
    width: 100%;
    height: 6px;
  `
)

export const LinePending = styled(Line)(
  ({ theme }: Theme) => css`
    background: linear-gradient(
      to right,
      ${theme.colors.p500} 0%,
      ${theme.colors.p500} 15%,
      ${theme.colors.n300} 15%,
      ${theme.colors.n300} 100%
    );
  `
)

export const LineDone = styled(Line)(
  ({ theme }: Theme) => css`
    background-color: ${theme.colors.p500};
  `
)

export const Circle = styled('div')(
  ({ theme }: Theme) => css`
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    ${theme.typography.headings.zetta};
    font-weight: ${theme.font.weight.bold};

    svg {
      fill: ${theme.colors.white};
    }
  `
)

export const CirclePending = styled(Circle)(
  ({ theme }: Theme) => css`
    border: 6px solid ${theme.colors.n300};

    svg {
      width: 32px;
      height: 32px;
      fill: ${theme.colors.n300};
      stroke-width: 2px;
      stroke: ${theme.colors.n300};
    }
  `
)

export const CircleInProgress = styled(Circle)(
  ({ theme }: Theme) => css`
    border: 6px solid ${theme.colors.p500};

    svg {
      width: 32px;
      height: 32px;
      fill: ${theme.colors.p500};
      stroke-width: 2px;
      stroke: ${theme.colors.p500};
    }
  `
)

export const CircleBlock = styled(Circle)(
  ({ theme }: Theme) => css`
    background-color: ${theme.colors.r500};

    svg {
      width: 28px;
      height: 28px;
      fill: ${theme.colors.white};
      stroke-width: 2px;
      stroke: ${theme.colors.white};
    }
  `
)

export const CircleDone = styled(Circle)(
  ({ theme }: Theme) => css`
    background-color: ${theme.colors.p500};

    svg {
      width: 32px;
      height: 32px;
      fill: ${theme.colors.white};
    }
  `
)
