import { css } from '@emotion/react'
import styled from '@emotion/styled'

const sortersWrapperStyles = () => css`
  label: sorter-wrapper;

  position: relative;

  width: 100%;
`

export const Wrapper = styled('div')(sortersWrapperStyles)
