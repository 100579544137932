import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import {
  TextField,
  Container,
  Row,
  Col,
  Button,
  Text,
  Heading
} from '@leroy-merlin-br/backyard-react'

import { isEmail } from 'utils/validators/validators'

import * as S from './styled'

function Form ({ onSubmit, isRequest }) {
  const {
    handleSubmit,
    register,
    reset,
    formState: { isSubmitSuccessful, errors }
  } = useForm({ mode: 'onBlur' })

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset()
    }
  }, [isSubmitSuccessful, reset])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <S.Wrapper>
        <Container>
          <Row align="center">
            <Col
              size={{
                kilo: 2,
                giga: 4,
                tera: 3
              }}
            >
              <S.Heading>
                <Heading color="white" size="giga" noMargin>
                  Fique por dentro das <span>novidades</span> e{' '}
                  <span>promoções</span>
                </Heading>
              </S.Heading>
            </Col>
            <Col
              size={{
                kilo: 2,
                giga: 4,
                tera: 9
              }}
            >
              <S.Inline spacing="mega" align="bottom" noWrap>
                <TextField
                  {...register('email', {
                    required: 'Insira seu e-mail',
                    validate: value => isEmail(value) || 'E-mail inválido'
                  })}
                  label="E-mail"
                  placeholder="seuemail@email.com"
                  state={Boolean(errors.email) && 'invalid'}
                />
                <Button type="submit" isLoading={isRequest}>
                  Cadastrar
                </Button>
              </S.Inline>
            </Col>
          </Row>
        </Container>
      </S.Wrapper>
      <S.TextWrapper>
        <Text size="kilo" color="n300" noMargin>
          Ao se cadastrar você concorda em receber e-mails promocionais e
          novidades. Saiba mais na nossa{' '}
          <a href="/institucional/politica-de-privacidade">
            Politica de Privacidade
          </a>
        </Text>
      </S.TextWrapper>
    </form>
  )
}

export default Form
