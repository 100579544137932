import styled from '@emotion/styled'
import { css } from '@emotion/react'

export const menuStyle = () => css`
  label: menu;

  position: absolute;
  right: 0;
  top: 0;
`

export const Menu = styled('div')(menuStyle)

const contentStyle = () => css`
  label: content;

  text-align: center;

  button {
    margin: 0 auto;
  }
`

export const Content = styled('div')(contentStyle)

const dividirStyle = ({ theme }) => css`
  label: divider;

  background: ${theme.colors.n100};
  display: block;
  height: 1px;
  margin: ${theme.spacings.giga} auto;
  max-width: 160px;
  width: 100%;
`

export const Divider = styled('div')(dividirStyle)

export const projectDateStyle = ({ theme }) => css`
  color: ${theme.colors.n600};
  display: block;
  font-size: ${theme.typography.text.kilo.fontSize};
  line-height: ${theme.typography.text.kilo.lineHeight};
`

export const ProjectDate = styled('span')(projectDateStyle)

export const boxStyle = ({ theme }) => css`
  label: box;

  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: ${theme.spacings.exa};
  padding-bottom: ${theme.spacings.giga};

  ${theme.media.mega} {
    flex-direction: row;
  }
`

export const Box = styled('div')(boxStyle)

export const boxItemStyle = ({ theme }) => css`
  label: box__item;

  text-align: center;

  &:first-of-type {
    border-bottom: 1px solid ${theme.colors.n100};
    border-top: 1px solid ${theme.colors.n100};
  }

  &:first-of-type,
  &:last-of-type {
    padding: ${theme.spacings.giga} 0;
  }

  ${theme.media.mega} {
    text-align: left;

    &:first-of-type,
    &:last-of-type {
      padding: 0 ${theme.spacings.peta};
    }

    &:first-of-type {
      border: 0;
      border-right: 1px solid ${theme.colors.n100};
    }

    width: calc(100% / 2);
  }

  width: 100%;
`

export const BoxItem = styled('div')(boxItemStyle)

export const groupStyle = ({ theme }) => css`
  label: group;

  align-items: flex-end;
  display: flex;
  justify-content: center;
  margin-bottom: ${theme.spacings.giga};

  & > div {
    flex: 1 0 0;
    margin-right: ${theme.spacings.mega};
  }

  input {
    margin-bottom: 0;
  }

  button {
    padding: 0 ${theme.spacings.giga};
    width: 100px;
  }

  ${theme.media.mega} {
    justify-content: flex-start;
  }
`

export const Group = styled('div')(groupStyle)

export const formStyle = ({ theme }) => css`
  label: form;

  display: flex;
  flex-direction: column;

  ${Group} {
    &:not(:last-of-type) {
      margin-bottom: ${theme.spacings.mega};
    }
  }
`

export const Form = styled('form')(formStyle)

const inputsWrapperStyles = () => css`
  label: inputs-wrapper;

  text-align: left;

  div[class*='inline'] {
    & > div:first-of-type {
      flex: 1;
    }
  }

  span[class*='validation-hint'] {
    position: absolute;
  }
`

export const projectFooterStyle = ({ theme }) => css`
  label: project__footer;

  margin-top: ${theme.spacings.giga};
`

export const ProjectFooter = styled('div')(projectFooterStyle)

export const InputsWrapper = styled('div')(inputsWrapperStyles)
