import { useModal, Button, Icon } from '@leroy-merlin-br/backyard-react'
import { InfoCircle } from '@backyard-ui/icons'

import * as S from './styled'

const CheckoutModal = () => {
  const { setModal } = useModal()

  document.addEventListener('checkoutModal', (data) => {
    setModal({
      size: 'mega',
      isCentered: true,
      children: ({ onClose }) => (
        <div>
          <S.WrapperModalTitle>
            <S.Icon>
              {<Icon size="giga" as={InfoCircle}/>}
            </S.Icon>
            <S.Title>{data.detail.title}</S.Title>
          </S.WrapperModalTitle>
          <S.WrapperModal>
            <S.TextBody>{data.detail.description}</S.TextBody>
            <S.Button>
              <Button onClick={onClose} size="kilo">Ok</Button>
            </S.Button>
          </S.WrapperModal>
        </div>
      )
    })
  })

  return null
}

export default CheckoutModal
