import { SelectableText } from 'frontendCheckout/ui/mobile'

type PickupInStoreProps = {
  isPickupInStore: boolean
  onPickUpInStore: () => void
}

export const PickupInStore = ({ isPickupInStore, onPickUpInStore }: PickupInStoreProps) => {
  return (
    <SelectableText selected={isPickupInStore} onClick={onPickUpInStore}>
      Retirar na loja
    </SelectableText>
  )
}
