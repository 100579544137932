export const newsArticleSchema = (item) => {
  const schema = {
    '@context': 'http://schema.org',
    '@type': 'NewsArticle',
    dateModified: item.publishDate,
    datePublished: item.publishDate,
    headline: item.title,
    author: 'Leroy Merlin',
    publisher: {
      '@type': 'Organization',
      name: 'Leroy Merlin',
      logo: {
        '@type': 'ImageObject',
        url: 'https://cdn.leroymerlin.com.br/assets/logo.png',
        name: 'Leroy'
      }
    },
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': 'https://leroymerlin.com.br/dicas'
    },
    image: [item.background_image]
  }

  return schema
}
