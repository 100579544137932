import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { Div as TrackedClickDiv } from 'site/Analytics'

const boxStyles = ({ theme }) => css`
  label: navigation;

  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  user-select: none;
  z-index: 1;

  svg {
    label: navigation__icon;
    position: relative;
    transition: all 600ms cubic-bezier(0.19, 1, 0.22, 1);
  }

  &:hover {
    svg {
      fill: ${theme.colors.p500};
    }
  }
`

const sidesStyles = css`
  &:first-of-type {
    label: navigation__icon--left;

    left: 10px;
  }

  &:last-of-type {
    label: navigation__icon--right;

    right: 10px;
  }
`

export const Box = styled(TrackedClickDiv)(boxStyles, sidesStyles)
