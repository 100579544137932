const successResponse = {
  200: <span>Cupom válido</span>,
  201: <span>Cupom aplicado</span>,
  206: <span>Cupom aplicado parcialmente</span>
}

const errorResponse = {
  404: (
    <span>
      Este cupom <strong>não</strong> é válido
    </span>
  ),
  410: (
    <span>
      <strong>Este cupom expirou</strong>, verifique as regras de apĺicação para
      ter certeza de como utilizá-lo.
    </span>
  ),
  418: (
    <span>
      Infelizmente este cupom não pode ser utilizado pois os itens em seu
      carrinho <strong>não se encaixam nas regras de aplicação</strong>.
      Verifique novamente como utilizá-lo.
    </span>
  ),
  409: (
    <span>
      Este cupom não pode ser aplicado pois{' '}
      <strong>os itens em seu carrinho já possuem desconto</strong>
    </span>
  )
}

const statusResponse = {
  ...successResponse,
  ...errorResponse
}

const isValid = ({ status }) => {
  return status === 200
}

const hasError = ({ status }) => {
  return status && status !== 200
}

export const getMessageByStatus = status => {
  return statusResponse[status] ? statusResponse[status] : statusResponse[404]
}

export const isLoyalty = ({ type }) => {
  return type === 'loyalty'
}
