import { Text } from '@leroy-merlin-br/backyard-react'

import * as S from './styled'

const OfferContent = ({
  offer,
  isOutlet,
  isTypeSale,
  isTypeOffer,
  isTypeLowestPriceOfferStyles,
  featureInstallmentDiscount
}) => {
  if (!offer) {
    return null
  }
  if (featureInstallmentDiscount) return null

  return (
    <S.OfferContent
      isOutlet={isOutlet}
      isTypeSale={isTypeSale}
      isTypeOffer={isTypeOffer}
      isTypeLowestPriceOfferStyles={isTypeLowestPriceOfferStyles}
    >
      <Text as="span" size="kilo" noMargin isBold casing="uppercase">
        {offer.label}
      </Text>
      {!Object.values(offer).includes('lowest-price') && offer.value && (
        <Text as="span" size="kilo" noMargin isBold>
          -{offer.value}%
        </Text>
      )}
    </S.OfferContent>
  )
}

export default OfferContent
