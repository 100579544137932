import { css } from '@emotion/react'
import styled from '@emotion/styled'

import type { Theme } from 'scripts/react-components/themes/Theme'

const wrapperStyles = ({ center = true }) => css`
  label: social-sign-up__wrapper;

  display: flex;
  margin-bottom: ${center ? '10vh' : '2vh'};
  margin-top: ${center ? '10vh' : '2vh'};
  min-width: 375px;
`

export const Wrapper = styled('div')(wrapperStyles)

const titleStyles = ({ theme }: Theme) => css`
  label: social-sign-up__title;

  margin-bottom: ${theme.spacings.kilo};
  min-width: 375px;
`

export const Title = styled('div')(titleStyles)

const rowStyles = ({ theme }: Theme) => css`
  label: social-sign-up__row;

  display: flex;

  flex-direction: column;

  gap: ${theme.spacings.mega};

  ${theme.media.mega} {
    flex-direction: row;
  }
`

export const Row = styled('div')(rowStyles)

const colStyles = () => css`
  label: social-sign-up__col;

  width: 100%;
`

export const Col = styled('div')(colStyles)

const fieldStyles = ({ theme }: Theme) => css`
  label: social-sign-up__field;

  input[disabled], select[disabled] {
    background-color: ${theme.colors.n100};
    box-shadow: none;
    color: ${theme.colors.n300};

    &:hover, &:active {
      border-color: ${theme.colors.n100};
      box-shadow: none;
    }
  }
`

export const Field = styled('div')(fieldStyles)

const buttonStyles = ({ theme }: Theme) => css`
  label: social-sign-up__button;

  margin-top: ${theme.spacings.kilo};
`

export const Button = styled('div')(buttonStyles)

const additionalFieldsWrapper = ({ isVisible }: { isVisible: boolean }) => css`
  label: social-sign-up__additional-fields-wrapper;

  display: ${isVisible ? 'block' : 'none'};
`

export const AdditionalFieldsWrapper = styled('div')(additionalFieldsWrapper)

const errorWithLinkWrapper = ({ theme }: Theme) => css`
  label: error-with-link-wrapper;

  a {
    color: ${theme.colors.p700};
  }
`

export const ErrorWithLinkWrapper = styled('span')(errorWithLinkWrapper)
