import qs from 'qs'

export const buildQueryString = (queryObject = {}, options = {}) => {
  const defaultOptions = {
    ...options,
    encode: false,
    skipNulls: true,
    addQueryPrefix: false
  }

  const paramsKeys = Object.keys(queryObject)

  const queryArray = paramsKeys
    .filter(key => !!queryObject[key])
    .map(key => {
      const value = queryObject[key]
      const query = { [key]: value }
      return qs.stringify(query, defaultOptions)
    })

  const queryString = queryArray.join('&')

  return queryString
}
