import $ from 'jquery'
import Template from 'templates/account/edit-password'

// NOTE: Dont remove this import the modal tests will fail
import Modal from 'garden/src/js/components/modal'

import emitter from 'scripts/utils/emitter'
import componentLoader from 'scripts/component-loader'
import toObject from 'scripts/utils/account/array-to-object'
import BaseComponent from 'scripts/components/base-component'
import { showSpinner, hideSpinner } from 'scripts/utils/spinner'

class Password extends BaseComponent {
  static componentName = 'password'

  static DOMEvents = {
    'click [data-trigger="edit"]': 'onEditClick'
  }

  init () {
    this.template = Template
    this.components = {}
  }

  bindModalListeners () {
    this.$submit.on('click', this.onModalSubmit.bind(this))
  }

  onEditClick () {
    this.unsetModal()
    this.setModal({ _token: $('[name="_token"]').val() })
  }

  onModalSubmit () {
    if (this.components.validation.validateAll()) {
      this.sendForm()
    }
  }

  unsetModal () {
    if (this.components.modal) {
      this.components.modal.destroy()
      return delete this.components.modal
    }
  }

  setModal (_token) {
    this.components.modal = $(this.template(_token))
      .modal({
        triggerClose: '[data-trigger="close"]'
      })
      .data('modal')

    this.components.modal.show()
    this.setModalComponents()
    this.bindModalListeners()
  }

  setModalComponents () {
    componentLoader(this.components.modal.$content.find('[data-component]'))

    this.$modal = this.components.modal.$content
    this.$submit = this.$modal.find('[data-trigger="submit"]')

    this.components.validation = this.$modal
      .find('[data-component="validation"]')
      .data('validation')
  }

  sendForm () {
    showSpinner()

    const serializedData = toObject(
      this.$modal.find('fieldset').serializeArray()
    )

    return $.post(this.$element.attr('data-ajax-update'), serializedData)
      .done(this.handleSuccess)
      .fail(this.handleError)
  }

  handleSuccess = () => {
    hideSpinner()
    this.components.modal.hide()

    emitter.emit('stickyFeedback:success', {
      title: 'Pronto!',
      content: 'Senha atualizada com sucesso.'
    })
  }

  clearNewPasswordValidation = event => {
    emitter.emit('validation:pristine', event.target)
    emitter.emit('validation:resetMessage', event.target)

    $('body').off('focus', '#new-password', this.clearNewPasswordValidation)
  }

  handleError = data => {
    hideSpinner()

    const message = data.responseJSON?.message
    const errors = data.responseJSON?.errors

    if (!message) {
      return emitter.emit('stickyFeedback:error', {
        title: 'Ops! Ocorreu um erro ao tentar alterar a senha.',
        content: 'Tente novamente.'
      })
    }

    if (message === 'Sua senha não confere') {
      const field = this.$modal.find('#current-password')
      return emitter.emit('validation:error', field, null, `${message}.`)
    }

    if (errors?.['new-password']) {
      const field = this.$modal.find('#new-password')
      const errorMessage = errors['new-password'][0]

      $('body').on('focus', '#new-password', this.clearNewPasswordValidation)

      return emitter.emit('validation:error', field, null, `${errorMessage}.`)
    }

    emitter.emit('stickyFeedback:error', {
      title: 'Ops! Ocorreram os seguintes erros:',
      content: message
    })
  }
}

export default $el => new Password($el).init()

export { Password as Component }
