import styled from '@emotion/styled'
import { css } from '@emotion/react'

export const menuStyle = () => css`
  label: menu;

  position: absolute;
  right: 0;
  top: 0;
`

export const Menu = styled('div')(menuStyle)

const contentStyle = () => css`
  label: content;

  text-align: center;

  button {
    margin: 0 auto;
  }
`

export const Content = styled('div')(contentStyle)

const dividirStyle = ({ theme }) => css`
  label: divider;

  background: ${theme.colors.n100};
  display: block;
  height: 1px;
  margin: ${theme.spacings.giga} auto;
  max-width: 160px;
  width: 100%;
`

export const Divider = styled('div')(dividirStyle)
