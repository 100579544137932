import React from 'react'
import type { ChangeEvent } from 'react'
import { IconButton, Text, Card, TextField, Stack } from '@backyard-ui/core'
import { Checkmark } from '@backyard-ui/icons'
import { useRange } from 'react-instantsearch'

import { insightsConfig } from 'scripts/react-components/entry-key/lib/algolia'
import { useServerContext } from 'scripts/react-components/entry-key/routes/routes.context'

import mask from 'utils/maskingUtils'
import formatPriceToBRL from 'utils/formatPriceToBRL'

import { onFilterDataLayer } from 'entry-key/analytics/data-layer'

interface PriceRangeProps {
  /**
   *
   */
  attribute: string

  /**
   *
   */
  index: number

  /**
   *
   */
  label: string
}

type RangeProps = {
  min: number
  max: number
}

export function PriceRange (props: PriceRangeProps) {
  const { attribute, label, index } = props
  const { algolia } = useServerContext()

  const { range, refine, canRefine } = useRange({ attribute })

  const minRange = range.min || 0
  const maxRange = range.max || 0

  const maxValueAccepted = maxRange - 1

  const [currentValues, setCurrentValues] = React.useState({ min: 0, max: 0 })
  const [hasErrors, setHasErrors] = React.useState({ min: false, max: false })

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const formattedValue = parseInt(event.target.value.replace(/\D/g, ''), 10)
    const finalValue = isNaN(formattedValue) ? 0 : formattedValue

    if (event.target.name) {
      setCurrentValues((previousObj: RangeProps) => {
        const updatedValues = {
          ...previousObj,
          [event.target.name]: finalValue
        }

        validateValues(updatedValues, event.target.name)

        return updatedValues
      })
    }
  }

  const handleSubmit = () => {
    insightsConfig.insightsClient('clickedFilters', {
      eventName: 'Filter Applied',
      filters: [`${attribute}:${currentValues.min}-${currentValues.max}`],
      index: algolia.indexName
    })

    onFilterDataLayer({
      searchParam: attribute,
      filterType: {
        index,
        value: label
      },
      filterElement: {
        index: 0,
        value: `${currentValues.min},${currentValues.max}`
      }
    })

    refine([currentValues.min, currentValues.max])

    setCurrentValues({ min: currentValues.min, max: currentValues.max })
  }

  const validateValues = ({ min, max }: RangeProps, attr: string) => {
    const defaultState = { min: false, max: false }

    if (max && max > maxValueAccepted) {
      setHasErrors({ min: false, max: true })
      return
    }

    if (min && min < minRange) {
      setHasErrors({ min: true, max: false })
      return
    }

    if (min !== 0 && max !== 0) {
      setHasErrors(prevState => {
        const state = Boolean(min >= max)

        return state ? { ...prevState, [attr]: state } : defaultState
      })
    } else {
      setHasErrors(defaultState)
    }
  }

  const isInvalidField = (field: 'min' | 'max') => {
    if (hasErrors[field]) {
      return true
    }

    if (currentValues[field]) {
      return false
    }
  }

  const disableSubmitAction =
    Boolean(!currentValues.min || !currentValues.max) ||
    hasErrors.min ||
    hasErrors.max

  const handleValue = (value: 'min' | 'max') => {
    return currentValues[value] !== 0
      ? mask.maskedText('money', currentValues[value])
      : ''
  }

  const handleEnterKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && !disableSubmitAction) {
      handleSubmit()
    }
  }

  if (!canRefine) {
    return null
  }

  return (
    <Card.Root key={attribute}>
      <Card.Header>
        <Text weight="bold" asChild>
          <span>{label}</span>
        </Text>
      </Card.Header>

      <Card.Body>
        <Stack align="center" spacing="1.5">
          <TextField.Root size="xs">
            <TextField.Input
              name="min"
              onChange={handleChange}
              onKeyDown={handleEnterKeyPress}
              value={handleValue('min')}
              placeholder="Mínimo"
              isInvalid={isInvalidField('min')}
              autoComplete="off"
            />
          </TextField.Root>

          <Text size="sm" color="neutral-700">
            até
          </Text>

          <TextField.Root size="xs">
            <TextField.Input
              name="max"
              onChange={handleChange}
              onKeyDown={handleEnterKeyPress}
              value={handleValue('max')}
              placeholder="Máximo"
              isInvalid={isInvalidField('max')}
              autoComplete="off"
            />
          </TextField.Root>
          <IconButton
            size="xs"
            icon={<Checkmark />}
            radius="lg"
            onClick={handleSubmit}
            isDisabled={disableSubmitAction}
          />
        </Stack>

        {isInvalidField('min') && (
          <Text size="sm" color="critical-600">
            Preço mínimo: {formatPriceToBRL(minRange)}
          </Text>
        )}

        {isInvalidField('max') && (
          <Text size="sm" color="critical-600">
            Preço máximo: {formatPriceToBRL(maxValueAccepted)}
          </Text>
        )}
      </Card.Body>
    </Card.Root>
  )
}
