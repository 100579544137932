import {
  Text,
  Visible,
  Heading,
  Button,
  Stack
} from '@leroy-merlin-br/backyard-react'

import * as S from './styled'

const FidelityProgram = ({
  howItWorksUrl = '',
  alreadyInUrl = '',
  imageUrl = ''
}) => (
  <S.Wrapper>
    <div>
      <Stack space="kilo">
        <Heading size="giga" color="v600" noMargin>
          Programa de Fidelidade Leroy Merlin Com Você
        </Heading>

        <Text size="mega">
          Comprando na Leroy Merlin, você acumula pontos e pode aproveitar
          nossos descontos exclusivos!
        </Text>

        <Button
          appearance="primary"
          as="a"
          href={howItWorksUrl}
          size="mega"
          isDisabled={!howItWorksUrl}
          isStretch
        >
          Quero fazer parte
        </Button>

        <Button
          variant="ghost"
          as="a"
          href={alreadyInUrl}
          size="mega"
          isDisabled={!alreadyInUrl}
          isStretch
        >
          Já faço parte
        </Button>
      </Stack>
    </div>

    <Visible when={['giga', 'tera']}>
      <S.Image url={imageUrl} />
    </Visible>
  </S.Wrapper>
)

export default FidelityProgram
