import * as S from './styled'

const Store = ({ stores = [] }) => (
  <ul>
    {stores.map((store, index) => (
      <li key={index}>
        <S.Link href={store.url}>
          <S.Image src={store.image} />
          <S.InfoContainer>
            <S.Title>{store.name}</S.Title>
            <S.Info>{store.address}</S.Info>
            <S.Info>Fone {store.phones}</S.Info>
            <S.Info>
              Segunda à sábado {store.schedule.weekdays[0]} -{' '}
              {store.schedule.weekdays[1]} / domingos e feriados{' '}
              {store.schedule.sunday[0]} - {store.schedule.sunday[1]}
            </S.Info>
          </S.InfoContainer>
        </S.Link>
      </li>
    ))}
  </ul>
)

export default Store
