import { memo, useRef } from 'react'
import PropTypes from 'prop-types'
import { motion } from 'framer-motion'
import { maxBy } from 'lodash'
import {
  Text,
  Icon,
  Inline,
  Stack,
  Card,
  Heading
} from '@leroy-merlin-br/backyard-react'
import { ClockOutline } from '@backyard-ui/icons'

import { Shell } from 'shared/components/Shell'

import { useResize } from 'utils/hooks/use-resize'
import { formatters } from 'utils/formatters'

import { TimeLineSkeleton } from './TimeLineSkeleton'
import { decimalToPercent } from './helpers'
import * as S from './styled'
import { TimeLineTooltip } from '../TimeLineTooltip'

const TimeLine = ({ data, isLoading }) => {
  const [isMobile] = useResize()

  const maxGoal =
    !isLoading && parseInt(maxBy(data.prizes, data => data.goal).goal, 10)

  const constraintsRef = useRef(null)

  return (
    <Card>
      <Stack space="mega">
        <Inline alignY="center" space="bit">
          <Heading as="h2" size="giga" noMargin>
            Meus Objetivos
          </Heading>
          <TimeLineTooltip />
        </Inline>

        <div>
          {isLoading ? (
            <Shell width={190} height={14} borderRadius="kilo" />
          ) : (
            <S.ExpirationLabel>
              <Icon as={ClockOutline} size="kilo" />
              <Text size="kilo" noMargin>
                Termina em: {data.seasonEndsAt}
              </Text>
            </S.ExpirationLabel>
          )}
        </div>
      </Stack>

      <S.Wrapper as={motion.div} ref={constraintsRef}>
        <S.DragArea
          width={1000}
          as={motion.div}
          drag="x"
          dragConstraints={{
            left: isMobile ? -750 : -200,
            right: 0
          }}
          whileTap={{ cursor: 'grabbing' }}
        >
          {isLoading ? (
            <TimeLineSkeleton />
          ) : (
            <S.Bar width={1000}>
              {data.prizes.map(({ id, goal, reward }) => (
                <S.Item
                  key={id}
                  toLeft={decimalToPercent(goal, maxGoal)}
                  isGoal={data.points.season.balance >= goal}
                >
                  <S.Ballon>+ {formatters.toDecimal(reward)} pts</S.Ballon>
                  <S.Label>{formatters.toCurrency(goal)}</S.Label>
                </S.Item>
              ))}

              <S.BarProgress
                progress={decimalToPercent(data.points.season.balance, maxGoal)}
                value={formatters.toCurrency(data.points.season.balance)}
              />
            </S.Bar>
          )}
        </S.DragArea>
      </S.Wrapper>
    </Card>
  )
}

TimeLine.propTypes = {
  data: PropTypes.object,
  isLoading: PropTypes.bool
}

export default memo(TimeLine)
