import { css } from '@emotion/react'
import styled from '@emotion/styled'

const shareButtonListStyles = () => css`
label: share-button__list;

align-items: center;
display: flex;
flex-flow: column wrap;
font-size: 0;
margin: 0;
position: relative;
`

const shareButtonListActiveStyles = ({ theme, isActive }) =>
  isActive &&
  css`
    label: share-button__list--active;

    left: 0;
    position: absolute;
    top: ${theme.typography.headings.exa.fontSize};
    z-index: ${theme.zIndex.modal};
  `

const shareButtonListItemStyles = ({ theme }) => css`
  label: share-button__item;

  display: flex;
  margin-top: ${theme.typography.subHeadings.kilo.fontSize};
`

export const ShareButtonList = styled('ul')(shareButtonListStyles, shareButtonListActiveStyles)
export const ShareButtonListItem = styled('li')(shareButtonListItemStyles)
