import axios from 'axios'

import { requestStore } from 'scripts/react-components/utils/request-store'

const endpoint = `${window.env && window.env.baseHost}/api/boitata/v1/categories`

export const getMenu = () =>
  axios.get(`${endpoint}/tree`, { withCredentials: true })

export const getStoredMenu = requestStore(getMenu)

export const getMenuVA = () =>
  axios.get(`${endpoint}/tree?va=true`, { withCredentials: true })

export const getStoredMenuVA = requestStore(getMenuVA)

export const getChildren = (id) =>
  axios.get(`${endpoint}/${id}/children`, { withCredentials: true })
