type MessageObject = {
  code: string
  message: string
}

type ErrorDescription = {
  code?: string
  message?: string
  messages?: MessageObject[]
}

export const hasUnknownError = (
  errors: ErrorDescription[],
  allowedCodes: string[]
): boolean => {
  const hasSomeUnknownError = errors.some(
    ({ code }) => !code || !allowedCodes.includes(code)
  )

  return hasSomeUnknownError
}
