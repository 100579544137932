import { PropsWithChildren } from 'react'

import { Component } from '../BaseComponent/BaseComponent'

type RootProps = { isOpen: boolean }

export const Root = ({ children, isOpen }: PropsWithChildren<RootProps>) => {
  return (
    <Component.Root isOpen={isOpen} placement="bottom">
      {children}
    </Component.Root>
  )
}
