import * as S from './styled'

const RegionSuggestion = ({ isOpen, suggestions = [], onRegionSelect }) =>
  isOpen && (
    <S.Suggestions>
      {suggestions.length &&
        suggestions.map(suggestion => (
          <S.SuggestionsItem
            key={`item-${suggestion.id}`}
            onClick={() => onRegionSelect(suggestion)}
            data-cy="suggestions-item"
          >
            {suggestion.city} - {suggestion.state}
          </S.SuggestionsItem>
        ))}
    </S.Suggestions>
  )

export default RegionSuggestion
