import { Fragment } from 'react'
import { Button, Stack, Text } from '@leroy-merlin-br/backyard-react'

import { CouponData } from 'lmcv/components/CouponModal/types'

import * as S from './styled'
import { Customer, InfoText } from '../../types'

type FooterProps = {
  couponData: CouponData
  infoText: InfoText
  isButtonDisabled: boolean
  isSharingLoading: boolean
  currentStep: number
  prevStep: () => void
  nextStep: () => void
  onCouponShare: () => void
  customerSelected?: Customer
}

const Footer = ({
  couponData,
  infoText,
  isButtonDisabled,
  isSharingLoading,
  currentStep,
  prevStep,
  nextStep,
  onCouponShare,
  customerSelected
}: FooterProps) => {
  const getNextButtonProps = () => {
    if (currentStep === 1) {
      return {
        children: 'compartilhar',
        onClick: nextStep,
        isDisabled: isButtonDisabled
      }
    }

    if (currentStep === 2) {
      return {
        children: 'Continuar',
        onClick: nextStep,
        isDisabled: !customerSelected
      }
    }

    if (currentStep === 3) {
      return {
        children: infoText.price,
        onClick: onCouponShare,
        isDisabled: !customerSelected,
        isLoading: isSharingLoading
      }
    }

    return {
      children: ''
    }
  }

  return (
    <S.Wrapper isActive={couponData.isActive}>
      <Stack space="byte">
        {currentStep === 1 && (
          <Text
            color={couponData.isActive ? 'f400' : 'n400'}
            size="mega"
            align="center"
            noMargin
          >
            {infoText.price && (
              <Fragment>
                Valor do cupom <br />
                <Text as="span" isBold>{`${infoText.price}`}</Text>
              </Fragment>
            )}

            {infoText.expire && (
              <Fragment>
                Válido por <strong>{infoText.expire}</strong>
                <br />
                após a ativação
              </Fragment>
            )}
          </Text>
        )}

        {currentStep !== 1 && (
          <Button
            isStretch
            variant="ghost"
            isDisabled={isSharingLoading}
            onClick={prevStep}
          >
            Voltar
          </Button>
        )}

        <Button isStretch {...getNextButtonProps()} />
      </Stack>
    </S.Wrapper>
  )
}

export default Footer
