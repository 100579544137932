import { default as QuantityButton } from 'checkout/containers/ProductQuantity'

import { ChangeItemTypeButton } from 'newCheckout/pages/Cart/components/ChangeItemTypeButton'

import { ServiceQuantity } from '../StandaloneService/Quantity'
import PriceInfo from './PriceInfo'
import * as S from './styled'

const PriceWrapperDesktop = ({
  discountByPaymentMethod,
  packaging,
  packagingCount,
  translatedUnit,
  quantity,
  price,
  brand,
  name,
  categoryTree,
  id,
  productId,
  regionId,
  shippingType,
  shopName,
  skuId,
  storeId,
  updateProduct,
  onSuccess,
  onError,
  isHybridEnabled,
  changeItemTypeCallback,
  cartId,
  itemAvailableStores,
  dataLayerInfo,
  installments,
  isStandaloneService,
  fetchPriceShipping,
  zipCode
}) => {
  return (
    <S.PriceWrapper isMobile={false}>
      {isStandaloneService ? (
        <ServiceQuantity id={id} regionId={regionId} quantity={quantity} callback={fetchPriceShipping} />
      ) : (
        <QuantityButton
          pageType="cart"
          brand={brand}
          name={name}
          categoryTree={categoryTree}
          id={id}
          productId={productId}
          regionId={regionId}
          type={shippingType}
          shopName={shopName}
          quantity={quantity}
          packaging={packaging}
          packagingCount={packagingCount}
          skuId={skuId}
          storeId={storeId}
          price={price}
          discountByPaymentMethod={discountByPaymentMethod}
          updateProduct={updateProduct}
          onSuccess={onSuccess}
          onError={onError}
          fromCart
          zipCode={zipCode}
        />
      )}
      <PriceInfo
        packaging={packaging}
        packagingCount={packagingCount}
        translatedUnit={translatedUnit}
        quantity={quantity}
        price={price}
        isMobile={false}
        discountByPaymentMethod={discountByPaymentMethod}
        installments={installments}
      />
      {isHybridEnabled && (
        <S.ChangeItemTypeDesktopWrapper>
          <ChangeItemTypeButton
            cartId={cartId}
            itemId={id}
            callback={changeItemTypeCallback}
            type={shippingType}
            itemAvailableStores={itemAvailableStores}
            dataLayerInfo={dataLayerInfo}
          />
        </S.ChangeItemTypeDesktopWrapper>
      )}
    </S.PriceWrapper>
  )
}

export default PriceWrapperDesktop
