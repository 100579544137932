import { Component, Fragment } from 'react'

import { GardenDrawer } from 'shared/components/GardenDrawer'

import StockInfoButton from './StockInfoButton'
import BatchSearchButton from './BatchSearchButton'
import StoreMap from '../StoreMap'

class Stock extends Component {
  constructor (props) {
    super(props)

    this.state = {
      disabled: this.props.disabled
    }
  }

  renderStoreMap = () => {
    if (!this.props.isLocateOnStoreEnabled || !this.props.selectedStore) {
      return null
    }

    const {
      closeMap,
      selectedStore: { storeCode, section, name, sectionName }
    } = this.props

    return (
      <StoreMap
        storeName={name}
        sectionName={sectionName}
        storeCode={storeCode}
        key={`${storeCode}${section}`}
        section={section}
        closeMap={closeMap}
      />
    )
  }

  render () {
    const {
      fetchStock,
      drawerOpen,
      hide,
      drawerClass,
      contentClass,
      children,
      trackingInfo,
      showBatchSearchButton,
      storeSlug,
      lm,
      isLocateOnStoreEnabled
    } = this.props

    return (
      <Fragment>
        <div className="stock-buttons">
          <StockInfoButton
            trackingInfo={trackingInfo}
            fetchStock={fetchStock}
            disabled={this.state.disabled}
            isLocateOnStoreEnabled={isLocateOnStoreEnabled}
          />

          {showBatchSearchButton && (
            <BatchSearchButton
              trackingInfo={trackingInfo}
              fetchStock={fetchStock}
              storeSlug={storeSlug}
              lm={lm}
            />
          )}
        </div>

        <GardenDrawer
          isOpen={drawerOpen}
          overlayClick={hide}
          className={drawerClass}
          dataCy="stock-drawer"
        >
          {this.renderStoreMap()}
          <div className={contentClass}>{children}</div>
        </GardenDrawer>
      </Fragment>
    )
  }
}

export default Stock
