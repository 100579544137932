import PropTypes from 'prop-types'

import { Shell } from 'shared/components/Shell'

import * as S from './styled'

const Avatar = ({ image, name, big, loading }) => {
  const getContent = () => {
    if (loading) {
      return <Shell height="100%" borderRadius="circle" />
    }

    if (image) {
      return <S.Image image={image} />
    }

    if (name) {
      return name[0]
    }
  }

  return <S.Photo big={big}>{getContent()}</S.Photo>
}

Avatar.propTypes = {
  name: PropTypes.string,
  image: PropTypes.string,
  big: PropTypes.bool,
  loading: PropTypes.bool
}

Avatar.defaultProps = {
  big: false,
  loading: false
}

export default Avatar
