export const TrashCan = () => (
  <svg
    width="14"
    height="15"
    viewBox="0 0 14 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.28544 1.34986H4.14258C4.22115 1.34986 4.28544 1.28558 4.28544 1.20701V1.34986H9.71401V1.20701C9.71401 1.28558 9.77829 1.34986 9.85686 1.34986H9.71401V2.63558H10.9997V1.20701C10.9997 0.576648 10.4872 0.0641479 9.85686 0.0641479H4.14258C3.51222 0.0641479 2.99972 0.576648 2.99972 1.20701V2.63558H4.28544V1.34986ZM13.2854 2.63558H0.714007C0.397935 2.63558 0.142578 2.89093 0.142578 3.20701V3.77843C0.142578 3.85701 0.206864 3.92129 0.285435 3.92129H1.36401L1.80508 13.2606C1.83365 13.8695 2.33722 14.3499 2.94615 14.3499H11.0533C11.664 14.3499 12.1658 13.8713 12.1944 13.2606L12.6354 3.92129H13.714C13.7926 3.92129 13.8569 3.85701 13.8569 3.77843V3.20701C13.8569 2.89093 13.6015 2.63558 13.2854 2.63558ZM10.9158 13.0641H3.08365L2.65151 3.92129H11.3479L10.9158 13.0641Z"
      fill="#102739"
    />
  </svg>
)
