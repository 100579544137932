import { useState, useEffect, useCallback, useMemo } from 'react'

import GPTPropTypes from 'catalog/utils/google-publisher-tag-proptypes'

export const useAdSlot = ({
  path,
  id,
  targeting,
  sizes,
  fetchMarginPercent
}) => {
  const [isLoading, setIsLoading] = useState(true)
  const [slot, setSlot] = useState()
  const [slotSize, setSlotSize] = useState([])
  const [currentViewportBreakpoint, setCurrentViewportBreakpoint] = useState()

  const slotSizesByViewport = useMemo(
    () =>
      sizes.reduce(
        (views, size) => ({
          ...views,
          [size.viewport[0]]: size.slot
        }),
        {}
      ),
    [sizes]
  )

  const sizesBreakpoints = useMemo(() => {
    const breakpoints = sizes.map(size => size.viewport[0])
    return breakpoints.sort((a, b) => b - a)
  }, [sizes])

  const getCurrentViewportBreakpoint = useCallback(() => {
    const currentBreakpoint = sizesBreakpoints.find(
      item => window.innerWidth >= item
    )

    return currentBreakpoint
  }, [sizesBreakpoints])

  const setSlotSizeBeforeRender = useCallback(() => {
    if (slotSizesByViewport && !isNaN(currentViewportBreakpoint)) {
      const hasMultipleSizes = isNaN(
        slotSizesByViewport[currentViewportBreakpoint][0]
      )
      const firstSize = !hasMultipleSizes
        ? slotSizesByViewport[currentViewportBreakpoint]
        : slotSizesByViewport[currentViewportBreakpoint][0]

      const width = firstSize[0]
      const height = firstSize[1]

      setSlotSize([width, height])
    }
  }, [slotSizesByViewport, currentViewportBreakpoint])

  const setSlotSizeAfterRender = useCallback(event => {
    setIsLoading(false)

    if (!event.isEmpty) {
      const width = event.size[0]
      const height = event.size[1]

      setSlotSize([width, height])
    } else {
      googletag.pubads().clear([event.slot])
      googletag.pubads().collapseEmptyDivs([event.slot])
    }
  }, [])

  const refreshSlots = useCallback(() => {
    setIsLoading(true)
    const newViewportBreakpoint = getCurrentViewportBreakpoint()

    if (newViewportBreakpoint !== currentViewportBreakpoint) {
      googletag.cmd.push(() => {
        googletag.pubads().refresh([slot])
      })
    }

    setCurrentViewportBreakpoint(newViewportBreakpoint)
  }, [currentViewportBreakpoint, getCurrentViewportBreakpoint, slot])

  const setTargeting = useCallback(
    slot => {
      Object.keys(targeting).forEach(key => {
        slot.setTargeting(key, targeting[key])
      })
    },
    [targeting]
  )

  const addSizes = useCallback(
    slot => {
      const sizeMappingArray = sizes
        .reduce((mapping, size) => {
          return mapping.addSize(size.viewport, size.slot)
        }, googletag.sizeMapping())
        .build()

      slot.defineSizeMapping(sizeMappingArray)
    },
    [sizes]
  )

  useEffect(() => {
    const initialViewportBreakpoint = getCurrentViewportBreakpoint()
    setCurrentViewportBreakpoint(initialViewportBreakpoint)

    setSlotSizeBeforeRender()
  }, [getCurrentViewportBreakpoint, setSlotSizeBeforeRender])

  useEffect(() => {
    window.googletag = window.googletag || { cmd: [] }

    const slotSizes = sizes.map(size => size.slot)

    googletag.cmd.push(() => {
      googletag.pubads().collapseEmptyDivs()
      googletag.pubads().enableLazyLoad({ fetchMarginPercent })

      const newSlot = googletag.defineSlot(path, slotSizes, id)
      newSlot.addService(googletag.pubads())

      if (sizes.length > 1) {
        addSizes(newSlot)
      }

      if (targeting) {
        setTargeting(newSlot)
      }

      setSlot(newSlot)

      googletag.pubads().enableSingleRequest()
      googletag.enableServices()
      googletag
        .pubads()
        .addEventListener('slotRenderEnded', setSlotSizeAfterRender)
    })

    googletag.cmd.push(() => {
      googletag.display(id)
    })
  }, [
    path,
    id,
    sizes,
    addSizes,
    targeting,
    setTargeting,
    fetchMarginPercent,
    setSlotSizeAfterRender
  ])

  useEffect(() => {
    if (sizes.length > 1) {
      window.addEventListener('resize', refreshSlots)
      return () => window.removeEventListener('resize', refreshSlots)
    }
  }, [refreshSlots, sizes, getCurrentViewportBreakpoint])

  return [isLoading, slotSize]
}

useAdSlot.propTypes = GPTPropTypes
