import { useState, memo } from 'react'
import { Button, Checkbox } from '@leroy-merlin-br/backyard-react'

import * as S from './styled'

const Footer = ({
  onClick,
  onClose
}) => {
  const [checked, setChecked] = useState(false)

  return (
    <S.Footer>
      <Checkbox
        id="crossServices"
        value="checked"
        onChange={() => setChecked((status) => !status)}
        checked={checked}
      >
        Aceito as condições do serviço
      </Checkbox>
      <S.ButtonsContainer>
        <Button onClick={onClose} variant="outline" appearance="info">
          Voltar
        </Button>
        <Button
          onClick={onClick}
          isDisabled={!checked}
          appearance={(checked ? 'primary' : 'info')}
        >
          Aceitar
        </Button>
      </S.ButtonsContainer>
    </S.Footer>
  )
}

export default memo(Footer)
