import styled from '@emotion/styled'
import { css } from '@emotion/react'

type LineProps = {
  lineStyle: 'dashed' | 'solid';
}

const wrapperStyle = () => css`
  width: 100%;
  padding-right: 24px;
`
export const Wrapper = styled('div')(wrapperStyle)

const orderStatusItemStyle = ({ lineStyle = 'dashed' }: LineProps) => css`
label: order-status-timeline;

display: flex;
gap: 8px;
padding: 16px;
justify-content: space-between;
position: relative;

&:not(:last-child)::after {
  content: '';
  position: absolute;
  width: 2px;
  height: calc(100% - 24px);
  background-color: transparent;
  border-left: 2px ${lineStyle} #C7C3BB;
  left: 23px;
  top: 36px;
}
`
export const OrderStatusItem = styled('div')(orderStatusItemStyle)

const contentTextStyle = () => css`
  label: order-status-text;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
`
export const ContentText = styled('div')(contentTextStyle)
