import { requestStore } from 'utils/request-store'

import instance from './api'
import { API_ENDPOINTS } from './constants'

type LoyaltyProfessional = {
  documentation?: {
    id?: string
    picture?: string
  }
}

type LoyaltyPoints = {
  actual?: number
  season?: {
    balance: number
    toExpire: number
    expireAt: string
  }
  toExpire?: number
  expireAt?: string
}

export type LoyaltyExternalCatalog = {
  slug?: string
  url?: string
  banners?: {
    desktop?: string
    mobile?: string
  }
}

export interface LoyaltyProfile {
  name: string
  picture: string | null
  points: LoyaltyPoints
  occupation: string | null
  professional: LoyaltyProfessional | never[]
  status: number
  hasPreferences: boolean
  hasProfessionalRelationship: boolean
  externalCatalogs?: LoyaltyExternalCatalog[]
  seasonEndsAt: string
}

export interface LoyaltyProfileResponse {
  data: LoyaltyProfile
}

export const getLoyaltyProfile = async () => {
  const response = await instance
    .get<LoyaltyProfileResponse>(API_ENDPOINTS.loyaltyProfile)
    .then(({ data }) => data)
    .catch((err) => {
      throw err
    })

  return response
}

export const storedGetLoyaltyProfile = requestStore(getLoyaltyProfile)
