import cookies from 'js-cookie'

import * as storage from 'utils/storage'

const redirectUrl = target => {
  const currentUrl = encodeURIComponent(window.location.href)

  cookies.remove('user')

  window.sessionStorage.removeItem('userInfo')
  window.location = `/${target}?redirect=${currentUrl}`

  storage.removeAll('@celebre')
}

export default redirectUrl
