import { Component } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { Button } from '@leroy-merlin-br/backyard-react'
import { ShoppingCartOutline } from '@backyard-ui/icons'

import emitter from 'scripts/utils/emitter'

import { hideSpinner, showSpinner } from 'utils/Spinner'

import FeedbackErrorsTemplate from './FeedbackErrorsTemplate'

const MESSAGES = {
  errors: {
    fail: ['Erro interno, tente novamente mais tarde']
  }
}

class QuantityControls extends Component {
  constructor (props) {
    super(props)

    const priceTo = props.price?.to || null

    this.state = {
      quantity: 1,
      isDisabledDecrementButton: true,
      isDisabledAddToCartButton: !priceTo
    }
  }

  incrementItem = () => {
    this.setState(prevState => ({
      quantity: prevState.quantity + 1,
      isDisabledDecrementButton: false
    }))
  }

  decrementItem = () => {
    this.setState(prevState => {
      return prevState.quantity <= 1
        ? { quantity: 1, isDisabledDecrementButton: true }
        : { quantity: prevState.quantity - 1, isDisabledDecrementButton: false }
    })
  }

  sendToHybrisCart = async () => {
    const { quantity } = this.state
    const { lm } = this.props

    const endpoint = `/hybris/carts/current?lm=${lm}&quantity=${quantity}`

    this.toggleCartButton()
    showSpinner()

    try {
      const {
        data: { redirect }
      } = await axios.get(endpoint)

      this.redirectTo(redirect)
    } catch (err) {
      this.onAddProductFailure(err)
    } finally {
      this.toggleCartButton()
      hideSpinner()
    }
  }

  redirectTo (url) {
    window.location.href = url
  }

  toggleCartButton () {
    const { isDisabledAddToCartButton } = this.state

    this.setState({ isDisabledAddToCartButton: !isDisabledAddToCartButton })
  }

  onAddProductFailure = ({ responseJSON }) => {
    let errors = MESSAGES.errors.fail

    if (responseJSON && responseJSON.errors) {
      errors = responseJSON.errors
    }

    emitter.emit('stickyFeedback:error', {
      title: 'Ops! Ocorreram os seguintes erros:',
      content: FeedbackErrorsTemplate({ errors })
    })
  }

  onChangeHandler = event => {
    this.setState({ quantity: event.target.value })
  }

  render () {
    const {
      quantity,
      isDisabledDecrementButton,
      isDisabledAddToCartButton
    } = this.state

    return (
      <div className="assisted-sale-controls">
        <div className="quantity-controls">
          <button
            className="decrement-button"
            onClick={this.decrementItem}
            type="button"
            disabled={isDisabledDecrementButton}
          >
            <i className="glyph glyph-minus-btn" />
          </button>
          <input
            className="quantity-input"
            type="number"
            value={quantity}
            onChange={this.onChangeHandler}
          />
          <button
            className="increment-button"
            onClick={this.incrementItem}
            type="button"
          >
            <i className="glyph glyph-plus-btn" />
          </button>
        </div>
        <div className="quantity-add">
          <Button
            onClick={this.sendToHybrisCart}
            isDisabled={isDisabledAddToCartButton}
            iconRight={ShoppingCartOutline}
            label="Adicionar ao Carrinho"
            appearance="primary"
            size="kilo"
            isStretch
          >
            Adicionar
          </Button>
        </div>
      </div>
    )
  }
}

QuantityControls.propTypes = {
  lm: PropTypes.number.isRequired
}

export default QuantityControls
