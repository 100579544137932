import type { MouseEvent } from 'react'
import { Card, ScrollArea, Text, Checkbox, Stack } from '@backyard-ui/core'
import { useRefinementList } from 'react-instantsearch'

import { onFilterDataLayer } from 'scripts/react-components/entry-key/analytics/data-layer'
import { useServerContext } from 'scripts/react-components/entry-key/routes/routes.context'

import { getCategoryAttributes } from 'entry-key/routes/proxy/category/utils/getCategoryAttributes'

import styles from './Checkboxes.styles'

interface CheckboxesProps {
  /**
   *
   */
  attribute: string

  /**
   *
   */
  index: number

  /**
   *
   */
  label: string
}

const ITEMS_RELATED_TO_FIXED_HEIGHT = 11

export function Checkboxes (props: CheckboxesProps) {
  const { attribute, label, index } = props

  const { algolia } = useServerContext()

  const {
    canRefine,
    items,
    refine,
    canToggleShowMore,
    isShowingMore,
    toggleShowMore
  } = useRefinementList({
    attribute,
    showMore: true,
    limit: 5,
    sortBy: ['count:desc', 'name:asc'],
    showMoreLimit: 1000
  })

  const { attributes: getAttribute } = getCategoryAttributes(
    algolia.facets,
    label
  )

  if (
    !canRefine ||
    (getAttribute?.characteristicName === label && getAttribute.isHidden)
  ) {
    return null
  }

  const handleRefine = (event: MouseEvent<HTMLInputElement>, index: number) => {
    refine(event.currentTarget.value)

    onFilterDataLayer({
      searchParam: attribute,
      filterType: {
        index: index ?? 0,
        value: label
      },
      filterElement: {
        index,
        value: event.currentTarget.value
      }
    })

    if (getAttribute) {
      localStorage.setItem(
        '@algolia/categories-attributes',
        JSON.stringify(getAttribute)
      )
    } else {
      localStorage.removeItem('@algolia/categories-attributes')
    }
  }

  const prefix = getAttribute?.prefix || ''
  const sufix = getAttribute?.sufix || ''

  const Elements = (
    <Stack direction="column" spacing="2">
      {items.map((item, index) => {
        if (!item.count) {
          return null
        }

        return (
          <Stack key={item.value} align="center" spacing="1">
            <Checkbox
              value={item.value}
              isChecked={item.isRefined}
              onClick={event => handleRefine(event, index)}
              className="overflow-hidden"
            >
              <span className={styles().label()}>
                {prefix} {item.label} {sufix}
              </span>
            </Checkbox>
            <span className={styles().count()}>{item.count}</span>
          </Stack>
        )
      })}
    </Stack>
  )

  return (
    <Card.Root key={attribute} data-testid="checkbox">
      <Card.Header>
        <Text weight="bold" asChild>
          <span>{label}</span>
        </Text>
      </Card.Header>

      <Card.Body>
        {isShowingMore && items.length > ITEMS_RELATED_TO_FIXED_HEIGHT ? (
          <ScrollArea UNSAFE_className="w-full h-[304px]">
            {Elements}
          </ScrollArea>
        ) : (
          Elements
        )}
        <button
          onClick={toggleShowMore}
          className={styles().button({ disabled: !canToggleShowMore })}
        >
          {isShowingMore ? 'Ver menos' : 'Ver mais'}
        </button>
      </Card.Body>
    </Card.Root>
  )
}
