import api from '../api'

export type Location = {
  id: string
  region: string
  city: string
  state: string
  stateCode: string
}

export const getLocationByZipcode = async (
  zipcode: string
): Promise<Location> => {
  const { data } = await api.get<Location>(`/api/boitata/v1/zipcode/${zipcode}`)

  return data
}

export const getLocationsByCityName = async (
  cityName: string
): Promise<Location[]> => {
  const { data } = await api.get<Location[]>(
    `/api/boitata/v1/zipcode/city/${cityName}`
  )

  return data
}
