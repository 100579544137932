/**
 * This function check if a search can be found inside a text, ignoring the case and normalizing the values.
 *
 * This function receives two string params: 'text' and 'search'. After normalizing them and lowering their case
 * it checks if the value inside 'search' is included inside 'text', returning a resulting boolean value.
 *
 * @param {string}  text         The string in which the search will be made.
 * @param {string}  search       The string that will be searched inside the text.
 *
 * @return {boolean} Returns if the value inside 'text' includes or not the value inside 'search'.
 */
export const textIncludesSearch = (text, search) => {
  // The normalize() method returns the Unicode Normalization form of the string.
  // Ex: 'ñ' -> '\u006E\u0303'. The code point for "n" (U+006E) followed by the code point for the combining tilde (U+0303).
  // The replace() method bellow is removing the accents code points, leaving just the normal caracteres code points.
  // For more info read: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/normalize .

  const normalizdedText = text
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')

  const normalizdedSearch = search
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')

  return normalizdedText.includes(normalizdedSearch)
}
