import { FC } from 'react'

import { TBrand } from '../../types/CardBrand'
import * as Icons from './icon'
import * as Logos from './logo'

export const CreditCardIcons: Record<TBrand, FC> = {
  amex: Icons.AmexIcon,
  celebre: Icons.CelebreIcon,
  'celebre-mais': Icons.CelebreIcon,
  'celebre-grafite': Icons.CelebreIcon,
  'celebre-nanquim': Icons.CelebreIcon,
  'celebre-pro': Icons.CelebreIcon,
  diners: Icons.DinersIcon,
  elo: Icons.EloIcon,
  hiper: Icons.HiperIcon,
  hipercard: Icons.HiperIcon,
  mastercard: Icons.MasterCardIcon,
  visa: Icons.VisaIcon,
  default: Icons.DefaultIcon
}

export const CreditCardLogos: Record<TBrand, FC> = {
  amex: Logos.AmexLogo,
  celebre: Logos.CelebreLogo,
  'celebre-mais': Logos.CelebreLogo,
  'celebre-grafite': Logos.CelebreLogo,
  'celebre-nanquim': Logos.CelebreLogo,
  'celebre-pro': Logos.CelebreLogo,
  diners: Logos.DinersLogo,
  elo: Logos.EloLogo,
  hiper: Logos.HiperLogo,
  hipercard: Logos.HiperLogo,
  mastercard: Logos.MasterCardLogo,
  visa: Logos.VisaLogo,
  default: Logos.AmexLogo
}
