import { Heading, Container, Col, Row } from '@leroy-merlin-br/backyard-react'

import { Step } from './components/Step'
import * as S from './styled'

const HowTo = () => (
  <Container>
    <S.TitleWrapper>
      <Heading size="exa" noMargin>
        Passo a Passo
      </Heading>
    </S.TitleWrapper>
    <S.Section>
      <Row>
        <Col
          size={{
            giga: 5,
            tera: 4
          }}
        >
          <Step
            image="passo1.png"
            stepLabel="1° PASSO"
            title="Cadastre-se"
            subtitle="E em até 48 horas você receberá um e-mail de confirmação!"
          />
        </Col>
        <Col
          size={{
            giga: 5,
            tera: 4
          }}
          offset={{ giga: 1, tera: 2 }}
        >
          <Step
            image="passo2.png"
            stepLabel="2° PASSO"
            title="Confira as ofertas"
            subtitle="Aproveite todas as novidades, cupons e promoções disponíveis no <strong>site</strong> da Leroy Merlin"
          />
        </Col>
        <Col
          size={{
            giga: 5,
            tera: 4
          }}
          offset={{ tera: 1 }}
        >
          <Step
            image="passo3.png"
            stepLabel="3° PASSO"
            title="Divulgue seu link"
            subtitle="Compartilhe  seu link com clientes e amigos e lucre a cada venda faturada!"
          />
        </Col>
        <Col size={{ giga: 5 }} offset={{ giga: 1, tera: 2 }}>
          <Step
            image="passo4.png"
            stepLabel="4° PASSO"
            title="Acompanhe as vendas"
            subtitle="Todas as vendas monitoradas disponíveis no dashboard dentro da plataforma!"
          />
        </Col>
      </Row>
    </S.Section>
  </Container>
)

export default HowTo
