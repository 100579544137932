export const ArrowUp = () => (
  <svg
    data-testid="arrow-up"
    width="8"
    height="8"
    viewBox="0 0 8 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.51657 5.94879L4.17829 3.85282C4.072 3.7868 3.928 3.7868 3.82171 3.85282L0.482285 5.93303C0.281143 6.05817 0 5.93401 0 5.72116V4.56527C0 4.48151 0.0468574 4.40268 0.125714 4.35242L3.82171 2.04952C3.928 1.98349 4.072 1.98349 4.17829 2.04952L7.87314 4.35242C7.95314 4.40268 8 4.48151 8 4.56527V5.73693C8 5.95175 7.71771 6.07591 7.51657 5.94879Z"
      fill="#41A017"
    />
  </svg>
)
