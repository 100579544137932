import { Stack, Skeleton } from '@backyard-ui/core'

const CommentsSkeleton = () => (
  <Stack direction="column" align="flex-start" spacing="9">
    <Skeleton width="196px" height="14px" radius="xl" />
    <Skeleton width="196px" height="14px" radius="xl" />

    <div className="w-full">
      <Stack direction="column" spacing="3">
        <Skeleton width={'100%'} height="14px" radius="xl" />
        <Skeleton width={'100%'} height="14px" radius="xl" />
        <Skeleton width={'100%'} height="14px" radius="xl" />
        <Skeleton width={'100%'} height="14px" radius="xl" />
      </Stack>
    </div>

    <Skeleton width="196px" height="14px" radius="xl" />
    <Skeleton width="310px" height="42px" radius="xl" />
  </Stack>
)

export default CommentsSkeleton
