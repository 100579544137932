import { splitCategoryTree, normalizedProduct } from './helpers'

export const sendToApp = args => {
  if (!window.ReactNativeWebView || !window.ReactNativeWebView.postMessage) {
    return
  }

  window.ReactNativeWebView.postMessage(
    JSON.stringify({ type: 'tracker', ...args })
  )

  window.ReactNativeWebView.postMessage(
    JSON.stringify({ type: 'track', data: { ...args } })
  )
}

const freightCosts = cart => {
  sendToApp({
    event: 'shipping',
    properties: {
      currency: 'BRL',
      shipping: parseFloat(cart.freightCost)
    }
  })
}

const purchase = context => {
  sendToApp({
    event: 'purchase',
    properties: {
      currency: 'BRL',
      ...context
    }
  })
}

const firstSale = context => {
  sendToApp({
    event: 'first_sale',
    properties: {
      currency: 'BRL',
      ...context
    }
  })
}

const addToWishlist = product => {
  const category = splitCategoryTree(product.category)
  const price = product.price.to
    ? parseFloat(`${product.price.to.integers}.${product.price.to.decimals}`)
    : ''

  sendToApp({
    event: 'add_to_wishlist',
    properties: {
      currency: 'BRL',
      items: [
        {
          item_id: product.id,
          item_name: product.name,
          discount: product.discountPercentage,
          item_brand: product.brand,
          item_category: category[0] || '',
          item_category2: category[1] || '',
          item_category3: category[2] || '',
          item_category4: category[3] || '',
          item_category5: category[4] || '',
          price: price,
          currency: 'BRL'
        }
      ],
      value: price
    }
  })
}

const clickBanner = banner => {
  sendToApp({
    event: 'clickBanner',
    properties: {
      page_url: banner.url,
      category: banner.category,
      label: banner.label,
      action: banner.action
    }
  })
}

const viewItem = product => {
  const category = splitCategoryTree(product.category)

  sendToApp({
    event: 'view_item',
    properties: {
      currency: 'BRL',
      items: [
        {
          item_id: product.id,
          item_name: product.name,
          discount: product.discountPercentage,
          item_brand: product.brand,
          item_category: category[0] || '',
          item_category2: category[1] || '',
          item_category3: category[2] || '',
          item_category4: category[3] || '',
          item_category5: category[4] || '',
          price: parseFloat(
            `${product.price.to.integers}.${product.price.to.decimals}`
          ),
          currency: 'BRL'
        }
      ],
      hasBuybox: product.hasBuybox,
      unavailable: product.unavailable ? 'ecommerce' : null,
      value: parseFloat(
        `${product.price.to.integers}.${product.price.to.decimals}`
      )
    }
  })
}

const viewItemList = itemsList => {
  sendToApp({
    event: 'view_item_list',
    properties: {
      items: itemsList.items.map(normalizedProduct)
    }
  })
}

const selectItem = selectedItem => {
  sendToApp({
    event: 'select_item',
    properties: {
      items: selectedItem.map(normalizedProduct)
    }
  })
}

const login = method => {
  sendToApp({
    event: 'login',
    properties: {
      method
    }
  })
}

const viewListing = () => {
  sendToApp({
    event: 'view_listing',
    properties: {}
  })
}

const viewContent = () => {
  sendToApp({
    event: 'view_content',
    properties: {}
  })
}

const signUp = method => {
  sendToApp({
    event: 'sign_up',
    properties: {
      method
    }
  })
}

export default {
  freightCosts,
  purchase,
  firstSale,
  addToWishlist,
  clickBanner,
  viewItem,
  login,
  signUp,
  viewListing,
  viewContent,
  selectItem,
  viewItemList
}
