import { useState, useEffect } from 'react'
import axios, { AxiosError } from 'axios'

import { loyaltyProfileApi } from 'api'
import type { LoyaltyProfile as LoyaltyProfileType } from 'api/loyaltyProfile'

import { formatters } from 'utils/formatters'

import { LoyaltyStatus } from '../../../lmcv-pro/utils/LoyaltyStatus'
import { useUserResources } from '../useUserResources'

export type LoyaltyStatusType = {
  getCode: () => number
  isActive: () => boolean
  isProEAD: () => boolean
  is: (isCode: number) => boolean
}

type LoyaltyPoints = {
  actual: number
  formatted: string
}

export const useLoyaltyProfile = () => {
  const [loyaltyProfile, setLoyaltyProfile] = useState<LoyaltyProfileType>()
  const [loyaltyPoints, setLoyaltyPoints] = useState<LoyaltyPoints>()
  const [loyaltyStatus, setLoyaltyStatus] = useState<LoyaltyStatusType>()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<AxiosError>()

  const { userResources, userControls } = useUserResources()

  const fetchLoyaltyProfile = async (clearRequest = false) => {
    setIsLoading(true)

    try {
      const { data } = await loyaltyProfileApi.storedGetLoyaltyProfile(
        clearRequest
      )

      const pointsActual = data?.points?.actual || 0
      const pointsFormatted = formatters.toDecimal(pointsActual)

      setLoyaltyProfile(data)
      setLoyaltyPoints({
        actual: pointsActual,
        formatted: pointsFormatted
      })

      if (data) {
        const newLoyaltyStatus = LoyaltyStatus(data?.status)

        setLoyaltyStatus(newLoyaltyStatus)
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        setError(error)
      } else {
        throw error
      }
    }

    setIsLoading(false)
  }

  const refresh = () => {
    fetchLoyaltyProfile(true)
  }

  useEffect(() => {
    const isRegularUserLoggedIn =
      userControls.isLoggedIn && userControls.isLoyaltyElegible
    if (isRegularUserLoggedIn) {
      fetchLoyaltyProfile()
    }
  }, [userControls, userResources])

  return {
    loyaltyProfile,
    loyaltyPoints,
    loyaltyStatus,
    isLoading,
    error,
    refresh
  }
}
