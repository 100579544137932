import React from 'react'

import { renderTemplate } from '../render-template'

const ProductComponent = ({
  product,
  assistedSale,
  storeSlug,
  placeholder,
  maxLimit,
  bazaarvoiceEnabled
}) => {
  const picture = product.pictures[0]
  const toPriceInt = product.toPrice.integers
  const toPriceDecimal = product.toPrice.decimals
  const showAddToCartLink = assistedSale && storeSlug
  const showStockInfo =
    storeSlug && product.stockByUserStoreContext !== undefined
  const installments = product.installments
  const moreThanOneUnit = product.stockByUserStoreContext > 1
  const parts = moreThanOneUnit ? 'peças' : 'peça'
  const unitMessage = product.unit === 'm²' ? 'm²' : parts
  const availableMessage = moreThanOneUnit ? 'disponíveis' : 'disponível'
  const isSoldOut = product.isSoldOut

  return (
    <div
      className={`thumbnail thumbnail-hover product-thumb ${
        showAddToCartLink ? 'store-product' : ''
      } ${product.isOutlet ? 'thumb-black-weekend' : ''}`}
      title={product.name}
    >
      <div className="row">
        <div className="col-xs-6 col-sm-12 image-wrapper align-left">
          {product.percentage && (
            <div className="badge badge-danger color-warning discount-badge">
              -{product.percentage}%
            </div>
          )}
          {product.isFreeShipping && !assistedSale ? (
            <span className="badge badge-primary product-badge buybox-option-badge">
              Frete grátis
            </span>
          ) : product.isOutlet ? (
            <div className="badge outlet-badge">Black Friday</div>
          ) : product.isOurLowestPrice ? (
              <div className="badge product-badge lowestprice-badge">
                Nosso preço + baixo
              </div>
          ) : (
            product.hasOnlineExclusiveLabel && (
              <div className="badge badge-primary product-badge exclusive-badge">
                Exclusivo Site
              </div>
            )
          )}
          <a href={product.url}>
            <img
              className="image product-thumb-image"
              src={placeholder}
              data-src={picture}
              alt={product.short_name}
              data-lazy
              width="158"
              height="163"
            />
          </a>
        </div>
        <div className="col-xs-6 col-sm-12">
          <figcaption className="caption align-left">
            <a href={product.url}>
              {assistedSale ? (
                <div className="name hidden-mobile">
                  {product.short_name.substr(0, 36)}...
                </div>
              ) : (
                <div className="name hidden-mobile">
                  {product.short_name.substr(0, 55)}...
                </div>
              )}
              <div className="name hidden-small">
                {product.short_name.substr(0, 44)}...
              </div>

              <div className="additional no-margin">
                {assistedSale && (
                  <>
                    <div className="product-code">Cód. {product._id}</div>
                    <div className="badges-wrapper">
                      {product.isAVS && (
                        <span className="badge badge-primary product-badge">
                          AVS
                        </span>
                      )}
                      {product.isVMI && (
                        <span className="badge badge-primary product-badge">
                          VEF
                        </span>
                      )}
                      {product.acceptsBNDES && (
                        <span className="badge badge-primary product-badge">
                          BNDES
                        </span>
                      )}
                    </div>
                  </>
                )}
              </div>

              {showStockInfo &&
              product.platformStockByUserStoreContext !== undefined ? (
                <div
                  className="additional stock-link"
                  data-component="assisted-sale/stock"
                  data-lm={product._id}
                  data-store-slug={storeSlug}
                >
                  {product.stockByUserStoreContext.toString().replace('.', ',')}{' '}
                  {unitMessage} na loja
                  <br />
                  {product.platformStockByUserStoreContext} {unitMessage} no CD
                </div>
              ) : (
                showStockInfo && (
                  <div
                    className="additional stock-link"
                    data-component="assisted-sale/stock"
                    data-lm={product._id}
                    data-store-slug={storeSlug}
                  >
                    {product.stockByUserStoreContext
                      .toString()
                      .replace('.', ',')}{' '}
                    {unitMessage} {availableMessage}
                  </div>
                )
              )}

              {showAddToCartLink && (
                <>
                  <div
                    className="quantity-selector compact margin-bottom margin-top-small"
                    data-component="quantity"
                  >
                    <button
                      className="button button-neutral no-padding"
                      data-action="subtract"
                    >
                      -
                    </button>
                    <input
                      className="input align-center"
                      type="text"
                      value="1"
                      data-step="1"
                      data-limit={maxLimit ? product.stock : 0}
                      data-product-lm={product._id}
                      data-quantity="input"
                    />
                    <button
                      className="button button-neutral no-padding"
                      data-action="sum"
                    >
                      +
                    </button>
                  </div>
                  <div className="align-center">
                    <a
                      className="color-primary"
                      data-product-code={product._id}
                      data-lm={product._id}
                      data-component="add-to-hybris-cart"
                    >
                      <p>
                        <i className="glyph glyph-cart"></i>
                        <span className="underlined" data-button="ecommerce">
                          Adicionar ao Carrinho
                        </span>
                      </p>
                    </a>
                  </div>
                </>
              )}

              {bazaarvoiceEnabled && (
                <div
                  className="bv-widget-rating margin-top"
                  data-bv-show="inline_rating"
                  data-bv-product-id={product._id}
                  data-bv-redirect-url={product.url}
                ></div>
              )}

              {isSoldOut ? (
                <div className="soldout">Indisponível</div>
              ) : (
                <div
                  className={`price-tag ${
                    product.fromPrice ? 'promotional' : ''
                  }`}
                >
                  <div>
                    {product.fromPrice && (
                      <div className="from-price">
                        <span className="price">
                          R$ {product.fromPrice.integers},{' '}
                          {product.fromPrice.decimals}
                        </span>
                        <span className="from-price-unit">{product.un}</span>
                      </div>
                    )}
                    <span className="price-currency">R$</span>
                    <span className="price-integer">{toPriceInt}</span>
                    <span className="price-decimal">, {toPriceDecimal}</span>
                    <span className="price-unit">{product.un}</span>
                  </div>
                  {installments.amount > 1 && (
                    <div className="installments">
                      {installments.amount}x de R$ {installments.value} sem
                      juros
                    </div>
                  )}
                </div>
              )}
            </a>
          </figcaption>
        </div>
      </div>
    </div>
  )
}

const Products = ({
  products,
  assistedSale,
  storeSlug,
  bazaarvoiceEnabled,
  maxLimit,
  placeholder
}) => {
  return (
    <>
      {products.map(product => (
        <ProductComponent
          key={product._id}
          product={product}
          assistedSale={assistedSale}
          storeSlug={storeSlug}
          bazaarvoiceEnabled={bazaarvoiceEnabled}
          maxLimit={maxLimit}
          placeholder={placeholder}
        />
      ))}
    </>
  )
}

const render = (props = {}) => renderTemplate(<Products {...props} />)

export default render
