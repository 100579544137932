import { css } from '@emotion/react'
import styled from '@emotion/styled'

const priceInfoStyles = ({ theme }) => css`
  label: price__info;

  display: flex;
  flex-direction: column;

  @media (max-width: ${theme.media.kilo}) {
    margin-bottom: ${theme.spacings.kilo};
  }
`

const unitPriceStyles = ({ theme, decoration }) => css`
  label: price__unit;

  color: ${theme.colors.n400};
  font-size: ${theme.typography.text.kilo.fontSize};
  height: 20px;
  line-height: ${theme.typography.text.kilo.lineHeight};
  text-align: left;
  text-decoration: ${decoration ? 'line-through' : 'unset'};
`

const totalPriceStyles = ({ theme }) => css`
  label: price__total;

  font-size: ${theme.typography.text.mega.fontSize};
  height: 20px;
  line-height: ${theme.typography.text.kilo.lineHeight};
  font-weight: ${theme.font.weight.regular};
`

export const UnitPrice = styled('span')(unitPriceStyles)
export const TotalPrice = styled('span')(totalPriceStyles)
export const PriceInfo = styled('span')(priceInfoStyles)
