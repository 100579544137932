import { ReactNode } from 'react'
import { Heading, Icon, Inline, Stack, Text } from '@leroy-merlin-br/backyard-react'
import { ChevronLeft } from '@backyard-ui/icons'
import { Link } from 'react-router-dom'

import * as S from './styled'

export type LayoutProps = {
  children: ReactNode
  description?: string | ReactNode
  backLink?: string
  maxWidth?: number | string
}

const Layout: React.FC<LayoutProps> = ({
  children,
  description,
  backLink,
  maxWidth
}) => (
  <S.Wrapper>
    {backLink && (
      <S.LinkWrapper>
        <Link to={backLink}>
          <Inline space="bit" alignY="baseline">
            <Icon as={ChevronLeft} size="kilo" />
            <Text size="mega" noMargin>
              voltar
            </Text>
          </Inline>
        </Link>
      </S.LinkWrapper>
    )}

    <S.SubWrapper style={{ maxWidth }}>
      <Stack space="byte">
        {description && (
          <Heading size="peta">
            {description}
          </Heading>
        )}

        {children}
      </Stack>
    </S.SubWrapper>
  </S.Wrapper>
)

export default Layout
