import { useEffect } from 'react'

import { useResize } from 'utils/hooks/use-resize'

import * as tracker from 'frontendCheckout/tracker'

import { SuccessType } from './type'
import Visual from './Visual'
import { Header, Summary, PaymentData, ProductList } from './components'
import { Pix } from './pages'
import * as dataLayer from './dataLayer'

const Success = ({
  order,
  paymentMethod,
  shippings,
  services,
  summaryData,
  user,
  transactionStatus,
  datalayerProducts,
  address
}: SuccessType) => {
  const [isMobile] = useResize(992)

  useEffect(() => {
    if (transactionStatus) {
      tracker.pushPurchase({
        order,
        dataLayerProducts: datalayerProducts,
        shippings,
        services,
        address,
        paymentMethod,
        summaryData
      })

      dataLayer.transaction({
        address,
        order,
        paymentMethod,
        shippings,
        summaryData,
        services,
        datalayerProducts
      })
      dataLayer.interaction({ paymentMethod, summaryData })
    }
  }, [
    order,
    paymentMethod,
    shippings,
    services,
    summaryData,
    user,
    transactionStatus,
    datalayerProducts,
    address
  ])

  if (paymentMethod.type === 'pix' && paymentMethod.qr_code) {
    return (
      <Pix
        orderId={order._id}
        orderRevenue={summaryData.totalPrice}
        baseQrCode={paymentMethod.qr_code.imageBase64}
        emvQrCode={paymentMethod.qr_code.emv}
        shippings={shippings}
        services={services}
      />
    )
  }

  return (
    <Visual
      content={
        <>
          <Header
            orderId={order._id}
            paymentMethod={paymentMethod}
            user={user}
          />
          <PaymentData paymentMethod={paymentMethod} />
          <ProductList
            address={address}
            shippings={shippings}
            services={services}
          />
        </>
      }
      summary={
        <Summary
          summaryData={summaryData}
          shippings={shippings}
          paymentMethod={paymentMethod}
          hasServices={!!services?.length}
          hasStandaloneService={
            !!services.find((service) => !service?.relatedProduct?.id)
          }
          isMobile={isMobile}
        />
      }
    />
  )
}

const SuccessParseValues = (props: any) => {
  const order = JSON.parse(props.order)
  const paymentMethod = JSON.parse(props.paymentMethod)
  const shippings = JSON.parse(props.shippings)
  const services = JSON.parse(props.services)
  const summaryData = JSON.parse(props.summaryData)
  const user = JSON.parse(props.user)
  const datalayerProducts = JSON.parse(props.datalayerProducts)
  const transactionStatus = !!props.transactionStatus
  const address = JSON.parse(props.address)

  if (paymentMethod.brand) {
    paymentMethod.brand = paymentMethod.brand.toLowerCase()
  }
  return (
    <Success
      order={order}
      paymentMethod={paymentMethod}
      shippings={shippings}
      services={services}
      summaryData={summaryData}
      user={user}
      transactionStatus={transactionStatus}
      datalayerProducts={datalayerProducts}
      address={address}
    />
  )
}

export default SuccessParseValues
