import { useEffect, Fragment } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { Stack } from '@leroy-merlin-br/backyard-react'
import { Global } from '@emotion/react'

import { Actions as CouponActions } from 'lmcv/redux/modules/coupon'
import { Actions as ProActions } from 'lmcv/redux/modules/professional'

import { Professional } from './components/Professional'
import { Preferences } from './components/Preferences'
import * as S from './styled'
import { CAROUSEL_COUPONS_LIMIT } from './components/CouponsCarousel/constants'
import { CouponsCarousel } from './components/CouponsCarousel'

const Home = () => {
  const dispatch = useDispatch()

  const coupons = useSelector(state => state.coupon, shallowEqual)
  const { payload: loyaltyProfile } = useSelector(
    state => state.loyaltyProfile.get,
    shallowEqual
  )
  const { professional } = useSelector(state => state, shallowEqual)
  const { loyaltyStatus } = useSelector(
    state => state.loyaltyProfile,
    shallowEqual
  )

  useEffect(() => {
    dispatch(CouponActions.getMoney(1, null, CAROUSEL_COUPONS_LIMIT))
    dispatch(CouponActions.getPercentage(1, null, CAROUSEL_COUPONS_LIMIT))
  }, [dispatch])

  useEffect(() => {
    if (!loyaltyStatus.isProEAD()) {
      dispatch(ProActions.getProfessional())
    }
  }, [dispatch, loyaltyStatus])

  return (
    <Fragment>
      <Global styles={S.globalStyles} />
      <Stack>
        <CouponsCarousel
          title="Minhas Vantagens"
          seeAllLink="/minhas-vantagens"
          data={coupons.percentage}
        />

        {!loyaltyProfile.hasPreferences && (
          <Preferences hasPreferences={loyaltyProfile.hasPreferences} />
        )}

        <CouponsCarousel
          title="Cashback"
          seeAllLink="/troque"
          data={coupons.money}
        />

        <Professional data={professional} />
      </Stack>
    </Fragment>
  )
}

export default Home
