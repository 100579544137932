import { css } from '@emotion/react'
import styled from '@emotion/styled'

const wrapperStyles = ({ theme }) => css`
  padding-bottom: ${theme.spacings.byte};

  strong {
    font-weight: ${theme.font.weight.bold};
  }

  .notification-item {
    margin-top: ${theme.spacings.mega};

    &:first-of-type {
      margin-top: 0;
    }
  }
`

export const Wrapper = styled('div')(wrapperStyles)
