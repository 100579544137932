const CelebreIcon = () => (
  <svg
    width="107"
    height="25"
    viewBox="0 0 107 25"
    xmlns="http://www.w3.org/2000/svg"
    style={{ backgroundColor: '#387F45', padding: 2 }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M63.0823 0.457686L60.953 10.9091H61.0198C62.4498 8.73971 64.5141 7.61497 66.6768 7.61497C70.2046 7.61497 71.5346 10.5436 71.5346 13.4055C71.5346 18.33 67.9751 24.4543 61.522 24.4543C58.6852 24.4543 56.5642 23.2862 56.5642 20.2941C56.5862 19.4541 56.6748 18.617 56.8295 17.791L60.3239 0.454346L63.0823 0.457686ZM59.588 17.8611C59.4247 18.525 59.3579 19.209 59.3893 19.892C59.3893 21.4222 60.2539 22.2866 61.9844 22.2866C66.1445 22.2866 68.6726 17.3288 68.6726 13.7677C68.6726 11.7652 67.8082 9.90783 65.6121 9.90783C63.2159 9.90783 60.7527 12.3709 59.9551 16.164L59.588 17.8611Z"
      fill="white"
    />
    <path
      d="M33.3001 24.0922L37.7923 0.466038L40.5541 0.466038L36.0284 24.0922L33.3001 24.0922Z"
      fill="white"
    />
    <path
      d="M9.73906 24.426C12.2687 24.426 14.298 23.9254 15.5629 23.353L15.1975 21.0901C13.9659 21.7209 12.1019 22.0547 10.4048 22.0547C5.41855 22.0547 3.58292 18.7606 3.58292 14.7339C3.58292 11.348 4.91467 8.18569 7.01055 6.22323C8.75671 4.60144 11.0504 3.698 13.4336 3.6934C15.4294 3.6934 17.0247 4.12395 17.8591 4.62458L18.7586 2.39511C18.1596 1.92954 16.4023 1.29707 13.8992 1.29707C10.605 1.29707 7.74324 2.36174 5.44691 4.32586C2.35141 6.8874 0.654297 10.9141 0.654297 15.141C0.654297 20.8649 4.08192 24.426 9.73906 24.426Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.4824 24.4176C26.6451 24.4176 28.6075 23.8519 29.8056 23.1527L29.4069 21.0885C28.0844 21.8275 26.5964 22.2202 25.0815 22.2299C23.416 22.2299 22.1194 21.4923 21.487 20.0605C21.1214 19.1961 21.0214 17.6976 21.1532 17C27.1107 17.1002 31.8699 15.9687 31.8699 11.7418C31.8699 9.4506 30.2062 7.61497 27.1441 7.61497C21.9526 7.61497 18.3581 13.1035 18.3581 17.9612C18.3581 21.5224 20.222 24.4176 24.4824 24.4176ZM21.487 14.8657C22.1528 12.3375 24.1153 9.77432 26.7452 9.77432C28.2088 9.77432 29.2066 10.5069 29.2066 11.8386C29.2066 14.3334 25.8124 14.8991 21.487 14.8657Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M47.1758 24.4176C49.3401 24.4176 51.3026 23.8519 52.5007 23.1527L52.1019 21.0885C50.7788 21.8275 49.2903 22.2201 47.7749 22.2299C46.111 22.2299 44.8128 21.4923 44.182 20.0605C43.8149 19.1961 43.7148 17.6976 43.8483 17C49.8057 17.1002 54.5633 15.9687 54.5633 11.7418C54.5633 9.4506 52.8996 7.61497 49.8391 7.61497C44.6476 7.61497 41.0531 13.1035 41.0531 17.9612C41.0531 21.5224 42.9171 24.4176 47.1758 24.4176ZM44.182 14.8657C44.8461 12.3375 46.8103 9.77432 49.4386 9.77432C50.9038 9.77432 51.9016 10.5069 51.9016 11.8386C51.9016 14.3334 48.5074 14.8991 44.182 14.8657Z"
      fill="white"
    />
    <path
      d="M75.3944 13.7093L73.4319 24.0922L76.1904 24.0856L77.5254 16.9984C78.2229 13.237 80.354 10.3417 82.7486 10.3417C82.9944 10.3409 83.2396 10.3633 83.4811 10.4085L84.0152 7.68673C83.7622 7.64648 83.5069 7.62418 83.2509 7.61998C81.0865 7.61998 79.356 9.28372 78.3248 11.4798H78.2246C78.3901 10.2591 78.5229 9.13532 78.6556 8.0123L78.6585 7.9871H76.2287C76.0619 9.48398 75.7949 11.6133 75.3944 13.7093Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M95.4277 23.1527C94.2296 23.8519 92.2672 24.4176 90.1028 24.4176C85.8441 24.4176 83.9801 21.5224 83.9801 17.9612C83.9801 13.1035 87.5746 7.61497 92.7662 7.61497C95.8266 7.61497 97.4903 9.4506 97.4903 11.7418C97.4903 15.9687 92.7328 17.1002 86.7753 17C86.6418 17.6976 86.7419 19.1961 87.109 20.0605C87.7415 21.4923 89.038 22.2299 90.7019 22.2299C92.2173 22.2204 93.7058 21.8278 95.0289 21.0885L95.4277 23.1527ZM92.3656 9.77432C89.7373 9.77432 87.7749 12.3375 87.109 14.8657C91.4344 14.8991 94.8286 14.3334 94.8286 11.8386C94.8286 10.5069 93.8308 9.77432 92.3656 9.77432Z"
      fill="white"
    />
    <path
      d="M100.818 20.2908C99.588 20.2908 98.7219 21.2887 98.7219 22.5203C98.7219 23.5916 99.4544 24.451 100.557 24.451C101.789 24.451 102.653 23.4848 102.653 22.2549C102.653 21.1552 101.916 20.2908 100.818 20.2908Z"
      fill="white"
    />
    <path
      d="M103.114 1.65586L100.486 17.6292H102.715L106.243 1.65586L103.114 1.65586Z"
      fill="white"
    />
  </svg>
)

export default CelebreIcon
