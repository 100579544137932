import axios from 'axios'

import { Cart } from 'newCheckout/types/Shipments'
import { SHIPMENT_ENDPOINT } from 'newCheckout/constants'

import instance, { axiosResponseToStoreResponse } from './axios'

export type getShipmentType = {
  cep?: string
}

export const getShipment = async ({ cep }: getShipmentType) => {
  try {
    let endPoint = SHIPMENT_ENDPOINT
    if (cep) endPoint += `/${cep}`
    const axiosResponse = await instance.get<Cart>(endPoint)
    return axiosResponseToStoreResponse(axiosResponse)
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      return axiosResponseToStoreResponse(error.response)
    }
    throw error
  }
}
