import { PureComponent, Fragment } from 'react'

import * as wishlist from 'scripts/api/wishlists'

import * as message from 'site/utils/message'
import { sendUrlInPostMessage } from 'site/utils/postMessageRN'

import { hideSpinner, showSpinner } from 'utils/Spinner'
import slugify from 'utils/slugify'

import CONSTANTS from './../common/constants'
import LoaderGeneric from './../components/list-detail/loaders/Generic'
import ModifyWishlist from '../components/drawer/lists/ModifyWishlist'
import Title from '../components/Title'
import Menu from '../components/list-detail/Menu'
import Details from '../components/list-detail/Details'
import NotFoundList from '../components/list-detail/NotFoundList'
import ListHelmet from '../components/list-detail/ListHelmet'
import ListCover from '../components/list-detail/ListCover'

class Wishlist extends PureComponent {
  constructor (props) {
    super(props)

    this.state = {
      breadcrumbNodes: [],
      hasError: false,
      isLoading: true,
      isOpenedDrawer: false,
      isEditList: true,
      notFound: false,
      menuActived: 0,
      menuItems: [...CONSTANTS.MENU_ITEMS],
      slug: this.props.match.params.slug,
      wishlistDetails: {}
    }
  }

  async componentDidMount () {
    sendUrlInPostMessage()
    this.sendGAEvent()
    await this.getWishlistResources()
  }

  sendGAEvent = () => {
    if (window.ga) {
      window.ga('send', 'pageview', `/${this.props.match.params.slug}`)
    }
  }

  getWishlistResources = async () => {
    showSpinner()

    try {
      const response = await wishlist.getList(this.state.slug)

      if (response.status === 404) {
        return this.setState(
          {
            isLoading: false,
            notFound: true
          },
          hideSpinner
        )
      }

      if (response.status !== 200) {
        hideSpinner()
        throw new Error('Cannot get wishlist details.')
      }

      this.setState(
        {
          breadcrumbNodes: [
            ...CONSTANTS.DEFAULT_NODES,
            {
              id: '3',
              name: response.data.name,
              url: response.data.slug
            }
          ],
          wishlistDetails: response.data,
          isLoading: false
        },
        hideSpinner
      )
    } catch (err) {
      this.handleError()
    }
  }

  removeWishlistItem = removedItem => {
    const list = this.state.wishlistDetails

    if (list.products.includes(removedItem)) {
      const newList = list.products.filter(itemList => itemList !== removedItem)

      return this.setState(prevState => ({
        wishlistDetails: {
          ...prevState.wishlistDetails,
          products: newList
        }
      }))
    }

    const newList = list.contents.filter(itemList => itemList !== removedItem)

    this.setState(prevState => ({
      wishlistDetails: {
        ...prevState.wishlistDetails,
        contents: newList
      }
    }))
  }

  handleMenuClick = evt => {
    this.setState({
      menuActived: parseInt(evt.target.dataset.id)
    })
  }

  handleDrawer = () => {
    this.setState({
      isOpenedDrawer: !this.state.isOpenedDrawer
    })
  }

  handleEditList = async listData => {
    const formatData = {
      name: listData.get('wishlist-name'),
      background_image: listData.get('thumb-wishlist')
    }

    showSpinner()

    try {
      const response = await wishlist.editList(
        formatData,
        this.state.wishlistDetails.slug
      )

      if (response.status !== 201 && response.status !== 200) {
        hideSpinner()
        throw new Error(
          `Cannot edit wishlist details, status: ${listDetails.status}`
        )
      }

      this.setState(
        {
          slug: slugify(listData.get('wishlist-name'))
        },
        () => {
          this.props.history.replace(this.state.slug)
          hideSpinner()
        }
      )

      this.handleDrawer()
      this.getWishlistResources()
      message.success('Lista de favoritos atualizada!')
    } catch (err) {
      hideSpinner()
      this.emitErrorsMessage(
        'Não conseguimos editar sua lista de favoritos, tente novamente'
      )
    }
  }

  handleError = () => this.setState({ hasError: true })

  renderWishlist = () => {
    const isDesktop = window.innerWidth > 768

    const {
      breadcrumbNodes,
      isOpenedDrawer,
      isEditList,
      menuItems,
      menuActived,
      wishlistDetails
    } = this.state

    const ModifyWishlistProps = {
      isOpenedDrawer: isOpenedDrawer,
      isEditList: isEditList,
      closeDrawer: this.handleDrawer,
      modifyList: this.handleEditList
    }

    const menuProps = {
      items: menuItems,
      activedItem: menuActived,
      onSelect: this.handleMenuClick
    }

    const detailsProps = {
      activedItem: menuActived,
      removeWishlistItem: this.removeWishlistItem,
      items:
        menuActived === 0 ? wishlistDetails.products : wishlistDetails.contents
    }

    return (
      <Fragment>
        <ListHelmet name={wishlistDetails.name} />
        <ModifyWishlist {...ModifyWishlistProps} />
        <ListCover>
          <Title name={wishlistDetails.name} />
        </ListCover>
        <Menu {...menuProps} />
        <Details {...detailsProps} />
      </Fragment>
    )
  }

  render () {
    const { isLoading, notFound, slug } = this.state

    if (isLoading) {
      return <LoaderGeneric />
    }

    if (notFound) {
      return <NotFoundList slug={slug} />
    }

    return this.renderWishlist()
  }
}

export default Wishlist
