import { Fragment } from 'react'
import { Visible, Hidden } from '@leroy-merlin-br/backyard-react'
import PropTypes from 'prop-types'

import * as dataLayer from 'scripts/utils/data-layer'

import { MobileIcon, DesktopIcon } from './icons'

const LoginButton = ({ trigger }) => {
  const redirectToLogin = () => {
    dataLayer.wishlistLoginButtonClick()
    trigger()
  }

  return (
    <Fragment>
      <h2 className="align-center">
        Faça o login para visualizar e começar a adicionar seus produtos
        favoritos
      </h2>
      <ul className="wishlist-collection">
        <li className="wishlist-collection-item">
          <button
            className="button button-primary button-full no-shadow radius"
            onClick={redirectToLogin}
          >
            Fazer Login
          </button>
        </li>
      </ul>
    </Fragment>
  )
}

const RenderDesktop = ({ trigger }) => (
  <div className="container wishlist-home-screen">
    <div className="wishlist-loggin">
      <h1 className="title">Minhas Listas de Favoritos</h1>
      <LoginButton trigger={trigger} />
    </div>
    <div>
      <DesktopIcon />
    </div>
  </div>
)

const RenderMobile = ({ trigger }) => (
  <div className="container wishlist-home-screen">
    <MobileIcon />
    <LoginButton trigger={trigger} />
  </div>
)

const HomeScreen = ({ trigger }) => (
  <>
    <Visible when={['untilKilo', 'kilo']}>
      <RenderMobile trigger={trigger} />
    </Visible>

    <Hidden when={['untilKilo', 'kilo']}>
      <RenderDesktop trigger={trigger} />
    </Hidden>
  </>
)

HomeScreen.propTypes = {
  trigger: PropTypes.func.isRequired
}

export default HomeScreen
