import { Component } from 'react'
import PropTypes from 'prop-types'

import { tracker } from 'utils/app'

import trackEvent from '../Google'

class Base extends Component {
  onClick = (event) => {
    const {
      eventCategory,
      eventAction,
      eventLabel,
      eventValue
    } = this.props

    if (eventCategory && eventAction && eventLabel) {
      trackEvent({ eventCategory, eventAction, eventLabel, eventValue })
    }

    tracker.clickBanner({
      url: location.href,
      category: eventCategory,
      label: eventLabel,
      action: eventAction
    })

    if (this.props.onClick) {
      return this.props.onClick(event)
    }
  }

  getElementProps (props) {
    const {
      eventCategory, eventAction, eventLabel, eventValue, ...divProps
    } = props

    return divProps
  }
}

Base.propTypes = {
  eventCategory: PropTypes.string.isRequired,
  eventAction: PropTypes.string.isRequired,
  eventLabel: PropTypes.string.isRequired,
  eventValue: PropTypes.string
}

export default Base
