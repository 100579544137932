import { getAttribute } from 'scripts/react-components/entry-key/ui/Aside/Filters/Filters.utils'

import { CategoryFiltersLabelProps } from '../../../routes'
import { getFiltersUrl } from '../createUrlContext/rebuildUrl'
import {
  PRICE_URL_TEXT,
  PriceRangeProps
} from '../createUrlContext/createUrlFilters/priceRangeFilter'
import { getBoitataFacetsAndFilters } from '../routerProxyCategory'

interface LocationProps {
  href: string
}

interface refinementListProps {
  [key: string]: Array<string>
}

interface ObjectAlgolia {
  refinementList?: refinementListProps
  toggle?: Record<string, boolean | boolean[]>
  sortBy?: string
  page?: string
  range?: PriceRangeProps
}

interface FiltersProps {
  [key: string]: string[]
}

interface ParsedUrlContext {
  [indexName: string]: {
    refinementList?: refinementListProps
    toggle?: Record<string, boolean | boolean[]>
    sortBy?: string
    page?: string
    range?: PriceRangeProps
  }
}
const IS_PAGE_FILTER = ['page']
const IS_SORT_BY_FILTER = ['melhor-preco', 'menor-preco', 'order']
const IS_OFFER = [
  'ofertas',
  'novidades',
  'exclusivo-leroy-merlin',
  'marca-propria'
]

export const ParseUrlContext = (
  location: LocationProps,
  indexName: string,
  region: string,
  categoryFiltersLabel = {},
  algoliaFacetsObject = {}
) => {
  const { href } = location
  const urlDecoded = decodeURIComponent(href)
  const filtersUrl = getFiltersUrl(urlDecoded)
  const finalObj: ParsedUrlContext = {}
  finalObj[indexName] = {}
  if (!filtersUrl) {
    return finalObj
  }

  const objectAlgolia: ObjectAlgolia = {}

  const filters: FiltersProps = {}

  filtersUrl.forEach((keyValue, index) => {
    if (
      index === 0 &&
      (!IS_PAGE_FILTER.some(filter => keyValue.includes(filter)) ||
        IS_SORT_BY_FILTER.some(filter => keyValue.includes(filter)) ||
        IS_OFFER.some(filter => keyValue.includes(filter)))
    ) {
      let explodeSegment = []

      if (keyValue.includes('=')) {
        explodeSegment = keyValue.split('=')
      } else {
        explodeSegment = keyValue.split('/')
      }

      filters[explodeSegment[0]] = [explodeSegment.slice(1).join('/')]
    } else {
      const [key, value] = keyValue.split('=')
      const cleanedKey = key.replace('filter[', '').replace(']', '')
      const decodedValue = value.replace('_', ' ')
      if (filters[cleanedKey]) {
        filters[cleanedKey].push(decodedValue)
      } else {
        filters[cleanedKey] = [decodedValue]
      }
    }
  })

  const entries = Object.entries(filters)

  entries.map(([key, value]) => {
    getRefinementList(
      key,
      value,
      objectAlgolia,
      categoryFiltersLabel,
      algoliaFacetsObject
    )
    getPage(key, value[0], objectAlgolia)
    getToggle(key, objectAlgolia, region)
    getSortBy(key, objectAlgolia, indexName, value)
    getRange(key, objectAlgolia, region, value)
  })
  return getReturnAlgolia(finalObj, objectAlgolia, indexName)
}

/**
 * Updates the refinement list in an Algolia object based on the provided key and value.
 */

const getRefinementList = (
  key: string,
  value: Array<string>,
  objectAlgolia: ObjectAlgolia,
  categoryFiltersLabel: CategoryFiltersLabelProps,
  algoliaFacetsObject: object
): void => {
  const { facets: BOITATA_FACETS } = getBoitataFacetsAndFilters()
  function removePrefixAndSuffix (key: string, item: string) {
    const attribute = categoryFiltersLabel[key]

    if (attribute) {
      const { prefix, sufix } = algoliaFacetsObject[attribute]

      if (sufix) {
        item = item.replace(`_${sufix}`, '')
      }

      if (prefix) {
        item = item.replace(`${prefix}_`, '')
      }

      return item
    }

    return item
  }

  if (
    !IS_PAGE_FILTER.some(filter => key.includes(filter)) &&
    !IS_OFFER.some(filter => key.includes(filter)) &&
    !IS_SORT_BY_FILTER.some(filter => key.includes(filter))
  ) {
    value.forEach(item => {
      if (!objectAlgolia.refinementList) {
        objectAlgolia.refinementList = {}
      }
      let index = ''

      item = removePrefixAndSuffix(key, item.replace(' ', '_'))
      item = item.includes('_') ? item.replace(/_/g, ' ') : item

      if (BOITATA_FACETS[key]) {
        index = BOITATA_FACETS[key]
      } else {
        index = categoryFiltersLabel?.[key]
          ? 'attributes.' + categoryFiltersLabel?.[key]
          : 'attributes.' + key.charAt(0).toUpperCase() + key.slice(1)
      }
      if (objectAlgolia.refinementList[index]) {
        objectAlgolia.refinementList[index].push(item)
      } else {
        objectAlgolia.refinementList[index] = [item]
      }
    })
  }
}

const getPage = (
  key: string,
  value: string,
  objectAlgolia: ObjectAlgolia
): void => {
  if (key === 'page') {
    objectAlgolia.page = value
  }
}

/**
 * Toggles a specific attribute in an Algolia object based on the provided key and region.
 */

const getToggle = (
  key: string,
  objectAlgolia: ObjectAlgolia,
  region: string
): void => {
  // get all toggle filters
  const toggleFilterObj = getAttribute(region).toggle

  // find the toggle filter entry({key, value}) based on the provided key
  const toggleFilterEntry = Object.entries(toggleFilterObj).find(
    obj => obj[1].filterName === key
  )

  // toggle the filter
  if (toggleFilterEntry) {
    objectAlgolia.toggle = {
      ...objectAlgolia.toggle,
      [toggleFilterEntry[0]]: true
    }
  }
}

/**
 * Get sort filters on url to parse to algolia object based on the provided key and value
 */

const getSortBy = (
  key: string,
  objectAlgolia: ObjectAlgolia,
  indexName: string,
  value: string[]
): void => {
  if (key === 'menor-preco' || value[0] === 'menor-preco') {
    objectAlgolia.sortBy = `${indexName}_price_asc`
  }

  if (key === 'melhor-preco' || value[0] === 'melhor-preco') {
    objectAlgolia.sortBy = `${indexName}_price_desc`
  }
}

const getRange = (
  key: string,
  objectAlgolia: ObjectAlgolia,
  region: string,
  value: string[]
): void => {
  if (key === PRICE_URL_TEXT) {
    let priceValue

    if (value[0].includes('-')) {
      priceValue = value[0].replace('-', ':')
    } else {
      priceValue = ':' + value[0]
    }

    objectAlgolia.range = {
      [`regionalAttributes.${region}.promotionalPrice`]: priceValue
    }
  }
}

const getReturnAlgolia = (
  finalObj: ParsedUrlContext,
  objectAlgolia: ObjectAlgolia,
  indexName: string
): ParsedUrlContext => {
  if (objectAlgolia.refinementList) {
    finalObj[indexName].refinementList = objectAlgolia.refinementList
  }
  if (objectAlgolia.page) {
    finalObj[indexName].page = objectAlgolia.page
  }
  if (objectAlgolia.toggle) {
    finalObj[indexName].toggle = objectAlgolia.toggle
  }

  if (objectAlgolia.sortBy) {
    finalObj[indexName].sortBy = objectAlgolia.sortBy
  }

  if (objectAlgolia.range) {
    finalObj[indexName].range = objectAlgolia.range
  }

  return finalObj
}
