import { useEffect, useMemo, useState, useCallback, Fragment } from 'react'
import DOMPurify from 'dompurify'
import { debounce } from 'lodash'
import { SearchOutline } from '@backyard-ui/icons'
import { Icon, Card, Visible, TextField } from '@leroy-merlin-br/backyard-react'

import * as quicksearchRepository from 'scripts/api/header/quicksearch'

import {
  Product,
  Term,
  Store,
  Content,
  StoreDetail,
  FidelityProgram,
  SearchHistory
} from './components'
import * as S from './styled'
import { useSearchHistory } from './hooks'

const DEFAULT_DEBOUNCE_TIMER = 400
const ENTER_KEY = 'Enter'

const QuickSearch = ({ debounceTimer, minSearchTermLimit, isQuickSearchApiV3Enabled }) => {
  const { storeSearchTerm, searchHistory } = useSearchHistory()
  const createSearchTermPath = term =>
    `/search?term=${term}&searchTerm=${term}&searchType=default`
  const createSearchTerm = term => ({
    term: decodeURI(term),
    url: `/search?term=${term}`
  })

  const redirectToSearch = () => {
    storeSearchTerm(searchTerm)
    window.location.href = searchPath
  }
  const handleKeyPress = event => {
    if (event.key === ENTER_KEY) {
      redirectToSearch()
    }
  }

  const [hasResult, setHasResult] = useState(false)
  const [contentResult, setContentResult] = useState([])
  const [termsResult, setTermsResult] = useState([])
  const [searchPath, setSearchPath] = useState(createSearchTermPath(''))
  const [productsResult, setProductsResult] = useState([])
  const [storesResult, setStoresResult] = useState([])
  const [loyaltyResult, setLoyaltyResult] = useState(null)
  const [cancelRequest, setCancelRequest] = useState(() => () => {})
  const [isVisible, setIsVisible] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')

  const hasSearchHistory = searchHistory.length !== 0

  const DEBOUNCE_TIMER = useMemo(
    () => parseInt(debounceTimer, 10) || DEFAULT_DEBOUNCE_TIMER,
    [debounceTimer]
  )

  useEffect(() => {
    return cancelRequest
  }, [cancelRequest])

  const apiCallGetTerm = useCallback(async encodedTerm => {
    const onFetchResultsSuccess = data => {
      const { terms, contents, products, stores, loyalty } = data
      const hasResults =
      terms?.length || contents?.length || products?.length || stores?.length || loyalty

      setHasResult(hasResults)

      Array.isArray(terms)
        ? setTermsResult(terms)
        : setTermsResult([createSearchTerm(encodedTerm)])
      setContentResult(Array.isArray(contents) ? contents : [])
      setProductsResult(Array.isArray(products) ? products : [])
      setStoresResult(Array.isArray(stores) ? stores : [])
      setLoyaltyResult(loyalty)
    }

    const onFetchResultsFailure = () => {
      resetStatus()
    }

    const resetStatus = () => {
      setHasResult(false)
      setContentResult([])
      setTermsResult([])
      setProductsResult([])
      setStoresResult([])
      setLoyaltyResult(null)
    }

    if (!encodedTerm) {
      resetStatus()
      return
    }

    try {
      if (encodedTerm.length < minSearchTermLimit) {
        return resetStatus()
      }

      const { cancelRequest: newCancelRequest, request } =
        quicksearchRepository.getTerms(encodedTerm, isQuickSearchApiV3Enabled)
      setCancelRequest(() => newCancelRequest)

      const response = await request()
      const result = { ...response, data: isQuickSearchApiV3Enabled ? response.data.data : response.data }

      result.status === 200
        ? onFetchResultsSuccess(result.data)
        : onFetchResultsFailure(result.status)
    } catch (error) {
      console.error('Error on fetch results', error)
      if (error.message) {
        onFetchResultsFailure(error)
      }
    }
  }, [minSearchTermLimit, isQuickSearchApiV3Enabled])

  const fetchResult = useMemo(
    () => debounce(apiCallGetTerm, DEBOUNCE_TIMER),
    [DEBOUNCE_TIMER, apiCallGetTerm]
  )

  const inputTypeText = value => {
    const term = DOMPurify.sanitize(value)
    const encodedTerm = encodeURIComponent(term)
    setSearchPath(createSearchTermPath(encodedTerm))
    setSearchTerm(value)
    fetchResult(encodedTerm)
  }

  const renderResults = () => {
    const hasTerms = termsResult?.length > 0
    const hasContent = contentResult?.length > 0

    if (loyaltyResult) {
      const { howItWorksUrl, alreadyInUrl, imageUrl } = loyaltyResult
      return (
        <FidelityProgram
          howItWorksUrl={howItWorksUrl}
          alreadyInUrl={alreadyInUrl}
          imageUrl={imageUrl}
        />
      )
    }

    if (storesResult?.length > 0) {
      return storesResult.length === 1 ? (
        <StoreDetail {...storesResult[0]} />
      ) : (
        <Store stores={storesResult} />
      )
    }

    return (
      <Fragment>
        <div>
          <SearchHistory hasTerms={hasTerms} />
          {hasTerms && <Term terms={termsResult} />}
          {hasContent && <Content contents={contentResult} />}
        </div>
        {productsResult?.length > 0 && (
          <Visible when={['giga', 'tera']}>
            <Product products={productsResult} />
          </Visible>
        )}
      </Fragment>
    )
  }

  const onFocusHandler = () => {
    setIsVisible(true)
    setHasResult(hasSearchHistory)
  }

  return (
    <div>
      <S.SearchWrapper>
        <S.SearchInputWrapper>
          <TextField
            id="searchbar"
            type="search"
            placeholder="Digite aqui..."
            onChange={event => inputTypeText(event.target.value)}
            onKeyPress={handleKeyPress}
            onFocus={onFocusHandler}
            onBlur={() => setIsVisible(false)}
            autoComplete="off"
          />
        </S.SearchInputWrapper>
        <S.SearchButton onClick={redirectToSearch} data-testid="quick-search-button" data-cy="quick-search-button">
          <Icon as={SearchOutline} size="giga" />
        </S.SearchButton>
      </S.SearchWrapper>

      {hasResult && isVisible && (
        <S.ResultCard onMouseDown={event => event.preventDefault()}>
          <Card shadow="double">
            <S.ResultBox>{renderResults()}</S.ResultBox>
          </Card>
        </S.ResultCard>
      )}
    </div>
  )
}

export default QuickSearch
