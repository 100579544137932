import { clamp } from 'lodash'

import { GeneratePageRange } from './types'

export const generatePageRange: GeneratePageRange = (
  currentPage,
  pageSize,
  totalReviewsCount
) => {
  const from = currentPage <= 1 ? 1 : (currentPage - 1) * pageSize + 1
  const to = clamp(currentPage * pageSize, totalReviewsCount)

  return `${from}-${to}`
}
