import { FunctionComponent } from 'react'
import { Stack, Text, Hidden, Visible } from '@backyard-ui/core'
import { StarRating } from 'hpdp/components'

import { Props } from './types'
import { getRelativeTimeFormat } from './utils'

const Head: FunctionComponent<Props> = ({ author, rating, date }) => (
  <Stack
    direction={{ initial: 'column', md: 'row' }}
    align={{ initial: 'flex-start', md: 'baseline' }}
    spacing="3.5"
  >
    <Visible above="md">
      <StarRating rating={rating} />
    </Visible>

    <Text size="2xl" weight="bold">
      {author}
    </Text>

    <Stack direction="row" align="center">
      <Hidden above="md">
        <StarRating rating={rating} />
      </Hidden>

      <Text size="lg" color="gray-300">
        {getRelativeTimeFormat(date)}
      </Text>
    </Stack>
  </Stack>
)

export default Head
