import produce from 'immer'

import { Constants } from './'

const initialState = {
  data: null,
  isRequest: false,
  error: null
}

const reducer = (state = initialState, action = {}) =>
  produce(state, draft => {
    switch (action.type) {
      case Constants.COUPONS_APPLY:
      case Constants.COUPONS_REMOVE:
        draft.isRequest = true
        draft.data = null
        draft.error = null
        break

      case Constants.COUPONS_APPLY_SUCCESS:
        draft.isRequest = false
        draft.data = action.data
        break

      case Constants.COUPONS_APPLY_FAILURE:
        draft.isRequest = false
        draft.error = action.status
        break

      case Constants.COUPONS_REMOVE_SUCCESS:
        draft.isRequest = false
        break

      case Constants.COUPONS_REMOVE_FAILURE:
        draft.isRequest = false
        draft.error = action.status
        break

      case Constants.COUPONS_SET:
        draft.data = action.data
        break

      default:
        return draft
    }
  })

export default reducer
