import styled from '@emotion/styled'
import { css } from '@emotion/react'

const linkStyles = ({ theme }) => css`
  label: notification__link;

  color: ${theme.colors.p600};
`

export const Link = styled('a')(linkStyles)
