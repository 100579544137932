import { tv } from 'tailwind-variants'

const styles = tv({
  base: `
    w-7
    h-7
    bg-gray-100
    rounded-full
    flex
    justify-center
    items-center
    px-2
    [&>span]:mb-1
  `
})

export default styles
