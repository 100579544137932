import { StateMachineProvider, createStore } from 'little-state-machine'

import { Content } from '../../components'
import { STEPS } from './constants'
import { CardRequestContextProvider } from '../../context/card-request-context'

const CardRequest = () => {
  sessionStorage.removeItem('@celebre/session')

  createStore(
    {
      basic: '',
      eligibility: '',
      complement: '',
      address: '',
      income: '',
      invoice: '',
      apiErrors: {},
      hasSubmittedForm: false
    },
    {
      name: '@celebre/session'
    }
  )

  return (
    <StateMachineProvider>
      <CardRequestContextProvider>
        <Content>
          {STEPS.map(({ Component, title }) => (
            <Component key={title} />
          ))}
        </Content>
      </CardRequestContextProvider>
    </StateMachineProvider>
  )
}

export default CardRequest
