import {
  Stack,
  Text,
  Inline,
  Container,
  Row,
  Col
} from '@leroy-merlin-br/backyard-react'

import * as S from './styled'

const Footer = () => (
  <S.Footer>
    <Container>
      <Row>
        <Col>
          <Stack space="byte">
            <Inline alignX="center" space="giga">
              <Text
                as="a"
                href="/institucional/politica-de-privacidade"
                size="kilo"
                noMargin
                target="_blank"
              >
                Política de Privacidade
              </Text>
              <span></span>

              <Text
                as="a"
                href="/institucional/termos-e-condicoes-de-uso-do-site"
                size="kilo"
                noMargin
                target="_blank"
              >
                Condições de Uso
              </Text>
              <span></span>

              <Text
                as="a"
                size="kilo"
                href="/fale-conosco"
                noMargin
                target="_blank"
              >
                Ajuda
              </Text>
              <span></span>

              <Text
                as="a"
                size="kilo"
                href="/institucional/politica-de-cookies"
                noMargin
                target="_blank"
              >
                Política de Cookies
              </Text>
              <span></span>

              <Text
                as="a"
                size="kilo"
                href="/institucional/termos-e-condicoes-de-uso-do-site"
                noMargin
                target="_blank"
              >
                Regulamento
              </Text>
            </Inline>

            <Text size="kilo" align="center" color="n300">
              Copyright © 2022 Leroy Merlin, todos os direitos reservados. Leroy
              Merlin Cia Brasileira de Bricolagem. Inscrição estadual nº
              298.176.665.115 CNPJ/MF sob o nº 01.438.784/0048-60. Rua Pascoal
              Pais, nº 525, 5º andar - Vila Cordeiro, CEP 04581-060, São
              Paulo/SP.
            </Text>
          </Stack>
        </Col>
      </Row>
    </Container>
  </S.Footer>
)

export default Footer
