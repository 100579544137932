import {
  TicketPercentOutline,
  MoneyOutline,
  GiftOutline
} from '@backyard-ui/icons'

import { Star } from 'lmcv/components'

export const ADVANTAGES = [
  {
    icon: TicketPercentOutline,
    title: 'Cupons especiais',
    description: 'Descontos exclusivos só para membros. É só ativar e usar'
  },
  {
    icon: MoneyOutline,
    title: 'Pontos que valem dinheiro',
    description:
      'Junte pontos em cada compra e troque por cashback de até R$150,00'
  },
  {
    icon: GiftOutline,
    title: 'Surpresa de aniversário',
    description: 'Cupom especial para você no mês do seu aniversário'
  },
  {
    icon: Star,
    title: 'Vantagens para profissionais da construção',
    description: 'Benefícios personalizados de acordo com o seu perfil'
  }
]
