import { Fragment } from 'react'
import { Icon } from '@leroy-merlin-br/backyard-react'
import UUID from 'pure-uuid'

import * as S from './styled'

const LinearGradient = ({ fill, id }) => (
  <S.GradientWrapper fill={fill}>
    <linearGradient x1="29.221%" x2="72.606%" y1="2.666%" y2="97.334%" id={id}>
      <stop offset="0%" stopColor="#9A21AA" />
      <stop offset="100%" stopColor="#7808CF" />
    </linearGradient>
  </S.GradientWrapper>
)

const CustomIcon = ({ fill, ...props }) => {
  if (fill === 'linearGradient') {
    const uuid = new UUID(1)
    const fillId = `gradient_${uuid}`
    const fillValue = `url(#${fillId})`

    return (
      <Fragment>
        <LinearGradient id={fillId} />
        <Icon {...props} fill={fillValue} />
      </Fragment>
    )
  }

  return <Icon fill={fill} {...props} />
}

export default CustomIcon
