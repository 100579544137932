import { css } from '@emotion/react'
import styled from '@emotion/styled'

const optionInputStyles = () => css`
  label: buybox__radio-input;

  display: none;
`

export const OptionInput = styled('input')(optionInputStyles)
