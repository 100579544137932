import { Children, useState } from 'react'
import PropTypes from 'prop-types'
import { Swiper, SwiperSlide } from 'swiper/react'
import UUID from 'pure-uuid'

import { useId } from 'utils/hooks/use-id'

import { Navigation as NavigationComponent } from './components/Navigation'
import * as S from './styled'

const GenericCarousel = ({
  children,
  customNavigation,
  hasOffset = true,
  freeMode = true,
  rollInGroup = true,
  customStyles,
  onInit = () => {},
  ...swiperProps
}) => {
  const persistentId = useId(new UUID(1).format().slice(-4))
  const [slidesToSwipe, setSlidesToSwipe] = useState(1)

  const prevProps = {
    [`data-swiper-prev-${persistentId}`]: true
  }

  const nextProps = {
    [`data-swiper-next-${persistentId}`]: true
  }

  const renderNavigation = () =>
    customNavigation ? (
      customNavigation({ prevProps, nextProps })
    ) : (
      <NavigationComponent
        hasOffset={hasOffset}
        prevProps={prevProps}
        nextProps={nextProps}
      />
    )

  const calculateSlidesPerGroup = (swiper = {}) => {
    if (!rollInGroup) return

    const {
      width,
      slidesGrid: [, cardWidth]
    } = swiper

    setSlidesToSwipe(prevVal => {
      const newValue = Math.floor(width / cardWidth)

      return newValue || prevVal
    })
  }

  const handleInitialRender = swiper => {
    calculateSlidesPerGroup(swiper)

    onInit(swiper)
  }

  return (
    <S.GenericCarousel hasOffset={hasOffset} css={customStyles}>
      <Swiper
        navigation={{
          prevEl: `[data-swiper-prev-${persistentId}]`,
          nextEl: `[data-swiper-next-${persistentId}]`
        }}
        freeMode={freeMode}
        slidesPerGroup={slidesToSwipe}
        speed={600}
        onInit={handleInitialRender}
        onResize={calculateSlidesPerGroup}
        watchOverflow
        {...swiperProps}
      >
        {Children.map(children, child => {
          return <SwiperSlide>{child}</SwiperSlide>
        })}
      </Swiper>

      {renderNavigation()}
    </S.GenericCarousel>
  )
}

GenericCarousel.propTypes = {
  children: PropTypes.node.isRequired,
  customNavigation: PropTypes.func,
  hasOffset: PropTypes.bool,
  freeMode: PropTypes.bool
}

export default GenericCarousel
