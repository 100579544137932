
import { Stack, Inline, Icon } from '@leroy-merlin-br/backyard-react'
import { CheckmarkCircleOutline } from '@backyard-ui/icons'

import * as S from './styled'
import { CouponData } from '../../types'

type StepsForActivationProps = {
  couponData: CouponData
}

const StepsForActivation = ({ couponData }: StepsForActivationProps) => {
  return (
    <S.Wrapper isActive={couponData.isActive}>
      <Stack space="mega">
        <Inline space="byte">
          {couponData.isActive ? (
            <Icon as={CheckmarkCircleOutline} size="giga" />
          ) : (
            <S.StepNumber>
              1
            </S.StepNumber>
          )}
          <S.StepDescription isActive={couponData.isActive}>Ative seu desconto</S.StepDescription>
        </Inline>
        <Inline space="byte">
          <S.StepNumber>
            2
          </S.StepNumber>
          <S.StepDescription isActive={couponData.isActive}>Adicione produtos ao carrinho</S.StepDescription>
        </Inline>
        <Inline space="byte">
          <S.StepNumber>
            3
          </S.StepNumber>
          <S.StepDescription isActive={couponData.isActive}>Confira o desconto no carrinho</S.StepDescription>
        </Inline>
      </Stack>
    </S.Wrapper>
  )
}

export default StepsForActivation
