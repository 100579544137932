import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import * as S from './styled'

const Input = ({
  packaging = 1,
  quantity = 0,
  updateQuantity
}) => {
  const [inputValue, setInputValue] = useState(() => quantity)
  const [shouldUpdateInput, setShouldUpdateInput] = useState(false)

  const handleInputChange = inputValue => {
    setShouldUpdateInput(true)
    setInputValue(inputValue)
  }

  useEffect(() => {
    setInputValue(quantity)
    setShouldUpdateInput(false)
  }, [quantity])

  useEffect(() => {
    let updatePackaging
    if (inputValue !== quantity && inputValue > 0 && shouldUpdateInput) {
      updatePackaging = setTimeout(async () => {
        const packagingCount = Math.ceil(inputValue / packaging)
        updateQuantity(packagingCount)
        setShouldUpdateInput(false)
      }, 500)
    }

    return () => {
      clearTimeout(updatePackaging)
    }
  }, [inputValue, quantity, packaging, updateQuantity, shouldUpdateInput])

  return (
    <S.Input
      data-cy="quantity-input"
      type="number"
      pattern="[0-9\.]"
      step="any"
      min="1"
      onChange={({ target: { value } }) => handleInputChange(value)}
      value={inputValue}
    />
  )
}

Input.propTypes = {
  packaging: PropTypes.number,
  quantity: PropTypes.number,
  updateQuantity: PropTypes.func.isRequired
}

export default Input
