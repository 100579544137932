const CreditCard = () => (
  <svg
    width="46"
    height="31"
    viewBox="0 0 46 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.955078"
      y="1.60352"
      width="43.5"
      height="28.5"
      rx="5.25"
      fill="white"
    />
    <rect
      opacity="0.7"
      x="4.70508"
      y="6.85352"
      width="36"
      height="6"
      rx="0.5"
      fill="#222020"
    />
    <rect
      opacity="0.7"
      x="4.70508"
      y="22.1078"
      width="3"
      height="1"
      rx="0.15"
      fill="#222020"
    />
    <rect
      opacity="0.7"
      x="8.71069"
      y="22.1078"
      width="3"
      height="1"
      rx="0.15"
      fill="#222020"
    />
    <rect
      opacity="0.7"
      x="12.7163"
      y="22.1078"
      width="3"
      height="1"
      rx="0.15"
      fill="#222020"
    />
    <ellipse
      opacity="0.7"
      cx="34.5695"
      cy="22.8579"
      rx="2.745"
      ry="2.745"
      fill="#222020"
    />
    <ellipse
      opacity="0.5"
      cx="37.9601"
      cy="22.8579"
      rx="2.745"
      ry="2.745"
      fill="#222020"
    />
  </svg>
)

export default CreditCard
