import { useHistory } from 'react-router-dom'
import { Icon } from '@leroy-merlin-br/backyard-react'
import { useState, type MouseEvent } from 'react'
import { ChevronUp, ChevronDown } from '@backyard-ui/icons'

import * as S from './styled'
import { type SidebarMenu } from '../../constants'

type NavItemProps = {
  menu: SidebarMenu
  activePathname: string | null
}

const NavItem = ({ menu, activePathname }: NavItemProps) => {
  const [isSubmenuOpen, setIsSubmenuOpen] = useState(true)

  const history = useHistory()

  const hasSubMenu = Boolean(menu?.submenu?.length)
  const baseHost = window?.env?.baseHost || ''
  const isUnderLoyalty = location.href.includes('/fidelidade')
  const DropdownIcon = isSubmenuOpen ? ChevronUp : ChevronDown

  const onDropdownClick = (event: MouseEvent) => {
    event.preventDefault()

    if (hasSubMenu) {
      setIsSubmenuOpen(currentValue => !currentValue)
    }
  }

  const onMenuClick = (event: MouseEvent, link: string) => {
    event.preventDefault()

    if (isUnderLoyalty && menu?.id === 'loyalty') {
      const newLink = link.replace('/fidelidade', '')
      history.push(newLink)
    } else {
      window.location.href = `${baseHost}${link}`
    }
  }

  return (
    <S.NavItem>
      <S.LinkWrapper
        isActive={menu.link === activePathname}
        hasSubMenu={hasSubMenu}
      >
        <a
          href={`${baseHost}${menu.link}`}
          onClick={event => onMenuClick(event, menu.link)}
          data-tracking={`sidebar-item-${menu.id}`}
        >
          <Icon as={menu.icon} />
          <span>{menu.name}</span>
        </a>
        {hasSubMenu && (
          <S.DropdownButton onClick={(event: MouseEvent) => onDropdownClick(event)}>
            <Icon as={DropdownIcon} />
          </S.DropdownButton>
        )}
      </S.LinkWrapper>

      {hasSubMenu && isSubmenuOpen && (
        <S.Submenu>
          {menu.submenu.map(submenu => (
            <S.Link
              key={submenu.id}
              isActive={submenu.link === activePathname}
              href={`${baseHost}${submenu.link}`}
              onClick={(event: MouseEvent) => onMenuClick(event, submenu.link)}
              data-tracking={`sidebar-subitem-${submenu.id}`}
            >
              <span>{submenu.name}</span>
            </S.Link>
          ))}
        </S.Submenu>
      )}
    </S.NavItem>
  )
}

export default NavItem
