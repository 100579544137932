import $ from 'jquery'

import Collapse from 'garden/src/js/components/collapse'

import BaseComponent from 'scripts/components/base-component'

class CommonQuestions extends BaseComponent {
  static componentName = 'common-questions'

  init () {
    $('.toggle').collapse()
  }
}

export default ($el) => new CommonQuestions($el).init()

export { CommonQuestions as Component }
