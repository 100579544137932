const Icon = () => (
  <svg width="322" height="285" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <path
        d="M168.264 117.84a91.773 91.773 0 0 1-4.475 9.928l-3.174-1.845a88.102 88.102 0 0 0 4.285-9.502l3.364 1.419ZM174 87c0 4.148-.3 8.32-.888 12.4l-3.662-.53a84.026 84.026 0 0 0 .67-17.397C167.113 35.738 127.514.983 81.85 3.995a82.778 82.778 0 0 0-38.763 12.639l-1.977-3.132A86.5 86.5 0 0 1 81.607.299c47.7-3.147 89.062 33.16 92.204 80.93.126 1.894.189 3.835.189 5.77M23.901 32.744c-12.97 14.88-20.114 33.75-20.114 53.138 0 1.848.062 3.695.185 5.491a80.758 80.758 0 0 0 5.26 23.752l-3.545 1.296A84.375 84.375 0 0 1 .193 91.614 85.253 85.253 0 0 1 0 85.882C0 65.626 7.462 45.91 21.012 30.37l2.89 2.375Z"
        fill="#DDD"
      />
      <path
        d="M157.59 81c2.584 39.344-27.142 73.337-66.392 75.926-39.25 2.589-73.162-27.207-75.745-66.55-2.583-39.342 27.14-73.337 66.393-75.926 39.25-2.59 73.162 27.205 75.745 66.55"
        fill="#E5E5E5"
      />
      <path
        d="M161.093 85.783c0 27.33-15.453 52.537-40.35 65.153l-1.716-3.292c25.028-12.685 40.006-38.768 38.16-66.456-.655-9.824-3.326-19.183-7.938-27.818l3.32-1.725c4.858 9.098 7.672 18.955 8.363 29.3a73.37 73.37 0 0 1 .161 4.838M87.956 12.407l-.042 3.701a69.693 69.693 0 0 0-5.419.148C50.84 18.348 24.7 41.65 18.923 72.922l-3.626-.674c6.083-32.94 33.617-57.483 66.957-59.686a74.23 74.23 0 0 1 5.702-.155M68.357 152.122l-.983 3.542c-29.875-8.237-51.316-34.492-53.351-65.333a74.075 74.075 0 0 1-.16-4.634l3.687-.01c.004 1.449.055 2.928.153 4.403 1.932 29.285 22.289 54.211 50.654 62.032"
        fill="#DDD"
      />
      <path
        d="M143.285 82.442c.975 15.115-3.943 29.724-13.876 41.121-9.92 11.383-23.672 18.203-38.696 19.211-31.04 2.054-57.953-21.672-59.995-52.895-.99-15.128 3.943-29.724 13.86-41.105C54.515 37.373 68.25 30.542 83.29 29.547c31.04-2.054 57.953 21.674 59.995 52.895"
        fill="#FFF"
      />
      <path
        d="M86.919 119.509c-.55 0-1.109-.115-1.597-.39-11.18-6.268-19.151-13.018-25.083-21.242-4.778-6.624-6.961-13.25-6.676-20.256.213-5.21 2.313-10.107 5.913-13.787a20.253 20.253 0 0 1 13.635-6.093c5.16-.228 9.82 1.518 13.864 5.188l.14-.111c1.1-.867 2.237-1.764 3.516-2.49 5.487-3.113 11.876-3.454 17.528-.935 5.89 2.623 10.271 8.08 11.723 14.598 1.357 6.097.337 12.291-3.12 18.938-3.52 6.768-8.903 12.82-16.456 18.499-2.807 2.11-5.799 4.003-8.692 5.833-.887.561-1.774 1.122-2.655 1.689-.519.333-1.271.559-2.04.559"
        fill="#DDD"
      />
      <g>
        <g fill="#DDD">
          <path d="M291.23 18.28a66.776 66.776 0 0 1 7.225 3.257l-1.343 2.31a64.105 64.105 0 0 0-6.916-3.119l1.033-2.449ZM268.785 14.103c3.019 0 6.054.218 9.024.646l-.386 2.666a61.126 61.126 0 0 0-12.66-.487c-33.285 2.19-58.579 31.02-56.387 64.269a60.288 60.288 0 0 0 9.198 28.222l-2.28 1.44a62.999 62.999 0 0 1-9.608-29.486c-2.29-34.73 24.132-64.845 58.898-67.133a63.662 63.662 0 0 1 4.2-.137M229.298 123.388c10.83 9.444 24.563 14.646 38.673 14.646 1.345 0 2.69-.046 3.997-.136a58.752 58.752 0 0 0 17.285-3.829l.943 2.58a61.384 61.384 0 0 1-18.053 4c-1.363.093-2.768.142-4.172.142-14.742 0-29.09-5.433-40.401-15.3l1.728-2.103Z" />
        </g>
        <path
          d="M318.995 74.328c1.88 28.633-19.762 53.372-48.34 55.257-28.577 1.884-53.268-19.801-55.149-48.434-1.88-28.632 19.761-53.373 48.34-55.257 28.577-1.884 53.269 19.8 55.15 48.434"
          fill="#E5E5E5"
        />
        <path
          d="M321.545 77.808c0 19.891-11.25 38.236-29.379 47.417l-1.248-2.396c18.222-9.231 29.127-28.214 27.783-48.365-.477-7.15-2.421-13.96-5.78-20.245l2.417-1.255c3.538 6.621 5.587 13.795 6.09 21.324.078 1.177.117 2.353.117 3.52M268.295 24.407l-.031 2.694a50.765 50.765 0 0 0-3.945.108c-23.048 1.522-42.081 18.48-46.287 41.24l-2.64-.49c4.43-23.974 24.476-41.836 48.751-43.439a54.07 54.07 0 0 1 4.152-.113M254.025 126.088l-.715 2.578c-21.753-5.995-37.363-25.102-38.845-47.548a53.887 53.887 0 0 1-.117-3.372l2.685-.007a50.6 50.6 0 0 0 .111 3.204c1.407 21.313 16.228 39.454 36.881 45.145"
          fill="#DDD"
        />
        <path
          d="M308.58 75.377c.71 11-2.871 21.632-10.103 29.927-7.223 8.284-17.236 13.247-28.175 13.981-22.6 1.495-42.195-15.772-43.682-38.495-.72-11.01 2.87-21.633 10.092-29.915 7.234-8.298 17.235-13.27 28.185-13.994 22.6-1.495 42.196 15.774 43.682 38.496"
          fill="#FFF"
        />
        <path
          d="M267.62 102.505c-.399 0-.803-.085-1.157-.285-8.098-4.576-13.87-9.503-18.167-15.506-3.46-4.834-5.042-9.671-4.835-14.786.154-3.803 1.675-7.377 4.283-10.063a14.618 14.618 0 0 1 9.875-4.447c3.737-.167 7.112 1.108 10.042 3.786l.102-.08c.796-.634 1.619-1.289 2.545-1.818 3.974-2.272 8.602-2.521 12.696-.683 4.265 1.915 7.44 5.899 8.49 10.656.984 4.45.244 8.972-2.26 13.824-2.55 4.94-6.448 9.357-11.918 13.503-2.033 1.54-4.2 2.922-6.296 4.258-.642.41-1.284.819-1.923 1.232-.375.244-.92.409-1.477.409"
          fill="#DDD"
        />
      </g>
      <g>
        <g fill="#DDD">
          <path d="M170.77 162.28a66.776 66.776 0 0 0-7.225 3.257l1.343 2.31a64.105 64.105 0 0 1 6.916-3.119l-1.033-2.449ZM193.215 158.103c-3.019 0-6.054.218-9.024.646l.386 2.666a61.126 61.126 0 0 1 12.66-.487c33.285 2.19 58.579 31.02 56.387 64.269a60.288 60.288 0 0 1-9.198 28.222l2.28 1.44a62.999 62.999 0 0 0 9.608-29.486c2.29-34.73-24.132-64.845-58.898-67.133a63.662 63.662 0 0 0-4.2-.137M232.702 267.388c-10.83 9.444-24.563 14.646-38.673 14.646-1.345 0-2.69-.046-3.997-.136a58.752 58.752 0 0 1-17.285-3.829l-.943 2.58a61.384 61.384 0 0 0 18.053 4c1.363.093 2.768.142 4.172.142 14.742 0 29.09-5.433 40.401-15.3l-1.728-2.103Z" />
        </g>
        <path
          d="M143.005 218.328c-1.88 28.633 19.762 53.372 48.34 55.257 28.577 1.884 53.268-19.801 55.149-48.434 1.88-28.632-19.761-53.373-48.34-55.257-28.577-1.884-53.269 19.8-55.15 48.434"
          fill="#E5E5E5"
        />
        <path
          d="M140.455 221.808c0 19.891 11.25 38.236 29.379 47.417l1.248-2.396c-18.222-9.231-29.127-28.214-27.783-48.365.477-7.15 2.421-13.96 5.78-20.245l-2.417-1.255c-3.538 6.621-5.587 13.795-6.09 21.324a53.374 53.374 0 0 0-.117 3.52M193.705 168.407l.031 2.694a50.765 50.765 0 0 1 3.945.108c23.048 1.522 42.081 18.48 46.287 41.24l2.64-.49c-4.43-23.974-24.476-41.836-48.751-43.439a54.07 54.07 0 0 0-4.152-.113M207.975 270.088l.715 2.578c21.753-5.995 37.363-25.102 38.845-47.548.074-1.128.114-2.262.117-3.372l-2.685-.007a50.6 50.6 0 0 1-.111 3.204c-1.407 21.313-16.228 39.454-36.881 45.145"
          fill="#DDD"
        />
        <path
          d="M153.42 219.377c-.71 11 2.871 21.632 10.103 29.927 7.223 8.284 17.236 13.247 28.175 13.981 22.6 1.495 42.195-15.772 43.682-38.495.72-11.01-2.87-21.633-10.092-29.915-7.234-8.298-17.235-13.27-28.185-13.994-22.6-1.495-42.196 15.774-43.682 38.496"
          fill="#FFF"
        />
        <path
          d="M194.38 246.505c.399 0 .803-.085 1.157-.285 8.098-4.576 13.87-9.503 18.167-15.506 3.46-4.834 5.042-9.671 4.835-14.786-.154-3.803-1.675-7.377-4.283-10.063a14.618 14.618 0 0 0-9.875-4.447c-3.737-.167-7.112 1.108-10.042 3.786l-.102-.08c-.796-.634-1.619-1.289-2.545-1.818-3.974-2.272-8.602-2.521-12.696-.683-4.265 1.915-7.44 5.899-8.49 10.656-.984 4.45-.244 8.972 2.26 13.824 2.55 4.94 6.448 9.357 11.918 13.503 2.033 1.54 4.2 2.922 6.296 4.258.642.41 1.284.819 1.923 1.232.375.244.92.409 1.477.409"
          fill="#DDD"
        />
      </g>
    </g>
  </svg>
)

export default Icon
