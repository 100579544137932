import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { Theme } from 'scripts/react-components/themes/Theme'

const bodyStyles = ({ theme }: Theme) => css`
  label: chatmodal_container-body;

  display: grid;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 0 ${theme.spacings.kilo};
  scroll-snap-type: y proximity;
`

export const Body = styled('div')(bodyStyles)
