import { css } from '@emotion/react'
import styled from '@emotion/styled'

const ilustrationWrapperStyles = ({ theme }) => css`
  label: ilustration-wrapper;

  display: flex;
  justify-content: center;
  margin-bottom: ${theme.spacings.giga};

  svg {
    width: 200px;
    height: 200px;
  }

  ${theme.media.mega} {
    svg {
      width: 230px;
      height: 230px;
    }
  }
`

const buttonWrapperStyles = ({ theme }) => css`
  label: button-wrapper;

  margin-top: ${theme.spacings.mega};
`

const inputsWrapperStyles = () => css`
  label: inputs-wrapper;

  div[class*='inline'] {
    & > div:first-of-type {
      flex: 1;
    }
  }
`

export const IlustrationWrapper = styled('div')(ilustrationWrapperStyles)
export const ButtonWrapper = styled('div')(buttonWrapperStyles)
export const InputsWrapper = styled('div')(inputsWrapperStyles)
