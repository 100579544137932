import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { Theme } from 'newCheckout/types/Theme'

const changePaddingCardStyle = ({ theme }: Theme) => css`
  label: success-summary__card;

  & > div {
    padding: ${theme.spacings.mega} 0px 0px 0px;
    & > div {
      overflow-x: hidden;
    }
  }
`
export const CardPadding = styled('span')(changePaddingCardStyle)

const wrapperStyles = ({ theme }: Theme) => css`
  label: success-summary__wrapper;

  display: flex;
  flex-direction: column;
  gap: ${theme.spacings.mega};
  margin: 0 -${theme.spacings.tera};

  & > * {
    padding: 0 ${theme.spacings.tera};

    &:not(:last-child) {
      border-bottom: 1px solid ${theme.colors.n100};
    }
  }
`
export const Wrapper = styled('div')(wrapperStyles)

const forecastPaddingStyles = ({ theme }: Theme) => css`
  label: success-summary__forcast-padding;

  padding-bottom: ${theme.spacings.mega};
`
export const ForecastPadding = styled('div')(forecastPaddingStyles)

const iconWrapperStyles = ({ theme }: Theme) => css`
  label: success-summary__icon-wrapper;

  margin-top: ${theme.spacings.bit};
`

export const IconWrapper = styled('div')(iconWrapperStyles)
