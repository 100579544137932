import { Text, Hidden } from '@leroy-merlin-br/backyard-react'

import { ImageThumbnail } from 'shared/components'

import formatPriceToBRL from 'utils/formatPriceToBRL'

import * as S from './styled'
import { ProductVisual } from './type'

const Product = ({
  name,
  picture,
  href,
  quantity,
  marketPlace,
  priceUnit,
  packagingUnit,
  totalPrice
}: ProductVisual) => (
  <>
    <S.ItemInfo>
      <S.ResponsiveInline space="byte">
        <ImageThumbnail
          alt={name}
          src={picture}
          href={href}
          responsiveSizes={{
            untilKilo: 'mega',
            kilo: 'mega',
            mega: 'mega',
            giga: 'mega',
            tera: 'mega'
          }}
          noBorder
        />
        <div>
          <Text as="div" color="black" noMargin>
            {name}
          </Text>
          {marketPlace && (
            <Text as="div" size="kilo" color="n600" noMargin>
              Vendido e entregue por{' '}
              <Text size="kilo" as="span" color="n900">
                {marketPlace}
              </Text>
            </Text>
          )}
          <Hidden when={['mega', 'giga', 'tera']}>
            <S.ItemPrice>
              <div>
                <Text size="kilo" noMargin>
                  {formatPriceToBRL(priceUnit)} / {packagingUnit?.toLowerCase()}
                </Text>
                <Text as="div" isBold noMargin>
                  {formatPriceToBRL(totalPrice)}
                </Text>
              </div>
            </S.ItemPrice>
          </Hidden>
        </div>
      </S.ResponsiveInline>
    </S.ItemInfo>
    <S.ItemQuantity>
      <Text isBold noMargin>
        {quantity}
      </Text>
    </S.ItemQuantity>
    <Hidden when={['untilKilo', 'kilo']}>
      <S.ItemPrice>
        <div>
          <Text size="kilo" noMargin>
            {formatPriceToBRL(priceUnit)} / {packagingUnit?.toLowerCase()}
          </Text>
          <Text as="div" isBold noMargin>
            {formatPriceToBRL(totalPrice)}
          </Text>
        </div>
      </S.ItemPrice>
    </Hidden>
  </>
)

export default Product
