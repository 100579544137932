import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { StyleIsActiveProps, StyleProps } from '../../types'

const wrapperStyles = ({ theme }: StyleProps) => css`
  label: wrapper;

  margin-inline: ${theme.spacings.giga};
  padding-block: ${theme.spacings.giga};

  border-top: 1px solid ${theme.colors.n200};
`

const stepNumberStyles = ({ theme }: StyleProps) => css`
  label: step-number;
  width: 24px;
  height: 24px;
  border-radius: ${theme.borderRadius.circle};

  background: ${theme.colors.v100};
  color: ${theme.colors.v800};
  font-weight: 600;
  font-size: 14px;

  display: flex;
  justify-content: center;
  align-items: center;

`

const stepDescriptionStyles = ({ theme }: StyleProps) => css`
  label: step-description;

  font-weight: 400;
  font-size: 14px;

  color: ${theme.colors.n900};

`

export const StepNumber = styled('div')(stepNumberStyles)
export const StepDescription = styled('div')(stepDescriptionStyles)

const wrapperActiveStyles = ({ theme, isActive }: StyleIsActiveProps) =>
  isActive &&
  css`
    label: wrapper--active;

    border-top: 1px solid rgba(255, 255, 255, 0.1);

    & svg {
      color: ${theme.colors.white};
      width: 24px;
      height: 24px;
    }

    ${StepDescription} {
      color: ${theme.colors.white};
    }
  `
export const Wrapper = styled('div')(wrapperStyles, wrapperActiveStyles)
