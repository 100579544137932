import { useUserResources } from 'shared/hooks'

import LoggedIn from './components/LoggedIn'
import LoggedOut from './components/LoggedOut'

const User = () => {
  const { userControls } = useUserResources()

  return userControls?.isLoggedIn ? <LoggedIn /> : <LoggedOut />
}

export default User
