import { FunctionComponent } from 'react'
import { SimpleGrid } from '@backyard-ui/core'

import { Props } from './types'
import { RatingResume } from '../../components'
import { StarResume } from './components'

const Resume: FunctionComponent<Props> = ({ generalScores }) => (
  <>
    {generalScores && (
      <SimpleGrid gapY="10" columns={{ initial: 1, md: 2 }}>
        <RatingResume generalScores={generalScores} />
        <StarResume generalScores={generalScores} />
      </SimpleGrid>
    )}
  </>
)

export default Resume
