import { css } from '@emotion/react'
import styled from '@emotion/styled'

const wrapperStyles = ({ theme }) => css`
  label: wrapper;

  width: 100%;
  display: grid;
  grid-template-columns: 1fr;

  ${theme.media.giga} {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: ${theme.spacings.giga};

    margin-bottom: 0;
  }
`

const imageStyles = ({ theme, url = '' }) => css`
  label: wrapper__image;

  min-height: 260px;
  border-radius: ${theme.borderRadius.giga};

  background-image: url(${url});
  background-position: 66%;
  background-repeat: no-repeat;
  background-size: cover;
`

export const Wrapper = styled('div')(wrapperStyles)
export const Image = styled('div')(imageStyles)
