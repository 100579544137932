import PropTypes from 'prop-types'

import { getDescriptionDiscount } from 'scripts/utils/discount-by-payment-method'

import { Installments } from 'shared/components/Installments'

import { FormatPrice, PrefixPrice } from 'utils/Price'

import * as S from './styled'

const PriceInfo = ({
  packaging,
  packagingCount,
  translatedUnit,
  quantity,
  price: { from, to, total },
  discountByPaymentMethod,
  isMobile,
  installments
}) => {
  const descriptionPaymentMethod = discountByPaymentMethod && getDescriptionDiscount(discountByPaymentMethod)

  return (
    <S.PriceInfo isMobile={isMobile} data-testid="price-info">
      {from && from !== to &&
        <S.UnitPrice data-testid="from" data-cy="from" decoration={true}>
          <FormatPrice quantity={1} unit={translatedUnit} price={from} />
        </S.UnitPrice>
      }
      <S.UnitPrice data-testid="to" data-cy="to">
        <FormatPrice
          quantity={1}
          unit={translatedUnit}
          price={to}
          extra={descriptionPaymentMethod}
        />
      </S.UnitPrice>
      <S.TotalPrice data-testid="total" data-cy="total">
        <PrefixPrice packaging={packaging} packagingCount={packagingCount} />
        <FormatPrice
          quantity={quantity}
          unit={translatedUnit}
          price={total}
          extra={descriptionPaymentMethod}
        />
      </S.TotalPrice>
      {installments && (
        <S.InstallmentWrapper data-cy="installments" data-testid="installments">
          <Installments
            isBranded={false}
            installments={installments}
            showOneInstallment
          />
        </S.InstallmentWrapper>
      )}
    </S.PriceInfo>
  )
}

PriceInfo.propTypes = {
  packaging: PropTypes.number.isRequired,
  packagingCount: PropTypes.number.isRequired,
  translatedUnit: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
  discountByPaymentMethod: PropTypes.shape({
    methods: PropTypes.array
  }),
  price: PropTypes.shape({
    from: PropTypes.number.isRequired,
    to: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired
  }).isRequired,
  isMobile: PropTypes.bool.isRequired,
  installments: PropTypes.objectOf(
    PropTypes.shape({
      totalPrice: PropTypes.number,
      amount: PropTypes.number.isRequired,
      value: PropTypes.number.isRequired
    })
  )
}

export default PriceInfo
