import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

import { wishlistSchema } from '../../schema'

const DESCRIPTION =
  'Confira aqui todos os produtos e inspirações de fotos escolhidas para o seu projeto na Leroy Merlin. Acesse e comece a planejar agora mesmo!'

const ListHelmet = ({ name = 'Minha lista de favoritos' }) => (
  <Helmet>
    <title>Minhas Listas de Favoritos: {name} - Leroy Merlin</title>
    <meta name="description" content={DESCRIPTION} />
    <meta property="og:description" content={DESCRIPTION} />
    <script type="application/ld+json">
      {`${JSON.stringify(wishlistSchema(name))}`}
    </script>
  </Helmet>
)

ListHelmet.propTypes = {
  name: PropTypes.string.isRequired
}

export default ListHelmet
