import styled from '@emotion/styled'
import { css } from '@emotion/react'

const wrapperMobileMenuStyles = ({ theme }) => css`
  label: wrapper-mobile-menu;

  position: relative;

  > div {
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    overflow-y: auto;
  }
`

const wrapperIconCrossStyles = ({ theme }) => css`
  label: wrapper-icon-cross;

  svg {
    path {
      stroke-width: 1px;
      stroke: ${theme.colors.white};
    }
    position: fixed;
    z-index: 990;
    left: 88%;
    top: ${theme.spacings.kilo};
  }
`

export const WrapperMobileMenu = styled('div')(wrapperMobileMenuStyles)
export const WrapperIconCross = styled('div')(wrapperIconCrossStyles)
