import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { Theme } from 'scripts/react-components/themes/Theme'

const confirmationButton = ({ theme }: Theme) => css`
  label: confirmation-button;

  button {
    margin-top: ${theme.spacings.tera};
  }
`

export const ConfirmationButton = styled('div')(confirmationButton)

const lastChangePasswordShell = ({ theme }: Theme) => css`
    label: last-change-password-shell;

    margin-top: ${theme.spacings.bit};
    margin-bottom: ${theme.spacings.giga};
`

export const LastChangePasswordShell = styled('div')(lastChangePasswordShell)

const changePasswordInfoShell = ({ theme }: Theme) => css`
    label: last-change-password-info-shell;

    margin-bottom: ${theme.spacings.byte};
`

export const ChangePasswordInfoShell = styled('div')(changePasswordInfoShell)

const changePasswordButtonShell = ({ theme }: Theme) => css`
  label: change-password-info-shell;

  margin-top: ${theme.spacings.tera};
`

export const ChangePasswordButtonShell = styled('div')(changePasswordButtonShell)
