import $ from 'jquery'

import BaseComponent from 'scripts/components/base-component'

const DEFAULTS = {
  stickyClass: 'sticky',
  offset: 92
}

class Sticky extends BaseComponent {
  static componentName = 'sticky'

  constructor (element, options = {}) {
    super(element)

    this.options = $.extend({}, DEFAULTS, options)
  }

  init () {
    this.$reference = $(this.$element.attr('data-sticky-reference'))
    this.$unstickReference = $(this.$element.attr('data-unsticky-reference'))
    this.$window = $(window)

    if (this.hasUnstickReference()) {
      this.storeUnstickOffset()
    }

    this.storeRefOffset()
    this.bindListeners()
    this.onWindowScroll()
  }

  hasUnstickReference () {
    return !!this.$unstickReference.length
  }

  storeRefOffset () {
    this.refOffset = this.$reference.offset().top + this.$reference.height()
  }

  storeUnstickOffset () {
    this.unstickOffset = this.$unstickReference.offset().top
  }

  bindListeners () {
    this.$window.on('scroll', this.onWindowScroll.bind(this))
  }

  onWindowScroll () {
    this.$element.toggleClass(this.options.stickyClass, this.shouldDisplay())
  }

  shouldDisplay () {
    return !this.isUnstickyReferenceVisible() && this.isRefBefore()
  }

  isRefBefore () {
    return this.getWindowOffset() > this.refOffset
  }

  isUnstickyReferenceVisible () {
    return this.getWindowOffset() > this.unstickOffset
  }

  getWindowOffset () {
    return this.$window.scrollTop() + this.options.offset
  }
}

export default ($el) => new Sticky($el).init()

export { Sticky as Component }
