import { PropsWithChildren } from 'react'

type GridLeftProps = {}

export const GridLeft = ({ children }: PropsWithChildren<GridLeftProps>) => {
  return (
    <div className="bg-white flex-grow">
      <div className="max-w-[784px] ml-auto">{children}</div>
    </div>
  )
}
