import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { Theme } from 'scripts/react-components/themes/Theme'

const securityCodeStyles = ({ theme }: Theme) => css`
  label: security-code;

  display: flex;

  gap: ${theme.spacings.mega};
  margin-top: ${theme.spacings.bit};
  margin-bottom: ${theme.spacings.byte};
`

export const SecurityCode = styled('div')(securityCodeStyles)

const securityCodeFieldStyles = ({ theme }: Theme) => css`
  label: security-code__field;

  width: 40px;

  input {
    text-align: center;
  }
`

export const SecurityCodeField = styled('div')(securityCodeFieldStyles)

const resendCodeWrapperStyles = ({ theme }: Theme) => css`
  label: security-code__resend-code;

  font-size: 14px;
  text-align: center;
  user-select: none;

  span {
    width: 20px;
    display: inline-block;
  }
`

export const ResendCodeWrapper = styled('div')(resendCodeWrapperStyles)

const spinnerWrapperStyles = ({ theme }: Theme) => css`
  label: security-code__spinner;

  height: 16px;
`

export const SpinnerWrapper = styled('div')(spinnerWrapperStyles)
