import { call, put, takeLatest } from 'redux-saga/effects'

import * as services from 'checkout/services/coupon'

import { Constants, Actions } from './'

function * applyCoupon ({ code }) {
  try {
    const { data } = yield call(services.applyCoupon, code)

    yield put(Actions.applyCouponSuccess(data))
  } catch ({ status }) {
    yield put(Actions.applyCouponFailure(status))
  }
}

function * removeCoupon ({ code }) {
  try {
    const { data } = yield call(services.removeCoupon, code)

    yield put(Actions.removeCouponSuccess(data))
  } catch ({ status }) {
    yield put(Actions.removeCouponFailure(status))
  }
}

function * updatePage () {
  document.dispatchEvent(new Event('updatePage'))
}

function * spinnerShow () {
  document.dispatchEvent(new Event('spinnerShow'))
}

function * spinnerHide () {
  document.dispatchEvent(new Event('spinnerHide'))
}

export default function * root () {
  yield takeLatest(Constants.COUPONS_APPLY, applyCoupon)
  yield takeLatest(Constants.COUPONS_APPLY, spinnerShow)

  yield takeLatest(Constants.COUPONS_REMOVE, removeCoupon)
  yield takeLatest(Constants.COUPONS_REMOVE, spinnerShow)

  yield takeLatest(Constants.COUPONS_APPLY_SUCCESS, updatePage)
  yield takeLatest(Constants.COUPONS_REMOVE_SUCCESS, updatePage)

  yield takeLatest(Constants.COUPONS_APPLY_FAILURE, spinnerHide)
  yield takeLatest(Constants.COUPONS_REMOVE_FAILURE, spinnerHide)
}
