import styled from '@emotion/styled'
import { css } from '@emotion/react'

const wrapperStyle = () => css`
  background-color: #f4f1eb;
  border-radius: 4px;
  padding: 24px;
  width: 100%;
`
export const Wrapper = styled('div')(wrapperStyle)

const descriptionStyle = () => css`
  padding: 8px 0;
  display: flex;
  justify-content: space-between;
`
export const Description = styled('div')(descriptionStyle)

const totalStyle = () => css`
  margin-top: 16px;
  border-top: 1px solid #DEDAD2;
  padding-top: 16px;
  display: flex;
  justify-content: space-between;
`
export const Total = styled('div')(totalStyle)
