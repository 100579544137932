import { Provider } from 'react-redux'

import { configureStore } from 'checkout/redux/store'
import { Coupon } from 'checkout/containers/Coupon'

const store = configureStore()

const PaymentWithProvider = ({
  couponCode,
  hasService,
  discountEnginePromoEnabled
}) => {
  localStorage.setItem('@checkout:coupon', couponCode)

  return (
    <Provider store={store}>
      <Coupon
        hasService={hasService}
        discountEnginePromoEnabled={discountEnginePromoEnabled}
      />
    </Provider>
  )
}

export default PaymentWithProvider
