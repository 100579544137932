import React from 'react'
import { css } from '@emotion/react'
import styled from '@emotion/styled'

type RadiusProps = 'none' | 'sm' | 'md' | 'lg' | 'pill'

type BorderSizeProps = 'none' | 'thin' | 'thick' | 'thicker'

type AppearanceProps =
  | 'neutral-default'
  | 'neutral-strong'
  | 'neutral-stronger'
  | 'neutral-interactive'
  | 'neutral-inverse'
  | 'semantic-accent'
  | 'semantic-loyalty'
  | 'semantic-info'
  | 'semantic-success'
  | 'semantic-warning'
  | 'semantic-danger'

const borderSizeStyles = {
  none: css`
    border-width: 0;
  `,
  thin: css`
    border-width: 1px;
  `,
  thick: css`
    border-width: 2px;
  `,
  thicker: css`
    border-width: 4px;
  `
}

const radiusStyles = {
  none: css`
    border-radius: : 0;
  `,
  sm: css`
    border-radius: 2px;
  `,
  md: css`
    border-radius: 4px;
  `,
  lg: css`
    border-radius: 8px;
  `,
  pill: css`
    border-radius: 160px;
  `
}

const appearanceStyles = {
  'neutral-default': css`
    border-color: var(--color-border-neutral-default);
  `,
  'neutral-strong': css`
    border-color: var(--color-border-neutral-strong);
  `,
  'neutral-stronger': css`
    border-color: var(--color-border-neutral-stronger);
  `,
  'neutral-interactive': css`
    border-color: var(--color-border-neutral-interactive);
  `,
  'neutral-inverse': css`
    border-color: var(--color-border-neutral-inverse);
  `,
  'semantic-accent': css`
    border-color: var(--color-border-semantic-accent);
  `,
  'semantic-loyalty': css`
    border-color: var(--color-border-semantic-loyalty);
  `,
  'semantic-info': css`
    border-color: var(--color-border-semantic-info);
  `,
  'semantic-success': css`
    border-color: var(--color-border-semantic-success);
  `,
  'semantic-warning': css`
    border-color: var(--color-border-semantic-warning);
  `,
  'semantic-danger': css`
    border-color: var(--color-border-semantic-danger);
  `
}

type ImageProps = {
  src: string
  alt: string
  border?: string
  width?: string | number
  height?: string | number
  loading?: 'eager' | 'lazy'
  appearance?: AppearanceProps
  radius?: RadiusProps
  borderSize?: BorderSizeProps
}

const ImageWrapper = styled.div<ImageProps>`
  display: inline-block;
  overflow: hidden;
  border-style: solid;
  ${({ radius }: { radius: RadiusProps }) => radiusStyles[radius]};
  ${({ appearance }: { appearance: AppearanceProps }) =>
    appearanceStyles[appearance]};
  ${({ borderSize }: { borderSize: BorderSizeProps }) =>
    borderSizeStyles[borderSize]};
  ${({ width }: { width: string | number }) =>
    width &&
    (typeof width === 'number' ? `width: ${width}px` : `width: ${width}`)};
  ${({ height }: { height: string | number }) =>
    height &&
    (typeof height === 'number' ? `height: ${height}px` : `height: ${height}`)};
`

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
`

const Image: React.FC<ImageProps> = ({
  src,
  alt,
  width = 'auto',
  height = 'auto',
  radius = 'none',
  borderSize = 'none',
  appearance = 'none',
  loading = 'lazy'
}) => {
  return (
    <ImageWrapper
      width={width}
      height={height}
      appearance={appearance}
      borderSize={borderSize}
      radius={radius}
    >
      <StyledImage src={src} alt={alt} loading={loading} />
    </ImageWrapper>
  )
}

export default Image
