import { Icon, Stack, Text } from '@leroy-merlin-br/backyard-react'
import {
  DollarSignOutline,
  MoneyOutline,
  PercentSignOutline
} from '@backyard-ui/icons'

import { Ellipsis } from 'shared/components/Ellipsis'
import { DEPARTMENT_ICONS } from 'shared/icons'

import { isLink } from 'utils/validators'

import * as S from './styled'
import { CouponData } from '../../types'

type HeaderProps = {
  couponData: CouponData
  subtitle: string
}

type DepartmentIcons = keyof typeof DEPARTMENT_ICONS

const Header = ({ couponData, subtitle }: HeaderProps) => {
  const PictureIcon =
    couponData.type === 'percentage' ? PercentSignOutline : DollarSignOutline

  const Picture = () => {
    if (!couponData.thumbnail) {
      return <Icon as={MoneyOutline} />
    }

    if (isLink(couponData.thumbnail)) {
      return <img src={couponData.thumbnail} alt={couponData.title} />
    }

    return (
      DEPARTMENT_ICONS[couponData.thumbnail as DepartmentIcons] || undefined
    )
  }

  return (
    <S.Wrapper>
      <Stack alignX="center" space="byte">
        <S.Picture>
          <Picture />

          <S.PictureIcon>
            <Icon as={PictureIcon} size="kilo" />
          </S.PictureIcon>
        </S.Picture>
        <div>
          <Text
            size="mega"
            align="center"
            noMargin
            color={couponData.isActive ? 'f400' : 'n400'}
          >
            {subtitle}
          </Text>

          <Text
            size="giga"
            align="center"
            noMargin
            isBold={true}
            color={couponData.isActive ? 'white' : 'n800'}
          >
            <Ellipsis clamp={2}>{couponData.title}</Ellipsis>
          </Text>
        </div>
      </Stack>
    </S.Wrapper>
  )
}

export default Header
