import { tracker } from './'

export const buildEventPurchase = item => {
  const dataLayerOrder = JSON.parse(
    document.querySelector('[name="data-layer-order"]').getAttribute('content')
  )

  const mappedItems = JSON.parse(item.getAttribute('content')).map(item => {
    const category = item.category.split('/')

    return {
      currency: 'BRL',
      item_id: item.id,
      item_name: item.name,
      price: item.price,
      quantity: item.quantity,
      discount: item.discount,
      item_brand: item.brand,
      item_category: category[0] || '',
      item_category2: category[1] || '',
      item_category3: category[2] || '',
      item_category4: category[3] || '',
      item_category5: category[4] || '',
      affiliation:
        item.shopName === 'Leroy Merlin'
          ? 'delivery: Leroy Merlin'
          : `pickup-in-store: ${item.shopName}`
    }
  })

  const mappedOrder = {
    items: mappedItems,
    value: dataLayerOrder.value,
    shipping: dataLayerOrder.shipping,
    region: dataLayerOrder.region,
    coupon: dataLayerOrder.coupon,
    transaction_id: dataLayerOrder.transaction_id,
    shipping_tier: dataLayerOrder.shipping_tier,
    payment_type: dataLayerOrder.payment_type,
    payment_installments: dataLayerOrder.payment_installments,
    currency: dataLayerOrder.currency,
    tax: ''
  }

  tracker.purchase(mappedOrder)
  tracker.firstSale(mappedOrder)
}

export const buildEventViewItem = element => {
  const product = JSON.parse(element.getAttribute('event-view-item'))
  tracker.viewItem(product)
}
