import { css } from '@emotion/react'
import styled from '@emotion/styled'

const navigationStyles = ({ theme }) => css`
  label: generic-carousel__navigation;

  position: absolute;
  top: 50%;
  left: ${theme.spacings.byte};
  right: ${theme.spacings.byte};
  transform: translateY(-50%);
  height: 0px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  z-index: 1;

  ${theme.media.tera} {
    left: -${theme.spacings.mega};
    right: -${theme.spacings.mega};
  }
`

const offsetStyles = ({ hasOffset, theme }) =>
  hasOffset &&
  css`
    label: generic-carousel__navigation--with-offset;

    ${theme.media.tera} {
      left: 0;
    }
  `

const boxStyles = ({ theme }) => css`
  label: navigation__box;

  background-color: ${theme.colors.n100};

  border-radius: ${theme.borderRadius.circle};

  opacity: 0.7;

  width: 36px;
  height: 38px;

  user-select: none;

  display: flex;
  justify-content: center;
  align-items: center;

  transition: all 600ms cubic-bezier(0.19, 1, 0.22, 1);

  display: none;

  ${theme.media.mega} {
    &:not(.swiper-button-lock) {
      display: flex;
    }
  }
`

const disabledStyles = ({ theme }) => css`
  label: navigation__box--disabled;

  cursor: not-allowed;

  &:not(.swiper-button-disabled) {
    cursor: pointer;

    &:hover {
      background-color: ${theme.colors.p500};
      opacity: 1;

      svg {
        fill: ${theme.colors.white};
      }
    }
  }
`

const leftIconStyles = ({ side }) =>
  side === 'left' &&
  css`
    & > svg {
      margin-right: 2px;
    }
  `

const rightIconStyles = ({ theme, side }) =>
  side === 'right' &&
  css`
    & > svg {
      margin-left: 2px;
    }
  `

export const Navigation = styled('div')(navigationStyles, offsetStyles)
export const Box = styled('div')(
  boxStyles,
  leftIconStyles,
  rightIconStyles,
  disabledStyles
)
