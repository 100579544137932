import axios from 'axios'

export const getStoresByRegion = async (region, filter) => {
  const response = await axios
    .get(`/api/v3/regions/${region}/stores` + (filter ? `?filter=${filter}` : ''))
    .then(({ data }) => data)
    .catch(err => {
      throw err.response
    })

  return response
}
