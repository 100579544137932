import React, { useEffect, useContext, useState, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { Stack, Button } from '@leroy-merlin-br/backyard-react'
import PropTypes from 'prop-types'

import { errorInMiniCartContext } from 'scripts/react-components/checkout/services/data-layer'

import { AlertMessage } from 'shared/components/AlertMessage'
import { SummaryPricesCart } from 'shared/components/Summary'
import { CouponAlertMessage } from 'shared/components'

import {
  callApiAndUpdateCart,
  cartProductAdded,
  updateCartQuantity
} from 'site/Cart/services'
import { removeListSubdomain } from 'site/utils/formatUrlListPage'
import { useCartDrawerContext } from 'site/Header/contexts/CartDrawerContext'

import {
  ProductsList,
  Summary,
  ServiceList
} from 'checkout/components/MiniCart'
import AssociatedProducts from 'checkout/containers/AssociatedProducts/Associated'
import { Actions } from 'checkout/redux/modules/crossServices'
import { Actions as CartActions } from 'checkout/redux/modules/cart'

import { useStandaloneService } from 'frontendCheckout/hooks'
import * as algonomy from 'frontendCheckout/services/algonomy'

import { Header } from '../Header'
import * as S from './styled'

const formattedUrlCart = removeListSubdomain('/carrinho')

const Contents = ({
  discountTotal,
  cheapestPayMethodTotal,
  isCartDrawerOpen,
  resetCart,
  regionId,
  shippings = [],
  subTotal,
  total,
  servicesSubTotal,
  services = [],
  availableServices = [],
  paymentMethodsSummary,
  paymentMethods,
  totalInstallments,
  totalDiscountsByType,
  firstCheapestPaymentMethod
}) => {
  const { toggleCartDrawer } = useCartDrawerContext()
  const drawerRef = useRef(null)
  const [firstCartOpening, setFirstCartOpening] = useState(true)
  const [currentCartTotalPrice, setCartTotalPrice] = useState(total)
  const [currentShipping, setCurrentShipping] = useState(shippings)
  const [currentCartDiscount, setCartDiscountTotal] = useState(discountTotal)
  const [currentCheapestPayMethodTotal, setCheapestPayMethodTotal] = useState(
    cheapestPayMethodTotal
  )
  const [currentCartServiceSubTotal, setCartServiceSubTotal] =
    useState(servicesSubTotal)
  const [currentCartSubTotal, setCartSubTotal] = useState(subTotal)
  const [associatedBlackList, setAssociatedBlackList] = useState([])
  const [currentPaymentMethods, setCartPaymentMethods] =
    useState(paymentMethods)
  const [currentTotalInstallments, setTotalInstallment] =
    useState(totalInstallments)
  const [currentTotalDiscountsByType, setTotalDiscountsByType] =
    useState(totalDiscountsByType)
  const [currentFirstCheapestPaymentMethod, setFirstCheapestPaymentMethod] =
    useState(firstCheapestPaymentMethod)

  const dispatch = useDispatch()

  const { isStandaloneServiceCart } = useStandaloneService({
    services,
    isCartWithoutProducts: !shippings?.length
  })

  const removeProductFromCart = async ({ shippingIndex = 0, id }) => {
    const productToBeRemoved = currentShipping?.[shippingIndex]?.items.find(
      (product) => product.id === id
    )

    if (productToBeRemoved !== undefined) {
      const updatedCart = await callApiAndUpdateCart()

      dispatch(Actions.removeService(productToBeRemoved.productId))

      const products = updatedCart?.shippings?.flatMap(shipment => shipment.items)
      algonomy.sendCartInfo(products)
    }

    const removeAllItemsFromCart =
      currentShipping.length <= 1 && currentShipping[0]?.items.length <= 1

    if (removeAllItemsFromCart) {
      errorInMiniCartContext(
        'clique_remover_item',
        'Removeu todos os itens do carrinho'
      )
    }
  }

  const updateCartInfo = ({
    discountTotal,
    cheapestPayMethodTotal,
    id,
    regionId,
    shippings,
    subTotal,
    total,
    uniqueProductsCount,
    zipCode,
    paymentMethodsSummary,
    services,
    servicesSubTotal,
    paymentMethods,
    totalInstallments,
    totalDiscountsByType,
    firstCheapestPaymentMethod
  }) => {
    setCartSubTotal(subTotal)
    setCartTotalPrice(total)
    setCartDiscountTotal(discountTotal)
    setCheapestPayMethodTotal(cheapestPayMethodTotal)
    setCurrentShipping(shippings)
    setCartServiceSubTotal(servicesSubTotal)
    setCartPaymentMethods(paymentMethods)
    setTotalInstallment(totalInstallments)
    setTotalDiscountsByType(totalDiscountsByType)
    setFirstCheapestPaymentMethod(firstCheapestPaymentMethod)

    cartProductAdded({
      discountTotal,
      cheapestPayMethodTotal,
      id,
      regionId,
      shippings,
      subTotal,
      total,
      uniqueProductsCount,
      zipCode,
      services,
      paymentMethodsSummary,
      servicesSubTotal,
      paymentMethods,
      totalInstallments,
      totalDiscountsByType,
      firstCheapestPaymentMethod
    })

    updateCartQuantity({ count: uniqueProductsCount })

    const products = shippings?.flatMap(shipping => shipping.items)
    algonomy.sendCartInfo(products)
  }

  useEffect(() => {
    if (isCartDrawerOpen && firstCartOpening) {
      const products = currentShipping?.flatMap(shipping => shipping.items) || []
      algonomy.sendCartInfo(products)
      setFirstCartOpening(false)
    }
  }, [isCartDrawerOpen, currentShipping, firstCartOpening])

  useEffect(() => {
    const shouldReset =
      currentCartTotalPrice !== 0 &&
      !currentShipping[0]?.items?.length &&
      !isCartDrawerOpen

    if (shouldReset) {
      resetCart()
    }
  })

  useEffect(() => {
    dispatch(CartActions.setShippings(shippings))
  }, [dispatch, shippings])

  useEffect(() => {
    setCurrentShipping(shippings)
  }, [shippings])

  useEffect(() => {
    setCartSubTotal(subTotal)
  }, [subTotal])

  useEffect(() => {
    setCartTotalPrice(total)
  }, [total])

  useEffect(() => {
    setCartDiscountTotal(discountTotal)
  }, [discountTotal])

  useEffect(() => {
    setCheapestPayMethodTotal(cheapestPayMethodTotal)
  }, [cheapestPayMethodTotal])

  useEffect(() => {
    setCartServiceSubTotal(servicesSubTotal)
  }, [servicesSubTotal])

  useEffect(() => {
    setCartPaymentMethods(paymentMethods)
  }, [paymentMethods])

  useEffect(() => {
    setTotalInstallment(totalInstallments)
  }, [totalInstallments])

  useEffect(() => {
    setTotalDiscountsByType(totalDiscountsByType)
  }, [totalDiscountsByType])

  useEffect(() => {
    setFirstCheapestPaymentMethod(firstCheapestPaymentMethod)
  }, [firstCheapestPaymentMethod])

  useEffect(() => {
    if (currentShipping[0]?.items?.length) {
      const blackList = currentShipping[0].items.map((product) => product.id)
      setTimeout(() => {
        setAssociatedBlackList(blackList)
      }, 500)
    }
  }, [currentShipping])

  const firstShipmentType = currentShipping[0]?.type
  const hasPaymentMethods = paymentMethods !== undefined
  return (
    <S.Drawer ref={drawerRef}>
      <Header plural={currentShipping} closeCardDrawer={() => {
        toggleCartDrawer()
        setFirstCartOpening(true)
      }} />
      {!currentCartTotalPrice ? (
        <AlertMessage type="warning">
          Você removeu todos os itens do seu carrinho, aproveite para escolher
          seus produtos novamente.
        </AlertMessage>
      ) : (
        <>
          <ProductsList
            regionId={regionId}
            shippings={currentShipping}
            removeProductFromCart={removeProductFromCart}
            updateProduct={updateCartInfo}
            services={services}
            availableServices={availableServices}
          />
          <ServiceList
            regionId={regionId}
            shippings={currentShipping}
            updateCartInfo={updateCartInfo}
            services={services}
          />
        </>
      )}
      <Stack space="mega">
        <S.Line />
        {!hasPaymentMethods && (
          <Summary
            discount={currentCartDiscount}
            subTotal={currentCartSubTotal}
            total={currentCartTotalPrice}
            servicesSubTotal={currentCartServiceSubTotal}
            paymentMethodsSummary={paymentMethodsSummary}
          />
        )}
        {hasPaymentMethods && (
          <SummaryPricesCart
            discount={currentCartDiscount}
            subTotal={currentCartSubTotal}
            total={currentCheapestPayMethodTotal}
            servicesSubTotal={currentCartServiceSubTotal}
            paymentMethods={currentPaymentMethods}
            totalInstallments={currentTotalInstallments}
            totalDiscountsByType={currentTotalDiscountsByType}
            firstCheapestPaymentMethod={currentFirstCheapestPaymentMethod}
            showDiscountsModal={false}
          />
        )}
        <Button
          data-cy="redirect-to-cart-button"
          size="kilo"
          href={formattedUrlCart}
          isStretch
        >
          Ir para o carrinho
        </Button>

        {!isStandaloneServiceCart && <CouponAlertMessage />}

        <AssociatedProducts
          regionId={regionId}
          blackList={associatedBlackList}
          cartType={firstShipmentType}
        />
      </Stack>
    </S.Drawer>
  )
}

Contents.propTypes = {
  products: PropTypes.array,
  subTotal: PropTypes.number,
  discountTotal: PropTypes.number,
  cheapestPayMethodTotal: PropTypes.number,
  total: PropTypes.number,
  paymentMethods: PropTypes.string,
  totalDiscountsByType: PropTypes.shape({
    stampedPrice: PropTypes.number,
    promo: PropTypes.number,
    employee: PropTypes.number,
    'payment-method': PropTypes.number,
    pix: PropTypes.number,
    bank_slip: PropTypes.number,
    credit_card: PropTypes.number
  }),
  firstCheapestPaymentMethod: PropTypes.string,
  totalInstallments: PropTypes.shape({
    installments: {
      amount: PropTypes.number,
      value: PropTypes.string,
      totalPrice: PropTypes.string
    }
  })
}

export default Contents
