import { useState } from 'react'
import { Provider } from 'react-redux'
import { Text } from '@leroy-merlin-br/backyard-react'

import { store, useAppSelector } from 'newCheckout/store'

import { SelectStoreDrawer } from '../Drawer'
import * as S from './Style'
import type { Store } from '../../../../types/PickUpInStore'

type OnChangeParameters = {
  type: 'pickup-in-store' | 'delivery'
  storeId?: string | null
  availableStores?: Store[]
}

const ChangeStore = ({
  cartId,
  onChange,
  storeId
}: {
  cartId: string
  onChange: (params: OnChangeParameters) => void
  storeId: string
}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const availableStores = useAppSelector(
    state => state.pickUpInStore.data.stores
  )

  const handleChange = (data: OnChangeParameters) => {
    onChange(data)
    setIsDrawerOpen(false)
  }

  if (availableStores.length === 0) return <></>

  const storeName = availableStores.find(
    store => store.storeId === storeId
  )?.storeName

  return (
    <>
      <SelectStoreDrawer
        isDrawerOpen={isDrawerOpen}
        overlayClick={() => setIsDrawerOpen(false)}
        stores={availableStores}
        onChange={handleChange}
        storeId={storeId}
        cartId={cartId}
      />
      <div>
        <Text
          as="span"
          isBold={true}
          size="mega"
          data-cy="cart-type-store-name"
        >
          {storeName}
        </Text>
        <S.ChangeStore
          onClick={() => setIsDrawerOpen(true)}
          data-cy="cart-type-change-button"
        >
          Trocar
        </S.ChangeStore>
      </div>
    </>
  )
}

const ChangeStoreWithStore = ({
  cartId,
  onChange,
  storeId
}: {
  cartId: string
  onChange: (params: OnChangeParameters) => void
  storeId: string
}) => (
  <Provider store={store}>
    <ChangeStore cartId={cartId} storeId={storeId} onChange={onChange} />
  </Provider>
)

export default ChangeStoreWithStore
