import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import {
  Button,
  Card,
  Stack,
  Inline,
  TextField,
  Text,
  Badge
} from '@leroy-merlin-br/backyard-react'

import { pluralize } from 'lmcv/utils/pluralize'
import { DropDown } from 'lmcv/components'
import * as relationship from 'lmcv/services/relationship'

import { Header } from './components/Header'
import * as S from './styled'

function ProfilePending ({ data, handleDropDown }) {
  const {
    customerName,
    projectName,
    status,
    remainingWeeks,
    inviteUrl,
    id
  } = data

  const {
    handleSubmit,
    register,
    formState: { errors }
  } = useForm({ mode: 'onBlur' })

  const [isOnClipboard, setIsOnClipboard] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isSentToEmail, setIsSentToEmail] = useState(false)

  const onCopyToClip = () => {
    setIsOnClipboard(true)
    setTimeout(() => setIsOnClipboard(false), 2000)
  }

  const onSendInvite = async ({ email }) => {
    setIsLoading(true)

    const payload = {
      relationshipId: id,
      guestEmail: email
    }

    const handleSuccess = () => {
      setIsSentToEmail(true)
      setTimeout(() => setIsSentToEmail(false), 2000)
    }

    const handleError = err => {
      console.error(err)
    }

    await relationship
      .sendInvite(payload)
      .then(handleSuccess)
      .catch(handleError)
      .finally(() => setIsLoading(false))
  }

  return (
    <Card>
      <S.Menu>
        <DropDown>
          <DropDown.Item text="Excluir cliente" onClick={handleDropDown} />
        </DropDown>
      </S.Menu>

      <S.Content>
        <Header
          customerName={customerName}
          projectName={projectName}
          projectStatus={status}
        />

        <S.Box>
          <S.BoxItem>
            <Text color="n600" isBold>
              Tempo previsto
            </Text>
            <Text color="n900" isBold noMargin>
              {remainingWeeks} {pluralize('semana', remainingWeeks !== 1)}
            </Text>
            <Text size="kilo" color="n400" noMargin>
              A contagem iniciará após seu cliente aceitar o convite.
            </Text>
          </S.BoxItem>

          <S.BoxItem>
            <Text color="n600" isBold>
              Reenviar convite
            </Text>
            <S.Form onSubmit={handleSubmit(onSendInvite)}>
              <S.InputsWrapper>
                <Stack>
                  <Inline alignX="flex-start" alignY="flex-end">
                    <TextField
                      type="text"
                      label="Compartilhar link"
                      defaultValue={inviteUrl}
                      isDisabled
                    />
                    <CopyToClipboard text={inviteUrl} onCopy={onCopyToClip}>
                      <Button type="button">
                        {isOnClipboard ? 'Copiado!' : 'Copiar'}
                      </Button>
                    </CopyToClipboard>
                  </Inline>

                  <Inline alignX="flex-start" alignY="flex-end">
                    <TextField
                      {...register('email', {
                        required: 'Insira um e-mail'
                      })}
                      label="Enviar link por e-mail"
                      state={Boolean(errors.email) && 'invalid'}
                      hint={
                        errors?.email
                          ? errors?.email?.message
                          : 'Ex: E-mail do cliente'
                      }
                    />
                    <Button type="submit" isLoading={isLoading}>
                      {isSentToEmail ? 'Enviado!' : 'Enviar'}
                    </Button>
                  </Inline>
                </Stack>
              </S.InputsWrapper>
            </S.Form>
          </S.BoxItem>
        </S.Box>
      </S.Content>
    </Card>
  )
}

export default ProfilePending
