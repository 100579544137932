export const ASSOCIATED_RESET = '@ASSOCIATED_RESET'
export const ASSOCIATED_UPDATE_BLACKLIST = '@ASSOCIATED_UPDATE_BLACKLIST'
export const ASSOCIATED_GET_PRODUCTS = '@ASSOCIATED_GET_PRODUCTS'
export const ASSOCIATED_GET_PRODUCTS_UPDATE = '@ASSOCIATED_GET_PRODUCTS_UPDATE'
export const ASSOCIATED_GET_PRODUCTS_ERROR = '@ASSOCIATED_GET_PRODUCTS_ERROR'
export const ASSOCIATED_PUT_PRODUCTS = '@ASSOCIATED_PUT_PRODUCTS'
export const ASSOCIATED_PUT_PRODUCTS_START = '@ASSOCIATED_PUT_PRODUCTS_START'
export const ASSOCIATED_PUT_PRODUCTS_SUCCESS = '@ASSOCIATED_PUT_PRODUCTS_SUCCESS'
export const ASSOCIATED_PUT_PRODUCTS_ERROR = '@ASSOCIATED_PUT_PRODUCTS_ERROR'
export const ASSOCIATED_PUT_PRODUCTS_RESET = '@ASSOCIATED_PUT_PRODUCTS_RESET'
export const ASSOCIATED_PUT_PRODUCTS_FAIL = '@ASSOCIATED_PUT_PRODUCTS_FAIL'

export default {
  ASSOCIATED_GET_PRODUCTS,
  ASSOCIATED_GET_PRODUCTS_UPDATE,
  ASSOCIATED_GET_PRODUCTS_ERROR,
  ASSOCIATED_PUT_PRODUCTS,
  ASSOCIATED_PUT_PRODUCTS_START,
  ASSOCIATED_PUT_PRODUCTS_SUCCESS,
  ASSOCIATED_PUT_PRODUCTS_ERROR,
  ASSOCIATED_PUT_PRODUCTS_RESET,
  ASSOCIATED_PUT_PRODUCTS_FAIL
}
