import { Card } from '@leroy-merlin-br/backyard-react'

import { DropDown } from 'lmcv/components'

import { Header } from './components/Header'
import ProjectDate from './components/ProjectDate'
import * as S from './styled'

function ProfileFinished ({ data, handleDropDown }) {
  const { customerName, projectName, status, expireAt } = data

  return (
    <Card>
      <S.Menu>
        <DropDown>
          <DropDown.Item text="Excluir cliente" onClick={handleDropDown} />
        </DropDown>
      </S.Menu>

      <S.Content>
        <Header
          customerName={customerName}
          projectName={projectName}
          projectStatus={status}
        />

        <S.Divider />

        {expireAt && (
          <ProjectDate title={'Projeto finalizado em'} data={expireAt} />
        )}
      </S.Content>
    </Card>
  )
}

export default ProfileFinished
