const LoaderProducts = () => (
  <div className="wishlist-loader wishlist-loader-products">
    <div className="wishlist-loader-item">
      <div className="wishlist-products-loader"></div>
    </div>
    <div className="wishlist-loader-item">
      <div className="wishlist-products-loader"></div>
    </div>
    <div className="wishlist-loader-item">
      <div className="wishlist-products-loader"></div>
    </div>
    <div className="wishlist-loader-item">
      <div className="wishlist-products-loader"></div>
    </div>
  </div>
)

export default LoaderProducts
