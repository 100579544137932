import { FunctionComponent } from 'react'
import dayjs from 'dayjs'

import { Props } from './types'

const ReviewSchema: FunctionComponent<Props> = ({
  author,
  ratingValue,
  datePublished,
  title,
  comment = ''
}) => {
  const rating = ratingValue ? ratingValue.toString() : ''
  const formatedDate = datePublished
    ? dayjs(datePublished).format('YYYY/MM/DD')
    : ''

  return (
    <div itemProp="review" itemScope itemType="http://schema.org/Review">
      <div itemProp="author" itemScope itemType="https://schema.org/Person">
        <meta itemProp="name" content={author} />
      </div>
      <div
        itemProp="reviewRating"
        itemScope
        itemType="http://schema.org/Rating"
      >
        <meta itemProp="ratingValue" content={rating} />
      </div>
      <meta itemProp="dateCreated" content={formatedDate} />
      <meta itemProp="datePublished" content={formatedDate} />
      <meta itemProp="headline" content={title} />
      <meta itemProp="reviewBody" content={comment} />
    </div>
  )
}

export default ReviewSchema
