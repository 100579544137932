import styled from '@emotion/styled'
import { css } from '@emotion/react'

const wrapperStyles = ({ theme }) =>
  css`
    label: wrapper;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 65px;
    height: 65px;

    border-radius: ${theme.borderRadius.circle};

    color: ${theme.colors.n500};
    font-size: ${theme.typography.headings.giga.fontSize};

    & svg {
      width: 30px;
      height: 30px;
    }
  `

const wrapperSmallStyles = ({ theme, isSmall }) =>
  isSmall &&
  css`
    label: wrapper--small;

    width: 44px;
    height: 44px;

    font-size: ${theme.typography.text.giga.fontSize};

    svg {
      width: 20px;
      height: 20px;
    }
  `

const wrapperOnLoyaltyStyles = ({ theme, isOnLoyalty, maskColor, isSmall }) =>
  isOnLoyalty &&
  css`
    label: wrapper--on-loyalty;

    background: linear-gradient(
      134.58deg,
      ${theme.colors.f600} -5.39%,
      ${theme.colors.v500} 100%
    );

    padding: ${isSmall ? '2px' : theme.spacings.bit};

    & > div {
      border-color: ${theme.colors[maskColor]};
      border-width: ${isSmall ? '2px' : '4px'};
      border-style: solid;
    }

    & > img {
      border-color: ${theme.colors[maskColor]};
      border-width: ${isSmall ? '2px' : '4px'};
      border-style: solid;
    }
  `

const userIconStyles = ({ theme }) => css`
  label: user-icon;

  display: flex;
  justify-content: center;
  align-items: center;

  flex: 1;
  height: 100%;

  border-radius: ${theme.borderRadius.circle};
  background: ${theme.colors.n200};
`

const userImageStyles = ({ theme }) => css`
  label: user-image;

  flex: 1;
  height: 100%;
  object-fit: cover;
  border-radius: ${theme.borderRadius.circle};
`

export const Wrapper = styled('div')(
  wrapperStyles,
  wrapperSmallStyles,
  wrapperOnLoyaltyStyles
)
export const UserIcon = styled('div')(userIconStyles)
export const UserImage = styled('img')(userImageStyles)
