import { Row, Col, Stack } from '@leroy-merlin-br/backyard-react'

import { Shell } from 'shared/components/Shell'

import * as S from './styled'

const CartSkeleton = () => (
  <S.Container>
    <Stack space="mega">
      <Shell height={100} />
      <Row>
        <Col size={{ giga: 7 }}>
          <Stack>
            <Shell height={100} />
            <Shell height={136} />
            <Shell height={200} />
          </Stack>
        </Col>

        <Col size={{ giga: 4 }} offset={{ giga: 1 }}>
          <Shell height={256} />
        </Col>
      </Row>
    </Stack>
  </S.Container>
)

export default CartSkeleton
