import execute from './execute'

export type ChangeCartType = {
  regionId: string
  shipmentType: 'pickupInStore' | 'delivery'
  productName: string
  productId: string
  productPrice: number
  productBrand: string
  productCategory: string
  marketPlace: string
  productQuantity: string
  relatedProduct: string | null
  store: string
}

export const dataLayerChangeCartType = ({
  regionId,
  shipmentType,
  productName,
  productId,
  productPrice,
  productBrand,
  productCategory,
  marketPlace,
  productQuantity,
  relatedProduct,
  store
}: ChangeCartType) => {
  const dataObject = {
    event: shipmentType === 'delivery' ? 'change_delivery' : 'change_store',
    dimension14: regionId,
    ecommerce: {
      currencyCode: 'BRL',
      change: {
        products: [
          {
            name: productName,
            id: productId,
            price: productPrice.toFixed(2),
            brand: productBrand,
            category: productCategory,
            quantity: productQuantity,
            dimension8: shipmentType,
            dimension9: store,
            dimension15: marketPlace,
            dimension31: relatedProduct
          }
        ]
      }
    }
  }

  return dataObject
}

const changeCartType = (changeCartTypeProps: ChangeCartType) => {
  execute(dataLayerChangeCartType(changeCartTypeProps))
}

export default changeCartType
