import { ServiceInstall } from 'newCheckout/images'

import { ProductsType } from './type'
import Visual from './Visual'
import VisualService from './VisualService'
import VisualProduct from './VisualProduct'
import * as S from './styled'

const Products = ({ items, services }: ProductsType) => {
  return <Visual>
    {items.map(item => (
      <S.ProductStyles key={item.product_id}>
        <VisualProduct
          name={item.product.name}
          marketPlace={item.marketPlace}
          href={'aaa'}
          packagingUnit={item.product.packagingUnit}
          picture={item.picture}
          priceUnit={item.price_unit}
          quantity={item.quantity}
          totalPrice={item.totalPrice}
        />
        {services
          .filter(
            service => service.relatedProduct.productId === item.product_id
          )
          .map(service => (
            <VisualService
              key={service.id}
              name={service.name}
              priceUnit={service.price.to}
              quantity={service.quantity}
              totalPrice={service.price.total}
              Component={<ServiceInstall />}
            />
          ))}
      </S.ProductStyles>
    ))}
  </Visual>
}

export default Products
