import { Card, Text, Inline, Stack } from '@leroy-merlin-br/backyard-react'

import * as S from './styled'
import { PixVisualType } from './type'

const Pix = ({ paymentMethod }: PixVisualType) => (
  <Card>
    <Text size="giga" isBold>
      Dados de pagamento
    </Text>
    <Stack space="kilo">
      <S.Panel>
        <Text size="mega" isBold>
          Pagamento PIX com QR Code
        </Text>
        <S.PixQRCodeWrapper>
          <S.PixQRCodeDescription>
            <Inline space="bit">
              <Text noMargin>1.</Text>
              <Text noMargin>
                Abra o App ou Site onde você cadastrou suas chaves PIX.
              </Text>
            </Inline>
            <Inline space="bit">
              <Text noMargin>2.</Text>
              <Text noMargin>
                Selecione a opção PIX no App/Site que você utiliza.
              </Text>
            </Inline>
            <Inline space="bit">
              <Text noMargin>3.</Text>
              <Text noMargin>
                Selecione a opção ”
                <Text isBold as="span">
                  Pagar QR Code
                </Text>
                ”.
              </Text>
            </Inline>
            <Inline space="bit">
              <Text noMargin>4.</Text>
              <Text noMargin>
                Escaneie o código exibido a seguir, nessa página. Finalize sua
                compra e{' '}
                <Text isBold as="span">
                  aguarde nosso e-mail de confirmação
                </Text>{' '}
                de pagamento.
              </Text>
            </Inline>
          </S.PixQRCodeDescription>
          <S.PixQRCodeImage src={`data:image/png;base64, ${paymentMethod.qr_code.imageBase64}`} />
        </S.PixQRCodeWrapper>
      </S.Panel>
      <S.Panel>
        <Text size="mega" isBold>
          Mas se você preferir, copie e cole o código abaixo:
        </Text>
        <Card shadow="double">
          <Text as="div" color="p700" align="center" noMargin isBold>
            <S.PixCodeText>
              {paymentMethod.qr_code.emv}
            </S.PixCodeText>
          </Text>
        </Card>
        <div>
          <Inline space="bit">
            <Text noMargin>1.</Text>
            <Text noMargin>
              Selecione a opção ”
              <Text isBold as="span">
                PIX Copia e Cola
              </Text>
              ”.
            </Text>
          </Inline>
          <Inline space="bit">
            <Text noMargin>2.</Text>
            <Text noMargin>
              Cole o código, confira as informações, finalize sua compra e{' '}
              <Text isBold as="span">
                aguarde nosso e-mail de confirmação
              </Text>{' '}
              de pagamento.
            </Text>
          </Inline>
        </div>
      </S.Panel>
    </Stack>
  </Card>
)

export default Pix
