import React from 'react'

import { renderTemplate } from '../render-template'

const ListPhone = ({ items }) => (
  <>
    {items.map((item, index) => (
      <fieldset
        key={index}
        className="col-xs-12 col-md-6"
        data-list-item="phone"
      >
        {Object.entries(item).map(([property, value]) => (
          <input
            key={property}
            type="hidden"
            name={`phones[${index}][${property}]`}
            value={typeof value === 'boolean' ? value.toString() : value}
          />
        ))}

        <div className="col-xs-12 info-box">
          <div className="row">
            <div className="col-xs-12 info-text">
              <strong>
                {item.type === 'cellphone' ? 'Celular:' : 'Fixo:'}
                &nbsp;
              </strong>
              <span data-phone-mask>{item.number}</span>
            </div>
            <div className="col-xs-12 info-text info-text-main">
              {item.default === true && 'Telefone principal'}
            </div>
          </div>

          <div className="row">
            <div className="col-xs-5">
              <button
                className="button button-neutral"
                type="button"
                data-trigger="modal"
              >
                Editar
              </button>
            </div>
            <div className="col-xs-7 align-right">
              <button
                className="button button-text"
                type="button"
                data-trigger="remove"
              >
                <i className="glyph glyph-trash-can"></i> Remover Telefone
              </button>
            </div>
          </div>
        </div>
      </fieldset>
    ))}
  </>
)

const render = (props = {}) => renderTemplate(<ListPhone {...props} />)

export default render
