import {
  Container,
  Row,
  Col,
  Stack,
  Hidden
} from '@leroy-merlin-br/backyard-react'

import { Shell } from 'shared/components/Shell'

import * as S from './styled'

const EntryKeySkeleton = () => (
  <Container>
    <Stack space="mega">
      <Shell width="40vw" height={32} />
      <Shell height={166} borderRadius="bit" />
      <Shell height={423} borderRadius="bit" />
      <Shell height={160} borderRadius="bit" />
      <Row justify="space-between">
        <Hidden when={['untilKilo', 'kilo', 'mega']}>
          <Col size={{ giga: 3 }}>
            <Stack space="bit">
              <Shell height={58} />
              <Shell height={58} />
              <Shell height={58} />
              <Shell height={58} />
              <Shell height={58} />
              <Shell height={58} />
            </Stack>
          </Col>
        </Hidden>

        <Col size={{ giga: 9 }}>
          <S.WrapperInline>
            <Shell height={594} />
            <Shell height={594} />
            <Shell height={594} />
            <Shell height={594} />
          </S.WrapperInline>
          <S.WrapperInline>
            <Shell height={594} />
            <Shell height={594} />
            <Shell height={594} />
            <Shell height={594} />
          </S.WrapperInline>
          <S.WrapperInline>
            <Shell height={594} />
            <Shell height={594} />
            <Shell height={594} />
            <Shell height={594} />
          </S.WrapperInline>
          <S.WrapperInline>
            <Shell height={594} />
            <Shell height={594} />
            <Shell height={594} />
            <Shell height={594} />
          </S.WrapperInline>
          <S.WrapperInline>
            <Shell height={594} />
            <Shell height={594} />
            <Shell height={594} />
            <Shell height={594} />
          </S.WrapperInline>
          <S.WrapperInline>
            <Shell height={594} />
            <Shell height={594} />
            <Shell height={594} />
            <Shell height={594} />
          </S.WrapperInline>
          <S.WrapperInline>
            <Shell height={594} />
            <Shell height={594} />
            <Shell height={594} />
            <Shell height={594} />
          </S.WrapperInline>
          <S.WrapperInline>
            <Shell height={594} />
            <Shell height={594} />
            <Shell height={594} />
            <Shell height={594} />
          </S.WrapperInline>
        </Col>
      </Row>
    </Stack>
  </Container>
)

export default EntryKeySkeleton
