import styled from '@emotion/styled'
import { css } from '@emotion/react'

const wrapperStyles = ({ theme }) => css`
  label: wrapper;

  display: grid;
  margin-bottom: ${theme.spacings.mega};
  grid-gap: ${theme.spacings.mega};

  grid-template-columns: 1fr 1fr;

  ${theme.media.mega} {
    grid-template-columns: repeat(3, 1fr);
  }

  ${theme.media.giga} {
    grid-template-columns: repeat(4, 1fr);
  }
`

export const Wrapper = styled('div')(wrapperStyles)
