import styled from '@emotion/styled'
import { css } from '@emotion/react'

const assetsPath = window && window.env.assetHost

const backgroundStyles = ({ theme }) => css`
  label: background;

  background: ${theme.colors.background};

  ${theme.media.giga} {
    padding-top: ${theme.spacings.exa};
    max-height: 1406px;
    background: ${theme.colors.n100};
    background-image: url(${assetsPath}assets/images/affiliates/banner-hero-background.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 76%;
  }
`

export const Background = styled('div')(backgroundStyles)
