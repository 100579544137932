import PropTypes from 'prop-types'

const Tags = ({ tags }) => (
  <div className="assisted-sale-badges">
    { tags.map((tag, index) => (
      <span key={`${tag}-${index}`} className="assisted-sale-badge">
        {tag}
      </span>
    ))}
  </div>
)

Tags.propTypes = {
  tags: PropTypes.arrayOf(
    PropTypes.string
  )
}

export default Tags
