import { css } from '@emotion/react'
import styled from '@emotion/styled'

const wrapperSpacingStyles = ({ theme }) => css`
  label: wrapper-spacing;

  padding: 0 ${theme.spacings.byte};
`

export const WrapperSpacing = styled('div')(wrapperSpacingStyles)
