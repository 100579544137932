import { css } from '@emotion/react'
import styled from '@emotion/styled'

const headerStyles = ({ theme }) => css`
  label: header;

  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: ${theme.spacings.byte};
  align-items: flex-start;

  margin-bottom: ${theme.spacings.mega};
`

const titleWrapperStyles = ({ theme }) => css`
  label: title__wrapper;

  padding-top: ${theme.spacings.bit};
`

const titleStyles = ({ theme }) => css`
  label: header__title;

  color: ${theme.colors.n900};
  font-size: ${theme.typography.headings.mega.fontSize};
  line-height: ${theme.typography.headings.mega.lineHeight};
  font-weight: ${theme.font.weight.semibold};

  margin-bottom: ${theme.spacings.byte};

  ${theme.media.mega} {
    font-size: ${theme.typography.headings.giga.fontSize};
    line-height: ${theme.typography.headings.giga.lineHeight};
  }
`

const subtitleStyles = ({ theme }) => css`
  label: header__subtitle;

  color: ${theme.colors.n600};
  font-size: ${theme.typography.text.kilo.fontSize};
  line-height: ${theme.typography.text.kilo.lineHeight};
`

const alertMessageWrapperStyles = ({ theme }) => css`
  label: header__alert-message;

  margin-bottom: ${theme.spacings.kilo};
`

const linkStyles = ({ theme }) => css`
  label: alert-message__link;

  color: ${theme.colors.p600};
  text-decoration: underline;
  font-weight: ${theme.font.weight.semibold};

  &:hover {
    color: ${theme.colors.p800};
  }
`

const productNameStyles = ({ theme }) => css`
  label: header__product-name;

  margin-bottom: ${theme.spacings.byte};
`

export const Header = styled('div')(headerStyles)
export const Title = styled('div')(titleStyles)
export const TitleWrapper = styled('div')(titleWrapperStyles)
export const Subtitle = styled('div')(subtitleStyles)
export const AlertMessaWrapper = styled('div')(alertMessageWrapperStyles)
export const Link = styled('a')(linkStyles)
export const ProductName = styled('div')(productNameStyles)
