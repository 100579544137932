import { AppLayout } from 'user/components'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'

import { ROUTES } from './constants'

const Routes = () => {
  return (
    <BrowserRouter basename="/celebre">
      <AppLayout>
        <Switch>
          {ROUTES.map(({ path, component }) => (
            <Route exact key={path} path={path} component={component} />
          ))}
          <Route path="*">
            <Redirect to="/" />
          </Route>
        </Switch>
      </AppLayout>
    </BrowserRouter>
  )
}

export default Routes
