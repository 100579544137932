import { useState } from 'react'

const SEARCH_HISTORY_LIMIT = 4

const storeSearchHistories = histories => {
  localStorage.setItem('@search:history', JSON.stringify(histories))
}

export const useSearchHistory = () => {
  const [history, setHistory] = useState(
    JSON.parse(localStorage.getItem('@search:history')) || []
  )

  const storeSearchTerm = searchTerm => {
    let newHistory = [...history]
    const hasAlreadyBeenSearched = newHistory.find(
      ({ term }) => term === searchTerm.toLowerCase()
    )

    if (hasAlreadyBeenSearched) {
      newHistory = newHistory.filter(
        ({ term }) => term !== searchTerm.toLowerCase()
      )

      newHistory.push(hasAlreadyBeenSearched)
      storeSearchHistories(newHistory)
      setHistory(newHistory)
      return
    }

    if (history.length >= SEARCH_HISTORY_LIMIT) {
      newHistory = [...newHistory.slice(1)]
    }

    newHistory = [
      ...newHistory,
      {
        searchPath: `/search?term=${searchTerm}&searchTerm=${searchTerm}&searchType=default`,
        term: searchTerm.toLowerCase()
      }
    ]

    storeSearchHistories(newHistory)
    setHistory(newHistory)
  }

  const searchHistory = history.slice(0).reverse()

  return { searchHistory, storeSearchTerm }
}
