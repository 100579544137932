import { Fragment, ReactElement } from 'react'
import { Icon } from '@leroy-merlin-br/backyard-react'
import { Checkmark } from '@backyard-ui/icons'
import { Clock, Cross } from '@leroy-merlin-br/backyard-icons'

import * as S from './styled'
import { Item } from './types/item'
import { ItemStatus } from './types/item-status'

interface StepperProps {
  items: Item[]
}

export default function Stepper ({ items }: StepperProps) {
  const renderCircle = (status: ItemStatus) =>
    ((
      {
        pending: (
          <S.CirclePending data-testid="stepper-circle-pending">
            <Clock viewBox="-1 -2 22 24" />
          </S.CirclePending>
        ),
        inProgress: (
          <S.CircleInProgress data-testid="stepper-circle-in-progress">
            <Clock viewBox="-1 -2 22 24" />
          </S.CircleInProgress>
        ),
        done: (
          <S.CircleDone data-testid="stepper-circle-done">
            <Icon as={Checkmark} />
          </S.CircleDone>
        ),
        block: (
          <S.CircleBlock data-testid="stepper-circle-block">
            <Cross />
          </S.CircleBlock>
        )
      } as { [key in ItemStatus]: ReactElement }
    )[status])

  const renderLine = (status: ItemStatus) =>
    ((
      {
        pending: <S.LinePending data-testid="stepper-line-pending" />,
        inProgress: <S.LinePending data-testid="stepper-line-in-progress" />,
        done: <S.LineDone data-testid="stepper-line-done" />,
        block: <S.LinePending data-testid="stepper-line-block" />
      } as { [key in ItemStatus]: ReactElement }
    )[status] as ReactElement)

  return (
    <S.Wrapper>
      {items.map((item, i) => (
        <Fragment key={'timeline' + i}>
          <S.ItemWrapper>
            {renderCircle(item.status)}
            <S.ItemLabel>{item.label}</S.ItemLabel>
          </S.ItemWrapper>
          {i < items.length - 1 && renderLine(item.status)}
        </Fragment>
      ))}
    </S.Wrapper>
  )
}
