import { Inline, Stack } from '@leroy-merlin-br/backyard-react'

import { Shell } from 'shared/components/Shell'

import * as S from './styled'

const PopoverLoading = () => (
  <>
    <S.Header>
      <Inline space="bit" alignY="center" alignX="center">
        <Shell width={44} height={44} borderRadius="circle" />
        <Shell width={100} height={28} borderRadius="kilo" />
      </Inline>
    </S.Header>
    <S.ShellWrapper>
      <Stack>
        <Shell width={130} height={28} borderRadius="kilo" />
        <Shell width={140} height={28} borderRadius="kilo" />
        <Shell width={120} height={28} borderRadius="kilo" />
        <Shell width={110} height={28} borderRadius="kilo" />
        <Shell width={80} height={28} borderRadius="kilo" />
      </Stack>
    </S.ShellWrapper>
  </>
)

export default PopoverLoading
