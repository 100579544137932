import styled from '@emotion/styled'
import { css } from '@emotion/react'

import {
  StyleIsActiveProps,
  StyleProps
} from 'lmcv/components/CouponModal/types'

const wrapperStyles = ({ theme }: StyleProps) => css`
  label: wrapper;

  padding-inline: ${theme.spacings.giga};
  padding-bottom: ${theme.spacings.giga};
`

const wrapperActiveStyles = ({ theme, isActive }: StyleIsActiveProps) =>
  isActive &&
  css`
    label: wrapper--active;
    padding: ${theme.spacings.giga};

    background: rgb(255,255,255,0.1);

  `

const activeLabelStyles = ({ theme }: StyleProps) => css`
  label: active-label;

  display: flex;
  align-items: center;
  justify-content: space-around;

  padding-block: ${theme.spacings.byte};

`

const activeStatusStyles = ({ theme }: StyleProps) => css`
  label: active-status;

  display: flex;
  align-items: center;
  column-gap: ${theme.spacings.kilo};

  & > svg {
    color: ${theme.colors.white};
  }
`
const buttonLabelStyles = ({ theme }: StyleProps) => css`
  label: button-label;

  margin-bottom: ${theme.spacings.byte};
`

const remainingTextStyles = ({ theme }: StyleProps) => css`
  label: remaining-text;

  margin-top: ${theme.spacings.kilo};

  color: ${theme.colors.n400};
`

export const ButtonLabel = styled('div')(buttonLabelStyles)
export const ActiveLabel = styled('div')(activeLabelStyles)
export const ActiveStatus = styled('div')(activeStatusStyles)
export const RemainingText = styled('div')(remainingTextStyles)
export const Wrapper = styled('div')(wrapperStyles, wrapperActiveStyles)
