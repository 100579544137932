import * as userResourcesRepository from 'scripts/api/user'

class Profile {
  constructor () {
    this.responseData = null
    this.fetchPromise = null
  }

  async isLoggedIn () {
    const body = await this.get()

    return body && body.email && body.email.length > 0
  }

  async get () {
    if (this.responseData) {
      return this.responseData
    }

    if (this.fetchPromise) {
      return this.fetchPromise
    }

    return this.fetch()
  }

  async fetch () {
    this.responseData = null

    this.fetchPromise = userResourcesRepository.get()
      .catch((error) => {
        console.error(error)

        return { data: {} }
      })
      .finally(() => {
        this.fetchPromise = null
      }).then(({ data }) => {
        this.responseData = data

        return this.responseData
      })

    return this.fetchPromise
  }
}

export default new Profile()

export { Profile as Component }
