import { Fragment, useState } from 'react'
import { useForm } from 'react-hook-form'

import * as relationship from 'lmcv/services/relationship'

import WeeksCount from './steps/WeeksCount'
import ProjectName from './steps/ProjectName'
import InviteCustomer from './steps/InviteCustomer'

const DEFAULT_CUSTOMER_DATA = {
  name: '',
  projectName: '',
  projectWeeks: '0'
}

const DEFAULT_INVITE_DATA = 'https://leroymerlin.com.br/fidelidade'

const WizardForm = ({ prevStep, nextStep, step, closeDrawer }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [hasError, setHasError] = useState(false)

  const [customerData, setCustomerData] = useState(DEFAULT_CUSTOMER_DATA)
  const [inviteData, setInviteData] = useState(DEFAULT_INVITE_DATA)

  const {
    formState: { errors },
    handleSubmit,
    reset,
    register
  } = useForm({ mode: 'onChange' })

  const { name, projectName, projectWeeks } = customerData

  const roundValue = value => {
    if (value <= 0 || value === '') {
      return '0'
    }

    return `${Math.round(parseFloat(value))}`
  }

  const onCustomerChange = ({ target: { name, value } }) => {
    const finalValue = name === 'projectWeeks' ? roundValue(value) : value

    setCustomerData({
      ...customerData,
      [name]: finalValue
    })
  }

  const onCustomerDataSubmit = async () => {
    setIsLoading(true)

    const payload = {
      customerName: name,
      weekCount: +projectWeeks,
      projectName: projectName
    }

    try {
      const { inviteUrl } = await relationship.createProject(payload)
      setInviteData(inviteUrl)
      nextStep()
    } catch (error) {
      setHasError(true)
    } finally {
      setIsLoading(false)
    }
  }

  const stepsCommonProps = {
    step,
    errors,
    register,
    customerData
  }

  return (
    <Fragment>
      <form
        onChange={onCustomerChange}
        onSubmit={handleSubmit(onCustomerDataSubmit)}
      >
        <ProjectName
          {...stepsCommonProps}
          nextStep={() => {
            nextStep()
            reset(customerData)
          }}
        />
        <WeeksCount
          {...stepsCommonProps}
          hasError={hasError}
          isLoading={isLoading}
          prevStep={() => {
            prevStep()
            reset(customerData)
          }}
        />
      </form>

      <InviteCustomer
        step={step}
        inviteUrl={inviteData}
        customerData={customerData}
        closeDrawer={closeDrawer}
        onCustomerChange={onCustomerChange}
      />
    </Fragment>
  )
}

export default WizardForm
