import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { styleHelpers } from '@leroy-merlin-br/backyard-react'

const baseStyles = ({ theme }) => css`
  label: breadcrumb-bar;

  display: flex;
  align-items: center;
  justify-content: space-between;

  background-color: ${theme.colors.background};

  position: sticky;
  top: 0;

  transition: 0.32s ease-in-out;

  z-index: 11;

  ${theme.media.giga} {
    background-color: transparent;
    position: relative;
    justify-content: center;
    z-index: 1;
  }
`

export const BreadcrumbBar = styled('div')(baseStyles)

const wrapperStyles = ({ theme }) => css`
  label: breadcrum-bar__wrapper;

  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: ${theme.spacings.mega};
  align-items: center;

  padding: ${theme.spacings.kilo} 0px;
`

export const Wrapper = styled('div')(wrapperStyles)

const wrapperMobileStyles = ({ theme }) => css`
  label: breadcrum-bar__wrapper-mobile-app;

  display: grid;
  align-items: center;

  padding: ${theme.spacings.giga} 0px;
`
export const WrapperMobile = styled('div')(wrapperMobileStyles)

const filterButtonWrapperStyles = ({ theme }) => css`
  label: filter-button-wrapper;

  background-color: ${theme.colors.white};
  ${styleHelpers.shadowDouble(theme)};

  border-radius: ${theme.borderRadius.giga};

  button {
    font-weight: unset
  }
`

export const FilterButtonWrapper = styled('div')(filterButtonWrapperStyles)
