export const Loyalty = () => (
  <svg
    width="18"
    height="16"
    viewBox="0 0 18 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.4853 15.9982C14.8022 15.9986 15.1177 15.9555 15.4229 15.8701C16.07 15.6739 16.4687 15.3321 16.7478 14.7197C17.0771 13.9981 17.1537 13.13 17.1537 12.3332V11.0253C17.1537 9.91279 17.22 8.78176 17.1654 7.67192C17.1477 7.28163 17.047 6.8996 16.8701 6.55126C16.7261 6.27449 16.539 6.02241 16.3158 5.80445C16.0435 5.5352 15.756 5.28165 15.4549 5.04501L9.12103 0.180388C8.97152 0.0651183 8.78849 0.0017832 8.59971 0H8.58528C8.39663 0.00174949 8.21373 0.0650935 8.06441 0.180388L1.73233 5.04682C1.43107 5.28345 1.14348 5.537 0.870975 5.80625C0.648055 6.02434 0.461137 6.27641 0.317184 6.55306C0.140258 6.90142 0.0394168 7.28342 0.0213476 7.67372C-0.0327689 8.78356 0.0330733 9.91459 0.0330733 11.0271V12.335C0.0330733 13.1318 0.108385 13.9995 0.438947 14.7215C0.717647 15.3339 1.1154 15.6758 1.76345 15.8719C2.0688 15.9573 2.38441 16.0004 2.70147 16L14.4853 15.9982Z"
      fill="url(#paint0_linear_5923_1030)"
    />
    <path
      d="M9.63672 7.10804L11.6354 5.10889L13.6346 7.10804H9.63672Z"
      fill="#78BE20"
    />
    <path
      d="M12.9364 11.9389C12.7258 12.4264 12.3271 12.7479 11.7282 12.7479C10.9525 12.7479 10.498 12.0052 10.498 10.8638C10.498 9.72237 10.9525 8.9697 11.7282 8.9697C12.3046 8.9697 12.6703 9.34626 12.8367 9.83421L14.154 9.45585C13.9988 8.61343 13.1343 7.7381 11.7819 7.7381C10.2414 7.7381 9.05531 8.86868 9.05531 10.8638C9.05531 12.9143 10.2414 13.9782 11.7819 13.9782C12.9346 13.9782 13.9321 13.2246 14.2095 12.1937L12.9364 11.9389ZM7.45211 7.88015H8.85958L6.65434 13.833H5.10254L3.29506 8.95707H2.51172V7.88196H4.3377L5.90122 12.3709L7.45211 7.88015Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_5923_1030"
        x1="-1.05766"
        y1="-0.738462"
        x2="15.9012"
        y2="17.2146"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9A21AA" />
        <stop offset="1" stopColor="#7808CF" />
      </linearGradient>
    </defs>
  </svg>
)
