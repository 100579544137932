import { Button, Stack, useBreakpoint } from '@leroy-merlin-br/backyard-react'

import { SupplierStatus } from 'scripts/react-components/lmcv-pro/pages/CashbackPage/types/supplier-status'
import { useCashbackContext } from 'scripts/react-components/lmcv-pro/pages/CashbackPage/contexts/CashbackContext'

import { Item } from 'lmcv/pages/CashbackPage/components/Stepper/types/item'
import { Stepper } from 'lmcv/pages/CashbackPage/components/Stepper'
import { ItemStatus } from 'lmcv/pages/CashbackPage/components/Stepper/types/item-status'

import * as S from './styled'

type StatusRules = {
  [key in SupplierStatus]: [ItemStatus, ItemStatus, ItemStatus]
}

const statusRules: StatusRules = {
  PENDING_REGISTRATION: ['pending', 'pending', 'pending'],
  WAITING_DOCUMENT_ANALYSIS: ['done', 'inProgress', 'pending'],
  DOCUMENT_UNAPPROVED: ['done', 'done', 'block'],
  DOCUMENT_APPROVED: ['done', 'done', 'done'],
  UNKNOWN: ['pending', 'pending', 'pending']
}

export default function Content () {
  const {
    supplierStatus = 'PENDING_REGISTRATION',
    setSupplierStatus,
    supplier
  } = useCashbackContext()

  const { breakpoint } = useBreakpoint()
  const isMobile = ['untilKilo', 'kilo'].includes(breakpoint as any)

  const itemsStatus = statusRules[supplierStatus]

  const stepperItems: Item[] = [
    {
      label: isMobile ? 'Cadastro' : 'Cadastro do Arquiteto',
      status: itemsStatus[0]
    },
    {
      label: isMobile ? 'Análise' : 'Análise de Documentação',
      status: itemsStatus[1]
    },
    {
      label: isMobile ? 'Aprovação' : 'Aprovação de Documentação',
      status: itemsStatus[2]
    }
  ]

  const showText = supplierStatus !== 'DOCUMENT_APPROVED'
  const showButtonEmitNF = supplierStatus === 'DOCUMENT_APPROVED'
  const showButtonResendRegistration = supplierStatus === 'DOCUMENT_UNAPPROVED'

  const message =
    supplierStatus === 'DOCUMENT_UNAPPROVED' && supplier?.status.message
      ? supplier?.status.message
      : 'Seu cadastro está sendo analisado pelo nosso time e assim que aprovado você poderá resgatar seu cashback em conta corrente.'

  return (
    <Stack space="giga">
      <Stepper items={Object.values(stepperItems)} />

      <Stack space="peta" alignX="center">
        {showText && (
          <S.Text data-testid="text-message">
            <p>{message}</p>
          </S.Text>
        )}

        {showButtonEmitNF && (
          <Button
            size="kilo"
            onClick={() => location.reload()}
          >
            Solicite seu cashback
          </Button>
        )}

        {showButtonResendRegistration && (
          <Button
            size="kilo"
            variant="outline"
            data-testid="button-resend-registration"
            onClick={() => setSupplierStatus('PENDING_REGISTRATION')}
          >
            Reenviar cadastro
          </Button>
        )}
      </Stack>
    </Stack>
  )
}
