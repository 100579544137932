import { useEffect, useState } from 'react'
import { datadogRum } from '@datadog/browser-rum'

import * as newTailRepository from 'scripts/api/new-tail'

const useBannersAds = props => {
  const { context, userId, sessionId, productId, categoryName, size, term } =
    props

  const [result, setResult] = useState({
    fullBanner: null
  })

  const [isFetching, setIsFetching] = useState(false)

  useEffect(() => {
    if (Boolean(context) && Boolean(sessionId) && Boolean(size)) {
      setIsFetching(true)
      ;(async () => {
        try {
          const {
            data: { fullBanner }
          } = await newTailRepository.getBannersAds({
            context,
            productId,
            categoryName,
            userId,
            sessionId,
            size,
            term
          })

          setResult({
            fullBanner: fullBanner[0]
          })
        } catch (e) {
          datadogRum.addError(e)
        } finally {
          setIsFetching(false)
        }
      })()
    }
  }, [context, productId, categoryName, userId, sessionId, size, term])

  return { result, isFetching }
}

export default useBannersAds
