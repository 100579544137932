import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { Drawer } from 'lmcv/components'
import {
  Button,
  Stack,
  Text,
  TextField,
  toast
} from '@leroy-merlin-br/backyard-react'
import * as S from './styled'
import { useRedeemContext } from '../../../../contexts/RedeemContext'
import formatPriceToBRL from '../../../../../../../../../utils/formatPriceToBRL'
import { Image } from '../../../../../../../../components/DocumentPro/components/Image'
import { createInvoiceService } from '../../../../../../services/invoice.service'
import { AxiosError } from 'axios'

interface InvoiceUploadProps {
  uploadInvoiceIsVisible: boolean
  handleClose: () => void
}

export const INVOICE_SIZE_MAX_MB = 4
export const INVOICE_ALLOWED_FORMATS = ['jpg', 'jpeg', 'png', 'pdf']

const formSchema = z.object({
  invoiceFile: z
    .instanceof(File, { message: 'NF é obrigatória' })
    .refine((file) => file != null, 'NF é obrigatória')
    .refine(
      (file) =>
        INVOICE_ALLOWED_FORMATS.includes(
          file.name.split('.').pop()?.toLowerCase() || ''
        ),
      `Formato deve ser um dos seguintes: ${INVOICE_ALLOWED_FORMATS.join(', ')}`
    )
    .refine(
      (file) => file.size <= INVOICE_SIZE_MAX_MB * 1024 * 1024,
      `Tamanho deve ser de no máximo ${INVOICE_SIZE_MAX_MB}MB`
    )
})

export type FormSchema = z.infer<typeof formSchema>

export default function InvoiceUpload({
  uploadInvoiceIsVisible,
  handleClose
}: InvoiceUploadProps) {
  const { cashback, fetchCashback, fetchInvoiceStatus } = useRedeemContext()

  const invoiceService = createInvoiceService()

  const {
    setValue,
    watch,
    formState: { errors },
    handleSubmit
  } = useForm<FormSchema>({
    resolver: zodResolver(formSchema)
  })

  const onSubmit = (data: FormSchema) => {
    if (Object.values(errors).length) return

    invoiceService
      .send(data.invoiceFile)
      .then(() => {
        toast.primary('Enviado com sucesso', {
          variant: 'solid'
        })
        handleClose()
        window.location.reload()
      })
      .catch((error: AxiosError) => {
        const errors: { code: string; message: string }[] | undefined =
          error.response?.data?.errors?.length

        if (errors?.length) {
          errors.forEach(({ message }) =>
            toast.critical(message, {
              variant: 'solid'
            })
          )
        } else {
          toast.critical('Erro ao enviar NF. Tente mais tarde.', {
            variant: 'solid'
          })
        }
      })
  }

  return (
    <Drawer
      dataCy="cashback-send-invoice"
      visible={uploadInvoiceIsVisible}
      title="Envio de Nota Fiscal"
      onClose={handleClose}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack space="mega">
          <TextField
            label="Valor"
            value={formatPriceToBRL(cashback?.cashback)}
            disabled
          />

          <Image
            label="Nota Fiscal"
            watch={watch}
            errors={errors}
            setValue={setValue}
            formFieldName="invoiceFile"
            setValueAsFile
            accept={INVOICE_ALLOWED_FORMATS.map((f) => `.${f}`).join(', ')}
          />

          <Stack space="bit">
            <Text noMargin size="kilo" color="n600">
              Prazo para pagamento
            </Text>
            <Text noMargin isBold>
              15 dias corridos a partir da data de emissão da nota.
            </Text>
          </Stack>

          <Text noMargin size="kilo" color="n600">
            Caso seja identificado algum erro na NFS-e enviada, será possível
            reenviar até o dia 25 do respectivo mês.
          </Text>

          <S.ActionButtons>
            <Button
              size="kilo"
              variant="outline"
              type="button"
              onClick={handleClose}
            >
              Enviar depois
            </Button>
            <Button size="kilo" type="submit">
              Enviar nota fiscal
            </Button>
          </S.ActionButtons>
        </Stack>
      </form>
    </Drawer>
  )
}
