import styled from '@emotion/styled'
import { css } from '@emotion/react'

import type { Theme } from 'scripts/react-components/themes/Theme'

const wrapperModalStyles = ({ theme }: Theme) => css`
  label: modal-app__wrapper;

  display: grid;
  place-items: center;

  width: 100%;
  min-height: 220px;
  max-width: 70%;
  top: 50%;
  left: 50%;
  position: fixed;
  transform: translate(-50%, -50%);

  background: ${theme.colors.white};
  border-radius: ${theme.borderRadius.giga};
  align-items: center;
  padding: ${theme.spacings.mega};
`
const titleStyles = ({ theme }: Theme) => css`
  label: modal-app__text-title;
  color: var(--neutral-900, #222020);
  text-align: center;
  font-family: Leroy Merlin Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
`
const textBodyStyles = ({ theme }: Theme) => css`
  label: modal-app__text-body;
  color: var(--neutral-500, #887f87);
  text-align: center;
  font-family: Leroy Merlin Sans;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
`

const wrapperStyles = ({ theme }: Theme) => css`
  label: modal-app__wrapper-body;
  padding: ${theme.spacings.kilo};
`

const buttonLater = ({ theme }: Theme) => css`
  text-decoration: underline;
  padding-bottom: 8px;
`
const iconBody = ({ theme }: Theme) => css`
  padding-top: 8px;
  padding-bottom: 24px;
`
export const WrapperModal = styled('div')(wrapperModalStyles)
export const Wrapper = styled('div')(wrapperStyles)
export const Title = styled('label')(titleStyles)
export const TextBody = styled('div')(textBodyStyles)
export const IconBody = styled('div')(iconBody)
export const ButtonLater = styled('button')(buttonLater)
