import styled from '@emotion/styled'
import { css } from '@emotion/react'

const sectionStyles = ({ theme }) => css`
  label: cta;

  padding: ${theme.spacings.peta} ${theme.spacings.giga} 80px;
  border-radius: 27px;

  ${theme.media.giga} {
    background: ${theme.colors.g500};

    padding: ${theme.spacings.peta} 75px 0;
    margin-bottom: -${theme.spacings.zetta};
  }
`

const ctaImageStyles = () => css`
  label: cta__image;

  margin-top: -25px;
`

const titleWrapperStyles = ({ theme }) => css`
  label: cta__title-wrapper;

  margin-top: ${theme.spacings.tera};

  ${theme.media.giga} {
    margin-top: 0;
  }

  h1 {
    font-weight: ${theme.font.weight.regular};
  }
`

const textWrapperStyles = () => css`
  label: cta__text-wrapper;

  max-width: 510px;
`

const imageWrapperStyles = ({ theme }) => css`
  label: cta__image-wrapper;

  display: flex;
  justify-content: center;

  margin-top: ${theme.spacings.peta};

  ${theme.media.giga} {
    margin-top: 0;
  }
`

export const Section = styled('section')(sectionStyles)
export const CtaImage = styled('img')(ctaImageStyles)
export const TitleWrapper = styled('div')(titleWrapperStyles)
export const TextWrapper = styled('div')(textWrapperStyles)
export const ImageWrapper = styled('div')(imageWrapperStyles)
