import { Customer } from 'lmcv/pages/Customer'
import { Faq } from 'lmcv/pages/Faq'
import { Home } from 'lmcv/pages/Home'
import { AdvantagesPage } from 'lmcv/pages/AdvantagesPage'
import { JoinProgram } from 'lmcv/pages/JoinProgram'
import { MyPointsPage } from 'lmcv/pages/MyPointsPage'
import { Professional } from 'lmcv/pages/Professional'
import { CustomersPage } from 'lmcv/pages/Customers'
import { ExchangePage } from 'lmcv/pages/ExchangePage'
import { CashbackPage } from 'lmcv/pages/CashbackPage'
import CashbackProvider from 'lmcv/pages/CashbackPage/contexts/CashbackContext'

export const getRoutes = ({ cashbackEnabled }) => {
  const routes = [
    {
      path: '/faca-parte',
      key: 'OUTSIDE_OF_PROGRAM',
      exact: true,
      isPrivate: false,
      component: JoinProgram
    },
    {
      path: '/',
      key: 'HOME',
      exact: true,
      isPrivate: true,
      component: Home
    },
    {
      path: '/meus-pontos',
      key: 'MY_POINTS',
      exact: true,
      isPrivate: true,
      component: MyPointsPage
    },
    {
      path: '/minhas-vantagens',
      key: 'ADVANTAGES_PAGE',
      exact: true,
      isPrivate: true,
      component: AdvantagesPage
    },
    {
      path: '/troque',
      key: 'MONEY_PAGE',
      exact: true,
      isPrivate: true,
      component: (props) => <ExchangePage {...props} voucherType="money" />
    },
    {
      path: '/clientes',
      key: 'CUSTOMERS_PAGE',
      exact: true,
      isPrivate: true,
      component: CustomersPage
    },
    {
      path: '/cliente/:id',
      key: 'CUSTOMER_PAGE',
      exact: true,
      isPrivate: true,
      component: Customer
    },
    {
      path: '/duvidas',
      key: 'FAQ',
      exact: true,
      isPrivate: true,
      component: Faq
    },
    {
      path: '/profissional/:id',
      key: 'PROFESSIONAL_PAGE',
      exact: true,
      isPrivate: true,
      component: Professional
    }
  ]

  if (cashbackEnabled) {
    routes.push({
      path: '/cashback',
      key: 'CASHBACK',
      exact: true,
      isPrivate: true,
      component: () => (
        <CashbackProvider>
          <CashbackPage />
        </CashbackProvider>
      )
    })
  }

  return routes
}
