export const ZOOM_DIMENSIONS = {
  widht: 1800,
  height: 1800
}

export const ZOOM_OPTIONS = {
  container: '.pswp',
  options: {
    maxSpreadZoom: 1,
    allowPanToNext: false,
    clickToCloseNonZoomable: false,
    history: false
  }
}
