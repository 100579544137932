import { Heading } from '@leroy-merlin-br/backyard-react'

import formatPriceToBRL from 'utils/formatPriceToBRL'

import * as S from './styled'

const availablePaymentMethods = {
  pix: 'PIX',
  ticket: 'Boleto'
}

const Header = ({ totalPrice, typePaymentMethod }) => {
  if (!totalPrice || !typePaymentMethod) return

  return (
    <S.Wrapper>
      <Heading as="h1" size="giga" color="white" align="center">
        Pague {formatPriceToBRL(totalPrice)} no {availablePaymentMethods[typePaymentMethod]}{' '}
        para garantir seu pedido
      </Heading>
    </S.Wrapper>
  )
}

export default Header
