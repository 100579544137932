import PropTypes from 'prop-types'

import { StaticMosaicColumn } from './StaticMosaicColumn'

const MosaicImageBuilder = ({ mosaicItems }) =>
  mosaicItems.map((item, index) => StaticMosaicColumn({ ...item, index }))

MosaicImageBuilder.propTypes = {
  mosaicItems: PropTypes.array.isRequired
}

export default MosaicImageBuilder
