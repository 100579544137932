import { Button, Text, TextField, toast } from '@leroy-merlin-br/backyard-react'
import { Popover } from 'user/components'
import { ChangeEvent, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { birthdateErrorMessage } from 'user/utils'

import { NumberField, Shell } from 'shared/components'

import * as validator from 'utils/validators/validators'

import { useMyAccountPersonalDataContext } from '../../context/my-account-personal-data-context'
import { updateProfile } from '../../services'
import * as S from './styled'

type FormValues = {
  name: string
  birthdate: string
}

type PersonalDatacardProps = {
  saveId?: string
  editId?: string
  tooltipId?: string
  title: string
  description: string
  text?: string
  popover?: boolean
  edit?: boolean
  showHide: boolean
  maskFunction?: (value: string) => string
  field: string
  editable: boolean
  setEditable: (value: boolean) => void
  loading: boolean
}

const PersonalDataCardDesktop = ({
  saveId,
  editId,
  tooltipId,
  title,
  description,
  text,
  popover,
  edit,
  showHide,
  maskFunction,
  field,
  editable,
  setEditable,
  loading
}: PersonalDatacardProps) => {
  const { contextData, updateData } = useMyAccountPersonalDataContext()

  const [showMasked, setShowMasked] = useState(showHide)

  const [showNameEdit, setShowNameEdit] = useState(false)

  const [fieldValue, setFieldValue] = useState(description)
  const [previousFieldValue, setPreviousFieldValue] = useState(description)

  const [loadingUpdate, setLoadingUpdate] = useState(false)

  const {
    control,
    formState: { errors, isValid }
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      name: '',
      birthdate: ''
    }
  })

  const handleEditClick = () => {
    if (!editable) {
      return
    }

    setShowNameEdit(true)

    setPreviousFieldValue(fieldValue)

    setEditable(false)
  }

  const handleCancelClick = () => {
    if (loadingUpdate) {
      return
    }

    setShowNameEdit(false)

    setFieldValue(previousFieldValue)

    setEditable(true)
  }

  const togglePassword = () => {
    if (!editable) {
      return
    }

    setShowMasked(!showMasked)
  }

  const handleSave = async () => {
    setLoadingUpdate(true)

    const update = await updateProfile({ [field]: fieldValue })

    setLoadingUpdate(false)

    setShowNameEdit(false)

    setEditable(true)

    if (update.data) {
      updateData({ ...contextData, [field]: fieldValue })

      const title = field === 'name' ? 'Nome atualizado com sucesso!' : 'Data de nascimento atualizada com sucesso!'

      toast.primary(title, {
        variant: 'solid'
      })
    }
  }

  return (
    <S.Wrapper>
      <S.Flex>
        <S.Label>
          {popover ? (
            <Popover
              list={{
                id: tooltipId,
                title: 'Usamos sua data de nascimento para:',
                items: ['Envio de ofertas especiais no seu aniversário.']
              }}
              placement="bottom"
            >
              <Text size="mega" noMargin isBold>
                {title}
              </Text>
            </Popover>
          ) : (
            <Text size="mega" noMargin isBold>
              {title}
            </Text>
          )}
        </S.Label>

        {!showNameEdit && (
          <div>
            {loading ? (
              <Shell borderRadius="kilo" height={24} width={200} />
            ) : (
              <S.TextWrapper>
                <Text noMargin>
                  {showMasked && maskFunction ? maskFunction(fieldValue) : fieldValue}
                </Text>
              </S.TextWrapper>
            )}

            {text && (
              <S.TextWrapper>
                <Text size="kilo" color="n600" noMargin>
                  {text}
                </Text>
              </S.TextWrapper>
            )}
          </div>
        )}
      </S.Flex>

      {!showNameEdit && (
        <S.Flex>
          {edit && (
            loading ? (
              <Shell borderRadius="kilo" height={20} width={40} />
            ) : (
              <a onClick={handleEditClick} data-gtm-element-id={editId}>
                <Text
                  noMargin
                  isBold
                  color={editable ? 'p700' : 'n300'}
                  size="kilo">
                  <span style={{ cursor: editable ? 'pointer' : 'not-allowed' }}>
                    editar
                  </span>
                </Text>
              </a>
            )
          )}

          {showHide && (
            loading ? (
              <Shell borderRadius="kilo" height={20} width={40} />
            ) : (
              <S.ActionShowHideLink onClick={togglePassword}>
                <Text
                  noMargin
                  isBold
                  color={editable ? 'p700' : 'n300'}
                  size="kilo">
                  <span style={{ cursor: editable ? 'pointer' : 'not-allowed' }}>
                    {showMasked ? 'mostrar' : 'ocultar'}
                  </span>
                </Text>
              </S.ActionShowHideLink>
            )
          )}
        </S.Flex>
      )}

      {showNameEdit && (
        <S.EditableData>
          {field === 'name' && (
            <Controller
              name="name"
              rules={{
                required: 'Insira seu nome',
                validate: (value: string) =>
                  validator.isValidName(value) || 'Nome inválido'
              }}
              control={control}
              state={Boolean(errors.name) && 'invalid'}
              hint={errors.name?.message}
              render={({
                field: { onChange, name, ref },
                fieldState: { error }
              }) => (
                <TextField
                  name={name}
                  ref={ref}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    setFieldValue(event.target.value)

                    onChange(event)
                  }}
                  value={fieldValue}
                  state={error ? 'invalid' : undefined}
                  hint={error?.message}
                  size="kilo"
                  disabled={loadingUpdate}
                />
              )}
            />
          )}

          {field === 'birthdate' && (
            <NumberField
              name="birthdate"
              format="##/##/####"
              valueType="formatted"
              style={{ width: '110px' }}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setFieldValue(event.target.value)
              }}
              value={fieldValue}
              rules={{
                required: 'Insira sua data de nascimento',
                validate: (value: string) => birthdateErrorMessage(value)
              }}
              control={control}
              state={Boolean(errors.birthdate) && 'invalid'}
              hint={errors.birthdate?.message}
              size="kilo"
              disabled={loadingUpdate}
            />
          )}

          <S.Flex>
            <S.CancelWrapper onClick={handleCancelClick}>
              <Text size="kilo" color="r600" noMargin isBold>
                Cancelar
              </Text>
            </S.CancelWrapper>

            <S.ButtonWrapper>
              <Button
                data-gtm-element-id={saveId}
                onClick={handleSave}
                isDisabled={!isValid}
                isStretch
                size="kilo"
                isLoading={loadingUpdate}
                type="button"
              >
                Salvar
              </Button>
            </S.ButtonWrapper>
          </S.Flex>
        </S.EditableData>
      )}
    </S.Wrapper>
  )
}

export default PersonalDataCardDesktop
