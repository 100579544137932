import {
  Visible,
  Inline,
  Icon,
  Tooltip,
  Badge
} from '@leroy-merlin-br/backyard-react'
import { InfoCircle } from '@leroy-merlin-br/backyard-icons'

import * as S from './styled'

const FilterTitle = ({ filter = {} }) => {
  const { name = '', description = '', selected = false, options = [] } = filter

  return (
    <Inline alignX="flex-start" alignY="center" space="byte">
      <span>{name}</span>
      {description && (
        <Visible when={['giga', 'tera']}>
          <Tooltip placement="right" content={<span>{description}</span>}>
            <S.DescriptionButton>
              <Icon as={InfoCircle} size="kilo" />
            </S.DescriptionButton>
          </Tooltip>
        </Visible>
      )}
      {selected && (
        <S.BadgeWrapper>
          <Badge variant="subtle" appearance="primary">
            {options.length}
          </Badge>
        </S.BadgeWrapper>
      )}
    </Inline>
  )
}

export default FilterTitle
