import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { reduce } from 'lodash'

import * as S from './styled'
import { MosaicImageBuilder } from './components'
import {
  addDataToMosaicImage,
  combine,
  populatedMosaicArray,
  MAX_MOSAIC_LENGTH
} from './helpers'

const Mosaic = ({ mosaic }) => {
  const [mosaicItems, setMosaicItems] = useState(new Array(8))
  const [mosaicIsBuild, setMosaicBuild] = useState(false)

  useEffect(() => {
    if (mosaic.length < MAX_MOSAIC_LENGTH) {
      populatedMosaicArray(mosaic)
    }

    const pairOfMosaicImages = reduce(mosaic, combine, [])
    const mosaicListItems = addDataToMosaicImage(pairOfMosaicImages)

    setMosaicItems(mosaicListItems)
    setMosaicBuild(true)
  }, [mosaic])

  return (
    mosaicIsBuild && (
      <S.SimpleRow>
        <MosaicImageBuilder mosaicItems={mosaicItems} />
      </S.SimpleRow>
    )
  )
}

Mosaic.propTypes = {
  mosaic: PropTypes.array.isRequired
}

export default Mosaic
