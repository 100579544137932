import { Heading, Inline, Stack, Text } from '@leroy-merlin-br/backyard-react'

import * as S from './styled'

const Step = ({ image, stepLabel, title, subtitle }) => {
  const assetsPath = window && window.env.assetHost

  return (
    <S.Wrapper>
      <Inline space="mega" alignY="flex-start">
        <S.ImageStep
          src={`${assetsPath}assets/images/lmcv/landing-page-2022/${image}`}
          alt={image}
        />

        <Stack space="byte">
          <Stack space="bit">
            <Text size="kilo" color="v200" noMargin>
              {stepLabel}
            </Text>
            <Heading size="mega" color="n900" noMargin>
              {title}
            </Heading>
            <S.TextWrapper>
              <Text size="mega" color="n500" noMargin>
                {subtitle}
              </Text>
            </S.TextWrapper>
          </Stack>
        </Stack>
      </Inline>
    </S.Wrapper>
  )
}

export default Step
