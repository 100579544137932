import { FunctionComponent } from 'react'
import { Stack } from '@backyard-ui/core'

import { Comment } from '../../../../components'
import { Props } from './types'
import { ReviewModal } from '../ReviewModal'

const Item: FunctionComponent<Props> = ({ itemRate }) => (
  <Stack direction="column" align="flex-start" spacing="4">
    <Comment.Head
      author={itemRate.author}
      date={itemRate.date}
      rating={itemRate.rating}
    />

    <Comment.Title title={itemRate.title} />

    <Comment.Description text={itemRate.text} hasEllipsis />

    <ReviewModal itemRate={itemRate} />
  </Stack>
)

export default Item
