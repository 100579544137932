import PropTypes from 'prop-types'

import * as S from './styled'

const StaticMosaicColumn = ({
  images,
  index,
  wrappers
}) => {
  const [top, bottom] = images
  const [TopWrapper, BottomWrapper] = wrappers

  return (
    <S.SimpleColumn key={index}>
      <TopWrapper>
        { !!top &&
          <img
            className="wishlist-mosaic-image"
            src={top}
          />
        }
      </TopWrapper>
      <BottomWrapper>
        { !!bottom &&
          <img
            className="wishlist-mosaic-image"
            src={bottom}
          />
        }
      </BottomWrapper>
    </S.SimpleColumn>
  )
}

StaticMosaicColumn.propTypes = {
  images: PropTypes.array.isRequired,
  index: PropTypes.number.isRequired,
  wrappers: PropTypes.array.isRequired
}

export default StaticMosaicColumn
