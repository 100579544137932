import emitter from 'scripts/utils/emitter'
import * as cartRepository from 'scripts/api/cart'

const tryAddToCartKazaplan = async data => {
  try {
    const response = await cartRepository.putProductsInCartKazaplan(data)

    return response
  } catch {
    emitter.emit('alertModal', {
      title: 'Ops!',
      description:
        'Ocorreu um erro ao adicionar seu produto ao carrinho, tente mais tarde.'
    })

    return { error: true }
  }
}

export default tryAddToCartKazaplan
