import { useLayoutEffect, Children } from 'react'
import { Helmet } from 'react-helmet'

const CustomHelmet = ({ children, force = false }) => {
  const addDataReactHelmetOnMetaTag = metaTagSelector => {
    document
      .querySelector(`meta[name="${metaTagSelector}"]`)
      ?.setAttribute('data-react-helmet', 'true')

    document
      .querySelector(`meta[property="${metaTagSelector}"]`)
      ?.setAttribute('data-react-helmet', 'true')
  }

  const addDataReactHelmetOnLink = linkRel => {
    document
      .querySelector(`link[rel=${linkRel}]`)
      ?.setAttribute('data-react-helmet', 'true')
  }

  useLayoutEffect(() => {
    Children.forEach(children, child => {
      if (child?.type === 'meta') {
        const selector = child.props.name || child.props.property

        addDataReactHelmetOnMetaTag(selector)
      }

      if (child?.type === 'link') {
        if (force || child.props.rel !== 'stylesheet') {
          addDataReactHelmetOnLink(child.props.rel)
        }
      }
    })
  }, [children, force])

  return <Helmet>{children}</Helmet>
}

export default CustomHelmet
