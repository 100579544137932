import { Spinner } from '@leroy-merlin-br/backyard-react'

import * as S from './styled'

const InputLoading = ({ children, isLoading }) => (
  <S.WrapperField>
    {children}

    {isLoading && (
      <S.WrapperLoading data-testid="input-loading">
        <Spinner size="giga" appearance="info" />
      </S.WrapperLoading>
    )}
  </S.WrapperField>
)

export default InputLoading
