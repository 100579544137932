import PropTypes from 'prop-types'

export default {
  thumbUrl: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  externalId: PropTypes.number.isRequired,
  shopName: PropTypes.string.isRequired,
  storeName: PropTypes.string,
  packaging: PropTypes.number.isRequired,
  unit: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
  price: PropTypes.shape({
    from: PropTypes.number,
    to: PropTypes.number.isRequired
  }).isRequired
}
