import { Text } from '@backyard-ui/core'

import useLoyaltyPoints from './hooks/useLoyaltyPoints'
import styles from './LoyaltyPoints.styles'

const classNames = styles()

const LoyaltyPoints: React.FC = () => {
  const { userPoints } = useLoyaltyPoints()

  const renderContent = () => {
    if (userPoints !== null && userPoints >= 1000) {
      return (
        <Text size="md" color="purple-600">
          Você tem cupons de descontos e cashback disponíveis.{' '}
          <strong>
            <a href="/fidelidade/troque">Ative agora!</a>
          </strong>
        </Text>
      )
    } else if (userPoints !== null && userPoints < 1000) {
      return (
        <Text size="md" color="purple-600">
          Você{' '}
          {userPoints === 0 ? (
            'ainda não tem pontos'
          ) : (
            <>
              tem <strong>{userPoints} pontos</strong>
            </>
          )}
          . Acumule pelo menos <strong>1.000 pontos</strong> para obter cashback
          e abater o valor de suas compras futuras!
        </Text>
      )
    }

    if (userPoints === null) {
      return (
        <Text size="md" color="purple-600">
          <a href="/cadastre-se">
            <strong>Cadastre-se</strong>
          </a>{' '}
          ou
          <a href="/login">
            {' '}
            <strong>faça o login</strong>
          </a>{' '}
          e participe do Programa de Fidelidade para acumular pontos e ganhar
          cashback!
        </Text>
      )
    }
  }

  return (
    <div className={classNames.base()}>
      <div className={classNames.container()}>{renderContent()}</div>
    </div>
  )
}
export default LoyaltyPoints
