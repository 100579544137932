import { useCallback } from 'react'

import { useCouponModal } from '../useCouponModal'

export const useFilterVoucherByUrl = (category: string) => {
  const searchParams = new URLSearchParams(window.location.search)
  const voucherId = searchParams.get('voucherId')
  const voucherActive = searchParams.get('voucherActive') === '1'

  const { openModal } = useCouponModal()

  const filterVoucherByUrl = useCallback(() => {
    if (voucherId) {
      const modalType = 'CouponModalCommon'

      openModal(modalType, {
        id: voucherId,
        category: category,
        active: voucherActive
      })
    }
  }, [voucherId, voucherActive, category, openModal])

  return { filterVoucherByUrl }
}
