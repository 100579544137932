import PropTypes from 'prop-types'

import PriceUnavailableMessage from './PriceUnavailableMessage'
import SoldOutProductMessage from './SoldOutProductMessage'

const PriceTag = ({ isSoldOut, price, unit, installments, url, name }) => {
  const { to, from } = price

  const renderPromotional = () => {
    if (from) {
      return (
        <span className="from-price">
          R$ {from.integers},{from.decimals} {unit}
        </span>
      )
    }

    return null
  }

  const renderInstallments = () => {
    if (installments) {
      return (
        <span>
          <strong>{installments.amount}x</strong> de <strong>R$ {installments.value}</strong> s/juros
        </span>
      )
    }

    return <span className="without-installments" />
  }

  if (isSoldOut) {
    return (
      <SoldOutProductMessage />
    )
  }

  if (to) {
    return (
      <a href={url} title={name}>
        <div
          className="price-tag"
        >
          <div className="price-content">
            <span className="price">
              <strong>
                R$ {to.integers},{to.decimals}
              </strong>
            </span>
            <span className="price-unit">
              <strong> {unit}</strong>
            </span>
          </div>

          <div className="installments-content">
            {renderPromotional()}
            {renderInstallments()}
          </div>
        </div>
      </a>
    )
  }

  return <PriceUnavailableMessage />
}

PriceTag.propTypes = {
  price: PropTypes.shape({
    to: PropTypes.shape({
      integers: PropTypes.string.isRequired,
      decimals: PropTypes.string.isRequired
    }),
    from: PropTypes.shape({
      integers: PropTypes.string.isRequired,
      decimals: PropTypes.string.isRequired
    })
  }),
  unit: PropTypes.string.isRequired,
  installments: PropTypes.shape({
    amount: PropTypes.number,
    value: PropTypes.string
  }),
  url: PropTypes.string.isRequired,
  name: PropTypes.string
}

export default PriceTag
