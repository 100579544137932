import { Fragment } from 'react'

import Disclaimer from 'checkout/components/MyOrders/components/Disclaimer'
import {
  PICKUP_IN_STORE,
  PICKUP_IN_STORE_LOCKER,
  STORE_WITHDRAWAL
} from 'checkout/components/MyOrders/components/consts'

const ORDER_STATUS = {
  WAITING_PAYMENT: 'Pedido recebido',
  WAITING_SEPARATION: 'Pedido em separação'
}

export const shouldDisplayPickupMessage = status =>
  [ORDER_STATUS.WAITING_PAYMENT, ORDER_STATUS.WAITING_SEPARATION].includes(
    status
  )

export const isOnlinePickup = shipmentType => [PICKUP_IN_STORE, PICKUP_IN_STORE_LOCKER].includes(shipmentType)

export const isPickup = shipmentType => [PICKUP_IN_STORE, PICKUP_IN_STORE_LOCKER, STORE_WITHDRAWAL].includes(shipmentType)

export const getDriveThruMessage = hasRegionDriveThru =>
  hasRegionDriveThru && (
    <strong>
      Devido ao cenário atual, para a segurança de todas as pessoas, as
      retiradas funcionarão apenas através do Drive-Thru em nossas lojas.
    </strong>
  )

export const getPickUpStatusMessage = ({ currentStep, delayTime = 23 }) => {
  if (currentStep === ORDER_STATUS.WAITING_PAYMENT) {
    return (
      <Fragment>
        <strong>Atenção!</strong> Você poderá ir <strong>à loja</strong> para
        retirar o seu pedido{' '}
        <strong>{delayTime} horas após a confirmação do seu pagamento.</strong>{' '}
        Mas não se preocupe, estamos cuidando de tudo e encaminharemos um novo
        e-mail quando seu pagamento for aprovado.{' '}
        <strong>
          Por favor, lembre-se de considerar o horário de funcionamento da loja
          antes de sair para retirar o seu pedido.
        </strong>
      </Fragment>
    )
  }

  if (currentStep === ORDER_STATUS.WAITING_SEPARATION) {
    return (
      <Fragment>
        <strong>Atenção!</strong> Estamos realizando a{' '}
        <strong>separação</strong> do seu produto e <strong>em {delayTime} horas</strong>{' '}
        você poderá ir à loja para retirá-lo. Você ainda receberá um{' '}
        <strong>e-mail de confirmação quando estiver tudo pronto.</strong>{' '}
        Aguarde só mais um pouquinho.
      </Fragment>
    )
  }

  return null
}

const getCurrentStep = steps =>
  steps.filter(step => step.current)[0]?.description

export const BuilderDisclaimer = ({
  steps,
  type,
  hasRegionDriveThru,
  delayTime
}) => {
  const currentStep = steps && getCurrentStep(steps)

  const disclaimers = isOnlinePickup(type)
    ? [
        getPickUpStatusMessage({ currentStep, delayTime }),
        getDriveThruMessage(hasRegionDriveThru) ?? null
      ]
    : []

  const hasDisclaimers = disclaimers.filter(Boolean).length

  if (!hasDisclaimers) {
    return null
  }

  return <Disclaimer type="warning" disclaimers={disclaimers} />
}
