/**
 * Component responsible to handle lazy-load interactions (using the lazy load component from garden)
 */
import $ from 'jquery'

import LazyLoad from 'garden/src/js/components/lazy-load'

/**
 * Instead of using the default $el.plugin(), the lazyLoad() uses a multiple
 * selection to handle them together, instead of handling them individually.
 *
 * A good approach is to use the component on elements such as wrappers or
 * body itself.
 */
export default ($el) => $el.find('[data-lazy]').lazyload()
