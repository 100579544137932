import {
  Container,
  Row,
  Col,
  Heading,
  Button,
  Stack,
  Text,
  Visible,
  Hidden,
  useBreakpoint,
  Inline
} from '@leroy-merlin-br/backyard-react'

import * as S from './styled'

const Cta = () => {
  const assetsPath = window && window.env.assetHost

  const { breakpoint } = useBreakpoint()
  const isMobile = ['untilKilo', 'kilo'].includes(breakpoint)
  const isPortrait = ['untilKilo', 'kilo', 'mega'].includes(breakpoint)

  return (
    <Container>
      <S.Section>
        <Row>
          <Col size={{ giga: 4 }}>
            <S.ImageWrapper>
              <Visible when={['untilKilo', 'kilo', 'mega']}>
                <Stack space="mega">
                  <S.CtaImage
                    src={`${assetsPath}assets/images/affiliates/layer.png`}
                    alt="Imagem representativa de um personagem utilizando o programa de Afiliados da Leroy Merlin"
                  />
                </Stack>
              </Visible>

              <Hidden when={['untilKilo', 'kilo', 'mega']}>
                <S.CtaImage
                  src={`${assetsPath}assets/images/affiliates/layer.png`}
                  alt="Imagem com QR code para aplicativo da Leroy Merlin"
                />
              </Hidden>
            </S.ImageWrapper>
          </Col>

          <Col size={{ giga: 6, tera: 7 }} offset={{ giga: 2, tera: 1 }}>
            <Stack space="tera">
              <Stack space="kilo">
                <S.TitleWrapper>
                  <Heading noMargin color={isPortrait ? 'n900' : 'white'}>
                    Cadastre-se e comece a lucrar!
                  </Heading>
                </S.TitleWrapper>
                <S.TextWrapper>
                  <Text noMargin color={isPortrait ? 'n700' : 'n900'}>
                    Se você é profissional de engenharia, arquitetura,
                    decoração, design de interiores, profissional de obra,
                    influencer ou outra especialidade do setor, agora você ganha
                    comissões ao indicar produtos da nossa loja online!
                  </Text>
                </S.TextWrapper>
              </Stack>
              <Button
                as="a"
                href="https://leroymerlin.rmaffiliate.com/register"
                isStretch={isMobile}
              >
                Cadastre-se
              </Button>
            </Stack>
          </Col>
        </Row>
      </S.Section>
    </Container>
  )
}

export default Cta
