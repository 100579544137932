import { useInstantSearch } from 'react-instantsearch'
import { Card } from '@backyard-ui/core'
import { type ReactNode } from 'react'

import { RetailMediaBanner as RetailMediaBannerModular } from 'site/Modular/modules/RetailMediaBanner'

function RetailMediaBanner () {
  const { results } = useInstantSearch()

  const term = results?._state.query || ''

  const wrapper = ({ children }: { children: ReactNode }) => {
    return (
      <Card.Root>
        <Card.Body>{children}</Card.Body>
      </Card.Root>
    )
  }

  return (
    <RetailMediaBannerModular
      extra={{
        term,
        noContentPadding: true,
        inline: true,
        hideTitleOnMobile: true
      }}
      context="search"
      wrapper={wrapper}
    />
  )
}

export default RetailMediaBanner
