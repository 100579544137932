import { Fragment } from 'react'
import { Inline, Text } from '@leroy-merlin-br/backyard-react'
import PropTypes from 'prop-types'
import Cookies from 'js-cookie'
import { StarRating } from 'hpdp/components'

import { Ellipsis } from 'shared/components/Ellipsis'

import { Shell } from '../../../Shell'
import * as S from './styled'

const MAX_SHORT_NAME_LENGTH = 61

const ProductInfo = ({
  name,
  shortName,
  id,
  url,
  reviewRating,
  isLoadingReview
}) => {
  const shouldShowEllipsis =
    name.substr(0, MAX_SHORT_NAME_LENGTH) === shortName &&
    name.length > shortName.length

  const shouldShowNewReviewsRating =
    window.env.feature.isNewReviewsSectionEnabled &&
    Cookies.get('new_reviews_ab_testing') === 'true'

  const ReviewRating = () => {
    return isLoadingReview ? (
      <Shell width={'120px'} height={'20px'} borderRadius={'mega'} />
    ) : (
      <Inline space="bit" alignY="center">
        <StarRating color="primary" rating={Number(reviewRating?.avgRating)} />
        <Text as="span" size="kilo">
          ({reviewRating?.totalReviewsCount})
        </Text>
      </Inline>
    )
  }

  return (
    <Fragment>
      <S.Title title={shortName} itemProp="url" href={url}>
        <Ellipsis clamp={3} itemProp="shortName">
          {shouldShowEllipsis ? `${shortName}...` : shortName}
        </Ellipsis>
      </S.Title>
      <S.ProductRating>
        <S.ProductCode>Cód. {id}</S.ProductCode>
        {shouldShowNewReviewsRating ? (
          <ReviewRating />
        ) : (
          <span
            className="product-rating bv-widget-rating"
            data-bv-show="inline_rating"
            data-bv-product-id={id}
            data-bv-redirect-url={url}
          />
        )}
      </S.ProductRating>
    </Fragment>
  )
}

ProductInfo.propTypes = {
  name: PropTypes.string.isRequired,
  shortName: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  url: PropTypes.string.isRequired
}

export default ProductInfo
