import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { Theme } from 'scripts/react-components/themes/Theme'

const wrapperStyles = ({ theme }: Theme) => css`
  label: chatmodal_container-wrapper;

  width: 375px;
  height: 544px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: ${theme.borderRadius.giga} ${theme.borderRadius.giga} 0 0;
  background-color: ${theme.colors.n100};
  box-shadow: 3px 0px 8px 0px rgba(60, 55, 56, 0.1),
    0px 3px 4px 0px rgba(60, 55, 56, 0.1),
    0px 0px 0px 1px rgba(186, 182, 188, 0.1);
  font-size: ${theme.typography.subHeadings.kilo.fontSize};
  line-height: ${theme.typography.subHeadings.mega.lineHeight};
  font-weight: ${theme.font.weight.regular};
`

export const Wrapper = styled('div')(wrapperStyles)
