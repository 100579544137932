import styled from '@emotion/styled'
import { css } from '@emotion/react'

const wrapperStyles = ({ theme }) => css`
  label: gift-icon;

  position: absolute;
  z-index: 3;
  top: -1px;
  right: ${theme.spacings.mega};

  width: 18px;
  height: 26px;
`

export const Wrapper = styled('div')(wrapperStyles)
