import { errorWhenAddingProductToCart } from 'scripts/react-components/checkout/services/data-layer'

import { genericError, mixedCartDelivery, mixedCartStore } from '.'

let eventAction

const treatMixedCartDeliveryError = (err) => {
  const ecommerceFlow = 'Você já escolheu produtos para receber na sua casa. No momento não conseguimos disponibilizar produtos do mesmo pedido em endereços diferentes ;(. Caso queira retirar produtos também na Loja remova os itens deste carrinho ou finalize o pedido. Assim você poderá iniciar um novo pedido para retirá-lo na Loja ;)'
  if (err.response.data.errors[0] === ecommerceFlow) {
    mixedCartDelivery()
    errorWhenAddingProductToCart(eventAction, 'Erro de tipo de entrega delivery-retira')
    return true
  }
}
const treatMixedCartStoreError = (err) => {
  const checkValue = (error) => {
    const escapeRegExp = (string) => string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
    const prefixo = 'Você já escolheu produtos para retirar na Loja '
    const sufixo = '. No momento não conseguimos disponibilizar produtos do mesmo pedido em endereços diferentes ;(. Caso queira receber produtos também na sua casa remova os itens deste carrinho ou finalize o pedido. Assim você poderá iniciar um novo pedido para receber na sua casa ;)'
    return RegExp(escapeRegExp(prefixo) + '(.*)' + escapeRegExp(sufixo)).exec(error)?.[1]
  }

  const store = checkValue(err.response.data.errors[0])
  if (store) {
    mixedCartStore({ store })
    errorWhenAddingProductToCart(eventAction, 'Erro de tipo de entrega retira-delivery')
    return true
  }

  return false
}

const treatGenericError = (err) => {
  genericError(err.response.data.errors[0])
  errorWhenAddingProductToCart(eventAction, 'Outros')
  return true
}

const treatOutOfStock = (err) => {
  const errorDescription = 'Não temos a quantidade de produtos que você precisa. Mas, temos outras opções similares disponíveis para retirada na Loja.'
  if (err.response.data.errors[0] === errorDescription) {
    genericError(err.response.data.errors[0])
    errorWhenAddingProductToCart(eventAction, 'Problema de estoque')
    return true
  }

  return false
}

const treatInsufficientStock = (err) => {
  const checkValue = (error) => {
    const escapeRegExp = (string) => string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
    const prefixo = 'Quantidade insuficiente em estoque para o produto'
    const sufixo = 'Caso queira comprar uma quantidade maior verifique a disponibilidade com o televendas pelo número:'
    return RegExp(escapeRegExp(prefixo) + '(.*)' + escapeRegExp(sufixo)).exec(error)?.[1]
  }

  if (checkValue(err.response.data.errors[0])) {
    genericError(err.response.data.errors[0])
    errorWhenAddingProductToCart(eventAction, 'Problema de estoque')
    return true
  }

  return false
}

const treatWhenAddProductToCart = (err) => {
  const errorDescription = 'Erro adicionar o item ao carrinho.'
  if (err.response.data.errors[0] === errorDescription) {
    genericError(err.response.data.errors[0])
    errorWhenAddingProductToCart(eventAction, 'Erro ao adicionar produto ao carrinho')
    return true
  }

  return false
}

const treatInvalidProductId = (err) => {
  const errorDescription = 'O item com o seguinte LM não foi encontrado:'
  if (err.response.data.errors[0].includes(errorDescription)) {
    genericError(err.response.data.errors[0])
    errorWhenAddingProductToCart(eventAction, 'Produto inexistente')
    return true
  }

  return false
}

const treatError = (err, typeSale) => {
  eventAction = typeSale === 'delivery' ? 'entrega' : 'retira'

  if (treatMixedCartDeliveryError(err)) return
  if (treatMixedCartStoreError(err)) return
  if (treatOutOfStock(err)) return
  if (treatInsufficientStock(err)) return
  if (treatWhenAddProductToCart(err)) return
  if (treatInvalidProductId(err)) return
  treatGenericError(err)
}

export default treatError
