import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { Theme } from 'scripts/react-components/themes/Theme'

export const Wrapper = styled('div')(
  ({ theme }: Theme) => css`
    display: flex;
    justify-content: space-between;
  `
)

export const Title = styled('h2')(
  ({ theme }: Theme) => css`
    ${theme.typography.headings.mega}
    font-weight: bold;
    margin: 0;
  `
)

export const Sub = styled('span')(
  ({ theme }: Theme) => css`
    color: ${theme.colors.n500};
  `
)
