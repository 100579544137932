import PropTypes from 'prop-types'

import { Ellipsis } from './Ellipsis'
import { Transparent } from './Transparent'

const Navigation = ({
  hasOffset,
  prevProps,
  nextProps,
  style = String
}) => {
  if (style === 'transparent') {
    return <Transparent prevProps={prevProps} nextProps={nextProps} />
  }

  return <Ellipsis hasOffset={hasOffset} prevProps={prevProps} nextProps={nextProps} />
}

Navigation.propTypes = {
  hasOffset: PropTypes.bool,
  prevProps: PropTypes.object.isRequired,
  nextProps: PropTypes.object.isRequired,
  style: PropTypes.string
}

Navigation.defaultProps = {
  style: 'ellipsis'
}

export default Navigation
