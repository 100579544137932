import { CONTEXT_APP } from 'frontendCheckout/constants'

export const getAlgonomyRcs = (): string | null => {
  return localStorage.getItem('rcs')
}

export const getUserDetails = (): {
  isLoggedIn: boolean,
  isOnMobileApp: boolean,
  userId: string | null,
  sessionId: string | null
} => {
  const userInfo = JSON.parse(sessionStorage.getItem('userInfo') ?? '{}')

  return {
    ...userInfo,
    isLoggedIn: userInfo?.email?.length > 0,
    isOnMobileApp: userInfo?.context === CONTEXT_APP,
    userId: userInfo?.id || null,
    sessionId: userInfo?.userTrackingId || null
  }
}
