import { css } from '@emotion/react'
import styled from '@emotion/styled'

const labelStyles = () => css`
  label: wrapper-complement;

  span {
    font-style: italic;
    font-weight: normal !important;
  }
`

export const Label = styled('div')(labelStyles)
