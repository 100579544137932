import axios from 'axios'
import Cookies from 'js-cookie'

const endpoint = `${
  window.env && window.env.baseHost
}/api/boitata/v1/modularContents`

export const getPage = async () => await axios.get(endpoint)

export const getModules = async (id, page = 1, device) =>
  await axios.get(`${endpoint}/${id}/modules?page=${page}&device=${device}`)

export const getWishlist = async () => {
  const API_TOKEN = Cookies.get('api_token')

  return await axios.get('/api/boitata/v1/wishlists/ids', {
    headers: { Authorization: `Bearer ${API_TOKEN}` }
  })
}
