import produce from 'immer'

import { Constants } from './'

const initialState = {
  blackList: [],
  get: {
    payload: [],
    error: {}
  },
  put: {
    statusIDs: {},
    error: {}
  }
}

const reducer = (state = initialState, action = {}) =>
  produce(state, draft => {
    switch (action.type) {
      case Constants.ASSOCIATED_UPDATE_BLACKLIST:
        draft.blackList = action.blackList
        draft.get.payload = {
          type: state?.get?.payload?.type,
          products: state?.get?.payload?.products?.filter(
            product => !action.blackList.includes(product.id)
          ) || []
        }
        break

      case Constants.ASSOCIATED_GET_PRODUCTS_UPDATE:
        action.payload.products.forEach(product => {
          product.id = product._id
          delete product._id
        })

        draft.get.error = {}
        draft.get.payload = {
          ...action.payload,
          products: action.payload.products.filter(product =>
            !state.blackList.includes(product._id)
          )
        }
        break

      case Constants.ASSOCIATED_GET_PRODUCTS_ERROR:
        draft.get.error = action.error
        break

      case Constants.ASSOCIATED_PUT_PRODUCTS_START:
        draft.put.statusIDs = {
          ...state.put.statusIDs,
          [action.id]: {
            wasAdded: false,
            isLoading: true
          }
        }
        break

      case Constants.ASSOCIATED_PUT_PRODUCTS_SUCCESS:
        draft.put.statusIDs = {
          ...state.put.statusIDs,
          [action.id]: {
            wasAdded: true,
            isLoading: false
          }
        }

        draft.put.error = {}
        break

      case Constants.ASSOCIATED_PUT_PRODUCTS_ERROR:
        draft.put.statusIDs = {
          ...state.put.statusIDs,
          [action.id]: {
            wasAdded: false,
            isLoading: false
          }
        }

        draft.put.error = action.error
        break

      case Constants.ASSOCIATED_PUT_PRODUCTS_RESET:
        draft.put.error = {}
        break

      case Constants.ASSOCIATED_RESET:
        return initialState

      default:
        return draft
    }
  })

export default reducer
