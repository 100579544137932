import $ from 'jquery'

import onTransitionEnd from 'garden/src/js/utils/transitionend'

import emitter from 'scripts/utils/emitter'
import BaseComponent from 'scripts/components/base-component'

class NavigationMenuTrigger extends BaseComponent {
  static componentName = 'navigation-menu-trigger'

  static DOMEvents = {
    click: 'onButtonClick'
  }

  static emitterEvents = {
    'navigation-trigger:hide': 'onMenuClose'
  }

  init () {
    this.$body = $('body')
    this.$close = this.$body.find('[data-trigger="close-navigation"]')
    this.$back = this.$body.find('[data-trigger="back-navigation"]')
    this.$menu = this.$body.find('.navigation-menu')
    this.$overlay = this.$body.find('.navigation-overlay')

    this.bindListeners()

    this.onEscDownHandler = this.onEscDown.bind(this)
  }

  bindListeners () {
    this.$close.on('click', this.onMenuClose.bind(this))
    this.$back.on('click', this.onMenuBack.bind(this))
  }

  onButtonClick () {
    emitter.emit('overlay-space:hide')

    this.$body.addClass('navigation-visible')
    this.$body.on('keydown', this.onEscDownHandler)
  }

  onEscDown (e) {
    if (e.which === 27) {
      this.onMenuClose()
    }
  }

  onMenuClose () {
    this.$overlay.removeClass('visible')

    setTimeout(() => this.$menu.removeClass('item-active'), 500)

    this.$body.off('keydown', this.onEscDownHandler)

    this.$body.removeClass('navigation-visible')
  }

  onMenuBack () {
    this.$menu.removeClass('item-active')
  }
}

export default ($el) => new NavigationMenuTrigger($el).init()

export { NavigationMenuTrigger as Component }
