import instance from '../api'

const baseHost = window.env && window.env.baseHost

export const getPictures = productId =>
  instance
    .get(`api/v3/products/${productId}/visual-medias/`)
    .then(({ data: { data } }) => {
      const medias = data.medias.map(media => {
        if (media.youtubeId) {
          return {
            alt: media.alt,
            main: `https://i.ytimg.com/vi/${media.youtubeId}/hqdefault.jpg`,
            miniature: `https://i.ytimg.com/vi/${media.youtubeId}/hqdefault.jpg`,
            type: 'video',
            youtubeId: media.youtubeId
          }
        }

        return {
          ...media,
          type: 'image'
        }
      })

      return {
        medias: medias,
        sketchFab: data.sketchFabImage && {
          type: 'sketchFab',
          id: data.sketchFabImage,
          alt: '3d',
          main: `${baseHost}/assets/images/sketchfab-placeholder.svg`,
          miniature: `${baseHost}/assets/images/sketchfab-placeholder.svg`
        }
      }
    })
    .catch(err => {
      throw err.data
    })
