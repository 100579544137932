import { useMemo } from 'react'

interface Service {
  relatedProduct: {
    id: string | number
  }
}

interface UseStandaloneServiceProps {
  isCartWithoutProducts?: boolean
  services?: Service[]
}

export const useStandaloneService = ({ isCartWithoutProducts, services = [] }: UseStandaloneServiceProps) => {
  const isStandaloneService = (service: Service) => service?.relatedProduct && !service.relatedProduct.id

  const standaloneServiceOnly = useMemo(() => services?.length > 0 && services.every(isStandaloneService), [services])

  const isStandaloneServiceCart = isCartWithoutProducts && standaloneServiceOnly

  return { isStandaloneService, isStandaloneServiceCart }
}
