
import { footerOptions } from '../constants'

type FooterProps = {}

export const Footer = ({}: FooterProps) => {
  return (
    <div className="border-t border-neutral-100">
      <div className="max-w-[1152px] m-auto">
        <ul className="text-md text-gray-500 flex flex-row gap-16 p-4">
          {footerOptions.map(option => (
            <li key={option.label}>
              <a href={option.url} target="blank">
                {option.label}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}
