import { Link } from 'react-router-dom'
import { Text, Icon } from '@leroy-merlin-br/backyard-react'
import { PlusCircleOutline } from '@backyard-ui/icons'

import * as S from './styled'

const SeeAllCoupons = ({ link }) => (
  <S.Wrapper>
    <Link to={link}>
      <S.Container>
        <Icon as={PlusCircleOutline} />
        <S.WrapperText>
          <Text noMargin align="center">
            Ver todos os cupons
          </Text>
        </S.WrapperText>
      </S.Container>
    </Link>
  </S.Wrapper>
)

export default SeeAllCoupons
