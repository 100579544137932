import { Heading, Text } from '@leroy-merlin-br/backyard-react'

import * as S from './styled'

const WarningScreenDimensions = () => {
  const { defaultImagePath } = window.env

  return (
    <S.Wrapper>
      <Heading as="h1" size="giga" color="n600" align="center">
        No momento, o Kazaplan é exclusivo para telas maiores como computadores e notebooks.
      </Heading>
      <S.ImageWrapper>
        <img src={`${defaultImagePath}/not-support-mobile.svg`} alt="página incompatível com smartphones e tablets." />
      </S.ImageWrapper>
      <S.MessageWrapper>
        <Text align="center" noMargin as="span" size="mega" color="n600">
           Não sendo compatível com smartphones e tablets.
        </Text>
      </S.MessageWrapper>
    </S.Wrapper>
  )
}

export default WarningScreenDimensions
