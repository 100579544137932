export const Coupon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.9997 8.33341C12.9997 7.41341 13.7463 6.66675 14.6663 6.66675V6.00008C14.6663 3.33341 13.9997 2.66675 11.333 2.66675H4.66634C1.99967 2.66675 1.33301 3.33341 1.33301 6.00008V6.33341C2.25301 6.33341 2.99967 7.08008 2.99967 8.00008C2.99967 8.92008 2.25301 9.66675 1.33301 9.66675V10.0001C1.33301 12.6667 1.99967 13.3334 4.66634 13.3334H11.333C13.9997 13.3334 14.6663 12.6667 14.6663 10.0001C13.7463 10.0001 12.9997 9.25341 12.9997 8.33341Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        opacity="0.4"
        d="M6 9.83325L10 5.83325"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        opacity="0.4"
        d="M9.99666 9.83333H10.0027"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        opacity="0.4"
        d="M5.99666 6.16659H6.00265"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
