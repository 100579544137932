import * as S from './styled'

interface ShellProps {
  width?: number | string
  height?: number | string
  borderRadius?: 'kilo' | 'mega' | 'giga' | 'tera' | 'circle'
}

const Shell = ({ width = '100%', height = 'auto', borderRadius = undefined }: ShellProps) => (
  <S.Shell width={width} height={height} borderRadius={borderRadius} />
)

export default Shell
