import { css } from '@emotion/react'
import styled from '@emotion/styled'

const wrapperStyles = ({ theme }) => css`
  label: wrapper;

  width: 100%;
  display: grid;
  grid-template-columns: 1fr;

  ${theme.media.kilo} {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 16px;
  }

  ${theme.media.mega} {
    grid-template-columns: 1fr 1fr 1fr;
  }
`

const contentWrapperStyles = ({ theme }) => css`
  label: content-wrapper;

  width: 100%;

  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-column-gap: 16px;

  padding: 0 10px;

  ${theme.media.kilo} {
    grid-template-columns: repeat(2, 1fr);
  }

  ${theme.media.tera} {
    grid-template-columns: repeat(3, 1fr);
  }
`

const buttonWrapperStyles = () => css`
  label: button-wrapper;

  padding: 10px;
`

export const ContentWrapper = styled('div')(contentWrapperStyles)
export const Wrapper = styled('div')(wrapperStyles)
export const ButtonWrapper = styled('div')(buttonWrapperStyles)
