import request from './axios'

export const getModule = async url => {
  const response = await request
    .get(url)
    .then(({ data }) => data)
    .catch(err => {
      throw err
    })

  return response
}
