export const loadScript = (chatbotUrl: string) => {
  return new Promise(function (resolve, reject) {
    const script = document.createElement('script')

    script.src = chatbotUrl
    script.onload = resolve
    script.onerror = reject

    document.head.appendChild(script)
  })
}
