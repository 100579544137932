import styled from '@emotion/styled'
import { css } from '@emotion/react'

const sizesDictionary = {
  small: {
    h1: { size: 'giga' },
    h2: { size: 'mega' },
    h3: { type: 'text' },
    h4: { weight: 'regular' }
  },
  medium: {
    h1: { size: 'tera' },
    h2: { size: 'giga' },
    h3: { type: 'headings' },
    h4: { weight: 'semibold' }
  }
}

const markdownStyles = ({ theme, type = 'medium' }) => {
  const { h1, h2, h3, h4 } = sizesDictionary[type]

  return css`
    label: markdown;

    margin-top: ${theme.spacings.tera};

    font-size: ${theme.typography.text.mega.fontSize};
    line-height: ${theme.typography.text.mega.lineHeight};

    margin-bottom: ${theme.spacings.giga};

    .widget.widget-markdown {
      label: widget-markwown;

      margin: 0 auto;

      ${theme.media.mega} {
        width: 700px;
      }
    }

    iframe {
      display: unset !important;
      width: 100% !important;
      height: 225px !important;
      max-width: 350px;
      padding: ${theme.spacings.byte} 0;

      ${theme.media.kilo} {
        height: 300px !important;
        max-width: 450px;
      }

      ${theme.media.mega} {
        height: 315px !important;
        max-width: 560px;
        padding: ${theme.spacings.byte};
      }
    }

    img {
      label: markdown__image;

      width: 100%;
      max-width: 100%;

      margin: 0 auto;

      ${theme.media.mega} {
        width: 50%;
      }
    }

    p {
      label: markdown__text;

      font-size: ${theme.typography.text.mega.fontSize};
      line-height: ${theme.typography.text.mega.lineHeight};

      margin-bottom: ${theme.spacings.kilo};
      margin-top: ${theme.spacings.kilo};
    }

    ul {
      label: markdown__list;

      font-size: ${theme.typography.text.mega.fontSize};

      list-style-type: disc;

      padding-left: ${theme.spacings.mega};

      li {
        label: markdown__list__item;

        margin-bottom: ${theme.spacings.byte};
      }
    }

    ol {
      label: markdown__ordered-list;

      margin-bottom: ${theme.spacings.mega};
      margin-top: ${theme.spacings.mega};
      padding: 0 ${theme.spacings.peta};
    }

    h1 {
      label: markdown__heading-one;

      font-size: ${theme.typography.headings[h1.size].fontSize};
      line-height: ${theme.typography.headings.tera.lineHeight};
      font-weight: ${theme.font.weight.semibold};

      margin-bottom: ${theme.spacings.byte};
      margin-top: ${theme.spacings.giga};
    }

    h2 {
      label: markdown__heading-two;

      font-size: ${theme.typography.headings[h2.size].fontSize};
      line-height: ${theme.typography.headings[h2.size].lineHeight};
      font-weight: ${theme.font.weight.semibold};

      margin-bottom: ${theme.spacings.byte};
      margin-top: ${theme.spacings.giga};
    }

    h3 {
      label: markdown__heading-three;

      font-size: ${theme.typography[h3.type].mega.fontSize};
      line-height: ${theme.typography[h3.type].mega.lineHeight};
      font-weight: ${theme.font.weight.semibold};

      margin-bottom: ${theme.spacings.byte};
      margin-top: ${theme.spacings.giga};
    }

    h4 {
      label: markdown__heading-four;

      font-weight: ${theme.font.weight[h4.weight]};
    }

    u {
      label: markdown__underline;

      text-decoration: underline;
    }

    strong {
      label: markdown__strong;

      font-weight: ${theme.font.weight.bold};
    }

    em {
      label: markdown__emphasis;

      font-style: italic;
    }

    blockquote {
      label: markdown__blockquote;

      margin-bottom: ${theme.spacings.mega};
      margin-top: ${theme.spacings.mega};
      padding-left: ${theme.spacings.peta};
    }

    .button-primary {
      label: markdown__button;

      position: relative;

      display: inline-flex;
      align-items: center;
      justify-content: center;

      width: auto;
      height: auto;

      font-weight: ${theme.font.weight.semibold};
      text-decoration: none;

      cursor: pointer;
      user-select: none;
      padding: calc(${theme.spacings.kilo} - ${theme.borderWidth.kilo})
        ${theme.spacings.mega};

      border-color: transparent;
      border-style: solid;
      border-width: ${theme.borderWidth.kilo};
      border-radius: ${theme.borderRadius.giga};

      outline: none;

      transition: color ${theme.transition.enter},
        background ${theme.transition.enter},
        border-color ${theme.transition.enter},
        box-shadow ${theme.transition.enter};

      &:disabled,
      &[disabled] {
        cursor: not-allowed;
        box-shadow: none;
        opacity: 0.5;
      }

      &:before,
      &:after {
        box-shadow: none;
      }

      &::-moz-focus-inner {
        border: 0;
      }

      color: ${theme.colors.white};
      background: ${theme.colors.g600};

      &:hover {
        background: ${theme.colors.g700};
        transform: none;
      }

      &:active {
        background: ${theme.colors.g800};
      }

      &:focus {
        box-shadow: ${theme.colors.g200} 0 0 0 3px;
      }
    }
  `
}

export const Markdown = styled('div')(markdownStyles)
