import { useState } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { Icon, Badge } from '@leroy-merlin-br/backyard-react'
import { CrossOutline } from '@backyard-ui/icons'

import { errorOnCartPage } from 'scripts/utils/data-layer'

import { Ellipsis } from 'shared/components/Ellipsis'
import { OptimizedImage } from 'shared/components/OptimizedImage'

import { useResize } from 'utils/hooks/use-resize'

import { Content } from 'checkout/components/CrossServices/Installation'
import ServiceImage from 'checkout/components/CrossServices/Installation/Icons/SquareBadge'

import PriceWrapper from './PriceWrapper/PriceWrapper'
import ProductError from './ProductError'
import * as S from './styled'
import productPropTypes from '../../prop-types/product'

const IMAGE_SIZE = { width: 90, height: 90 }

const Product = ({
  brand,
  categoryTree,
  errors,
  id,
  thumbUrl,
  productUrl,
  name,
  shippingType,
  shopName,
  storeId,
  skuId,
  packaging,
  packagingCount,
  productId,
  translatedUnit,
  quantity,
  price,
  updateProduct,
  onRemove,
  services,
  regionId,
  discountByPaymentMethod,
  isHybridEnabled,
  isSimilarProductsEnabled,
  changeItemTypeCallback,
  cartId,
  itemAvailableStores,
  installments,
  fetchPriceShipping,
  zipCode,
  isStandaloneService = false
}) => {
  const availableServices = useSelector(
    state =>
      state.crossServices.get.payload.filter(
        service => service.productId === productId
      ),
    shallowEqual
  )

  const [customError, setCustomError] = useState([])

  const [isMobile] = useResize()

  const onError = (data, deltaValue) => {
    if (data.errors && customError.stock !== data.errors?.[0]) {
      setCustomError({
        stock: data.errors?.[0]
      })
    }

    const itemWithInsufficientStock =
      data.errors?.length >= 1 &&
      data.errors.map(error => error.toLowerCase().includes('estoque'))

    const eventAction =
      deltaValue > 0 ? 'clique_adicionar_item' : 'clique_remover_item'

    if (itemWithInsufficientStock) {
      errorOnCartPage(eventAction, 'Problema de estoque')
      return
    }

    errorOnCartPage(eventAction, 'Erro ao alterar a quantidade de um item')
  }

  const onSuccess = ({ currentPackaging, productMaxStock }) => {
    if (currentPackaging < productMaxStock && productMaxStock !== Infinity) {
      setCustomError([])
    }
  }

  const checkRelatedServices = () => {
    if (!availableServices) return

    const addedServices =
      services && services.filter(service => service.relatedProduct.id === id)

    const product = {
      productId,
      productName: name,
      relatedId: id,
      productQuantity: quantity,
      skuId
    }

    if (addedServices?.length > 0) {
      const addedService = addedServices[0]

      return {
        ...addedService,
        ...product,
        isAvailable: true,
        isAdded: true,
        price: {
          ...addedService.price
        }
      }
    }

    const availableService = availableServices[0]?.services[0]
    if (availableService) {
      return {
        ...availableService,
        ...product,
        serviceId: availableService.id,
        isAvailable: true,
        price: {
          total: availableService.price.to * quantity,
          ...availableService.price
        }
      }
    }
  }

  const getRelatedServices = checkRelatedServices()

  return (
    <S.WrapperColumn data-cy="cart-product">
      <S.InfoWrapper>
        <S.AnchorImage href={productUrl}>
          {isStandaloneService ? (
            <S.ServiceImage>
              <ServiceImage />
            </S.ServiceImage>
          ) : (
            <OptimizedImage
              as={S.Image}
              src={thumbUrl}
              alt={name}
              breakpoints={{ 768: { width: 60, slot: 60 } }}
              width={IMAGE_SIZE.width}
              height={IMAGE_SIZE.height}
            />
          )}
        </S.AnchorImage>

        <S.Column>
          <S.BasicInfoWrapper>
            {isStandaloneService && (
              <div>
                <Badge appearance="warning">Serviço de Instalação</Badge>
              </div>
            )}
            <S.Name href={productUrl} title={name}>
              <Ellipsis clamp={isMobile ? 2 : 1} itemProp="name">
                {name}
              </Ellipsis>
            </S.Name>
            <S.SmallInfo>
              {isStandaloneService ? 'Executado por Leroy Merlin' : 'Vendido e entregue por' }
              <strong>{shopName}</strong>
            </S.SmallInfo>
          </S.BasicInfoWrapper>
          <PriceWrapper
            discountByPaymentMethod={discountByPaymentMethod}
            packaging={packaging}
            packagingCount={packagingCount}
            translatedUnit={translatedUnit}
            quantity={quantity}
            price={price}
            brand={brand}
            name={name}
            categoryTree={categoryTree}
            id={id}
            productId={productId}
            regionId={regionId}
            shippingType={shippingType}
            shopName={shopName}
            skuId={skuId}
            storeId={storeId}
            updateProduct={updateProduct}
            onSuccess={onSuccess}
            onError={onError}
            isHybridEnabled={isHybridEnabled}
            changeItemTypeCallback={changeItemTypeCallback}
            cartId={cartId}
            itemAvailableStores={itemAvailableStores}
            installments={installments}
            fetchPriceShipping={fetchPriceShipping}
            zipCode={zipCode}
            isStandaloneService={isStandaloneService}
          />
        </S.Column>
        <S.RemoveButton
          data-cy="button-remove-item-cart"
          onClick={() => {
            onRemove({
              brand,
              categoryTree,
              id,
              productId,
              name,
              price,
              discountByPaymentMethod,
              type: shippingType,
              quantity,
              shopName,
              services,
              regionId
            })
          }}
        >
          <Icon as={CrossOutline} />
        </S.RemoveButton>
      </S.InfoWrapper>
      {(errors || customError) && (
        <ProductError errors={{ ...errors, ...customError }} />
      )}
      {checkRelatedServices() && (
        <Content {...getRelatedServices} regionId={regionId} />
      )}
    </S.WrapperColumn>
  )
}

Product.propTypes = { ...productPropTypes }

export default Product
