import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { Theme } from 'newCheckout/types/Theme'

const panelStyles = ({ theme }: Theme) => css`
  label: success-payment-data-pix__panel;

  display: flex;
  flex-direction: column;
  gap: ${theme.spacings.mega};

  padding: ${theme.spacings.mega} ${theme.spacings.giga};

  background-color: ${theme.colors.n100};
  border-radius: ${theme.borderRadius.giga};
`
export const Panel = styled('div')(panelStyles)

const pixQRCodeWrapperStyle = ({ theme }: Theme) => css`
  label: success-payment-data-pix__qr-code;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${theme.spacings.giga};

  ${theme.media.giga} {
    flex-direction: row;
  }
`
export const PixQRCodeWrapper = styled('div')(pixQRCodeWrapperStyle)

const pixQRCodeDescriptionStyle = ({ theme }: Theme) => css`
  label: success-payment-data-pix__qr-code-description;

  display: flex;
  flex-direction: column;
`
export const PixQRCodeDescription = styled('div')(pixQRCodeDescriptionStyle)

const pixQRCodeImageStyle = ({ theme }: Theme) => css`
  label: success-payment-data-pix__qr-image;

  width: 120px;
  height: 120px;
  min-width: 120px;
  min-height: 120px;
  background-color: ${theme.colors.white};
  padding: ${theme.spacings.bit};
`
export const PixQRCodeImage = styled('img')(pixQRCodeImageStyle)

const pixCodeTextStyles = ({ theme }: Theme) => css`
  label: success-payment-data-pix__code-text;

  overflow-wrap: anywhere;
`
export const PixCodeText = styled('div')(pixCodeTextStyles)
