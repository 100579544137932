import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { Theme } from 'scripts/react-components/themes/Theme'

type ButtonWrapperProps = {
  isEmpty: boolean;
};

const wrapperStyles = ({ isEmpty }: ButtonWrapperProps) => css`
  display: flex;
  min-height: ${isEmpty ? 0 : '356px'};
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`

export const Wrapper = styled('div')(wrapperStyles)

const listStyles = ({ theme }: Theme) => css`
  display: flex;
  gap: ${theme.spacings.mega};
  flex-direction: column;

  margin-bottom: ${theme.spacings.giga};
  margin-top: ${theme.spacings.mega};

  ${theme.media.mega} {
    flex: 0 0 33.333333%;
    flex-direction: row;
    flex-wrap: wrap;

    margin-top: 0;
  }
`

export const List = styled('div')(listStyles)

const emptyStyles = ({ theme }: Theme) => css`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${theme.spacings.byte};
  margin-bottom: ${theme.spacings.peta};
`

export const Empty = styled('div')(emptyStyles)

const buttonWrapperStyles = ({ theme, isEmpty }: Theme & ButtonWrapperProps) => css`
  align-self: ${isEmpty ? 'center' : 'end'};
  display: flex;
  width: 100%;

  button {
    width: 100%;
  }

  ${theme.media.kilo} {
    width: auto;

    button {
      width: auto;
    }
  }
`

export const ButtonWrapper = styled('div')(buttonWrapperStyles)
