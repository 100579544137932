import PropTypes from 'prop-types'
import { Stack } from '@leroy-merlin-br/backyard-react'

import { PhoneContent } from './components/PhoneContent'
import * as S from './styled'

const CallCenterContent = ({ whatsappEnabled }) => {
  const whatsappIsEnabled = Boolean(whatsappEnabled)

  const productUrl = document.location.href
  const text = `Ol%C3%A1,+desejo+comprar+esse+produto:+${productUrl}`

  return (
    <S.Wrapper>
      <Stack space="byte">
        <S.Title>Compre pelo Telefone</S.Title>

        <Stack space="bit">
          {whatsappIsEnabled && (
            <PhoneContent
              iconType="whatsapp"
              link={`https://api.whatsapp.com/send?phone=551140071380&text=${text}`}
              phoneNumber="(11) 4007-1380"
              locale="Nacional"
            />
          )}
          <PhoneContent
            link="tel:+55-11-4007-1380"
            phoneNumber="4007-1380"
            locale="Capitais"
          />
          <PhoneContent
            link="tel:+55-0800-602-1380"
            phoneNumber="0800-602-1380"
            locale="Demais regiões"
          />
        </Stack>
      </Stack>
    </S.Wrapper>
  )
}

CallCenterContent.propTypes = {
  whatsappEnabled: PropTypes.string.isRequired
}

export default CallCenterContent
