import type { Message } from './types'

export const welcome: Message = {
  type: 'income',
  message:
    'Bem-vindo à Leroy Merlin! Sou seu concierge digital para tudo sobre produtos. Estou aqui para te ajudar a encontrar o que precisa, de forma rápida e personalizada.'
}

export const incomeHelpMessage: Message = {
  type: 'income',
  message: 'Certo, em que posso ajudar?'
}

export const userInputMessage: Message = {
  type: 'outcome',
  message: `estou mexendo na minha sala, me mostre algumas opções de sofás na
  cor azul que caibam em um espaço de 2m2`
}

export const incomeChatResponse: Message = {
  type: 'income',
  message: `
  2, 20 x 1,80m  Andreza Enxovais (código: 91061054)
  -Material: 100% Poliéster
  -Complementar: Pano de Microfibra 4 unidades 35 x 35 cm  (código: 91096250) para manter sua manta sempre limpa.

  2) Futon Alto 120x80cm Linho Twist Azul Jeans (código: 91835310)
  -Material: Tecido
  -Complementar: Pano de Microfibra 4 unidades 4 unidades 35x35cm (código: 91096250) para manter sua manta sempre limpa.

  3) Manta para sofá Eterna Azul 2,20 x 2,00m Guaratinguetá (código: 90907124)
  -Material: Tecido
  -Complementar: Pano de Microfibra 4 unidades 4 unidades 35x35cm (código: 91096250) para manter sua manta sempre limpa.
  Digite o código no campo de busca do site para encontrar o produto.

  Verifique as dimensões para garantir que os produtos sugeridos se encaixem perfeitamente ao seu sofá.
  `
}

export const endMessage: Message = {
  type: 'highlight',
  message: '',
  componentContent: 'feedback'
}

export const feedbackSubmitedMessage: Message = {
  type: 'income',
  message: 'Agradecemos sua colaboração! Para solicitar uma nova ajuda, você pode continuar conversando com o assistente virtual.'
}

export const messageList: Array<Message> = [
  welcome,
  incomeHelpMessage,
  userInputMessage,
  incomeChatResponse
]
