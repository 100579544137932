import { Stack } from '@backyard-ui/core'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { Theme } from 'scripts/react-components/themes/Theme'

const headerStyles = ({ theme }: Theme) => css`
  label: chat__header;

  z-index: 1;
  position: relative;
  height: 55px;
  padding: ${theme.spacings.byte} ${theme.spacings.kilo};
  background-color: ${theme.colors.white};
  border-top-left-radius: 8px;
`

const betaStyles = ({ theme }: Theme) => css`
  label: chat__beta;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 32px;
  padding: 6px ${theme.spacings.kilo};
  color: ${theme.colors.p700};
  font-weight: ${theme.font.weight.bold};
  background-color: ${theme.colors.p200};
  border-radius: 160px;
`

export const Header = styled(Stack)(headerStyles)
export const Beta = styled('div')(betaStyles)
