import $ from 'jquery'

import BaseComponent from 'scripts/components/base-component'

class PasswordPreview extends BaseComponent {
  static componentName = 'password-preview'

  static DOMEvents = {
    'click [data-preview-trigger]': 'onPreviewClick'
  }

  init () {
    this.$trigger = this.$element.find('[data-preview-trigger]')
    this.$input = this.$element.find('[data-preview-target]')

    this.buildContent()
  }

  onPreviewClick () {
    const currentType = this.$input.attr('type')

    const newtype = currentType === 'password' ? 'text' : 'password'

    this.$input.attr('type', newtype)

    this.buildContent()
  }

  buildContent () {
    const assetsPath = window?.env?.assetHost || '/'

    const currentType = this.$input.attr('type')

    const newImage = currentType === 'password' ? 'eye-closed' : 'eye'

    const newContent = $(
      `<img
        src="${assetsPath}assets/images/icons/${newImage}.svg"
        data-testid="image-${newImage}"
      >`
    )

    this.$trigger.empty()
    this.$trigger.append(newContent)
  }
}

export default $el => new PasswordPreview($el).init()

export { PasswordPreview as Component }
