import PropTypes from 'prop-types'
import { Text } from '@leroy-merlin-br/backyard-react'
import { Fragment } from 'react'

import { AlertMessage } from 'shared/components/AlertMessage'

import { isPickup } from 'checkout/components/MyOrders/modules/messages/shipment'

const LockerPinMessage = ({ type, pinCode }) => {
  const shouldShowMessage = isPickup(type) && Boolean(pinCode)

  return (
    shouldShowMessage && (
      <Fragment>
        <AlertMessage type="info">
          Seu pedido está separado em uma das gavetas do nosso Locker. Utilize seu código{' '}
          <Text as="strong" size="kilo" isBold noMargin>
            {pinCode}{' '}
          </Text>
          para abrir a gaveta.
        </AlertMessage>
        <AlertMessage type="info" tag="span">
          <Text as="p" size="kilo" noMargin>
            Você tem até 2 dias para retirar seu pedido.
          </Text>
        </AlertMessage>
      </Fragment>
    )
  )
}

LockerPinMessage.propTypes = {
  type: PropTypes.string.isRequired,
  pinCode: PropTypes.string,
  steps: PropTypes.array,
  phone: PropTypes.string
}

export default LockerPinMessage
