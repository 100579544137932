import type { MutableRefObject } from 'react'

import { Highlight, Message } from './Components'
import type { MessageTypeList } from './types'

export const MessageTypeElement: MessageTypeList = {
  income: Message,
  outcome: Message,
  highlight: Highlight
}

export const scrollBottom = (wrapperRef: MutableRefObject<HTMLElement>) => {
  const parentElement = wrapperRef.current?.parentElement
  if (parentElement?.scrollTo) {
    parentElement?.scrollTo({
      top: wrapperRef.current?.scrollHeight,
      behavior: 'auto'
    })
  }
}
