import PropTypes from 'prop-types'
import LazyLoad from 'react-lazyload'

import { OptimizedImage } from 'shared/components/OptimizedImage'

import WishListButton from './WishListButton'

const IMAGE_SIZE = { width: 280, height: 280 }

const ProductImage = ({
  shortName,
  inWishList,
  tag,
  picture,
  url,
  offer,
  assistedSale,
  id,
  price
}) => (
  <div className="product-thumbnail-content">
    {!assistedSale &&
      <WishListButton isActive={inWishList} product={{ id, shortName, price }} />
    }

    { tag && <span className={`product-badge ${tag.type}-badge`}>{tag.label}</span> }

    <a href={url}>
      <LazyLoad height={IMAGE_SIZE.height} offset={200} once>
        <OptimizedImage
          alt={shortName}
          title={shortName}
          className="product-thumbnail"
          itemProp="image"
          src={picture}
          width={IMAGE_SIZE.width}
          height={IMAGE_SIZE.height}
          noSrcSet
        />
      </LazyLoad>

      { offer &&
        <div className="offer-content">
          <span className="offer-title">
            <strong>{offer.label}</strong>
          </span>
          { (!Object.values(offer).includes('lowest-price') && offer.value) &&
            <span className="offer-value">
              <strong>{offer.value}%</strong>
            </span>
          }
        </div>
      }
    </a>
  </div>
)

ProductImage.propTypes = {
  inWishList: PropTypes.bool.isRequired,
  tag: PropTypes.shape({
    type: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
  }),
  picture: PropTypes.string.isRequired,
  url: PropTypes.string,
  offer: PropTypes.shape({
    type: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.number
  }),
  assistedSale: PropTypes.object,
  shortName: PropTypes.string
}

export default ProductImage
