import { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import {
  Text,
  Heading,
  useModal,
  Stack,
  Button,
  Row,
  Col,
  Container,
  Card
} from '@leroy-merlin-br/backyard-react'
import { CheckmarkCircleOutline, ClockCircleOutline } from '@backyard-ui/icons'

import { DocumentPro } from 'lmcv/components'
import handleModal from 'lmcv/pages/Customers/handleModal'
import * as loyaltyStatus from 'lmcv/utils/LoyaltyStatus/constants'

import { TimeLine } from './components/TimeLine'
import { TimeLineItem } from './components/TimeLineItem'
import * as S from './styled'
import { STATUS_DATA } from '../../constants/statusData'

const DocumentStatus = ({
  status,
  hasProfessionalRelationship,
  professionalData
}) => {
  const { setModal } = useModal()

  const [drawerVisibility, setDrawerVisibility] = useState(false)
  const isNotEligibleToEAD = status === 11 && hasProfessionalRelationship

  const showDrawer = () => {
    isNotEligibleToEAD ? handleModal(setModal) : setDrawerVisibility(true)
  }

  const closeDrawer = () => setDrawerVisibility(false)

  const onSubmit = () => document.location.reload()

  const onCatch = () => {
    closeDrawer()
    handleModal(setModal)
  }

  const title =
    status === loyaltyStatus.PRO_EAD_DOCUMENTATION_DENIED
      ? 'Seu documento não foi aprovado'
      : 'Seu documento precisa ser validado para acessar essa área'

  const hasUploadDate = professionalData?.documentation?.uplodedAt

  const uploadAtFormatted = hasUploadDate
    ? `
    Enviado dia
    ${dayjs(uplodedAt).format('DD/MM/YYYY')}
  `
    : 'Enviado!'

  return (
    <Fragment>
      <Card title="Clientes">
        <S.Body>
          <Heading size="tera" noMargin>
            {title}
          </Heading>

          <TimeLine status={status}>
            <TimeLineItem
              icon={CheckmarkCircleOutline}
              status={loyaltyStatus.PRO_EAD_ACTIVE}
            >
              {uploadAtFormatted}
            </TimeLineItem>

            <TimeLineItem
              icon={ClockCircleOutline}
              status={loyaltyStatus.PRO_EAD_ACTIVE}
            >
              Em análise
            </TimeLineItem>

            <TimeLineItem status={status}>
              {STATUS_DATA[status].iconText}
            </TimeLineItem>
          </TimeLine>

          {status ===
            loyaltyStatus.PRO_EAD_WAITING_DOCUMENTATION_VALIDATION && (
            <Text noMargin size="kilo">
              {STATUS_DATA['8'].text}
            </Text>
          )}

          {status === loyaltyStatus.PRO_EAD_DOCUMENTATION_DENIED && (
            <Stack space="bit">
              <Text noMargin size="kilo" isBold>
                Motivo da reprovação:
              </Text>
              <Text noMargin size="kilo">
                {professionalData &&
                  professionalData.documentation.rejectionReason}
              </Text>
            </Stack>
          )}

          {status === loyaltyStatus.PRO_EAD_DOCUMENTATION_DENIED && (
            <Container>
              <Row justify="center">
                <Col size={{ giga: 4 }}>
                  <S.ButtonWrapper>
                    <Button
                      onClick={showDrawer}
                      appearance="primary"
                      isStretch
                      size="mega"
                    >
                      Reenviar Documentos
                    </Button>
                  </S.ButtonWrapper>
                </Col>
              </Row>
            </Container>
          )}
        </S.Body>
      </Card>

      <DocumentPro
        open={drawerVisibility}
        onClose={closeDrawer}
        onCancel={closeDrawer}
        onFinish={onSubmit}
        onCatch={onCatch}
      />
    </Fragment>
  )
}

DocumentStatus.propTypes = {
  status: PropTypes.number,
  hasProfessionalRelationship: PropTypes.bool,
  professionalData: PropTypes.object
}

export default DocumentStatus
