import axios from 'axios'
import Cookies from 'js-cookie'

import {
  CART_ENDPOINT,
  CART_ENDPOINT_V3,
  CART_UNIQUE_PRODUCTS_COUNT,
  CART_STORES_ENDPOINT,
  CART_KAZAPLAN,
  ENDPOINT_SHIPMENT
} from 'scripts/constants'

const request = axios.create({
  baseURL: window.env && window.env.baseHost
})

export const getCart = async () => (await request.get(CART_ENDPOINT)).data

export const getCartUniqueProductsCount = async () =>
  (await request.get(`${CART_ENDPOINT}${CART_UNIQUE_PRODUCTS_COUNT}`)).data

export const putProductsInCart = async data =>
  (await request.post(CART_ENDPOINT, data)).data

export const putProductsInCartKazaplan = async data =>
  (await request.post(`${CART_ENDPOINT}${CART_KAZAPLAN}`, data)).data

export const removeProductFromCart = productId =>
  request.delete(`${CART_ENDPOINT}/${productId}`)

export const fetchCartShipping = async (postalCode = '') => {
  const url = `${ENDPOINT_SHIPMENT}${postalCode && `/${postalCode}`}`

  return (await request.get(url)).data
}

export const getitemsAvailableStores = cartId => {
  const API_TOKEN = Cookies.get('api_token')

  return axios.get(`${CART_ENDPOINT_V3}/${cartId}/shipment/availability`, {
    headers: {
      ...(API_TOKEN ? { Authorization: `Bearer ${API_TOKEN}` } : {})
    }
  })
}
