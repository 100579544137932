import styled from '@emotion/styled'
import { css } from '@emotion/react'

const wrapperStyles = () => css`
  label: wrapper;

  height: calc(100vh - 66px);

  display: flex;
  align-items: center;
  justify-content: center;
`

const imageWrapperStyles = ({ theme }) => css`
  label: warning__image;

  display: flex;
  justify-content: center;

  margin-bottom: ${theme.spacings.mega};

  & > img {
    width: 100%;
    height: 100%;
    max-width: 450px;
  }
`

export const Wrapper = styled('div')(wrapperStyles)
export const ImageWrapper = styled('div')(imageWrapperStyles)
