import BaseComponent from 'scripts/components/base-component'

class PinterestScript extends BaseComponent {
  static componentName = 'pinterest-script'

  init () {
    this.addImagesDataAttributes()
    this.loadScript(document.getElementsByTagName('body')[0])
  }

  addImagesDataAttributes () {
    this.addImagesNoPinDataAttribute()
    this.addImagesPinDescriptionDataAttribute()
  }

  addImagesNoPinDataAttribute () {
    const images = document.querySelectorAll('img')

    images.forEach(img => {
      const $element = this.$element[0]

      if (!$element.contains(img)) {
        img.dataset.pinNopin = true
      }
    })
  }

  addImagesPinDescriptionDataAttribute () {
    const images = document.querySelectorAll('img')

    images.forEach(img => {
      const $element = this.$element[0]

      if ($element.contains(img)) {
        img.dataset.pinDescription = document.title
      }
    })
  }

  loadScript (parentElement) {
    const script = document.createElement('script')

    script.src = '//assets.pinterest.com/js/pinit.js'
    script.async = true
    script.dataset.pinHover = true

    return parentElement.appendChild(script)
  }
}

export default ($el) => new PinterestScript($el).init()

export { PinterestScript as Component }
