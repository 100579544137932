import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { Theme } from '@leroy-merlin-br/backyard-theme'

type ContentWrapperProps = {
  hasVideo: boolean
  theme: Theme
}

const contentWrapperStyles = ({ theme, hasVideo }: ContentWrapperProps) => css`
  label: top-content__content__wrapper;

  width: 100%;

  display: grid;
  grid-template-columns: 1fr;
  grid-auto-flow: row;
  grid-gap: ${theme.spacings.mega};

  & > a {
    justify-content: space-between;
  }

  ${theme.media.mega} {
    grid-template-columns: repeat(${hasVideo ? 2 : 3}, 1fr);
    grid-template-rows: repeat(3, auto);
    grid-auto-flow: column;
  }
`

export const ContentWrapper = styled('div')(contentWrapperStyles)
