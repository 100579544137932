import { PropsWithChildren, useCallback, useEffect } from 'react'

import { Feedback } from '..'

import { ChatModalProperties } from './types'
import Modal from './Containers'
import { Cover, FormInput, Header, Messaging } from './Components'

const ChatModal = ({
  isActive = true,
  isOpen,
  isWaiting,
  onOpen,
  onClose,
  onHide,
  onSubmitMessage,
  onFeedbackSubmit,
  messages,
  isShowingCover,
  ...divProps
}: PropsWithChildren<ChatModalProperties>) => {
  const openModal = useCallback(() => {
    if (isOpen && onOpen) {
      onOpen()
    }
  }, [isOpen, onOpen])

  const closeModal = () => {
    if (onClose) {
      onClose()
    }
  }

  const hideModal = () => {
    if (onHide) {
      onHide()
    }
  }

  useEffect(() => {
    const startModal = () => {
      if (isOpen === true) {
        openModal()
      }
    }

    startModal()
  }, [isOpen, openModal])

  if (!isOpen) {
    return null
  }

  return (
    <Modal.Wrapper {...divProps}>
      <Modal.Header>
        <Header onClose={closeModal} />
      </Modal.Header>
      <Modal.Body>
        {!isActive && isShowingCover ? (
          <Cover>
            <Feedback.Thanks />
          </Cover>
        ) : (
          <Messaging
            messages={messages ?? []}
            isWaitingResponse={isWaiting}
            onFeedbackSubmit={onFeedbackSubmit}
          />
        )}
      </Modal.Body>
      {isActive && (
        <Modal.Footer>
          <FormInput onSubmit={onSubmitMessage} isWaiting={isWaiting} />
        </Modal.Footer>
      )}
    </Modal.Wrapper>
  )
}

export default ChatModal
