import { css } from '@emotion/react'
import styled from '@emotion/styled'

const cardIconStyles = () => css`
  label: main-icon;

  & > svg {
    width: 75px;
    height: 75px;
  }
`

const sideIconStyles = ({ theme }) => css`
  label: side-icon;

  position: absolute;
  bottom: 0;
  left: -14px;

  background-color: ${theme.colors.white};
  border-radius: ${theme.borderRadius.circle};

  & > svg {
    width: 37px;
    height: 37px;
  }
`

export const CardIcon = styled('div')(cardIconStyles)
export const SideIcon = styled('div')(sideIconStyles)

const wrapperStyles = ({ theme, isCritical }) => css`
  label: wrapper;

  position: relative;
  width: 75px;
  margin: 0 auto ${theme.spacings.giga};

  ${CardIcon} > svg {
    color: ${isCritical ? theme.colors.n200 : theme.colors.p500};
  }

  ${SideIcon} > svg {
    color: ${isCritical ? theme.colors.r600 : theme.colors.p600};
  }
`

export const Wrapper = styled('div')(wrapperStyles)
