import api from '../api'

type SubCategory = {
  id?: string
  name: string
  url?: string
  hasChildren?: boolean
}

type Category = {
  id?: string
  name: string
  url?: string
  items?: SubCategory[]
}

type CategoriesTreeItem = Category & SubCategory

type CategoriesTreeResponse = {
  data: {
    results: CategoriesTreeItem[]
  }
}

type SubCategoryChild = {
  id: string
  name: string
  url: string
}

type SubCategoryChildrenResponse = {
  data: {
    results: SubCategoryChild[]
  }
}

export const getCategoriesTree = async (): Promise<CategoriesTreeResponse> => {
  const { data } = await api.get<CategoriesTreeResponse>(
    '/api/v3/categories/tree'
  )

  return data
}

export const getSubcategoryChildren = async (
  subcategoryId: string
): Promise<SubCategoryChildrenResponse> => {
  const { data } = await api.get<SubCategoryChildrenResponse>(
    `/api/v3/categories/${subcategoryId}/children`
  )

  return data
}
