import { useState } from 'react'

import { SuggestionOption, SuggestionOptions } from '../../types'
import Option from '../Option'
import * as S from './styled'

const Suggestions = ({ options }: SuggestionOptions) => {
  const [isEnabledOption, setIsEnabledOption] = useState(true)

  const commandClick = (command: () => void) => {
    return () => {
      command()
      setIsEnabledOption(false)
    }
  }

  return (
    <S.SuggestionsWrapper>
      {options.map(
        ({ command, label, isDisabled, isSelected }: SuggestionOption) => (
          <Option
            key={`key-${label}`}
            command={commandClick(command)}
            label={label}
            isSelected={isSelected}
            isDisabled={!!isDisabled || !isEnabledOption}
          />
        )
      )}
    </S.SuggestionsWrapper>
  )
}

export default Suggestions
