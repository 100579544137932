import PropTypes from 'prop-types'
import { Inline, Text } from '@leroy-merlin-br/backyard-react'

import * as S from './styled'
import { PhoneIcon } from '../PhoneIcon'
import { WhatsAppIcon } from '../WhatsAppIcon'

const PhoneContent = ({ iconType = 'phone', link, phoneNumber, locale }) => {
  const contacts = {
    whatsapp: {
      name: 'WhatsApp',
      icon: WhatsAppIcon
    },
    phone: {
      name: 'Telefone',
      icon: PhoneIcon
    }
  }

  const { name, icon: Icon } = contacts[iconType]

  return (
    <a href={link} target="_blank" rel="noopener noreferrer">
      <Inline space="bit" alignX="flex-start" alignY="center" wrap="wrap">
        <Icon width={22} />
        <Text color="n900" size="kilo" noMargin>
          {name}:
        </Text>
        <S.PhoneNumber>
          <Text color="p600" size="kilo" isBold noMargin>
            {phoneNumber}
          </Text>
        </S.PhoneNumber>
        <Text color="n600" size="kilo" noMargin>
          {locale}
        </Text>
      </Inline>
    </a>
  )
}

PhoneContent.propTypes = {
  iconType: PropTypes.string,
  link: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  locale: PropTypes.string
}

export default PhoneContent
