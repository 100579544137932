import { checkShippingType } from 'scripts/utils/data-layer'

import { handleServices, handleProducts } from './utils'

const pushDataObject = dataObject => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push(dataObject)
}

export const addToCart = ({
  regionId,
  productName,
  productId,
  price,
  productBrand,
  category,
  shipmentType,
  storeId,
  marketPlace,
  quantity,
  relatedProduct = null
}) => {
  const dataLayerObject = {
    event: 'addToCart',
    dimension14: regionId,
    ecommerce: {
      currencyCode: 'BRL',
      add: {
        products: [
          {
            name: productName,
            id: productId,
            price: parseFloat(price).toFixed(2),
            brand: productBrand,
            category,
            quantity,
            dimension8: shipmentType,
            dimension9: shipmentType === 'pickup-in-store' ? storeId : '',
            dimension15: shipmentType === 'delivery' ? marketPlace : '',
            dimension31: relatedProduct
          }
        ]
      }
    }
  }
  pushDataObject(dataLayerObject)
}

export const handleChangeCartTypeDatalayer = ({
  regionId,
  products,
  services,
  storeId,
  shipmentType
}) => {
  const json = {
    event: shipmentType === 'delivery' ? 'change_delivery' : 'change_store',
    dimension14: regionId,
    ecommerce: {
      currencyCode: 'BRL',
      change: {
        products: [
          ...handleProducts({
            products,
            services,
            storeId,
            shipmentType
          }),
          ...handleServices({
            products,
            services,
            shipmentType
          })
        ]
      }
    }
  }

  pushDataObject(json)
}

export const removeFromCart = ({
  regionId,
  productName,
  productId,
  price,
  productBrand,
  category,
  quantity
}) => {
  const json = {
    event: 'removeFromCart',
    dimension14: regionId,
    ecommerce: {
      remove: {
        products: [
          {
            name: productName,
            id: productId,
            price: parseFloat(price).toFixed(2),
            brand: productBrand,
            category: category || null,
            quantity
          }
        ]
      }
    }
  }
  pushDataObject(json)
}

/**
 *
 * @param {{
 *  regionId:string,
 *  products:[{
 *    name:string
 *    id:string
 *    brand:string
 *    category:string
 *    quantity:string
 *    price:string
 *    shipmentType:string
 *    store:string
 *    storeId:string
 *    marketPlace:string
 *    withdrawalDate:string
 *    shipmentDate:string
 *    hasDiscount:string
 *    discountValue:string
 *    relatedProduct:string
 *  }]
 * }} params0
 */
export const checkoutStep1 = ({ regionId, products }) => {
  const _products = products.map(product => {
    const reformulatedPrice = product.packaging ? product.price * product.packaging : product.price

    return {
      id: product.id,
      name: product.name,
      price: parseFloat(reformulatedPrice).toFixed(2),
      brand: product.brand,
      category: product.category,
      quantity: product.quantity,
      dimension8: product.shipmentType,
      dimension9: product.storeId || null,
      dimension15: product.marketPlace,
      dimension16: product.withdrawalDate,
      dimension17: product.shipmentDate,
      dimension21: product.hasDiscount,
      dimension22: product.discountValue,
      dimension31: product.relatedProduct || null
    }
  })

  const dataLayerObject = {
    event: 'checkout',
    dimension14: regionId,
    shipping_type: checkShippingType(_products),
    ecommerce: {
      checkout: {
        actionField: { step: 1, action: 'checkout' },
        products: _products
      }
    }
  }

  pushDataObject(dataLayerObject)
}

/**
 *
 * @param {
 *  products:[{
 *    name: string,
 *    shipmentOptions: string
 *  }]
 * } param0
 */
export const cepCarrinho = ({ products }) => {
  const json = {
    event: 'cep-carrinho',
    products: products.map(product => ({
      name: product.name,
      dimension28: product.shipmentOptions
    }))
  }
  pushDataObject(json)
}

/**
 * @param  {eventAction} string description for action on trigger event
 * @param  {eventLabel} string label description for error
 */
export const errorWhenAddingProductToCart = (eventAction, eventLabel) => {
  const dataObject = {
    event: 'erros',
    'event-category': 'erros_pdp',
    'event-action': eventAction,
    'event-label': eventLabel
  }

  pushDataObject(dataObject)
}

/**
 * @param  {eventAction} string description for action on trigger event
 * @param  {eventLabel} string label description for error
 */
export const errorInMiniCartContext = (eventAction, eventLabel) => {
  const dataObject = {
    event: 'erros',
    'event-category': 'erros_minicart',
    'event-action': eventAction,
    'event-label': eventLabel
  }

  pushDataObject(dataObject)
}
