import { useInstantSearch } from 'react-instantsearch'
import { Card } from '@backyard-ui/core'
import { type ReactNode } from 'react'

import { RetailMediaProductsCarousel as RetailMediaProductsCarouselModular } from 'site/Modular/modules/RetailMediaProductsCarousel'

function RetailMediaProductsCarousel () {
  const { results } = useInstantSearch()

  const term = results?._state.query || ''

  const wrapper = ({ children }: { children: ReactNode }) => {
    return (
      <Card.Root>
        <Card.Body>{children}</Card.Body>
      </Card.Root>
    )
  }

  return (
    <RetailMediaProductsCarouselModular
      extra={{
        term
      }}
      title="Produtos Patrocinados"
      context="search"
      noTopSpacing
      wrapper={wrapper}
    />
  )
}

export default RetailMediaProductsCarousel
