import instance from '../api'
import {
  GetProductReviewsSummaryResponse,
  GetProductReviewsOrder,
  GetProductReviewsResponse,
  GetRatingReviewResponse,
  FeedbackVoteValue,
  FeedbackType
} from './types'

export const getProductReviewsSummary = async (productId: number) =>
  await instance
    .get<GetProductReviewsSummaryResponse>(
      `/api/v3/reviews/${productId}/summary`
    )
    .then(({ data }) => data.data)
    .catch(err => {
      throw err.response
    })

export const getProductReviews = async (
  productId: number,
  page: number,
  order: GetProductReviewsOrder = 'usefulness'
) =>
  await instance
    .get<GetProductReviewsResponse>(`/api/v3/reviews/${productId}`, {
      params: { page, order }
    })
    .then(({ data }) => data.data)
    .catch(err => {
      throw err.response
    })

export const getProductsReviewsRating = async (ids: number[]) =>
  await instance
    .get<GetRatingReviewResponse>('/api/v3/reviews/rating', {
      params: { productsLms: ids }
    })
    .then(({ data }) => data.data)
    .catch(err => {
      throw err.response
    })

export const postReviewFeedback = async (
  reviewId: string,
  feedbackType: FeedbackType,
  voteValue?: FeedbackVoteValue
) =>
  await instance
    .post('/api/v3/reviews/feedback', {
      reviewId,
      feedbackType,
      voteValue
    })
    .then(res => res)
    .catch(err => {
      throw err.response
    })
