import PropTypes from 'prop-types'
import { Card } from '@leroy-merlin-br/backyard-react'

import { RetailMediaProductsCarousel as RetailMediaProductsCarouselModular } from 'site/Modular/modules/RetailMediaProductsCarousel'

const RetailMediaProductsCarousel = props => {
  const { term = { value: '' }, isSearch, categoryName = '' } = props

  const Wrapper = ({ children }) => {
    return <Card>{children}</Card>
  }

  return (
    <RetailMediaProductsCarouselModular
      extra={{
        term: isSearch ? term.value : '',
        categoryName
      }}
      noContentPadding
      noTopSpacing
      title="Produtos Patrocinados"
      context={isSearch ? 'search' : 'category'}
      wrapper={Wrapper}
    />
  )
}

RetailMediaProductsCarousel.propTypes = {
  isSearch: PropTypes.bool,
  categoryName: PropTypes.string,
  term: PropTypes.shape({
    value: PropTypes.string
  })
}

export default RetailMediaProductsCarousel
