import { FC } from 'react'
import { Icon, Text, Heading } from '@backyard-ui/core'
import { LocationOutline } from '@backyard-ui/icons'
import { Bullet } from 'frontendMyOrders/components/Bullet'

import * as S from './styled'

const OrderStatusTimeline: FC = () => {
  return (
    <S.Wrapper>
      <S.OrderStatusItem lineStyle="solid">
        <Bullet status="completed" />
        <S.ContentText>
          <Heading size="sm" color="neutral-900">
            Pedido aceito
          </Heading>
          <Text size="sm" color="neutral-700">
            Sábado, 17 de julho de 2023
          </Text>
        </S.ContentText>
        <Text size="sm" color="neutral-700">
          10:10
        </Text>
      </S.OrderStatusItem>

      <S.OrderStatusItem lineStyle="solid">
        <Bullet status="current" />
        <S.ContentText>
          <Heading size="sm" color="neutral-900">
            Pagamento em análise
          </Heading>
          <Text size="sm" color="neutral-700">
            Sábado, 17 de julho de 2023
          </Text>
        </S.ContentText>
        <Text size="sm" color="neutral-700">
          10:30
        </Text>
      </S.OrderStatusItem>

      <S.OrderStatusItem>
        <Bullet status="upcoming" />
        <S.ContentText>
          <Heading size="sm" color="neutral-900">
            Em trânsito
          </Heading>
          <Text size="sm" color="neutral-700">
            Sábado, 17 de julho de 2023
          </Text>
        </S.ContentText>
        <Text size="sm" color="neutral-700">
          17:00
        </Text>
      </S.OrderStatusItem>

      <S.OrderStatusItem>
        <Bullet status="canceled" />
        <S.ContentText>
          <Heading size="sm" color="neutral-900">
            Em trânsito
          </Heading>
          <Text size="sm" color="neutral-700">
            Sábado, 17 de julho de 2023
          </Text>
        </S.ContentText>
        <Text size="sm" color="neutral-700">
          17:00
        </Text>
      </S.OrderStatusItem>

      <S.OrderStatusItem>
        <Bullet status="pending" />
        <S.ContentText>
          <Heading size="sm" color="neutral-900">
            Em trânsito
          </Heading>
          <Text size="sm" color="neutral-700">
            Sábado, 17 de julho de 2023
          </Text>
        </S.ContentText>
        <Text size="sm" color="neutral-700">
          17:00
        </Text>
      </S.OrderStatusItem>

      <S.OrderStatusItem>
        <Icon>
          <LocationOutline fill="#D3D2D6" />
        </Icon>
        <S.ContentText>
          <Heading size="sm" color="neutral-900">
            Entregue
          </Heading>
          <Text size="sm" color="neutral-700">
            Sábado, 18 de julho de 2023
          </Text>
        </S.ContentText>
        <Text size="sm" color="neutral-700">
          15:00
        </Text>
      </S.OrderStatusItem>
    </S.Wrapper>
  )
}

export default OrderStatusTimeline
