export const wishlistSchema = (name) => {
  const schema = {
    '@context': 'http://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement:
    [
      {
        '@type': 'ListItem',
        position: 1,
        item:
        {
          '@id': 'https://leroymerlin.com.br/minhas-listas-de-desejos',
          name: 'Minhas Listas de Favoritos'
        }
      }
    ]
  }

  if (name) {
    schema.itemListElement.push({
      '@type': 'ListItem',
      position: 2,
      item:
      {
        '@id': `https://leroymerlin.com.br/minhas-listas-de-desejos/${name}`,
        name: `${name}`
      }
    })
  }

  return schema
}
