/**
 * Validate localStorage has an item.
 * @param {String} key name of the item you want check
 */
export const has = key => localStorage.getItem(key) !== null

/**
 * Remove item from localStorage.
 * @param {String} key name of the item you want to remove
 */
export const remove = key => {
  if (localStorage.getItem(key) === null) {
    throw new Error(
      `it's not possible remove ${key}, this item doesn't exists.`
    )
  }

  return localStorage.removeItem(key)
}

export const removeAll = value => {
  Object.keys(localStorage)
    .filter(key => key.includes(value))
    .forEach(key => localStorage.removeItem(key))
}

/**
 * Validate storage expired.
 * @param {String} key name of the item you want check
 */
export const isExpired = key => {
  const item = get(key)

  return new Date().getTime() - item.created >= item.expires
}

/**
 * Remove localStorage when timestamp expire.
 * @param {String} key name of the item you want check
 */
export const removeOnExpired = key => {
  if (!has(key)) {
    return console.warn(`"${key}" not found, you can't remove it.`)
  }

  if (isExpired(key)) {
    return localStorage.removeItem(key)
  }
}

/**
 * Get localStorage item by key.
 * @param {String} key name of the item you want to get
 */
export const get = key => JSON.parse(localStorage.getItem(key))

/**
 * Set an object to localStorage.
 * @param {String} key name of storage
 * @param {Object} content content you wish storing
 * @param {Number} time time that content will be storing in hours
 */
export const set = (key = null, content, expires = 24) => {
  if (key === null || key === '') {
    throw new Error('the value of key can not be null or empty.')
  }

  localStorage.setItem(
    key,
    JSON.stringify({
      content: content,
      expires: 1000 * 60 * expires,
      created: new Date().getTime()
    })
  )
}
