import { Stack, Heading, Text } from '@leroy-merlin-br/backyard-react'

import { Widget } from '../Widget'
import * as S from './styled'

const ModulesError = () => (
  <Widget>
    <S.Container>
      <Stack space="mega">
        <Heading as="h1" size="exa" noMargin>
          Oops!
        </Heading>
        <Text size="giga" noMargin data-testid="text">
          Tivemos um erro ao carregar nossas ofertas, tente novamente.
        </Text>
      </Stack>
    </S.Container>
    <img
      src="/assets/frontend/images/load-modules-error.svg"
      alt="Error Image"
    />
  </Widget>
)

export default ModulesError
