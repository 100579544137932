import { css, keyframes } from '@emotion/react'
import styled from '@emotion/styled'

const animationFadeOut = keyframes`
  from {
    opacity: 1;
  }

  75% {
    opacity: 0.75;
    height: 75%;
  }

  50% {
    opacity: 0.5;
    height: 50%;
  }
  to {
    opacity: 0;
  }
`

const animationFadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

const fadeOut = ({ fadeOut }) => fadeOut && css`
  animation: ${animationFadeOut} 1s ease-in-out;
`

const fadeIn = ({ fadeIn }) => fadeIn && css`
  animation: ${animationFadeIn} 1s ease-in-out;
`

const wrapperStyles = () => css`
  width: 100%;
`

const lineStyles = ({ theme }) => css`
  margin: ${theme.spacings.kilo} 0 ${theme.spacings.mega};
  width: 100%;
  border-top: 1px solid ${theme.colors.n100};
`

const animatedWrapperStyles = ({ theme }) => css`
  margin: 0 0 ${theme.spacings.kilo};
  display: flex;
  gap: ${theme.spacings.kilo};
`

const productImgStyles = ({ theme }) => css`
  border: 1px solid ${theme.colors.n400};
  border-radius: ${theme.borderRadius.giga};
  width: 82px;
  height: 82px;
  margin-right: ${theme.spacings.kilo};
`

export const Wrapper = styled('div')(wrapperStyles, fadeIn)
export const Line = styled('div')(lineStyles)
export const AnimatedWrapper = styled('div')(animatedWrapperStyles, fadeOut)
export const ProductImg = styled('img')(productImgStyles)
