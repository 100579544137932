import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'

import { AsyncRequest } from 'newCheckout/types/AsyncRequest'
import { Cart } from 'newCheckout/types/Cart'
import { cartApi } from 'newCheckout/api'

const initialState: AsyncRequest<Cart, string | null> = {
  status: 'init',
  error: null,
  requestCode: null,
  data: {
    id: '',
    subTotal: 0,
    regionId: null,
    servicesSubTotal: 0,
    discountTotal: 0,
    freightCost: 0,
    total: 0,
    uniqueProductsCount: 0,
    zipCode: '',
    shippings: [],
    services: [],
    errors: [],
    stock: []
  }
}

const getCart = createAsyncThunk('Cart/getCart', async () => {
  return await cartApi.getCart()
})

const changeProductQuantity = createAsyncThunk(
  'Cart/changeProductQuantity',
  async (addProduct: cartApi.addProductType) => {
    return await cartApi.changeProductQuantity(addProduct)
  }
)

const removeProduct = createAsyncThunk(
  'Cart/removeProduct',
  async (removeProduct: cartApi.removeProductType) => {
    return await cartApi.removeProduct(removeProduct)
  }
)

export const cartSlice = createSlice({
  name: 'Cart',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getCart.pending, state => {
      state.status = 'loading'
      state.error = null
    })
    builder.addCase(getCart.fulfilled, (state, action) => {
      state.status = 'done'
      state.requestCode = action.payload?.status
      state.data = {
        ...state.data,
        ...action.payload.data.data
      }
    })
    builder.addCase(getCart.rejected, state => {
      state.status = 'error'
      state.error = 'Erro desconhecido'
    })

    builder.addCase(changeProductQuantity.pending, state => {
      state.status = 'loading'
      state.error = null
    })
    builder.addCase(changeProductQuantity.fulfilled, (state, action) => {
      state.status = 'done'
      state.requestCode = action.payload?.status
      state.data = {
        ...state.data,
        ...action.payload.data.data
      }
    })
    builder.addCase(changeProductQuantity.rejected, state => {
      state.status = 'error'
      state.error = 'Erro desconhecido'
    })

    builder.addCase(removeProduct.pending, state => {
      state.status = 'loading'
      state.error = null
    })
    builder.addCase(removeProduct.fulfilled, (state, action) => {
      state.status = 'done'
      state.requestCode = action.payload?.status
      const productId = action.meta.arg.productId
      state.data.shippings.forEach(shipping => {
        shipping.items = shipping.items.filter(item => item.id !== productId)
      })
    })
    builder.addCase(removeProduct.rejected, state => {
      state.status = 'error'
      state.error = 'Erro desconhecido'
    })
  }
})

export { getCart, changeProductQuantity, removeProduct }

export default cartSlice.reducer
