import { Fragment } from 'react'
import PropTypes from 'prop-types'

import Tags from './Tags'
import { StockContent } from './StockContent'
import QuantityControls from './QuantityControls'

const AssistedSaleContentManager = ({
  isMobileContext,
  assistedSale = {},
  unit,
  id,
  price
}) => {
  const hasAssistedSale = Object.keys(assistedSale).length
  const { tags, stock } = assistedSale

  const stockProps = { stock, unit }
  const quantityProps = { lm: id, price }

  const renderDesktopContext = () => (
    <div className="assisted-sale-content">
      <Tags tags={tags} />
      <StockContent {...stockProps} id={id} />
      <QuantityControls {...quantityProps} />
    </div>
  )

  const renderMobileContext = () => (
    <Fragment>
      <StockContent {...stockProps} id={id} />
      <QuantityControls {...quantityProps} />
    </Fragment>
  )

  const renderByContext = () => {
    return (
      <Fragment>
        { isMobileContext
          ? renderMobileContext()
          : renderDesktopContext()
        }
      </Fragment>
    )
  }

  if (hasAssistedSale) {
    return renderByContext()
  }

  return null
}

AssistedSaleContentManager.propTypes = {
  isMobileContext: PropTypes.bool,
  unit: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  assistedSale: PropTypes.shape({
    tags: PropTypes.arrayOf(PropTypes.string),
    stock: PropTypes.shape({
      store: PropTypes.number,
      warehouse: PropTypes.number
    })
  })
}

export default AssistedSaleContentManager
