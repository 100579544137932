export const NotFound = () => {
  return (
    <svg
      width="159"
      height="108"
      viewBox="0 0 159 108"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="138.5" cy="89.0879" r="2" stroke="#F0F0F0" strokeWidth="2" />
      <circle cx="19.5" cy="50.0879" r="2" stroke="#F0F0F0" strokeWidth="2" />
      <circle cx="82" cy="46" r="16" stroke="#E5E5E5" strokeWidth="2"/>

      <path
        d="M27.44 68.0879C23.2712 68.0879 19.88 71.4522 19.88 75.5879C19.88 76.1793 20.3638 76.6593 20.96 76.6593C21.5562 76.6593 22.04 76.1793 22.04 75.5879C22.04 72.634 24.4624 70.2307 27.44 70.2307C30.4176 70.2307 32.84 72.634 32.84 75.5879C32.84 78.5418 30.4176 80.945 27.44 80.945H9.08C8.48384 80.945 8 81.425 8 82.0165C8 82.6079 8.48384 83.0879 9.08 83.0879H27.44C31.6088 83.0879 35 79.7236 35 75.5879C35 71.4522 31.6088 68.0879 27.44 68.0879Z"
        fill="#F0F0F0"
      />
      <path
        d="M24.86 92.0879C22.8528 92.0879 21.22 90.5179 21.22 88.5879C21.22 88.3119 21.453 88.0879 21.74 88.0879C22.027 88.0879 22.26 88.3119 22.26 88.5879C22.26 89.9664 23.4264 91.0879 24.86 91.0879C26.2936 91.0879 27.46 89.9664 27.46 88.5879C27.46 87.2094 26.2936 86.0879 24.86 86.0879H16.02C15.733 86.0879 15.5 85.8639 15.5 85.5879C15.5 85.3119 15.733 85.0879 16.02 85.0879H24.86C26.8672 85.0879 28.5 86.6579 28.5 88.5879C28.5 90.5179 26.8672 92.0879 24.86 92.0879Z"
        fill="#F0F0F0"
      />
      <rect x="1" y="81.0879" width="5" height="2" rx="1" fill="#F0F0F0" />
      <rect x="35" y="29.0878" width="3" height="2" rx="1" fill="#F0F0F0" />
      <rect x="125.5" y="52.0879" width="3" height="2" rx="1" fill="#F0F0F0" />
      <rect x="40.5" y="29.0878" width="14" height="2" rx="1" fill="#F0F0F0" />
      <rect
        x="141.5"
        y="57.0879"
        width="12"
        height="1"
        rx="0.5"
        fill="#F0F0F0"
      />
      <path
        d="M150.44 39.0878C146.271 39.0878 142.88 42.4521 142.88 46.5878C142.88 47.1792 143.364 47.6592 143.96 47.6592C144.556 47.6592 145.04 47.1792 145.04 46.5878C145.04 43.6338 147.462 41.2306 150.44 41.2306C153.418 41.2306 155.84 43.6338 155.84 46.5878C155.84 49.5417 153.418 51.9449 150.44 51.9449H132.08C131.484 51.9449 131 52.4249 131 53.0163C131 53.6078 131.484 54.0878 132.08 54.0878H150.44C154.609 54.0878 158 50.7235 158 46.5878C158 42.4521 154.609 39.0878 150.44 39.0878Z"
        fill="#F0F0F0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M134.377 12.3549C134.466 12.3803 134.554 12.4043 134.641 12.4277C140.485 13.9931 143.651 11.8892 144.084 11.5713C145.039 11.073 145.72 10.253 146.004 9.25746C146.293 8.24631 146.138 7.17532 145.569 6.24121C144.999 5.3072 144.083 4.62201 142.988 4.31235C141.895 3.9974 140.753 4.10498 139.775 4.60087C135.4 6.81842 134.023 11.4851 133.967 11.6829C133.886 11.967 134.069 12.2681 134.377 12.3549ZM135.261 11.4825C135.735 10.2561 137.199 7.15061 140.354 5.5512C141.066 5.19028 141.896 5.11292 142.694 5.34107C143.491 5.56672 144.159 6.06554 144.573 6.74584C144.988 7.42576 145.101 8.20578 144.891 8.94228C144.68 9.67848 144.172 10.2829 143.46 10.644C143.427 10.6606 143.396 10.6802 143.368 10.7028C143.34 10.7249 140.626 12.8036 135.261 11.4825Z"
        fill="#F0F0F0"
      />
      <path
        d="M139.45 9.68293C139.237 9.52064 139.2 9.24369 139.368 9.06404C139.518 8.9033 143.107 5.12169 148.043 4.81116C148.312 4.79396 148.56 4.9759 148.598 5.21786C148.635 5.45937 148.447 5.66886 148.177 5.6856C143.64 5.97122 140.175 9.6146 140.141 9.65136C140.018 9.78177 139.822 9.82503 139.642 9.77677C139.574 9.75856 139.508 9.72727 139.45 9.68293Z"
        fill="#F0F0F0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M98.5 15.0878H94.5V17.0878H98.5V15.0878ZM104.5 17.4292C106.526 18.1454 108.053 19.9194 108.417 22.0878H110.438C110.029 18.8095 107.637 16.1472 104.5 15.3398V17.4292ZM74.5 15.0878H78.5V17.0878H74.5V15.0878ZM88.5 17.0878H84.5V15.0878H88.5V17.0878ZM64.5 15.0878H68.5V17.0878H64.5V15.0878ZM58.5 15.6693C55.8639 16.7363 53.926 19.1677 53.5619 22.0878H55.583C55.884 20.2932 56.9817 18.7687 58.5 17.8905V15.6693ZM53.5 32.0878V28.0878H55.5V32.0878H53.5ZM53.5 38.0878V42.0878H55.5V38.0878H53.5ZM53.5 52.0878V48.0878H55.5V52.0878H53.5ZM53.5 58.0878V98.0878C53.5 102.506 57.0817 106.088 61.5 106.088H102.5C106.918 106.088 110.5 102.506 110.5 98.0878V78.0878H108.5V98.0878C108.5 101.401 105.814 104.088 102.5 104.088H61.5C58.1863 104.088 55.5 101.401 55.5 98.0878V58.0878H53.5ZM108.5 72.0878H110.5V68.0878H108.5V72.0878ZM110.5 58.0878V62.0878H108.5V58.0878H110.5ZM108.5 52.0878H110.5V48.0878H108.5V52.0878ZM110.5 38.0878V42.0878H108.5V38.0878H110.5ZM108.5 32.0878H110.5V28.0878H108.5V32.0878Z"
        fill="#D8D8D8"
      />
      <rect
        x="74.5"
        y="41.5"
        width="3.36842"
        height="4"
        rx="1.68421"
        fill="#E5E5E5"
      />
      <rect
        x="87.1316"
        y="41.7059"
        width="3.36842"
        height="4"
        rx="1.68421"
        fill="#E5E5E5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M75.0815 55.0879H76.9408C78.0086 53.1134 80.0977 51.7721 82.5 51.7721C84.9024 51.7721 86.9914 53.1134 88.0592 55.0879H89.9185C88.7317 52.1561 85.8574 50.0879 82.5 50.0879C79.1426 50.0879 76.2683 52.1561 75.0815 55.0879Z"
        fill="#E5E5E5"
      />
      <path
        d="M74.4478 85.5879H76.1838V83.4879H77.2758V81.9199H76.1838V79.6239H74.4478V81.9199H72.1658L74.7558 75.8579H72.8378L70.2058 82.0319V83.4879H74.4478V85.5879ZM82.9575 75.6759C80.6335 75.6759 79.3595 77.4679 79.3595 80.7159C79.3595 83.9779 80.6335 85.7699 82.9575 85.7699C85.2955 85.7699 86.5835 83.9779 86.5835 80.7159C86.5835 77.4679 85.2955 75.6759 82.9575 75.6759ZM82.9575 77.2719C84.1195 77.2719 84.7215 78.5039 84.7215 80.7159C84.7215 82.9279 84.1195 84.1739 82.9575 84.1739C81.8235 84.1739 81.2215 82.9279 81.2215 80.7159C81.2215 78.5039 81.8235 77.2719 82.9575 77.2719ZM92.9771 85.5879H94.7131V83.4879H95.8051V81.9199H94.7131V79.6239H92.9771V81.9199H90.6951L93.2851 75.8579H91.3671L88.7351 82.0319V83.4879H92.9771V85.5879Z"
        fill="#D8D8D8"
      />
    </svg>
  )
}
