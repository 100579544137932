import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { styleHelpers } from '@leroy-merlin-br/backyard-react'

const listStyles = () => css`
  label: list-element;

  margin-top: 0;
  margin-bottom: 0;
  list-style: none;
`

const listItemStyles = ({ theme }) => css`
  label: item;

  position: relative;

  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;

  margin: 0;
  padding: ${theme.spacings.giga};

  &:not(:last-of-type) {
    border-bottom: 1px solid ${theme.colors.n100};
  }

  ${theme.media.giga} {
    padding: ${theme.spacings.giga} ${theme.spacings.exa};
  }
`

const circleStyles = ({ theme, color }) =>
  css`
    label: circle;

    z-index: 1;
    width: 40px;
    height: 40px;

    display: flex;
    align-items: center;
    justify-content: center;

    ${styleHelpers.shadowTriple(theme)};

    border-radius: 16px;
    background: ${theme.colors[color]};
    margin-right: ${theme.spacings.mega};

    svg {
      width: 18px;
      height: 20px;
      margin-left: 1px;
      color: ${theme.colors.white};
    }
  `

const lineStyles = ({ theme, startColor, endColor }) => {
  const linearGradient = `linear-gradient(180deg, ${theme.colors[startColor]} 0%, ${theme.colors[endColor]} 100%)`

  return css`
    content: '';

    position: absolute;
    top: 50%;
    left: 44px;

    width: 2px;
    height: 100%;
    background: ${linearGradient};

    ${theme.media.giga} {
      left: 67px;
    }
  `
}

const wrapperEmptyHistory = ({ theme }) => css`
  label: wrapper-empty-history;

  display: flex;
  justify-content: center;
  padding: ${theme.spacings.giga};
`

export const List = styled('ul')(listStyles)
export const ListItem = styled('li')(listItemStyles)
export const Circle = styled('div')(circleStyles)
export const Line = styled('div')(lineStyles)
export const WrapperEmptyHistory = styled('div')(wrapperEmptyHistory)
