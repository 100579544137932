import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { getRoutes, NoMatch } from 'frontendMyOrders/routes'

const App = () => {
  const routes = getRoutes()

  return (
    <BrowserRouter basename="/meus-pedidos">
      <Switch>
        {routes.map(({ ...props }) =>
          <Route {...props} />
        )}
        <Route component={NoMatch} />
      </Switch>
    </BrowserRouter>
  )
}

export default App
