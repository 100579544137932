import PropTypes from 'prop-types'
import { Text, Button, Inline, Card } from '@leroy-merlin-br/backyard-react'

const Banner = ({ onClick }) => (
  <Card>
    <Inline alignX="space-between" alignY="center">
      <div>
        <Text size="kilo" noMargin isBold>
          Conta pra gente o que você curte!
        </Text>
        <Text size="kilo" noMargin>
          Assim conseguimos mostrar só vantagens que tem a ver com você. ;)
        </Text>
      </div>

      <Button
        appearance="secondary"
        variant="outline"
        size="kilo"
        onClick={onClick}
      >
        Preencher preferências
      </Button>
    </Inline>
  </Card>
)

Banner.propTypes = {
  onClick: PropTypes.func.isRequired
}

export default Banner
