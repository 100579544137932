import axios from 'axios'

import { api } from './api'

export type SendCodePayload = {
  fiscalId: string
  fullName: string
  type: string
  contact: string
  context: string
}

export const sendCodeAuth = async (payload: SendCodePayload) => {
  try {
    const { data } = await api.post(
      '/otp/signup/send-code',
      payload
    )

    return data
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error.response
    } else {
      throw error
    }
  }
}
