const UPDATE_QUERY = '@UPDATE_QUERY'
const TOGGLE_MOBILE_FILTER = '@TOGGLE_MOBILE_FILTER'
const GET_PRODUCTS_SUCCESS = '@GET_PRODUCTS_SUCCESS'
const GET_PRODCUTS_FAILURE = '@GET_PRODCUTS_FAILURE'

export default {
  UPDATE_QUERY,
  TOGGLE_MOBILE_FILTER,
  GET_PRODUCTS_SUCCESS,
  GET_PRODCUTS_FAILURE
}
