import { tv } from 'tailwind-variants'

const styles = tv({
  slots: {
    description: `
      max-h-60
    `,
    footer: `
      w-full
      pb-9
      md:pt-9
    `
  }
})

export default styles
