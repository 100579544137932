import styled from '@emotion/styled'
import { css } from '@emotion/react'

const popoverStyles = ({ theme }) => css`
  label: popover;

  background: ${theme.colors.white};

  border-radius: ${theme.borderRadius.tera};

  position: absolute;
  right: 0;
  top: 85px;

  max-width: 235px;
  min-width: 207px;
  z-index: ${theme.zIndex.backdrop};
`

const itemStyles = ({ theme }) => css`
  label: popover__item;

  padding: ${theme.spacings.kilo} ${theme.spacings.giga} ${theme.spacings.mega};

  svg {
    color: ${theme.colors.n400};
    width: 13px;
    height: 13px;
  }

  &:last-of-type {
    border-bottom-right-radius: ${theme.borderRadius.tera};
    border-bottom-left-radius: ${theme.borderRadius.tera};
  }

  transition: background 0.3s;

  &:hover {
    transition: background 0.3s;
    background: ${theme.colors.n100};
  }
`

const headerStyles = ({ theme }) => css`
  label: popover__header;

  padding: ${theme.spacings.mega} ${theme.spacings.giga};
  border-bottom: solid 1px ${theme.colors.n100};
`

const wrapperInfoStyles = ({ theme }) => css`
  label: popover__wrapper-info;

  display: flex;
  align-items: baseline;

  svg {
    color: ${theme.colors.n300};
  }
`

const wrapperNameStyles = () => css`
  label: popover__wrapper-name;

  &:first-of-type {
    text-transform: capitalize;
  }
`

const shellWrapperStyles = ({ theme }) => css`
  label: popover__shell-wrapper;

  padding: ${theme.spacings.kilo} ${theme.spacings.giga};
`

export const Popover = styled('div')(popoverStyles)

export const Item = styled('a')(itemStyles)
export const Header = styled('div')(headerStyles)
export const WrapperInfo = styled('div')(wrapperInfoStyles)
export const WrapperName = styled('div')(wrapperNameStyles)
export const ShellWrapper = styled('div')(shellWrapperStyles)
