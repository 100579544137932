import { useContext } from 'react'
import PropTypes from 'prop-types'

import { ModularContext } from 'site/Modular/contexts/ModularContext'

import Time from '../Time/Time'
import * as S from './styled'

const Countdown = ({ time, ...props }) => {
  const { isBlackWeekend } = useContext(ModularContext)

  const hasDays = time.days !== 0
  const hasHours = time.hours !== 0

  return (
    <S.Wrapper isBlackWeekend={isBlackWeekend} {...props}>
      <span>Oferta acaba em</span>
      <S.TimeWrapper>
        {hasDays && <Time
          label="dias"
          time={time.days}
        />}

        {(hasDays || hasHours) && <Time
          label="hs"
          time={time.hours}
        />}

        <Time
          label="min"
          time={time.minutes}
        />

        {!hasDays && <Time
          label="seg"
          time={time.seconds}
        />}
      </S.TimeWrapper>
    </S.Wrapper>
  )
}

Countdown.propTypes = {
  time: PropTypes.shape({
    days: PropTypes.number.isRequired,
    hours: PropTypes.number.isRequired,
    minutes: PropTypes.number.isRequired,
    seconds: PropTypes.number.isRequired
  }).isRequired,
  category: PropTypes.string.isRequired
}

export default Countdown
