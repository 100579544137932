import { useMemo } from 'react'
import PropTypes from 'prop-types'
import {
  Container,
  Row,
  Col,
  Icon,
  Text,
  Visible
} from '@leroy-merlin-br/backyard-react'
import { useStateMachine } from 'little-state-machine'

import * as S from './styled'
import { useCardRequestContext } from '../../context/card-request-context'
import { STEPS, FORMS_NAMES } from '../../pages/CardRequest/constants'

const Content = ({ children }) => {
  const { activeStep, setActiveStep, updateAction } = useCardRequestContext()

  const {
    state: { hasSubmittedForm, apiErrors }
  } = useStateMachine({ updateAction })

  const assetHost = window && window.env.assetHost

  const getStepIsActive = (currentStep, stepName) => {
    const currentPathIndex = STEPS.findIndex(
      ({ title }) => title === currentStep
    )

    const itemPathIndex = STEPS.findIndex(({ title }) => title === stepName)

    return itemPathIndex <= currentPathIndex
  }

  return (
    <S.Content>
      <Container>
        <Row>
          <Col
            size={{
              giga: 5,
              tera: 4
            }}
          >
            <S.StepsWrapper>
              {STEPS.map(({ icon, label, title }, index) => {
                const isAllowed =
                  title !== FORMS_NAMES.basic && hasSubmittedForm

                return (
                  <S.Step
                    key={index}
                    isActive={getStepIsActive(activeStep, title)}
                    isAllowed={isAllowed}
                    hasError={Boolean(apiErrors[title]?.length)}
                    onClick={() => isAllowed && setActiveStep(title)}
                  >
                    <S.StepIcon>
                      <Icon as={icon} size="mega" />
                    </S.StepIcon>
                    <Text size="kilo" noMargin>
                      {label}
                    </Text>
                  </S.Step>
                )
              })}
            </S.StepsWrapper>
          </Col>
        </Row>
        <Row>
          <Col
            size={{
              giga: 5,
              tera: 4
            }}
          >
            {children}
          </Col>

          <Visible when={['giga', 'tera']}>
            <Col
              size={{
                giga: 5,
                tera: 4
              }}
              offset={{ tera: 1 }}
            >
              <S.Image
                src={`${assetHost}assets/images/celebre/background.svg`}
                alt="Complete com suas informações e preferências para pedir o seu novo cartão."
              />
            </Col>
          </Visible>
        </Row>
      </Container>
    </S.Content>
  )
}

Content.propTypes = {
  children: PropTypes.node
}

export default Content
