import { tv } from 'tailwind-variants'

const styles = tv({
  slots: {
    base: `
    flex 
    items-center 
    justify-center 
    w-full
  `,
    container: `
      bg-gray-100 
      py-2 
      px-4 
      rounded-md 
      w-[97%]
      sm:w-full
  `
  }
})

export default styles
