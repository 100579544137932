import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { Theme } from 'newCheckout/types/Theme'

const wrapperStyle = ({ theme }:Theme) => css`
  label: method-list__item;

  display: flex;
  gap: ${theme.spacings.giga};
  padding: ${theme.spacings.mega} ${theme.spacings.giga} 0 ${theme.spacings.giga};

`
export const Wrapper = styled('div')(wrapperStyle)
