import { css } from '@emotion/react'
import styled from '@emotion/styled'

const COLOR_MAP = {
  active: 'p600',
  inactive: 'n200',
  canceled: 'r600',
  partial: 'y500'
}

const wrapperStyles = ({ theme }) => css`
  label: step-wrapper;

  display: flex;
  flex-direction: column;
  padding: ${theme.spacings.mega} 0;
  border-top: solid 1px ${theme.colors.n100};
  border-bottom: solid 1px ${theme.colors.n100};

  ${theme.media.mega} {
    flex-direction: row;
    margin-bottom: ${theme.spacings.giga};
    border-top: none;
  }
`

const stepStyles = ({ theme, isActive }) => css`
  label: step;

  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 50px;
  margin: 0 auto;

  text-align: center;

  ${theme.media.mega} {
    display: block;
    min-height: 80px;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    height: 50%;
    width: 4px;
    left: ${theme.spacings.mega};

    background-color: ${isActive ? theme.colors.p600 : theme.colors.n200};

    z-index: 0;

    ${theme.media.mega} {
      height: 4px;
      width: 50%;
      top: 20px;
      left: unset;
    }
  }

  &::before {
    transform: translateY(-50%);

    ${theme.media.mega} {
      left: -1px;
    }
  }

  &::after {
    transform: translateY(50%);

    ${theme.media.mega} {
      right: -1px;
      transform: translateY(-50%);
    }
  }

  &:first-of-type::before,
  &:last-of-type::after {
    display: none;
  }
`

const currentStepStyles = ({ theme, isCurrent }) =>
  isCurrent &&
  css`
    label: step--current;

    &::after {
      background-color: ${theme.colors.n200};
    }
  `

const stripesStyles = ({ theme, isLastActive }) =>
  isLastActive &&
  css`
    label: step--stripes;

    margin-bottom: ${theme.spacings.mega};

    &::after {
      top: ${theme.spacings.giga};
      background: linear-gradient(
        to bottom,
        ${theme.colors.n200},
        ${theme.colors.n200} 50%,
        ${theme.colors.white} 50%,
        ${theme.colors.white}
      );
      background-size: 16px 8px;
    }

    &:last-of-type::after {
      display: block;
    }

    ${theme.media.mega} {
      margin-bottom: 0;

      &::after {
        top: 20px;
      }
    }
  `

const bulletStyles = ({ theme, status }) => {
  const color = COLOR_MAP[status]

  return css`
    label: step__bullet;

    position: relative;

    display: inline-block;

    height: 35px;
    width: 35px;

    background-color: ${theme.colors[color]};
    border-radius: ${theme.borderRadius.circle};

    z-index: 1;

    ${theme.media.mega} {
      height: 40px;
      width: 40px;
    }
  `
}

const descriptionStyles = ({ theme, isDark }) => css`
  label: step__description;

  display: flex;
  flex-direction: column;
  margin-left: ${theme.spacings.byte};

  color: ${isDark ? theme.colors.n900 : theme.colors.n400};
  text-align: left;

  ${theme.media.mega} {
    margin-top: ${theme.spacings.byte};
    margin-left: 0;
    text-align: center;
  }
`

const iconStyle = ({ theme, image, isDark }) =>
  css`
    label: step__icon;

    mask-image: url(${window && window.env.assetHost}${image});
    mask-repeat: no-repeat;
    mask-position: center center;
    mask-size: 16px;
    height: 100%;
    width: 100%;
    background-color: ${isDark ? theme.colors.black : theme.colors.white};

    ${theme.media.mega} {
      mask-size: 20px;
    }
  `

export const Wrapper = styled('div')(wrapperStyles)
export const Step = styled('div')(stepStyles, currentStepStyles, stripesStyles)
export const Bullet = styled('div')(bulletStyles)
export const Description = styled('div')(descriptionStyles)
export const Icon = styled('div')(iconStyle)
