import request from './axios'
import { API_ENDPOINTS } from './constants'

export const getCoupon = async (id, type, active) => {
  const response = await request
    .get(`${API_ENDPOINTS.coupon}/${id}`, {
      params: { type, active: +active } // this cast will be removed soon
    })
    .then(data => data)
    .catch(err => {
      throw err.response
    })

  return response
}

export const getCouponsByType = async (
  category,
  page = 1,
  status = null,
  perPage = 10
) => {
  const response = await request
    .get(API_ENDPOINTS.vouchers(category), {
      params: {
        page,
        status,
        perPage
      }
    })
    .then(data => data)
    .catch(err => {
      throw err.response
    })

  return response
}

export const getCouponsActivated = async () => {
  const response = await request
    .get(API_ENDPOINTS.vouchers('moneyActivated'))
    .then(data => data)
    .catch(err => {
      throw err.response
    })

  return response
}

export const setCouponActive = async (id, type) => {
  const response = await request
    .post(API_ENDPOINTS.vouchers('active'), {
      voucherId: id,
      type
    })
    .then(data => data)
    .catch(err => {
      throw err.response
    })

  return response
}

export const setShareCouponActive = async (id, inhabitantId) => {
  const response = await request
    .post(`${API_ENDPOINTS.coupon}/transfer`, {
      voucherId: id,
      inhabitantId: inhabitantId
    })
    .then(data => data)
    .catch(err => {
      throw err.response
    })

  return response
}
