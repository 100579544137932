import request from 'scripts/api/product'

const BASE_URL = window.env && window.env.baseHost

export const getProductStock = async lm => {
  console.log('lm', lm)
  const response = await request
    .get(`${BASE_URL}/stock/${lm}`)
    .then(({ data }) => data)
    .catch(error => {
      throw error.response
    })

  return response
}
