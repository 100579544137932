import { Fragment } from 'react'
import { Text, Popover } from '@leroy-merlin-br/backyard-react'

import { TooltipIcon } from '../TooltipIcon'

const TimeLineTooltip = () => (
  <Popover
    title="Como funcionam os Meus Objetivos?"
    content={
      <Fragment>
        <Text size="kilo">
          O valor gasto em reais pelos seu(s) Cliente(s) no projeto são
          acumulados durante o prazo determinado. Ao atingir cada uma das
          missões, você ganha a recompensa em pontos.
        </Text>

        <Text size="kilo">
          Ao final do prazo, o valor de todas as compras dos seus Clientes
          reinicia e você recebe a recompensa de acordo com a missão atingida.
        </Text>
      </Fragment>
    }
    placement="bottom"
  >
    <TooltipIcon />
  </Popover>
)

export default TimeLineTooltip
