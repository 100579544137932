import { css } from '@emotion/react'
import styled from '@emotion/styled'

const baseStyles = ({ theme }) => css`
  label: wrapper;

  width: 100%;
  padding-right: ${theme.spacings.mega};

  ${theme.media.mega} {
    padding-right: 0;
  }
`

export const Wrapper = styled('div')(baseStyles)
