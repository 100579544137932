import { applyCoupon } from './applyCoupon'
import { getAvailableVouchersAndCoupons } from './getAvailableVouchersAndCoupons'
import { getSummary } from './getSummary'
import { removeCoupon } from './removeCoupon'
import { addProduct } from './addProduct'
export * as Cart from './types'

export const cart = {
  applyCoupon,
  getAvailableVouchersAndCoupons,
  getSummary,
  removeCoupon,
  addProduct
}
