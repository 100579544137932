import { PureComponent } from 'react'
import classnames from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'

import debounce from 'garden/src/js/utils/debounce'

import { GardenDrawer } from 'shared/components/GardenDrawer'

import StepOne from '../steps/One'
import StepTwo from '../steps/Two'
import StepOneMobile from '../steps/OneMobile'

const variants = {
  initial: {
    opacity: 0
  },
  enter: {
    opacity: 1,
    transition: {
      duration: 0.3
    }
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.3
    }
  }
}

class AddToWishlist extends PureComponent {
  constructor (props) {
    super(props)

    this.state = {
      screenSize: window.innerWidth,
      isNewList: false,
      isOpenedDrawer: false,
      isOpenedMobile: false
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.props.isOpenedDrawer !== prevProps.isOpenedDrawer) {
      this.setState({
        isOpenedDrawer: this.props.isOpenedDrawer,
        isNewList: this.props.isNewList
      })
    }
  }

  onNewList = () => {
    this.setState({ isNewList: true })
  }

  onTouchMove = debounce((touches, target) => {
    this.setState({
      isOpenedMobile: touches[0].clientY < target.getBoundingClientRect().top
    })
  }, 150)

  hideElement = (stateName) => {
    this.setState({ [stateName]: false })

    this.props.closeDrawer(stateName)
  }

  isMobile = () => (
    this.state.screenSize <= 768
  )

  renderStepOne = (status) => {
    const props = {
      close: () => this.hideElement('isOpenedDrawer'),
      next: this.onNewList,
      status: status,
      wishlists: this.props.wishlists,
      addToList: this.props.addToList
    }

    if (this.isMobile()) {
      return (
        <StepOneMobile
          {...props}
          move={ (e) => this.onTouchMove(e.touches, e.target) }
        />
      )
    }

    return <StepOne {...props} />
  }

  render () {
    const {
      isOpenedMobile
    } = this.state

    const drawerClass = classnames(
      'wishlist-drawer',
      {
        'is-opened': isOpenedMobile
      }
    )

    const drawerProps = {
      overlayClick: () => this.hideElement('isOpenedDrawer'),
      isOpen: this.state.isOpenedDrawer,
      className: drawerClass
    }

    const stepTwoProps = {
      close: () => this.hideElement('isNewList'),
      modify: this.props.createWishlist,
      status: status,
      move: (e) => this.onTouchMove(e.touches, e.target)
    }

    return (
      <GardenDrawer {...drawerProps}>
        <div className="wishlist-drawer-content">
          <AnimatePresence>
            {
              drawerProps.isOpen && <motion.div
                variants={variants}
                initial="initial"
                animate="enter"
                exit="exit"
              >
                {
                  !this.state.isNewList
                    ? this.renderStepOne(status)
                    : <StepTwo {...stepTwoProps}/>
                }
              </motion.div>
            }
          </AnimatePresence>
        </div>
      </GardenDrawer>
    )
  }
}

export default AddToWishlist
