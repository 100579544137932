import { toast } from '@leroy-merlin-br/backyard-react'
import cookies from 'js-cookie'
import { ChangeEvent, FocusEvent, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useQuery, useStores } from 'user/shared/hooks'
import { prependBrazilCountryCode } from 'user/signup/utils'
import {
  birthdateErrorMessage,
  handlePhoneFormat,
  phoneValidation
} from 'user/utils'

import * as validator from 'utils/validators/validators'

import * as services from '../../services'
import { SocialSignUp, View } from '../../types'

const FORM_DEFAULT_VALUES = {
  birthdate: '',
  favoriteStore: '',
  fiscalId: '',
  name: '',
  phone: '',
  privacy_and_usage_terms: true,
  token: ''
}

const GENERIC_ERROR_ON_SIGN_UP = {
  message: 'Ocorreu um erro ao realizar o cadastro!'
}

export const FORM_RULES = {
  name: {
    required: 'Insira seu nome',
    validate: (value: string) => validator.isValidName(value) || 'Nome inválido'
  },
  birthdate: {
    validate: (value: string) => !value || birthdateErrorMessage(value)
  },
  phone: {
    required: 'Insira seu Celular',
    validate: (value: string) =>
      validator.isCellPhone(value) || 'Celular inválido'
  }
}

export const useSocialSignUp = (props: View) => {
  const {
    clearErrors,
    control,
    formState,
    handleSubmit,
    setError,
    setValue,
    trigger
  } = useForm({
    mode: 'onBlur',
    defaultValues: FORM_DEFAULT_VALUES
  })

  const { stores } = useStores({
    onHasStore: (store: string) => {
      setValue('favoriteStore', store)
    }
  })

  const [isContinue, setIsContinue] = useState(false)

  const [checkingFiscalId, setCheckingFiscalId] = useState(false)

  const [isSubmitting, setIsSubmitting] = useState(false)

  const [email, setEmail] = useState('')

  const query = useQuery()

  const handleBirthdateFormatBlur = ({
    target
  }: FocusEvent<HTMLInputElement>) => {
    if (!target.value) {
      return
    }

    const message = birthdateErrorMessage(target.value)

    if (typeof message === 'string') {
      return setError('birthdate', { message })
    }

    clearErrors('birthdate')
  }

  const handlePhoneFormatBlur = async ({
    target
  }: FocusEvent<HTMLInputElement>) => {
    const error = phoneValidation(target.value)

    if (error.message) {
      return setError('phone', error)
    }

    clearErrors('phone')

    await trigger('phone')
  }

  const checkFiscalIdOnService = async (
    fiscalId: string,
    redirect: string | null
  ) => {
    setCheckingFiscalId(true)

    try {
      await services.checkFiscalId(fiscalId, redirect)

      setIsContinue(true)
    } catch (error) {
      const errorResponse = error as { data?: { errors: { message: string }[] } }

      const genericError = { message: 'Ocorreu um erro ao validar o CPF' }

      const [displayError] = errorResponse?.data?.errors || [genericError]

      setError('fiscalId', displayError)
    } finally {
      setCheckingFiscalId(false)
    }
  }

  const checkFiscalIdOnChange = async ({
    target
  }: ChangeEvent<HTMLInputElement>) => {
    const rawFiscalId = target.value.replace(/[^\d]/g, '')

    if (rawFiscalId.length === 11) {
      await trigger('fiscalId')

      if (validator.isCpf(rawFiscalId)) {
        const redirect = query.get('redirect')

        checkFiscalIdOnService(rawFiscalId, redirect)
      } else {
        setError('fiscalId', { message: 'CPF inválido' })

        setIsContinue(false)
      }
    }
  }

  const onFormSubmit = async (values: SocialSignUp) => {
    setIsSubmitting(true)

    values.phone = prependBrazilCountryCode(values.phone)
    values.token = props.token

    try {
      const { data } = await services.addSocialSignUp(props.provider, values)

      if (data.token.accessToken) {
        cookies.set('jwt_before_session', data.token.accessToken)

        const location: Location = '/cadastre-se/sucesso' as unknown as Location

        window.location = location
      } else {
        toast.critical(GENERIC_ERROR_ON_SIGN_UP.message, { variant: 'solid' })

        setIsSubmitting(false)
      }
    } catch (error) {
      const errorResponse = error as { data?: { errors: { message: string }[] } }

      const [validationError] = errorResponse?.data?.errors || []

      toast.critical((validationError || GENERIC_ERROR_ON_SIGN_UP).message, {
        variant: 'solid'
      })

      setIsSubmitting(false)
    }
  }

  useEffect(() => {
    if (props.name) {
      setValue('name', props.name, { shouldValidate: true })
    }
  }, [props.name, setValue, trigger])

  useEffect(() => {
    setEmail(props.email)
  }, [props.email, setEmail])

  return {
    checkingFiscalId,
    checkFiscalIdOnChange,
    control,
    formState,
    email,
    handleBirthdateFormatBlur,
    handlePhoneFormat,
    handlePhoneFormatBlur,
    isContinue,
    isSubmitting,
    onSubmit: handleSubmit(onFormSubmit),
    stores
  }
}
