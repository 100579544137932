import type { SVGProps } from 'react'

interface IconLoyaltyProps extends SVGProps<SVGSVGElement> {}

export default function IconLoyalty(props: IconLoyaltyProps) {
  return (
    <svg
      width="71"
      height="34"
      viewBox="0 0 71 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_774_59945)">
        <path
          d="M0.0947266 10.8558V7.28076H0.911307V10.1727H2.44189V10.8558H0.0947266Z"
          fill="#3C3738"
        />
        <path
          d="M3.25049 10.8558V7.28076H5.5664V7.96389H4.06707V8.73534H5.37007V9.41846H4.06707V10.1751H5.69222V10.8582L3.25049 10.8558Z"
          fill="#3C3738"
        />
        <path
          d="M9.19795 10.8556H8.23152L7.63131 9.65275H7.25066V10.8564H6.43408V7.28057H7.74269C7.91408 7.27928 8.08463 7.3038 8.24835 7.35325C8.40088 7.39999 8.54382 7.47248 8.67066 7.56742C8.7985 7.66474 8.89956 7.79151 8.96476 7.93634C9.03815 8.09929 9.0745 8.27581 9.07134 8.45376C9.07773 8.67482 9.02233 8.8934 8.91107 9.08608C8.80401 9.26242 8.64709 9.40489 8.4591 9.49643L9.19795 10.8556ZM7.25066 7.96369V8.9665H7.66737C8.04133 8.9665 8.22831 8.79741 8.22831 8.45923C8.23414 8.39056 8.22438 8.32148 8.19972 8.25691C8.17507 8.19234 8.13613 8.13385 8.08567 8.08562C7.97725 7.99956 7.83979 7.95607 7.70022 7.96369H7.25066Z"
          fill="#3C3738"
        />
        <path
          d="M12.2569 10.4415C11.9753 10.763 11.5971 10.9237 11.1221 10.9237C10.6472 10.9237 10.269 10.763 9.98743 10.4415C9.70642 10.1195 9.56592 9.66039 9.56592 9.06428C9.56592 8.46817 9.70642 8.01145 9.98743 7.69412C10.2679 7.37366 10.6461 7.21317 11.1221 7.21265C11.5982 7.21213 11.9764 7.37262 12.2569 7.69412C12.5373 8.01458 12.6776 8.4713 12.6776 9.06428C12.6776 9.65726 12.5373 10.1163 12.2569 10.4415ZM11.1221 10.2226C11.2215 10.2241 11.3198 10.2026 11.409 10.1601C11.4983 10.1128 11.5754 10.0463 11.6342 9.96548C11.7098 9.85639 11.7621 9.73359 11.7881 9.60437C11.8269 9.4269 11.8452 9.24573 11.8426 9.06428C11.8451 8.88412 11.8269 8.70426 11.7881 8.52809C11.7621 8.39913 11.7098 8.27659 11.6342 8.16777C11.5749 8.08659 11.4966 8.0204 11.4058 7.97472C11.3165 7.93242 11.2182 7.91127 11.1189 7.91297C11.0143 7.91027 10.9107 7.93395 10.8182 7.98174C10.7257 8.02953 10.6474 8.0998 10.5908 8.18575C10.4616 8.36709 10.3972 8.65967 10.3977 9.0635C10.395 9.24487 10.4128 9.42599 10.4506 9.60359C10.4758 9.73263 10.5273 9.85542 10.6021 9.96469C10.6611 10.0459 10.7387 10.1126 10.8289 10.1593C10.9201 10.2028 11.0206 10.2245 11.1221 10.2226Z"
          fill="#3C3738"
        />
        <path
          d="M14.2231 10.8558V9.60522L12.9609 7.28149H13.8857L14.635 8.77359L15.3714 7.28149H16.2962L15.0397 9.60444V10.855L14.2231 10.8558Z"
          fill="#3C3738"
        />
        <path
          d="M17.7935 10.8558V7.28076H18.6685L19.6606 9.07846L20.6623 7.28076H21.5334V10.8558H20.7136V8.67984L19.9924 10.0649H19.3337L18.6124 8.67984V10.8558H17.7935Z"
          fill="#3C3738"
        />
        <path
          d="M22.3721 10.8558V7.28076H24.688V7.96389H23.1862V8.73534H24.4893V9.41846H23.1862V10.1751H24.8114V10.8582L22.3721 10.8558Z"
          fill="#3C3738"
        />
        <path
          d="M28.3176 10.8556H27.3511L26.7517 9.65275H26.3703V10.8564H25.5537V7.28057H26.8599C27.0313 7.27928 27.2019 7.3038 27.3656 7.35325C27.5181 7.39999 27.661 7.47248 27.7879 7.56742C27.9157 7.66474 28.0168 7.79151 28.082 7.93634C28.1554 8.09929 28.1917 8.27581 28.1886 8.45376C28.195 8.67482 28.1396 8.8934 28.0283 9.08608C27.9225 9.26175 27.7673 9.40416 27.5811 9.49643L28.3176 10.8556ZM26.3703 7.96369V8.9665H26.7878C27.1618 8.9665 27.3487 8.79741 27.3487 8.45923C27.3546 8.39056 27.3448 8.32148 27.3202 8.25691C27.2955 8.19234 27.2566 8.13385 27.2061 8.08562C27.0979 7.99974 26.9608 7.95627 26.8215 7.96369H26.3703Z"
          fill="#3C3738"
        />
        <path
          d="M28.8633 10.8558V7.28076H29.6799V10.1727H31.2104V10.8558H28.8633Z"
          fill="#3C3738"
        />
        <path
          d="M32.0239 10.8558V7.28076H32.8405V10.8558H32.0239Z"
          fill="#3C3738"
        />
        <path
          d="M33.6802 10.8558V7.28076H34.4399L35.835 9.43175V7.28076H36.654V10.8558H35.8943L34.4968 8.70016V10.8558H33.6802Z"
          fill="#3C3738"
        />
        <path
          d="M9.15873 21.1799C9.09336 21.4153 9.00563 21.6443 8.89668 21.8638C8.19069 23.2903 6.62164 24.2727 4.84503 24.2727C2.10761 24.2727 4.61849e-05 22.4289 4.61849e-05 18.8749C-0.00977911 18.0914 0.115926 17.3119 0.371875 16.5692C0.39271 16.5121 0.415949 16.4559 0.437586 16.3996C0.448003 16.3746 0.456818 16.348 0.467236 16.3214C0.477654 16.2949 0.493681 16.262 0.507304 16.2323C0.563398 16.1034 0.622699 15.9775 0.686807 15.8572L0.737292 15.7696C1.58352 14.2689 3.08286 13.4561 4.84503 13.4561C7.17297 13.4561 8.68753 14.8801 9.03051 16.2964C9.04173 16.3425 9.05134 16.3879 9.06016 16.4332L7.80444 16.7834L6.7162 17.0866C6.4205 16.2417 5.7706 15.5883 4.74647 15.5883C3.50838 15.5883 2.73106 16.6427 2.58281 18.2911C2.56572 18.4782 2.55717 18.6723 2.55717 18.8734C2.5555 19.2274 2.58499 19.581 2.64532 19.9301C2.88572 21.2979 3.62938 22.139 4.74326 22.139C5.80746 22.139 6.51666 21.5817 6.89009 20.7367L8.25881 21.0033L9.15873 21.1799ZM36.6163 17.3766V24.0203H34.1145V17.8948C34.1145 16.4731 33.3067 15.8009 32.3419 15.8009C31.3771 15.8009 30.53 16.4926 30.53 17.9722V24.0234H28.029V17.8948C28.029 16.4731 27.2413 15.8009 26.2757 15.8009C25.2908 15.8009 24.4438 16.5121 24.4438 17.9894V24.0218H21.9427V15.8353H20.8873V13.707H24.3853V14.8598C24.7068 14.4297 25.1271 14.079 25.6124 13.8355C26.0977 13.592 26.6347 13.4626 27.1804 13.4576C28.3023 13.4576 29.3072 13.9188 29.8192 14.956C30.4499 14.0337 31.5526 13.4576 32.8331 13.4576C34.7828 13.4576 36.6147 14.6871 36.6147 17.3766H36.6163ZM15.0879 22.1405C13.6695 22.1405 12.9011 21.0072 12.9011 18.8749C12.9011 16.7232 13.6695 15.5922 15.0879 15.5922C16.5063 15.5922 17.274 16.7255 17.274 18.8749C17.274 21.0072 16.5055 22.1405 15.0879 22.1405ZM15.0879 13.4576C12.2912 13.4576 10.3415 15.2631 10.3415 18.8749C10.3415 22.4477 12.2912 24.2727 15.0879 24.2727C17.9039 24.2727 19.8344 22.4477 19.8344 18.8749C19.8344 15.2631 17.9039 13.4576 15.0879 13.4576Z"
          fill="#3C3738"
        />
        <path
          d="M66.231 27.7339C66.7941 27.7347 67.3547 27.66 67.897 27.512C69.0469 27.172 69.7553 26.5795 70.2514 25.5181C70.8364 24.2675 70.9726 22.7629 70.9726 21.3818V19.1151C70.9726 17.1869 71.0904 15.2266 70.9934 13.3031C70.9619 12.6266 70.7829 11.9645 70.4685 11.3608C70.2127 10.8811 69.8803 10.4442 69.4837 10.0664C68.9997 9.59979 68.489 9.16034 67.9539 8.75021L56.6989 0.318991C56.4332 0.119209 56.1079 0.00943825 55.7725 0.00634766H55.7469C55.4116 0.00937983 55.0866 0.119166 54.8213 0.318991L43.5695 8.75334C43.0341 9.16346 42.5231 9.60291 42.0389 10.0696C41.6428 10.4476 41.3106 10.8844 41.0548 11.3639C40.7404 11.9677 40.5613 12.6298 40.5291 13.3062C40.433 15.2298 40.55 17.19 40.55 19.1183V21.3849C40.55 22.766 40.6838 24.2698 41.2712 25.5212C41.7664 26.5826 42.4732 27.1751 43.6248 27.5151C44.1674 27.663 44.7282 27.7377 45.2916 27.7371L66.231 27.7339Z"
          fill="#7808CF"
        />
        <path
          d="M57.6157 12.326L61.1673 8.86108L64.7197 12.326H57.6157Z"
          fill="#78BE20"
        />
        <path
          d="M63.4816 20.6987C63.1074 21.5436 62.399 22.1009 61.3348 22.1009C59.9565 22.1009 59.1487 20.8136 59.1487 18.8353C59.1487 16.857 59.9565 15.5525 61.3348 15.5525C62.3589 15.5525 63.0088 16.2052 63.3045 17.0509L65.6453 16.3951C65.3696 14.9351 63.8334 13.418 61.4302 13.418C58.6927 13.418 56.5852 15.3775 56.5852 18.8353C56.5852 22.3893 58.6927 24.2331 61.4302 24.2331C63.4784 24.2331 65.251 22.927 65.7439 21.1403L63.4816 20.6987ZM53.7364 13.6642H56.2374L52.3188 23.9814H49.5613L46.3495 15.5307H44.9575V13.6673H48.2022L50.9805 21.4474L53.7364 13.6642Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_774_59945">
          <rect width="71" height="34" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
