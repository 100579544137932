import { Constants } from '.'

export const getProfile = () => ({
  type: Constants.USER_PROFILE_GET
})

export const getSuccess = payload => ({
  type: Constants.USER_PROFILE_GET_SUCCESS,
  payload
})

export const getFailure = error => ({
  type: Constants.USER_PROFILE_GET_FAILURE,
  error
})
