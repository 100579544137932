export const FAQ_DATA = [
  {
    title: 'Como se cadastrar no Programa de Fidelidade Leroy Merlin Com Você?',
    text: `Para se cadastrar no Programa de Fidelidade Leroy Merlin Com Você,
    basta acessar o site <a href="/fidelidade">www.leroymerlin.com.br/fidelidade</a>
    ou entrar no App, na aba "Programa de Fidelidade", clicar em "eu quero"
    e seguir o passo a passo. Para ser cadastrado no Programa, é preciso estar
    cadastrado no site também.`
  },
  {
    title: 'O que é a "loja favorita"?',
    text: `A loja favorita é identificada no ato do cadastro, de acordo com a sua
    preferência. Ela serve para você ter acesso a todos os descontos exclusivos da
    loja que você mais gosta. Caso você queira alterar a sua loja, basta acessar
    “Meus dados”, verificar se a sua localização está indicando o local que você
    está naquele momento e escolher a sua preferida.`
  },
  {
    title: 'Para que é feita a identificação da profissão?',
    text: `Os profissionais da construção civil que indicam a profissão passam a fazer
    parte do programa de fidelidade PRO e recebem benefícios personalizados
    para a sua profissão.`
  },
  {
    title: 'Como anexar o documento e o órgão de classe?',
    text: `A anexação do documento é feita pelos engenheiros, arquitetos e decoradores
    no cadastro, ao identificar a profissão. Caso o número do órgão de classe e a
    foto do documento não sejam anexados no ato do cadastro, é necessário
    acessar a aba "Clientes" dentro do Programa de Fidelidade, clicar em "Enviar
    documento", preencher com essas informações e anexar a imagem.`
  },
  {
    title:
      'Em quais pontos de venda o Programa de Fidelidade pode ser utilizado?',
    text: `O Leroy Merlin Com Você pode ser utilizado em todos os pontos de venda
    da Leroy Merlin, sendo eles: site, app, lojas físicas, televendas e WhatsApp.`
  },
  {
    title: 'Como redefinir a senha cadastrada na Leroy Merlin?',
    text: `Para alterar a senha cadastrada no site da Leroy Merlin é preciso
    clicar no botão "esqueceu a senha?", preencher o campo disponível com o e-mail
    ou o CPF cadastrados e apertar o botão enviar. O link para redefinir a senha
    será encaminhado para o e-mail registrado no site em até 24 horas.`
  },
  {
    title:
      'O que fazer caso tenha esquecido o e-mail que está cadastrado na Leroy Merlin?',
    text: `Para fazer a troca do e-mail cadastrado no site da Leroy Merlin é preciso
     entrar em contato com a Central de Atendimento, através do número 4020-5376
     para capital ou 0800-020-5376 para as demais regiões, e solicitar a troca de e-mail.`
  },
  {
    title: 'Quais são as vantagens do Leroy Merlin Com Você?',
    text: `Os principais benefícios são: pontos acumulativos que podem ser trocados por
    cashback de até R$150, desconto de 5% no mês do seu aniversário e descontos em
    seção, subseção e produto concedidos pelo Leroy Merlin Com Você.`
  },
  {
    title:
      'Em quais canais podem ser utilizadas as vantagens do Leroy Merlin Com Você?',
    text: `Os descontos exclusivos, surpresa de aniversário e cashback podem ser utilizados
    no Site, App, Lojas Físicas, WhatsApp e Televendas, dependendo das
    condições descritas em cada um dos benefícios`
  },
  {
    title: 'O que é o “Minhas Vantagens”?',
    text: `O Minhas Vantagens é a parte do site ou App em que ficam os cupons de desconto
      em porcentagem concedidos pelo Programa de Fidelidade. Para resgatá-los basta clicar em
      "Ativar" dentro do perfil logado no Leroy Merlin Com Você. Após essa dinâmica, basta
      identificar a compra com o CPF. Não é preciso utilizar pontos para ativá-los.`
  },
  {
    title:
      'Qual o prazo para utilização dos descontos fornecidos pelo Leroy Merlin Com Você?',
    text: `Os descontos têm o prazo de validade determinado no cupom. Após o período estabelecido,
     os cupons de desconto saem automaticamente da conta.`
  },
  {
    title:
      'É preciso utilizar pontos para ativar um cupom de desconto em porcentagem?',
    text: `Não é preciso utilizar pontos para resgatar cupons em porcentagem concedido pelo
    Leroy Merlin Com Você. É preciso utilizar pontos apenas para resgatar um cashback.`
  },
  {
    title: 'O que são pontos e como funcionam?',
    text: `Quando você é cadastrado no Leroy Merlin Com Você, todas as suas compras se tornam pontos.
     A cada R$1,00 em compras, você ganha 1 ponto e pode trocar por cashback. Os pontos acumulados ficam
     válidos por até 3 meses, a partir do mês de acúmulo. Os pontos não resgatados
     neste período serão expirados automaticamente.`
  },
  {
    title: 'É possível acompanhar o saldo de pontos dentro do site?',
    text: `Sim. Para isso, é preciso entrar no Programa de Fidelidade
    pelo site www.leroymerlin.com.br/fidelidade ou App, clicar na aba “Meus
    Pontos” e descer a tela até o título “Saldo de Pontos”. Nesta parte é possível
    verificar todos os pontos que foram adquiridos nas compras e os que foram
    utilizados para resgatar cashback.`
  },
  {
    title: 'Por quais canais de compras é possível adquirir pontos?',
    text: `Para receber pontos é preciso se cadastrar no Leroy Merlin Com Você e
    comprar na Leroy Merlin através das lojas físicas, e-commerce,
    televendas, Serviço de Instalação ou App, identificando a compra
    com o CPF. Não é possível acumular pontos em serviços e formas de
    pagamento como: Garantia Estendida, Vale-trocas, cashback, Frete e Cartão Presente.`
  },
  {
    title: 'Qual o prazo para receber os pontos no perfil logado do LMCV?',
    text: `Os pontos serão atribuídos à conta em até 10 dias após a realização da
    compra. É possível acompanhar a pontuação dentro do perfil logado no site
    www.leroymerlin.com.br/fidelidade ou no App, na aba "Pontos", dentro do campo
    "Saldo de pontos".`
  },
  {
    title: 'O que é o cashback em pontos?',
    text: `O cashback é o cupom em dinheiro obtido através da troca de pontos.
    Para resgatá-lo é preciso ter 1.000 pontos para um cashback de R$10,00,
    2.500 pontos para um cashback de R$35,00 e 7.500 pontos para um cashback de R$150,00.
    O cashback precisa ser ativado no site ou App, dentro do perfil logado no Programa de Fidelidade,
    na aba "troque". Após a ativação, o cashback pode ser utilizado mediante a identificação
    da compra com o CPF. O prazo de validade após ativo é de 30 dias.`
  },
  {
    title: 'Como trocar os pontos por um cashback?',
    text: `O resgate de cashback utilizando pontos é feito no site
    www.leroymerlin.com.br/fidelidade ou no App, dentro do perfil logado. Para ter
    acesso aos cashbacks disponíveis é preciso entrar na aba "Troque" e clicar em "Ativar",
    se houver pontos suficientes. A pontuação mínima para resgatar um cashback é
    1.000 pontos. Após o resgate, o cashback é válido por 30 dias.`
  },
  {
    title: 'Os pontos adquiridos podem ser doados para outra pessoa?',
    text: `Não. Os pontos adquiridos através das compras são pessoais e intransferíveis.
    Não é permitida a doação, transferência ou qualquer forma de troca de pontos entre Clientes.`
  },
  {
    title: 'Qual o prazo de validade dos pontos adquiridos nas compras?',
    text: `Os pontos acumulados têm validade por até 3 meses, a partir do mês de acúmulo.
    Os pontos não resgatados neste período serão expirados automaticamente.`
  }
]
