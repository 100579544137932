import { ModalProvider } from '@leroy-merlin-br/backyard-react'

import { Routes } from './routes'

const App = () => {
  return (
    <ModalProvider>
      <Routes />
    </ModalProvider>
  )
}

export default App
