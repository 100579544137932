import { ChevronRight } from '@leroy-merlin-br/backyard-icons'
import {
  Heading,
  Text,
  Stack,
  Icon,
  Inline
} from '@leroy-merlin-br/backyard-react'
import { CardIcon, BaseModal } from 'user/celebre/components'

import { REASONS } from './constants'

const RejectedCardModal = ({ onClose }) => (
  <BaseModal onClose={onClose}>
    <CardIcon appearance="critical" />

    <Stack space="giga">
      <Heading as="h3" size="tera" noMargin>
        Parece que você não teve crédito pré aprovado :(
      </Heading>

      <Text size="mega" noMargin>
        As propostas são analisadas pelo nosso parceiro Cetelem de forma
        criteriosa para a aprovação do crédito, veja os{' '}
        <Text size="mega" as="strong" isBold noMargin>
          principais motivos da não aprovação
        </Text>
        :
      </Text>

      <Stack space="mega">
        {REASONS.map(({ id, text }) => (
          <Inline key={id} alignY="baseline" space="mega">
            <Icon as={ChevronRight} size="kilo" color="info" />
            <Text size="mega" noMargin isBold>
              {text}
            </Text>
          </Inline>
        ))}
      </Stack>

      <Text size="mega" noMargin>
        Você pode fazer uma nova solicitação em até 30 dias após o seu último
        pedido para uma reavaliação. Para mais infomações entre em contato com a
        central Cetelem via WhatsApp (11) 9564 0983.
      </Text>
    </Stack>
  </BaseModal>
)

export default RejectedCardModal
