import emitter from 'scripts/utils/emitter'
import BaseComponent from 'scripts/components/base-component'

class StockChecker extends BaseComponent {
  static componentName = 'stock-checker'

  static DOMEvents = {
    click: 'onElementClick'
  }

  static emitterEvents = {
    'buybox:sellerChange': 'changeDisabled'
  }

  onElementClick () {
    emitter.emit('showStock')
  }

  changeDisabled ({ purchaseButtonPickupInStoreDisabled }) {
    this.$element[0].disabled = (purchaseButtonPickupInStoreDisabled === 'true')
  }
}

export default ($el) => new StockChecker($el)

export { StockChecker as Component }
