import { createContext, useContext, useState, ReactNode } from 'react'

type MyAccountPersonalDataContextType = {
  contextData: FormValuesType
  updateData: (response: FormValuesType) => void
}

export type FormValuesType = {
  name: string
  fiscalId: string
  birthdate: string
}

type MyAccountPersonalDataContextProviderProps = {
  children: ReactNode
}

const MyAccountPersonalDataContext = createContext<
  MyAccountPersonalDataContextType | Record<string, never>
>({})

const FORM_VALUES = {
  name: '',
  fiscalId: '',
  birthdate: ''
}

export const MyAccountPersonalDataContextProvider = ({
  children
}: MyAccountPersonalDataContextProviderProps) => {
  const [contextData, setContextData] = useState<FormValuesType>(FORM_VALUES)

  const value = {
    contextData,
    updateData: setContextData
  }

  return (
    <MyAccountPersonalDataContext.Provider value={value}>
      {children}
    </MyAccountPersonalDataContext.Provider>
  )
}

export const useMyAccountPersonalDataContext = () => useContext(MyAccountPersonalDataContext)
