import { css } from '@emotion/react'
import styled from '@emotion/styled'

const soldOutProductMessageStyles = ({ theme }) => css`
  label: wrapper;

  border-bottom: ${theme.spacings.bit} solid ${theme.colors.n300};
  background: ${theme.colors.n100};

  display: flex;
  flex-flow: row wrap;
  align-items: center;

  justify-content: center;

  padding: ${theme.spacings.mega} 0;

  p {
    flex-basis: 0;
    text-align: center;
    font-size: 19px;

    ${theme.media.giga} {
      font-size: ${theme.typography.text.mega.fontSize};
    }
  }
`
export const SoldOutProductMessage = styled('div')(soldOutProductMessageStyles)
