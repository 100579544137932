import { css } from '@emotion/react'
import styled from '@emotion/styled'

const mainWrapperStyles = ({ theme }) => css`
  label: price-range__main-wrapper;

  margin-bottom: ${theme.spacings.bit};

  ${theme.media.mega} {
    padding-right: ${theme.spacings.kilo};
  }

  input {
    padding: ${theme.spacings.kilo} ${theme.spacings.byte};
  }
`

export const MainWrapper = styled('div')(mainWrapperStyles)
