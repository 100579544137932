import { useContext } from 'react'
import PropTypes from 'prop-types'
import { Badge } from '@leroy-merlin-br/backyard-react'

import { ShapeThumb } from 'shared/constants/prop-types'
import { HeartIcon } from 'shared/components/HeartIcon'
import { Tag } from 'shared/icons'

import { WishlistContext } from 'site/Wishlist/context'

import * as S from './styled'

const ThumbFlags = ({ data: { id, inWishList, products } }) => {
  const { onPressHeart, isOnWishlist } = useContext(WishlistContext)

  const wishlistAction = () => onPressHeart({ inWishList, id }, 'contents')

  return (
    <S.FlagsWrapper>
      <S.FlagsOffers>
        <Tag width={24} height={24} />

        <S.BadgeWrapper>
          <Badge apperance="primary">{products.length}</Badge>
        </S.BadgeWrapper>
      </S.FlagsOffers>

      <HeartIcon onClick={wishlistAction} isActive={isOnWishlist(id)} />
    </S.FlagsWrapper>
  )
}

ThumbFlags.propTypes = {
  data: PropTypes.shape({ ...ShapeThumb }).isRequired
}

export default ThumbFlags
