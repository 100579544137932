import { tv } from 'tailwind-variants'

const styles = tv({
  base: `
      w-64
      h-5
      overflow-hidden
      bg-gray-200
      rounded-3xl
  `,
  slots: {
    fill: `
      block
      bg-green-500
      rounded-3xl
      h-full
    `
  },
  variants: {
    size: {
      sm: `
        w-32
        h-2
      `,
      md: `
        w-64
        h-5
      `
    }
  }
})

export default styles
