import { Text } from '@leroy-merlin-br/backyard-react'

import { AlertMessage } from 'shared/components/AlertMessage'

import { ShippingAddress } from 'checkout/components/MyOrders/components/ShippingAddress'
import FullBadge from 'checkout/components/CrossServices/Installation/Icons/SquareBadge'

import { SERVICE_SHIPPING_TYPE } from '../consts'
import * as S from './styled'

const ServiceContent = ({ address, ...props }) => (
  <S.Wrapper>
    <div className="item-box margin-top-small">
      <div className="item-thumb">
        <S.ServiceThumb>
          <FullBadge />
        </S.ServiceThumb>
        <div>
          <span className="color-primary">{props.name}</span>

          {!props.isStandaloneService && (
            <div className="margin-top-small small shop-name">
              <div className="color-default">
                <Text as="strong" size="kilo" isBold noMargin>
                  Para o produto
                </Text>
                : {props.relatedProduct.name}
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="items-infos">
        <div className="infos-box">
          <div className="item">
            <div className="item-title">Qtde.</div>
            <span>{props.quantity}</span>
          </div>
          <div className="item">
            <div className="item-title">Valor unit.</div>
            <span>
              {' '}
              R$ {props.price.unit.integers}, {props.price.unit.decimals}
            </span>
          </div>
          <div className="item">
            <div className="item-title">Subtotal</div>
            <span>
              {' '}
              R$ {props.price.subTotal.integers},{' '}
              {props.price.subTotal.decimals}
            </span>
          </div>
          <div className="item"></div>
        </div>
      </div>
    </div>

    <ShippingAddress addressType={SERVICE_SHIPPING_TYPE} {...address} />

    {!props.isStandaloneService && (
      <AlertMessage type="info">
        <Text as="strong" size="kilo" isBold noMargin>
          Para a instalação
        </Text>
        : A instalação ocorrerá{' '}
        <Text as="strong" size="kilo" isBold noMargin>
          a partir de 3 dias úteis após o recebimento dos produtos
        </Text>
        . Você receberá um{' '}
        <Text as="strong" size="kilo" isBold noMargin>
          SMS para agendar dia e horário{' '}
        </Text>
        da instalação do(s) serviço(s) contratado(s). Fique atento às mensagens
        recebidas no número cadastrado.
      </AlertMessage>
    )}
  </S.Wrapper>
)

export default ServiceContent
