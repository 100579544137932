import { isBirthdate } from 'utils/validators/validators'
import { isAcceptedAge } from 'utils/date-helpers/date-helpers'

export const birthdateErrorMessage = (value: string): boolean | string => {
  if (!isBirthdate(value)) {
    return 'Data de nascimento inválida'
  }

  if (!isAcceptedAge(value)) {
    return 'Você precisa ter entre 18 e 100 anos para participar'
  }

  return true
}
