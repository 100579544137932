import UUID from 'pure-uuid'

import BaseComponent from 'scripts/components/base-component'

class Tracking extends BaseComponent {
  static componentName = 'clearsales-tracking'

  init () {
    this.APP_CODE = 'sdt694e4avf0ds71rr4a'
    this.SESSION_ID = this.getId() || this.generateId()

    this.loadScript()
    this.callTracking()
  }

  loadScript () {
    (function (a, b, c, d, e, f, g) {
      a.CsdpObject = e; a[e] = a[e] || function () {
        (a[e].q = a[e].q || []).push(arguments)
      }

      a[e].l = 1 * new Date()

      f = b.createElement(c)
      g = b.getElementsByTagName(c)[0]
      f.async = 1
      f.src = d
      g.parentNode.insertBefore(f, g)
    })(window, document, 'script', '//device.clearsale.com.br/p/fp.js', 'csdp')
  }

  callTracking () {
    window.csdp('app', this.APP_CODE)
    window.csdp('sessionid', this.SESSION_ID)
  }

  getId () {
    return sessionStorage.getItem('user-tracking-id')
  }

  generateId () {
    const ID = new UUID(1)
    sessionStorage.setItem('user-tracking-id', ID)
    return generateId
  }
}

export default ($el) => new Tracking($el).init()

export { Tracking as Component }
