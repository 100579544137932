const TruckDelivery = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    width="16"
    height="16"
    fill="currentColor"
  >
    <path d="M14.702 6.909c.393.078.75.284 1.014.585a1.361 1.361 0 0 1 .229 1.215 46.173 46.173 0 0 1-.48 1.602c-.165.53-.306.982-.306 1.02a.694.694 0 0 1-.658.485h-1.334a1.91 1.91 0 0 1-3.563 0H7.986a1.92 1.92 0 0 1-3.572 0h-.622a.723.723 0 0 1-.539-.256.74.74 0 0 1-.137-.585L4.523 4.58A1.891 1.891 0 0 1 6.26 2.962h4.34a1.828 1.828 0 0 1 1.654 1.142h.914a1.928 1.928 0 0 1 1.471.685 1.5 1.5 0 0 1 .347 1.206 6.571 6.571 0 0 1-.283.914Zm-8.707 4.713c.067.027.138.04.21.038a.528.528 0 0 0 .539-.539.54.54 0 0 0-.927-.387.548.548 0 0 0-.119.596.54.54 0 0 0 .297.292Zm5.181 0c.067.027.138.04.21.038a.538.538 0 0 0 .548-.539.548.548 0 1 0-.936.385c.051.05.112.09.178.116Zm2-1.186h.832c.183-.63.511-1.69.658-2.083-.028-.092-.293-.201-.805-.201a.676.676 0 0 1-.657-.576.569.569 0 0 0 0-.064V7.44a.303.303 0 0 1 0-.128c.045-.189.125-.422.215-.682l.004-.012a7.82 7.82 0 0 0 .265-.914.52.52 0 0 0-.466-.265h-.914l-.63 2.458a.676.676 0 0 1-.832.493.685.685 0 0 1-.493-.831l.694-2.851a.42.42 0 0 0-.447-.329H6.26c-.202 0-.394.347-.394.54L4.77 9.905a1.892 1.892 0 0 1 1.434-.658 1.91 1.91 0 0 1 1.782 1.188h1.627a1.9 1.9 0 0 1 3.563 0ZM2.87 6.507H1.417a.685.685 0 1 1 0-1.37h1.452a.685.685 0 1 1 0 1.37Zm-.658.302H1.142a.685.685 0 1 0 0 1.37h1.07a.685.685 0 0 0 0-1.37Zm-1.526 1.7H2.13a.685.685 0 0 1 0 1.37H.685a.685.685 0 0 1 0-1.37Z" />
  </svg>
)

export default TruckDelivery
