import request from './axios'
import { API_ENDPOINTS } from './constants'

export const createProject = async payload => {
  const response = await request
    .post(API_ENDPOINTS.customers, payload)
    .then(({ data }) => data)
    .catch(err => {
      throw err.response
    })

  return response
}

export const sendInvite = async payload => {
  const response = await request
    .post(API_ENDPOINTS.customerInvite, payload)
    .then(({ data }) => data)
    .catch(err => {
      throw err.response
    })

  return response
}
