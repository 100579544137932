import { tv } from 'tailwind-variants'

const styles = tv({
  base: `
    flex
    justify-center
    items-center
    border
    p-4
    border-gray-200
    cursor-pointer
    relative

    [&_img]:max-h-full
    [&_img]:max-w-full
  `,
  slots: {
    playContainer: `
      absolute
      flex
      justify-center
      items-center
    `
  },
  variants: {
    size: {
      sm: `
        w-40
        h-40
      `,
      md: `
        w-52
        h-52
      `
    }
  }
})

export default styles
