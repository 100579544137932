export const Time = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 0C9.30843 0 12 2.6916 12 6.00002C12 9.30844 9.30843 12 6 12C2.69157 12 0 9.30844 0 6.00002C0 2.6916 2.69157 0 6 0ZM6 0.751192C3.10577 0.751192 0.751195 3.1058 0.751195 6.00002C0.751195 8.8942 3.10577 11.2488 6 11.2488C8.89423 11.2488 11.2488 8.8942 11.2488 6.00002C11.2488 3.1058 8.89423 0.751192 6 0.751192ZM6.38196 1.58133V5.82398L8.45851 7.55259L7.97792 8.12993L5.76607 6.28871C5.68035 6.21735 5.63077 6.11156 5.63077 6V1.58133H6.38196Z"
        fill="currentColor"
      />
    </svg>
  )
}
