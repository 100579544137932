import { Button, Card, Stack, Text } from '@leroy-merlin-br/backyard-react'

import { SeparatingEdge } from '../'
import * as S from './styled'

const MyOrdersCard = ({ orderId = '' }) => (
  <Card target="card" shadow="triple">
    <Stack space="mega">
      <S.TitleWrapper>
        <Text as="span" size="mega" color="n900" isBold noMargin>
          Acompanhe seu pedido
        </Text>

        <Text as="span" size="mega" color="p600" isBold noMargin>
          #{orderId}
        </Text>
      </S.TitleWrapper>

      <SeparatingEdge />
    </Stack>

    <S.ContentWrapper>
      <Text size="mega" color="n900" noMargin>
        Acompanhe e veja os detalhes da sua compra na área de pedido.
      </Text>

      <Button as="a" href="/meus-pedidos" size="mega" isStretch>
        Ver meus pedidos
      </Button>
    </S.ContentWrapper>
  </Card>
)

export default MyOrdersCard
