import axios from 'axios'

export const getProductsAds = async props => {
  const { context, sessionId, userId, productId, categoryName, term } = props

  const baseHost = window.env.newTail.baseHost
  const publisherId = window.env.newTail.publisherId

  const endpoint = `${baseHost}rma/${publisherId}`

  return await axios.post(endpoint, {
    context,
    product_sku: productId && String(productId),
    category_name: categoryName,
    term,
    session_id: sessionId,
    user_id: userId,
    placements: {
      productsAds: { quantity: 10, types: ['product'] }
    }
  })
}

export const getBannersAds = async props => {
  const { context, sessionId, userId, productId, categoryName, size, term } =
    props

  const baseHost = window.env.newTail.baseHost
  const publisherId = window.env.newTail.publisherId

  const endpoint = `${baseHost}rma/${publisherId}`

  return await axios.post(endpoint, {
    context,
    product_sku: productId && String(productId),
    category_name: categoryName,
    term,
    session_id: sessionId,
    user_id: userId,
    placements: {
      fullBanner: { quantity: 1, types: ['banner'], size }
    }
  })
}
