import { Card, Heading } from '@leroy-merlin-br/backyard-react'

import { ShowMore } from 'shared/components/ShowMore'
import { Markdown } from 'shared/components/Markdown'

import * as S from './styled'

const ContentCard = ({ title, description, children }) => (
  <Card>
    <S.Wrapper>
      <Heading size="exa" as="h1" noMargin>
        {title}
      </Heading>

      <ShowMore scrollToParent showFloatButton>
        <S.ShowMoreWrapper>
          {description && <Markdown content={description} />}
          {children}
        </S.ShowMoreWrapper>
      </ShowMore>
    </S.Wrapper>
  </Card>
)

export default ContentCard
