import { Text, Icon, Heading, Stack } from '@leroy-merlin-br/backyard-react'
import { CheckmarkCircle } from '@backyard-ui/icons'
import { BaseModal } from 'user/celebre/components'
import { handlePhoneFormat } from 'user/utils'

import * as S from './styled'

const RequestSuccessModal = ({ onClose, userPhone }) => {
  const parsedUserPhone = userPhone.replace('+55', '')
  const formattedUserPhone = handlePhoneFormat(parsedUserPhone)

  return (
    <BaseModal onClose={onClose}>
      <Stack alignX="center" space="kilo">
        <S.IconWrapper>
          <Icon as={CheckmarkCircle} />
        </S.IconWrapper>
        <Heading size="tera" noMargin>
          Seu pedido de cartão foi enviado! Próximos passos no Whatsapp :)
        </Heading>
        <Text noMarign>
          Agora falta pouco! Você receberá uma mensagem via Whatsapp do nosso
          parceiro, Banco Cetelem, pelo número {formattedUserPhone} e continuará
          as etapas finais por lá, ok?
        </Text>
      </Stack>
    </BaseModal>
  )
}

export default RequestSuccessModal
