import React from 'react'

import Typography, { TypographyProps } from '../Typography'

type UserInterfaceType = Omit<TypographyProps, 'variant'> & {
  size?: 'xs' | 'sm' | 'md' | 'lg'
  type?: 'default' | 'strong'
}

function UserInterface ({
  size = 'xs',
  type,
  children,
  ...rest
}: UserInterfaceType) {
  if (React.isValidElement(children)) {
    throw new Error(
      'UserInterface component does not accept React elements as children'
    )
  }

  return (
    <Typography variant={`ui-${size}`} type={type} {...rest}>
      {children}
    </Typography>
  )
}

export default UserInterface
