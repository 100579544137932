import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { STATUS_DATA } from 'lmcv/pages/Customers/constants/statusData'

const colorStyle = (status) => {
  let currentColor = STATUS_DATA[status].color

  if (status === 10 || status === 11) {
    currentColor = STATUS_DATA[7].color
  }

  if (!currentColor) {
    return css`
      background: ${currentColor};
    `
  }

  return css`
    background: ${currentColor};
  `
}

const containerStyles = ({ theme, status }) => css`
  label: document-status__timeline;

  display: flex;
  align-items: center;
  justify-content: space-between;

  position: relative;

  width: 54.7%;
  margin: ${theme.spacings.peta} auto 0 auto;

  &::after,
  &::before {
    content: '';

    display: block;

    width: calc(50% - 30px);
    height: 6px;

    position: absolute;
    top: ${theme.spacings.tera};
    z-index: 1;
  }

  &::before {
    background: ${theme.colors.p500};

    left: ${theme.spacings.tera};
  }

  &::after {
    right: ${theme.spacings.tera};

    ${colorStyle(status)};
  }

  width: 100%;

  ${theme.media.mega} {
    width: 54.7%;
  }
`

export const Container = styled('ul')(containerStyles)
