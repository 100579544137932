import { Drawer } from 'shared/components'

import { Store } from 'newCheckout/types/PickUpInStore'

import SelectStore from './SelectStore'

type OnChangeParameters = {
  type: 'pickup-in-store' | 'delivery'
  storeId?: string | null
  availableStores?: Store[]
}

const SelectStoreDrawer = ({
  isDrawerOpen,
  overlayClick,
  stores,
  onChange,
  storeId,
  cartId
}: {
  isDrawerOpen: boolean
  overlayClick: () => void
  stores: Store[]
  onChange: (params: OnChangeParameters) => void
  storeId: string
  cartId: string
}) => (
  <Drawer
    onClose={overlayClick}
    title="Escolha a loja para retirar o seu pedido"
    isOpen={isDrawerOpen}
    dataCy="store-drawer"
  >
    <SelectStore
      stores={stores}
      onChange={onChange}
      storeId={storeId}
      cartId={cartId}
    />
  </Drawer>
)

export default SelectStoreDrawer
