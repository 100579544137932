import { useUserResources } from 'shared/hooks'

import { Kazaplan } from './components/Kazaplan'
import { WarningScreenDimensions } from './components/WarningScreenDimensions'
import { WarningOffline } from './components/WarningOffline'

const KazaplanPage = ({
  isMobileBrowser,
  credentials,
  url,
  env,
  environment,
  token
}) => {
  const { userResources } = useUserResources()
  const shouldRenderWarning = isMobileBrowser === '1'

  const { appId, partnerKey } = JSON.parse(credentials)

  const hasEnvVars = Boolean(appId && partnerKey && env && environment && url)
  const shouldRenderKazaplan = Boolean(userResources?.id && hasEnvVars)

  if (!userResources?.context) {
    return null
  }

  if (userResources?.isAssistedSale) {
    return <h1>Serviço não disponível para Venda Assistida</h1>
  }

  if (shouldRenderWarning) {
    return <WarningScreenDimensions />
  }

  if (!shouldRenderKazaplan) {
    return <WarningOffline />
  }

  return (
    <Kazaplan
      profile={userResources}
      appId={appId}
      partnerKey={partnerKey}
      url={url}
      env={env}
      environment={environment}
      token={token}
    />
  )
}

export default KazaplanPage
