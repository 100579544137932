import { useState } from 'react'
import { SummaryMobile as Summary, SummaryValues, Separator, CouponsSection } from 'frontendCheckout/ui/mobile'
import { formatters } from 'frontendCheckout/utils'

type SummaryMobileProps = {
  couponsAvailable: number
}

export const SummaryMobile = ({ couponsAvailable }: SummaryMobileProps) => {
  const [open, setOpen] = useState(false)

  return (
    <Summary.root>
      <Summary.bottomSheet isOpen={open}>
        <SummaryValues.root>
          <SummaryValues.item name='Subtotal' value={formatters.numberToPrice(9000.12)} />
          <SummaryValues.item name='Instalação' value={formatters.numberToPrice(250)} />
          <SummaryValues.item name='Frete' value={formatters.numberToPrice(50)} />
          <SummaryValues.item name='Cupom' value='-10%' colorName="tertiary" />
          <SummaryValues.item name='Leroy Merlin Com Você' value={formatters.numberToPrice(-100)} colorName="tertiary" />
        </SummaryValues.root>
      </Summary.bottomSheet>

      <CouponsSection.root>
        <CouponsSection.main>
          <CouponsSection.icon />
          <CouponsSection.text>Cupons e Cashback</CouponsSection.text>
          <CouponsSection.coupons amount={couponsAvailable} />
        </CouponsSection.main>
        <CouponsSection.button onClick={() => {}} />
      </CouponsSection.root>

      <div className='mx-6'>
        <Separator />
      </div>

      <Summary.actions>
        <Summary.price
          totalPrice={formatters.numberToPrice(9000.12)}
          onCollapse={() => setOpen(open => !open)}
          isOpen={open} />
        <Summary.button onConfirm={() => {}}>
          Continuar
        </Summary.button>
      </Summary.actions>
    </Summary.root>
  )
}
