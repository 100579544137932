import { useContext } from 'react'
import { Icon } from '@leroy-merlin-br/backyard-react'
import { ChevronDown } from '@backyard-ui/icons'

import { onMenuClick } from 'scripts/utils/data-layer'

import DropdownProviderMethods from 'site/Header/providers/DropdownProviderMethods'
import { HeaderContext } from 'site/Header/contexts/HeaderContext'

import slugify from 'utils/slugify'

import Children from '../common/children'
import * as S from './styled'

const DesktopMenu = () => {
  const { dropdownElements } = useContext(HeaderContext)

  const onItemClick = (event, item) => {
    event.preventDefault()

    onMenuClick({ item })

    const anchorElement = event.currentTarget.querySelector('a')
    const url = anchorElement.href

    window.location.href = url
  }

  const renderMenuItem = item => {
    const uniqueId = `${slugify(item.name)}-${item.id}`

    const isHighLighted = item.isHighLighted

    return (
      <li
        className="menu-item"
        key={uniqueId}
        onClick={event => onItemClick(event, item)}
      >
        <S.Link href={item.url} isHighLighted={isHighLighted}>
          {item.name}
          {item.content && <Icon as={ChevronDown} />}
        </S.Link>
        {item.content && (
          <ul className="menu-item-list menu-dropdown">
            <DropdownProviderMethods>
              <Children element={item} />
            </DropdownProviderMethods>
          </ul>
        )}
      </li>
    )
  }

  return (
    <ul className="list-old menu-list">
      {dropdownElements && dropdownElements.map(renderMenuItem)}
    </ul>
  )
}

export default DesktopMenu
