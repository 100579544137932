import { Fragment, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Container,
  toast,
  Heading,
  Text
} from '@leroy-merlin-br/backyard-react'

import { useUserProfile } from 'shared/hooks'

import { useRegions } from 'lmcv/hooks/useRegions'
import { updateUserProfile } from 'lmcv/services/userProfile'
import { getSignUpInformation } from 'lmcv/services/signUpInformation'
import { DocumentPro } from 'lmcv/components'

import { schemas } from './schemas'
import { Form, FormSkeleton } from './components/Form'
import * as S from './styled'

const SignUpPage = ({ isLoyaltyPro }) => {
  const [signUpInformation, setSignUpInformation] = useState('')
  const [classOccupation, setClassOccupation] = useState('')
  const [isLoadingForm, setIsLoadingForm] = useState(false)

  const {
    data: regions,
    isLoading: isRegionsLoading,
    error: regionsError
  } = useRegions()

  const {
    userProfile,
    isLoading: isUserLoading,
    error: hasUserError
  } = useUserProfile()

  const hasError = regionsError || hasUserError
  const isLoading = isRegionsLoading || isUserLoading

  useEffect(() => {
    loadSignUpInformation(userProfile?.regionSlug)
  }, [userProfile])

  const loadSignUpInformation = async region => {
    if (region) {
      const { data } = await getSignUpInformation(region)

      setSignUpInformation({ ...data })
    }
  }

  const queryString = location.search
  const urlParams = new URLSearchParams(queryString)
  const loyaltyRedirect = urlParams.get('redirect')

  const redirectUser = redirect => {
    const isValidRedirect = redirect !== null

    if (isValidRedirect) {
      location.href = redirect
    }

    window.setTimeout(() => {
      location.href = '/fidelidade'
    }, 1000)
  }

  const redirectUserError = () => {
    location.href = '/404'
  }

  const onSubmit = async (dataForm, e) => {
    e.preventDefault()

    setIsLoadingForm(true)

    try {
      const { data } = await updateUserProfile(
        schemas.completeRegister(dataForm),
        loyaltyRedirect
      )

      setIsLoadingForm(false)

      if (isLoyaltyPro && data.needDocumentation) {
        return setClassOccupation(dataForm.occupation)
      }

      toast.primary('Seu cadastro foi concluído com sucesso!', {
        variant: 'solid'
      })

      redirectUser(loyaltyRedirect)
    } catch {
      setIsLoadingForm(false)

      toast.critical('Não foi possivel concluir o seu cadastro', {
        variant: 'solid',
        content: 'Tente novamente mais tarde!'
      })
    }
  }

  return (
    <Fragment>
      <S.CoverBackground />
      <Container>
        <S.Content>
          <Heading size="mega" color="n800">
            Complete seu cadastro para participar do programa de fidelidade
          </Heading>
          <Text size="kilo" color="n800">
            Para criar uma experiência personalizada para o seu perfil
            precisamos de mais algumas informações
          </Text>

          {isLoading || !userProfile || !regions ? (
            <FormSkeleton />
          ) : (
            <Form
              initialState={userProfile}
              regions={regions}
              stores={signUpInformation.stores}
              occupations={signUpInformation.occupations}
              onRegionChange={loadSignUpInformation}
              onSubmit={onSubmit}
              loading={isLoadingForm}
              isLoyaltyPro={isLoyaltyPro}
            />
          )}

          {!isLoading && hasError && redirectUserError()}
        </S.Content>
      </Container>

      {isLoyaltyPro && (
        <DocumentPro
          open={classOccupation !== ''}
          classOccupation={classOccupation}
          onFinish={redirectUser}
          onCancel={redirectUser}
        />
      )}
    </Fragment>
  )
}

SignUpPage.propTypes = {
  loyaltyPro: PropTypes.string
}

export default SignUpPage
