import { Fragment } from 'react'
import { Card, Stack, Inline, Text } from '@leroy-merlin-br/backyard-react'

import { SeparatingEdge } from '../'

const careGuide = [
  'Não enviamos cobranças de frete após a conclusão dos pedidos;',
  'Não enviamos códigos de Whatsapp ou SMS para confirmar ou acessar contas;',
  'Não compartilhe seus dados de senha, cartões e dados bancários com ninguém.'
]

const ScamsWarningCard = () => (
  <Card target="card" shadow="triple">
    <Stack space="giga">
      <Fragment>
        <Text size="mega" color="n900" isBold>
          Cuidado com golpes
        </Text>

        <SeparatingEdge />
      </Fragment>

      <Stack space="byte">
        {careGuide.map((guide, index) => (
          <Inline key={index} space="kilo" alignY="center" wrap="nowrap">
            <Text as="span" color="n900" size="kilo" isBold noMargin>
              {index + 1}
            </Text>
            <Text color="n900" size="kilo" noMargin>
              {guide}
            </Text>
          </Inline>
        ))}
      </Stack>
    </Stack>
  </Card>
)

export default ScamsWarningCard
