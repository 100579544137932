import qs from 'qs'

import { ProductRef, Service } from 'newCheckout/types/Services'
import { SERVICE_FETCH_ENDPOINT, SERVICE_ENDPOINT } from 'newCheckout/constants'

import instance, { axiosResponseToStoreResponse } from './axios'

export type checkServicesType = {
  regionName: string
  productIds: number[]
}

export type addServiceType = {
  services: [
    { id: number; quantity: number; relatedId: string; skuId: string | null }
  ]
}

export type changeServiceType = {
  services: [
    { id: number; quantity: number; relatedId: string; skuId: string | null }
  ]
}

export type removeServiceType = {
  id: string
}

export const checkServices = async ({
  regionName,
  productIds
}: checkServicesType) => {
  const axiosResponse = await instance.get<{ data: ProductRef[] }>(
    SERVICE_FETCH_ENDPOINT,
    {
      params: {
        regionId: regionName,
        products: productIds.map(id => ({ id }))
      },
      paramsSerializer: params =>
        qs.stringify(params, { arrayFormat: 'brackets', encode: false })
    }
  )
  return axiosResponseToStoreResponse(axiosResponse)
}

export const addServices = async ({
  services
}: addServiceType) => {
  const axiosResponse = await instance.post<{ data: Service[] }>(
    SERVICE_ENDPOINT,
    { override: true, services }
  )
  return axiosResponseToStoreResponse(axiosResponse)
}

export const changeServiceQuantity = async ({
  services
}: addServiceType) => {
  const axiosResponse = await instance.post<{ data: Service[] }>(
    SERVICE_ENDPOINT,
    { override: true, services }
  )
  return axiosResponseToStoreResponse(axiosResponse)
}

export const removeService = async ({
  id
}: removeServiceType) => {
  const endPoint = `${SERVICE_ENDPOINT}/${id}`
  const axiosResponse = await instance.delete(endPoint)
  return axiosResponseToStoreResponse(axiosResponse)
}
