import { Constants } from '.'

export const get = () => ({
  type: Constants.NOTIFICATION_GET
})

export const getSuccess = payload => ({
  type: Constants.NOTIFICATION_GET_SUCCESS,
  payload
})

export const getFailure = error => ({
  type: Constants.NOTIFICATION_GET_FAILURE,
  error
})

export const deleteNotification = code => ({
  type: Constants.NOTIFICATION_DELETE,
  code
})

export const deleteSuccess = () => ({
  type: Constants.NOTIFICATION_DELETE_SUCCESS
})

export const deleteFailure = error => ({
  type: Constants.NOTIFICATION_DELETE_FAILURE,
  error
})
