import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { Theme } from 'scripts/react-components/new-checkout/types/Theme'

const baseStyles = ({ theme }: Theme) => css`
  height: 375px;
  width: 100%;

  display: flex;
  align-items: center;

  ${theme.media.mega} {
    height: 440px;
  }

  iframe {
    width: 100%;
    height: 375px;

    ${theme.media.mega} {
      height: 440px;
    }
  }

  img {
    label: sketchfab-model__preview-image;

    cursor: pointer;
  }
`

export const SketchfabModel = styled('div')(baseStyles)
