import { useModal } from '@leroy-merlin-br/backyard-react'
import { useEffect, useState, useCallback } from 'react'

import {
  loadVersionData,
  verifyShouldAlertToUpdateApp,
  defineTimeToAlertAgain,
  clickAlertNewVersionButton
} from './CheckOldAppModalBehaviour'
import { CheckOldAppModalComponent } from './CheckOldAppModalComponent'

const CheckOldAppModalContainer = () => {
  const { setModal } = useModal()
  const [isOpen, setIsOpen] = useState(false)
  const [versionData, setVersionData] = useState({
    canUpdateApp: false,
    mustUpdateApp: false,
    timeForNavigeteInOldApp: 0
  })

  const loadVersionAppData = useCallback(async () => {
    const versionData = await loadVersionData()
    setVersionData(versionData)
    setIsOpen(
      versionData.mustUpdateApp ||
        (versionData.canUpdateApp && verifyShouldAlertToUpdateApp(versionData))
    )
  }, [])

  const setTimeToAlertAgain = useCallback(() => {
    defineTimeToAlertAgain()
    clickAlertNewVersionButton('suggest_update', 'atualizar_mais_tarde')
    setIsOpen(false)
  }, [])

  useEffect(() => {
    loadVersionAppData()
  }, [loadVersionAppData])

  useEffect(() => {
    setModal({
      isOpen: isOpen,
      shouldCloseOnOverlayClick: false,
      children: () => (
        <CheckOldAppModalComponent
          versionData={versionData}
          setTimeToAlertAgain={setTimeToAlertAgain}
        />
      )
    })
  }, [versionData, isOpen, setModal, setTimeToAlertAgain])

  return null
}

export default CheckOldAppModalContainer
