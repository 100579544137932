import { CustomHelmet } from 'shared/components/CustomHelmet'

interface MetaTag {
  type: string
  value: string
}

type MetaTagsArray = MetaTag[][]

interface PropTags {
  canonical?: string
  prev?: string
  next?: string
  metaTags?: MetaTagsArray
  gaTrack?: string
}

const CategoryCustomHelmet = (tags: PropTags) => {
  const getMetaTags = (metaTags: MetaTagsArray) => {
    return metaTags.map((metaTagArray, index) => {
      const dynamicAttributes: { [key: string]: string } = {}
      metaTagArray.forEach(tag => {
        if (tag.value !== 'ga-track') {
          dynamicAttributes[tag.type] = tag.value
        }
      })

      return <meta key={index} {...dynamicAttributes} />
    })
  }
  return (
    <CustomHelmet>
      {tags.canonical && <link rel="canonical" href={tags.canonical} />}
      {tags.prev && <link rel="prev" href={tags.prev} />}
      {tags.next && <link rel="next" href={tags.next} />}
      {tags.gaTrack && <meta name="ga-track" content={tags.gaTrack} />}
      {tags.metaTags && getMetaTags(tags.metaTags)}
    </CustomHelmet>
  )
}

export default CategoryCustomHelmet
