import { Text, Heading } from '@leroy-merlin-br/backyard-react'

import { AntiFraud } from '../../AntiFraud'
import { ContentHeader } from '../../ContentHeader'
import * as S from './styled'
import { PixErrorVisualType } from './type'

const PixError = ({ orderId, user }: PixErrorVisualType) => (
  <ContentHeader
    title="Atenção! Erro ao gerar o código PIX."
    content={
      <>
        <Text>
          Número do pedido:{' '}
          <Text as="span" isBold>
            {orderId}
          </Text>
        </Text>
        <Text>
          Vamos enviar{' '}
          <Text as="span" isBold>
            as orientações de como realizar o pagamento
          </Text>{' '}
          no seu email{' '}
          <Text as="span" isBold>
            {user.email}
          </Text>
          . Verifique sua caixa de Spam, caso não tenha recebido nosso e-mail.
        </Text>
        <AntiFraud />
      </>
    }
  />
)

export default PixError
