import { css } from '@emotion/react'
import styled from '@emotion/styled'

const trailStyles = ({ theme }) => css`
  label: toggle-button__trail;

  display: flex;
  align-items: center;

  position: relative;

  height: 20px;
  width: 32px;

  margin-left: ${theme.spacings.byte};

  background-color: ${theme.colors.n400};

  border-radius: ${theme.borderRadius.tera};

  transition: background-color ease 180ms;

  cursor: pointer;

  &:focus {
    outline: none;
  }
`

const activeStyles = ({ theme, isActive }) =>
  isActive &&
  css`
    label: toggle-button__trail--active;

    background-color: ${theme.colors.p600};

    & ${Pin} {
      label: toggle-button__pin--active;

      left: unset;
      right: ${theme.spacings.bit};
    }
  `

const pinStyles = ({ theme }) => css`
  label: toggle-button__pin;

  position: absolute;
  left: ${theme.spacings.bit};
  right: unset;

  height: 12px;
  width: 12px;

  background-color: ${theme.colors.white};

  border-radius: ${theme.borderRadius.circle};

  transition: all ease 180ms;
`

export const Trail = styled('button')(trailStyles, activeStyles)
export const Pin = styled('span')(pinStyles)
