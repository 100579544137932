import { Card, Inline, Stack, Text } from '@leroy-merlin-br/backyard-react'

import formatCep from 'utils/formatCep'

import { Products } from '../Products'
import CartSVG from '../../images/Cart'
import PinSVG from '../../images/Pin'
import * as S from './styled'
import { PickUpInStoreVisualType } from './type'

const PickUpInStore = ({ shipping, services }: PickUpInStoreVisualType) => (
  <Card>
    <Text isBold size="giga">
      Dados da Retirada
    </Text>
    <Text>
      Para ver mais detalhes e acompanhar sua compra, acesse a página
      <a href="/meus-pedidos">
        <Text isBold color="p700" as="span">
          {' '}
          Meus Pedidos{' '}
        </Text>
      </a>
      ou baixe nosso
      <a href="/aplicativo-leroy-merlin">
        <Text isBold color="p700" as="span">
          {' '}
          App Leroy Merlin
        </Text>
      </a>
      .
    </Text>
    <Stack space="byte">
      <S.Panel>
        <S.PanelFirstLine>
          <CartSVG />{' '}
          <Text noMargin isBold>
            {' '}
            Retirar na Loja {shipping.storeName}
          </Text>
        </S.PanelFirstLine>
        <S.PanelSecondLine>
          <Text size="kilo" noMargin>
            a partir de {shipping.time} horas{' '}
            <Text as="span" size="kilo" color="n600">
              após a confirmação do pagamento
            </Text>
          </Text>
        </S.PanelSecondLine>
      </S.Panel>
      <S.Panel>
        <S.PanelFirstLine>
          <PinSVG />{' '}
          <Text noMargin isBold>
            {' '}
            {shipping.store.street}, {shipping.store.number}{' '}
            {shipping.store.complement && ', '}
            {shipping.store.complement}
          </Text>
        </S.PanelFirstLine>
        <S.PanelSecondLine>
          <Text as="span" size="kilo" color="n600">
            {shipping.store.district} • {shipping.store.city} •{' '}
            {shipping.store.state} • CEP {formatCep(shipping.store.cep)}
          </Text>
        </S.PanelSecondLine>
      </S.Panel>
    </Stack>
    <Products items={shipping.items} services={services} />
  </Card>
)

export default PickUpInStore
