import { useCallback, useEffect, useState } from 'react'

import { api, apiTypes } from 'frontendCheckout/services/api'

const emptyState: apiTypes.Cart.getAvailableVouchersAndCoupons.CouponAndVoucher =
  {
    points: { expiration: '', points: 0 },
    totalOfItems: 0,
    vouchers: { money: [], percentage: [] },
    coupons: []
  }

export const useAvailableCouponsAndVouchers = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [cancelRequest, setCancelRequest] = useState<(() => void) | null>(null)
  const [availableCouponsAndVoucher, setAvailableCouponsAndVoucher] =
    useState<apiTypes.Cart.getAvailableVouchersAndCoupons.CouponAndVoucher>(
      emptyState
    )
  const [status, setStatus] = useState(0)

  useEffect(() => {
    return () => {
      if (cancelRequest) cancelRequest()
    }
  }, [])

  const getAvailableCouponsAndVouchers = useCallback(
    async ({ cartId }: { cartId: string }) => {
      try {
        if (cancelRequest) cancelRequest()
        setIsLoading(true)

        const { response, cancel } = api.cart.getAvailableVouchersAndCoupons({
          cartId
        })

        setCancelRequest(() => cancel)

        const { value, status } = await response
        setAvailableCouponsAndVoucher(value.data)
        setStatus(status)
        setIsLoading(false)
      } catch (e) {
        setAvailableCouponsAndVoucher(emptyState)
        setIsLoading(false)
        setStatus(-1)
      }
    },
    []
  )

  return {
    availableCouponsAndVoucher,
    getAvailableCouponsAndVouchers,
    isLoading,
    status
  }
}
