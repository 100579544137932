import { AlertMessage } from 'shared/components/AlertMessage'

const ProductError = ({ errors = {} }) => {
  const hasError = Object.keys(errors).length
  const errorMessage =
    errors.stock ??
    'O produto está indisponível para a sua região. Por favor, remova do carrinho para seguir com a compra.'

  if (!hasError) {
    return null
  }

  return (
    <AlertMessage type="critical">
      {errorMessage}
    </AlertMessage>
  )
}

export default ProductError
