import styled from '@emotion/styled'
import { css } from '@emotion/react'

const baseStyles = ({ theme }) => css`
  label: quantity;

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 4px 6px;

  width: 100%;

  border: 1px solid ${theme.colors.n300};
  border-radius: ${theme.borderRadius.giga};
`

const buttonStyles = ({ theme, disabled }) => css`
  label: quantity__button;

  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0;

  border-radius: ${theme.borderRadius.circle};

  color: ${disabled ? theme.colors.n300 : theme.colors.p500};

  &:focus {
    outline: none;
  }

  &:hover {
    color: ${disabled ? theme.colors.n300 : theme.colors.p700};
  }
`

const inputStyles = ({ theme }) => css`
  label: quantity__input;

  cursor: pointer;
  overflow: hidden;
  appearance: textfield;

  text-align: center;
  width: 100%;

  border: none;

  background-color: ${theme.colors.white};

  font-size: ${theme.typography.text.mega.fontSize};
  line-height: ${theme.typography.text.mega.lineHeight};

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }

  &:focus {
    outline: 0;
    box-shadow: none;
  }
`

const inputOutline = ({ theme }) => css`
  label: input__outline;

  border-color: ${theme.colors.n100};
  border-style: solid;
  border-width: ${theme.borderWidth.kilo};

  &:hover {
    border-color: ${theme.colors.p600};
  }

  &:focus-within {
    border-color: ${theme.colors.p600};
    box-shadow: 0 0 0 3px ${theme.colors.p100};
  }
`

export const Wrapper = styled('div')(baseStyles, inputOutline)
export const ActionButton = styled('button')(buttonStyles)
export const Input = styled('input')(inputStyles)
