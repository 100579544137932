import { Stack, Text, Icon, Button, Modal, Dialog } from '@leroy-merlin-br/backyard-react'
import { FC, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Popover } from 'user/components'

import { Shell } from 'shared/components'

import mask from 'utils/maskingUtils'

import { MobileLayout } from '../../../components/MobileLayout'
import { useMyAccountContactContext } from '../../../context/my-account-contact-context'
import { EmailIcon, CellphoneIcon, ContactsIcon, SettingsIcon } from '../../../icons'
import { ContactsData, Phone } from '../../../types'
import * as S from './styled'

export type ContactsMobileProps = {
  data: ContactsData
  loading: boolean
  handleCancelDeleteNumber: () => void
  handleDeletePhoneConfirm: (phone: Phone) => void
  handleDeletePhoneSubmit: (isDesktop: boolean) => void
  deletePhone: Phone
  deleteLoading: boolean
}

const ContactsMobile: FC<ContactsMobileProps> = ({
  loading,
  data,
  handleCancelDeleteNumber,
  handleDeletePhoneConfirm,
  handleDeletePhoneSubmit,
  deletePhone,
  deleteLoading
}) => {
  const history = useHistory()

  const { contextData } = useMyAccountContactContext()

  const handleEditEmail = () => {
    history.push({
      pathname: '/validar-celular',
      search: `?origin=editEmail&mainCellphone=${data.mainCellphone}&email=${data.email}&fiscalId=${data.fiscalId}`
    })
  }

  const handleEditMainCell = () => {
    history.push({
      pathname: '/validar-email',
      search: `?origin=editMainCell&mainCellphone=${data.mainCellphone}&email=${data.email}&fiscalId=${data.fiscalId}`
    })
  }

  return (
    <MobileLayout description="Contatos">
      <S.Wrapper>
        <S.Flex>
          <S.IconWrapper>
            <Icon as={EmailIcon} />
          </S.IconWrapper>
          <Stack space="bit">
            <Text noMargin isBold>
              e-mail
            </Text>

            {loading ? (
              <Shell borderRadius="kilo" height={24} width={200} />
            ) : (
              <Text size="mega" noMargin>
                {data.email}
              </Text>
            )}
          </Stack>
        </S.Flex>

        {loading ? (
          <Shell borderRadius="kilo" height={20} width={38} />
        ) : (
          <button onClick={handleEditEmail} data-gtm-element-id="click-edit-email-button">
            <Text noMargin isBold color="p700" size="kilo">
              editar
            </Text>
          </button>
        )}
      </S.Wrapper>

      <S.Wrapper>
        <S.Flex>
          <S.IconWrapper>
            <Icon as={CellphoneIcon} />
          </S.IconWrapper>
          <Stack space="bit">
            <Popover
              list={{
                id: 'click-phone-tooltip',
                title: 'Seu celular é usado para:',
                items: [
                  'Garantir mais segurança para sua conta',
                  'Contato rápido para informar sobre seu pedido',
                  'Envio de novidades e ofertas se você aceitar'
                ]
              }}
              placement="bottom"
            >
              <Text noMargin>
                <Text as="span" isBold>Celular</Text> - principal
              </Text>
            </Popover>

            {loading ? (
              <Shell borderRadius="kilo" height={24} width={120} />
            ) : (
              <Text size="mega" noMargin>
                {mask.maskedText('phone', data.mainCellphone)}
              </Text>
            )}
          </Stack>
        </S.Flex>

        {loading ? (
          <Shell borderRadius="kilo" height={20} width={38} />
        ) : (
          <button onClick={handleEditMainCell} data-gtm-element-id="click-edit-main-phone-button">
            <Text noMargin isBold color="p700" size="kilo">
              editar
            </Text>
          </button>
        )}
      </S.Wrapper>

      {contextData.phones.map((phone: Phone, key: number) => (
        <S.Wrapper key={`phones-${key}`}>
          <S.Flex>
            <S.PhoneIconWrapper>
              <Icon
                as={phone.type === 'landline' ? ContactsIcon : CellphoneIcon}
              />
            </S.PhoneIconWrapper>
            <Stack space="bit">
              <Text noMargin isBold>
                {phone.type === 'landline' ? 'Telefone' : 'Celular'}
              </Text>

              <Text size="mega" noMargin>
                {mask.maskedText('phone', phone.number)}
              </Text>
            </Stack>
          </S.Flex>

          <S.Flex>
            <button onClick={() => handleDeletePhoneConfirm(phone)} data-gtm-element-id="click-phone-delete-button">
              <Text noMargin isBold color="r600" size="kilo">
                excluir
              </Text>
            </button>

            <a onClick={() => {
              history.push({
                pathname: '/editar-telefone',
                state: {
                  origin: 'editPhone',
                  phone
                }
              })
            }}
            >
              <Text noMargin isBold color="p700" size="kilo">
                editar
              </Text>
            </a>
          </S.Flex>
        </S.Wrapper>
      ))}

      <S.Wrapper>
        <S.Flex>
          <S.IconWrapper>
            <Icon as={SettingsIcon} />
          </S.IconWrapper>
          <Popover
            list={{
              id: 'click-preferences-tooltip',
              title: 'Preferências de contato:',
              items: [
                'Selecionar por quais meios você deseja receber contato',
                'Receber comunicações e ofertas'
              ]
            }}
            placement="bottom"
          >
            <Text size="mega" noMargin isBold>
              Preferências de contato
            </Text>
          </Popover>
        </S.Flex>

        {loading ? (
          <Shell borderRadius="kilo" height={20} width={38} />
        ) : (
          <button onClick={() => history.push('/preferencias-de-contato')} data-gtm-element-id="click-contact-preferences-button">
            <Text noMargin isBold color="p700" size="kilo">
              editar
            </Text>
          </button>
        )}
      </S.Wrapper>

      <S.ButtonWrapper>
        <Button
          variant="outline"
          size="mega"
          isStretch
          onClick={() => history.push('/adicionar-telefone')}
          data-gtm-element-id="click-add-new-telephone"
        >
          Adicionar novo telefone
        </Button>
      </S.ButtonWrapper>

      <Modal
        isOpen={Boolean(deletePhone)}
        isCentered
        onClose={handleCancelDeleteNumber}>
        <Dialog
          hasScroll
          onClose={handleCancelDeleteNumber}
          title="Excluir telefone?"
        >
          <Text>
            {`Tem certeza que deseja excluir o telefone ${deletePhone?.number ? mask.maskedText('phone', deletePhone?.number) : ''}?`}
          </Text>

          <Button
            data-gtm-element-id="click-confirm-phone-delete-button"
            isStretch
            isLoading={deleteLoading}
            onClick={() => handleDeletePhoneSubmit(false)}
          >
            Sim, excluir
          </Button>

          <S.CancelButtonWrapper>
            <Button
              isStretch
              isDisabled={deleteLoading}
              onClick={handleCancelDeleteNumber}
              appearance="critical"
              variant="ghost"
            >
              Cancelar e manter número
            </Button>
          </S.CancelButtonWrapper>
        </Dialog>
      </Modal>
    </MobileLayout>
  )
}

export default ContactsMobile
