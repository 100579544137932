import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { Theme } from 'newCheckout/types/Theme'

const changeStoreStyle = ({ theme }:Theme) => css`
  label: change-store;

  color: ${theme.colors.p600};

  text-decoration: underline;
  cursor: pointer;

  margin-left: ${theme.spacings.byte};

`
export const ChangeStore = styled('span')(changeStoreStyle)
