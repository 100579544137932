import { useEffect, useState } from 'react'
import { Card } from '@leroy-merlin-br/backyard-react'
import { shallowEqual, useSelector } from 'react-redux'

import { Drawer, WizardForm } from 'lmcv/components'
import { useStep } from 'lmcv/hooks/useStep'
import { getCustomers } from 'lmcv/services/customers'
import * as loyaltyStatus from 'lmcv/utils/LoyaltyStatus/constants'
import { showErrorAlert } from 'lmcv/utils/showErrorAlert'

import { List, ListSkeleton } from './components/List'
import { DocumentStatus } from './components/DocumentStatus'
import { JoinProfessional } from './components/JoinProfessional'

const CustomersPage = () => {
  const [customersLoading, setCustomersLoading] = useState(true)
  const [customers, setCustomers] = useState([])
  const [status, setStatus] = useState(null)
  const [hasProfessionalRelationship, setHasProfessionalRelationship] =
    useState(false)
  const [professionalData, setProfessionalData] = useState()
  const { prevStep, nextStep, step, setStep } = useStep(1)
  const [isVisible, setIsVisible] = useState(false)
  const [countRemainingInvites, setCountRemainingInvites] = useState(0)

  const { payload: loyaltyProfile, isRequest } = useSelector(
    state => state.loyaltyProfile.get,
    shallowEqual
  )

  const openDrawer = () => setIsVisible(true)

  const closeDrawer = () => {
    setIsVisible(false)
    setStep(1)

    if (step === 3) {
      window.location.reload()
    }
  }

  useEffect(() => {
    if (!loyaltyProfile) return

    setStatus(loyaltyProfile.status)
    setHasProfessionalRelationship(loyaltyProfile.hasProfessionalRelationship)

    if (loyaltyProfile.professional) {
      setProfessionalData(loyaltyProfile.professional)
    }
  }, [loyaltyProfile])

  useEffect(() => {
    getCustomers()
      .then(({ data, remainingInvites }) => {
        setCustomers(data)
        setCountRemainingInvites(remainingInvites)
        setCustomersLoading(false)
      })
      .catch(() => {
        showErrorAlert('profile')
      })
  }, [])

  const canShowData = !isRequest && status

  if (!canShowData) {
    return (
      <Card title="Clientes">
        <ListSkeleton />
      </Card>
    )
  }

  if (status === loyaltyStatus.PRO_EAD_ACTIVE) {
    return (
      <Card title="Clientes">
        {customersLoading ? (
          <ListSkeleton />
        ) : (
          <List
            data={customers}
            remainingInvites={countRemainingInvites}
            onClickAdd={openDrawer}
          />
        )}

        <Drawer
          title="Adicionar Clientes"
          visible={isVisible}
          onClose={closeDrawer}
        >
          {isVisible && (
            <WizardForm
              prevStep={prevStep}
              nextStep={nextStep}
              step={step}
              closeDrawer={closeDrawer}
            />
          )}
        </Drawer>
      </Card>
    )
  }

  if (status === loyaltyStatus.PRO_EAD_WITHOUT_DOCUMENTATION) {
    return (
      <JoinProfessional
        status={status}
        hasProfessionalRelationship={hasProfessionalRelationship}
      />
    )
  }

  return (
    <DocumentStatus
      status={status}
      professionalData={professionalData}
      hasProfessionalRelationship={hasProfessionalRelationship}
    />
  )
}

export default CustomersPage
