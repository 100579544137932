import { ToastContainer } from '@leroy-merlin-br/backyard-react'
import { Fragment } from 'react'

import reactComponentLoader from 'scripts/react-component-loader'

import { SignUpPage } from '../SignUp'

const SignUpPageProvider = ({ loyaltyPro, ...props }) => {
  const isLoyaltyPro = JSON.parse(loyaltyPro)

  return (
    <Fragment>
      <ToastContainer position="top-right" />
      <SignUpPage isLoyaltyPro={isLoyaltyPro} {...props} />
    </Fragment>
  )
}

reactComponentLoader(SignUpPageProvider, '[data-lmcv-signup]')
