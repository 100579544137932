import { Theme as BackyardTheme } from '@leroy-merlin-br/backyard-theme'

import * as S from './Style'

type Spacing = keyof BackyardTheme['spacings'];

type RadioCardType = {
  isChecked:boolean,
  onClick:() => void,
  name:string,
  value:string | number,
  title: React.ReactNode,
  content?: React.ReactNode,
  id:string | null,
  size: Spacing,
  disable: boolean
}

const RadioCard = ({
  isChecked = false,
  onClick = () => {},
  name,
  value,
  title,
  content,
  id = null,
  size,
  disable = false
}: RadioCardType) => (
  <S.Wrapper style={{ width: '100%' }}>
    <S.Card isChecked={isChecked} disable={disable} onClick={onClick}>
      <S.Inline>
        <S.Title>{title}</S.Title>
        <S.Radio
          id={id}
          name={name}
          type="radio"
          value={value}
          checked={isChecked}
          onChange={() => {}}
          size={size}
        />
      </S.Inline>
      {content}
    </S.Card>
  </S.Wrapper>
)

export default RadioCard
