import { useCallback } from 'react'
import { useModal } from '@leroy-merlin-br/backyard-react'

import ModalComponent from './ModalComponent'
import * as ModalBahaviour from './ModalBehaviour'

const MODIFY_MODAL_POSITION = 4

type ModalContainerProps = {
  datePromotion: string
}

const ModalContainer = ({ datePromotion }: ModalContainerProps) => {
  const { setModal } = useModal()

  const setMarginTopIfMobile = useCallback(() => {
    ModalBahaviour.changeModalToBeBottom(
      '[data-id="promotion-modal"]',
      MODIFY_MODAL_POSITION
    )
  }, [])

  const openModal = useCallback(() => {
    const buildPromotionModal = async () => {
      await setModal({
        size: 'giga',
        onClose: () => true,
        shouldCloseOnOverlayClick: false,
        children: ({ onClose }) => (
          <ModalComponent
            datePromotion={datePromotion}
            onClose={e => onClose(e)}
          />
        )
      })
      await setMarginTopIfMobile()
    }

    buildPromotionModal()
  }, [setModal, datePromotion, setMarginTopIfMobile])

  return { openModal }
}

export default ModalContainer
