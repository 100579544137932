const months = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro'
]

/**
 * eg transform from 02/04/2015 to object { day:02, month:Abril, year:2015 }
 * @param date string
 */
export const dateToObject = (date: string) => {
  const [day, month, year] = date.split('/')

  return {
    day,
    month: months[parseInt(month, 10) - 1],
    year
  }
}
