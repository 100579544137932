import { Fragment, useRef, forwardRef, useImperativeHandle, useEffect } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

import Overlay from './components/Overlay'

const GardenDrawer = forwardRef(({ overlayClick, isOpen, children, className, dataCy = 'drawer' }, ref) => {
  const drawerRef = useRef()
  useImperativeHandle(ref, () => drawerRef.current, [drawerRef])

  useEffect(() => {
    isOpen ? disableBodyScroll(drawerRef.current) : enableBodyScroll(drawerRef.current)
  }, [isOpen])

  const componentClass = classNames(
    'drawer',
    'drawer-padding',
    {
      open: isOpen
    },
    className
  )

  return (
    <Fragment>
      <Overlay onClick={overlayClick} isOpen={isOpen} />
      <div data-cy={dataCy} className={componentClass} ref={drawerRef}>
        <div>
          {children}
        </div>
      </div>
    </Fragment>
  )
})

GardenDrawer.propTypes = {
  overlayClick: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string
}

export default GardenDrawer
