import { css } from '@emotion/react'
import styled from '@emotion/styled'

const baseStyles = ({ theme }) => css`
  label: content;

  display: flex;
  align-items: center;
  background-color: ${theme.colors.white};
  width: 100%;
  flex: 1 auto;
  padding: 80px ${theme.spacings.giga};
`

const imageStyles = () => css`
  label: content__image;

  width: 100%;
`

export const Content = styled('div')(baseStyles)

export const Image = styled('img')(imageStyles)
