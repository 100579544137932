import { Component } from 'react'
import Cookies from 'js-cookie'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

import * as dropdownRepository from 'scripts/api/header/dropdown-menu'

import { userResourcesApi } from 'api'

import { HeaderProvider } from '../contexts/HeaderContext'

class HeaderProviderMethods extends Component {
  constructor (props) {
    super(props)

    this.state = {
      dropdownElements: [],
      isLoading: true,
      isLogged: false,
      userResources: {},
      activatedParent: null,
      isExpanded: false,
      isOpened: false,
      shouldShowAutoLocationPopper: false,
      toggleMenu: this.toggleMenu,
      toggleMenuSize: this.toggleMenuSize,
      toggleChildren: this.toggleChildren,
      setParentActivated: this.setParentActivated,
      assistedSalesLinks:
        this.props.assistedSalesLinks &&
        JSON.parse(this.props.assistedSalesLinks),
      isBlackWeekend: this.props.isBlackWeekend,
      isAutomaticLocationEnabled: this.props.isAutomaticLocationEnabled,
      algoliaSearchClient:
        this.props.algoliaSearchClient &&
        JSON.parse(this.props.algoliaSearchClient)
    }

    this.isAssistedSale = Cookies.get('assisted_sale') === '1'
    this.menuElement = null
    this.subMenuElement = null
  }

  async componentDidMount () {
    this.menuElement = document.querySelector('ul.menu-list-mobile-elements')
    this.subMenuElement = document.querySelector('ul.menu-list')

    try {
      const [userResources, dropdownData] = await Promise.all([
        userResourcesApi.storedGetUserResources(),
        this.getMenu()
      ])

      this.setState(
        {
          shouldShowAutoLocationPopper: this.checkIfItShouldShowPopper(),
          userResources: userResources,
          isLogged: userResources.email && userResources.email.length > 0,
          dropdownElements: dropdownData.data,
          isLoading: false,
          isAssistedSale: this.isAssistedSale
        },
        () => this.setAlreadyVisitedFlag()
      )
    } catch (err) {
      console.error(err)
    }
  }

  checkIfItShouldShowPopper () {
    const hasCloudflareCookie = Cookies.get('location_source') === 'cloudflare'
    const alreadyVisited = Boolean(localStorage.getItem('already-visited'))

    return (
      this.state.isAutomaticLocationEnabled &&
      !alreadyVisited &&
      hasCloudflareCookie
    )
  }

  setAlreadyVisitedFlag () {
    if (this.state.shouldShowAutoLocationPopper) {
      localStorage.setItem('already-visited', true)
    }
  }

  getMenu () {
    if (this.isAssistedSale) {
      return dropdownRepository.getStoredMenuVA()
    }

    return dropdownRepository.getStoredMenu()
  }

  setParentActivated = activatedParent => {
    this.setState({ activatedParent })
  }

  toggleChildren = () => {
    this.setState(
      {
        isExpanded: !this.state.isExpanded
      },
      () => {
        this.state.isExpanded
          ? disableBodyScroll(this.subMenuElement)
          : enableBodyScroll(this.subMenuElement)
      }
    )
  }

  toggleMenu = () => {
    this.setState(
      {
        isOpened: !this.state.isOpened
      },
      () => {
        this.state.isOpened
          ? disableBodyScroll(this.menuElement)
          : enableBodyScroll(this.menuElement)
      }
    )
  }

  toggleMenuSize = () => {
    this.setState({
      isExpanded: !this.state.isExpanded
    })
  }

  render () {
    return (
      <HeaderProvider value={this.state}>{this.props.children}</HeaderProvider>
    )
  }
}

export default HeaderProviderMethods
