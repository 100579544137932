import { css } from '@emotion/react'
import styled from '@emotion/styled'

const linkStyles = ({ theme }) => css`
  label: link;

  color: ${theme.colors.p600};
`

const wrapperComplementStyles = () => css`
  label: wrapper-complement;

  span {
    font-style: italic;
  }
`

const newsletterOptionsWrapperStyles = ({ theme }) => css`
  label: newsletter-options-wrapper;

  padding-left: 26px;
  margin-bottom: ${theme.spacings.byte};

  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: ${theme.spacings.giga};
  grid-row-gap: ${theme.spacings.kilo};
  justify-content: start;

  ${theme.media.mega} {
    grid-template-columns: repeat(3, auto);
  }
`

const titleWrapperStyles = () => css`
  label: title-wrapper;

  p,
  span {
    font-size: 32px;
    line-height: 46px;
  }
`

const imageWrapperStyles = () => css`
  label: image-wrapper;

  img {
    width: 575px;
  }
`

const buttonWrapperStyles = ({ theme }) => css`
  label: button-wrapper;

  margin-top: -10px;
  padding-bottom: ${theme.spacings.kilo};
`

const errorWithLinkWrapper = ({ theme }) => css`
  label: error-with-link-wrapper;

  a {
    color: ${theme.colors.p700};
  }
`

export const Link = styled('a')(linkStyles)
export const WrapperComplement = styled('div')(wrapperComplementStyles)
export const NewsletterOptionsWrapper = styled('div')(
  newsletterOptionsWrapperStyles
)
export const TitleWrapper = styled('div')(titleWrapperStyles)
export const ImageWrapper = styled('div')(imageWrapperStyles)
export const ButtonWrapper = styled('div')(buttonWrapperStyles)
export const ErrorWithLinkWrapper = styled('span')(errorWithLinkWrapper)
