import styled from '@emotion/styled'
import { css } from '@emotion/react'

const simpleColumnStyles = ({ theme }) => css`
  label: mosaic__simple-column;

  display: flex;
  flex-direction: column;
  width: 25%;

  &:nth-of-type(n+2) {
    border-left: 1px solid ${theme.colors.n100};
  }

  & div:nth-of-type(even) {
    border-top: 1px solid ${theme.colors.n100};
  }
`

export const SimpleColumn = styled('div')(simpleColumnStyles)
