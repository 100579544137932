import { Drawer } from 'shared/components'

import { Store } from 'newCheckout/types/PickUpInStore'

import SelectStores from './SelectStores'

type OnChangeParameters = {
  storeId?: string | null
}

const SelectStoreDrawer = ({
  isDrawerOpen,
  overlayClick,
  stores,
  onChange,
  storeId,
  title
}: {
  isDrawerOpen: boolean
  overlayClick: () => void
  stores: Store[]
  onChange: (params: OnChangeParameters) => void
  storeId?: string
  title: string
}) => (
  <Drawer
    onClose={overlayClick}
    title={title}
    isOpen={isDrawerOpen}
    dataCy="stores-drawer"
  >
    <SelectStores
      stores={stores}
      onChange={onChange}
      storeId={storeId}
    />
  </Drawer>
)

export default SelectStoreDrawer
