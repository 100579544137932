import { AxiosError, AxiosResponse } from 'axios'

type Request<T> = () => Promise<T>

export const requestStore = <T>(request: Request<T>) => {
  let promise: Promise<T> | undefined
  let error: AxiosResponse | undefined

  return (clear = false) => {
    if (clear) {
      promise = undefined
      error = undefined
    }

    if (promise) return promise
    if (error) throw error

    try {
      promise = request()
      return promise
    } catch (err) {
      throw (error = (err as AxiosError).response)
    }
  }
}
