import axios from 'axios'

import { api } from './api'
import { Profile, ProfilePayload } from '../types'

export const getProfile = async () => {
  try {
    const { data } = await api.get<{ data: Profile }>('/account/profile')

    return data
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error.response
    } else {
      throw error
    }
  }
}

export const updateProfile = async (payload: ProfilePayload) => {
  try {
    const { data } = await api.patch<{ data: Profile }>('/account', payload)

    return data
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error.response
    } else {
      throw error
    }
  }
}
