import styled from '@emotion/styled'
import { css } from '@emotion/react'

const carouselStyles = () => css`
  label: carousel;

  display: flex;
  margin: 0;
  white-space: nowrap;
`

export const Carousel = styled('ul')(carouselStyles)

const carouseItemStyles = ({ theme }) => css`
  label: carousel__item;

  position: relative;
  margin: 0;

  &:not(:last-of-type) {
    margin-right: ${theme.spacings.byte};
  }
`

export const CarouseItem = styled('li')(carouseItemStyles)
