import { ResponseType, request } from 'frontendCheckout/services/fetch'

import { endpoints } from './endpoints'

export type Summary = {
  appliedVouchers: string[]
  appliedCoupon: string | null
  appliedMoneyVouchers: string[]
  moneyVouchersTotal: number
  total: number
  subTotal: number
  serviceTotal: number
  freight: number
  discount: number
  promiseDates: PromiseDate[]
  discountsByPaymentMethods: DiscountsByPaymentMethods
  totalDiscountByType: TotalDiscountByType
  creditCardInstallmentsByBrand: CreditCardInstallmentsByBrand
  availableCashbackTotal: number
  isSameDayDelivery: boolean
  errors: ErrorsByCoupon[]
}

export type response = ResponseType<200, { data: Summary }>

export type status = response extends ResponseType<infer Code, unknown>
  ? Code
  : never

export type queryParams = {
  cash_payment: boolean
  payment_method?: 'pix' | 'token' | 'bank_slip' | 'credit_card'
  vouchers?: string[]
  money_vouchers?: string[]
  coupon?: string
  simulate?: boolean
}

export type props = {
  cartId: string
  params?: queryParams
}

export const getSummary = ({ cartId, params }: props) => {
  return request<response>({
    url: endpoints.getSummary(cartId),
    method: 'GET',
    params
  })
}

export interface InstallmentsByBrand {
  quantity: number
  value: number
  withDiscount: boolean
  total: number
}

export interface ErrorsByCoupon {
  code: string
  vouchers: string[]
}

export interface SummaryInfoProps {
  cartId: string
  summary: Summary
  getSummary: ({ cartId, params }: props) => Promise<void>
  isLoadingSummary: boolean
  getSummaryStatusCode: number
}

interface PromiseDate {
  type: 'delivery'
  forecast: string
}

interface PaymentMethodDiscount {
  totalPriceWithoutDiscount: number
  totalPrice: number
}

interface DiscountsByPaymentMethods {
  credit_card: PaymentMethodDiscount
  bank_slip: PaymentMethodDiscount
  token: PaymentMethodDiscount
  pix: PaymentMethodDiscount
}

interface CreditCardInstallmentsByBrand {
  brandedPro: InstallmentsByBrand[]
  branded: InstallmentsByBrand[]
  default: InstallmentsByBrand[]
}

interface DiscountDetail {
  cheapestPaymentMethods: 'credit_card' | 'bank_slip' | 'token' | 'pix'
  totalDiscount: number
  totalValue: number
  totalInstallments: number
  totalDiscountsByType: number
  firstCheapestPaymentMethod: 'credit_card' | 'bank_slip' | 'token' | 'pix'
}

interface TotalDiscountByType {
  employee: DiscountDetail
  loyaltyProgram: DiscountDetail
  'payment-method': DiscountDetail
  promo: DiscountDetail
}
