import styled from '@emotion/styled'
import { css } from '@emotion/react'

type BulletProps = {
  status: 'completed' | 'current' | 'upcoming' | 'pending' | 'canceled';
}

const outerCircleStyles = {
  completed: () => css`
    background-color: #EAF3F1  ;
  `,
  current: () => css`
    background-color: #EAF2F9;
  `,
  pending: () => css`
    background-color: #FCF3DF ;
  `,
  canceled: () => css`
    background-color: #FFEEEB;
  `,
  upcoming: () => css`
    background-color: #F4F1EB;
  `
}

const innerCircleStyles = {
  completed: () => css`
    background-color: #006053;
  `,
  current: () => css`
    background-color: #00598A  ;
  `,
  pending: () => css`
    background-color: #F3A620  ;
  `,
  canceled: () => css`
    background-color: #AE0000;
  `,
  upcoming: () => css`
    background-color: #DEDAD2   ;
  `
}

export const OuterCircle = styled('div')<BulletProps>`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ status }: { status: BulletProps['status'] }) => outerCircleStyles[status]}
`

export const InnerCircle = styled('div')<BulletProps>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  ${({ status }: { status: BulletProps['status'] }) => innerCircleStyles[status]}
`
