import request from 'checkout/utils/axios'

export const getOrders = async fiscalId =>
  await request
    .get('/user-sale-orders', { params: { fiscalId: fiscalId || null } })
    .then(({ data }) => data)
    .catch(err => {
      throw err.response
    })

export const getOrdersToPay = async fiscalId =>
  await request
    .get('/user-pending-payment-sale-orders', {
      params: { fiscalId: fiscalId || null }
    })
    .then(({ data }) => data)
    .catch(err => {
      throw err.response
    })
