import { create } from 'zustand'

interface EntryState {
  ui: {
    isFiltersOpened?: boolean
  }
}

export type EntryActions = EntryState & {
  ui: {
    toggleFilters?: () => void
  }
}

export const useEntryStore = create<EntryActions>(set => ({
  ui: {
    isFiltersOpened: false,
    toggleFilters: () =>
      set(state => ({
        ui: {
          ...state.ui,
          isFiltersOpened: !state.ui.isFiltersOpened
        }
      }))
  }
}))
