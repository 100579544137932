import styled from '@emotion/styled'
import { css } from '@emotion/react'

const containerStyles = ({ clamp = 1 }) => css`
  label: ellipsis;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: ${clamp};
  -webkit-box-orient: vertical;
`

export const Container = styled('span')(containerStyles)
