import PropTypes from 'prop-types'

import { useUserResources } from 'shared/hooks'

/**
 * @param {{when:string[], children:JSX.Element}} param
 * @returns JSX.Element | null
 */
const ContextVisibility = ({ when = [], children = null }) => {
  const { userResources } = useUserResources()

  if (when.includes(userResources?.context)) {
    return children
  }

  return null
}

ContextVisibility.propTypes = {
  when: PropTypes.arrayOf(
    PropTypes.oneOf(['app', 'assisted-sale', 'ecommerce'])
  ),
  children: PropTypes.node
}

export default ContextVisibility
