import { Inline, Text } from '@leroy-merlin-br/backyard-react'

import * as S from './styled'

const ServicesCard = ({ title = '', children }) => {
  const { defaultImagePath } = window.env

  return (
    <S.CardWrapper>
      <Inline alignY="center" space="byte">
        <S.ImageWrapper>
          <img
            className="service-badge-icon"
            src={`${defaultImagePath}/icons/lmbr-services.svg`}
            alt="Logo do Leroy Merlin instala"
          />
        </S.ImageWrapper>
        <div>
          {title && (
            <Text align="start" size="mega" color="n900" noMargin isBold>
              {title}
            </Text>
          )}
          <Text align="start" size="kilo" color="n900" noMargin>
            {children}
          </Text>
        </div>
      </Inline>
    </S.CardWrapper>
  )
}

export default ServicesCard
