import { Fragment } from 'react'

import pluralize from 'utils/pluralize'

import StoreStockTable from './StoreStockTable'

const Header = ({ hideStock }) => (
  <div className="col-xs-12 margin-bottom">
    <h3 className="heading-3">
      <button aria-label="Fechar" className="glyph glyph-x close-button" onClick={hideStock} />
      Disponibilidade de estoque
    </h3>
    <h2 className="heading-4 no-margin-bottom">Estoque</h2>
    <small className="small color-default">Atualizado em tempo real</small>
  </div>
)

const OutofStockMessage = () => (
  <span className="margin-bottom">Esta Loja não possui estoque no momento.</span>
)

const StockStoreContent = ({ store, warehouses, unit }) => (
  <Fragment>
    <div className="store-name">
      <i className="glyph glyph-location icon-location"></i>{store.name}
    </div>

    <small className="small color-primary margin-bottom">
      {store.totalStock} {unit} {pluralize(store.totalStock, 'available')}
      {unit !== 'un' && ` - ${store.largerStock} ${unit || ''} no maior lote`}
    </small>

    {warehouses && <StoreStockTable warehouses={warehouses} />}
  </Fragment>
)

const StockStore = (props) => {
  const { store, warehouses, unit } = props

  return (
    <div className="col-xs-12 margin-bottom">
      {!store
        ? <OutofStockMessage />
        : <StockStoreContent
          store={store}
          warehouses={warehouses}
          unit={unit}
        />
      }
    </div>
  )
}

const ContentStockAssistedSale = (props) => {
  return (
    <Fragment>
      <Header hideStock={props.hideStock} />
      <StockStore {...props} />
    </Fragment>
  )
}

export default ContentStockAssistedSale
