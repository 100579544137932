import { useMemo, useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import {
  Button,
  Card,
  Stack,
  Checkbox,
  Text,
  useModal
} from '@leroy-merlin-br/backyard-react'
import { Popover } from 'user/components'
import { useCardRequestContext } from 'user/celebre/context/card-request-context'
import { sendCardRequest } from 'user/celebre/services'
import { isServerError } from 'user/utils'
import {
  ErrorModal,
  ExistingCardModal,
  RequestDoneModal
} from 'user/celebre/components'
import { useStateMachine } from 'little-state-machine'

import { changeDateFormat } from 'scripts/react-components/utils/date'

import { SelectField, AlertMessage } from 'shared/components'

import * as S from './styled'
import { RequestSuccessModal } from './components/RequestSuccessModal'
import { parseApiErrors } from '../../../../utils/parse-api-errors'
import { FORMS_NAMES, FORM_DEFAULT_VALUES } from '../../constants'

const InvoiceForm = () => {
  const [isLoading, setIsLoading] = useState(false)

  const { updateAction, setShowExitConfirm, activeStep } =
    useCardRequestContext()

  const { setModal } = useModal()

  const {
    actions,
    state: { basic, eligibility, complement, address, income, apiErrors }
  } = useStateMachine({ updateAction })

  const { register, handleSubmit, control, setError, formState } = useForm({
    mode: 'onChange',
    defaultValues: FORM_DEFAULT_VALUES.invoice
  })

  const { errors, isValid } = formState

  const hasErrors = useMemo(() => {
    return Object.keys(formState.errors).length > 0
  }, [formState])

  const dueDayOptions = useMemo(() => {
    if (eligibility) {
      const { dueDays } = eligibility
      return dueDays.map(day => ({
        value: day,
        label: day
      }))
    }
  }, [eligibility])

  const hasSomeApiError = useMemo(() => {
    return Object.values(apiErrors).some(item => item.length)
  }, [apiErrors])

  const onSubmit = async data => {
    const parsedDate = changeDateFormat(
      complement.document.emissionDate,
      'DD/MM/YYYY',
      'YYYY-MM-DD'
    )

    const formData = {
      ...data,
      ...complement,
      ...address,
      ...income,
      document: { ...complement.document, emissionDate: parsedDate }
    }

    setIsLoading(true)

    try {
      await sendCardRequest(formData)

      return setModal({
        children: ({ onClose }) => (
          <RequestSuccessModal onClose={onClose} userPhone={basic.phone} />
        ),
        isCentered: true,
        shouldCloseOnOverlayClick: false
      })
    } catch ({ data: { data, errors }, status }) {
      const hasServerError =
        isServerError(status) ||
        data?.status === 'integration_error' ||
        data?.status === 'internal_error'

      if (hasServerError) {
        return setModal({
          children: ({ onClose }) => <ErrorModal onClose={onClose} />,
          isCentered: true,
          shouldCloseOnOverlayClick: false
        })
      }

      if (data?.status === 'has_card') {
        return setModal({
          children: ({ onClose }) => <ExistingCardModal onClose={onClose} />,
          isCentered: true,
          shouldCloseOnOverlayClick: false
        })
      }

      if (data?.status === 'pending_subscription') {
        return setModal({
          children: ({ onClose }) => <RequestDoneModal onClose={onClose} />,
          isCentered: true,
          shouldCloseOnOverlayClick: false
        })
      }

      const apiErrors = parseApiErrors(errors)
      actions.updateAction({ apiErrors, hasSubmittedForm: true, invoice: data })
    } finally {
      setShowExitConfirm(false)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (apiErrors.invoice) {
      apiErrors.invoice.forEach(({ code, message }) => {
        setError(code, { type: 'manual', message: message })
      })
    }
  }, [apiErrors, setError])

  const isActive = activeStep === FORMS_NAMES.invoice

  if (!isActive) {
    return
  }

  return (
    <Card
      title={
        <Popover
          list={{
            title:
              'Escolha o melhor dia de vencimento de sua fatura e verifique os serviços oferecidos.'
          }}
        >
          Fatura e Serviços
        </Popover>
      }
    >
      <Stack>
        {hasSomeApiError && (
          <AlertMessage type="critical">
            <Text as="strong" size="kilo" isBold noMargin>
              Verifique os erros nos passos sinalizados em vermelho.
            </Text>
          </AlertMessage>
        )}

        <form onSubmit={handleSubmit(onSubmit)} data-cy="invoice-data-form">
          <Stack space="mega">
            <SelectField
              name="dueDay"
              label="Vencimento da fatura"
              options={dueDayOptions}
              rules={{
                required: 'Escolha a data de vencimento'
              }}
              control={control}
              state={Boolean(errors.dueDay) && 'invalid'}
              hint={errors.dueDay?.message}
            />

            <Checkbox
              {...register('optins.emergencyCredit')}
              hint={
                <S.HintWrapper>
                  <Stack space="bit">
                    <Text color="n600" size="kilo" noMargin>
                      "Evite recusas de compras sem análise. O Banco Cetelem
                      oferece o serviço de Avaliação Emergencial de Crédito, que
                      possibilita a você que as compras acima do limite do seu
                      cartão passem por análise para aprovação."
                    </Text>
                    <Text color="n400" size="kilo" noMargin>
                      *Serviço sujeito a cobrança conforme tabela de tarifas
                      vigente no site do CETELEM, limitada a única cobrança no
                      período.
                    </Text>
                  </Stack>
                </S.HintWrapper>
              }
            >
              Avaliação Emergencial de Crédito
            </Checkbox>

            <Checkbox
              {...register('optins.purchaseInsurance')}
              hint={
                <S.HintWrapper>
                  <Stack space="bit">
                    <Text color="n600" size="kilo" noMargin>
                      Tranquilidade em caso de imprevistos. Garante o pagamento
                      da fatura em caso de desemprego involuntário ou
                      incapacidade física por algum acidente entre outros
                      imprevistos.
                    </Text>
                    <Text color="n600" size="kilo" noMargin>
                      Coberturas:
                    </Text>
                    <S.ToppingsWrapper>
                      <Text color="n600" size="kilo" noMargin>
                        Morte ou invalidez permanente total por acidente. Até R$
                        10.000,00
                      </Text>
                      <Text color="n600" size="kilo" noMargin>
                        Desemprego involuntário, incapacidade física total
                        temporária. Até R$ 2.000,00
                      </Text>
                    </S.ToppingsWrapper>
                    <Text color="n400" size="kilo" noMargin>
                      *Cobrança de 3,5% sob o valor da fatura.
                    </Text>
                  </Stack>
                </S.HintWrapper>
              }
            >
              Compra mais segura
            </Checkbox>
          </Stack>
          <S.ButtonWrapper>
            <Button
              isStretch
              type="submit"
              isLoading={isLoading}
              isDisabled={!isValid || hasErrors || hasSomeApiError}
            >
              Finalizar e pedir cartão
            </Button>
          </S.ButtonWrapper>
        </form>
      </Stack>
    </Card>
  )
}

export default InvoiceForm
