import { Fragment } from 'react'
import { Text } from '@leroy-merlin-br/backyard-react'

import { AlertMessage } from 'shared/components/AlertMessage'

import { getSortedModalities } from 'catalog/utils/sort-shipment-modalities'

import { ShipmentModality } from '../ShipmentModality'
import * as S from './styled'

const ShipmentOption = ({ sku, shipments }) => {
  const shopName = sku.shop?.name
  const isPickupInStoreEnabled = sku.purchaseButtons?.pickupInStore

  const skuShipments = shipments.find(
    ({ skuId }) => skuId === sku.id || (!skuId && !sku.id)
  )
  const skuModalities = skuShipments && getSortedModalities(skuShipments)
  const skuPickupInStore = shipments[0].pickupInStore

  const shoudlShowPickupInStore =
    sku.id === '' && isPickupInStoreEnabled && Boolean(skuPickupInStore)

  return (
    <S.Seller key={sku.id} data-testid="seller-wrapper">
      <S.SellerName>{shopName}</S.SellerName>

      {!skuShipments && !shoudlShowPickupInStore ? (
        <AlertMessage type="critical">
          <Text as="strong" size="kilo" isBold noMargin>
            Ops!{' '}
          </Text>
          Tivemos um problema ao tentar apresentar o frete,{' '}
          <Text as="strong" size="kilo" isBold noMargin>
            mas você poderá conferi-lo em seu carrinho
          </Text>
          .
        </AlertMessage>
      ) : (
        <Fragment>
          {shoudlShowPickupInStore && (
            <ShipmentModality
              modality="pickupInStore"
              deadline={skuPickupInStore.delayTime}
              cost={skuPickupInStore.cost}
            />
          )}

          {skuModalities?.map(modality => (
            <ShipmentModality
              key={modality}
              modality={modality}
              {...skuShipments.modalities[modality]}
            />
          ))}
        </Fragment>
      )}
    </S.Seller>
  )
}

export default ShipmentOption
