import { buildEventPurchase, buildEventViewItem } from './lizard'
import { buildEventScreenSignIn } from './lizard_screen'

document
  .querySelectorAll('[name="data-layer-products"]')
  .forEach(buildEventPurchase)

document.querySelectorAll('[event-view-item]').forEach(buildEventViewItem)

document.querySelectorAll('[data-app-login-button]').forEach(element =>
  element.addEventListener('click', event => {
    event.preventDefault()
    buildEventScreenSignIn()
  })
)
