import { useState, memo } from 'react'
import PropTypes from 'prop-types'
import qs from 'qs'

import * as dataLayer from 'scripts/utils/data-layer'

import { ImageWrapper } from '../../styled'
import * as S from './styled'

const BASE_SKETCHFAB_URL = 'https://sketchfab.com/models'

type Props = {
  modelId: string
  placeholder: string
  productId: number
}

const SketchfabModel = ({ modelId, placeholder, productId }: Props) => {
  const [shoudRenderModel, setShouldRenderModel] = useState(false)

  const embedOptions = {
    autostart: 1,
    camera: 0,
    ui_animations: 0,
    ui_infos: 0,
    ui_stop: 0,
    ui_inspector: 0,
    ui_watermark_link: 0,
    ui_watermark: 0,
    ui_ar: 0,
    ui_help: 0,
    ui_settings: 0,
    ui_vr: 0,
    ui_fullscreen: 1,
    ui_annotations: 0
  }

  const stringifiedEmbedOptions = qs.stringify(embedOptions)

  const modelUrl = `${BASE_SKETCHFAB_URL}/${modelId}/embed?${stringifiedEmbedOptions}`

  return (
    <S.SketchfabModel>
      {shoudRenderModel ? (
        <iframe title="sketchFabModel" allowFullScreen src={modelUrl} />
      ) : (
        <ImageWrapper
          data-testid="render-sketchfab"
          onClick={() => {
            setShouldRenderModel(true)
            dataLayer.midiasCarouselSketchfabStart(productId)
          }}
        >
          <img width="440px" src={placeholder} alt="Modelo 3d" />
        </ImageWrapper>
      )}
    </S.SketchfabModel>
  )
}

SketchfabModel.propTypes = {
  modelId: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  trackingEvent: PropTypes.object
}

export default memo(SketchfabModel)
