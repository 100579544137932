import { css } from '@emotion/react'
import styled from '@emotion/styled'

const wrapperStyles = ({ theme }) => css`
  label: wrapper;

  padding: ${theme.spacings.tera};
  margin-bottom: ${theme.spacings.byte};

  background-color: #02273b;
  background-size: 100px;
  background-image: url(${window && window.env.assetHost}assets/images/home/newsletter/cart.svg);
  background-repeat: no-repeat;
  background-position: calc(100% + 15px) 10px;
  border-radius: ${theme.borderRadius.tera};

  label {
    color: ${theme.colors.white};
  }

  ${theme.media.mega} {
    padding: ${theme.spacings.tera} ${theme.spacings.peta};
    background-position: -15px 10px;
  }
`

const headingStyles = ({ theme }) => css`
  margin-bottom: ${theme.spacings.giga};

  ${theme.media.mega} {
    margin-bottom: 0;
  }

  span {
    display: inline-block;
    color: ${theme.colors.g600};
  }
`

const inlineStyles = ({ theme }) => css`
  display: flex;
  align-items: flex-end;
  flex-direction: column;

  div {
    width: 100%;
    flex: 1 auto;
  }

  button {
    width: 100%;
    margin-top: ${theme.spacings.giga};
  }

  ${theme.media.mega} {
    flex-direction: row;

    button {
      width: auto;
      margin-left: ${theme.spacings.mega};
    }
  }
`

const textWrapperStyles = ({ theme }) => css`
  label: text-wrapper;

  margin-top: ${theme.spacings.byte};
  font-style: italic;
`

export const Wrapper = styled('div')(wrapperStyles)
export const Heading = styled('div')(headingStyles)
export const Inline = styled('div')(inlineStyles)
export const TextWrapper = styled('div')(textWrapperStyles)
