export const FORM_DEFAULT_VALUES = {
  fiscalId: '',
  name: '',
  email: '',
  emailConfirm: '',
  phone: '',
  password: '',
  loyalty: {
    optInPro: false,
    favoriteStore: '',
    occupation: '',
    birthdate: ''
  },
  optins: {
    general: false,
    email: false,
    sms: false,
    whatsApp: false
  }
}

export const FORM_FIELDS_CODES = [
  'fiscalId',
  'name',
  'email',
  'emailConfirm',
  'phone',
  'password',
  'loyalty.optInPro,',
  'loyalty.favoriteStore',
  'loyalty.birthdate',
  'loyalty.occupation'
]

export const PROFESSIONS = [
  {
    value: 'engineer',
    label: 'Engenheiro'
  },
  {
    value: 'architect',
    label: 'Arquiteto'
  },
  {
    value: 'decorator',
    label: 'Designer de Interiores e Ambientes'
  },
  {
    value: 'mason',
    label: 'Pedreiro'
  },
  {
    value: 'joiner',
    label: 'Marceneiro'
  },
  {
    value: 'electrician',
    label: 'Eletricista'
  },
  {
    value: 'ceramist',
    label: 'Ceramista'
  },
  {
    value: 'plumber',
    label: 'Encanador'
  },
  {
    value: 'gardener',
    label: 'Jardineiro'
  },
  {
    value: 'painter',
    label: 'Pintor'
  },
  {
    value: 'blacksmith',
    label: 'Serralheiro'
  },
  {
    value: 'landscaper',
    label: 'Paisagista'
  },
  {
    value: 'constructor',
    label: 'Construtor'
  },
  {
    value: 'personalOrganizer',
    label: 'Personal Organizer'
  }
]

export const CONTACT_OPTINS = [
  { item: 'email', label: 'E-mail' },
  { item: 'sms', label: 'SMS' },
  { item: 'whatsApp', label: 'WhatsApp' }
]
