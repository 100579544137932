import { Text } from '@leroy-merlin-br/backyard-react'

import { AlertMessage } from 'shared/components'

const FraudNotification = () => {
  return (
    <AlertMessage type="warning">
      Atenção!{' '}
      <Text as="strong" size="kilo" noMargin isBold>
        A Leroy Merlin não envia nenhum tipo de código via WhatsApp ou SMS{' '}
      </Text>
      para confirmar ou acessar sua conta,{' '}
      <Text as="strong" size="kilo" noMargin isBold>
        nem solicita pagamentos extras{' '}
      </Text>
      após a efetivação da sua compra. Não compartilhe seus dados com ninguém.
      Em caso de dúvidas, utilize nossos canais de atendimento.
    </AlertMessage>
  )
}

export default FraudNotification
