import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { Theme as BackyardTheme } from '@leroy-merlin-br/backyard-theme'

import { Theme } from 'newCheckout/types/Theme'

type Spacing = keyof BackyardTheme['spacings']

const cardStyles = ({ theme }: Theme) => css`
  label: method-list__item;

  &:hover,
  label:hover {
    cursor: pointer;
  }

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 0 1 0;

  min-height: 50px;
  min-width: 120px;
  width: 100%;
  padding: ${theme.spacings.mega};

  border-radius: ${theme.borderRadius.giga};
  background-color: ${theme.colors.white};
  box-shadow: 0 0 0 1px ${theme.colors.shadow}, 0 0 1px 0 ${theme.colors.shadow},
    0 2px 2px 0 ${theme.colors.shadow};
`

const activeStyles = ({ theme, isChecked, disabled }: Theme<{ isChecked: boolean, disabled: boolean }>) =>
  isChecked && !disabled &&
  css`
    label: method-list__item--active;

    color: ${theme.colors.n200};
    background-color: ${theme.colors.p600};
  `
const disableStyles = ({ theme, disable }: Theme<{ disable: boolean }>) =>
  disable &&
  css`
    label: method-list__item--disable;

    &:hover,
    label:hover {
      cursor: default;
    }

    color: ${theme.colors.n200};
    background-color: ${theme.colors.n100};
  `

const radioStyles = ({ theme, size = 'mega' }: Theme<{ size: Spacing }>) => css`
  label: method-list__radio;

  height: ${theme.spacings[size]};
  width: ${theme.spacings[size]};

  accent-color: ${theme.colors.p600};
`

const inlineStyles = ({ theme }: Theme) => css`
  label: method-list__radio;

  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`

const titleStyles = ({ theme }: Theme) => css`
  label: method-list__radio;

  width: 100%;
  margin-right: ${theme.spacings.kilo};
`

const wrapperStyles = ({ theme }: Theme) => css`
  label: wrapper;

  width: 100%;
`

export const Wrapper = styled('div')(wrapperStyles)
export const Card = styled('div')(cardStyles, activeStyles, disableStyles)
export const Radio = styled('input')(radioStyles)
export const Inline = styled('div')(inlineStyles)
export const Title = styled('label')(titleStyles)
