import { useController } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import { TextField as Input } from '@leroy-merlin-br/backyard-react'

const NumberField = ({ valueType = 'string', ...props }) => {
  const {
    field: { name, value, onChange, ref }
  } = useController(props)

  const getValue = event => {
    if (valueType === 'formatted') {
      return event.formattedValue
    }

    if (valueType === 'number') {
      return parseInt(event.value)
    }

    if (valueType === 'string') {
      return event.value
    }
  }

  return (
    <NumberFormat
      name={name}
      customInput={Input}
      getInputRef={ref}
      value={value}
      onValueChange={event => {
        const newValue = getValue(event)
        onChange(newValue)
      }}
      {...props}
    />
  )
}

export default NumberField
