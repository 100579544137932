import { css } from '@emotion/react'
import styled from '@emotion/styled'

const wrapperStyles = ({ theme }) => css`
  label: wrapper;

  background-color: ${theme.colors.white};
  border: 1px solid ${theme.colors.n100};
  display: flex;
  flex: 0 0 auto;
  flex-flow: column wrap;
  transition: border-color .3s ease-in-out;

  ${theme.media.kilo} {
    max-width: 280px;
  }

  &:hover {
    border-color: ${theme.colors.n200};
  }
`

const variableWidthStyles = ({ variableWidth, theme }) => variableWidth && css`
  label: wrapper--variable-width;

  width: 100%;

  ${theme.media.kilo} {
    max-width: unset;
  }
`

export const Wrapper = styled('div')(wrapperStyles, variableWidthStyles)
