import { css } from '@emotion/react'
import styled from '@emotion/styled'

const baseStyles = ({ theme }) => css`
  label: conditions;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: ${theme.spacings.byte};

  ${theme.media.giga} {
    margin-bottom: 0;
  }
`

export const Conditions = styled('div')(baseStyles)
