import axios from 'axios'

const baseHost = window.env && window.env.baseHost

export const getTerms = (term, isApiV3Enabled = false) => {
  const cancelTokenSource = axios.CancelToken.source()

  const cancelRequest = async () => cancelTokenSource.cancel()
  const endpoint = isApiV3Enabled ? '/api/v3/quick_search' : '/api/v1/quick_search'

  const request = async () => {
    return await axios.get(
      `${endpoint}?term=${term}`,
      {
        cancelToken: cancelTokenSource.token,
        baseURL: baseHost,
        withCredentials: true,
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-XSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').content
        }
      }
    )
  }

  return { cancelRequest, request }
}
