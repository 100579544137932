import { Stack, Text } from '@backyard-ui/core'
import { StarRating } from 'hpdp/components'

const EmptyState = () => (
  <Stack
    align={{ initial: 'flex-start', sm: 'center' }}
    direction={{ initial: 'column', sm: 'row' }}
  >
    <StarRating rating={0} />
    <Text color="gray-600">Nenhuma avaliação publicada até o momento.</Text>
  </Stack>
)

export default EmptyState
