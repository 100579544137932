import React from 'react'

import { renderTemplate } from '../render-template'

const ListAddress = ({ items }) => (
  <>
    {items.map((item, index) => (
      <fieldset key={index} className="col-xs-12" data-list-item="address">
        {Object.entries(item).map(
          ([property, value]) =>
            property !== 'data' && (
              <input
                key={property}
                type="hidden"
                name={`addresses[${index}][${property}]`}
                value={typeof value === 'boolean' ? value.toString() : value}
              />
            )
        )}

        <div className="col-xs-12 info-box">
          <div className="row">
            <div className="col-xs-12 info-text">{item.nickname}</div>
            <div className="col-xs-12 info-text">{`${item.street} - ${item.number}`}</div>
            <div className="col-xs-12 info-text">{`${item.district} - ${item.city} - ${item.state}`}</div>
            <div className="col-xs-12 info-text">
              CEP: <span data-mask="00000-000">{item.cep}</span>
            </div>
            {(item.default === 'true' || index === 0) && (
              <div className="col-xs-12 info-text">
                <strong>Endereço principal</strong>
              </div>
            )}
          </div>

          <div className="row">
            <div className="col-xs-5">
              <button
                className="button button-neutral"
                type="button"
                data-trigger="modal"
              >
                Editar
              </button>
            </div>
            <div className="col-xs-7 align-right">
              <button
                className="button button-text"
                type="button"
                data-trigger="remove"
              >
                <i className="glyph glyph-trash-can"></i> Remover Endereço
              </button>
            </div>
          </div>
        </div>
      </fieldset>
    ))}
  </>
)

const render = (props = {}) => renderTemplate(<ListAddress {...props} />)

export default render
