import PropTypes from 'prop-types'

export const cloudflarePropTypes = {
  fit: PropTypes.oneOf(['scale-down', 'contain', 'cover', 'crop', 'pad']),
  quality: PropTypes.number,
  dpr: PropTypes.number,
  gravity: PropTypes.oneOf(['left', 'right', 'top', 'bottom', 'center']),
  anim: PropTypes.bool,
  sharpen: PropTypes.number,
  blur: PropTypes.number,
  background: PropTypes.string,
  rotate: PropTypes.oneOf([90, 180, 270]),
  metadata: PropTypes.oneOf(['keep', 'copyright', 'none'])
}
