import {
  Text,
  Heading,
  Button,
  Stack,
  Row,
  Col,
  Container
} from '@leroy-merlin-br/backyard-react'
import { useHistory } from 'react-router-dom'
import { signupPostMessage } from 'user/signup/utils/appPostMessage'
import { useEffect } from 'react'

import * as dataLayer from 'scripts/utils/data-layer'

import * as S from './styled'

function Success () {
  const history = useHistory()

  const onSignupFinish = () => {
    dataLayer.signUpSuccessButtonClick()

    signupPostMessage()

    const redirect = sessionStorage.getItem('redirect')

    if (redirect) {
      sessionStorage.removeItem('redirect')
    }

    window.location.href = history.location.state?.redirect || redirect || '/'
  }

  useEffect(() => {
    dataLayer.signupSuccessPageRender(
      history.location.state?.signupType || 'pf'
    )
  }, [history])

  return (
    <S.Content>
      <Container>
        <S.Card>
          <Row>
            <Col size={{ giga: 4 }}>
              <S.WrapperImage>
                <img
                  src={`${
                    window && window.env.assetHost
                  }assets/images/signup/check.svg`}
                  alt="check"
                />
              </S.WrapperImage>
              <Stack space="kilo">
                <Heading size="tera" noMargin color="n900">
                  Tudo pronto, conta criada!
                </Heading>
                <Text size="mega" color="n600">
                  Agora que você já é de casa, fique a vontade para aproveitar
                  todas as funcionalidades do nosso site feitas para te ajudar a
                  deixar o seu ambiente do jeitinho que você sempre sonhou!
                </Text>
                <Row>
                  <Col size={{ giga: 10 }}>
                    <Button
                      isStretch
                      onClick={onSignupFinish}
                      data-cy="button-success"
                    >
                      Continuar
                    </Button>
                  </Col>
                </Row>
              </Stack>
            </Col>
          </Row>
        </S.Card>
      </Container>
    </S.Content>
  )
}

export default Success
