import PropTypes from 'prop-types'

import * as S from './styled'

const ButtonTrigger = ({ trigger, active }) => (
  <S.ButtonTrigger
    aria-label="Compartilhar"
    isActive={!!active}
    className="glyph glyph-share"
    onClick={trigger}
  />
)

ButtonTrigger.propTypes = {
  trigger: PropTypes.func.isRequired
}

export default ButtonTrigger
