import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { Theme } from 'scripts/react-components/themes/Theme'

const thanksWrapperStyles = ({ theme }: Theme) => css`
  label: chat__feedback--thanks-wrapper;

  width: calc(100% - (${theme.spacings.exa}));
  height: calc(100% - (${theme.spacings.exa}));
  margin: auto;
  display: flex;
  flex-direction: column;
  align-content: space-between;
  padding: calc(${theme.spacings.giga} + ${theme.spacings.bit});
  border-radius: ${theme.borderRadius.giga};
  background: ${theme.colors.white};
  box-shadow: 0px 2px 2px 0px ${theme.colors.shadow},
    0px 0px 0px 1px rgba(186, 182, 188, 0.07);
`

const titleStyles = ({ theme }: Theme) => css`
  label: chat__feedback--thanks-title;

  margin-top: ${theme.spacings.peta};
  margin-bottom: ${theme.spacings.tera};
  font-size: ${theme.typography.headings.mega.fontSize};
  line-height: 120%;
  font-weight: ${theme.font.weight.bold};
`

const orderedListStyles = ({ theme }: Theme) => css`
  label: chat__feedback--thanks-list;

  counter-reset: thanks-item;
  list-style: none outside;
  margin: 0px ${theme.spacings.byte} 0px 36px;
  padding: initial;
  display: grid;
  flex: 1;
  grid-gap: ${theme.spacings.tera};

  & li {
    flex-direction: column;
    justify-content: center;
    color: ${theme.colors.n800};
    font-size: ${theme.typography.subHeadings.mega.fontSize};
    line-height: 120%;
  }

  & li::before {
    counter-increment: thanks-item;
    content: counter(thanks-item, decimal);
    position: absolute;
    display: grid;
    align-content: center;
    justify-content: center;
    width: ${theme.spacings.giga};
    height: ${theme.spacings.giga};
    padding: ${theme.spacings.byte};
    margin-left: -36px;
    margin-right: ${theme.spacings.kilo};
    border-radius: ${theme.borderRadius.circle};
    border: 1px solid ${theme.colors.n200};
    color: ${theme.colors.n800};
    font-size: ${theme.typography.subHeadings.kilo.fontSize};
    font-weight: ${theme.font.weight.bold};
  }
`

export const ThanksWrapper = styled('div')(thanksWrapperStyles)
export const Title = styled('h2')(titleStyles)
export const OrderedList = styled('ol')(orderedListStyles)
