import DOMPurify from 'dompurify'
import { Button, Icon, Text } from '@leroy-merlin-br/backyard-react'
import { LocationOutline } from '@backyard-ui/icons'

import { default as tryAddToCart } from 'scripts/utils/cart/addToCart'

import * as S from './styled'

const StoreInfo = ({
  store,
  lm,
  region,
  hideStock = () => {},
  onMapSelect = () => {},
  isLocateOnStoreEnabled
}) => {
  const {
    name,
    message,
    inventoryDisclaimer,
    address,
    id,
    isPickupAvailable,
    pickupDisclaimer
  } = store
  const { street, number, cep, city } = address

  const addProductPickup = event => {
    event.preventDefault()
    tryAddToCart({
      regionId: region,
      storeId: id,
      type: 'pickupInStore',
      products: [
        {
          id: lm,
          quantity: 1
        }
      ],
      override: false
    })

    hideStock(event)
  }

  return (
    <S.StoreInfo>
      <Icon as={LocationOutline} />

      <div>
        <S.StoreName>{name}</S.StoreName>
        <S.StockQuantity>
          <Text size="kilo" noMargin>
            {message}
          </Text>
        </S.StockQuantity>

        {inventoryDisclaimer && (
          <S.InventoryDisclaimer>
            <Text
              size="kilo"
              noMargin
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(inventoryDisclaimer)
              }}
            />
          </S.InventoryDisclaimer>
        )}

        <Text size="kilo" noMargin>
          {street}, {number} - {cep} - {city}
        </Text>

        <S.Buttons>
          {isPickupAvailable && (
            <Button size="mega" onClick={addProductPickup} isStretch>
              Retire na loja
            </Button>
          )}

          {isLocateOnStoreEnabled && (
            <Button
              size="mega"
              variant="outline"
              onClick={() => onMapSelect(store)}
              isStretch
            >
              Localizar na Loja
            </Button>
          )}
        </S.Buttons>

        {pickupDisclaimer && (
          <S.PickupDisclaimer>
            <Text size="kilo" isBold noMargin>
              {pickupDisclaimer.header}
            </Text>
            <S.PickupDisclaimerList>
              {pickupDisclaimer.body.map((disclaimer, index) => (
                <li key={index}>{disclaimer}</li>
              ))}
            </S.PickupDisclaimerList>
          </S.PickupDisclaimer>
        )}
      </div>
    </S.StoreInfo>
  )
}

export default StoreInfo
