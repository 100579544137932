import { Item } from 'frontendMyOrders/services/sale-order'
import { Image } from 'frontendMyOrders/user-interface/media'
import { Text, UserInterface } from 'frontendMyOrders/user-interface/typography'
import { FC } from 'react'
import { Badge } from 'frontendMyOrders/user-interface/badge'

import formatPriceToBRL from 'utils/formatPriceToBRL'
import { useResize } from 'utils/hooks/use-resize'

import * as S from './styled'

type Props = {
  item?: Item
}

const ProductItem: FC<Props> = ({ item }: Props) => {
  const [isMobile] = useResize()

  if (!item) {
    return <div>Carregando...</div>
  }

  const isCanceled = item.status === 'canceled'

  return (
    <S.Wrapper>
      <S.FlexRow>
        <S.ImageWrapper>
          <Image
            src={item.image}
            alt={item.name}
            width={60}
            radius="sm"
          ></Image>
        </S.ImageWrapper>
        <S.FlexColumn>
          <UserInterface
            tag="h2"
            size={isMobile ? 'sm' : 'md'}
            appearance="neutral-strong"
            strike={isCanceled}
          >
            {item.name}
          </UserInterface>
          <Text
            appearance="neutral-soft"
            strike={isCanceled}
          >{`Unidades: ${item.quantity}x`}</Text>
        </S.FlexColumn>
      </S.FlexRow>
      <S.FlexColumn>
        <UserInterface
          strike={isCanceled}
          appearance="neutral-strong"
          size="md"
          type="strong"
        >
          {formatPriceToBRL(item.price.unit)}
        </UserInterface>
        {isCanceled && <Badge label="Cancelado" type="danger" />}
      </S.FlexColumn>
    </S.Wrapper>
  )
}

export default ProductItem
