import { forwardRef } from 'react'
import classNames from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'

const variants = {
  initial: {
    opacity: 0
  },
  enter: {
    opacity: 1,
    transition: {
      duration: 1.5
    }
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 5
    }
  }
}

const Overlay = forwardRef(({ isOpen, onClick }, ref) => {
  const componentClass = classNames(
    'drawer-overlay',
    { entered: isOpen }
  )

  return (
    <div className={componentClass} ref={ref} onClick={onClick}>
      <AnimatePresence>
        {
          isOpen && <motion.div
            variants={variants}
            initial="initial"
            animate="enter"
            exit="exit"
          />
        }
      </AnimatePresence>
    </div>
  )
})

export default Overlay
