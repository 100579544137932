import styled from '@emotion/styled'
import { css } from '@emotion/react'

const TRANSITION_DELAY = 500

const contentStyles = ({ theme, height }) => css`
  label: content;

  min-height: 20px;
  height: ${height}px;

  margin-bottom: ${theme.spacings.byte};

  overflow: hidden;

  transition: height ${TRANSITION_DELAY}ms ease-in-out;

  will-change: height;

  position: relative;
`

const rowLayoutStyles = ({ isOpen, isRowLayout }) =>
  isRowLayout &&
  css`
    label: content--row-layout;

    flex: ${isOpen ? '0 1 100%' : '1 0 0%'};

    transition: height ${TRANSITION_DELAY}ms ease-in-out,
      flex 0ms ease-in-out ${isOpen ? 0 : TRANSITION_DELAY - 30}ms;
  `

const floatButtonStyles = ({ theme }) => css`
  label: float-button;

  position: fixed;
  bottom: 40px;
  right: 50%;
  transform: translateX(50%);
  z-index: 2;

  border-radius: ${theme.borderRadius.giga};
  background: ${theme.colors.white};
  box-shadow: 0 0 0 1px ${theme.colors.shadow},
    0 ${theme.spacings.bit} ${theme.spacings.bit} 0 ${theme.colors.shadow},
    0 ${theme.spacings.byte} ${theme.spacings.byte} 0 ${theme.colors.shadow};
`

const buttonWrapperStyles = () => css`
  label: button-wrapper;

  display: flex;
  justify-content: flex-end;
`

const moveRightButtonWrapperStyles = ({ moveRight }) =>
  moveRight &&
  css`
    label: button-wrapper--move-right;

    width: 100%;
  `

const defaultWrapperStyles = () => css`
  label: default-wrapper;

  display: inline-block;

  width: 100%;
`

const wrapperStyles = () => css`
  label: wrapper;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`

const showEllipsisStyles = ({ shouldShowEllipsis }) =>
  shouldShowEllipsis &&
  css`
    label: default-wrapper--show-ellipsis;

    [target='markdown'] > *:first-of-type {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      transition: all 0ms ease-in-out 500ms;
    }
  `

export const Content = styled('div')(contentStyles, rowLayoutStyles)
export const FloatButton = styled('div')(floatButtonStyles)
export const ButtonWrapper = styled('div')(
  buttonWrapperStyles,
  moveRightButtonWrapperStyles
)
export const DefaultWrapper = styled('div')(
  defaultWrapperStyles,
  showEllipsisStyles
)
export const Wrapper = styled('div')(wrapperStyles)
