import { Fragment } from 'react'
import { Text } from '@leroy-merlin-br/backyard-react'

import emitter from 'scripts/utils/emitter'

const MixedCartStoreMessage = ({ store }) => (
  <Fragment>
    <Text>
      Você já escolheu produtos para{' '}
      <Text isBold as="span">
        retirar na Loja { store }
      </Text>
      . No momento, você pode escolher seus itens apenas para{' '}
      <Text isBold as="span">
        retirada na mesma loja
      </Text>
      . Caso queira{' '}
      <Text isBold as="span">
        receber esse produto
      </Text>
      , acesse nosso canal de{' '}
      <Text isBold as="span">
        vendas por WhatsApp
      </Text>
      , nossos assessores vão te ajudar a{' '}
      <Text isBold as="span">
        finalizar seu pedido
      </Text>{' '}
      de itens para{' '}
      <Text isBold as="span">
        receber
      </Text>
      . Você já escolheu produtos para{' '}
      <Text isBold as="span">
        receber
      </Text>
      . No momento, você pode escolher seus itens apenas para{' '}
      <Text isBold as="span">
        receber no mesmo endereço
      </Text>
      .
    </Text>
    <Text>
      Caso queira{' '}
      <Text isBold as="span">
        retirar esse produto
      </Text>
      , acesse nosso{' '}
      <Text isBold as="span">
        canal de vendas por WhatsApp
      </Text>
      , nossos assessores vão te ajudar a{' '}
      <Text isBold as="span">
        finalizar seu pedido
      </Text>{' '}
      de itens para{' '}
      <Text isBold as="span">
        retirar
      </Text>
      .
    </Text>
  </Fragment>
)

const mixedCartDelivery = ({ store }) => {
  emitter.emit('alertModal', {
    title: 'Pedido misto',
    description: <MixedCartStoreMessage store={store} />,
    type: 'whatsapp'
  })
}

export default mixedCartDelivery
