import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { Theme } from 'scripts/react-components/themes/Theme'

const formWrapperStyles = ({ theme }: Theme) => css`
  label: chat-form;

  & label {
    display: none;
  }

  & input {
    background: ${theme.colors.white};
    color: ${theme.colors.n800};

    display: flex;
    align-items: flex-start;
    flex-shrink: 0;

    resize: none;
    height: 40px !important;
    min-height: 28px;
    max-height: 40px;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 12px;
    font-weight: ${theme.font.weight.regular};
    line-height: 13px;
    gap: 12px;

    border: 1px solid ${theme.colors.n100};
    border-radius: 40px;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }

    &:hover,
    &:focus,
    &:focus-visible,
    &:active {
      border: 1px solid ${theme.colors.n100};
      background: white;
      box-shadow: none;
    }
  }

  & button {
    pointer-events: all;
    cursor: pointer;
    height: 100%;
    width: 40px;
    padding: 3px 0px 0px 0px;
    border: 0;
    outline: none;

    &:hover,
    &:focus,
    &:focus-visible,
    &:active {
      color: ${theme.colors.n500};
      background: transparent;
      border: none;
      box-shadow: none;
    }

    & svg {
      transform: rotate(30deg);
      color: ${theme.colors.n500};
    }
  }
`

export const Form = styled('form')(formWrapperStyles)
