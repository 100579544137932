import { css } from '@emotion/react'
import styled from '@emotion/styled'

const baseStyles = ({ theme }) => css`
  label: select__container;

  position: relative;

  width: 100%;

  &:hover ${Select} {
    border-color: ${theme.colors.p600};
  }

  &:focus ${Select} {
    border-color: ${theme.colors.p600};
    box-shadow: 0 0 0 3px ${theme.colors.p100};
  }

  &:active ${Select} {
    border-color: ${theme.colors.p700};
    box-shadow: 0 0 0 3px ${theme.colors.p100};
  }

  ${IconContainer} {
    width: 40px;
  }

  ${Select} {
    pointer-events: auto;
  }
`

const isSelectedBaseStyles = ({ theme, isSelected }) =>
  isSelected &&
  css`
    label: select__container--selected;

    ${IconContainer} {
      width: 52px;
    }

    ${Select} {
      border-color: ${theme.colors.g700};
      pointer-events: none;
    }
  `

const selectStyles = ({ theme }) => css`
  label: select;

  user-select: none;

  width: 100%;
  box-sizing: border-box;
  padding: calc(${theme.spacings.kilo} - ${theme.borderWidth.kilo});
  padding-right: ${theme.spacings.peta};

  font-size: ${theme.typography.text.mega.fontSize};
  line-height: ${theme.typography.text.mega.lineHeight};

  color: ${theme.colors.n900};
  text-align: left;

  background: ${theme.colors.white};

  border-color: ${theme.colors.n100};
  border-radius: ${theme.borderRadius.giga};
  outline: 0;

  border-style: solid;
  border-width: ${theme.borderWidth.kilo};

  appearance: none;
  transition: box-shadow ${theme.transition.enter},
    border-color ${theme.transition.enter};
`

const iconContainerStyles = ({ theme }) => css`
  label: icon__container;

  position: absolute;
  display: flex;

  height: 100%;
  width: 52px;
  right: 0;

  justify-content: center;
  align-items: center;
  pointer-events: none;
  user-select: none;

  color: ${theme.colors.n300};
`

const clearButtonStyles = ({ theme }) => css`
  label: clear-button;

  width: 20px;
  height: 20px;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  pointer-events: auto;
  user-select: none;

  background: ${theme.colors.n100};

  border-radius: ${theme.borderRadius.circle};

  transition: background ${theme.transition.leave} ease;

  & svg {
    width: 8px;
    height: 8px;

    pointer-events: none;
    user-select: none;

    color: ${theme.colors.n800};
  }

  &:hover {
    background: ${theme.colors.n200};

    & svg {
      color: ${theme.colors.black};
    }
  }
`

export const SelectContainer = styled('div')(baseStyles, isSelectedBaseStyles)
export const Select = styled('select')(selectStyles)
export const IconContainer = styled('div')(iconContainerStyles)
export const ClearButton = styled('div')(clearButtonStyles)
