import { FieldErrors } from 'react-hook-form'
import { RouterProps } from 'react-router-dom'

import { flattenFieldErrorObject } from '../flattenFieldErrorObject'

export const redirectOnUnknownError = (
  errors: FieldErrors,
  history: RouterProps['history']
) => {
  const errorsObject = flattenFieldErrorObject(errors)
  const errorsArray = Object.values(errorsObject)

  const isUnknownError = errorsArray.some(
    (value: any) => typeof value.ref === 'undefined'
  )

  if (isUnknownError) {
    history.push('/erro-interno')
  }
}
