import { call, put, takeLatest } from 'redux-saga/effects'

import * as service from 'lmcv/services/customers'

import { Constants, Actions } from '.'

function * getCustomers () {
  try {
    const customers = yield call(service.getCustomers)

    yield put(Actions.getSuccess(customers))
  } catch (error) {
    yield put(Actions.getFailure(error))
  }
}

export default function * root () {
  yield takeLatest(Constants.CUSTOMERS_GET, getCustomers)
}
