import styled from '@emotion/styled'
import { css } from '@emotion/react'

const sectionStyles = ({ theme }) => css`
  label: hero-banner;

  padding: ${theme.spacings.tera} ${theme.spacings.giga} ${theme.spacings.exa};

  ${theme.media.giga} {
    padding: 0;
  }

  overflow-x: hidden;
`

const titleWrapperStyles = ({ theme }) => css`
  label: hero-banner__title-wrapper;

  span {
    color: ${theme.colors.g500};
  }
`

const buttonWrapperStyles = ({ theme }) => css`
  label: hero-banner__button-wrapper;

  margin-top: ${theme.spacings.peta};

  ${theme.media.giga} {
    margin-top: 70px;
  }
`

const wrapperImageStyles = ({ theme }) => css`
  label: hero-banner__wrapper-image;

  width: 100%;

  display: flex;

  ${theme.media.kilo} {
    justify-content: center;
  }
`

const imageStyles = ({ theme }) => css`
  label: wrapper__image;

  position: relative;

  width: 120%;
  right: -16px;

  ${theme.media.kilo} {
    width: 100%;
    right: 0;
  }

  ${theme.media.mega} {
    width: auto;
    right: 0;
  }

  ${theme.media.giga} {
    width: auto;
    height: 588px;
    right: 0;
  }
`

const iconWrapperStyles = ({ theme }) => css`
  label: hero-banner__icon-wrapper;

  svg {
    width: 100px;
    height: 48px;
  }

  ${theme.media.giga} {
    svg {
      margin-top: ${theme.spacings.exa};
    }
  }
`

const subtitleWrapperStyles = ({ theme }) => css`
  label: hero-banner__subtitle-wrapper;

  ${theme.media.giga} {
    max-width: 430px;
  }
`

export const Section = styled('section')(sectionStyles)
export const TitleWrapper = styled('div')(titleWrapperStyles)
export const ButtonWrapper = styled('div')(buttonWrapperStyles)
export const Image = styled('img')(imageStyles)
export const WrapperImage = styled('div')(wrapperImageStyles)
export const IconWrapper = styled('div')(iconWrapperStyles)
export const SubtitleWrapper = styled('div')(subtitleWrapperStyles)
