import axios from 'axios'

import { stringifyQuery } from '../utils/buildUrl'
import { ENTRYKEY_THUMBS_PER_PAGE } from '../utils/constants'

const buildEndpointByType = ({ apiDomain, categoryId, type }) => {
  const endpoints = {
    entryKey: `${apiDomain}/api/boitata/v1/categories/${categoryId}/products?perPage=${ENTRYKEY_THUMBS_PER_PAGE}`,
    search: `${apiDomain}/api/boitata/v1/search`,
    mappedSearch: `${apiDomain}/api/boitata/v1/search`,
    imageSearch: `${apiDomain}/api/boitata/v1/image_search`
  }

  return endpoints[type]
}

const getApiData = ({ endpoint, query }) => {
  const response = axios.get(endpoint, {
    params: query,
    paramsSerializer: params =>
      stringifyQuery(params, { addQueryPrefix: false, encode: true })
  })
    .then(({ data }) => data)
    .catch(error => { throw error.response })

  return response
}

export const getResource = ({ apiDomain, categoryId, type, query }) => {
  const endpoint = buildEndpointByType({ apiDomain, categoryId, type })
  const data = getApiData({ endpoint, query })

  return data
}
