import PropTypes from 'prop-types'
import { useSelector, shallowEqual } from 'react-redux'
import { Plus, Checkmark } from '@backyard-ui/icons'
import { Button } from '@leroy-merlin-br/backyard-react'

import { Selectors } from 'checkout/redux/modules/associatedProducts'

import * as S from './styled'

const ButtonComponent = ({ onAddToCartClick, id }) => {
  const { wasAdded = false, isLoading = false } = useSelector(
    state => Selectors.putStatusProductById(state, id),
    shallowEqual
  )

  const Icon = () => (wasAdded
    ? <Checkmark width={13} height={13} />
    : <Plus width={13} height={13} />
  )

  return (
    <S.WapperButton>
      <Button
        variant="outline"
        size="kilo"
        isLoading={isLoading}
        onClick={() => !wasAdded && !isLoading && onAddToCartClick()}
        isStretch
        isDisabled={wasAdded}
        data-cy="button-add-complementary-product"
      >
        <Icon />
        {wasAdded ? 'Produto adicionado' : 'Adicionar'}
      </Button>
    </S.WapperButton>
  )
}

ButtonComponent.propTypes = {
  onAddToCartClick: PropTypes.func.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
}

export default ButtonComponent
