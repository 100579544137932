import styled from '@emotion/styled'
import { css } from '@emotion/react'

const wrapperModalStyles = ({ theme }) => css`
  label: modal-app__wrapper;

  position: relative;
  display: flex;
  padding: ${theme.spacings.mega};
  flex-direction: column;
  -webkit-box-pack: justify;
  justify-content: space-evenly;
  background: ${theme.colors.white};
  border-radius: 0 0 ${theme.borderRadius.mega} ${theme.borderRadius.mega};
  pointer-events: auto;
  align-items: left;
  min-height: 70px;

`
const wrapperModalTitleStyles = ({ theme }) => css`
  label: modal-app__wrapper_title;

  position: relative;
  display: flex;
  padding: ${theme.spacings.mega};
  flex-direction: row;
  -webkit-box-pack: justify;
  justify-content: flex-start;
  color: ${theme.colors.white};
  background: ${theme.colors.p500};
  border-radius: ${theme.borderRadius.mega} ${theme.borderRadius.mega} 0 0;
  pointer-events: auto;
  min-height: 10px;
  align-items: left;

`
const titleStyles = ({ theme }) => css`
  label: modal-app__text-title;
  color: ${theme.colors.white};
  font-size: ${theme.typography.text.mega.fontSize};
  margin-left: 5px;
  margin-top: 5px;
`
const textBodyStyles = ({ theme }) => css`
  label: modal-app__text-body;

  font-size: ${theme.typography.text.mega.fontSize};
`

const iconInfoStyles = () => css`
  label: modal-app__icon;

  transform: rotate(180deg);
`

const buttonStyles = () => css`
  label: modal-app__button;
  align-items: left;
`
export const WrapperModal = styled('div')(wrapperModalStyles)
export const WrapperModalTitle = styled('div')(wrapperModalTitleStyles)
export const Title = styled('label')(titleStyles)
export const TextBody = styled('div')(textBodyStyles)
export const Button = styled('div')(buttonStyles)
export const Icon = styled('div')(iconInfoStyles)
