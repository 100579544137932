import breakpoints from 'scripts/constants/breakpoints'
import { pushDataObject } from 'scripts/utils/data-layer'

const MOBILE_PREFIX = 'm_'

const isMobile = (width = window.innerWidth) => width <= breakpoints.sm

export const event = ({
  eventCategory,
  eventAction,
  eventLabel,
  eventValue
}) => {
  if (isMobile()) {
    eventCategory = `${MOBILE_PREFIX}${eventCategory}`
  }

  if (window.ga) {
    window.ga(
      'send',
      'event',
      eventCategory,
      eventAction,
      eventLabel,
      eventValue
    )
  }

  pushDataObject({
    event: eventAction,
    eventCategory,
    eventLabel,
    eventValue
  })
}

export default event
