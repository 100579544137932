import $ from 'jquery'
import querystring from 'querystring'

import emitter from 'scripts/utils/emitter'
import accentsReplacer from 'scripts/utils/entry-key/accents-replacer'
import BaseComponent from 'scripts/components/base-component'

const DEFAULTS = {
  urlAttribute: 'href'
}

class ProductsFilter extends BaseComponent {
  static componentName = 'products-filter'

  static DOMEvents = {
    click: 'onElementClick'
  }

  constructor (element, options = {}, location = window.location, history = window.history) {
    super(element)

    this.location = location
    this.history = history
    this.options = $.extend({}, DEFAULTS, options)
  }

  init () {
    this.query = this.getQuery()
    this.filters = this.getFilters()
    this.facet = this.getFacet()
    this.facetSuffix = this.getSuffix()
    this.queryValue = this.getQueryValue()
    this.origin = this.getOrigin()

    this.updateElement()

    return this
  }

  getQuery () {
    const query = (this.$element.data('query') || '').replace('&', '%26')
    return this.parse(query)
  }

  getFilters () {
    const queries = this.location.search.slice(1)
    return this.parse(queries)
  }

  getFacet () {
    return (this.$element.data('facet') || '').replace(/\s/g, '-')
  }

  getSuffix () {
    return (this.$element.data('suffix') + '').replace(/\s/g, '_')
  }

  getQueryValue () {
    const key = Object.keys(this.query)[0]
    return (this.query[key] || '').replace(/\s/g, '_')
  }

  getOrigin () {
    return this.$element.data('origin')
  }

  onElementClick (event) {
    event.preventDefault()

    if (this.$element[0].hasAttribute('data-stock-trigger')) {
      this.onSetStockQuery()
    }

    const url = this.$element.attr(this.options.urlAttribute)

    emitter.emit('overlay-space:hide')
    emitter.emit('products-pagination:request', url)
    emitter.emit('ordering-filters:refresh')

    if (this.isOrdering()) {
      this.$element.trigger('blur')
    }
  }

  onSetStockQuery () {
    const parent = this.$element.closest('.row')
    const minimumStock = parent.find('[data-stock-quantity]').val()

    this.$element.attr('data-query', `minimumStock=${minimumStock}`)
    this.query = Object.assign({}, this.query, { minimumStock })

    this.updateElement()
  }

  refreshOrderingFilters () {
    this.filters = this.getFilters()
    this.updateElement()
  }

  updateElement () {
    this.updateUrl()
    this.toggleActiveClass()
  }

  updateUrl () {
    const href = this.buildUrl()

    this.$element.attr(this.options.urlAttribute, href)
  }

  toggleActiveClass () {
    this.$element.toggleClass('active', this.isFilterApplied())
  }

  parse (string) {
    return querystring.parse(string)
  }

  buildUrl () {
    const pathname = this.buildPathname()
    const query = this.buildQuery()

    if (this.shouldSetExclusiveURL() || !query) {
      return `${pathname}`
    }

    return `${pathname}?${query}`
  }

  buildQuery () {
    const filters = $.extend({}, this.filters, this.query)

    if (this.shouldRemoveFromFilters()) {
      delete filters[Object.keys(this.query)[0]]
    }

    delete filters.page

    return decodeURIComponent(querystring.stringify(filters))
  }

  buildPathname () {
    const pathname = this.location.pathname.replace(/\/$/, '')
    const facet = accentsReplacer(this.facet)
    const facetSuffix = this.isOrdering() ? facet : `${facet}/${this.facetSuffix}`

    if (this.shouldAddToPathname()) {
      return this.isBlocked() ? `/${this.origin}/${facet}` : `${pathname}/${facetSuffix}`
    } else {
      return pathname.split(`/${encodeURI(facetSuffix)}`).join('')
    }
  }

  isQueryApplied () {
    return Object.keys(this.query).reduce((acc, key) => {
      return acc && this.query[key] === this.filters[key]
    }, true)
  }

  isURLApplied () {
    if (this.isURLFilter()) {
      const facet = accentsReplacer(this.facet)
      const facetSuffix = this.isOrdering() ? facet : `${facet}/${encodeURI(this.queryValue)}`

      return this.location.pathname.indexOf(facetSuffix) !== -1
    }

    return false
  }

  isFilterApplied () {
    return this.isURLApplied() || this.isQueryApplied()
  }

  isOrdering () {
    return !!+this.$element.data('ordering')
  }

  hasExclusiveAttribute () {
    return !!+this.$element.data('exclusive-url')
  }

  hasIgnoreExclusiveAttribute () {
    return !!+this.$element.data('ignore-exclusive-url')
  }

  isOrderingExclusive () {
    return this.isOrdering() && (Object.keys(this.filters).length === 0)
  }

  isBlocked () {
    const pathname = this.location.pathname
    const isFacetOnPathname = pathname.indexOf(this.facet) === -1
    const isBlockerOnPathname = pathname.indexOf('preco') !== -1

    return isFacetOnPathname && isBlockerOnPathname && this.isBlocker()
  }

  isBlocker () {
    return this.facet.indexOf('preco') !== -1
  }

  isPathFree () {
    const facetFromPath =
      this.location.pathname
        .replace(this.origin, '')
        .replace(/\//g, '')

    return facetFromPath.length === 0
  }

  isExclusive () {
    return !this.hasIgnoreExclusiveAttribute() &&
           (this.hasExclusiveAttribute() || this.isOrderingExclusive())
  }

  hasQuerystring () {
    return Object.keys(this.filters).length
  }

  shouldSetExclusiveURL () {
    return this.isExclusiveAndPathIsFree() && !this.hasQuerystring()
  }

  isExclusiveAndPathIsFree () {
    return this.isExclusive() && this.isPathFree()
  }

  shouldRemoveFromFilters () {
    return this.isFilterApplied() ||
           this.isExclusive() && this.isBlocked() ||
           this.isExclusiveAndPathIsFree()
  }

  shouldAddToPathname () {
    return this.isExclusiveAndPathIsFree() && !this.isQueryApplied() ||
           this.isBlocked()
  }

  isURLFilter () {
    return this.isExclusive() || this.isBlocker() || this.isOrdering()
  }
}

export default ($el) => new ProductsFilter($el).init()

export { ProductsFilter as Component }
