import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { styleHelpers } from '@leroy-merlin-br/backyard-react'

const wrapperStyles = ({ theme }) => css`
  label: wrapper;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, auto);
  grid-row-gap: ${theme.spacings.kilo};
  align-items: center;

  padding: ${theme.spacings.kilo} ${theme.spacings.byte};

  background: ${theme.colors.white};

  ${theme.media.mega} {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    grid-column-gap: ${theme.spacings.bit};
    justify-items: start;

    margin-bottom: 0;
  }
`

const infoStyles = ({ theme }) => css`
  label: info;

  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  column-gap: ${theme.spacings.kilo};
`

const logoStyles = ({ theme }) => css`
  label: logo;

  border: 1px solid ${theme.colors.n100};
  height: 60px;
  width: 60px;
`

const labelStyles = ({ theme }) => css`
  label: label;

  ${styleHelpers.subHeadingMega(theme)};
  font-weight: ${theme.font.weight.bold};

  color: ${theme.colors.n800};
  word-wrap: break-word;
`

export const Wrapper = styled('section')(wrapperStyles)
export const Info = styled('div')(infoStyles)
export const Logo = styled('img')(logoStyles)
export const Label = styled('div')(labelStyles)
