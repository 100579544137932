import PropTypes from 'prop-types'
import { Icon } from '@leroy-merlin-br/backyard-react'
import { ChevronLeftOutline, ChevronRightOutline } from '@backyard-ui/icons'

import * as S from './styled'

const Ellipsis = ({ hasOffset, prevProps, nextProps }) => (
  <S.Navigation hasOffset={hasOffset}>
    <S.Box {...prevProps} side="left">
      <Icon as={ChevronLeftOutline} size="giga" />
    </S.Box>
    <S.Box {...nextProps} side="right">
      <Icon as={ChevronRightOutline} size="giga" />
    </S.Box>
  </S.Navigation>
)

Ellipsis.propTypes = {
  hasOffset: PropTypes.bool,
  prevProps: PropTypes.object.isRequired,
  nextProps: PropTypes.object.isRequired
}

export default Ellipsis
