import PropTypes from 'prop-types'

import { GenericCarousel } from 'shared/components/GenericCarousel'
import { GtmDataAttributesGenerator } from 'shared/tracker/data-attributes-generators'

import { CategoryItem } from './components/CategoryItem'

const CategoryCarousel = ({ title, elementsType, items, onItemClick }) => (
  <GenericCarousel
    spaceBetween={15}
    slidesPerView="auto"
    centerInsufficientSlides
  >
    {items.map((item, index) => (
      <div
        key={item.id}
        {...GtmDataAttributesGenerator.banner({
          id: item.id,
          elementType: elementsType,
          type: 'carousel',
          name: item.name,
          index: index,
          listName: title
        })}
      >
        <CategoryItem item={item} onClick={onItemClick} />
      </div>
    ))}
  </GenericCarousel>
)

CategoryCarousel.propTypes = {
  title: PropTypes.string,
  elementsType: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  onItemClick: PropTypes.func
}

export default CategoryCarousel
