import { Constants } from '.'

const initialState = {
  get: {
    isRequest: true,
    payload: {},
    error: {}
  }
}

export default function reducer (state = initialState, action = {}) {
  switch (action.type) {
    case Constants.USER_PROFILE_GET:
      return {
        ...state,
        get: {
          ...state.get
        }
      }

    case Constants.USER_PROFILE_GET_SUCCESS:
      return {
        ...state,
        get: {
          ...state.get,
          isRequest: false,
          payload: action.payload
        }
      }

    case Constants.USER_PROFILE_GET_FAILURE:
      return {
        ...state,
        get: {
          ...state.get,
          isRequest: false,
          error: action.error
        }
      }

    default:
      return state
  }
}
