import produce from 'immer'

import { Constants } from './'

const initialState = {
  region: {},
  services: [],
  shippings: [],
  availableServices: []
}

const reducer = (state = initialState, action = {}) =>
  produce(state, draft => {
    switch (action.type) {
      case Constants.CART_SET_REGION:
        draft.region = action.data
        break

      case 'SET_CART_SERVICES':
        draft.services = action.payload
        break

      case 'SET_CART_SHIPPINGS':
        draft.shippings = action.payload.map(shipping => ({ type: shipping.type, storeId: shipping.storeId, products: shipping.items }))
        break

      case 'SET_AVAILABLE_SERVICES':
        draft.availableServices = action.payload
        break

      default:
        return draft
    }
  })

export default reducer
