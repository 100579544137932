import { tv } from 'tailwind-variants'

export const message = tv({
  base: 'flex gap-1 mt-1 items-center',
  variants: {
    color: {
      success: 'text-success-700',
      error: 'text-critical-700'
    },
    size: {
      xs: 'text-xs'
    }
  },
  defaultVariants: {
    size: 'xs'
  }
})
