import styled from '@emotion/styled'
import { css } from '@emotion/react'

const directionRow = css`
  grid-template-columns: auto 1fr;
  grid-template-rows: auto;
`
const directionColumn = css`
  grid-template-columns: auto;
  grid-template-rows: auto auto;
`

const wrapperStyles = ({ theme }) => css`
  position: relative;
  bottom: 0;
  right: 0;
  left: 0;

  display: grid;
  ${directionRow}
  grid-column-gap: ${theme.spacings.byte};

  background: ${theme.colors.p600};
  color: ${theme.colors.white};

  font-weight: ${theme.font.weight.semibold};
  font-family: ${theme.font.family.default};
  font-size: ${theme.typography.text.giga.fontSize};
  line-height: ${theme.typography.headings.giga.lineHeight};

  padding: ${theme.spacings.byte};
  padding-left: ${theme.spacings.kilo};

  @media (min-width: 375px) {
    position: absolute;
  }

  ${theme.media.kilo} {
    ${directionColumn}
  }

  ${theme.media.mega} {
    ${directionRow}
  }

  ${theme.media.giga} {
    padding: ${theme.spacings.byte} ${theme.spacings.tera} ${theme.spacings.byte} ${theme.spacings.mega};

    font-size: ${theme.typography.headings.tera.fontSize};
    line-height: ${theme.typography.headings.tera.lineHeight};
  }
`

const blackWeekendStyles = ({ theme, isBlackWeekend }) =>
  isBlackWeekend && css`
    background: ${theme.colors.black};
    color: ${theme.colors.p500};

    & > span {
      color: ${theme.colors.white};
    }
  `

const bannerStyles = ({ theme, category }) => {
  const banners = {
    full_banner: css`
      @media (min-width: 480px) {
        ${directionRow}
      }

      ${theme.media.kilo} {
        left: unset;
        ${directionRow}
      }
    `,

    carousel_full_banner: css`
      top: 0;
      bottom: unset;
      position: absolute;

      @media (min-width: 480px) {
        ${directionRow}
      }

      ${theme.media.kilo} {
        left: unset;
        ${directionRow}
      }

      ${theme.media.mega} {
        left: unset;
        top: unset;
        bottom: 0;
        ${directionRow}
      }
    `,

    two_banners: css`
      @media (min-width: 480px) {
        ${directionColumn}
      }

      ${theme.media.mega} {
        right: unset;
        ${directionRow}
      }
    `,

    two_banners_wide: css`
      @media (min-width: 480px) {
        position: relative;
        ${directionColumn}
      }

      ${theme.media.mega} {
        position: absolute;
        right: unset;
        ${directionRow}
      }
    `,

    three_banners: css`
      @media (min-width: 480px) {
        font-size: ${theme.typography.subHeadings.kilo.fontSize};
        line-height: ${theme.typography.subHeadings.kilo.lineHeight};

        ${directionColumn}
      }

      ${theme.media.kilo} {
        font-size: ${theme.typography.text.giga.fontSize};
        line-height: ${theme.typography.headings.giga.lineHeight};
      }

      ${theme.media.giga} {
        font-size: ${theme.typography.headings.tera.fontSize};
        line-height: ${theme.typography.headings.tera.lineHeight};

        right: unset;
      }

      ${theme.media.tera} {
        ${directionRow}

        right: 0;
      }
    `
  }

  return banners[category]
}

const timeWrapperStyles = ({ theme }) => css`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: ${theme.spacings.bit};
  align-items: start;
  justify-content: start;
`

export const Wrapper = styled('div')(wrapperStyles, bannerStyles, blackWeekendStyles)

export const TimeWrapper = styled('div')(timeWrapperStyles)
