import emitter from 'scripts/utils/emitter'

const genericError = (message) => {
  emitter.emit('alertModal', {
    title: 'Ops!',
    description: message
  })
}

export default genericError
