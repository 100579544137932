import Header from './Header/Header'
import Wrapper from './Wrapper/Wrapper'
import Footer from './Footer/Footer'
import Body from './Body/Body'

export default {
  Header,
  Wrapper,
  Footer,
  Body
}
