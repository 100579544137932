import { Text, Card } from '@leroy-merlin-br/backyard-react'

import { useChatbot } from 'lmcv/hooks'
import { FaqList } from 'lmcv/components'

import * as S from './styled'

const Faq = () => {
  const { openChatbot } = useChatbot()

  return (
    <S.CardWrapper>
      <Card title="Dúvidas">
        <FaqList />

        <S.ContactsContainer>
          <Text isBold size="giga">
            Ainda com dúvidas?
          </Text>

          <S.ContainerFlex>
            <S.ContainerAssistant>
              <Text size="mega" color="n900" noMargin>
                Pergunte pra Lia. Nossa assistente virtual:
              </Text>

              <S.ButtonChatBot onClick={openChatbot} />
            </S.ContainerAssistant>

            <S.Separator />

            <div>
              <Text size="mega" color="n900" noMargin>
                Ou ligue na central de atendimento
              </Text>
              <Text size="kilo" color="n600">
                De segunda a sábado das 08h às 19h, exceto feriados
              </Text>

              <S.ContactWrapper>
                <S.Contact>
                  <Text size="kilo" noMargin>
                    Capitais
                  </Text>
                  <Text size="mega" as="a" href="tel:4020-5376" isBold noMargin>
                    4020-5376
                  </Text>
                </S.Contact>
                <S.Contact>
                  <Text size="kilo" noMargin>
                    Outras regiões
                  </Text>
                  <Text
                    size="mega"
                    as="a"
                    href="tel:0800-020-5376"
                    isBold
                    noMargin
                  >
                    0800-020-5376
                  </Text>
                </S.Contact>
              </S.ContactWrapper>
            </div>
          </S.ContainerFlex>
        </S.ContactsContainer>

        <div>
          <Text size="kilo" noMargin color="n500">
            Para mais detalhes sobre o programa, consulte nosso.{' '}
            <a
              href="https://www.leroymerlin.com.br/institucional/regulamento-programa-leroy-merlin-com-voce"
              target="_blank"
            >
              Regulamento
            </a>
          </Text>
        </div>
      </Card>
    </S.CardWrapper>
  )
}

export default Faq
