import { useCallback, useEffect } from 'react'
import {
  enableBodyScroll,
  disableBodyScroll,
  clearAllBodyScrollLocks
} from 'body-scroll-lock'

export const useBodyScrollLock = (scrollElement) => {
  const disableScroll = useCallback((element) => {
    disableBodyScroll(element, {
      allowTouchMove: () => true
    })
  }, [])

  const enableScroll = useCallback((element) => {
    enableBodyScroll(element)
  }, [])

  const clearAll = useCallback(() => {
    clearAllBodyScrollLocks()
  }, [])

  useEffect(() => {
    const currentElement = scrollElement?.current
    currentElement && disableScroll(currentElement)

    return () => {
      currentElement && enableScroll(currentElement)
    }
  }, [disableScroll, enableScroll, scrollElement])

  return [disableScroll, enableScroll, clearAll]
}
