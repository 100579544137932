const EloIcon = () => (
  <svg
    width="30"
    height="21"
    viewBox="0 0 30 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="30" height="21" rx="3.5" fill="white" />
    <path
      d="M8.11728 8.70091C8.34782 8.62364 8.58926 8.58443 8.83225 8.58479C9.92311 8.58479 10.8338 9.36429 11.0419 10.4004L12.5878 10.0832C12.2331 8.32234 10.6867 6.99719 8.83225 6.99719C8.41927 6.99683 8.00895 7.06369 7.61719 7.19519L8.11728 8.70091Z"
      fill="#FFCB05"
    />
    <path
      d="M6.29342 13.743L7.33885 12.5533C7.09969 12.3405 6.90817 12.079 6.77698 11.7861C6.64579 11.4932 6.57792 11.1757 6.57787 10.8544C6.57781 10.5331 6.64557 10.2155 6.77665 9.92261C6.90774 9.62968 7.09916 9.36812 7.33825 9.15523L6.29252 7.96564C5.5001 8.67222 5 9.7045 5 10.8536C4.9994 11.4 5.11443 11.9403 5.33745 12.4385C5.56046 12.9367 5.88635 13.3814 6.29342 13.743"
      fill="#00A4E0"
    />
    <path
      d="M11.0425 11.3089C10.833 12.3445 9.92351 13.1225 8.83325 13.1225C8.58998 13.123 8.34827 13.0836 8.11758 13.0059L7.61719 14.5122C8.00931 14.6436 8.41996 14.7103 8.83325 14.7098C10.6859 14.7098 12.232 13.3868 12.5882 11.6281L11.0425 11.3089Z"
      fill="#EF4123"
    />
    <path
      d="M13.94 12.6035C13.8798 12.503 13.826 12.3987 13.779 12.2913C13.5342 11.7193 13.5225 11.1274 13.729 10.559C13.9563 9.93594 14.3905 9.45888 14.9515 9.21596C15.6569 8.91051 16.4369 8.97073 17.1133 9.37438C17.5427 9.62233 17.847 10.0051 18.0783 10.5464C18.1077 10.6156 18.1337 10.6898 18.1584 10.7526L13.94 12.6035ZM15.3489 10.1347C14.8479 10.3514 14.5897 10.8242 14.6428 11.3786L16.7645 10.4595C16.3998 10.0281 15.9249 9.88508 15.3489 10.1347ZM17.0293 12.1343L17.0281 12.1355L16.984 12.1052C16.8574 12.3121 16.66 12.4796 16.4106 12.5887C15.9364 12.7963 15.4968 12.7428 15.1811 12.464L15.1521 12.5084C15.1521 12.5079 15.1516 12.5075 15.1512 12.5075L14.6129 13.3179C14.7468 13.4115 14.8902 13.4906 15.0407 13.5538C15.6353 13.8023 16.2438 13.7908 16.8431 13.5285C17.2767 13.3396 17.6167 13.0511 17.8493 12.6857L17.0293 12.1343Z"
      fill="#231F20"
    />
    <path
      d="M19.6337 8.02264V12.5286L20.3298 12.8125L19.9343 13.7413L19.1659 13.419C18.9935 13.3439 18.8757 13.2289 18.7872 13.0991C18.7022 12.9666 18.6387 12.7851 18.6387 12.5406V8.02264H19.6337Z"
      fill="#231F20"
    />
    <path
      d="M21.4382 11.3809C21.4382 11.1986 21.477 11.0185 21.5521 10.8527C21.6272 10.6869 21.7367 10.5392 21.8733 10.4196L21.1592 9.61823C20.911 9.83896 20.7123 10.1102 20.5761 10.414C20.4399 10.7178 20.3693 11.0473 20.3691 11.3806C20.3689 11.7139 20.4391 12.0434 20.5749 12.3474C20.7107 12.6514 20.9091 12.9228 21.157 13.1439L21.8702 12.3417C21.7342 12.2219 21.6253 12.0741 21.5508 11.9084C21.4763 11.7426 21.4379 11.5628 21.4382 11.3809V11.3809Z"
      fill="#231F20"
    />
    <path
      d="M22.7063 12.6597C22.5695 12.6593 22.4337 12.637 22.304 12.5935L21.9629 13.614C22.2022 13.6947 22.4528 13.7359 22.7052 13.7361C23.8361 13.7372 24.7803 12.9297 24.999 11.8564L23.9507 11.641C23.8913 11.9289 23.7352 12.1875 23.5085 12.373C23.2818 12.5586 22.9985 12.6598 22.7063 12.6597Z"
      fill="#231F20"
    />
    <path
      d="M22.7084 9.02796C22.4564 9.02746 22.2059 9.06804 21.9668 9.14811L22.3048 10.1694C22.4753 10.1123 22.656 10.0923 22.8348 10.1106C23.0136 10.129 23.1865 10.1853 23.342 10.2759C23.4976 10.3665 23.6323 10.4893 23.7372 10.6362C23.8421 10.783 23.9149 10.9506 23.9507 11.1279L24.9991 10.914C24.7834 9.83908 23.8401 9.02866 22.7084 9.02796Z"
      fill="#231F20"
    />
  </svg>
)

export default EloIcon
