import styled from '@emotion/styled'
import { css } from '@emotion/react'

const wrapperFieldStyles = () => css`
  label: wrapper_field;

  position: relative;
`

const wrapperLoadingStyles = ({ theme }) => css`
  label: wrapper_loading;

  right: ${theme.spacings.kilo};
  top: 35px;
  position: absolute;
`

export const WrapperField = styled('div')(wrapperFieldStyles)
export const WrapperLoading = styled('div')(wrapperLoadingStyles)
