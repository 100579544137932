import PropTypes from 'prop-types'
import { Container, Card } from '@leroy-merlin-br/backyard-react'

import { RetailMediaBanner as RetailMediaBannerModular } from 'site/Modular/modules/RetailMediaBanner'

const RetailMediaBanner = props => {
  const { term = { value: '' }, isSearch, categoryName = '' } = props

  const Wrapper = ({ children }) => {
    return (
      <Container>
        <Card>{children}</Card>
      </Container>
    )
  }

  return (
    <RetailMediaBannerModular
      extra={{
        term: isSearch ? term.value : '',
        categoryName,
        noContentPadding: true,
        inline: true,
        hideTitleOnMobile: true
      }}
      wrapper={Wrapper}
      context={isSearch ? 'search' : 'category'}
    />
  )
}

RetailMediaBanner.propTypes = {
  isSearch: PropTypes.bool,
  categoryName: PropTypes.string,
  term: PropTypes.shape({
    value: PropTypes.string
  })
}

export default RetailMediaBanner
