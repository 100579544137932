import * as S from './styled'

const TrailingLoading = () => (
  <S.TrailingLoading>
    <p>.</p>
    <p>.</p>
    <p>.</p>
  </S.TrailingLoading>
)

export default TrailingLoading
