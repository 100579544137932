import styled from '@emotion/styled'
import { css } from '@emotion/react'

const directionRow = css`
  grid-template-columns: auto 1fr;
  grid-template-rows: auto;
`
const directionColumn = css`
  grid-template-columns: auto;
  grid-template-rows: auto auto;
`

const timeWrapperStyles = ({ theme }) => css`
  display: flex;

  &:not(:last-of-type):after {
    content: ':';
    display: block;
    margin-left: ${theme.spacings.bit};
  }
`

const timeStyles = ({ theme }) => css`
  display: grid;
  ${directionColumn}
  grid-column-gap: ${theme.spacings.bit};
  align-items: baseline;

  text-align: center;

  span {
    font-size: ${theme.typography.subHeadings.kilo.fontSize};
    line-height: ${theme.typography.subHeadings.kilo.lineHeight};
  }

  @media (min-width: 375px) {
    ${directionRow}
  }

  ${theme.media.mega} {
    ${directionColumn}
  }
`

export const TimeWrapper = styled('div')(timeWrapperStyles)
export const TimeElement = styled('div')(timeStyles)
