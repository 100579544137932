import { Constants } from '.'

const initialState = {
  get: {
    isRequest: true,
    isUpdate: false,
    payload: {},
    error: false
  },
  percentage: {
    isRequest: true,
    payload: [],
    error: {}
  },
  percentagePaginated: {
    isRequest: false,
    payload: [],
    error: {}
  },
  money: {
    isRequest: true,
    payload: [],
    error: {}
  },
  moneyPaginated: {
    isRequest: false,
    payload: [],
    error: {}
  },
  activated: {
    isRequest: true,
    payload: [],
    error: {}
  },
  active: {
    id: '',
    isRequest: false,
    error: {}
  },
  shareable: {
    isRequest: false,
    error: {}
  }
}

export default function reducer (state = initialState, action = {}) {
  switch (action.type) {
    case Constants.COUPON_GET:
      return {
        ...state,
        get: {
          ...state.get,
          isRequest: true
        }
      }

    case Constants.COUPON_GET_COMPLETE:
      return {
        ...state,
        get: {
          ...state.get,
          isRequest: false,
          error: action.error,
          payload: action.payload
        }
      }

    case Constants.COUPON_GET_MONEY:
      return {
        ...state,
        money: {
          ...state.money,
          isRequest: true
        }
      }

    case Constants.COUPON_GET_MONEY_SUCCESS:
      return {
        ...state,
        money: {
          ...state.money,
          isRequest: false,
          payload: action.payload.map(item => ({ ...item, isLoading: false }))
        }
      }

    case Constants.COUPON_GET_MONEY_FAILURE:
      return {
        ...state,
        money: {
          ...state.money,
          isRequest: false,
          error: action.error
        }
      }

    case Constants.COUPON_GET_MONEY_PAGINATED:
      return {
        ...state,
        moneyPaginated: {
          ...state.moneyPaginated,
          isRequest: true
        }
      }

    case Constants.COUPON_GET_MONEY_PAGINATED_SUCCESS:
      return {
        ...state,
        moneyPaginated: {
          ...state.moneyPaginated,
          isRequest: false,
          payload: action.payload
        }
      }

    case Constants.COUPON_GET_MONEY_PAGINATED_FAILURE:
      return {
        ...state,
        moneyPaginated: {
          ...state.moneyPaginated,
          isRequest: false,
          error: action.error
        }
      }

    case Constants.COUPON_GET_PERCENTAGE:
      return {
        ...state,
        percentage: {
          ...state.percentage,
          isRequest: true
        }
      }

    case Constants.COUPON_GET_PERCENTAGE_SUCCESS:
      return {
        ...state,
        percentage: {
          ...state.percentage,
          isRequest: false,
          payload: action.payload.map(item => ({ ...item, isLoading: false }))
        }
      }

    case Constants.COUPON_GET_PERCENTAGE_FAILURE:
      return {
        ...state,
        percentage: {
          ...state.percentage,
          isRequest: false,
          error: action.error
        }
      }

    case Constants.COUPON_GET_PERCENTAGE_PAGINATED:
      return {
        ...state,
        percentagePaginated: {
          ...state.percentagePaginated,
          isRequest: true
        }
      }

    case Constants.COUPON_GET_PERCENTAGE_PAGINATED_SUCCESS:
      return {
        ...state,
        percentagePaginated: {
          ...state.percentagePaginated,
          isRequest: false,
          payload: action.payload
        }
      }

    case Constants.COUPON_GET_PERCENTAGE_PAGINATED_FAILURE:
      return {
        ...state,
        percentagePaginated: {
          ...state.percentagePaginated,
          isRequest: false,
          error: action.error
        }
      }

    case Constants.COUPON_GET_ACTIVATED:
      return {
        ...state,
        activated: {
          ...state.activated,
          isRequest: true
        }
      }

    case Constants.COUPON_GET_ACTIVATED_SUCCESS:
      return {
        ...state,
        activated: {
          ...state.activated,
          isRequest: false,
          payload: action.payload.map(item => ({ ...item, isLoading: false }))
        }
      }

    case Constants.COUPON_GET_ACTIVATED_FAILURE:
      return {
        ...state,
        activated: {
          ...state.activated,
          isRequest: false,
          error: action.error
        }
      }

    case Constants.COUPON_ACTIVE:
      return {
        ...state,
        active: {
          ...state.active,
          id: action.id,
          isRequest: true
        },
        [action.category]: {
          ...state[action.category],
          payload: state[action.category].payload.map(item => {
            if (item.id === action.id) {
              return {
                ...item,
                isLoading: true
              }
            }

            return item
          })
        }
      }

    case Constants.COUPON_ACTIVE_SUCCESS:
      return {
        ...state,
        active: {
          ...state.active,
          isRequest: false
        },
        get: {
          ...state.get,
          payload: {
            ...state.get.payload,
            isActive: true
          }
        },
        [action.category]: {
          ...state[action.category],
          payload: state[action.category].payload.map(item => {
            if (item.id === action.id) {
              return {
                ...item,
                isActive: action.category !== 'money' && true,
                isLoading: false,
                activatedId: action.payload.activatedId
              }
            }

            return item
          })
        }
      }

    case Constants.COUPON_ACTIVE_FAILURE:
      return {
        ...state,
        active: {
          ...state.active,
          isRequest: false,
          error: action.error
        },
        [action.category]: {
          ...state[action.category],
          payload: state[action.category].payload.map(item => {
            if (item.id === action.id) {
              return {
                ...item,
                isLoading: false
              }
            }

            return item
          })
        }
      }

    case Constants.COUPON_SHARE:
      return {
        ...state,
        shareable: {
          ...state.shareable,
          isRequest: true
        }
      }

    case Constants.COUPON_SHARE_SUCCESS:
      return {
        ...state,
        shareable: {
          ...state.shareable,
          isRequest: false
        }
      }

    case Constants.COUPON_SHARE_FAILURE:
      return {
        ...state,
        shareable: {
          ...state.shareable,
          isRequest: false,
          error: action.error
        }
      }

    default:
      return state
  }
}
