import React, {
  Fragment,
  useCallback,
  useMemo,
  useRef,
  useState,
  useEffect
} from 'react'
import { SearchField } from '@leroy-merlin-br/backyard-react'

import { Item } from './components/item'
import { textIncludesSearch } from './utils'
import * as S from './styled'
import { useComboBoxFloating } from './use-combo-box-floating'

const ComboBox = ({
  inputValue: propInputValue,
  selectedValue: propSelectedValue,

  onInputChange,
  onSelectionChange,

  options,
  hasAutoFilter,

  ...restProps
}) => {
  const [internalInputValue, setInternalInputValue] = useState('')
  const [internalSelectedValue, setInternalSelectedValue] = useState(null)
  const [isOptionsVisible, setIsOptionVisible] = useState(false)
  const [activeIndex, setActiveIndex] = useState(null)

  const hasBeenTouched = useRef(false)

  const inputValue = useMemo(() => {
    return propInputValue || internalInputValue
  }, [internalInputValue, propInputValue])

  const selectedValue = useMemo(() => {
    return propSelectedValue || internalSelectedValue
  }, [internalSelectedValue, propSelectedValue])

  const availableOptions = useMemo(() => {
    if (!inputValue) {
      return []
    }

    if (hasAutoFilter) {
      return options.filter(({ label }) =>
        textIncludesSearch(label, inputValue)
      )
    }

    return options
  }, [hasAutoFilter, options, inputValue])

  const {
    referenceElementRef,
    getReferenceProps,
    floatingElementRef,
    getFloatingProps,
    getItemProps,
    optionsListRef
  } = useComboBoxFloating({
    activeIndex,
    setActiveIndex,
    isOptionsVisible,
    setIsOptionVisible
  })

  const updateInputValue = useCallback(
    value => {
      if (onInputChange) {
        onInputChange(value)
      }

      setInternalInputValue(value)
    },
    [onInputChange]
  )

  const updateSelectedValue = useCallback(
    value => {
      if (onSelectionChange) {
        onSelectionChange(value)
      }

      setInternalSelectedValue(value)
      setIsOptionVisible(false)
    },
    [onSelectionChange]
  )

  const handleInputChange = ({ target: { value } }) => {
    updateInputValue(value)

    if (value) {
      setIsOptionVisible(true)
      setActiveIndex(0)
    }
  }

  const handleInputClear = () => {
    updateInputValue('')
    updateSelectedValue(null)
  }

  const handleInputKeyDown = event => {
    hasBeenTouched.current = true

    if (event.key === 'Enter') {
      event.preventDefault()

      if (activeIndex != null && availableOptions[activeIndex]) {
        updateSelectedValue(availableOptions[activeIndex]?.value)
        setActiveIndex(null)
      }
    }

    if (event.key === 'Tab') {
      setIsOptionVisible(false)
    }
  }

  useEffect(() => {
    if (!inputValue && hasBeenTouched.current) {
      updateSelectedValue(null)
      return
    }

    if (isOptionsVisible) {
      return
    }

    if (selectedValue && options?.length) {
      const foundOption = options.find(option => option.value === selectedValue)
      updateInputValue(foundOption?.label || '')
    } else {
      updateInputValue('')
    }
  }, [
    inputValue,
    isOptionsVisible,
    options,
    selectedValue,
    updateInputValue,
    updateSelectedValue
  ])

  return (
    <Fragment>
      <SearchField
        {...getReferenceProps({
          value: inputValue,
          onChange: handleInputChange,
          onKeyDown: handleInputKeyDown,
          autocomplete: 'off',
          'aria-autocomplete': 'list',
          placeholder: 'Digite para buscar'
        })}
        onClear={handleInputClear}
        ref={referenceElementRef}
        {...restProps}
      />

      {isOptionsVisible && (
        <S.OptionsBox {...getFloatingProps()} ref={floatingElementRef}>
          <S.Title>
            {availableOptions.length > 0
              ? 'Selecione uma opção'
              : 'Nenhuma opção encontrada'}
          </S.Title>

          {availableOptions.map((option, index) => (
            <Item
              {...getItemProps({
                key: option.value,
                ref: node => (optionsListRef.current[index] = node),
                onClick: () => updateSelectedValue(option.value)
              })}
              value={option.value}
              isActive={activeIndex === index}
              data-cy="options-item"
            >
              {option.label}
            </Item>
          ))}
        </S.OptionsBox>
      )}
    </Fragment>
  )
}

export default ComboBox
