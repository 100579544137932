import styled from '@emotion/styled'
import { css } from '@emotion/react'

const wrapperStyles = ({ theme }) => css`
  label: wrapper;

  & > div {
    & > div:first-of-type {
      flex: unset;
      display: none;
    }

    & > div:last-of-type {
      flex: unset;
      width: 100%;
    }

    ${theme.media.giga} {
      & > div:first-of-type {
        display: block;
        width: 172px;
      }

      & > div:last-of-type {
        width: calc(100% - 196px);
      }
    }
  }

  ${theme.media.mega} {
    & > div {
      padding: 0 ${theme.spacings.kilo};
    }
  }
`

const containerStyles = ({ theme }) => css`
  label: container;

  position: relative;
  margin-bottom: ${theme.spacings.tera};

  ${theme.media.giga} {
    width: 100%;
  }
`

export const Wrapper = styled('div')(wrapperStyles)
export const Container = styled('div')(containerStyles)
