import { Hidden, Text, Visible } from '@leroy-merlin-br/backyard-react'
import { FC, useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { MobileLayout } from '../../components/MobileLayout'
import { Layout } from '../../components/Layout'
import { AddressForm } from '../../components/AddressForm'
import { Address, ProfileResponse } from '../../types'
import { getProfile } from '../../services'

const Info = 'Use o formulário abaixo para inserir as informações do seu endereço'

function useQuery () {
  const { search } = useLocation()

  return useMemo(() => new URLSearchParams(search), [search])
}

const AddressNew: FC = () => {
  const query = useQuery()

  const hash = query.get('hash')

  const [loading, setLoading] = useState(false)
  const [currentAddress, setCurrentAddress] = useState<Address>()

  useEffect(() => {
    (async () => {
      if (hash) {
        setLoading(true)

        const profile: ProfileResponse = await getProfile()

        if (profile.data.addresses.length > 0) {
          const [address] = profile.data.addresses.filter(address => address.hash === hash)

          address.ibgeCityCode = address.ibgeCode ?? ''

          delete address.ibgeCode

          setCurrentAddress(address)
        }

        setLoading(false)
      }
    })()
  }, [hash])

  return (
    <>
      <Visible when={['untilKilo', 'kilo']}>
        <MobileLayout description="Endereços">
          <Text>{Info}</Text>

          <AddressForm loading={loading} data={currentAddress} />
        </MobileLayout>
      </Visible>
      <Hidden when={['untilKilo', 'kilo']}>
        <Layout description="Endereços" backLink="/enderecos">
          <Text>{Info}</Text>

          <AddressForm loading={loading} data={currentAddress} />
        </Layout>
      </Hidden>
    </>
  )
}

export default AddressNew
