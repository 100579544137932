import { css } from '@emotion/react'
import styled from '@emotion/styled'

const cardStyles = ({ theme }) => css`
  label: wrapper-card;

  background: ${theme.colors.white};
  border-radius: ${theme.borderRadius.giga};

  box-shadow: 0 0 0 1px ${theme.colors.shadow}, 0 0 1px 0 ${theme.colors.shadow},
    0 2px 2px 0 ${theme.colors.shadow};

  & > h1 {
    padding: ${theme.spacings.giga} 0 0 ${theme.spacings.giga};
  }
`

export const Card = styled('div')(cardStyles)
