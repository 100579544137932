import { Button, ButtonGroup } from '@leroy-merlin-br/backyard-react'

const FilterButtons = ({
  currentType,
  filterByActivated,
  filterByAvailable
}) => {
  const isDefault = currentType === 'activated'

  return (
    <ButtonGroup label="filter" align="flex-start" inlineMobile>
      <Button
        size="kilo"
        variant={isDefault ? 'solid' : 'outline'}
        appearance={isDefault ? 'secondary' : 'info'}
        onClick={filterByActivated}
      >
        Ativados
      </Button>
      <Button
        size="kilo"
        variant={isDefault ? 'outline' : 'solid'}
        appearance={isDefault ? 'info' : 'secondary'}
        onClick={filterByAvailable}
      >
        Disponíveis
      </Button>
    </ButtonGroup>
  )
}

export default FilterButtons
