const AmexIcon = () => (
  <svg
    width="30"
    height="21"
    viewBox="0 0 30 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="30" height="21" rx="3.5" fill="white" />
    <path
      d="M18.8592 7.79832L19.2432 6.75432H21V4.85352H9V16.8535H21V14.9575H19.344L18.732 14.2375L18.096 14.9575H13.392V11.1463H11.8392L13.7808 6.75432H15.6672L16.1232 7.75032V6.75432H18.468L18.8592 7.79832V7.79832ZM17.5368 8.48952V8.06952L17.6976 8.48952L18.4776 10.5727H19.248L20.04 8.48952L20.1912 8.07432V10.5727H21V7.37352H19.644L19.0272 8.99832L18.864 9.43752L18.696 8.99832L18.0768 7.37352H16.7208V10.5727H17.5368V8.48952ZM15.78 10.5727H16.7208L15.312 7.37352H14.22L12.804 10.5727H13.7328L13.9728 9.95832H15.5256L15.7656 10.5727H15.78ZM14.5968 8.45352L14.76 8.05752L14.916 8.45352L15.24 9.26472H14.256L14.5968 8.45352ZM14.04 11.1487V14.3383H16.7208V13.6447H14.8632V13.0903H16.68V12.4015H14.8632V11.8423H16.7208V11.1487H14.04ZM19.692 14.3383H20.76L19.2624 12.7375L20.76 11.1487H19.7088L18.7488 12.1855L17.7888 11.1487H16.7208L18.2112 12.7495L16.7208 14.3383H17.7528L18.7248 13.2967L19.6848 14.3383H19.692ZM20.0952 12.7327L21 13.6543V11.8255L20.0952 12.7327V12.7327Z"
      fill="#006FCF"
    />
  </svg>
)

export default AmexIcon
