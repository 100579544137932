import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { Theme } from 'scripts/react-components/themes/Theme'

export const Text = styled('div')(
  ({ theme }: Theme) => css`
    display: flex;
    justify-content: center;

    p {
      ${theme.typography.text.mega};
      color: ${theme.colors.n500};
      text-align: center;

      ${theme.media.mega} {
        max-width: 350px;
      }
    }
  `
)
