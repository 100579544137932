import { useEffect, useState } from 'react'
import { useController } from 'react-hook-form'
import { TextField } from '@leroy-merlin-br/backyard-react'

import {
  removeCurrencyFormat,
  formatPriceToBRL
} from 'utils/currency-format'

const CurrencyField = props => {
  const {
    field: { name, value, onChange, ref }
  } = useController(props)

  const [formatedValue, setFormatedValue] = useState('0')

  useEffect(() => {
    setFormatedValue(formatPriceToBRL(value || 0.0))
  }, [value])

  return (
    <TextField
      type="text"
      ref={ref}
      name={name}
      value={formatedValue}
      onChange={({ target: { value } }) =>
        onChange(removeCurrencyFormat(value))
      }
      {...props}
    />
  )
}

export default CurrencyField
