import styled from '@emotion/styled'
import { css } from '@emotion/react'

const bodyStyles = () => css`
  label: join-professional__body;

  height: 100%;
  text-align: center;
`

const buttonWrapperStyles = ({ theme }) => css`
  label: join-professional__button-wrapper;

  margin-top: ${theme.spacings.tera};

  ${theme.media.kilo} {
    margin: ${theme.spacings.tera} 0;
  }
`

const imageStyles = ({ theme }) => css`
  label: join-professional__image;

  margin: ${theme.spacings.giga} 0;

  ${theme.media.kilo} {
    display: inline-block;

    margin: ${theme.spacings.giga} 0;
  }
`

export const Body = styled('div')(bodyStyles)
export const ButtonWrapper = styled('div')(buttonWrapperStyles)
export const Image = styled('img')(imageStyles)
