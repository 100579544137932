import { PaymentMethod, User } from '../../type'
import { BankSlip } from './BankSlip'
import { CreditCard } from './CreditCard'
import { Pix } from './Pix'
import { PixError } from './PixError'
import { BankSlipError } from './BankSlipError'

const ContentHeader = ({
  paymentMethod,
  orderId,
  user
}: {
  paymentMethod: PaymentMethod
  orderId: string
  user: User
}) => {
  switch (paymentMethod.type) {
    case 'bank_slip':
      return paymentMethod.number ? <BankSlip orderId={orderId} /> : <BankSlipError orderId={orderId} user={user}/>
    case 'credit_card':
    case 'token':
      return <CreditCard orderId={orderId}/>
    case 'pix':
      return paymentMethod.qr_code ? <Pix orderId={orderId}/> : <PixError orderId={orderId} user={user} />
  }
}

export default ContentHeader
