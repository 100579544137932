import { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { Visible, Icon, Text, Inline } from '@leroy-merlin-br/backyard-react'
import { CrossOutline, ShareOutline } from '@backyard-ui/icons'

import { useBodyScrollLock } from 'utils/hooks/use-body-scroll-lock'

import { useStep } from 'lmcv/hooks/useStep'
import { Actions as ActionsCoupon } from 'lmcv/redux/modules/coupon'

import {
  Header,
  Info,
  TabsInfo,
  ModalError,
  ModalShareError,
  ModalSkeleton
} from '../components'
import { Customers, Conclude, Footer } from './components'
import { useCouponModalShare } from './useCouponModalShare'
import * as S from './styled'
import { CouponModalProps } from '../types'
import { Customer } from './types'

const CouponModalShare = ({
  id,
  category,
  active,
  onClose
}: CouponModalProps) => {
  const [customerSelected, setCustomerSelected] = useState<
    Customer | undefined
  >()

  const {
    couponData,
    isRequestLoading,
    hasRequestError,
    infoText,
    isSharingLoading,
    isButtonDisabled,
    customersList,
    hasCustomersError,
    loyaltyBalance
  } = useCouponModalShare(id, category, active)

  const { prevStep, nextStep, step } = useStep(1)
  const dispatch = useDispatch()

  const scrollElement = useRef()

  useBodyScrollLock(scrollElement)

  const onCouponShare = () => {
    customerSelected &&
      dispatch(ActionsCoupon.share(id, customerSelected.customer.id, onClose))
  }

  useEffect(() => {
    if (step !== 3) {
      setCustomerSelected(undefined)
    }
  }, [step])

  const onClickModalError = () => {
    onClose()
    window.location.href = 'fidelidade/clientes'
  }

  if (isRequestLoading) {
    return <ModalSkeleton />
  }

  if (hasRequestError) {
    return <ModalError onClose={onClose} />
  }

  if (hasCustomersError) {
    return <ModalShareError onClose={onClose} onClick={onClickModalError} />
  }

  const HeaderElement = () => (
    <Header couponData={couponData} subtitle={infoText.subtitle} />
  )

  const FooterElement = () => (
    <Footer
      couponData={couponData}
      infoText={infoText}
      isSharingLoading={isSharingLoading}
      isButtonDisabled={isButtonDisabled}
      currentStep={step}
      nextStep={nextStep}
      prevStep={prevStep}
      customerSelected={customerSelected}
      onCouponShare={onCouponShare}
    />
  )

  const ContentElement = ({ isMobile }: { isMobile?: boolean }) => {
    if (step === 1) {
      return isMobile ? (
        <TabsInfo couponData={couponData} />
      ) : (
        <Info couponData={couponData} />
      )
    }

    if (step === 2) {
      return (
        <Customers
          customers={customersList}
          onSelectCustomer={setCustomerSelected}
          customerSelected={customerSelected}
        />
      )
    }

    if (step === 3) {
      return (
        <Conclude
          customerSelected={customerSelected}
          price={couponData.price}
          loyaltyBalance={loyaltyBalance}
        />
      )
    }

    return null
  }

  return (
    <S.Wrapper isActive={couponData.isActive}>
      <S.ShareIcon>
        <Icon as={ShareOutline} />
      </S.ShareIcon>

      <S.CloseButton onClick={onClose}>
        <Inline space="kilo" alignY="baseline">
          <Icon as={CrossOutline} size="kilo" />
          <Text size="mega">Fechar</Text>
        </Inline>
      </S.CloseButton>

      {/* Mobile Version */}
      <Visible when={['untilKilo', 'kilo']}>
        <HeaderElement />
        <S.Content ref={scrollElement}>
          <ContentElement isMobile />
        </S.Content>
        <FooterElement />
      </Visible>

      {/* Desktop Version */}
      <Visible when={['mega', 'giga', 'tera']}>
        <S.Content ref={scrollElement}>
          <ContentElement />
        </S.Content>
        <S.Aside>
          <HeaderElement />
          <FooterElement />
        </S.Aside>
      </Visible>
    </S.Wrapper>
  )
}

export default CouponModalShare
