import { Fragment } from 'react'

const Loader = () => (
  <Fragment>
    {
      [1, 2, 3].map(key => (
        <div key={key} className="react-collapse order-react-collapse" style={{ height: '56px', borderColor: '#e5e5e5' }}>
          <div className="collapse-title">
            <div className="order-title">
              <div className="order-id color-primary">
                <div className="loader-item" style={{ width: '190px' }} />
              </div>
              <div className="loader-item" style={{ width: '85px' }} />
              <div className="color-default">
                <div className="loader-item" style={{ width: '90px' }} />
              </div>
              <div className="color-default">
                <div className="loader-item" style={{ width: '90px' }} />
              </div>
            </div>
          </div>
        </div>
      ))
    }
  </Fragment>
)

export default Loader
