import { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Text } from '@leroy-merlin-br/backyard-react'

import { Avatar } from 'lmcv/components'

import * as S from './styled'

function Header ({
  professionalName,
  customerPhoto,
  projectStatus,
  projectName
}) {
  return (
    <Fragment>
      <S.Photo>
        <Avatar name={professionalName} image={customerPhoto} big />
      </S.Photo>

      <Text size="giga" noMargin>
        {professionalName}
      </Text>
      <S.ProjectName size="kilo">{projectName}</S.ProjectName>
      <S.ProjectStatus size="kilo" status={projectStatus.toLowerCase()}>
        {projectStatus}
      </S.ProjectStatus>
    </Fragment>
  )
}

Header.propTypes = {
  professionalName: PropTypes.string,
  customerPhoto: PropTypes.string,
  projectName: PropTypes.string.isRequired,
  projectStatus: PropTypes.string.isRequired
}

Header.defaultProps = {
  professionalName: 'Nome do usuário'
}

export default Header
