import { useMediaQuery } from '@backyard-ui/hooks'
import { Button, Dialog, Modal, toast } from '@leroy-merlin-br/backyard-react'
import { FC, useState } from 'react'
import { Link } from 'react-router-dom'

import * as S from './styled'
import { Address } from '../../types'
import { maskCEP } from '../../utils'
import { removeAddress } from '../../services/addresses'

export type AddressProps = {
  data: Address
}

const AddressCard: FC<AddressProps> = ({ data }) => {
  const [showDeleteModal, setDeleteModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [deleteSuccess, setDeleteSuccess] = useState(false)

  const isDesktop = useMediaQuery('md')

  const handleDeleteConfirm = async () => {
    try {
      setLoading(true)

      await removeAddress(data.hash)

      toast.primary('Endereço removido com sucesso!', {
        variant: isDesktop ? 'solid' : 'light'
      })

      setDeleteSuccess(true)
    } catch (error) {
      toast.critical('Não foi possível remover o endereço!', {
        variant: isDesktop ? 'solid' : 'light'
      })
    } finally {
      setLoading(false)

      setDeleteModal(false)
    }
  }

  const handleHeader = () => {
    if (data.main) {
      if (data.nickname) {
        return (
          <S.Title>
            <span>{data.nickname}</span> - endereço principal
          </S.Title>
        )
      }

      return (
        <S.Title>
          <strong>Endereço principal</strong>
        </S.Title>
      )
    }

    if (data.nickname) {
      return (
        <S.Title>
          <span>{data.nickname}</span>
        </S.Title>
      )
    }
  }

  if (deleteSuccess) {
    return null
  }

  return (
    <S.Wrapper>
      <div>
        {handleHeader()}

        <S.Description>
          {data.street}, {data.number}{data.complement ? ` ${data.complement}` : ''}, {data.district}<br />
          {data.city} - {data.state}<br />
          CEP {maskCEP(data.postalCode.number)}
        </S.Description>
      </div>

      <S.WrapperButtons style={{ flexDirection: data.main ? 'column' : 'row' }}>
        {!data.main && (
          <Button
            size="kilo"
            variant="link"
            appearance="critical"
            onClick={() => setDeleteModal(true)}
          >
            excluir
          </Button>
        )}
      </S.WrapperButtons>

      <Modal
        isOpen={showDeleteModal}
        isCentered
        onClose={() => !loading && setDeleteModal(false)}
      >
        <Dialog
          onClose={() => !loading && setDeleteModal(false)}
          title="Tem certeza que deseja excluir o endereço?"
        >
          <S.WrapperButtonsInModal>
            <Button
              isDisabled={loading}
              onClick={() => !loading && setDeleteModal(false)}
              appearance="critical"
              variant="ghost"
            >
              Cancelar
            </Button>

            <Button isLoading={loading} onClick={handleDeleteConfirm}>
              Sim, excluir
            </Button>
          </S.WrapperButtonsInModal>
        </Dialog>
      </Modal>
    </S.Wrapper>
  )
}

export default AddressCard
