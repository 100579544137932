import instance from './api'
import { API_ENDPOINTS } from './constants'

export const getAddressByCep = async cep => {
  const response = await instance
    .get(`${API_ENDPOINTS.address}/${cep}`)
    .then(({ data }) => data)
    .catch(err => {
      throw err.response
    })

  return response
}
