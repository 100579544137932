import $ from 'jquery'

import emitter from 'scripts/utils/emitter'
import BaseComponent from 'scripts/components/base-component'

class BaseDropdown extends BaseComponent {
  static componentName = 'base-dropdown'

  static emitterEvents = {
    'dropdown:close': 'conditionallyCloseDropdown'
  }

  static DOMEvents = {
    'click [data-dropdown="link"]': 'onElementClick'
  }

  init () {
    this.setupElements()
    this.bindBodyListener()
  }

  setupElements () {
    this.$link = this.$element.find('[data-dropdown="link"]')
    this.$name = this.$element.find('[data-dropdown="name"]')
    this.$dropdown = this.$element.find('[data-action="dropdown"]')
    this.$body = $('body')
  }

  bindBodyListener () {
    this.$body.on('click', this.onBodyClick.bind(this))
  }

  onElementClick (e) {
    e.preventDefault()

    this.toggleDropdownClass()
  }

  isNotDropdownTarget (e) {
    return !this.$element.is(e.target) && !this.$element.find(e.target).length
  }

  conditionallyCloseDropdown (e) {
    if (this.isNotDropdownTarget(e)) {
      this.$dropdown.removeClass('dropdown-active')
    }
  }

  onBodyClick (e) {
    if (this.isNotDropdownTarget(e)) {
      emitter.emit('dropdown:close', e)
    }
  }

  toggleDropdownClass () {
    this.$dropdown.toggleClass('dropdown-active')
  }
}

export default ($el) => new BaseDropdown($el).init()

export { BaseDropdown as Component }
