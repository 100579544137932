import PropTypes from 'prop-types'

import * as S from './styled'

const ButtonSocial = ({ name, url }) => (
  <a href={url} title={name} target="_blank" rel="noopener noreferrer">
    <S.ButtonSocialIcon className={`glyph glyph-${name} share-button share-button-${name}`} />
  </a>
)

ButtonSocial.propTypes = {
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired
}

export default ButtonSocial
