import request from 'catalog/utils/axios'

export const getShipments = async (productId, zipCode, region) => {
  const regionQuery = `regionId=${region}`
  const zipCodeQuery = `zipCode=${zipCode}`

  const response = await request
    .get(
      `/api/boitata/v1/products/${productId}/shipments?${regionQuery}&${zipCodeQuery}`
    )
    .then(({ data }) => data)
    .catch(err => {
      throw err.response
    })

  return response
}
