import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { Theme } from 'scripts/react-components/themes/Theme'

type PropsStyles = {
  isStrech: boolean
}

const wrapperStyles = css`
  label: current-carousel__wrapper;

  height: 100%;
`

const imageWrapperStyles = ({ theme, isStrech }: Theme & PropsStyles) => css`
  label: current-carousel__image-wrapper;

  width: 100%;
  max-width: 375px;
  min-height: auto;

  margin: 0 auto;

  outline: none;

  ${theme.media.mega} {
    max-width: ${isStrech ? '100%' : '440px'};
  }

  & img {
    cursor: pointer;

    border: none !important;
    border-radius: ${theme.borderRadius.giga};

    width: 100%;
    min-height: auto;
  }
`

export const Wrapper = styled('div')(wrapperStyles)
export const ImageWrapper = styled('div')(imageWrapperStyles)
