const socialMediaList = [
  {
    name: 'twitter',
    url: 'https://twitter.com/home?status='
  },
  {
    name: 'facebook',
    url: 'https://www.facebook.com/sharer/sharer.php?u='
  }
]

export default socialMediaList
