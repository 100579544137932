import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { styleHelpers } from '@leroy-merlin-br/backyard-react'

const pointsWrapperStyles = ({ theme }) => css`
  label: points-wrapper;

  display: flex;
  justify-content: center;

  width: 100%;

  ${theme.media.giga} {
    padding: 0 ${theme.spacings.exa};
  }
`

const pointsStyles = ({ theme }) => css`
  label: points;

  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: repeat(2, auto);

  width: 100%;
  padding: ${theme.spacings.mega} 0;
  margin-bottom: ${theme.spacings.giga};
  min-height: 95px;

  border-radius: ${theme.spacings.mega};
  ${styleHelpers.shadowDouble(theme)};

  ${theme.media.giga} {
    min-height: 142px;
    grid-column-gap: ${theme.spacings.tera};
    padding: ${theme.spacings.kilo} 0px;
  }
`

const ticketStyles = ({ theme }) => css`
  label: ticket;

  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  text-align: center;
  white-space: pre-wrap;

  ${theme.media.giga} {
    width: 288px;
    height: 90px;

    border: 1px solid ${theme.colors.n100};
    border-radius: ${theme.spacings.giga};

    padding: ${theme.spacings.kilo} 0;

    &:before,
    &:after {
      content: '';

      position: absolute;
      top: 50%;

      width: 22px;
      height: 22px;

      background: ${theme.colors.white};

      transform: translateY(-50%);
    }

    &:before {
      left: -11px;

      border: 1px solid ${theme.colors.n100};
      border-radius: ${theme.borderRadius.circle};
    }

    &:after {
      left: -23px;
    }
  }
`

const ticketValueStyles = ({ theme }) => css`
  label: ticket__value;

  display: flex;
  align-items: center;

  & > span {
    width: 25px;
    height: 0px;

    border: 1px solid ${theme.colors.v500};
    transform: rotate(90deg);

    margin-bottom: 10px;
  }

  p {
    font-size: 11px;
    line-height: 13px;
  }
`

const expireInfoStyles = ({ theme }) => css`
  label: expire-info;

  svg {
    display: inline;
    color: ${theme.colors.n600};
    margin-right: ${theme.spacings.byte};
  }
`
export const PointsWrapper = styled('div')(pointsWrapperStyles)
export const Points = styled('div')(pointsStyles)
export const Ticket = styled('div')(ticketStyles)
export const TicketValue = styled('div')(ticketValueStyles)
export const ExpireInfo = styled('div')(expireInfoStyles)
