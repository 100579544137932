import reactComponentLoader from 'scripts/react-component-loader'

import MediasCarousel from './MediasCarousel'
import PaymentMethodsModal from './PaymentMethodsModal'
import PurchaseButtonsSection from './PurchaseButtonsSection'
import ReviewPopover from './ReviewPopover'
import Reviews from './Reviews'
import ServiceLabel from './ServiceLabel'
import Variants from './Variants'
import LoyaltyPoints from './LoyaltyPoints'

reactComponentLoader(PaymentMethodsModal, '[data-pdp-payment-methods-modal]')
reactComponentLoader(LoyaltyPoints, '[data-pdp-loyalty-points]')

reactComponentLoader(
  PurchaseButtonsSection,
  '[data-pdp-purchase-buttons-section]'
)

reactComponentLoader(Reviews, '[data-pdp-reviews]')
reactComponentLoader(ReviewPopover, '[data-pdp-review-popover]')
reactComponentLoader(ServiceLabel, '[data-service-label]')
reactComponentLoader(Variants, '[data-pdp-variants]')
reactComponentLoader(MediasCarousel, '[data-pdp-medias-carousel]')
