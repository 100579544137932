import { Button, Icon, Text } from '@leroy-merlin-br/backyard-react'
import { ShoppingCart } from '@leroy-merlin-br/backyard-icons'

import { default as tryAddToCart } from 'scripts/utils/cart/addToCart'

import * as S from './styled'

const EcommerceInfo = ({
  stockMessage,
  hasStock,
  lm,
  region,
  hideStock = () => {}
}) => {
  const addToCart = () => {
    tryAddToCart({
      regionId: region,
      type: 'delivery',
      products: [
        {
          id: lm,
          quantity: 1
        }
      ],
      override: false
    })

    hideStock()
  }

  return (
    <S.EcommerceInfo>
      <Icon as={ShoppingCart} />

      <div>
        <Text noMargin>Site</Text>
        <S.StockQuantity>
          <Text size="kilo" noMargin>{stockMessage || 'Produto indisponível'}</Text>
        </S.StockQuantity>

        <S.ButtonWrapper>
          <Button
            size="mega"
            onClick={addToCart}
            disabled={!hasStock}
            isStretch
          >
            Entregamos para você
          </Button>
        </S.ButtonWrapper>
      </div>
    </S.EcommerceInfo>
  )
}

export default EcommerceInfo
