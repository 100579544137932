import { PropsWithChildren } from 'react'

type BottomSheetProps = {
  isOpen: boolean
}

export const BottomSheet = ({
  children,
  isOpen
}: PropsWithChildren<BottomSheetProps>) => (
  <div
    className={`transition-all ease-in duration-300 px-8 pt-6 pb-[100px] rounded-t-2xl fixed bottom-0 bg-white shadow-[0_-15px_30px_0px_rgba(0,0,0,0.10)] w-full ${
      isOpen ? '-translate-y-0' : 'translate-y-full'
    }`}
  >
    {children}
  </div>
)
