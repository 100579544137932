import { css } from '@emotion/react'
import styled from '@emotion/styled'

const ecommerceInfoStyles = ({ theme }) => css`
  label: ecommerce-info;

  display: grid;
  grid-template-columns: auto 1fr;

  > svg {
    margin-top: ${theme.spacings.bit};
    margin-right: ${theme.spacings.byte};
    color: ${theme.colors.p600};
  }
`

const stockQuantityStyles = ({ theme }) => css`
  label: store-info__stock-quantity;

  color: ${theme.colors.n400};
  margin-bottom: ${theme.spacings.bit};
`

const buttonWrapperStyles = ({ theme }) => css`
  label: store-info__buttons;

  display: flex;
  flex-direction: column;

  margin-top: ${theme.spacings.byte};

  button:not(:first-of-type) {
    margin-left: 0;
    margin-top: ${theme.spacings.kilo};
  }

  ${theme.media.mega} {
    flex-direction: row;

    button:not(:first-of-type) {
      margin-left: ${theme.spacings.byte};
      margin-top: 0;
    }
  }
`

export const EcommerceInfo = styled('div')(ecommerceInfoStyles)
export const StockQuantity = styled('div')(stockQuantityStyles)
export const ButtonWrapper = styled('div')(buttonWrapperStyles)
