import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { Theme } from 'newCheckout/types/Theme'

const panelStyles = ({ theme }: Theme) => css`
  label: success-delivery__panel;
  
  display: flex;
  flex-direction: column;

  padding: ${theme.spacings.mega} ${theme.spacings.giga};

  background-color: ${theme.colors.n100};
  border-radius: ${theme.borderRadius.giga};
`
export const Panel = styled('div')(panelStyles)

const panelFirstLineStyles = ({ theme }: Theme) => css`
  label: success-delivery__panel__first-line;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${theme.spacings.byte};
`
export const PanelFirstLine = styled('div')(panelFirstLineStyles)

const panelSecondLineStyles = ({ theme }: Theme) => css`
  label: success-delivery__panel__second-line;

  margin-left: ${theme.spacings.giga} ;
`
export const PanelSecondLine = styled('div')(panelSecondLineStyles)
