import $ from 'jquery'

import BaseComponent from 'scripts/components/base-component'

const DEFAULTS = {
  zoomedClass: 'zoomed',
  which: 90
}

class ProductCodeZoom extends BaseComponent {
  static componentName = 'product-code-zoom'

  constructor (element, options = {}) {
    super(element)

    this.options = $.extend({}, DEFAULTS, options)
  }

  init () {
    this.bindListeners()
  }

  bindListeners () {
    $(document).on('keydown', this.onWindowKeyDown.bind(this))
    $(document).on('keyup', this.onWindowKeyUp.bind(this))
  }

  onWindowKeyDown (e) {
    if (e.which === this.options.which && !$(e.target).is('input')) {
      this.$element.addClass(this.options.zoomedClass)
    }
  }

  onWindowKeyUp (e) {
    if (e.which === this.options.which) {
      this.$element.removeClass(this.options.zoomedClass)
    }
  }
}

export default ($el) => new ProductCodeZoom($el).init()

export { ProductCodeZoom as Component }
