import PropTypes from 'prop-types'
import { Hidden, Heading } from '@leroy-merlin-br/backyard-react'

import * as S from './styled'

const Widget = ({
  title,
  noTopSpacing,
  noBottomSpacing,
  full,
  sideBackgroundColors = null,
  hiddenMobile,
  withBackground,
  noContentPadding,
  children
}) => (
  <Hidden when={hiddenMobile ? ['untilKilo', 'kilo'] : []}>
    <S.Wrapper
      data-testid="carousel-widget"
      hasTitle={Boolean(title)}
      noTopSpacing={noTopSpacing}
      noBottomSpacing={noBottomSpacing}
      full={full}
      sideBackgroundColors={sideBackgroundColors}
      withBackground={withBackground}
      noContentPadding={noContentPadding}
    >
      <div>
        {title && (
          <Heading as="h2" size="peta">
            {title}
          </Heading>
        )}
        {children}
      </div>
    </S.Wrapper>
  </Hidden>
)

Widget.propTypes = {
  title: PropTypes.string,
  noTopSpacing: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  noBottomSpacing: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  full: PropTypes.bool,
  sideBackgroundColors: PropTypes.shape({
    left: PropTypes.string.isRequired,
    right: PropTypes.string.isRequired
  }),
  hiddenMobile: PropTypes.bool,
  noContentPadding: PropTypes.bool,
  children: PropTypes.node.isRequired,
  withBackground: PropTypes.bool
}

export default Widget
