export const BASE_URL = (window as any).env && (window as any).env.baseHost
export const CART_ENDPOINT = `${BASE_URL}/api/v1/cart`
export const CART_V3_ENDPOINT = `${BASE_URL}/api/v3/carts`
export const CART_STORES_ENDPOINT = '/shipment-modalities'
export const CART_CHANGE_SHIPMENT_ENDPOINT = '/change-shipment'
export const CART_CHANGE_SHIPMENT_AVAILABILITY = '/shipment/availability'
export const CART_CHANGE_ITEM_SHIPMENT_ENDPOINT = '/change-item-shipment'

export const SHIPMENT_ENDPOINT = `${BASE_URL}/api/v1/shipments`
export const SERVICE_FETCH_ENDPOINT = `${BASE_URL}/api/v3/crosses/services`
export const SERVICE_ENDPOINT = `${BASE_URL}/api/v3/carts/services`
export const IS_DEV = process.env.NODE_ENV !== 'production'
export const MOCKREQUESTS = IS_DEV
