import {
  LocationRepository,
  NavigationRepository
} from '@leroy-merlin-br/backpack-header'

import { getSubcategoryChildren } from 'api/Header/categories'
import {
  getLocationByZipcode,
  getLocationsByCityName
} from 'api/Header/location'

export const locationRepository: LocationRepository = {
  getByCityName: async (cityName) => {
    const locations = await getLocationsByCityName(cityName)
    return locations
  },
  getByZipcode: async (zipcode) => {
    const location = await getLocationByZipcode(zipcode)
    return location
  }
}

export const navigationRepository: NavigationRepository = {
  getChildrenById: async (subcategoryId: string) => {
    const { data } = await getSubcategoryChildren(subcategoryId)
    return data.results
  }
}
