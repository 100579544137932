import { tv } from 'tailwind-variants'

const styles = tv({
  slots: {
    resume: 'mt-1',
    wrapper: 'w-full'
  }
})

export default styles
