import { Button, TextField, Stack } from '@leroy-merlin-br/backyard-react'

import WizardStep from '../components/WizardStep'
import isValid from '../utils/isValid'
import * as Ilustrations from '../components/Ilustrations'
import * as S from '../styled'

const ProjectName = ({ step, errors, register, nextStep, customerData }) => {
  const { name, projectName } = customerData

  const isDisabled = !isValid(name) || !isValid(projectName)

  return (
    <WizardStep step={1} currentStep={step}>
      <Ilustrations.People />

      <Stack space="mega">
        <TextField
          {...register('name', {
            required: 'Campo obrigatório.',
            maxLength: { value: 40, message: 'Limite máximo de 40 caracteres.' }
          })}
          type="text"
          label="Nome do Cliente"
          placeholder="Ex: João da Silva"
          state={Boolean(errors.name) && 'invalid'}
          hint={errors.name?.message}
        />

        <TextField
          {...register('projectName', {
            required: 'Campo obrigatório.',
            maxLength: { value: 40, message: 'Limite máximo de 40 caracteres.' }
          })}
          type="text"
          label="Nome do Projeto"
          placeholder="Ex: Cozinha integrada"
          state={Boolean(errors.projectName) && 'invalid'}
          hint={errors.projectName?.message}
        />

        <S.ButtonWrapper>
          <Button
            type="submit"
            onClick={nextStep}
            isDisabled={isDisabled}
            isStretch
          >
            Próxima etapa
          </Button>
        </S.ButtonWrapper>
      </Stack>
    </WizardStep>
  )
}

export default ProjectName
