import { pluralize } from 'lmcv/utils/pluralize'

export const couponMessage = {
  expire: (days: number): string => {
    if (isNaN(+days)) {
      return ''
    }

    return days === 0
      ? 'Expira hoje'
      : `Expira em ${days} ${pluralize('dia', days > 1)}`
  }
}
