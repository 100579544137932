export const Brithness = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.76102 2.60175H2.46531V3.30505C2.46531 3.65917 2.74172 3.93557 3.09485 3.93557C3.44994 3.93557 3.72635 3.65917 3.72635 3.30505V2.60175H4.42966C4.78279 2.60175 5.06017 2.32436 5.06017 1.97123C5.06017 1.6181 4.78279 1.34071 4.42966 1.34071H3.72635V0.631501C3.72635 0.277388 3.44994 0 3.09485 0C2.74172 0 2.46531 0.277388 2.46531 0.631501V1.33481H1.76102C1.40691 1.33481 1.12952 1.6122 1.12952 1.96631C1.12952 2.31649 1.41281 2.60175 1.76102 2.60175ZM4.6438 8.40516H3.26866V7.03003C3.26866 6.67493 2.99127 6.39852 2.63716 6.39852C2.28304 6.39852 2.00566 6.67493 2.00566 7.03003V8.40516H0.630518C0.277388 8.40516 0 8.68157 0 9.03568C0 9.3898 0.277388 9.6662 0.630518 9.6662H2.00566V11.0364C2.00566 11.3895 2.28304 11.666 2.63716 11.666C2.99127 11.666 3.26866 11.3895 3.26866 11.0364V9.6662H4.6438C4.99791 9.6662 5.2753 9.3898 5.2753 9.03568C5.2753 8.68747 4.99201 8.40516 4.6438 8.40516ZM8.47067 6.25816C8.54346 6.22865 9.05988 6.03979 9.46121 5.89323L8.44018 5.53813C8.36838 5.51452 8.14509 5.44075 8.05558 5.15647L7.70048 4.13545L7.32768 5.19385C7.27063 5.35714 7.14472 5.47321 6.95291 5.5401C6.89979 5.5578 6.33714 5.75256 5.90139 5.90503C6.23976 6.04471 6.77192 6.26603 7.03849 6.38308C7.27259 6.48538 7.35325 6.65752 7.39752 6.7972C7.41279 6.84301 7.51605 7.13703 7.62414 7.44481C7.64834 7.51373 7.67279 7.58333 7.69654 7.651C7.71908 7.588 7.74316 7.52057 7.76777 7.45169C7.8849 7.12374 8.01387 6.76263 8.04476 6.68703C8.12935 6.47063 8.25231 6.34669 8.47067 6.25816ZM9.14054 4.44923L11.58 5.2932C11.8318 5.38074 12 5.62076 12 5.88831C12 6.15291 11.8298 6.39193 11.5751 6.48243L9.13464 7.32542L8.29165 9.76584C8.20607 10.0206 7.96606 10.1918 7.69556 10.1918C7.42899 10.1918 7.18997 10.0206 7.10144 9.76683L6.25747 7.32542L3.81803 6.48341C3.56228 6.39193 3.39112 6.15291 3.39112 5.88831C3.39112 5.62174 3.56129 5.38271 3.81606 5.2932L6.25747 4.44923L7.10242 2.00684C7.1939 1.76289 7.42899 1.60649 7.70048 1.60649C7.97787 1.60944 8.21099 1.76683 8.29657 2.00782L9.14054 4.44923Z"
        fill="#C86F21"
      />
    </svg>
  )
}
