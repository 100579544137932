import { ModalProvider } from '@leroy-merlin-br/backyard-react'

import reactComponentLoader from 'scripts/react-component-loader'

import { Modal } from '.'

const ModalWithProvider = () => (
  <ModalProvider>
    <Modal />
  </ModalProvider>
)

reactComponentLoader(ModalWithProvider, '[data-fake-news-leroy]')
