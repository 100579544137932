import { PropsWithChildren, ReactNode } from 'react'

import * as S from './styled'

const Footer = ({ children }: PropsWithChildren<ReactNode>) => (
  <S.Footer data-testid="chat-footer" className="chat-footer">
    {children}
  </S.Footer>
)

export default Footer
