import { get } from 'lodash'

function getCategoryAttributes (store: object, attribute: string) {
  const attributes = get(store, attribute)

  const prefix = attributes?.prefix || ''
  const sufix = attributes?.sufix || ''

  return { attributes, prefix, sufix }
}

export { getCategoryAttributes }
