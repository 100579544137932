import { DetailedOrder } from 'frontendMyOrders/pages/DetailedOrder'
import { Home } from 'frontendMyOrders/pages/Home'

const getRoutes = () => {
  const routes = [
    {
      path: '/',
      key: 'HOME',
      exact: true,
      isPrivate: true,
      component: Home
    },
    {
      path: '/:id',
      key: 'DETAILED_ORDER',
      exact: true,
      isPrivate: true,
      component: DetailedOrder
    }
  ]

  return routes
}

export default getRoutes
