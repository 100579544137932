import { Fragment } from 'react'

import { Link } from '../pages/SignUp/styled'

export const hasAccountFiscalId = (errors, fiscalId) => {
  const errorsMessage = errors.fiscalId?.message
  const message = `Já existe uma conta com esse ${fiscalId}`

  return (
    errorsMessage && (
      <Fragment>
        {errorsMessage}
        {' '}
        {errorsMessage === message && <Link href="/login">Faça Login.</Link>}
      </Fragment>
    )
  )
}
