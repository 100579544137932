import { Fragment } from 'react'
import { Button } from '@leroy-merlin-br/backyard-react'
import { ChevronRight } from '@backyard-ui/icons'

import { normalizePath } from 'scripts/react-components/site/EntryKey/utils/buildUrl'

import { ContentTagSEO } from '../../../page'

type ContentListProps = {
  hasVideo: boolean
  contents: ContentTagSEO[]
}

const ContentList = ({ hasVideo, contents }: ContentListProps) => {
  const MAX_CONTENTS = hasVideo ? 6 : 9

  const slicedContents = contents.slice(0, MAX_CONTENTS)

  return (
    <Fragment>
      {slicedContents.map(content => (
        <Button
          as="a"
          key={content.id}
          variant="outline"
          iconRight={ChevronRight}
          href={normalizePath(content.url)}
        >
          {content.title}
        </Button>
      ))}
    </Fragment>
  )
}

export default ContentList
