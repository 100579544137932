export const changeUserRegion = (postalCode, region) => {
  setRegionOnApp(region)
  setRegionOnWeb(postalCode)
}

const setRegionOnApp = regionData => {
  const { region, city, stateCode } = regionData
  const regionName = `${city} - ${stateCode}`

  if (window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        type: 'updateRegion',
        data: {
          regionName,
          region
        }
      })
    )

    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        type: 'setRegion',
        data: {
          name: regionName,
          slug: region
        }
      })
    )
  }
}

const setRegionOnWeb = postalCode => {
  const currentUrl = location.href
  const baseURL = window.env.baseHost
  const query = `zipCode=${postalCode}`
  const previousUrl = `&previousUrl=${currentUrl}`

  const url = `${baseURL}/localizacao/store/?${query}${previousUrl}`

  location.replace(url)
}
