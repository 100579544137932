import { useEffect, useState } from 'react'

import * as dataLayer from 'scripts/utils/data-layer'

import { LocationContent } from '../'
import * as S from './styled'

const LocationPopper = ({ region, isVisible, onChange = () => {} }) => {
  const [isOpen, setIsOpen] = useState(false)

  const handleClose = event => {
    setIsOpen(false)

    event.stopPropagation()
  }

  const handleConfirm = event => {
    handleClose(event)

    dataLayer.confirmLocationAction({
      regionName: region
    })
  }

  const handleChange = event => {
    onChange(event)

    handleClose(event)

    dataLayer.changeLocationAction({
      regionName: region
    })
  }

  useEffect(() => {
    setIsOpen(isVisible)

    if (isVisible) {
      dataLayer.showLocationPopper({
        regionName: region
      })
    }
  }, [isVisible, region])

  if (!isOpen || !region) {
    return null
  }

  return (
    <S.PopperWrapper>
      <LocationContent
        regionText={region}
        onConfirm={handleConfirm}
        onChange={handleChange}
      />
    </S.PopperWrapper>
  )
}

export default LocationPopper
