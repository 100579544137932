import { Text } from '@leroy-merlin-br/backyard-react'

const FromPrice = ({ isOutlet, hidePrice, price, unit }) => {
  const hasPrice = Boolean(price?.integers)

  if (!hasPrice || hidePrice) {
    return null
  }

  return (
    <Text
      data-cy="product-from-price"
      color={isOutlet ? 'white' : 'black'}
      size="kilo"
      decoration="line-through"
      noMargin
    >
      R$ {price.integers},{price.decimals} /{unit}
    </Text>
  )
}

export default FromPrice
