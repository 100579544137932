import { toast } from '@leroy-merlin-br/backyard-react'
import { Toastappearance } from '@leroy-merlin-br/backyard-react/dist/esm/src/components/Toast/toast'

export type ShowToastParams = {
  type: Toastappearance
  title: string
  content?: string
}

export const showToast = (params: ShowToastParams) => {
  toast[params.type](
    params.title,
    {
      content: params.content,
      variant: 'solid'
    })
}
