import { Card, Heading } from '@leroy-merlin-br/backyard-react'

import * as S from './styled'

const WarningOffline = () => {
  const { defaultImagePath } = window.env

  return (
    <S.Wrapper>
      <Card>
        <S.ImageWrapper>
          <img src={`${defaultImagePath}/offline.svg`} alt="página fora do ar." />
        </S.ImageWrapper>

        <Heading color="n600">
          Infelizmente estamos fora do ar, volte mais tarde.
        </Heading>
      </Card>
    </S.Wrapper>
  )
}

export default WarningOffline
