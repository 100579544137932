import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { styleHelpers } from '@leroy-merlin-br/backyard-react'

const cardStyles = ({ theme }) =>
  css`
    background: unset;
    padding: 0px;
    box-shadow: unset;

    & > div {
      padding-left: 0px;
      padding-right: 0px;
    }

    overflow-x: unset;

    & > div:nth-of-type(2) {
      overflow: unset;
    }

    ${theme.media.mega} {
      background: ${theme.colors.white};
      ${styleHelpers.shadowSingle(theme)};
      border-radius: ${theme.borderRadius.mega};
      padding: ${theme.spacings.giga} ${theme.spacings.tera};

      overflow-x: hidden;

      & > div:nth-of-type(2) {
        overflow: unset;
      }
    }

    .swiper-container {
      overflow: unset;
    }
  `

export const Card = styled('div')(cardStyles)
