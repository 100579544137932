import { FC } from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

import OptionsToReceiveSecurityCodeStep from 'scripts/react-components/user/my-account/components/OptionsToReceiveSecurityCodeStep/OptionsToReceiveSecurityCodeStep'

import { Layout } from '../../Layout'
import { useAccessDataContext } from '../../../../context/my-account-access-data-context'

type SelectContactProps = {
  onNextStep: () => void
}

const SelectContact: FC<SelectContactProps> = ({ onNextStep }) => {
  const { captchaKey } = useAccessDataContext()
  return (
    <Layout>
      <GoogleReCaptchaProvider reCaptchaKey={captchaKey}>
        <OptionsToReceiveSecurityCodeStep onNextStep={onNextStep} />
      </GoogleReCaptchaProvider>
    </Layout>
  )
}

export default SelectContact
