import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { StyleIsActiveProps, StyleProps } from '../types'

const wrapperStyles = ({ theme }: StyleProps) => css`
  label: modal__wrapper;

  pointer-events: all;
  position: relative;

  min-height: 450px;
  max-height: 60vh !important;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;

  border-radius: ${theme.borderRadius.mega};

  ${theme.media.mega} {
    min-height: unset;

    grid-template-columns: 1fr 200px;
    grid-template-rows: 450px;
  }
`

const closeButtonStyles = ({ theme }: StyleProps) => css`
  label: modal__close-button;

  position: absolute;

  top: -30px;
  right: 0;

  color: ${theme.colors.white};
  cursor: pointer;

  svg {
    path {
      stroke-width: 2px;
      stroke: ${theme.colors.white};
    }
  }
`

const shareIconStyles = ({ theme, isActive }: StyleIsActiveProps) => css`
  label: modal__shared-icon;

  position: absolute;

  top: 10px;
  right: ${theme.spacings.kilo};

  svg {
    display: block;

    width: 14px;
    height: 14px;

    color: ${isActive ? theme.colors.f300 : theme.colors.v500};
  }
`

const asideStyles = ({ theme }: StyleProps) => css`
  label: modal__aside;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  background: ${theme.colors.white};
  border-top-right-radius: ${theme.borderRadius.mega};
  border-bottom-right-radius: ${theme.borderRadius.mega};
`

const contentStyles = ({ theme }: StyleProps) => css`
  label: modal__content;

  overflow-y: auto;

  padding: ${theme.spacings.giga};

  background: ${theme.colors.white};

  box-shadow: inset 0px 4px 4px ${theme.colors.shadow},
    inset 0px -4px 4px ${theme.colors.shadow};

  ${theme.media.mega} {
    background: ${theme.colors.n100};
    border-top-left-radius: ${theme.borderRadius.mega};
    border-bottom-left-radius: ${theme.borderRadius.mega};
    box-shadow: unset;
  }
`

export const CloseButton = styled('div')(closeButtonStyles)
export const ShareIcon = styled('span')(shareIconStyles)
export const Aside = styled('div')(asideStyles)
export const Content = styled('div')(contentStyles)

const wrapperActiveStyles = ({ theme, isActive }: StyleIsActiveProps) =>
  isActive &&
  css`
    label: modal__wrapper--active;

    ${Aside} {
      background: linear-gradient(
        134.58deg,
        ${theme.colors.f600} -5.39%,
        ${theme.colors.v500} 100%
      );
    }

    ${ShareIcon} {
      svg {
        color: ${theme.colors.f300};
      }
    }
  `

export const Wrapper = styled('div')(wrapperStyles, wrapperActiveStyles)
