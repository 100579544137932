import { Text } from '@leroy-merlin-br/backyard-react'

import { AlertMessage } from 'shared/components/AlertMessage'

import * as S from './styled'

const Disclaimer = ({ disclaimers, ...props }) => (
  <S.Wrapper>
    <AlertMessage {...props}>
      {disclaimers.filter(Boolean).map((disclaimer, index) => (
        <Text as="span" key={index} className="notification-item" size="kilo" noMargin>
          {disclaimer}
        </Text>
      ))}
    </AlertMessage>
  </S.Wrapper>
)

export default Disclaimer
