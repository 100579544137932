const DiscountTicket = () => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13 8.52555C13 7.60555 13.7467 6.85889 14.6667 6.85889V6.19222C14.6667 3.52555 14 2.85889 11.3333 2.85889H4.66667C2 2.85889 1.33333 3.52555 1.33333 6.19222V6.52555C2.25333 6.52555 3 7.27222 3 8.19222C3 9.11222 2.25333 9.85889 1.33333 9.85889V10.1922C1.33333 12.8589 2 13.5256 4.66667 13.5256H11.3333C14 13.5256 14.6667 12.8589 14.6667 10.1922C13.7467 10.1922 13 9.44555 13 8.52555Z"
      stroke="#6C0CAE"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      opacity="0.4"
      d="M6 10.0259L10 6.02588"
      stroke="#6C0CAE"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      opacity="0.4"
      d="M9.99634 10.0257H10.0023"
      stroke="#6C0CAE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      opacity="0.4"
      d="M5.99634 6.35921H6.00233"
      stroke="#6C0CAE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default DiscountTicket
