import styled from '@emotion/styled'
import { css } from '@emotion/react'

const wrapperStyles = ({ theme }) => css`
  label: header__wrapper;

  margin-top: ${theme.spacings.mega};
`

const contentStyles = ({ theme }) => css`
  label: header__content;

  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

  ${theme.media.mega} {
    flex-direction: row;
  }
`

export const Wrapper = styled('section')(wrapperStyles)
export const Content = styled('div')(contentStyles)
