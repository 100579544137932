import { MajorCorner, MajorMiddle, MinorCorner, MinorMiddle } from './styled'

export const MAX_MOSAIC_LENGTH = 8

/*
  A helper method created to combine the itens of an array in pairs
EX: input = [ a, 1, b, 2, c, 3, d, 4... ]
    output = [ [a, 1], [b, 2], [c, 3], [d, 4] ]
  the result will not have more than four pairs
*/
export const combine = (result, _, index, arr) => {
  if (index % 2 === 0 && index < MAX_MOSAIC_LENGTH) {
    result.push(arr.slice(index, index + 2))
  }
  return result
}

/*
  It'll add data information about the mosaic
this data will be used to render the component and set the proper format/layout
*/
export const addDataToMosaicImage = list => list.map((item, index) => {
  const [topIMG, bottomIMG] = item
  const middles = [MajorMiddle, MinorMiddle]
  const corners = [MajorCorner, MinorCorner]
  const args = {
    wrappers: [...corners],
    images: [topIMG, bottomIMG]
  }

  if (index > 0 && index < 3) args.wrappers = middles
  if (index % 2 !== 0) args.wrappers = args.wrappers.reverse()

  return args
})

/*
  For wishlists that have less than 8 itens, this will populate the array with a default value
*/
export const populatedMosaicArray = list => {
  while (list.length < 8) {
    list.push(null)
  }
}
