import { FunctionComponent } from 'react'

import { Props } from './types'

const AggregateRatingSchema: FunctionComponent<Props> = ({
  distribution = [],
  ratingValue,
  ratingCount
}) => {
  const ratings = distribution.map(rating => rating.value)
  const hasRatings = ratings.length > 0
  const bestRating = hasRatings ? Math.max(...ratings).toString() : ''
  const worstRating = hasRatings ? Math.min(...ratings).toString() : ''

  if (!ratingCount || !ratingValue) {
    return null
  }

  return (
    <div
      itemProp="aggregateRating"
      itemScope
      itemType="https://schema.org/AggregateRating"
    >
      <meta itemProp="bestRating" content={bestRating} />
      <meta itemProp="worstRating" content={worstRating} />
      <meta itemProp="ratingValue" content={ratingValue} />
      <meta itemProp="ratingCount" content={ratingCount} />
    </div>
  )
}

export default AggregateRatingSchema
