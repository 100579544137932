import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import qs from 'qs'

import { storesApi } from 'api'

import { useUserResources } from 'shared/hooks'

type Store = {
  code: number,
  id: string
  name: string
}

export type UseStoresProps = {
  onHasStore: (store: string) => void
}

type QueryParams = qs.ParsedQs & { store: string }

export const useStores = ({ onHasStore }: UseStoresProps) => {
  const history = useHistory()

  const [stores, setStores] = useState<Store[]>([])

  const { userResources } = useUserResources()

  const queryParams = qs.parse(history.location.search, {
    ignoreQueryPrefix: true
  }) as QueryParams

  useEffect(() => {
    if (!userResources?.region || stores.length) {
      return
    }

    (async (region: string) => {
      try {
        const { data } = await storesApi.getStoresByRegion(
          region,
          'loyaltyProgram'
        )

        const storesArray = data.map((store: Store) => ({
          value: store.id,
          label: store.name
        }))

        setStores(storesArray)

        const store = storesArray.find(
          (item: { value: string, label: string }) =>
            item.value === queryParams.store
        )

        if (store) {
          onHasStore(queryParams.store)
        }
      } catch (error) {
        history.push('/erro-interno')
      }
    })(userResources?.region)
  }, [history, queryParams.store, onHasStore, stores, userResources?.region])

  return { queryParams, stores }
}
