import { useMediaQuery } from '@backyard-ui/hooks'

import { useUserResources } from 'shared/hooks/useUserResources'

import { GET_USER, IS_ON_MOBILE_APP } from '../constants'
import { IS_CATEGORY, IS_SIMPLEX } from '../utils/constants'

function useAnalyticsTags () {
  const { userResources } = useUserResources()
  const isDesktop = useMediaQuery('md')

  const analyticsTags: string[] = []

  if (!userResources) {
    return analyticsTags
  }

  analyticsTags.push(`#${userResources.region}`)

  analyticsTags.push(GET_USER ? '#loginOn' : '#loginOff')

  if (IS_CATEGORY) {
    analyticsTags.push('#categorypage')
  }

  if (IS_SIMPLEX) {
    analyticsTags.push('#simplex')
  }

  if (IS_ON_MOBILE_APP) {
    analyticsTags.push('#app')
  } else if (isDesktop) {
    analyticsTags.push('#desktop')
  } else {
    analyticsTags.push('#mobile')
  }

  return analyticsTags
}

export { useAnalyticsTags }
