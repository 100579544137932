import {
  Container,
  Row,
  Col,
  Heading,
  Button,
  Stack,
  Text,
  Visible,
  Hidden,
  useBreakpoint,
  Inline
} from '@leroy-merlin-br/backyard-react'

import * as S from './styled'

const Cta = () => {
  const assetsPath = window && window.env.assetHost

  const { breakpoint } = useBreakpoint()
  const isMobile = ['untilKilo', 'kilo'].includes(breakpoint)
  const isPortrait = ['untilKilo', 'kilo', 'mega'].includes(breakpoint)

  return (
    <Container>
      <S.Section>
        <Row>
          <Col size={{ giga: 4 }}>
            <S.ImageWrapper>
              <Visible when={['untilKilo', 'kilo', 'mega']}>
                <Stack space="mega">
                  <S.CtaImage
                    src={`${assetsPath}assets/images/lmcv/landing-page-2022/phone-with-app.png`}
                    alt="Imagem representativa do aplicativo da Leroy Merlin"
                  />
                  <Inline space="bit">
                    <a href="https://apps.apple.com/br/app/leroy-merlin-brasil/id1470425305">
                      <img
                        src={`${assetsPath}assets/images/lmcv/landing-page-2022/app-store.png`}
                        alt="botão para App store"
                      />
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=br.com.leroymerlin">
                      <img
                        src={`${assetsPath}assets/images/lmcv/landing-page-2022/google-play.png`}
                        alt="botão para Google Play"
                      />
                    </a>
                  </Inline>
                </Stack>
              </Visible>

              <Hidden when={['untilKilo', 'kilo', 'mega']}>
                <S.CtaImage
                  src={`${assetsPath}assets/images/lmcv/landing-page-2022/phone-with-qr-code.png`}
                  alt="Imagem com QR code para aplicativo da Leroy Merlin"
                />
              </Hidden>
            </S.ImageWrapper>
          </Col>

          <Col size={{ giga: 6, tera: 7 }} offset={{ giga: 2, tera: 1 }}>
            <Stack space="tera">
              <Stack space="kilo">
                <S.TitleWrapper>
                  <Heading noMargin color={isPortrait ? 'n900' : 'white'}>
                    Tudo na sua mão
                  </Heading>
                </S.TitleWrapper>
                <S.TextWrapper>
                  <Text noMargin color={isPortrait ? 'n700' : 'v300'}>
                    Cadastre-se e comece a aproveitar os seus descontos,
                    acumular pontos e trocar por vantagens exclusivas em todos
                    os nossos pontos de venda
                  </Text>
                </S.TextWrapper>
              </Stack>
              <Button as="a" href="/fidelidade/participar" isStretch={isMobile}>
                Aproveite agora
              </Button>
            </Stack>
          </Col>
        </Row>
      </S.Section>
    </Container>
  )
}

export default Cta
