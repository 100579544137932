import { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@emotion/react'
import { datadogRum } from '@datadog/browser-rum'
import { getProductsReviewsRating } from 'hpdp/services/reviews'

import {
  setProductImpressionItems,
  onProductClick,
  pushDataObject
} from 'scripts/utils/data-layer'

import { useUserResources } from 'shared/hooks'
import { NewProductThumb } from 'shared/components/NewProductThumb'
import { GenericCarousel } from 'shared/components/GenericCarousel'

import SalesClick from 'site/utils/dataLayer/Sales/SalesClick'
import WishlistProviderMethods from 'site/Wishlist/provider'

import slugify from 'utils/slugify'
import { tracker } from 'utils/app'

import * as S from './styled'

const ProductsCarousel = ({
  partnerName,
  products,
  title,
  canAddProductsToWishlist = true,
  additionalProductContent = null,
  shouldShowNewReviewsRating = false,
  handleClick
}) => {
  const theme = useTheme()
  const { userResources } = useUserResources()
  const [ratingResponse, setRatingResponse] = useState([])
  const [isLoadingReview, setIsLoadingReview] = useState(false)

  useEffect(() => {
    if (!shouldShowNewReviewsRating) {
      return
    }

    const fetchData = async () => {
      const ids = products.map(item => {
        return Number(item.id)
      })

      try {
        setIsLoadingReview(true)

        const response = await getProductsReviewsRating(ids)
        setRatingResponse(response)
      } catch (e) {
        datadogRum.addError(e)
      } finally {
        setIsLoadingReview(false)
      }
    }

    if (products.length >= 1) {
      fetchData()
    }
  }, [products, shouldShowNewReviewsRating])

  const formattedTitle = useMemo(() => slugify(title, '_'), [title])

  const sendSalesClick = (product, type, position, title) => {
    const salesClick = new SalesClick(product, type, position, title)

    salesClick.sendClickToDatalayer()
  }

  const onClickProductThumb = (product, options) => {
    if (handleClick) {
      handleClick(product.id, options.position)
      return
    }

    onProductClick(product, options)

    sendSalesClick(product, 'offers', options.position, title)

    try {
      tracker.selectItem([
        {
          item_id: product.id,
          item_name: product.name,
          discountPercentage: product.offer?.value,
          item_brand: product.brand,
          categoryTree: product.categoryTree,
          price: product.price.to,
          item_list_name: options.list
        }
      ])
    } catch (e) {
      datadogRum.addError(e)
    }
  }

  const renderCarousel = () => (
    <GenericCarousel
      slidesPerView="auto"
      spaceBetween={5}
      customStyles={S.customGenericCarouselStyles({ theme })}
    >
      {products.map((product, index) => (
        <S.WrapperItem
          className="wrapper-carousel-item"
          key={`product-thumb-${product.id}`}
        >
          <NewProductThumb
            onClick={() => {
              onClickProductThumb(product, {
                list: formattedTitle,
                position: index
              })
            }}
            partnerName={partnerName}
            product={product}
            index={index}
            showAddToWishlistIcon={canAddProductsToWishlist}
            additionalContent={additionalProductContent}
            reviewRating={ratingResponse.find(
              item => item.productLm === Number(product.id)
            )}
            isLoadingReview={isLoadingReview}
            shouldShowNewReviewsRating={shouldShowNewReviewsRating}
            itemList={title}
            itemCategory={
              product.categoryTree
                ? product.categoryTree.replace(/\//g, '|')
                : ''
            }
          />
        </S.WrapperItem>
      ))}
    </GenericCarousel>
  )

  useEffect(() => {
    if (formattedTitle && userResources?.region && products) {
      const dataObject = {
        event: 'productImpression',
        dimension14: userResources && userResources?.region,
        ecommerce: {
          currencyCode: 'BRL',
          impressions: [...setProductImpressionItems(products, formattedTitle)]
        }
      }

      pushDataObject(dataObject)

      try {
        tracker.viewItemList({
          items: products.map(product => ({
            item_id: product.id,
            item_name: product.name,
            discountPercentage: product.offer?.value,
            item_brand: product.brand,
            categoryTree: product.categoryTree,
            price: product.price.to,
            item_list_name: formattedTitle
          }))
        })
      } catch (e) {
        datadogRum.addError(e)
      }
    }
  }, [formattedTitle, userResources, products])

  return (
    <WishlistProviderMethods items={products}>
      <div itemScope itemType="http://schema.org/ItemList">
        {renderCarousel()}
      </div>
    </WishlistProviderMethods>
  )
}

ProductsCarousel.propTypes = {
  partnerName: PropTypes.oneOf([
    'algolia',
    'algonomy',
    'elastic search',
    'retail media'
  ]),
  products: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      shortName: PropTypes.string.isRequired,
      unit: PropTypes.string.isRequired,
      inWishList: PropTypes.bool.isRequired,
      price: PropTypes.object.isRequired,
      installments: PropTypes.object,
      tag: PropTypes.object,
      offer: PropTypes.object,
      url: PropTypes.string.isRequired
    })
  ).isRequired,
  title: PropTypes.string.isRequired
}

export default ProductsCarousel
