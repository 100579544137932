import styled from '@emotion/styled'
import { css } from '@emotion/react'

const thumbnailPictureStyles = ({ theme }) => css`
  label: thumbnail__picture;

  overflow: hidden;
  position: relative;

  border-radius: ${theme.borderRadius.tera};

  height: 100%;
  width: 100%;

  figure, img {
    height: 100%;
    width: 100%;
  }
`

const patternGradientStyles = () => css`
  label: pattern-gradient;

  background-image: linear-gradient(rgba(0, 0, 0, .5), transparent);

  height: 100%;
  width: 100%;

  position: absolute;
`

const badgeWrapperStyles = ({ theme }) => css`
  label: badge__wrapper;

  position: absolute;
  left: ${theme.spacings.mega};
  top: 20px;
`

export const ThumbnailPicture = styled('div')(thumbnailPictureStyles)
export const PatternGradient = styled('div')(patternGradientStyles)
export const BadgeWrapper = styled('div')(badgeWrapperStyles)
