import { Swiper, SwiperSlide } from 'swiper/react'
import { FunctionComponent } from 'react'

import { NavigationButton } from './NavigationButton'
import { Props } from './types'
import * as S from './styled'

const Carousel: FunctionComponent<Props> = ({ children, initialSlide }) => (
  <S.Container>
    <Swiper
      mousewheel
      freeMode
      onScroll={(_, e) => e.preventDefault()}
      grabCursor
      threshold={2}
      navigation={{
        prevEl: '[data-navigation-mode="prev"]',
        nextEl: '[data-navigation-mode="next"]'
      }}
      slidesPerView={'auto'}
      spaceBetween={8}
      initialSlide={initialSlide}
    >
      {children.map(child => (
        <SwiperSlide key={`variant-slide-${child.key}`}>{child}</SwiperSlide>
      ))}
      <NavigationButton mode="prev" />
      <NavigationButton mode="next" />
    </Swiper>
  </S.Container>
)

export default Carousel
