import { css, keyframes } from '@emotion/react'
import styled from '@emotion/styled'

import { Theme } from 'scripts/react-components/themes/Theme'

type StyleProps = {
  width?: number | string
  height?: number | string
  borderRadius?: 'kilo' | 'mega' | 'giga' | 'tera' | 'circle'
}

const loadingAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`

const baseStyles = ({ theme, width, height }: Theme & StyleProps) => {
  const finalWidth = typeof width === 'number' ? width + 'px' : width
  const finalHeight = typeof height === 'number' ? height + 'px' : height

  return css`
    label: shell;

    animation: ${loadingAnimation} 2s ease infinite;
    background: linear-gradient(90deg, ${theme.colors.n200}, ${theme.colors.n100});
    background-size: 200% 200%;
    display: block;
    width: ${finalWidth};
    height: ${finalHeight};
  `
}

const roundedStyles = ({ theme, borderRadius }: Theme & StyleProps) =>
  borderRadius &&
  css`
    label: shell--rounded-border;

    border-radius: ${theme.borderRadius[borderRadius]};
  `

export const Shell = styled('div')(baseStyles, roundedStyles)
