import Cookies from 'js-cookie'
import { Provider } from 'react-redux'
import { ModalProvider } from '@leroy-merlin-br/backyard-react'

import { useUserResources } from 'shared/hooks'

import CartBody from 'checkout/components/Cart/Body'
import { configureStore } from 'checkout/redux/store'

import { Header } from './components/Header'
import * as S from './styled'

const store = configureStore()

const Cart = ({ isChangeCartTypeEnabled, isHybridEnabled, isSimilarProductsEnabled }) => {
  const isOnMobileApp = Cookies.get('is_on_mobile_app') || false
  const shouldRenderHeader = !isOnMobileApp

  const { userResources } = useUserResources()

  return (
    <Provider store={store}>
      <ModalProvider>
        <S.Container className="new-container">
          {shouldRenderHeader && (
            <Header regionName={userResources?.selectedRegionName} />
          )}
          <CartBody
            isHybridEnabled={isHybridEnabled}
            regionName={userResources?.selectedRegionName}
            regionInit={userResources?.region}
            isChangeCartTypeEnabled={isChangeCartTypeEnabled}
            isSimilarProductsEnabled={isSimilarProductsEnabled}
          />
        </S.Container>
      </ModalProvider>
    </Provider>
  )
}

export default Cart
