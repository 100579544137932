import { toast } from '@leroy-merlin-br/backyard-react'

import { updateProject } from 'lmcv/services/customers'

import {
  FAIL_FINISH_PROJECT,
  FAIL_DELETE_CLIENT,
  TRY_AGAIN_LATER
} from './constants'

const errorMessage = message =>
  toast.critical(message, {
    content: TRY_AGAIN_LATER,
    variant: 'solid'
  })

export const finishProject = (id, onClose) => {
  onClose()
  updateProject(id, { status: 'cancelled' })
    .then(() => {
      window.location = '/fidelidade/clientes'
    })
    .catch(() => errorMessage(FAIL_FINISH_PROJECT))
}

export const deleteClient = (id, onClose) => {
  onClose()
  updateProject(id, { status: 'excluded' })
    .then(() => {
      window.location = '/fidelidade/clientes'
    })
    .catch(() => errorMessage(FAIL_DELETE_CLIENT))
}
