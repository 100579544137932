import Cookies from 'js-cookie'

import reactComponentLoader from 'scripts/react-component-loader'

import { CartDrawerProvider } from 'site/Header/contexts/CartDrawerContext'

import CartDrawer from '../components/CartDrawer'

const ProviderAddCart = ({ children }) => {
  const isOnMobileApp = Cookies.get('is_on_mobile_app') || false

  return (
    <CartDrawerProvider>
      {isOnMobileApp && <CartDrawer />}
      {children}
    </CartDrawerProvider>
  )
}

reactComponentLoader(ProviderAddCart, '[data-add-to-cart]')
