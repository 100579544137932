import { Fragment, useEffect } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { useModal } from '@leroy-merlin-br/backyard-react'

import { Actions } from 'lmcv/redux/modules/userProfile'

import { Banner } from './components/Banner'
import { Form } from './components/Form'

const Preferences = () => {
  const dispatch = useDispatch()
  const { payload: userProfile, isRequest } = useSelector(
    state => state.userProfile.get,
    shallowEqual
  )
  const { setModal } = useModal()

  const formModal = () => {
    return setModal({
      hasScroll: 'true',
      size: 'giga',
      children: ({ onClose }) => (
        <Form onClose={onClose} preferencesData={userProfile.data.interests} />
      )
    })
  }

  useEffect(() => {
    dispatch(Actions.getProfile())
  }, [dispatch])

  return (
    <Fragment>
      {!isRequest && (
        <Fragment>
          <Banner onClick={formModal} />
        </Fragment>
      )}
    </Fragment>
  )
}

export default Preferences
