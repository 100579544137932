import { Provider } from 'react-redux'
import { ModalProvider, ToastContainer } from '@leroy-merlin-br/backyard-react'
import dayjs from 'dayjs'
import 'dayjs/locale/pt-br'

import { configureStore } from 'lmcv/redux/store'
import { ConfigureInterceptor } from 'lmcv/services/interceptors'

ConfigureInterceptor()
dayjs.locale('pt-br')

const store = configureStore && configureStore()

const AppProvider = ({ children }) => {
  return (
    <Provider store={store}>
      <ModalProvider>
        <ToastContainer position="top-right" />
        {children}
      </ModalProvider>
    </Provider>
  )
}

export default AppProvider
