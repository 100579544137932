import { useCallback, useState } from 'react'

import socialMediaList from './components/socialMediaList'
import { ButtonTrigger } from './components/ButtonTrigger'
import { Overlay } from './components/Overlay'
import { SocialButtonList } from './components/SocialButtonList'
import * as S from './styled'

const ShareButtons = () => {
  const [isOpen, setIsOpen] = useState(false)

  const pageURL = window.location.href

  const toggleOverlay = useCallback(
    () => {
      setIsOpen((prev) => !prev)
    },
    [setIsOpen]
  )

  const checkBlackList = () => {
    const blackList = [
      '/meus-pedidos',
      '/meus-projetos',
      '/minha-conta',
      '/minhas-listas-de-desejo',
      '/minhas-listas-de-favorito'
    ]

    return blackList.includes(window.location.pathname)
  }

  if (checkBlackList()) {
    return null
  }

  return (
    <S.ShareButtonsWrapper>
      <Overlay
        isVisible={isOpen}
        handleClick={toggleOverlay}
      />

      <ButtonTrigger trigger={toggleOverlay} active={isOpen} />

      <S.ShareButtonsContent isActive={isOpen}>
        <SocialButtonList
          isActive={isOpen}
          socialMediaList={socialMediaList}
          pageURL={pageURL}
        />
      </S.ShareButtonsContent>
    </S.ShareButtonsWrapper>
  )
}

export default ShareButtons
