import PropTypes from 'prop-types'
import {
  useRef,
  forwardRef,
  useImperativeHandle,
  useEffect,
  useState
} from 'react'
import { CloseButton } from '@leroy-merlin-br/backyard-react'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

import * as S from './styled'

const Drawer = forwardRef(
  ({ onClose, children, title = '', isOpen: initialOpen = false, dataCy }, ref) => {
    const [isOpen, setIsOpen] = useState(initialOpen)
    const drawerRef = useRef(null)
    useImperativeHandle(ref, () => drawerRef.current, [drawerRef])

    const onClickClose = () => {
      setIsOpen(false)

      if (onClose) {
        onClose()
      }
    }

    useEffect(() => {
      initialOpen
        ? disableBodyScroll(drawerRef.current)
        : enableBodyScroll(drawerRef.current)
      setIsOpen(initialOpen)
    }, [initialOpen])

    return (
      <S.Wrapper isOpen={isOpen} data-testid="drawer-wrapper">
        <S.Overlay onClick={onClickClose} />
        <S.Drawer isOpen={isOpen} data-cy={dataCy}>
          <S.Header>
            {title && <S.Title>{title}</S.Title>}
            <CloseButton onClick={onClickClose} />
          </S.Header>

          {children && (
            <S.Body ref={drawerRef} data-testid="body">
              <div>{children}</div>
            </S.Body>
          )}
        </S.Drawer>
      </S.Wrapper>
    )
  }
)

Drawer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func,
  title: PropTypes.string
}

export default Drawer
