import { memo } from 'react'
import PropTypes from 'prop-types'
import { Text, useModal } from '@leroy-merlin-br/backyard-react'

import ModalContent from './components/ModalContent/ModalContent'
import * as S from './styled'

const ShipmentsOptions = ({ shipments, skus, region, onClick }) => {
  const { setModal } = useModal()

  const regionName = `${region.city} - ${region.stateCode}`

  const showModal = () => {
    setModal({
      hasScroll: true,
      children: ({ onClose }) => (
        <ModalContent
          onClose={onClose}
          shipments={shipments}
          skus={skus}
          regionName={regionName}
        />
      )
    })

    if (onClick) {
      onClick()
    }
  }

  return (
    <S.Trigger>
      <Text
        size="kilo"
        noMargin
        onClick={showModal}
        color="p600"
      >
        Outras formas de frete para{' '}
        <Text size="kilo" as="strong" isBold noMargin>
          {regionName}
        </Text>
      </Text>
    </S.Trigger>
  )
}

ShipmentsOptions.propTypes = {
  shipments: PropTypes.arrayOf(
    PropTypes.shape({
      skuId: PropTypes.string,
      modalities: PropTypes.objectOf(
        PropTypes.shape({
          cost: PropTypes.number.isRequired,
          quotePrice: PropTypes.number.isRequired,
          deadline: PropTypes.number.isRequired
        })
      )
    })
  ).isRequired,
  skus: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      shop: PropTypes.shape({
        name: PropTypes.string
      })
    })
  ).isRequired,
  region: PropTypes.shape({
    city: PropTypes.string,
    id: PropTypes.string,
    region: PropTypes.string,
    state: PropTypes.string,
    stateCode: PropTypes.string
  }).isRequired
}

export default memo(ShipmentsOptions)
