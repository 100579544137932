import { Button } from '@leroy-merlin-br/backyard-react'
import PropTypes from 'prop-types'

const DeliveryTrackLink = ({ trackingUrl }) => (
  <Button href={trackingUrl} target="_blank" rel="noreferrer">
    Acompanhar entrega
  </Button>
)

DeliveryTrackLink.propTypes = {
  trackingUrl: PropTypes.string.isRequired
}

export default DeliveryTrackLink
