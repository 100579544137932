import BaseTracking from './base-tracking'

class RichRelevanceCartTracking extends BaseTracking {
  static componentName = 'rich-relevance-cart-tracking'

  sendItems (data, R3_COMMON) {
    var R3_CART = new r3_cart() // eslint-disable-line

    data.items.forEach((item) => {
      R3_CART.addItemId(item.itemId)
    })

    return R3_CART
  }
}

export default ($el) => new RichRelevanceCartTracking($el).init()

export { RichRelevanceCartTracking as Component }
