import { Hidden, Visible } from '@leroy-merlin-br/backyard-react'

import { MobileLayout } from '../../components/MobileLayout'
import { Layout } from '../../components/Layout'
import { Addresses } from '../../components/Addresses'

const AddressesPage = () => {
  return (
    <>
      <Visible when={['untilKilo', 'kilo']}>
        <MobileLayout description="Endereços">
          <Addresses />
        </MobileLayout>
      </Visible>
      <Hidden when={['untilKilo', 'kilo']}>
        <Layout description="Endereços" backLink="/">
          <Addresses />
        </Layout>
      </Hidden>
    </>
  )
}

export default AddressesPage
