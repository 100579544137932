import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { Textarea } from '@backyard-ui/core'

import { Theme } from 'scripts/react-components/themes/Theme'

const assessmentWrapperStyles = ({ theme }: Theme) => css`
  label: chat__feedback--assessment-wrapper;

  width: calc(100% - (${theme.spacings.tera} * 2));
  height: auto;
  margin: auto;
  display: flex;
  gap: 14px;
  flex-direction: column;
  align-content: space-between;
  padding: calc(${theme.spacings.giga} + ${theme.spacings.bit});
  border-radius: ${theme.borderRadius.giga};
  background: ${theme.colors.white};
  box-shadow: 0px 2px 2px 0px ${theme.colors.shadow},
    0px 0px 0px 1px rgba(186, 182, 188, 0.07);
`

const disclaimerTextStyles = ({ theme }: Theme) => css`
  label: chat__feedback--assessment-disclaimer-text;

  color: ${theme.colors.n800};
  font-size: ${theme.typography.subHeadings.kilo.fontSize};
  line-height: 120%;
`

const formWrapperStyles = ({ theme }: Theme) => css`
  label: chat__feedback--form;

  display: grid;
  gap: 14px;

  & label {
    cursor: pointer;
    display: none;
  }

  & input[type='radio'] {
    counter-increment: assessment-point;
    -webkit-appearance: none;
    -moz-appearance: none;
    position: relative;
    display: inline-flex;
    height: 22px;
    width: 22px;
    margin: 0 6px 0 0;
    cursor: pointer;
    border: 1px solid ${theme.colors.n200};
    border-radius: ${theme.borderRadius.circle};
    background: ${theme.colors.white};

    &::before {
      content: counter(assessment-point, decimal);
      width: 100%;
      height: 100%;
      display: flex;
      position: relative;
      align-content: center;
      justify-content: center;
      overflow: hidden;
      color: ${theme.colors.n800};
      font-size: 10px;
      font-weight: ${theme.font.weight.bold};
      line-height: 1.7em;
      z-index: ${theme.zIndex.default + 1};
    }

    &:checked {
      border-color: ${theme.colors.n300};
      background-color: ${theme.colors.n100};

      &::before {
        color: ${theme.colors.n500};
      }
    }
  }
`

export const textFieldStyles = ({ theme }: Theme) => css`
  label: chat-input;

  background: ${theme.colors.n100};
  color: ${theme.colors.n800};
  display: flex;
  align-items: flex-start;
  height: 42px;
  padding: 8px 12px;
  font-size: 10px;
  line-height: 13px;
  border: 1px solid ${theme.colors.n300};
  border-radius: ${theme.borderRadius.giga};
  resize: none;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  &:hover,
  &:focus,
  &:focus-visible,
  &:active {
    border: 1px solid ${theme.colors.n300};
    box-shadow: none;
  }
`

const formRadioGroupLabelStyles = () => css`
  label: radio-scale-label;

  display: flex;
  justify-content: space-between;
`

const buttonWrapperStyles = () => css`
  label: button-assesment-wrapper;

  text-align: right;
`

export const AssessmentWrapper = styled('div')(assessmentWrapperStyles)
export const DisclaimerText = styled('h2')(disclaimerTextStyles)
export const FormWrapper = styled('form')(formWrapperStyles)
export const TextField = styled(Textarea)(textFieldStyles)
export const FormRadioGroupLabel = styled('div')(formRadioGroupLabelStyles)
export const ButtonWrapper = styled('div')(buttonWrapperStyles)
