declare global {
  interface Window {
    dataLayer: Record<string, any>[]
  }
}

window.dataLayer = window.dataLayer || []

const execute = (dataLayerObject: Record<string, any>) => {
  window.dataLayer.push(dataLayerObject)
}

export default execute
