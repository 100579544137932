import request from 'scripts/react-components/api/api'

const BASE_URL = window.env && window.env.baseHost

export const getStoresByBatch = async (lm, storeSlug, batchNumber) => {
  const response = await request
    .get(`${BASE_URL}/hybris/stock/${lm}/${storeSlug}/${batchNumber}`)
    .then(({ data }) => data)
    .catch(error => {
      throw error.response
    })

  return response
}

export const getBatchesByStore = async (lm, storeSlug) => {
  const response = await request
    .get(`${BASE_URL}/hybris/stock/${lm}/${storeSlug}`)
    .then(({ data }) => data)
    .catch(error => {
      throw error.response
    })

  return response
}
