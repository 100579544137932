import { Text, Tooltip } from '@leroy-merlin-br/backyard-react'
import { FunctionComponent } from 'react'

import { Props } from './types'
import * as S from './styled'

const Base: FunctionComponent<Props> = ({
  isDisabled,
  isActive,
  onClick,
  url = '#',
  children
}) => (
  <Tooltip
    disabled={!isDisabled}
    offset={[0, 8]}
    content={<span>{'Indisponível'}</span>}
  >
    <S.Wrapper
      isDisabled={isDisabled}
      isActive={isActive}
      href={url}
      onClick={onClick}
    >
      {children}
    </S.Wrapper>
  </Tooltip>
)

export default Base
