import PropTypes from 'prop-types'
import { useEffect } from 'react'

import { Markdown as BaseMarkdown } from 'shared/components/Markdown'

import { Widget } from 'site/Modular/components/Widget'

import { tracker } from 'utils/app'

const Markdown = ({ widget }) => {
  useEffect(() => tracker.viewContent(), [])

  if (widget) {
    return (
      <Widget>
        <BaseMarkdown content={widget.content || JSON.parse(widget)} />
      </Widget>
    )
  }

  return null
}

Markdown.propTypes = {
  widget: PropTypes.shape({
    content: PropTypes.string,
    title: PropTypes.string
  }).isRequired
}
export default Markdown
