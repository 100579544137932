import { FocusEvent } from 'react'

import {
  Message,
  SuggestionOption
} from './Components/ChatModal/Components/Messaging/types'

const MAX_TIME_AWAY = 10000

let chatConciergeTimeout: string | number | NodeJS.Timeout

export const checkCurrentMessageOptions = (
  messages: Message[],
  currentMessage: Message,
  selectedOption: SuggestionOption
): Message[] => {
  const spreadMessages = [...messages]
  const messageIndex = messages.findIndex((value) => value === currentMessage)

  if (messageIndex > 0 && currentMessage.options) {
    spreadMessages[messageIndex] = {
      ...spreadMessages[messageIndex],
      options: (currentMessage.options ?? []).map<SuggestionOption>(
        (option) => ({
          ...option,
          isDisabled: true,
          isSelected: option.label === selectedOption.label
        })
      )
    }
  }

  return spreadMessages
}

export const embedOptionsMessage = (
  message: Message,
  callback: (currentMessage: Message, selectedOption: SuggestionOption) => void
): Message => {
  if (!message.options) {
    return message
  }

  const messageWithOption = { ...message }

  messageWithOption.options = messageWithOption.options?.map((option) => ({
    ...option,
    command: () => {
      option.command()
      callback(messageWithOption, option)
    }
  }))

  return messageWithOption
}

export const getProductsFromMessage = (message: string) => {
  const regex = /[^/]+[_]\d+/g
  const products = message.match(regex)

  if (products && products.length > 0) {
    return products.map((product) => {
      return product.split('_')[1]
    })
  }

  return []
}

export const controlInteractionsFocus = (
  ev: FocusEvent,
  callback?: () => void
) => {
  const clearChatConciergeTimeout = () => {
    if (chatConciergeTimeout) {
      clearTimeout(chatConciergeTimeout)
    }
  }

  if (ev.type === 'blur') {
    chatConciergeTimeout = setTimeout(() => {
      if (callback) {
        callback()
      }

      clearChatConciergeTimeout()
    }, MAX_TIME_AWAY)
  }

  if (ev.type === 'focus') {
    clearChatConciergeTimeout()
  }
}
