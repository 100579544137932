import classnames from 'classnames'
import PropTypes from 'prop-types'

const Menu = ({ items, activedItem, onSelect }) => {
  const renderItems = (item, index) => {
    const { name } = item

    const itemClasses = classnames('wishlist-menu-item', {
      'is-active': activedItem === index
    })

    const itemProps = {
      'data-id': index,
      onClick: onSelect,
      className: itemClasses
    }

    return (
      <li key={`menu-${name}`} {...itemProps}>
        <h2 data-id={index}>{name}</h2>
      </li>
    )
  }

  return (
    <div className="wishlist-page-menu">
      <div className="container">
        <ul className="wishlist-menu">{items.map(renderItems)}</ul>
      </div>
    </div>
  )
}

Menu.proptypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired
    })
  ).isRequired,
  activedItem: PropTypes.number.isRequired,
  onSelect: PropTypes.func.isRequired
}

export default Menu
