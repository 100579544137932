
import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { Theme } from 'scripts/react-components/themes/Theme'

const wrapperStyles = ({ theme }: Theme) => css`
  border: 1px solid ${theme.colors.n100};
  border-radius: ${theme.borderRadius.giga};
  box-sizing: border-box;

  display: flex;
  flex-direction: column;

  justify-content: space-between;

  padding: ${theme.spacings.giga};

  width: 100%;

  ${theme.media.mega} {
    width: 287px;
  }
`

export const Wrapper = styled('div')(wrapperStyles)

const titleStyles = ({ theme }: Theme) => css`
  margin-bottom: ${theme.spacings.byte};

  span {
    font-weight: bold;
  }
`

export const Title = styled('div')(titleStyles)

const descriptionStyles = () => css`
`

export const Description = styled('div')(descriptionStyles)

const wrapperButtonsStyles = ({ theme }: Theme) => css`
  display: flex;

  justify-content: space-between;

  margin: ${theme.spacings.kilo} -${theme.spacings.mega} -${theme.spacings.kilo} -${theme.spacings.mega};

  button {
    font-size: 14px;

    &:focus {
      box-shadow: none;
    }
  }
`

export const WrapperButtons = styled('div')(wrapperButtonsStyles)

const wrapperButtonsInModalStyles = ({ theme }: Theme) => css`
  display: flex;

  justify-content: space-between;

  margin-top: ${theme.spacings.mega};

  button:focus {
    box-shadow: none;
  }
`

export const WrapperButtonsInModal = styled('div')(wrapperButtonsInModalStyles)
