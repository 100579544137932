import { css } from '@emotion/react'
import styled from '@emotion/styled'

const badgeWrapperStyles = ({ theme }) => css`
  label: services-badge;

  margin: ${theme.spacings.giga} ${theme.spacings.kilo} 0;

  ${theme.media.mega} {
    margin: ${theme.spacings.giga} 0 0;
  }
`

export const BadgeWrapper = styled('div')(badgeWrapperStyles)
