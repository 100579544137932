import { css } from '@emotion/react'
import styled from '@emotion/styled'

const infoWrapperStyles = ({ theme }) => css`
  label: info-wrapper;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding: ${theme.spacings.kilo} ${theme.spacings.mega};

  height: 40%;
`

const authorWrapperStyles = () => css`
  label: author-wrapper;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const linkTitleWrapper = ({ theme }) => css`
  label: link__title;

  margin-bottom: ${theme.spacings.mega};
`

export const InfoWrapper = styled('div')(infoWrapperStyles)
export const AuthorWrapper = styled('div')(authorWrapperStyles)
export const LinkTitle = styled('a')(linkTitleWrapper)
