import emitter from 'scripts/utils/emitter'

import { putServices, putServicesParams } from 'checkout/services/cart'

import { genericError } from './errors'

const addServiceToCart = async (data: putServicesParams) => {
  try {
    await putServices(data)

    emitter.emit('cart:services-added')
  } catch (err) {
    const message =
      'Aconteceu um erro técnico. Por favor, recarregue a página e tente novamente. Se o problema persistir, estamos à disposição nos telefones: 4020-5376 Capitais (Telefone e WhatsApp) e 0800-020-5376 Demais Regiões.'

    genericError(message)
  }
}

export default addServiceToCart
