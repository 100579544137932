import { tv } from 'tailwind-variants'

const styles = tv({
  slots: {
    swiper: `
      [&>.swiper-pagination]:pointer-events-none
      [&>.swiper-pagination>.swiper-pagination-bullet-active]:bg-green-500
    `,
    swiperSlide: `
      flex
      items-center
      justify-center
      h-96

      [&_img]:max-w-full
      [&_img]:max-h-full
    `,
    prevButtonWrapper: `
      absolute
      left-2
      top-2/4
      z-[1]
      -translate-y-2/4
    `,
    nextButtonWrapper: `
      absolute
      right-2
      top-2/4
      z-[1]
      -translate-y-2/4
    `
  }
})

export default styles
