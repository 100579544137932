import { Text } from '@leroy-merlin-br/backyard-react'

const HOME_DELIVERY = 'Entrega em domicílio'
const SERVICE = 'Serviços'
const PICKUP = 'Clique e retire'
const SMART_CHECKOUT = 'Retirada na Loja'

type ShippingLabelType = { [key: string]: string }

const SHIPPING_LABEL: ShippingLabelType = {
  [HOME_DELIVERY]: 'Data entrega',
  [SERVICE]: 'Previsão de realização',
  [PICKUP]: 'Data entrega',
  [SMART_CHECKOUT]: 'Data entrega'
}

type ForecastLabelType = { [key: string]: string }

const FORECAST_LABEL: ForecastLabelType = {
  SERVICE: '3 dias após a entrega',
  STANDALONE_SERVICE: '3 dias úteis'
}

type ShippingInfoProps = {
  type: string
  date: string
  isStandaloneService: boolean
}

const shippingFactory = ({
  type,
  date,
  isStandaloneService
}: ShippingInfoProps) => {
  if (!type) {
    return
  }

  if (type === SERVICE || isStandaloneService) {
    const serviceType = isStandaloneService ? 'STANDALONE_SERVICE' : 'SERVICE'

    return {
      label: SHIPPING_LABEL[type],
      forecast: FORECAST_LABEL[serviceType] || date
    }
  } else {
    return {
      label: SHIPPING_LABEL[type],
      forecast: date
    }
  }
}

const ShippingInfo = ({
  type,
  date,
  isStandaloneService
}: ShippingInfoProps) => {
  const presenter = shippingFactory({
    type,
    date,
    isStandaloneService
  })

  if (!presenter) {
    return null
  }

  return (
    <div>
      <Text size="kilo" noMargin>
        {presenter.label}
      </Text>
      <Text as="strong" size="kilo" isBold noMargin>
        {presenter.forecast}
      </Text>
    </div>
  )
}

export default ShippingInfo
