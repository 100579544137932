import { Visible } from '@leroy-merlin-br/backyard-react'

const Wishlist = () => (
  <section className="my-wishlist">
    <div className="my-wishlist-icon">
      <a href="/minhas-listas-de-desejos">
        <i className="glyph glyph-heart"></i>
        <Visible when={['giga', 'tera']}>
          <div className="content">Listas de Favoritos</div>
        </Visible>
      </a>
    </div>
  </section>
)

export default Wishlist
