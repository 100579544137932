import React from 'react'

import * as S from './styled'

type DragAndDropComponentProps = {
  displayComponent: boolean
  isMoved: boolean
  onTouchStart: (e: React.TouchEvent<HTMLDivElement>) => void
  onTouchMove: (e: React.TouchEvent<HTMLDivElement>) => void
  onTouchEnd: () => void
  positionAfterMove: number
  children: any
}

const DragAndDropComponent = ({
  displayComponent,
  isMoved,
  onTouchStart,
  onTouchMove,
  onTouchEnd,
  positionAfterMove,
  children
}: DragAndDropComponentProps) => {
  if (!displayComponent) {
    return null
  }

  const manipulatingCss = isMoved
    ? S.Move(positionAfterMove)
    : S.Moving(positionAfterMove)

  return (
    <S.Wrapper css={manipulatingCss}>
      <S.WrapperHeader
        data-testid="drag-and-drop"
        draggable={true}
        onTouchStart={onTouchStart}
        onTouchMove={onTouchMove}
        onTouchEnd={onTouchEnd}
      >
        <S.WrapperLine />
      </S.WrapperHeader>
      {children}
    </S.Wrapper>
  )
}

export default DragAndDropComponent
