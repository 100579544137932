import { isOnApp } from 'scripts/react-components/utils/onAppCheck'

import { useResize } from 'utils/hooks/use-resize'

import {
  verifyShouldDisplayBannerWithCashback,
  validDateFormat
} from './BannerBehaviour'
import BannerComponentApp from './BannerComponentApp'
import BannerComponentMobile from './BannerComponentMobile'

type Props = {
  appCashbackStartDate?: string
}

const BannerContainer = ({ appCashbackStartDate }: Props) => {
  const [isOnMobileBrowser] = useResize(760)

  const isOnMobileApp = isOnApp()
  const dateStartPromotion = validDateFormat(appCashbackStartDate) ?? ''

  const displayComponent = verifyShouldDisplayBannerWithCashback(
    dateStartPromotion,
    isOnMobileBrowser,
    isOnMobileApp
  )

  if (!isOnMobileApp && isOnMobileBrowser) {
    return (
        <BannerComponentMobile />
    )
  }

  if (isOnMobileApp) {
    return (
    <BannerComponentApp
      displayComponent={displayComponent}
      datePromotion={dateStartPromotion}
    />
    )
  }

  return null
}

export default BannerContainer
