const StoreStockTable = ({ warehouses }) => (
  <div className="row">
    <div className="col-xs-12">
      <table className="table table-zebra table-bordered margin-top">
        <thead>
          <tr className="color-primary">
            <th><strong>Depósito</strong></th>
            <th><strong>Físico</strong></th>
            <th><strong>Reservado</strong></th>
            <th><strong>Disponível</strong></th>
          </tr>
        </thead>
        <tbody>
          {warehouses.map((warehouse, index) => (
            <tr key={index + warehouse.slug}>
              <td className="color-primary">{warehouse.locationName}</td>
              <td>{warehouse.quantity}</td>
              <td>{warehouse.reserved}</td>
              <td>{warehouse.available}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </div>
)

export default StoreStockTable
