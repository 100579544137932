import { Text } from '@leroy-merlin-br/backyard-react'

import {
  STANDARD_SHIPPING_TYPE,
  SERVICE_SHIPPING_TYPE,
  STORE_ADDRESS_LABEL,
  USER_ADDRESS_LABEL,
  INSTALLATION_ADDRESS_LABEL
} from '../consts'

const labels = {
  [SERVICE_SHIPPING_TYPE]: INSTALLATION_ADDRESS_LABEL,
  [STANDARD_SHIPPING_TYPE]: USER_ADDRESS_LABEL
}

const AddressLabel = ({ addressType }) => (
  <Text as="strong" size="kilo" isBold noMargin>
    {labels[addressType] || STORE_ADDRESS_LABEL}
  </Text>
)

export default AddressLabel
