import { getAttribute } from 'scripts/react-components/entry-key/ui/Aside/Filters/Filters.utils'

import { PRICE_URL_TEXT } from './createUrlFilters/priceRangeFilter'
import { ToggleFilterProps } from './createUrlFilters/toggleFilter'
import { getBoitataFacetsAndFilters } from '../routerProxyCategory'

interface FilterToRenderProps {
  [key: string]: string
}

interface refinementListProps {
  [key: string]: Array<string>
}

interface routeStateProps {
  refinementList?: Array<refinementListProps>
  toggle?: ToggleFilterProps
  page?: string
  sortBy?: string
  range?: string
}

export const rebuildUrl = (
  routeState: routeStateProps,
  urlToRebuild: string,
  originalPath: string,
  region: string
) => {
  const urlToRebuildDecoded = decodeURIComponent(urlToRebuild)
  let allFiltersUrl = getFiltersUrl(urlToRebuildDecoded)

  if (Object.keys(routeState).length === 0 || !allFiltersUrl) {
    return originalPath
  }

  const filterToRemove = verifyIfFiltersExistsInUrl(
    allFiltersUrl,
    routeState,
    region
  )

  if (filterToRemove[0]) {
    const filterToRemoveObject: { [key: string]: string } =
      filterToRemove.reduce(
        (acc, current, index) => ({
          ...acc,
          [current]: String(index)
        }),
        {}
      )

    allFiltersUrl = allFiltersUrl.filter(item => !filterToRemoveObject[item])

    urlToRebuild = reassemblingUrl(originalPath, allFiltersUrl, region)
  }

  return urlToRebuild
}

const reassemblingUrl = (
  originalPath: string,
  allFiltersUrl: Array<string>,
  region: string
) => {
  let newUrl = originalPath

  allFiltersUrl.forEach((filter, index) => {
    const attributesToCheck = [
      `regionalAttributes.${region}.offer.enabled`,
      `regionalAttributes.${region}.facets.freeShipping`,
      `regionalAttributes.${region}.facets.blackFriday`
    ]

    const toggles = getAttribute(region).toggle

    for (const key in toggles) {
      const getToggleFilter = toggles[key]

      if (
        attributesToCheck.includes(key) &&
        filter.includes(getToggleFilter.filterValue) &&
        index === 0
      ) {
        filter = getToggleFilter.filterName
      }
    }

    const hasStringFilter = filter.includes('filter')
    const hasOrder = filter.includes('order')
    const [key, value] = hasStringFilter || hasOrder ? filter.split('=') : []

    if (index === 0) {
      newUrl +=
        '/' +
        (hasStringFilter || hasOrder
          ? `${key
              .replace('filter[', '')
              .replace(']', '')
              .replace('order', '')}${hasOrder ? '' : '/'}${value}`
          : filter.replace('=', '/'))
    } else {
      newUrl += (index === 1 ? '?' : '&') + filter
    }
  })

  return newUrl
}

export const getFiltersUrl = (url: string) => {
  const { pathname, search } = new URL(url)

  const firstFilter = decodeURI(pathname).split('/').slice(2).join('/')

  // IMPORTANT: This regex is necessary to work on Safari IOS version <= 18
  const otherFilters = decodeURI(search)
    .replace(/_&/g, '_@and@_')
    .split(/\?|&/)
    .filter(Boolean)
    .map(str => str.replace(/_@and@_/g, '_&'))

  const reorderArrayUrl = []

  if (firstFilter || otherFilters.length) {
    reorderArrayUrl.push(firstFilter)
    return reorderArrayUrl.concat(otherFilters).filter(Boolean)
  }
}

const parseFilterType = (key: string): string =>
  key
    .replace(/\[[^\]]*\]/, '')
    .replace(/^\[|\]$/g, '')
    .toLowerCase()

const checkItemForFilter = (item: string, filterValues: string[]): boolean =>
  filterValues.reverse().some(value => {
    const valueReplace = value.replaceAll(' ', '_').toLowerCase()
    return item.toLowerCase().includes(valueReplace)
  })

const verifyIfFiltersExistsInUrl = (
  allFiltersUrl: Array<string>,
  routeState: routeStateProps,
  region: string
): Array<string> => {
  const filterToRemove: Array<string> = []
  allFiltersUrl.forEach(item => {
    let existsFilter = false

    if (routeState.refinementList) {
      existsFilter = verifyRefinementListExists(
        routeState.refinementList,
        item,
        existsFilter
      )
    }

    if (routeState.page) {
      existsFilter = verifyPageExists(routeState.page, item, existsFilter)
    }

    if (routeState.sortBy) {
      existsFilter = verifyOrderExists(item, existsFilter)
    }

    if (routeState.toggle) {
      existsFilter = verifyToggleExists(
        item,
        existsFilter,
        routeState.toggle,
        region
      )
    }

    if (routeState.range) {
      existsFilter = verifyRangeExists(item, existsFilter)
    }

    if (!existsFilter) {
      filterToRemove.push(item)
    }
  })

  return filterToRemove
}

const verifyRefinementListExists = (
  refinementList: Array<refinementListProps>,
  item: string,
  existsFilter: boolean
): boolean => {
  const { filters: FILTER_TO_RENDER } = getBoitataFacetsAndFilters()

  const refinementListObj = Object.entries(refinementList)
  refinementListObj.forEach(([key, value]) => {
    if (key.includes('attributes') || FILTER_TO_RENDER[key]) {
      parseFilterType(key)

      if (checkItemForFilter(item, value)) {
        existsFilter = true
      }
    }
  })
  return existsFilter
}

const verifyPageExists = (
  page: string,
  item: string,
  existsFilter: boolean
): boolean => {
  const pageString = 'page=' + page
  if (pageString === item) {
    existsFilter = true
  }
  return existsFilter
}

const verifyOrderExists = (item: string, existsFilter: boolean): boolean => {
  if (item.includes('melhor-preco') || item.includes('menor-preco')) {
    existsFilter = true
  }
  return existsFilter
}

const verifyToggleExists = (
  item: string,
  existsFilter: boolean,
  toggle: ToggleFilterProps,
  region: string
): boolean => {
  const hasFilter = Object.keys(toggle).some(key => {
    const filter = getAttribute(region).toggle[key]

    return item.includes(filter.filterName)
  })

  if (hasFilter) {
    existsFilter = true
  }
  return existsFilter
}

const verifyRangeExists = (item: string, existsFilter: boolean): boolean => {
  if (item.includes(PRICE_URL_TEXT)) {
    existsFilter = true
  }

  return existsFilter
}
