import { tv } from 'tailwind-variants'

export const buttonTrigger = tv({
  base: 'flex flex-row gap-2 text-lg font-semibold items-center px-4 h-11 rounded-lg text-left',
  variants: {
    color: {
      primary: 'text-white bg-gradient-to-br from-[#9A21AA] to-[#7808CF]',
      neutral: 'text-[#7808CF] bg-neutral-100'
    }
  },
  defaultVariants: {
    color: 'primary'
  }
})