import {
  Card,
  Text,
  Inline,
  Button,
  Stack
} from '@leroy-merlin-br/backyard-react'

import * as S from './styled'
import { BankSlipVisualType } from './type'

const BankSlip = ({ paymentMethod }: BankSlipVisualType) => (
  <Card>
    <Text size="giga" isBold>
      Dados para pagamento
    </Text>
    <Stack space="kilo">
      <Card>
        <Text as="div" align="center" color="p700" isBold>
          {paymentMethod.number}
        </Text>
        <hr />
        <S.SlipPaymentAction>
          <Text noMargin>
            Se preferir, você pode fazer o pagamento por código de barras
          </Text>
          <Button as="a" href={paymentMethod.url}>Visualizar boleto</Button>
        </S.SlipPaymentAction>
      </Card>
      <S.Panel>
        <Text noMargin isBold>
          Sobre pagamento no boleto
        </Text>
        <Stack space="bit">
          <Inline space="bit">
            <Text noMargin>●</Text>
            <Text noMargin>O pagamento do boleto não pode ser parcelado.</Text>
          </Inline>
          <Inline space="bit">
            <Text noMargin>●</Text>
            <Text noMargin>
              Lembre-se de que a confirmação de pagamento pode levar até 3 dias
              úteis.
            </Text>
          </Inline>
          <Inline space="bit">
            <Text noMargin>●</Text>
            <Text noMargin>
              Em caso de não pagamento até a data de vencimento, o pedido será
              cancelado automaticamente.
            </Text>
          </Inline>
          <Inline space="bit">
            <Text noMargin>●</Text>
            <Text noMargin>
              O boleto é válido somente para este pedido. Para repetir a compra,
              você deve realizar um novo pedido.
            </Text>
          </Inline>
          <Inline space="bit">
            <Text noMargin>●</Text>
            <Text noMargin>
              Você também pode acessar seu boleto através da página Meus
              Pedidos.
            </Text>
          </Inline>
        </Stack>
      </S.Panel>
    </Stack>
  </Card>
)

export default BankSlip
