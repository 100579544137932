import { Fragment } from 'react'
import { Text } from '@leroy-merlin-br/backyard-react'
import {
  TicketPercentOutline,
  GiftOutline,
  MobileLikeOutline,
  StarsOutline,
  HelmetOutline
} from '@backyard-ui/icons'

import { GenericCarousel } from 'shared/components/GenericCarousel'
import { CustomIcon } from 'shared/components/CustomIcon'

import { useResize } from 'utils/hooks/use-resize'

import * as S from './styled'

const ITEMS = [
  {
    icon: TicketPercentOutline,
    title: 'Cupons de Desconto'
  },
  {
    icon: GiftOutline,
    title: 'Surpresa de Aniversário'
  },
  {
    icon: StarsOutline,
    title: 'Pontos que valem dinheiro'
  },
  {
    icon: HelmetOutline,
    title: 'Vantagens para profissionais'
  },
  {
    icon: MobileLikeOutline,
    title: 'Conteúdos Exclusivos'
  }
]

const Carousel = () => {
  const [isMobile] = useResize()

  const carouselProps = {
    slidesPerView: 'auto',
    spaceBetween: 64,
    centerInsufficientSlides: true,
    hasOffset: false
  }

  const renderIcons = () =>
    ITEMS.map(({ icon, title }, index) => {
      const lastItem = index === ITEMS.length - 1
      return (
        <S.Item key={title} hasDivider={!lastItem} isMobile={isMobile}>
          <S.Image>
            <CustomIcon as={icon} fill="linearGradient" />
          </S.Image>
          <Text align="center" size="kilo" color="n800" noMargin>
            {title}
          </Text>
        </S.Item>
      )
    })

  return (
    <Fragment>
      {isMobile ? (
        <GenericCarousel {...carouselProps}>{renderIcons()}</GenericCarousel>
      ) : (
        <S.List>{renderIcons()}</S.List>
      )}
    </Fragment>
  )
}

Carousel.propTypes = {}

export default Carousel
