import { Component, Fragment, createRef } from 'react'
import { flushSync } from 'react-dom'
import { Helmet } from 'react-helmet'

import debounce from 'garden/src/js/utils/debounce'

import emitter from 'scripts/utils/emitter'
import * as wishlistsRepository from 'scripts/api/wishlists'

import { userResourcesApi } from 'api'

import { Breadcrumb } from 'site/Breadcrumb'
import { sendUrlInPostMessage } from 'site/utils/postMessageRN'

import Spinner from 'utils/Spinner'

import { wishlistSchema } from '../schema'
import Title from '../components/Title'
import HomeScreen from '../components/HomeScreen'
import ModifyWishlist from '../components/drawer/lists/ModifyWishlist'
import WishlistCollection from '../components/lists/WishlistCollection'

const ITEMS_PER_PAGE = 20

class MyWishlists extends Component {
  constructor (props) {
    super(props)

    const breadcrumbNodes = [
      {
        id: '1',
        name: 'Home',
        url: '/'
      },
      {
        id: '2',
        name: 'Minhas Listas de Favoritos',
        url: 'minhas-listas-de-desejos'
      }
    ]

    this.scrollObserver = this.createScrollObserver()

    this.containerModuleList = createRef(null)

    this.state = {
      breadcrumbNodes: breadcrumbNodes,
      wishlists: [],
      isOpenedDrawer: false,
      isEditList: false,
      isLogged: false,
      hasError: false,
      slug: '',
      spinnerVisible: false,
      page: 1,
      pageCount: 1
    }
  }

  async componentDidMount () {
    await this.changeUserState()
    sendUrlInPostMessage()
  }

  componentWillUnmount () {
    emitter.removeAllListeners()
  }

  resetWishLists () {
    window.scrollTo(0, 0)
    this.setState({
      spinnerVisible: false,
      wishlists: [],
      page: 1
    })
  }

  createScrollObserver = () => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.9
    }

    return new IntersectionObserver(([entry]) => {
      const { page, pageCount } = this.state
      const hasPagination = page < pageCount
      if (
        entry.isIntersecting &&
        hasPagination &&
        this.state.wishlists.length > 0
      ) {
        this.setState({ page: page + 1 }, async () => {
          await this.loadList()
          this.updateScrollObserver()
        })
      }
    }, options)
  }

  updateScrollObserver = () => {
    this.scrollObserver.disconnect()
    this.scrollObserver.observe(this.containerModuleList.current)
  }

  loadList = async () => {
    this.toggleSpinner()

    try {
      const lists = await wishlistsRepository.lists(
        this.state.page,
        ITEMS_PER_PAGE
      )

      if (lists.status !== 200) {
        throw new Error(
          `Cannot get wishlists, API returns status: ${lists.status}`
        )
      }

      const { data, metadata } = lists.data

      this.setState(
        {
          wishlists: [...this.state.wishlists, ...data],
          spinnerVisible: false,
          pageCount: metadata.pageCount
        },
        () => this.state.wishlists.length > 0 && this.updateScrollObserver()
      )
    } catch (err) {
      this.setState({
        hasError: true,
        spinnerVisible: false
      })
    }
  }

  modifyList = debounce(async listData => {
    this.toggleSpinner()

    const formatData = {
      name: listData.get('wishlist-name'),
      background_image: listData.get('thumb-wishlist')
    }

    try {
      const response = await (this.state.isEditList
        ? wishlistsRepository.editList(formatData, this.state.slug)
        : wishlistsRepository.createList(formatData))

      if (response.status !== 201 && response.status !== 200) {
        this.emitErrorsMessage(response.body.errors)
        this.toggleSpinner()
      } else {
        this.emitSuccessMessage(
          `Lista de favoritos ${
            response.status === 201 ? 'criada' : 'atualizada'
          }!`
        )

        flushSync(() => {
          this.resetWishLists()
        })
        this.closeDrawer()
        this.loadList()
      }
    } catch (err) {
      this.toggleSpinner()
      this.emitErrorsMessage(
        'Não conseguimos adicionar essa lista de favoritos, tente novamente.'
      )
    }
  }, 500)

  removeList = debounce(async item => {
    this.toggleSpinner()

    try {
      const response = await wishlistsRepository.removeList(item)

      if (response.status !== 204) {
        this.emitErrorsMessage(response.body.errors)
        this.toggleSpinner()
      } else {
        flushSync(() => {
          this.resetWishLists()
        })
        this.emitSuccessMessage('Lista de favoritos removida!')
        this.loadList()
      }
    } catch (err) {
      this.toggleSpinner()
      this.emitErrorsMessage(
        'Não conseguimos remover essa lista de favoritos, tente novamente.'
      )
    }
  }, 500)

  openDrawer = (editOptions = {}) => {
    const { isEdit, slug } = editOptions
    this.setState({
      isOpenedDrawer: true,
      isEditList: isEdit,
      slug: slug
    })
  }

  closeDrawer = () => {
    this.setState({
      isOpenedDrawer: false,
      isNewList: false
    })
  }

  emitSuccessMessage (success) {
    emitter.emit('stickyFeedback:success', {
      title: 'Sucesso:',
      content: success
    })
  }

  emitErrorsMessage (error) {
    emitter.emit('stickyFeedback:error', {
      title: 'Ops! Ocorreu o seguinte erro:',
      content: error
    })
  }

  toggleSpinner = () => {
    this.setState(prevState => ({
      spinnerVisible: !prevState.spinnerVisible
    }))
  }

  isLoggedIn = data => data?.email && data.email.length > 0

  changeUserState = async () => {
    this.toggleSpinner()
    const isLoggedIn = this.isLoggedIn(
      await userResourcesApi.storedGetUserResources()
    )

    this.setState({
      isLogged: isLoggedIn,
      spinnerVisible: false
    })
    isLoggedIn && this.loadList()
  }

  redirectToLogin = () => {
    const currentUrl = encodeURIComponent(window.location.href)
    window.location = `/login?redirect=${currentUrl}`
  }

  renderLists = userLists =>
    !this.state.hasError ? (
      <WishlistCollection
        ref={this.containerModuleList}
        wishlists={userLists}
        onCreate={this.openDrawer}
        onEdit={this.openDrawer}
        onRemove={this.removeList}
      />
    ) : (
      <section className="offers-fetch-error">
        <h1>
          Ocorreu um erro ao carregar suas listas, tente novamente em instantes.
        </h1>
      </section>
    )

  render () {
    const { breadcrumbNodes, wishlists, isOpenedDrawer, isLogged, isEditList } =
      this.state

    const FoldersProps = {
      isOpenedDrawer: isOpenedDrawer,
      closeDrawer: this.closeDrawer,
      modifyList: this.modifyList,
      isEditList: isEditList
    }

    return (
      <Fragment>
        <Helmet>
          <title>Minhas Listas de Favoritos - Leroy Merlin</title>
          <meta
            name="description"
            content="Meus projetos na Leroy Merlin. Crie um projeto novo ou
              acesse para conferir todos os produtos e fotos escolhidas aqui."
          />
          <meta
            property="og:description"
            content="Meus projetos na Leroy Merlin. Crie um projeto novo ou
              acesse para conferir todos os produtos e fotos escolhidas aqui."
          />
          <script type="application/ld+json">
            {`${JSON.stringify(wishlistSchema())}`}
          </script>
        </Helmet>
        {this.state.spinnerVisible && <Spinner visible />}
        <ModifyWishlist {...FoldersProps} />
        <div className="wishlist-page-header">
          <div className="container">
            {breadcrumbNodes && <Breadcrumb nodes={breadcrumbNodes} />}
            {isLogged && <Title name={'Minhas Listas de Favoritos'} />}
          </div>
        </div>
        {isLogged ? (
          this.renderLists(wishlists)
        ) : (
          <HomeScreen trigger={this.redirectToLogin} />
        )}
      </Fragment>
    )
  }
}

export default MyWishlists
