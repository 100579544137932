import * as CouponUI from 'frontendCheckout/ui/v1/CouponAndVoucher'
import * as Icons from 'frontendCheckout/assets/icons'

import { SelectableButton } from './SelectableButton'

type Props = {
  title: string
  isSelected: boolean
  isSelectable: boolean
  expiration?: string
  points?: number
  onClick: () => void
}

export const VoucherItem = ({
  isSelectable,
  isSelected,
  title,
  expiration,
  points,
  onClick
}: Props) => {
  return (
    <CouponUI.ListItem color={'default'}>
      <div className="flex flex-row justify-between">
        <div className="items-center flex gap-1 text-lg">
          <div className="text-primary-600">
            <Icons.Voucher />
          </div>
          {title}
        </div>
        <div>
          <SelectableButton
            isSelectable={isSelectable}
            isSelected={isSelected}
            onClick={onClick}
          />
        </div>
      </div>
      <div className="text-sm text-gray-500 flex gap-1 items-center w-full mt-0.5 justify-between">
        <div className="flex items-center gap-1">
          {expiration && (
            <>
              <Icons.Time />
              <span>{expiration}</span>
            </>
          )}
        </div>
        <div className="text-tertiary-600">
          {!!points && `Você troca: ${points?.toLocaleString('pt-BR')} pts`}
        </div>
      </div>
    </CouponUI.ListItem>
  )
}
