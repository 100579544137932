import { css } from '@emotion/react'
import styled from '@emotion/styled'

const searchWrapper = ({ theme }) => css`
  label: search-wrapper;

  display: grid;
  align-items: end;
  grid-template-columns: 1fr auto;

  column-gap: ${theme.spacings.mega};
`

const tableWrapperStyles = ({ theme }) => css`
  label: table-wrapper;

  width: 100%;
  border: 1px solid ${theme.colors.n100};
  border-radius: ${theme.borderRadius.mega};
  overflow: hidden;

  table {
    width: 100%;
    border: none;
    border-collapse: collapse;

    td,
    th {
      border: none;
    }

    td {
      padding: ${theme.spacings.bit} ${theme.spacings.byte};
    }

    tbody tr {
      &:nth-of-type(odd) {
        background-color: ${theme.colors.n100};
      }
    }
  }
`

export const SearchWrapper = styled('div')(searchWrapper)
export const TableWrapper = styled('div')(tableWrapperStyles)
