import { createRef, PureComponent } from 'react'

import * as modularRepository from 'scripts/api/modular'

import { ModulesError } from './components/ModulesError'
import { ModulesSpinner } from './components/ModulesSpinner'
import { ModulesLoading } from './components/ModulesLoading'
import { ModulesDictionary } from './components/ModulesDictionary'
import ModularProviderMethods from './providers/ModularProviderMethods'

class Modular extends PureComponent {
  constructor (props) {
    super(props)

    this.container = createRef(null)
    this.containerModuleList = createRef(null)

    this.scrollObserver = this.createScrollObserver()

    this.state = {
      hasError: false,
      isLoading: true,
      modules: [],
      page: 1,
      pageCount: 1,
      pageErrors: []
    }
  }

  async componentDidMount () {
    window.scrollTo({ top: 0, behavior: 'smooth' })

    await this.getPageModules(this.state.page)

    this.setState({ isLoading: false })
  }

  componentDidUpdate () {
    if (this.containerModuleList.current) {
      this.scrollObserver.observe(this.containerModuleList.current)
    }

    if (this.state.page === this.state.pageCount) {
      this.scrollObserver.disconnect()
    }
  }

  getPageModules = async page => {
    const { modularContentId } = this.props
    const { modules, pageCount } = this.state
    try {
      const screenSize = window.screen.width
      const webDevice = screenSize >= 768 ? 'desktop' : 'mobile'
      const isOnMobileApp = Boolean(
        navigator.userAgent.match('Boitata-App') ||
          document.cookie.match('is_on_mobile_app=true') ||
          window.location.search.match('isOnMobileApp=true')
      )
      const device = isOnMobileApp ? 'app' : webDevice

      const { data } = await modularRepository.getModules(
        modularContentId,
        page,
        device
      )

      this.setState({
        modules: [...modules, ...data.results],
        pageCount: data.metadata.pageCount
      })
    } catch (err) {
      if (page < pageCount) {
        this.setState({ page: page + 1 })
        this.getPageModules(page + 1)
      }
      if (page >= pageCount && modules.length <= 0) {
        this.setState({ hasError: true })
      }
    }
  }

  createScrollObserver = () => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.9
    }

    return new IntersectionObserver(([entry]) => {
      const { page, pageCount } = this.state
      const hasPagination = page < pageCount

      if (entry.isIntersecting && hasPagination) {
        this.setState({ page: page + 1 }, async () => {
          this.scrollObserver.unobserve(this.containerModuleList.current)
          await this.getPageModules(this.state.page)
        })
      }
    }, options)
  }

  renderModules = () => {
    return this.state.modules.map((module, index) => {
      const contentName = module.type === 'children' && {
        contentName: this.props.contentName
      }

      const data = {
        ...module,
        ...contentName,
        extra: this.props.extra || {},
        context: this.props.modularContext
      }

      return (
        <div key={index} ref={this.containerModuleList}>
          <ModulesDictionary data={data} />
        </div>
      )
    })
  }

  render () {
    const { hasError, isLoading, page, pageCount } = this.state

    if (hasError) {
      return <ModulesError />
    }

    if (isLoading) {
      return <ModulesLoading defaultFallback={this.props.fallback} />
    }

    return (
      <ModularProviderMethods isBlackWeekend={this.props.isBlackWeekend}>
        <div ref={this.container}>{this.renderModules()}</div>
        {page < pageCount && <ModulesSpinner />}
      </ModularProviderMethods>
    )
  }
}

export default Modular
