import { tv } from 'tailwind-variants'

export default tv({
  base: '',
  slots: {
    list: 'space-y-2',
    child: 'my-2 ml-4',
    item: 'text-red-100',
    link: `
      flex
      items-start

      after:grayscale
    `,
    label: `
      mb-0
      mr-2

      inline-block
      
      text-gray-700
      hover:text-gray-800

      transition-colors
      duration-150
    `,
    count: `
      inline-block
      select-none

      rounded-full
      bg-gray-100

      px-2
      py-0.5

      text-center
      text-sm

      font-bold
      text-gray-700
    `,
    button: `
      p-0
      mt-2
      mb-0
      mx-0

      text-[14px]
      font-bold
      text-green-700

      bg-transparent

      hover:bg-transparent
      hover:text-green-800

      focus:text-green-800
      focus:bg-transparent
    `
  },
  variants: {
    selected: {
      true: {
        item: 'font-bold'
      }
    },
    disabled: {
      true: {
        button: 'hidden'
      }
    }
  }
})
