import PropTypes from 'prop-types'
import { Stack, Text, Icon } from '@leroy-merlin-br/backyard-react'
import { PlusCircleOutline, MinusCircleOutline } from '@backyard-ui/icons'

import * as S from './styled'

const Quantity = ({
  isDecrementDisabled,
  isIncrementDisabled,
  updateQuantity,
  quantity,
  submit,
  label
}) => {
  const handleInputChange = event => {
    const value = parseFloat(event.target.value, 10)
    const isValid = value > 0
    const newQuantity = isValid ? value : 0

    updateQuantity(newQuantity)
  }

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      submit && submit()
    }
  }

  return (
    <Stack space="bit">
      {label && (
        <Text size="kilo" noMargin isBold>
          {label}
        </Text>
      )}

      <S.Wrapper>
        <S.ActionButton
          disabled={isDecrementDisabled}
          onClick={() => updateQuantity(quantity - 1)}
        >
          <Icon as={MinusCircleOutline} />
        </S.ActionButton>

        <S.Input
          type="number"
          step="any"
          min="1"
          placeholder="0"
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
          value={quantity || ''}
        />

        <S.ActionButton
          disabled={isIncrementDisabled}
          onClick={() => updateQuantity(quantity + 1)}
        >
          <Icon as={PlusCircleOutline} />
        </S.ActionButton>
      </S.Wrapper>
    </Stack>
  )
}

Quantity.propTypes = {
  isDecrementDisabled: PropTypes.bool,
  isIncrementDisabled: PropTypes.bool,
  updateQuantity: PropTypes.func.isRequired,
  quantity: PropTypes.number.isRequired,
  submit: PropTypes.func,
  label: PropTypes.string
}

export default Quantity
