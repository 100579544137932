import { ToastContainer } from '@leroy-merlin-br/backyard-react'
import { FC } from 'react'

import { Routes } from './routes'
import { View } from './types'

const App: FC<View> = (props) => (
  <>
    <ToastContainer position="top-right" />

    <Routes {...props} />
  </>
)

export default App
