import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { Theme } from 'scripts/react-components/themes/Theme'

const wrapperStyles = ({ theme }: Theme) => css`
  label: home-wrapper;

  cursor: pointer;

  width: auto;
  display: flex;
  gap: ${theme.spacings.kilo};

  margin-top: ${theme.spacings.byte};

  flex-direction: column;

  ${theme.media.mega} {
    flex-direction: row;
    flex-wrap: wrap;
  }
`

export const Wrapper = styled('div')(wrapperStyles)
