import { useEffect } from 'react'

import { useChatbot } from 'lmcv/hooks'

const Chatbot = ({ url, token, floatingButton, setTriggers }) => {
  const chatbotData = {
    url,
    token,
    floatingButton
  }

  const { openChatbot } = useChatbot(chatbotData)

  useEffect(() => {
    if (!setTriggers) return

    const triggers = document.querySelectorAll('[data-chatbot-trigger]')

    triggers.forEach(trigger => {
      trigger.addEventListener('click', openChatbot)
    })

    return () => {
      triggers.forEach(trigger => {
        trigger.removeEventListener('click', openChatbot)
      })
    }
  }, [openChatbot, setTriggers])

  return null
}

export default Chatbot
