import {
  Button,
  Checkbox,
  Heading,
  Hidden,
  Select,
  Stack,
  Text,
  Visible
} from '@leroy-merlin-br/backyard-react'
import { Popover } from 'user/components'
import {
  Bathtub,
  Bedding,
  AirConditioner,
  Kitchen,
  Frame,
  Fridge,
  DoorHinge,
  Drill,
  Pendant,
  Fence,
  Broom,
  Shovel,
  Plug,
  Faucet,
  Wood,
  Couch,
  Hanger,
  Paw,
  PaintBucket,
  Floor,
  Door,
  CameraSecurity,
  Mixer
} from '@leroy-merlin-br/backyard-icons'

import { Shell } from 'shared/components'

import { Layout } from '../../components/Layout'
import { MobileLayout } from '../../components/MobileLayout'
import * as S from './styled'
import { InterestOptionData, usePreferences } from './usePreferences'
import * as Icons from '../../icons'

const interestIcons: Record<string, JSX.Element> = {
  bathroom: <Bathtub width={30} height={30} />,
  ironmongery: <DoorHinge width={30} height={30} />,
  tools: <Drill width={30} height={30} />,
  wood: <Wood width={30} height={30} />,
  construction: <Shovel width={30} height={30} />,
  electrical: <Plug width={30} height={30} />,
  hydraulic: <Faucet width={30} height={30} />,
  paints: <PaintBucket width={30} height={30} />,
  floor: <Floor width={30} height={30} />,
  door: <Door width={30} height={30} />,
  kitchen: <Kitchen width={30} height={30} />,
  decoration: <Frame width={30} height={30} />,
  lighting: <Pendant width={30} height={30} />,
  garden: <Fence width={30} height={30} />,
  furnitures: <Couch width={30} height={30} />,
  organization: <Hanger width={30} height={30} />,
  maintenance: <Broom width={30} height={30} />,
  pet_shop: <Paw width={30} height={30} />,
  ventilation: <AirConditioner width={30} height={30} />,
  security: <CameraSecurity width={30} height={30} />,
  diy: <Icons.BricolageIcon />,
  pool: <Icons.PoolIcon />,
  bed_table_bath: <Bedding width={30} height={30} />,
  home_appliances: <Fridge width={30} height={30} />,
  small_appliances: <Mixer width={30} height={30} />,
  informatics: <Icons.ComputingIcon />,
  audio_video_tv: <Icons.AudioVideoTvIcon />
}

const Preferences = () => {
  const {
    loyalty,
    setLoyalty,
    loading,
    handleUpdateInterests,
    checkIsSelected,
    handleSelect,
    occupations,
    favoriteStores,
    interestsOptions,
    interestsShellOptions,
    disableProfessionField
  } = usePreferences()

  const renderComponent = () => (
    <S.Wrapper>
      <S.TitleWrapper>
        <Heading noMargin>
          Preferências
        </Heading>
      </S.TitleWrapper>

      <Text color="n900">
        Conhecendo seus interesses, podemos sugerir ofertas e benefícios que se
        encaixam no que você deseja.
      </Text>

      <S.Heading>
        <Heading color="n900" size="mega" noMargin>
          Programa Fidelidade
        </Heading>
      </S.Heading>

      <S.SubWrapper>
        <S.Field>
          {
            loading ? (
              <Stack space="bit">
                <Shell borderRadius="kilo" height={25} width={200} />
                <Shell borderRadius="kilo" height={35} width={330} />
              </Stack>
            ) : (
              <S.SelectWrapper>
                <Select
                  disabled={loading || disableProfessionField}
                  label={
                    <S.Label>
                      <Popover
                        list={{
                          title: 'Vantagens',
                          items: [
                            'Profissionais da construção possuem vantagens exclusivas no Programa de Fidelidade'
                          ]
                        }}
                        placement="bottom"
                      >
                        <Text color="n800" size="mega" isBold noMargin>
                          Qual sua profissão?
                        </Text>
                      </Popover>
                    </S.Label>
                  }
                  options={occupations}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setLoyalty({ ...loyalty, occupation: e.target.value })}
                  placeholder="Selecione sua profissão"
                  value={loyalty?.occupation}
                />
              </S.SelectWrapper>
            )
          }
        </S.Field>

        <S.Field>
          {
            loading ? (
              <Stack space="bit">
                <Shell borderRadius="kilo" height={25} width={200} />
                <Shell borderRadius="kilo" height={35} width={330} />
              </Stack>
            ) : (
              <S.SelectWrapper>
                <Select
                  disabled={loading}
                  label={
                    <S.Label>
                      <Text color="n800" size="mega" isBold>
                        Qual sua loja favorita?
                      </Text>
                    </S.Label>
                  }
                  options={favoriteStores}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setLoyalty({ ...loyalty, favoriteStore: e.target.value })}
                  placeholder="Selecione sua loja favorita"
                  value={loyalty?.favoriteStore}
                />
              </S.SelectWrapper>
            )
          }
        </S.Field>

        <S.Field>
          {
            loading ? (
              <Stack space="byte">
                <Shell borderRadius="kilo" height={25} width={200} />
                <Shell borderRadius="kilo" height={45} />
              </Stack>
            ) : (
              <>
                <Text color="n900" size="mega" isBold>
                  Programa de fidelidade "Leroy Merlin Com Você”
                </Text>

                <S.CheckboxWrapper>
                  <Checkbox
                    disabled={loading}
                    defaultChecked={loyalty?.optIn}
                    onClick={() => setLoyalty({ ...loyalty, optIn: !loyalty?.optIn })}
                    color="n900"
                  >
                    Aceito participar do programa de fidelidade "Leroy Merlin Com Você” para
                    receber benefícios e vantagens exclusivas por e-mail e SMS.
                  </Checkbox>
                </S.CheckboxWrapper>
              </>
            )
          }
        </S.Field>
      </S.SubWrapper>

      <S.Heading>
        <Heading color="n900" size="mega">
          Selecione outros temas do seu interesse:
        </Heading>
      </S.Heading>

      <S.SubWrapper style={{ marginBottom: 30 }}>
        <S.RadioSquareWrapper>
          {
            loading ? (
              <ul>
                {
                  interestsShellOptions.map((interestShell: number) => (
                    <S.CustomShell
                      key={`preferences-interest-${interestShell}`}
                    >
                      <Shell
                        borderRadius="kilo"
                        height={144}
                      />
                    </S.CustomShell>
                  ))
                }
              </ul>
            ) : (
              <ul>
                {interestsOptions?.map((interest: InterestOptionData) => (
                  <S.Item
                    key={`preferences-interest-${interest.id}`}
                    loading={loading}
                    selected={checkIsSelected(interest.id)}
                    onClick={() => handleSelect(interest.id)}
                  >
                    {interestIcons[interest.id]}
                    {interest.name}
                  </S.Item>
                ))}
              </ul>
            )
          }
        </S.RadioSquareWrapper>
      </S.SubWrapper>

      <S.ButtonWrapper>
        <Button isLoading={loading} onClick={handleUpdateInterests}>
          Salvar
        </Button>
      </S.ButtonWrapper>
    </S.Wrapper>
  )

  return (
    <>
      <Visible when={['untilKilo', 'kilo']}>
        <MobileLayout>
          {renderComponent()}
        </MobileLayout>
      </Visible>
      <Hidden when={['untilKilo', 'kilo']}>
        <Layout backLink="/">
          {renderComponent()}
        </Layout>
      </Hidden>
    </>
  )
}

export default Preferences
