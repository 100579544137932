import PropTypes from 'prop-types'

import { Input } from './components/Input'
import * as S from './styled'

const Quantity = ({
  packaging,
  packagingCount,
  productMaxStock,
  updateQuantity,
  useFormatQuantity,
  handleInputChange
}) => {
  return (
    <S.Wrapper fromCart>
      <S.ActionButton
        data-cy="quantity-minus-action"
        data-testid="quantity-minus-action"
        disabled={useFormatQuantity <= packaging}
        onClick={() => updateQuantity({
          value: -1,
          isDecreasing: true
        })}
      >
        <i className="glyph glyph-minus-btn"/>
      </S.ActionButton>
      <Input quantity={useFormatQuantity} packaging={packaging} updateQuantity={handleInputChange}/>
      <S.ActionButton
        data-cy="quantity-plus-action"
        data-testid="quantity-plus-action"
        disabled={packagingCount >= productMaxStock}
        onClick={() => updateQuantity({ value: 1 })}
      >
        <i className="glyph glyph-plus-btn"/>
      </S.ActionButton>
    </S.Wrapper>
  )
}

Quantity.propTypes = {
  handleInputChange: PropTypes.func.isRequired,
  packaging: PropTypes.number,
  packagingCount: PropTypes.number,
  productMaxStock: PropTypes.number,
  updateQuantity: PropTypes.func.isRequired,
  useFormatQuantity: PropTypes.number
}

export default Quantity
