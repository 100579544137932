import React from 'react'

import { renderTemplate } from '../render-template'

const QuestionMarkTooltip = ({ facet }) => (
  <>
    <button
      type="button"
      className="button button-text question-mark-tooltip circular align-middle question-mark-tooltip-small"
      data-component="tooltip"
      data-placement="right-start"
      data-tooltip="[data-question-tooltip]"
    >
      ?
    </button>
    <div
      className="clickable-tooltip tooltip"
      data-question-tooltip
      x-placement="right"
    >
      {facet.description}
    </div>
  </>
)

const FacetFilter = ({ facets, ignoreExclusive, origin, assistedSale }) => {
  const shouldIgnoreExclusive = ignoreExclusive ? 1 : 0

  return (
    <div>
      {assistedSale && (
        <div className="stock-filter margin-bottom margin-top">
          <div className="row">
            <div className="col-xs-12">
              <p className="no-margin-top color-primary">
                <strong>Disponibilidade de estoque</strong>
              </p>
            </div>
            <div className="col-xs-4 col-md-12 col-lg-4">
              <div className="filter-label">Minímo de:</div>
            </div>
            <div className="col-xs-4 col-md-12 col-lg-4">
              <input
                className="input stock-quantity"
                name="stock-quantity"
                value={assistedSale.minimumStock}
                data-stock-quantity
              />
            </div>
            <div className="col-xs-4 col-md-12 col-lg-4 margin-top-small">
              <div className="filterlabel">peças</div>
            </div>
            <div className="col-xs-12 margin-top">
              <button
                className="button button-primary button-full button-thinner no-shadow"
                data-component="entry-key/products-filter"
                data-facet="minimumStock"
                data-origin={origin}
                data-query={`minimumStock=${assistedSale.minimumStock}`}
                data-stock-trigger
              >
                ok
              </button>
            </div>
          </div>
        </div>
      )}

      {facets.map(facet => {
        if (!facet.options || facet.options.length === 0) return null

        const isExpandable = facet.options.length > 5
        const readableName = facet.readableName
        const isURL = facet.isUrl ? 1 : 0

        return (
          <div
            key={facet.name}
            className={`${
              isExpandable
                ? 'margin-bottom-double'
                : 'margin-bottom facet-filter'
            }`}
          >
            <div className="heading-5 strong color-primary margin-y-small align-middle">
              {readableName}
            </div>

            {facet.description && <QuestionMarkTooltip facet={facet} />}

            <div
              className={`${
                isExpandable ? 'expandable-product-list-filter-desktop' : ''
              }`}
            >
              <ul
                className={isExpandable ? 'collapse' : ''}
                data-facet-expandable={facet.name}
              >
                {facet.options.map(option => {
                  const amount = option.quantity
                  const filterKey = facet.name
                  const filterQuery = `filter[${filterKey}]=${option.name}`

                  if (amount <= 0) return null

                  return (
                    <a
                      key={option.name}
                      className="color-default-dark"
                      href="#"
                      data-component="entry-key/products-filter"
                      data-query={filterQuery}
                      data-exclusive-url={isURL}
                      data-suffix={option.query}
                      data-origin={origin}
                      data-facet={filterKey}
                      data-ignore-exclusive-url={shouldIgnoreExclusive}
                      title={option.title}
                    >
                      <li className="product-filter-item">
                        {option.label}
                        <span className="color-default small">({amount})</span>
                      </li>
                    </a>
                  )
                })}
              </ul>

              {isExpandable && (
                <div className="line-around small margin-bottom">
                  <div
                    className="link hidden"
                    data-component="live-collapse"
                    data-target={`[data-facet-expandable=${facet.name}]`}
                    data-is-facet-collapse="true"
                  >
                    <div className="line-wrapper">
                      <span className="hidden-active">Ver mais</span>
                      <span className="visible-active">Ver menos</span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )
      })}
    </div>
  )
}

const render = (props = {}) => renderTemplate(<FacetFilter {...props} />)

export default render
