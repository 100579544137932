import { Component } from 'react'
import { AnimatePresence, motion } from 'framer-motion'

import debounce from 'garden/src/js/utils/debounce'

import { GardenDrawer } from 'shared/components/GardenDrawer'

import StepTwo from '../steps/Two'

const variants = {
  initial: {
    opacity: 0
  },
  enter: {
    opacity: 1,
    transition: {
      duration: 0.3
    }
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.3
    }
  }
}

class ModifyWishlist extends Component {
  constructor (props) {
    super(props)

    this.state = {
      screenSize: window.innerWidth,
      isOpenedDrawer: false,
      isOpenedMobile: false
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.props.isOpenedDrawer !== prevProps.isOpenedDrawer) {
      this.setState({
        isOpenedDrawer: this.props.isOpenedDrawer
      })
    }
  }

  onTouchMove = debounce((touches, target) => {
    this.setState({
      isOpenedMobile: touches[0].clientY < target.getBoundingClientRect().top
    })
  }, 150)

  hideElement = (stateName) => {
    this.setState({ [stateName]: false })

    this.props.closeDrawer(stateName)
  }

  render () {
    const {
      modifyList,
      isEditList
    } = this.props

    return (
      <GardenDrawer
        overlayClick={ () => this.hideElement('isOpenedDrawer') }
        isOpen={ this.state.isOpenedDrawer }
        className="wishlist-drawer">
        <div className="wishlist-drawer-content">
          <AnimatePresence>
            {
              this.state.isOpenedDrawer && <motion.div
                variants={variants}
                initial="initial"
                animate="enter"
                exit="exit"
              >
                <StepTwo
                  isEdit={isEditList}
                  close={ () => this.hideElement('isOpenedDrawer') }
                  modify={modifyList}
                  status={ status }
                  move={ (e) => this.onTouchMove(e.touches, e.target) }
                />
              </motion.div>
            }
          </AnimatePresence>
        </div>
      </GardenDrawer>
    )
  }
}

export default ModifyWishlist
