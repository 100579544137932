import { Text } from '@leroy-merlin-br/backyard-react'
import { FunctionComponent } from 'react'

import { Base } from '../components'
import { Props } from './types'

const Default: FunctionComponent<Props> = ({
  label,
  isDisabled,
  isActive,
  onClick,
  url = '#'
}) => (
  <Base isDisabled={isDisabled} isActive={isActive} onClick={onClick} url={url}>
    <Text size="kilo" noMargin color={'n900'} align="center" as="span" isBold>
      {label}
    </Text>
  </Base>
)

export default Default
