import { SubHeading } from '@leroy-merlin-br/backyard-react'

import * as S from './styled'

interface OptionalLabelProps {
  children: string
}

export default function OptionalLabel ({ children }: OptionalLabelProps) {
  return (
    <S.Label>
      <SubHeading size="kilo" as="h6" noMargin>
        {children}
        <span> - opcional</span>
      </SubHeading>
    </S.Label>
  )
}
