import { Fragment } from 'react'
import { Text, Popover } from '@leroy-merlin-br/backyard-react'

import { TooltipIcon } from '../TooltipIcon'

const PointsTooltip = () => {
  return (
    <Popover
      title="Como funcionam os Bônus de Clientes?"
      content={
        <Fragment>
          <Text size="kilo">
            Os bônus de clientes são todos pontos que você ganha quando seus
            clientes realizam compras.
          </Text>
          <Text size="kilo">
            Durante um projeto você ganha{' '}
            <Text as="span" size="kilo" isBold>
              10%
            </Text>{' '}
            de pontos pela compra de cada cliente.
          </Text>
          <Text size="kilo" noMargin>
            Você também pode ganhar bônus de clientes concluindo os
            <Text as="span" size="kilo" isBold>
              {' '}
              Meus Objetivos
            </Text>
            .
          </Text>
        </Fragment>
      }
      placement="bottom"
    >
      <TooltipIcon />
    </Popover>
  )
}

export default PointsTooltip
