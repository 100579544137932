import { FunctionComponent } from 'react'
import { Stack, Text, Flex } from '@backyard-ui/core'
import { StarIcon } from 'hpdp/components'

import { Props } from './types'
import { RatingBar } from './RatingBar'
import styles from './RatingResume.styles'

const RatingResume: FunctionComponent<Props> = ({
  generalScores,
  isPopover = false
}) => {
  const {
    distribution,
    statistics: { totalReviewsCount }
  } = generalScores

  const valuesArray = new Array(5).fill({})

  return (
    <Stack direction="column" data-testid="rating-resume">
      <Stack spacing="2" direction="column-reverse">
        {valuesArray.map((_, i) => {
          const realIndex = i + 1

          const count =
            distribution.find(item => item.value === realIndex)?.count || 0
          const percentage = Math.round(
            (count / parseInt(totalReviewsCount)) * 100
          )

          return (
            <Stack
              justify="flex-start"
              align="center"
              spacing={2}
              key={realIndex}
              data-testid="rating-stack"
            >
              <Flex justify="center" align="center">
                <div className={styles()}>
                  <Text weight="bold" size={isPopover ? 'lg' : '3xl'} asChild>
                    <span data-testid="rating-resume-value">{realIndex}</span>
                  </Text>
                </div>
              </Flex>
              <StarIcon color="primary" />
              <RatingBar
                percentage={percentage}
                size={isPopover ? 'sm' : 'md'}
              />
              <div className={styles()}>
                <Text size="lg" color="gray-600" data-testid="count-text">
                  {count}
                </Text>
              </div>
            </Stack>
          )
        })}
      </Stack>
    </Stack>
  )
}

export default RatingResume
