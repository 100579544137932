import { ReactNode, createContext, useContext, useEffect, useState } from 'react'

import { OptionToRecovery } from '../../components/OptionsToReceiveSecurityCodeStep/OptionsToReceiveSecurityCodeStep'

type AccessDataContextType = {
  contextData: FormValuesType
  updateData: (response: FormValuesType) => void
  isCaptchaEnabled: boolean
  captchaKey: string
}
type AccessDataProviderProps = {
  children: ReactNode
  isCaptchaEnabled: boolean
  captchaKey: string
}

export type FormValuesType = {
  fiscalId: string
  code: string
  optionToRecovery: OptionToRecovery
}

const FORM_VALUES: FormValuesType = {
  fiscalId: '',
  code: '',
  optionToRecovery: {}
}

const AccessDataContext = createContext<
  AccessDataContextType | Record<string, never>
>({})

export const AccessDataProvider = ({ children, isCaptchaEnabled, captchaKey }: AccessDataProviderProps) => {
  const initialContextData: FormValuesType = FORM_VALUES

  const [contextData, setContextData] = useState<FormValuesType>(initialContextData)

  return (
    <AccessDataContext.Provider value={{ contextData, updateData: setContextData, isCaptchaEnabled, captchaKey }}>
      {children}
    </AccessDataContext.Provider>
  )
}

export const useAccessDataContext = () => useContext(AccessDataContext)
