import { Fragment, useReducer } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  TextField,
  Text,
  Stack,
  Card,
  Icon
} from '@leroy-merlin-br/backyard-react'
import { Location } from '@leroy-merlin-br/backyard-icons'
import { CrossOutline } from '@backyard-ui/icons'

import debounce from 'garden/src/js/utils/debounce'

import * as locationRepository from 'scripts/api/header/location'
import * as dataLayer from 'scripts/utils/data-layer'

import * as message from 'site/utils/message'
import { buildQueryString } from 'site/utils/buildQueryString'

import { hideSpinner, showSpinner } from 'utils/Spinner'

import { RegionSuggestion } from './components/RegionSuggestion'
import * as S from './styled'
import {
  LocationFormReducer,
  LocationFormInitialState
} from './LocationFormReducer'

const DEFAULTS = {
  errors: {
    PERMISSION_DENIED: 'Permissão de acesso negada pelo usuário',
    POSITION_UNAVAILABLE: 'Geolocalização indisponível',
    TIMEOUT: 'Tempo esgotado'
  },
  positionOptions: {
    enableHighAccuracy: true,
    timeout: 60000,
    maximumAge: 0
  }
}

const LocationForm = ({ showCloseButton, onClose }) => {
  const [state, dispatch] = useReducer(
    LocationFormReducer,
    LocationFormInitialState
  )

  const {
    suggestions,
    cityInput,
    zipCodeInput,
    zipCodeId,
    inputError,
    showSuggestion,
    showSecondStep,
    currentOption
  } = state

  const location = window.location
  const { protocol, hostname, pathname, href: currentUrl } = location

  const getCoordinates = () => {
    const hasGeolocationFeature = !!navigator.geolocation

    if (hasGeolocationFeature) {
      navigator.geolocation.getCurrentPosition(
        setGeoLocation,
        errorHandler,
        DEFAULTS.positionOptions
      )
    }
  }

  const setGeoLocation = ({ coords }) => {
    const { latitude, longitude } = coords

    dataLayer.locationChangeSelectionAction({ action: 'auto-location' })

    const baseURL = `${protocol}//${hostname}`
    const previousUrl =
      pathname === '/localizacao' ? '' : `&previousUrl=${currentUrl}`

    const url = `${baseURL}/localizacao/store/?lat=${latitude}&long=${longitude}&${previousUrl}`

    location.replace(url)
  }

  const getZipCode = async zipCode => {
    showSpinner()

    try {
      const region = await locationRepository.getZipCode(zipCode)

      if (region.status !== 200 && region.status !== 404) {
        throw new Error(
          `Cannot get region, API returns status: ${region.status}`
        )
      }

      if (region.status === 404) {
        hideSpinner()
        dispatch({ type: 'GET_ZIPCODE_ERROR' })
        return
      }

      hideSpinner()
      dispatch({ type: 'SELECT_REGION', regionData: region.data })
    } catch (err) {
      dispatch({ type: 'GET_ZIPCODE_ERROR' })
      hideSpinner()
    }
  }

  const onZipCodeChange = event => {
    event.stopPropagation()

    const zipCodeInputValue = event.target.value
    const zipCodeFormatted = zipCodeInputValue
      .replace(/\D/g, '')
      .replace(/(\d{5})(\d{3})(.?)/, '$1-$2')

    dispatch({
      type: 'ON_ZIPCODE_CHANGE',
      zipCodeInput: zipCodeFormatted
    })

    if (zipCodeFormatted.length > 8) {
      getZipCode(zipCodeFormatted)
    }
  }

  const onTextIputChange = event => {
    const city = event.target.value

    dispatch({ type: 'SET_CITY_INPUT', cityInput: city })

    if (city === '') {
      dispatch({ type: 'CLOSE_SUGGESTIONS', showSecondStep: false })
      return
    }

    if (city.length >= 3) {
      getSuggestions(city)
    }
  }

  const getSuggestions = debounce(async userText => {
    try {
      const suggestionsResponse = await locationRepository.getSuggestion(
        userText
      )

      if (
        suggestionsResponse.status !== 200 &&
        suggestionsResponse.status !== 404
      ) {
        throw new Error(
          `Cannot get suggestions, API returns status: ${suggestionsResponse.status}`
        )
      }

      if (suggestionsResponse.status === 404) {
        dispatch({ type: 'GET_SUGGESTIONS_ERROR' })
        return
      }

      dispatch({
        type: 'GET_SUGGESTIONS_SUCCESS',
        suggestions: suggestionsResponse.data
      })
    } catch (err) {
      dispatch({ type: 'GET_SUGGESTIONS_ERROR' })
    }
  }, 500)

  const errorHandler = error => {
    const { PERMISSION_DENIED, POSITION_UNAVAILABLE, TIMEOUT } = DEFAULTS.errors

    switch (error.code) {
      case error.PERMISSION_DENIED:
        return message.error(PERMISSION_DENIED)

      case error.POSITION_UNAVAILABLE:
        return message.error(POSITION_UNAVAILABLE)

      case error.TIMEOUT:
        return message.error(TIMEOUT)
    }
  }

  const avoidEnterSubmit = event => {
    const ENTEY_KEY = 13
    if (event.keyCode === ENTEY_KEY) {
      return event.preventDefault()
    }
  }

  const onSelectSuggestion = region => {
    dispatch({
      type: 'SELECT_REGION',
      regionData: region,
      zipCodeInput: ''
    })
  }

  const handleSubmit = event => {
    event.preventDefault()

    const isZipCodeValid =
      zipCodeInput && zipCodeInput.length > 8 && !inputError.zipcode

    const chosenAction = isZipCodeValid ? 'zip-code' : 'selected-region'

    dataLayer.locationChangeSelectionAction({
      action: chosenAction,
      regionName: cityInput,
      zipCode: zipCodeInput
    })

    const baseURL = `${protocol}//${hostname}`

    const zipCodeObject = isZipCodeValid
      ? { zipCode: zipCodeInput }
      : { zipCodeId: zipCodeId }

    const query = buildQueryString({
      ...zipCodeObject
    })

    const previousUrl =
      pathname === '/localizacao' ? '' : `&previousUrl=${currentUrl}`

    const url = `${baseURL}/localizacao/store/?${query}${previousUrl}`

    location.replace(url)
  }

  const closeSuggestions = debounce(
    () => dispatch({ type: 'CLOSE_SUGGESTIONS' }),
    200
  )

  return (
    <Fragment>
      <div data-spinner-component></div>
      <S.Wrapper>
        <Card title=" " onClose={showCloseButton ? onClose : null}>
          <form autoComplete="off" onSubmit={handleSubmit}>
            <Stack space="byte">
              <S.Title>Onde você está?</S.Title>
              <Text size="mega" noMargin>
                Dessa forma você terá acesso aos produtos e ofertas da sua
                região.
              </Text>
              <Button
                type="button"
                size="mega"
                variant="ghost"
                iconLeft={Location}
                onClick={getCoordinates}
                isStretch
              >
                Utilizar localização automática
              </Button>
            </Stack>

            <S.InputsWrapper>
              <Stack space="bit">
                <Text size="mega" noMargin>
                  Você também pode:
                </Text>
                <S.RegionSelect>
                  <Stack space="bit">
                    <TextField
                      type="text"
                      name="city"
                      list="cities"
                      placeholder="Digitar o nome da cidade"
                      onChange={onTextIputChange}
                      onKeyDown={avoidEnterSubmit}
                      onBlur={closeSuggestions}
                      value={cityInput}
                      state={Boolean(inputError.city) && 'invalid'}
                      disabled={currentOption}
                      hint={inputError.city && 'Cidade não encontrada'}
                      renderSuffix={() =>
                        currentOption && (
                          <S.ClearButton
                            onClick={() => dispatch({ type: 'CLEAR_ALL' })}
                          >
                            <Icon as={CrossOutline} size="kilo" />
                          </S.ClearButton>
                        )
                      }
                    />
                    <RegionSuggestion
                      isOpen={showSuggestion}
                      suggestions={suggestions}
                      onRegionSelect={onSelectSuggestion}
                    />
                  </Stack>
                </S.RegionSelect>
                <Text size="mega" noMargin>
                  ou:
                </Text>
                <TextField
                  type="text"
                  name="zipcode"
                  placeholder="Digitar o CEP"
                  onChange={onZipCodeChange}
                  onKeyDown={avoidEnterSubmit}
                  maxLength="9"
                  value={zipCodeInput}
                  state={Boolean(inputError.zipcode) && 'invalid'}
                  hint={inputError.zipcode && 'CEP não encontrado'}
                />
              </Stack>
            </S.InputsWrapper>

            {showSecondStep && (
              <Button size="kilo" data-cy="confirm-button" isStretch>
                Confirmar
              </Button>
            )}
          </form>
        </Card>
      </S.Wrapper>
    </Fragment>
  )
}

LocationForm.propTypes = {
  showCloseButton: PropTypes.bool,
  onClose: PropTypes.func
}

export default LocationForm
