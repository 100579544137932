import { useState } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import {
  TextField,
  Button,
  Stack,
  SubHeading,
  Row,
  Col
} from '@leroy-merlin-br/backyard-react'

import * as S from './styled'
import { Image } from '../../components/Image'

const getLabelDocument = occupation => {
  if (occupation === 'architect') {
    return 'Número do CAU'
  }

  if (occupation === 'engineer') {
    return 'Número do CREA'
  }

  if (occupation === 'decorator') {
    return 'Número do ABD'
  }

  return 'Número do documento de classe'
}

const Form = ({ occupation, onSubmit, onCancel }) => {
  const [loading, setLoading] = useState(false)
  const [image, setImage] = useState()
  const {
    handleSubmit,
    register,
    formState: { errors },
    ...propsForm
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      document: '',
      image: ''
    }
  })

  const generateDataforSubmit = async data => {
    const dataSend = new FormData()
    dataSend.append('id', data.document)
    dataSend.append('picture', image, data.image)

    setLoading(true)
    await onSubmit(dataSend)
    setLoading(false)
  }

  const validateDocument = value => {
    const isCharactersRepeated = /(\d)\1{5,}/i.test(value)
    const containStrangeCharacters = /[^a-zA-Z0-9\-.]+/i.test(value)
    const isSequential = /(0?123456789|9876543210?)/i.test(value)

    return !isCharactersRepeated && !containStrangeCharacters && !isSequential
  }

  return (
    <S.Wrapper>
      <form
        onSubmit={handleSubmit(generateDataforSubmit)}
        data-cy="document-pro-form"
      >
        <Stack>
          <TextField
            label={getLabelDocument(occupation)}
            {...register('document', {
              required: 'Campo documento é obrigatório.',
              validate: value =>
                validateDocument(value) || 'O documento precisa ser válido',
              maxLength: {
                value: 13,
                message: 'Máximo 13 caracteres'
              },
              minLength: {
                value: 3,
                message: 'Mínimo 3 caracteres'
              }
            })}
            state={Boolean(errors.document) && 'invalid'}
            hint={errors.document?.message}
          />

          <Image
            label="Foto do documento de classe"
            setImage={setImage}
            {...register('image', {
              required: 'Tipo da imagem .jpg, .jpeg e .png com no máximo 10mb',
              pattern: {
                value: /\.(png|jpe?g)$/i,
                message: 'É permitido apenas PNG e JPEG'
              }
            })}
            errors={errors}
            {...propsForm}
          />

          <SubHeading color="n400" noMargin>
            É necessário a validação do documento para ter acesso exclusivo a
            área profissional do Programa de Fidelidade da Leroy Merlin. Após o
            envio, a aprovação ocorre em até 4 dias úteis. Você também pode
            enviar o documento posteriormente.
          </SubHeading>

          <Row>
            <Col size={{ untilKilo: 2 }}>
              <Button
                size="kilo"
                type="button"
                variant="outline"
                onClick={onCancel}
                isDisabled={loading}
                isStretch
              >
                Enviar depois
              </Button>
            </Col>
            <Col size={{ untilKilo: 2 }}>
              <Button size="kilo" type="submit" isLoading={loading} isStretch>
                Enviar
              </Button>
            </Col>
          </Row>
        </Stack>
      </form>
    </S.Wrapper>
  )
}

Form.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func
}

export default Form
