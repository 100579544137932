import DOMPurify from 'dompurify'

import * as S from './styled'

const Markdown = ({ content, type }) => (
  <S.Markdown
    type={type}
    target="markdown"
    dangerouslySetInnerHTML={{
      __html: DOMPurify.sanitize(content, { ADD_TAGS: ['iframe'] })
    }}
  />
)

export default Markdown
