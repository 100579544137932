import request from 'checkout/utils/axios'

const getAssociateds = async () => {
  const response = await request
    .get('/carrinho/crosses')
    .then(({ data }) => data)
    .catch(err => {
      throw err.response
    })

  return response
}

export default getAssociateds
