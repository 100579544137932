import { css } from '@emotion/react'
import styled from '@emotion/styled'

const baseStyles = ({ theme }) => css`
  label: breadcrumb__list__item;

  display: flex;
  justify-content: center;
  align-items: center;

  color: ${theme.colors.p600};

  margin: 0;

  &:last-of-type {
    color: ${theme.colors.n500};
  }

  & > p {
    label: breadcrumb__list__title;

    max-width: 140px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  & > a:hover {
    text-decoration: underline;
  }

  & > svg {
    label: breadcrumb__list__separator;

    color: ${theme.colors.n300};
    margin: 0 ${theme.spacings.byte};

    ${theme.media.mega} {
      margin: 0 ${theme.spacings.mega};
    }
  }
`

export const Item = styled('span')(baseStyles)
