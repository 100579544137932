import axios, { AxiosResponse } from 'axios'

import { IS_DEV } from 'newCheckout/constants'

import { enableMockCommands } from './mocks/mock'

const instance = axios.create()

if (IS_DEV) {
  enableMockCommands(instance)
}

const axiosResponseToStoreResponse = <T>(axiosResponse: AxiosResponse<T>) => {
  return {
    status: axiosResponse.status,
    data: axiosResponse.data,
    headers: axiosResponse.headers
  }
}

export default instance
export { axiosResponseToStoreResponse }
