export const ArrowLeft = () => {
  return (
    <svg
      width="9"
      height="14"
      viewBox="0 0 9 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8 1L2 6.99983L8 13" stroke="#222020" strokeWidth="2" />
    </svg>
  )
}
