export const CreditCard = () => (
  <svg
    width="30"
    height="20"
    viewBox="0 0 30 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="30" height="20" rx="4" fill="#D3D2D6" />
    <rect
      opacity="0.7"
      x="3"
      y="4"
      width="24"
      height="4"
      rx="0.5"
      fill="#222020"
    />
    <rect
      opacity="0.7"
      x="3"
      y="14.1694"
      width="3"
      height="1"
      rx="0.15"
      fill="#222020"
    />
    <rect
      opacity="0.7"
      x="7.00586"
      y="14.1694"
      width="3"
      height="1"
      rx="0.15"
      fill="#222020"
    />
    <rect
      opacity="0.7"
      x="11.0117"
      y="14.1694"
      width="3"
      height="1"
      rx="0.15"
      fill="#222020"
    />
    <path
      opacity="0.7"
      d="M23.6515 13.32C23.8516 13.0726 24.2289 13.0726 24.429 13.32L26.3418 15.6849C26.6062 16.0118 26.3735 16.4993 25.953 16.4993H22.1276C21.7071 16.4993 21.4744 16.0118 21.7388 15.6849L23.6515 13.32Z"
      fill="#222020"
    />
  </svg>
)
