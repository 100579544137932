import { Fragment, useState, useContext, useEffect, useRef } from 'react'
import { useModal } from '@leroy-merlin-br/backyard-react'
import { LocationOutline } from '@backyard-ui/icons'

import emitter from 'scripts/utils/emitter'

import { HeaderContext } from 'site/Header/contexts/HeaderContext'

import { LocationPopper } from './components'
import { LocationForm } from './index'
import { WrapperLocationInfo } from './styled'

const LocationInfo = () => {
  const [selectedRegionName, setSelectedRegionName] = useState('')

  const contextData = useContext(HeaderContext)

  const {
    isAutomaticLocationEnabled,
    shouldShowAutoLocationPopper,
    userResources: {
      region,
      selectedRegionName: userResourcesRegionName,
      context
    }
  } = contextData

  // Only users on ecommerce context can change its region
  // using this feature.
  const isOnEcommerceContext = context !== 'assisted-sale'

  const pathname = window.location.pathname
  const [isRegionSelectorModalOpen, setRegionSelectorModal] = useState(false)
  const [isContextualized, setUserContext] = useState(false)

  const { setModal } = useModal()

  const handleClick = () => {
    if (!isOnEcommerceContext) {
      return
    }

    setRegionSelectorModal(true)
    setUserContext(true)
  }

  useEffect(() => {
    if (pathname === '/localizacao' && !isRegionSelectorModalOpen) {
      setRegionSelectorModal(true)
    }
  }, [pathname, isRegionSelectorModalOpen])

  useEffect(() => {
    isRegionSelectorModalOpen &&
      setModal({
        onClose: () => setRegionSelectorModal(false),
        children: ({ onClose }) => (
          <LocationForm showCloseButton={isContextualized} onClose={onClose} />
        )
      })
  }, [isContextualized, isRegionSelectorModalOpen, setModal])

  useEffect(() => {
    emitter.on('cart:region-changed', data =>
      setSelectedRegionName(data.regionName)
    )
  }, [])

  useEffect(() => {
    setSelectedRegionName(userResourcesRegionName || '')
  }, [userResourcesRegionName])

  const renderLocation = () => {
    if (!region) {
      return 'Onde você está?'
    }

    return (
      <WrapperLocationInfo>
        <LocationOutline height={12} width={12} />
        <span>{selectedRegionName}</span>
      </WrapperLocationInfo>
    )
  }

  const locationClass = isOnEcommerceContext
    ? 'color-primary location-info'
    : 'color-primary'

  return (
    <Fragment>
      <span className={locationClass} onClick={handleClick}>
        {renderLocation()}
      </span>
      {isAutomaticLocationEnabled && (
        <LocationPopper
          region={selectedRegionName}
          isVisible={shouldShowAutoLocationPopper}
          onChange={handleClick}
        />
      )}
    </Fragment>
  )
}

export default LocationInfo
