export const BASE_URL =
  ((window as any).env && (window as any).env.baseHost) || ''

export const endpoints = {
  addProduct: () => `${BASE_URL}/api/v1/cart`,
  applyCoupon: () => `${BASE_URL}/api/v1/cart/coupon`,
  removeCoupon: () => `${BASE_URL}/api/v1/cart/coupon`,
  getSummary: (cartId: string) => `${BASE_URL}/api/v3/carts/${cartId}/summary`,
  getAvailableVouchersAndCoupons: (cartId: string) =>
    `${BASE_URL}/api/v3/carts/${cartId}/available-vouchers-and-coupons`
}
