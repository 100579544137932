import { css } from '@emotion/react'

export const COLOR_MAP = {
  default: {
    color: 'n900',
    background: 'n100'
  },
  success: {
    color: 'p900',
    background: 'p100'
  },
  danger: {
    color: 'r900',
    background: 'r100'
  },
  warning: {
    color: 'y900',
    background: 'y100'
  },
  loyalty: {
    color: 'white',
    background: 'g1'
  }
}

export const tagStyles = () => css`
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:disabled,
  &[disabled] {
    pointer-events: none;
    box-shadow: none;
    opacity: 0.5;
  }

  &::-moz-focus-inner {
    border: 0;
  }

  svg {
    display: inline-block;
    vertical-align: middle;
    margin-right: 4px;
  }
`

export const iconColorStyles = ({ theme, color, disabled }) => {
  const currentColor = disabled ? COLOR_MAP.default : COLOR_MAP[color]

  return css`
    color: ${theme.colors[currentColor.color]};
  `
}

export const deleteStyles = () => css`
  border: 0;
  outline: none;
  padding: 0 4px;
  padding-right: 0;
  margin-left: 8px;
  display: inline-flex;
`
