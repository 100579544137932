import axios, { AxiosResponse } from 'axios'

import { api } from './api'

export type Contact = {
  contact: string
  hash: string
  type: string
}

type OTPVerify = {
  data: {
    message: string
    status: string
  }
}

type NewPasswordSendPayloadType = {
  fiscalId: string
  code: string
  password: string
  password_confirmation: string
}

export const getContact = async (
  fiscalId: string,
  recaptchaToken: string | null
) => {
  try {
    const { data } = await api.get<{ data: Contact[] }>(
      `/otp/contacts/${fiscalId}`,
      {
        headers: { 'G-Recaptcha': recaptchaToken }
      }
    )

    return data
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error.response
    } else {
      throw error
    }
  }
}

export const sendOTPCode = async (
  fiscalId: string,
  hash: string,
  type: string,
  context: string
) => {
  try {
    const response: AxiosResponse<OTPVerify> = await api.post(
      '/otp/send-code',
      {
        fiscalId,
        hash,
        type,
        context
      }
    )

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error.response
    } else {
      throw error
    }
  }
}

export const sendOTPVerify = async (fiscalId: string, code: string, context: string) => {
  try {
    const response: AxiosResponse<OTPVerify> = await api.post('/otp/verify', {
      fiscalId,
      code,
      context
    })

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error.response
    } else {
      throw error
    }
  }
}

export const sendNewPassword = async (payload: NewPasswordSendPayloadType) => {
  try {
    const response: AxiosResponse<OTPVerify> = await api.post(
      '/auth/reset',
      payload
    )

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error.response
    } else {
      throw error
    }
  }
}
