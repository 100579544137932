import styled from '@emotion/styled'
import { css } from '@emotion/react'

const imgStyles = ({ theme }) => css`
  label: image;

  display: block;
  height: 156px;
  margin: ${theme.spacings.tera} auto;
  margin-top: 0;
  width: 78px;
`

export const Img = styled('img')(imgStyles)

const buttonContentStyles = () => css`
  label: button_container;

  display: flex;
  justify-content: center;
`
export const ButtonContent = styled('div')(buttonContentStyles)
