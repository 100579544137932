/**
 * Code to be used if User is not enrolled when sending LoyaltyProgram
 * information to our integrations (BUC/IDB/etc)
 */
export const NOT_ENROLLED = 0

/**
 * User is active on Loyalty Program
 */
export const ACTIVE = 1

/**
 * User opted-in on the Loyalty Program via the physical store and need to finish the enrollment
 */
export const HAS_INTEREST = 2

/**
 * User has opted-out from the Loyalty Program
 */
export const INACTIVE = 3

/**
 * User Pro Executor is active on Loyalty Program
 */
export const PRO_EXECUTOR_ACTIVE = 4

/**
 * User Pro Executor has opted-out from the Loyalty Program
 */
export const PRO_EXECUTOR_INACTIVE = 6

/**
 * User Pro EAD is active on Loyalty Program
 */
export const PRO_EAD_ACTIVE = 7

/**
 * User Pro EAD opted-in on the Loyalty Program and waiting for documentation  validation
 */
export const PRO_EAD_WAITING_DOCUMENTATION_VALIDATION = 8

/**
 * User Pro EAD has opted-out from the Loyalty Program
 */
export const PRO_EAD_INACTIVE = 9

/**
 * User Pro EAD opted-in on the Loyalty Program and need to send documentation for validation
 */
export const PRO_EAD_WITHOUT_DOCUMENTATION = 10

/**
 * User Pro EAD opted-in on the Loyalty Program but the documentation was denied
 */
export const PRO_EAD_DOCUMENTATION_DENIED = 11

export const PRO_EAD_ACTIVE_STATUS = [
  PRO_EAD_ACTIVE,
  PRO_EAD_WAITING_DOCUMENTATION_VALIDATION,
  PRO_EAD_WITHOUT_DOCUMENTATION,
  PRO_EAD_DOCUMENTATION_DENIED
]

export const ACTIVE_STATUS = [
  ...PRO_EAD_ACTIVE_STATUS,
  ACTIVE,
  PRO_EXECUTOR_ACTIVE
]
