/**
 * Responsible to require specific components based on the provided [data-component] of the selection.
 */
import $ from 'jquery'

/**
 * Wraps the element into a $ selection, then tries to split the [data-component] into an array. For each item on the [data-component] array, a new component is attached to the $component, with the usage of @method data from $.
 * @function loader
 * @param    {element} element The element to be prepared
 */
function loader (element) {
  const $element = $(element)
  const components = $element.attr('data-component')

  if (components) {
    components.split(' ').forEach((component) => {
      if (process.env.NODE_ENV !== 'production') {
        return instance(component, $element)
      }

      try {
        instance(component, $element)
      } catch (e) {
        console.error('loader:', component, 'not loaded')
      }
    })
  }
}

function instance (component, $element) {
  require(`scripts/components/${component}`).default($element)
}

/**
 * Uses @method forEach on the selection, considering it might be a multiple one, then runs the @function loader on each item of the selection.
 * @param  {element} $el An element selection (Usually a $ selector)
 * @return {element}     The provided $ selection
 */
export default ($element) => {
  Array.prototype.forEach.call($element, loader)

  return $element
}
