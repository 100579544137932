import { css } from '@emotion/react'
import styled from '@emotion/styled'

const contentStyles = ({ theme }) => css`
  label: content;

  overflow-y: auto;
`

const fixedHeightContentStyles = ({ fixedHeight, theme }) =>
  fixedHeight &&
  css`
    label: content--fixed-height;

    ${theme.media.giga} {
      max-height: 128px;
    }
  `

export const Content = styled('div')(contentStyles, fixedHeightContentStyles)
