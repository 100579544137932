import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { Theme } from 'newCheckout/types/Theme'

const containerStyles = ({ theme }: Theme) => css`
  label: container;

  display: flex;
  flex-direction: column;
  gap: ${theme.spacings.kilo};
`

export const Container = styled('div')(containerStyles)

const storeCardTitle = ({ theme }: Theme) => css`
  label: store-card__title;

  display: flex;
  gap: ${theme.spacings.byte};
  justify-content: space-between;
  align-items: flex-start;

  & > div {
    flex: none;
  }

  ${theme.media.kilo} {
    gap: ${theme.spacings.giga};
  }
`
export const StoreCardTitle = styled('div')(storeCardTitle)
