import { createAsyncThunk, createSlice, Dispatch } from '@reduxjs/toolkit'

import { AsyncRequest } from 'newCheckout/types/AsyncRequest'
import { Cart } from 'newCheckout/types/Shipments'
import { shipmentApi } from 'newCheckout/api'
import { getProductsFromShipments } from 'newCheckout/helpers'

import { store } from './index'
import { checkServices } from './service'

type ErrorType = {
  needReload: boolean
  message: string | null
  region: string | null
}
const defaultError: ErrorType = {
  needReload: false,
  message: null,
  region: null
}

const initialState: AsyncRequest<Cart, ErrorType> = {
  status: 'init',
  error: defaultError,
  requestCode: null,
  data: {
    id: '',
    subTotal: 0,
    servicesSubTotal: 0,
    discountTotal: 0,
    freightCost: 0,
    canBePurchased: false,
    total: 0,
    uniqueProductsCount: 0,
    zipCode: '',
    shippings: [],
    services: []
  }
}

const getShipment = createAsyncThunk(
  'Shipment/getShipment',
  async (getShipment: shipmentApi.getShipmentType, thunkAPI) => await shipmentApi.getShipment(getShipment)
)

const getShipmentAndServices = (params: shipmentApi.getShipmentType) => async (
  dispatch: Dispatch<any>
) => {
  await dispatch(getShipment(params))

  const productIds = getProductsFromShipments(
    store.getState().shipments.data
  ).map(products => products.productId)

  if (!productIds.length) return

  await dispatch(
    checkServices({ productIds: productIds, regionName: 'grande_sao_paulo' })
  )
}

export const shipmentSlice = createSlice({
  name: 'Shipment',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getShipment.pending, state => {
      state.status = 'loading'
      state.error = defaultError
    })
    builder.addCase(getShipment.fulfilled, (state, action) => {
      state.status = 'done'
      state.requestCode = action.payload.status
      state.data = { ...state.data, ...action.payload.data }

      if (action.payload.data?.message) {
        state.error.message = action.payload.data?.message
      }
      if (action.payload.data?.needReload) {
        state.error.needReload = action.payload.data?.needReload
      }
      if (action.payload.data?.region) {
        state.error.region = action.payload.data?.region
      }
    })
    builder.addCase(getShipment.rejected, (state, action) => {
      state.status = 'error'
      state.requestCode = null
      state.error.needReload = true
    })
  }
})

export { getShipment, getShipmentAndServices }

export default shipmentSlice.reducer
