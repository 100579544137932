import { Button, Stack, Text } from '@leroy-merlin-br/backyard-react'
import { ChangeEvent, FormEvent, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory, useLocation } from 'react-router-dom'
import { handlePhoneFormat, removeNonNumericCharacters } from 'user/utils'

import { NumberField } from 'shared/components'

import * as validator from 'utils/validators/validators'

import { MobileLayout } from '../../components/MobileLayout'
import { useMyAccountContactContext } from '../../context/my-account-contact-context'
import * as S from './styled'

type FormValues = {
  cellphone: string
}

function useQuery () {
  const { search } = useLocation()

  return useMemo(() => new URLSearchParams(search), [search])
}

const EditMainCell = () => {
  const query = useQuery()

  const origin = query.get('origin')
  const identityCode = query.get('identityCode')
  const mainCellphone = query.get('mainCellphone')
  const newMainCellphone = query.get('newMainCellphone')
  const fiscalId = query.get('fiscalId')

  const { contextData } = useMyAccountContactContext()

  const [fieldValue, setFieldValue] = useState(newMainCellphone || mainCellphone)

  const [loading, setLoading] = useState(false)

  const {
    control,
    formState: { errors, isValid }
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      cellphone: ''
    }
  })

  const history = useHistory()

  const onSubmit = (event: FormEvent<EventTarget>) => {
    event.preventDefault()

    setLoading(true)

    history.push({
      pathname: '/validar-celular',
      search: `?origin=${origin}&identityCode=${identityCode}&mainCellphone=${mainCellphone}&newMainCellphone=${fieldValue}&fiscalId=${fiscalId}`
    })
  }

  return (
    <MobileLayout description="Contatos">
      <Text>
        Edite seu celular principal
      </Text>

      <Stack space="giga">
        <form onSubmit={onSubmit}>
          <NumberField
            isDisabled={loading}
            name="cellphone"
            label="Número de celular"
            rules={{
              required: 'Insira seu Celular',
              validate: (value: string) => (validator.isCellPhone(value) && validator.isCellPhoneDigit(removeNonNumericCharacters(value))) || 'Celular inválido'
            }}
            control={control}
            state={Boolean(errors.cellphone) && 'invalid'}
            hint={errors.cellphone?.message}
            format={handlePhoneFormat}
            value={fieldValue}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              setFieldValue(event.target.value)
            }}
          />

          <S.ButtonWrapper>
            <Button
              isStretch
              isLoading={loading}
              type="submit"
              isDisabled={!isValid || contextData.mainCellphone === fieldValue}
            >
              Salvar e validar telefone
            </Button>
          </S.ButtonWrapper>
        </form>
      </Stack>

      <Button
        isStretch
        isDisabled={loading}
        appearance="critical"
        variant="ghost"
        onClick={() => history.push('/meus-contatos')}
      >
        Cancelar
      </Button>

    </MobileLayout>
  )
}

export default EditMainCell
