import { useState, useEffect, useCallback, Dispatch } from 'react'
import Slider from 'react-slick'
import PhotoSwipe from 'photoswipe'
import PhotoSwipeUIDefault from 'photoswipe/dist/photoswipe-ui-default'

import * as dataLayer from 'scripts/utils/data-layer'

import { YoutubePlayer } from 'shared/components/YoutubePlayer'

import { SketchfabModel } from './components'
import { CarouselItem } from '../../../types'
import { ZOOM_OPTIONS, ZOOM_DIMENSIONS } from '../constants'
import * as S from './styled'

type Props = {
  productId: number
  carouselItems: CarouselItem[]
  setCurrentCarouselItem: Dispatch<Slider>
  itemsNavigatorSlider: Slider | null
}

type ZoomGallery = {
  src: string
  title: string
  w: number
  h: number
}

const CurrentCarouselItem = ({
  productId,
  carouselItems,
  setCurrentCarouselItem,
  itemsNavigatorSlider
}: Props) => {
  const [nextIndex, setNextIndex] = useState(0)
  const [zoomGalleryItems, setZoomGalleryItems] = useState<Array<ZoomGallery>>(
    []
  )

  const buildZoomGalleryItems = useCallback(() => {
    const zoomGallery = carouselItems.reduce(
      (collector: Array<ZoomGallery>, item) => {
        if (item.type === 'image') {
          return [
            ...collector,
            {
              src: item.zoom,
              title: item.alt,
              w: ZOOM_DIMENSIONS.widht,
              h: ZOOM_DIMENSIONS.height
            }
          ]
        }
        return collector
      },
      []
    )

    setZoomGalleryItems(zoomGallery)
  }, [setZoomGalleryItems, carouselItems])

  const onImageClick = (index: number) => {
    const options = {
      ...ZOOM_OPTIONS.options,
      index
    }

    const plugin = new PhotoSwipe(
      document.querySelector(ZOOM_OPTIONS.container) as HTMLElement,
      PhotoSwipeUIDefault,
      zoomGalleryItems,
      options
    )

    plugin.init()
  }

  useEffect(buildZoomGalleryItems, [buildZoomGalleryItems])

  return (
    <S.Wrapper id="current-carousel">
      <Slider
        lazyLoad={'ondemand'}
        infinite
        speed={500}
        className="current-carousel-item"
        draggable={false}
        arrows={false}
        asNavFor={itemsNavigatorSlider || undefined}
        beforeChange={(_oldIndex, index) => {
          setNextIndex(index)
        }}
        ref={slider => slider && setCurrentCarouselItem(slider)}
      >
        {carouselItems?.map((item: CarouselItem, index) => {
          if (item.type === 'sketchFab') {
            return (
              <S.ImageWrapper key={item.id} isStrech>
                <SketchfabModel
                  modelId={item.id}
                  placeholder={item.main}
                  productId={productId}
                />
              </S.ImageWrapper>
            )
          }

          if (item.type === 'video') {
            return (
              <S.ImageWrapper key={item.youtubeId} isStrech>
                <YoutubePlayer
                  alt={item.alt}
                  thumbUrl={`https://i.ytimg.com/vi/${item.youtubeId}/hqdefault.jpg`}
                  videoId={item.youtubeId}
                  index={index}
                  isCurrentSlide={nextIndex === index}
                />
              </S.ImageWrapper>
            )
          }

          if (item.type === 'image') {
            return (
              <S.ImageWrapper key={index} title="Zoom">
                <img
                  alt={item.alt}
                  src={item.main}
                  onClick={() => {
                    onImageClick(index)
                    dataLayer.midiasCarouselSuperZoomClick(productId)
                  }}
                />
              </S.ImageWrapper>
            )
          }
        })}
      </Slider>
    </S.Wrapper>
  )
}

export default CurrentCarouselItem
