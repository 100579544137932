import styled from '@emotion/styled'
import { css } from '@emotion/react'

import type { Theme } from 'scripts/react-components/themes/Theme'

const wrapperModalStyles = ({ theme }: Theme) => css`
  label: modal-app__wrapper;

  display: grid;

  width: 100%;
  min-height: 220px;
  top: 50%;
  left: 50%;
  position: fixed;
  transform: translate(-50%, -50%);

  background: ${theme.colors.white};
  border-radius: ${theme.borderRadius.giga} ${theme.borderRadius.giga} 0px 0px;
  align-items: center;
  padding: ${theme.spacings.mega};
`
const titleStyles = ({ theme }: Theme) => css`
  font-size:  ${theme.typography.text.mega.fontSize};
  line-height: ${theme.typography.text.mega.lineHeight};
  font-weight: ${theme.font.weight.semibold};
  margin: 0 auto;
`

const subTitleStyles = ({ theme }: Theme) => css`
  font-size: ${theme.typography.text.kilo.fontSize};
  line-height: ${theme.typography.text.kilo.lineHeight};
  font-weight:  ${theme.font.weight.regular};
  margin: 0 auto;
`

const textBodyStyles = ({ theme }: Theme) => css`
  label: modal-app__text-body;
  font-size: ${theme.typography.text.mega.fontSize};
  a {
    text-decoration: underline;
  }
`

const bodyStyles = ({ theme }: Theme) => css`
  label: modal-app__wrapper-body;
  padding: ${theme.spacings.giga};
`
const headerStyles = () => css`
  display: inline-grid;
`

const iconStyles = () => css`
  margin: 0 auto;
`

const closeButtonStyles = ({ theme }: Theme) => css`
  label: modal__close-button;
  position: absolute;
  top: ${theme.spacings.mega};
  right: ${theme.spacings.mega};

  cursor: pointer;

  svg {
    width: 20px;
    height: 20px;
    path {
      stroke-width: 1px;
      stroke: ${theme.colors.black};
    }
  }
`

const listStyles = ({ theme }: Theme) => css`
  list-style: inside;
  margin: ${theme.spacings.mega} 0px;
`
export const WrapperModal = styled('div')(wrapperModalStyles)
export const Body = styled('div')(bodyStyles)
export const Title = styled('label')(titleStyles)
export const SubTitle = styled('label')(subTitleStyles)
export const TextBody = styled('div')(textBodyStyles)
export const Header = styled('div')(headerStyles)
export const List = styled('ul')(listStyles)
export const Icon = styled('div')(iconStyles)
export const CloseButton = styled('div')(closeButtonStyles)
