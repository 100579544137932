import PropTypes from 'prop-types'
import { Route, withRouter } from 'react-router-dom'
import { useSignupContext } from 'user/signup/context/signup-context'

import RedirectOut from './RedirectOut'
function PrivateRoute ({ component: Component, ...rest }) {
  const { isLoggedIn } = useSignupContext()

  return (
    <Route
      {...rest}
      render={props =>
        isLoggedIn ? (
          <RedirectOut to="/minha-conta" />
        ) : (
          <Component {...props} {...rest} />
        )
      }
    />
  )
}

PrivateRoute.propTypes = {
  component: PropTypes.elementType
}

export default withRouter(PrivateRoute)
