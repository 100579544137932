import { Fragment } from 'react'

import * as CouponUI from 'frontendCheckout/ui/v1/CouponAndVoucher'
import { formatters } from 'frontendCheckout/utils'

import { VoucherItem } from './VoucherItem'
import { Points } from './Points'

const moneyVoucherExpirationDate = (expiration?: string) => {
  if (!expiration) return undefined

  const { day, month } = formatters.dateToObject(expiration)

  return `Use até ${day} de ${month}`
}

const pointsExpirationText = (expiration?: string) => {
  if (!expiration) return undefined

  const { day, month } = formatters.dateToObject(expiration)

  return `Válidos até ${day} de ${month}`
}

const percentageVoucherExpirationDate = (expiration?: number) => {
  if (!expiration) return undefined

  return expiration
    ? `Válido até ${expiration} dia${expiration > 1 ? 's' : ''}`
    : undefined
}

type MoneyVoucher = {
  title: string
  id: string
  expiration?: string
  active: boolean
  points?: number
}

type VoucherProps = {
  showLoad: boolean
  points: number
  pointsExpiration: string
  moneyVoucherList: MoneyVoucher[]
  percentageVoucherList: { title: string; id: string; expiration?: number }[]
  moneyVoucherClick: (id: string, active: boolean, points?: number) => void
  percentageVoucherClick: (id: string) => void
  appliedMoneyVouchers: string[]
  appliedPercentageVouchers: string[]
  concludeButtonClick: () => void
}

export const VoucherModalContent = ({
  moneyVoucherList,
  percentageVoucherList,
  showLoad,
  appliedMoneyVouchers,
  appliedPercentageVouchers,
  concludeButtonClick,
  moneyVoucherClick,
  percentageVoucherClick,
  points,
  pointsExpiration
}: VoucherProps) => {
  const isSelectableMoneyVoucher = (
    id: string,
    active: boolean,
    voucherPoints?: number
  ) => {
    if (active) return true
    if (!voucherPoints) return true
    if (voucherPoints <= points) return true

    return false
  }

  const moneyVouchersToShow = moneyVoucherList.flatMap((voucher) => [
    voucher,
    ...appliedMoneyVouchers
      .filter(
        (appliedVoucherId) => appliedVoucherId === voucher.id && !voucher.active
      )
      .map(() => ({ ...voucher, active: true, id: voucher.id }))
  ])

  return (
    <>
      <Points
        points={points ?? 0}
        expiration={
          points > 0 ? pointsExpirationText(pointsExpiration) : undefined
        }
      />
      {moneyVoucherList.length > 0 && <CouponUI.Separator text="Cashback" />}
      <CouponUI.Box>
        {moneyVouchersToShow.map(
          ({ id, expiration, points, title, active }, index) => (
            <Fragment key={index}>
              <VoucherItem
                title={title}
                isSelected={active && appliedMoneyVouchers.includes(id)}
                isSelectable={isSelectableMoneyVoucher(id, active, points)}
                expiration={moneyVoucherExpirationDate(expiration)}
                points={active ? 0 : points}
                onClick={() =>
                  moneyVoucherClick(id, active, active ? 0 : points)
                }
              />
            </Fragment>
          )
        )}
        {percentageVoucherList.length > 0 && (
          <CouponUI.Separator text="Voucher" />
        )}
        {percentageVoucherList.map((percentageVoucher, key) => (
          <Fragment key={percentageVoucher.id}>
            <VoucherItem
              title={percentageVoucher.title}
              isSelected={appliedPercentageVouchers.includes(
                percentageVoucher.id
              )}
              isSelectable={true}
              expiration={percentageVoucherExpirationDate(
                percentageVoucher.expiration
              )}
              onClick={() => percentageVoucherClick(percentageVoucher.id)}
            />
          </Fragment>
        ))}
        {!showLoad &&
          moneyVoucherList.length <= 0 &&
          percentageVoucherList.length <= 0 && (
            <CouponUI.NotFound
              titleMessage="Nenhum voucher encontrado"
              detailMessage=""
            />
            // eslint-disable-next-line indent
          )}
      </CouponUI.Box>
      <CouponUI.Button color="default" onClick={concludeButtonClick}>
        Concluído
      </CouponUI.Button>
    </>
  )
}
