import PropTypes from 'prop-types'
import { ModalProvider, useModal } from '@leroy-merlin-br/backyard-react'

import * as S from './styled'
import { StockInfo } from './StockInfo'

const StockContent = ({ stock, unit, id }) => {
  const { store, warehouse } = stock
  const { setModal } = useModal()

  const showStockInfo = () => {
    setModal({
      hasScroll: true,
      size: 'giga',
      children: ({ onClose }) => <StockInfo id={id} onClose={onClose} />
    })
  }

  const productUnit = unit !== 'cada' ? unit : 'peças'
  const isEmptyStockInfo = !stock || stock.store === undefined

  if (isEmptyStockInfo) {
    return <S.Wrapper className="assisted-sale-product-stock" />
  }

  return (
    <S.Wrapper onClick={showStockInfo} className="assisted-sale-product-stock">
      <S.Item>
        {parseInt(store, 10)} {productUnit} na loja
      </S.Item>
      <S.Item>
        {parseInt(warehouse, 10)} {productUnit} no CD
      </S.Item>
    </S.Wrapper>
  )
}

const StockContentWithProvider = props => (
  <ModalProvider>
    <StockContent {...props} />
  </ModalProvider>
)

export default StockContentWithProvider

StockContent.propTypes = {
  stock: PropTypes.shape({
    store: PropTypes.number,
    warehouse: PropTypes.number
  }),
  unit: PropTypes.string,
  id: PropTypes.number
}
