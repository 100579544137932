import {
  Card,
  Stack,
  Heading
} from '@leroy-merlin-br/backyard-react'

import * as S from './styled'
import { ContentHeaderVisualType } from './type'

const ContentHeader = ({ title, content }: ContentHeaderVisualType) => (
    <Card shadow="double">
      <Stack space="kilo">
        <S.Title>
          <Heading color="n900" size="tera">{title}</Heading>
        </S.Title>
        {content}
      </Stack>
    </Card>
)

export default ContentHeader
