import {
  Text,
  Button,
  Icon,
  Inline
} from '@leroy-merlin-br/backyard-react'
import { CheckmarkCircleOutline, ClockOutline } from '@backyard-ui/icons'

import { CouponData } from 'lmcv/components/CouponModal/types'
import {
  ButtonText,
  InfoText
} from 'lmcv/components/CouponModal/CouponModalCommon/types'

import * as S from './styled'

type FooterProps = {
  couponData: CouponData
  infoText: InfoText
  buttonText: ButtonText
  ActiveVoucherButton: React.FC
}

const Footer = ({
  couponData,
  infoText,
  buttonText,
  ActiveVoucherButton
}: FooterProps) => {
  return (
    <S.Wrapper isActive={couponData.isActive}>
      {couponData.isActive ? (
        <S.ActiveLabel>
          <S.ActiveStatus>
            <Icon as={CheckmarkCircleOutline} size="mega" />
            <Text size="mega" color="white" noMargin>
            {buttonText.active}
          </Text>
          </S.ActiveStatus>
          <Text size="mega" color="white" noMargin>
            Válido até dia {infoText.expire}
          </Text>
        </S.ActiveLabel>
      ) : (
        <ActiveVoucherButton />
      )}

      {infoText.remaining && !couponData.isActive && (
        <S.RemainingText>
          <Inline space="bit" alignY="center" alignX="center">
            <Icon as={ClockOutline} size="kilo" />
            <Text size="kilo" align="center" noMargin>
              {infoText.remaining}
            </Text>
          </Inline>
        </S.RemainingText>
      )}
    </S.Wrapper>
  )
}

export default Footer
