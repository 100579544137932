import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { StyleProps } from '../../types'

const infoItemStyles = ({ theme }: StyleProps) => css`
  label: info-item;

  h3 {
    margin-bottom: ${theme.spacings.byte};
  }
`

export const InfoItem = styled('div')(infoItemStyles)
