import request from './axios'
import { API_ENDPOINTS } from './constants'

export const getRegions = async () => {
  const response = await request
    .get(API_ENDPOINTS.regions)
    .then(({ data }) => data)
    .catch(err => {
      throw err
    })

  return response
}
