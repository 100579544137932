import { Text } from '@leroy-merlin-br/backyard-react'

import { SameDayDeliveryInfo } from 'shared/components'

import formatPriceToBRL from 'utils/formatPriceToBRL'

import * as S from './styled'

const modalityMessage = ({
  message,
  cost,
  deadlineMessage,
  deadlinePrefix,
  type,
  expressDeliveryDay
}) => {
  const hasExpress = type === 'express' && expressDeliveryDay
  if (type === 'override') {
    return (
      <Text as="span" size="kilo" noMargin>
        {message}
      </Text>
    )
  }

  if (type === 'scheduled') {
    return (
      <Text as="span" size="kilo" noMargin>
        Agende para receber por{' '}
        <Text as="span" size="kilo" noMargin isBold className="cost">
          {formatPriceToBRL(cost)}
        </Text>
      </Text>
    )
  }

  if (hasExpress) {
    return (
      <SameDayDeliveryInfo
        deliveryPrice={cost}
        deliveryKey={expressDeliveryDay}
      />
    )
  }

  return (
    <Text as="span" size="kilo" noMargin>
      {deadlinePrefix}:{' '}
      <Text as="span" size="kilo" noMargin isBold>
        {deadlineMessage}
      </Text>{' '}
      por{' '}
      <Text as="span" size="kilo" noMargin isBold className="cost">
        {formatPriceToBRL(cost)}
      </Text>
    </Text>
  )
}

const sortModalitiesBySameDayDelivery = ({ type, expressDeliveryDay }) => {
  const isSameDayDelivery = type === 'express' && expressDeliveryDay

  if (isSameDayDelivery) {
    return -1
  }
  return 1
}

const ShipmentModalities = ({ modalities = [] }) => {
  return (
    <div>
      {modalities.sort(sortModalitiesBySameDayDelivery)?.map((modality, i) => (
        <S.DeadlineBox key={i}>{modalityMessage(modality)}</S.DeadlineBox>
      ))}
    </div>
  )
}

export default ShipmentModalities
