import { getAttribute } from 'scripts/react-components/entry-key/ui/Aside/Filters/Filters.utils'

export interface ToggleFilterProps {
  [key: string]: boolean
}

export const toggleFilter = (
  routeState: ToggleFilterProps,
  numberFilters: number,
  urlToConcatenate: string,
  region: string
) => {
  if (!routeState) {
    return urlToConcatenate
  }

  Object.keys(routeState).forEach(key => {
    const filter = getAttribute(region).toggle[key]
    let addFilter = true

    if (urlToConcatenate.includes(filter.filterName)) {
      addFilter = false
    } else if (numberFilters === 0) {
      const filterValue =
        filter.filterName === 'ofertas' ||
        filter.filterName === 'frete-gratis' ||
        filter.filterName === 'black-friday'
          ? ''
          : `/${filter.filterValue}`

      urlToConcatenate = `${urlToConcatenate}/${filter.filterName}${filterValue}`
    } else if (numberFilters === 1) {
      urlToConcatenate = `${urlToConcatenate}?filter[${filter.filterName}]=${filter.filterValue}`
    } else if (numberFilters > 1) {
      urlToConcatenate = `${urlToConcatenate}&filter[${filter.filterName}]=${filter.filterValue}`
    }

    if (addFilter) numberFilters++
  })

  return urlToConcatenate
}
