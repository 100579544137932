import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { styleHelpers } from '@leroy-merlin-br/backyard-react'

const wrapperStyles = ({ theme }) => css`
  label: wrapper;

  max-width: ${theme.grid.container.mega}rem;
`

const titleStyles = ({ theme }) => css`
  label: title;

  font-weight: ${theme.font.weight.semibold};

  ${styleHelpers.headingKilo(theme)};

  ${theme.media.mega} {
    ${styleHelpers.headingGiga(theme)};
  }
`

const productContainerStyles = ({ theme }) => css`
  label: product__container;

  display: flex;

  background: ${theme.colors.white};

  margin-bottom: ${theme.spacings.mega};
  padding: ${theme.spacings.byte} ${theme.spacings.byte} ${theme.spacings.kilo};

  ${theme.media.mega} {
    margin-bottom: 0;
  }
`

const productImageStyles = () => css`
  label: product__image;

  height: 100px;
  width: 100px;
`

const skusHeaderStyles = ({ theme }) => css`
  label: skus-header;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  grid-column-gap: ${theme.spacings.bit};

  justify-items: start;

  padding: ${theme.spacings.kilo} ${theme.spacings.byte};
`

export const Wrapper = styled('div')(wrapperStyles)
export const Title = styled('span')(titleStyles)
export const ProductContainer = styled('section')(productContainerStyles)
export const ProductImage = styled('img')(productImageStyles)
export const SkusHeader = styled('div')(skusHeaderStyles)
