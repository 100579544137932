import formatPriceToBRL from 'utils/formatPriceToBRL'

export const FormatPrice = ({ quantity, unit, price, extra = '' }) => {
  extra = extra ? ` ${extra}` : ''
  return `${quantity} ${unit} ${formatPriceToBRL(price)}${extra}`
}

export const PrefixPrice = ({ packaging, packagingCount }) => {
  const isBoxProduct = packaging => packaging !== 1
  const plural = packagingCount === 1 ? 'caixa' : 'caixas'
  return isBoxProduct(packaging) ? `${packagingCount} ${plural} | ` : ''
}
