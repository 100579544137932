import { css } from '@emotion/react'
import styled from '@emotion/styled'

const iconStyles = ({ theme }) => css`
  label: icon;

  width: 16px;
  height: 16px;
  margin-left: ${theme.spacings.bit};

  display: inline-flex;
  align-items: center;
  justify-content: center;

  color: ${theme.colors.n600};
  font-size: 10px;
  font-weight: 600;
  line-height: 12px;

  background-color: ${theme.colors.n100};
  border-radius: 100%;
  cursor: pointer;
`
export const IconWrapper = styled('div')(iconStyles)
