import { FunctionComponent } from 'react'
import { Stack, Text } from '@backyard-ui/core'

import styles from './BankslipContent.styles'

const BankslipContent: FunctionComponent = () => (
  <Stack direction="column" spacing="4">
    <Stack direction="column" spacing="2">
      <Text size="lg" color="green-600" weight="bold">
        Pagamento
      </Text>

      <ul className={styles().dotList()}>
        <Text size="md" asChild>
          <li>
            Em caso de boleto à vista, a confirmação de pagamento pode levar até
            3 dias úteis.
          </li>
        </Text>
        <Text size="md" asChild>
          <li>
            Em caso de não pagamento até a data de vencimento, realizaremos o
            cancelamento automático do pedido.
          </li>
        </Text>
        <Text size="md" asChild>
          <li>
            O boleto é válido somente para este pedido. Para repetir a compra
            você deve realizar um novo pedido.
          </li>
        </Text>
      </ul>
    </Stack>

    <Stack direction="column" spacing="2">
      <Text size="lg" color="green-600" weight="bold">
        Entrega
      </Text>

      <ul className={styles().dotList()}>
        <Text size="md" asChild>
          <li>
            O prazo de entrega dos pedidos com boleto começa a contar a partir
            da confirmação do pagamento pela instituição financeira.
          </li>
        </Text>
      </ul>
    </Stack>

    <Stack direction="column" spacing="2">
      <Text size="lg" color="green-600" weight="bold">
        Acesso
      </Text>

      <ul className={styles().dotList()}>
        <Text size="md" asChild>
          <li>
            Você poderá acessar o seu boleto através da página "Meus Pedidos".
          </li>
        </Text>
      </ul>
    </Stack>
  </Stack>
)

export default BankslipContent
