import { Fragment } from 'react'
import PropTypes from 'prop-types'
import { ChevronRight } from '@leroy-merlin-br/backyard-icons'
import { Text, Icon } from '@leroy-merlin-br/backyard-react'

import node from '../../utils/prop-types'
import * as S from './styled'

const Item = ({ node, isLastItem }) => {
  const categoryId = node.id || null

  return (
    <S.Item data-category-id={categoryId}>
      {isLastItem ? (
        <Text size="kilo" noMargin>
          {node.name}
        </Text>
      ) : (
        <Fragment>
          <a href={node.url}>
            <Text size="kilo" noMargin>
              {node.name}
            </Text>
          </a>
          <Icon as={ChevronRight} size="byte" />
        </Fragment>
      )}
    </S.Item>
  )
}

Item.propTypes = {
  node: PropTypes.shape({ ...node }).isRequired,
  isLastItem: PropTypes.bool.isRequired
}

export default Item
