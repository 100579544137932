import { Component } from 'react'

import emitter from 'scripts/utils/emitter'
import DEPARTMENT_NAMES from 'scripts/constants/departments'

import * as StoreMapService from '../services/storeMap'
import Spinner from './Spinner'

const FETCH_ERROR_TITLE = 'Erro ao Carregar Mapa'
const FETCH_ERROR_MESSAGE = 'Não foi possível carregar o mapa, por favor, tente novamente.'

class StoreMap extends Component {
  constructor (props) {
    super(props)

    this.state = {
      storeMap: ''
    }
  }

  componentDidMount () {
    this.fetchStoreMap()
  }

  componentDidUpdate (prevProps) {
    if (
      prevProps.storeCode === this.props.storeCode &&
      prevProps.section === this.props.section
    ) {
      return
    }

    this.fetchStoreMap()
  }

  handleFetchError = (message = null) => {
    this.props.closeMap()

    emitter.emit('stickyFeedback:error', {
      title: FETCH_ERROR_TITLE,
      content: message || FETCH_ERROR_MESSAGE
    })
  }

  fetchStoreMap = () => {
    const { storeCode } = this.props
    const section = parseInt(this.props.section)

    StoreMapService
      .getStoreMap(storeCode, section)
      .then(data => {
        if (!data.department_file_url) {
          this.handleFetchError(data.message)
          return
        }

        this.setState({
          storeMap: data.department_file_url
        })
      })
      .catch(() => this.handleFetchError())
  }

  getSectionName = () => {
    const section = parseInt(this.props.section)

    return this.props.sectionName || DEPARTMENT_NAMES[section]
  }

  render () {
    const { storeMap } = this.state
    const { closeMap, storeName, sectionName } = this.props

    if (!storeMap) {
      return (
        <div className="store-map-container">
          <div className="store-map-header">
            <a className="glyph glyph-arrow-right close-map" onClick={closeMap} />
          </div>
          <Spinner />
        </div>
      )
    }

    return (
      <div className="store-map-container">
        <div className="store-map-header">
          <a className="glyph glyph-arrow-right close-map" onClick={closeMap} />

          <span className="title">
            Este produto está na seção&nbsp;
            <span className="section-name">
              {this.getSectionName()}&nbsp;
            </span>
            na loja {storeName}
          </span>
        </div>

        <object data={storeMap} />
      </div>
    )
  }
}

export default StoreMap
