import { useCallback, MouseEvent } from 'react'
import { Card, Text } from '@backyard-ui/core'
import { HierarchicalMenu as HMenu } from 'react-instantsearch'

import { onFilterDataLayer } from 'scripts/react-components/entry-key/analytics/data-layer'

import styles from './HierarchicalMenu.styles'

function HierarchicalMenu () {
  const handleClick = useCallback((event: MouseEvent<HTMLDivElement>) => {
    const value = (event.target as HTMLDivElement).textContent ?? ''

    onFilterDataLayer({
      searchParam: 'hierarquicalMenu',
      filterType: {
        index: 0,
        value: 'hierarchicalCategories.lvl0'
      },
      filterElement: { index: 0, value }
    })
  }, [])

  return (
    <Card.Root>
      <Card.Header>
        <Text weight="bold" asChild>
          <span>Departamento</span>
        </Text>
      </Card.Header>
      <Card.Body>
        <HMenu
          classNames={{
            list: styles().list(),
            childList: styles().child(),
            item: styles().item(),
            selectedItem: styles().item({ selected: true }),
            link: styles().link(),
            label: styles().label(),
            count: styles().count(),
            showMore: styles().button(),
            disabledShowMore: styles().button({ disabled: true })
          }}
          attributes={[
            'hierarchicalCategories.lvl0',
            'hierarchicalCategories.lvl1',
            'hierarchicalCategories.lvl2',
            'hierarchicalCategories.lvl3'
          ]}
          limit={5}
          showMoreLimit={10}
          sortBy={['count:desc']}
          showMore
          translations={{
            showMoreButtonText ({ isShowingMore }) {
              return isShowingMore ? 'Ver menos' : 'Ver mais'
            }
          }}
          onClick={handleClick}
        />
      </Card.Body>
    </Card.Root>
  )
}

export default HierarchicalMenu
