import $ from 'jquery'
import Cookies from 'js-cookie'

import BaseComponent from 'scripts/components/base-component'
import getBreakpoint from 'scripts/utils/get-breakpoint'

const DEFAULTS = {
  cookieName: 'utrack',
  metaName: '[name="user-track"]',
  url: 'usertrack/api/_track'
}

class Track extends BaseComponent {
  static componentName = 'track'

  static emitterEvents = {
    'user-resources:done': 'onUserResourcesDone'
  }

  constructor (element, options = {}) {
    super(element)

    this.options = $.extend({}, DEFAULTS, options)
  }

  init () {
    this.checkUserTrack()
  }

  onUserResourcesDone (data) {
    if (!Cookies.get(this.options.cookieName) && data.email) {
      Cookies.set(this.options.cookieName, data.email, { expires: Infinity })
    }
  }

  checkUserTrack () {
    const $track = $(this.options.metaName)
    const email = Cookies.get(this.options.cookieName)

    if ($track.length && email) {
      const trackObject = {
        'properties[product]': $track.data('product'),
        'properties[category]': $track.data('category')
      }

      this.sendUserTrack(
        this.setURL($track.data('event'), email),
        trackObject
      )
    }
  }

  sendUserTrack (url, data) {
    $.get(url, data)
  }

  setURL (eventName, email) {
    return `${this.options.url}/${email}/${getBreakpoint()}/${eventName}`
  }
}

export default ($el) => new Track($el).init()

export { Track as Component }
