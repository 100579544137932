import { ClockOutline } from '@backyard-ui/icons'
import { Heading, Stack, Icon } from '@leroy-merlin-br/backyard-react'
import { useSearchHistory } from 'search/containers/QuickSearch/hooks'

import * as S from './styled'

const SearchHistory = ({ hasTerms }) => {
  const { searchHistory, storeSearchTerm } = useSearchHistory()

  if (searchHistory.length === 0) return null

  return (
    <S.Wrapper hasTerms={hasTerms}>
      <Stack space="bit">
        <Heading size="mega" noMargin>
          Buscas recentes
        </Heading>
        {searchHistory.map(({ term, searchPath }) => (
          <S.LinkWrapper key={searchPath} hasTerms={hasTerms}>
            <Icon as={ClockOutline} color="primary" size="kilo" />
            <a href={searchPath} onClick={ () => storeSearchTerm(term)} data-cy="search-history-term">
              {term}
            </a>
          </S.LinkWrapper>
        ))}
      </Stack>
    </S.Wrapper>
  )
}

export default SearchHistory
