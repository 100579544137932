import { Text, Button, Icon, useBreakpoint } from '@leroy-merlin-br/backyard-react'
import { LocationOutline } from '@backyard-ui/icons'

import * as S from './styled'

const POPOVER_DESCRIPTION =
  'Para acessar produtos e ofertas da sua região, identificamos que você está em '

const LocationContent = ({
  regionText = '',
  onConfirm = () => {},
  onChange = () => {}
}) => {
  const { breakpoint } = useBreakpoint()
  const isMobile = ['untilKilo', 'kilo', 'mega'].includes(breakpoint)

  return (
    <S.ContentWrapper>
      <S.DescriptionWrapper>
        {isMobile && <Icon as={LocationOutline} size="mega" />}
        <Text align="left" size="kilo" color={isMobile ? 'white' : 'n900'} noMargin>
          {POPOVER_DESCRIPTION}
          <S.Spotlight>{regionText + '.'}</S.Spotlight>
        </Text>
      </S.DescriptionWrapper>
      <S.ButtonWrapper isWhite={isMobile}>
        <Button
          target="btn-confirm"
          size="kilo"
          onClick={onConfirm}
          isStretch
        >
          Continuar
        </Button>
        <Button
          target="btn-change"
          size="kilo"
          variant="outline"
          onClick={onChange}
          isStretch
        >
          Alterar
        </Button>
      </S.ButtonWrapper>
    </S.ContentWrapper>
  )
}

export default LocationContent
