import { Icon, Skeleton } from '@backyard-ui/core'
import {
  ClockOutline,
  DollarSignOutline,
  MoneyOutline
} from '@backyard-ui/icons'
import {
  Button,
  Heading,
  Inline,
  Text,
  Visible
} from '@leroy-merlin-br/backyard-react'
import { useEffect, useState } from 'react'

import { useLoyaltyProfile } from 'shared/hooks'

import { useResize } from 'utils/hooks/use-resize'

import { Header } from 'lmcv/components'

import IconLoyalty from '../../../../Icons/IconLoyalty'
import * as S from './styled'
import { InvoiceUpload } from './components/InvoiceUpload'
import { useRedeemContext } from '../../contexts/RedeemContext'

export default function Redeem() {
  const { loyaltyProfile } = useLoyaltyProfile()
  const { fetchCashback: getCashback, cashback, loadingCashback } = useRedeemContext()
  const [isMobile] = useResize()

  const [uploadInvoiceIsVisible, setUploadInvoiceIsVisible] = useState(false)

  useEffect(() => {
    getCashback()
  }, [])

  return (
    <>
      <InvoiceUpload
        uploadInvoiceIsVisible={uploadInvoiceIsVisible}
        handleClose={() => setUploadInvoiceIsVisible(false)}
      />

      {loadingCashback ? (
        <Skeleton width="100%" height="408px" />
      ) : (
        <>
          {!isMobile && loyaltyProfile && (
            <Header loyaltyProfile={loyaltyProfile} />
          )}
          <S.Wrapper>
            <S.Title>
              <Heading as="h1">Solicitação de cashback</Heading>
              <IconLoyalty width={80} />
            </S.Title>
            <S.Subtitle>
              Troque seus pontos por cashback na sua conta corrente, enviando
              uma nota fiscal correspondente ao valor disponível para resgate.
            </S.Subtitle>
            <S.Content disabled={!cashback?.enabled}>
              <S.ContentLine>
                <S.FlexResponsive>
                  <Inline>
                    <S.Circle>
                      <Icon
                        size="2xl"
                        appearance={cashback?.enabled ? 'tertiary' : undefined}
                      >
                        <MoneyOutline />
                      </Icon>
                      <S.CircleIcon disabled={!cashback?.enabled}>
                        <Icon size="xs">
                          <DollarSignOutline />
                        </Icon>
                      </S.CircleIcon>
                    </S.Circle>
                  </Inline>
                  <S.ResponsiveAlignment>
                    <Text
                      isBold
                      noMargin
                      size="giga"
                      color={!cashback?.enabled ? 'n400' : 'n900'}
                    >
                      {cashback?.cashback} reais
                    </Text>
                    <Text noMargin size="mega" color="n400">
                      por {cashback?.balance} pts
                    </Text>
                  </S.ResponsiveAlignment>
                </S.FlexResponsive>
                <Button
                  type="button"
                  appearance="secondary"
                  disabled={!cashback?.enabled}
                  onClick={() => setUploadInvoiceIsVisible(true)}
                >
                  <span>Anexar nota fiscal</span>
                </Button>
              </S.ContentLine>
              <S.ContentLine>
                <Inline alignY="baseline" space="bit">
                  <Icon size="xs">
                    <ClockOutline />
                  </Icon>
                  <Text color="n400" size="mega" noMargin>
                    Expira em {cashback?.daysUntilExpiration} dias
                  </Text>
                </Inline>
                {cashback?.enabled && (
                  <Visible when={['mega', 'giga', 'tera']}>
                    <Text color="f500" size="mega" noMargin>
                      A Nota Fiscal deve ser emitida e enviada até o dia 20 de
                      cada mês
                    </Text>
                  </Visible>
                )}
              </S.ContentLine>
            </S.Content>
          </S.Wrapper>
        </>
      )}
    </>
  )
}
