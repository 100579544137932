export type ChatType = {
  message: string
  from: string
  date: string
}

export type subOptionType = {
  id: string
  name: string
}

export type OptionType = {
  type: string
  id: string
  name: string
  subOptions?: subOptionType[]
}

export type ApiDataType = {
  chat: ChatType[]
  options: OptionType[]
  status: string
  option: string
  subOption: string
  ticket: string
}

export type PresenterType = {
  message: string
  from: string
  date: string
  options: OptionType[] | subOptionType[]
  selected: string
}

function marketplaceChatFactory (data: ApiDataType) {
  switch (data.option) {
    case 'deliveryInformation':
      return deliveryInformationFactory(data)
    case 'cancellation':
      return cancellationFactory(data)
    case 'defectiveProduct':
      return defectiveProductFactory(data)
    case 'missingProduct':
      return missingProductFactory(data)
    case 'shiftReturn':
      return shiftReturnFactory(data)
    case 'productInformation':
      return productInformationFactory(data)
    case 'invoice':
      return invoiceFactory(data)
    case 'incorrectProduct':
      return incorrectProductFactory(data)
    case 'reversal':
      return reversalFactory(data)
    case 'otherSubject':
      return otherSubjectFactory(data)
    default:
      return defaultGreetingFactory(data)
  }
}

function deliveryInformationFactory (data: ApiDataType): PresenterType[] {
  return [
    {
      message: 'Lorem ipsum dolor sit amet',
      from: 'bot',
      date: new Date().toISOString(),
      options: data.options,
      selected: 'deliveryInformation'
    }
  ]
}

function cancellationFactory (data: ApiDataType): PresenterType[] {
  return [
    {
      message: 'Lorem ipsum dolor sit amet',
      from: 'bot',
      date: new Date().toISOString(),
      options: data.options,
      selected: 'cancellation'
    }
  ]
}

function defectiveProductFactory (data: ApiDataType): PresenterType[] {
  return [
    {
      message: 'Lorem ipsum dolor sit amet',
      from: 'bot',
      date: new Date().toISOString(),
      options: data.options,
      selected: 'defectiveProduct'
    }
  ]
}

function missingProductFactory (data: ApiDataType): PresenterType[] {
  return [
    {
      message: 'Lorem ipsum dolor sit amet',
      from: 'bot',
      date: new Date().toISOString(),
      options: data.options,
      selected: 'missingProduct'
    }
  ]
}

function shiftReturnFactory (data: ApiDataType): PresenterType[] {
  return [
    {
      message: 'Lorem ipsum dolor sit amet',
      from: 'bot',
      date: new Date().toISOString(),
      options: data.options,
      selected: 'shiftReturn'
    }
  ]
}

function productInformationFactory (data: ApiDataType): PresenterType[] {
  return [
    {
      message: 'Lorem ipsum dolor sit amet',
      from: 'bot',
      date: new Date().toISOString(),
      options: data.options,
      selected: 'productInformation'
    }
  ]
}

function invoiceFactory (data: ApiDataType): PresenterType[] {
  return [
    {
      message: 'Lorem ipsum dolor sit amet',
      from: 'bot',
      date: new Date().toISOString(),
      options: data.options,
      selected: 'invoice'
    }
  ]
}

function incorrectProductFactory (data: ApiDataType): PresenterType[] {
  return [
    {
      message: 'Lorem ipsum dolor sit amet',
      from: 'bot',
      date: new Date().toISOString(),
      options: data.options,
      selected: 'incorrectProduct'
    }
  ]
}

function reversalFactory (data: ApiDataType): PresenterType[] {
  return [
    {
      message: 'Lorem ipsum dolor sit amet',
      from: 'bot',
      date: new Date().toISOString(),
      options: data.options,
      selected: 'reversal'
    }
  ]
}

function otherSubjectFactory (data: ApiDataType): PresenterType[] {
  return [
    {
      message: 'Lorem ipsum dolor sit amet',
      from: 'bot',
      date: new Date().toISOString(),
      options: data.options,
      selected: 'otherSubject'
    }
  ]
}

function defaultGreetingFactory (data: ApiDataType): PresenterType[] {
  return [
    {
      message: 'Então, por favor, selecione qual dos assuntos você deseja tratar.',
      from: 'bot',
      date: new Date().toISOString(),
      options: data.options,
      selected: ''
    }
  ]
}

export default marketplaceChatFactory
