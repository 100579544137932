import { tv } from 'tailwind-variants'

const styles = tv({
  base: `
    px-2
  `,
  slots: {
    wrapper: `
      bg-white
      py-14
      px-6
    `
  }
})

export default styles
