import axios from 'axios'

import { api } from './api'
import { Address, Profile, ShortAddress } from '../types'

export const getAddressByZipcode = async (zipcode: string) => {
  try {
    const { data } = await axios.get<{ data: ShortAddress }>(`/endereco/${zipcode}`)

    return data
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error.response
    } else {
      throw error
    }
  }
}

export const addAddress = async (address: Address) => {
  try {
    const { data } = await api.post<{ data: Address }>(
      '/account/addresses',
      address
    )

    return data
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error.response
    } else {
      throw error
    }
  }
}

export const removeAddress = async (hash: string) => {
  try {
    const { data } = await api.delete<{ data: Profile }>(`/account/addresses/${hash}`)

    return data
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error.response
    } else {
      throw error
    }
  }
}
