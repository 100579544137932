import PropTypes from 'prop-types'

import * as S from './styled'

const TimeLine = ({ children, status }) => {
  return <S.Container status={status}>{children}</S.Container>
}

TimeLine.propTypes = {
  children: PropTypes.node,
  status: PropTypes.string
}

export default TimeLine
