const _cache = new Map()

export const cache = {
  has (key: string) {
    return _cache.has(key)
  },

  get (key: string) {
    return _cache.get(key)[0]
  },

  set (key: string, value: any) {
    return _cache.set(key, [value, Date.now()])
  },

  delete (key: string) {
    return _cache.delete(key)
  },

  clear () {
    return _cache.clear()
  },

  isExpired (key: string, seconds = 10) {
    const [, timestamp] = _cache.get(key)
    return Date.now() - timestamp > seconds * 1000
  }
}
