import { useContext } from 'react'
import PropTypes from 'prop-types'
import {
  Stack,
  SubHeading,
  useBreakpoint
} from '@leroy-merlin-br/backyard-react'
import LazyLoad from 'react-lazyload'

import { DEPARTMENT_ICONS } from 'shared/icons'
import { OptimizedImage } from 'shared/components/OptimizedImage'

import { ModularContext } from 'site/Modular/contexts/ModularContext'

import slugify from 'utils/slugify'

import * as S from './styled'

const IMAGE_SIZE = { width: 125, height: 125 }

const CategoryItem = ({ item, onClick }) => {
  const { isBlackWeekend } = useContext(ModularContext)

  const { breakpoint } = useBreakpoint()

  const isMobile = ['untilKilo', 'kilo'].includes(breakpoint)

  const { name, url, picture } = item

  const handleClick = event => {
    if (onClick) {
      onClick(event, item)
    }
  }

  const renderImg = () => {
    const svgSlug = slugify(name)

    if (Object.keys(DEPARTMENT_ICONS).includes(svgSlug)) {
      return (
        <S.Thumbnail
          as="div"
          isSvg
          width={IMAGE_SIZE.width}
          height={IMAGE_SIZE.height}
          isBlackWeekend={isBlackWeekend}
        >
          {DEPARTMENT_ICONS[svgSlug]}
        </S.Thumbnail>
      )
    }

    return (
      <OptimizedImage
        as={S.Thumbnail}
        src={picture}
        alt={name}
        title={name}
        width={IMAGE_SIZE.width}
        height={IMAGE_SIZE.height}
        itemProp="image"
        noSrcSet
      />
    )
  }

  return (
    <div
      itemScope="itemscope"
      itemType="http://www.schema.org/SiteNavigationElement"
    >
      <S.Item href={url} title={name} itemProp="url" onClick={handleClick}>
        <Stack space="byte">
          <LazyLoad height={IMAGE_SIZE.height} offset={200} once>
            {renderImg()}
          </LazyLoad>

          <S.ItemLabel>
            <SubHeading
              as="h3"
              size={isMobile ? 'kilo' : 'mega'}
              align="center"
              itemProp="name"
              noMargin
            >
              {name}
            </SubHeading>
          </S.ItemLabel>
        </Stack>
      </S.Item>
    </div>
  )
}

CategoryItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    picture: PropTypes.string,
    url: PropTypes.string
  }).isRequired,
  onClick: PropTypes.func
}

export default CategoryItem
