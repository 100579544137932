import request from './axios'
import { API_ENDPOINTS } from './constants'

export const getUserProfile = async () => {
  const response = await request
    .get(API_ENDPOINTS.profile)
    .then(({ data }) => data)
    .catch(err => {
      throw err.response
    })

  return response
}

export const updateUserProfile = async (data, redirect) => {
  const isValidRedirect = redirect !== null

  const buildRedirect = redirect => {
    if (isValidRedirect) {
      return {
        headers: {
          'Redirect-URL': redirect
        }
      }
    }
  }

  const response = await request
    .put(API_ENDPOINTS.profile, data, buildRedirect(redirect))
    .then(({ data }) => data)
    .catch(err => {
      throw err.response
    })

  return response
}
