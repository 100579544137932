import { PropsWithChildren } from 'react'

type RootProps = {
  noMargin?: boolean
}

export const Root = ({ children, noMargin }: PropsWithChildren<RootProps>) => {

  return (
    <div className={`py-6 flex flex-row justify-between items-center ${noMargin ? 'mx-0' : 'mx-6'}`}>
      {children}
    </div>
  )
}
