import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { Theme } from 'scripts/react-components/themes/Theme'

const optionsWrapperStyles = ({ theme }: Theme) => css`
  label: wrapper;

  margin-top: ${theme.spacings.byte};
  margin-bottom: 20px;
`

export const OptionsWrapper = styled('div')(optionsWrapperStyles)

const optionStyles = ({
  theme,
  active,
  disabled
}: Theme & { active: boolean, disabled: boolean }) => css`
  label: option-wrapper;

  align-items: center;
  cursor: ${disabled ? 'default' : 'pointer'};
  display: flex;
  justify-content: space-between;
  padding: ${theme.spacings.mega};
  transition: all 0.15s ease-in-out;
  margin: 0 -${theme.spacings.mega};

  background-color: ${active && theme.colors.n100};

  &:hover {
    background-color: ${!disabled && theme.colors.n100};
  }

  svg {
    color: ${theme.colors.p600}
  }
`

export const Option = styled('div')(optionStyles)

const optionTextStyles = () => css`
  width: 220px;
`

export const OptionText = styled('div')(optionTextStyles)

const optionIconWrapperStyles = () => css`
  width: 18px;
`

export const OptionIconWrapper = styled('div')(optionIconWrapperStyles)
