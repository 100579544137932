export const SHIPMENTS_ERROR_STATUS = {
  differentRegion: 422
}

export const SHIPMENTS_EXPRESS_MODALITIES = ['express', 'marketplace_express']

export const POSTAL_CODE_STATUS = {
  success: {
    state: 'valid',
    hint: 'CEP válido'
  },
  invalid_cep: {
    state: 'invalid',
    hint: 'CEP não encontrado'
  },
  shipments_error: {
    state: 'invalid',
    hint: 'Erro ao calcular o frete'
  },
  loading: {
    state: undefined,
    hint: 'Buscando...'
  },
  idle: {
    state: undefined,
    hint: 'Ex: 00000-000'
  }
}
