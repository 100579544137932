import { css } from '@emotion/react'
import styled from '@emotion/styled'

const baseStyles = ({ theme }) => css`
  label: footer;

  display: none;

  border-bottom: ${theme.spacings.bit} solid ${theme.colors.g500};

  ${theme.media.mega} {
    display: block;

    & span {
      display: none;
    }

    & p {
      padding: 0 ${theme.spacings.exa};
    }
  }

  ${theme.media.giga} {
    & span {
      display: inline-block;
      vertical-align: middle;

      width: 3px;
      height: 3px;
      margin: 0 ${theme.spacings.mega};

      background-color: ${theme.colors.n300};
      border-radius: ${theme.borderRadius.circle};
    }
  }
`

export const Footer = styled('footer')(baseStyles)
