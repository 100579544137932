/**
 * Component responsible to handle masks with the usage of [data-mask]. This component relies on a third-party plugin called jquery-mask-plugin. In order to use this component, a good practice is to set it as a high-order component, since it will handle all [data-mask] inside of the component.
 */
import $ from 'jquery'
import mask from 'jquery-mask-plugin'

export default ($el) => {
  const behavior = (val) => val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009'

  const maskOptions = {
    onKeyPress: function (val, e, field, options) {
      field.mask(behavior.apply({}, arguments), options)
    }
  }

  /**
   * Custom function to handle 9 digit phones
   */
  $('[data-phone-mask]').mask(behavior, maskOptions)
}
