import { css } from '@emotion/react'
import styled from '@emotion/styled'

const containerStyles = () => css`
  label: skeleton;

  overflow: hidden;
`

export const Container = styled('div')(containerStyles)
