import { useEffect, useRef, useState } from 'react'
import { Icon, Visible, Button } from '@leroy-merlin-br/backyard-react'
import { CrossOutline, GiftOutline } from '@backyard-ui/icons'

import { useBodyScrollLock } from 'utils/hooks/use-body-scroll-lock'

import {
  Header,
  Info,
  ModalError,
  ModalSkeleton,
  StepsForActivation
} from '../components'
import { Footer } from './components/Footer'
import { useCouponModalCommon } from './useCouponModalCommon'
import * as S from './styled'
import { CouponModalProps } from '../types'

const CouponModalCommon = ({
  id,
  category,
  active,
  onClose
}: CouponModalProps) => {
  const scrollElement = useRef()

  const elementRef = useRef()

  const [showActiveVoucherButton, setShowActiveVoucherButton] = useState(false)

  const observerCallback = ([entry]: IntersectionObserverEntry[]) => {
    if (entry.intersectionRatio <= 0.25) {
      setShowActiveVoucherButton(true)
    } else {
      setShowActiveVoucherButton(false)
    }
  }

  useBodyScrollLock(scrollElement)

  const {
    couponData,
    isRequestLoading,
    hasRequestError,
    infoText,
    buttonText,
    isActivationLoading,
    isButtonDisabled,
    onCouponActive
  } = useCouponModalCommon(id, category, active)

  useEffect(() => {
    if (!isRequestLoading && elementRef?.current) {
      const observer = new IntersectionObserver(observerCallback, {
        threshold: 0.25
      })
      observer.observe(elementRef?.current)
    }
  }, [elementRef, isRequestLoading])

  if (isRequestLoading) {
    return <ModalSkeleton />
  }

  if (hasRequestError) {
    return <ModalError onClose={onClose} />
  }

  const ActiveVoucherButton = () => (
    <Button
      appearance="secondary"
      isDisabled={isButtonDisabled}
      isLoading={isActivationLoading}
      onClick={onCouponActive}
      isStretch
    >
      {buttonText.default}
    </Button>
  )

  const FooterElement = () => (
    <Footer
      couponData={couponData}
      infoText={infoText}
      buttonText={buttonText}
      ActiveVoucherButton={ActiveVoucherButton}
    />
  )

  const StepsForActivationElement = () => (
    <StepsForActivation couponData={couponData} />
  )

  const HeaderElement = () => (
    <Header couponData={couponData} subtitle={infoText.subtitle} />
  )

  enum couponTagCategory {
    money = 'cashback',
    percentage = 'advantage'
  }

  return (
    <S.Wrapper
      isActive={couponData.isActive}
      data-id="wrapperModal"
      data-gtm-coupon-id={couponData.id}
      data-gtm-coupon-name={couponData.title}
      data-gtm-coupon-category={couponTagCategory[couponData.type]}
      data-gtm-coupon-type={couponData.type}
      data-gtm-coupon-is-active={couponData.isActive}
      data-gtm-coupon-price={couponData.price}
      data-gtm-coupon-discount={couponData.discount}
      data-gtm-coupon-remaining-days={couponData.remainingDays}
    >
      {couponData.giftedBy && (
        <S.SharedIcon>
          <Icon as={GiftOutline} />
        </S.SharedIcon>
      )}

      <S.CloseButton onClick={onClose}>
        <Icon as={CrossOutline} size="kilo" />
      </S.CloseButton>

      <S.Aside ref={elementRef}>
        <HeaderElement />
        <StepsForActivationElement />
        <FooterElement />
      </S.Aside>
      <S.Content ref={scrollElement} data-id="voucherDescription">
        <Visible when={['untilKilo', 'kilo']}>
          <S.ArrowIcon />
        </Visible>

        <Info couponData={couponData} />

        {!couponData.isActive && showActiveVoucherButton && (
          <Visible when={['untilKilo', 'kilo']}>
            <S.ActiveButton>
              <ActiveVoucherButton />
            </S.ActiveButton>
          </Visible>
        )}
      </S.Content>
    </S.Wrapper>
  )
}

export default CouponModalCommon
