import { store as reduxStore } from 'newCheckout/store'
import { Store } from 'newCheckout/types/PickUpInStore'
import { changeShipment } from 'newCheckout/store/pickupInStore'

import * as S from './Style'
import { StoreCard } from './Visual'

type OnChangeParameters = {
  type: 'pickup-in-store' | 'delivery'
  storeId?: string | null
  availableStores?: Store[]
}

const SelectStore = ({
  stores,
  onChange,
  storeId,
  cartId
}: {
  stores: Store[]
  onChange: (params: OnChangeParameters) => void
  storeId: string
  cartId: string
}) => {
  return (
    <S.Container>
      {stores.map(store => (
        <StoreCard
          isChecked={!!storeId && storeId === store.storeId}
          onClick={async () => {
            await reduxStore.dispatch(
              changeShipment({
                cartId,
                storeId: store.storeId,
                type: 'pickupInStore'
              })
            )
            onChange({ type: 'pickup-in-store', storeId: store.storeId, availableStores: stores })
          }}
          radioName="select-store"
          value={store.storeId}
          {...store}
          key={store.storeId}
        />
      ))}
    </S.Container>
  )
}

export default SelectStore
