import styled from '@emotion/styled'
import { css } from '@emotion/react'

const containerStyles = () => css`
  label: modal__container;

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  svg {
    width: 68px;
    height: 62px;
  }
`

export const Container = styled('div')(containerStyles)
