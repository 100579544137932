import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { Theme } from 'scripts/react-components/themes/Theme'

export const Wrapper = styled('div')(
  ({ theme }: Theme) => css`
    padding: ${theme.spacings.mega};
  `
)

export const FieldWrapper = styled('div')(
  ({ theme }: Theme) => css`
    padding-top: ${theme.spacings.byte} !important;
    padding-bottom: ${theme.spacings.byte} !important;
  `
)

export const AutoCompleteSelectWrapper = styled('div')(
  ({ theme }: Theme) => css`
    padding-top: ${theme.spacings.byte} !important;
    padding-bottom: ${theme.spacings.byte} !important;
    width: 100%;
  `
)

export const Label = styled('div')(() => css`
  display: block;
  margin-bottom: 4px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 800;
  color: #222020;
`)

export const Error = styled('div')(
  ({ theme }: Theme) => css`
    color: ${theme.colors.r500};
    ${theme.typography.text.kilo}
  `
)

export const Block = styled('div')(() => css`
  width: 100%;
`)
