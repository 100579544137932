import { useEffect, useState, useMemo, useRef, useCallback } from 'react'
import { debounce } from 'lodash'
import { useForm } from 'react-hook-form'
import {
  Button,
  Card,
  Stack,
  Row,
  Col,
  Text
} from '@leroy-merlin-br/backyard-react'
import { Popover, InputLoading } from 'user/components'
import { useCardRequestContext } from 'user/celebre/context/card-request-context'
import { removeNonNumericCharacters } from 'user/utils'
import { ErrorModal } from 'user/celebre/components'
import { useStateMachine } from 'little-state-machine'

import { addressApi } from 'api'

import { NumberField, TextField, ToggleButton } from 'shared/components'

import { validateNumberByState } from 'utils/account/factoryAddressRules.js'
import * as validator from 'utils/validators/validators'

import * as S from './styled'
import { FORMS_NAMES, FORM_DEFAULT_VALUES } from '../../constants'

const AddressForm = () => {
  const [isLoadingCep, setIsLoadingCep] = useState(false)
  const [isCepNotFound, setIsCepNotFound] = useState(false)
  const [addressFormData, setAddressFormData] = useState(null)
  const [isSavedAddressActive, setIsSavedAddressActive] = useState(false)

  const lastCep = useRef()

  const {
    mainAddress,
    updateAction,
    setShowExitConfirm,
    activeStep,
    setActiveStep
  } = useCardRequestContext()

  const {
    actions,
    state: { address: addressData, apiErrors }
  } = useStateMachine({ updateAction })

  const {
    handleSubmit,
    control,
    setError,
    formState,
    getValues,
    reset,
    trigger
  } = useForm({
    mode: 'onChange',
    defaultValues: FORM_DEFAULT_VALUES.address
  })

  const { errors, isValid } = formState

  const savedAddress = useMemo(() => {
    return (
      mainAddress && {
        address: {
          postalCode: mainAddress.postalCode.number,
          district: mainAddress.district,
          complement: mainAddress.complement,
          city: mainAddress.city,
          state: mainAddress.state,
          street: mainAddress.street,
          streetNumber: mainAddress.number
        }
      }
    )
  }, [mainAddress])

  const hasErrors = useMemo(() => {
    return Object.keys(formState.errors).length > 0
  }, [formState])

  const searchCep = async cep => {
    setIsLoadingCep(true)

    try {
      const data = await addressApi.getAddressByCep(cep)

      setAddressFormData({
        address: {
          postalCode: data.cep,
          district: data.district,
          city: data.city,
          state: data.state,
          street: data.street
        }
      })

      setIsCepNotFound(false)
    } catch (error) {
      if (error?.status === 404) {
        setIsCepNotFound(true)
      } else {
        setShowExitConfirm(false)

        return setModal({
          children: ({ onClose }) => <ErrorModal onClose={onClose} />,
          isCentered: true,
          shouldCloseOnOverlayClick: false
        })
      }
    } finally {
      setIsLoadingCep(false)
    }
  }

  const handleCepChange = debounce(event => {
    const { value } = event.target
    const cep = removeNonNumericCharacters(value)

    if (cep === lastCep.current) {
      return
    }

    reset({
      address: { ...FORM_DEFAULT_VALUES.address.address, postalCode: value }
    })
    setAddressFormData(null)

    if (cep.length > 7) {
      searchCep(cep)
    } else {
      setIsCepNotFound(false)
      trigger('zipCode')
    }

    lastCep.current = cep
  }, 600)

  const onRegisteredAddressClick = useCallback(
    isChecked => {
      if (isChecked) {
        setAddressFormData({ ...savedAddress })
      } else {
        reset(FORM_DEFAULT_VALUES.address)
      }
      setIsSavedAddressActive(isChecked)
    },
    [reset, savedAddress]
  )

  const onSubmit = data => {
    actions.updateAction({
      address: data,
      apiErrors: { ...apiErrors, address: [] }
    })

    setActiveStep(FORMS_NAMES.income)
  }

  useEffect(() => {
    if (addressData) {
      setAddressFormData(addressData)
    }
  }, [addressData])

  useEffect(() => {
    if (addressFormData) {
      reset(addressFormData)
    }

    if (apiErrors.address) {
      apiErrors.address.forEach(({ code, message }) => {
        setError(code, { type: 'manual', message: message })
      })
    }
  }, [addressFormData, apiErrors.address, reset, setError])

  const isActive = activeStep === FORMS_NAMES.address

  if (!isActive) {
    return
  }

  return (
    <Card
      title={
        <Popover
          list={{
            title:
              'Este é o endereço de cobrança da fatura do seu cartão de crédito.'
          }}
        >
          Endereço de cobrança
        </Popover>
      }
    >
      {savedAddress && (
        <S.ToggleWrapper
          onClick={() => onRegisteredAddressClick(!isSavedAddressActive)}
          data-cy="registered-address-toggle"
        >
          <Text size="mega" noMargin color="n800">
            Utilizar o meu endereço cadastrado
          </Text>
          <ToggleButton isActive={isSavedAddressActive} />
        </S.ToggleWrapper>
      )}

      <form onSubmit={handleSubmit(onSubmit)} data-cy="address-data-form">
        <Stack space="mega">
          <InputLoading isLoading={isLoadingCep}>
            <NumberField
              name="address.postalCode"
              label="CEP"
              control={control}
              rules={{
                required: 'Insira seu CEP',
                validate: value => validator.isCep(value) || 'CEP inválido'
              }}
              onChange={event => handleCepChange(event)}
              state={
                (isCepNotFound || Boolean(errors.address?.postalCode)) &&
                'invalid'
              }
              hint={
                isCepNotFound
                  ? 'CEP não encontrado'
                  : errors.address?.postalCode?.message
              }
              format="#####-###"
              isDisabled={isSavedAddressActive}
            />
          </InputLoading>

          <TextField
            name="address.district"
            label="Bairro"
            rules={{
              required: 'Insira seu bairro',
              maxLength: {
                value: 100,
                message: 'Limite de 100 caracteres'
              }
            }}
            state={Boolean(errors.address?.district) && 'invalid'}
            hint={errors.address?.district?.message}
            control={control}
            isDisabled={isSavedAddressActive}
          />

          <TextField
            name="address.complement"
            label="Complemento"
            control={control}
            isDisabled={isSavedAddressActive}
            rules={{
              maxLength: { value: 50, message: 'Limite de 50 caracteres' }
            }}
            state={Boolean(errors.address?.complement) && 'invalid'}
            hint={errors.address?.complement?.message}
          />

          <TextField
            name="address.city"
            label="Cidade"
            rules={{
              required: 'Insira sua cidade',
              maxLength: {
                value: 60,
                message: 'Limite de 60 caracteres'
              }
            }}
            control={control}
            isDisabled={isSavedAddressActive}
            state={Boolean(errors.address?.city) && 'invalid'}
            hint={errors.address?.city?.message}
          />

          <TextField
            name="address.state"
            label="Estado"
            rules={{
              required: 'Insira seu estado'
            }}
            control={control}
            state={Boolean(errors.address?.state) && 'invalid'}
            hint={errors.address?.state?.message}
            isDisabled={isSavedAddressActive}
          />

          <Row>
            <Col size={{ giga: 8 }}>
              <S.FieldMargin>
                <TextField
                  name="address.street"
                  label="Endereço"
                  rules={{
                    required: 'Insira sua rua/avenida',
                    maxLength: {
                      value: 120,
                      message: 'Limite de 120 caracteres'
                    }
                  }}
                  state={Boolean(errors.address?.street) && 'invalid'}
                  hint={errors.address?.street?.message}
                  control={control}
                  isDisabled={isSavedAddressActive}
                />
              </S.FieldMargin>
            </Col>

            <Col size={{ giga: 4 }}>
              <NumberField
                name="address.streetNumber"
                label="Número"
                control={control}
                rules={{
                  required: 'Insira o número',
                  validate: value =>
                    validateNumberByState(value, getValues('state')) ||
                    'Número inválido'
                }}
                state={Boolean(errors.address?.streetNumber) && 'invalid'}
                hint={errors.address?.streetNumber?.message}
                isDisabled={isSavedAddressActive}
                valueType="number"
              />
            </Col>
          </Row>
        </Stack>

        <S.ButtonWrapper>
          <Button isStretch type="submit" isDisabled={!isValid || hasErrors}>
            Continuar
          </Button>
        </S.ButtonWrapper>
      </form>
    </Card>
  )
}

export default AddressForm
