import type { PropsWithChildren, ReactNode } from 'react'

import * as S from './styled'

const Separator = ({ children }: PropsWithChildren<ReactNode>) => {
  return (
    <S.SeparatorWrapper>
      <S.LineThrough />
      <S.Children>{children}</S.Children>
      <S.LineThrough />
    </S.SeparatorWrapper>
  )
}

export default Separator
