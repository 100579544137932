import { TrashCan as TrashCanIcon } from 'frontendCheckout/assets/icons'

type TrashCanProps = {
  onDelete: () => void
}

export const TrashCan = ({ onDelete }: TrashCanProps) => {
  return (
    <div onClick={onDelete} className="ml-auto p-1">
      <TrashCanIcon />
    </div>
  )
}
