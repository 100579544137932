import PropTypes from 'prop-types'
import {
  Text,
  Icon,
  Stack,
  Inline,
  Popover as PopoverBase
} from '@leroy-merlin-br/backyard-react'
import { Checkmark, InfoCircleOutline } from '@backyard-ui/icons'

import * as S from './styled'

function Popover ({ children, list, info, placement, ...props }) {
  const content = (
    <Stack space="byte">
      <Text size="kilo" as="span" noMargin isBold>
        {list.title}
      </Text>

      {list.items?.map(item => (
        <Inline
          key={item}
          alignY="baseline"
          alignX="flex-start"
          space="byte"
          noWrap
        >
          <Icon as={Checkmark} size="kilo" color="primary" />
          <Text size="kilo" noMargin>
            {item}
          </Text>
        </Inline>
      ))}

      {info && (
        <Stack space="byte">
          <S.Divider />

          <Text size="kilo" noMargin color="n500">
            {info}
          </Text>
        </Stack>
      )}
    </Stack>
  )

  return (
    <Inline alignY="center" alignX="flex-start" space="byte" data-gtm-element-id={list.id}>
      <div>{children}</div>

      <PopoverBase
        content={content}
        placement={placement}
        {...props}
      >
        <S.WrapperIcon>
          <Icon as={InfoCircleOutline} color="primary" />
        </S.WrapperIcon>
      </PopoverBase>
    </Inline>
  )
}

Popover.propTypes = {
  list: PropTypes.shape({
    title: PropTypes.string,
    items: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.arrayOf(PropTypes.node)
    ])
  }).isRequired,
  info: PropTypes.string,
  placement: PropTypes.oneOf([
    'bottom',
    'bottom-end',
    'bottom-start',
    'left',
    'left-end',
    'left-start',
    'right',
    'right-end',
    'right-start',
    'top',
    'top-end',
    'top-start'
  ])
}

Popover.defaultProps = {
  placement: 'right-start'
}

export default Popover
