export const flattenObject = (object) => {
  const result = {}

  for (const i in object) {
    if ((typeof object[i]) === 'object') {
      const tempObject = flattenObject(object[i])
      for (const j in tempObject) {
        result[i + '.' + j] = tempObject[j]
      }
    } else {
      result[i] = object[i]
    }
  }
  return result
}
