import qs from 'qs'

import request from 'checkout/utils/axios'

const getServices = async (regionId, products) => {
  const params = qs.stringify(
    {
      regionId,
      products
    },
    {
      addQueryPrefix: true,
      arrayFormat: 'indices',
      encode: false,
      skipNulls: true
    }
  )

  const response = await request.get(`/api/v3/crosses/services${params}`)

  return response
}

export default getServices
