import { Text } from '@leroy-merlin-br/backyard-react'
import PropTypes from 'prop-types'

import { AlertMessage } from 'shared/components/AlertMessage'

const FinishInstallments = ({ onButtonClick }) => (
  <AlertMessage
    type="info"
    buttonText="Finalizar contratação"
    onButtonClick={onButtonClick}
  >
    <Text as="span" size="kilo" noMargin>
      Caso você não tenha concluído a contratação de parcelamento com a nossa
      parceira{' '}
      <Text as="strong" size="kilo" isBold noMargin>
        BoletoFlex
      </Text>
      , seu pedido será cancelado automaticamente.
    </Text>
  </AlertMessage>
)

FinishInstallments.propTypes = {
  onButtonClick: PropTypes.func
}

export default FinishInstallments
