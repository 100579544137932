import { Card, Stack, Text } from '@leroy-merlin-br/backyard-react'

import formatCep from 'utils/formatCep'

import PinSVG from '../../images/Pin'
import * as S from './styled'
import { StandaloneServiceType } from './type'
import { Services } from '../Services'
import Drill from '../../images/Drill'

const Visual = ({ address, services }: StandaloneServiceType) => {
  return (
    <Card>
      <Text isBold size="giga">
        Dados do Serviço
      </Text>
      <Text>
        Para ver mais detalhes e acompanhar sua compra, acesse a página
        <a href="/meus-pedidos">
          <Text isBold color="p700" as="span">
            {' '}
            Meus Pedidos{' '}
          </Text>
        </a>
        ou baixe nosso
        <a href="/aplicativo-leroy-merlin">
          <Text isBold color="p700" as="span">
            {' '}
            App Leroy Merlin
          </Text>
        </a>
        .
      </Text>
      <Stack space="byte">
        <S.Panel>
          <S.PanelFirstLine>
            <Drill />{' '}
            <Text noMargin isBold>
              Previsão de instalação
            </Text>
          </S.PanelFirstLine>
          <S.PanelSecondLine>
            <Text size="kilo" noMargin>
              De 1 a 3 dias úteis{' '}
              <Text as="span" size="kilo" color="n600">
                após a confirmação do pagamento
              </Text>
            </Text>
          </S.PanelSecondLine>
        </S.Panel>
        <S.Panel>
          <S.PanelFirstLine>
            <PinSVG />{' '}
            <Text noMargin isBold>
              {address.street}, {address.number}
              {address.complement && ' ,'}
              {address.complement}
            </Text>
          </S.PanelFirstLine>
          <S.PanelSecondLine>
            <Text as="span" size="kilo" color="n600">
              {address.district} • {address.city} • {address.state} • CEP{' '}
              {formatCep(address.cep)}
            </Text>
          </S.PanelSecondLine>
        </S.Panel>
      </Stack>
      <Services services={services} />
    </Card>
  )
}

export default Visual
