import { useContext } from 'react'
import PropTypes from 'prop-types'
import { Text, Checkbox } from '@leroy-merlin-br/backyard-react'

import * as S from './styled'
import { buildPath } from '../../../../utils/buildUrl'
import { EntryKeyContext } from '../../../../context'

const buildFilterPath = (name, value, url, filtersQuery, isMappedSearch) => {
  const query = {
    ...filtersQuery,
    [name]: { value, type: 'filter' }
  }

  const path = buildPath(url, query, isMappedSearch)

  return `${window.env.baseHost}${path.pathname}${path.search}`
}

const OptionFilterItem = ({
  filterKey,
  query,
  optionLabel,
  quantity,
  selected,
  onFilterClick,
  filtersQuery,
  url,
  isMappedSearch,
  rightSpacing,
  isDisabled
}) => {
  const { name, baseUrl } = useContext(EntryKeyContext)

  const SEOEntryKeyFilterPath = `/${baseUrl}/${filterKey}/${query.replace(/\s/g, '_')}`

  return (
    <S.CheckboxWrapper
      href={buildFilterPath(
        filterKey,
        query.replace(/\s/g, '_'),
        url,
        filtersQuery,
        isMappedSearch
      )}
      title={`${name} ${optionLabel}`}
      data-seo-entry-key-filter-path={SEOEntryKeyFilterPath}
      onClick={onFilterClick}
      rightSpacing={rightSpacing}
      isDisabled={isDisabled}
    >
      <Checkbox defaultChecked={selected}>
        <Text as="span" size="mega" color={isDisabled ? 'n300' : ''} noMargin>
          {optionLabel}{' '}
        </Text>
        <Text as="span" size="kilo" color={isDisabled ? 'n300' : ''} noMargin>
          ({quantity})
        </Text>
      </Checkbox>
    </S.CheckboxWrapper>
  )
}

OptionFilterItem.propTypes = {
  filterKey: PropTypes.string.isRequired,
  optionLabel: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
  select: PropTypes.bool,
  onFilterClick: PropTypes.func.isRequired,
  filtersQuery: PropTypes.object,
  url: PropTypes.string,
  isMappedSearch: PropTypes.bool,
  rightSpacing: PropTypes.number,
  isDisabled: PropTypes.bool
}

export default OptionFilterItem
