import { FC } from 'react'
import { Spinner, Stack } from '@leroy-merlin-br/backyard-react'
import { Text } from '@backyard-ui/core'

import * as S from './styled'
import ResendCodeLink from './ResendCodeLink/ResendCodeLink'
import SecurityCodeInput, { InputStyledProps } from './SecureCodeInput/SecurityCodeInput'

type SecurityCodeParams = {
  contactDescription: string
  onInputComplete: (code: string) => void
  onResendLink: () => void
  fieldState: string
  isLoading: boolean
  inputStyledProps?: InputStyledProps
}

const SecurityCode: FC<SecurityCodeParams> = ({
  contactDescription,
  onInputComplete,
  onResendLink,
  fieldState,
  isLoading,
  inputStyledProps
}: SecurityCodeParams) => {
  const getContactDescription = () => (
    <Text data-testid="contact-description" dangerouslySetInnerHTML={{ __html: contactDescription }}>{}</Text>
  )

  return (
    <S.SecurityCode>

      {getContactDescription()}

      <SecurityCodeInput fieldState={fieldState} onInputComplete={onInputComplete} inputStyledProps={inputStyledProps} />

      <Stack alignX="center">
        <S.ResendCodeWrapper data-testid="content-resend-link">
          <ResendCodeLink onResendLink={onResendLink} />
        </S.ResendCodeWrapper>
      </Stack>

      <Stack alignX="center">
        {isLoading && <Spinner data-testid="spinner" appearance="primary" />}
      </Stack>

    </S.SecurityCode>
  )
}

export default SecurityCode
