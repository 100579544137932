import { useCallback } from 'react'
import type { MouseEvent } from 'react'
import { Card, Text, Tag, Stack, Button } from '@backyard-ui/core'
import { useCurrentRefinements, useClearRefinements } from 'react-instantsearch'

import { onFilterDataLayer } from 'scripts/react-components/entry-key/analytics/data-layer'
import { useServerContext } from 'scripts/react-components/entry-key/routes/routes.context'

import { useUserResources } from 'shared/hooks'

import formatPriceToBRL from 'utils/formatPriceToBRL'

import { getCategoryAttributes } from 'entry-key/routes/proxy/category/utils/getCategoryAttributes'

import { FILTER_PREFIX } from '../Filters/Filters'

type Refinements = Array<{
  value: string | number
  label: string
  isRefined?: boolean
  attribute: string
  type:
    | 'facet'
    | 'exclude'
    | 'disjunctive'
    | 'hierarchical'
    | 'numeric'
    | 'query'
    | 'tag'
}>

function SelectedFilters () {
  const { userResources } = useUserResources()
  const { items, refine } = useCurrentRefinements()
  const { refine: clearFilters } = useClearRefinements()

  const { algolia } = useServerContext()

  const sendEvent = useCallback(value => {
    onFilterDataLayer({
      searchParam: value.attribute,
      filterElement: { index: 0, value: value.value },
      filterType: { index: 0, value: value.attribute }
    })
  }, [])

  if (!items.length) {
    return null
  }

  if (!userResources?.region) {
    return null
  }

  const ATTR_LABELS = {
    'relevanceFields.isOurLowestPrice': 'Nosso Preço + Baixo',
    'relevanceFields.novelty': 'Novidades',
    'relevanceFields.exclusiveBrand': 'Marca Própria',
    'relevanceFields.exclusiveMarkeplace': 'Exclusivo Leroy Merlin',
    'relevanceFields.hasStock': 'Em estoque',
    [`regionalAttributes.${userResources?.region}.offer.enabled`]:
      'Somente Ofertas',
    [`regionalAttributes.${userResources?.region}.facets.freeShipping`]:
      'Frete Grátis',
    [`regionalAttributes.${userResources?.region}.facets.expressShipping`]:
      'Entrega Rápida',
    [`regionalAttributes.${userResources?.region}.facets.blackFriday`]:
      'Black Friday'
  }

  const getPriceLabel = (refinements: Refinements) => {
    const formatPrice = (value: string | number) =>
      formatPriceToBRL(value).replace(',00', '')

    const minPrice = formatPrice(refinements[0]?.value)
    const maxPrice = formatPrice(refinements[1]?.value)

    return refinements.length > 1
      ? `${minPrice} a ${maxPrice}`
      : `Até ${minPrice}`
  }

  const onPriceRefine = (
    event: MouseEvent<HTMLButtonElement>,
    refinements: Refinements
  ) => {
    event.preventDefault()
    event.stopPropagation()

    if (refinements.length > 1) {
      refinements.forEach(refinement => {
        sendEvent(refinement)
        refine(refinement)
      })
    } else {
      sendEvent(refinements[0])
      refine(refinements[0])
    }
  }

  const shouldShowClearFilter =
    items[0].refinements.length >= 2 || items.length >= 2

  const promotionalPrice = `regionalAttributes.${userResources?.region}.promotionalPrice`

  return (
    <Card.Root>
      <Card.Header>
        <Text weight="bold" asChild>
          <span>Filtros Selecionados:</span>
        </Text>
      </Card.Header>
      <Card.Body>
        <Stack spacing="2" isWrap>
          {items.map(item =>
            item.attribute === promotionalPrice ? (
              <Tag.Root
                key={item.label}
                title={getPriceLabel(item.refinements)}
              >
                <Tag.Label>{getPriceLabel(item.refinements)}</Tag.Label>
                <Tag.Dismiss
                  onClick={event => onPriceRefine(event, item.refinements)}
                />
              </Tag.Root>
            ) : (
              item.refinements.map(refinement => {
                const LABEL =
                  ATTR_LABELS[
                    refinement.attribute as keyof typeof ATTR_LABELS
                  ] ?? refinement.label

                const { prefix, sufix } = getCategoryAttributes(
                  algolia.facets,
                  refinement.attribute.slice(FILTER_PREFIX.length)
                )

                return (
                  <Tag.Root key={refinement.value} title={LABEL}>
                    <Tag.Label>{`${prefix} ${LABEL} ${sufix}`}</Tag.Label>
                    <Tag.Dismiss
                      onClick={() => {
                        refine(refinement)
                        sendEvent(refinement)
                      }}
                    />
                  </Tag.Root>
                )
              })
            )
          )}
        </Stack>
      </Card.Body>

      {shouldShowClearFilter && (
        <Card.Footer>
          <Button
            size="xs"
            isFullWidth
            onClick={() => {
              sendEvent({
                attribute: 'clean all',
                label: 'Limpar todos os filtros',
                value: 'clean all',
                type: 'tag',
                isRefined: false
              })
              clearFilters()
            }}
          >
            Limpar filtros
          </Button>
        </Card.Footer>
      )}
    </Card.Root>
  )
}

export default SelectedFilters
