import axios from 'axios'

import { api } from './api'
import { AddSocialSignUpResponse, SocialSignUp } from '../types'

export const checkFiscalId = async (fiscalId: string, redirect: string | null) => {
  try {
    const headers: {
      'Redirect-URL'?: string
    } = {}

    if (redirect) {
      headers['Redirect-URL'] = redirect
    }

    const { data } = await api.get<{ data: any }>(
      `/signup/check-cpf/${fiscalId}`,
      { headers }
    )

    return data
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error.response
    } else {
      throw error
    }
  }
}

export const addSocialSignUp = async (
  provider: string,
  payload: SocialSignUp
) => {
  try {
    const { data } = await api.post<AddSocialSignUpResponse>(
      `/signup/social/${provider}`,
      payload
    )

    return data
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error.response
    } else {
      throw error
    }
  }
}
