import { css } from '@emotion/react'
import styled from '@emotion/styled'

const tagStyles = ({ theme }) => css`
  label: applied-filters__tag;

  display: flex;
  align-items: center;

  padding: ${theme.spacings.bit} ${theme.spacings.byte};
  margin-top: ${theme.spacings.kilo};

  background-color: ${theme.colors.p600};
  border-radius: 20px;
`

const tagLabelStyles = ({ theme }) => css`
  label: applied-filters__tag-label;

  font-size: ${theme.typography.subHeadings.kilo.fontSize};
  font-weight: ${theme.font.weight.semibold};
  line-height: 15px;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  color: ${theme.colors.white};
`

const tagCloseButtonStyles = ({ theme }) => css`
  label: applied-filters__tag-close-button;

  padding: 0;

  color: ${theme.colors.white};

  & > svg {
    cursor: pointer;
    margin-left: ${theme.spacings.bit};
  }
`

export const Tag = styled('div')(tagStyles)
export const Label = styled('span')(tagLabelStyles)
export const ButtonClose = styled('button')(tagCloseButtonStyles)
