import { FC, useRef, useState } from 'react'
import { Heading, Text } from '@leroy-merlin-br/backyard-react'
import { useHistory } from 'react-router-dom'
import { AxiosResponse } from 'axios'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

import SecurityCode from 'scripts/react-components/user/components/SecurityCode/SecurityCode'
import { InputStyledProps } from 'scripts/react-components/user/components/SecurityCode/SecureCodeInput/SecurityCodeInput'
import { ShowToastParams, showToast } from 'scripts/react-components/user/my-account/utils/toast'
import { handleErrorsMessage, isServerError } from 'scripts/react-components/user/utils'
import { useUserResources } from 'scripts/react-components/shared/hooks'
import { verifyCode } from 'scripts/react-components/user/my-account/services'

import { loginPostMessage } from '../../utils/appPostMessage'
import * as S from './styled'
import { SendCodePayload, sendCodeAuth } from '../../services/verify-contact'
import { useSignupContext } from '../../context/signup-context'
import { signup } from '../../services/signup'
import { useFormPF } from '../SignUp/components/FormPF/useFormPF'

type VerifyAccountParams = {
  onPreviousStep: () => void
}

const VerifyAccount = (props: VerifyAccountParams) => {
  const { captchaKey } = useSignupContext()

  return (
    <GoogleReCaptchaProvider reCaptchaKey={captchaKey}>
      <WithRecaptchaComponent {...props} />
    </GoogleReCaptchaProvider>
  )
}

const WithRecaptchaComponent: FC<VerifyAccountParams> = ({ onPreviousStep }) => {
  const history = useHistory()

  const [isLoading, setIsLoading] = useState(false)
  const [fieldState, setfieldState] = useState('')

  const redirectTo = useRef()
  const { setIsLoggedIn, contextData } = useSignupContext()
  const { userControls } = useUserResources()

  const { getSignupRecaptchaParams, handleResponseSignUp } = useFormPF(true)

  const inputStyledProps: InputStyledProps = {
    height: 47,
    width: 60
  }

  const onHandleValidation = (fieldState: string) => {
    setfieldState(fieldState)
  }

  const onSendCodeToVerify = async (code: string) => {
    setIsLoading(true)

    try {
      const { data } = await verifyCode({
        code,
        context: 'confirm_contact',
        fiscalId: contextData.data.fiscalId
      })

      switch (data.status) {
        case 'Valid':
          await onSignUp(code)
          break
        case 'InvalidCode':
          showToast({
            type: 'critical',
            title: data.message,
            content: 'Insira o código correto para prosseguir.'
          })
          break
        case 'ExpiredCode':
          showToast({
            type: 'critical',
            title: data.message,
            content: 'Clique em reenviar código para gerar um novo.'
          })
          break
        default:
          showToast({
            type: 'critical',
            title: 'Não foi possível validar o código',
            content: 'Tente novamente mais tarde.'
          })
      }
    } catch (error) {
      const { status } = error as AxiosResponse

      const hasServerError = status && isServerError(status)

      if (hasServerError) {
        showToast({
          type: 'critical',
          title: 'Não foi possível verificar o código de segurança!',
          content: 'Tente novamente mais tarde.'
        })
      } else if (status === 429) {
        showToast({
          type: 'critical',
          title: 'Você atingiu o limite de tentativas!',
          content: 'Tente novamente mais tarde.'
        })
      }
    } finally {
      setIsLoading(false)
    }
  }

  const onSignUp = async (code: string) => {
    contextData.data.code = code

    const signupWithRecaptchaParams = await getSignupRecaptchaParams(
      contextData.data
    )

    const showToastParams: ShowToastParams = {
      type: 'critical',
      title: 'Não foi possível validar o código.',
      content: 'Tente novamente mais tarde.'
    }

    try {
      const { data } = await signup(signupWithRecaptchaParams)

      const shouldNeedDocumentation = handleResponseSignUp(
        data,
        signupWithRecaptchaParams
      )

      if (shouldNeedDocumentation) {
        return
      }

      redirectTo.current = data.redirect

      if (userControls?.isOnMobileApp) {
        loginPostMessage(data.token)
      }

      setIsLoggedIn(true)

      history.push({
        pathname: '/sucesso',
        state: {
          redirect: redirectTo.current,
          signupType: 'pf'
        }
      })
    } catch (error) {
      const { data, status } = error as AxiosResponse

      if (status === 429) {
        showToastParams.title = 'Você atingiu o limite de tentativas!'
      }

      if (data.errors && Array.isArray(data.errors)) {
        for (const { message, messages } of data.errors) {
          showToastParams.title = handleErrorsMessage(message, messages)
          showToastParams.content = undefined

          showToast(showToastParams)
        }
      }
    } finally {
      setIsLoading(false)
    }
  }

  const onResendCodeLInk = async () => {
    setIsLoading(true)

    const showToastParams: ShowToastParams = {
      type: 'critical',
      title: 'Não foi possível reenviar o código.',
      content: 'Tente novamente mais tarde.'
    }

    const otpParams: SendCodePayload = {
      fiscalId: contextData.data.fiscalId,
      type: 'email',
      fullName: contextData.data.name,
      contact: contextData.data.email,
      context: 'confirm_contact'
    }

    try {
      await sendCodeAuth(otpParams)

      showToastParams.type = 'primary'
      showToastParams.title = 'Código reenviado!'
      showToastParams.content = 'Insira o novo código.'
      showToast(showToastParams)
      onHandleValidation('')
    } catch (error) {
      const { status } = error as AxiosResponse

      const hasServerError = status && isServerError(status)

      if (hasServerError) {
        showToastParams.title = 'Não foi possível reenviar o código.'
      } else if (status === 429) {
        showToastParams.title = 'Você atingiu o limite de tentativas!'
      }

      showToast(showToastParams)
    } finally {
      setIsLoading(false)
    }
  }

  const getContactDescription = () => {
    return 'Digite o código de 6 dígitos recebido em seu <strong>e-mail</strong> ' + contextData?.data?.email
  }

  if (!contextData?.data?.email) {
    onPreviousStep()

    return null
  }

  return (
    <S.SecurityCodeWraper>
      <S.SecurityCodeCard>
        <Heading noMargin>Criar Conta</Heading>
        <Heading as="h2" noMargin>
          Valide seu e-mail para finalizar seu cadastro
        </Heading>

        <SecurityCode
          contactDescription={getContactDescription()}
          onInputComplete={onSendCodeToVerify}
          onResendLink={onResendCodeLInk}
          fieldState={fieldState}
          isLoading={isLoading}
          inputStyledProps={inputStyledProps}
        >
        </SecurityCode>

      </S.SecurityCodeCard>

      <S.securityCodePrevius>
        <Text
          noMargin
          size="mega"
          onClick={onPreviousStep}
        >
          Voltar ao cadastro
        </Text>
      </S.securityCodePrevius>

    </S.SecurityCodeWraper>

  )
}

export default VerifyAccount
