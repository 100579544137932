import axios from 'axios'
import Cookies from 'js-cookie'

const API_TOKEN = Cookies.get('api_token') || ''

const api = axios.create({
  baseURL: `${window.env && window.env.baseHost}/api/boitata/v1/wishlists`,
  headers: { Authorization: `Bearer ${API_TOKEN}` }
})

export const lists = async (page = 1, perPage = 20) => {
  const response = await api
    .get('/', {
      params: {
        page,
        perPage
      }
    })
    .then(data => data)
    .catch(err => {
      throw err.response
    })

  return response
}

export const ids = async () => {
  const response = await api
    .get('/ids')
    .then(data => data)
    .catch(err => {
      throw err.response
    })

  return response
}

export const createList = async data => {
  const response = await api
    .post('', { ...data })
    .then(data => data)
    .catch(err => {
      throw err.response
    })

  return response
}

export const editList = async (data, slug) => {
  const response = await api
    .put(`/${slug}`, { ...data })
    .then(data => data)
    .catch(err => {
      throw err.response
    })

  return response
}

export const getList = async slug => {
  const response = await api
    .get(`/${slug}`)
    .then(data => data)
    .catch(err => {
      throw err.response
    })

  return response
}

export const removeList = async slug => {
  const response = await api
    .delete(`/${slug}`)
    .then(data => data)
    .catch(err => {
      throw err.response
    })

  return response
}

export const isOnWishlist = async id => {
  const response = await api
    .get(`/product/${id}`)
    .then(data => data)
    .catch(err => {
      throw err.response
    })

  return response
}

export const removeProductFromLists = async id => {
  const response = await api
    .delete(`/product/${id}`)
    .then(data => data)
    .catch(err => {
      throw err.response
    })

  return response
}

export const addProduct = async (slug, data) => {
  const response = await api
    .post(`/${slug}/product`, { ...data })
    .then(data => data)
    .catch(err => {
      throw err.response
    })

  return response
}

export const removeContentFromLists = async id => {
  const response = await api
    .delete(`/content/${id}`)
    .then(data => data)
    .catch(err => {
      throw err.response
    })

  return response
}

export const addContent = async (slug, id) => {
  const response = await api
    .post(`/${slug}/content/${id}`, {})
    .then(data => data)
    .catch(err => {
      throw err.response
    })

  return response
}
