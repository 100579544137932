import Base from './Base'

class Button extends Base {
  render () {
    return (
      <button {...this.getElementProps(this.props)} onClick={this.onClick}>
        {this.props.children}
      </button>
    )
  }
}

export default Button
