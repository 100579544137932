import { useState, useEffect, ChangeEvent } from 'react'
import { mapValues } from 'lodash'
import { Controller, FieldPath, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { useSignupContext } from 'user/signup/context/signup-context'
import { loginPostMessage } from 'user/signup/utils/appPostMessage'
import { signupService } from 'user/signup/services'
import {
  flattenObject,
  hasAccountFiscalId,
  redirectOnUnknownError
} from 'user/signup/utils'
import {
  handleErrorsMessage,
  isServerError,
  removeNonNumericCharacters
} from 'user/utils'
import * as dataLayer from 'user/signup/utils/data-layer'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

import { addressApi, companyApi } from 'api'

import { useUserResources } from 'shared/hooks'

import * as validator from 'utils/validators/validators'
import {
  factoryAddressRules,
  validateNumberByState
} from 'utils/account/factoryAddressRules'
import { tracker } from 'utils/app'

type CompanyAddress = {
  street: string
  city: string
  district: string
  number: string
  complement: string
  state: string
  ibgeCode: string
  postalCode: {
    number: string
    type: string
    description: string
  }
}

type CompanyInfo = {
  company: {
    socialName: string
    fantasyName: string
  }
  stateRegistration: {
    number: string
  }
  address: CompanyAddress
  email: string
  emailConfirm: string
  password: string
  optins: {
    email: false
  }
  fiscalId: string
  sales_enabled: boolean
}

type ApiMessageError = {
  code: string
  message: string
}

type ApiErrors = {
  code: string
  message: string
  messages?: ApiMessageError[]
}

type ErrorFieldNames = FieldPath<CompanyInfo>

export function useFormPj() {
  const defaultValues: CompanyInfo = {
    company: {
      socialName: '',
      fantasyName: ''
    },
    stateRegistration: {
      number: ''
    },
    address: {
      street: '',
      city: '',
      district: '',
      number: '',
      complement: '',
      state: '',
      ibgeCode: '',
      postalCode: {
        number: '',
        type: '',
        description: ''
      }
    },
    email: '',
    emailConfirm: '',
    password: '',
    optins: {
      email: false
    },
    fiscalId: '',
    sales_enabled: false
  }

  const {
    control,
    register,
    formState: { errors },
    setError,
    handleSubmit,
    watch,
    setValue,
    getValues,
    reset
  } = useForm({
    mode: 'onChange',
    defaultValues: { ...defaultValues }
  })

  const { executeRecaptcha } = useGoogleReCaptcha()

  const [apiErrors, setApiErrors] = useState<ApiErrors[]>()
  const [isRequest, setIsRequest] = useState(false)
  const [visibleCompany, setVisibleCompany] = useState(false)
  const [companyInfo, setCompanyInfo] = useState<CompanyInfo>()
  const [address, setAddress] = useState<CompanyAddress>()
  const [loadingCnpj, setLoadingCnpj] = useState(false)
  const [loadingCep, setLoadingCep] = useState(false)
  const [cepNotFound, setCepNotFound] = useState(false)
  const [addressRules, setAddressRules] = useState<any>()

  const validationAddress = address || companyInfo?.address

  const history = useHistory()

  const { setIsLoggedIn, isCaptchaEnabled } = useSignupContext()
  const { userControls } = useUserResources()

  useEffect(() => {
    apiErrors?.map(({ code, message, messages }) => {
      return setError(code as ErrorFieldNames, {
        type: 'manual',
        message: handleErrorsMessage(message, messages)
      })
    })

    redirectOnUnknownError(errors, history)
  }, [apiErrors, errors, history, setError])

  useEffect(() => {
    if (visibleCompany) {
      mapValues(flattenObject(companyInfo), (value: string, key: any) => {
        return setValue(key, value)
      })

      handleAddressRules(flattenObject(companyInfo.address), true)
    }
  }, [setValue, visibleCompany, companyInfo])

  useEffect(() => {
    if (address) {
      mapValues(address, (value: string, key: string) => {
        // @ts-ignorey
        return setValue(`address.${key}`, value || '')
      })
      handleAddressRules(address, false)
    }
  }, [address, setValue])

  useEffect(() => {
    const number = addressRules?.number?.value
    const complement = addressRules?.complement?.value

    if (number) {
      setValue('address.number', number)
    }

    if (complement) {
      setValue('address.complement', complement)
    }
  }, [addressRules, setValue])

  const handleAddressRules = (
    addressData: CompanyAddress,
    isDataFromNfeio: boolean
  ) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const newAddressRules: any = factoryAddressRules(addressData)

    if (isDataFromNfeio) {
      newAddressRules.complement.disabled = true
    }

    setAddressRules(newAddressRules)
  }

  const handleCompany = async (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    const fiscalId = removeNonNumericCharacters(value)

    if (!validator.isCnpj(value)) {
      setVisibleCompany(false)
      return
    }

    setLoadingCnpj(true)
    setAddress({
      street: '',
      city: '',
      district: '',
      number: '',
      complement: '',
      state: '',
      ibgeCode: '',
      postalCode: {
        number: '',
        type: '',
        description: ''
      }
    })
    reset({ ...defaultValues, fiscalId })

    companyApi
      .getCompany(fiscalId)
      .then((data) => {
        if (!data.sales_enabled) {
          // inactive cnpj
          setVisibleCompany(false)
          setApiErrors([
            {
              code: 'fiscalId',
              message: 'CNPJ inválido'
            }
          ])

          return
        }

        setApiErrors([])
        setCompanyInfo(data)
        setVisibleCompany(true)
      })
      .catch(({ data, status }) => {
        setIsLoggedIn(false)

        if (isServerError(status)) {
          history.push('/erro-interno')
        }

        setApiErrors(data.errors || [])
        setVisibleCompany(false)
      })
      .finally(() => {
        setLoadingCnpj(false)
      })
  }

  const handleCepChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    const cep = removeNonNumericCharacters(value)

    if (cep.length > 7) {
      searchCep(cep)
    }
  }

  const toUpperCaseNumber = (value: string | number) =>
    typeof value === 'string' ? value.toUpperCase() : value

  const handleSubmitPJ = async (value: CompanyInfo) => {
    const data = {
      fiscalId: value.fiscalId,
      socialName: value.company.socialName,
      fantasyName: value.company.fantasyName,
      stateRegistration: value.stateRegistration.number,
      address: {
        ...value.address,
        number: toUpperCaseNumber(value.address.number)
      },
      email: value.email,
      emailConfirm: value.emailConfirm,
      password: value.password,
      optins: {
        email: value.optins.email
      }
    }

    if (!executeRecaptcha) {
      return null
    }

    const recaptchaToken = isCaptchaEnabled
      ? await executeRecaptcha('signUpFormPJ')
      : null

    const csrfToken = document.querySelector('meta[name="csrf-token"]').content

    if (!validationAddress) {
      return setApiErrors([
        { code: 'address.postalCode.number', message: 'CEP não encontrado' }
      ])
    }

    const signupParams = {
      data,
      recaptchaToken,
      csrfToken
    }

    setIsRequest(true)

    signupService
      .signupCompany(signupParams)
      .then(({ data }) => {
        tracker.signUp('site-pj')

        if (userControls?.isOnMobileApp) {
          loginPostMessage(data.token)
        }

        setIsLoggedIn(true)

        dataLayerSubmitFormSuccess()
        history.push({
          pathname: '/sucesso',
          state: {
            redirect: data.redirect,
            signupType: 'pj'
          }
        })
      })
      .catch(({ data, status }) => {
        const dataLayerErrors: any = {}
        if (data.errors && Array.isArray(data.errors)) {
          for (const { code } of data.errors) {
            dataLayerErrors[code] = ''
          }
        }
        onFormInvalid(dataLayerErrors)
        setIsLoggedIn(false)

        if (isServerError(status)) {
          history.push('/erro-interno')
        }

        setApiErrors(data.errors)
      })
      .finally(() => {
        setIsRequest(false)
      })
  }

  const onFormInvalid = (errors: RequestError) => {
    const dataLayerObj = {
      signupType: 'pj',
      fieldsStatus: dataLayer.fieldsStatusFormatterPJ(errors),
      optinsSelected: dataLayer.optinsSelectedFormatterPJ(getValues())
    }
    dataLayer.onSubmitFormWithFieldsErrors(dataLayerObj)
  }

  const dataLayerSubmitFormSuccess = () => {
    const dataLayerObj = {
      signupType: 'pj',
      optinsSelected: dataLayer.optinsSelectedFormatterPJ(getValues())
    }

    dataLayer.onSubmitFormSuccess(dataLayerObj)
  }

  const searchCep = async (cep: string) => {
    setLoadingCep(true)

    reset({
      ...getValues(),
      address: {
        ...defaultValues.address,
        postalCode: {
          ...defaultValues.address.postalCode,
          number: cep
        }
      }
    })

    try {
      const data = await addressApi.getAddressByCep(cep)

      setAddress({
        street: data.street,
        city: data.city,
        district: data.district,
        number: data.number,
        complement: '',
        state: data.state,
        ibgeCode: data.ibgeCode,
        postalCode: {
          number: data.cep,
          type: data.type,
          description: data.description
        }
      })

      setCepNotFound(false)
    } catch (error) {
      setCepNotFound(true)
    }

    setLoadingCep(false)
  }

  return {
    Controller,
    handleCepChange,
    loadingCnpj,
    validator,
    control,
    errors,
    companyInfo,
    validationAddress,
    addressRules,
    visibleCompany,
    loadingCep,
    cepNotFound,
    isRequest,
    validateNumberByState,
    handleSubmit,
    handleCompany,
    hasAccountFiscalId,
    handleSubmitPJ,
    onFormInvalid,
    watch,
    register,
    getValues
  }
}
