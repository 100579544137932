import { css } from '@emotion/react'
import styled from '@emotion/styled'

export const CartLink = styled.a(
  ({ theme }) => css`
    position: relative;

    color: ${theme.colors.p500};
    font-size: 26px;
    margin: 0 2px;

    ${theme.media.giga} {
      font-size: 32px;
      margin: 0;
    }
  `
)

export const CartCount = styled.span(
  ({ theme }) => css`
    align-self: flex-start;
    background: ${theme.colors.r500};
    border-radius: 8px;
    color: ${theme.colors.white};
    font-size: 12px;
    left: -10px;
    margin-bottom: 3px;
    padding: 0 6px;
    position: absolute;
    top: 2px;

    ${theme.media.giga} {
      padding: 2px 6px;
      margin-bottom: 3px;
      position: absolute;
      top: -2px;
    }
  `
)

export const DesktopComplement = styled.div(
  ({ theme }) => css`
      color: ${theme.colors.white};
      display: flex;
      flex-direction: column;
      font-size: 12px;
      margin-left: 10px;
      max-width: 70px;
  `
)

export const Title = styled.h1(
  () => css`
    font-size: 16px;
    margin-bottom: 20px;
  `
)
