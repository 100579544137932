import { call, put, takeLatest } from 'redux-saga/effects'

import * as service from 'lmcv/services/professional'

import { Constants, Actions } from '.'

function * getProfessional () {
  try {
    const customer = yield call(service.getProfessional)

    yield put(Actions.getSuccess(customer))
  } catch (e) {
    yield put(Actions.getFailure(e))
  }
}

export default function * root () {
  yield takeLatest(Constants.PROFESSIONAL_GET, getProfessional)
}
