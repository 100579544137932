import PropTypes from 'prop-types'
import { Text, Icon } from '@leroy-merlin-br/backyard-react'

import { TruckDelivery } from 'shared/icons/TruckDelivery'

import * as S from './styled.js'

const deliveryDaysDictionary = {
  today: 'hoje',
  tomorrow: 'amanhã'
}

const ExpressDeliveryTag = ({ deliveryDate, isPromotional, isSoldOut }) => {
  if (isSoldOut || !deliveryDate) {
    return null
  }

  return (
    <S.Wrapper data-testid="delivery-tag" className={'delivery-tag'} isPromotional={isPromotional} deliveryDay={deliveryDate}>
      <Text as="strong" size="kilo" isBold noMargin>
        Receba {deliveryDaysDictionary[deliveryDate]}
      </Text>

      <Icon as={TruckDelivery} />
    </S.Wrapper>
  )
}

ExpressDeliveryTag.propTypes = {
  deliveryDate: PropTypes.string.isRequired,
  isPromotional: PropTypes.bool,
  isSoldOut: PropTypes.bool
}

export default ExpressDeliveryTag
