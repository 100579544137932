import { Component } from 'react'
import throttle from 'lodash/throttle'
import sortBy from 'lodash/sortBy'

import scroll from 'garden/src/js/utils/scroll'

import { sendEventToAlgolia } from 'scripts/react-components/shared/analytics/algolia.tracker'

import Item from './Item'

const options = [
  {
    anchor: '#caracteristicas-tecnicas',
    text: 'Características Técnicas'
  },
  {
    anchor: '#descricao-do-produto',
    text: 'Descrição do produto'
  },
  {
    anchor: '#avaliacoes',
    text: 'Avaliações'
  },
  {
    anchor: '#produtos-complementares',
    text: 'Produtos complementares'
  },
  {
    anchor: '#conteudos',
    text: 'Conteúdos'
  }
]

const scrollConfig = {
  duration: 600,
  offset: -70,
  a11y: false,
  callback: () => {
    setTimeout(() => {
      window.dispatchEvent(new Event('scroll'))
    }, 1000)
  }
}

const ELEMENT_OFFSET_TO_ACTIVATE = 71

class NavbarProductDetails extends Component {
  constructor (props) {
    super(props)

    this.state = {
      activeLink: null
    }

    this.navBarElement = null
    this.windowScroll = 0
  }

  componentDidMount () {
    this.toggleNavScrollPosition()

    const optionsWithPosition = options.reduce((options, option) => {
      const element = this.getContentElement(option.anchor)
      if (element) {
        options.push({
          ...option,
          position: this.getContentElement(option.anchor).getBoundingClientRect().top
        })
      }

      return options
    }, [])

    const orderedOptions = sortBy(optionsWithPosition, 'position')

    this.setState({ options: orderedOptions })

    window.addEventListener(
      'scroll',
      throttle(this.toggleNavScrollPosition, 200, { trailing: true })
    )
  }

  getContentElement (elementName) {
    const destinationAnchor = `[name="${elementName}"]`.replace('#', '')
    const element = document.querySelector(destinationAnchor)

    if (element) {
      return element
    }

    return false
  }

  toggleNavScrollPosition = () => {
    if (
      this.navBarElement.getBoundingClientRect().top <= 0 &&
      this.navBarWrapperElement.getBoundingClientRect().top < 0
    ) {
      this.navBarElement.classList.add('fixed')
    } else {
      this.navBarElement.classList.remove('fixed')
    }

    this.setActiveAnchor()
  }

  setActiveAnchor = () => {
    const { options } = this.state

    if (!options) {
      return
    }

    let activeOption = options[0]

    options.every((option) => {
      const element = this.getContentElement(option.anchor)
      if (element.getBoundingClientRect().top <= ELEMENT_OFFSET_TO_ACTIVATE) {
        activeOption = option
        return true
      }

      return false
    })

    this.setState({ activeLink: activeOption.anchor })
  }

  renderItems () {
    const { options } = this.state

    if (!options) {
      return null
    }

    return options
      .filter((item) => this.getContentElement(item.anchor))
      .map((item, index) => (
        <Item
          {...item}
          activeLink={this.state.activeLink === item.anchor}
          key={'navbar-item' + index}
          onClick={this.onClickItem(item.anchor)}
        />
      ))
  }

  doScroll (anchor) {
    const element = this.getContentElement(anchor)

    if (element) {
      scroll(element, scrollConfig)
    }
  }

  onClickItem = (anchor) => () => {
    this.doScroll(anchor)
    sendEventToAlgolia()
  }

  setNavElementRef = (element) => {
    this.navBarElement = element
  }

  setNavWrapperElementRef = (element) => {
    this.navBarWrapperElement = element
  }

  render () {
    return (
      <span ref={this.setNavWrapperElementRef}>
        <nav ref={this.setNavElementRef} className="navbar-product-details">
          <ul>
            {this.renderItems()}
          </ul>
        </nav>
      </span>
    )
  }
}

export default NavbarProductDetails
