import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { Theme } from 'scripts/react-components/themes/Theme'

const wrapperStyles = ({ theme }: Theme) => css`
  min-width: 375px;

  ${theme.media.mega} {
    margin: 0 20px;
  }
`

export const Wrapper = styled('div')(wrapperStyles)

const linkWrapperStyles = ({ theme }: Theme) => css`
  label: layout-link-wrapper;

  max-width: 944px;

  & > a {
    color: ${theme.colors.p700};
    position: absolute;
    margin-top: -36px;
  }

  ${theme.media.mega} {
    margin-top: ${theme.spacings.tera};
  }

  @media (min-width: 961px) {
    margin: 0 0 0 190px;
  }
`

export const LinkWrapper = styled('div')(linkWrapperStyles)

const subWrapperStyles = ({ theme }: Theme) => css`
  label: layout-wrapper;

  max-width: 944px;
  background-color: white;

  padding: ${theme.spacings.giga} ${theme.spacings.mega};
  margin-top: -${theme.spacings.mega};

  ${theme.media.mega} {
    border-radius: ${theme.borderRadius.giga};

    margin-bottom: ${theme.spacings.tera};
    padding-left: ${theme.spacings.giga};
    padding-right: ${theme.spacings.giga};
  }

  @media (min-width: 961px) {
    min-height: 470px;

    border: 1px solid ${theme.colors.n100};
    border-radius: ${theme.borderRadius.giga};


    margin: 0 0 ${theme.spacings.tera} 190px;
  }
`

export const SubWrapper = styled('div')(subWrapperStyles)
