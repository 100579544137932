import { Heading, Icon, Spacer, Text } from '@backyard-ui/core'
import { FC } from 'react'
import { CreditCardOutline } from 'frontendMyOrders/user-interface/icons/components'

import formatPriceToBRL from 'utils/formatPriceToBRL'

import * as S from './styled'

type SummaryProps = {
  total: number
  shippingCost: number
  discount: number
  subTotal: number
}

type Props = {
  summary: SummaryProps
  index: number
}

const Summary: FC<Props> = ({ summary, index }: Props) => {
  if (!summary) {
    return <div>Carregando...</div>
  }

  return (
    <S.Wrapper>
      <Icon>
        <CreditCardOutline />
      </Icon>
      <Spacer size={2} axis="vertical" />
      <Heading size="sm" color="neutral-900">
        Resumo da entrega {index + 1}
      </Heading>
      <Spacer size={20} axis="vertical" />
      <S.Description>
        <Text size="lg">Sub-total</Text>
        <Text size="lg">{formatPriceToBRL(summary.subTotal)}</Text>
      </S.Description>
      <S.Description>
        <Text size="lg">Frete</Text>
        <Text size="lg">
          + {formatPriceToBRL(summary.shippingCost)}
        </Text>
      </S.Description>
      <S.Description>
        <Text size="lg">Desconto</Text>
        <Text size="lg">- {formatPriceToBRL(summary.discount)}</Text>
      </S.Description>

      <S.Total>
        <Text size="xl" weight="bold">
          Total
        </Text>
        <Text size="xl" weight="bold" color="green-600">
          {formatPriceToBRL(summary.total)}
        </Text>
      </S.Total>
    </S.Wrapper>
  )
}

export default Summary
