import { useState, useEffect, useRef, useCallback } from 'react'

export const useIntersectionObserver = options => {
  const observer = useRef()
  const [element, setElement] = useState()
  const [observerEntry, setObserverEntry] = useState()

  useEffect(() => {
    observer.current = new IntersectionObserver(([entry]) => {
      setObserverEntry(entry)
    }, options)

    const currentObserver = observer.current

    return () => currentObserver.disconnect()
  }, [options])

  useEffect(() => {
    if (element) {
      const currentObservable = observer.current

      currentObservable.observe(element)

      return () => currentObservable.disconnect()
    }
  }, [element])

  const elementRef = useCallback(node => {
    setElement(node)
  }, [])

  return [elementRef, observerEntry]
}
