import { InputFile as BackyardInputFile } from '@leroy-merlin-br/backyard-react'
import * as S from './styled'

interface InputFileProps {
  isValid?: boolean
  isInvalid?: boolean
  buttonText?: string
  id?: string
  label?: string
  validationHint?: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  accept?: string

  /**
   * Used when file is not loaded, but only it name
   */
  fileName?: string
}

export default function InputFile({ fileName, ...props }: InputFileProps) {
  const Component = BackyardInputFile as any
  return (
    <S.InputWrapper>
      <Component {...props} />
      {fileName && <S.FileName>{fileName}</S.FileName>}
    </S.InputWrapper>
  )
}
