import { Text, Hidden } from '@leroy-merlin-br/backyard-react'

import * as S from './styled'
import { ProductsVisualType } from './type'

const Products = ({
  children
}: React.PropsWithChildren<ProductsVisualType>) => (
  <S.Wrapper>
    <S.Header>
      <Text isBold noMargin>
        Itens do pedido
      </Text>
      <Hidden when={['untilKilo', 'kilo']}>
        <Text align="center" as="span" isBold color="n600" size="kilo" noMargin>
          Quantidade
        </Text>
        <Text align="center" as="span" isBold color="n600" size="kilo" noMargin>
          Valor total
        </Text>
      </Hidden>
    </S.Header>
    {children}
  </S.Wrapper>
)

export default Products
