import { Stack, Text, Icon } from '@leroy-merlin-br/backyard-react'
import { SVGAttributes, useState } from 'react'
import { Popover } from 'user/components'
import { Link } from 'react-router-dom'

import { Shell } from 'shared/components'

import * as S from './styled'

type PersonalDatacardProps = {
  icon: React.ForwardRefExoticComponent<
    SVGAttributes<SVGSVGElement> & React.RefAttributes<SVGSVGElement>
  >
  editId?: string
  tooltipId?: string
  title: string
  description: string
  link?: string
  text?: string
  popover?: boolean
  edit: boolean
  showHide: boolean
  maskFunction?: (value: string) => string
  loading: boolean
}

const PersonalDataCardMobile = ({
  icon,
  editId,
  tooltipId,
  title,
  description,
  link,
  text,
  popover,
  edit,
  showHide,
  maskFunction,
  loading
}: PersonalDatacardProps) => {
  const [showMasked, setShowMasked] = useState(showHide)

  return (
    <S.Wrapper>
      <S.IconWrapper>
        <Icon as={icon} />
      </S.IconWrapper>

      <Stack space="bit">
        {popover ? (
          <Popover
            list={{
              id: tooltipId,
              title: 'Usamos sua data de nascimento para:',
              items: ['Envio de ofertas especiais no seu aniversário.']
            }}
            placement="bottom"
          >
            <Text size="mega" color="n700" noMargin isBold>
              {title}
            </Text>
          </Popover>
        ) : (
          <Text size="mega" color="n700" noMargin isBold>
            {title}
          </Text>
        )}

        {loading ? (
          <Shell borderRadius="kilo" height={24} width={200} />
        ) : (
          <Text noMargin>
            {showMasked && maskFunction ? maskFunction(description) : description}
          </Text>
        )}

        {text && (
          <Text size="kilo" color="n600" noMargin>
            {text}
          </Text>
        )}
      </Stack>
      {edit && (
        <Link to={link} data-gtm-element-id={editId}>
          {loading ? (
            <Shell borderRadius="kilo" height={20} width={40} />
          ) : (
            <Text noMargin isBold color="p700" size="kilo">editar</Text>
          )}
        </Link>
      )}

      {showHide && (
        <button onClick={() => setShowMasked(!showMasked)}>
          {loading ? (
            <Shell borderRadius="kilo" height={20} width={40} />
          ) : (
            <Text noMargin isBold color="p700" size="kilo" align="right">
              {showMasked ? 'mostrar' : 'ocultar'}
            </Text>
          )}
        </button>
      )}
    </S.Wrapper>
  )
}

export default PersonalDataCardMobile
