import { Text, Heading } from '@leroy-merlin-br/backyard-react'

import { ShippingItemUI } from 'frontendCheckout//ui/v1/ShippingItem'
import { numberToPrice } from 'frontendCheckout/utils/formatters'

type ShippingProductType = {
  productName: string
  productQuantity: string
  productId: string
  productUrl: string
  productImageUrl: string
  productUnitPrice: string
  productNetUnitPrice: string
  productTotalUnitPrice: string
  formattedDiscount: string
  productFormattedUnit: string
  installmentsTotalPrice: string
  installmentsAmount: string
  installmentsValue: string
}

export const ShippingProduct = ({
  productName,
  productQuantity,
  productId,
  productUrl,
  productImageUrl,
  productUnitPrice,
  productNetUnitPrice,
  productTotalUnitPrice,
  formattedDiscount,
  productFormattedUnit,
  installmentsTotalPrice,
  installmentsAmount,
  installmentsValue
}: ShippingProductType) => {
  return (
    <ShippingItemUI.Root>
      <ShippingItemUI.Thumb urlImage={productImageUrl} alt={productName} />
      <div className="flex-grow">
        <a href={productUrl} target="_blank">
          <Heading size="kilo" color="g600" as="h3" noMargin>
            {productName}
          </Heading>
        </a>

        <Text size="kilo" color="n400" noMargin>
          Cód. do produto: {productId}
        </Text>

        <ShippingItemUI.Prices>
          {productUnitPrice && (
            <span data-cy="from">
              <Text size="kilo" color="n400" noMargin decoration="line-through">
                1 {productFormattedUnit}{' '}
                {numberToPrice(Number(productUnitPrice))}
                {` ${formattedDiscount}`}
              </Text>
            </span>
          )}

          <span data-cy="to">
            <Text size="kilo" color="n400" noMargin>
              1 {productFormattedUnit}{' '}
              {numberToPrice(Number(productNetUnitPrice))}
              {` ${formattedDiscount}`}
            </Text>
          </span>

          <span data-cy="total">
            <Text size="kilo" isBold color="n900" noMargin>
              {productQuantity.length ? productQuantity : 1} {productFormattedUnit}{' '}
              {numberToPrice(Number(productTotalUnitPrice))}
              {` ${formattedDiscount}`}
            </Text>
          </span>

          {installmentsTotalPrice && (
            <span data-cy="installment">
              <Text size="kilo" isBold color="n900" noMargin>
                ou R$ {installmentsTotalPrice} em até{' '}
                {installmentsAmount}x de{' '}
                R$ {installmentsValue} sem juros
              </Text>
            </span>
          )}
        </ShippingItemUI.Prices>
      </div>
    </ShippingItemUI.Root>
  )
}
