import { PropsWithChildren } from 'react'

import { Component } from '../BaseComponent/BaseComponent'

type DescriptionProps = {}

export const Description = ({
  children
}: PropsWithChildren<DescriptionProps>) => {
  return <Component.Description>{children}</Component.Description>
}
