import { requestStore } from 'utils/request-store'

import instance from './api'
import { API_ENDPOINTS } from './constants'

export interface UserResources {
  email: string
  phone: string
  name: string
  id: string
  user_url: string
  cartItems: string | null
  picture: string | null
  context: 'ecommerce' | 'assisted-sale' | 'app' | 'app-store'
  region: string
  regionId: string
  regionName: string
  selectedRegionName: string
  zipCode: string
  showDisclaimer: boolean
  regionDriveThru: boolean
  storeSlug: string | null
  salesForceId: string | null
  isLegalUser: boolean
  isEmployee: boolean
  locationSource?: 'cloudflare' | 'user' | null
  companyId: string | null
  userTrackingId: string
  loyaltyProgram: {
    active: boolean
    professional: boolean
    favoriteStore: string
  }
}

export const getUserResources = async () => {
  const response = await instance
    .get<UserResources>(API_ENDPOINTS.userResources)
    .then(({ data }) => data)
    .catch((err) => {
      throw err
    })

  return response
}

export const storedGetUserResources = requestStore(getUserResources)
