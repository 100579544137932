import PropTypes from 'prop-types'
import LazyLoad from 'react-lazyload'
import { Badge } from '@leroy-merlin-br/backyard-react'

import { ShapeThumb } from 'shared/constants/prop-types'
import { OptimizedImage } from 'shared/components/OptimizedImage'

import { ThumbFlags } from '../ThumbFlags'
import * as S from './styled'

const ThumbPicture = ({ data, children }) => (
  <S.ThumbnailPicture>
    <S.PatternGradient />

    <LazyLoad offset={200} once>
      <figure>
        <OptimizedImage
          src={data.background_image}
          alt={data.title}
          title={data.title}
          width={300}
          height={300}
          noSrcSet
        />
      </figure>
    </LazyLoad>

    <S.BadgeWrapper>
      <Badge apperance="primary" variant="subtle">
        Inspire-se
      </Badge>
    </S.BadgeWrapper>

    {children}
    <ThumbFlags data={data} />
  </S.ThumbnailPicture>
)

ThumbPicture.propTypes = {
  data: PropTypes.shape({ ...ShapeThumb }).isRequired
}

export default ThumbPicture
