import { forwardRef } from 'react'

import * as S from './styled'

const TooltipIcon = forwardRef(({ ...props }, ref) => {
  return (
    <S.IconWrapper ref={ref} {...props}>
      ?
    </S.IconWrapper>
  )
})

export default TooltipIcon
