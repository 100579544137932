export const Error = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 6C0 2.686 2.68667 0 6 0C9.314 0 12 2.686 12 6C12 9.31333 9.314 12 6 12C2.68667 12 0 9.31333 0 6ZM8.33116 4.59138L7.01097 5.89087L8.33116 7.19036C8.37127 7.24411 8.3909 7.30384 8.3909 7.37039C8.3909 7.43694 8.37127 7.4907 8.33116 7.5308L7.53068 8.33029C7.47777 8.37039 7.4206 8.39087 7.36086 8.39087C7.30112 8.39087 7.24395 8.37039 7.19104 8.33029L5.89133 7.01032L4.59076 8.33029C4.53785 8.37039 4.47726 8.39087 4.4107 8.39087C4.34413 8.39087 4.29037 8.37039 4.25111 8.33029L3.45064 7.5308C3.41053 7.47705 3.3909 7.42073 3.3909 7.36101C3.3909 7.30043 3.41053 7.24411 3.45064 7.19036L4.77083 5.89087L3.45064 4.59138C3.41053 4.53763 3.3909 4.4779 3.3909 4.41135C3.3909 4.34479 3.41053 4.29104 3.45064 4.25094L4.25111 3.45145C4.30402 3.41135 4.3612 3.39087 4.42094 3.39087C4.48068 3.39087 4.53785 3.41135 4.59076 3.45145L5.89133 4.77142L7.19104 3.45145C7.24395 3.41135 7.30454 3.39087 7.3711 3.39087C7.43767 3.39087 7.49058 3.41135 7.53068 3.45145L8.33116 4.25094C8.37127 4.30469 8.3909 4.36101 8.3909 4.42073C8.3909 4.48131 8.37127 4.53763 8.33116 4.59138Z"
        fill="currentColor"
      />
    </svg>
  )
}
