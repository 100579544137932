import { useCallback } from 'react'

import { requestRichRelevanceTracking } from 'catalog/services/track-rich-relevance'

import PriceTag from './components/PriceTag'
import { ProductInfo } from './components/ProductInfo'
import ProductImage from './components/ProductImage'
import AssistedSaleContentManager from './components/assisted-sale/AssistedSaleContentManager'
import * as S from './styled'

const ProductThumb = ({
  product,
  position = 0,
  variableWidth = false,
  onClick = () => {},
  reviewRating = { avgRating: '0', totalReviewsCount: 0 },
  isLoadingReview
}) => {
  const {
    id,
    shortName,
    picture,
    unit,
    inWishList,
    installments,
    isOutlet = false,
    tag,
    offer,
    price,
    url,
    trackingUrl = null,
    assistedSale,
    isSoldOut,
    name
  } = product

  const typeClass = offer ? `type-${offer.type}` : ''
  const outletClass = isOutlet ? 'type-outlet' : ''
  const isSoldOutClass = isSoldOut ? 'type-soldout' : ''
  const productThumbClass = `new-product-thumb ${typeClass} ${outletClass} ${isSoldOutClass}`

  const handleWrapperClick = useCallback(
    event => {
      event.preventDefault()
      const anchorElement = event.target.closest('a')

      const trackClickAndGoToUrl = async () => {
        if (trackingUrl && anchorElement) {
          await requestRichRelevanceTracking(trackingUrl)
        }

        onClick()

        if (anchorElement) {
          window.location.href = url
        }
      }

      trackClickAndGoToUrl()
    },
    [onClick, trackingUrl, url]
  )

  return (
    <S.Wrapper
      variableWidth={variableWidth}
      className={productThumbClass}
      onClick={handleWrapperClick}
    >
      <ProductImage
        id={id}
        tag={tag}
        url={url}
        offer={offer}
        picture={picture}
        shortName={shortName}
        inWishList={inWishList}
        assistedSale={assistedSale}
        price={price}
      />
      <div className="new-product-thumb-info">
        <ProductInfo
          shortName={shortName}
          id={id}
          url={url}
          name={name}
          reviewRating={reviewRating}
          isLoadingReview={isLoadingReview}
        />
        <AssistedSaleContentManager
          {...product}
          isMobileContext={false}
          price={price}
        />
        <PriceTag
          price={price}
          unit={unit}
          installments={installments}
          url={url}
          shortName={shortName}
          isSoldOut={isSoldOut}
        />
      </div>
      <meta itemProp="position" content={position} />
    </S.Wrapper>
  )
}

export default ProductThumb
