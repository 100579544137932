import { ACTIVE_STATUS, PRO_EAD_ACTIVE_STATUS } from './constants'

const LoyaltyStatus = (programCode: number) => {
  const loyaltyStatus = {
    getCode: (): number => programCode,
    isActive: (): boolean => ACTIVE_STATUS.includes(programCode),
    isProEAD: (): boolean => PRO_EAD_ACTIVE_STATUS.includes(programCode),
    is: (isCode: number): boolean => isCode === programCode
  }

  return loyaltyStatus
}

export default LoyaltyStatus
