import { memo, useCallback, useContext, useEffect, useMemo } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import PropTypes from 'prop-types'

import { onCrossItemClick } from 'scripts/utils/data-layer'

import { useCartDrawerContext } from 'site/Header/contexts/CartDrawerContext'

import AssociatedProducts from 'checkout/components/AssociatedProducts/Associated'
import { Actions, Selectors } from 'checkout/redux/modules/associatedProducts'
import { miniCartHotJar } from 'checkout/utils/hotjar'

import { ASSOCIATED_PRODUCT_TYPES } from './utils'

const Container = ({ blackList = [], regionId }) => {
  const { isCartDrawerOpen } = useCartDrawerContext()

  const { products, type, error } = useSelector(
    state => Selectors.associatedData(state),
    shallowEqual
  )

  const dispatch = useDispatch()

  const hasError = useMemo(() => {
    if (typeof error === 'object') {
      return !!Object.keys(error).length
    }

    return false
  }, [error])

  const addProductToCart = useCallback(
    id => {
      dispatch(
        Actions.addAssociatedToCart({
          id,
          regionId,
          sourceName: 'minicart_cross'
        })
      )
    },
    [dispatch, regionId]
  )

  const sendToDataLayer = useCallback(
    ({ name, id }, index) => {
      const productsIds = products.map(current => current.id.toString())

      const dataObject = {
        page: 'Mini cart',
        source: 'own source',
        crossTitle: ASSOCIATED_PRODUCT_TYPES[type],
        productName: name,
        productId: id.toString(),
        productIndex: index,
        productsIds
      }

      onCrossItemClick(dataObject)
    },
    [products, type]
  )

  const onProductNameClick = useCallback(
    (event, product, index) => {
      event.preventDefault()

      sendToDataLayer(product, index)

      window.location.href = product.url
    },
    [sendToDataLayer]
  )

  const onAddToCartClick = useCallback(
    (product, index) => {
      sendToDataLayer(product, index)

      addProductToCart(product.id)
    },
    [addProductToCart, sendToDataLayer]
  )

  useEffect(() => {
    if (isCartDrawerOpen) {
      dispatch(Actions.getAssociateds())
    } else {
      dispatch(Actions.resetAssociated())
    }
  }, [dispatch, isCartDrawerOpen])

  useEffect(() => {
    if (blackList.length) {
      dispatch(Actions.updateAssociatedsBlackList(blackList))
    }
  }, [blackList, dispatch])

  useEffect(() => {
    if (products?.length) {
      miniCartHotJar()
    }
  }, [products])

  if (!products?.length || error?.status) return null

  return (
    <AssociatedProducts
      onAddToCartClick={onAddToCartClick}
      onProductNameClick={onProductNameClick}
      hasError={hasError}
      products={products}
      title={ASSOCIATED_PRODUCT_TYPES[type]}
      blackList={blackList}
    />
  )
}

Container.propTypes = {
  blackList: PropTypes.array.isRequired
}

export default memo(Container)
