import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { Theme } from 'scripts/react-components/themes/Theme'

const containerPassword = ({ theme }: Theme) => css`
  label {
    font-size: 14px;
  }

  form {
    margin-top: ${theme.spacings.tera};
  }
`

export const CreateNewPasswordContainer = styled('div')(containerPassword)

const buttonSubmit = ({ theme }: Theme) => css`
  button {
    margin-top: ${theme.spacings.tera};

    ${theme.media.mega} {
      margin-top: ${theme.spacings.peta};
    }

  }
`

export const ButtonSubmit = styled('div')(buttonSubmit)
