import styled from '@emotion/styled'
import { css } from '@emotion/react'

const wrapperStyles = ({ theme }) => css`
  label: header;

  display: flex;
  align-items: center;

  margin-bottom: ${theme.spacings.mega};

  svg {
    margin-right: ${theme.spacings.mega};
    margin-top: ${theme.spacings.bit};
    cursor: pointer;
  }
`

export const Wrapper = styled('div')(wrapperStyles)
