import { useContext } from 'react'
import PropTypes from 'prop-types'
import { IconButton } from '@backyard-ui/core'

import { WishlistContext } from 'site/Wishlist/context'

const WishListButton = ({ isActive, product, onClick }) => {
  const wishlistValues = useContext(WishlistContext)
  const { onPressHeart, isOnWishlist } = wishlistValues

  const data = {
    inWishList: isActive,
    id: product.id,
    product
  }

  const wishlistAction = (event) => {
    event.stopPropagation()

    onPressHeart(data, 'products')
    if (onClick) {
      onClick(event)
    }
  }

  const inWishlist = isOnWishlist(product.id)

  return (
    <IconButton
      UNSAFE_className="bg-white"
      ariaLabel={`${
        inWishlist ? 'Remover da' : 'Adicionar à'
      } lista de desejos`}
      appearance="critical"
      size="xs"
      variant="subtle"
      icon={
        inWishlist ? (
          <svg
            viewBox="0 0 26 26"
            fill="currentColor"
            data-testid="wishlist-filled"
          >
            <path d="M12.97 23c-.196 0-.397-.041-.572-.139-4.01-2.232-6.868-4.635-8.995-7.563C1.69 12.939.906 10.58 1.009 8.085a7.409 7.409 0 0 1 2.12-4.909 7.287 7.287 0 0 1 4.89-2.169c1.85-.081 3.522.54 4.972 1.847l.05-.04c.395-.308.802-.627 1.261-.886 1.968-1.108 4.26-1.23 6.286-.333 2.112.934 3.684 2.877 4.204 5.198.487 2.17.12 4.376-1.119 6.743-1.262 2.41-3.193 4.564-5.901 6.587-1.007.751-2.08 1.425-3.117 2.077l-.953.6c-.186.12-.455.2-.731.2" />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 13 12"
            data-testid="wishlist-not-filled"
          >
            <path
              fill="currentColor"
              fillRule="evenodd"
              d="M8.61.5c.958 0 1.86.377 2.545 1.063a3.727 3.727 0 0 1 1.09 2.623c.052 1.242-.34 2.428-1.198 3.616-1.165 1.61-4.338 3.66-4.472 3.746a.596.596 0 0 1-.65 0c-.134-.086-3.305-2.136-4.472-3.746C.595 6.614.203 5.424.254 4.159c-.002-.95.396-1.9 1.09-2.596A3.572 3.572 0 0 1 3.885.5h.029c.917 0 1.665.254 2.337.799C6.925.75 7.663.503 8.61.5Zm-.06 1.192c-.674-.017-1.198.193-1.746.717l-.115.107a.72.72 0 0 1-.437.187c-.15 0-.3-.057-.41-.157L5.7 2.412C5.163 1.9 4.662 1.7 3.912 1.7c-.659 0-1.26.252-1.718.711a2.52 2.52 0 0 0-.74 1.772c-.04 1.005.278 1.955.972 2.916.83 1.145 2.991 2.663 3.824 3.225.834-.562 2.995-2.08 3.824-3.225.696-.962 1.014-1.908.972-2.89a2.537 2.537 0 0 0-.74-1.798 2.378 2.378 0 0 0-1.69-.711l-.066-.008Z"
              clipRule="evenodd"
            />
          </svg>
        )
      }
      onClick={wishlistAction}
    />
  )
}

WishListButton.propTypes = {
  isActive: PropTypes.bool,
  product: PropTypes.shape({
    id: PropTypes.number.isRequired,
    price: PropTypes.shape({
      to: PropTypes.shape({
        integers: PropTypes.string,
        decimals: PropTypes.string
      }),
      from: PropTypes.shape({
        integers: PropTypes.string,
        decimals: PropTypes.string
      }),
      regionPrice: PropTypes.shape({
        integers: PropTypes.string,
        decimals: PropTypes.string
      })
    }),
    shortName: PropTypes.string.isRequired
  }).isRequired
}

export default WishListButton
