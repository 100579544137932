import { Fragment } from 'react'
import {
  Card,
  Text,
  Icon,
  Inline,
  Stack,
  TextField,
  Button
} from '@leroy-merlin-br/backyard-react'
import { InfoCircleOutline } from '@backyard-ui/icons'

import { SeparatingEdge } from '../'
import { copyQRCode } from '../../utils'
import * as S from './styled'

const stepByStep = [
  'Acesse seu app de pagamentos',
  'Escolha pagar via PIX',
  'Escaneie o seguinte código'
]

const QRCodeCard = ({ baseQrCode = '', emvQrCode = '' }) => {
  const QRCodeImage = `data:image/png;base64, ${baseQrCode}`

  return (
    <Card target="card" shadow="triple">
      <Stack space="giga">
        <Fragment>
          <S.TitleWrapper>
            <Text color="black" isBold>
              Escaneie este código QR para pagar
            </Text>
          </S.TitleWrapper>

          <SeparatingEdge />
        </Fragment>

        <Inline
          space="mega"
          alignX="space-between"
          alignY="flex-start"
          wrap="nowrap"
        >
          <S.InstructionWrapper>
            <ol>
              {stepByStep.map((step, index) => (
                <li key={index}>
                  <Text as="span" color="black" size="mega" isBold noMargin>
                    {index + 1}. {' '}
                  </Text>
                  <Text as="span" color="black" size="mega" noMargin>
                    {step}
                  </Text>
                </li>
              ))}
            </ol>

            <Inline space="bit" alignY="center">
              <Icon as={InfoCircleOutline} size="kilo" />
              <Text color="black" size="kilo" noMargin>
                Pague e será creditado na hora
              </Text>
            </Inline>
          </S.InstructionWrapper>

          <img src={QRCodeImage} width="160" height="160" alt="pix qrcode" />
        </Inline>

        <SeparatingEdge />

        <Fragment>
          <Text color="black" size="mega" isBold>
            Ou copie este código QR para fazer o pagamento
          </Text>

          <Text color="black" size="kilo">
            Escolha pagar via PIX pelo seu Internet Banking ou app de
            pagamentos. Depois, cole o seguinte código:
          </Text>

          <TextField name="qr-code" size="kilo" value={emvQrCode} readOnly />
        </Fragment>

        <S.ButtonsWrapper>
          <Button
            size="mega"
            onClick={() => copyQRCode(emvQrCode)}
            isDisabled={!emvQrCode}
          >
            Copiar código
          </Button>

          <Button as="a" size="mega" variant="ghost" href="/meus-pedidos">
            Meus pedidos
          </Button>
        </S.ButtonsWrapper>

        <SeparatingEdge />

        <Inline space="byte" alignY="center">
          <Icon as={InfoCircleOutline} size="kilo" />
          <Fragment>
            <Text as="span" color="black" size="kilo" noMargin>
              Seu pedido já foi registrado pela Leroy Merlin e{' '}
            </Text>
            <Text as="span" color="black" size="kilo" isBold noMargin>
              será processado após a confirmação de pagamento.
            </Text>
          </Fragment>
        </Inline>
      </Stack>
    </Card>
  )
}

export default QRCodeCard
