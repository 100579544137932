import $ from 'jquery'

import BaseComponent from 'scripts/components/base-component'

class QuestionMarkUpdater extends BaseComponent {
  static componentName = 'question-mark-updater'

  static DOMEvents = {
    click: 'onElementClick'
  }

  init () {
    this.$facetsContainer = $('[data-facets-overlay-container]')
  }

  onElementClick () {
    const $tooltipElements = this.$facetsContainer.find('[data-tooltip]')

    const tooltips = $.map($tooltipElements, (element) => {
      return $(element).data('popper')
    })

    this.updateTooltipsPosition(tooltips)
  }

  updateTooltipsPosition (tooltips) {
    tooltips.forEach((tooltip) => tooltip.update())
  }
}

export default ($el) => new QuestionMarkUpdater($el).init()

export { QuestionMarkUpdater as Component }
