import { FunctionComponent } from 'react'
import { ArrowRight } from 'frontendCheckout/assets/icons'

type ButtonProps = {
  icon?: FunctionComponent,
  onClick?: () => void
}

export const Button = ({ icon: Icon = ArrowRight, onClick }: ButtonProps) => {
  return (
    <div role="button" onClick={onClick} className="ml-1 cursor-pointer">
      <Icon />
    </div>
  )
}
