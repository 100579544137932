import { useSaleOrder } from 'frontendMyOrders/hooks'
import { Link } from 'react-router-dom'

const SaleOrder = () => {
  const { orders } = useSaleOrder()

  return (
    <div>
      <ul>
        {orders?.map(order => (
          <li key={order.id}>
            <Link to={`/${order.id}`}>Pedido {order.id}</Link>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default SaleOrder
