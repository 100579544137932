import React from 'react'
import {
  SubHeading,
  Button,
  Stack,
  Col,
  Heading,
  Row
} from '@leroy-merlin-br/backyard-react'
import { PasswordField } from 'user/signup/components'
import { InputLoading, Popover } from 'user/components'
import { EMAIL_POPOVER } from 'user/constants'
import * as S from 'user/signup/pages/SignUp/styled'

import { NumberField, TextField } from 'shared/components'

import { TextFieldMargin, ButtonWrapper } from './FormPJ.styles'
import { useFormPj } from './useFormPJ'
import NewsInfo from '../NewsInfo'

export function FormPJ () {
  const {
    handleCepChange,
    loadingCnpj,
    validator,
    control,
    errors,
    companyInfo,
    validationAddress,
    addressRules,
    visibleCompany,
    loadingCep,
    cepNotFound,
    isRequest,
    validateNumberByState,
    handleSubmit,
    handleCompany,
    hasAccountFiscalId,
    handleSubmitPJ,
    onFormInvalid,
    watch,
    register,
    getValues
  } = useFormPj()

  return (
    <form onSubmit={handleSubmit(handleSubmitPJ, onFormInvalid)} data-cy="signup-form-pj">
      <Stack space="mega">
        <Heading size="mega" noMargin>
          1. Dados da empresa
        </Heading>

        <InputLoading isLoading={loadingCnpj}>
          <NumberField
            name="fiscalId"
            label="CNPJ"
            rules={{
              required: 'Insira o CNPJ da empresa',
              validate: (value: string | undefined) =>
                validator.isCnpj(value) || 'CNPJ inválido'
            }}
            control={control}
            state={Boolean(errors.fiscalId) && 'invalid'}
            hint={hasAccountFiscalId(errors, 'CNPJ')}
            format="##.###.###/####-##"
            onChange={handleCompany}
          />
        </InputLoading>

        {visibleCompany && (
          <Stack space="mega">
            <TextField
              name="company.socialName"
              label="Razão social"
              control={control}
              isDisabled
            />
            <TextField
              name="company.fantasyName"
              label="Nome fantasia"
              control={control}
              isDisabled
            />
            <NumberField
              name="stateRegistration.number"
              label="Inscrição estadual"
              control={control}
              isDisabled
            />

            <InputLoading isLoading={loadingCep}>
              <NumberField
                name="address.postalCode.number"
                label="CEP"
                control={control}
                rules={{
                  required: 'Insira seu CEP',
                  validate: (value: string | undefined) =>
                    validator.isCep(value) || 'CEP inválido'
                }}
                onChange={handleCepChange}
                state={
                  (cepNotFound ||
                    Boolean(errors.address?.postalCode?.number)) &&
                  'invalid'
                }
                hint={
                  cepNotFound
                    ? 'CEP não encontrado'
                    : errors.address?.postalCode?.number?.message
                }
                format="#####-###"
                isDisabled={Boolean(companyInfo?.address?.postalCode?.number)}
              />
            </InputLoading>

            {validationAddress && (
              <Stack space="mega">
                <Row>
                  <Col size={{ giga: 8 }}>
                    <TextFieldMargin
                      name="address.street"
                      label="Rua/Avenida"
                      rules={{
                        required:
                          addressRules?.street.required &&
                          'Insira sua rua/avenida',
                        maxLength: {
                          value: 120,
                          message: 'Limite de 120 caracteres'
                        }
                      }}
                      state={Boolean(errors.address?.street) && 'invalid'}
                      hint={errors.address?.street?.message}
                      control={control}
                      isDisabled={addressRules?.street.disabled}
                    />
                  </Col>

                  <Col size={{ giga: 4 }}>
                    <TextField
                      name="address.number"
                      label="Número"
                      control={control}
                      rules={{
                        required:
                          addressRules?.number.required && 'Insira o número',
                        validate: (value: any) => {
                          if (!addressRules?.number.required) {
                            return true
                          }

                          const isValidNumber = validateNumberByState(
                            value,
                            getValues('address.state')
                          )

                          return isValidNumber || 'Número inválido'
                        }
                      }}
                      state={Boolean(errors.address?.number) && 'invalid'}
                      hint={errors.address?.number?.message}
                      isDisabled={addressRules?.number.disabled}
                    />
                  </Col>
                </Row>

                <TextField
                  name="address.complement"
                  label={
                    <S.WrapperComplement>
                      <SubHeading size="kilo" as="h6" noMargin>
                        Complemento <span>- opcional</span>
                      </SubHeading>
                    </S.WrapperComplement>
                  }
                  control={control}
                  isDisabled={addressRules?.complement?.disabled}
                  rules={{
                    maxLength: { value: 50, message: 'Limite de 50 caracteres' }
                  }}
                  state={Boolean(errors.address?.complement) && 'invalid'}
                  hint={errors.address?.complement?.message}
                />

                <TextField
                  name="address.district"
                  label="Bairro"
                  rules={{
                    required:
                      addressRules?.district.required && 'Insira seu bairro',
                    maxLength: {
                      value: 100,
                      message: 'Limite de 100 caracteres'
                    }
                  }}
                  state={Boolean(errors.address?.district) && 'invalid'}
                  hint={errors.address?.district?.message}
                  control={control}
                  isDisabled={addressRules?.district.disabled}
                />

                <Row>
                  <Col size={{ giga: 8 }}>
                    <TextFieldMargin
                      name="address.city"
                      label="Cidade"
                      rules={{
                        required:
                          addressRules?.city.required && 'Insira sua cidade',
                        maxLength: {
                          value: 60,
                          message: 'Limite de 60 caracteres'
                        }
                      }}
                      control={control}
                      isDisabled={addressRules?.city.disabled}
                    />
                  </Col>

                  <Col size={{ giga: 4 }}>
                    <TextField
                      name="address.state"
                      label="Estado"
                      rules={{
                        required:
                          addressRules?.state.required && 'Insira seu estado'
                      }}
                      control={control}
                      isDisabled={addressRules?.state.disabled}
                    />
                  </Col>
                </Row>
              </Stack>
            )}
          </Stack>
        )}

        <Heading size="mega" noMargin>
          2. Dados de acesso
        </Heading>
        <TextField
          name="email"
          type="email"
          label={
            <Popover list={EMAIL_POPOVER.list}>
              E-mail para login
            </Popover>
          }
          rules={{
            required: 'Insira seu e-mail',
            validate: (value: string | undefined) =>
              validator.isEmail(value) || 'E-mail inválido'
          }}
          control={control}
          autoComplete="new-password"
          state={Boolean(errors.email) && 'invalid'}
          hint={errors.email?.message}
        />
        {Boolean(watch('email')) && (
          <TextField
            name="emailConfirm"
            type="email"
            label="Confirmar e-mail"
            rules={{
              required: 'Insira seu e-mail',
              validate: (value: string | undefined) =>
                value === watch('email') || 'Os e-mail precisam ser iguais'
            }}
            onPaste={(e: React.FormEvent<HTMLInputElement>) => {
              e.preventDefault()

              return false
            }}
            control={control}
            autoComplete="new-password"
            state={Boolean(errors.emailConfirm) && 'invalid'}
            hint={errors.emailConfirm?.message}
          />
        )}
        <PasswordField
          name="password"
          label="Senha"
          rules={{
            required: 'Insira sua senha'
          }}
          control={control}
          autoComplete="new-password"
          state={Boolean(errors.password) && 'invalid'}
          hint={errors.password?.message}
        />

        <NewsInfo />
      </Stack>
      <input type="hidden" {...register('address.ibgeCode')} />
      <input type="hidden" {...register('address.postalCode.type')} />
      <input type="hidden" {...register('address.postalCode.description')} />

      <ButtonWrapper>
        <Button isStretch type="submit" isLoading={isRequest} isDisabled={!visibleCompany}>
          Concordar e criar conta
        </Button>
      </ButtonWrapper>
    </form>
  )
}
