import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { Theme } from 'scripts/react-components/themes/Theme'

const buttonWrapperStyles = ({ theme }: Theme) => css`
  label: edit-birthdate-mobile-wrapper;

  & button {
    margin-top: ${theme.spacings.giga};
  }
`

export const ButtonWrapper = styled('div')(buttonWrapperStyles)
