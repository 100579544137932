import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Stack, useModal } from '@leroy-merlin-br/backyard-react'
import { ChevronLeftOutline } from '@backyard-ui/icons'

import { getProject } from 'lmcv/services/customers'
import { Breadcrumb } from 'lmcv/components'

import { ProfileSkeleton } from './components/ProfileSkeleton'
import { Profile } from './components/Profile'
import handleModal from './handleModal'

function Customer ({ match: { params } }) {
  const clientId = params.id
  const [data, setData] = useState({})
  const [loading, setLoading] = useState(true)
  const { setModal } = useModal()

  useEffect(() => {
    getProject(clientId)
      .then(({ data }) => {
        setData(data)
        setLoading(false)
      })
      .catch(e => {
        setLoading(false)
      })
  }, [clientId])

  const PROFILES_COMPONENTS = {
    ativo: Profile.Active,
    concluído: Profile.Completed,
    finalizado: Profile.Finished,
    pendente: Profile.Pending,
    loading: <ProfileSkeleton />
  }

  if (loading) {
    return <ProfileSkeleton />
  }

  const ProfileComponent = PROFILES_COMPONENTS[data.status.toLowerCase()] || (
    <ProfileSkeleton />
  )

  return (
    <Stack>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/clientes">
            <ChevronLeftOutline width={16} height={16} fill="currentColor" />{' '}
            Clientes
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{data.customerName}</Breadcrumb.Item>
      </Breadcrumb>

      <ProfileComponent
        data={data}
        handleDropDown={() =>
          handleModal(setModal, data.id, data.status.toLowerCase())
        }
      />
    </Stack>
  )
}

export default Customer
