import { Tabs, Text } from '@leroy-merlin-br/backyard-react'
import { Fragment } from 'react'

import { CouponData } from '../../types'

type TabsInfoProps = {
  couponData: CouponData
}

const TabsInfo = ({ couponData }: TabsInfoProps) => {
  const tabs = []

  if (couponData.description) {
    tabs.push({
      id: 'description',
      label: 'Descrição',
      panel: (
        <Fragment>
          <Text size="kilo" color="n500" noMargin>
            {couponData.description}
          </Text>
          {couponData.link && (
            <Text size="kilo" as="span" color="p600" noMargin>
              <a href={couponData.link} target="_blank">
                Veja a página do produto
              </a>
            </Text>
          )}
        </Fragment>
      )
    })
  }

  if (couponData.rules) {
    tabs.push({
      id: 'rules',
      label: 'Regulamento',
      panel: (
        <Text size="kilo" color="n500" noMargin>
          {couponData.rules}
        </Text>
      )
    })
  }

  return <Tabs appearance="secondary" items={tabs} />
}

export default TabsInfo
