import {
  Container,
  Hidden,
  Stack,
  Visible
} from '@leroy-merlin-br/backyard-react'
import { Fragment } from 'react'

import { ContextVisibility } from 'scripts/react-components/shared/components'

import {
  Header,
  DownloadAppsCard,
  MyOrdersCard,
  QRCodeCard,
  QRCodeCardMobile,
  ScamsWarningCard,
  ReceiptInfoCard
} from './components'
import * as S from './styled'

const Pix = props => {
  const { orderId, orderRevenue, baseQrCode, emvQrCode, shippings, services } =
    props

  return (
    <Fragment>
      <Header totalPrice={orderRevenue} typePaymentMethod="pix" />
      <Container>
        <S.Wrapper>
          <Stack space="giga">
            <Fragment>
              <Hidden when={['untilKilo', 'kilo']}>
                <QRCodeCard baseQrCode={baseQrCode} emvQrCode={emvQrCode} />
              </Hidden>

              <Visible when={['untilKilo', 'kilo']}>
                <QRCodeCardMobile emvQrCode={emvQrCode} />
              </Visible>
            </Fragment>

            <ScamsWarningCard />

            {shippings.map((shipping, index) => (
              <ReceiptInfoCard key={index} shipping={shipping} services={services} />
            ))}

            <Fragment>
              <ContextVisibility when={['app']}>
                <MyOrdersCard orderId={orderId} />
              </ContextVisibility>

              <ContextVisibility when={['ecommerce']}>
                <Hidden when={['untilKilo', 'kilo']}>
                  <MyOrdersCard orderId={orderId} />
                </Hidden>

                <Visible when={['untilKilo', 'kilo']}>
                  <DownloadAppsCard />
                </Visible>
              </ContextVisibility>
            </Fragment>
          </Stack>
        </S.Wrapper>
      </Container>
    </Fragment>
  )
}

export default Pix
