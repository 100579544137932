import styled from '@emotion/styled'
import { css } from '@emotion/react'

const iconWrapperStyles = ({ theme }) => css`
  label: icon-wrapper;

  svg {
    width: 58px;
    height: 58px;
    color: ${theme.colors.p600};
  }
`

export const IconWrapper = styled('div')(iconWrapperStyles)
