import { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Icon } from '@leroy-merlin-br/backyard-react'
import { ChevronLeftOutline, ChevronRightOutline } from '@backyard-ui/icons'

import * as S from './styled'

const Transparent = ({ prevProps, nextProps }) => (
  <Fragment>
    <S.Box {...prevProps}>
      <Icon as={ChevronLeftOutline} size="giga" />
    </S.Box>
    <S.Box {...nextProps}>
      <Icon as={ChevronRightOutline} size="giga" />
    </S.Box>
  </Fragment>
)

Transparent.propTypes = {
  prevProps: PropTypes.object.isRequired,
  nextProps: PropTypes.object.isRequired
}

export default Transparent
