import { Stack } from '@leroy-merlin-br/backyard-react'

import { Shell } from 'shared/components/Shell'

import * as S from 'lmcv/components/Coupon/styled'
import { Background } from 'lmcv/components/Coupon/components/Background'

const CouponLoading = () => (
  <S.Wrapper>
    <Background isActive={false} />

    <S.Content>
      <S.GroupDescription>
        <S.PictureShellWraper>
          <Shell width={84} height={84} borderRadius="circle" />
        </S.PictureShellWraper>
        <S.Title>
          <Shell width={130} height={19} borderRadius="kilo" />
        </S.Title>

        <S.Description>
          <Shell width={110} height={18} borderRadius="kilo" />
        </S.Description>
      </S.GroupDescription>

      <S.Divider />

      <S.ButtonWrapper>
        <Stack space="byte">
          <Shell height={48} borderRadius="giga" />
          <S.TextFooter>
            <Shell height={18} width={110} borderRadius="kilo" />
          </S.TextFooter>
        </Stack>
      </S.ButtonWrapper>
    </S.Content>
  </S.Wrapper>
)

export default CouponLoading
