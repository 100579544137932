import { Text } from '@leroy-merlin-br/backyard-react'
import PropTypes from 'prop-types'

import { AlertMessage } from 'shared/components/AlertMessage'

const RetryPayment = ({ onButtonClick, orderDate = '' }) => (
  <AlertMessage
    type="warning"
    buttonText="Tentar novamente"
    onButtonClick={onButtonClick}
  >
    <Text as="span" size="kilo" noMargin>
      Infelizmente{' '}
      <Text as="strong" size="kilo" isBold noMargin>
        seu pagamento não foi aprovado{' '}
      </Text>
      , mas você pode tentar pagar seu pedido novamente. Garantimos as mesmas
      condições de preço, frete e estoque{' '}
      <Text as="strong" size="kilo" isBold noMargin>
        até às 22:59 do dia {orderDate}
      </Text>
      .
    </Text>
  </AlertMessage>
)

RetryPayment.propTypes = {
  onButtonClick: PropTypes.func,
  orderDate: PropTypes.string
}

export default RetryPayment
