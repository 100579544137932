import { removeNonNumericCharacters } from '../../../../user/utils'
import request from '../../../services/axios'
import { LegalEntity } from '../types/api/legal-entity'
import { API_ENDPOINTS } from '../../../services/constants'

export const createLegalEntityService = () => {
  const getByDocumentNumber = (fiscalId: string): Promise<LegalEntity> =>
    request
      .get(API_ENDPOINTS.legalEntitySearch, {
        params: {
          bypassLocalCheck: true,
          fiscalId: removeNonNumericCharacters(fiscalId)
        }
      })
      .then((response) => response.data.data)

  return { getByDocumentNumber }
}
