import { css } from '@emotion/react'
import styled from '@emotion/styled'

const scrollAreaStyles = ({ theme }) => css`
  label: terms__scrollable-wrapper;

  display: flex;
  flex-direction: column;

  ${theme.media.mega} {
    max-height: 276px;
  }
`

const termsTextWrapperStyles = ({ theme }) => css`
  label: terms__text;

  margin-top: 8px;
  border-bottom: dashed ${theme.colors.n300} 2px;

  &:last-child {
    border-bottom: none;
  }
`

const footerStyles = ({ theme }) => css`
  label: terms__footer;

  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

  ${theme.media.kilo} {
    flex-direction: row;
  }
`

const buttonsContainerStyles = ({ theme }) => css`
  label: terms__buttons-wrapper;

  width: 100%;

  display: flex;
  justify-content: space-evenly;

  margin-top: ${theme.spacings.mega};

  ${theme.media.kilo} {
    width: auto;

    justify-content: flex-end;

    margin-top: 0;

    button:last-child {
      margin-left: ${theme.spacings.byte};
    }
  }
`

export const Footer = styled('div')(footerStyles)
export const ButtonsContainer = styled('div')(buttonsContainerStyles)
export const ScrollArea = styled('div')(scrollAreaStyles)
export const TermsTextWrapper = styled('div')(termsTextWrapperStyles)
