import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { Theme } from 'scripts/react-components/themes/Theme'

const buttonWrapperStyles = ({ theme }: Theme) => css`
  label: wrapper-button;

  margin-top: ${theme.spacings.mega};
`

export const ButtonWrapper = styled('div')(buttonWrapperStyles)
