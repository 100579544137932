import { Button, Icon } from '@leroy-merlin-br/backyard-react'
import { CrossOutline } from '@backyard-ui/icons'

import * as S from './styled'
import { clickOnConsult, clickedTermsAndConditions } from './ModalBehaviour'
import { DownloadApp } from '../Icons'

type ModalComponentProps = {
  datePromotion: string
  onClose: (event: React.MouseEvent<Element, MouseEvent>) => void
}

const ModalComponent = ({ datePromotion, onClose }: ModalComponentProps) => (
  <S.WrapperModal data-id="promotion-modal">
    <S.Header>
      <S.Icon>
        <DownloadApp />
      </S.Icon>
      <S.CloseButton onClick={onClose}>
        <Icon as={CrossOutline} size="kilo" data-testid="modal-close" />
      </S.CloseButton>

      <S.SubTitle>Veja as condições para ganhar</S.SubTitle>
      <S.Title>R$ 10 de desconto</S.Title>
    </S.Header>
    <S.Body data-id="promotion-modal-body">
      <S.TextBody>Importante você saber:</S.TextBody>
      <S.List>
        <li>
          Será necessário possuir uma conta na Leroy Merlin e ser Cadastrado no
          programa de fidelidade.
        </li>
        <li>
          Será válido apenas para clientes que baixaram o app pela primeira vez
          a partir de {datePromotion}.
        </li>
        <li>O desconto não é acumulativo.</li>
      </S.List>
      <S.TextBody>
        Leia os{' '}
        <a
          href="#"
          onClick={e => clickedTermsAndConditions(e)}
          data-testid="terms-and-conditions"
        >
          Termos e condições
        </a>
      </S.TextBody>
    </S.Body>
    <Button
      size="kilo"
      data-cy="button-modal-promotion"
      data-id="button-modal-promotion"
      onClick={e => clickOnConsult(e)}
    >
      Consultar
    </Button>
  </S.WrapperModal>
)

export default ModalComponent
