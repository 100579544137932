export const EXCLUSIVE_URL_BLOCKED_PARAMS = ['bank']

export const FILTERS_TO_BE_REMOVED = ['filter', 'sorter', 'products']

export const STATUS_TO_REDIRECT = ['422']

export const SWITCH_FILTERS_DICTIONARY = {
  'frete-gratis': 'Com_Frete_Grátis',
  'black-friday': 'Black_Friday',
  ofertas: 'Em_Oferta'
}

export const ENTRYKEY_THUMBS_PER_ROW = 4
export const ENTRYKEY_THUMBS_LINES_PER_PAGE = 9
export const ENTRYKEY_THUMBS_PER_PAGE = ENTRYKEY_THUMBS_PER_ROW * ENTRYKEY_THUMBS_LINES_PER_PAGE

export const buildSearchMessage = (type, term) => {
  const dictionary = {
    strict: {
      prefix: 'Você pesquisou:',
      term: term,
      description:
        'Também encontramos alguns conteúdos relacionados à sua busca, veja abaixo:'
    },
    fuzzy: {
      prefix: 'Você pesquisou:',
      term: term,
      description:
        'Confira abaixo alguns produtos e conteúdos que você pode se interessar'
    },
    imageSearch: {
      term: 'Encontramos alguns produtos similares a sua imagem',
      description: 'Confira abaixo'
    }
  }

  return dictionary[type] || { term: '', description: '' }
}
