import { call, put, takeLatest } from 'redux-saga/effects'

import * as service from 'lmcv/services/module'

import { Constants, Actions } from '.'

function * getModule ({ url }) {
  try {
    const { widget } = yield call(service.getModule, url)

    yield put(Actions.getSuccess(widget))
  } catch (e) {
    yield put(Actions.getFailure(e))
  }
}

export default function * root () {
  yield takeLatest(Constants.MODULE_GET, getModule)
}
