import dayjs from 'dayjs'

export const maskDate = date => {
  return date.replace(/^(\d{2})(\d{2})(\d{4})$/g, '$1/$2/$3')
}

export const isAcceptedAge = field => {
  const [day, month, year] = field.split('/')
  const now = dayjs()

  const age = now.diff(`${year}-${month}-${day}`, 'year')

  const acceptedAge = age >= 18 && age <= 100

  return acceptedAge
}
