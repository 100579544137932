import { AxiosError } from 'axios'

import request from '../../../services/axios'
import { API_ENDPOINTS } from '../../../services/constants'
import { Cashback } from '../types/api/cashback'

export const createCashbackService = () => {
  const getCashback = async (): Promise<Cashback | null> => {
    try {
      const { data } = await request
        .get(API_ENDPOINTS.cashback)
        .then((response) => response.data)

      return data
    } catch (error) {
      if ((error as AxiosError).response?.status === 404) return null
      throw error
    }
  }

  return { getCashback }
}
