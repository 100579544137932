import { tv } from 'tailwind-variants'

export default tv({
  base: `
    fixed
    inset-0
    z-50

    w-full
    h-full
    overflow-y-auto

    duration-300
    transition-transform
    ease-[cubic-bezier(.32,.72,0,1)]
    -translate-x-full
    will-change-transform

    bg-green-600

    lg:relative
    lg:w-auto
    lg:h-auto
    lg:z-0
    lg:transform-none
    lg:bg-transparent
    lg:overflow-y-visible
  `,
  slots: {
    header: `
      inset-x-0
      bottom-0

      w-full
      py-4

      [&_svg]:block
      [&_svg]:w-[55px]
    `,
    container: `
      px-2
      pt-3

      lg:px-0
      lg:py-0

      bg-white
      rounded-t-md

      lg:bg-transparent
    `,
    footer: `
      sticky
      inset-x-0
      bottom-0

      w-[calc(100%+theme('spacing.4'))]

      px-2
      py-4

      -mx-2

      bg-white
      border-t
      border-gray-100
    `
  },
  variants: {
    isOpen: {
      true: 'transform-none'
    }
  }
})
