import styled from '@emotion/styled'
import { css } from '@emotion/react'

const wrapperStyles = ({ theme }) => css`
  label: content-card;

  display: flex;
  flex-direction: column;

  width: 100%;

  overflow-y: hidden;

  margin-bottom: ${theme.spacings.bit};
`

const showMoreWrapperStyles = ({ theme }) => css`
  label: show-more-wrapper;

  margin-top: ${theme.spacings.giga};
  margin-bottom: ${theme.spacings.tera};
`

export const Wrapper = styled('div')(wrapperStyles)
export const ShowMoreWrapper = styled('div')(showMoreWrapperStyles)
