import $ from 'jquery'

import Modal from 'garden/src/js/components/modal'

import BaseComponent from 'scripts/components/base-component'
import componentLoader from 'scripts/component-loader'

const DEFAULTS = {
  triggerData: 'data-payment-methods'
}

class PaymentMethod extends BaseComponent {
  constructor (element) {
    super(element)

    this.sellerLink = this.$element[0].querySelector('[data-seller-link]')
    this.shopId = this.$element[0].dataset.shopId
    this.productId = this.$element[0].dataset.productId
    this.trigger = this.$element[0].querySelector('.payment-trigger')
    this.mobileTrigger = this.trigger.cloneNode(true)
  }

  static componentName = 'payment-method'

  static emitterEvents = {
    'buybox:sellerChange': 'onSellerChange'
  }

  init () {
    this.buildNewTrigger()
    this.render()
    this.setModal()
  }

  setModal () {
    const $modalSelector = $(`[data-modal="${this.shopId}"]`)

    $modalSelector.modal({
      triggerOpen: this.trigger,
      size: 'large'
    })

    componentLoader($modalSelector.data('modal').$content.find('.payment-container'))
  }

  onSellerChange ({ shopId }) {
    const oldShopId = this.shopId
    this.shopId = shopId

    this.destroyOldModal(oldShopId)
    this.setNewModal()
    this.changeMobileTrigger()
  }

  destroyOldModal (shopId) {
    $(`[data-modal="${shopId}"]`).data('modal').destroy()
    this.trigger.removeAttribute(`${DEFAULTS.triggerData}-${shopId}`)
  }

  setNewModal () {
    this.trigger.setAttribute(`${DEFAULTS.triggerData}-${this.shopId}`, '')
    this.setModal()
  }

  buildUrl () {
    const pathID = this.shopId === 'leroy' ? '' : this.shopId
    return `${window.env && window.env.baseHost}/desconto-por-forma-de-pagamento/${this.productId}/${pathID}`
  }

  buildNewTrigger () {
    this.mobileTrigger = this.trigger.cloneNode(true)

    this.mobileTrigger.removeAttribute(DEFAULTS.triggerData)
    this.changeMobileTrigger()
  }

  changeMobileTrigger () {
    this.mobileTrigger.href = this.buildUrl()
  }

  removeTriggerChild () {
    return this.$element.html('')
  }

  render () {
    if (window.innerWidth < 960) {
      this.removeTriggerChild()
      return this.$element[0].appendChild(this.mobileTrigger)
    }
  }
}

export default $el => new PaymentMethod($el).init()

export { PaymentMethod as Component }
