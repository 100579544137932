import BaseComponent from 'scripts/components/base-component'
import {
  pushDataLayerSyncRegisterWithError,
  pushDataLayerSyncRegister
} from 'scripts/utils/data-layer'

class SignSocial extends BaseComponent {
  static componentName = 'sign-social'
  static DOMEvents = {
    submit: 'onSubmit'
  }

  init () {
    this.getFormValidation()
    this.provider = this.$element.data('provider')
  }

  getFormValidation () {
    if (this.validation) {
      return
    }
    this.validation = this.$element.data('validation')
  }

  onSubmit (e) {
    e.preventDefault()

    if (!this.validation.validateAll()) {
      const error = []
      for (const field of this.validation.getFilteredInputs()) {
        if (!this.validation.validate(field)) {
          error.push(field.getAttribute('name'))
        }
      }
      pushDataLayerSyncRegisterWithError(
        this.getLabelProvider(this.provider),
        this.getErrorMapped(error))
      return
    }

    pushDataLayerSyncRegister(this.getLabelProvider(this.provider))
    this.$element[0].submit()
  }

  getErrorMapped (errors) {
    const errorMapped = {
      document_number: 'cpf_invalido',
      name: 'nome_invalido',
      privacy_and_usage_terms: 'optout_termos'
    }

    return errors.map(error => errorMapped[error]).join()
  }

  getLabelProvider (provider) {
    const providerMapped = {
      facebook: 'sociallogin-facebook',
      google: 'sociallogin-google',
      apple: 'sociallogin-apple'
    }

    return providerMapped[provider] ?? 'others'
  }
}

export default ($el) => new SignSocial($el).init()

export { SignSocial as Component }
