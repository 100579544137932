import React from 'react'

import { renderTemplate } from '../render-template'

const EditAddress = ({ index, data, ...address }) => (
  <div className="hidden">
    <div className="container-fluid">
      <div className="modal-title">
        {address?._id ? 'Alterar endereço' : 'Adicionar endereço'}
      </div>

      <fieldset
        className="row"
        data-component="validation account/search-zipcode"
      >
        <input type="hidden" name="index" defaultValue={index} />

        <input
          type="hidden"
          name={`addresses[${index}][ibge_city_code]`}
          defaultValue={address?.ibge_city_code}
        />

        <input
          type="hidden"
          name={`addresses[${index}][postal_code_type]`}
          defaultValue={address?.postal_code_type}
        />

        <input
          type="hidden"
          name={`addresses[${index}][postal_code_description]`}
          defaultValue={address?.postal_code_description}
        />

        {address?.idb_id && (
          <input
            type="hidden"
            name={`addresses[${index}][idb_id]`}
            defaultValue={address?.idb_id}
          />
        )}
        {address?.external_source && (
          <input
            type="hidden"
            name={`addresses[${index}][external_source]`}
            defaultValue={address?.external_source}
          />
        )}
        {address?._id && (
          <input
            type="hidden"
            name={`addresses[${index}][_id]`}
            defaultValue={address?._id}
          />
        )}
        {address?.bucId && (
          <input
            type="hidden"
            name={`addresses[${index}][bucId]`}
            defaultValue={address?.bucId}
          />
        )}

        <div className="col-xs-12">
          <div className="row">
            <div className="col-xs-12">
              <div className="field">
                <input
                  className="input"
                  type="text"
                  name={`addresses[${index}][nickname]`}
                  id={`addresses[${index}][nickname]`}
                  defaultValue={address?.nickname}
                />
                <label
                  className="label"
                  htmlFor={`addresses[${index}][nickname]`}
                >
                  Qual o apelido do endereço?
                </label>
                <span
                  className="helper"
                  aria-describedby={`addresses[${index}][nickname]`}
                >
                  Ex: Casa dos pais, casa de praia, trabalho...
                </span>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xs-12">
              <div className="field" data-validation="field">
                <input
                  className="input"
                  type="text"
                  id={`addresses[${index}][cep]`}
                  name={`addresses[${index}][cep]`}
                  data-mask="00000-000"
                  data-required
                  data-validate="minlength zipcodedata"
                  data-minlength="9"
                  defaultValue={address?.cep}
                  data-trigger="search-zipcode"
                />
                <label className="label" htmlFor={`addresses[${index}][cep]`}>
                  CEP
                </label>
                <span className="helper" data-warning="zipcode"></span>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xs-8">
              <div className="field" data-validation="field">
                <input
                  className="input"
                  type="text"
                  id={`addresses[${index}][street]`}
                  name={`addresses[${index}][street]`}
                  data-required
                  data-validate="required maxlength"
                  data-maxlength="120"
                  maxLength="120"
                  defaultValue={address?.street}
                  disabled
                />
                <label
                  className="label"
                  htmlFor={`addresses[${index}][street]`}
                >
                  Endereço
                </label>
                <span
                  className="helper"
                  aria-describedby={`addresses[${index}][street]`}
                >
                  120 é o limite de caracteres, abrevie se necessário
                </span>
              </div>
            </div>

            <div className="col-xs-4">
              <div className="field" data-validation="field">
                <input
                  className="input"
                  type="text"
                  id={`addresses[${index}][number]`}
                  name={`addresses[${index}][number]`}
                  maxLength="10"
                  data-maxlength="10"
                  defaultValue={address?.number}
                  disabled
                  data-required
                  data-validate="required maxlength addressNumber"
                  data-state-field-id={`addresses[${index}][state]`}
                />
                <label
                  className="label"
                  htmlFor={`addresses[${index}][number]`}
                >
                  Número
                </label>
                <span
                  className="helper"
                  aria-describedby={`addresses[${index}][number]`}
                >
                  Número do endereço
                </span>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xs-12">
              <div className="field" data-validation="field">
                <input
                  className="input"
                  type="text"
                  id={`addresses[${index}][complement]`}
                  name={`addresses[${index}][complement]`}
                  data-required
                  maxLength="50"
                  data-validate="maxlength"
                  defaultValue={address?.complement}
                  disabled
                />
                <label
                  className="label"
                  htmlFor={`addresses[${index}][complement]`}
                >
                  Complemento
                </label>
                <span
                  className="helper"
                  aria-describedby={`addresses[${index}][complement]`}
                >
                  Informe aqui o bloco ou número do apartamento
                </span>
              </div>
            </div>

            <div className="col-xs-12">
              <div className="field" data-validation="field">
                <input
                  className="input"
                  type="text"
                  id={`addresses[${index}][reference]`}
                  name={`addresses[${index}][reference]`}
                  data-required
                  maxLength="50"
                  data-maxlength="50"
                  data-validate="maxlength"
                  defaultValue={address?.reference}
                />
                <label
                  className="label"
                  htmlFor={`addresses[${index}][reference]`}
                >
                  Referência
                </label>
                <span
                  className="helper"
                  aria-describedby={`addresses[${index}][reference]`}
                >
                  Existe algum ponto conhecido próximo ao endereço?
                </span>
              </div>
            </div>

            <div className="col-xs-12">
              <div className="field" data-validation="field">
                <input
                  className="input"
                  type="text"
                  id={`addresses[${index}][district]`}
                  name={`addresses[${index}][district]`}
                  data-required
                  data-validate="required maxlength"
                  data-maxlength="100"
                  maxLength="100"
                  defaultValue={address?.district}
                  disabled
                />
                <label
                  className="label"
                  htmlFor={`addresses[${index}][district]`}
                >
                  Bairro
                </label>
                <span
                  className="helper"
                  aria-describedby={`addresses[${index}][district]`}
                >
                  Em qual bairro está localizado o endereço?
                </span>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xs-8">
              <div className="field" data-validation="field">
                <input
                  className="input"
                  type="text"
                  id={`addresses[${index}][city]`}
                  name={`addresses[${index}][city]`}
                  data-required
                  data-validate="required maxlength"
                  data-maxlength="60"
                  maxLength="60"
                  defaultValue={address?.city}
                  disabled
                />
                <label className="label" htmlFor={`addresses[${index}][city]`}>
                  Cidade
                </label>
                <span
                  className="helper"
                  aria-describedby={`addresses[${index}][city]`}
                >
                  60 é o limite de caracteres, abrevie se necessário
                </span>
              </div>
            </div>

            <div className="col-xs-4">
              <div className="field" data-validation="field">
                <select
                  className="select"
                  name={`addresses[${index}][state]`}
                  id={`addresses[${index}][state]`}
                  data-required
                  data-validate="required"
                  disabled
                  defaultValue={address?.state}
                >
                  <option defaultValue=""></option>
                  {Object.entries(data.states).map(([initials, name]) => (
                    <option key={initials} value={initials}>
                      {name}
                    </option>
                  ))}
                </select>
                <label className="label" htmlFor={`addresses[${index}][state]`}>
                  Estado
                </label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xs-12 inline-field">
              <div className="field input-button">
                <input
                  type="checkbox"
                  id={`addresses[${index}][hasPool]`}
                  name={`addresses[${index}][hasPool]`}
                  defaultValue="true"
                  defaultChecked={address?.hasPool === 'true'}
                />
                <label
                  className="label"
                  htmlFor={`addresses[${index}][hasPool]`}
                >
                  Possuo piscina neste endereço
                </label>
              </div>

              <div className="field input-button">
                <input
                  type="checkbox"
                  id={`addresses[${index}][hasGarden]`}
                  name={`addresses[${index}][hasGarden]`}
                  defaultValue="true"
                  defaultChecked={address?.hasGarden === 'true'}
                />
                <label
                  className="label"
                  htmlFor={`addresses[${index}][hasGarden]`}
                >
                  Possuo jardim neste endereço
                </label>
              </div>
            </div>
          </div>

          <input
            type="hidden"
            name={`addresses[${index}][type]`}
            id={`addresses[${index}][type]`}
            defaultValue={`${address?.type || 'residential'}`}
          />

          <div className="row">
            <div className="col-xs-12">
              <div className="field" data-validation="field">
                <input
                  type="checkbox"
                  name={`addresses[${index}][default]`}
                  id={`addresses[${index}][default]`}
                  defaultValue="1"
                  defaultChecked={
                    address?.default === 'true' ||
                    address?.default === '1' ||
                    index === 0
                  }
                />
                <label
                  className="label"
                  htmlFor={`addresses[${index}][default]`}
                >
                  Este é meu endereço principal
                </label>
              </div>
            </div>
          </div>

          <div className="col-xs-12 modal-footer align-right">
            <button
              className="button button-text"
              type="button"
              data-trigger="close"
            >
              Cancelar
            </button>
            <button
              className="button button-primary"
              type="button"
              data-trigger="submit"
            >
              Salvar endereço
            </button>
          </div>
        </div>
      </fieldset>
    </div>
  </div>
)

const render = (props = {}) => renderTemplate(<EditAddress {...props} />)

export default render
