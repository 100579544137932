import { ServicesCard } from 'shared/components/ServicesCard'

import * as S from './styled'

const ServicesBadge = () => (
  <S.BadgeWrapper>
    <ServicesCard title="Instalamos para você!">
      Consulte o preço ao adicionar no carrinho
    </ServicesCard>
  </S.BadgeWrapper>
)

export default ServicesBadge
