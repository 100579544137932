import { pushDataObject } from '../../data-layer'
import { getItemsJoinedByPipe } from '../../utils'

export type PageType = 'product' | 'cart' | 'shipping'

export type PushShippingCalculationProps = {
  pageType: PageType
  shipping: {
    city?: string
    stateCode?: string
    zipCode?: string
    price: number
    deliveryTime?: number
    seller: string
    items: (string | number)[]
  }
}

export const pushShippingCalculation = ({
  pageType,
  shipping
}: PushShippingCalculationProps) => {
  const items = getItemsJoinedByPipe(shipping.items ? shipping.items : [])

  pushDataObject({
    _clear: true,
    event: 'shipping_calculation',
    shipping: {
      city: shipping.city ? shipping.city : undefined,
      state_code: shipping.stateCode ? shipping.stateCode : undefined,
      cep: shipping.zipCode ? shipping.zipCode : undefined,
      price: shipping.price,
      delivery_time: shipping.deliveryTime ? shipping.deliveryTime : undefined,
      delivery_type: 'delivery',
      seller: shipping.seller,
      items: items,
      page_type: pageType
    }
  })
}
