import * as S from './styled'

const Product = ({ products = [] }) => (
  <S.Wrapper>
    <S.Title>Produtos Relacionados</S.Title>
    <ul>
      {products
        .filter(product => product.price.to !== null)
        .map((product, index) => (
          <S.ProductWrapper key={index}>
            <a href={product.url}>
              <S.Image src={product.image} />
              <div>
                <S.Name>{product.name}</S.Name>
                {product.price.from && (
                  <S.PriceContainer>
                    <S.PriceDiscountFrom>
                      R$ {product.price.from.integers},
                      {product.price.from.decimals} {product.unit}
                    </S.PriceDiscountFrom>
                    <S.PriceDiscountTo>
                      R$ {product.price.to.integers},{product.price.to.decimals}{' '}
                      {product.unit}
                    </S.PriceDiscountTo>
                  </S.PriceContainer>
                )}
                {!product.price.from && (
                  <S.PriceContainer>
                    <S.Price>
                      R$ {product.price.to.integers},{product.price.to.decimals}{' '}
                      {product.unit}
                    </S.Price>
                  </S.PriceContainer>
                )}
              </div>
            </a>
          </S.ProductWrapper>
        ))}
    </ul>
  </S.Wrapper>
)

export default Product
