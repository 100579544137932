import PropTypes from 'prop-types'

import Title from '../generic/Title'
import ListFoldersItems from '../generic/ListFoldersItems'

const URL_WISHLIST = '/minhas-listas-de-desejos'

const StepOne = ({ close, next, status, wishlists, addToList }) => (
  <div className={`wishlist-drawer-${status}`}>
    <Title text="Adicionar à lista de favoritos" close={close} />

    <div className="wishlist-drawer-list-new">
      <button
        onClick={next}
        className="button button-full wishlist-drawer-button no-shadow"
        type="button"
      >
        Criar uma nova lista
      </button>
    </div>

    <div className="wishlist-drawer-divisor">
      <h4>Adicionar à uma lista existente</h4>
    </div>

    <ListFoldersItems
      title="Todas as listas"
      addToList={addToList}
      items={wishlists}
    />

    {wishlists.length > 0 && (
      <div className="wishlist-drawer-list-new">
        <a
          href={URL_WISHLIST}
          className="button-hollow folder-item-button redirect-to-wishlist-button"
        >
          Ver Mais
        </a>
      </div>
    )}
  </div>
)

StepOne.propTypes = {
  close: PropTypes.func.isRequired,
  next: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired
}

export default StepOne
