/**
 * Entry point for webpack.config.js, in order to generate the scripts folder bundle.
 */
import 'regenerator-runtime/runtime'
import $ from 'jquery'
import { datadogRum } from '@datadog/browser-rum'
import SwiperCore, {
  Autoplay,
  Navigation,
  Pagination,
  Mousewheel
} from 'swiper'

import componentLoader from 'scripts/component-loader'

const shouldInitDataDog = function () {
  return window.env && window.env.datadog.enabled === true
}

if (shouldInitDataDog()) {
  const isOnMobileApp = Boolean(
    navigator.userAgent.match('Boitata-App') ||
      document.cookie.match('is_on_mobile_app=true') ||
      window.location.search.match('isOnMobileApp=true')
  )

  datadogRum.init({
    site: 'datadoghq.eu',
    env: window.env.datadog.env,
    service: window.env.datadog.service,
    version: window.env.datadog.version,
    applicationId: window.env.datadog.applicationId,
    clientToken: window.env.datadog.clientToken,
    sessionSampleRate: window.env.datadog.sessionSampleRate,
    sessionReplaySampleRate: window.env.datadog.sessionReplaySampleRate,
    trackUserInteractions: window.env.datadog.trackUserInteractions,
    allowedTracingOrigins: window.env.datadog.tracingOrigins.split(','),
    silentMultipleInit: true
  })

  datadogRum.setRumGlobalContext({
    is_on_mobile_app: isOnMobileApp
  })
}

$.ajaxSetup({
  xhrFields: {
    withCredentials: true
  }
})

SwiperCore.use([Autoplay, Navigation, Pagination, Mousewheel])

/**
 * Uses the @method componentLoader on a jQuery selection of [data-component].
 * In order to use a dynamic [data-component] (Outside of the DOMReady scope), please refer to the component-loader.
 * @param  {element}
 */
componentLoader($('[data-component]'))
