import { datadogRum } from '@datadog/browser-rum'
import { useEffect, useState } from 'react'
import {
  getSaleOrderDetailed,
  Order
} from 'frontendMyOrders/services/sale-order'

const useSaleOrderDetailed = ({ id }: { id: string }) => {
  const [order, setOrder] = useState<Order | null>(null)

  useEffect(() => {
    const fetchSaleOrderDetailed = async () => {
      try {
        const data = await getSaleOrderDetailed({ id })
        if (data) {
          setOrder(data)
        }
      } catch (error) {
        datadogRum.addError(error)
      }
    }

    fetchSaleOrderDetailed()
  }, [id, setOrder])

  return { order }
}

export default useSaleOrderDetailed
