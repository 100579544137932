import { checkShippingType } from 'scripts/utils/data-layer'
import execute from './execute'

export type DataLayerTransaction = {
  products: ProductObject[]
  services: ServiceObject[]
  purchaseObject: PurchaseObject
}

export const dataLayerTransaction = ({
  products,
  services,
  purchaseObject
}: DataLayerTransaction) => {
  const dataLayerObject = {
    event: 'transaction',
    dimension14: purchaseObject.region || '',
    dimension18: purchaseObject.paymentMethod || '',
    dimension19: purchaseObject.installments || '',
    shipping_type: '',
    ecommerce: {
      purchase: {
        actionField: {
          id: purchaseObject.orderId || '',
          affiliation: 'Leroy Merlin Brasil',
          revenue: purchaseObject.revenue.toFixed(2) || '',
          shipping: purchaseObject.freight || 0,
          coupon: purchaseObject.coupon || ''
        },
        products: []
      }
    }
  }

  const dataLayerProducts = dataLayerObject.ecommerce.purchase.products as DataLayerProduct[]

  products.forEach((product) => {
    dataLayerProducts.push({
      name: product.name,
      id: product.id,
      price: product.packagingPrice,
      brand: product.brand,
      category: product.category,
      quantity: product.quantity,
      dimension8: product.deliveryMode || '',
      dimension9: product.storeId || '',
      dimension15: product.marketplace || '',
      dimension16: product.pickupStoreForecast || '',
      dimension17: product.deliveryForecast || '',
      dimension21: product.withDiscount || '',
      dimension22: product.discount || '',
      dimension70: PRODUCT_DISCOUNT_TYPE_TRANSLATE[product.discountType] || ''
    })
  })

  dataLayerObject['shipping_type'] = checkShippingType(dataLayerProducts)

  services.forEach((product) => {
    dataLayerProducts.push({
      name: product.name,
      id: product.id,
      price: product.price,
      brand: product.brand,
      category: product.category,
      quantity: product.quantity,
      dimension8: product.shippementType || '',
      dimension9: product.store || '',
      dimension15: product.marketPlace || '',
      dimension16: product.withdrawalDate || '',
      dimension17: product.shippementDate || '',
      dimension21: product.hasDiscount || '',
      dimension22: product.discountValue || ''
    })
  })

  return dataLayerObject
}

const transaction = (props: DataLayerTransaction) => {
  execute(dataLayerTransaction(props))
}

export default transaction

export type PurchaseObject = {
  region?: string
  paymentMethod?: string
  installments?: string | number
  orderId: string
  revenue: number
  freight: number
  coupon: string
}

export type PRODUCT_DISCOUNT_TYPE =
  | 'stampedPrice'
  | 'employee'
  | 'loyaltyProgram'
  | 'payment-method'
  | 'promo'
  | ''

const PRODUCT_DISCOUNT_TYPE_TRANSLATE: Record<PRODUCT_DISCOUNT_TYPE, string | null> = {
  stampedPrice: 'carimbado',
  employee: 'funcionario',
  loyaltyProgram: 'fidelidade',
  'payment-method': 'pagamento',
  promo: 'cupom',
  '': null
}

export type ProductObject = {
  name: string
  id: number
  price: number
  packagingPrice: string
  brand: string
  category: string
  quantity: number
  deliveryMode: string
  storeId: string
  storeName: string
  marketplace: string
  pickupStoreForecast: string
  deliveryForecast: string
  withDiscount: string
  discount: number
  discountType: PRODUCT_DISCOUNT_TYPE
}

export type ServiceObject = {
  name: string,
  id: number,
  brand: string,
  category: string,
  quantity: number,
  price: number,
  shippementType: 'delivery',
  store: '',
  marketPlace: 'Leroy Merlin',
  withdrawalDate: '',
  shippementDate: '3 dias úteis após a entrega ou retirada do produto' | '3 dias úteis',
  hasDiscount: 'sem-desconto',
  discountValue: '',
  relatedProduct: string,
  discountType: PRODUCT_DISCOUNT_TYPE
}

export type DataLayerProduct = {
  name: string
  id: number
  price: number | string
  brand: string
  category: string
  quantity: number
  dimension8: string
  dimension9: string
  dimension15: string
  dimension16: string
  dimension17: string
  dimension21: string
  dimension22: string | number
  dimension70?: string
}
