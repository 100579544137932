import { PureComponent, Fragment } from 'react'
import classNames from 'classnames'
import LazyLoad from 'react-lazyload'

import { OptimizedImage } from 'shared/components/OptimizedImage'
import { GtmDataAttributesGenerator } from 'shared/tracker/data-attributes-generators'
import { getGTMWidgetType } from 'shared/tracker/utils'

import SalesClick from 'site/utils/dataLayer/Sales/SalesClick'
import { Link as TrackedClickLink } from 'site/Analytics'

import bannerPropTypes from '../../prop-types/banner'
import { PriceBadge, SoldOutBadge, EndTimeBadge } from './components'

class Banner extends PureComponent {
  constructor (props) {
    super(props)

    this.state = {
      isSoldOut: false
    }

    this.salesClick = new SalesClick(
      props,
      props.parentWidgetType,
      props.position
    ).sendClickToDatalayer
  }

  componentDidMount () {
    this.setSoldOut()
  }

  isNotPromotionalStock = () => {
    const { product } = this.props
    return product && product.promotionalStock <= 0
  }

  isNotPromotionalPrice = () => {
    const { product } = this.props
    return product && !product.price.to
  }

  isTimeOver = propName => {
    const now = Math.round(Date.now() / 1000)
    const { schedule } = this.props

    const hasProp = propName in schedule

    const propDictionary = {
      start: hasProp && now < schedule[propName],
      end: hasProp && now >= schedule[propName]
    }

    if (this.isNotPromotionalStock() || this.isNotPromotionalPrice()) {
      return true
    }

    if (schedule) {
      return propDictionary[propName]
    }

    return false
  }

  setSoldOut = () => {
    this.setState({
      isSoldOut: this.isTimeOver('start') || this.isTimeOver('end')
    })
  }

  hasPrice = product => {
    return !!(product && product.price.to)
  }

  renderPriceBadge = () => {
    const { product } = this.props

    if (this.hasPrice(product)) {
      return <PriceBadge price={product.price} unit={product.unit} />
    }

    return null
  }

  renderScheduleBadge = () => {
    const { showCountDown, schedule } = this.props
    const hasScheduleEnd = schedule && schedule.end

    if (hasScheduleEnd && showCountDown) {
      return (
        <EndTimeBadge
          time={new Date(schedule.end * 1000)}
          showCountDown={showCountDown}
          updateBanner={this.setSoldOut}
          category={this.props.parentWidgetType}
        />
      )
    }

    return null
  }

  renderBadges () {
    return (
      <Fragment>
        {this.renderPriceBadge()}
        {this.renderScheduleBadge()}
      </Fragment>
    )
  }

  renderBanner = () => {
    const { pictures, parentWidgetType } = this.props
    const imageUrl = pictures[parentWidgetType].url
    const { width, height } = pictures[parentWidgetType].image_size
    const imageAlt = this.props.alt || pictures[parentWidgetType].alt

    const mobileImageUrl = pictures.mobile_banner
      ? pictures.mobile_banner.url
      : imageUrl

    const { width: mobileImageWidth, height: mobileImageHeight } =
      pictures.mobile_banner
        ? pictures.mobile_banner.image_size
        : pictures[parentWidgetType].image_size

    const { isSoldOut } = this.state

    return (
      <Fragment>
        {isSoldOut && <SoldOutBadge />}

        <LazyLoad height={height} offset={200} once>
          <picture
            onClick={this.salesClick}
            {...GtmDataAttributesGenerator.banner({
              elementType: this.props.elementType,
              type: getGTMWidgetType(this.props.parentWidgetType),
              name: imageAlt,
              index: this.props.index,
              listName: this.props.title
            })}
          >
            <meta itemProp="name" content={imageAlt} />
            <OptimizedImage.Source
              breakpoint={480}
              src={mobileImageUrl}
              width={mobileImageWidth}
              height={mobileImageHeight}
            />
            <OptimizedImage
              src={imageUrl}
              alt={imageAlt}
              width={width}
              height={height}
              noSrcSet
            />
          </picture>
        </LazyLoad>

        {!isSoldOut && this.renderBadges()}
      </Fragment>
    )
  }

  render () {
    const { id, moduleUrl, pictures, parentWidgetType, onClick } = this.props

    const { isSoldOut } = this.state

    const locationRef = window.location.href

    const bannerClass = classNames(
      'offers-widget-banner',
      `offers-widget-banner-${parentWidgetType.replace(/_/g, '-')}`,
      { 'sold-out': isSoldOut }
    )

    const bannerProps = {
      className: bannerClass,
      itemScope: 'itemscope',
      itemType: 'http://www.schema.org/SiteNavigationElement'
    }

    if (!pictures[parentWidgetType]) {
      return null
    }

    if (!moduleUrl) {
      return <div {...bannerProps}>{this.renderBanner()}</div>
    }

    return (
      <div {...bannerProps}>
        <TrackedClickLink
          onClick={onClick}
          itemProp="url"
          href={moduleUrl}
          eventCategory="banner_TV"
          eventAction={`clicou_banner - página ${locationRef}`}
          eventLabel={`${this.props.parentWidgetType}_${moduleUrl}`}
        >
          {this.renderBanner()}
        </TrackedClickLink>
      </div>
    )
  }
}

Banner.propTypes = bannerPropTypes

export default Banner
