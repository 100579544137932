export const Check = () => {
  return (
    <svg
      width="12"
      height="13"
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.6052 1.66588C11.364 1.54588 11.0796 1.56748 10.86 1.72108L10.8384 1.73308L4.866 7.79188C4.8432 7.80268 4.8324 7.79188 4.8108 7.79188L1.1508 4.76788L1.14 4.75708C0.9204 4.59148 0.6348 4.57108 0.3948 4.70188C0.1536 4.83388 0 5.07508 0 5.33788V7.08029C0 7.31069 0.1092 7.51829 0.2844 7.64909L4.4052 11.0583L4.428 11.0691C4.548 11.1567 4.7016 11.1999 4.8432 11.1999C4.986 11.1999 5.1396 11.1567 5.2596 11.0691L5.2824 11.0583L11.7264 4.95388C11.9016 4.82308 12 4.61428 12 4.38508V2.30188C12 2.02828 11.8464 1.78708 11.6052 1.66588Z"
        fill="currentColor"
      />
    </svg>
  )
}
