import { useContext } from 'react'
import {
  Container,
  Card,
  Heading,
  useBreakpoint
} from '@leroy-merlin-br/backyard-react'

import { EntryKeyContext } from '../../context/EntryKeyContext'
import { SEARCH_MESSAGE, MESSAGE } from './consts'
import * as S from './styled'

const NoResultsSearch = ({ isSearch }) => {
  const {
    query: { term }
  } = useContext(EntryKeyContext)

  const { breakpoint } = useBreakpoint()

  const { defaultImagePath } = window.env

  const isMobile = ['untilKilo', 'kilo'].includes(breakpoint)

  const titleSize = isMobile ? 'giga' : 'tera'
  const termSize = isMobile ? 'kilo' : 'mega'
  const message = isSearch ? SEARCH_MESSAGE : MESSAGE

  return (
    <S.Wrapper>
      <Container>
        <Card>
          <S.Main>
            <S.Box>
              <S.Image
                src={`${defaultImagePath}/not-found-result.svg`}
                alt="Error Image"
              />
            </S.Box>

            <Heading
              align="center"
              color="n600"
              size={titleSize}
              as="h1"
              noMargin
            >
              {message}
            </Heading>

            {isSearch && (
              <S.TermWrapper>
                <Heading
                  align="center"
                  color="n600"
                  size={termSize}
                  as="h2"
                  noMargin
                >
                  {`"${term?.value}"`}
                </Heading>
              </S.TermWrapper>
            )}
          </S.Main>
        </Card>
      </Container>
    </S.Wrapper>
  )
}

export default NoResultsSearch
