import { Constants } from '.'

export const get = () => ({
  type: Constants.LOYALTY_PROFILE_GET
})

export const getSuccess = payload => ({
  type: Constants.LOYALTY_PROFILE_GET_SUCCESS,
  payload
})

export const getFailure = error => ({
  type: Constants.LOYALTY_PROFILE_GET_FAILURE,
  error
})

export const setProfilePreferences = payload => ({
  type: Constants.LOYALTY_PROFILE_SET_PREFERENCES,
  payload
})

export const setProfilePreferencesSuccess = () => ({
  type: Constants.LOYALTY_PROFILE_SET_PREFERENCES_SUCCESS
})

export const setProfilePreferencesFailure = error => ({
  type: Constants.LOYALTY_PROFILE_SET_PREFERENCES_FAILURE,
  error
})
