import { Constants } from '.'

export const get = (id, category, active) => ({
  type: Constants.COUPON_GET,
  id,
  category,
  active
})

export const getComplete = payload => ({
  type: Constants.COUPON_GET_COMPLETE,
  payload
})

export const getMoney = (page, status, perPage) => ({
  type: Constants.COUPON_GET_MONEY,
  page,
  status,
  perPage
})

export const getMoneySuccess = payload => ({
  type: Constants.COUPON_GET_MONEY_SUCCESS,
  payload
})

export const getMoneyFailure = error => ({
  type: Constants.COUPON_GET_MONEY_FAILURE,
  error
})

export const getMoneyPaginated = (page, status, perPage) => ({
  type: Constants.COUPON_GET_MONEY_PAGINATED,
  page,
  status,
  perPage
})

export const getMoneyPaginatedSuccess = payload => ({
  type: Constants.COUPON_GET_MONEY_PAGINATED_SUCCESS,
  payload
})

export const getMoneyPaginatedFailure = error => ({
  type: Constants.COUPON_GET_MONEY_PAGINATED_FAILURE,
  error
})

export const getPercentagePaginated = (page, status, perPage) => ({
  type: Constants.COUPON_GET_PERCENTAGE_PAGINATED,
  page,
  status,
  perPage
})

export const getPercentagePaginatedSuccess = payload => ({
  type: Constants.COUPON_GET_PERCENTAGE_PAGINATED_SUCCESS,
  payload
})

export const getPercentagePaginatedFailure = error => ({
  type: Constants.COUPON_GET_PERCENTAGE_PAGINATED_FAILURE,
  error
})

export const getPercentage = (page, status, perPage) => ({
  type: Constants.COUPON_GET_PERCENTAGE,
  page,
  status,
  perPage
})

export const getPercentageSuccess = payload => ({
  type: Constants.COUPON_GET_PERCENTAGE_SUCCESS,
  payload
})

export const getPercentageFailure = error => ({
  type: Constants.COUPON_GET_PERCENTAGE_FAILURE,
  error
})

export const getActivated = (page, category, perPage) => ({
  type: Constants.COUPON_GET_ACTIVATED,
  page,
  category,
  perPage
})

export const getActivatedSuccess = payload => ({
  type: Constants.COUPON_GET_ACTIVATED_SUCCESS,
  payload
})

export const getActivatedFailure = error => ({
  type: Constants.COUPON_GET_ACTIVATED_FAILURE,
  error
})

export const share = (id, inhabitantId, callback) => ({
  type: Constants.COUPON_SHARE,
  id,
  inhabitantId,
  callback
})

export const shareSuccess = () => ({
  type: Constants.COUPON_SHARE_SUCCESS
})

export const shareFailure = (error) => ({
  type: Constants.COUPON_SHARE_FAILURE,
  error
})

export const active = (id, category) => ({
  type: Constants.COUPON_ACTIVE,
  id,
  category
})

export const activeSuccess = (id, category, payload) => ({
  type: Constants.COUPON_ACTIVE_SUCCESS,
  id,
  category,
  payload
})

export const activeFailure = (id, category, error) => ({
  type: Constants.COUPON_ACTIVE_FAILURE,
  id,
  category,
  error
})
