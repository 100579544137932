import { Fragment } from 'react'
import {
  Stack,
  Card,
  Text,
  Icon,
  Inline
} from '@leroy-merlin-br/backyard-react'
import { LocationOutline, TruckDeliveryOutline } from '@backyard-ui/icons'

import { AlertMessage } from 'shared/components/AlertMessage'

import { SeparatingEdge } from '../'
import { formatZipCode } from '../../utils'
import * as S from './styled'
import Drill from '../../../../images/Drill'

const ReceiptInfoCard = ({ shipping, services }) => {
  const { defaultImagePath } = window.env
  const isDelivery = shipping.type === 'delivery'

  const {
    street = '',
    number = '',
    cep = '',
    district = '',
    city = ''
  } = shipping.deliveryAddress || shipping.store

  const mainAddress = `${street}, ${number}`
  const extraAddress = `${district}, ${city} - CEP ${formatZipCode(cep)}`
  const mergedAddress = `${mainAddress} - ${extraAddress}`

  const hasService = !!services.length
  const hasStandaloneService = !!services.find(
    service => !service?.relatedProduct?.id
  )
  const hasAttachedService = !!services.find(
    service => service?.relatedProduct?.id
  )

  const serviceInstallMessage = () => {
    if (!hasStandaloneService) {
      return 'De 1 a 3 dias úteis após a entrega do produto'
    }

    if (hasAttachedService && hasStandaloneService) {
      return 'De 1 a 3 dias úteis*'
    }

    return 'De 1 a 3 dias úteis após a confirmação do pagamento'
  }

  return (
    <Card target="card" shadow="triple">
      <Stack space="giga">
        <Fragment>
          <Text color="black" size="mega" isBold>
            Informações de {isDelivery ? 'entrega' : 'retirada'}
          </Text>

          <SeparatingEdge />
        </Fragment>

        <Fragment>
          <S.AddressWrapper>
            <Inline space="byte" alignY="center" wrap="nowrap">
              <Icon as={LocationOutline} color="black" size="mega" />
              <Text color="black" size="mega" isBold noMargin>
                {isDelivery ? mainAddress : `Loja ${shipping.storeName}`}
              </Text>
            </Inline>
            <Text color="black" size="kilo" noMargin>
              {isDelivery ? extraAddress : mergedAddress}
            </Text>
          </S.AddressWrapper>
          <S.AddressWrapper>
            <Inline space="byte" alignY="center" wrap="nowrap">
              <Fragment>
                {isDelivery ? (
                  <Icon as={TruckDeliveryOutline} color="black" size="mega" />
                ) : (
                  <img
                    src={`${defaultImagePath}/icons/store.svg`}
                    alt="Logo da loja Leroy Merlin"
                  />
                )}
              </Fragment>
              <Text color="black" size="mega" isBold noMargin>
                {isDelivery
                  ? `Entrega ${shipping.deliveryType}`
                  : `Retirar na Loja ${shipping.storeName}`}
              </Text>
            </Inline>
            {shipping.isScheduled && (
              <Text as="span" color="n500" size="kilo" noMargin>
                entrega agendada para o dia{' '}
              </Text>
            )}
            <Text as="span" color="black" size="kilo" noMargin>
              {isDelivery
                ? shipping.time
                : `a partir de ${shipping.time} horas`}{' '}
            </Text>
            {!shipping.isScheduled && (
              <Text as="span" color="n500" size="kilo" noMargin>
                após a confirmação do pagamento
              </Text>
            )}
          </S.AddressWrapper>

          {hasService && (
            <S.AddressWrapper>
              <Inline space="byte" alignY="center" wrap="nowrap">
                <Icon as={Drill} color="black" size="mega" />
                <Text color="black" size="mega" isBold noMargin>
                  Previsão de instalação
                </Text>
              </Inline>
              <Text color="black" size="kilo" noMargin>
                {serviceInstallMessage()}
              </Text>
              {hasAttachedService && hasStandaloneService && (
                <AlertMessage type="warning">
                  *A data de instalação poderá variar para cada serviço
                  contratado. Para serviços atrelados ao produto, a data de
                  instalação será de 1 a 3 dias úteis partir da entrega do seu
                  produto.
                </AlertMessage>
              )}
            </S.AddressWrapper>
          )}
        </Fragment>
      </Stack>
    </Card>
  )
}

export default ReceiptInfoCard
