import { Inline, Stack } from '@leroy-merlin-br/backyard-react'

import { Shell } from 'shared/components/Shell'

import { Widget } from 'site/Modular/components/Widget'

import * as S from './styled'

const Skeleton = () => {
  return (
    <Widget noTopSpacing>
      <S.Container>
        <Stack space="kilo">
          <Shell width={300} height={24} borderRadius="mega" />
          <Inline space="mega" alignX="flex-start">
            <Shell width={200} height={300} borderRadius="mega" />
            <Shell width={200} height={300} borderRadius="mega" />
          </Inline>
        </Stack>
      </S.Container>
    </Widget>
  )
}

export default Skeleton
