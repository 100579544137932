import { Icon } from '../Icon'

const WhatsAppIcon = (props) => (
  <Icon {...props} fill="none" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg" alt="WhatsApp">
    <path d="m1.6692 19.658l1.2532-4.5634c-0.77057-1.3352-1.1812-2.8521-1.177-4.4028 0-4.8592 3.9629-8.8099 8.8319-8.8099 2.3625 0 4.581 0.9169 6.2449 2.5817 1.6682 1.6648 2.5869 3.8789 2.5827 6.2324 0 4.8592-3.9629 8.8099-8.8319 8.8099h-0.0042c-1.4776 0-2.9298-0.3719-4.2212-1.0733l-4.6784 1.2254zm4.8986-2.8225l0.26673 0.1605c1.1262 0.6676 2.4175 1.0183 3.7343 1.0226h0.0042c4.0434 0 7.3373-3.2831 7.3373-7.3226 0-1.9563-0.7621-3.7944-2.1466-5.1803-1.3844-1.3859-3.2304-2.1465-5.1907-2.1465-4.0476 0-7.3415 3.2831-7.3415 7.3225 0 1.3817 0.38528 2.7296 1.122 3.8958l0.17359 0.2788-0.73669 2.6958 2.7774-0.7267z" fill="#fff"/>
    <path d="m1.9741 19.349l1.2109-4.407c-0.74516-1.2888-1.1389-2.755-1.1389-4.2507 0-4.6902 3.8274-8.5057 8.5228-8.5057 2.2821 0 4.4202 0.88732 6.029 2.493 1.6089 1.6056 2.4938 3.7437 2.4938 6.0169 0 4.6901-3.8274 8.5056-8.5228 8.5056h-0.0042c-1.4268 0-2.8282-0.3591-4.073-1.0352l-4.5175 1.1831z" fill="url(#b)"/>
    <path d="m1.6692 19.658l1.2532-4.5634c-0.77057-1.3352-1.1812-2.8521-1.177-4.4028 0-4.8592 3.9629-8.8099 8.8319-8.8099 2.3625 0 4.581 0.9169 6.2449 2.5817 1.6682 1.6648 2.5869 3.8789 2.5827 6.2324 0 4.8592-3.9629 8.8099-8.8319 8.8099h-0.0042c-1.4776 0-2.9298-0.3719-4.2212-1.0733l-4.6784 1.2254zm4.8986-2.8225l0.26673 0.1605c1.1262 0.6676 2.4175 1.0183 3.7343 1.0226h0.0042c4.0434 0 7.3373-3.2831 7.3373-7.3226 0-1.9563-0.7621-3.7944-2.1466-5.1803-1.3844-1.3859-3.2304-2.1465-5.1907-2.1465-4.0476 0-7.3415 3.2831-7.3415 7.3225 0 1.3817 0.38528 2.7296 1.122 3.8958l0.17359 0.2788-0.73669 2.6958 2.7774-0.7267z" fill="url(#a)"/>
    <path d="m8.3672 7.0032c-0.16512-0.36761-0.33871-0.37606-0.49536-0.38028-0.12702-0.00423-0.2752-0.00423-0.42339-0.00423s-0.38528 0.05493-0.58851 0.27465-0.77056 0.75211-0.77056 1.838c0 1.0817 0.79173 2.1296 0.90181 2.2775s1.5284 2.4422 3.7681 3.3253c1.8629 0.7352 2.244 0.5874 2.6462 0.5493 0.4064-0.038 1.304-0.5324 1.4903-1.0479 0.1821-0.5155 0.1821-0.9549 0.127-1.0478-0.055-0.093-0.2032-0.1479-0.4234-0.2578-0.2201-0.1099-1.304-0.6422-1.5072-0.7183-0.2032-0.0718-0.3514-0.1099-0.4954 0.1099-0.1482 0.2197-0.5715 0.714-0.6986 0.8619-0.127 0.1479-0.2582 0.1648-0.4784 0.055-0.2202-0.1099-0.9314-0.3423-1.774-1.0944-0.65625-0.5831-1.1008-1.3056-1.2278-1.5254-0.12702-0.21967-0.0127-0.33798 0.09738-0.44784 0.09738-0.09718 0.22016-0.25775 0.33024-0.38451s0.14819-0.21972 0.22016-0.3676c0.07198-0.14789 0.03811-0.27465-0.01693-0.38451-0.05928-0.10563-0.4869-1.1958-0.68166-1.631z" clipRule="evenodd" fill="#fff" fillRule="evenodd"/>
    <defs>
      <linearGradient id="b" x1="10.536" x2="10.536" y1="19.349" y2="2.186" gradientUnits="userSpaceOnUse">
        <stop stopColor="#20B038" offset="0"/>
        <stop stopColor="#60D66A" offset="1"/>
      </linearGradient>
      <linearGradient id="a" x1="10.536" x2="10.536" y1="19.657" y2="1.8781" gradientUnits="userSpaceOnUse">
        <stop stopColor="#F9F9F9" offset="0"/>
        <stop stopColor="#fff" offset="1"/>
      </linearGradient>
    </defs>
  </Icon>
)

export default WhatsAppIcon
