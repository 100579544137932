import { Shippings, Service, DeliveryAddress } from '../../type'
import { Delivery } from '../Delivery'
import { PickUpInStore } from '../PickUpInStore'
import { StandaloneService } from '../StandaloneService'

const ProductList = ({
  shippings,
  services,
  address
}: {
  shippings: Shippings
  services: Service[]
  address: DeliveryAddress
}) => {
  const hasStandaloneService = !!services.find(
    (service) => !service?.relatedProduct?.id
  )

  return (
    <>
      {shippings.map((shipping, index) => {
        switch (shipping.type) {
          case 'delivery':
            return (
              <Delivery key={index} shipping={shipping} services={services} />
            )
          case 'pickUpInStore':
            return (
              <PickUpInStore
                key={index}
                shipping={shipping}
                services={services}
              />
            )
        }
      })}
      {hasStandaloneService ? (
        <StandaloneService address={address} services={services} />
      ) : null}
    </>
  )
}

export default ProductList
