import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { Theme } from 'scripts/react-components/themes/Theme'

type OptionContainerProps = Theme & { isSelected: boolean }

const optionContainerStyle = ({ theme }: OptionContainerProps) => css`
  label: container;

  button,
  button:hover,
  button:disabled,
  button[disabled] {
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${theme.colors.p700};
    font-weight: ${theme.font.weight.regular};
    background: #C5E4BE;
    box-shadow: 0px 2px 8px 0px rgba(60, 55, 56, 0.07),
      0px 2px 4px 0px rgba(60, 55, 56, 0.07),
      0px 0px 0px 1px rgba(186, 182, 188, 0.07);
  }
`

const optionSelectedContainerStyle = ({
  theme,
  isSelected
}: OptionContainerProps) =>
  isSelected &&
  css`
    label: container-selected;

    & button {
      box-shadow: 0px 0px 0px 2px ${theme.colors.p300} !important;
    }
  `

export const OptionContainer = styled('div')(
  optionContainerStyle,
  optionSelectedContainerStyle
)
