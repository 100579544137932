type PriceToBRLOptions = {
  locales?: string
} & Partial<Intl.NumberFormatOptions>

export function numberToPrice (
  price: number,
  {
    style = 'currency',
    currency = 'BRL',
    locales = 'pt-BR',
    ...props
  }: PriceToBRLOptions = {}
) {
  if (isNaN(price)) {
    return ''
  }

  const formatter = new Intl.NumberFormat(locales, {
    ...props,
    style,
    currency
  })

  return formatter.format(parseFloat(price.toString()))
}
