import { Spinner } from '@leroy-merlin-br/backyard-react'

import * as S from './styled'

const ModalSkeleton = () => {
  return (
    <S.Wrapper>
      <Spinner />
    </S.Wrapper>
  )
}

export default ModalSkeleton
