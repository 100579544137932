import PropTypes from 'prop-types'
import LazyLoad from 'react-lazyload'

import { ShapeThumb } from 'shared/constants/prop-types'
import { OptimizedImage } from 'shared/components/OptimizedImage'

import * as S from './styled'

const IMAGE_SIZE = { width: 369, height: 201 }

const ThumbPicture = ({
  data: { url = '', title = '', background_image: backgroundImage = '' }
}) => (
  <S.PictureWrapper>
    <a href={url} title={title}>
      <LazyLoad offset={200} once>
        <figure>
          <OptimizedImage
            src={backgroundImage}
            alt={title}
            title={title}
            width={IMAGE_SIZE.width}
            height={IMAGE_SIZE.height}
            noSrcSet
          />
        </figure>
      </LazyLoad>
    </a>
  </S.PictureWrapper>
)

ThumbPicture.propTypes = {
  data: PropTypes.shape({ ...ShapeThumb }).isRequired
}

export default ThumbPicture
