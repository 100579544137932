import { css } from '@emotion/react'
import styled from '@emotion/styled'

const wrapperStyles = ({ theme }) => css`
  label: filter-type__wrapper;

  ${theme.media.mega} {
    [target='card'],
    [target='switch-card'] {
      padding: ${theme.spacings.mega} 0;

      & > div {
        overflow-y: hidden;
      }
    }

    [target='switch-card'] {
      & > div {
        padding: 0 ${theme.spacings.mega};
      }
    }

    [target='card'] {
      & > div {
        padding: 0 ${theme.spacings.bit} 0 ${theme.spacings.mega};
      }
    }
  }
`

const blackFridayWrapperStyles = ({ theme, isBlackFriday }) =>
  isBlackFriday &&
  css`
    label: filter-type__wrapper--black-friday;

    [target='switch-card'] {
      background-color: ${theme.colors.n800};
      color: ${theme.colors.white};
    }
  `

const fastDeliveryStyles = ({ theme }) => css`
  label: fast-delivery;

  display: flex;
  align-items: center;

  text-transform: capitalize;

  svg {
    width: 22px;
    height: 22px;

    margin: 0 ${theme.spacings.byte};
  }
`

export const Wrapper = styled('div')(wrapperStyles, blackFridayWrapperStyles)
export const FastDelivery = styled('div')(fastDeliveryStyles)
