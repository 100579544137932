import { useEffect } from 'react'
import PropTypes from 'prop-types'

import Title from '../generic/Title'

const getData = (e, fn) => {
  e.preventDefault()
  const data = new FormData(e.target)
  fn(data)
}

const StepTwo = ({ close, move, modify, status, isEdit }) => {
  const drawerTitle = isEdit ? 'Editar' : 'Criar'

  useEffect(() => {
    return () => {
      document.getElementById('step-two-form')?.reset()
    }
  })

  return (
    <form
      id="step-two-form"
      onSubmit={event => getData(event, modify)}
      className={`wishlist-drawer-finish wishlist-drawer-${status}`}
    >
      <Title
        text={`${drawerTitle} lista de favoritos`}
        close={close}
        move={move}
      />

      <div className="wishlist-drawer-choose-name wishlist-drawer-border">
        <h4>Dê um nome para sua lista:</h4>
        <input
          type="text"
          name="wishlist-name"
          className="wishlist-drawer-input"
          placeholder="Ex: Minha Cozinha"
          required
        />
      </div>

      <div className="wishlist-drawer-send">
        <button className="button button-full wishlist-drawer-button no-shadow">
          {`${drawerTitle} lista de favoritos`}
        </button>
      </div>
    </form>
  )
}

StepTwo.propTypes = {
  close: PropTypes.func.isRequired,
  modify: PropTypes.func.isRequired,
  move: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired
}

export default StepTwo
