import styled from '@emotion/styled'
import { css } from '@emotion/react'

const breadStyles = ({ theme }) => css`
  label: breadcrumb;

  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  padding: 0 ${theme.spacings.mega};
`

const itemStyles = ({ theme }) => css`
  label: item;

  display: flex;
  font-size: 14px;
  color: ${theme.colors.n800};

  & a {
    display: flex;
    align-items: center;
  }

  &:after {
    color: currentColor;
  }

  &:not(:last-of-type) {
    margin-right: ${theme.spacings.giga};
    color: ${theme.colors.p500};

    &:after {
      content: '|';
      position: relative;
      top: -2px;
      left: 12px;
      display: inline-block;
      color: ${theme.colors.n800};
    }
  }

  ${theme.media.mega} {
    margin-right: ${theme.spacings.tera};
    font-size: 16px;
  }
`

export const Breadcrumb = styled('div')(breadStyles)
export const Item = styled('div')(itemStyles)
