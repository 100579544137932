import PropTypes from 'prop-types'
import { Container } from '@leroy-merlin-br/backyard-react'

import * as S from './styled'

const Content = ({ children }) => (
  <S.Content>
    <Container>{children}</Container>
  </S.Content>
)

Content.propTypes = {
  children: PropTypes.node
}

export default Content
