import styled from '@emotion/styled'
import { css } from '@emotion/react'

const imgStyles = ({ theme }) => css`
  label: product__image;

  border: 1px solid ${theme.colors.n400};
  border-radius: ${theme.borderRadius.giga};

  margin-right: ${theme.spacings.kilo};
`

const productTitleStyles = ({ theme }) => css`
  label: product__title;

  width: 100%;
  display: flex;
`

const wrapperStyles = () => css`
  label: product__wrapper;

  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`

const contentStyles = ({ theme }) => css`
  label: product__content;

  width: calc(100% - (82px - ${theme.spacings.kilo}));
`

const wrapperButtonStyles = ({ theme }) => css`
  label: product__button;

  height: 36px;
  width: 170px;

  ${theme.media.mega} {
    width: inherit;
  }

  svg {
    fill: ${theme.colors.p600};

    margin-right: ${theme.spacings.byte};
  }
`

const wrapperContentStyles = ({ theme }) => css`
  label: wrapper__content;

  display: flex;
  flex-direction: column;

  ${theme.media.mega} {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }

  div > .delivery-tag {
    margin-inline: unset;
    position: unset;
  }
`

const containerStyles = () => css`
  label: price-tag__container;

  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;

  width: 100%;
`

export const Img = styled('img')(imgStyles)
export const ProductTitle = styled('div')(productTitleStyles)
export const Content = styled('div')(contentStyles)
export const Wrapper = styled('div')(wrapperStyles)
export const WapperButton = styled('div')(wrapperButtonStyles)
export const WrapperContent = styled('div')(wrapperContentStyles)
export const Container = styled('div')(containerStyles)
