import {
  BathtubOutline,
  KitchenOutline,
  FloorOutline,
  HangerOutline,
  PaintBucketOutline,
  CameraSecurityOutline,
  BroomOutline,
  PendantOutline,
  DrillOutline,
  FaucetOutline,
  WoodOutline,
  AirConditionerOutline,
  DoorHingeOutline,
  ShovelOutline,
  PlugOutline,
  FenceOutline,
  DoorOutline,
  FrameOutline,
  CarpetOutline
} from '@backyard-ui/icons'

export default {
  hasChildIndicator: false,
  DIYRelationType: [
    { value: 'high', label: 'Estou sempre botando a mão na massa!' },
    { value: 'medium', label: 'Gosto, mas não possuo habilidade' },
    { value: 'low', label: 'Não tenho interesse' }
  ],
  homeDecorKnowledgeLevel: [
    {
      value: 'high',
      label: ' Sim, gosto muito de manter minha casa bem decorada'
    },
    { value: 'low', label: 'Sim, mas não conheço muito sobre o assunto' },
    { value: 'none', label: 'Não tenho interesse' }
  ],
  interestAreaName: [
    {
      isSelected: false,
      label: 'Banheiros',
      icon: BathtubOutline,
      value: 'bathroom',
      order: 1
    },
    {
      isSelected: false,
      label: 'Cozinhas e Áreas de Serviço',
      icon: KitchenOutline,
      value: 'kitchen',
      order: 2
    },
    {
      isSelected: false,
      label: 'Pisos e Revestimentos',
      icon: FloorOutline,
      value: 'floor',
      order: 3
    },
    {
      isSelected: false,
      label: 'Organização da Casa',
      icon: HangerOutline,
      value: 'organization',
      order: 4
    },
    {
      isSelected: false,
      label: 'Pintura e Acessórios',
      icon: PaintBucketOutline,
      value: 'paints',
      order: 5
    },
    {
      isSelected: false,
      label: 'Segurança e Comunicação',
      icon: CameraSecurityOutline,
      value: 'security',
      order: 6
    },
    {
      isSelected: false,
      label: 'Manutenção e Limpeza de Casa',
      icon: BroomOutline,
      value: 'maintenance',
      order: 7
    },
    {
      isSelected: false,
      label: 'Iluminação',
      icon: PendantOutline,
      value: 'lighting',
      order: 8
    },
    {
      isSelected: false,
      label: 'Ferramentas',
      icon: DrillOutline,
      value: 'tools',
      order: 9
    },
    {
      isSelected: false,
      label: 'Materiais Hidráulicos',
      icon: FaucetOutline,
      value: 'hydraulic',
      order: 10
    },
    {
      isSelected: false,
      label: 'Madeiras',
      icon: WoodOutline,
      value: 'wood',
      order: 11
    },
    {
      isSelected: false,
      label: 'Climatização e Ventilação',
      icon: AirConditionerOutline,
      value: 'ventilation',
      order: 12
    },
    {
      isSelected: false,
      label: 'Ferragens',
      icon: DoorHingeOutline,
      value: 'ironmongery',
      order: 13
    },
    {
      isSelected: false,
      label: 'Materiais de Construção',
      icon: ShovelOutline,
      value: 'construction',
      order: 14
    },
    {
      isSelected: false,
      label: 'Materiais Elétricos',
      icon: PlugOutline,
      value: 'electrical',
      order: 15
    },
    {
      isSelected: false,
      label: 'Jardim e Varanda',
      icon: FenceOutline,
      value: 'garden',
      order: 16
    },
    {
      isSelected: false,
      label: 'Portas, Janelas e Portões',
      icon: DoorOutline,
      value: 'door',
      order: 17
    },
    {
      isSelected: false,
      label: 'Decoração',
      icon: FrameOutline,
      value: 'decoration',
      order: 18
    },
    {
      isSelected: false,
      label: 'Tapetes',
      icon: CarpetOutline,
      value: 'carpet',
      order: 19
    }
  ]
}
