import { css } from '@emotion/react'
import styled from '@emotion/styled'

const errorNotificationStyles = ({ theme }) =>
  css`
    label: error-notification;

    background-color: ${theme.colors.r100};

    border: 1px solid ${theme.colors.r200};
    border-radius: ${theme.borderRadius.giga};

    padding: ${theme.spacings.byte};
  `

export const ErrorNotification = styled('div')(errorNotificationStyles)
