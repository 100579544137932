import { Fragment } from 'react'
import { Badge, Button, IconButton, Inline } from '@leroy-merlin-br/backyard-react'

import { getPaginationInfo } from './helpers'

const Pagination = (props) => {
  const {
    pageNumbers,
    current,
    firstPage,
    lastPage,
    next,
    prev
  } = getPaginationInfo(props)
  const { setPage } = props

  return (
    <nav className="new-pagination">
      {current !== 1 &&
        <Fragment>
          <IconButton
            appearance="primary"
            onClick={() => setPage(firstPage)}
            type="button"
            variant="link"
            size="kilo"
          >
            <i className="glyph glyph-double-arrow-left" />
          </IconButton>

          <IconButton
            appearance="primary"
            onClick={() => setPage(prev)}
            rel="prev"
            type="button"
            size="kilo"
            variant="link"
          >
            <i className="glyph glyph-arrow-left" />
          </IconButton>
        </Fragment>
      }

      <Inline space="bit">
        {
          pageNumbers.map(({ number }) => {
            if (number === current) {
              return (
                <Button
                  key={number}
                  type="button"
                  variant="link"
                  size="kilo"
                >
                  <Badge
                    appearance="primary"
                    variant="solid"
                  >
                    {number}
                  </Badge>
                </Button>
              )
            }

            return (
              <Button
                appearance="primary"
                key={number}
                onClick={() => setPage(number)}
                type="button"
                variant="link"
                size="kilo"
              >
                {number}
              </Button>
            )
          })
        }
      </Inline>

      {
        current !== lastPage &&
        <Fragment>
          <IconButton
            appearance="primary"
            onClick={() => setPage(next)}
            rel="next"
            type="button"
            size="kilo"
            variant="link"
          >
            <i className="glyph glyph-arrow-right" />
          </IconButton>

          <IconButton
            appearance="primary"
            onClick={() => setPage(lastPage)}
            type="button"
            variant="link"
            size="kilo"
          >
            <i className="glyph glyph-double-arrow-right" />
          </IconButton>
        </Fragment>
      }
    </nav>
  )
}

export default Pagination
