import { Fragment, useState, useCallback, useContext } from 'react'
import { Text } from '@leroy-merlin-br/backyard-react'

import { CustomPriceRange } from './components'
import { FilterOptions, FilterTitle } from '../Filters/components'
import { EntryKeyContext } from '../../context'

const PriceRange = ({ filter, filterTypeIndex }) => {
  const { filterKey, selected } = filter

  const { onOptionClick } = useContext(EntryKeyContext)

  const [usedFilters, setUsedFilters] = useState({
    checkboxFilter: selected,
    inputFilter: false
  })

  const handleInputFilterStatus = useCallback(state => {
    setUsedFilters(prevObj => ({ ...prevObj, inputFilter: state }))
  }, [])

  const handleFilter = (event, currentValues) => {
    event.preventDefault()
    event.stopPropagation()

    const arrayUsedFilters = Object.entries(currentValues).reduce(
      (filters, [key, value], index) => {
        if (value) {
          filters = [
            ...filters,
            {
              type: 'filter',
              name: `${filterKey}-${key}`,
              value: valueFormatting(key, value),
              dataLayerOptions: {
                filter: 'Normal',
                filterType: { index: filterTypeIndex, value: 'precos' },
                filterElement: { index, value: key }
              }
            }
          ]
        }

        return filters
      },
      []
    )

    onOptionClick([...arrayUsedFilters])
  }

  const valueFormatting = (key, originalValue) => {
    if (originalValue % 1 !== 0) {
      const newValue = originalValue.replace(/,/g, '.')

      return key === 'min' ? Math.floor(newValue) : Math.ceil(newValue)
    } else {
      return originalValue
    }
  }

  return (
    <Fragment>
      <Text as="div" size="giga" isBold>
        <FilterTitle filter={filter} />
      </Text>
      <FilterOptions
        {...filter}
        numberOptions={5}
        customizableClickOption={(e, { min, max }) =>
          handleFilter(e, { min, max })
        }
        isDisabled={usedFilters.inputFilter}
      />
      <CustomPriceRange
        onClick={handleFilter}
        onFocus={handleInputFilterStatus}
        isDisabled={usedFilters.checkboxFilter}
      />
    </Fragment>
  )
}

export default PriceRange
