import PropTypes from 'prop-types'

import Carousel from '../../modules/Carousel'
import Department from '../../modules/Department'
import StandardBanners from '../../modules/StandardBanners'
import { LoyaltyCarousel } from '../../modules/LoyaltyCarousel'
import { FullBannerCarousel } from '../../modules/FullBannerCarousel'
import BeInspiredCarousel from '../../modules/BeInspiredCarousel'
import { LastContentsCarousel } from '../../modules/LastContentsCarousel'
import RecommendationCarousel from '../../modules/RecommendationCarousel'
import { LeadCapture } from '../../modules/LeadCapture'
import { Markdown } from '../../modules/Markdown'
import { RetailMediaProductsCarousel } from '../../modules/RetailMediaProductsCarousel'
import { RetailMediaBanner } from '../../modules/RetailMediaBanner'

const ModulesDictionary = ({ data }) => {
  const { type } = data

  const DefaultComponent = type => {
    console.info(`No ${type} component was found`)

    return null
  }

  const modules = {
    markdown: data => <Markdown widget={data} />,
    contentList: () => null,
    offers: data => <Carousel partnerName="elastic search" widget={data} />,
    children: data => <Department widget={data} />,
    vouchers: data => <LoyaltyCarousel widget={data} />,
    beInspired: data => <BeInspiredCarousel widget={data} />,
    departments: data => <Department widget={data} />,
    lastContents: data => <LastContentsCarousel widget={data} />,
    leadCapture: () => <LeadCapture />,
    carousel_full_banner: data => <FullBannerCarousel {...data} />,
    single_informative: data => (
      <StandardBanners full hiddenMobile bannersCount={1} {...data} />
    ),
    full_banner: data => <StandardBanners full bannersCount={1} {...data} />,
    two_banners: data => <StandardBanners bannersCount={2} {...data} />,
    two_banners_wide: data => <StandardBanners bannersCount={2} {...data} />,
    three_banners: data => <StandardBanners bannersCount={3} {...data} />,
    three_informative: data => (
      <StandardBanners hiddenMobile bannersCount={3} {...data} />
    ),
    recommendedProducts: data => <RecommendationCarousel {...data} />,
    retailMediaSponsoredProducts: data => (
      <RetailMediaProductsCarousel {...data} />
    ),
    retailMediaSponsoredBanner: data => <RetailMediaBanner {...data} />,
    default: type => <DefaultComponent type={type} />
  }

  return modules[type]?.call(this, data) || modules.default.call(this, type)
}

ModulesDictionary.propTypes = {
  data: PropTypes.object.isRequired
}

export default ModulesDictionary
