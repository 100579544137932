import { useState, useEffect } from 'react'
import Slider from 'react-slick'
import { PlayCircle, SpinnerCubeOutline } from '@backyard-ui/icons'

import * as dataLayer from 'scripts/utils/data-layer'
import { sendEventToAlgolia } from 'scripts/react-components/shared/analytics/algolia.tracker'

import { type CarouselItem } from '../../types'
import { CarouselItemsNavigator, CurrentCarouselItem } from './'
import * as S from './styled'

type TButton = {
  label: string
  icon: React.FC<React.SVGAttributes<SVGElement>>
  onClick: (event: Event, Slider: Slider) => void
  variant: string
}

type Props = {
  carouselItems: CarouselItem[]
  productId: number
  hasVideos: boolean
  hasSketchFab: boolean
  wishListButton: TButton
}

type CarouselButtonProps = {
  carouselButtons: TButton[]
  slider: Slider
}

const CarouselButtons = ({ carouselButtons, slider }: CarouselButtonProps) => (
  <S.ButtonsWrapper>
    {carouselButtons.map((button, index) => {
      return (
        <S.Button
          data-testid={button.label}
          key={`button-${index}`}
          iconLeft={button.icon}
          appearance="primary"
          variant={button.variant}
          size="kilo"
          onClick={(event: Event) => button.onClick(event, slider)}
        >
          <span>{button.label}</span>
        </S.Button>
      )
    })}
  </S.ButtonsWrapper>
)

const Carousel = ({
  carouselItems,
  productId,
  hasVideos,
  hasSketchFab,
  wishListButton
}: Props) => {
  const [bottomButtons, setBottomButtons] = useState<Array<TButton>>([])
  const [topButtons, setTopButtons] = useState<Array<TButton>>([])
  const [currentCarouselItem, setCurrentCarouselItem] = useState<Slider | null>(
    null
  )

  const [carouselItemsNavigator, setCarouselItemsNavigator] =
    useState<Slider | null>(null)

  const changeSlideByDirection = (direction: 'next' | 'prev') => {
    if (carouselItemsNavigator) {
      sendEventToAlgolia()
      if (direction === 'next') {
        carouselItemsNavigator.slickNext()
      }
      if (direction === 'prev') {
        carouselItemsNavigator.slickPrev()
      }
    }
  }

  useEffect(() => {
    const bottomButtons: Array<TButton> = []
    const topButtons: Array<TButton> = []

    if (hasVideos) {
      bottomButtons.push({
        label: 'Video',
        icon: PlayCircle,
        variant: 'ghost',
        onClick: (event: Event, slider: Slider) => {
          dataLayer.midiasCarouselVideoButtonClick(productId)
          sendEventToAlgolia()

          slider.slickGoTo(
            carouselItems.findIndex(item => item.type === 'video')
          )
        }
      })
    }

    if (hasSketchFab) {
      bottomButtons.push({
        label: '3D do Produto',
        icon: SpinnerCubeOutline,
        variant: 'ghost',
        onClick: (event: Event, slider: Slider) => {
          dataLayer.midiasCarouselSketchfabButtonClick(productId)
          const sketchFabIndex = carouselItems.findIndex(
            item => item.type === 'sketchFab'
          )
          slider.slickGoTo(sketchFabIndex)
        }
      })
    }

    if (wishListButton) {
      topButtons.push(wishListButton)
    }

    bottomButtons && setBottomButtons(bottomButtons)
    topButtons && setTopButtons(topButtons)
  }, [hasVideos, hasSketchFab, wishListButton, carouselItems, productId])

  return (
    <>
      <S.Carousel data-testid="carousel">
        <CarouselItemsNavigator
          productId={productId}
          carouselItems={carouselItems}
          mainSlide={currentCarouselItem}
          changeSlideByDirection={changeSlideByDirection}
          setCarouselItemsNavigator={setCarouselItemsNavigator}
        />

        {carouselItemsNavigator && (
          <S.CurrentCarouselWrapper>
            {topButtons && (
              <CarouselButtons
                carouselButtons={topButtons}
                slider={carouselItemsNavigator}
              />
            )}

            <CurrentCarouselItem
              productId={productId}
              itemsNavigatorSlider={carouselItemsNavigator}
              carouselItems={carouselItems}
              setCurrentCarouselItem={setCurrentCarouselItem}
            />

            {bottomButtons && (
              <CarouselButtons
                carouselButtons={bottomButtons}
                slider={carouselItemsNavigator}
              />
            )}
          </S.CurrentCarouselWrapper>
        )}
      </S.Carousel>
    </>
  )
}

export default Carousel
