import { Fragment, Suspense } from 'react'
import PropTypes from 'prop-types'

import LoaderFolderItem from './../lists/LoaderFolderItem'
import FolderItem from './../lists/FoldersItem'

const ListFoldersItems = ({ title, items, addToList }) => {
  const renderResults = () => (
    <Fragment>
      <h5>{ title }</h5>
      <ul>
        {
          items.map((item) => (
            <li key={ `element-${item.name}` }>
              <Suspense fallback={ <LoaderFolderItem /> }>
                <FolderItem
                  item={ item }
                  addToList={ addToList }
                />
              </Suspense>
            </li>
          ))
        }
      </ul>
    </Fragment>
  )

  const renderNoResults = () => (
    <span>
      Você ainda não possui nenhuma lista.
    </span>
  )

  return (
    <div className="wishlist-drawer-list-all">
      {
        items.length > 0 ? renderResults() : renderNoResults()
      }
    </div>
  )
}

ListFoldersItems.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    contents: PropTypes.number.isRequired,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    products: PropTypes.number.isRequired,
    slug: PropTypes.string.isRequired
  })).isRequired,
  title: PropTypes.string.isRequired
}

export default ListFoldersItems
