import styled from '@emotion/styled'
import { css } from '@emotion/react'

const STATUS_MAP = {
  ativo: 'p500',
  pendente: 'y500',
  finalizado: 'n500',
  concluído: 'n500'
}

const listStyles = () => css`
  label: list;

  list-style: none;
`

const itemStyles = ({ theme }) => css`
  label: list__item;

  padding: ${theme.spacings.giga} 0;

  &:not(:last-of-type) {
    border-bottom: 1px solid ${theme.colors.n100};
  }

  ${theme.media.mega} {
    display: flex;

    padding: ${theme.spacings.mega} 0;

    &:first-of-type {
      padding-top: 0;
    }

    &:last-of-type {
      padding-bottom: 0;
    }
  }
`

const itemColStyles = ({ theme }) => css`
  label: list__item-col;

  display: flex;
  align-items: center;

  &:nth-of-type(1) {
    width: 100%;
  }

  &:nth-of-type(2),
  &:nth-of-type(3) {
    width: 30%;
    display: none;
  }

  &:nth-of-type(3) {
    justify-content: flex-end;
  }

  ${theme.media.mega} {
    &:nth-of-type(1) {
      width: 40%;
    }

    &:nth-of-type(2),
    &:nth-of-type(3) {
      display: flex;
    }
  }
`

const infoStyles = ({ theme }) => css`
  label: list__info;

  margin-left: ${theme.spacings.mega};

  ${theme.media.mega} {
    margin-left: ${theme.spacings.giga};
  }
`

const statusStyles = ({ theme, color }) => css`
  label: info__status;

  position: relative;
  display: flex;
  align-items: center;

  color: ${!color ? theme.colors.n800 : theme.colors[STATUS_MAP[color]]};

  &:before {
    content: '';

    display: inline-block;

    width: 8px;
    height: 8px;

    margin-right: ${theme.spacings.byte};
    background: currentColor;
    border-radius: ${theme.borderRadius.circle};
  }
`

const dividerStyles = ({ theme }) => css`
  label: list__divider;

  position: relative;

  width: 100%;
  height: 1px;

  background: ${theme.colors.n100};
  margin: ${theme.spacings.mega} 0;

  ${theme.media.mega} {
    margin: ${theme.spacings.giga} 0;
  }
`

const footerStyles = ({ theme }) => css`
  label: list__footer;

  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  button {
    width: 100%;
  }

  ${theme.media.mega} {
    flex-direction: row;

    button {
      width: auto;
    }
  }
`
const textFooterStyles = ({ theme }) => css`
  label: list__text-footer;

  display: block;

  margin-bottom: ${theme.spacings.mega};

  color: ${theme.colors.n600};
  font-size: ${theme.typography.text.mega.fontSize};
  text-align: center;

  ${theme.media.mega} {
    text-align: left;
    margin-bottom: 0;
  }
`

export const List = styled('ul')(listStyles)
export const Item = styled('li')(itemStyles)
export const ItemCol = styled('div')(itemColStyles)
export const Info = styled('div')(infoStyles)
export const Status = styled('span')(statusStyles)
export const Divider = styled('div')(dividerStyles)
export const Footer = styled('div')(footerStyles)
export const TextFooter = styled('span')(textFooterStyles)
