import { css } from '@emotion/react'
import styled from '@emotion/styled'

const descriptionButtonStyles = () => css`
  label: filter-title__description-button;

  cursor: pointer;
  display: inline-block;
`

const badgeWrapperStyles = () => css`
  label: filter-title__badge-wrapper;

  display: flex;
  align-items: center;
`

export const DescriptionButton = styled('span')(descriptionButtonStyles)
export const BadgeWrapper = styled('div')(badgeWrapperStyles)
