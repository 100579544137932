import { Heading, Button, Card, Text } from '@leroy-merlin-br/backyard-react'

import { Carousel } from './components'
import * as S from './styled'

function JoinProgram () {
  return (
    <Card>
      <S.Img
        src={`${window &&
          window.env.assetHost}assets/images/lmcvNew/locked.jpg`}
      />
      <Heading align="center" size="mega" noMargin>
        Essa área é exclusiva para membros do{' '}
        <a
          href="/fidelidade/como-funciona"
          target="_blank"
          rel="noopener noreferrer"
        >
          Programa Fidelidade
        </a>
        !
      </Heading>
      <Text color="n600" align="center" size="kilo">
        Além de juntar pontos, só membros tem acesso à vantagens exclusivas
        como:
      </Text>

      <Carousel />

      <S.ButtonContent>
        <Button
          size="mega"
          href="/fidelidade/participar"
          rel="noopener noreferrer"
          as="a"
        >
          Quero Participar!
        </Button>
      </S.ButtonContent>
    </Card>
  )
}

export default JoinProgram
