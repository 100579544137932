import $ from 'jquery'
import errorsTemplate from 'templates/feedback-errors'
import ListPhone from 'templates/account/list-phone'
import EditPhone from 'templates/account/edit-phone'
import ListAddress from 'templates/account/list-address'
import EditAddress from 'templates/account/edit-address'
import ListPhonePJ from 'templates/account/list-phone-pj'
import EditPhonePJ from 'templates/account/edit-phone-pj'
import ListAddressPJ from 'templates/account/list-address-pj'
import EditAddressPJ from 'templates/account/edit-address-pj'

import componentLoader from 'scripts/component-loader'
import mask from 'scripts/components/mask'
import addressTypes from 'scripts/constants/address-types'
import states from 'scripts/constants/states'
import toObject from 'scripts/utils/account/array-to-object'
import confirm from 'scripts/utils/account/confirm'
import getInputValues from 'scripts/utils/account/get-input-values'
import emitter from 'scripts/utils/emitter'
import BaseComponent from 'scripts/components/base-component'
import { showSpinner, hideSpinner } from 'scripts/utils/spinner'

const componentsMap = {
  'list-phone': ListPhone,
  'edit-phone': EditPhone,
  'list-address': ListAddress,
  'edit-address': EditAddress,
  'list-phone-pj': ListPhonePJ,
  'edit-phone-pj': EditPhonePJ,
  'list-address-pj': ListAddressPJ,
  'edit-address-pj': EditAddressPJ
}

class List extends BaseComponent {
  static componentName = 'account-list'

  static DOMEvents = {
    'click [data-trigger="modal"]': 'onModalClick',
    'click [data-trigger="remove"]': 'onRemoveClick'
  }

  init () {
    this.$container = this.$element.find('[data-list="container"]')
    this.listType = this.$element.attr('data-list-type')
    this.listName = this.$element.attr('data-array-name')
    this.ajaxURL = this.$element.attr('data-ajax-url')

    this.fieldset = this.$container.find('[data-list-item]')

    this.components = this.getComponents()

    this.listTemplate = componentsMap[`list-${this.listType}`]
    this.editTemplate = componentsMap[`edit-${this.listType}`]

    this.itemList = this.getItemList()
  }

  getComponents () {
    const $form = $('[data-form="account"]')

    return {
      form: $form.data('form')
    }
  }

  bindModalListeners () {
    this.$submit.on('click', this.onModalSubmit.bind(this))
  }

  onModalClick (e) {
    this.unsetModal()

    this.setModal(this.setVariables(e))
  }

  onRemoveClick (e) {
    const itemList = this.getItemList()

    const itemIndex = $(e.target).closest('[data-list-item="phone"]').index()
    const phoneType = itemList[itemIndex].type

    if (phoneType === 'landline') {
      confirm(
        this.onRemoveClickConfim.bind(this, e.target),
        'Deseja remover esse item?'
      )
    } else if (phoneType === 'cellphone') {
      const cellphones = itemList.filter(item => item.type === 'cellphone')

      if (cellphones.length > 1) {
        confirm(
          this.onRemoveClickConfim.bind(this, e.target),
          'Deseja remover esse item?'
        )
      } else {
        emitter.emit('alertModal', {
          title: 'Não é possível remover este telefone',
          description:
            'É preciso ter pelo menos um celular cadastrado em sua conta. Adicione um novo telefone celular para conseguir fazer a remoção.'
        })
      }
    }
  }

  getItemList () {
    return Array.prototype.map.call(
      this.$container.find('[data-list-item]'),
      field => {
        return this.parseObject(toObject($(field).serializeArray()))
      }
    )
  }

  onRemoveClickConfim (target, confirmation) {
    if (confirmation) {
      target.closest('[data-list-item]').remove()
      this.sendRemoveForm(this.targetIndex)
    }
  }

  onModalSubmit () {
    if (this.components.validation.validateAll()) {
      this.sendForm()
    }
  }

  onSendSuccess (data) {
    hideSpinner()
    this.updateList(data.user[this.listName])
    this.components.modal.hide()

    emitter.emit('stickyFeedback:success', {
      title: 'Pronto!',
      content: 'Informações atualizadas com sucesso.'
    })

    delete this._tempItemList
  }

  onSendError (data) {
    hideSpinner()

    const errors =
      data.responseJSON?.errors?.length > 0
        ? data.responseJSON.errors
        : ['Ocorreu um erro inesperado. Tente novamente mais tarde.']

    emitter.emit('stickyFeedback:error', {
      title: 'Ops! Ocorreram os seguintes erros:',
      content: errorsTemplate({ errors })
    })

    delete this._tempItemList
  }

  onRemoveSuccess (data) {
    hideSpinner()
    this.updateList(data.user[this.listName])

    emitter.emit('stickyFeedback:success', {
      title: 'Pronto!',
      content: 'Informações atualizadas com sucesso.'
    })

    delete this._tempItemList
  }

  unsetModal () {
    if (this.components.modal) {
      this.components.modal.destroy()
      return delete this.components.modal
    }
  }

  setVariables (e) {
    const $fieldset = $(e.target).closest('[data-list-item]')
    let variables = {}

    if ($fieldset.length) {
      variables = this.getVariables($fieldset)
      variables.index = $fieldset.index()
    }

    const isNewItem = !variables.index && variables.index !== 0

    if (isNewItem) {
      variables.index = this.itemList.length
    }

    if (this.isAddressType()) {
      variables.data = {}
      variables.data.addressTypes = addressTypes
      variables.data.states = states
    }

    return variables
  }

  setModal (variables) {
    this.components.modal = $(this.editTemplate(variables))
      .modal({
        triggerClose: '[data-trigger="close"]'
      })
      .data('modal')

    this.components.modal.show()

    this.setModalComponents()
    this.bindModalListeners()
  }

  setModalComponents () {
    $.applyDataMask('[data-mask]')
    mask()

    this.components.form.toggleFieldsActiveClass()

    componentLoader(this.components.modal.$content.find('[data-component]'))

    this.$modal = this.components.modal.$content
    this.$submit = this.$modal.find('[data-trigger="submit"]')

    this.components.validation = this.$modal
      .find('[data-component^="validation"]')
      .data('validation')
  }

  updateList (list) {
    this.itemList = list

    if (this.isAddressType()) {
      this.itemList.forEach(item => {
        item.data = {}
        item.data.addressTypes = addressTypes
        item.data.states = states
      })
    }

    this.$container.html(this.listTemplate({ items: this.itemList }))

    $.applyDataMask('[data-mask]')
    mask()
  }

  sendForm () {
    showSpinner()

    const dataFormObject = this.createFormObject()

    $.post(this.ajaxURL, dataFormObject)
      .done(this.onSendSuccess.bind(this))
      .fail(this.onSendError.bind(this))
  }

  sendRemoveForm (index) {
    showSpinner()

    const dataObject = this.createRemoveObject(index)

    $.post(this.ajaxURL, dataObject)
      .done(this.onRemoveSuccess.bind(this))
      .fail(this.onSendError.bind(this))
  }

  createFormObject () {
    const form = getInputValues(this.components.form.$element)
    const modalForm = getInputValues(this.components.modal.$content)

    return {
      ...this.removeListObject(form),
      ...this.applyUpdateMethod(modalForm)
    }
  }

  createRemoveObject (index) {
    const form = getInputValues(this.components.form.$element)

    const props = {}

    for (const prop in form) {
      if (!prop.includes(`${this.listName}[${index}]`)) {
        props[prop] = form[prop]
      }
    }

    return { ...props }
  }

  applyUpdateMethod (object) {
    const parsed = this.parseObject(object)

    if (parsed.index !== '0' && parsed.default) {
      return this.updateDefaultPosition(parsed)
    }

    if (this.itemList.length > parsed.index) {
      return this.updatePosition(parsed)
    }

    return this.addPosition(parsed)
  }

  updateDefaultPosition (object) {
    this._tempItemList = this.itemList.slice()

    this._tempItemList.splice(object.index, 1)

    delete object.index

    this._tempItemList[0].default = false
    this._tempItemList.unshift(object)

    return this.toFormData(this._tempItemList)
  }

  updatePosition (object) {
    this._tempItemList = this.itemList.slice()

    this._tempItemList[object.index] = object

    return this.toFormData(this._tempItemList)
  }

  addPosition (object) {
    this._tempItemList = this.itemList.slice()

    this._tempItemList.push(object)

    return this.toFormData(this._tempItemList)
  }

  removePosition (index) {
    return this.toFormData(
      this.itemList.filter((element, currentIndex) => currentIndex !== index)
    )
  }

  getVariables ($fieldset) {
    return Array.prototype.reduce.call(
      $fieldset.find('[name]'),
      (variables, element) => {
        const property = element.name.match(/\[(\w+)\]$/) // eslint-disable-line no-useless-escape

        if (property) {
          variables[property[1]] = element.value
        }

        return variables
      },
      {}
    )
  }

  parseObject (object) {
    const parsed = {}

    for (const prop in object) {
      const name = prop.match(/\[(\w+)\]$/) // eslint-disable-line no-useless-escape

      if (name) {
        parsed[name[1]] = object[prop]
      }

      if (prop === 'index') {
        parsed[prop] = object[prop]
      }
    }

    if (this.isAddressType()) {
      parsed.data = {}
      parsed.data.addressTypes = addressTypes
      parsed.data.states = states
    }

    return parsed
  }

  toFormData (list) {
    return list.reduce((formData, item, index) => {
      for (const prop in item) {
        if (prop !== 'data') {
          formData[`${this.listName}[${index}][${prop}]`] = item[prop]
        }
      }

      return formData
    }, {})
  }

  removeListObject (object) {
    const clean = {}

    for (const prop in object) {
      if (!~prop.indexOf(this.listName)) {
        clean[prop] = object[prop]
      }
    }

    return clean
  }

  isAddressType () {
    return this.listType === 'address' || this.listType === 'address-pj'
  }
}

export default $el => new List($el).init()

export { List as Component }
