import { combineReducers } from 'redux'

import { Reducer as associated } from './modules/associatedProducts'
import { Reducer as cart } from './modules/cart'
import { Reducer as coupon } from './modules/coupon'
import { Reducer as crossServices } from './modules/crossServices'
import { Reducer as summary } from './modules/summary'

export default () =>
  combineReducers({
    associated,
    cart,
    coupon,
    crossServices,
    summary
  })
