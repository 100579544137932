import { css } from '@emotion/react'
import styled from '@emotion/styled'

export const wrapperStyles = () => css`
  label: whatsapp;

  position: fixed;
  bottom: 10px;
  right: 20px;
  z-index: 10;
`

const iconStyles = ({ theme }) => css`
  label: whatsapp__logo;

  cursor: pointer;

  width: 50px;
  height: 50px;

  ${theme.media.mega} {
    width: 90px;
    height: 90px;
  }
`

export const Wrapper = styled('a')(wrapperStyles)
export const Icon = styled('svg')(iconStyles)
