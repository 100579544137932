const buildModularBannersFromAd = ({ fullBanner }) => {
  const banners = []

  if (fullBanner) {
    banners.push({
      id: fullBanner.ad_id,
      title: 'Banner Patrocinado',
      alt: 'Banner Patrocinado',
      url: fullBanner.destination_url,
      moduleUrl: fullBanner.destination_url,
      schedule: {},
      type: 'full_banner',
      pictures: {
        full_banner: {
          title: 'Banner Patrocinado',
          alt: 'Banner Patrocinado',
          url: fullBanner.media_url,
          image_size: {
            width: 728,
            height: 90
          },
          type: 'full_banner'
        }
      },
      retailMedia: {
        clickUrl: fullBanner.click_url,
        impressionUrl: fullBanner.impression_url,
        viewUrl: fullBanner.view_url
      }
    })
  }

  return banners
}

export default buildModularBannersFromAd
