import type { DataLayerItemObject, ServiceObject } from '../../components/types'
import { pushDataObject } from '../../data-layer'
import {
  getCartType,
  getDataLayerProducts,
  getDataLayerServices,
  getShippingType
} from '../../utils'

export type PushAddPaymentInfoProps = {
  products: DataLayerItemObject[]
  services: ServiceObject[]
  payment: {
    value: number
    discount: number
    method: string
  }
  freightCost: number
}

export const pushAddPaymentInfo = (props: PushAddPaymentInfoProps) => {
  const { products, services, payment, freightCost } = props

  const dataLayerProducts = getDataLayerProducts(products)
  const dataLayerServices = getDataLayerServices(services)
  const dataLayerItems = [...dataLayerProducts, ...dataLayerServices]

  const shopNameItems = dataLayerProducts.map((item) => item.seller)
  const cartType = getCartType(shopNameItems)

  const shippingTypeItems = dataLayerProducts.map((item) => item.delivery_type)
  const shippingType = getShippingType(shippingTypeItems)

  pushDataObject({
    _clear: true,
    event: 'add_payment_info',
    items: dataLayerItems,
    payment: {
      value: payment.value,
      method: payment.method
    },
    shipping: {
      price: freightCost || undefined,
      type: shippingType
    },
    cart: {
      discount: payment.discount || undefined,
      type: cartType
    }
  })
}
