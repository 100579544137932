import React from 'react'
import { Collapsible } from '@backyard-ui/core'

import { Trigger } from './components/Trigger'
import { Content } from './components/Content'
import { OrderType, ShipmentType } from '../../types/Order'

type OrderProps = {
  order: OrderType
  saleOrderId: string
  fiscalId: string
  isOrderToPay: boolean
}

const AVAILABLE_PICKUP_STATUS = 'DISPONIVEL PARA RETIRADA'
const DELIVERY_ATTEMPT_STATUS = [
  'RESPONSÁVEL PELO RECEBIMENTO AUSENTE',
  'DIFICULDADE DE ACESSAR O LOCAL',
  'ENDEREÇO DE ENTREGA NÃO ENCONTRADO'
]
const CANCELLED_STATUS = 'CANCELADO'

const Order = ({
  order,
  saleOrderId,
  fiscalId,
  isOrderToPay
}: OrderProps) => {
  const [open, setOpen] = React.useState(false)

  const shouldBeOpen = (order: OrderType) => saleOrderId === order.id || open

  const findSeparationProblem = (shipments: ShipmentType[]) => {
    const itemSeparationProblem = shipments.find(shipment =>
      shipment.items.find(item => item.status === 'Problema na separação')
    )
    return !!itemSeparationProblem
  }

  const isOrderToPickUp = order.status === AVAILABLE_PICKUP_STATUS
  const hasDeliveryAttempt = DELIVERY_ATTEMPT_STATUS.includes(order.status)
  const hasCancelled = order.status === CANCELLED_STATUS
  const hasSeparationProblem = findSeparationProblem(order.shipments)

  return (
    <Collapsible.Root isOpen={shouldBeOpen(order)} onOpenChange={setOpen}>
      <Collapsible.Trigger asChild>
        <div className="w-full cursor-pointer">
          <Trigger
            fiscalId={fiscalId}
            isOpen={shouldBeOpen(order)}
            id={order.id}
            shipments={order.shipments}
            totalValue={order.totalValue}
            date={order.date}
            payable={order.payable}
            paymentUrl={order.paymentUrl}
            isOrderToPay={isOrderToPay}
            serviceCount={
              order.services?.items.length ? order.services.items.length : 0
            }
            isOrderToPickUp={isOrderToPickUp}
            hasDeliveryAttempt={hasDeliveryAttempt}
            hasCancelled={hasCancelled}
            hasSeparationProblem={hasSeparationProblem}
          />
        </div>
      </Collapsible.Trigger>

      <Collapsible.Content>
        <Content
          id={order.id}
          date={order.date}
          discount={order.discount}
          vouchers={order.vouchers}
          payable={order.payable}
          payments={order.payments}
          shipments={order.shipments}
          shippingCost={order.shippingCost}
          invoices={order.invoices}
          steps={order?.shipments[0]?.steps}
          totalValue={order.totalValue}
          services={order.services}
        />
      </Collapsible.Content>
    </Collapsible.Root>
  )
}

export default Order
