import $ from 'jquery'
import cloneDeep from 'lodash/cloneDeep'

import emitter from 'scripts/utils/emitter'
import BaseComponent from 'scripts/components/base-component'
import { factoryAddressRules } from 'scripts/react-components/utils/account/factoryAddressRules.js'
import { showSpinner, hideSpinner } from 'scripts/utils/spinner'

class SearchZipcode extends BaseComponent {
  static componentName = 'search-zipcode'

  static DOMEvents = {
    'input [data-trigger="search-zipcode"]': 'handleInput'
  }

  zipcodeFields = {
    street: 'street',
    number: 'number',
    complement: 'complement',
    district: 'district',
    city: 'city',
    state: 'state',
    ibge_city_code: 'ibgeCode',
    postal_code_type: 'type',
    postal_code_description: 'description'
  }

  init () {
    const zipcodeFieldsKeys = Object.keys(this.zipcodeFields)

    this.fields = zipcodeFieldsKeys.reduce((fieldsObject, field) => {
      const type = (field === 'state') ? 'select' : 'input'

      const selector = `${type}[name*='[${field}]']`

      fieldsObject[field] = this.$element[0].querySelector(selector)

      return fieldsObject
    }, {})

    this.components = this.getComponents()

    this.zipcodeField = this.$element[0].querySelector('[data-trigger="search-zipcode"]')
    this.zipcodeWarning = this.$element[0].querySelector('[data-warning="zipcode"]')

    const zipcodeValue = this.zipcodeField?.value

    if (zipcodeValue) {
      this.applyZipcode(zipcodeValue)
    }
  }

  getComponents () {
    return {
      validation: this.$element.data('validation')
    }
  }

  trimAddressData (addressData) {
    const addressDataClone = cloneDeep(addressData)
    const zipcodeFieldsValues = Object.values(this.zipcodeFields)

    for (const key of zipcodeFieldsValues) {
      const value = addressData[key]

      if (!value) continue

      const trimmedValue = typeof value === 'string'
        ? value.trim()
        : value

      addressDataClone[key] = trimmedValue
    }

    return addressDataClone
  }

  async getAddressData (zipcode) {
    return new Promise((resolve, reject) => {
      const isValidZipcode = zipcode.length === 9

      if (!isValidZipcode) return reject(new Error('Invalid zipcode.'))

      $.get(`/endereco/${zipcode}`)
        .done(addressData => {
          const trimmedAddressData = this.trimAddressData(addressData)

          resolve(trimmedAddressData)
        })
        .fail(errorData => reject(errorData))
    })
  }

  getAddressRules (addressData) {
    const addressObject = {
      street: addressData.street,
      city: addressData.city,
      district: addressData.district,
      state: addressData.state,

      ibgeCode: addressData.ibgeCode,
      'postalCode.type': addressData.type,
      'postalCode.description': addressData.description
    }

    const addressRules = factoryAddressRules(addressObject)

    return addressRules
  }

  applyAddressData (addressData, addressRules) {
    const zipcodeFieldsEntries = Object.entries(this.zipcodeFields)

    for (const [fieldName, fieldKey] of zipcodeFieldsEntries) {
      const fieldRules = addressRules[fieldName]
      const fieldValue = fieldRules?.value || addressData[fieldKey]

      const value = typeof fieldValue === 'string'
        ? fieldValue.trim()
        : fieldValue

      $(this.fields[fieldName])
        .val(value)
        .trigger('change')
    }
  }

  applyAddressRules (addressRules) {
    for (const fieldName in this.zipcodeFields) {
      const fieldElement = $(this.fields[fieldName])
      const fieldRules = addressRules[fieldName]

      const currentDataValidate = fieldElement.attr('data-validate') || ''
      const dataValidate = currentDataValidate
        ? currentDataValidate.split(' ')
        : []

      if (!fieldRules) continue

      fieldElement.attr('disabled', fieldRules.disabled)

      if (fieldRules.required) {
        fieldElement.attr('required', true)
        dataValidate.push('required')
      }

      if (dataValidate.length > 0) {
        const dataValidateString = dataValidate.join(' ')
        fieldElement.attr('data-validate', dataValidateString)
      }
    }
  }

  cleanFieldsData () {
    for (const field in this.zipcodeFields) {
      const fieldElement = $(this.fields[field])

      fieldElement
        .val('')
        .trigger('change')
    }
  }

  cleanFieldsValidation () {
    for (const field in this.zipcodeFields) {
      const fieldElement = $(this.fields[field])
      const currentDataValidate = fieldElement.attr('data-validate') || ''

      const newDataValidate = currentDataValidate
        .split(' ')
        .filter(rule => rule !== 'required')
        .join(' ')

      this.components.validation.setPristine(fieldElement)

      if (newDataValidate) {
        fieldElement.attr('data-validate', newDataValidate)
      } else {
        fieldElement.removeAttr('data-validate')
      }

      fieldElement
        .attr('disabled', true)
        .attr('required', false)
        .trigger('change')
    }
  }

  async applyZipcode (zipcode, applyData) {
    const isValidZipcode = zipcode?.length === 9

    if (!isValidZipcode) return

    showSpinner()

    applyData && this.cleanFieldsData()
    this.cleanFieldsValidation()
    this.clearZipcodeError()

    try {
      const addressData = await this.getAddressData(zipcode)
      const addressRules = this.getAddressRules(addressData)

      this.applyAddressRules(addressRules)
      if (applyData) {
        this.applyAddressData(addressData, addressRules)
      }
    } catch (error) {
      this.cleanFieldsData()
      this.cleanFieldsValidation()

      this.onZipcodeError(error)
    }

    hideSpinner()
  }

  handleInput (event) {
    const zipcode = event.target.value
    this.applyZipcode(zipcode, true)
  }

  onZipcodeError (error) {
    const message = error?.status && error.status === 404
      ? 'O CEP não foi encontrado.'
      : 'Não foi possível recuperar o endereço. Tente Novamente.'

    $(this.zipcodeField).data('zipcode-data-error', true)
    $(this.zipcodeWarning).html(message)

    emitter.emit('stickyFeedback:error', {
      title: 'Ops! Ocorreram os seguintes erros:',
      content: message
    })
  }

  clearZipcodeError () {
    this.components.validation.setPristine(this.zipcodeField)

    $(this.zipcodeField).data('zipcode-data-error', false)
    $(this.zipcodeWarning).html('')
  }
}

export default $el => new SearchZipcode($el).init()

export { SearchZipcode as Component }
