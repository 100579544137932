import reactComponentLoader from 'scripts/react-component-loader'

import Modular from 'site/Modular'

import { RetailMediaProductsCarousel } from './modules/RetailMediaProductsCarousel'
import { RetailMediaBanner } from './modules/RetailMediaBanner'

reactComponentLoader(Modular, '[data-modular-content]')
reactComponentLoader(RetailMediaBanner, '[data-modular-retail-media-banner]')

reactComponentLoader(
  RetailMediaProductsCarousel,
  '[data-modular-retail-media-products-carousel]'
)
