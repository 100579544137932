import { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Inline } from '@leroy-merlin-br/backyard-react'
import { shallowEqual, useSelector } from 'react-redux'
import { Sidebar } from 'user/components/Sidebar'

import { HeaderContainer } from 'lmcv/containers/Header'

import * as S from './styled'

function AuthLayout ({ children }) {
  const {
    get: { payload: loyaltyProfile },
    loyaltyStatus,
    loyaltyPointsFormatted
  } = useSelector((state) => state.loyaltyProfile, shallowEqual)

  return (
    <S.Container>
      <S.Wrapper>
        <Inline wrap="nowrap">
          <Sidebar
            userName={loyaltyProfile.name}
            loyaltyStatus={loyaltyStatus}
            loyaltyPointsFormatted={loyaltyPointsFormatted}
            isLoyaltyElegible
          />
          <Fragment>
            <HeaderContainer />
            {children}
          </Fragment>
        </Inline>
      </S.Wrapper>
    </S.Container>
  )
}

AuthLayout.propTypes = {
  children: PropTypes.node.isRequired
}

export default AuthLayout
