import styled from '@emotion/styled'
import { css } from '@emotion/react'

const overlayStyles = ({ theme }) => css`
  label: overlay;

  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: ${theme.colors.overlay};
`

const wrapperStyles = ({ theme, isOpen }) => css`
  label: wrapper;

  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  opacity: ${isOpen ? '1' : '0'};
  visibility: ${isOpen ? 'visible' : 'hidden'};
  transition: ${theme.transition.enter} ease;
`

const drawerStyles = ({ theme, isOpen }) => css`
  label: drawer;

  background-color: ${theme.colors.white};
  width: 480px;
  max-width: 100%;
  height: 100vh;
  padding: ${theme.spacings.mega} 0px;
  position: absolute;
  top: 0;
  right: ${isOpen ? '0' : '-100%'};
  transition: ${theme.transition.leave} ease;
  overflow-y: auto;
`

const headerStyles = ({ theme }) => css`
  label: drawer__header;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px ${theme.spacings.mega};
`

const titleStyles = ({ theme }) => css`
  label: drawer__title;

  font-size: ${theme.typography.headings.giga.fontSize};
  font-weight: ${theme.font.weight.semibold};
`

const bodyStyles = ({ theme }) => css`
  label: drawer__body;

  padding: ${theme.spacings.kilo} ${theme.spacings.mega};
`

export const Wrapper = styled('div')(wrapperStyles)
export const Overlay = styled('div')(overlayStyles)
export const Header = styled('div')(headerStyles)
export const Title = styled('strong')(titleStyles)
export const Body = styled('div')(bodyStyles)
export const Drawer = styled('div')(drawerStyles)
