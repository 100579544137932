/**
 * Transform a string with accents into a string without accents
 * @param  {string} string A string to be transformed
 * @return {string}       The string transformed without accents
 */

export default (string) => {
  let finalString = decodeURI(string)

  const regexpMap = {
    a: /[\xE0-\xE6]/g,
    e: /[\xE8-\xEB]/g,
    i: /[\xEC-\xEF]/g,
    o: /[\xF2-\xF6]/g,
    u: /[\xF9-\xFC]/g,
    c: /\xE7/g,
    n: /\xF1/g
  }

  for (const letter in regexpMap) {
    const regexp = regexpMap[letter]
    finalString = finalString.replace(regexp, letter)
  }

  return finalString
}
