import { css } from '@emotion/react'
import styled from '@emotion/styled'

import type { Theme } from 'scripts/react-components/themes/Theme'

const wrapperStyles = ({ theme }: Theme) => css`
  label: service-label;

  display: flex;
  align-items: center;
  padding: ${theme.spacings.kilo} ${theme.spacings.mega};
  gap: ${theme.spacings.kilo};

  background: ${theme.colors.p100};
  border-radius: ${theme.borderRadius.giga};
  margin: ${theme.spacings.giga} 10px 0 10px;

  ${theme.media.mega} {
    margin: ${theme.spacings.giga} 0 0 0;
  }
`

const logoStyles = () => css`
  label: service-label__logo;

  display: flex;
`

const hiddenPriceStyles = () => css`
  label: service-label__price--hidden;

  display: none;
`

export const Wrapper = styled('div')(wrapperStyles)
export const Logo = styled('div')(logoStyles)
export const HiddenPrice = styled('span')(hiddenPriceStyles)
