import { useState } from 'react'

import VerifyAccount from './VerifyAccount/VerifyAccount'
import { SignUp } from './SignUp/SignUp'

type Step = {
  component: React.ComponentType<any>
}

type StepComponentSignUpProps = {
  verifyCodeStepOnSignup: boolean
}

export const StepComponentSignUp = (props: StepComponentSignUpProps) => {
  const { verifyCodeStepOnSignup } = props

  const [currentStep, setCurrentStep] = useState(0)

  const handleNextStep = () => {
    const lastStep = 2

    if (currentStep < lastStep && verifyCodeStepOnSignup) {
      setCurrentStep(prevStep => prevStep + 1)
    }
  }

  const handlePreviusStep = () => {
    if (currentStep > 0 && verifyCodeStepOnSignup) {
      setCurrentStep(prevStep => prevStep - 1)
    }
  }

  const steps: Step[] = [
    {
      component: SignUp
    },
    {
      component: VerifyAccount
    }
  ]

  const StepComponent = steps[currentStep].component

  return (
    <StepComponent
      verifyCodeStepOnSignup={verifyCodeStepOnSignup}
      onNextStep={handleNextStep}
      onPreviousStep={handlePreviusStep}
    />
  )
}
