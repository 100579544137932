import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Badge } from '@leroy-merlin-br/backyard-react'
import { CrossOutline } from '@backyard-ui/icons'

import { deleteStyles, iconColorStyles, tagStyles } from './styles'

const TagElement = styled('div')(tagStyles)
const Remove = styled('button')(deleteStyles, iconColorStyles)

const COLOR_MAP = {
  default: 'info',
  success: 'primary',
  danger: 'critical',
  warning: 'warning',
  loyalty: 'secondary'
}

const Tag = ({ type, label, icon: Icon, isDisabled, onDelete }) => {
  const currentColor = isDisabled ? COLOR_MAP.default : COLOR_MAP[type]

  return (
    <Badge variant="subtle" appearance={currentColor}>
      <TagElement>
        {Icon && <Icon data-testid="tag-icon" />}
        {label}
        {onDelete && (
          <Remove
            type="button"
            data-testid="button-remove"
            color={type}
            onClick={onDelete}
            disabled={isDisabled}
          >
            <CrossOutline width={10} height={10} />
          </Remove>
        )}
      </TagElement>
    </Badge>
  )
}

Tag.propTypes = {
  type: PropTypes.oneOf(['default', 'success', 'danger', 'warning', 'loyalty'])
    .isRequired,
  label: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  onDelete: PropTypes.func,
  icon: PropTypes.func
}

Tag.defaultProps = {
  type: 'default',
  isDisabled: false
}

export default Tag
