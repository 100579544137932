import { CreditCard } from 'frontendCheckout/assets/icons'

type InstallmentsProps = {}

export const Installments = ({}: InstallmentsProps) => {
  return (
    <div>
      <div className="text-sm mb-2 flex items-center">
        <CreditCard />{' '}
        <span className="ml-2">
          Faltam R$100 para{' '}
          <span className="text-teal-500 font-bold">parcelar em 9 vezes</span>
        </span>
      </div>
      <div className="h-1 w-full bg-gray-300 mb-4">
        <div className="h-1 w-[70%] bg-teal-500"></div>
      </div>
    </div>
  )
}
