import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { styleHelpers } from '@leroy-merlin-br/backyard-react'

const wrapperStyles = ({ theme }) => css`
  label: wrapper;

  position: relative;
  padding: ${theme.spacings.mega} ${theme.spacings.byte};

  cursor: pointer;
`

const menuStyles = ({ theme, active }) => css`
  label: menu;

  display: ${active ? 'block' : 'none'};
  position: absolute;
  top: 14px;
  right: 32px;

  min-width: max-content;
  padding: 0;
  margin: 0;

  background: ${theme.colors.white};
  border: 1px solid ${theme.colors.n100};
  border-radius: ${theme.borderRadius.mega};

  ${styleHelpers.shadowSingle(theme)};

  transition: opacity 100ms ease;
  will-change: transform, opacity;
`

const itemStyles = ({ theme }) => css`
  label: item;

  display: block;
  position: relative;
  height: auto;
  padding: ${theme.spacings.byte} ${theme.spacings.mega};
  border: none;
  border-top: none;
  cursor: pointer;

  &:not(:last-child) {
    border-bottom: 1px solid ${theme.colors.n100};
  }
`

export const Wrapper = styled('div')(wrapperStyles)
export const Menu = styled('div')(menuStyles)
export const ItemElement = styled('span')(itemStyles)
