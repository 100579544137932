import { memo, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Heading, Text } from '@leroy-merlin-br/backyard-react'

import { GenericCarousel } from 'shared/components'

import { CouponContainer } from 'lmcv/containers/Coupon'

import { CAROUSEL_COUPONS_LIMIT } from './constants'
import { SeeAllCoupons } from './SeeAllCoupons'
import { CarouselSkeleton } from './Skeleton'
import * as S from './styled'
import { Navigation } from './Navigation'

const CouponsCarousel = ({ data, title, seeAllLink }) => {
  const { payload, isRequest, error } = data || {}

  const hasCoupons = payload?.length > 0
  const shouldShowAllCouponsButton = payload?.length >= CAROUSEL_COUPONS_LIMIT
  const hasError = Object.keys(error).length > 0

  if (isRequest) {
    return (
      <S.Card>
        <Heading size="giga">{title}</Heading>

        <CarouselSkeleton size={5} />
      </S.Card>
    )
  }

  if (hasError) {
    return (
      <S.Card>
        <Heading size="giga">{title}</Heading>
        <Text>
          Ops, estamos com instabilidades para exibir seus cupons. Tente
          novamente mais tarde caso o problema persista!
        </Text>
      </S.Card>
    )
  }

  return (
    <S.Card>
      <Heading size="giga">{title}</Heading>

      {hasCoupons ? (
        <GenericCarousel
          slidesPerView="auto"
          spaceBetween={8}
          hasOffset={false}
          customNavigation={({ prevProps, nextProps }) => (
            <Navigation prevProps={prevProps} nextProps={nextProps} />
          )}
        >
          {data.payload.map(data => (
            <CouponContainer key={data.id} {...data} />
          ))}

          {shouldShowAllCouponsButton && <SeeAllCoupons link={seeAllLink} />}
        </GenericCarousel>
      ) : (
        <Fragment>
          <Text size="mega" noMargin>
            Você não tem nenhum cupom disponível.
          </Text>
          <Text size="mega" noMargin>
            Eles podem ter sido usados ou terem expirado. Mas não se preocupe,
            em breve mandaremos cupons novinhos :)
          </Text>
        </Fragment>
      )}
    </S.Card>
  )
}

CouponsCarousel.propTypes = {
  data: PropTypes.shape({
    isRequest: PropTypes.bool,
    payload: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        title: PropTypes.string,
        discount: PropTypes.number,
        thumbnail: PropTypes.string,
        type: PropTypes.string,
        remainingDays: PropTypes.number,
        shortDescription: PropTypes.string,
        isShared: PropTypes.bool,
        isActive: PropTypes.bool,
        giftedBy: PropTypes.string,
        isLoading: PropTypes.bool,
        activatedId: PropTypes.string
      })
    )
  }).isRequired
}

export default memo(CouponsCarousel)
