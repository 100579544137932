import PropTypes from 'prop-types'

const HamburguerMenu = ({ trigger }) => (
  <div className="hamburguer-menu" onClick={ trigger }>
    <div className="hamburguer-menu-bread"></div>
    <div className="hamburguer-menu-bread"></div>
    <div className="hamburguer-menu-bread"></div>
  </div>
)

HamburguerMenu.propTypes = {
  trigger: PropTypes.func.isRequired
}

export default HamburguerMenu
