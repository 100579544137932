import {
  Text,
  Dialog,
  ButtonGroup,
  Button
} from '@leroy-merlin-br/backyard-react'

import { deleteClient, finishProject } from './services'

const handleModal = (setModal, id, status) => {
  const modalDeleteClient = ({ onClose }) => (
    <Dialog
      title="Tem certeza que deseja excluir esse cliente?"
      onClose={onClose}
      footer={
        <ButtonGroup align="flex-end">
          <Button
            size="kilo"
            appearance="primary"
            variant="outline"
            onClick={onClose}
          >
            Voltar
          </Button>
          <Button
            size="kilo"
            appearance="critical"
            onClick={() => deleteClient(id, onClose)}
          >
            Excluir cliente
          </Button>
        </ButtonGroup>
      }
    >
      <Text size="mega" noMargin>
        Ao excluir esse cliente ele desaparecerá da lista de clientes com
        projetos concluídos.
      </Text>
    </Dialog>
  )

  const modalCancelProject = ({ onClose }) => (
    <Dialog
      title="Tem certeza que deseja finalizar esse projeto?"
      onClose={onClose}
      footer={
        <ButtonGroup align="flex-end">
          <Button
            size="kilo"
            appearance="primary"
            variant="outline"
            onClick={onClose}
          >
            Voltar
          </Button>
          <Button
            size="kilo"
            appearance="primary"
            onClick={() => finishProject(id, onClose)}
          >
            Finalizar projeto
          </Button>
        </ButtonGroup>
      }
    >
      <Text size="mega" noMargin>
        Ao finalizar o projeto, você não poderá mais enviar cupons nem ganhar
        bônus pelas compras deste cliente.
      </Text>
    </Dialog>
  )

  const STATUS_TYPE = ['pendente', 'finalizado', 'concluído']

  if (STATUS_TYPE.includes(status)) {
    return setModal({
      size: 'giga',
      children: modalDeleteClient
    })
  }

  return setModal({
    size: 'giga',
    children: modalCancelProject
  })
}

export default handleModal
