import styled from '@emotion/styled'
import { css } from '@emotion/react'

const wrapperStyles = ({ theme }) => css`
  label: hybris-cart__wrapper;

  padding: ${theme.spacings.mega};
  margin-top: ${theme.spacings.tera};

  ${theme.media.mega} {
    padding: 0;
    margin-top: 0;
  }
`

const itemStyles = ({ theme }) => css`
  label: hybris-cart__item;

  display: flex;
  flex-flow: column nowrap;
  padding: ${theme.spacings.byte} 0;
  border-bottom: 1px solid ${theme.colors.n100};
  font-size: ${theme.typography.text.mega.fontSize};
  line-height: ${theme.typography.text.mega.lineHeight};

  &:last-child {
    border-bottom: 0;
  }
`

const descriptionWrapperStyles = ({ theme }) => css`
  label: hybris-cart__description-wrapper;

  display: flex;
  width: 100%;
  margin-bottom: ${theme.spacings.byte};
`

const keyStyles = ({ theme }) => css`
  label: hybris-cart__key;

  margin-right: ${theme.spacings.bit};
  color: ${theme.colors.n400};
`

const valueStyles = ({ theme }) => css`
  label: hybris-cart__value;

  flex: 1 1 auto;
  color: ${theme.colors.n800};
`

const priceStyles = ({ theme }) => css`
  label: hybris-cart__price;

  font-weight: ${theme.font.weight.semibold};
  color: ${theme.colors.n800};
`

export const Wrapper = styled('div')(wrapperStyles)
export const Item = styled('a')(itemStyles)
export const DescriptionWrapper = styled('div')(descriptionWrapperStyles)
export const Key = styled('div')(keyStyles)
export const Value = styled('div')(valueStyles)
export const Price = styled('div')(priceStyles)
