import PropTypes from 'prop-types'
import { Redirect, Route, withRouter } from 'react-router-dom'

const GuestRoute = ({ component: Component, ...rest }) => {
  const loyaltyToken = localStorage.getItem('@loyalty:token')

  return (
    <Route
      {...rest}
      render={props =>
        !loyaltyToken ? <Component {...props} /> : <Redirect to="/" />
      }
    />
  )
}

GuestRoute.propTypes = {
  component: PropTypes.elementType.isRequired
}

export default withRouter(GuestRoute)
