import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Tabs, Visible } from '@leroy-merlin-br/backyard-react'

import { useLoyaltyProfile } from 'scripts/react-components/shared/hooks'

import * as S from './styled'
import { getLinks } from './utils'

const Navigation = ({ history, loyaltyStatus }) => {
  const { loyaltyProfile } = useLoyaltyProfile()

  const links = getLinks(loyaltyStatus, loyaltyProfile?.hasArchitectCashback)

  const [activeItem, setActiveItem] = useState()

  useEffect(() => {
    const activeItemFound = links.find(
      ({ path }) => path === history.location.pathname
    )

    activeItemFound && setActiveItem(activeItemFound.id)
  }, [history.location.pathname, links])

  const handleTabClick = (panelId) => {
    const selectedItem = links.find((item) => item.id === panelId)
    const { path, id } = selectedItem

    setActiveItem(id)
    history.push(path)
  }

  return (
    <Visible when={['untilKilo', 'kilo', 'mega']}>
      <S.NavigationWrapper>
        <Tabs
          items={links}
          onTabClick={(_, panelId) => handleTabClick(panelId)}
          selectedId={activeItem}
        />
      </S.NavigationWrapper>
    </Visible>
  )
}

Navigation.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({
      pathname: PropTypes.string
    })
  }).isRequired,
  loyaltyStatus: PropTypes.object.isRequired
}

export default Navigation
