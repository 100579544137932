import React from 'react'
import {
  Dialog,
  Text,
  SubHeading,
  Modal
} from '@leroy-merlin-br/backyard-react'

import Footer from './Footer'
import * as S from './styled'

const TermsOfUse = ({
  serviceId,
  name,
  acceptanceTerms = [],
  showTerms,
  onClose,
  onClickCallBack,
  productQuantity = 1
}) => {
  return (
    <Modal isOpen={showTerms} onClose={onClose} hasScroll>
      <Dialog
        hasScroll
        onClose={onClose}
        title={name}
        footer={
          <Footer
            onClose={onClose}
            onClick={() => {
              onClickCallBack(productQuantity)
              onClose()
            }}
          />
        }
      >
        <Text size="mega" as="div" color="n300">
          Cod. do servico: {serviceId}
        </Text>
        <S.ScrollArea>
          <SubHeading as="h2" size="mega" noMargin>
            Condições do serviço de instalação
          </SubHeading>
          {acceptanceTerms.map(({ title, message }, index) => (
            <S.TermsTextWrapper key={`${serviceId}-${index}`} index={index}>
              <Text as="strong" size="kilo" isBold>
                {title}
              </Text>
              <Text as="p" size="kilo">
                {message}
              </Text>
            </S.TermsTextWrapper>
          ))}
        </S.ScrollArea>
      </Dialog>
    </Modal>
  )
}

export default React.memo(TermsOfUse)
