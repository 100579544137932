import { ChangeEvent, useState } from 'react'
import { Button, Stack, Text, TextField, toast } from '@leroy-merlin-br/backyard-react'
import { Controller, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'

import * as validator from 'utils/validators/validators'

import { MobileLayout } from '../../components/MobileLayout'
import * as S from './styled'
import { useMyAccountPersonalDataContext } from '../../context/my-account-personal-data-context'
import { updateProfile } from '../../services'

type FormValues = {
  name: string
}

const EditName = () => {
  const { contextData, updateData } = useMyAccountPersonalDataContext()

  const [fieldValue, setFieldValue] = useState(contextData.name)

  const [loading, setLoading] = useState(false)

  const history = useHistory()

  const {
    control,
    formState: { errors, isValid }
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      name: ''
    }
  })

  const handleSave = async () => {
    setLoading(true)

    const update = await updateProfile({ name: fieldValue })

    setLoading(false)

    if (update.data) {
      updateData({ ...contextData, name: fieldValue })

      toast.primary('Nome atualizado com sucesso!', {
        variant: 'light'
      })

      history.push('/dados-pessoais')
    }
  }

  return (
    <MobileLayout description="Dados pessoais" text="Edite seu nome">
      <Stack space="giga">
        <Controller
          name="name"
          rules={{
            required: 'Insira seu nome',
            validate: (value: string) =>
              validator.isValidName(value) || 'Nome inválido'
          }}
          control={control}
          state={Boolean(errors.name) && 'invalid'}
          hint={errors.name?.message}
          render={({
            field: { onChange, name, ref },
            fieldState: { error }
          }) => (
            <TextField
              disabled={loading}
              label="Nome"
              name={name}
              ref={ref}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setFieldValue(event.target.value)

                onChange(event)
              }}
              value={fieldValue}
              state={error ? 'invalid' : undefined}
              hint={error?.message}
            />
          )}
        />

        <S.ButtonWrapper>
          <Button
            data-gtm-element-id="click-save-name-button"
            isStretch
            isDisabled={!isValid}
            onClick={handleSave}
            isLoading={loading}
          >
            Salvar
          </Button>
        </S.ButtonWrapper>
      </Stack>

      <Stack alignX="center">
        <button onClick={() => {
          if (loading) {
            return
          }

          history.push('/dados-pessoais')
        }}>
          <Text size="mega" color="r600" noMargin isBold>
            Cancelar
          </Text>
        </button>
      </Stack>
    </MobileLayout>
  )
}

export default EditName
