import axios, { AxiosError, AxiosResponse } from 'axios'

import {
  CART_ENDPOINT,
  CART_V3_ENDPOINT,
  CART_CHANGE_SHIPMENT_ENDPOINT,
  CART_STORES_ENDPOINT,
  CART_CHANGE_SHIPMENT_AVAILABILITY,
  CART_CHANGE_ITEM_SHIPMENT_ENDPOINT
} from 'newCheckout/constants'
import { Cart } from 'newCheckout/types/Cart'
import { Store } from 'newCheckout/types/PickUpInStore'

import instance, { axiosResponseToStoreResponse } from './axios'

type ProductType = { id: string; quantity: number; skuId: string | null }
export type AddProductType = {
  type: string
  products: ProductType[]
  storeId: string | null
}

const STOCK_ERROR_CODE = 406

export const changeProductQuantity = async ({
  type,
  products,
  storeId
}: AddProductType) => {
  try {
    const axiosResponse = await instance.post<{ data: Partial<Cart> }>(
      CART_ENDPOINT,
      {
        products,
        type,
        storeId
      }
    )
    return axiosResponseToStoreResponse(axiosResponse)
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response?.status === STOCK_ERROR_CODE) {
        return axiosResponseToStoreResponse(treatStockError(error, products))
      }
    }
    throw error
  }
}

export type removeProductType = {
  productId: string
}

export const removeProduct = async ({ productId }: removeProductType) => {
  return axiosResponseToStoreResponse(
    await instance.delete<void>(`${CART_ENDPOINT}/${productId}`)
  )
}

export const getCart = async () => {
  return axiosResponseToStoreResponse(
    await instance.get<{ data: Partial<Cart> }>(CART_ENDPOINT)
  )
}

export type getAvailableStoresType = {
  cartId: string
}

export const getAvailableStores = async ({
  cartId
}: getAvailableStoresType) => {
  return axiosResponseToStoreResponse(
    await instance.get<{
      data: { store: Store[]; deliveryAvailable: boolean }
    }>(`${CART_V3_ENDPOINT}/${cartId}${CART_STORES_ENDPOINT}`)
  )
}

export type ChangeItemShipment = {
  itemId: string
  cartId: string
  storeId?: string | null
  type: 'pickupInStore' | 'deliveryCompany'
}

export const changeItemShipment = async ({
  itemId,
  cartId,
  storeId,
  type
}: ChangeItemShipment) => {
  return axiosResponseToStoreResponse(
    await instance.put(
      `${CART_V3_ENDPOINT}/${cartId}${CART_CHANGE_ITEM_SHIPMENT_ENDPOINT}`,
      { items: [{ itemId, storeId, type }] }
    )
  )
}

export type ChangeShipmentType = {
  cartId: string
  storeId: string | null
  type: 'pickupInStore' | 'delivery'
}

export const changeShipment = async ({
  cartId,
  storeId,
  type
}: ChangeShipmentType) => {
  return axiosResponseToStoreResponse(
    await instance.put<{ data: Store[] }>(
      `${CART_V3_ENDPOINT}/${cartId}${CART_CHANGE_SHIPMENT_ENDPOINT}`,
      { storeId, type }
    )
  )
}

// TO DO: passar essa responsabilidade para o backend
const treatStockError = (error: AxiosError<any>, products: ProductType[]) => {
  const response = error.response
  if (response?.data.errors) {
    response.data.errors = response.data.errors?.map((error: string) => ({
      id: products[0].id,
      description: error
    }))
  }

  if (response?.data) {
    response.data = { data: response.data }
  }

  return error.response as AxiosResponse<{ data: Partial<Cart> }>
}
