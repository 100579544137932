import { Text, Badge } from '@leroy-merlin-br/backyard-react'

import { ImageThumbnail } from 'shared/components'

import formatPriceToBRL from 'utils/formatPriceToBRL'

import { ServiceInstall } from 'newCheckout/images'

import { Service } from '../../type'
import * as S from './styled'

export default function VisualStandaloneService ({
  standaloneService
}: {
  standaloneService: Service
}) {
  const name = standaloneService.name
  const priceUnit = standaloneService.price.to
  const quantity = standaloneService.quantity
  const totalPrice = standaloneService.price.total
  const Component = <ServiceInstall />

  return (
    <S.StandlaloneService>
      <S.ItemInfo>
        <S.ResponsiveInline space="byte">
          <ImageThumbnail
            alt={name}
            src={''}
            Component={Component}
            responsiveSizes={{
              untilKilo: '48px',
              kilo: '48px',
              mega: '90px',
              giga: '90px',
              tera: '90px'
            }}
            noBorder
          />
          <div>
            <Badge appearance="warning">Serviço de Instalação</Badge>
            <Text as="div" color="g600" noMargin>
              {name}
            </Text>
            <S.ItemPrice>
              <div>
                <Text as="span" size="kilo" color="n600" noMargin>
                  {formatPriceToBRL(priceUnit)} / un
                </Text>
                <Text as="div" size="kilo" isBold noMargin>
                  {formatPriceToBRL(totalPrice)}
                </Text>
              </div>
              <div>
                <Text as="span" size="kilo" color="n600">
                  Qtde:{' '}
                  <Text as="span" color="black" isBold>
                    {quantity}
                  </Text>
                </Text>
              </div>
            </S.ItemPrice>
          </div>
        </S.ResponsiveInline>
      </S.ItemInfo>
    </S.StandlaloneService>
  )
}
