import { ResponseType, request } from 'frontendCheckout/services/fetch'

import { endpoints } from './endpoints'

export type response = ResponseType<200, null>

export type status = response extends ResponseType<infer Code, unknown>
  ? Code
  : never

export type props = {}

export const removeCoupon = () => {
  return request<response>({
    url: endpoints.removeCoupon(),
    method: 'DELETE'
  })
}
