import styled from '@emotion/styled'
import { css } from '@emotion/react'

import type { Theme } from 'scripts/react-components/themes/Theme'

const wrapperStyles = ({ theme }: Theme) => css`
  label: search-interest__wrapper;
  background-color: ${theme.colors.p200};
  color: ${theme.colors.n900};
  width: 100%;
  min-height: 77px;
  padding: ${theme.spacings.mega};
  box-sizing: border-box;
  display: flex;
  transition: transform 0.8s ease;
`
const wrapperIcon = ({ theme }: Theme) => css`
  label: search-interest__icon;
  max-width: 40px;
  margin: auto 0;
  background: RGB(255,255,255, 0.6);
  padding: ${theme.spacings.byte};
  border-radius: ${theme.borderRadius.circle};
`
const wrapperTextPromotion = ({ theme }: Theme) => css`
  label: search-interest__text-promotion;
  font-size: ${theme.typography.text.kilo.fontSize};
  line-height: ${theme.typography.text.kilo.lineHeight};
  font-weight:  ${theme.font.weight.regular};
`
const wrapperTextCall = ({ theme }: Theme) => css`
  label: search-interest__text-call;
  font-size: 16px;
  line-height: ${theme.typography.text.mega.lineHeight};
  font-weight: ${theme.font.weight.semibold};
`
const wrapperButton = () => css`
  label: search-interest__button;
  margin-top: 5px;
  margin-left: auto;
`
const wrapperText = ({ theme }: Theme) => css`
  label: search-interest__text;
  padding-left: ${theme.spacings.byte};
`

export const Wrapper = styled('div')(wrapperStyles)
export const WrapperText = styled('div')(wrapperText)
export const WrapperButton = styled('div')(wrapperButton)
export const WrapperTextPromotion = styled('div')(wrapperTextPromotion)
export const WrapperTextCall = styled('div')(wrapperTextCall)
export const WrapperIcon = styled('div')(wrapperIcon)
