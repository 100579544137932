import { css } from '@emotion/react'
import styled from '@emotion/styled'

const moduleStyles = () => css`
  label: module;

  margin: 30px 0;
  display: inline-block;
  width: 100%;
  overflow: hidden;
`

const threeBannersStyles = ({ theme }) => css`
  label: module__three-banners;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: ${theme.spacings.kilo};
`

export const Module = styled('div')(moduleStyles)
export const ThreeBanners = styled('div')(threeBannersStyles)
