import styled from '@emotion/styled'
import { css } from '@emotion/react'

const trailingLoadingStyle = () => css`
  label: loading-frames;

  p {
    display: inline-block;
    font-size: 30px;
    opacity: 0;
    animation: load 1.5s infinite;
    line-height: 5px;
  }

  p:nth-of-type(2) {
    animation-delay: 0.2s;
  }

  p:nth-of-type(3) {
    animation-delay: 0.4s;
  }

  @keyframes load {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`

export const TrailingLoading = styled('div')(trailingLoadingStyle)
