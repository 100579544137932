import request from 'catalog/utils/axios'

export const requestRichRelevanceTracking = async url => {
  const response = await request
    .get(url)
    .then(({ data }) => data)
    .catch(error => error)

  return response
}
