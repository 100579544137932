import { Constants } from './'

export const setRegion = data => ({
  type: Constants.CART_SET_REGION,
  data
})

export const setServices = payload => ({
  type: 'SET_CART_SERVICES',
  payload
})

export const setShippings = payload => ({
  type: 'SET_CART_SHIPPINGS',
  payload
})

export const setAvailableServices = payload => ({
  type: 'SET_AVAILABLE_SERVICES',
  payload
})

export const getAvailableServices = () => ({
  type: 'GET_AVAILABLE_SERVICES'
})
