import { useController } from 'react-hook-form'

import { ComboBox } from 'shared/components/ComboBox'

const ComboBoxField = props => {
  const {
    field: { name, value, onChange }
  } = useController(props)

  return (
    <ComboBox
      name={name}
      selectedValue={value}
      onSelectionChange={onChange}
      {...props}
    />
  )
}

export default ComboBoxField
