import { Text } from '@leroy-merlin-br/backyard-react'
import PropTypes from 'prop-types'

import { sortByPaymentMethodLength, translatePaymentMethod } from 'scripts/utils/discount-by-payment-method'

import formatPriceToBRL from 'utils/formatPriceToBRL'

import * as S from './styled'

const TotalPaymentMethods = ({ paymentMethodsSummary }) => {
  if (!paymentMethodsSummary) {
    return
  }
  const sortedPaymentMethods = Object.entries(paymentMethodsSummary).sort(sortByPaymentMethodLength)
  return (
    sortedPaymentMethods.map(([paymentMethod, value]) =>
      <S.PaymentMethodSummaryWrapper key={paymentMethod}>
        <S.TotalPaymentMethods>
          <Text
            size="giga"
            isBold
            noMargin
            data-cy="summary-payment-method"
          >
            Total via {translatePaymentMethod(paymentMethod)}
          </Text>
          <Text
            size="giga"
            noMargin
            isBold
            data-cy="summary-total-payment-method"
          >
            {formatPriceToBRL(value)}
          </Text>
        </S.TotalPaymentMethods>
      </S.PaymentMethodSummaryWrapper>
    )
  )
}

TotalPaymentMethods.propTypes = {
  paymentMethodsSummary: PropTypes.oneOfType([
    PropTypes.objectOf(PropTypes.number),
    PropTypes.arrayOf(PropTypes.number)
  ])
}

export default TotalPaymentMethods
