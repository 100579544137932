const dictionary = {
  available: {
    singular: 'disponível',
    plural: 'disponíveis'
  },
  shipmentDeadline: {
    singular: 'dia útil',
    plural: 'dias úteis'
  },
  hours: {
    singular: 'hora',
    plural: 'horas'
  }
}

const pluralize = (number, type) => {
  const { [type]: word } = dictionary

  return number > 1 ? word.plural : word.singular
}

export default pluralize
