import { useState } from 'react'
import { Button, Card } from '@leroy-merlin-br/backyard-react'

import { useStep } from 'lmcv/hooks/useStep'
import { DropDown, Drawer, WizardForm } from 'lmcv/components'

import { Header } from './components/Header'
import ProjectDate from './components/ProjectDate'
import * as S from './styled'

function ProfileCompleted ({ data, handleDropDown }) {
  const { customerName, projectName, status, expireAt } = data

  const { prevStep, nextStep, step, setStep } = useStep(1)
  const [isVisible, setIsVisible] = useState(false)

  const openDrawer = () => setIsVisible(true)

  const closeDrawer = () => {
    setIsVisible(false)
    setStep(1)
  }

  return (
    <Card>
      <S.Menu>
        <DropDown>
          <DropDown.Item text="Excluir cliente" onClick={handleDropDown} />
        </DropDown>
      </S.Menu>

      <S.Content>
        <Header
          customerName={customerName}
          projectName={projectName}
          projectStatus={status}
        />

        <S.Divider />

        {expireAt && (
          <ProjectDate title={'Projeto finalizado em'} data={expireAt} />
        )}

        <S.ProjectFooter>
          <Button variant="outline" size="kilo" onClick={openDrawer}>
            Iniciar Novo Projeto
          </Button>
        </S.ProjectFooter>
      </S.Content>
      <Drawer
        title="Adicionar Cliente"
        visible={isVisible}
        onClose={closeDrawer}
      >
        {isVisible && (
          <WizardForm prevStep={prevStep} nextStep={nextStep} step={step} />
        )}
      </Drawer>
    </Card>
  )
}

export default ProfileCompleted
