import { SelectableText } from 'frontendCheckout/ui/mobile'

type DeliveryProps = {
  isDelivery: boolean
  onReceive: () => void
}

export const Delivery = ({ isDelivery, onReceive }: DeliveryProps) => {
  return (
    <SelectableText selected={isDelivery} onClick={onReceive}>
      Receber
    </SelectableText>
  )
}
