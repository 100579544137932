import { getAlgonomyRcs, getUserDetails } from 'frontendCheckout/utils/storage'
import { request } from 'frontendCheckout/services/fetch'

import { Response, QueryParams, InitialParams } from './types'
import { endpoints } from './endpoints'

export const getCartInfoUrl = (params: QueryParams) => {
  const envs = window?.env?.algonomy || {}
  const rcs = getAlgonomyRcs()
  const { isOnMobileApp, sessionId, userId } = getUserDetails()

  const initialParams: InitialParams = {
    apiKey: envs.apiKey,
    apiClientKey: isOnMobileApp ? envs.apiClientKey : envs.apiClientKeyApp
  }

  if (sessionId) {
    initialParams.sessionId = sessionId
  }

  if (userId) {
    initialParams.userId = userId
  }

  if (rcs) {
    initialParams.rcs = rcs
  }

  const updateParams = { ...initialParams, ...params }

  return request<Response>({
    url: endpoints.getCartInfoUrl(),
    method: 'GET',
    params: updateParams,
    errors: [400, 404]
  })
}
