import { useCallback, useEffect } from 'react'

import { pushAddPaymentInfo } from '../../'
import { getItemsSubtotal, getOnlyProducts } from '../utils'
import type { ServiceObject } from '../types'

export type GTMPaymentPageProps = {
  services: ServiceObject[]
  totalDiscount: number
  freightCost: string
}

const GTMPaymentPage = ({
  services,
  totalDiscount,
  freightCost
}: GTMPaymentPageProps) => {
  const handleSubmit = useCallback(
    ({ detail }) => {
      const { cart, items } = detail

      const onlyProducts = getOnlyProducts(items, services)
      const subTotal = parseFloat(
        (getItemsSubtotal(items) + totalDiscount).toFixed(2)
      )

      pushAddPaymentInfo({
        products: onlyProducts,
        services,
        payment: {
          value: subTotal,
          discount: totalDiscount,
          method: cart?.method
        },
        freightCost: parseFloat(freightCost)
      })
    },
    [services, totalDiscount, freightCost]
  )

  useEffect(() => {
    document.addEventListener('payment:submit', handleSubmit)

    return () => {
      document.removeEventListener('payment:submit', handleSubmit)
    }
  }, [handleSubmit])

  return null
}

export default GTMPaymentPage
