import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { StyleProps } from '../../types'

const descriptionStyles = ({ theme }: StyleProps) => css`
  label: modal__description;

  display: block;
  margin-bottom: ${theme.spacings.giga};
`

export const Description = styled('span')(descriptionStyles)
