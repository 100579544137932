import { css } from '@emotion/react'
import styled from '@emotion/styled'

const leftStyles = ({ theme, left }) => left && css`
  label: space--left;

  padding-left: ${theme.spacings.giga};

  ${theme.media.mega} {
    padding-left: ${theme.spacings.tera};
  }
`

const rightStyles = ({ theme, right }) => right && css`
  label: space--right;

  padding-right: ${theme.spacings.giga};

  ${theme.media.mega} {
    padding-right: ${theme.spacings.tera};
  }
`

export const Space = styled('div')(rightStyles, leftStyles)
