import { pushDataObject } from '../../data-layer'
import {
  buildTrackerItems,
  getCartType,
  getDataLayerItems,
  getShippingType
} from '../../utils'
import { type Service, type Shipping } from '../../types'

export type PushCartProps = {
  eventName: 'view_cart' | 'begin_checkout'
  shippings: Shipping[]
  services: Service[]
  zipCode: string
  freightCost?: number | undefined
  cart: {
    value: number
    discount: number | undefined
  }
  region?: {
    city: string | undefined
    stateCode: string | undefined
  }
}

export const pushCart = (props: PushCartProps) => {
  const {
    eventName,
    shippings,
    services,
    zipCode,
    freightCost = undefined,
    cart,
    region
  } = props

  const trackerItems = buildTrackerItems({
    shippings,
    services,
    zipCode,
    region
  })

  const dataLayerItems = getDataLayerItems(trackerItems)

  const shippingTypeItems = shippings.map((shipping) => shipping.type)
  const shippingType = getShippingType(shippingTypeItems)

  const shopNameItems = shippings.flatMap((shipping) => {
    return shipping.shipments.flatMap((shipment) => {
      return shipment.items.map((item) => item.shopName)
    })
  })
  const cartType = getCartType(shopNameItems)

  pushDataObject({
    _clear: true,
    event: eventName,
    items: dataLayerItems,
    shipping: {
      price: freightCost,
      type: shippingType
    },
    cart: {
      value: cart.value,
      discount: cart.discount || undefined,
      type: cartType
    }
  })
}
