type Colors = 'primary' | 'neutral'

type ArrowDownProps = {
  width?: number,
  height?: number,
  colorName?: Colors
}

const colorDictionary: { [key in Colors]: string } = {
  primary: '#41A017',
  neutral: '#222020'
}

export const ArrowDown = ({ width = 8, height = 8, colorName = 'primary' }: ArrowDownProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 8 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="arrow-down"
  >
    <path
      d="M0.483429 2.05121L3.82171 4.14718C3.928 4.2132 4.072 4.2132 4.17829 4.14718L7.51771 2.06697C7.71886 1.94183 8 2.06599 8 2.27884V3.43473C8 3.51849 7.95314 3.59732 7.87429 3.64758L4.17829 5.95048C4.072 6.01651 3.928 6.01651 3.82171 5.95048L0.126857 3.64758C0.0468571 3.59732 0 3.51849 0 3.43473L0 2.26307C0 2.04825 0.282286 1.92409 0.483429 2.05121Z"
      fill={colorDictionary[colorName]}
    />
  </svg>
)
