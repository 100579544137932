import { Fragment, forwardRef } from 'react'
import { Helmet } from 'react-helmet'

import { useAdSlot } from 'catalog/hooks/use-ad-slot'
import GPTPropTypes from 'catalog/utils/google-publisher-tag-proptypes'

import * as S from './styled'

const GooglePublisherTag = forwardRef(
  ({ path, id, targeting, sizes, fetchMarginPercent = 0.5 }, ref) => {
    const [isLoading, slotSize] = useAdSlot({
      path,
      id,
      targeting,
      fetchMarginPercent,
      sizes
    })

    return (
      <Fragment>
        <Helmet>
          <script
            async
            src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"
          />
        </Helmet>

        <S.Ad
          isLoading={isLoading}
          width={`${slotSize[0]}px`}
          height={`${slotSize[1]}px`}
          id={id}
          ref={ref}
          data-testid={id}
        />
      </Fragment>
    )
  }
)

GooglePublisherTag.propTypes = GPTPropTypes

export default GooglePublisherTag
