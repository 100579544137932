import {
  Button,
  Stack,
  Heading,
  Container,
  Text,
  Row,
  Col,
  Icon
} from '@leroy-merlin-br/backyard-react'
import { BricksOutline } from '@backyard-ui/icons'

import * as S from './styled'

const ModalContentError = ({ text, title, onClose }) => (
  <S.ContentWrapper>
    <Stack alignX="center" space="giga">
      <Icon as={BricksOutline} />

      <Heading color="n800" size="giga">
        {title}
      </Heading>

      <Text color="n500" size="kilo">
        {text}
      </Text>
    </Stack>

    <Container>
      <Row justify="center">
        <Col size={{ giga: 2 }}>
          <Button type="button" appearance="primary" onClick={onClose}>
            ok
          </Button>
        </Col>
      </Row>
    </Container>
  </S.ContentWrapper>
)

export default ModalContentError
