import { FunctionComponent } from 'react'
import { Loyalty } from 'frontendCheckout/assets/icons'

type IconProps = {
  icon?: FunctionComponent
}

export const Icon = ({ icon: Icon = Loyalty }: IconProps) => {
  return (
    <div className="flex mr-2">
      <Icon />
    </div>
  )
}
