import { useSearchHistory } from 'search/containers/QuickSearch/hooks'

import * as S from './styled.js'

const Term = ({ terms = [] }) => {
  const { storeSearchTerm } = useSearchHistory()

  return (
    <S.Wrapper>
      <div>
        <S.Title>{terms.length > 1 ? 'Termos' : 'Termo'} da busca</S.Title>
      </div>

      <div>
        <ul>
          {terms.map(({ term, url, category }, index) => (
            <li key={index}>
              <S.Link href={url} onClick={() => storeSearchTerm(term)}>
                <strong>{term}</strong>
                {category && ` em: ${category}`}
              </S.Link>
            </li>
          ))}
        </ul>
      </div>
    </S.Wrapper>
  )
}

export default Term
