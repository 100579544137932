import { Twitter, Facebook } from '@backyard-ui/icons'

const socialMediaList = [
  {
    name: 'twitter',
    icon: Twitter,
    url: 'https://twitter.com/home?status=',
    color: '#55acee'
  },
  {
    name: 'facebook',
    icon: Facebook,
    url: 'https://www.facebook.com/sharer/sharer.php?u=',
    color: '#3b5998'
  }
]

export default socialMediaList
