import { useEffect, useRef, useState } from 'react'

import { type Service, type Shipping } from '../../types'
import { pushCart } from '../..'

export type ViewCartProps = {
  shippings: Shipping[]
  services: Service[]
  zipCode: string
  freightCost?: number | undefined
  cart: {
    value: number
    discount: number | undefined
  }
  region?: {
    city: string | undefined
    stateCode: string | undefined
  }
}

const useViewCart = () => {
  const [viewCart, setViewCart] = useState<ViewCartProps>()
  const [hasBeenTriggered, setHasBeenTriggered] = useState<boolean>(false)

  const viewCartRef = useRef<ViewCartProps>()

  useEffect(() => {
    if (viewCart) {
      viewCartRef.current = viewCart

      const hasShippings = viewCart.shippings && viewCart.shippings.length > 0

      if (hasShippings && viewCart.zipCode) {
        pushCart({
          eventName: 'view_cart',
          ...viewCart
        })
        setHasBeenTriggered(true)
      }
    }
  }, [viewCart])

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      const current = viewCartRef.current

      if (!hasBeenTriggered && current) {
        const hasShippings = current.shippings && current.shippings.length > 0

        if (hasShippings) {
          pushCart({
            eventName: 'view_cart',
            ...current
          })
          setHasBeenTriggered(true)
        }
      }
    }, 20000)

    return () => clearTimeout(timeoutId)
  }, [hasBeenTriggered])

  useEffect(() => {
    const handleBeforeUnload = () => {
      if (!hasBeenTriggered && viewCart) {
        const hasShippings = viewCart.shippings && viewCart.shippings.length > 0

        if (hasShippings) {
          pushCart({
            eventName: 'view_cart',
            ...viewCart
          })
          setHasBeenTriggered(true)
        }
      }
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [hasBeenTriggered, viewCart])

  return {
    setViewCart
  }
}

export default useViewCart
