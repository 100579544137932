import UpdateNowButtonHelper from './UpdateNowButtonHelper'
import * as S from './styled'
import { MobilePhone } from './Icon'

const MustUpdateNewVersionComponentComponent = () => {
  const textBody =
    'Para utilizar seu aplicativo Leroy Merlin será necessário realizar a atualização para a versão mais nova.'

  const textTitle = 'Atualização necessária'
  return (
    <S.WrapperModal>
      <S.IconBody>
        <MobilePhone />
      </S.IconBody>
      <S.Title>{textTitle}</S.Title>
      <S.Wrapper />
      <S.TextBody>{textBody}</S.TextBody>
      <S.Wrapper />
      <UpdateNowButtonHelper category={'force_update'} />
    </S.WrapperModal>
  )
}

export default MustUpdateNewVersionComponentComponent
