import { Text } from '@leroy-merlin-br/backyard-react'

import { AntiFraud } from '../../AntiFraud'
import { ContentHeader } from '../../ContentHeader'
import * as S from './styled'
import { CreditCardVisualType } from './type'

const CreditCard = ({ orderId }: CreditCardVisualType) => (
  <ContentHeader
    title={'Recebemos seu pedido!'}
    content={
      <>
        <Text>
          Número do pedido:{' '}
          <Text as="span" isBold>
            {orderId}
          </Text>
        </Text>
        <Text>
          Após a{' '}
          <Text as="span" isBold>
            aprovação do pagamento,
          </Text>{' '}
          vamos iniciar o processo de separação do seu pedido,{' '}
          <Text as="span" isBold>
            aguarde nosso e-mail de confirmação
          </Text>
        </Text>
        <AntiFraud />
      </>
    }
  />
)

export default CreditCard
