import { Button, Stack } from '@leroy-merlin-br/backyard-react'
import { InvoiceStatus } from 'lmcv/pages/CashbackPage/types/invoice-status'
import { Item } from 'lmcv/pages/CashbackPage/components/Stepper/types/item'
import { ItemStatus } from 'lmcv/pages/CashbackPage/components/Stepper/types/item-status'
import { Stepper } from 'lmcv/pages/CashbackPage/components/Stepper'
import * as S from './styled'
import { useRedeemContext } from '../../../../contexts/RedeemContext'

type StatusRules = {
  [key in InvoiceStatus]: [ItemStatus, ItemStatus, ItemStatus]
}

const statusRules: StatusRules = {
  NOT_SENT: ['pending', 'pending', 'pending'],
  SENT_WAITING_ANALYSIS: ['done', 'inProgress', 'pending'],
  PAYMENT_SUCCESS: ['done', 'done', 'done'],
  PAYMENT_DENIED: ['done', 'done', 'block'],
  UNKNOWN: ['pending', 'pending', 'pending']
}

export default function Content() {
  const {
    invoiceStatus = 'NOT_SENT',
    setComponent,
    invoiceStatusData
  } = useRedeemContext()

  const itemsStatus = statusRules[invoiceStatus]

  const stepperItems: Item[] = [
    {
      label: 'Enviada',
      status: itemsStatus[0]
    },
    {
      label: 'Análise',
      status: itemsStatus[1]
    },
    {
      label: 'Pagamento',
      status: itemsStatus[2]
    }
  ]

  const showButtonContinue = invoiceStatus === 'PAYMENT_SUCCESS'
  const showButtonResend = invoiceStatus === 'PAYMENT_DENIED'

  const message =
    invoiceStatus === 'PAYMENT_DENIED' && invoiceStatusData?.status.message
      ? invoiceStatusData?.status.message
      : 'Sua nota fiscal já está sendo analisada pelo nosso time e assim que aprovada seguiremos para o pagamento.'

  return (
    <Stack space="giga">
      <Stepper items={Object.values(stepperItems)} />

      <Stack space="peta" alignX="center">
        <S.Text>
          <p>{message}</p>
        </S.Text>

        {showButtonContinue && <Button size="kilo">Continuar</Button>}

        {showButtonResend && (
          <Button
            size="kilo"
            variant="outline"
            onClick={() => setComponent('REDEEM')}
          >
            Reenviar nota fiscal
          </Button>
        )}
      </Stack>
    </Stack>
  )
}
