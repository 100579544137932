import { zodResolver } from '@hookform/resolvers/zod'
import { toast } from '@leroy-merlin-br/backyard-react'
import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import {
  FormState,
  UseFormReturn,
  useForm,
  useFormState
} from 'react-hook-form'
import { z } from 'zod'
import { removeNonNumericCharacters } from 'user/utils'

import { useCashbackContext } from 'lmcv/pages/CashbackPage/contexts/CashbackContext'
import { Bank, BankOption } from 'lmcv/pages/CashbackPage/types/api/bank'
import { LegalEntity } from 'lmcv/pages/CashbackPage/types/api/legal-entity'
import { createSupplierService } from 'lmcv/pages/CashbackPage/services/supplier.service'
import { createBankService } from 'lmcv/pages/CashbackPage/services/bank.service'
import { createLegalEntityService } from 'lmcv/pages/CashbackPage/services/legal-entity.service'

import { Supplier } from '../../../../../types/api/supplier'
import { ArchitectRegistrationDto } from '../../../../../types/dto/architect-registration.dto'
import { AttachmentsDto } from '../../../../../types/dto/attachments.dto'
import { AxiosError } from 'axios'

const REQUIRED_FIELD_MESSAGE = 'Campo obrigatório'

export const ATTACHMENT_SIZE_MAX_MB = 4
export const ATTACHMENT_ALLOWED_FORMATS = ['jpg', 'jpeg', 'png', 'pdf']

const ATTACHMENT_SIZE_VALIDATOR = (file: File) =>
  file.size <= ATTACHMENT_SIZE_MAX_MB * 1024 * 1024

const ATTACHMENT_SIZE_ERROR_MESSAGE = `Tamanho deve ser de no máximo ${ATTACHMENT_SIZE_MAX_MB}MB`

const formSchema = z
  .object({
    // company
    companyDocumentNumber: z
      .string()
      .min(1, REQUIRED_FIELD_MESSAGE)
      .transform(removeNonNumericCharacters),
    companySocialName: z.string().min(1, REQUIRED_FIELD_MESSAGE),
    companyFantasyName: z.string().optional(),
    companyStateRegistration: z.string().optional(),
    companyMainCnae: z.string().min(1, REQUIRED_FIELD_MESSAGE),
    companyCityRegistration: z.string().min(1, REQUIRED_FIELD_MESSAGE),

    // company representative
    companyIsRepresentative: z.boolean(),
    companyRepresentativeName: z.string().optional(),
    companyRepresentativeDocumentNumber: z
      .string()
      .optional()
      .transform(removeNonNumericCharacters),
    companyRepresentativeEmail: z
      .string()
      .email('E-mail inválido')
      .optional()
      .or(z.literal('')),
    companyRepresentativePhoneNumber: z
      .string()
      .optional()
      .transform(removeNonNumericCharacters),

    // bank
    bankName: z.string().min(1, REQUIRED_FIELD_MESSAGE).default(''),
    bankAgencyNumber: z.string().min(1, REQUIRED_FIELD_MESSAGE).default(''),
    bankAccountNumber: z.string().min(1, REQUIRED_FIELD_MESSAGE),
    bankAccountType: z.string().min(1, REQUIRED_FIELD_MESSAGE),

    // address
    addressCep: z.string().trim().min(1, REQUIRED_FIELD_MESSAGE).default(''),
    addressState: z.string().default(''),
    addressCity: z.string().default(''),
    addressStreet: z.string().default(''),
    addressNumber: z.string().trim().optional().default(''),
    addressComplement: z.string().trim().optional().default(''),
    addressDistrict: z.string().default(''),

    // attachments
    attachmentCityRegistration: z
      .instanceof(File, { message: REQUIRED_FIELD_MESSAGE })
      .refine((file) => file != null, {
        message: REQUIRED_FIELD_MESSAGE
      })
      .refine(ATTACHMENT_SIZE_VALIDATOR, ATTACHMENT_SIZE_ERROR_MESSAGE),
    attachmentBankStatement: z
      .instanceof(File, { message: REQUIRED_FIELD_MESSAGE })
      .refine((file) => file != null, {
        message: REQUIRED_FIELD_MESSAGE
      })
      .refine(ATTACHMENT_SIZE_VALIDATOR, ATTACHMENT_SIZE_ERROR_MESSAGE),

    // contract check
    contractCheck: z
      .boolean()
      .refine((value) => !!value, 'O código de conduta deve ser aceito')
  })
  .superRefine((values, ctx) => {
    const companyRepresentativeConditionalRequiredFields = [
      'companyRepresentativeName',
      'companyRepresentativeDocumentNumber',
      'companyRepresentativeEmail',
      'companyRepresentativePhoneNumber'
    ]

    companyRepresentativeConditionalRequiredFields.forEach((field) => {
      if (values.companyIsRepresentative === false && !(values as any)[field]) {
        ctx.addIssue({
          path: [field],
          code: z.ZodIssueCode.too_small,
          type: 'string',
          minimum: 1,
          inclusive: true,
          message: REQUIRED_FIELD_MESSAGE
        })
      }
    })
  })

export type FormSchema = z.infer<typeof formSchema>

export interface IFormProvider {
  form: UseFormReturn<FormSchema>
  formState: FormState<FormSchema>
  onSubmit: (data: FormSchema) => void
  banks: Bank[]
  legalEntity: LegalEntity | undefined
  loadingLegalEntity: boolean
  getLegalEntity: () => void
  submitting: boolean
  companyFormIsDisabled: boolean
  handleGetAgenciesByBank: (inputValue: string) => void
  internalBankName: BankOption | undefined
  setInternalBankName: (value: BankOption) => void
  internalBankAgency: BankOption | undefined
  setInternalBankAgency: (value: BankOption) => void
  attachmentCityRegistrationIsViewOnly: boolean
  setAttachmentCityRegistrationIsViewOnly: (value: boolean) => void
  attachmentBankStatementIsViewOnly: boolean
  setAttachmentBankStatementIsViewOnly: (value: boolean) => void
}

interface FormProviderProps {
  children: JSX.Element | JSX.Element[]
}

const FormContext = createContext<IFormProvider | undefined>(undefined)

const FormProvider = (props: FormProviderProps) => {
  const { setSupplierStatus, supplier } = useCashbackContext()

  const architectService = createSupplierService()
  const bankService = createBankService()
  const legalEntityService = createLegalEntityService()

  const form = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      companyIsRepresentative: true
    }
  })

  const formState = useFormState({ control: form.control })

  const [banks, setBanks] = useState<Bank[]>([])

  const [internalBankName, setInternalBankName] = useState<BankOption>()
  const [internalBankAgency, setInternalBankAgency] = useState<BankOption>()

  const [loadingLegalEntity, setLoadingLegalEntity] = useState(false)
  const [legalEntity, setLegalEntity] = useState<LegalEntity>()

  const [submitting, setSubmitting] = useState(false)

  const [companyFormIsDisabled, setCompanyFormIsDisabled] = useState(true)

  /**
   * This states are used to verify is file is loaded or view only.
   * Is view only when return from supplier api.
   */
  const [
    attachmentCityRegistrationIsViewOnly,
    setAttachmentCityRegistrationIsViewOnly
  ] = useState(false)
  const [
    attachmentBankStatementIsViewOnly,
    setAttachmentBankStatementIsViewOnly
  ] = useState(false)

  const companyDocumentNumber = form.watch('companyDocumentNumber')

  const isResend = Boolean(supplier)

  useEffect(() => {
    if (supplier) {
      setResendFormValues(supplier)
    }
  }, [supplier])

  useEffect(() => {
    getBanks()
  }, [])

  const setResendFormValues = (sup: Supplier) => {
    form.setValue('companyDocumentNumber', sup.company.cnpj)
    form.setValue('companySocialName', sup.company.socialName)
    form.setValue('companyFantasyName', sup.company.fantasyName)
    form.setValue('companyStateRegistration', sup.company.stateRegistration)
    form.setValue('companyMainCnae', sup.company.economicActivity)
    form.setValue('companyCityRegistration', sup.company.cityRegistration)

    form.setValue(
      'companyIsRepresentative',
      sup.legalRepresentative.isLegalRepresentative
    )
    if (!sup.legalRepresentative.isLegalRepresentative) {
      form.setValue('companyRepresentativeName', sup.legalRepresentative.name)
      form.setValue(
        'companyRepresentativeDocumentNumber',
        sup.legalRepresentative.cpf
      )
      form.setValue('companyRepresentativeEmail', sup.legalRepresentative.email)
      form.setValue(
        'companyRepresentativePhoneNumber',
        sup.legalRepresentative.phone
      )
    }

    form.setValue('bankName', sup.bank.name)

    form.setValue('bankAccountType', sup.bank.accountType)
    form.setValue('bankAccountNumber', sup.bank.accountNumber)

    form.setValue('addressCep', sup.address.postalCode)
    form.setValue('addressState', sup.address.state)
    form.setValue('addressCity', sup.address.city)
    form.setValue('addressStreet', sup.address.street)
    form.setValue('addressNumber', sup.address.number)
    form.setValue('addressComplement', sup.address.complement || '')
    form.setValue('addressDistrict', sup.address.district)

    const cityRegistration = sup.files.find(
      (f) => f.type == 'INSCRICAO_MUNICIPAL'
    )
    if (cityRegistration) {
      form.setValue(
        'attachmentCityRegistration',
        new File([], cityRegistration.filename)
      )
      setAttachmentCityRegistrationIsViewOnly(true)
    }

    const bankStatement = sup.files.find(
      (f) => f.type == 'COMPROVANTE_BANCARIO'
    )
    if (bankStatement) {
      form.setValue(
        'attachmentBankStatement',
        new File([], bankStatement.filename)
      )
      setAttachmentBankStatementIsViewOnly(true)
    }
  }

  const getLegalEntity = () => {
    setLoadingLegalEntity(true)
    legalEntityService
      .getByDocumentNumber(companyDocumentNumber)
      .then((response) => {
        if (!response.sales_enabled) {
          // inactive cnpj
          form.setError('companyDocumentNumber', { message: 'CNPJ inválido' })
          return
        }

        setLegalEntity(response)
      })
      .catch(() => {
        form.clearErrors('companyDocumentNumber')
      })
      .finally(() => {
        setLoadingLegalEntity(false)
        setCompanyFormIsDisabled(false)
      })
  }

  const getBanks = () => {
    bankService.getAll().then(async (banks) => {
      setBanks(banks)

      if (supplier?.bank?.name) {
        const [bank] = banks.filter(
          (bank: Bank) => bank.nome_numero === supplier.bank.name
        )

        setInternalBankName(bank)

        try {
          const bankAgencies = await bankService.getAgenciesByBank(
            bank.sys_id,
            supplier.bank.agency
          )

          const [bankAgency] = bankAgencies.map((bank: Bank) => ({
            sys_id: bank.sys_id,
            value: bank.codigo,
            label: bank.nome_numero
          }))

          setInternalBankAgency(bankAgency)

          form.setValue('bankAgencyNumber', bankAgency.value)
        } catch {}
      }
    })
  }

  const handleGetAgenciesByBank = async (inputValue: string) => {
    if (!internalBankName?.sys_id) {
      return []
    }

    try {
      const bankAgencies = await bankService.getAgenciesByBank(
        internalBankName.sys_id,
        inputValue
      )

      return bankAgencies.map((bank: Bank) => ({
        sys_id: bank.sys_id,
        value: bank.codigo,
        label: bank.nome_numero
      }))
    } catch {
      return []
    }
  }

  const onSubmit = (data: FormSchema) => {
    const payload: ArchitectRegistrationDto = {
      company: {
        documentNumber: data.companyDocumentNumber,
        socialName: data.companySocialName,
        fantasyName: data.companyFantasyName,
        stateRegistration: removeNonNumericCharacters(
          data.companyStateRegistration
        ),
        mainCnae: removeNonNumericCharacters(data.companyMainCnae),
        cityRegistration: removeNonNumericCharacters(
          data.companyCityRegistration
        ),
        isRepresentative: data.companyIsRepresentative,
        representativeName: data.companyRepresentativeName,
        representativeDocumentNumber: data.companyRepresentativeDocumentNumber,
        representativeEmail: data.companyRepresentativeEmail,
        representativePhoneNumber: removeNonNumericCharacters(
          data.companyRepresentativePhoneNumber
        ),
        address: {
          postalCode: data.addressCep,
          state: data.addressState,
          city: data.addressCity,
          street: data.addressStreet,
          number: data.addressNumber,
          complement: data.addressComplement,
          district: data.addressDistrict
        }
      },
      bank: {
        name: data.bankName,
        agencyNumber:
          data.bankAgencyNumber.length === 5
            ? data.bankAgencyNumber.slice(0, -1)
            : data.bankAgencyNumber,
        agencyDigit:
          data.bankAgencyNumber.length === 5
            ? data.bankAgencyNumber.slice(-1)
            : null,
        accountNumber: data.bankAccountNumber,
        accountType: data.bankAccountType
      }
    }

    const attachments: AttachmentsDto = {
      cityRegistration: attachmentCityRegistrationIsViewOnly
        ? null
        : data.attachmentCityRegistration,
      bankStatement: attachmentBankStatementIsViewOnly
        ? null
        : data.attachmentBankStatement
    }

    setSubmitting(true)
    architectService
      .submitRegistration(payload, attachments)
      .then(() => {
        toast.primary('Solicitação de cadastro enviada', {
          variant: 'solid'
        })
        setSupplierStatus('WAITING_DOCUMENT_ANALYSIS')
      })
      .catch((error: AxiosError) => {
        if (error.response?.data?.errors?.length) {
          mapSubmitErrors(error.response.data?.errors)
        } else {
          toast.critical('Erro ao enviar cadastro. Tente mais tarde.', {
            variant: 'solid'
          })
        }
      })
      .finally(() => {
        setSubmitting(false)
      })
  }

  const mapSubmitErrors = (errors: { code: string; message: string }[]) => {
    const fields: { [key: string]: keyof FormSchema } = {
      'company.cnpj': 'companyDocumentNumber',
      'company.socialName': 'companySocialName',
      'company.fantasyName': 'companyFantasyName',
      'company.stateRegistration': 'companyStateRegistration',
      'company.economicActivity': 'companyMainCnae',
      'company.cityRegistration': 'companyCityRegistration',
      'legalRepresentative.name': 'companyRepresentativeName',
      'legalRepresentative.cpf': 'companyRepresentativeDocumentNumber',
      'legalRepresentative.email': 'companyRepresentativeEmail',
      'legalRepresentative.phone': 'companyRepresentativePhoneNumber',
      'bank.codigo': 'bankName',
      'bank.accountType': 'bankAccountType',
      'bank.agency': 'bankAgencyNumber',
      'bank.accountNumber': 'bankAccountNumber',
      'address.postalCode': 'addressCep',
      'address.state': 'addressState',
      'address.city': 'addressCity',
      'address.street': 'addressStreet',
      'address.number': 'addressNumber',
      'address.complement': 'addressComplement',
      'address.district': 'addressDistrict'
    }

    errors.forEach((error) => {
      const formField = fields[error.code]

      if (formField) {
        form.setError(fields[error.code], {
          type: 'validate',
          message: error.message
        })
      } else {
        toast.critical(error.message, {
          variant: 'solid'
        })
      }
    })

    form.setFocus(fields[errors[0].code])

    const errorsCount = errors.length
    toast.critical(
      `${errorsCount} ${
        errorsCount > 1 ? 'erros' : 'erro'
      } ao enviar o cadastro.`,
      {
        variant: 'solid'
      }
    )
  }

  const returnValue = useMemo(
    () => ({
      form,
      formState,
      onSubmit,
      banks,
      legalEntity,
      loadingLegalEntity,
      getLegalEntity,
      submitting,
      companyFormIsDisabled,
      handleGetAgenciesByBank,
      internalBankName,
      setInternalBankName,
      internalBankAgency,
      setInternalBankAgency,
      attachmentCityRegistrationIsViewOnly,
      setAttachmentCityRegistrationIsViewOnly,
      attachmentBankStatementIsViewOnly,
      setAttachmentBankStatementIsViewOnly
    }),
    [
      form,
      formState,
      banks,
      legalEntity,
      loadingLegalEntity,
      submitting,
      companyFormIsDisabled,
      handleGetAgenciesByBank,
      internalBankName,
      setInternalBankName,
      internalBankAgency,
      setInternalBankAgency,
      attachmentCityRegistrationIsViewOnly,
      attachmentBankStatementIsViewOnly
    ]
  )

  return (
    <FormContext.Provider value={returnValue}>
      {props.children}
    </FormContext.Provider>
  )
}

export default FormProvider

export const useFormContext = () => useContext(FormContext)!
