import PropTypes from 'prop-types'
import { Stack } from '@leroy-merlin-br/backyard-react'

import { CouponLoading, Transition } from 'lmcv/components'

import * as S from './styled'

const VouchersSkeleton = ({ size = 3 }) => {
  return (
    <Stack>
      <Transition.Fade>
        <S.ListContainer>
          {Array(size)
            .fill()
            .map((_, index) => (
              <CouponLoading key={`item-${index}`} />
            ))}
        </S.ListContainer>
      </Transition.Fade>
    </Stack>
  )
}

VouchersSkeleton.propTypes = {
  size: PropTypes.number
}

export default VouchersSkeleton
