export default {
  residential: 'Casa',
  apartment: 'Apartamento',
  condominium: 'Condomínio',
  shop: 'Loja ou Sobreloja',
  room: 'Sala ou Conjunto Comercial',
  rural: 'Rural - Chácara, Fazenda ou Sítio',
  storage: 'Depósito ou Galpão',
  lot: 'Terreno ou Loteamento',
  box: 'Box ou Stand',
  other: 'Outro'
}
