import { useEffect, useState } from 'react'

import { getMarketplaceChat } from '../services'
import type { ApiDataType } from '../factory/factory'

function useMarketplaceChatService () {
  const [marketplaceChat, setMarketplaceChat] = useState<ApiDataType | null>(null)

  useEffect(() => {
    const { data } = getMarketplaceChat()

    setMarketplaceChat(data)
  }, [setMarketplaceChat])

  return { marketplaceChat }
}

export default useMarketplaceChatService
