import styled from '@emotion/styled'
import { css } from '@emotion/react'

const searchWrapperStyles = () => css`
  label: search__wrapper;

  display: flex;
  flex-direction: row;
  width: 100%;
  height: 44px;
`
export const SearchWrapper = styled('div')(searchWrapperStyles)

const searchInputStyles = ({ theme }) => css`
  label: search__wrapper;

  font-size: ${theme.typography.text.mega.fontSize};
  line-height: ${theme.typography.text.mega.lineHeight};
  height: 100%;
  width: 100%;
  border: none;
  border-top-left-radius: 40px;
  border-bottom-left-radius: 40px;
  outline-style: none;
  padding: ${theme.spacings.mega} 18px;
  background-color: ${theme.colors.n100};

  ::placeholder {
    color: ${theme.colors.n300};
  }
`

export const SearchInput = styled('input')(searchInputStyles)

const searchButtonStyles = ({ theme }) => css`
  label: search__button;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 100%;
  z-index: 1;
  border-radius: 0px ${theme.borderRadius.giga} ${theme.borderRadius.giga} 0px;
  background-color: ${theme.colors.p600};
  cursor: pointer;

  :hover {
    background-color: ${theme.colors.p700};
  }

  & > svg {
    color: ${theme.colors.white};
  }
`
export const SearchButton = styled('button')(searchButtonStyles)

const resultCardStyles = () => css`
  label: result-card;

  margin-top: 10px;
  z-index: 15;

  & > div {
    z-index: 15;
  }
`

export const ResultCard = styled('div')(resultCardStyles)

const resultBoxStyles = ({ theme }) => css`
  label: result-box;
  display: flex;
  flex-wrap: wrap;
  max-height: 75vh;

  & > div {
    flex: 1 100%;

    ${theme.media.mega} {
      flex: 1 50%;
      :nth-of-type(2) {
        border-left: 1px solid ${theme.colors.n100};
      }
    }
  }
`
export const ResultBox = styled('div')(resultBoxStyles)

const searchInputWrapperStyles = () => css`
  label: text-field-search;

  margin-right: -50px;
  width: 100%;

  & > div {
    width: 100%;
  }

  & input {
    height: 44px;

    padding-right: 50px;

    :focus {
      box-shadow: none;
    }
  }

  & label {
    margin-bottom: 0px;
  }
`
export const SearchInputWrapper = styled('div')(searchInputWrapperStyles)
