'use client'
import { PropsWithChildren } from 'react'
import { VariantProps } from 'tailwind-variants'

import { Check, Error } from 'frontendCheckout/assets/icons'

import { message } from './Message.style'

type MessageVariants = VariantProps<typeof message>

export const Message = ({
  children,
  ...tvProps
}: PropsWithChildren<{}> & MessageVariants) => {
  if (!children) {
    return <></>
  }

  const Icon = tvProps.color === 'error' ? Error : Check
  return (
    <div className={message(tvProps)}>
      <Icon />
      <span>{children}</span>
    </div>
  )
}
