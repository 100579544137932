import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { styleHelpers } from '@leroy-merlin-br/backyard-react'

const wrapperStyles = ({ theme }) => css`
  label: wrapper;

  padding: ${theme.spacings.mega};
  margin-bottom: ${theme.spacings.mega};

  width: 100%;

  background: ${theme.colors.n100};

  border-radius: ${theme.borderRadius.giga};
`

const titleStyles = ({ theme }) => css`
  label: title;

  ${styleHelpers.headingKilo(theme)};
  font-weight: ${theme.font.weight.semibold};

  margin-bottom: ${theme.spacings.byte};
`

export const Wrapper = styled('div')(wrapperStyles)
export const Title = styled('strong')(titleStyles)
