import { Button, Dialog } from '@leroy-merlin-br/backyard-react'
import { redirectModal } from 'user/celebre/utils'

const BaseModal = ({ onClose, children }) => {
  const footer = (
    <Button
      size="mega"
      data-cy="modal-confirm-button"
      onClick={() => redirectModal(onClose)}
      isStretch
    >
      Entendi!
    </Button>
  )

  return (
    <Dialog onClose={onClose} footer={footer}>
      {children}
    </Dialog>
  )
}

export default BaseModal
