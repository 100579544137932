import { useSelector, shallowEqual } from 'react-redux'

import { GenericCarousel } from 'shared/components/GenericCarousel'

import { Coupon } from '../Coupon'
import * as S from './styled'

const Carousel = () => {
  const {
    get: { coupons }
  } = useSelector(state => state.module, shallowEqual)

  const carouselProps = {
    slidesPerView: 'auto',
    spaceBetween: 15
  }

  return (
    <S.Wrapper>
      {!coupons.length
        ? null
        : (
          <GenericCarousel {...carouselProps}>
            {coupons.map(data => (
              <Coupon key={data.id} {...data} />
            ))}
          </GenericCarousel>
          )}
    </S.Wrapper>
  )
}

export default Carousel
