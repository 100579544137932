import React from 'react'

import { renderTemplate } from '../render-template'

const EditPhone = ({ _id, index, type, number, default: isDefault }) => (
  <div className="hidden">
    <div className="container-fluid" data-component="validation">
      <div className="modal-title">
        {_id ? 'Alterar telefone' : 'Adicionar telefone'}
      </div>

      <fieldset className="row" data-component="account/edit-phone">
        <input type="hidden" name="index" value={index} />

        {_id && (
          <input type="hidden" name={`phones[${index}][_id]`} value={_id} />
        )}

        <div className="col-xs-12 col-sm-4">
          <div className="field" data-validation="field">
            <select
              className="select"
              name={`phones[${index}][type]`}
              id={`phones[${index}][type]`}
              data-required
              data-validate="required"
              defaultValue={type}
              data-phone-edit="select"
            >
              <option value=""></option>
              <option value="landline">Fixo</option>
              <option value="cellphone">Celular</option>
            </select>

            <label className="label" htmlFor={`phones[${index}][type]`}>
              Tipo
            </label>
            <div className="helper">Fixo/Celular</div>
          </div>
        </div>

        <div className="col-xs-12 col-sm-8">
          <div className="field" data-validation="field">
            <input
              className="input"
              type="text"
              name={`phones[${index}][number]`}
              id={`phones[${index}][number]`}
              data-required
              data-validate="minlength"
              data-minlength="14"
              data-phone-mask
              defaultValue={number}
            />

            <label className="label" htmlFor={`phones[${index}][number]`}>
              Número do telefone
            </label>
            <div className="helper">Ex: (11) 99999-9999</div>
          </div>
        </div>

        <div className="col-xs-12">
          <div className="field" data-validation="field" data-phone-edit="checkbox">
            <input
              type="checkbox"
              name={`phones[${index}][default]`}
              id={`phones[${index}][default]`}
              defaultValue="1"
              defaultChecked={isDefault === 'true' || index === 0}
            />

            <label className="label" htmlFor={`phones[${index}][default]`}>
              Este é meu telefone principal
            </label>
          </div>
        </div>

        <div className="col-xs-12 modal-footer align-right">
          <button
            className="button button-text"
            type="button"
            data-trigger="close"
          >
            Cancelar
          </button>
          <button
            className="button button-primary"
            type="button"
            data-trigger="submit"
          >
            Salvar telefone
          </button>
        </div>
      </fieldset>
    </div>
  </div>
)

const render = (props = {}) => renderTemplate(<EditPhone {...props} />)

export default render
