import styled from '@emotion/styled'
import { css } from '@emotion/react'

const itemStyles = ({ theme }) => css`
  label: item;

  display: block;
  position: relative;
  margin-top: ${theme.spacings.tera};
  margin-bottom: ${theme.spacings.tera};
`

const desktopStyles = ({ isMobile }) =>
  !isMobile &&
  css`
    label: item--desktop;

    &:not(:last-of-type) {
      margin-right: 64px;
    }
  `

const withDividerStyles = ({ hasDivider, theme }) =>
  hasDivider &&
  css`
    label: item--with-divider;

    &::after {
      background: linear-gradient(${theme.colors.n200}, ${theme.colors.n200}),
        linear-gradient(${theme.colors.n200}, ${theme.colors.n200}),
        rgba(0, 0, 0, 0);
      background-position: center;
      background-repeat: no-repeat;
      background-size: 14px 2px, 2px 14px;
      content: '';
      display: block;
      height: ${theme.spacings.mega};
      position: absolute;
      right: -38px;
      top: ${theme.spacings.mega};
      width: 16px;
    }
  `

const listStyles = () => css`
  label: list;

  display: flex;
  justify-content: center;
  list-style: none;

  & > ${Item} {
    max-width: 100px;
  }

  width: 100%;
`

const imageStyles = ({ theme }) => css`
  label: image;

  svg {
    display: block;
    margin: 0 auto;
    width: 40px;
    height: 40px;
    margin-bottom: ${theme.spacings.mega};
  }
`

export const Item = styled('div')(itemStyles, desktopStyles, withDividerStyles)
export const List = styled('ul')(listStyles)
export const Image = styled('div')(imageStyles)
