import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import { Link } from 'react-router-dom'
import {
  Hidden,
  Visible,
  Card,
  Col,
  Text
} from '@leroy-merlin-br/backyard-react'
import { ChevronRightOutline } from '@backyard-ui/icons'

import { formatDate } from 'utils/date'
import { useResize } from 'utils/hooks/use-resize'

import * as S from './styled'

const Professional = ({ data }) => {
  const [isMobile] = useResize()

  if (isEmpty(data.payload) || data.isRequest) {
    return null
  }

  const { id, professionalName, projectName, status, expireAt } = data.payload

  return (
    <Card title="Meu Profissional">
      <Link to={`/profissional/${id}`}>
        <S.ModuleContent>
          <Col size={{ mega: 3 }}>
            <S.DividerWrapper>
              <S.ModuleWrapper>
                <S.ModuleAvatar>{professionalName?.[0]}</S.ModuleAvatar>
                <S.TextWrapper>
                  <Text color="n500" isBold noMargin>
                    {professionalName}
                  </Text>
                  <Visible when={['untilKilo']}>
                    <Text color="n500" noMargin>
                      {projectName}
                    </Text>
                  </Visible>
                  <S.StatusWrapper>
                    <Text size="kilo" noMargin color="p500">
                      {status}
                    </Text>
                  </S.StatusWrapper>
                </S.TextWrapper>
              </S.ModuleWrapper>
            </S.DividerWrapper>
          </Col>

          <Hidden when={['untilKilo']}>
            <Col size={{ mega: 2 }}>
              <S.ModuleWrapper>
                <S.TextWrapper>
                  <Text color="n500" noMargin>
                    Nome do projeto:
                  </Text>
                  <Text color="p500" noMargin>
                    {projectName}
                  </Text>
                </S.TextWrapper>
              </S.ModuleWrapper>
            </Col>
          </Hidden>

          <Hidden when={['untilKilo']}>
            <Col size={{ mega: 3 }}>
              <S.ModuleWrapper>
                <S.TextWrapper>
                  <Text color="n500" noMargin>
                    Tempo Previsto:
                  </Text>
                  {expireAt && (
                    <Text color="n500" size="kilo" noMargin>
                      ({formatDate(expireAt, 'DD/MM/YYYY')})
                    </Text>
                  )}
                </S.TextWrapper>

                <S.ButtonIcon>
                  <ChevronRightOutline width={18} height={18} fill="currentColor" />
                </S.ButtonIcon>
              </S.ModuleWrapper>
            </Col>
          </Hidden>
        </S.ModuleContent>
      </Link>
    </Card>
  )
}

Professional.propTypes = {
  data: PropTypes.shape({
    payload: PropTypes.shape({
      id: PropTypes.string,
      professionalName: PropTypes.string,
      projectName: PropTypes.string,
      status: PropTypes.string,
      expireAt: PropTypes.string
    }),
    isRequest: PropTypes.bool
  })
}

export default Professional
