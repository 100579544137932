import { ModalProvider, ToastContainer } from '@leroy-merlin-br/backyard-react'

import reactComponentLoader from 'scripts/react-component-loader'

import { Header } from 'site/Header'

const HeaderWithModalProvider = props => (
  <ModalProvider>
    <ToastContainer position="top-right" />
    <Header {...props} />
  </ModalProvider>
)

reactComponentLoader(HeaderWithModalProvider, '[data-new-header]')
