import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { StyleProps } from 'lmcv/components/CouponModal/types'

const titleWrapperStyles = ({ theme }: StyleProps) => css`
  label: title-wrapper;

  span {
    color: ${theme.colors.p500};
  }
`

export const TitleWrapper = styled('div')(titleWrapperStyles)
