import { ReactNode } from 'react'
import {
  Heading,
  Text,
  Stack,
  SubHeading
} from '@leroy-merlin-br/backyard-react'

import * as S from './styled'

export type LayoutProps = {
  children: ReactNode
  description: string | ReactNode
}

const Layout: React.FC<LayoutProps> = ({ children, description }) => (
  <S.Wrapper>
    <Stack space="byte">
      <Heading size="peta" noMargin>
        Esqueci a senha
      </Heading>
      <SubHeading as="h4" size="mega">
        {description}
      </SubHeading>

      {children}
    </Stack>
  </S.Wrapper>
)

export default Layout
