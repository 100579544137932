export const findProductFromCart = (id, cart) => {
  let productFound = null

  cart.shippings.forEach((shipping) =>
    shipping.items.forEach((product) => {
      if (product.productId === id) {
        productFound = product
      }
    })
  )

  return productFound
}

export const splitCategoryTree = (categoryTree) => {
  if (!categoryTree) return []

  if (Array.isArray(categoryTree)) {
    return categoryTree
  }

  return categoryTree.split('/')
}

const discountedPrice = (price, discountPercentage) =>
  parseFloat((price - (discountPercentage / 100) * price).toFixed(2))

export const normalizedProduct = (product) => {
  const categories = splitCategoryTree(product.categoryTree)
  const hasDiscountToApply = product.price && product.discountPercentage
  const hasPrice =
    product.price && product.price.integers && product.price.decimals

  const productPrice = hasPrice
    ? parseFloat(`${product.price.integers}.${product.price.decimals}`)
    : 0

  return {
    item_id: product.item_id,
    item_name: product.item_name,
    discount: hasDiscountToApply
      ? discountedPrice(productPrice, product.discountPercentage)
      : 0,
    item_brand: product.item_brand,
    item_category: categories[0] || '',
    item_category2: categories[1] || '',
    item_category3: categories[2] || '',
    item_category4: categories[3] || '',
    item_category5: categories[4] || '',
    price: productPrice,
    currency: 'BRL',
    item_list_name: product.item_list_name
  }
}
