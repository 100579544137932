import styled from '@emotion/styled'
import { css } from '@emotion/react'

const wrapperStyles = ({ theme }) => css`
  label: wrapper;

  display: grid;
  grid-template-rows: auto auto;
  grid-row-gap: ${theme.spacings.byte};

  background: ${theme.colors.n100};
  width: 100%;
  padding: ${theme.spacings.mega};
  border-radius: ${theme.borderRadius.giga};

  color: ${theme.colors.n900};

  & p {
    label: wrapper__text;
    color: ${theme.colors.n600};
  }
`

export const Wrapper = styled('div')(wrapperStyles)
