import { useSaleOrderDetailed } from 'frontendMyOrders/hooks'
import { Item } from 'frontendMyOrders/services/sale-order'
import { Badge } from 'frontendMyOrders/user-interface/badge'
import {
  Heading,
  LongForm,
  Numerals,
  Overline,
  Subtitle,
  Text,
  UserInterface
} from 'frontendMyOrders/user-interface/typography'
import { useParams } from 'react-router-dom'

import { OrderStatusTimeline } from './components/OrderStatusTimeline'
import { ProductItem } from './components/ProducItem'
import { Summary } from './components/Summary'
import * as S from './styled'

function DetailedOrder () {
  const { id } = useParams<{ id: string }>()

  const { order } = useSaleOrderDetailed({ id })

  if (!order) {
    return <div>Carregando...</div>
  }

  return (
    <div>
      <h1>Pedido detalhado com id: {order.id}</h1>

      <Heading tag="h2">Heading Text</Heading>
      <br />
      <LongForm>LongForm Text</LongForm>
      <br />
      <Numerals>Numerals Text</Numerals>
      <br />
      <Overline>Overline Text</Overline>
      <br />
      <Subtitle tag="h3">Subtitle Text</Subtitle>
      <br />
      <Text>Base Text</Text>
      <br />
      <UserInterface>UI Text</UserInterface>
      <br />
      <Badge type="danger" label="Cancelado" />
      <br />
      <Badge type="info" label="Saiu para entrega" />
      <br />
      <Badge type="loyalty" label="Fidelidade" />
      <br />
      <Badge type="neutral" label="Neutral" />
      <br />
      <Badge type="success" label="Entregue" />
      <br />
      <Badge type="warning" label="Atrasado" />

      {order.shipments.map((shipment, index) => (
        <>
          <S.ShipmentItem key={shipment.id}>
            <OrderStatusTimeline />
            <Summary summary={shipment.values} index={index} />
          </S.ShipmentItem>

          <Text size="md" type="strong">
            Itens nessa entrega
          </Text>
          {shipment.items.map((item: Item, itemIndex: number) => (
            <S.ProductsWrapper>
              <ProductItem item={item} />
              {itemIndex < shipment.items.length - 1 && <S.Divider />}
            </S.ProductsWrapper>
          ))}
        </>
      ))}
    </div>
  )
}

export default DetailedOrder
