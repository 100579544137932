import { Visible, Hidden } from '@leroy-merlin-br/backyard-react'

import * as S from './styled'

let counter = 0

const Background = ({ isActive }) => {
  counter++
  return (
    <S.Background>
      <Visible when={['giga', 'tera']}>
        <Desktop isActive={isActive} counter={counter} />
      </Visible>
      <Hidden when={['giga', 'tera']}>
        <Mobile isActive={isActive} counter={counter} />
      </Hidden>
    </S.Background>
  )
}

const Desktop = ({ isActive, counter }) => (
  <svg
    width="194"
    height="329"
    fill="none"
    viewBox="0 0 194 329"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter={`url(#b${counter})`}>
      <path
        d="m11 25c0-6.627 5.373-12 12-12h148c6.627 0 12 5.373 12 12v185.04c-6.16 0.508-11 5.668-11 11.959s4.84 11.451 11 11.959v67.041c0 6.627-5.373 12-12 12h-148c-6.627 0-12-5.373-12-12v-67c6.627 0 12-5.373 12-12s-5.373-12-12-12v-185z"
        fill={isActive ? `url(#linear${counter})` : '#FFFFFF'}
        clipRule="evenodd"
        fillRule="evenodd"
      />
    </g>
    <defs>
      <filter
        id={`b${counter}`}
        x="0"
        y="0"
        width="194"
        height="329"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="8" />
        <feGaussianBlur stdDeviation="4" />
        <feColorMatrix values="0 0 0 0 0.235294 0 0 0 0 0.215686 0 0 0 0 0.219608 0 0 0 0.07 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_3:17" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="-2" />
        <feGaussianBlur stdDeviation="5.5" />
        <feColorMatrix values="0 0 0 0 0.235294 0 0 0 0 0.215686 0 0 0 0 0.219608 0 0 0 0.1 0" />
        <feBlend
          in2="effect1_dropShadow_3:17"
          result="effect2_dropShadow_3:17"
        />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feMorphology
          in="SourceAlpha"
          operator="dilate"
          radius="1"
          result="effect3_dropShadow_3:17"
        />
        <feOffset />
        <feColorMatrix values="0 0 0 0 0.729412 0 0 0 0 0.713726 0 0 0 0 0.737255 0 0 0 0.07 0" />
        <feBlend
          in2="effect2_dropShadow_3:17"
          result="effect3_dropShadow_3:17"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect3_dropShadow_3:17"
          result="shape"
        />
      </filter>
      <linearGradient
        id={`linear${counter}`}
        x1=".415"
        x2="273.23"
        y1="-.846"
        y2="153.3"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#9A21AA" />
        <stop offset="1" stopColor="#7808CF" />
      </linearGradient>
    </defs>
  </svg>
)

const Mobile = ({ isActive, counter }) => (
  <svg
    fill="none"
    width="174"
    height="329"
    viewBox="0 0 174 329"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter={`url(#b${counter})`}>
      <path
        d="m11 25c0-6.627 5.373-12 12-12h128c6.627 0 12 5.373 12 12v185.04c-6.16 0.508-11 5.668-11 11.959s4.84 11.451 11 11.959v67.041c0 6.627-5.373 12-12 12h-128c-6.627 0-12-5.373-12-12v-67c6.627 0 12-5.373 12-12s-5.373-12-12-12v-185z"
        fill={isActive ? `url(#linear${counter})` : '#FFFFFF'}
        clipRule="evenodd"
        fillRule="evenodd"
      />
    </g>
    <defs>
      <filter
        id={`b${counter}`}
        x="0"
        y="0"
        width="174"
        height="329"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="8" />
        <feGaussianBlur stdDeviation="4" />
        <feColorMatrix values="0 0 0 0 0.235294 0 0 0 0 0.215686 0 0 0 0 0.219608 0 0 0 0.07 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_5:9" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="-2" />
        <feGaussianBlur stdDeviation="5.5" />
        <feColorMatrix values="0 0 0 0 0.235294 0 0 0 0 0.215686 0 0 0 0 0.219608 0 0 0 0.1 0" />
        <feBlend in2="effect1_dropShadow_5:9" result="effect2_dropShadow_5:9" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feMorphology
          in="SourceAlpha"
          operator="dilate"
          radius="1"
          result="effect3_dropShadow_5:9"
        />
        <feOffset />
        <feColorMatrix values="0 0 0 0 0.729412 0 0 0 0 0.713726 0 0 0 0 0.737255 0 0 0 0.07 0" />
        <feBlend in2="effect2_dropShadow_5:9" result="effect3_dropShadow_5:9" />
        <feBlend
          in="SourceGraphic"
          in2="effect3_dropShadow_5:9"
          result="shape"
        />
      </filter>
      <linearGradient
        id={`linear${counter}`}
        x1="1.646"
        x2="256.24"
        y1="-.846"
        y2="126.28"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#9A21AA" />
        <stop offset="1" stopColor="#7808CF" />
      </linearGradient>
    </defs>
  </svg>
)

export default Background
