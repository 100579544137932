import { FunctionComponent } from 'react'
import { SimpleGrid, Stack, Text } from '@backyard-ui/core'

import { DislikeIcon, Item, LikeIcon } from './components'
import { Props } from './types'

const HelpfulComments: FunctionComponent<Props> = ({ badRate, goodRate }) => (
  <SimpleGrid gapY="16" columns={{ initial: 1, md: 2 }}>
    {goodRate && (
      <Stack direction="column" spacing={{ initial: 6, md: 10 }}>
        <Stack direction="row" spacing="2" align="center">
          <LikeIcon />
          <Text size="lg" color="gray-600">
            Avaliação favorável mais útil
          </Text>
        </Stack>

        <Item itemRate={goodRate} />
      </Stack>
    )}

    {badRate && (
      <Stack direction="column" spacing={{ initial: 6, md: 10 }}>
        <Stack direction="row" spacing="2" align="center">
          <DislikeIcon />
          <Text size="lg" color="gray-600">
            Avaliação crítica mais útil
          </Text>
        </Stack>

        <Item itemRate={badRate} />
      </Stack>
    )}
  </SimpleGrid>
)

export default HelpfulComments
