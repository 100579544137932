import { memo } from 'react'
import { Text } from '@leroy-merlin-br/backyard-react'

import { AlertMessage } from 'shared/components/AlertMessage'

import * as S from './styled'

const UserInvalid = () => (
  <AlertMessage type="critical">
    <Text as="strong" size="kilo" isBold>
      Ops! O número registrado como CPF está incompleto.
    </Text>
    <br />
    Por favor, entre em contato com a nossa{' '}
    <S.Link href="https://www.leroymerlin.com.br/fale-conosco" target="_blank">
      Central de Atendimento
    </S.Link>{' '}
    para corrigir essa informação.
  </AlertMessage>
)

export default memo(UserInvalid)
