import { SVGAttributes } from 'react'

import { ContactsIcon, PreferencesIcon, UserIcon, AddressIcon, KeyIcon } from '../icons'

type MenuAccountButton = {
  id: string,
  icon: React.ForwardRefExoticComponent<SVGAttributes<SVGSVGElement> & React.RefAttributes<SVGSVGElement>>,
  title: string,
  description: string,
  route: string,
  outsideOfReact?: boolean,
  hasSmallTextOnDesktop?: boolean
}

export type ItemsMyAccount = {
  account?: boolean
  contacts?: boolean
  addresses?: boolean
  accessData?: boolean
  complementaryData?: boolean
  preferences?: boolean
}

export const ACCOUNT: MenuAccountButton = {
  id: 'click-personal-data-link',
  icon: UserIcon,
  title: 'Dados Pessoais',
  description: 'Gerencie seus dados pessoais: nome e CPF.',
  route: '/dados-pessoais'
}

export const CONTACTS: MenuAccountButton = {
  id: 'click-contacts-link',
  icon: ContactsIcon,
  title: 'Contatos',
  description: 'Gerencie e-mails e telefone cadastrados.',
  route: '/meus-contatos'
}

export const ADDRESS: MenuAccountButton = {
  id: 'click-address-link',
  icon: AddressIcon,
  title: 'Endereços',
  description: 'Altere ou adicione endereços de entregas.',
  route: '/enderecos'
}

export const ACCESS_DATA: MenuAccountButton = {
  id: 'click-access-data-link',
  icon: KeyIcon,
  title: 'Dados de Acesso',
  description: 'Troque sua senha.',
  route: '/alterar-senha'
}

export const COMPLEMENTARY_DATA: MenuAccountButton = {
  id: 'click-additional-data-link',
  icon: PreferencesIcon,
  title: 'Dados Complementares',
  description: 'Gerencie seus endereços, senha e interesses.',
  route: '/dados-complementares',
  outsideOfReact: true,
  hasSmallTextOnDesktop: true
}

export const PREFERENCES: MenuAccountButton = {
  id: 'click-preferences-link',
  icon: PreferencesIcon,
  title: 'Preferências',
  description: 'Conte-nos suas preferências.',
  route: '/preferencias'
}

export const getItemsMyAccount = (
  itemsMyAccount: ItemsMyAccount
) => {
  const activeItems = []

  if (itemsMyAccount?.account) {
    activeItems.push(ACCOUNT)
  }

  if (itemsMyAccount?.contacts) {
    activeItems.push(CONTACTS)
  }

  if (itemsMyAccount?.accessData) {
    activeItems.push(ACCESS_DATA)
  }

  if (itemsMyAccount?.addresses) {
    activeItems.push(ADDRESS)
  }

  if (itemsMyAccount?.complementaryData) {
    activeItems.push(COMPLEMENTARY_DATA)
  }

  if (itemsMyAccount?.preferences) {
    activeItems.push(PREFERENCES)
  }

  return activeItems
}
