import type { SVGAttributes } from 'react'
import { forwardRef } from 'react'

const GiftIcon = forwardRef<SVGSVGElement, SVGAttributes<SVGSVGElement>>((props, ref) => (
  <svg ref={ref} viewBox="0 0 32 32" {...props}>
    <path
      d="M25 11h-1.56A3.91 3.91 0 0024 9a4 4 0 00-8 0 4 4 0 00-8 0 3.91 3.91 0 00.56 2H7a2 2 0 00-2 2v12a2 2 0 002 2h18a2 2 0 002-2V13a2 2 0 00-2-2zM12 7a2 2 0 012 2v2h-2a2 2 0 010-4zm3 18H7v-5h8zm0-7H7v-5h8zm3-9a2 2 0 112 2h-2zm-1 4h8v5h-8zm0 12v-5h8v5z"
    />
  </svg>
))

export default GiftIcon
