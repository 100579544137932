import { ClipboardEvent, FC, useRef, KeyboardEvent } from 'react'
import { useForm } from 'react-hook-form'

import { hasExactlySixDigits } from 'scripts/react-components/user/utils/hasExactlySixDigits'

import { NumberField } from 'shared/components'

import * as S from './styled'

type FormValues = {
  securityCode: string[]
}

export type InputStyledProps = {
  height: number
  width: number
}

type SecurityCodeInputProps = {
  fieldState: string
  inputStyledProps?: InputStyledProps
  onInputComplete: (code: string) => void
}

const SecurityCodeInput: FC<SecurityCodeInputProps> = ({ fieldState, onInputComplete, inputStyledProps }) => {
  const { control, setValue, getValues } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: { securityCode: [] }
  })

  const handlePasteAllSecurityCode = ({ clipboardData }: ClipboardEvent<HTMLInputElement>) => {
    const securityCode = String(clipboardData.getData('Text'))

    if (!hasExactlySixDigits(securityCode)) {
      return
    }

    for (let index = 0; index <= securityCodeRef.length; index++) {
      setValue(
        `securityCode[${index}]` as `securityCode.${typeof index}`,
        String(clipboardData.getData('Text'))[index]
      )

      if (index === securityCodeRef.length) {
        securityCodeRef[5]?.current?.focus()
        securityCodeRef[5]?.current?.setSelectionRange(1, 1, 'forward')
      }
    }

    const code = getValues('securityCode').join('')

    setTimeout(() => {
      onInputComplete(code)
    }, 500)
  }

  const handleChange = () => {
    const code = getValues('securityCode').join('')
    if (code.length === 6) {
      onInputComplete(code)
    }
  }

  const handleKeyUp = async (event: KeyboardEvent<HTMLInputElement>, index: number) => {
    const { key, currentTarget } = event

    if (key === 'ArrowLeft' || key === 'ArrowRight') {
      const arrows = {
        ArrowLeft: -1,
        ArrowRight: 1
      }

      securityCodeRef[index + arrows[key]]?.current?.focus()

      securityCodeRef[index + arrows[key]]?.current?.setSelectionRange(0, 1, 'forward')
    } else if (key === 'Backspace' && !currentTarget.value) {
      securityCodeRef[index - 1]?.current?.focus()
      securityCodeRef[index - 1]?.current?.setSelectionRange(0, 1, 'forward')
    } else if (key !== 'Backspace' && currentTarget.value) {
      securityCodeRef[index + 1]?.current?.focus()
      securityCodeRef[index + 1]?.current?.setSelectionRange(0, 1, 'forward')
    }
  }

  const numFields = 6
  const securityCodeRef = Array.from({ length: numFields }, () => useRef<HTMLInputElement>())

  return (
    <S.SecurityCode>
      {
        securityCodeRef.map((ref, index) => {
          return (
            <S.SecurityCodeField key={index} inputStyledProps={inputStyledProps}>
              <NumberField
                onChange={handleChange}
                data-testid={`securityCode[${index}]`}
                pattern="[0-9]*"
                inputmode="numeric"
                name={`securityCode[${index}]`}
                control={control}
                maxLength={1}
                getInputRef={ref}
                onKeyUp={(event: KeyboardEvent<HTMLInputElement>) => handleKeyUp(event, index)}
                state={fieldState}
                onPaste={handlePasteAllSecurityCode}
              />
            </S.SecurityCodeField>
          )
        })
      }
    </S.SecurityCode>
  )
}

export default SecurityCodeInput
