import { SVGProps, memo } from 'react'
export const MobilePhone = memo((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="44"
    height="44"
    viewBox="0 0 44 44"
    fill="none"
  >
    <rect width="44" height="44" rx="8" fill="#CBE3B5" />
    <path
      d="M26.0644 12H17.9448C17.3441 12 16.7677 12.238 16.3421 12.662C15.9164 13.0859 15.676 13.6613 15.6736 14.2621V29.7287C15.676 30.3304 15.9161 30.9067 16.3415 31.3321C16.7669 31.7575 17.3432 31.9976 17.9448 32H26.0644C26.6652 31.9976 27.2405 31.7572 27.6645 31.3315C28.0884 30.9058 28.3265 30.3295 28.3265 29.7287V14.2621C28.3265 13.6621 28.0881 13.0868 27.6639 12.6625C27.2397 12.2383 26.6643 12 26.0644 12ZM26.7173 14.2621V29.7287C26.7185 29.8152 26.7025 29.9011 26.6702 29.9814C26.638 30.0617 26.5901 30.1347 26.5293 30.1963C26.4686 30.2579 26.3962 30.3069 26.3164 30.3402C26.2366 30.3736 26.1509 30.3908 26.0644 30.3908H17.9448C17.7693 30.3908 17.6009 30.3211 17.4767 30.1969C17.3525 30.0727 17.2828 29.9043 17.2828 29.7287V14.2621C17.2828 14.1756 17.3 14.0899 17.3333 14.0101C17.3667 13.9303 17.4156 13.8579 17.4772 13.7971C17.5389 13.7364 17.6119 13.6885 17.6922 13.6562C17.7725 13.624 17.8583 13.608 17.9448 13.6092H19.5173L20.0046 14.5287C20.0726 14.66 20.1756 14.7699 20.3022 14.8462C20.4288 14.9225 20.574 14.9622 20.7219 14.9609H23.2874C23.4371 14.9605 23.5837 14.918 23.7105 14.8383C23.8373 14.7587 23.9392 14.6451 24.0046 14.5103L24.4736 13.5908H26.0644C26.1517 13.5908 26.2381 13.6082 26.3186 13.6422C26.399 13.6762 26.4718 13.7259 26.5326 13.7885C26.5935 13.8511 26.6412 13.9252 26.6729 14.0066C26.7046 14.0879 26.7197 14.1748 26.7173 14.2621Z"
      fill="white"
    />
  </svg>
))
