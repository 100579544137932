import PropTypes from 'prop-types'
import { Icon } from '@leroy-merlin-br/backyard-react'
import { ChevronRight, ChevronLeft } from '@backyard-ui/icons'

import * as S from './styled'

const Navigation = ({ prevProps, nextProps }) => (
  <S.Navigation>
    <S.Box {...prevProps} side="left">
      <Icon as={ChevronLeft} size="giga" />
    </S.Box>
    <S.Box {...nextProps} side="right">
      <Icon as={ChevronRight} size="giga" />
    </S.Box>
  </S.Navigation>
)

Navigation.propTypes = {
  prevProps: PropTypes.object.isRequired,
  nextProps: PropTypes.object.isRequired
}

export default Navigation
