import { maxBy } from 'lodash'

import { decimalToPercent } from './helpers'
import * as S from './styled'

const DATA = {
  items: [
    {
      id: 0,
      goal: 2000
    },
    {
      id: 1,
      goal: 5000
    },

    {
      id: 2,
      goal: 1000
    },
    {
      id: 3,
      goal: 7000
    }
  ]
}

export function TimeLineSkeleton () {
  const maxGoal = parseInt(maxBy(DATA.items, DATA => DATA.goal).goal, 10)

  return (
    <S.Bar width={1000}>
      {DATA.items.map(({ id, goal }) => (
        <S.Item key={id} toLeft={decimalToPercent(goal, maxGoal)}>
          <S.Ballon isLoading />
          <S.Label>---</S.Label>
        </S.Item>
      ))}
    </S.Bar>
  )
}
