export const Plus = () => (
  <svg
    width="7"
    height="9"
    viewBox="0 0 7 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.392 3.53534H4.384V0.319336H3.008V3.53534H0V4.86334H3.008V8.09534H4.384V4.86334H7.392V3.53534Z"
      fill="#158110"
    />
  </svg>
)
