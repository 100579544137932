import { ReactNode } from 'react'

export interface BoxProps {
  children: ReactNode
}

export const Box = ({ children }: BoxProps) => {
  return (
    <div className="w-full mb-4">
      <div className="w-full my-1 overflow-x-auto h-80 pb-12 scrollbar scrollbar-thumb scrollbar-track">
        {children}
      </div>
    </div>
  )
}
