import { FunctionComponent } from 'react'
import dayjs from 'dayjs'

import { Props } from './types'

const OfferSchema: FunctionComponent<Props> = ({
  availability,
  productUrl,
  productPrice: { integers, decimals }
}) => {
  const hasPrice = Boolean(integers && decimals)
  const price = hasPrice
    ? `${integers?.replace(/\./g, '').replace(/,/g, '')}.${decimals}`
    : '0'
  const formattedNow = dayjs().format('YYYY-MM-DD')

  return (
    <div itemProp="offers" itemScope itemType="https://schema.org/Offer">
      <link itemProp="url" href={productUrl} />
      <meta
        itemProp="availability"
        content={`https://schema.org/${availability}`}
      />
      <meta itemProp="priceCurrency" content="BRL" />
      <meta
        itemProp="itemCondition"
        content="https://schema.org/NewCondition"
      />
      <meta itemProp="price" content={price} />
      <meta itemProp="priceValidUntil" content={formattedNow} />
    </div>
  )
}

export default OfferSchema
