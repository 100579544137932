import PropTypes from 'prop-types'
import { Card } from '@leroy-merlin-br/backyard-react'

import { useUserResources } from 'shared/hooks'

import { ChangeStore } from 'newCheckout/pages/Cart/components/ChangeStore'

import { Product } from '../Product'
import { Title } from '../Title'
import { ShipmentModalities } from '../ShipmentModalities'
import * as S from './styled'

const Section = ({
  modalities,
  onRemove,
  products,
  storeName,
  type,
  updateProduct,
  services,
  regionId,
  cartId,
  onChange,
  storeId,
  isChangeCartTypeEnabled,
  isHybridEnabled,
  isSimilarProductsEnabled,
  changeItemTypeCallback,
  itemsAvailableStores,
  isStandaloneService,
  fetchPriceShipping,
  zipCode
}) => {
  const { userResources } = useUserResources()

  const titlesByType = {
    delivery: 'Entregamos para você',
    pickupInStore: userResources?.regionDriveThru
      ? `Você retira no drive-thru da loja ${storeName}`
      : `Você retira na loja ${storeName}`,
    scanAndGo: 'Produtos que você escolheu',
    services: 'Serviços'
  }
  const itemAvailableStores = product =>
    itemsAvailableStores.find(
      itemAvailableStores => itemAvailableStores.itemId === product.id
    )

  return (
    <S.Wrapper>
      <Card>
        <S.Title>
          <Title as="h2" size="giga">
            {titlesByType[type]}
          </Title>

          {isChangeCartTypeEnabled && (
            <ChangeStore
              cartId={cartId}
              onChange={onChange}
              storeId={storeId}
            />
          )}
        </S.Title>

        <ShipmentModalities modalities={modalities} />

        <div>
          {products.map(product => (
            <Product
              key={product.id}
              updateProduct={updateProduct}
              onRemove={onRemove}
              shippingType={type}
              services={services}
              regionId={regionId}
              isHybridEnabled={isHybridEnabled}
              isSimilarProductsEnabled={isSimilarProductsEnabled}
              changeItemTypeCallback={changeItemTypeCallback}
              cartId={cartId}
              itemAvailableStores={itemAvailableStores(product)}
              isStandaloneService={isStandaloneService}
              fetchPriceShipping={fetchPriceShipping}
              zipCode={zipCode}
              {...product}
            />
          ))}
        </div>
      </Card>
    </S.Wrapper>
  )
}

Section.propTypes = {
  type: PropTypes.oneOf(['delivery', 'pickupInStore', 'scanAndGo']).isRequired,
  onRemove: PropTypes.func.isRequired
}

export default Section
