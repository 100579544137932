import { Constants } from './'

const initialState = {
  isLoading: false,
  isLoadingProducts: false,
  hasError: false,
  hasHighlightedFilters: false,
  products: [],
  pageInfo: {},
  filters: [],
  sorters: [],
  metadata: {},
  metaTags: {},
  query: {},
  baseUrl: null,
  searchType: null,
  isMobileFilterActive: false,
  isInitialRender: false
}

export const initializeState = ({ propSearchType, url, initialData }) => {
  if (initialData) {
    return {
      ...initialState,

      filters: initialData.filters,
      sorters: initialData.sorters,
      products: initialData.products,
      metadata: initialData.metadata,
      metaTags: initialData.metaTags,
      pageInfo: {
        name: initialData.name,
        description: initialData.topDescription,
        video: initialData.video,
        contents: initialData.contents,
        descriptiveText: initialData.descriptiveText,
        filterTitle: initialData.filterTitle
      },
      hasHighlightedFilters: initialData.filters.some(
        filter => filter.highlight
      ),

      searchType: propSearchType,
      baseUrl: url
    }
  } else {
    return {
      ...initialState,

      isLoading: true,
      isLoadingProducts: true,

      searchType: propSearchType,
      baseUrl: url,

      isInitialRender: true
    }
  }
}

export const reducer = (state, action) => {
  switch (action.type) {
    case Constants.UPDATE_QUERY: {
      const { newQuery = {}, loadOnlyProducts, resetLoad } = action.payload

      const isLoading = resetLoad ? false : !loadOnlyProducts
      const isLoadingProducts = resetLoad ? false : loadOnlyProducts

      return {
        ...state,
        isLoading,
        isLoadingProducts,
        query: newQuery,
        isInitialRender: false
      }
    }

    case Constants.TOGGLE_MOBILE_FILTER: {
      return {
        ...state,
        isMobileFilterActive: !state.isMobileFilterActive
      }
    }

    case Constants.GET_PRODUCTS_SUCCESS: {
      const {
        products,
        name,
        filters,
        sorters,
        topDescription: description,
        descriptiveText,
        video,
        contents,
        metadata,
        metaTags,
        searchType,
        term,
        filterTitle
      } = action.payload.data

      return {
        ...state,
        isLoading: false,
        isLoadingProducts: false,
        filters,
        sorters,
        products,
        metadata,
        metaTags,
        pageInfo: {
          name: (action.payload.isSearch ? term : name) || 'resultados',
          description,
          video,
          contents,
          descriptiveText,
          filterTitle
        },
        searchType,
        hasHighlightedFilters: filters.some(filter => filter.highlight)
      }
    }

    case Constants.GET_PRODCUTS_FAILURE: {
      return {
        ...state,
        isLoading: false,
        hasError: true,
        products: []
      }
    }

    default: {
      return state
    }
  }
}
