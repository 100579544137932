import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

const NotFoundList = ({ slug }) => (
  <div className="wishlist-not-found">
    <div className="wishlist-not-found-content">
      <p className="wishlist-not-found-title">
        Não encontramos a lista de favoritos:
        <span className="wishlist-not-found-term"> "/{ slug }"</span>
      </p>
      <p className="wishlist-not-found-text">Por favor, tente acessar outra lista.</p>
      <Link to="/">
        <button className="button button-primary button-return no-shadow" type="button">Voltar para minhas listas de favoritos</button>
      </Link>
    </div>
  </div>
)

NotFoundList.propTypes = {
  slug: PropTypes.string.isRequired
}

export default NotFoundList
