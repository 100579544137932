import { useEffect } from 'react'

const RedirectOut = ({ to }) => {
  useEffect(() => {
    window.location.replace(to)
  }, [to])

  return null
}

export default RedirectOut
