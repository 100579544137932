import { Hidden, Visible, toast } from '@leroy-merlin-br/backyard-react'
import { AxiosResponse } from 'axios'
import { useEffect, useState } from 'react'
import { isServerError } from 'user/utils'

import { ContactsDesktop } from './ContactsDesktop'
import { ContactsMobile } from './ContactsMobile'
import { getProfile, removePhone } from '../../services'
import { useMyAccountContactContext } from '../../context/my-account-contact-context'
import { ContactsData, Email, Phone, ProfileResponse } from '../../types'

const Contacts = () => {
  const { contextData, updateData } = useMyAccountContactContext()

  const [data, setData] = useState<ContactsData>(contextData)

  const [loading, setLoading] = useState(true)

  const [deletePhone, setDeletePhone] = useState<Phone | undefined>()
  const [deleteLoading, setDeleteLoading] = useState(false)

  const handleCancelDeleteNumber = () => !deleteLoading && setDeletePhone(undefined)

  const handleDeletePhoneConfirm = (phone: Phone) => {
    setDeletePhone(phone)
  }

  const handleDeletePhoneSubmit = async (isDesktop: boolean) => {
    setDeleteLoading(true)

    try {
      await removePhone(`+55${deletePhone?.number}`)

      toast.primary('Número de telefone excluído com sucesso!', {
        variant: isDesktop ? 'solid' : 'light'
      })

      setDeletePhone(undefined)

      const phones = contextData.phones.filter((phone: Phone) => (
        phone.number !== deletePhone?.number
      ))

      updateData({ ...contextData, phones })
    } catch (error) {
      const { status } = error as AxiosResponse

      if (status === 422) {
        return toast.critical('Número de telefone inválido!', {
          variant: isDesktop ? 'solid' : 'light'
        })
      }

      const hasServerError = status && isServerError(status)

      if (hasServerError) {
        toast.critical('Não foi possível remover o telefone!', {
          variant: isDesktop ? 'solid' : 'light'
        })
      }
    } finally {
      setDeleteLoading(false)
    }
  }

  useEffect(() => {
    (async () => {
      const profile: ProfileResponse = await getProfile()

      if (profile.data) {
        const [email] = profile.data.emails.filter((email: Email) => email.main)

        const [mainCellphone] = profile.data.customer.phones.filter((phone: Phone) => phone.default)

        const phones = profile.data.customer.phones.filter((phone: Phone) => !phone.default)

        const newData = {
          fiscalId: profile.data.fiscalId,
          email: email.address,
          mainCellphone: mainCellphone.number,
          phones,
          optins: profile.data.optins
        }

        updateData(newData)

        setData(newData)
      }

      setLoading(false)
    })()
  }, [updateData])

  return (
    <>
      <Visible when={['untilKilo', 'kilo']}>
        <ContactsMobile
          loading={loading}
          data={data}
          handleCancelDeleteNumber={handleCancelDeleteNumber}
          handleDeletePhoneConfirm={handleDeletePhoneConfirm}
          handleDeletePhoneSubmit={handleDeletePhoneSubmit}
          deletePhone={deletePhone}
          deleteLoading={deleteLoading}
        />
      </Visible>
      <Hidden when={['untilKilo', 'kilo']}>
        <ContactsDesktop
          loading={loading}
          data={data}
          handleCancelDeleteNumber={handleCancelDeleteNumber}
          handleDeletePhoneConfirm={handleDeletePhoneConfirm}
          handleDeletePhoneSubmit={handleDeletePhoneSubmit}
          deletePhone={deletePhone}
          deleteLoading={deleteLoading}
        />
      </Hidden>
    </>
  )
}

export default Contacts
