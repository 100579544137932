import { tv } from 'tailwind-variants'

const styles = tv({
  slots: {
    strike: `
      h-px
      flex-1
      bg-black
    `,
    selectContainer: `
      w-full
      md:w-auto
    `
  }
})

export default styles
