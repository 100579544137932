import styled from '@emotion/styled'
import { css } from '@emotion/react'

const sectionStyles = ({ theme }) => css`
  label: advantages;
  background: ${theme.colors.n100};
  padding: ${theme.spacings.tera} ${theme.spacings.giga} ${theme.spacings.giga};

  ${theme.media.giga} {
    padding: ${theme.spacings.peta} 0 0;
  }

  ${theme.media.giga} {
    background: unset;
    height: 765px;
  }

  div[class*='Backyard-Container'] {
    height: 100%;
  }
`

const imageStyles = ({ theme }) => css`
  label: advantages__image;
  display: none;

  ${theme.media.giga} {
    width: 100%;
    height: auto;
    display: block;
    margin-bottom: -${theme.spacings.mega};
  }

  ${theme.media.tera} {
    width: auto;
    height: 405px;
  }
`
const imageWrapperStyles = () => css`
  label: advantages__image-wrapper;
  display: flex;
  height: 100%;
  align-items: flex-end;
`

const titleWrapperStyles = ({ theme }) => css`
  label: advantages__title-wrapper;

  h1 {
    font-weight: ${theme.font.weight.regular};
  }

  span {
    color: ${theme.colors.g500};
    font-weight: ${theme.font.weight.semibold};
  }

  margin-top: 0;

  ${theme.media.giga} {
    margin-top: 65px;
  }

  ${theme.media.tera} {
    max-width: 432px;
  }
`

const boxStyles = ({ theme }) => css`
  label: advantages__box;
  background: ${theme.colors.white};
  padding: ${theme.spacings.giga};
  border-radius: ${theme.borderRadius.tera};
  margin-bottom: ${theme.spacings.giga};
  height: auto;

  ${theme.media.giga} {
    height: 250px;
  }

  ${theme.media.tera} {
    height: 197px;
  }

  svg {
    width: 20px;
    height: 20px;
  }
`

const advantagesWrapperStyles = () => css`
  label: advantages__wrapper;
  height: 100%;
  display: flex;
  justify-content: space-between;
`

export const Section = styled('section')(sectionStyles)
export const Image = styled('img')(imageStyles)
export const ImageWrapper = styled('div')(imageWrapperStyles)
export const TitleWrapper = styled('div')(titleWrapperStyles)
export const Box = styled('div')(boxStyles)
export const AdvantagesWrapper = styled('div')(advantagesWrapperStyles)
