import PropTypes from 'prop-types'
import { Inline, Text } from '@leroy-merlin-br/backyard-react'

import { SameDayDeliveryInfo } from 'shared/components'

import formatPriceToBRL from 'utils/formatPriceToBRL'

import {
  getShipmentMessage,
  shipmentsDictionary
} from 'catalog/utils/shipments-dictionary'
import { SHIPMENTS_EXPRESS_MODALITIES } from 'catalog/utils/constants'

const ShipmentModality = ({
  modality,
  deadline,
  cost = 0,
  expressDeliveryDay
}) => {
  const price = formatPriceToBRL(cost)
  const isSameDayDelivery = modality === 'express' && expressDeliveryDay
  const { label, deadlineMessage } = getShipmentMessage(modality, deadline)

  const LabelContent = () => {
    const isExpress = SHIPMENTS_EXPRESS_MODALITIES.includes(modality)

    if (isExpress) {
      return `${label} expressa`
    }
    return label
  }

  if (isSameDayDelivery) {
    return (
      <Inline
        key={modality}
        space="kilo"
        alignX="space-between"
        data-testid="shipment-modality"
      >
        <div>
          <SameDayDeliveryInfo
            deliveryPrice={cost}
            showPrice={false}
            labelSize="kilo"
            deliveryKey={expressDeliveryDay}
            displayFullMessage={true}
          />
        </div>

        <Text as="strong" color={expressDeliveryDay === 'today' ? 'v400' : 'v700'} isBold noMargin>
          {price}
        </Text>
      </Inline>
    )
  }

  return (
    <Inline
      key={modality}
      space="kilo"
      alignX="space-between"
      data-testid="shipment-modality"
    >
      <Text noMargin>
        <LabelContent /> {deadlineMessage.prefix}{' '}
        {deadlineMessage.main && (
          <Text as="strong" isBold noMargin>
            {deadlineMessage.main}*{' '}
          </Text>
        )}
      </Text>
      <Text as="strong" color="p600" isBold noMargin>
        {price}
      </Text>
    </Inline>
  )
}

export default ShipmentModality

ShipmentModality.propTypes = {
  modality: PropTypes.oneOf(Object.keys(shipmentsDictionary)).isRequired,
  cost: PropTypes.number.isRequired,
  deadline: PropTypes.number,
  expressDeliveryDay: PropTypes.string
}
