import { useEffect } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'

import { Actions } from 'lmcv/redux/modules/coupon'
import { CouponsCard } from 'lmcv/components/CouponsCard'

import { useFilterVoucherByUrl } from '../../hooks/useFilterVoucherByUrl'

const AdvantagesPage = () => {
  const dispatch = useDispatch()

  const { filterVoucherByUrl } = useFilterVoucherByUrl('percentage')

  useEffect(() => {
    filterVoucherByUrl()
  }, [filterVoucherByUrl])

  const { percentagePaginated, percentage, activated } = useSelector(
    state => state.coupon,
    shallowEqual
  )

  useEffect(() => {
    dispatch(Actions.getPercentage(1, 'available', 15))
    dispatch(Actions.getActivated(1, 'advantages', 15))
  }, [dispatch])

  const getVouchers = () => Actions.getPercentage(1, 'available', 15)
  const getActivated = () => Actions.getActivated(1, 'advantages', 15)
  const getPaginated = (pagination, type) =>
    Actions.getPercentagePaginated(pagination, type, 15)

  return (
    <CouponsCard
      title="Minhas vantagens"
      vouchers={percentage}
      activated={activated}
      paginated={percentagePaginated}
      getVouchers={getVouchers}
      getActivated={getActivated}
      getPaginated={getPaginated}
    />
  )
}

export default AdvantagesPage
