import axios from 'axios'
import Cookies from 'js-cookie'

export const api = axios.create()

api.defaults.baseURL = window.env.baseHost

api.interceptors.request.use(
  (request) => {
    const API_TOKEN = Cookies.get('api_token')

    if (API_TOKEN) {
      request.headers.Authorization = `Bearer ${API_TOKEN}`
    }

    return request
  },
  (error) => {
    return Promise.reject(error)
  }
)

export default api
