import PropTypes from 'prop-types'

import { BeInspiredThumb } from 'shared/components/BeInspiredThumb'
import { ShapeThumb } from 'shared/constants/prop-types'
import { GenericCarousel } from 'shared/components/GenericCarousel'

import WishlistProviderMethods from 'site/Wishlist/provider'

import { Widget } from '../components/Widget'

const BeInspiredCarousel = ({ widget }) => {
  const { items, title } = widget
  const hasContent = items.length > 0

  const renderSlides = item => (
    <div key={item.id}>
      <BeInspiredThumb item={item} />
    </div>
  )

  return (
    hasContent && (
      <Widget title={title} withBackground noContentPadding>
        <WishlistProviderMethods>
          <GenericCarousel
            slidesPerView="auto"
            spaceBetween={15}
            centerInsufficientSlides
          >
            {items.map(renderSlides)}
          </GenericCarousel>
        </WishlistProviderMethods>
      </Widget>
    )
  )
}

BeInspiredCarousel.propTypes = {
  widget: PropTypes.shape({
    title: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(PropTypes.shape(ShapeThumb)).isRequired
  }).isRequired
}

export default BeInspiredCarousel
