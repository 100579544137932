import { Text } from '@leroy-merlin-br/backyard-react'

import * as S from './styled'
import { AddressLabel } from '../AddressLabel'
import { DeliveryTrackLink } from '../DeliveryTrackLink'
import { OBSERVATION_LABEL } from '../consts'

const ShippingAddress = ({
  addressType,
  cep,
  city,
  complement,
  district,
  hasCarrierTracking,
  trackingUrl,
  number,
  observation,
  state,
  street
}) => (
  <S.Wrapper>
    <S.Description>
      {observation && (
        <S.Observation>
          <Text as="strong" size="kilo" isBold noMargin>
            {OBSERVATION_LABEL}
          </Text>
          <Text data-testid="observation" size="kilo" noMargin>
            {observation}
          </Text>
        </S.Observation>
      )}

      <AddressLabel addressType={addressType} />
      <Text size="kilo" noMargin>
        {street}, {number} | {district} {city} - {state} | CEP {cep}
      </Text>

      {complement && (
        <Text data-testid="complement" size="kilo" noMargin>
          Complemento: {complement}
        </Text>
      )}
    </S.Description>

    {trackingUrl && (
      <S.Actions>
        <DeliveryTrackLink
          data-testid="delivery-track-link"
          trackingUrl={trackingUrl}
        />
      </S.Actions>
    )}
  </S.Wrapper>
)

export default ShippingAddress
