import { Heading, Stack, Text } from '@leroy-merlin-br/backyard-react'
import { CardIcon, BaseModal } from 'user/celebre/components'

const ExistingCardModal = ({ onClose }) => (
  <BaseModal onClose={onClose}>
    <CardIcon appearance="primary" />

    <Stack space="giga">
      <Heading as="h3" size="tera" noMargin>
        Você já tem um Celebre
      </Heading>

      <Text size="mega" noMargin>
        Verificamos que você já possui um cartão Celebre cadastrado no seu nome.
        Se precisar de ajuda você pode entrar em contato com nosso banco
        parceiro Cetelem via WhatsApp{' '}
        <Text size="mega" as="strong" isBold noMargin>
          (11) 9564 0983
        </Text>
        .
      </Text>
    </Stack>
  </BaseModal>
)

export default ExistingCardModal
