import { ChevronDown, ChevronUp } from '@backyard-ui/icons'
import { Button as BaseButton } from '@leroy-merlin-br/backyard-react'

import * as S from './styled'

const Button = ({ onClick, isOpen }) => (
  <S.Wrapper>
    <BaseButton
      size="kilo"
      variant="ghost"
      onClick={onClick}
      iconRight={isOpen ? ChevronUp : ChevronDown}
      data-track-show-more={isOpen ? 'opened' : 'collapsed'}
    >
      {isOpen ? 'Ver menos' : 'Ver mais'}
    </BaseButton>
  </S.Wrapper>
)

export default Button
