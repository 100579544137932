import { css } from '@emotion/react'
import styled from '@emotion/styled'

const priceStyles = ({ theme }) => css`
  label: details__price;

  display: block;
  margin-bottom: 1px;
`

const headerStyles = ({ theme }) => css`
  label: details__header;

  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;

  .glyph-trash-can {
    font-size: ${theme.typography.text.mega.fontSize};
  }
`

const wrapperStyles = css`
  label: details__product-info;

  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
`

const anchorTitleStyles = ({ theme }) => css`
  label: details__anchor;

  font-size: ${theme.typography.text.kilo.fontSize};
  line-height: ${theme.typography.text.kilo.lineHeight};
  font-weight: ${theme.font.weight.semibold};
  text-align: left;
  display: block;
  margin-bottom: ${theme.spacings.bit};
  color: ${theme.colors.p600};
`

const contentStyles = css`
  label: details__content;

  display: flex;
  line-height: 0;
  justify-content: space-between;
  align-items: stretch;
  margin-bottom: 3px;
`

const footerStyles = css`
  label: details__footer;

  display: flex;
  justify-content: space-between;
  align-items: center;
  align-items: flex-end;
`

const badgesStyles = ({ theme }) => css`
  label: details__badges;

  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .delivery-tag {
    margin-inline: unset;
    position: unset;
    margin-top: ${theme.spacings.bit}
  }
`

const badgeWrapperStyles = ({ theme }) => css`
  label: services-badge;

  margin: ${theme.spacings.bit} 0 ${theme.spacings.byte};

  & strong {
    font-weight: ${theme.font.weight.bold};
  }

  & img.service-badge-icon {
    width: 40px;
    height: 40px;
  }
`

export const BadgeWrapper = styled('div')(badgeWrapperStyles)
export const Header = styled('div')(headerStyles)
export const Price = styled('div')(priceStyles)
export const Wrapper = styled('div')(wrapperStyles)
export const AnchorTitle = styled('a')(anchorTitleStyles)
export const Content = styled('div')(contentStyles)
export const Footer = styled('div')(footerStyles)
export const Badges = styled('div')(badgesStyles)
