import PropTypes from 'prop-types'

export default {
  cover: PropTypes.object.isRequired,
  contents: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  products: PropTypes.number.isRequired,
  slug: PropTypes.string.isRequired
}
