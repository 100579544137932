import PropTypes from 'prop-types'

import * as S from './styled'

const Breadcrumb = props => <S.Breadcrumb {...props} />

Breadcrumb.propTypes = {
  children: PropTypes.node.isRequired
}

const Item = props => <S.Item {...props} />

Item.propTypes = {
  children: PropTypes.node.isRequired
}

Breadcrumb.Item = Item

export default Breadcrumb
