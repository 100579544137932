import styled from '@emotion/styled'
import { css } from '@emotion/react'

import type { Theme } from 'scripts/react-components/themes/Theme'

const headerStyles = ({ theme }: Theme) => css`
  label: header;

  display: flex;

  span {
    margin-left: ${theme.spacings.giga};
    margin-top: ${theme.spacings.giga};
    cursor: pointer;
  }
`

export const Header = styled('div')(headerStyles)

const wrapperStyles = ({ theme }: Theme) => css`
  label: wrapper;

  padding-bottom: ${theme.spacings.byte};
  width: 600px;
`

export const Wrapper = styled('div')(wrapperStyles)
