export const COUPON_GET = '@COUPON_GET'
export const COUPON_GET_COMPLETE = '@COUPON_GET_COMPLETE'

export const COUPON_GET_MONEY = '@COUPON_GET_MONEY'
export const COUPON_GET_MONEY_SUCCESS = '@COUPON_GET_MONEY_SUCCESS'
export const COUPON_GET_MONEY_FAILURE = '@COUPON_GET_MONEY_FAILURE'

export const COUPON_GET_MONEY_PAGINATED = '@COUPON_GET_MONEY_PAGINATED'
export const COUPON_GET_MONEY_PAGINATED_SUCCESS = '@COUPON_GET_MONEY_PAGINATED_SUCCESS'
export const COUPON_GET_MONEY_PAGINATED_FAILURE = '@COUPON_GET_MONEY_PAGINATED_FAILURE'

export const COUPON_GET_PERCENTAGE = '@COUPON_GET_PERCENTAGE'
export const COUPON_GET_PERCENTAGE_SUCCESS = '@COUPON_GET_PERCENTAGE_SUCCESS'
export const COUPON_GET_PERCENTAGE_FAILURE = '@COUPON_GET_PERCENTAGE_FAILURE'

export const COUPON_GET_PERCENTAGE_PAGINATED = '@COUPON_GET_PERCENTAGE_PAGINATED'
export const COUPON_GET_PERCENTAGE_PAGINATED_SUCCESS = '@COUPON_GET_PERCENTAGE_PAGINATED_SUCCESS'
export const COUPON_GET_PERCENTAGE_PAGINATED_FAILURE = '@COUPON_GET_PERCENTAGE_PAGINATED_FAILURE'

export const COUPON_GET_ACTIVATED = '@COUPON_GET_ACTIVATED'
export const COUPON_GET_ACTIVATED_SUCCESS = '@COUPON_GET_ACTIVATED_SUCCESS'
export const COUPON_GET_ACTIVATED_FAILURE = '@COUPON_GET_ACTIVATED_FAILURE'

export const COUPON_SHARE = '@COUPON_SHARE'
export const COUPON_SHARE_SUCCESS = '@COUPON_SHARE_SUCCESS'
export const COUPON_SHARE_FAILURE = '@COUPON_SHARE_FAILURE'

export const COUPON_ACTIVE = '@COUPON_ACTIVE'
export const COUPON_ACTIVE_SUCCESS = '@COUPON_ACTIVE_SUCCESS'
export const COUPON_ACTIVE_FAILURE = '@COUPON_ACTIVE_FAILURE'

export default {
  COUPON_GET,
  COUPON_GET_COMPLETE,
  COUPON_GET_MONEY,
  COUPON_GET_MONEY_SUCCESS,
  COUPON_GET_MONEY_FAILURE,
  COUPON_GET_PERCENTAGE,
  COUPON_GET_PERCENTAGE_SUCCESS,
  COUPON_GET_PERCENTAGE_FAILURE,
  COUPON_GET_PERCENTAGE_PAGINATED,
  COUPON_GET_PERCENTAGE_PAGINATED_SUCCESS,
  COUPON_GET_PERCENTAGE_PAGINATED_FAILURE,
  COUPON_GET_ACTIVATED,
  COUPON_GET_ACTIVATED_SUCCESS,
  COUPON_GET_ACTIVATED_FAILURE,
  COUPON_SHARE,
  COUPON_SHARE_SUCCESS,
  COUPON_SHARE_FAILURE,
  COUPON_ACTIVE,
  COUPON_ACTIVE_SUCCESS,
  COUPON_ACTIVE_FAILURE
}
