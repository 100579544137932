import React from 'react'

import { renderTemplate } from '../render-template'

function QuestionMarkTooltip ({ facet }) {
  return (
    <React.Fragment>
      <button
        type="button"
        className="button button-text question-mark-tooltip circular align-middle"
        data-component="tooltip"
        data-placement="top"
        data-tooltip="[data-question-tooltip]"
      >
        ?
      </button>
      <div
        className="clickable-tooltip tooltip"
        data-question-tooltip
        x-placement="top"
      >
        {facet.description}
      </div>
    </React.Fragment>
  )
}

function FacetList ({ facets, ignoreExclusive, origin }) {
  const shouldIgnoreExclusive = ignoreExclusive ? 1 : 0

  return (
    <React.Fragment>
      {facets.map((facet, index) => {
        if (!facet.options || facet.options.length === 0) return null

        const isExpandable = facet.options.length > 5
        const readableName = facet.readableName
        const isURL = facet.isUrl ? 1 : 0

        return (
          <div
            key={index}
            className={isExpandable ? 'margin-bottom-double' : 'margin-bottom'}
          >
            <div className="heading-5 strong color-neutral margin-y-small align-middle">
              {readableName}
              {facet.description && <QuestionMarkTooltip facet={facet} />}
            </div>

            <div
              className={`${
                isExpandable ? 'expandable-product-list-filter' : ''
              }`}
            >
              <div
                className={`group-list product-filter ${
                  isExpandable ? 'collapse' : ''
                }`}
                data-facet-overlay-expandable={facet.name}
              >
                {facet.options.map((option, optionIndex) => {
                  const amount = option.quantity

                  if (amount <= 0) return null

                  const filterKey = facet.name.toLowerCase()
                  const title = option.title
                  const filterQuery = `filter[${filterKey}]=${option.name}`

                  return (
                    <a
                      key={optionIndex}
                      href="#"
                      data-component="entry-key/products-filter"
                      data-query={filterQuery}
                      data-exclusive-url={isURL}
                      data-origin={origin}
                      data-facet={readableName.toLowerCase()}
                      data-ignore-exclusive-url={shouldIgnoreExclusive}
                      title={title}
                    >
                      {title}
                      <span className="color-default small"> ({amount})</span>
                    </a>
                  )
                })}
              </div>

              {isExpandable && (
                <div className="group-list">
                  <div className="line-around small margin-bottom">
                    <div
                      className="link hidden"
                      data-component="live-collapse"
                      data-target={`[data-facet-overlay-expandable=${facet.name}]`}
                    >
                      <div className="line-wrapper">
                        <span className="hidden-active">Ver mais</span>
                        <span className="visible-active">Ver menos</span>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )
      })}
    </React.Fragment>
  )
}

const render = (props = {}) => renderTemplate(<FacetList {...props} />)

export default render
