import { call, put, takeLatest, select } from 'redux-saga/effects'

import * as services from 'checkout/services/cart'
import * as emitters from 'checkout/services/emitters'

import { Constants, Actions, Selectors } from './'

function * removeServiceById ({ id }) {
  const filteredList = yield select(Selectors.filterServiceListById, id)

  if (filteredList) {
    yield put(Actions.serviceRemovedUpdateList(filteredList))
  }
}

function * serviceQuantityUpdate ({ data }) {
  try {
    const { id, productQuantity, relatedId, skuId, value } = data

    const quantity = value <= productQuantity ? value : productQuantity

    const { data: payload } = yield call(services.putServices, {
      override: true,
      services: [
        {
          id,
          skuId,
          quantity,
          relatedId
        }
      ]
    })

    if (payload) {
      const response = yield call(services.fetchCartShipping)
      yield put(Actions.serviceUpdateQuantity(response?.services))
      yield call(emitters.serviceHasUpdated, response)
    }
  } catch (e) {
    yield put(Actions.quantityError(e))
  }
}

function * deleteService ({ id }) {
  const { status } = yield call(services.removeService, id)
  if (status === 204) {
    yield call(emitters.updateCartShipping)
  }
}

export default function * root () {
  yield takeLatest(Constants.CROSS_SERVICE_REMOVE_ITEM, removeServiceById)
  yield takeLatest(Constants.CROSS_SERVICE_DELETE, deleteService)
  yield takeLatest(Constants.CROSS_SERVICE_POST_QUANTITY, serviceQuantityUpdate)
}
