import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { Theme } from 'newCheckout/types/Theme'

const slipPaymentActionStyles = ({ theme }:Theme) => css`
  label: success-payment-data-bankslip__action;

  display: flex;
  flex-direction: column;
  gap: ${theme.spacings.giga};

  & > * {
    flex: 1 0 0px;
  }

  ${theme.media.giga} {
    flex-direction: row;
  }
`
export const SlipPaymentAction = styled('div')(slipPaymentActionStyles)

const panelStyles = ({ theme }: Theme) => css`
  label: success-payment-data-bankslip__panel;

  display: flex;
  flex-direction: column;
  gap: ${theme.spacings.mega};

  padding: ${theme.spacings.mega} ${theme.spacings.giga};

  background-color: ${theme.colors.n100};
  border-radius: ${theme.borderRadius.giga};
`
export const Panel = styled('div')(panelStyles)
