import { ModalProvider } from '@leroy-merlin-br/backyard-react'

import reactComponentLoader from 'scripts/react-component-loader'

import {
  StockInfo,
  Description,
  StockAssistedSale,
  NavbarProductDetails,
  CallCenterContent,
  ExclusiveSaleSiteMessage,
  AugmentedRealityFullButton,
  AugmentedRealityMiniButton,
  StampLeroyWarrant,
  UserReviewShell
} from './'

const StockAssistedSaleWithProviders = (props) => (
  <ModalProvider>
    <StockAssistedSale {...props} />
  </ModalProvider>
)

reactComponentLoader(StockInfo, '[data-stock-info-wrapper]')
reactComponentLoader(StockAssistedSaleWithProviders, '[data-stock-assisted-sale-wrapper]')
reactComponentLoader(NavbarProductDetails, '[data-navbar-product-details-container]')
reactComponentLoader(Description, '[data-product-description]')
reactComponentLoader(CallCenterContent, '[data-call-center-content]')
reactComponentLoader(ExclusiveSaleSiteMessage, '[data-exclusive-sale-site-message]')
reactComponentLoader(StampLeroyWarrant, '[data-stamp-leroy-warrant]')
reactComponentLoader(AugmentedRealityMiniButton, '[data-augmented-reality-mini-button-wrapper]')
reactComponentLoader(AugmentedRealityFullButton, '[data-augmented-reality-full-button-wrapper]')
reactComponentLoader(UserReviewShell, '[data-user-review-shell]')
