import $ from 'jquery'
import errorsTemplate from 'templates/feedback-errors'

import scroll from 'garden/src/js/utils/scroll'

import emitter from 'scripts/utils/emitter'
import BaseComponent from 'scripts/components/base-component'
import { showSpinner, hideSpinner } from 'scripts/utils/spinner'

const DEFAULTS = {
  clickAndCollectLabel: 'pickup',
  hiddenClass: 'hidden',
  activeClass: 'active',
  fieldSelector: '.field',
  ecommerceLabel: 'site',
  storeLabel: 'store',
  orderUnrelatedOption: ['other', 'quote'],
  validationAttribute: 'data-required'
}

class SupportForm extends BaseComponent {
  static componentName = 'support-form'

  static DOMEvents = {
    submit: 'onFormSubmit',
    'change [data-purchase-place]': 'onPurchasePlaceChange',
    'change [data-delivery-type]': 'onDeliveryTypeChange',
    'change [data-contact-reason]': 'onContactReasonChange'
  }

  constructor ($element, options = {}) {
    super($element)

    this.options = Object.assign({}, DEFAULTS, options)
  }

  init () {
    this.setupElements()
    this.components = this.getComponents()
    this.fillOutUserInfo(this.options)
  }

  setupElements () {
    this.$commonFields = this.$element.find('[data-toggle-field]')
    this.$ecommerceFields = this.$element.find('[data-ecommerce-toggle-field]')
    this.$storeFields = this.$element.find('[data-store-toggle-field]')
    this.$dynamicInputField = this.$element.find('[data-dynamic-field]')
    this.$pickupStoreField = this.$element.find('[data-pickup-store-field]')
    this.$orderFields = this.$element.find('[data-order-toggle-field]')

    this.$purchasePlace = this.$element.find('[data-purchase-place]')
    this.$deliveryType = this.$element.find('[data-delivery-type]')
    this.$contactReason = this.$element.find('[data-contact-reason]')
    this.$customerName = this.$element.find('[data-customer-name]')
    this.$customerEmail = this.$element.find('[data-customer-email]')
    this.$customerPhone = this.$element.find('[data-customer-phone]')
    this.$customerCpf = this.$element.find('[data-customer-cpf]')
    this.$pickupStore = this.$element.find('[data-pickup-store]')
    this.$purchaseStore = this.$element.find('[data-purchase-store]')
    this.$feedbackType = this.$element.find('[data-feedback-type]')
  }

  onContactReasonChange (event) {
    const $currentElement = $(event.currentTarget)
    const value = $currentElement.val()
    const { hiddenClass, orderUnrelatedOption } = this.options
    const shouldToggleClass = orderUnrelatedOption.includes(value)

    this.$orderFields.toggleClass(hiddenClass, shouldToggleClass)

    shouldToggleClass
      ? this.unsetContactReasonField()
      : this.setContactReasonField()
  }

  onDeliveryTypeChange (event) {
    const $currentElement = $(event.currentTarget)
    const shouldToggleClass = !(
      $currentElement.val() === this.options.clickAndCollectLabel
    )
    const { hiddenClass, validationAttribute } = this.options

    if (shouldToggleClass) {
      this.$pickupStore.removeAttr(validationAttribute)
      this.$pickupStore.removeAttr('name')
      this.$pickupStoreField.addClass(hiddenClass)
    } else {
      this.$purchaseStore.removeAttr(validationAttribute)
      this.$pickupStore.attr(validationAttribute, '')
      this.$pickupStore.attr('name', 'purchase-store')
      this.$pickupStoreField.removeClass(hiddenClass)
    }
  }

  getComponents () {
    return {
      validation: this.$element.data('validation'),
      scroll
    }
  }

  validate () {
    return this.components.validation.validateAll()
  }

  onFormSubmit (event) {
    const action = this.$element.attr('action')

    event.preventDefault()

    if (this.validate()) {
      showSpinner()

      $.post(action, this.$element.serialize())
        .done(this.onSendContactSuccess.bind(this))
        .fail(this.onSendContactFailure.bind(this))
        .always(hideSpinner)
    } else {
      this.scrollToError()
    }
  }

  onSendContactSuccess () {
    emitter.emit('stickyFeedback:success', {
      title: `Pronto, ${this.$customerName.val()}!`,
      content:
        'Recebemos sua mensagem com sucesso! Nosso prazo de resposta é de até 1 dia útil.'
    })

    this.clearForm()
    this.removeAllValidationStyles()
  }

  onSendContactFailure ({ responseJSON }) {
    if (responseJSON) {
      emitter.emit('stickyFeedback:error', {
        title: 'Tente novamente.',
        content: errorsTemplate({ errors: responseJSON })
      })
    }
  }

  onPurchasePlaceChange () {
    const inputValue = this.$purchasePlace.val()
    const {
      hiddenClass,
      ecommerceLabel,
      storeLabel,
      validationAttribute
    } = this.options

    this.resetField(this.$deliveryType)
    this.resetField(this.$pickupStore)
    this.resetField(this.$purchaseStore)

    if (inputValue.length) {
      this.setPurchasePlaceField()
      this.$commonFields.removeClass(hiddenClass)
    } else {
      this.unsetPurchasePlaceField()
      this.unsetContactReasonField()
      this.hideAllFields()
      this.removeAllValidationStyles()
    }

    if (inputValue === ecommerceLabel) {
      this.$pickupStore.attr('name', 'purchase-store')
      this.$purchaseStore.removeAttr('name')
      this.$storeFields.addClass(hiddenClass)
      this.$ecommerceFields.removeClass(hiddenClass)
      this.$deliveryType.attr(validationAttribute, '')
      this.$deliveryType.attr('name', 'delivery-type')
    }

    if (inputValue === storeLabel) {
      this.$purchaseStore.attr(validationAttribute, '')
      this.$purchaseStore.attr('name', 'purchase-store')
      this.$pickupStore.removeAttr('name')
      this.$pickupStore.removeAttr(validationAttribute)
      this.$ecommerceFields.addClass(hiddenClass)
      this.$pickupStoreField.addClass(hiddenClass)
      this.$storeFields.removeClass(hiddenClass)
      this.$deliveryType.removeAttr(validationAttribute)
      this.$deliveryType.removeAttr('name')
    }
  }

  setContactReasonField () {
    const $contactReasonField = this.$contactReason.closest(
      '[data-toggle-field]'
    )

    $contactReasonField.removeClass('col-md-12 ')
    $contactReasonField.addClass('col-md-6')
  }

  unsetContactReasonField () {
    const $contactReasonField = this.$contactReason.closest(
      '[data-toggle-field]'
    )

    $contactReasonField.removeClass('col-md-6 ')
    $contactReasonField.addClass('col-md-12')
  }

  setPurchasePlaceField () {
    this.$dynamicInputField.removeClass('col-md-12')
    this.$dynamicInputField.addClass('col-md-6')
  }

  unsetPurchasePlaceField () {
    this.clearForm()

    this.$dynamicInputField.removeClass('col-md-6')
    this.$dynamicInputField.addClass('col-md-12')
  }

  resetField ($field) {
    $field.val('')
    $field.closest('[data-validation]').removeClass('valid invalid active')
  }

  clearForm () {
    const formFields = this.$element.find('input, select, textarea')
    const { activeClass, fieldSelector } = this.options

    formFields.val('')
    formFields.closest(fieldSelector).removeClass(activeClass)
  }

  hideAllFields () {
    const { hiddenClass, validationAttribute } = this.options

    this.$commonFields.addClass(hiddenClass)
    this.$storeFields.addClass(hiddenClass)
    this.$ecommerceFields.addClass(hiddenClass)
    this.$pickupStoreField.addClass(hiddenClass)
    this.$orderFields.addClass(hiddenClass)
    this.$pickupStore.removeAttr(validationAttribute)
    this.$purchaseStore.removeAttr(validationAttribute)
  }

  removeAllValidationStyles () {
    const $validationFields = this.$element.find('[data-required]')

    $validationFields.closest('[data-validation]').removeClass('valid invalid')
  }

  fillOutUserInfo ({ userName, userSurname, userEmail, userPhone, userCpf }) {
    const { fieldSelector, activeClass } = this.options

    if (userName) {
      this.$customerName.val(`${userName} ${userSurname}`)
      this.$customerName.closest(fieldSelector).addClass(activeClass)
    }

    if (userEmail) {
      this.$customerEmail.val(userEmail)
      this.$customerEmail.closest(fieldSelector).addClass(activeClass)
    }

    if (userPhone) {
      this.$customerPhone.val(userPhone)
      this.$customerPhone.closest(fieldSelector).addClass(activeClass)
    }

    if (userCpf) {
      this.$customerCpf.val(userCpf)
      this.$customerCpf.closest(fieldSelector).addClass(activeClass)
    }
  }

  scrollToError () {
    this.components.scroll(this.$element.find('.invalid').first(), {
      offset: -120
    })
  }
}

export default $el => new SupportForm($el, $el.data()).init()

export { SupportForm as Component }
