import { FunctionComponent } from 'react'
import { Text } from '@backyard-ui/core'

import { Props } from './types'

const Title: FunctionComponent<Props> = ({ title }) => (
  <Text size="3xl" weight="bold">
    {title}
  </Text>
)

export default Title
