import { useEffect } from 'react'
import {
  Dialog,
  Heading,
  Stack,
  Text,
  useModal,
  Icon
} from '@leroy-merlin-br/backyard-react'
import { AlertTriangleOutline } from '@backyard-ui/icons'

import * as S from './styles'

const ModalWarning = () => {
  const { setModal } = useModal()

  useEffect(() => {
    setModal({
      hasScroll: true,
      size: 'giga',
      children: ({ onClose }) => (
        <Dialog title="Aviso" onClose={onClose}>
          <S.Container>
            <Stack space="bit" alignX="center">
              <Icon as={AlertTriangleOutline} color="critical" />
              <Heading size="mega">Atenção!</Heading>

              <Text>
                <Text isBold as="strong">
                  Uma campanha falsa sobre a Black Friday 2020 está sendo
                  divulgada{' '}
                </Text>
                na internet em nome da Leroy Merlin. O perfil falso envia um
                link de uma ação de Responda e Ganhe, ofertando um
                Ar-Condicionado portátil.
              </Text>

              <Text>
                <Text isBold as="strong">
                  Não fazemos sorteios
                </Text>{' '}
                e nem ações de Responda e Ganhe. Por isso, caso receba essa
                campanha,{' '}
                <Text isBold as="strong">
                  não forneça dados pessoais
                </Text>
                , não acesse links externos e nem preencha questionários
              </Text>
              <Text color="p500" isBold>
                Estamos tomando as providências necessárias.
              </Text>
            </Stack>
          </S.Container>
        </Dialog>
      )
    })
  }, [setModal])

  return null
}

export default ModalWarning
