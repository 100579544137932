import React from 'react'
import type { ReactNode, Context } from 'react'
import { Configure, InstantSearch } from 'react-instantsearch'
import { useMediaQuery } from '@backyard-ui/hooks'

import { useUserResources } from '../../hooks'
import {
  indexName,
  insightsConfig,
  searchClient as originalSearchClient
} from './lib'
import routerProxy from './routing'

const HITS_PER_PAGE = 36
export const MAX_VALUES_PER_FACET = 60
const APP_CONTEXT = 'app'

interface AlgoliaInstantSearchProps {
  children?: ReactNode
  isSearchResultPage?: boolean
  categoryPageId?: string
}

export type HeaderContextProps = Context<{}>

const searchClient: Pick<typeof originalSearchClient, 'search'> = {
  ...originalSearchClient,
  search(requests) {
    if (requests.every(({ params }) => !params?.query)) {
      return Promise.resolve({
        results: requests.map(() => ({
          hits: [],
          nbHits: 0,
          nbPages: 0,
          page: 0,
          processingTimeMS: 0,
          hitsPerPage: 0,
          exhaustiveNbHits: false,
          query: '',
          params: ''
        }))
      })
    }

    return originalSearchClient.search(requests)
  }
}

const AlgoliaInstantSearch = ({
  children,
  isSearchResultPage,
  categoryPageId
}: AlgoliaInstantSearchProps) => {
  const { userResources } = useUserResources()
  const isNotMobile = useMediaQuery('md')

  const urlParams = new URLSearchParams(window.location.search)

  const page = urlParams.get('i1[page]')

  const url = window.location.href

  const segments = url.split('/')
  const lastSegment = segments[segments.length - 1]

  const result = lastSegment.replace(/-/g, ' ')

  const categoryResultQueryAccepted = result.split('?')[0] || ''

  const isCategoryPageAlgolia =
    Boolean(
      document
        .querySelector('[data-type]')
        ?.getAttribute('data-is-category-page-algolia')
    ) || categoryPageId

  const termQuery = () => {
    if (url.includes('gad_source') || url.includes('gclid')) {
      return ''
    }
    return urlParams.get('term') || ''
  }

  const regionFilter = `regionalAttributes.${userResources?.region}.promotionalPrice>0 AND regionalAttributes.${userResources?.region}.available=1`

  const filters = categoryPageId
    ? `${regionFilter} AND ${categoryPageId}`
    : regionFilter

  const buildAnalyticsTags = () => {
    const analyticTags: string[] = []

    if (!userResources) {
      return analyticTags
    }

    analyticTags.push(`#${userResources.region}`)

    if (userResources.email.length > 0) {
      analyticTags.push('#loginOn')
    } else {
      analyticTags.push('#loginOff')
    }

    if (userResources.context === APP_CONTEXT) {
      analyticTags.push('#app')
    } else if (isNotMobile) {
      analyticTags.push('#desktop')
    } else {
      analyticTags.push('#mobile')
    }

    return analyticTags
  }

  return (
    <InstantSearch
      indexName={indexName}
      initialUiState={{
        [indexName]: {
          query: isCategoryPageAlgolia
            ? categoryResultQueryAccepted
            : termQuery()
        }
      }}
      searchClient={searchClient}
      routing={!categoryPageId && isSearchResultPage && routerProxy(indexName)}
      insights={insightsConfig}
    >
      {userResources?.region && (
        <Configure
          hitsPerPage={HITS_PER_PAGE}
          analyticsTags={buildAnalyticsTags()}
          analytics
          clickAnalytics
          filters={filters}
          facets={['*']}
          maxValuesPerFacet={MAX_VALUES_PER_FACET}
          page={Math.max(Number(page) - 1, 0)}
          facetingAfterDistinct
        />
      )}
      {children}
    </InstantSearch>
  )
}

export default React.memo(AlgoliaInstantSearch)
