import { light as theme } from '@leroy-merlin-br/backyard-theme'
import { CheckmarkCircleOutline, TimesCircleOutline } from '@backyard-ui/icons'

export const STATUS_DATA = {
  7: {
    icon: CheckmarkCircleOutline,
    iconText: 'Aprovado',
    color: theme.colors.p500,
    text: ''
  },
  8: {
    icon: CheckmarkCircleOutline,
    iconText: 'Aprovação',
    color: theme.colors.n200,
    text:
      'Agora falta pouco! Seu documento já está com a gente e a análise será feita em até 4 dias úteis.'
  },
  10: {
    icon: TimesCircleOutline,
    iconText: 'Reprovado',
    color: theme.colors.r500,
    text: ''
  },
  11: {
    icon: TimesCircleOutline,
    iconText: 'Reprovado',
    color: theme.colors.r500,
    text: ''
  }
}
