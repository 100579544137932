import { FunctionComponent } from 'react'
import { ChevronRight, ChevronLeft } from '@backyard-ui/icons'

import { Props } from './types'
import * as S from './styled'

const NavigationButton: FunctionComponent<Props> = ({ mode }) => (
  <S.Container mode={mode} data-navigation-mode={mode} data-testid="container">
    <S.IconWrapper>
      {mode === 'prev' ? (
        <ChevronLeft data-testid="chevron-left" />
      ) : (
        <ChevronRight data-testid="chevron-right" />
      )}
    </S.IconWrapper>
  </S.Container>
)

export default NavigationButton
