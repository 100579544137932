import { configureStore } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'

import cartReducer from './cart'
import shipmentsReducer from './shipments'
import serviceReducer from './service'
import pickUpInStoreReducer from './pickupInStore'

export const store = configureStore({
  reducer: {
    services: serviceReducer,
    cart: cartReducer,
    pickUpInStore: pickUpInStoreReducer,
    shipments: shipmentsReducer
  }
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
