import axios from 'axios'
import Cookies from 'js-cookie'

const https = axios.create({
  baseURL: window.env && window.env.baseHost
})

https.interceptors.request.use(
  request => {
    const API_TOKEN = Cookies.get('api_token')

    if (API_TOKEN) {
      request.headers.Authorization = `Bearer ${API_TOKEN}`
    }

    return request
  },
  error => {
    return Promise.reject(error)
  }
)

export const getModules = async () => {
  const { data } = await https.get('/api/v2/loyaltyProgram/modules')
  return data
}

export const getVouchersActivated = async () => {
  const { data } = await https.get('/api/v2/loyaltyProgram/vouchers/money/activated')
  return data
}

export const setVoucherEnable = async (payload) => {
  const { data } = await https.post('/api/v2/loyaltyProgram/vouchers/activate', payload)
  return data
}

export const getInformationVoucher = async (type, id, active = false) => {
  const { data } = await https.get(`/api/v2/loyaltyProgram/vouchers/${id}`, { params: { type, active: +active } })
  return data
}

export const setProfilePreferences = async (payload) => {
  const { data } = await https.put('api/v2/user/profile', payload)
  return data
}

export const getProfilePreferences = async () => {
  const { data } = await https.get('api/v2/user/profile')
  return data
}

export const getAdvantageVouchers = async (params = {}) => {
  const { data } = await https.get('api/v2/loyaltyProgram/advantages', { params: { ...params, perPage: 1 } })
  return data
}

export const getMoneyVouchers = async (params = {}) => {
  const { data } = await https.get('api/v2/loyaltyProgram/vouchers/money', { params: { ...params, perPage: 1 } })
  return data
}
