import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { styleHelpers } from '@leroy-merlin-br/backyard-react'

const priceTagLabelStyles = ({ theme }) => css`
  label: price-tag__label;

  display: flex;
  justify-content: space-between;

  width: 100%;
  padding: ${theme.spacings.bit} ${theme.spacings.byte} ${theme.spacings.bit} ${theme.spacings.mega};

  color: ${theme.colors.white};
  font-weight: ${theme.font.weight.semibold};
  background-color: ${theme.colors.p600};
`

const priceTagLabelPromotionalStyles = ({ theme, isPromotional }) =>
  isPromotional &&
  css`
    label: price-tag__label--promotional;

    background-color: ${theme.colors.r600};
  `

const priceTagLabelPromotionalPaymentMethodStyles = ({ isPromotionalPaymentMethod }) =>
  isPromotionalPaymentMethod &&
  css`
    label: price-tag__label--promotional-payment-method;

    @media (max-width: 400px) {
      flex-direction: column;
    }
  `

const priceTagLabelOutletPaymentMethodStyles = ({ theme, isOutletByPaymentMethod }) =>
  isOutletByPaymentMethod &&
  css`
    label: price-tag__label--outlet-payment-method;

    ${styleHelpers.subHeadingMega(theme)};

    @media (max-width: 400px) {
      flex-direction: column;
    }
  `

export const PriceTagLabel = styled('div')(
  priceTagLabelStyles,
  priceTagLabelPromotionalStyles,
  priceTagLabelPromotionalPaymentMethodStyles,
  priceTagLabelOutletPaymentMethodStyles
)
