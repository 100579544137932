import { FC } from 'react'

import * as S from './styled'

type BulletProps = {
  status: 'completed' | 'current' | 'upcoming' | 'pending' | 'canceled'
}

const Bullet: FC<BulletProps> = ({ status }) => {
  return (
    <S.OuterCircle data-testid="outer-circle" status={status}>
      <S.InnerCircle data-testid="inner-circle" status={status}/>
    </S.OuterCircle>
  )
}

export default Bullet
