import { datadogRum } from '@datadog/browser-rum'
import { useEffect, useState } from 'react'
import {
  getSaleOrderPendingPayment,
  Order
} from 'frontendMyOrders/services/sale-order'

const useSaleOrderPendingPayment = () => {
  const [pendingPaymentOrders, setPendingPaymentOrders] = useState<
    Order[] | null
  >(null)

  useEffect(() => {
    const fetchSaleOrderPendingPayment = async () => {
      try {
        const data = await getSaleOrderPendingPayment()
        if (data) {
          setPendingPaymentOrders(data)
        }
      } catch (error) {
        datadogRum.addError(error)
      }
    }

    fetchSaleOrderPendingPayment()
  }, [setPendingPaymentOrders])

  return { pendingPaymentOrders }
}

export default useSaleOrderPendingPayment
