import { forwardRef } from 'react'
import {
  IconButton,
  Select,
  Stack,
  Text,
  Visible,
  Skeleton
} from '@backyard-ui/core'
import { ChevronLeft, ChevronRight } from '@backyard-ui/icons'
import type { GetProductReviewsOrder } from 'hpdp/services/reviews'

import { PageIndicator } from './components'
import { Props } from './types'
import styles from './Pagination.styles'

const Pagination = forwardRef<HTMLDivElement, Props>(
  (
    {
      children,
      onNext,
      onPrev,
      onReorder,
      currentPage,
      pageSize,
      totalReviewsCount,
      orderItems,
      isLoading
    },
    ref
  ) => (
    <Stack direction="column" spacing="12" ref={ref}>
      <Stack
        direction={{ initial: 'column', md: 'row' }}
        spacing={{ initial: '3', md: '4' }}
        align={{ initial: 'stretch', md: 'center' }}
      >
        {isLoading ? (
          <Skeleton width="152px" height="24px" radius="xl" />
        ) : (
          <PageIndicator
            currentPage={currentPage}
            pageSize={pageSize}
            totalReviewsCount={totalReviewsCount}
          />
        )}

        <Visible above="md">
          <span className={styles().strike()} />
        </Visible>

        <Stack spacing="2" align={'center'}>
          <Visible above="md">
            <Text asChild>
              <span>Classificar por:</span>
            </Text>
          </Visible>

          <div className={styles().selectContainer()}>
            <Select.Root
              defaultValue={orderItems[0].value}
              onValueChange={(value: GetProductReviewsOrder) =>
                onReorder(value)
              }
            >
              <Select.Trigger>
                <Select.Value />
              </Select.Trigger>

              <Select.Content>
                {orderItems.map(({ name, value }, index) => (
                  <Select.Item key={index} value={value} textValue={name}>
                    {name}
                  </Select.Item>
                ))}
              </Select.Content>
            </Select.Root>
          </div>
        </Stack>
      </Stack>

      <Stack direction="column" spacing="20">
        {children}
      </Stack>

      <Stack
        spacing={{ initial: '0', md: '4' }}
        align={{ initial: 'center', md: 'center' }}
        justify={{ initial: 'space-between', md: 'flex-start' }}
      >
        {isLoading ? (
          <Skeleton width="152px" height="24px" radius="xl" />
        ) : (
          <PageIndicator
            currentPage={currentPage}
            pageSize={pageSize}
            totalReviewsCount={totalReviewsCount}
          />
        )}

        <Visible above="md">
          <span className={styles().strike()} />
        </Visible>

        <Stack spacing="2.5">
          <IconButton
            icon={<ChevronLeft />}
            size="xs"
            variant="outline"
            appearance="neutral"
            onClick={onPrev}
            disabled={currentPage <= 1}
            data-testid="prev-button"
          />

          <IconButton
            icon={<ChevronRight />}
            size="xs"
            variant="outline"
            appearance="neutral"
            onClick={onNext}
            disabled={currentPage * pageSize >= totalReviewsCount}
            data-testid="next-button"
          />
        </Stack>
      </Stack>
    </Stack>
  )
)

export default Pagination
