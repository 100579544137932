import { Text, Stack, Spinner } from '@leroy-merlin-br/backyard-react'
import { KeyboardEvent } from 'react'

import * as dataLayer from 'scripts/utils/data-layer'

import { NumberField } from 'shared/components'

import { Layout } from '../Layout'
import * as S from './styled'
import { SecurityCodeStepProps, useSecurityCodeStep } from './useSecurityCodeStep'

const SecurityCodeStep: React.FC<SecurityCodeStepProps> = ({ onNextStep }) => {
  const {
    isLoading,
    description,
    securityCodeRef,
    handlePasteAllSecurityCode,
    control,
    fieldState,
    counter,
    handleKeyUp,
    onRensendCode
  } = useSecurityCodeStep({ onNextStep })

  return (
    <Layout description={description}>
      <S.SecurityCode>
        {securityCodeRef.map((ref, index) => {
          const pasteProps = index === 0 ? { onPaste: handlePasteAllSecurityCode } : {}

          return (
            <S.SecurityCodeField key={index}>
              <NumberField
                pattern="[0-9]*"
                inputmode="numeric"
                disabled={isLoading}
                name={`securityCode[${index}]`}
                control={control}
                maxLength={1}
                getInputRef={ref}
                state={fieldState}
                onKeyUp={(event: KeyboardEvent<HTMLInputElement>) => handleKeyUp(event, index)}
                {...pasteProps}
              />
            </S.SecurityCodeField>
          )
        })}
      </S.SecurityCode>

      <Stack alignX="center" space="mega">
        <S.ResendCodeWrapper onClick={dataLayer.recoveryResenCodeClick}>
          {counter ? (
            <Text size="kilo" color="n600" noMargin>
              Reenviar código em <span>{counter}</span> segundos
            </Text>
          ) : (
            <Text size="kilo" noMargin color="p600">
              <a onClick={onRensendCode}>Reenviar código</a>
            </Text>
          )}
        </S.ResendCodeWrapper>

        <S.SpinnerWrapper>
          {isLoading && <Spinner appearance="primary" />}
        </S.SpinnerWrapper>

        <Text size="mega" noMargin color="p600">
          <a
            href="/login"
            // Esse id está sendo utilizado para o tagueamento
            id="security-code-step-login-link"
            onClick={dataLayer.recoveryBackToLoginLinkClick}
          >
            Voltar ao login
          </a>
        </Text>
      </Stack>
    </Layout>
  )
}

export default SecurityCodeStep
