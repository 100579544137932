export const getTotalDiscountFromDocument = () => {
  const totalDiscountElement = document.getElementById('totalDiscount')
  return String(totalDiscountElement?.textContent)
}

export const getSelectedPaymentMethodFromDocument = () => {
  const paymentSelected = (
    document.querySelector(
      'input[name="payment-method"]:checked'
    ) as HTMLInputElement | null
  )?.value

  return String(paymentSelected)
}
