import request from 'checkout/utils/axios'

const applyCoupon = async code => {
  const response = await request
    .post('/api/v1/cart/coupon', { couponCode: code })
    .then(({ data }) => data)
    .catch(err => {
      throw err.response
    })

  return response
}

export default applyCoupon
