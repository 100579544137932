import { Fragment } from 'react'

import { HeroBanner, Advantages, Faq, HowTo, Cta } from './sections'
import * as S from './styled'

const LandingPage = () => (
  <Fragment>
    <S.Background>
      <HeroBanner />
      <Advantages />
    </S.Background>
    <HowTo />
    <Cta />
    <Faq />
  </Fragment>
)

export default LandingPage
