const updateCart = (cartId, uniqueProductsCount) => {
  window.ReactNativeWebView?.postMessage(
    JSON.stringify({
      type: 'updateCart',
      data: {
        cartId: cartId || null,
        uniqueProductsCount: uniqueProductsCount || 0
      }
    })
  )
}

export default {
  updateCart
}
