import { css } from '@emotion/react'
import styled from '@emotion/styled'

const titleStyles = ({ theme }) => css`
  label: new-product-thumb__title;

  color: ${theme.colors.n900};
  height: 72px;
  display: block;

  font-size: ${theme.typography.text.mega.fontSize};
  line-height: ${theme.typography.text.mega.lineHeight};
  word-break: break-word;

  margin: ${theme.spacings.mega} 0 0;
  padding: 0  ${theme.spacings.kilo};

  ${theme.media.kilo} {
    overflow: visible;
    max-width: unset;
  }
`

const productRatingStyles = ({ theme }) => css`
  label: new-product-thumb__rating-wrapper;

  color: ${theme.colors.n300};
  font-size: ${theme.typography.subHeadings.kilo.fontSize};

  margin: ${theme.spacings.mega} 0 ${theme.spacings.kilo};
  padding: 0 6px;

  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;

  ${theme.media.giga} {
    margin: ${theme.spacings.bit} 0 ${theme.spacings.kilo};
    padding: 0  ${theme.spacings.kilo};
  }
`

const productCodeStyles = ({ theme }) => css`
  label: new-product-thumb__product-code;

  display: inline-block;

  line-height: ${theme.typography.subHeadings.mega.lineHeight};

  vertical-align: bottom;
`

export const Title = styled('a')(titleStyles)
export const ProductRating = styled('div')(productRatingStyles)
export const ProductCode = styled('div')(productCodeStyles)
