import { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import Confirm from 'garden/src/js/components/confirm'

import { Mosaic } from '../mosaic'
import shapePropTypes from './shapePropTypes'

const shouldRemove = (item, fn) => {
  const options = {
    textMessage: 'Deseja mesmo remover sua lista?',
    textConfirmButton: 'Remover',
    textCancelButton: 'Cancelar'
  }

  const callback = (value) => {
    if (value) {
      fn(item)
    }
  }

  return new Confirm(callback, options)
}

const WishlistItem = forwardRef(({ item, onEdit, onRemove }, ref) => (
  <li className="wishlist-collection-item" data-id={item.id} ref={ref}>
    <Link to={item.slug} className="width-full">
      <Mosaic mosaic={item.cover.mosaic}/>
    </Link>
    <div className="description">
      <div className="title">
        <Link to={item.slug}>
          <h2>{item.name}</h2>
          <span className="count">
            {`${item.products}
              ${item.products > 1 ? ' Produtos, ' : ' Produto, '}
            `}
            {`${item.contents}
              ${item.contents > 1 ? ' Conteúdos' : ' Conteúdo'}
            `}
          </span>
        </Link>
      </div>
      <div className="edit-button">
        <span
          className="glyph glyph-pencil"
          onClick={() => onEdit({ slug: item.slug, isEdit: true })}
        />
        <span
          data-confirm-small
          className="glyph glyph-trash-can margin-left"
          onClick={() => shouldRemove(item.slug, onRemove)}
        />
      </div>
    </div>
  </li>
))

WishlistItem.propTypes = {
  item: PropTypes.shape({ ...shapePropTypes }).isRequired,
  onRemove: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired
}

export default WishlistItem
