import { Fragment } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import {
  Text,
  Button,
  Visible,
  Stack,
  Heading
} from '@leroy-merlin-br/backyard-react'

import { formatDate } from 'utils/date'

import { Avatar } from 'lmcv/components'

import * as S from './styled'

const Status = ({ status, expireAt }) => {
  const MAP_STATUS_TEXT = {
    Pendente: '',
    Expirado: 'Expirado',
    Ativo: 'Finaliza',
    Concluído: 'Finalizado',
    Finalizado: 'Finalizado'
  }

  if (expireAt && MAP_STATUS_TEXT[status]) {
    return (
      <S.ItemCol>
        <Text color="n800" size="mega" noMargin>
          <strong>{MAP_STATUS_TEXT[status]} em:</strong>{' '}
          {formatDate(expireAt, 'DD/MM/YYYY')}
        </Text>
      </S.ItemCol>
    )
  }

  return null
}

const MessageInvites = ({ invites }) => {
  if (invites && invites > 1) {
    return `Você possui ${invites} convites para enviar para seus clientes`
  }

  if (invites && invites === 1) {
    return `Você possui ${invites} convite para enviar para seus clientes`
  }

  return 'Você atingiu o número máximo de convites para enviar'
}

const List = ({ data, remainingInvites, onClickAdd }) => (
  <Fragment>
    {data.length ? (
      <S.List>
        {data.map(({ id, customerName, status, projectName, expireAt }) => (
          <S.Item key={id} as={Link} to={`/cliente/${id}`}>
            <S.ItemCol>
              <Avatar name={customerName} />

              <S.Info>
                <Stack space="bit">
                  <Heading color="n800" size="kilo" noMargin>
                    {customerName}
                  </Heading>
                  <Visible when={['untilKilo', 'kilo']}>
                    <Text color="n600" size="mega" noMargin>
                      {projectName}
                    </Text>
                  </Visible>
                  <S.Status color={status.toLowerCase()}>
                    <Text size="kilo" noMargin>
                      {status}
                    </Text>
                  </S.Status>
                </Stack>
              </S.Info>
            </S.ItemCol>
            <S.ItemCol>
              <Stack space="bit">
                <Text color="n500" size="mega" noMargin>
                  Nome do Projeto:
                </Text>
                <Text color="p500" noMargin>
                  {projectName}
                </Text>
              </Stack>
            </S.ItemCol>
            <Status expireAt={expireAt} status={status} />
          </S.Item>
        ))}
      </S.List>
    ) : (
      <Stack space="byte">
        <Heading size="tera" color="n800" noMargin>
          Você ainda não possui nenhum cliente
        </Heading>
        <Text color="n600" size="kilo" noMargin>
          Clique em adicionar clientes para começar a utilizar a plataforma
        </Text>
      </Stack>
    )}

    <S.Divider />

    <S.Footer>
      <S.TextFooter>
        <MessageInvites invites={remainingInvites} />
      </S.TextFooter>

      <Button size="kilo" isDisabled={!remainingInvites} onClick={onClickAdd}>
        Adicionar {data.length > 0 ? 'mais clientes' : 'cliente'}
      </Button>
    </S.Footer>
  </Fragment>
)

List.defaultProps = {
  data: PropTypes.array.isRequired,
  onClickAdd: PropTypes.func
}

export default List
