import * as CouponUI from 'frontendCheckout/ui/v1/CouponAndVoucher'
type Props = {
  points: number
  expiration?: string
}

export const Points = ({ points, expiration }: Props) => {
  return (
    <div className="flex space justify-between items-end self-stretch mt-5 mb-4">
      <CouponUI.Points points={points} />
      {expiration && <CouponUI.Tag date={expiration} />}
    </div>
  )
}
