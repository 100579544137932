import styled from '@emotion/styled'
import { css } from '@emotion/react'

const wrapperList = ({ theme }) => css`
  label: wrapper-list;

  padding: ${theme.spacings.giga};
  border-bottom: 2px solid ${theme.colors.b800};

  a {
    color: ${theme.colors.white};
  }
`

const wrapperLoginStyles = ({ theme }) => css`
  label: wrapper-login;

  padding: ${theme.spacings.giga} ${theme.spacings.mega};
  border-bottom: 2px solid ${theme.colors.b800};

  p {
    text-transform: capitalize;
  }

  h1 {
    font-weight: ${theme.font.weight.regular};
  }
`

const wrapperLogoutStyles = ({ theme }) => css`
  label: wrapper-logout;

  border-bottom: 2px solid ${theme.colors.b800};
  padding: ${theme.spacings.mega};
`

const wrapperIconStyles = ({ theme }) => css`
  label: wrapper-icon;

  svg {
    color: ${theme.colors.v300};
  }
`

export const WrapperList = styled('div')(wrapperList)
export const WrapperLogin = styled('div')(wrapperLoginStyles)
export const WrapperLogout = styled('div')(wrapperLogoutStyles)
export const WrapperIcon = styled('div')(wrapperIconStyles)
