import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { Theme } from 'newCheckout/types/Theme'

const wrapperStyles = ({ theme }: Theme) => css`
  label: change-item-type__wrapper;

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: ${theme.spacings.bit};
  isolation: isolate;

  padding: ${theme.spacings.bit};

  border: 1px solid ${theme.colors.n200};
  border-radius: ${theme.borderRadius.giga};

  width: max-content;
`

const disableWrapperStyle = ({ theme, isDisabled }: Theme<{isDisabled:boolean}>) => isDisabled && css`
  label: change-item-type__wrapper--disabled;

  cursor: not-allowed;
  background: ${theme.colors.n100};
`

export const Wrapper = styled('div')(wrapperStyles, disableWrapperStyle)

const buttonStyles = ({ theme }: Theme) => css`
  label: change-item-type__button;
  
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  
  cursor: pointer;

  padding: ${theme.spacings.byte};
  gap: ${theme.spacings.bit};

  width: 89px;
  height: 28px;

  ${theme.typography.subHeadings.kilo};

  border-radius: ${theme.borderRadius.mega};

  font-weight: ${theme.font.weight.bold};

  color: ${theme.colors.n500};
`

const buttonDisabledStyles = ({ theme, isDisabled }: Theme<{isDisabled:boolean}>) => isDisabled && css`
  label: change-item-type__button--disabled;

  color: ${theme.colors.n300};
  cursor: not-allowed;
`

const buttonSelectedStyles = ({
  theme,
  selected
}: Theme<{ selected: boolean }>) =>
  selected &&
  css`
    label: change-item-type__button--select;

    background: ${theme.colors.p100};
    color: ${theme.colors.p600};
  `

const buttonSelectedDisabledStyles = ({
  theme,
  selected,
  isDisabled
}: Theme<{ selected: boolean, isDisabled: boolean}>) =>
  selected && isDisabled &&
  css`
    label: change-item-type__button--select-disabled;

    background: ${theme.colors.n200};
    color: ${theme.colors.n500};
  `

export const Button = styled('div')(buttonStyles, buttonDisabledStyles, buttonSelectedStyles, buttonSelectedDisabledStyles)
