import { useCallback, useEffect, useState } from 'react'

import SearchInterestServiceComponent from './SearchInterestServiceComponent'
import { verifyShouldDisplaySearchAgain } from './SearchInterestServiceBehaviour'

const SearchInterestServiceContainer = () => {
  const [displayComponent, setDisplayComponent] = useState(false)

  const verifyShouldDisplay = useCallback(async () => {
    setDisplayComponent(await verifyShouldDisplaySearchAgain())
  }, [])

  useEffect(() => {
    verifyShouldDisplay()
  }, [verifyShouldDisplay])

  return <SearchInterestServiceComponent displayComponent={displayComponent} />
}

export default SearchInterestServiceContainer
