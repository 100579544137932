import { useState } from 'react'
import { toast } from '@leroy-merlin-br/backyard-react'

import * as service from './services'
import { Form } from './components/Form'
import { Widget } from '../../components/Widget'

function LeadCapture () {
  const [isRequest, setIsRequest] = useState(false)

  const handleSubmit = data => {
    setIsRequest(true)
    service
      .signup(data)
      .then(() => {
        setIsRequest(false)
        toast.primary('Pronto!', {
          content: 'E-mail cadastrado com sucesso!',
          variant: 'solid'
        })
      })
      .catch(() => {
        setIsRequest(false)
        toast.critical(
          'Ocorreu alguma instabilidade para cadastrar seu e-mail.',
          {
            content: 'Tente novamente mais tarde!',
            variant: 'solid'
          }
        )
      })
  }

  return (
    <Widget withBackground>
      <Form onSubmit={handleSubmit} isRequest={isRequest} />
    </Widget>
  )
}

export default LeadCapture
