import Cookies from 'js-cookie'
import { forwardRef } from 'react'
import { Container } from '@leroy-merlin-br/backyard-react'

import * as S from './styled'
import { WrapperWeb } from './components/WrapperWeb'
import { WrapperApp } from './components/WrapperApp'

const BreadcrumbBar = forwardRef((props, ref) => {
  const isOnMobileApp = Cookies.get('is_on_mobile_app') || false

  const Wrapper = ({ children }) => {
    return (
    <S.BreadcrumbBar ref={ref}>
      <Container>
        {children}
      </Container>
    </S.BreadcrumbBar>
    )
  }

  if (isOnMobileApp) {
    return (
      <Wrapper>
        <WrapperApp></WrapperApp>
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <WrapperWeb></WrapperWeb>
    </Wrapper>
  )
})

export default BreadcrumbBar
