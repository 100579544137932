import { useLayoutEffect, useRef } from 'react'
import {
  autoUpdate,
  size,
  useDismiss,
  useFloating,
  useInteractions,
  useListNavigation,
  useRole
} from '@floating-ui/react-dom-interactions'

export const useComboBoxFloating = ({
  activeIndex,
  setActiveIndex,

  isOptionsVisible,
  setIsOptionVisible
}) => {
  const optionsListRef = useRef([])

  const {
    x: floatingElementX,
    y: floatingElementY,
    floating: floatingElementRef,
    strategy: floatingElementPosition,
    reference: referenceElementRef,
    context
  } = useFloating({
    whileElementsMounted: autoUpdate,
    open: isOptionsVisible,
    onOpenChange: setIsOptionVisible,
    middleware: [
      size({
        apply ({ rects, availableHeight, elements }) {
          Object.assign(elements.floating.style, {
            width: `${rects.reference.width}px`,
            maxHeight: `${availableHeight}px`
          })
        },
        padding: 12
      })
    ]
  })

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions(
    [
      useRole(context, { role: 'listbox' }),
      useDismiss(context),
      useListNavigation(context, {
        listRef: optionsListRef,
        activeIndex,
        onNavigate: setActiveIndex,
        virtual: true,
        loop: true
      })
    ]
  )

  useLayoutEffect(() => {
    requestAnimationFrame(() => {
      if (activeIndex != null) {
        optionsListRef.current[activeIndex]?.scrollIntoView({
          block: 'nearest'
        })
      }
    })
  }, [activeIndex, optionsListRef])

  return {
    floatingElementRef,
    getFloatingProps: props => {
      return getFloatingProps({
        style: {
          position: floatingElementPosition,
          left: floatingElementX ?? 0,
          top: floatingElementY ?? 0
        },
        ...props
      })
    },

    referenceElementRef: referenceElementRef,
    getReferenceProps: getReferenceProps,

    getItemProps,
    optionsListRef
  }
}
