import { Text } from '@leroy-merlin-br/backyard-react'
import PropTypes from 'prop-types'

import { AlertMessage } from 'shared/components/AlertMessage'

const RetryInstallments = ({ onButtonClick, orderDate = '' }) => (
  <AlertMessage
    type="warning"
    buttonText="Tentar novamente"
    onButtonClick={onButtonClick}
  >
    <Text as="span" size="kilo" noMargin>
      Infelizmente seu{' '}
      <Text as="strong" size="kilo" isBold noMargin>
        parcelamento{' '}
      </Text>
      com a nossa parceira BoletoFlex{' '}
      <Text as="strong" size="kilo" isBold noMargin>
        não foi aprovado
      </Text>
      , mas você ainda pode utilizar{' '}
      <Text as="strong" size="kilo" isBold noMargin>
        outro método de pagamento
      </Text>
      . Garantimos as mesmas condições de preço, frete e estoque{' '}
      <Text as="strong" size="kilo" isBold noMargin>
        até às 22:59 do dia {orderDate}
      </Text>
      .
    </Text>
  </AlertMessage>
)

RetryInstallments.propTypes = {
  onButtonClick: PropTypes.func,
  orderDate: PropTypes.string
}

export default RetryInstallments
