import {
  Text,
  Dialog,
  ButtonGroup,
  Button
} from '@leroy-merlin-br/backyard-react'

import { deleteRelationship } from './services'

const handleModal = (setModal, id) => {
  const modalDeleteRelationship = ({ onClose }) => (
    <Dialog
      title="Tem certeza que deseja excluir o projeto com esse profissional?"
      onClose={onClose}
      footer={
        <ButtonGroup align="flex-end">
          <Button
            size="kilo"
            appearance="primary"
            variant="outline"
            onClick={onClose}
          >
            Voltar
          </Button>
          <Button
            size="kilo"
            appearance="critical"
            onClick={() => deleteRelationship(id, onClose)}
          >
            Excluir
          </Button>
        </ButtonGroup>
      }
    >
      <Text size="mega" noMargin>
        Você não poderá mais receber vantagens, como cupons de desconto, desse
        profissional.
      </Text>
    </Dialog>
  )

  return setModal({
    size: 'giga',
    children: modalDeleteRelationship
  })
}

export default handleModal
