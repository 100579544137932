import { format as formatCPF } from '@fnando/cpf'
import { FC } from 'react'

import { formatDate } from 'utils/date'

import { MobileLayout } from '../../../components/MobileLayout'
import { PersonalDataCardMobile } from '../../../components/PersonalDataCardMobile'
import { CalendarIcon, GiftIcon, UserIcon } from '../../../icons'

const maskDocumentId = (cpf: string) => {
  const maskedCPF = `${cpf.substring(0, 3)}.***.***-${cpf.substring(9, 11)}`

  return maskedCPF
}

function isRawDate (date: string) {
  if (!date) {
    return
  }

  const regEx = /^\d{4}-\d{2}-\d{2}$/

  return date.match(regEx) != null
}

export type PersonalDataMobileProps = {
  data: {
    name: string,
    fiscalId: string,
    birthdate: string
  }
  loading: boolean
}

const PersonalDataMobile: FC<PersonalDataMobileProps> = ({ data, loading }) => {
  const CARD_INFO = [
    {
      icon: UserIcon,
      editId: 'click-edit-name-button',
      title: 'Nome',
      description: data?.name,
      edit: true,
      link: '/editar-nome'
    },
    {
      icon: CalendarIcon,
      title: 'CPF',
      description: formatCPF(data?.fiscalId),
      text: 'Este dado não pode ser alterado',
      popover: false,
      showHide: true,
      maskFunction: maskDocumentId
    },
    {
      icon: GiftIcon,
      editId: 'click-edit-birthdate-button',
      tooltipId: 'click-birthdate-tooltip',
      title: 'Data de nascimento',
      description: isRawDate(data?.birthdate) ? formatDate(data?.birthdate, 'DD/MM/YYYY') : data?.birthdate,
      edit: true,
      link: '/editar-data-nascimento',
      popover: true
    }
  ]

  return (
    <MobileLayout description="Dados pessoais">
      {CARD_INFO.map(
        ({
          editId,
          tooltipId,
          icon,
          title,
          description,
          text = '',
          edit = false,
          link = '',
          showHide = false,
          maskFunction,
          popover
        }) => (
          <PersonalDataCardMobile
            editId={editId}
            tooltipId={tooltipId}
            key={title}
            icon={icon}
            title={title}
            text={text}
            description={description}
            edit={edit}
            link={link}
            showHide={showHide}
            maskFunction={maskFunction}
            popover={popover}
            loading={loading}
          />
        )
      )}
    </MobileLayout>
  )
}

export default PersonalDataMobile
