import $ from 'jquery'

import breakpoints from 'scripts/constants/breakpoints'

/**
 * Util responsible to get the current breakpoint (mobile/desktop) based on window.innerWidth.
 * @return {string} The current breakpoint (mobile/desktop)
 */
const $window = $(window)

export default () => $window.width() >= breakpoints.sm ? 'desktop' : 'mobile'
