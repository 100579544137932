import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { Theme } from 'scripts/react-components/themes/Theme'

import { WrapperProps } from './types'

const wrapperStyles = ({
  theme,
  isDisabled,
  isActive
}: WrapperProps & Theme) => {
  const baseStyles = css`
    label: variants-base-item__wrapper;

    height: ${theme.spacings.tera};
    padding: 0 ${theme.spacings.mega};

    border-radius: 24px;
    border: 1px solid ${theme.colors.n300};

    display: flex;
    align-items: center;

    &:hover {
      background-color: ${theme.colors.n100};
    }
  `

  const activeStyles =
    isActive &&
    css`
      background-color: ${theme.colors.p100};

      border-color: ${theme.colors.p400};
      border-width: 2px;

      &:hover {
        background-color: ${theme.colors.p100};
      }
    `

  const disabledStyles =
    isDisabled &&
    css`
      opacity: 0.5;

      border-style: dashed;

      &:hover {
        background-color: ${theme.colors.white};
      }
    `

  return css`
    ${baseStyles}
    ${disabledStyles}
    ${activeStyles}
  `
}

export const Wrapper = styled('a')(wrapperStyles)
