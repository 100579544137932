import { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

import * as S from './styled'

const List = ({ children }) => {
  const reference = useRef()

  useEffect(() => {
    reference.current.scrollLeft = 1000
  }, [])

  return <S.List ref={reference}>{children}</S.List>
}

List.propTypes = {
  children: PropTypes.node.isRequired
}

export default List
