import { css } from '@emotion/react'
import styled from '@emotion/styled'

const wrapperStyles = ({ theme }) => css`
  label: service__content-wrapper;

  margin-bottom: ${theme.spacings.kilo};
`

const blockStyles = ({ theme }) => css`
  label: service__block;

  display: block;
  margin-top: ${theme.spacings.kilo};
`

const serviceThumbStyles = ({ theme }) => css`
  label: service__thumb;

  width: 75px;
  height: 75px;

  margin-right: ${theme.spacings.byte};

  svg {
    width: 75px;
  }
`

export const Block = styled('span')(blockStyles)
export const Wrapper = styled('div')(wrapperStyles)
export const ServiceThumb = styled('div')(serviceThumbStyles)
