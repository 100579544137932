import { AxiosResponse } from 'axios'
import { useEffect, useMemo } from 'react'
import { toast } from '@leroy-merlin-br/backyard-react'
import { useHistory, useLocation } from 'react-router-dom'
import { isServerError } from 'user/utils'

import { VerifyLayout } from '../../components/VerifyLayout'
import { useMyAccountContactContext } from '../../context/my-account-contact-context'
import { sendCodeAuth, sendCodeAuthIdentity, updateMainContact } from '../../services'

function useQuery () {
  const { search } = useLocation()

  return useMemo(() => new URLSearchParams(search), [search])
}

const CheckEmailCode = () => {
  const history = useHistory()
  const query = useQuery()

  const origin = query.get('origin')
  const email = query.get('email')
  const newEmail = query.get('newEmail')
  const mainCellphone = query.get('mainCellphone')
  const identityCode = query.get('identityCode')
  const fiscalId = query.get('fiscalId')

  const { contextData, updateData } = useMyAccountContactContext()

  const actions = {
    editEmail: {
      text: () => (
        <>
          Digite o código de 6 dígitos recebido em seu
          <strong> novo e-mail </strong>
          que deseja cadastrar: {newEmail}
          {' '}<a
                onClick={() => history.push({ pathname: 'editar-email' })}
                data-gtm-element-id="click-edit-email-validation-code-button"
              >editar</a>
        </>
      ),
      onSuccess: async (deviceCode: string) => {
        try {
          await updateMainContact({
            email: newEmail,
            identityCode,
            deviceCode
          })

          updateData({ ...contextData, email: newEmail })

          toast.primary('E-mail alterado com sucesso!', {
            variant: 'light'
          })
        } catch (error) {
          const { status } = error as AxiosResponse

          if (status === 422) {
            return toast.critical('Não foi possível trocar o e-mail!', {
              variant: 'light'
            })
          }

          const hasServerError = status && isServerError(status)

          if (hasServerError) {
            toast.critical('Não foi possível trocar o e-mail!', {
              variant: 'light'
            })
          }
        } finally {
          history.push('/meus-contatos')
        }
      }
    },
    editMainCell: {
      text: () => <>Para sua segurança, digite o código de 6 dígitos recebido em <strong>seu e-mail</strong> {email}</>,
      onSuccess: (identityCode: string) => {
        updateData({ ...contextData, identityCode })

        history.push({
          pathname: '/editar-celular-principal',
          search: `?origin=${origin}&identityCode=${identityCode}&mainCellphone=${mainCellphone}&fiscalId=${fiscalId}`
        })
      }
    }
  }

  const handleValidEmailAndSendCode = async () => {
    try {
      await sendCodeAuth({
        type: 'email',
        contact: newEmail,
        context: 'confirm_device'
      })
    } catch (error) {
      const { status } = error as AxiosResponse

      if (status === 422) {
        return toast.critical('Não foi possível enviar o código de segurança!', {
          variant: 'light'
        })
      }

      const hasServerError = status && isServerError(status)

      if (hasServerError) {
        toast.critical('Não foi possível enviar o código de segurança!', {
          variant: 'light'
        })
      }
    }
  }

  const handleValidMainCellAndSendCode = async () => {
    try {
      await sendCodeAuthIdentity({
        type: 'email',
        context: 'confirm_identity'
      })
    } catch (error) {
      const { status } = error as AxiosResponse

      if (status === 422) {
        return toast.critical('Não foi possível enviar o código de segurança!', {
          variant: 'light'
        })
      }

      const hasServerError = status && isServerError(status)

      if (hasServerError) {
        toast.critical('Não foi possível enviar o código de segurança!', {
          variant: 'light'
        })
      }
    }
  }

  useEffect(() => {
    (async () => {
      if (origin === 'editEmail') {
        await handleValidEmailAndSendCode()
      } else if (origin === 'editMainCell') {
        await handleValidMainCellAndSendCode()
      }
    })()
  }, [origin])

  return (
    <VerifyLayout
      infoText={actions[origin].text()}
      onSuccess={actions[origin].onSuccess}
      onRensendCode={
        origin === 'editEmail'
          ? handleValidEmailAndSendCode
          : handleValidMainCellAndSendCode
      }
      payload={{
        fiscalId,
        context: origin === 'editEmail' ? 'confirm_device' : 'confirm_identity'
      }}
    />
  )
}

export default CheckEmailCode
