import Cookies from 'js-cookie'
import insightsClient from 'search-insights'

import {
  GetInsightsStoredInfoProps,
  getInsightsStoredInfo
} from 'scripts/react-components/entry-key/utils/analytics'
import { CATEGORY_PAGE_REFERRER } from 'scripts/react-components/entry-key/utils/constants'

const getEventProps = () => {
  const algoliaInsightsObjAfterSearch = getInsightsStoredInfo()

  const userId = Cookies.get('user_id')
  const url = new URL(window.location.href)

  const isFromSearch = document.referrer.includes('search')
  const isFromRecommendedProduct =
    url.searchParams.get('is_recommended_product') === 'true'

  const isFromCategoryPage =
    url.searchParams.get('referrer') === CATEGORY_PAGE_REFERRER

  const shouldSendEventToAlgolia =
    algoliaInsightsObjAfterSearch &&
    algoliaInsightsObjAfterSearch.queryID &&
    (isFromRecommendedProduct || isFromSearch || isFromCategoryPage)

  return {
    userId,
    algoliaInsightsObjAfterSearch,
    shouldSendEventToAlgolia
  }
}

const mountEventObject = (
  algoliaInsightsObjAfterSearch: GetInsightsStoredInfoProps,
  userId?: string
) => {
  return {
    objectIDs: [algoliaInsightsObjAfterSearch.objectID],
    queryID: algoliaInsightsObjAfterSearch.queryID,
    index: algoliaInsightsObjAfterSearch.indexName,
    ...(userId ? { userToken: userId } : { useCookie: true })
  }
}

export const sendEventToAlgolia = async () => {
  const { userId, algoliaInsightsObjAfterSearch, shouldSendEventToAlgolia } =
    getEventProps()

  if (shouldSendEventToAlgolia && algoliaInsightsObjAfterSearch) {
    insightsClient('clickedObjectIDsAfterSearch', {
      ...mountEventObject(algoliaInsightsObjAfterSearch, userId),
      eventName: 'clicked_pdp',
      positions: [algoliaInsightsObjAfterSearch.position]
    })
  }
}

export const addToCartEvent = () => {
  const { userId, algoliaInsightsObjAfterSearch, shouldSendEventToAlgolia } =
    getEventProps()

  if (shouldSendEventToAlgolia && algoliaInsightsObjAfterSearch) {
    const event = {
      ...mountEventObject(algoliaInsightsObjAfterSearch, userId),
      eventName: 'addToCart',
      eventSubType: 'addToCart',
      objectData: [
        {
          price: algoliaInsightsObjAfterSearch.price,
          quantity: algoliaInsightsObjAfterSearch.quantity
        }
      ],
      currency: algoliaInsightsObjAfterSearch.currency
    }

    insightsClient('convertedObjectIDsAfterSearch', event)
  }
}
