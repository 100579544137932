import { ModalProvider } from '@leroy-merlin-br/backyard-react'

import reactComponentLoader from 'scripts/react-component-loader'

import AlertModal from './AlertModal'

const AlerModalContainer = ({ ...props }) => (
  <ModalProvider>
    <AlertModal {...props} />
  </ModalProvider>
)

reactComponentLoader(AlerModalContainer, '[data-alert-modal]')
