import { Text } from '@leroy-merlin-br/backyard-react'

import { AlertMessage } from 'shared/components/AlertMessage'

const CancelledAfterResponsibleAbsent = () => (
  <AlertMessage type="critical">
    Seu pedido foi{' '}
    <Text as="strong" size="kilo" isBold noMargin>
      cancelado
    </Text>
    , pois não foi possível encontrar alguém para receber a sua compra.
  </AlertMessage>
)

export default CancelledAfterResponsibleAbsent
