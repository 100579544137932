import { AxiosError } from 'axios'

import request from '../../../services/axios'
import { API_ENDPOINTS } from '../../../services/constants'

export const createBankService = () => {
  const getAll = async () => {
    try {
      const { data } = await request.get(API_ENDPOINTS.banks('all'))

      return data.result.data
    } catch (error) {
      if ((error as AxiosError).response?.status === 404) return null
      throw error
    }
  }

  const getAgenciesByBank = async (bankId: string, agencyName: string) => {
    try {
      const { data } = await request.get(API_ENDPOINTS.bankAgencies(bankId, agencyName))

      return data.result.data
    } catch (error) {
      if ((error as AxiosError).response?.status === 404) return null
      throw error
    }
  }

  return { getAll, getAgenciesByBank }
}
