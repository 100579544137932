import { shallowEqual, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { useResize } from 'utils/hooks/use-resize'

import { Header, Navigation } from 'lmcv/components'

function HeaderContainer ({ history }) {
  const {
    get: { payload: loyaltyProfile },
    loyaltyStatus
  } = useSelector((state) => state.loyaltyProfile, shallowEqual)

  const [isMobile] = useResize()

  /**
   * If isDesktop check routes to render header
   */
  const isDesktopOnHome = !isMobile && history.location.pathname !== '/'

  if (!isDesktopOnHome) {
    return (
      <Header loyaltyProfile={loyaltyProfile}>
        <Navigation history={history} loyaltyStatus={loyaltyStatus} />
      </Header>
    )
  }

  return null
}

export default withRouter(HeaderContainer)
