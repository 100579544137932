import { Fragment } from 'react'
import { Heading } from '@leroy-merlin-br/backyard-react'

import { onCrossItemClick } from 'scripts/utils/data-layer'

import { ProductsCarousel } from 'site/Modular/modules/ProductsCarousel'
import WishlistProviderMethods from 'site/Wishlist/provider'

import * as S from './styled'

const ComplementaryProducts = ({
  crosses,
  shouldShowNewReviewsRating = ''
}) => {
  const parsedCrosses = JSON.parse(crosses)
  const handleCrossCarouselClick = (
    productId,
    productIndex,
    crossDataLayer
  ) => {
    const { crossProductList, crossIndex, crossTitle, crossOriginProductId } =
      crossDataLayer
    const product = parsedCrosses[crossIndex].crossProducts.find(
      product => product.id === productId
    )

    const crossProduct = {
      crossTitle: crossTitle,
      productName: product.name,
      productId: product.id,
      page: 'PDP',
      crossIndex: crossIndex,
      source: 'own source',
      productIndex: productIndex,
      productsIds: crossProductList,
      originProductId: crossOriginProductId
    }
    onCrossItemClick(crossProduct)
  }

  return (
    <WishlistProviderMethods>
      {parsedCrosses.map((cross, crossIndex) => {
        const crossDataLayer = {
          crossOriginProductId: cross.crossOriginProductId,
          crossIndex,
          crossTitle: cross.crossTitle,
          crossProductList: cross.crossProductList
        }
        return (
          <Fragment key={crossIndex}>
            <S.WrapperTitle>
              <Heading as="h3" size="peta">
                {cross.crossTitle}
              </Heading>
            </S.WrapperTitle>
            <ProductsCarousel
              partnerName="elastic search"
              key={cross.id}
              title={cross.crossTitle}
              products={cross.crossProducts}
              shouldShowNewReviewsRating={shouldShowNewReviewsRating === 'true'}
              handleClick={(productId, productIndex) =>
                handleCrossCarouselClick(
                  productId,
                  productIndex,
                  crossDataLayer
                )
              }
              isCrossProduct
            />
          </Fragment>
        )
      })}
    </WishlistProviderMethods>
  )
}

export default ComplementaryProducts
