import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock'
import { CloseButton } from '@leroy-merlin-br/backyard-react'

import * as S from './styled'

function Drawer ({ visible, onClose, title, children, dataCy = 'drawer' }) {
  useEffect(() => {
    const targetElement = document.body

    visible ? disableBodyScroll(targetElement) : enableBodyScroll(targetElement)

    return () => {
      enableBodyScroll(targetElement)
    }
  }, [visible])

  return (
    <S.Wrapper visible={visible}>
      <S.BackDrop onClick={onClose} />

      <S.Aside data-cy={dataCy}>
        <S.Header>
          {onClose && <CloseButton onClick={onClose} />}
          <S.Title>{title}</S.Title>
        </S.Header>
        <S.Content>{children}</S.Content>
      </S.Aside>
    </S.Wrapper>
  )
}

Drawer.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string.isRequired,
  children: PropTypes.node
}

Drawer.defaultProps = {
  visible: false
}

export default Drawer
