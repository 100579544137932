import { useContext, useEffect, useState } from 'react'
import { Checkmark, HeartOutline } from '@backyard-ui/icons'

import { getPictures } from 'scripts/react-components/api/Product/pictures'
import * as dataLayer from 'scripts/utils/data-layer'
import { sendEventToAlgolia } from 'scripts/react-components/shared/analytics/algolia.tracker'

import { withWishlistProviderMethods } from 'site/Wishlist/provider'
import { WishlistContext } from 'site/Wishlist/context'

import { Carousel } from './components'
import { type CarouselItem } from './types'
import * as S from './styled'

interface MediasCarouselProps {
  productId: number
  productName: string
  productPrice: {
    to: {
      integers: string
      decimals: string
    }
  }
  categoryTree: string
  brand: string
}

const MediasCarousel = ({
  productId,
  productName,
  productPrice,
  categoryTree,
  brand
}: MediasCarouselProps) => {
  const [carouselItems, setCarouselItems] = useState<CarouselItem[]>([])
  const [buttons, setButtons] = useState({
    hasVideos: false,
    hasSketchFab: false
  })

  const wishlistValues: any = useContext(WishlistContext)
  const isOnWishlist = wishlistValues.isOnWishlist(productId)
  const { onPressHeart } = wishlistValues

  const wishlistAction = (event: Event) => {
    event.stopPropagation()
    dataLayer.midiasCarouselWishlistButtonClick(productId)
    sendEventToAlgolia()
    onPressHeart(
      {
        inWishList: isOnWishlist,
        id: productId,
        product: {
          id: productId,
          shortName: productName,
          price: productPrice,
          category: categoryTree,
          brand,
          name: productName
        }
      },
      'products'
    )
  }

  const wishListButton = {
    label: isOnWishlist ? 'Adicionado aos Favoritos' : 'Lista de Favoritos',
    icon: isOnWishlist ? Checkmark : HeartOutline,
    onClick: wishlistAction,
    variant: isOnWishlist ? 'solid' : 'ghost'
  }

  useEffect(() => {
    getPictures(productId).then(data => {
      const carouselItems = data.medias.concat(data.sketchFab || [])

      const hasVideos =
        carouselItems.filter((item: CarouselItem) => item.type === 'video')
          .length > 0

      const hasSketchFab =
        carouselItems.filter((item: CarouselItem) => item.type === 'sketchFab')
          .length > 0

      setCarouselItems(carouselItems)

      setButtons({
        hasVideos,
        hasSketchFab
      })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <S.Container>
      <Carousel
        carouselItems={carouselItems}
        productId={productId}
        hasVideos={buttons.hasVideos}
        hasSketchFab={buttons.hasSketchFab}
        wishListButton={wishListButton}
      />
    </S.Container>
  )
}

export default withWishlistProviderMethods(MediasCarousel)
