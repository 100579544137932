import feedbackErrorsTemplate from 'templates/feedback-errors'

import emitter from 'scripts/utils/emitter'
import BaseComponent from 'scripts/components/base-component'

const DEFAULTS = {
  errors: {
    PERMISSION_DENIED: 'Permissão de acesso negada pelo usuário',
    POSITION_UNAVAILABLE: 'Geolocalização indisponível',
    TIMEOUT: 'Tempo esgotado'
  },
  positionOptions: {
    enableHighAccuracy: true,
    timeout: 60000,
    maximumAge: 0
  }
}

class LocationButton extends BaseComponent {
  static componentName = 'location-button'

  constructor (element, location = window.location, navigator = window.navigator) {
    super(element)

    this.options = Object.assign({}, DEFAULTS)
    this.location = location
    this.navigator = navigator
  }

  static DOMEvents = {
    click: 'getCoordinates'
  }

  hasGeolocationFeature () {
    return !!this.navigator.geolocation
  }

  getCoordinates () {
    if (this.hasGeolocationFeature()) {
      this.navigator.geolocation.getCurrentPosition(
        this.setLocation.bind(this),
        this.errorHandler.bind(this),
        this.options.positionOptions
      )
    }
  }

  setLocation (position) {
    const { protocol, hostname } = this.location
    const { latitude, longitude } = position.coords
    const baseURL = `${protocol}//${hostname}`
    const url = `${baseURL}/localizacao/store/?lat=${latitude}&long=${longitude}`

    this.location.replace(url)
  }

  errorHandler (error) {
    const {
      PERMISSION_DENIED,
      POSITION_UNAVAILABLE,
      TIMEOUT
    } = this.options.errors

    switch (error.code) {
      case error.PERMISSION_DENIED:
        return this.errorMessage(PERMISSION_DENIED)

      case error.POSITION_UNAVAILABLE:
        return this.errorMessage(POSITION_UNAVAILABLE)

      case error.TIMEOUT:
        return this.errorMessage(TIMEOUT)
    }
  }

  errorMessage (errorType) {
    emitter.emit('stickyFeedback:error', {
      title: 'Ops! Ocorreu o seguinte erro:',
      content: feedbackErrorsTemplate(
        { errors: [errorType] }
      )
    })
  }
}

export default ($el) => new LocationButton($el)

export { LocationButton as Component }
