import classNames from 'classnames'

const Item = ({ activeLink, text, anchor, onClick }) => {
  const classActive = classNames({
    active: activeLink
  })

  return (
    <li onClick={onClick}>
      <a
        data-navbar-scroll
        className={classActive}
        href={anchor}
      >
        <span>{text}</span>
      </a>
    </li>
  )
}

export default Item
