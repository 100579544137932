export const ArrowRight = () => (
  <svg
    width="4"
    height="8"
    viewBox="0 0 4 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.0512078 7.51657L2.14718 4.17829C2.2132 4.072 2.2132 3.928 2.14718 3.82171L0.0669744 0.482285C-0.0581729 0.281143 0.065989 0 0.278838 0H1.43473C1.51849 0 1.59732 0.0468574 1.64758 0.125714L3.95048 3.82171C4.01651 3.928 4.01651 4.072 3.95048 4.17829L1.64758 7.87314C1.59732 7.95314 1.51849 8 1.43473 8H0.263071C0.0482516 8 -0.0759103 7.71771 0.0512078 7.51657Z"
      fill="currentColor"
    />
  </svg>
)
