import { useState, useEffect } from 'react'

export const useDataLayerPush = ({ runOnce = true, callback }) => {
  const [isPushed, setIsPushed] = useState(false)

  useEffect(() => {
    if (!isPushed || !runOnce) {
      callback && callback()
    }

    if (runOnce) {
      setIsPushed(true)
    }
  }, [isPushed, runOnce, callback])
}
