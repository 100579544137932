import { Text } from '@leroy-merlin-br/backyard-react'

import {
  MoneyIcon,
  HouseIcon,
  BoxIcon,
  TrainingIcon
} from '../../components/Icons'

export const ADVANTAGES = [
  {
    icon: MoneyIcon,
    title: 'Ganhe renda extra!',
    description: (
      <>
        Participe{' '}
        <Text as="span" isBold color="n600">
          gratuitamente
        </Text>{' '}
        e receba comissões a cada venda!
      </>
    )
  },
  {
    icon: HouseIcon,
    title: 'Ganhe sem sair de casa',
    description:
      'Compre online e escolha receber em casa ou retirar na sua loja favorita!'
  },
  {
    icon: BoxIcon,
    title: 'Tudo para o seu  projeto num só lugar!',
    description:
      'A Leroy Merlin tem tudo que você precisa para decorar ou reformar.'
  },
  {
    icon: TrainingIcon,
    title: 'Treinamento e suporte exclusivo',
    description:
      'Tenha acesso a webinars de treinamento após confirmação do cadastro!'
  }
]
