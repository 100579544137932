import { css } from '@emotion/react'
import styled from '@emotion/styled'

const triggerStyles = () => css`
  label: trigger;

  cursor: pointer;

  &:hover * {
    text-decoration: underline;
  }
`

export const Trigger = styled('div')(triggerStyles)
