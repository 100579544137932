import { css } from '@emotion/react'
import styled from '@emotion/styled'

const contentWrapperStyles = ({ theme }) => css`
  label: content-wrapper;

  display: flex;
  flex-direction: column;
  gap: ${theme.spacings.byte};
`

const descriptionWrapperStyles = ({ theme }) => css`
  label: description-wrapper;

  svg {
    float: left;

    color: white;
    margin-right: ${theme.spacings.bit};
  }
`

const spotlightStyles = ({ theme }) => css`
  label: spotlight;

  font-weight: ${theme.font.weight.bold};

  ${theme.media.giga} {
    color: ${theme.colors.p500};
  }
`

const buttonWrapperStyles = ({ theme }) => css`
  label: button-wrapper;

  display: flex;
  justify-content: center;
  gap: ${theme.spacings.mega};
  margin-top: ${theme.spacings.byte};
`

const whiteButtonWrapperStyles = ({ theme, isWhite }) =>
  isWhite &&
  css`
    label: button-wrapper--white;

    [target='btn-confirm'] {
      background-color: white;
      color: ${theme.colors.b800};
    }

    [target='btn-confirm']:hover {
      border: none;
      background-color: ${theme.colors.b200};
    }

    [target='btn-confirm']:focus {
      border: none;
      background-color: ${theme.colors.b300};
    }

    [target='btn-change'] {
      border-color: white;
      color: white;
    }

    [target='btn-change']:hover {
      background-color: ${theme.colors.b900};
      border-color: ${theme.colors.b200};
      color: ${theme.colors.b200};
    }

    [target='btn-change']:focus {
      background-color: ${theme.colors.b900};
      border-color: ${theme.colors.b300};
      color: ${theme.colors.b300};
    }
  `

export const ContentWrapper = styled('div')(contentWrapperStyles)
export const DescriptionWrapper = styled('div')(descriptionWrapperStyles)
export const Spotlight = styled('span')(spotlightStyles)
export const ButtonWrapper = styled('div')(buttonWrapperStyles, whiteButtonWrapperStyles)
