import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useModal, Stack } from '@leroy-merlin-br/backyard-react'
import { ChevronLeftOutline } from '@backyard-ui/icons'

import { getRelationship } from 'lmcv/services/customers'
import { Breadcrumb } from 'lmcv/components'

import { ProfileSkeleton } from './components/ProfileSkeleton'
import { Profile } from './components/Profile'
import handleModal from './handleModal'

function Professional ({ match: { params } }) {
  const relationshipId = params.id
  const [data, setData] = useState({})
  const [loading, setLoading] = useState(true)
  const { setModal } = useModal()

  useEffect(() => {
    getRelationship(relationshipId)
      .then(data => {
        setData(data)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }, [relationshipId])

  const PROFILES_COMPONENTS = {
    ativo: Profile.Active
  }

  if (loading) {
    return <ProfileSkeleton />
  }

  const ProfileComponent = PROFILES_COMPONENTS[data.status.toLowerCase()] || (
    <ProfileSkeleton />
  )

  return (
    <Stack>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/">
            <ChevronLeftOutline width={16} height={16} fill="currentColor" />{' '}
            Inicio
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{data.professionalName}</Breadcrumb.Item>
      </Breadcrumb>
      <ProfileComponent
        data={data}
        handleDropDown={() =>
          handleModal(setModal, data.id, data.status.toLowerCase())
        }
      />
    </Stack>
  )
}

export default Professional
