import { FunctionComponent } from 'react'
import { Stack, Text } from '@backyard-ui/core'

import styles from './PixContent.styles'

const PixContent: FunctionComponent = () => (
  <Stack direction="column" spacing="4">
    <Stack direction="column" spacing="2">
      <Text size="lg" color="green-600" weight="bold">
        Pagar com o PIX é simples, rápido e seguro
      </Text>

      <ul className={styles({ ordered: false })}>
        <Text size="md" asChild>
          <li>
            Tão simples que com a câmera do celular você consegue realizar o
            pagamento!
          </li>
        </Text>
        <Text size="md" asChild>
          <li>
            Tão rápido que nós confirmamos seu pagamento dentro de 15 segundos.
          </li>
        </Text>
        <Text size="md" asChild>
          <li>
            E o melhor, como o PIX é instantâneo, o prazo de entrega começa a
            contar imediatamente após o pagamento.
          </li>
        </Text>
      </ul>
    </Stack>

    <Stack direction="column" spacing="2">
      <Text size="lg" color="green-600" weight="bold">
        Mas atenção!
      </Text>

      <ol className={styles({ ordered: true })}>
        <Text size="md" asChild>
          <li>
            Quando você escolhe realizar o pagamento via PIX, o valor do seu
            pedido não poderá ser parcelado.
          </li>
        </Text>
        <Text size="md" asChild>
          <li>
            Para a sua segurança, geramos códigos PIX exclusivos para cada
            pedido. Então, caso você não consiga realizar o pagamento até a
            expiração do código, não se preocupe, faremos o cancelamento
            automático.
          </li>
        </Text>
        <Text size="md" asChild>
          <li>
            Em casos de cancelamento por expiração do código, você pode tentar
            repetir a compra, nosso site estará sempre disponível para você
            realizar um novo pedido.
          </li>
        </Text>
      </ol>
    </Stack>

    <Stack direction="column" spacing="2">
      <Text size="lg" color="green-600" weight="bold">
        Para acesso
      </Text>

      <Text size="md">
        Você também poderá acessar o seu código PIX desse pedido através da
        página "Meus Pedidos" ou nas comunicações enviadas por e-mail.
      </Text>
    </Stack>
  </Stack>
)

export default PixContent
