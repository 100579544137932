import { css } from '@emotion/react'
import styled from '@emotion/styled'

const containerStyles = ({ theme }) => css`
  label: cart__container;

  ${theme.media.mega} {
    padding: 0 5px;
  }
`

export const Container = styled('div')(containerStyles)
