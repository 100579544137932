import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { styleHelpers } from '@leroy-merlin-br/backyard-react'

const suggestionsStyles = ({ theme }) => css`
  label: suggestions;

  position: absolute;
  width: 100%;
  z-index: ${theme.zIndex.modal + 1};

  background-color: ${theme.colors.white};

  border-radius: ${theme.borderRadius.mega};
  ${styleHelpers.shadowSingle(theme)};
`

const suggestionsItemStyles = ({ theme }) => css`
  label: suggestions__item;

  width: 100%;
  padding: ${theme.spacings.byte} ${theme.spacings.mega};

  cursor: pointer;

  text-align: left;
  color: ${theme.colors.n400};
  font-size: ${theme.typography.subHeadings.mega.fontSize};
  line-height: ${theme.typography.subHeadings.mega.lineHeight};

  &:hover {
    background-color: ${theme.colors.p600};
    color: ${theme.colors.white};
  }

  &:first-of-type {
    border-top-left-radius: ${theme.borderRadius.mega};
    border-top-right-radius: ${theme.borderRadius.mega};
  }

  &:last-of-type {
    border-bottom-left-radius: ${theme.borderRadius.mega};
    border-bottom-right-radius: ${theme.borderRadius.mega};
  }
`

export const Suggestions = styled('div')(suggestionsStyles)
export const SuggestionsItem = styled('div')(suggestionsItemStyles)
