
import {
  IconButton,
  Inline,
  Icon,
  Hidden
} from '@leroy-merlin-br/backyard-react'
import { useContext, useMemo } from 'react'
import Cookies from 'js-cookie'
import { FilterOutline } from '@backyard-ui/icons'

import { EntryKeyContext } from 'scripts/react-components/site/EntryKey/context'

import { Breadcrumb as BaseBreadcrumb } from 'site/Breadcrumb'

import { ShareButton } from '../ShareButton'
import * as S from './styled'
import { Space } from '../../../Space'

const WrapperWeb = () => {
  const isOnMobileApp = Cookies.get('is_on_mobile_app') || false
  const shouldRenderShareButton = !isOnMobileApp

  const {
    apiDomain,
    categoryId,
    query: { term },
    isSearch,
    toggleMobileFilter
  } = useContext(EntryKeyContext)

  const nodes = useMemo(() => {
    if (isSearch) {
      return [
        {
          id: '1',
          name: 'Home',
          url: '/'
        },
        {
          id: '2',
          name: `Busca: ${term?.value}`,
          url: window.location.href
        }
      ]
    }
  }, [term, isSearch])

  return (
    <Space>
      <S.Wrapper>
        <BaseBreadcrumb
          categoryId={categoryId}
          basehost={apiDomain}
          nodes={nodes}
        />

        <Hidden when={['giga', 'tera']}>
          <Inline space="mega">
            {shouldRenderShareButton && <ShareButton />}
            <S.FilterButtonWrapper data-testid="filter-button-responsive">
              <IconButton
                variant="ghost"
                size="kilo"
                label="filtrar"
                onClick={toggleMobileFilter}
              >
                <Icon as={FilterOutline} />
              </IconButton>
            </S.FilterButtonWrapper>
          </Inline>
        </Hidden>
      </S.Wrapper>
    </Space>
  )
}

export default WrapperWeb
