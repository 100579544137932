import styled from '@emotion/styled'
import { css } from '@emotion/react'

const titleWrapperStyles = ({ theme }) => css`
  label: title-wrapper;

  display: flex;
  gap: ${theme.spacings.byte};
`

const contentWrapperStyles = ({ theme }) => css`
  label: content-wrapper;

  p:first-of-type {
    margin-bottom: ${theme.spacings.byte};
  }

  a {
    box-sizing: border-box;
    text-decoration: none;
  }

  margin-top: ${theme.spacings.giga};
`

export const TitleWrapper = styled('div')(titleWrapperStyles)
export const ContentWrapper = styled('div')(contentWrapperStyles)
