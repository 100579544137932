import styled from '@emotion/styled'
import { css } from '@emotion/react'

const containerStyles = ({ theme }) => css`
  label: container;

  width: 100%;

  ${theme.media.mega} {
    width: 480px;
  }
`

export const Container = styled('div')(containerStyles)
