import styled from '@emotion/styled'
import { css } from '@emotion/react'

const deadlineBoxStyles = ({ theme }) => css`
  label: deadline__box;

  background-color: ${theme.colors.n100};
  margin-bottom: 1px;
  padding: ${theme.spacings.byte} ${theme.spacings.kilo};
  width: 100%;

  &:last-child {
    margin-bottom: ${theme.spacings.kilo};
  }
`

export const DeadlineBox = styled('div')(deadlineBoxStyles)
