import { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Text, Inline, Stack } from '@leroy-merlin-br/backyard-react'

import { formatters } from 'utils/formatters'
import { formatDate } from 'utils/date'

import { WelcomeText } from './components/WelcomeText'
import * as S from './styled'

const Header = ({ loyaltyProfile, children }) => {
  const {
    picture,
    name,
    points: {
      actual,
      season: { expireAt, toExpire }
    }
  } = loyaltyProfile

  const hasBalanceToExpire = toExpire > 0

  return (
    <Fragment>
      <S.HeaderElement>
        <S.Photo>
          <S.PhotoImage avatar={picture}>{!picture && name?.[0]}</S.PhotoImage>
        </S.Photo>

        <S.Welcome>
          <S.TextWelcome>Bem-vindo, {name}!</S.TextWelcome>
          <WelcomeText />
        </S.Welcome>

        <Stack alignX="center" space="byte">
          <S.Ticket>
            <Stack space="bit" alignX="center">
              <Inline space="byte" alignY="baseline">
                <S.TicketHaveText>Você tem</S.TicketHaveText>
                <Inline space="bit" alignY="baseline">
                  <S.TicketValue>{formatters.toDecimal(actual)}</S.TicketValue>
                  <Text as="span" size="kilo" noMargin color="white">
                    {actual === 1 ? 'pt' : 'pts'}
                  </Text>
                </Inline>
              </Inline>
            </Stack>

            {hasBalanceToExpire && (
              <S.ExpireInfo>
                <Text size="kilo" noMargin color="white">
                  <span>
                    {formatters.toDecimal(toExpire)} pontos expiram no dia{' '}
                    {formatDate(expireAt, 'DD/MM/YYYY')}
                  </span>
                </Text>
              </S.ExpireInfo>
            )}
          </S.Ticket>

          <S.PointsInfo>
            <Text
              as="a"
              size="kilo"
              noMargin
              color="white"
              href="/modular/programa-fidelidade"
              isBold
            >
              clique aqui{' '}
            </Text>
            <Text as="span" size="kilo" noMargin color="white">
              e saiba mais sobre expiração de pontos
            </Text>
          </S.PointsInfo>
        </Stack>
      </S.HeaderElement>
      {children}
    </Fragment>
  )
}

Header.propTypes = {
  loyaltyProfile: PropTypes.shape({
    name: PropTypes.string,
    points: PropTypes.object,
    picture: PropTypes.string
  }).isRequired,
  children: PropTypes.node
}

export default Header
