import produce from 'immer'

import { Constants } from '.'
import { Constants as CouponContants } from '../coupon'

const initialState = {
  get: {
    isRequest: true,
    user: {
      isAuthenticated: false,
      isMember: false
    },
    coupons: [],
    error: null
  }
}

export default function reducer (state = initialState, action = {}) {
  return produce(state, draft => {
    switch (action.type) {
      case Constants.MODULE_GET: {
        draft.get.isRequest = true

        break
      }

      case Constants.MODULE_GET_SUCCESS: {
        draft.get.isRequest = false
        draft.get.user = {
          isAuthenticated: action.payload.isLoggedUser,
          isMember: action.payload.isOnLoyaltyProgram
        }
        action.payload.vouchers.forEach(item =>
          draft.get.coupons.push({
            ...item,
            isLoading: false
          })
        )

        break
      }

      case Constants.MODULE_GET_FAILURE: {
        draft.get.isRequest = true
        draft.get.error = action.error

        break
      }

      case CouponContants.COUPON_ACTIVE: {
        const item = draft.get.coupons.filter(item => item.id === action.id)[0]

        if (item) {
          Object.assign(item, {
            isLoading: true
          })
        }

        break
      }

      case CouponContants.COUPON_ACTIVE_SUCCESS: {
        const coupon = draft.get.coupons.filter(
          item => item.id === action.id
        )[0]

        if (coupon) {
          Object.assign(coupon, {
            isLoading: false,
            isActive: true
          })
        }
        break
      }

      case CouponContants.COUPON_ACTIVE_FAILURE: {
        const hasCoupon = draft.get.coupons.filter(
          item => item.id === action.id
        )[0]

        if (hasCoupon) {
          Object.assign(hasCoupon, {
            isLoading: false
          })
        }

        break
      }

      default: {
        return state
      }
    }
  })
}
