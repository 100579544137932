import styled from '@emotion/styled'
import { css } from '@emotion/react'

const coverWrapperStyles = () => css`
  label: chat__component-cover;

  width: 100%;
  height: 100%;
  display: flex;
`

export const CoverWrapper = styled('div')(coverWrapperStyles)
