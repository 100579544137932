export const COUPONS_SET = '@COUPONS_SET'

export const COUPONS_APPLY = '@COUPONS_APPLY'
export const COUPONS_APPLY_SUCCESS = '@COUPONS_APPLY_SUCCESS'
export const COUPONS_APPLY_FAILURE = '@COUPONS_APPLY_FAILURE'

export const COUPONS_REMOVE = '@COUPONS_REMOVE'
export const COUPONS_REMOVE_SUCCESS = '@COUPONS_REMOVE_SUCCESS'
export const COUPONS_REMOVE_FAILURE = '@COUPONS_REMOVE_FAILURE'

export default {
  COUPONS_SET,

  COUPONS_APPLY,
  COUPONS_APPLY_SUCCESS,
  COUPONS_APPLY_FAILURE,

  COUPONS_REMOVE,
  COUPONS_REMOVE_SUCCESS,
  COUPONS_REMOVE_FAILURE
}
