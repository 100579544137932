import { Heading, Stack, Text } from '@leroy-merlin-br/backyard-react'
import { CardIcon, BaseModal } from 'user/celebre/components'

const CNPJModalError = ({ onClose }) => (
  <BaseModal onClose={onClose}>
    <CardIcon appearance="critical" />

    <Stack space="giga">
      <Heading as="h3" size="tera" noMargin>
        Não é possível solicitar o cartão Celebre com CNPJ :(
      </Heading>

      <Text size="mega" noMargin>
        Infelizmente não será possível continuar com o processo. O cartão
        Celebre é disponibilizado apenas para pessoas físicas no momento.
      </Text>
    </Stack>
  </BaseModal>
)

export default CNPJModalError
