import { css } from '@emotion/react'
import styled from '@emotion/styled'

const buttonStyles = ({ theme, full }) => {
  const fullStyle = css`
    label: ar-button--full;

    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    margin: 16px auto;
    font-size: 16px;
    font-weight: 600;
    background: ${theme.colors.white};
    color: ${theme.colors.p600};
    width: 100%;
    height: 44px;
    border-radius: 8px;
    border: 1px solid ${theme.colors.p600};

    svg {
      fill: ${theme.colors.p600};
      margin-right: 8px;
      width: 29px;
    }

    &:hover {
      background: ${theme.colors.p600};
      color: ${theme.colors.white};
      transition: background .3s ease-in-out;

      svg {
        fill: ${theme.colors.white};
        transition: fill .3s ease-in-out;
      }
    }
  `

  const miniStyle = css`
    label: ar-button--mini;

    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    font-weight: 600;
    background: ${theme.colors.white};
    color: ${theme.colors.p600};
    border-radius: 8px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
    width: 44px;
    height: 44px;

    svg {
      fill: ${theme.colors.p600};
      width: 27px;
    }

    &:hover {
      background: ${theme.colors.p600};
      color: ${theme.colors.white};

      svg {
        fill: ${theme.colors.white};
        transition: fill .3s ease-in-out;
      }
    }
  `

  return full ? fullStyle : miniStyle
}

const containerStyles = ({ full, theme }) => {
  const fullStyle = css`
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    margin: 16px auto 0;
    border-top: 1px solid ${theme.colors.n200};

    ${theme.media.mega} {
      display: none;
    }
  `
  const miniStyle = css`
    display: flex;
    flex: 1 1 auto;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 48px;
    right: 16px;
    z-index: ${theme.zIndex.modal};

    ${theme.media.mega} {
      display: none;
    }
  `
  return full ? fullStyle : miniStyle
}

const miniButtonContainerStyles = ({ theme }) => css`
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 48px;
  right: 16px;
  z-index: ${theme.zIndex.modal};

  ${theme.media.mega} {
    display: none;
  }
`

const fullButtonContainerStyles = ({ theme }) => css`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  margin: 16px auto 0;
  border-top: 1px solid ${theme.colors.n200};

  ${theme.media.mega} {
    display: none;
  }
`

export const MiniButtonContainer = styled('div')(miniButtonContainerStyles)
export const FullButtonContainer = styled('div')(fullButtonContainerStyles)
export const Button = styled('button')(buttonStyles)
