import styled from '@emotion/styled'
import { css } from '@emotion/react'

const baseStyles = ({ theme }) => css`
  label: quantity__input;

  cursor: pointer;
  overflow: hidden;
  appearance: textfield;

  text-align: center;
  width: 100%;

  border: none;

  background-color: ${theme.colors.white};

  font-size: ${theme.typography.text.mega.fontSize};
  line-height: ${theme.typography.text.mega.lineHeight};

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }

  &:focus {
    outline: 0;
    box-shadow: none;
  }
`

export const Input = styled('input')(baseStyles)
