import { Fragment } from 'react'
import { Helmet } from 'react-helmet'

import { HeroBanner, Advantages, Faq, HowTo, Cta } from './sections'
import * as S from './styled'

const Affiliates = () => (
  <Fragment>
    <Helmet>
      <title>Programa de Afiliados para Profissionais - Leroy Merlin</title>
      <meta
        name="description"
        content="Participe gratuitamente do Programa de Afiliados para Profissionais do setor e ganhe renda extra sem sair de casa! Acesse e confira como se cadastrar!"
      />
    </Helmet>
    <S.Background>
      <HeroBanner />
      <Advantages />
    </S.Background>
    <HowTo />
    <Cta />
    <Faq />
  </Fragment>
)

export default Affiliates
