import { useEffect, FunctionComponent, useState } from 'react'
import {
  Text,
  Stack,
  Spacer,
  Popover,
  Skeleton,
  Button
} from '@backyard-ui/core'
import { datadogRum } from '@datadog/browser-rum'
import { getProductReviewsSummary } from 'hpdp/services/reviews'
import type { GetProductReviewsSummaryData } from 'hpdp/services/reviews'
import { StarRating } from 'hpdp/components'

import { AggregateRatingSchema } from 'shared/schemas'

import { RatingResume } from '../Reviews/components'
import type { Props } from './types'

const ReviewPopover: FunctionComponent<Props> = ({ productId }) => {
  const [summaryResponse, setSummaryResponse] =
    useState<GetProductReviewsSummaryData>({})
  const [isLoading, setIsLoading] = useState(true)

  const { generalScores } = summaryResponse

  const rating = generalScores?.statistics?.avgRating
  const totalReviewsCount = generalScores?.statistics?.totalReviewsCount || 0

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getProductReviewsSummary(productId)
        setSummaryResponse(response)
      } catch (e) {
        datadogRum.addError(e)
      } finally {
        setIsLoading(false)
      }
    }

    fetchData()
  }, [productId])

  const handleScrollToReview = () => {
    const reviewSection = document.querySelector('[data-scroll="user-reviews"]')

    if (reviewSection) {
      reviewSection.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const handleClickReview = () => {
    if (!generalScores) {
      handleScrollToReview()
    }
  }

  if (isLoading) {
    return <Skeleton width="172px" height="23px" radius="lg" data-testid="loading-skeleton"/>
  }

  return (
    <>
      <Popover.Root>
        <Popover.Trigger onClick={handleClickReview}>
          {generalScores && (
            <AggregateRatingSchema
              distribution={generalScores.distribution}
              ratingValue={rating}
              ratingCount={generalScores.statistics.totalReviewsCount}
            />
          )}

          <Stack align="center">
            <StarRating color="primary" rating={Number(rating)} />

            <Stack align="center" spacing="1">
              <Text>{rating}</Text>
              <Text>({totalReviewsCount})</Text>
              <Spacer axis="horizontal" />
            </Stack>
          </Stack>
        </Popover.Trigger>

        {generalScores && (
          <Popover.Content align="start">
            <Popover.Body>
              <div className="p-2">
                <Stack direction="column">
                  <RatingResume
                    generalScores={generalScores}
                    isPopover={true}
                  />

                  <Button
                    variant="ghost"
                    size="xs"
                    onClick={handleScrollToReview}
                  >
                    Ler {totalReviewsCount} Avaliações
                  </Button>
                </Stack>
              </div>
            </Popover.Body>
          </Popover.Content>
        )}
      </Popover.Root>
    </>
  )
}

export default ReviewPopover
