import PropTypes from 'prop-types'
import LazyLoad from 'react-lazyload'

import WishListButton from '../WishListButton'
import { OfferContent } from './'
import * as S from './styled.js'

const IMAGE_SIZE = 280

const ProductImage = ({
  shortName,
  inWishList,
  tag,
  picture,
  url,
  offer,
  assistedSale,
  id,
  price,
  brand,
  category,
  name,
  onHeartClick,
  featureInstallmentDiscount
}) => {
  const isTypeOffer = Boolean(offer?.type === 'offer')
  const isTypeSale = Boolean(offer?.type === 'sale')
  const isTypeLowestPriceOfferStyles = Boolean(offer?.type === 'lowest-price')
  const isOutlet = Boolean(tag?.type === 'outlet')

  return (
    <S.ProductThumbNailContent>
      <S.ProductThumbNailMeta>
        {!assistedSale && (
          <WishListButton
            isActive={inWishList}
            product={{
              id,
              shortName,
              price,
              name,
              category,
              brand,
              discountPercentage: offer?.value || 0
            }}
            onClick={onHeartClick}
          />
        )}

        {tag && <S.ProductBadge tagType={tag.type}>{tag.label}</S.ProductBadge>}
      </S.ProductThumbNailMeta>

      <a href={url}>
        <LazyLoad height={IMAGE_SIZE} offset={200} once>
          <S.ProductThumbNailImage
            alt={shortName}
            title={shortName}
            itemProp="image"
            src={picture}
            width={IMAGE_SIZE}
            height={IMAGE_SIZE}
          />
        </LazyLoad>

        <OfferContent
          offer={offer}
          isOutlet={isOutlet}
          isTypeSale={isTypeSale}
          isTypeOffer={isTypeOffer}
          isTypeLowestPriceOfferStyles={isTypeLowestPriceOfferStyles}
          featureInstallmentDiscount={featureInstallmentDiscount}
        />
      </a>
      <meta itemProp="image" content={picture} />
      <meta itemProp="brand" content={brand} />
    </S.ProductThumbNailContent>
  )
}

ProductImage.propTypes = {
  inWishList: PropTypes.bool.isRequired,
  tag: PropTypes.shape({
    type: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
  }),
  picture: PropTypes.string.isRequired,
  url: PropTypes.string,
  offer: PropTypes.shape({
    type: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.number
  }),
  assistedSale: PropTypes.object,
  shortName: PropTypes.string
}

export default ProductImage
