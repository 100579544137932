import { Fragment } from 'react'
import {
  Stack,
  Card,
  Text,
  Icon,
  Inline
} from '@leroy-merlin-br/backyard-react'
import { LocationOutline, TruckDeliveryOutline } from '@backyard-ui/icons'

import {
  workingHoursText,
  workingDaysText
} from 'catalog/utils/shipments-dictionary'

import { SeparatingEdge } from '../'
import { formatZipCode } from '../../utils'
import * as S from './styled'

const shippingTypeNames = {
  standard: 'Entrega econômica',
  scheduled: 'Entrega agendada',
  express: 'Entrega rápida',
  pickupInStore: 'Retirada gratuita'
}

const ReceiptInfoCard = ({
  shippingInformation: {
    shippingType = '',
    deadline = '',
    storeName = '',
    completeAddress = {}
  }
}) => {
  const {
    street = '',
    number = '',
    cep = '',
    district = '',
    city = ''
  } = completeAddress

  const { defaultImagePath } = window.env
  const isPickupInStore = shippingType === 'pickupInStore'

  const mainAddress = `${street}, ${number}`
  const extraAddress = `${district}, ${city} - CEP ${formatZipCode(cep)}`
  const mergedAddress = `${mainAddress} - ${extraAddress}`

  const receivingLocation = {
    firstLine: isPickupInStore ? `Loja ${storeName}` : mainAddress,
    secondLine: isPickupInStore ? mergedAddress : extraAddress
  }

  const deadlineMessage = () => {
    let message = { prefix: '', main: '' }

    if (isPickupInStore) {
      message = workingHoursText(deadline)
    } else {
      message = workingDaysText(deadline)
    }

    return `${message.prefix} ${message.main}`
  }

  return (
    <Card target="card" shadow="triple">
      <Stack space="giga">
        <Fragment>
          <Text color="black" size="mega" isBold>
            Informações de entrega
          </Text>

          <SeparatingEdge />
        </Fragment>

        <Fragment>
          <S.AddressWrapper>
            <Inline space="byte" alignY="center" wrap="nowrap">
              <Icon as={LocationOutline} color="primary" size="mega" />
              <Text color="black" size="mega" isBold noMargin>
                {receivingLocation.firstLine}
              </Text>
            </Inline>
            <Text color="black" size="kilo" noMargin>
              {receivingLocation.secondLine}
            </Text>
          </S.AddressWrapper>
          <S.AddressWrapper>
            <Inline space="byte" alignY="center" wrap="nowrap">
              <Fragment>
                {isPickupInStore ? (
                  <img
                    src={`${defaultImagePath}/icons/store.svg`}
                    alt="Logo da loja Leroy Merlin"
                  />
                ) : (
                  <Icon as={TruckDeliveryOutline} color="primary" size="mega" />
                )}
              </Fragment>
              <Text color="black" size="mega" isBold noMargin>
                {shippingTypeNames[shippingType]}
              </Text>
            </Inline>
            <Text as="span" color="black" size="kilo" noMargin>
              {deadlineMessage()}
            </Text>
            <Text as="span" color="n500" size="kilo" noMargin>
              {' '}
              após a confirmação do pagamento
            </Text>
          </S.AddressWrapper>
        </Fragment>
      </Stack>
    </Card>
  )
}

export default ReceiptInfoCard
