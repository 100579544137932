import { css } from '@emotion/react'
import styled from '@emotion/styled'

const cardWrapperStyles = ({ theme }) => css`
  label: services__card;

  background-color: ${theme.colors.p100};
  border-radius: ${theme.borderRadius.giga};

  padding: ${theme.spacings.kilo} 0;

  ${theme.media.mega} {
    padding: ${theme.spacings.kilo} 0;
  }

  & > div {
    padding: 0 ${theme.spacings.mega};
  }
`

const imageWrapper = () => css`
  label: services__image-wrapper;

  display: flex;
  width: 48px;
  height: 48px;

  img {
    width: 100%;
    height: 100%;
  }
`

export const CardWrapper = styled('div')(cardWrapperStyles)
export const ImageWrapper = styled('div')(imageWrapper)
