import PropTypes from 'prop-types'

import {
  DeliveryAddressNotFound,
  InaccessiblePlace,
  CancelledAfterDeliveryAddressNotFound,
  CancelledAfterInaccessiblePlace,
  CancelledAfterResponsibleAbsent,
  ResponsibleAbsent
} from './components/index'

const DELIVERY_ATTEMPT_CONTENT = {
  inaccessible_place: <InaccessiblePlace />,
  delivery_address_not_found: <DeliveryAddressNotFound />,
  responsible_absent: <ResponsibleAbsent />,
  canceled_after_inaccessible_place: <CancelledAfterInaccessiblePlace />,
  canceled_after_responsible_absent: <CancelledAfterResponsibleAbsent />,
  canceled_after_delivery_address_not_found: (
    <CancelledAfterDeliveryAddressNotFound />
  )
}

const DeliveryAttemptBuilder = ({ slug = '' }) => {
  const Message = () => DELIVERY_ATTEMPT_CONTENT[slug] || null

  return <Message />
}

DeliveryAttemptBuilder.propTypes = {
  slug: PropTypes.string
}

export default DeliveryAttemptBuilder
