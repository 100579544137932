import $ from 'jquery'

const getInputValues = (element) => {
  const inputs = $(element).find(':input')
    .not('button, :checkbox:not(:checked), :radio:not(:checked)')

  const inputsData = Array.from(inputs)
    .reduce((total, input) => {
      return { ...total, [input.name]: input.value }
    }, {})

  return inputsData
}

export default getInputValues
