import { dataLayer } from 'newCheckout/dataLayer'
import { AddToCart } from 'newCheckout/dataLayer/addToCart'
import { RemoveFromCart } from 'newCheckout/dataLayer/removeFromCart'
import { ChangeCartType } from 'newCheckout/dataLayer/changeCartType'

export const addToCart = (dataLayerInfo: AddToCart) => {
  dataLayer.addToCart(dataLayerInfo)
}

export const removeFromCart = (dataLayerInfo: RemoveFromCart) => {
  dataLayer.removeFromCart(dataLayerInfo)
}

export const changeCartType = (dataLayerInfo: ChangeCartType) => {
  dataLayer.changeCartType(dataLayerInfo)
}
