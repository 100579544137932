import { tv } from 'tailwind-variants'

const styles = tv({
  base: `
    flex
    justify-between
    items-center
    px-4
    h-14
    bg-green-700
    rounded-t-xl
  `
})

export default styles
