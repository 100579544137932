export const numberFilters = (urlFull: string): number => {
  let numberFilters = 0
  const numberSegments = urlFull.split('/')

  if (
    urlFull.includes('?') &&
    !urlFull.includes('&') &&
    urlFull.includes('page')
  ) {
    numberFilters = 1
    return numberFilters
  }

  if (urlFull.includes('?')) {
    numberFilters = 2
  }

  if (urlFull.includes('&')) {
    numberFilters = 3
  }

  if (numberFilters !== 2 && numberFilters !== 3) {
    if (numberSegments.length >= 5) {
      numberFilters = 1
    }
  }
  return numberFilters
}
