import { ModalProvider } from '@leroy-merlin-br/backyard-react'

import reactComponentLoader from 'scripts/react-component-loader'

import { PriceTag } from './pages/Product/components/PriceTag'
import { ServicesBadge } from './pages/Product/components/ServicesBadge'
import { Buybox } from './pages/Product/containers/Buybox'
import Kazaplan from './pages/Kazaplan'
import { PostalCode } from './pages/Product/components/PostalCode'
import { FooterAd } from './pages/Family/components/Ads'
import { ComplementaryProducts } from './pages/Product/containers/ComplementaryProducts'

const PostalCodeWithModalProvider = props => (
  <ModalProvider>
    <PostalCode {...props} />
  </ModalProvider>
)

reactComponentLoader(PriceTag, '[data-product-price-tag]')
reactComponentLoader(ServicesBadge, '[data-product-available-service]')
reactComponentLoader(Buybox, '[data-product-buybox]')
reactComponentLoader(PostalCodeWithModalProvider, '[data-postal-code]')
reactComponentLoader(FooterAd, '[data-family-gpt]')
reactComponentLoader(Kazaplan, '[data-kazaplan]')
reactComponentLoader(ComplementaryProducts, '[data-complementary-products]')
