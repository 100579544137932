import { useState } from 'react'

import { ModularProvider } from '../contexts/ModularContext'

const ModularProviderMethods = (props) => {
  const [state] = useState({
    isBlackWeekend: props.isBlackWeekend
  })

  return (
    <ModularProvider value={state}>
      {props.children}
    </ModularProvider>
  )
}

export default ModularProviderMethods
