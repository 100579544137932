import { Flex, Switch, Text, Card } from '@backyard-ui/core'
import { useToggleRefinement } from 'react-instantsearch'

import { onFilterDataLayer } from 'scripts/react-components/entry-key/analytics/data-layer'

interface SwitchesProps {
  /**
   *
   */
  attribute: string

  /**
   *
   */
  index: number

  /**
   *
   */
  label: string
}

export function Switches(props: SwitchesProps) {
  const { attribute, index, label } = props

  const { canRefine, value, refine } = useToggleRefinement({ attribute })

  if (!canRefine) {
    return null
  }

  const handleRefine = (checked: boolean) => {
    refine({
      isRefined: !checked
    })

    onFilterDataLayer({
      searchParam: attribute,
      filterElement: {
        index: 0,
        value: value.name
      },
      filterType: {
        index,
        value: label
      }
    })
  }

  return (
    <Card.Root>
      <Card.Body>
        <Flex align="center" justify="space-between">
          <Text weight="bold" asChild>
            <span>{label}</span>
          </Text>
          <Switch isChecked={value.isRefined} onCheckedChange={handleRefine} />
        </Flex>
      </Card.Body>
    </Card.Root>
  )
}
