import { AxiosResponse } from 'axios'
import { FC, useState } from 'react'

import { useAccessDataContext } from 'scripts/react-components/user/my-account/context/my-account-access-data-context'
import { OtpCodeParams, SendOTPVerifyParams, sendOTPCode, sendOTPVerify } from 'scripts/react-components/user/my-account/services'
import { ShowToastParams, showToast } from 'scripts/react-components/user/my-account/utils/toast'
import { isServerError } from 'scripts/react-components/user/utils'

import SecurityCode from '../../../../../components/SecurityCode/SecurityCode'
import { Layout } from '../../Layout'

type VerifySecurityCodeProps = {
  onNextStep: () => void
}

const VerifySecurityCode: FC<VerifySecurityCodeProps> = ({ onNextStep }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [fieldState, setfieldState] = useState('')

  const { contextData, updateData } = useAccessDataContext()

  const { type, contact } = contextData.optionToRecovery

  const onHandleValidation = (fieldState: string) => {
    setfieldState(fieldState)
  }

  const onSendCodeToVerify = async (code: string) => {
    setIsLoading(true)

    const sendOTPVerifyParams: SendOTPVerifyParams = {
      fiscalId: contextData.fiscalId,
      code: code,
      context: 'reset_password'
    }

    const showToastParams: ShowToastParams = {
      type: 'critical',
      title: 'Não foi possível validar o código.',
      content: 'Tente novamente mais tarde.'
    }

    try {
      const { data } = await sendOTPVerify(sendOTPVerifyParams)

      switch (data.status) {
        case 'Valid':
          updateData({ ...contextData, code })
          onHandleValidation('valid')
          onNextStep()
          break
        case 'InvalidCode':
          showToastParams.title = data.message
          showToastParams.content = 'Insira o código correto para prosseguir.'
          showToast(showToastParams)
          onHandleValidation('invalid')
          break
        case 'ExpiredCode':
          showToastParams.title = data.message
          showToastParams.content = 'Clique em reenviar código para gerar um novo.'
          showToast(showToastParams)
          onHandleValidation('invalid')
          break
        default:
          showToast(showToastParams)
          break
      }
    } catch (error) {
      const { status } = error as AxiosResponse

      if (status === 429) {
        showToastParams.title = 'Você atingiu o limite de tentativas!'
      }
      showToast(showToastParams)
    } finally {
      setIsLoading(false)
    }
  }

  const onResendCodeLInk = async () => {
    setIsLoading(true)

    const showToastParams: ShowToastParams = {
      type: 'critical',
      title: 'Não foi possível reenviar o código.',
      content: 'Tente novamente mais tarde.'
    }

    const otpParams: OtpCodeParams = {
      fiscalId: contextData.fiscalId,
      hash: contextData.optionToRecovery.hash,
      type: contextData.optionToRecovery.type,
      context: 'reset_password'
    }

    try {
      await sendOTPCode(otpParams)

      showToastParams.type = 'primary'
      showToastParams.title = 'Código reenviado!'
      showToastParams.content = 'Insira o novo código.'
      showToast(showToastParams)
      onHandleValidation('')
    } catch (error) {
      const { status } = error as AxiosResponse

      const hasServerError = status && isServerError(status)

      if (hasServerError) {
        showToastParams.title = 'Não foi possível reenviar o código.'
      } else if (status === 429) {
        showToastParams.title = 'Você atingiu o limite de tentativas!'
      }

      showToast(showToastParams)
    } finally {
      setIsLoading(false)
    }
  }

  const getContactDescription = (): string => {
    const descriptions: Record<string, string> = {
      email: 'e-mail',
      phone: 'telefone'
    }

    return `Para alterar sua senha, digite o código <br />
        de 6 dígitos recebido em <strong>seu ${descriptions[type] ?? 'contato'} </strong>
        <br />
        ${contact}`
  }

  return (
    <Layout>
      <SecurityCode
        contactDescription={getContactDescription()}
        onInputComplete={onSendCodeToVerify}
        onResendLink={onResendCodeLInk}
        fieldState={fieldState}
        isLoading={isLoading}
      />
    </Layout>
  )
}

export default VerifySecurityCode
