import { Stack } from '@leroy-merlin-br/backyard-react'

import { Shell } from 'shared/components/Shell'

import * as S from '../styled'

const ListSkeleton = () => (
  <S.ListItem>
    <S.Circle>
      <Shell height="100%" borderRadius="tera" />
    </S.Circle>

    <Stack space="bit">
      <Shell width={60} height={15} borderRadius="mega" />
      <Shell width={100} height={15} borderRadius="mega" />
    </Stack>

    <Shell width={60} height={20} borderRadius="mega" />
  </S.ListItem>
)
export default ListSkeleton
