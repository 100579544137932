import { css } from '@emotion/react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

const baseStyles = ({ theme }) => css`
  label: spacing;

  padding: 0 ${theme.spacings.mega};

  ${theme.media.mega} {
    padding: 0;
  }
`

const spacingStyles = ({ theme, placement }) => {
  const margins = {
    top: css`
      label: spacing--top;

      margin-top: ${theme.spacings.mega};
    `,
    bottom: css`
      label: spacing--bottom;

      margin-bottom: ${theme.spacings.mega};
    `
  }

  return margins[placement]
}

const Spacing = styled('div')(baseStyles, spacingStyles)

Spacing.displayName = 'Spacing'

Spacing.propTypes = {
  placement: PropTypes.oneOf(['top', 'bottom'])
}

Spacing.defaultProps = {
  placement: 'bottom'
}

export default Spacing
