const { R2U } = window

const SDK_PARAMS = {
  customerId: '5dc1b0a7a1154000086eadfa',
  fallbackOptions: {
    alertMessage:
      'O recurso de Realidade Aumentada não é compatível com o dispositivo ',
    fallback: 'viewer'
  }
}

function AugmentedRealityFactory (sku, { customerId } = SDK_PARAMS) {
  async function serviceIsAvailable () {
    const clientWasInitialized = await initializeR2UService()
    const have3DModel = await skuIsActive()

    const isAvailable = clientWasInitialized && have3DModel

    return isAvailable
  }

  async function initializeR2UService () {
    try {
      await R2U.init({ customerId })
      return true
    } catch (err) {
      throw new Error(err)
    }
  }

  /**
   * O tratamento da promise via try/catch é necessário
   * devido à falta de tratamento no método do sdk
   * em caso de rejeição da promise, quebrando a execução
   */
  async function skuIsActive () {
    try {
      const isActive = await R2U.sku.isActive(sku)

      return Boolean(isActive)
    } catch (err) {
      return false
    }
  }

  async function openAR () {
    const url = await getLink()

    window.ReactNativeWebView?.postMessage(
      JSON.stringify({
        type: 'openBrowserInApp',
        url
      })
    )

    window.ReactNativeWebView?.postMessage(
      JSON.stringify({
        type: 'openBrowserInAppV2',
        data: {
          url
        }
      })
    )
  }

  async function getLink () {
    const link = await R2U.ar.getLink(sku)

    return link
  }

  function attachEvent (element) {
    try {
      R2U.ar.attach({
        element,
        sku,
        showInstructions: true,
        fallbackOptions: {
          fallback: 'full'
        }
      })
    } catch (err) {
      throw new Error(err)
    }
  }

  return {
    serviceIsAvailable,
    openAR,
    attachEvent
  }
}

export default AugmentedRealityFactory
