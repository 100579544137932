import { ServiceType } from './type'
import Visual from './Visual'
import VisualStandaloneService from './VisualStandaloneService'

export default function Services ({ services }: ServiceType) {
  return (
    <Visual>
      {services
        .filter(service => !service?.relatedProduct?.id)
        .map(standaloneService => {
          return (
            <VisualStandaloneService
              key={standaloneService.id}
              standaloneService={standaloneService}
            />
          )
        })}
    </Visual>
  )
}
