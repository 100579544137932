import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { Error500 } from 'shared/page/Error500'

import { Actions as UserProfileActions } from 'lmcv/redux/modules/userProfile'
import { Actions as LoyaltyProfileActions } from 'lmcv/redux/modules/loyaltyProfile'
import { Actions as NotificationActions } from 'lmcv/redux/modules/notification'
import { AuthLayout } from 'lmcv/containers/AuthLayout'
import { PrivateRoute, GuestRoute, NoMatch, getRoutes } from 'lmcv/routes'
import { AuthLayoutShell } from 'lmcv/components'

const App = ({ onLoyaltyProgram, loyaltyProRelationship }) => {
  localStorage.setItem('@loyalty:token', onLoyaltyProgram)
  localStorage.setItem('@loyalty:proRelationship', loyaltyProRelationship)

  const dispatch = useDispatch()
  const { isRequest: isRequestUser } = useSelector(
    (state) => state.userProfile.get
  )
  const { payload: loyaltyProfile, isRequest: isRequestLoyalty } = useSelector(
    (state) => state.loyaltyProfile.get
  )

  useEffect(() => {
    dispatch(UserProfileActions.getProfile())
    dispatch(LoyaltyProfileActions.get())
  }, [dispatch])

  useEffect(() => {
    if (!isRequestLoyalty) {
      dispatch(NotificationActions.get())
    }
  }, [dispatch, isRequestLoyalty])

  if (isRequestUser || isRequestLoyalty) {
    return <AuthLayoutShell />
  }

  if (!loyaltyProfile) {
    return <Error500 />
  }

  const routes = getRoutes({
    cashbackEnabled: loyaltyProfile?.hasArchitectCashback
  })

  return (
    <BrowserRouter basename="/fidelidade">
      <AuthLayout>
        <Switch>
          {routes.map(({ isPrivate, ...props }) =>
            isPrivate ? <PrivateRoute {...props} /> : <GuestRoute {...props} />
          )}
          <Route component={NoMatch} />
        </Switch>
      </AuthLayout>
    </BrowserRouter>
  )
}

App.propTypes = {
  props: PropTypes.shape({
    lmcvDashboard: PropTypes.string,
    onLoyaltyProgram: PropTypes.string.isRequired
  })
}

export default App
