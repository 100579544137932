import Base from './Base'

class Div extends Base {
  render () {
    return (
      <div {...this.getElementProps(this.props)} onClick={this.onClick}>
        {this.props.children}
      </div>
    )
  }
}

export default Div
