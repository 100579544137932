type Colors = 'tertiary'

type ItemProps = {
  name: string
  value: string
  colorName?: Colors
}

const colors: { [key in Colors]: string } = {
  tertiary: 'text-tertiary-600'
}

export const Item = ({ name, value, colorName }: ItemProps) => (
  <div className="flex flex-row justify-between mb-4">
    <div className="text-sm text-neutral-900">{name}</div>
    <div className={`text-xl font-semibold ${colorName ? colors[colorName] : ''}`}>{value}</div>
  </div>
)
