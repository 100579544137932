import { Shell } from 'shared/components/Shell'

import * as S from './styled'

const Skeleton = () => {
  return (
    <S.Container>
      <Shell width={728} height={90} />
    </S.Container>
  )
}

export default Skeleton
