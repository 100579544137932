import { handleErrorsMessage } from 'user/utils'

const FORMS = [
  {
    name: 'complement',
    fields: [
      'maritalStatus',
      'birthData.country',
      'birthData.state',
      'birthData.city',
      'motherName',
      'document.type',
      'document.number',
      'document.emissionDate'
    ]
  },
  {
    name: 'address',
    fields: [
      'address.postalCode',
      'address.district',
      'address.complement',
      'address.city',
      'address.state',
      'address.street',
      'address.streetNumber'
    ]
  },
  {
    name: 'income',
    fields: ['occupation.income', 'equityValue', 'occupation.cap']
  },
  {
    name: 'invoice',
    fields: ['dueDay', 'optins.emergencyCredit', 'optins.purchaseInsurance']
  }
]

export const parseApiErrors = (errorsArray = []) => {
  const errorsObject = {
    complement: [],
    address: [],
    income: [],
    invoice: []
  }

  for (const { code, message, messages } of errorsArray) {
    const foundForm = FORMS.find(({ fields }) => fields.includes(code))

    if (!foundForm) {
      continue
    }

    errorsObject[foundForm.name].push({
      code,
      message: handleErrorsMessage(message, messages)
    })
  }

  return errorsObject
}
