/**
 * Converts a @method $.serializeArray into an object.
 * @param  {array}  array An array with {name, value} items
 * @return {object}       The converted object
 */
export default (array) => array.reduce((object, field) => {
  object[field.name] = field.value

  return object
}, {})
