import cookies from 'js-cookie'

const MOBILE_SCREEN_SIZE = 768

export default () => {
  const contextList = []

  if (cookies.get('is_on_mobile_app')) {
    contextList.push('app')
  } else {
    contextList.push('web')
  }

  if (cookies.get('assisted_sale')) {
    contextList.push('assistedSale')
  }

  if (window.innerWidth < MOBILE_SCREEN_SIZE) {
    contextList.push('responsive')
  }

  return contextList
}
