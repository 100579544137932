import $ from 'jquery'

import scroll from 'garden/src/js/utils/scroll'

import emitter from 'scripts/utils/emitter'
import confirm from 'scripts/utils/account/confirm'
import tokenNotify from 'scripts/utils/account/token-notifier'
import BaseComponent from 'scripts/components/base-component'
import { showSpinner, hideSpinner } from 'scripts/utils/spinner'

class CreatePassword extends BaseComponent {
  static componentName = 'create-password'

  static DOMEvents = {
    submit: 'onFormSubmit',
    'click [data-loyalty-optin]': 'onOptinLoyalty',
    'click [data-loyalty-pro-optin]': 'onOptinLoyalty'
  }

  init () {
    this.$feedbackWarning = $('[data-feedback-warning]')
    this.$feedbackSuccess = $('[data-feedback-success]')
    this.$loyaltyOptin = $('[data-loyalty-optin]')
    this.$loyaltyProOptin = $('[data-loyalty-pro-optin]')
    this.$loyaltyForm = $('[data-loyalty-form]')
    this.$loyaltyProForm = $('[data-loyalty-pro-form]')
    this.$loyaltyInputs = $('[data-loyalty-inputs]')
    this.$loyaltyProInputs = $('[data-loyalty-pro-inputs]')
    this.$loyaltyBirthdate = $('[data-loyalty-birthdate]')
    this.scroll = scroll
  }

  onOptinLoyalty (event) {
    const optin = $(event.currentTarget)

    if (optin.attr('name') === 'loyalty_program_pro') {
      return this.toggleFormLoyalty(optin.is(':checked'), this.$loyaltyProInputs, this.$loyaltyProForm)
    }

    return this.toggleFormLoyalty(optin.is(':checked'), this.$loyaltyInputs, this.$loyaltyForm)
  }

  toggleFormLoyalty (optin, inputs, form) {
    Array.prototype.forEach.call(
      inputs,
      (field) => emitter.emit('validation:pristine', field)
    )

    if (optin) {
      inputs
        .attr('data-required', true)
        .attr('data-validate', 'required')

      return form.slideDown()
    }

    confirm(this.callbackConfirm.bind(this), this.renderModalConfirm(), {
      textConfirmButton: 'Quero participar',
      textCancelButton: 'Não gosto de descontos'
    })
  }

  getFormValidation () {
    if (!this.validation) {
      this.validation = this.$element.validation().data('validation')
    }
  }

  onFormSubmit (event) {
    event.preventDefault()
    this.getFormValidation()

    if (!this.validation.validateAll()) {
      return this.scrollTop()
    }

    showSpinner()
    return this.submitForm()
  }

  submitForm () {
    const action = this.$element.attr('action')
    const data = this.$element.serialize()

    $.post(action, data, 'json')
      .done(this.onSubmitSuccess.bind(this))
      .fail(this.onSubmitWarning.bind(this))
      .always(this.scrollTop())
  }

  onSubmitSuccess (response) {
    if (response.status !== 'success') {
      return console.error(response)
    }

    tokenNotify()

    if (response.needDocumentation) {
      hideSpinner()
      return this.toggleDocumentSend()
    }

    if (response.redirect) {
      location.href = response.redirect
    }
  }

  onSubmitWarning (response) {
    hideSpinner()

    let errors

    if (response.responseJSON && response.responseJSON.errors) {
      errors = response.responseJSON.errors
    } else {
      errors = {
        error: ['Por favor, tente novamente mais tarde.']
      }
    }

    const $response = this.$feedbackWarning.find('.response')

    $response.html('')

    $.each(errors, (key, val) => {
      val.map(message => $response.append(`${message}<br>`))
    })

    this.showFeedbackWarning()
  }

  onValidateWarning (input) {
    this.toggleMessage(input, 'warning')
  }

  onValidateSuccess (input) {
    if (this.$loyaltyBirthdate.is(input)) {
      this.onSuccessBirthDate()
    }

    this.toggleMessage(input, 'success')
  }

  toggleMessage (input, type) {
    const $input = $(input)
    const $field = $input.closest('.field')
    const target = $input.data('validate-target')
    const message = $input.data(`validate-${type}`)

    if (type === 'warning') {
      $field.removeClass('valid').addClass('invalid')
    } else if (type === 'success') {
      $field.removeClass('invalid').addClass('valid')
    }

    $(target).html(message)
  }

  showFeedbackWarning () {
    this.hideFeedbackSuccess()
    this.$feedbackWarning.removeClass('hidden')
  }

  hideFeedbackWarning () {
    this.$feedbackWarning.addClass('hidden')
  }

  showFeedbackSuccess () {
    this.hideFields()
    this.hideFeedbackWarning()
  }

  hideFeedbackSuccess () {
    this.$feedbackSuccess.addClass('hidden')
  }

  renderModalConfirm () {
    const modalConfirm = `<div>
      <div class="loyalty-modal">
        <h3>Tem certeza que não quer participar do programa fidelidade?</h3>
        <p>Você desmarcou a opção para participar do programa fidelidade.
        Sem essa confirmação voce perde a chance de juntar pontos e receber vantagens como cupons de desconto exclusivos…</p>
      </div>
    </div>
    `
    return modalConfirm
  }

  callbackConfirm (answer) {
    if (answer) {
      return this.$loyaltyOptin.prop('checked', true)
    }

    this.$loyaltyOptin.prop('checked', false)

    this.$loyaltyInputs
      .removeAttr('data-required')
      .removeAttr('data-validate')

    Array.prototype.forEach.call(
      this.$loyaltyInputs,
      (field) => {
        const isRadio = $(field).attr('type') === 'radio'
        isRadio ? $(field).prop('checked', false) : $(field).val('')
      }
    )

    return this.$loyaltyForm.slideUp()
  }

  hideFields () {
    this.$element.find('[data-hide-on-success]').addClass('hidden')
  }

  scrollTop () {
    this.scroll($('body'), { offset: -120 })
  }

  redirectLoyalty () {
    location.href = '/fidelidade'
  }

  toggleDocumentSend () {
    emitter.emit('loyalty-pro:open', {
      occupation: document.querySelector('[data-loyalty-pro-occupation]').value,
      onFinish: this.redirectLoyalty,
      onCancel: this.redirectLoyalty
    })
  }
}

export default ($el) => new CreatePassword($el).init()

export { CreatePassword as Component }
