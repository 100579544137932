import request from 'scripts/api/product'

export const STORE_MAP_BASE_URL = 'https://smartmap.leroymerlin.com.br/api/v1/stores'

export const getStoreMap = async (storeCode, section) => {
  const response = await request
    .get(`${STORE_MAP_BASE_URL}/${storeCode}/last_map/${parseInt(section)}`)
    .then(({ data }) => data)
    .catch(error => {
      throw error.response
    })

  return response
}
