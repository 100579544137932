import PropTypes from 'prop-types'
import { createContext } from 'react'

const EntryKeyContext = createContext(null)

const EntryKeyContextProvider = ({ children, value }) => {
  return (
    <EntryKeyContext.Provider value={value}>
      {children}
    </EntryKeyContext.Provider>
  )
}

export { EntryKeyContext, EntryKeyContextProvider }

const productPropTypes = {
  id: PropTypes.number.isRequired,
  brand: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  categoryTree: PropTypes.string.isRequired,
  unit: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  shortName: PropTypes.string.isRequired,
  picture: PropTypes.string.isRequired,
  inWishList: PropTypes.bool.isRequired,
  isFreeShipping: PropTypes.bool.isRequired,
  isOutlet: PropTypes.bool.isRequired,
  isSoldOut: PropTypes.bool.isRequired,
  installments: PropTypes.shape({
    amount: PropTypes.number,
    value: PropTypes.string
  }),
  offer: PropTypes.shape({
    type: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired
  }),
  price: PropTypes.shape({
    to: PropTypes.shape({
      integers: PropTypes.string.isRequired,
      decimals: PropTypes.string.isRequired
    }).isRequired,
    from: PropTypes.shape({
      integers: PropTypes.string,
      decimals: PropTypes.string
    }),
    regionPrice: PropTypes.shape({
      integers: PropTypes.string,
      decimals: PropTypes.string
    })
  }),
  tag: PropTypes.shape({
    label: PropTypes.string,
    type: PropTypes.string
  }),
  soldBy: PropTypes.oneOf(['1p', '3p', 'hybrid'])
}

const filtersPropTypes = {
  name: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  description: PropTypes.string,
  filterKey: PropTypes.string.isRequired,
  hasExclusiveUrl: PropTypes.bool,
  highlight: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      quantity: PropTypes.number.isRequired,
      query: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired
    })
  )
}

EntryKeyContext.propTypes = {
  children: PropTypes.element.isRequired,
  value: PropTypes.shape({
    isLoadingProducts: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isInitialRender: PropTypes.bool.isRequired,
    isBlackWeekend: PropTypes.bool.isRequired,
    isMappedSearch: PropTypes.bool.isRequired,
    isMobileFilterActive: PropTypes.bool.isRequired,
    isSearch: PropTypes.bool.isRequired,
    hasError: PropTypes.bool.isRequired,
    hasHighlightedFilters: PropTypes.bool,
    searchType: PropTypes.oneOf(['strict', 'fuzzy']),
    type: PropTypes.oneOf([
      'entryKey',
      'search',
      'mappedSearch',
      'imageSearch'
    ]),
    products: PropTypes.arrayOf(productPropTypes),
    filters: PropTypes.arrayOf(filtersPropTypes),
    sorters: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        slug: PropTypes.string.isRequired
      })
    ),
    pageInfo: PropTypes.shape({
      name: PropTypes.string.isRequired,
      description: PropTypes.string,
      descriptiveText: PropTypes.string,
      filterTitle: PropTypes.string,
      contents: PropTypes.array,
      video: PropTypes.string
    }),
    metadata: PropTypes.shape({
      page: PropTypes.number,
      pageCount: PropTypes.number,
      perPage: PropTypes.number,
      totalCount: PropTypes.number
    }),
    metaTags: PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      image: PropTypes.string
    }),
    query: PropTypes.shape({
      filter: PropTypes.object,
      order: PropTypes.string,
      page: PropTypes.number
    }),
    personalizationTrack: PropTypes.shape({
      apiKey: PropTypes.string,
      baseUrl: PropTypes.string,
      categoryId: PropTypes.shape({
        $oid: PropTypes.string
      })
    }),
    setPage: PropTypes.func,
    scrollToTop: PropTypes.func,
    cleanFilters: PropTypes.func,
    filterByStock: PropTypes.func,
    filterUsedOptions: PropTypes.array
  }),
  url: PropTypes.string
}
