import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { Theme } from 'scripts/react-components/themes/Theme'

const securityCodeStyles = ({ theme }: Theme) => css`
  background-color: white;

  display: grid;
  justify-content: center;

  padding: ${theme.spacings.tera} 0 500px;

  ${theme.media.mega} {
    padding: ${theme.spacings.tera} 0 ${theme.spacings.tera};
  }
`

export const SecurityCodeWraper = styled('div')(securityCodeStyles)

const securityCodePreviusStyles = ({ theme }: Theme) => css`
  p {
    color: ${theme.colors.p600};
    font-weight: 400;
    text-align: center;

    cursor: pointer;
  }
`

export const securityCodePrevius = styled('div')(securityCodePreviusStyles)

const securityCodeCardStyles = ({ theme }: Theme) => css`

    width: 378px;

    h1 {
      margin-bottom: 16px;
    }

    h2 {
      margin-bottom: 24px;
      font-size: 18px;
    }

    margin-bottom: 32px;

`

export const SecurityCodeCard = styled('div')(securityCodeCardStyles)
