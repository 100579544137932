import { useCallback, useContext, useEffect } from 'react'
import { Card, Stack, SimpleGrid, Spacer } from '@backyard-ui/core'
import { useHits, useInstantSearch, usePagination } from 'react-instantsearch'

import { setInsightsInfo } from 'scripts/react-components/entry-key/utils/analytics'

import { NewProductThumb } from 'shared/components/NewProductThumb'
import { Pagination } from 'shared/components/Pagination'

import { WishlistContext } from 'site/Wishlist/context'

import {
  onProductClickDataLayer,
  onProductImpressionDataLayer
} from './Content.tracker'
import { Loading } from './loading'
import { useRating } from './queries/use-rating'
import { getItemCategory, normalize } from './Content.utils'
import { IS_CATEGORY } from '../../utils/constants'
import { IS_ON_MOBILE_APP } from '../../constants'

type WishlistContextType = {
  isOnWishlist: (id: string) => boolean
}
interface ContentProps {
  region: string
}

function Content ({ region }: ContentProps) {
  const { nbPages, currentRefinement, refine } = usePagination()
  const { status, indexUiState } = useInstantSearch()
  const { isOnWishlist } = useContext(WishlistContext) as WishlistContextType

  const transformItems = useCallback(
    (items: any[]) =>
      items.map(item => ({
        ...item,
        ...normalize(item, region, IS_CATEGORY)
      })),
    [region]
  )

  const { hits, results, sendEvent } = useHits({
    escapeHTML: false,
    transformItems
  })

  const { data: rating, isLoading: isLoadingRating } = useRating({ hits })

  const isRefetching = status === 'loading' || status === 'stalled'

  const setPage = (page: number) => {
    refine(page - 1)
  }

  const onProductClick = (product: any, index: number) => {
    setInsightsInfo(product, region)

    sendEvent('click', product, 'productListClick')

    onProductClickDataLayer({
      index,
      region,
      product,
      page: currentRefinement + 1,
      term: indexUiState.query ?? '',
      inWishlist: isOnWishlist(product.id)
    })
  }

  useEffect(() => {
    window.scrollTo({
      top: 0
    })
  }, [currentRefinement])

  useEffect(() => {
    if (hits.length) {
      onProductImpressionDataLayer({
        region,
        products: hits,
        term: indexUiState.query ?? '',
        page: results?.page ? results?.page + 1 : 1
      })
    }
  }, [hits, indexUiState.query, region, results?.page])

  if (isRefetching) {
    return <Loading />
  }

  return (
    <Stack align="center" direction="column">
      <Card.Root>
        <Card.Body>
          <SimpleGrid
            columns={{
              initial: 2,
              sm: 3,
              md: 4
            }}
          >
            {hits.map((hit, index) => (
              <NewProductThumb
                partnerName="algolia"
                key={hit.id}
                onClick={() => onProductClick(hit, index)}
                product={hit}
                reviewRating={rating.find(
                  item => item.productLm === Number(hit.id)
                )}
                fullWidth
                isLoadingReview={isLoadingRating}
                index={index}
                itemList={`entry-key-${indexUiState.query}`}
                itemCategory={
                  hit?.categoryTree?.length
                    ? getItemCategory(hit.categoryTree)
                    : ''
                }
              />
            ))}
          </SimpleGrid>
        </Card.Body>
      </Card.Root>

      {nbPages > 1 && (
        <Pagination
          currentPage={currentRefinement + 1}
          totalPages={nbPages}
          setPage={setPage}
          pageCount={nbPages}
          page={currentRefinement + 1}
        />
      )}

      {IS_ON_MOBILE_APP && (
        <Spacer
          axis="vertical"
          size="10"
          data-testid="mobile-context-bottom-spacer"
        />
      )}
    </Stack>
  )
}

export default Content
