import { useModal, Icon, Text } from '@leroy-merlin-br/backyard-react'
import { ChevronRight } from '@backyard-ui/icons'

import DiscountsModal from '../DiscountsModal'

import * as S from './styled'

type TotalDiscountsByType = {
  stampedPrice: number
  promo: number
  employee: number
  'payment-method': number
  pix: number
  bank_slip: number
  credit_card: number
}

type Props = {
  discount: number
  totalDiscountsByType: TotalDiscountsByType
  paymentMethods?: string
  firstCheapestPaymentMethod?: string
  isPaymentPage?: boolean
}

const DiscountsInfoOpenButton = ({
  discount,
  totalDiscountsByType,
  paymentMethods,
  firstCheapestPaymentMethod,
  isPaymentPage = false
}: Props) => {
  const { setModal } = useModal()

  const lengthOfOffers = (): number => {
    const DISCOUNTS_FOR_CASH_PAYMENT_METHOD = ['pix', 'bank_slip', 'credit_card']
    let count = 0
    if (totalDiscountsByType) {
      Object.keys(totalDiscountsByType).forEach(discount => {
        if (DISCOUNTS_FOR_CASH_PAYMENT_METHOD.every(type => type !== discount)) {
          count++
        }
      })
    }
    return count
  }

  const renderDiscountsModal = () => {
    setModal({
      size: 'kilo',
      onClose: () => {},
      children: ({ onClose }) => (
        <DiscountsModal
          onClose={onClose}
          discount={discount}
          totalDiscountsByType={totalDiscountsByType}
          paymentMethods={paymentMethods}
          firstCheapestPaymentMethod={firstCheapestPaymentMethod}
          isPaymentPage={isPaymentPage}
        />
      )
    })
  }

  return (
    <S.DiscountButton
      data-testid="discount-info-icon"
      data-cy="discounts-modal-trigger"
      onClick={() => renderDiscountsModal()}
    >
      <Text size="mega" as="span" color="n600" noMargin>
        {lengthOfOffers()} aplicados
      </Text>
      <Icon as={ChevronRight} size="byte" color="info" />
    </S.DiscountButton>
  )
}

export default DiscountsInfoOpenButton
