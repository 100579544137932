import { useContext } from 'react'
import { CloseButton } from '@leroy-merlin-br/backyard-react'

import { EntryKeyContext } from '../../../../context/EntryKeyContext'
import * as S from './styled'

const Header = () => {
  const { toggleMobileFilter } = useContext(EntryKeyContext)

  return (
    <S.Header>
      <S.Title>Filtros</S.Title>
      <CloseButton onClick={toggleMobileFilter} />
    </S.Header>
  )
}

export default Header
