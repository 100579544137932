import { css } from '@emotion/react'
import styled from '@emotion/styled'

const baseStyles = ({ theme }) => css`
  label: breadcrumb;

  max-width: 88%;
  width: 100%;
  overflow: hidden;

  ${theme.media.mega} {
    max-width: 100%;
  }
`

export const Breadcrumb = styled('div')(baseStyles)
