import { Container, Text } from '@leroy-merlin-br/backyard-react'

import { Facets } from '../Facets'
import { CleanFiltersButton } from '../CleanFiltersButton'
import { Space } from '../Space'
import * as S from './styled'

const FacetsBar = () => (
  <S.Wrapper>
    <Container>
      <Space>
        <S.Content>
          <Text size="mega" as="span" noMargin>
            Listar Produtos Com
          </Text>

          <Facets />

          <CleanFiltersButton variant="ghost" />
        </S.Content>
      </Space>
    </Container>
  </S.Wrapper>
)

export default FacetsBar
