import styled from '@emotion/styled'
import { css } from '@emotion/react'

const wrapperMenuListBaseStyles = ({ theme }) => css`
  label: wrapper-menu-list;

  font-size: ${theme.typography.text.mega.fontSize};
  margin-bottom: ${theme.spacings.giga};
`

export const WrapperMenuList = styled('li')(wrapperMenuListBaseStyles)
