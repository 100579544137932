import { Constants } from './'

export const setCoupon = data => ({
  type: Constants.COUPONS_SET,
  data
})

export const applyCoupon = code => ({
  type: Constants.COUPONS_APPLY,
  code
})

export const applyCouponSuccess = data => ({
  type: Constants.COUPONS_APPLY_SUCCESS,
  data
})

export const applyCouponFailure = status => ({
  type: Constants.COUPONS_APPLY_FAILURE,
  status
})

export const removeCoupon = code => ({
  type: Constants.COUPONS_REMOVE,
  code
})

export const removeCouponSuccess = data => ({
  type: Constants.COUPONS_REMOVE_SUCCESS,
  data
})

export const removeCouponFailure = status => ({
  type: Constants.COUPONS_REMOVE_FAILURE,
  status
})
