import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { Theme } from 'scripts/react-components/themes/Theme'

import { InputStyledProps } from './SecurityCodeInput'

const securityCodeStyles = ({ theme }: Theme) => css`
  label: security-code;

  display: flex;

  gap: ${theme.spacings.mega};
`

export const SecurityCode = styled('div')(securityCodeStyles)

const securityCodeFieldStyles = ({ inputStyledProps }: { inputStyledProps: InputStyledProps }) =>
  css`
  label: security-code__field;

  input {
    text-align: center;
  }

  width: ${inputStyledProps?.width ?? 40}px;

  ${inputStyledProps?.height ? 'height: ' + inputStyledProps?.height + 'px;' : ''}
`

export const SecurityCodeField = styled('div')(securityCodeFieldStyles)
