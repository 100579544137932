import { Constants } from '.'

export const updateSummary = () => ({
  type: Constants.UPDATE_SUMMARY
})

export const updateSummarySuccess = data => ({
  type: Constants.UPDATE_SUMMARY_SUCCESS,
  data
})

export const updateSummaryFailure = status => ({
  type: Constants.COUPONS_REMOVE_FAILURE,
  status
})
