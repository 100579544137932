import type { DataLayerItemObject, ServiceObject } from './types'

export const getOnlyProducts = (
  items: DataLayerItemObject[],
  services: ServiceObject[]
) => {
  if (!services || !services.length) {
    return items
  }

  return items.filter((item) =>
    services.some((service) => item.productId !== service.serviceId)
  )
}

export const getItemsSubtotal = (items: DataLayerItemObject[]) => {
  return items.reduce((accumulated, item) => {
    const itemTotal = item.price * item.productQuantity
    return accumulated + itemTotal
  }, 0)
}
