import { SimpleGrid, Card, Skeleton } from '@backyard-ui/core'

export function Loading () {
  return (
    <Card.Root>
      <Card.Body>
        <SimpleGrid
          columns={{
            initial: 2,
            md: 4
          }}
        >
          <Skeleton width="100%" height="358px" />
          <Skeleton width="100%" height="358px" />
          <Skeleton width="100%" height="358px" />
          <Skeleton width="100%" height="358px" />
          <Skeleton width="100%" height="358px" />
          <Skeleton width="100%" height="358px" />
          <Skeleton width="100%" height="358px" />
          <Skeleton width="100%" height="358px" />
        </SimpleGrid>
      </Card.Body>
    </Card.Root>
  )
}
