import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { useLoyaltyProfile, useUserResources } from 'shared/hooks'

import { NavItem } from './components/NavItem'
import { type SidebarMenu, menuList } from './constants'
import { SidebarShell } from './components/SidebarShell'
import * as S from './styled'
import { Avatar } from '../Avatar'

const Sidebar = () => {
  const [activePathname, setActivePathname] = useState<string | null>(null)

  const {
    userControls: { isLoyaltyElegible },
    isLoadingResources,
    userResources
  } = useUserResources()

  const {
    loyaltyProfile,
    loyaltyStatus,
    loyaltyPoints,
    isLoading: isLoadingLoyaltyProfile
  } = useLoyaltyProfile()

  const history = useHistory()

  const isOnLoyalty = loyaltyStatus?.isActive()

  const cashbackEnabled = loyaltyProfile?.hasArchitectCashback

  const avaliableMenu: SidebarMenu[] = menuList({
    loyaltyStatus,
    isLoyaltyElegible,
    cashbackEnabled
  })

  const updateActivePathName = () => {
    const { pathname } = location

    const endsWithSlash = pathname.endsWith('/')
    const newPathName = endsWithSlash ? pathname.slice(0, -1) : pathname

    setActivePathname(newPathName)
  }

  useEffect(() => {
    if (history) {
      history.listen(() => {
        updateActivePathName()
      })
    }

    updateActivePathName()
  }, [history])

  const shouldShowShell =
    (isLoyaltyElegible && !loyaltyProfile) ||
    isLoadingResources ||
    isLoadingLoyaltyProfile

  if (shouldShowShell) {
    return (
      <S.SidebarShellWrapper>
        <SidebarShell />
      </S.SidebarShellWrapper>
    )
  }

  return (
    <S.Wrapper>
      <S.Header>
        <Avatar isSmall />

        <S.UserDetails isLoyaltyElegible={isLoyaltyElegible}>
          <span>{userResources?.name}</span>

          {isOnLoyalty ? (
            <S.UserPoints>
              {loyaltyPoints?.formatted} <span>pts</span>
            </S.UserPoints>
          ) : (
            isLoyaltyElegible && (
              <S.UserPoints>
                <span>0 pts</span>
              </S.UserPoints>
            )
          )}
        </S.UserDetails>
      </S.Header>

      <S.Nav>
        <S.NavTitle>
          Minha Conta
        </S.NavTitle>
        {avaliableMenu
          .filter((menu) => menu.isActive)
          .map((menu) => (
            <NavItem
              key={menu.id}
              menu={menu}
              activePathname={activePathname}
            />
          ))}
      </S.Nav>
    </S.Wrapper>
  )
}

export default Sidebar
