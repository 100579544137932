import { useState, useEffect, useCallback, useMemo } from 'react'

import emitter from 'scripts/utils/emitter'

import { pushDataLayer } from 'catalog/utils/shipments-data-layer'
import { cheapestModalityOption } from 'catalog/utils/cheapest-modality-option'
import { SHIPMENTS_ERROR_STATUS } from 'catalog/utils/constants'

export const useShipmentsDataLayer = (
  postalCode,
  region,
  shipments,
  errorStatus,
  skus
) => {
  const [selectedSeller, setSelectedSeller] = useState(skus[0])
  const [cheapest, setCheapest] = useState()
  const [sellersAlreadyPushed, setSellersAlreadyPushed] = useState({})

  const isPostalCodeValid = useMemo(() => postalCode.length > 8, [postalCode])

  const hasErrorMessage = useMemo(
    () => errorStatus && errorStatus !== SHIPMENTS_ERROR_STATUS.differentRegion,
    [errorStatus]
  )

  const hasFetchedShipments = useMemo(
    () => (shipments.length || hasErrorMessage) && isPostalCodeValid,
    [shipments, hasErrorMessage, isPostalCodeValid]
  )

  const sellerHasAlreadyBeenPushed = useMemo(
    () => sellersAlreadyPushed[selectedSeller.id],
    [sellersAlreadyPushed, selectedSeller.id]
  )

  const shouldPushDataLayer = useMemo(
    () =>
      hasFetchedShipments &&
      !sellerHasAlreadyBeenPushed &&
      region &&
      cheapest,
    [hasFetchedShipments, sellerHasAlreadyBeenPushed, cheapest, region]
  )

  useEffect(() => {
    if (!cheapest && hasFetchedShipments) {
      updateCheapest(selectedSeller)
    }
  }, [cheapest, hasFetchedShipments, updateCheapest, selectedSeller])

  useEffect(() => {
    emitter.on('buybox:sellerChange', data => {
      const seller = JSON.parse(data.selectedSeller)
      setSelectedSeller(seller)

      if (hasFetchedShipments) {
        updateCheapest(seller)
      }
    })
  }, [selectedSeller, updateCheapest, hasFetchedShipments])

  useEffect(() => {
    if (isPostalCodeValid) {
      setSellersAlreadyPushed({})
      setCheapest()
    }
  }, [isPostalCodeValid])

  const updateCheapest = useCallback(
    newSelectedSeller => {
      const skuShipments = shipments.find(seller => {
        const skuId = seller.skuId || ''
        return skuId === newSelectedSeller.id
      })

      const cheapestShipment = cheapestModalityOption(skuShipments?.modalities)

      setCheapest({
        sellerId: newSelectedSeller.id,
        shipment: cheapestShipment
      })
    },
    [shipments]
  )

  const usePushDataLayer = useCallback(() => {
    if (shouldPushDataLayer) {
      pushDataLayer(postalCode, region, cheapest.shipment, selectedSeller)
      setSellersAlreadyPushed(prevState => ({
        ...prevState,
        [selectedSeller.id]: true
      }))
    }
  }, [shouldPushDataLayer, postalCode, region, cheapest, selectedSeller])

  return usePushDataLayer
}
