import { Container } from '@backyard-ui/core'
import { Button, Heading, Stack, Text } from '@leroy-merlin-br/backyard-react'
import { FC } from 'react'
import { InputLoading, Popover } from 'user/components'
import {
  BIRTHDATE_POPOVER,
  EMAIL_POPOVER,
  FAVORITE_STORE_POPOVER,
  PHONE_POPOVER
} from 'user/constants'
import { LegalText } from 'user/shared/components'

import { NumberField, SelectField, TextField } from 'shared/components'

import * as S from './styled'
import { FORM_RULES, useSocialSignUp } from './useSocialSignUp'
import { View } from '../../types'
import { OptionalLabel } from '../../../../shared/components/OptionalLabel'

const SocialSignUp: FC<View> = (props) => {
  const {
    checkingFiscalId,
    checkFiscalIdOnChange,
    control,
    email,
    formState,
    handleBirthdateFormatBlur,
    handlePhoneFormat,
    handlePhoneFormatBlur,
    isContinue,
    isSubmitting,
    onSubmit,
    stores
  } = useSocialSignUp(props)

  return (
    <S.Wrapper center={props.center}>
      <Container size="lg">
        <S.Title>
          <Heading noMargin as="h1" color="p800" size="giga">
            Seja bem-vindo a Leroy Merlin
          </Heading>
        </S.Title>

        <Text size="mega">
          Para concluir o seu cadastro, precisamos de mais algumas informações.
        </Text>

        <form onSubmit={onSubmit}>
          <Stack space="giga">
            <S.Field>
              <InputLoading isLoading={checkingFiscalId}>
                <NumberField
                  name="fiscalId"
                  label="CPF"
                  rules={{ required: 'Insira seu CPF' }}
                  control={control}
                  state={Boolean(formState.errors.fiscalId) && 'invalid'}
                  hint={
                    <S.ErrorWithLinkWrapper
                      dangerouslySetInnerHTML={{
                        __html: formState.errors.fiscalId?.message
                      }}
                    />
                  }
                  format="###.###.###-##"
                  placeholder="Digite seu CPF"
                  onChange={checkFiscalIdOnChange}
                  disabled={checkingFiscalId || isSubmitting}
                />
              </InputLoading>
            </S.Field>

            <S.AdditionalFieldsWrapper isVisible={isContinue}>
              <Stack space="giga">
                <S.Field>
                  <TextField
                    disabled={isSubmitting}
                    name="name"
                    label="Nome completo"
                    rules={FORM_RULES.name}
                    control={control}
                    state={Boolean(formState.errors.name) && 'invalid'}
                    hint={formState.errors.name?.message}
                    placeholder="Digite seu nome completo"
                  />
                </S.Field>

                <S.Field>
                  <TextField
                    disabled
                    name="email"
                    label={
                      <Popover list={EMAIL_POPOVER.list}>
                        {EMAIL_POPOVER.label}
                      </Popover>
                    }
                    control={control}
                    value={email}
                  />
                </S.Field>

                <S.Row>
                  <S.Col>
                    <S.Field>
                      <NumberField
                        disabled={isSubmitting}
                        name="birthdate"
                        label={
                          <Popover list={BIRTHDATE_POPOVER.list}>
                            <OptionalLabel>
                              {BIRTHDATE_POPOVER.label}
                            </OptionalLabel>
                          </Popover>
                        }
                        rules={FORM_RULES.birthdate}
                        control={control}
                        state={Boolean(formState.errors.birthdate) && 'invalid'}
                        hint={formState.errors.birthdate?.message}
                        format="##/##/####"
                        valueType="formatted"
                        placeholder="Digite a data de nascimento"
                        onBlur={handleBirthdateFormatBlur}
                      />
                    </S.Field>
                  </S.Col>

                  <S.Col>
                    <S.Field>
                      <NumberField
                        disabled={isSubmitting}
                        name="phone"
                        label={
                          <Popover list={PHONE_POPOVER.list}>
                            {PHONE_POPOVER.label}
                          </Popover>
                        }
                        rules={FORM_RULES.phone}
                        control={control}
                        state={Boolean(formState.errors.phone) && 'invalid'}
                        hint={formState.errors.phone?.message}
                        format={handlePhoneFormat}
                        onBlur={handlePhoneFormatBlur}
                        placeholder="Digite seu número de celular"
                      />
                    </S.Field>
                  </S.Col>
                </S.Row>

                <S.Field>
                  <SelectField
                    disabled={isSubmitting}
                    name="favoriteStore"
                    label={
                      <Popover list={FAVORITE_STORE_POPOVER.list}>
                        {FAVORITE_STORE_POPOVER.label}
                      </Popover>
                    }
                    options={stores}
                    rules={{
                      required: 'Escolha sua loja favorita'
                    }}
                    control={control}
                    state={Boolean(formState.errors.favoriteStore) && 'invalid'}
                    hint={formState.errors.favoriteStore?.message}
                    placeholder="Selecione sua loja favorita"
                  />
                </S.Field>

                <LegalText />

                <S.Button>
                  <Button
                    isLoading={isSubmitting}
                    isStretch
                    disabled={!formState.isValid}
                    type="submit"
                  >
                    Concordar e criar conta
                  </Button>
                </S.Button>
              </Stack>
            </S.AdditionalFieldsWrapper>
          </Stack>
        </form>
      </Container>
    </S.Wrapper>
  )
}

export default SocialSignUp
