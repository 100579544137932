import styled from '@emotion/styled'
import { css } from '@emotion/react'

const descriptionWrapperStyles = ({ theme }) => css`
  label: description-wrapper;

  padding-left: ${theme.spacings.giga};
`

export const DescriptionWrapper = styled('div')(descriptionWrapperStyles)
