import { getCartInfoUrl } from 'frontendCheckout/apis/algonomy'
import { QueryParams, CartItem } from 'frontendCheckout/apis/algonomy/types'

export const sendCartInfo = async (cart: CartItem[]) => {
  const envs = window?.env?.algonomy

  if (!envs?.enabled || !JSON.parse(envs.enabled)) {
    return
  }

  const params: QueryParams = {
    placements: 'cart_page',
    productId:
      (cart?.length && cart.map((item) => item.productId).join('|')) || ''
  }

  try {
    const { response } = await getCartInfoUrl(params)

    const { status, value: result } = await response

    // Armazena o valor rcs no cache
    if (status === 200 && result?.rcs) {
      localStorage.setItem('rcs', result.rcs)
    }

    return response
  } catch (error) {
    console.error('Error sending cart info to Algonomy:', error)
    throw error
  }
}
