export type SupplierStatus =
  | 'PENDING_REGISTRATION'
  | 'WAITING_DOCUMENT_ANALYSIS'
  | 'DOCUMENT_UNAPPROVED'
  | 'DOCUMENT_APPROVED'
  | 'UNKNOWN'

export const supplierStatusMap = new Map<number, SupplierStatus>([
  [-10, 'DOCUMENT_UNAPPROVED'],
  [-11, 'DOCUMENT_UNAPPROVED'],
  [-12, 'WAITING_DOCUMENT_ANALYSIS'],
  [-14, 'WAITING_DOCUMENT_ANALYSIS'],
  [-15, 'WAITING_DOCUMENT_ANALYSIS'],
  [-16, 'WAITING_DOCUMENT_ANALYSIS'],
  [-17, 'WAITING_DOCUMENT_ANALYSIS'],
  [-18, 'WAITING_DOCUMENT_ANALYSIS'],
  [-2, 'DOCUMENT_UNAPPROVED'],
  [-20, 'WAITING_DOCUMENT_ANALYSIS'],
  [-30, 'WAITING_DOCUMENT_ANALYSIS'],
  [-5, 'WAITING_DOCUMENT_ANALYSIS'],
  [-7, 'WAITING_DOCUMENT_ANALYSIS'],
  [-8, 'WAITING_DOCUMENT_ANALYSIS'],
  [-9, 'WAITING_DOCUMENT_ANALYSIS'],
  [1, 'WAITING_DOCUMENT_ANALYSIS'],
  [2, 'WAITING_DOCUMENT_ANALYSIS'],
  [3, 'DOCUMENT_APPROVED'],
  [4, 'DOCUMENT_UNAPPROVED']
])

export const supplierStatusGetFirstCodeByStatus = (
  status: SupplierStatus
): number | undefined => {
  for (const [key, value] of Array.from(supplierStatusMap.entries())) {
    if (value === status) {
      return key
    }
  }

  return undefined
}

export const supplierStatusGetAllCodesByStatus = (
  status: SupplierStatus
): number[] => {
  const keys: number[] = []

  for (const [key, value] of Array.from(supplierStatusMap.entries())) {
    if (value === status) {
      keys.push(key)
    }
  }

  return keys
}
