import { renderTemplate } from './render-template'

const FeedbackError = ({ errors = [] }) => {
  const allErrors = Array.isArray(errors) ? errors : Object.values(errors)

  return (
    <ul>
      {allErrors.map((error, index) => (
        <li key={`item_${index}`}>{error}</li>
      ))}
    </ul>
  )
}

const render = (props = {}) => renderTemplate(<FeedbackError {...props} />)

export default render
