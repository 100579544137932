import BaseComponent from 'scripts/components/base-component'

class HybrisUserName extends BaseComponent {
  static componentName = 'hybris-user-name'

  static emitterEvents = {
    'user-resources:done': 'onResourcesDone'
  }

  onResourcesDone ({ name }) {
    if (name) {
      this.name = this.formatUserName(name)
      this.setUserName()
    }
  }

  formatUserName (name) {
    const formattedName = name.toLowerCase()

    return formattedName.charAt(0).toUpperCase() + formattedName.slice(1)
  }

  setUserName () {
    setTimeout(() => {
      this.$element.text(`Olá, ${this.name}`)
    }, 500)
  }
}

export default ($el) => new HybrisUserName($el)

export { HybrisUserName as Component }
