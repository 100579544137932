import $ from 'jquery'

import Collapse from 'garden/src/js/components/collapse'

const DEFAULTS = {
  isFacetCollapse: false,

  openedFacetCollapse: {
    maxHeight: 260,
    overflowY: 'scroll'
  },

  closedFacetCollapse: {
    maxHeight: 130,
    overflowY: 'hidden'
  }
}

class LiveCollapse extends Collapse {
  constructor (element, options, window = window) {
    super(element, options)

    $(window).on('resize', this._onWindowResize.bind(this))
    this.options = $.extend({}, this.options, DEFAULTS, options)

    this.registerComponent()
  }

  setInitialState () {
    super.setInitialState()

    setTimeout(() => {
      this.setHidden()
    }, this.options.timing)
  }

  setHidden () {
    const currentHeight = parseInt(this.$toggle.css('max-height'), 10) || 0
    const isHidden = (this.isCollapsed && currentHeight >= this.toggleHeight)

    this.$element.toggleClass('hidden', isHidden)
  }

  showTarget () {
    if (this.options.isFacetCollapse) {
      this.setScroolableCollapse(this.options.openedFacetCollapse)
    }

    super.showTarget()
  }

  hideTarget () {
    if (this.options.isFacetCollapse) {
      this.setScroolableCollapse(this.options.closedFacetCollapse)
    }

    super.hideTarget()
  }

  setScroolableCollapse (styles) {
    const { maxHeight, overflowY } = styles

    this.toggleHeight = maxHeight

    if (this.toggle.scrollHeight > maxHeight) {
      this.toggle.style.overflowY = overflowY
    }
  }

  _onWindowResize () {
    this.setInitialState()
  }

  registerComponent () {
    this.$element.data('live-collapse', this)
  }
}

export { LiveCollapse as Component }
export default ($el) => new LiveCollapse($el, $el.data()).init()
