const deliveryLimitHours = () => {
  const date = new Date()
  const currentDay = date.toLocaleString('en-us', { weekday: 'long' })
  const currentHour = date.getHours()
  const isDeliveryOnSaturday = currentDay === 'Saturday' && currentHour < 11
  const isDeliveryOnSunday = currentDay === 'Sunday'

  const deliveryOptions = {
    today: {
      limitHour: '12h',
      deliveryForecast: 'hoje'
    },
    tomorrow: {
      limitHour: '23h59h',
      deliveryForecast: 'amanhã'
    },
    saturday: {
      limitHour: '11h',
      deliveryForecast: 'hoje'
    }
  }

  if (isDeliveryOnSaturday) {
    return deliveryOptions.saturday
  }

  if (isDeliveryOnSunday) {
    return deliveryOptions.tomorrow
  }

  return currentHour < 12 ? deliveryOptions.today : deliveryOptions.tomorrow
}

export default deliveryLimitHours
