import { QuickSearch } from 'search/containers'

import { LocationInfo } from 'site/Location'

import { HeaderLogo } from '../../common/HeaderLogo'
import { Cart } from '../../../Cart'
import { MyAccount } from '../../../MyAccount'
import HeartIcon from '../../../Wishlist'
import DesktopMenu from '../../DesktopMenu'

const RenderDesktop = ({
  isAssistedSale,
  debounceTimer,
  minSearchTermLimit,
  isQuickSearchApiV3Enabled
}) => (
  <div className="new-header-wrapper wrapper-content">
    <div className="header-topbar"></div>
    <div className="logo-wrapper">
      <HeaderLogo />
    </div>
    <div className="new-header-content-wrapper">
      <div className="telesales-info">
        <LocationInfo />
        <span className="color-neutral">
          <span className="color-primary">Compre pelo telefone: </span>{' '}
          4007-1380 (Capitais) - 0800-602-1380 (Demais regiões).
        </span>

        <a
          className="color-primary"
          href="https://www.leroymerlin.com.br/modular/dicasdeseguranca"
        >
          Dicas de segurança
        </a>

        <span className="color-neutral">
          Dúvidas?{' '}
          <a
            className="color-primary"
            href="https://www.leroymerlin.com.br/fale-conosco"
          >
            Clique aqui
          </a>
        </span>
      </div>
      <div className="new-header-navigation">
        <div className="new-search-wrapper">
          <QuickSearch
            debounceTimer={debounceTimer}
            minSearchTermLimit={minSearchTermLimit}
            isQuickSearchApiV3Enabled={isQuickSearchApiV3Enabled}
          />
        </div>
        <div className="new-navigation-tools">
          <Cart />
          {!isAssistedSale && <HeartIcon />}
          <MyAccount />
        </div>
      </div>
      <div className="new-header-menu" data-desktop-menu>
        <nav className="menu">
          <DesktopMenu />
        </nav>
      </div>
    </div>
  </div>
)

export default RenderDesktop
