import { Fragment } from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'

import reactComponentLoader from 'scripts/react-component-loader'

import MyWishlists from 'site/Wishlist/containers/MyWishlists'
import Wishlist from 'site/Wishlist/containers/Wishlist'

const WishlistApp = () => (
  <Router basename="/minhas-listas-de-desejos">
    <Fragment>
      <Route exact path="/" component={MyWishlists} />
      <Route exact path="/:slug" component={Wishlist} />
    </Fragment>
  </Router>
)

export default WishlistApp
