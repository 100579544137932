import mask from 'utils/maskingUtils'

import { removeNonNumericCharacters } from '../utils'
import { AppProps } from './entry'
import { HomeProps } from './pages/Home/Home'

export const maskCEP = (value: string) => mask.maskedText('cep', removeNonNumericCharacters(value))

export const parsedItemsNewMyAccount = (value: AppProps['itemsMyAccount']) => {
  return JSON.parse(
    value as unknown as string
  ) as HomeProps['itemsMyAccount']
}
