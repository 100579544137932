import {
  UserOutline,
  ArchiveOutline,
  BellCogOutline,
  HeartOutline,
  LmcvLogo,
  FileOutline
} from '@backyard-ui/icons'

import { type LoyaltyStatus } from 'scripts/react-components/shared/hooks'

export type MenuListProps = {
  loyaltyStatus?: LoyaltyStatus
  isLoyaltyElegible?: boolean
  cashbackEnabled?: boolean
}

export type SidebarSubMenu = {
  id: string,
  name: string,
  link: string
}

export type SidebarMenu = {
  id: string;
  name: string;
  link: string;
  icon: (props: React.SVGProps<SVGSVGElement>, ref: React.Ref<SVGSVGElement>) => JSX.Element;
  isActive: boolean;
  submenu: SidebarSubMenu[]
}

const loyaltyMenu = (loyaltyStatus: LoyaltyStatus | null = null, cashbackEnabled = false): SidebarSubMenu[] => {
  if (!loyaltyStatus || !loyaltyStatus.isActive()) {
    return []
  }

  const links: SidebarSubMenu[] = [
    {
      id: 'coupons',
      name: 'Vantagens',
      link: '/fidelidade/minhas-vantagens'
    },
    {
      id: 'exchange',
      name: 'Troque',
      link: '/fidelidade/troque'
    },
    {
      id: 'score',
      name: 'Meus pontos',
      link: '/fidelidade/meus-pontos'
    },
    {
      id: 'faq',
      name: 'Dúvidas',
      link: '/fidelidade/duvidas'
    }
  ]

  if (cashbackEnabled) {
    links.push({
      id: 'cashback',
      name: 'Cashback',
      link: '/fidelidade/cashback'
    })
  }

  if (loyaltyStatus.isProEAD()) {
    links.splice(2, 0, {
      id: 'customers',
      name: 'Clientes',
      link: '/fidelidade/clientes'
    })
  }

  return links
}

export const menuList = ({ loyaltyStatus, isLoyaltyElegible, cashbackEnabled }: MenuListProps): SidebarMenu[] => {
  const items = [
    {
      id: 'resume',
      name: 'Resumo',
      link: '',
      icon: UserOutline,
      isActive: false,
      submenu: []
    },
    {
      id: 'account',
      name: 'Meu Perfil',
      link: '/minha-conta',
      icon: FileOutline,
      isActive: true,
      submenu: []
    },
    {
      id: 'orders',
      name: 'Meus Pedidos',
      link: '/meus-pedidos',
      icon: ArchiveOutline,
      isActive: true,
      submenu: []
    },
    {
      id: 'notifications',
      name: 'Notificações',
      link: '',
      icon: BellCogOutline,
      isActive: false,
      submenu: []
    },
    {
      id: 'wishes',
      name: 'Favoritos',
      link: '/minhas-listas-de-desejos',
      icon: HeartOutline,
      isActive: true,
      submenu: [] as SidebarSubMenu[]
    }
  ]

  if (isLoyaltyElegible) {
    const loyaltyItem: SidebarMenu = {
      id: 'loyalty',
      name: 'Fidelidade',
      link: '/fidelidade',
      icon: LmcvLogo,
      submenu: loyaltyMenu(loyaltyStatus, cashbackEnabled),
      isActive: true
    }
    items.splice(3, 0, loyaltyItem)
  }

  return items
}
