import { type ReactNode } from 'react'
import {
  Configure,
  InstantSearch as InstantSearchProvider
} from 'react-instantsearch'
import search from 'algoliasearch/lite'

import { useUserResources } from 'shared/hooks/useUserResources'
import { useSearchParams } from 'shared/hooks/useSearchParams'

import { insightsConfig } from './lib/algolia'
import { entry, entryCategory } from './routes/proxy'
import { useAnalyticsTags } from './hooks/useAnalyticsTags'
import { IS_CATEGORY } from './utils/constants'
import { AlgoliaConfigProps, CategoryFiltersLabelProps } from './routes/routes'
import { algoliaStringFormatter } from './routes/proxy/category/utils/algoliaStringFormatter'

interface InstantSearchProps {
  children?: ReactNode
  isSearchResultPage?: boolean
  categoryPageId?: string
  algolia: AlgoliaConfigProps
}

const HITS_PER_PAGE = 36
const MAX_VALUES_PER_FACET = 1000
const STALLED_SEARCH_DELAY = 2000

export function InstantSearch (props: InstantSearchProps) {
  const { children, categoryPageId, algolia } = props

  const { userResources } = useUserResources()

  const query = useSearchParams()
  const analyticsTags = useAnalyticsTags()

  const searchClient = search(algolia.appId, algolia.apiKey)

  if (!userResources?.region) {
    return null
  }

  const defaultFilter = `regionalAttributes.${userResources.region}.promotionalPrice > 0 AND regionalAttributes.${userResources.region}.available=1`

  const categoryFilter = `${defaultFilter} AND ${categoryPageId}`

  const categoryFiltersLabel = Object.keys(algolia.facets || {}).reduce(
    (acc: CategoryFiltersLabelProps, item: string) => {
      const key = algoliaStringFormatter(item)
      acc[key] = item
      return acc
    },
    {}
  )

  const routing = entry(algolia.indexName)
  const routingCategory = entryCategory(
    algolia.indexName,
    userResources?.region,
    categoryFiltersLabel,
    algolia.facets
  )

  const customSearchClient: Pick<typeof searchClient, 'search'> = {
    ...searchClient,
    search (requests) {
      if (requests.every(({ params }) => !params?.query)) {
        return Promise.resolve({
          results: requests.map(() => ({
            hits: [],
            nbHits: 0,
            nbPages: 0,
            page: 0,
            processingTimeMS: 0,
            hitsPerPage: 0,
            exhaustiveNbHits: false,
            query: '',
            params: ''
          }))
        })
      }

      return searchClient.search(requests)
    }
  }

  return (
    <InstantSearchProvider
      insights={insightsConfig}
      indexName={algolia.indexName}
      initialUiState={{
        [algolia.indexName]: {
          query: query.term
        }
      }}
      routing={IS_CATEGORY ? routingCategory : routing}
      searchClient={IS_CATEGORY ? searchClient : customSearchClient}
      future={{
        preserveSharedStateOnUnmount: true
      }}
      stalledSearchDelay={STALLED_SEARCH_DELAY}
    >
      {userResources?.region && (
        <Configure
          facets={['*']}
          filters={categoryPageId ? categoryFilter : defaultFilter}
          hitsPerPage={HITS_PER_PAGE}
          analyticsTags={analyticsTags}
          maxValuesPerFacet={MAX_VALUES_PER_FACET}
          page={Math.max(Number(query.page) - 1, 0) || 0}
          analytics
          clickAnalytics
          facetingAfterDistinct
        />
      )}
      {children}
    </InstantSearchProvider>
  )
}
