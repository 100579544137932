import { Heading } from '@leroy-merlin-br/backyard-react'
import { GridBlocks, AlignLeft, PlayCircle } from '@backyard-ui/icons'

import * as S from './styled.js'

const icon = {
  article: props => <AlignLeft {...props} />,
  assistant: props => <GridBlocks {...props} />,
  image: props => <PlayCircle {...props} />,
  video: props => <PlayCircle {...props} />
}

const Content = ({ contents = [] }) => (
  <S.Wrapper>
    <S.TitleWrapper>
      <Heading as="h3" size="mega" noMargin>
        Conteúdo relacionado
      </Heading>
    </S.TitleWrapper>
    <ul>
      {contents.map((content, index) => {
        const ContentIcon = icon[content.type] || icon.article

        return (
          <li key={index}>
            <S.Link href={content.url}>
              <ContentIcon width={18} height={18} fill="currentColor" />
              <span dangerouslySetInnerHTML={{ __html: content.name }} />
            </S.Link>
          </li>
        )
      })}
    </ul>
  </S.Wrapper>
)

export default Content
