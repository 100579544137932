
import { Icon } from '@leroy-merlin-br/backyard-react'

import { TruckDelivery } from 'shared/icons/TruckDelivery'

import { deliveryLimitHours } from 'site/utils/deliveryLimitHours'

import { SwitchFilter } from '../../../'
import * as S from './styled'

const ExpressDeliveryFilter = ({ filter }) => {
  const freight = deliveryLimitHours()

  if (!filter) {
    return <></>
  }

  return (
    <div data-testid="express-delivery-filter">
      <SwitchFilter
        optionClick={{
          name: filter.filterKey,
          value: filter.options[0].query.replace(/\s/g, '_'),
          type: 'filter',
          dataLayerOptions: {
            filter: 'Normal',
            filterType: { index: 0, value: filter.name },
            filterElement: { index: 0, value: filter.options[0].label }
          }
        }}
      >
        <S.FilterLabel>
          Receba {freight.deliveryForecast}
          <Icon as={TruckDelivery} />
        </S.FilterLabel>
      </SwitchFilter>
    </div>
  )
}

export default ExpressDeliveryFilter
