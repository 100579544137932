import type { SVGAttributes } from 'react'
import { forwardRef } from 'react'

const CalendarIcon = forwardRef<SVGSVGElement, SVGAttributes<SVGSVGElement>>((props, ref) => (
  <svg ref={ref} viewBox="0 0 32 32" {...props}>
    <path d="M27 7H5a2 2 0 00-2 2v14a2 2 0 002 2h22a2 2 0 002-2V9a2 2 0 00-2-2zM5 23V9h22v14z" />
    <path d="M13.3 16.38A3 3 0 0014.5 14a3 3 0 00-6 0 3 3 0 001.2 2.38A4.5 4.5 0 007 20.5h1a3.5 3.5 0 017 0h1a4.5 4.5 0 00-2.7-4.12zM11.5 16a2 2 0 112-2 2 2 0 01-2 2zM18 12.5h7v1h-7zM18 15.5h7v1h-7z"/>
  </svg>
))

export default CalendarIcon
