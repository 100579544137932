import { Stack } from '@leroy-merlin-br/backyard-react'

import { AlertMessage } from 'shared/components/AlertMessage'

import { buildText } from './utils/message'
import * as S from './styled'

const UnavailableProductMessage = ({ isAssistedSale, hidePrice }) => {
  const { title, message } = buildText({ isAssistedSale, hidePrice })

  return (
    <Stack space="giga">
      <S.Title>{title}</S.Title>

      {message && (
        <AlertMessage type="warning">
          {message}
        </AlertMessage>
      )}
    </Stack>
  )
}

export default UnavailableProductMessage
