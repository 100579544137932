import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { Theme } from 'newCheckout/types/Theme'

const baseStyles = ({ theme, clicked }: Theme & { clicked: boolean }) => css`
  label: youtube-player;

  cursor: pointer;

  background: black;

  display: flex;
  align-items: center;

  position: relative;

  height: 375px;
  width: 100%;

  ${theme.media.giga} {
    height: 440px;
    max-width: 100%;
  }

  display: flex;
  justify-content: center;

  &::after,
  &::before {
    content: '';

    position: absolute;
    left: 50%;
    top: 50%;

    pointer-events: none;

    display: ${clicked ? 'none' : 'block'};
  }

  &::before {
    background-color: ${theme.colors.p500};
    border-radius: ${theme.borderRadius.circle};
    transform: translate(-50%, -50%);
    height: 64px;
    width: 64px;
  }

  &::after {
    border-bottom: 16px solid transparent;
    border-left: 28px solid ${theme.colors.white};
    border-top: 16px solid transparent;
    color: ${theme.colors.white};
    transform: translateY(-50%) translateX(-50%)
      translateX(${theme.spacings.bit});
  }
`
const thumbStyles = ({ theme }: Theme) => css`
  label: youtube-player__thumb;
  max-width: 375px;
  width: 100%;

  display: flex;
  justify-content: center;

  overflow: hidden;

  ${theme.media.kilo} {
    max-width: 100%;
  }

  & > img {
    max-width: 375px;
    width: 100%;

    ${theme.media.mega} {
      max-width: 100%;
    }
  }
`

export const Thumb = styled('div')(thumbStyles)
export const YoutubePlayer = styled('div')(baseStyles)
