import { instance } from 'scripts/react-components/api'

import { SaleOrders, SaleOrderDetailed } from './types'

export const getSaleOrder = async () => {
  const response = await instance
    .get<SaleOrders>('/api/v1/sale-order')
    .then(({ data }) => data.data)
    .catch(err => {
      throw err.response
    })

  return response
}

export const getSaleOrderDetailed = async ({ id }: { id: string }) => {
  const response = await instance
    .get<SaleOrderDetailed>(`/api/v1/sale-order/${id}`)
    .then(({ data }) => data.data)
    .catch(err => {
      throw err.response
    })

  return response
}

export const getSaleOrderPendingPayment = async () => {
  const response = await instance
    .get<SaleOrders>('/api/v1/sale-order/pending-payment-store-orders')
    .then(({ data }) => data.data)
    .catch(err => {
      throw err.response
    })

  return response
}
