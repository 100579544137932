import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { Theme } from 'scripts/react-components/themes/Theme'

import { Shell } from 'shared/components'

const wrapperStyles = () => css`
  label: preferences__wrapper;
`

export const Wrapper = styled('div')(wrapperStyles)

const subWrapperStyles = ({ theme }: Theme) => css`
  label: preferences__sub-wrapper;

  ${theme.media.mega} {
    border: 1px solid ${theme.colors.n100};
    border-radius: ${theme.borderRadius.giga};
    padding: ${theme.spacings.giga};
  }
`

export const SubWrapper = styled('div')(subWrapperStyles)

const titleWrapperStyles = ({ theme }: Theme) => css`
  label: preferences__title-wrapper;

  margin-bottom: ${theme.spacings.mega};
`

export const TitleWrapper = styled('div')(titleWrapperStyles)

const headingStyles = ({ theme }: Theme) => css`
  label: preferences__heading;

  margin-bottom: ${theme.spacings.mega};
  margin-top: ${theme.spacings.giga};
`

export const Heading = styled('div')(headingStyles)

const fieldStyles = ({ theme }: Theme) => css`
  label: preferences__field;

  margin-bottom: ${theme.spacings.giga};

  &:last-child {
    margin-bottom: 0;
  }
`

export const Field = styled('div')(fieldStyles)

const selectWrapperStyles = ({ theme }: Theme) => css`
  label: preferences__select-wrapper;

  width: 400px;
`

export const SelectWrapper = styled('div')(selectWrapperStyles)

const labelStyles = ({ theme }: Theme) => css`
  label: preferences__label;

  margin-bottom: ${theme.spacings.kilo};
`

export const Label = styled('div')(labelStyles)

const checkboxWrapperStyles = ({ theme }: Theme) => css`
  label: preferences__checkbox-wrapper;

  input, label:before, label svg {
    top: 15%;
  }

  label {
    font-weight:  ${theme.font.weight.regular};
  }

  ${theme.media.mega} {
    input, label:before, label svg {
      top: 25%;
    }
  }
`

export const CheckboxWrapper = styled('div')(checkboxWrapperStyles)

const radioWrapperStyles = ({ theme }: Theme) => css`
  label: preferences__radio-wrapper;

  display: flex;

  width: 140px;
  justify-content: space-between;
`

export const RadioWrapper = styled('div')(radioWrapperStyles)

const radioSquareWrapperStyles = ({ theme }: Theme) => css`
  label: preferences__radio-square-wrapper;

  margin-bottom: ${theme.spacings.giga};

  ul {
    display: flex;
    flex-wrap: wrap;
    gap: ${theme.spacings.giga};
  }

  ${theme.media.mega} {
    margin-bottom: ${theme.spacings.bit};
  }
`

export const RadioSquareWrapper = styled('div')(radioSquareWrapperStyles)

const itemStyles = ({
  loading,
  selected,
  theme
}: Theme & { loading: boolean, selected: boolean }) => css`
  label: preferences__item;

  align-items: center;
  border: 1px solid ${loading ? theme.colors.n200 : selected ? theme.colors.p700 : 'white'};
  border-radius: ${theme.borderRadius.mega};
  color: ${theme.colors.n200};
  color: ${loading ? theme.colors.n200 : selected ? theme.colors.p700 : theme.colors.n600};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 144px;
  justify-content: center;
  padding: ${theme.spacings.kilo};
  text-align: center;
  width: calc(50% - ${theme.spacings.giga}/2);
  box-shadow:  0px 3px 4px 0px rgba(0,0,0,0.10);
  transition: all .3s ease-out;

  svg {
    fill: ${loading ? theme.colors.n200 : selected ? theme.colors.p700 : theme.colors.n600};
    width: 48px;
  }

  ${theme.media.mega} {
    width: calc(25% - ${theme.spacings.giga});
  }
`

export const Item = styled('div')(itemStyles)

const customShellStyles = ({
  theme
}: Theme) => css`
  width: calc(50% - ${theme.spacings.giga}/2);
  height: 144px;

  ${theme.media.mega} {
    width: calc(25% - ${theme.spacings.giga});
  }
`
export const CustomShell = styled('div')(customShellStyles)

const buttonWrapperStyles = ({ theme }: Theme) => css`
  label: preferences__button-wrapper;

  text-align: right;
  width: 100%;

  button {
    width: 100%;

    ${theme.media.mega} {
      width: 285px;
    }
  }
`

export const ButtonWrapper = styled('div')(buttonWrapperStyles)
