import { css, keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import { styleHelpers } from '@leroy-merlin-br/backyard-react'

const checkListStyles = ({ theme }) => css`
  label: checklist;
  display: none;

  margin-top: ${theme.spacings.bit};
  margin-bottom: ${theme.spacings.kilo};

  ${styleHelpers.shadowDouble(theme)};

  width: 100%;

  padding: ${theme.spacings.mega};

  background: ${theme.colors.white};
  border-radius: ${theme.borderRadius.giga};

  animation: ${appearFromTop} 0.5s forwards;
`

const appearFromTop = keyframes`
    from {
      opacity: 0;
      transform: translateY(0);
    }
    to {
      opacity: 1;
      transform: translateY(14px);
    }
`

const visibleStyles = ({ isVisible }) =>
  isVisible &&
  css`
    label: checklist--visible;
    display: block;
    margin-top: 0;
  `

export const CheckListWrapper = styled('div')(checkListStyles, visibleStyles)
