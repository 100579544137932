import { css } from '@emotion/react'
import styled from '@emotion/styled'

const baseStyles = ({ theme }) => css`
  label: content;

  width: 100%;
  flex: 1 auto;
  padding: ${theme.spacings.zetta} 0;
`

export const Content = styled('div')(baseStyles)
