import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { Theme } from 'scripts/react-components/themes/Theme'

export const Wrapper = styled('div')(
  ({ theme }: Theme) => css`
    padding: ${theme.spacings.peta};
    background-color: ${theme.colors.white};
    display: flex;
    justify-content: space-between;
    gap: ${theme.spacings.zetta};
  `
)

export const ContainerContent = styled('div')(
  ({ theme }: Theme) => css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacings.tera};
  `
)

ContainerContent.Title = styled('div')(
  ({ theme }: Theme) => css`
    ${theme.typography.headings.zetta};
    font-weight: bold;

    mark {
      all: unset;
      color: ${theme.colors.f500};
    }
  `
)

ContainerContent.Checklist = styled('div')(
  ({ theme }: Theme) => css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacings.byte};
    margin-left: ${theme.spacings.byte};

    h3 {
      font-weight: bold;
      position: relative;
      padding-left: ${theme.spacings.mega};
    }

    h3::before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 11px;
      height: 12px;
      background-color: ${theme.colors.p500};
      clip-path: polygon(0 0, 100% 50%, 0 100%);
    }

    ul {
      color: ${theme.colors.n500};
      padding-left: ${theme.spacings.mega};
      display: flex;
      flex-direction: column;
      gap: ${theme.spacings.byte};
    }
  `
)

ContainerContent.Checklist

export const ContainerImage = styled('div')(
  ({ theme }: Theme) => css`
    display: flex;
    flex-direction: column;
    justify-content: end;
  `
)
