import styled from '@emotion/styled'
import { css } from '@emotion/react'

const wrapperStyles = ({ theme }) => css`
  label: wrapper;

  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: ${theme.spacings.mega};
  align-items: end;

  margin-top: ${theme.spacings.mega};
  margin-bottom: ${theme.spacings.mega};
  padding: ${theme.spacings.mega};

  border: 1px solid ${theme.colors.n100};
  border-radius: ${theme.borderRadius.giga};
`

const contentStyles = ({ theme }) => css`
  label: content;

  display: grid;
  grid-template-rows: auto auto;
  grid-row-gap: ${theme.spacings.byte};
`

export const Wrapper = styled('div')(wrapperStyles)
export const Content = styled('div')(contentStyles)
