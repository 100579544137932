import PropTypes from 'prop-types'

export const ShapeThumb = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  tags: PropTypes.array.isRequired,
  background_image: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  inWishList: PropTypes.bool.isRequired,
  isFeatured: PropTypes.bool.isRequired,
  products: PropTypes.arrayOf(PropTypes.object),
  publishDate: PropTypes.string,
  createdAt: PropTypes.string
}
