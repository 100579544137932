import { css } from '@emotion/react'
import styled from '@emotion/styled'

const pictureWrapperStyles = () => css`
  label: picture-wrapper;

  height: 60%;
`

export const PictureWrapper = styled('div')(pictureWrapperStyles)
