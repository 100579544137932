import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Button as BaseButton } from '@leroy-merlin-br/backyard-react'

const ghostStyles = ({ theme, variant }) =>
  variant === 'ghost' &&
  css`
    label: clean-filters--ghost;

    background-color: ${theme.colors.n200};
    color: ${theme.colors.n900};

    &:disabled {
      background-color: ${theme.colors.n100};
      color: ${theme.colors.n300};
    }

    &:hover {
      background-color: ${theme.colors.n300};
      color: ${theme.colors.n900};
    }

    &:focus {
      background-color: ${theme.colors.n200};
      color: ${theme.colors.n900};

      border-color: ${theme.colors.n700};
      box-shadow: none;
    }

    &:active {
      background-color: ${theme.colors.n200};
      color: ${theme.colors.n900};
    }
  `

export const Button = styled(BaseButton)(ghostStyles)
