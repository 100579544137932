import styled from '@emotion/styled'
import { css } from '@emotion/react'

const wrapperStyles = ({ theme }) => css`
  label: pix-wrapper;

  ${theme.media.giga} {
    [target='card'] {
      width: 564px;
    }

    margin-bottom: ${theme.spacings.giga};
  }

  display: flex;
  justify-content: center;

  padding-top: 98px;
`

export const Wrapper = styled('div')(wrapperStyles)
