import { useState, useEffect, useCallback } from 'react'
import { Card, Stack, Heading } from '@leroy-merlin-br/backyard-react'
import { shallowEqual, useSelector } from 'react-redux'

import { BalanceList } from 'lmcv/components'
import { getProfileBalance } from 'lmcv/services/loyaltyProfile'
import { showErrorAlert } from 'lmcv/utils/showErrorAlert'
import { PRO_EAD_ACTIVE } from 'lmcv/utils/LoyaltyStatus/constants'

import { Points } from './components/Points'
import { TimeLine } from './components/TimeLine'
import * as S from './styled'

const MyPointsPage = () => {
  const [balance, setBalance] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [hasNextPage, setHasNextPage] = useState(true)
  const [page, setPage] = useState(0)

  const { payload: loyaltyProfile, isRequest } = useSelector(
    state => state.loyaltyProfile.get,
    shallowEqual
  )

  const fetchBalance = async pageProp => {
    setIsLoading(true)
    try {
      const { data, meta } = await getProfileBalance(pageProp)
      if (!meta.next_page) setHasNextPage(false)
      setBalance(prev => [...prev, ...data])
    } catch {
      showErrorAlert('points')
    }
    setIsLoading(false)
  }

  const handleLoadMore = useCallback(() => {
    setPage(currentPage => currentPage + 1)
  }, [])

  useEffect(() => {
    setPage(1)
  }, [])

  useEffect(() => {
    page && fetchBalance(page)
  }, [page])

  if (isRequest) {
    return null
  }

  if (loyaltyProfile.status === PRO_EAD_ACTIVE) {
    return (
      <Stack>
        <Card>
          <Heading size="giga">Meus Pontos</Heading>
          <Points
            user={loyaltyProfile}
            isLoading={isRequest}
            isLoyaltyPro={true}
          />
        </Card>

        <TimeLine isLoading={isRequest} data={loyaltyProfile} />

        <S.Card>
          <Heading size="giga">Saldo de Pontos</Heading>
          <div>
            <BalanceList
              loadMore={handleLoadMore}
              data={balance}
              isLoading={isLoading}
              hasNextPage={hasNextPage}
            />
          </div>
        </S.Card>
      </Stack>
    )
  }

  return (
    <S.Card>
      <Heading size="giga">Histórico</Heading>
      <Points user={loyaltyProfile} isLoading={isRequest} />
      <div>
        <BalanceList
          loadMore={handleLoadMore}
          data={balance}
          isLoading={isLoading}
          hasNextPage={hasNextPage}
        />
      </div>
    </S.Card>
  )
}

export default MyPointsPage
