import { css } from '@emotion/react'
import styled from '@emotion/styled'

const shareButtonsWrapperStyles = ({ theme }) => css`
  label: share-buttons__component;

  margin: 0 ${theme.spacings.byte};
  position: relative;

  & button {
    padding: 0;
  }

  ${theme.media.giga} {
    display: flex;
    justify-content: space-between;
  }
`

const shareButtonsContentStyles = ({ theme }) => css`
  label: share-button__content;

  display: none;

  ${theme.media.giga} {
    align-items: center;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }
`

const shareButtonsContentActiveStyles = ({ isActive }) =>
  isActive &&
  css`
    display: flex;
  `

export const ShareButtonsWrapper = styled('div')(shareButtonsWrapperStyles)
export const ShareButtonsContent = styled('div')(shareButtonsContentStyles, shareButtonsContentActiveStyles)
