import { useEffect, useState } from 'react'
import { datadogRum } from '@datadog/browser-rum'

import * as newTailRepository from 'scripts/api/new-tail'

const useProductsAds = props => {
  const { context, userId, sessionId, productId, categoryName, term } = props

  const [result, setResult] = useState([])
  const [isFetching, setIsFetching] = useState(false)

  useEffect(() => {
    if (Boolean(context) && Boolean(sessionId)) {
      setIsFetching(true)
      ;(async () => {
        try {
          const {
            data: { productsAds: result }
          } = await newTailRepository.getProductsAds({
            context,
            productId,
            categoryName,
            term,
            userId,
            sessionId
          })

          setResult(result)
        } catch (e) {
          datadogRum.addError(e)
        } finally {
          setIsFetching(false)
        }
      })()
    }
  }, [categoryName, context, productId, sessionId, term, userId])

  return { result, isFetching }
}

export default useProductsAds
