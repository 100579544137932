import $ from 'jquery'

import BaseComponent from 'scripts/components/base-component'

class RequiredStore extends BaseComponent {
  static componentName = 'required-store'

  static DOMEvents = {
    change: 'onChange'
  }

  init () {
    this.select = $('[data-loyalty-required]')
    this.validation = $('[data-form="account"]').data('validation')
  }

  onChange () {
    if (this.$element.is(':not(:checked)')) {
      this.select.removeAttr('data-required')
      this.validation.setPristine(this.select)
    }
  }
}

export default ($el) => new RequiredStore($el).init()

export { RequiredStore as Component }
