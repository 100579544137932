import type { SVGAttributes } from 'react'
import { forwardRef } from 'react'

const AddressIcon = forwardRef<SVGSVGElement, SVGAttributes<SVGSVGElement>>((props, ref) => (
  <svg ref={ref} viewBox="0 0 64 64"{...props}>
    <path
      d="M58.5697 31.3L33.4197 5.79996C33.2336 5.61279 33.0124 5.4643 32.7687 5.36304C32.5249 5.26178 32.2636 5.20975 31.9997 5.20996C31.7358 5.20975 31.4744 5.26178 31.2307 5.36304C30.987 5.4643 30.7658 5.61279 30.5797 5.79996L5.43969 30.94C5.17473 31.2243 5.03049 31.6004 5.03734 31.989C5.0442 32.3776 5.20162 32.7484 5.47645 33.0232C5.75128 33.298 6.12205 33.4555 6.51065 33.4623C6.89925 33.4692 7.27534 33.3249 7.55969 33.06L8.99969 31.62V57C8.99969 57.5304 9.21041 58.0391 9.58548 58.4142C9.96055 58.7892 10.4693 59 10.9997 59H24.9997C25.5301 59 26.0388 58.7892 26.4139 58.4142C26.789 58.0391 26.9997 57.5304 26.9997 57V43H36.9997V57C36.9997 57.5304 37.2104 58.0391 37.5855 58.4142C37.9606 58.7892 38.4693 59 38.9997 59H52.9997C53.5301 59 54.0388 58.7892 54.4139 58.4142C54.789 58.0391 54.9997 57.5304 54.9997 57V32L56.4297 33.45C56.5692 33.5923 56.7357 33.7054 56.9194 33.7827C57.1031 33.8599 57.3004 33.8998 57.4997 33.9C57.8755 33.8889 58.2331 33.7351 58.4997 33.47C58.651 33.3347 58.7733 33.1701 58.8592 32.9862C58.9451 32.8023 58.9927 32.6028 58.9993 32.3999C59.0058 32.1971 58.9711 31.995 58.8973 31.8059C58.8235 31.6168 58.712 31.4447 58.5697 31.3ZM51.9997 56H39.9997V42C39.9997 41.4695 39.789 40.9608 39.4139 40.5857C39.0388 40.2107 38.5301 40 37.9997 40H25.9997C25.4693 40 24.9606 40.2107 24.5855 40.5857C24.2104 40.9608 23.9997 41.4695 23.9997 42V56H11.9997V28.62L31.9997 8.61996L51.9997 28.91V56Z"
    />
  </svg>
))

export default AddressIcon
