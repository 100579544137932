import {
  Children,
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
  React
} from 'react'
import PropTypes from 'prop-types'

import elementOuterHeight from 'shared/utils/elementOuterHeight'

import { Button } from './components'
import * as S from './styled'

const SCROLL_GAP = 250

const ShowMore = ({
  children,
  isOpen: initialOpen = false,
  scrollToParent = false,
  visibleRows,
  minHeight = 63,
  CustomButtonWrapper = null,
  showFloatButton = false,
  CustomWrapper,
  layout = 'row'
}) => {
  const [isOpen, setIsOpen] = useState(initialOpen)
  const [isButtonVisible, setIsButtonVisible] = useState(true)
  const [closedHeight, setClosedHeight] = useState(minHeight)
  const [openHeight, setOpenHeight] = useState(0)
  const [isFloatButtonVisible, setIsFloatButtonVisible] = useState(false)
  const [isContentIntersecting, setIsContentIntersecting] = useState(false)
  const [isButtonIntersecting, setIsButtonIntersecting] = useState(false)

  const mainRef = useRef(null)
  const buttonRef = useRef(null)

  const ButtonWrapper = CustomButtonWrapper || S.ButtonWrapper
  const ContentWrapper = CustomWrapper || S.DefaultWrapper

  const buttonWrapperProps = CustomButtonWrapper ? {} : {
    ref: buttonRef,
    moveRigth: isOpen
  }

  const getVisibleChildrenHeight = (visibleRows, countChildren, current) => {
    let updatedHeight = 0

    for (let i = 0; i < visibleRows && i < countChildren; i++) {
      updatedHeight += elementOuterHeight(current.firstElementChild.children[i])
    }

    return updatedHeight
  }

  const setInitialVisibleButton = useCallback(() => {
    const countChildren = Children.count(children)
    const shouldRenderButton = visibleRows
      ? countChildren > visibleRows
      : openHeight > closedHeight

    setIsButtonVisible(shouldRenderButton)
  }, [openHeight, closedHeight, visibleRows, children])

  const setInitialVisibleHeight = useCallback(
    ({ current }) => {
      const countChildren = Children.count(children)
      const needUpdateHeight = countChildren > 0 && visibleRows

      if (needUpdateHeight) {
        const updatedHeight = getVisibleChildrenHeight(
          visibleRows,
          countChildren,
          current
        )
        return setClosedHeight(updatedHeight)
      }

      setClosedHeight(minHeight)
    },
    [children, visibleRows, minHeight]
  )

  const scroll = () => {
    const bodyTop = document.body.getBoundingClientRect().top
    const mainRefTop = mainRef.current.getBoundingClientRect().top

    const whereToScroll = mainRefTop - bodyTop - SCROLL_GAP

    window.scroll({
      top: whereToScroll,
      behavior: 'smooth'
    })
  }

  const onButtonClick = useCallback(() => {
    if (scrollToParent && isOpen) {
      scroll()
    }
    setIsFloatButtonVisible(false)
    setIsOpen(prev => !prev)
  }, [scrollToParent, isOpen])

  useEffect(() => {
    if (mainRef.current) {
      const { firstElementChild } = mainRef.current

      setOpenHeight(elementOuterHeight(firstElementChild))

      const resizeObserver = new ResizeObserver(([entry]) => {
        setOpenHeight(elementOuterHeight(firstElementChild))
      })

      if (isOpen) {
        resizeObserver.observe(firstElementChild)
      }

      return () => {
        resizeObserver.disconnect()
      }
    }
  }, [isOpen])

  useEffect(() => {
    if (showFloatButton && mainRef.current && buttonRef.current) {
      const contentObserver = new IntersectionObserver(([entry]) => {
        setIsContentIntersecting(entry.isIntersecting)
      })

      const buttonObserver = new IntersectionObserver(([entry]) => {
        setIsButtonIntersecting(entry.isIntersecting)
      })

      contentObserver.observe(mainRef.current)
      buttonObserver.observe(buttonRef.current)

      return () => {
        contentObserver.disconnect()
        buttonObserver.disconnect()
      }
    }
  }, [isOpen, showFloatButton])

  useEffect(() => {
    if (showFloatButton) {
      const shouldFloatButtonBeVisible =
        isContentIntersecting && !isButtonIntersecting && isOpen

      setIsFloatButtonVisible(shouldFloatButtonBeVisible)
    }
  }, [isButtonIntersecting, isContentIntersecting, isOpen, showFloatButton])

  useEffect(() => {
    if (mainRef.current) {
      setInitialVisibleHeight(mainRef)
      setInitialVisibleButton(mainRef)
    }
  }, [setInitialVisibleHeight, setInitialVisibleButton])

  return (
    <Fragment>
      {isFloatButtonVisible && (
        <S.FloatButton data-testid="float-button">
          <Button isOpen={isOpen} onClick={onButtonClick} />
        </S.FloatButton>
      )}

      <S.Wrapper>
        <S.Content
          data-testid="content"
          ref={mainRef}
          isOpen={isOpen}
          height={isOpen ? openHeight : closedHeight}
          isRowLayout={layout === 'row'}
        >
          <ContentWrapper shouldShowEllipsis={!isOpen}>
            {children}
          </ContentWrapper>
        </S.Content>

        {isButtonVisible && (
          <ButtonWrapper {...buttonWrapperProps}>
            <Button isOpen={isOpen} onClick={onButtonClick} />
          </ButtonWrapper>
        )}
      </S.Wrapper>
    </Fragment>
  )
}

ShowMore.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  isOpen: PropTypes.bool,
  scrollToParent: PropTypes.bool,
  visibleRows: PropTypes.number,
  minHeight: PropTypes.number,
  showFloatButton: PropTypes.bool,
  CustomWrapper: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  CustomButtonWrapper: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  layout: PropTypes.oneOf(['column', 'row'])
}

export default ShowMore
