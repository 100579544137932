import { css } from '@emotion/react'
import styled from '@emotion/styled'

const baseStyles = ({ theme }) => css`
  label: share-button;

  button svg {
    label: share-button__icon;

    color: ${theme.colors.n600};
  }
`
const iconWrapperStyles = () => css`
  label: icon-wrapper;

  svg {
    width: 35px;
    height: 35px;
  }
`

export const ShareButton = styled('span')(baseStyles)
export const IconWrapper = styled('div')(iconWrapperStyles)
