import request from './axios'
import { API_ENDPOINTS } from './constants'

export const getCustomers = async () => {
  const response = await request
    .get(API_ENDPOINTS.customers)
    .then(({ data }) => data)
    .catch(err => {
      throw err.response
    })

  return response
}

export const getProject = async id => {
  const response = await request
    .get(`${API_ENDPOINTS.customers}/${id}`)
    .then(({ data }) => data)
    .catch(err => {
      throw err.response
    })

  return response
}

export const updateProject = async (id, data) => {
  const response = await request
    .put(`${API_ENDPOINTS.customers}/${id}`, data)
    .then(({ data }) => data)
    .catch(err => {
      throw err.response
    })

  return response
}

export const getRelationship = async id => {
  const response = await request
    .get(`${API_ENDPOINTS.relationship}/${id}`)
    .then(({ data }) => data)
    .catch(err => {
      throw err.response
    })

  return response
}

export const updateRelationship = async (id, data) => {
  const response = await request
    .put(`${API_ENDPOINTS.relationship}/${id}`, data)
    .then(({ data }) => data)
    .catch(err => {
      throw err.response
    })

  return response
}
