import { Text } from '@leroy-merlin-br/backyard-react'

import { SHIPMENTS_ERROR_STATUS } from 'catalog/utils/constants'

import * as S from './styled'

const ErrorNotification = ({ errorStatus }) => {
  if (errorStatus === SHIPMENTS_ERROR_STATUS.differentRegion) {
    return null
  }

  return (
    <S.ErrorNotification>
      <Text size="kilo" noMargin>
        <Text as="strong" size="kilo" isBold noMargin>
          Ops!{' '}
        </Text>
        Tivemos um problema ao tentar apresentar o frete,{' '}
        <Text as="strong" size="kilo" isBold noMargin>
          mas você poderá conferi-lo em seu carrinho
        </Text>
        .
      </Text>
    </S.ErrorNotification>
  )
}

export default ErrorNotification
