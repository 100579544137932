import emitter from 'scripts/utils/emitter'
import * as cartRepository from 'scripts/api/cart'
import { cartEventApp } from 'scripts/react-components/utils/app'

import { hideSpinner, showSpinner } from 'utils/Spinner'

export { default as dataLayer } from './data-layer'

export const updateCartQuantity = ({ count }) =>
  emitter.emit('cart:update', { count })

export const cartProductAdded = cart =>
  emitter.emit('cart:products-replace', cart)

export const callApiAndUpdateCart = async () => {
  const { data } = await cartRepository.getCart()

  cartProductAdded(data)

  updateCartQuantity({ count: data.uniqueProductsCount })
  cartEventApp.updateCart(data.id, data.uniqueProductsCount)

  return data
}

export const handleSpinner = ({ useSpinner = false, action = 'hide' }) => {
  if (!useSpinner) return

  action === 'show' ? showSpinner() : hideSpinner()
}
