import type { Hit } from '@algolia/client-search'
import type { BaseHit } from 'instantsearch.js/es/types'

import { indexName } from '../lib/algolia'

export type GetInsightsStoredInfoProps = {
  queryID: string
  indexName: string
  position: number
  objectID: string
  price: number
  currency: string
  quantity: number
}

interface InsightInfoHit extends Hit<BaseHit> {
  regionalAttributes: {
    [key: string]: {
      promotionalPrice: number
    }
  }
}

export const getInsightsStoredInfo = () =>
  JSON.parse(
    localStorage.getItem('__algoliaInsights__') ?? 'null'
  ) as GetInsightsStoredInfoProps | null

export const setInsightsInfo = (hit: InsightInfoHit, region: string) => {
  const hitObj = {
    indexName,
    objectID: hit.objectID,
    queryID: hit.__queryID,
    position: hit.__position,
    price: hit.regionalAttributes[region].promotionalPrice,
    currency: 'BRL',
    quantity: 1
  }

  localStorage.setItem('__algoliaInsights__', JSON.stringify(hitObj))
}
