import { AxiosError } from 'axios'
import request from '../../../services/axios'
import { API_ENDPOINTS } from '../../../services/constants'
import { Supplier } from '../types/api/supplier'
import { ArchitectRegistrationDto } from '../types/dto/architect-registration.dto'
import { AttachmentsDto } from '../types/dto/attachments.dto'
import { GetSupplierError } from '../types/get-supplier-error'

export const createSupplierService = () => {
  const submitRegistration = (
    payload: ArchitectRegistrationDto,
    attachments: AttachmentsDto
  ): Promise<any> => {
    const data = buildSendFormData(payload, attachments)

    return request
      .post(API_ENDPOINTS.supplier, data)
      .then((response) => response.data.data)
  }

  const buildSendFormData = (
    payload: ArchitectRegistrationDto,
    attachments: AttachmentsDto
  ) => {
    const data = new FormData()

    const appendParam = (name: string, value: any) =>
      data.append(name, normalizeFormDataParam(value))

    const appendFile = (name: string, file: File) => data.append(name, file)

    //#region Company
    appendParam('company[cnpj]', payload.company.documentNumber)
    appendParam('company[socialName]', payload.company.socialName)
    appendParam('company[fantasyName]', payload.company.fantasyName)
    appendParam('company[stateRegistration]', payload.company.stateRegistration)
    appendParam('company[cityRegistration]', payload.company.cityRegistration)
    appendParam('company[economicActivity]', payload.company.mainCnae)
    //#endregion

    //#region Address
    appendParam('address[postalCode]', payload.company.address.postalCode)
    appendParam('address[state]', payload.company.address.state)
    appendParam('address[city]', payload.company.address.city)
    appendParam('address[street]', payload.company.address.street)
    appendParam('address[number]', payload.company.address.number)
    appendParam('address[complement]', payload.company.address.complement)
    appendParam('address[district]', payload.company.address.district)
    //#endregion

    //#region LegalRepresentative
    appendParam('isLegalRepresentative', payload.company.isRepresentative)
    appendParam('legalRepresentative[name]', payload.company.representativeName)
    appendParam(
      'legalRepresentative[cpf]',
      payload.company.representativeDocumentNumber
    )
    appendParam(
      'legalRepresentative[email]',
      payload.company.representativeEmail
    )
    appendParam(
      'legalRepresentative[phone]',
      payload.company.representativePhoneNumber
    )
    //#endregion

    //#region Bank
    appendParam('bank[name]', payload.bank.name)
    appendParam('bank[agency]', payload.bank.agencyNumber)
    appendParam('bank[agencyDigit]', payload.bank.agencyDigit)
    appendParam('bank[accountType]', payload.bank.accountType)
    appendParam('bank[accountNumber]', payload.bank.accountNumber)
    //#endregion

    //#region Attachments
    if (attachments.cityRegistration)
      appendFile('cityRegistration', attachments.cityRegistration)

    if (attachments.bankStatement)
      appendFile('bankReceipt', attachments.bankStatement)
    //#endregion

    return data
  }

  const normalizeFormDataParam = (
    value: string | number | boolean | undefined
  ) => {
    if (value === true || value === false) return value ? '1' : '0'
    return String(value || '')
  }

  const getSupplier = async (): Promise<Supplier | GetSupplierError> => {
    try {
      const { data } = await request
        .get(API_ENDPOINTS.supplier)
        .then((response) => response.data)

      return data
    } catch (error) {
      const response = (error as AxiosError).response
      const statusCode = response?.status

      if (!statusCode) throw error

      return { statusCode, responseData: response.data }
    }
  }

  return { submitRegistration, getSupplier }
}
