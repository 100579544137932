import { Breadcrumb as BreadcrumbBase } from 'site/Breadcrumb'

import { useServerContext } from '../../../../routes/routes.context'

function Breadcrumb () {
  const { meta, apiDomain } = useServerContext()

  return (
    <div className="py-3">
      <BreadcrumbBase basehost={apiDomain} categoryId={meta?.id?.$oid} />
    </div>
  )
}

export default Breadcrumb
