class SalesTransformer {
  static transformToBannerPromotionData ({ alt, categoryTree = null, title }, position = null, type) {
    return ({
      id: type,
      name: title,
      creative: alt,
      ...(categoryTree && { category: categoryTree }),
      ...position && { position: `slot${position}` }
    })
  }

  static transformToProductPromotionData ({ id, name, categoryTree = null, title }, position, type, parentTitle) {
    const promotionName = name || title
    return ({
      id: `${type}${parentTitle && ('-' + parentTitle)}`,
      name: promotionName,
      creative: id,
      ...categoryTree && { category: categoryTree },
      ...position && { position: `slot${position}` }
    })
  }

  static transformToProductPromotionClickData ({ id, name, categoryTree = null }, position, type, title) {
    return ({
      id: `${type}-${title}`,
      name,
      creative: id,
      ...(categoryTree && { category: categoryTree }),
      position: `slot${position}`
    })
  }
}

export default SalesTransformer
