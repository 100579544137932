import { PropsWithChildren } from 'react'

import { Component } from '../BaseComponent/BaseComponent'

type FooterProps = {}

export const Footer = ({ children }: PropsWithChildren<FooterProps>) => {
  return (
    <Component.Footer>
      <div className="w-full text-xs flex items-center justify-center">
        <span>{children}</span>
      </div>
    </Component.Footer>
  )
}
