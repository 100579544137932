import { call, put, takeLatest } from 'redux-saga/effects'

import * as services from 'checkout/services/summary'

import { Constants, Actions } from './'

function * updateSummary () {
  try {
    const { data } = yield call(services.getSummary)
    yield put(Actions.updateSummarySuccess(data))
  } catch ({ status }) {
    yield put(
      Actions.updateSummaryFailure(status)
    )
  }
}

export default function * root () {
  yield takeLatest(Constants.UPDATE_SUMMARY, updateSummary)
}
