export const json = `{
  "data": {
    "chat": [
      {
        "message": "Olá, como posso te ajudar?",
        "from": "seller",
        "date": "2020-08-13T14:00:00"
      }
    ],
    "options": [
      {
        "type": "chat",
        "id": "deliveryInformation",
        "name": "Informações de entrega"
      },
      {
        "type": "chat",
        "id": "cancellation",
        "name": "Cancelamento",
        "subOptions": [
          {
            "id": "lateDelivery",
            "name": "Atraso na entrega"
          },
          {
            "id": "repentance",
            "name": "Arrependimento"
          },
          {
            "id": "purchaseNotRecognized",
            "name": "Compra não reconhecida"
          },
          {
            "id": "other",
            "name": "Outros"
          }
        ]
      },
      {
        "type": "chat",
        "id": "defectiveProduct",
        "name": "Produto com defeito"
      },
      {
        "type": "chat",
        "id": "missingProduct",
        "name": "Produto faltando"
      },
      {
        "type": "chat",
        "id": "shiftReturn",
        "name": "Troca e devolução",
        "subOptions": [
          {
            "id": "lateDelivery",
            "name": "Atraso na entrega"
          },
          {
            "id": "repentance",
            "name": "Arrependimento"
          },
          {
            "id": "purchaseNotRecognized",
            "name": "Compra não reconhecida"
          },
          {
            "id": "other",
            "name": "Outros"
          },
          {
            "id": "defectiveProduct",
            "name": "Produto com defeito"
          },
          {
            "id": "repentance",
            "name": "Arrependimento"
          },
          {
            "id": "productError",
            "name": "Produto errado"
          },
          {
            "id": "missingProduct",
            "name": "Produto faltando"
          },
          {
            "id": "other",
            "name": "Outros"
          }
        ]
      },
      {
        "type": "chat",
        "id": "productInformation",
        "name": "Informações de produto"
      },
      {
        "type": "chat",
        "id": "invoice",
        "name": "Nota fiscal"
      },
      {
        "type": "chat",
        "id": "incorrectProduct",
        "name": "Produto incorreto"
      },
      {
        "type": "ticket",
        "id": "reversal",
        "name": "Estorno não realizado",
        "subOptions": [
          {
            "id": "lateDelivery",
            "name": "Atraso na entrega"
          },
          {
            "id": "repentance",
            "name": "Arrependimento"
          },
          {
            "id": "purchaseNotRecognized",
            "name": "Compra não reconhecida"
          },
          {
            "id": "other",
            "name": "Outros"
          },
          {
            "id": "defectiveProduct",
            "name": "Produto com defeito"
          },
          {
            "id": "repentance",
            "name": "Arrependimento"
          },
          {
            "id": "productError",
            "name": "Produto errado"
          },
          {
            "id": "missingProduct",
            "name": "Produto faltando"
          },
          {
            "id": "other",
            "name": "Outros"
          }
        ]
      },
      {
        "type": "chat",
        "id": "otherSubject",
        "name": "Outro assunto"
      }
    ],
    "status": "idle",
    "option": null,
    "subOption": null,
    "ticket": null
  }
}`
