import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { Theme } from 'scripts/react-components/themes/Theme'

const wrapperTextFieldStyles = ({ theme }: Theme) => css`
  margin-top: ${theme.spacings.giga};
`

export const WrapperTextField = styled('div')(wrapperTextFieldStyles)

const wrapperComplementStyles = ({ theme }: Theme) => css`
  font-size: 14px;

  span {
    font-weight: normal;
  }
`

export const WrapperComplement = styled('div')(wrapperComplementStyles)

const wrapperButtonsStyles = ({ theme }: Theme) => css`
  display: flex;
  justify-content: space-between;
  margin-top: ${theme.spacings.tera};
`

export const WrapperButtons = styled('div')(wrapperButtonsStyles)
