import { Dialog, useModal } from '@leroy-merlin-br/backyard-react'

import { BatchSearch } from '../BatchSearch'

const BatchSearchButton = ({ lm, storeSlug, limit }) => {
  const { setModal } = useModal()

  const showModal = () => {
    setModal({
      isCentered: true,
      children: ({ onClose }) => (
        <Dialog onClose={onClose} title="Consulta de Lote">
          <BatchSearch lm={lm} storeSlug={storeSlug} limit={limit} />
        </Dialog>
      )
    })
  }

  return (
    <button className="button-hollow button-hollow-primary" onClick={showModal}>
      Ver Lote
    </button>
  )
}

export default BatchSearchButton
