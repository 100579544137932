import { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'

import type { LoyaltyProfile } from 'api/loyaltyProfile'

import { useResize } from 'utils/hooks/use-resize'
import { formatDate } from 'utils/date'
import { formatters } from 'utils/formatters'

import { Actions } from 'lmcv/redux/modules/coupon'
import { couponMessage } from 'lmcv/utils/couponMessage'

import { CouponData, CouponModalCategory } from '../types'
import { ButtonText, InfoText } from './types'

type State = {
  coupon: {
    active: {
      isRequest: boolean
    }
    get: {
      payload: CouponData
      isRequest: boolean
      error: unknown
    }
  }
  loyaltyProfile: {
    get: {
      payload: LoyaltyProfile
    }
  }
}

export const useCouponModalCommon = (
  id: string,
  category: CouponModalCategory,
  active: boolean
) => {
  const dispatch = useDispatch()

  const [isMobile] = useResize(760)

  const {
    coupon: {
      active: { isRequest: isActivationLoading },
      get: {
        payload: couponData,
        isRequest: isRequestLoading,
        error: requestError
      }
    },
    loyaltyProfile: {
      get: { payload: loyaltyProfile }
    }
  } = useSelector((state: State) => state, shallowEqual)

  const infoText = useMemo<InfoText>(() => {
    const defaultResponse = {
      subtitle: 'Cupom',
      expire: '',
      remaining: ''
    }

    if (!couponData) {
      return defaultResponse
    }

    const { expire, type, price, remainingDays } = couponData

    if (type === 'percentage') {
      return {
        subtitle: '',
        expire: formatDate(expire, 'D [de] MMMM'),
        remaining: couponMessage.expire(remainingDays)
      }
    }

    if (type === 'money') {
      return {
        subtitle: `Troque ${formatters.toDecimal(price)} pontos por`,
        expire: formatDate(expire, 'D [de] MMMM'),
        remaining: couponMessage.expire(remainingDays)
      }
    }

    return defaultResponse
  }, [couponData])

  const buttonText = useMemo<ButtonText>(() => {
    return {
      default: 'Ativar',
      active: 'Ativado'
    }
  }, [])

  const isButtonDisabled = useMemo<boolean>(() => {
    if (!couponData) {
      return false
    }

    const { type, price, isActive } = couponData

    return (
      type === 'money' &&
      !isActive &&
      price > (loyaltyProfile?.points?.actual || 0)
    )
  }, [couponData, loyaltyProfile?.points?.actual])

  useEffect(() => {
    dispatch(Actions.get(id, category, active))
  }, [dispatch, id, category, active])

  const onCouponActive = () => {
    const { type, isActive } = couponData

    !isActive && dispatch(Actions.active(id, type))
  }

  const setMarginTopIfMobile = useCallback(() => {
    const voucherDescription = document.querySelector(
      '[data-id="voucherDescription"]'
    )
    const wrapperModal = document.querySelector(
      '[data-id="wrapperModal"]'
    ) as HTMLElement
    if (voucherDescription && wrapperModal) {
      const lengthTopVoucherDescription =
        voucherDescription.getBoundingClientRect().top
      const windowHeight =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight // Altura da janela do navegador em pixels

      const marginTopCalculate =
        windowHeight - (lengthTopVoucherDescription + 57)
      wrapperModal.style.top = `${marginTopCalculate}px`
    }
  }, [])

  useEffect(() => {
    if (!isRequestLoading && isMobile) {
      setMarginTopIfMobile()
    }
  }, [isRequestLoading, isMobile, setMarginTopIfMobile])

  return {
    couponData: couponData,
    isRequestLoading: isRequestLoading,
    hasRequestError: Boolean(requestError),
    infoText,
    buttonText,
    isActivationLoading,
    isButtonDisabled,
    onCouponActive
  }
}
