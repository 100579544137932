import cookies from 'js-cookie'
import axios from 'axios'

const BASE_URL = (window.env && window.env.baseHost) || ''

const API_TOKEN = cookies.get('api_token')

export const api = axios.create({
  baseURL: `${BASE_URL}/api/v3`,
  headers: {
    ...(API_TOKEN ? { Authorization: `Bearer ${API_TOKEN}` } : {})
  }
})

export const apiV2 = axios.create({
  baseURL: `${BASE_URL}/api/v2`,
  headers: {
    ...(API_TOKEN ? { Authorization: `Bearer ${API_TOKEN}` } : {})
  }
})
