import request from './axios'
import { API_ENDPOINTS } from './constants'

export const getNotification = async () => {
  const response = await request
    .get(API_ENDPOINTS.notifications)
    .then(({ data }) => data)
    .catch(err => {
      throw err.response
    })

  return response
}

export const deleteNotification = async code => {
  const response = await request
    .delete(`${API_ENDPOINTS.notifications}/${code}`)
    .then(({ data }) => data)
    .catch(err => {
      throw err.response
    })

  return response
}
