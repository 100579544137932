import BaseTracking from './base-tracking'

class RichRelevanceHomeTracking extends BaseTracking {
  static componentName = 'rich-relevance-home-tracking'

  sendItems (_, R3_COMMON) {
    var R3_HOME = new r3_home() // eslint-disable-line
  }
}

export default ($el) => new RichRelevanceHomeTracking($el).init()

export { RichRelevanceHomeTracking as Component }
