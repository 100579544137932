import { useState, useRef, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Icon, IconButton, Text } from '@leroy-merlin-br/backyard-react'
import { Menu } from '@backyard-ui/icons'

import * as S from './styled'

const DropDown = ({ children }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const toggleMenu = () => setIsMenuOpen(currentValue => !currentValue)
  const ref = useRef()

  const onBodyClick = useCallback(
    event => {
      if (ref.current.contains(event.target)) {
        return
      }
      setIsMenuOpen(false)
    },
    [ref]
  )

  useEffect(() => {
    document.body.addEventListener('click', onBodyClick)

    return () => {
      document.body.removeEventListener('click', onBodyClick)
    }
  }, [onBodyClick])

  return (
    <>
      <IconButton
        appearance="info"
        variant="ghost"
        onClick={toggleMenu}
        ref={ref}
      >
        <Icon as={Menu} size="giga" />
      </IconButton>
      <S.Menu active={isMenuOpen} data-testid="menu">
        {children}
      </S.Menu>
    </>
  )
}

const Item = ({ text, onClick }) => {
  return (
    <S.ItemElement onClick={onClick}>
      <Text size="kilo" color="n800" noMargin>
        {text}
      </Text>
    </S.ItemElement>
  )
}

DropDown.Item = Item

DropDown.propTypes = {
  children: PropTypes.node.isRequired
}

Item.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func
}

export default DropDown
