import type { SVGAttributes } from 'react'
import { forwardRef } from 'react'

const ComputingIcon = forwardRef<SVGSVGElement, SVGAttributes<SVGSVGElement>>((props, ref) => (
  <svg ref={ref} viewBox="0 0 144 136"{...props}>
    <path d="M22.6 25.9c-1 1.6-.7 79.4.2 80.3.5.5 5.1.7 10.3.6l9.4-.3v-81l-9.7-.3c-5.5-.1-9.9.1-10.2.7zM39 66v37H26V29h13v37z" />
    <path d="M30.5 37.2c-.3.8-.4 7.5-.3 14.9.3 12.6.4 13.4 2.3 13.4s2-.7 2-14.5c0-13-.2-14.5-1.8-14.8-.9-.2-1.9.3-2.2 1zM30.5 81.4c-2.5 1.8-1.1 5.6 2 5.6 2.1 0 3.5-1.7 3.5-4 0-1.9-3.7-3-5.5-1.6zM49 62.5V87h22v3.4c0 3-.3 3.5-2.7 3.8-1.7.2-2.8.9-2.8 1.8 0 1.3 2.7 1.5 18 1.5s18-.2 18-1.5c0-.9-1.1-1.6-2.7-1.8-2.4-.3-2.8-.8-2.8-3.7v-3.4l12.8-.3 12.7-.3v-48l-36.2-.3L49 38v24.5zm68 0V83H53V42h64v20.5zm-25 28V94H75v-7h17v3.5z" />
  </svg>
))

export default ComputingIcon
