import { Stack, Text, Spinner, toast } from '@leroy-merlin-br/backyard-react'
import { AxiosResponse } from 'axios'
import {
  FC,
  useRef,
  useState,
  ClipboardEvent,
  KeyboardEvent,
  useEffect,
  ReactNode
} from 'react'
import { useForm } from 'react-hook-form'
import { isServerError } from 'user/utils'
import { hasExactlySixDigits } from 'user/utils/hasExactlySixDigits'

import { NumberField } from 'shared/components'

import { MobileLayout } from '../MobileLayout'
import * as S from '../MobileLayout/styled'
import { verifyCode } from '../../services'

type FormValues = {
  securityCode: string[]
}

type VerifyLayoutProps = {
  infoText: string | ReactNode
  inModal?: boolean
  onSuccess: (code: string) => void
  onRensendCode: () => void
  payload: {
    fiscalId: string
    context: string
  }
}

const VerifyLayout: FC<VerifyLayoutProps> = ({
  infoText,
  inModal,
  onSuccess,
  onRensendCode,
  payload
}) => {
  const [isLoading, setIsLoading] = useState(false)

  const [fieldState, setFieldState] = useState<string>()

  const [counter, setCounter] = useState(30)

  const [securityCodeRef] = useState([
    useRef<HTMLInputElement>(),
    useRef<HTMLInputElement>(),
    useRef<HTMLInputElement>(),
    useRef<HTMLInputElement>(),
    useRef<HTMLInputElement>(),
    useRef<HTMLInputElement>()
  ])

  const { control, setValue, getValues } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: { securityCode: [] }
  })

  const handlePasteAllSecurityCode = ({ clipboardData }: ClipboardEvent<HTMLInputElement>) => {
    const securityCode = String(clipboardData.getData('Text'))

    if (!hasExactlySixDigits(securityCode)) {
      return
    }

    for (let index = 0; index <= securityCodeRef.length; index++) {
      setValue(
        `securityCode[${index}]` as `securityCode.${typeof index}`,
        String(clipboardData.getData('Text'))[index]
      )

      if (index === securityCodeRef.length) {
        securityCodeRef[5]?.current?.focus()
        securityCodeRef[5]?.current?.setSelectionRange(1, 1, 'forward')
      }
    }
  }

  const handleKeyUp = async (event: KeyboardEvent<HTMLInputElement>, index: number) => {
    const { key, currentTarget } = event

    const isLastInput = index === securityCodeRef.length - 1

    if (key === 'ArrowLeft' || key === 'ArrowRight') {
      const arrows = {
        ArrowLeft: -1,
        ArrowRight: 1
      }

      securityCodeRef[index + arrows[key]]?.current?.focus()

      securityCodeRef[index + arrows[key]]?.current?.setSelectionRange(0, 1, 'forward')
    } else if (key === 'Backspace' && !currentTarget.value) {
      securityCodeRef[index - 1]?.current?.focus()
      securityCodeRef[index - 1]?.current?.setSelectionRange(0, 1, 'forward')
    } else if (key !== 'Backspace' && currentTarget.value) {
      if (isLastInput) {
        const code = getValues('securityCode').join('')

        securityCodeRef[5]?.current?.blur()

        code.length === 6 && await onSendCodeToVerify(code)
      } else {
        securityCodeRef[index + 1]?.current?.focus()
        securityCodeRef[index + 1]?.current?.setSelectionRange(0, 1, 'forward')
      }
    }
  }

  const onSendCodeToVerify = async (code: string) => {
    setIsLoading(true)

    setFieldState('valid')

    try {
      const { data } = await verifyCode({ ...payload, code })

      if (data.status === 'InvalidCode') {
        toast.critical(data.message, {
          content: 'Insira o código correto para prosseguir.',
          variant: inModal ? 'solid' : 'light'
        })

        setFieldState('invalid')

        setIsLoading(false)
      } else if (data.status === 'ExpiredCode') {
        toast.critical(data.message, {
          content: 'Clique em reenviar código para gerar um novo.',
          variant: inModal ? 'solid' : 'light'
        })

        setFieldState('invalid')

        setIsLoading(false)
      } else if (data.status === 'Valid') {
        setFieldState('valid')

        onSuccess(code)
      } else {
        toast.primary('Não foi possível validar o código', {
          content: 'Tente novamente mais tarde.',
          variant: inModal ? 'solid' : 'light'
        })

        setIsLoading(false)
      }
    } catch (error) {
      const { status } = error as AxiosResponse

      const hasServerError = status && isServerError(status)

      setFieldState('invalid')

      if (hasServerError) {
        toast.critical('Não foi possível verificar o código de segurança!', {
          content: 'Tente novamente mais tarde.',
          variant: inModal ? 'solid' : 'light'
        })
      } else if (status === 429) {
        toast.critical('Você atingiu o limite de tentativas!', {
          content: 'Tente novamente mais tarde.',
          variant: inModal ? 'solid' : 'light'
        })
      }

      setIsLoading(false)
    }
  }

  const handleRensendCode = async () => {
    if (isLoading) {
      return
    }

    setIsLoading(true)

    setCounter(30)

    setTimeout(() => securityCodeRef[0]?.current?.focus(), 0)

    setIsLoading(false)

    onRensendCode()
  }

  useEffect(() => {
    securityCodeRef[0]?.current?.focus()
  }, [setValue, securityCodeRef])

  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(prev => prev - 1), 1000)
  }, [counter])

  const renderComponent = () => (
    <>
      <Text>
        {infoText}
      </Text>

      <S.SecurityCode>
        {securityCodeRef.map((ref, index) => {
          const pasteProps = index === 0 ? { onPaste: handlePasteAllSecurityCode } : {}

          return (
            <S.SecurityCodeField key={index}>
              <NumberField
                pattern="[0-9]*"
                inputmode="numeric"
                disabled={isLoading}
                name={`securityCode[${index}]`}
                control={control}
                maxLength={1}
                getInputRef={ref}
                state={fieldState}
                onKeyUp={(event: KeyboardEvent<HTMLInputElement>) => handleKeyUp(event, index)}
                {...pasteProps}
              />
            </S.SecurityCodeField>
          )
        })}
      </S.SecurityCode>

      <Stack alignX="center" space="mega">
        <S.ResendCodeWrapper>
          {counter ? (
            <Text size="kilo" color="n600" noMargin>
              Reenviar código em <span>{counter}</span> segundos
            </Text>
          ) : (
            <Text size="kilo" noMargin color="p600">
              <a onClick={handleRensendCode} data-gtm-element-id="click-resend-code-link">Reenviar código</a>
            </Text>
          )}
        </S.ResendCodeWrapper>

        <div style={{ height: 20 }}>
          {isLoading && <Spinner appearance="primary" />}
        </div>
      </Stack>
    </>
  )

  if (inModal) {
    return renderComponent()
  }

  return (
    <MobileLayout description="Contatos">
      {renderComponent()}
    </MobileLayout>
  )
}

export default VerifyLayout
