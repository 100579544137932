import dayjs from 'dayjs'
import 'dayjs/locale/pt-br'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'

dayjs.extend(isSameOrBefore)
dayjs.locale('pt-br')

export const formatDate = (date, template) => dayjs(date).format(template)

export const isSameOrBeforeToday = date => dayjs().isSameOrBefore(date, 'day')

export const nextMonthsFromNow = months =>
  dayjs(new Date()).add(months, 'months')

export const changeDateFormat = (date, currentFormat, editedFormat) => {
  if (!date) {
    return
  }
  return dayjs(date, currentFormat).format(editedFormat)
}
