import { Dispatch } from 'react'
import Slider from 'react-slick'
import { ChevronDownOutline, ChevronUpOutline } from '@backyard-ui/icons'
import { Icon } from '@leroy-merlin-br/backyard-react'
import { clamp } from 'lodash'

import * as dataLayer from 'scripts/utils/data-layer'
import { sendEventToAlgolia } from 'scripts/react-components/shared/analytics/algolia.tracker'

import { type CarouselItem } from '../../../types'
import * as S from './styled'

type Props = {
  productId: number
  carouselItems: CarouselItem[]
  setCarouselItemsNavigator: Dispatch<Slider>
  mainSlide: Slider | null
  changeSlideByDirection: (direction: 'prev' | 'next') => void
}

const CarouselItemsNavigator = ({
  productId,
  carouselItems,
  changeSlideByDirection,
  setCarouselItemsNavigator,
  mainSlide
}: Props) => (
  <S.Wrapper>
    <S.ButtonCarouselWrapper isTopSide>
      <S.Arrow
        data-testid="left-arrow"
        side="left"
        title="Anterior"
        onClick={() => {
          changeSlideByDirection('prev')
          dataLayer.midiasCarouselPrevClick(productId)
        }}
      >
        <Icon as={ChevronUpOutline} size="giga" />
      </S.Arrow>
    </S.ButtonCarouselWrapper>
    <Slider
      vertical
      verticalSwiping
      arrows={false}
      focusOnSelect
      infinite
      slidesToShow={clamp(carouselItems.length, 6)}
      asNavFor={mainSlide || undefined}
      responsive={[
        {
          breakpoint: 768,
          settings: {
            slidesToShow: clamp(carouselItems.length, 3),
            vertical: false,
            verticalSwiping: false,
            centerMode: true
          }
        }
      ]}
      ref={carouselNavigator =>
        carouselNavigator && setCarouselItemsNavigator(carouselNavigator)
      }
    >
      {carouselItems.map((item, index) => {
        if (item.type === 'sketchFab') {
          return (
            <S.ImageWrapper
              key={`carousel-navigator-${index}`}
              title="Imagem 3D"
              onClick={() => {
                sendEventToAlgolia()
                dataLayer.midiasCarouselSketchfabClick(productId)
              }}
            >
              <img alt={item.alt} src={item.miniature} />
            </S.ImageWrapper>
          )
        }

        if (item.type === 'video') {
          return (
            <S.ImageWrapper
              key={`carousel-navigator-${index}`}
              title="Vídeo"
              onClick={() => {
                sendEventToAlgolia()
                dataLayer.midiasCarouselVideoClick(productId)
              }}
            >
              <img alt={item.alt} src={item.miniature} />
            </S.ImageWrapper>
          )
        }

        return (
          <S.ImageWrapper
            key={`carousel-navigator-${index}`}
            onClick={() => {
              sendEventToAlgolia()
            }}
          >
            <img alt={item.alt} src={item.miniature} />
          </S.ImageWrapper>
        )
      })}
    </Slider>
    <S.ButtonCarouselWrapper isBottomSide>
      <S.Arrow
        data-testid="right-arrow"
        side="right"
        title="Próximo"
        onClick={() => {
          changeSlideByDirection('next')
          dataLayer.midiasCarouselNextClick(productId)
        }}
      >
        <Icon as={ChevronDownOutline} size="giga" />
      </S.Arrow>
    </S.ButtonCarouselWrapper>
  </S.Wrapper>
)

export default CarouselItemsNavigator
