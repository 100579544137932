import { ChangeEvent, useState } from 'react'
import { Button, Stack, Text, toast } from '@leroy-merlin-br/backyard-react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { Popover } from 'user/components'
import { birthdateErrorMessage } from 'user/utils'

import { NumberField } from 'shared/components'

import { formatDate } from 'utils/date'

import { MobileLayout } from '../../components/MobileLayout'
import * as S from './styled'
import { useMyAccountPersonalDataContext } from '../../context/my-account-personal-data-context'
import { updateProfile } from '../../services'

type FormValues = {
  birthdate: string
}

const EditBirthdate = () => {
  const { contextData, updateData } = useMyAccountPersonalDataContext()

  const [fieldValue, setFieldValue] = useState(
    formatDate(contextData.birthdate, 'DD/MM/YYYY')
  )

  const [loading, setLoading] = useState(false)

  const {
    control,
    formState: { errors, isValid }
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      birthdate: ''
    }
  })

  const history = useHistory()

  const handleSave = async () => {
    setLoading(true)

    const update = await updateProfile({ birthdate: fieldValue })

    setLoading(false)

    if (update.data) {
      updateData({ ...contextData, birthdate: fieldValue })

      toast.primary('Data de nascimento atualizada com sucesso!', {
        variant: 'light'
      })

      history.push('/dados-pessoais')
    }
  }

  return (
    <MobileLayout
      description="Dados pessoais"
      text="Edite sua data de nascimento"
    >
      <Stack space="giga">
        <NumberField
          disabled={loading}
          name="birthdate"
          label={
            <Popover
              placement="bottom"
              list={{
                title: 'Usamos sua data de nascimento para:',
                items: ['Envio de ofertas especiais no seu aniversário.']
              }}
            >
              Data de nascimento
            </Popover>
          }
          rules={{
            required: 'Insira sua data de nascimento',
            validate: (value: string) => birthdateErrorMessage(value)
          }}
          control={control}
          state={Boolean(errors.birthdate) && 'invalid'}
          hint={errors.birthdate?.message}
          format="##/##/####"
          valueType="formatted"
          value={fieldValue}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            setFieldValue(event.target.value)
          }}
        />
        <S.ButtonWrapper>
          <Button
            data-gtm-element-id="click-save-birthdate-button"
            isStretch
            isDisabled={!isValid}
            onClick={handleSave}
            isLoading={loading}
          >
            Salvar
          </Button>
        </S.ButtonWrapper>
      </Stack>

      <Stack alignX="center">
        <button onClick={() => {
          if (loading) {
            return
          }

          history.push('/dados-pessoais')
        }}>
          <Text size="mega" color="r600" noMargin isBold>
            Cancelar
          </Text>
        </button>
      </Stack>
    </MobileLayout>
  )
}

export default EditBirthdate
