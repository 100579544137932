import {
  Text,
  Stack,
  Heading,
  Container,
  Row,
  Col,
  useBreakpoint
} from '@leroy-merlin-br/backyard-react'

import { CustomIcon } from 'shared/components/CustomIcon'

import { ADVANTAGES } from './constants'
import * as S from './styled'

const Advantages = () => {
  const { breakpoint } = useBreakpoint()
  const isMobile = ['untilKilo', 'kilo'].includes(breakpoint)

  const assetsPath = window && window.env.assetHost

  return (
    <S.Section>
      <Container>
        <S.AdvantagesWrapper>
          <Col size={{ giga: 6 }}>
            <Stack space={isMobile ? 'mega' : 'tera'}>
              <S.TitleWrapper>
                <Heading size="exa" noMargin>
                  Conheça os benefícios <br />
                  do <span>Leroy Merlin Com Você</span>
                </Heading>
              </S.TitleWrapper>

              <Row>
                {ADVANTAGES.map(({ icon, title, description }, index) => (
                  <Col size={{ giga: 6 }} key={index}>
                    <S.Box>
                      <Stack space="byte">
                        <CustomIcon as={icon} fill="linearGradient" />
                        <Heading size="kilo" noMargin>
                          {title}
                        </Heading>
                        <Text size="mega" color="n500" noMargin>
                          {description}
                        </Text>
                      </Stack>
                    </S.Box>
                  </Col>
                ))}
              </Row>
            </Stack>
          </Col>

          <S.ImageWrapper>
            <S.Image
              src={`${assetsPath}assets/images/lmcv/landing-page-2022/guy-with-shopping-bag.png`}
            />
          </S.ImageWrapper>
        </S.AdvantagesWrapper>
      </Container>
    </S.Section>
  )
}

export default Advantages
