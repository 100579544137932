import $ from 'jquery'

import BaseComponent from 'scripts/components/base-component'
import { Component as ProductsFilter } from 'scripts/components/entry-key/products-filter'

class OrderingFilter extends BaseComponent {
  static componentName = 'ordering-filter'

  static emitterEvents = {
    'ordering-filters:refresh': 'onOrderingFiltersRefresh'
  }

  init () {
    this.filter = new ProductsFilter(this.$element)
    this.filter.init()
  }

  onOrderingFiltersRefresh () {
    this.filter.refreshOrderingFilters()
  }
}

export default ($el) => new OrderingFilter($el).init()

export { OrderingFilter as Component }
