import BaseTracking from './base-tracking'

class RichRelevanceGenericTracking extends BaseTracking {
  static componentName = 'rich-relevance-generic-tracking'

  sendItems (_, R3_COMMON) {
    var R3_GENERIC = new r3_generic() // eslint-disable-line
  }
}

export default ($el) => new RichRelevanceGenericTracking($el).init()

export { RichRelevanceGenericTracking as Component }
