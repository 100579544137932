import PropTypes from 'prop-types'

import { ShapeThumb } from 'shared/constants/prop-types'
import { ContentsThumb } from 'shared/components/ContentsThumb'
import { GenericCarousel } from 'shared/components/GenericCarousel'

import WishlistProviderMethods from 'site/Wishlist/provider'

import { Widget } from '../../components/Widget'
import * as S from './styled'

const LastContentsCarousel = ({ widget = {} }) => {
  const { items: contents = [], title = '' } = widget

  if (!contents.length) {
    return null
  }

  return (
    <Widget title={title} withBackground noContentPadding>
      <WishlistProviderMethods>
        <GenericCarousel slidesPerView="auto" spaceBetween={3}>
          {contents.map(content => (
            <S.WrapperSpacing key={content.id}>
              <ContentsThumb item={content} />
            </S.WrapperSpacing>
          ))}
        </GenericCarousel>
      </WishlistProviderMethods>
    </Widget>
  )
}

LastContentsCarousel.propTypes = {
  widget: PropTypes.shape({
    title: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(PropTypes.shape(ShapeThumb)).isRequired
  }).isRequired
}

export default LastContentsCarousel
