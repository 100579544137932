import styled from '@emotion/styled'
import { css } from '@emotion/react'

const listContainerStyle = ({ theme }) => css`
  label: list_container;

  display: grid;
  gap: ${theme.spacings.kilo};
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
`

const cardCouponWrapperStyles = () => css`
  label: card-coupon-wrapper;

  div[class*='card__body'] {
    overflow-y: visible;
  }
`

export const ListContainer = styled('ul')(listContainerStyle)
export const CardCouponWrapper = styled('div')(cardCouponWrapperStyles)
