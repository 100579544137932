import { Text } from '@leroy-merlin-br/backyard-react'

import { AntiFraud } from '../../AntiFraud'
import { ContentHeader } from '../../ContentHeader'
import * as S from './styled'
import { BankSlipVisualType } from './type'

const BankSlip = ({ orderId }: BankSlipVisualType) => (
  <ContentHeader
    title={'Recebemos seu pedido!'}
    content={
      <>
        <Text>
          Número do pedido:{' '}
          <Text as="span" isBold>
            {orderId}
          </Text>
        </Text>
        <Text>
          Agora basta efetuar o pagamento, copiando o código de{' '}
          <Text as="span" isBold>
            boleto bancário
          </Text>
          , exibido logo abaixo. Sempre verifique se os dados exibidos no
          momento do pagamento são referentes a{' '}
          <Text as="span" isBold>
            Leroy Merlin.
          </Text>
        </Text>
        <Text>
          Após a{' '}
          <Text as="span" isBold>
            aprovação do pagamento
          </Text>
          , vamos iniciar o processo de separação do seu pedido,{' '}
          <Text as="span" isBold>
            aguarde nosso e-mail de confirmação
          </Text>
          . Esse processo pode levar{' '}
          <Text as="span" isBold>
            até 3 dias úteis
          </Text>
          .
        </Text>
        <AntiFraud />
      </>
    }
  />
)

export default BankSlip
