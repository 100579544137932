import PropTypes from 'prop-types'
import { Text, IconButton } from '@leroy-merlin-br/backyard-react'
import { Badge } from '@backyard-ui/core'

import emitter from 'scripts/utils/emitter'
import { getDescriptionDiscount } from 'scripts/utils/discount-by-payment-method'
import { ExpressDeliveryTag } from 'scripts/react-components/shared/components/NewProductThumb/components'

import { ServicesCard } from 'shared/components/ServicesCard'
import { Installments } from 'shared/components/Installments'

import { FormatPrice, PrefixPrice } from 'utils/Price'

import { default as QuantityButton } from 'checkout/containers/ProductQuantity'
import { isServicesEnabled } from 'checkout/modules/feature'
import * as dataLayer from 'checkout/services/data-layer'

import * as tracker from 'frontendCheckout/tracker'

import * as S from './styled'

const Details = ({
  regionId,
  type,
  removeProduct,
  isMobile,
  updateProduct,
  services = [],
  availableServices = [],
  productRemovalIsDisabled = false,
  ...product
}) => {
  const {
    brand,
    categoryTree,
    id,
    name,
    packaging,
    packagingCount,
    price: { from: priceFrom, to: priceTo, total: priceTotal },
    productUrl,
    quantity,
    productId,
    skuId,
    shopName,
    storeId,
    translatedUnit,
    discountByPaymentMethod,
    installments,
    isExpressDelivery,
    expressDeliveryDay,
    isSoldOut
  } = product

  const hasService = availableServices.find(
    service => service.productId === productId
  )

  const removeServiceFromDataLayer = () => {
    const service = services.find(
      item => item.relatedProduct.productId === productId
    )

    if (service) {
      dataLayer.removeFromCart({
        category: service.serviceBankName,
        price: service.price?.to,
        productBrand: 'Serviço',
        productId: service.serviceId,
        productName: service.name,
        quantity: service.quantity,
        regionId
      })

      tracker.pushRemoveFromCart({
        pageType: 'minicart',
        product: {
          id: service.serviceId,
          productId: service.relatedProduct.productId,
          name: service.name,
          price: service.price?.to,
          discount: 0,
          discountType: undefined,
          brand: 'Leroy Merlin',
          categoryTree: 'serviço',
          seller: 'Leroy Merlin',
          quantity: service.quantity
        },
        deliveryType: 'delivery',
        zipCode: ''
      })
    }
  }

  const handleRemoveProduct = event => {
    event.preventDefault()

    emitter.emit('cart:removing', id)

    removeProduct({ ...product, regionId })
    removeServiceFromDataLayer()
  }

  const errorWhenChangeQuantity = (data, deltaValue) => {
    const itemWithInsufficientStock =
      data.errors?.length >= 1 &&
      data.errors.filter(error => error.toLowerCase().includes('estoque'))
        .length > 0

    const eventAction =
      deltaValue > 0 ? 'clique_adicionar_item' : 'clique_remover_item'
    if (itemWithInsufficientStock) {
      dataLayer.errorInMiniCartContext(eventAction, 'Problema de estoque')
      return
    }

    dataLayer.errorInMiniCartContext(
      eventAction,
      'Erro ao alterar a quantidade de um item'
    )
  }

  return (
    <S.Wrapper>
      <S.Header>
        <S.AnchorTitle href={productUrl} title={name} data-testid="name">
          {name}
        </S.AnchorTitle>

        <IconButton
          appearance="critical"
          variant="ghost"
          size="kilo"
          onClick={handleRemoveProduct}
          isDisabled={productRemovalIsDisabled}
          data-cy="button-remove-item-minicart"
          data-testid="button-remove-item-minicart"
        >
          <i className="glyph glyph-trash-can" />
        </IconButton>
      </S.Header>

      <S.Content>
        <div>
          {isServicesEnabled && hasService && (
            <S.BadgeWrapper>
              <ServicesCard>
                Serviço de instalação disponível nesse produto. Clique em "
                <strong>Ir para o carrinho</strong>" para consultar preço e
                condições.
              </ServicesCard>
            </S.BadgeWrapper>
          )}
          {priceFrom && priceFrom !== priceTo && (
            <S.Price>
              <Text
                as="span"
                size="kilo"
                decoration="line-through"
                align="left"
                color="n400"
                data-cy="from"
                data-testid="from"
              >
                <FormatPrice
                  quantity={1}
                  unit={translatedUnit}
                  price={priceFrom}
                />
              </Text>
            </S.Price>
          )}

          {priceTo && (
            <S.Price>
              <Text
                as="span"
                size="kilo"
                align="left"
                color="n400"
                data-cy="to"
                data-testid="to"
              >
                <FormatPrice
                  quantity={1}
                  unit={translatedUnit}
                  price={priceTo}
                />
                {discountByPaymentMethod &&
                  ' ' + getDescriptionDiscount(discountByPaymentMethod)}
              </Text>
            </S.Price>
          )}

          <Text
            as="strong"
            size="mega"
            color="n800"
            isBold
            data-cy="total"
            data-testid="total"
          >
            <PrefixPrice
              packaging={packaging}
              packagingCount={packagingCount}
            />
            <FormatPrice
              quantity={quantity}
              unit={translatedUnit}
              price={priceTotal}
            />
            {discountByPaymentMethod &&
              ' ' + getDescriptionDiscount(discountByPaymentMethod)}
          </Text>
          {installments && (
            <Text
              size="kilo"
              color="p600"
              data-cy="installments"
              data-testid="installments"
            >
              <Installments
                isBranded={false}
                installments={installments}
                showOneInstallment
              />
            </Text>
          )}
        </div>
      </S.Content>

      <S.Footer>
        <QuantityButton
          pageType="minicart"
          brand={brand}
          name={name}
          categoryTree={categoryTree}
          id={id}
          productId={productId}
          regionId={regionId}
          type={type}
          shopName={shopName}
          quantity={quantity}
          packaging={packaging}
          packagingCount={packagingCount}
          skuId={skuId}
          storeId={storeId}
          price={{ to: priceTo, from: priceFrom }}
          discountByPaymentMethod={discountByPaymentMethod}
          updateProduct={updateProduct}
          onError={errorWhenChangeQuantity}
        />

        <S.Badges>
          {type === 'delivery' && (
            <Badge variant="subtle" appearance="warning">
              Entrega
            </Badge>
          )}

          {type === 'pickupInStore' && (
            <Badge variant="subtle" appearance="secondary">
              Retirar
            </Badge>
          )}
          {isExpressDelivery && (
            <ExpressDeliveryTag
              isSoldOut={isSoldOut}
              deliveryDate={expressDeliveryDay}
            />
          )}
        </S.Badges>
      </S.Footer>
    </S.Wrapper>
  )
}

Details.propTypes = {
  regionId: PropTypes.string,
  type: PropTypes.string,
  removeProduct: PropTypes.func,
  isMobile: PropTypes.bool,
  updateProduct: PropTypes.func,
  services: PropTypes.array,
  availableServices: PropTypes.array,
  product: PropTypes.shape({
    brand: PropTypes.string,
    categoryTree: PropTypes.string,
    externalId: PropTypes.number,
    id: PropTypes.string,
    name: PropTypes.string,
    packaging: PropTypes.number,
    packagingCount: PropTypes.number,
    packagingUnitLabels: PropTypes.shape({
      singular: PropTypes.string,
      plural: PropTypes.string
    }),
    price: PropTypes.shape({
      discount: PropTypes.number,
      from: PropTypes.number,
      to: PropTypes.number,
      total: PropTypes.number
    }),
    productId: PropTypes.number,
    productUrl: PropTypes.string,
    quantity: PropTypes.number,
    shopName: PropTypes.string,
    skuId: PropTypes.string,
    storeId: PropTypes.string,
    thumbUrl: PropTypes.string,
    translatedUnit: PropTypes.string,
    unit: PropTypes.string,
    installments: PropTypes.shape({
      amount: PropTypes.number,
      value: PropTypes.string,
      totalPrice: PropTypes.string
    })
  })
}

export default Details
