import styled from '@emotion/styled'
import { css } from '@emotion/react'

const fileDragFieldStyles = ({ theme }) => css`
  label: file;

  border: 2px dashed ${theme.colors.p600};
  border-radius: ${theme.borderRadius.giga};
  width: 100%;
  height: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${theme.spacings.bit};
`

const fileStyles = css`
  label: file__input;

  visibility: hidden;
  position: absolute;
`

const fileLabelStyles = ({ theme, hasError = false }) => css`
  label: file__label;

  font-weight: ${theme.font.weight.semibold};
  max-width: 65%;
  display: inline-block;
  font-size: ${theme.typography.subHeadings.mega.fontSize};
  line-height: ${theme.typography.subHeadings.mega.lineHeight};
  color: ${hasError ? theme.colors.danger : theme.colors.p600};
`

const ellipsisStyles = ({ ellipsis = true }) =>
  ellipsis &&
  css`
    label: file__label--ellipsis;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `

const checkStyles = ({ theme, check = false }) =>
  check &&
  css`
    label: file__label--checked;

    &:before {
      display: none;
      content: ' ';
      border: 2px solid ${theme.colors.p600};
      width: 9px;
      height: 12px;
      display: inline-block;
      margin-right: ${theme.spacings.byte};
      border-left: transparent;
      border-top: transparent;
      transform: rotate(30deg);
    }
  `

const buttonCloseStyles = ({ theme }) => css`
  label: file__button-close;

  width: 12px;
  height: 12px;
  display: block;
  margin-left: ${theme.spacings.kilo};
  cursor: pointer;
  fill: ${theme.colors.n400};
`

const messageStyles = ({ theme }) => css`
  label: document__message;

  margin-top: ${theme.spacings.byte};
`

export const FileDragField = styled('div')(fileDragFieldStyles)
export const File = styled('input')(fileStyles)
export const FileLabel = styled('span')(
  fileLabelStyles,
  ellipsisStyles,
  checkStyles
)
export const ButtonClose = styled('svg')(buttonCloseStyles)
export const Message = styled('div')(messageStyles)
