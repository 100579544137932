import { Component, Fragment } from 'react'
import classNames from 'classnames'

import emitter from 'scripts/utils/emitter'

import { getStockRequest } from 'shared/services/hybris/stock'

import SapStockList from './SapStockList'
import ContentStockAssistedSale from './ContentStockAssistedSale'
import Stock from '../Stock'

const DEFAULTS = {
  messages: {
    error: {
      title: 'Ops! Ocorreu o seguinte erro:',
      description: 'Não foi possível verificar a informação de estoque. Tente novamente mais tarde.'
    }
  }
}

class StockAssistedSale extends Component {
  constructor (props) {
    super(props)

    this.state = {
      drawerOpen: false
    }

    this.messages = DEFAULTS.messages
  }

  toggleDrawer = () => {
    this.setState((prevState) => {
      return { drawerOpen: !prevState.drawerOpen }
    })
  }

  hideStock = () => {
    this.toggleDrawer()
  }

  fetchStock = () => {
    const { lm, storeSlug } = this.props

    const { request } = getStockRequest({ id: lm, storeSlug })

    request()
      .then(({ data }) => this.onFetchSuccess(data))
      .catch(this.onFetchFailure)
  }

  onFetchSuccess = ({ stocks, unit }) => {
    this.setState(
      {
        ...stocks,
        unit
      }, this.toggleDrawer()
    )
  }

  onFetchFailure = (error) => {
    const title = this.messages.error.title
    const content = error.response?.data?.errors[0] || this.messages.error.description

    emitter.emit('stickyFeedback:error', { title, content })
    throw new Error(error)
  }

  infoContainerClasses = () => {
    return classNames(
      'stock-info-container drawer-padding assisted-sale-stock-container',
      {
        'with-map': this.state.selectedStore
      }
    )
  }

  closeMap = () => {
    this.setState({ selectedStore: null })
  }

  selectStore = (selectedStore) => {
    if (!selectedStore.code) {
      return
    }

    this.setState({
      selectedStore: {
        name: selectedStore.name,
        section: this.props.department,
        storeCode: selectedStore.code
      }
    })
  }

  render () {
    const {
      store, warehouses, otherStores, unit,
      drawerOpen, selectedStore
    } = this.state

    const {
      lm, department, region, showBatchSearchButton, buttonDisabled,
      storeSlug, isLocateOnStoreEnabled
    } = this.props

    return (
      <Stock
        closeMap={this.closeMap}
        section={department}
        drawerOpen={drawerOpen}
        fetchStock={this.fetchStock}
        hide={this.hideStock}
        storeSlug={storeSlug}
        lm={lm}
        selectedStore={selectedStore}
        contentClass={this.infoContainerClasses()}
        trackingInfo={{ lm, region }}
        drawerClass="stock-info-drawer assisted-sale-container"
        showBatchSearchButton={showBatchSearchButton}
        isLocateOnStoreEnabled={isLocateOnStoreEnabled}
        disabled={buttonDisabled}
      >
        <Fragment>
          <ContentStockAssistedSale
            store={store}
            warehouses={warehouses}
            unit={unit}
            hideStock={this.hideStock}
          />

          <SapStockList
            selectStore={this.selectStore}
            stores={otherStores}
            unit={unit}
            isLocateOnStoreEnabled={isLocateOnStoreEnabled}
          />
        </Fragment>
      </Stock>
    )
  }
}

export default StockAssistedSale
