import { Plus } from '@backyard-ui/icons'

const mobileWidth = 769

const WishlistAddButton = ({ onCreate }) => (
  <li className="wishlist-collection-item add-list">
    {window.innerWidth > mobileWidth && (
      <button className="color-primary" onClick={onCreate}>
        <Plus width={36} height={40} />
        <h2>Criar Nova Lista</h2>
      </button>
    )}
    {window.innerWidth < mobileWidth && (
      <button
        className="button button-primary wishlist-drawer-button no-shadow"
        onClick={onCreate}
      >
        Criar Nova Lista
      </button>
    )}
  </li>
)

export default WishlistAddButton
