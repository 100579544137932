import { Fragment } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Hidden, Visible } from '@leroy-merlin-br/backyard-react'

import { EmployeeNotification } from 'shared/components/EmployeeNotification'

import HeaderProviderMethods from 'site/Header/providers/HeaderProviderMethods'

import RenderDesktop from './components/DropdownMenu/DesktopMenu/components/RenderDesktop'
import RenderMobile from './components/DropdownMenu/MobileMenu/components/RenderMobile'
import { CartDrawerProvider } from './contexts/CartDrawerContext'
import * as S from './styled'

const Header = props => {
  const headerClass = classnames('new-header', {
    'is-black-weekend': props.isBlackWeekend,
    'is-black-weekend-menu': props.isBlackWeekendMenuEnabled
  })

  return (
    <S.Header>
      <div className={headerClass}>
        <HeaderProviderMethods
          assistedSalesLinks={props.assistedSalesLinks}
          algoliaSearchClient={props.algoliaSearchClient}
          isBlackWeekend={props.isBlackWeekend}
          isAutomaticLocationEnabled={props.isAutomaticLocationEnabled}
        >
          <CartDrawerProvider>
            <Visible when={['giga', 'tera']}>
              <RenderDesktop {...props} />
            </Visible>

            <Hidden when={['giga', 'tera']}>
              <RenderMobile {...props} />
            </Hidden>
          </CartDrawerProvider>
        </HeaderProviderMethods>
      </div>
      <EmployeeNotification />
    </S.Header>
  )
}

Header.propTypes = {
  props: PropTypes.shape({
    isAssistedSale: PropTypes.bool,
    isBlackWeekend: PropTypes.bool
  })
}

export default Header
