import { sendToApp } from 'scripts/react-components/utils/app/tracker'

const { protocol, hostname } = location

const trackingClickConsultButton = () => {
  sendToApp({
    event: 'download_app',
    properties: {
      step_name: 'cashback_click_consult'
    }
  })
}

export const clickOnConsult = (
  event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
) => {
  trackingClickConsultButton()
  redirectToPromotionValidate(event)
}

export const clickedTermsAndConditions = (
  event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
) => {
  event.preventDefault()

  const url =
    'https://leroy-production.s3.sa-east-1.amazonaws.com/cdn/terms/termos_condicoes_campanha_baixe_app.pdf'
  window.open(url)
}

const redirectToPromotionValidate = (
  event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
) => {
  event.preventDefault()
  window.location.replace(`${protocol}//${hostname}/promotion-app`)
}

export const changeModalToBeBottom = (
  element: string,
  positionModifier: number
) => {
  const modal = document.querySelector(element) as HTMLElement
  if (modal) {
    const heigthModal = modal.getBoundingClientRect().height
    const windowHeight =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight
    const marginTopCalculate = windowHeight - heigthModal - positionModifier
    modal.style.top = `${marginTopCalculate}px`
  }
}
