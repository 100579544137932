import styled from '@emotion/styled'
import { css } from '@emotion/react'

const titleWrapperStyles = ({ theme }) => css`
  label: title-wrapper;

  margin-bottom: ${theme.spacings.kilo};

  h3 {
    font-weight: ${theme.font.weight.regular};
  }
`

export const TitleWrapper = styled('div')(titleWrapperStyles)

const linkStyles = ({ theme }) => css`
  label: link;

  display: flex;
  gap: ${theme.spacings.byte};
  margin: ${theme.spacings.byte} 0;
  font-size: 14px;
  color: #333;

  :hover {
    text-decoration: underline;
  }

  & > svg {
    color: #72bf44;
    flex-shrink: 0;
  }
`
export const Link = styled('a')(linkStyles)

const wrapperStyles = ({ theme }) => css`
  label: wrapper;

  margin-top: ${theme.spacings.mega};
  margin-right: ${theme.spacings.mega};
`

export const Wrapper = styled('div')(wrapperStyles)
