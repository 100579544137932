import { useContext } from 'react'

import { EntryKeyContext } from '../../context/EntryKeyContext'
import { Select } from '../Facets/components'
import * as S from './styled'

const PLACEHOLDER = 'Selecionar ordem'

const Sorters = () => {
  const { sorters, onOptionClick, query } = useContext(EntryKeyContext)

  const findSelectedOption = searchValue => sorters.find(opt => opt.slug === searchValue)

  const handleChange = (_, selectedValue) => {
    const selectedItem = findSelectedOption(selectedValue || query?.order?.value)

    if (selectedItem) {
      onOptionClick({
        name: 'order',
        value: selectedItem.slug,
        type: 'sorter',
        dataLayerOptions: {
          sorterType: selectedItem.name
        }
      })
    }
  }

  const convertToName = value => {
    if (!value) {
      return PLACEHOLDER
    }

    return findSelectedOption(value).name
  }

  return (
    <S.Wrapper title={convertToName(query?.order?.value)}>
      <Select
        name={query?.order?.value}
        placeholder={PLACEHOLDER}
        onChange={handleChange}
        value={query?.order?.value || ''}
      >
        {sorters.map(option => (
          <option key={option.slug} value={option.slug}>
            {option.name}
          </option>
        ))}
      </Select>
    </S.Wrapper>
  )
}

export default Sorters
