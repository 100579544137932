import { HighlightMessage } from '../../types'
import Separator from '../Separator'

const Highlight = ({ title, message, ...rest }: HighlightMessage) => {
  return (
    <div {...rest}>
      <Separator>{title}</Separator>
      <div>{message}</div>
    </div>
  )
}

export default Highlight
