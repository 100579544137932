'use client'
import { AvailablePointsProps } from './Interface'

export const Points = ({ points }: AvailablePointsProps) => {
  return (
    <div className="flex h-12 flex-col justify-between items-start flex-1 gap-1 text-base text-[#7808CF]">
      <div className="w-full h-4 flex-shrink-0 text-xs font-semibold">
        Você tem
      </div>
      <div className="w-full h-8 flex-shrink-0 ">
        <span className="font-bold text-[32px]">
          {points?.toLocaleString('pt-BR')}{' '}
        </span>
        <span className="text-base font-normal">pts</span>
      </div>
    </div>
  )
}
