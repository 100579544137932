import qs from 'qs'
import cloneDeep from 'lodash/cloneDeep'
import isEmpty from 'lodash/isEmpty'

import {
  EXCLUSIVE_URL_BLOCKED_PARAMS,
  FILTERS_TO_BE_REMOVED,
  SWITCH_FILTERS_DICTIONARY
} from './constants'

const stringifyQuery = (
  query,
  { addQueryPrefix = true, ...restOptions } = {}
) => {
  const options = {
    encodeValuesOnly: true,
    skipNulls: true,
    ...restOptions,
    addQueryPrefix: false
  }

  const parsedQuery = cloneDeep(query)
  const paramsKeys = Object.keys(parsedQuery)

  const queryArray = paramsKeys
    .filter(key => !!parsedQuery[key].value)
    .map(key => {
      const param = parsedQuery[key]

      return qs.stringify(queryItem(key, param), options)
    })

  const queryString = queryArray.join('&')

  return queryString !== '' && addQueryPrefix ? `?${queryString}` : queryString
}

const queryItem = (key, param) => {
  switch (param.type) {
    case 'filter':
      return { filter: { [key]: param.value } }
    default:
      return { [key]: param.value }
  }
}

const objectifyQuery = queryString => {
  if (!queryString) return {}

  const queryArray = queryString.replace('?', '').split('&')

  const newQuery = queryArray.reduce((queryObject, param) => {
    const parsedParam = qs.parse(param)

    const isFilter = Boolean(parsedParam.filter)
    const isOrder = Boolean(parsedParam.order)

    const parsedObject = isFilter ? parsedParam.filter : parsedParam

    const [name, value] = Object.entries(parsedObject)[0]

    let type = 'other'

    if (isFilter) {
      type = 'filter'
    }

    if (isOrder) {
      type = 'sorter'
    }

    return {
      ...queryObject,
      [name]: {
        type,
        value
      }
    }
  }, {})

  return newQuery
}

const replacer = (str, oldSeparator, newSeparator) => {
  return str.replace(new RegExp(oldSeparator, 'g'), newSeparator)
}

const normalizePath = path => {
  if (!path) {
    return null
  }

  return path.charAt(0) === '/' ? path : `/${path}`
}

const exclusiveUrlToParams = (pathName, basePath) => {
  const exlusiveUrlArray = pathName
    .split('/')
    .filter(item => item && item !== basePath)

  if (exlusiveUrlArray.length < 1) {
    return {}
  }

  const key = exlusiveUrlArray[0]
  const value = exlusiveUrlArray.slice(1).join('/')

  if (value) {
    return {
      [key]: {
        value,
        type: 'filter'
      }
    }
  }

  if (Object.keys(SWITCH_FILTERS_DICTIONARY).some(filter => filter === key)) {
    return {
      [key]: {
        value: SWITCH_FILTERS_DICTIONARY[key],
        type: 'filter'
      }
    }
  }

  switch (key) {
    case 'melhor-preco':
    case 'menor-preco':
      return {
        order: {
          value: key,
          type: 'sorter'
        }
      }

    default:
      return {
        term: {
          value: replacer(key, '-', ' '),
          type: 'other'
        }
      }
  }
}

const paramsToExclusiveUrl = query => {
  const clonedQuery = cloneDeep(query)

  if (isEmpty(clonedQuery)) {
    return {
      exclusiveUrl: '',
      restQuery: {}
    }
  }

  const exclusiveKey = Object.keys(clonedQuery).find(
    key =>
      !EXCLUSIVE_URL_BLOCKED_PARAMS.includes(key) &&
      clonedQuery[key].type !== 'other'
  )

  if (!exclusiveKey) {
    return {
      exclusiveUrl: '',
      restQuery: clonedQuery
    }
  }

  const { value, type } = clonedQuery[exclusiveKey]

  delete clonedQuery[exclusiveKey]

  let exclusiveUrl
  let path

  switch (type) {
    case 'filter':
      path = Object.keys(SWITCH_FILTERS_DICTIONARY).some(filter => filter === exclusiveKey)
        ? exclusiveKey
        : `${exclusiveKey}/${value}`
      exclusiveUrl = normalizePath(path)
      break

    default:
      exclusiveUrl = normalizePath(value)
      break
  }

  return {
    exclusiveUrl,
    restQuery: clonedQuery
  }
}

/**
 *
 * @param {object} { location } react router prop
 * @param {string} { basePath } base path name from EntryKey prop
 */
const urlToObject = ({ location, url: basePath }) => {
  const exclusiveUrlParams = exclusiveUrlToParams(location.pathname, basePath)

  const parsedQuery = objectifyQuery(location.search)

  if (isEmpty(exclusiveUrlParams) && isEmpty(parsedQuery)) {
    return {}
  }

  return {
    ...exclusiveUrlParams,
    ...parsedQuery
  }
}

/**
 *
 * @param {string} basepath
 * @param {object} query { order: string, filter: object, page: int }
 */
const buildPath = (basepath, query = {}, isMappedSearch) => {
  const pathname = normalizePath(basepath)
  const clonedQuery = cloneDeep(query)

  if (!basepath) {
    return {
      pathname: '',
      search: stringifyQuery(clonedQuery)
    }
  }

  if (isEmpty(clonedQuery)) {
    return { pathname }
  }

  if (isMappedSearch) {
    const termValue = clonedQuery.term.value
    delete clonedQuery.term

    return {
      pathname: replacer(termValue, ' ', '-'),
      search: stringifyQuery(clonedQuery)
    }
  }

  if (!isEmpty(clonedQuery)) {
    const { exclusiveUrl, restQuery } = paramsToExclusiveUrl(clonedQuery)

    return {
      pathname: pathname + exclusiveUrl,
      search: stringifyQuery(restQuery)
    }
  }
}

const removeFilterQueries = query => {
  const queryArray = Object.entries(query)

  const newQuery = queryArray.reduce((object, item) => {
    const key = item[0]
    const value = item[1]

    const shouldBeRemoved =
      FILTERS_TO_BE_REMOVED.includes(value.type) || key === 'page'

    if (!shouldBeRemoved) {
      object[key] = value
    }

    return object
  }, {})

  return newQuery
}

const buildPagination = ({
  query,
  currentPage,
  pageCount,
  baseUrl,
  isMappedSearch
}) => {
  if (!currentPage || !pageCount) return {}
  const hasNextPage = currentPage < pageCount
  const hasPrevPage = currentPage >= 2
  const result = {}
  const buildPaginationURL = pageQuery => {
    const { pathname = window.location.pathname, search = '' } = buildPath(
      baseUrl,
      { ...query, ...pageQuery },
      isMappedSearch
    )
    return `${origin}${normalizePath(pathname)}${search}`
  }
  if (hasNextPage) {
    result.nextPage = buildPaginationURL({
      page: { value: currentPage + 1, type: 'other' }
    })
  }
  if (hasPrevPage) {
    result.prevPage = buildPaginationURL({
      page: { value: currentPage - 1, type: 'other' }
    })
  }
  return result
}

export {
  stringifyQuery,
  objectifyQuery,
  exclusiveUrlToParams,
  paramsToExclusiveUrl,
  buildPath,
  urlToObject,
  replacer,
  removeFilterQueries,
  buildPagination,
  normalizePath
}
