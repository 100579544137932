import PropTypes from 'prop-types'

import * as S from './styled'

const ImageThumbnail = props => {
  const {
    src,
    alt,
    href = null,
    responsiveSizes = {},
    noBorder = false,
    Component = null
  } = props

  const renderImg = () => {
    if (href) {
      return (
        <a href={href}>
          {Component || <S.Image src={src} alt={alt} />}
        </a>
      )
    }

    return Component || <S.Image src={src} alt={alt} />
  }

  return (
    <S.Wrapper sizes={responsiveSizes} noBorder={noBorder}>
      {renderImg()}
    </S.Wrapper>
  )
}

ImageThumbnail.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  href: PropTypes.string,
  responsiveSizes: PropTypes.object,
  noBorder: PropTypes.bool,
  Component: PropTypes.node
}

export default ImageThumbnail
