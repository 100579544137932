import { Fragment } from 'react'

import BuyButton from './BuyButton'

const renderPrice = ({ integers, decimals }) => (
  <Fragment>
    R$
    <span className="price-integers">
      {integers?.toLocaleString('pt-BR')}
    </span>
    ,{decimals?.toString().padStart(2, '0')}
  </Fragment>
)

const renderPromotionalPrice = (price, unit) => {
  const { to, from } = price

  if (from) {
    return (
      <Fragment>
        <div className="offers-price-badge-to">
          {renderPrice(to)} {unit}
        </div>
        <div className="offers-price-badge-from">
          {renderPrice(from)} {unit}
        </div>
      </Fragment>
    )
  }

  return (
    <div className="offers-price-badge-to">
      {renderPrice(to)} {unit}
    </div>
  )
}

const PriceBadge = ({ price, unit }) => (
  <div className="offers-price-badge">
    <div className="prices">
      {renderPromotionalPrice(price, unit)}
    </div>
    <BuyButton />
  </div>
)

export default PriceBadge
