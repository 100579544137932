import styled from '@emotion/styled'
import { css } from '@emotion/react'

const addressWrapperStyles = ({ theme }) => css`
  label: address-wrapper;

  background-color: ${theme.colors.background};

  padding: ${theme.spacings.kilo};
  margin-bottom: ${theme.spacings.byte};
`

export const AddressWrapper = styled('div')(addressWrapperStyles)
