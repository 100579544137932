import { memo, Fragment, FocusEvent, ClipboardEvent, ChangeEvent } from 'react'
import {
  Button,
  Hidden,
  Stack,
  Text,
  Checkbox
} from '@leroy-merlin-br/backyard-react'
import { PasswordField } from 'user/signup/components'
import { InputLoading, Popover } from 'user/components'
import { prependBrazilCountryCode } from 'user/signup/utils'
import {
  BIRTHDATE_POPOVER,
  EMAIL_POPOVER,
  FAVORITE_STORE_POPOVER,
  PHONE_POPOVER
} from 'user/constants'
import {
  birthdateErrorMessage,
  handlePhoneFormat,
  phoneValidation
} from 'user/utils'

import { TextField, SelectField, NumberField } from 'shared/components'

import * as validator from 'utils/validators/validators'

import { DocumentPro } from 'lmcv/components'

import * as S from '../../styled'
import { PROFESSIONS } from './constants'
import { useFormPF } from './useFormPF'
import NewsInfo from '../NewsInfo'
import { UserInfo } from './useNewsLetterOptin'
import { OptionalLabel } from '../../../../../../shared/components/OptionalLabel'

export type FormPfProps = {
  verifyCodeStepOnSignup: boolean
  onNextStep?: () => void
}

const FormPF = (props: FormPfProps) => {
  const { verifyCodeStepOnSignup, onNextStep } = props

  const {
    clearErrors,
    control,
    register,
    setError,
    handleSubmit,
    watch,
    isSubmitting,
    classOccupation,
    stores,
    isProfessionalWatch,
    onFormSubmit,
    onFormInvalid,
    onSignupCompleted,
    errors,
    handleCheckDocument,
    isCheckingFiscalId
  } = useFormPF(verifyCodeStepOnSignup, onNextStep)

  function onSubmit(userInfo: UserInfo) {
    const userInfoWithFormattedPhone = {
      ...userInfo,
      phone: prependBrazilCountryCode(userInfo.phone)
    }

    onFormSubmit(userInfoWithFormattedPhone)
  }

  return (
    <Fragment>
      <DocumentPro
        open={classOccupation !== ''}
        onCancel={onSignupCompleted}
        onFinish={onSignupCompleted}
        classOccupation={classOccupation}
      />
      <form
        onSubmit={handleSubmit(onSubmit, onFormInvalid)}
        data-cy="signup-form"
      >
        <Stack space="mega">
          <Hidden when={['giga', 'tera']}>
            <Text size="giga" isBold noMargin>
              Faça seu cadastro e transforme as suas compras em{' '}
              <Text as="span" size="giga" color="v500" isBold>
                pontos
              </Text>{' '}
              e benefícios.
            </Text>
          </Hidden>

          <InputLoading isLoading={isCheckingFiscalId}>
            <NumberField
              name="fiscalId"
              label={
                <Popover
                  list={{
                    title: 'Seu CPF será usado para:',
                    items: [
                      'Te identificar',
                      'Acessar sua conta',
                      'Consultar pedidos',
                      'Analisar crédito',
                      'Realizar transações financeiras'
                    ]
                  }}
                >
                  CPF
                </Popover>
              }
              disabled={isCheckingFiscalId}
              rules={{
                required: 'Insira seu CPF',
                validate: async (value: string) =>
                  await handleCheckDocument(value)
              }}
              control={control}
              state={Boolean(errors.fiscalId) && 'invalid'}
              hint={
                <S.ErrorWithLinkWrapper
                  dangerouslySetInnerHTML={{ __html: errors.fiscalId?.message }}
                />
              }
              format="###.###.###-##"
              onChange={async ({ target }: ChangeEvent<HTMLInputElement>) =>
                await handleCheckDocument(target.value)
              }
            />
          </InputLoading>

          <TextField
            name="name"
            label={
              <Popover
                list={{
                  title: 'Seu Nome será usado para:',
                  items: ['Te identificar', 'Saber como podemos te chamar']
                }}
                info="Se você preferir, pode informar seu nome social com sobrenome, ok?"
              >
                Nome completo
              </Popover>
            }
            rules={{
              required: 'Insira seu nome',
              validate: (value: string) =>
                validator.isValidName(value) || 'Nome inválido'
            }}
            control={control}
            state={Boolean(errors.name) && 'invalid'}
            hint={errors.name?.message}
          />
          <NumberField
            name="loyalty.birthdate"
            label={
              <Popover list={BIRTHDATE_POPOVER.list}>
                <OptionalLabel>{BIRTHDATE_POPOVER.label}</OptionalLabel>
              </Popover>
            }
            rules={{
              validate: (value: string) =>
                !value || birthdateErrorMessage(value)
            }}
            control={control}
            state={Boolean(errors.loyalty?.birthdate) && 'invalid'}
            hint={errors.loyalty?.birthdate?.message}
            format="##/##/####"
            valueType="formatted"
          />
          <SelectField
            name="loyalty.favoriteStore"
            label={
              <Popover list={FAVORITE_STORE_POPOVER.list}>
                {FAVORITE_STORE_POPOVER.label}
              </Popover>
            }
            options={stores}
            rules={{
              required: 'Escolha sua loja favorita'
            }}
            control={control}
            state={Boolean(errors.loyalty?.favoriteStore) && 'invalid'}
            hint={errors.loyalty?.favoriteStore?.message}
          />
          <TextField
            name="email"
            type="email"
            label={
              <Popover list={EMAIL_POPOVER.list}>{EMAIL_POPOVER.label}</Popover>
            }
            rules={{
              required: 'Insira seu e-mail',
              validate: (value: string) =>
                validator.isEmail(value) || 'E-mail inválido'
            }}
            control={control}
            autoComplete="new-password"
            state={Boolean(errors.email) && 'invalid'}
            hint={errors.email?.message}
          />
          {Boolean(watch('email')) && (
            <TextField
              name="emailConfirm"
              type="email"
              label="Confirmar e-mail"
              rules={{
                required: 'Insira seu e-mail',
                validate: (value: string) =>
                  value === watch('email') || 'Os e-mail precisam ser iguais'
              }}
              onPaste={(e: ClipboardEvent<HTMLInputElement>) => {
                e.preventDefault()

                return false
              }}
              control={control}
              autoComplete="new-password"
              state={Boolean(errors.emailConfirm) && 'invalid'}
              hint={errors.emailConfirm?.message}
            />
          )}
          <NumberField
            name="phone"
            label={
              <Popover list={PHONE_POPOVER.list}>{PHONE_POPOVER.label}</Popover>
            }
            rules={{
              required: 'Insira seu Celular',
              validate: (value: string) =>
                validator.isCellPhone(value) || 'Celular inválido'
            }}
            control={control}
            state={Boolean(errors.phone) && 'invalid'}
            hint={errors.phone?.message}
            format={handlePhoneFormat}
            onBlur={({ target }: FocusEvent<HTMLInputElement>) => {
              const error = phoneValidation(target.value)

              if (error.message) {
                return setError('phone', error)
              }

              clearErrors('phone')
            }}
          />
          <PasswordField
            name="password"
            label="Senha"
            rules={{
              required: 'Insira sua senha'
            }}
            control={control}
            autoComplete="new-password"
            state={Boolean(errors.password) && 'invalid'}
            hint={errors.password?.message}
          />

          <Checkbox
            {...register('loyalty.optInPro')}
            hint="Profissionais das áreas de engenharia, arquitetura, decoração, ou mesmo quem atua como pedreiro ou pedreira, etc tem vantagens únicas como carteira de clientes e acesso à eventos."
          >
            Sou profissional da construção civil
          </Checkbox>

          {isProfessionalWatch && (
            <SelectField
              name="loyalty.occupation"
              label="Qual sua profissão?"
              options={PROFESSIONS}
              rules={{
                required: 'Escolha sua profissão'
              }}
              control={control}
              state={Boolean(errors.loyalty?.occupation) && 'invalid'}
              hint={errors.loyalty?.occupation?.message}
            />
          )}

          <NewsInfo />

          <Button isStretch type="submit" isLoading={isSubmitting}>
            {verifyCodeStepOnSignup
              ? 'Concordar e validar e-mail'
              : 'Concordar e criar conta'}
          </Button>
        </Stack>
      </form>
    </Fragment>
  )
}

export default memo(FormPF)
