import { Visible, Hidden, Stack, Inline } from '@leroy-merlin-br/backyard-react'

import { Shell } from 'shared/components/Shell'

import { Widget } from '../Widget'
import * as S from './styled'

const ModulesLoading = ({ defaultFallback }) => {
  if (defaultFallback) {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: defaultFallback
        }}
      />
    )
  }

  return (
    <Widget noTopSpacing>
      <Visible when={['untilKilo', 'kilo']}>
        <S.Module>
          <Shell height={200} />
        </S.Module>

        <S.Module data-testid="shell">
          <Stack space="kilo">
            <Shell width={300} height={27} borderRadius="giga" />
            <Inline space="kilo" alignX="flex-start" wrap="nowrap">
              {Array(5)
                .fill('')
                .map((item, index) => (
                  <Shell
                    key={index}
                    width={125}
                    height={125}
                    borderRadius="giga"
                  />
                ))}
            </Inline>
          </Stack>
        </S.Module>

        <S.Module>
          <Stack space="kilo">
            <Shell width={300} height={27} borderRadius="giga" />
            <Shell height={200} />
            <Shell height={200} />
            <Shell height={200} />
          </Stack>
        </S.Module>
      </Visible>

      <Hidden when={['untilKilo', 'kilo']}>
        <S.Module>
          <Shell height={320} />
        </S.Module>

        <S.Module data-testid="shell">
          <Stack space="kilo">
            <Shell width={300} height={27} borderRadius="giga" />
            <Inline space="mega" alignX="flex-start" wrap="nowrap">
              {Array(9)
                .fill('')
                .map((item, index) => (
                  <Shell
                    key={index}
                    width={125}
                    height={125}
                    borderRadius="giga"
                  />
                ))}
            </Inline>
          </Stack>
        </S.Module>

        <S.Module>
          <Stack space="kilo">
            <Shell width={300} height={27} borderRadius="giga" />
            <S.ThreeBanners>
              {Array(3)
                .fill('')
                .map((item, index) => (
                  <Shell key={index} height={320} />
                ))}
            </S.ThreeBanners>
          </Stack>
        </S.Module>
      </Hidden>
    </Widget>
  )
}

export default ModulesLoading
