import Active from './Active'
import Completed from './Completed'
import Finished from './Finished'
import Pending from './Pending'

export const Profile = {
  Active,
  Completed,
  Finished,
  Pending
}
