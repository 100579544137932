import { Col, Row } from '@leroy-merlin-br/backyard-react'

import * as S from './styled'

const StoreDetail = ({
  locationImage,
  name,
  address,
  phones,
  schedule,
  url,
  tour,
  location
}) => (
  <Row>
    <Col size={{ giga: 12, tera: 6 }}>
      <a href={url}>
        <S.Title>{name}</S.Title>
        <S.Info>{address}</S.Info>
        <S.Info>Fone {phones}</S.Info>
        <S.Info>
          Segunda à sábado {schedule.weekdays[0]} - {schedule.weekdays[1]} /
          domingos e feriados {schedule.sunday[0]} - {schedule.sunday[1]}
        </S.Info>
      </a>
      <S.ActionWrapper>
        <S.Action
          href={tour}
          target="_blank"
          rel="noopener nofollow"
          data-tour-link
          disabled={!tour}
        >
          Tour 360°
        </S.Action>
        <S.Action
          href={`https://www.google.com/maps/dir/?api=1&destination=${location.lat},${location.lon}`}
          target="_blank"
          rel="noopener nofollow"
          data-directions-link
        >
          Como chegar
        </S.Action>
      </S.ActionWrapper>
    </Col>

    <Col size={{ giga: 12, tera: 6 }}>
      <S.Image data-testid="store-detail-image" src={locationImage} />
    </Col>
  </Row>
)

export default StoreDetail
