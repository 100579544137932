import PropTypes from 'prop-types'
import { Text, useBreakpoint } from '@leroy-merlin-br/backyard-react'
import React from 'react'

import { useRunOnce } from 'scripts/react-components/shared/hooks'

import { useIntersectionObserver } from 'utils/hooks/use-intersection-observer'

import { useBannersAds } from './hooks'
import Banner from '../../components/Banner'
import Skeleton from './Skeleton'
import * as S from './styled'
import { buildModularBannersFromAd } from './utils'

const RetailMediaBanner = props => {
  const {
    context,
    wrapper: Wrapper = React.Fragment,
    extra: {
      productId,
      categoryName,
      term,
      noContentPadding,
      inline,
      hideTitleOnMobile
    }
  } = props
  const userInfo = JSON.parse(sessionStorage.getItem('userInfo') ?? '{}')

  const { breakpoint } = useBreakpoint()
  const [ref, observerEntry] = useIntersectionObserver()

  const isMobile = ['untilKilo', 'kilo'].includes(breakpoint)

  const { isFetching: isFetchingBannersAds, result: bannersAds } =
    useBannersAds({
      context,
      productId,
      categoryName,
      term,
      sessionId: userInfo.userTrackingId,
      userId: userInfo.id,
      size: 'full_banner_sm_desktop'
    })

  const modularBanners = buildModularBannersFromAd(bannersAds)

  useRunOnce(() => {
    navigator.sendBeacon(modularBanners[0].retailMedia.impressionUrl)
  }, Boolean(modularBanners[0]))

  useRunOnce(() => {
    navigator.sendBeacon(modularBanners[0].retailMedia.viewUrl)
  }, Boolean(modularBanners[0]) && Boolean(observerEntry?.isIntersecting))

  if (isFetchingBannersAds) {
    return (
      <Wrapper>
        <Skeleton />
      </Wrapper>
    )
  }

  if (modularBanners.length < 1) {
    return <></>
  }

  return (
    <Wrapper>
      <S.Container
        ref={ref}
        noContentPadding={noContentPadding}
        inline={inline}
      >
        {!(isMobile && hideTitleOnMobile) && (
          <S.TextWrapper>
            <Text color="black">Publicidade</Text>
          </S.TextWrapper>
        )}
        <S.BannerContainer>
          <S.BannerWrapper>
            <Banner
              position={1}
              onClick={() => {
                navigator.sendBeacon(modularBanners[0].retailMedia.clickUrl)
              }}
              parentWidgetType={'full_banner'}
              {...modularBanners[0]}
            />
          </S.BannerWrapper>
        </S.BannerContainer>
      </S.Container>
    </Wrapper>
  )
}

RetailMediaBanner.propTypes = {
  wrapper: PropTypes.func,
  context: PropTypes.string,
  extra: PropTypes.shape({
    productId: PropTypes.number,
    categoryName: PropTypes.string,
    term: PropTypes.string,
    noContentPadding: PropTypes.bool,
    inline: PropTypes.bool,
    hideTitleOnMobile: PropTypes.bool
  })
}

export default RetailMediaBanner
