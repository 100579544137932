import { pushDataObject } from 'scripts/utils/data-layer'

import { getShipmentMessage } from 'catalog/utils/shipments-dictionary'

export const pushDataLayer = (
  postalCode,
  region,
  shipment,
  selectedSeller
) => {
  const { modality, cost, deadline } = shipment || {}
  const { shop } = selectedSeller || {}

  const { deadlineMessage } = getShipmentMessage(modality, deadline)
  const message = `${deadlineMessage.prefix} ${deadlineMessage.main}`

  const regionName = `${region.city} - ${region.stateCode}`

  const eventParams = {
    event: 'calculo-frete-pdp',
    CidadeEstado: regionName,
    CEP: postalCode,
    Seller: shop.name,
    'prazo-frete': message,
    value: cost || 'calculo-frete-carrinho'
  }

  pushDataObject(eventParams)
}

export const pushProductPageShipmentsError = error => {
  const eventLabel = shipmentsErrorsClassify(error)
  const dataLayerObject = {
    event: 'erros',
    'event-category': 'erros_pdp',
    'event-action': 'clique_calcular_frete',
    'event-label': eventLabel
  }
  pushDataObject(dataLayerObject)
}

const shipmentsErrorsClassify = error => {
  if (error.data?.message === 'Not Found') {
    return 'Funcionalidade não disponível na região'
  }

  if (error.data?.exception?.message === 'Invalid zip code format.') {
    return 'Cep inválido'
  }

  return 'Outros'
}
