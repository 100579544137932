import {
  formatPriceToDataLayer,
  pushDataObject,
  setProductImpressionItems
} from 'scripts/utils/data-layer'

import { GA_TRACK, TYPE } from 'entry-key/analytics/data-layer'

type Type = 'search' | 'mappedSearch' | 'entryKey'

type OnProductClickDataLayerProps = {
  product: any
  region?: string
  term: string
  index: number
  page: number
  inWishlist: boolean
}

type OnProductImpressionDataLayerProps = {
  products: any[]
  region?: string
  term: string
  page: number
}

const getCategoryList = (type: Type, term: string) => {
  if (!type) {
    return null
  }

  const pageType = {
    search: 'Busca',
    mappedSearch: 'Lista',
    entryKey: 'Chave de entrada'
  }

  return `${pageType[type]}: ${term}`
}

export const onProductImpressionDataLayer = ({
  products,
  region,
  term,
  page
}: OnProductImpressionDataLayerProps) => {
  const list = getCategoryList(TYPE as Type, term)

  const impressions = setProductImpressionItems(products, list as null)

  const data = {
    event: 'productListView',
    dimension14: region,
    paginacao: page,
    'ga-trackAtualizado': GA_TRACK,
    categoryList: list,
    ecommerce: {
      currencyCode: 'BRL',
      impressions
    }
  }

  pushDataObject(data)
}

export const onProductClickDataLayer = ({
  term,
  page,
  index,
  region,
  product,
  inWishlist
}: OnProductClickDataLayerProps) => {
  const list = getCategoryList(TYPE as Type, term)

  const hasInstallments =
    product.installments?.amount && product.installments?.value

  const data = {
    event: 'productListClick',
    dimension14: region,
    paginacao: page,
    categoryList: list,
    'ga-trackAtualizado': GA_TRACK,
    ecommerce: {
      click: {
        actionField: { list },
        products: [
          {
            name: product.name,
            id: product.id,
            price: formatPriceToDataLayer(product.price?.to),
            brand: product.brand,
            category: product.categoryTree,
            position: index + 1,
            dimension45: product.tag?.type,
            dimension19:
              hasInstallments &&
              `${product.installments.amount}x de R$${product.installments.value}`,
            dimension46: formatPriceToDataLayer(product.price?.from),
            dimension47: product.offer?.value,
            dimension48: Boolean(inWishlist),
            dimension49: product.soldBy
          }
        ]
      }
    }
  }

  pushDataObject(data)
}
