import styled from '@emotion/styled'
import { css } from '@emotion/react'

const wrapperStyles = ({ theme }) => css`
  display: flex;
  flex-direction: column;

  ${theme.media.giga} {
    flex-direction: row;
    margin-bottom: 15px;
  }
`

const cartSectionStyles = () => css`
  display: flex;
  flex: 2;
  flex-direction: column;
`

const titleStyles = ({ theme }) => css`
  color: #3C3738;
  font-size: 16px;
  font-weight: bold;
`

export const CartSection = styled('div')(cartSectionStyles)
export const Title = styled('p')(titleStyles)
export const Wrapper = styled('div')(wrapperStyles)
