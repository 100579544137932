import { Fragment } from 'react'

import { Collapse } from 'lmcv/components'

import { FAQ_DATA } from './constants'

const FaqList = () => (
  <Fragment>
    {FAQ_DATA.map(({ title, text }, index) => (
      <Collapse
        key={index}
        titleNumber={index + 1}
        title={title}
        content={text}
      />
    ))}
  </Fragment>
)

export default FaqList
