import { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { Text, Icon } from '@leroy-merlin-br/backyard-react'
import { ChevronDown, ChevronUp } from '@backyard-ui/icons'

import * as S from './styled'

const Collapse = ({ title, titleNumber, content }) => {
  const [active, setActive] = useState(false)
  const [height, setHeight] = useState('0')

  const containerContent = useRef(null)

  const toggleActive = () => {
    setActive(state => !state)
    setHeight(active ? 0 : `${containerContent.current.scrollHeight}px`)
  }

  return (
    <S.Wrapper>
      <S.TitleContainer onClick={toggleActive}>
        <Text noMargin>
          <Text as="span" isBold>
            {titleNumber}
            {'. '}
          </Text>
          {title}
        </Text>
        <Icon
          as={active ? ChevronUp : ChevronDown}
          size="kilo"
          color="primary"
        />
      </S.TitleContainer>

      <S.ContentContainer style={{ maxHeight: height }} ref={containerContent}>
        <S.Content
          dangerouslySetInnerHTML={{
            __html: content
          }}
        />
      </S.ContentContainer>
    </S.Wrapper>
  )
}

Collapse.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  titleNumber: PropTypes.number
}

export default Collapse
