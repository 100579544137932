import { FocusEvent, useCallback, useEffect, useState } from 'react'
import { Icon } from '@leroy-merlin-br/backyard-react'

import { useResize } from 'scripts/react-components/utils/hooks/use-resize'

import { pushConciergeItemList } from 'site/data-layers/chat'

import type { FormInput, Messaging } from './Components/ChatModal/types'
import messagesMock from './messages.mock'
import { cancelRequest, sendChatMessage } from './Services/Http'
import {
  checkCurrentMessageOptions,
  controlInteractionsFocus,
  embedOptionsMessage,
  getProductsFromMessage
} from './helpers'
import { ChatModal, ChatModalOptions, Tooltip, Icons } from './Components'
import * as S from './styled'
import type { PushMessage, RefreshOptionsPushMessage } from './types'

const Chat = () => {
  const [isMobile] = useResize()
  const [modalState, setModalState] = useState(() => {
    const savedModalState = localStorage.getItem('modalState')
    return savedModalState || 'closed'
  })
  const [isWaiting, setIsWaiting] = useState(false)
  const [isChatActive, setIsChatActive] = useState(true)
  const [isShowingCover, setIsShowingCover] = useState(false)
  const [messages, setMessages] = useState<Messaging.Message[]>(() => {
    // Recupera o histórico de mensagens do localStorage
    const savedMessages = localStorage.getItem('chatMessages')
    return savedMessages ? JSON.parse(savedMessages) : []
  })
  const [hasShownInactivityMessage, setHasShownInactivityMessage] =
    useState(false)

  useEffect(() => {
    localStorage.setItem('modalState', modalState)
    localStorage.setItem('chatMessages', JSON.stringify(messages))
  }, [modalState, messages])

  const handleSalesAssistantClick = () => {
    setModalState('chat')
  }

  const pushMessage: PushMessage = (pushedMessage) => {
    setMessages((previousMessages) => {
      const updatedMessages = [...previousMessages, pushedMessage]
      const lastThreeMessages = updatedMessages.slice(-5) // mantém as últimas 5 mensagens
      return lastThreeMessages
    })
  }

  const checkOptionsMessage: RefreshOptionsPushMessage = (
    currentMessage,
    selectedOption,
    newMessage
  ) => {
    setMessages((messages) => {
      const checkedOption = checkCurrentMessageOptions(
        messages,
        currentMessage,
        selectedOption
      )

      if (newMessage) {
        checkedOption.push(newMessage)
      }

      return checkedOption
    })
  }

  const onSubmit: FormInput.SubmitAction = async ({
    message: inputMessage
  }) => {
    const pushedMessage: Messaging.Message = {
      message: inputMessage,
      type: 'outcome'
    }

    pushMessage(pushedMessage)

    setIsWaiting(true)

    sendChatMessage(inputMessage, () => {
      let isStarted = false

      return {
        notify: (typing: string) => {
          const defaultMessage: Messaging.Message = {
            message: '_',
            type: 'income'
          }

          setMessages((currentMessages) => {
            let streammedMessage: Messaging.Message = defaultMessage

            if (isStarted) {
              streammedMessage = currentMessages.pop() ?? defaultMessage
            }

            isStarted = true
            streammedMessage.message = typing

            return [...currentMessages, streammedMessage]
          })
        },
        done: (lastData) => {
          if (lastData && lastData.data) {
            const products = getProductsFromMessage(lastData.data)

            if (products.length > 0) {
              pushConciergeItemList({
                products: products
              })
            }
          }

          setIsWaiting(false)
        }
      }
    })
  }

  const whenInactiveCallback = () => {
    if (hasShownInactivityMessage) return

    const inactivityMessage = messagesMock.getInactivityMessage(
      () => pushMessage(messagesMock.incomeHelpMessage),
      () => {
        cancelRequest('User satisfied')
        setIsChatActive(false)
        pushMessage(messagesMock.endMessage)
      }
    )

    pushMessage(embedOptionsMessage(inactivityMessage, checkOptionsMessage))
    setHasShownInactivityMessage(true)
  }

  const onBlur = (ev: FocusEvent): void => {
    if (isWaiting || !isChatActive) {
      return
    }

    controlInteractionsFocus(ev, whenInactiveCallback)
  }

  const onOpen = useCallback(() => {
    if (messages.length) {
      return
    }

    const welcomeMessages = [messagesMock.welcome].entries()

    const messagesListeningInterval = setInterval(() => {
      const currentMessage = welcomeMessages.next()
      if (currentMessage.done) {
        clearInterval(messagesListeningInterval)
        return
      }
      pushMessage(currentMessage.value[1])
    }, 500)
  }, [messages.length])

  useEffect(() => {
    if (modalState === 'chat') {
      onOpen()
    }
  }, [modalState, onOpen])

  const closeAction = () => {
    cancelRequest('Chat closed')
    setIsWaiting(false)
    setModalState('closed')
    setIsChatActive(true)
    setIsShowingCover(false)
    setMessages([])
    setHasShownInactivityMessage(false)
    localStorage.removeItem('chatMessages')
  }

  const onFeedbackSubmit = () => {
    setMessages((previousMessages) => {
      const currentMessages = previousMessages.filter(
        (message) => message.componentContent !== 'feedback'
      )
      const updatedMessages = [
        ...currentMessages,
        messagesMock.feedbackSubmitedMessage
      ]
      const lastThreeMessages = updatedMessages.slice(-5) // mantém as últimas 5 mensagens
      return lastThreeMessages
    })
  }

  if (isMobile) {
    return null
  }

  return (
    <S.ChatButtonWrapper data-testid="chat-button-wrapper" tooltipFade={false}>
      {modalState === 'chat' && (
        <ChatModal
          isActive={true}
          isShowingCover={isShowingCover}
          isOpen={true}
          isWaiting={isWaiting}
          onBlur={onBlur}
          onFocus={controlInteractionsFocus}
          onOpen={() => setModalState('chat')}
          onClose={closeAction}
          onHide={() => setModalState('closed')}
          onSubmitMessage={onSubmit}
          onFeedbackSubmit={onFeedbackSubmit}
          messages={messages}
        />
      )}

      {modalState === 'options' && (
        <ChatModalOptions
          onClose={closeAction}
          onSalesAssistantClick={handleSalesAssistantClick}
        />
      )}

      {modalState === 'closed' && (
        <Tooltip data-testid="chat-tooltip-wrapper">
          <S.ChatButton
            data-gtm-element-id="concierge-chat-balloon"
            title="chat-baloon"
            role="button"
            onClick={() => setModalState('options')}
          >
            <Icon as={Icons.ChatTyping} />
          </S.ChatButton>
        </Tooltip>
      )}
    </S.ChatButtonWrapper>
  )
}

export default Chat
