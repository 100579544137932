import emitter from 'scripts/utils/emitter'
import BaseComponent from 'scripts/components/base-component'

class DisableRelated extends BaseComponent {
  static componentName = 'disable-related'

  static DOMEvents = {
    click: 'disableRelatedField'
  }

  init () {
    this.reference = document.querySelector(`#${this.$element[0].dataset.disableReference}`)
  }

  disableRelatedField ({ target }) {
    if (target.checked) {
      this.reference.value = ''
      this.reference.removeAttribute('data-required')
      this.reference.setAttribute('disabled', true)
      return emitter.emit('validation:pristine', this.reference)
    }

    this.reference.setAttribute('data-required', true)
    this.reference.removeAttribute('disabled')
  }
}

export default ($el) => new DisableRelated($el).init()

export { DisableRelated as Component }
