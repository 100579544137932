import React, { createContext, useContext, useMemo } from 'react'
import { MarketplaceChat } from 'frontendMyOrders/plugins/Chat/MarketPlace'

type ContextType = {
  isRefactorEnabled: boolean
  isMarketplaceChatEnabled: boolean
  errors: string
  fiscalId: string
  saleOrderId: string
  plugins: {
    MarketplaceChat: React.ComponentType | null
  }
}

const MyOrdersContext = createContext<ContextType>({
  isRefactorEnabled: false,
  isMarketplaceChatEnabled: false,
  errors: '',
  fiscalId: '',
  saleOrderId: '',
  plugins: {
    MarketplaceChat: null
  }
})

type MyOrdersProviderProps = {
  isMarketplaceChatEnabled: boolean
  children: React.ReactNode
}

const MyOrdersProvider = ({
  isMarketplaceChatEnabled,
  children
}: MyOrdersProviderProps) => {
  const plugins = useMemo(() => {
    return {
      MarketplaceChat: isMarketplaceChatEnabled ? MarketplaceChat : null
    }
  }, [isMarketplaceChatEnabled])

  const store = useMemo(() => {
    return {
      isRefactorEnabled: false,
      isMarketplaceChatEnabled: isMarketplaceChatEnabled,
      errors: '',
      fiscalId: '',
      saleOrderId: '',
      plugins
    }
  }, [plugins, isMarketplaceChatEnabled])

  return (
    <MyOrdersContext.Provider value={store}>
      {children}
    </MyOrdersContext.Provider>
  )
}

export const useMyOrdersContext = () => {
  return useContext(MyOrdersContext)
}

export default MyOrdersProvider
