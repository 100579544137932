import $ from 'jquery'

import scroll from 'garden/src/js/utils/scroll'

import BaseComponent from 'scripts/components/base-component'

const DEFAULTS = {
  duration: 500,
  offset: -90
}

class ElementScroll extends BaseComponent {
  static componentName = 'element-scroll'

  static DOMEvents = {
    click: 'onElementClick'
  }

  constructor (element, options = {}) {
    super(element)

    this.options = $.extend({}, DEFAULTS, options)
  }

  init () {
    const offset = this.$element.attr('data-scroll-offset')

    this.$target = $(this.$element.attr('data-scroll-target'))
    this.scroll = scroll

    if (offset !== null && offset !== undefined) {
      this.options.offset = parseInt(offset, 10)
    }
  }

  onElementClick () {
    this.scroll(this.$target, this.options)
  }
}

export default ($el) => new ElementScroll($el).init()

export { ElementScroll as Component }
