export const handleServices = ({
  products,
  services
}) =>
  services?.map(service => {
    const resolve = {
      name: service.name,
      id: service.serviceId,
      price: parseFloat(service.price.total / service.quantity).toFixed(2),
      brand: 'Serviço',
      category: service.serviceBankName,
      quantity: service.quantity,
      dimension8: 'delivery',
      dimension15: 'Leroy Merlin',
      dimension31:
        products?.find(product => service.relatedProduct.id === product.id)
          ?.name || null
    }

    return resolve
  })
