export const Minus = () => (
  <svg
    width="8"
    height="2"
    viewBox="0 0 8 2"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 1.9427H8V0.470703H0V1.9427Z" fill="#158110" />
  </svg>
)
