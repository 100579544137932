import { memo } from 'react'

import * as S from './styled'
import { padTime } from '../../utils'

const Time = ({ label, time }) => (
  <S.TimeWrapper>
    <S.TimeElement>
      {padTime(time)}
      <span>{label}</span>
    </S.TimeElement>
  </S.TimeWrapper>
)

export default memo(Time)
