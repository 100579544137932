import type {
  DatalayerProduct,
  DeliveryAddress,
  Order,
  PaymentMethod,
  Service,
  Shippings,
  SummaryData
} from 'newCheckout/pages/Success/type'

import { getCartType, getShippingType } from 'frontendCheckout/tracker/utils'

import { pushDataObject } from '../../data-layer'
import { getPurchaseItems } from './utils'

export type PushPurchaseProps = {
  order: Order
  dataLayerProducts: DatalayerProduct[]
  shippings: Shippings
  services: Service[]
  address: DeliveryAddress
  paymentMethod: PaymentMethod
  summaryData: SummaryData
}

export const pushPurchase = (props: PushPurchaseProps) => {
  const {
    order,
    dataLayerProducts,
    shippings,
    services,
    address,
    paymentMethod,
    summaryData
  } = props

  const dataLayerItems = getPurchaseItems({
    address,
    dataLayerProducts,
    shippings,
    services
  })

  const hasInstallments =
    paymentMethod.type === 'credit_card' || paymentMethod.type === 'token'

  const shopNameItems = dataLayerProducts.map((item) => item.shopName)
  const cartType = getCartType(shopNameItems)

  const shippingTypeItems = shippings.map((shipping) => shipping.type)
  const shippingType = getShippingType(shippingTypeItems)

  pushDataObject({
    _clear: true,
    event: 'purchase',
    items: dataLayerItems,
    transaction: {
      id: order._id,
      value: summaryData.subtotal + summaryData.totalServices,
      discount: order.discount ? order.discount : undefined,
      payment: {
        method: paymentMethod.type,
        installments: hasInstallments
          ? paymentMethod.amount_installment
          : undefined
      }
    },
    shipping: {
      price: summaryData.freight ? summaryData.freight : undefined,
      type: shippingType
    },
    cart: {
      type: cartType
    }
  })
}
