'use client'
import { Time } from 'frontendCheckout/assets/icons'

import { TagProps } from './Interface'

export const Tag = ({ date }: TagProps) => {
  return (
    <div className=" flex items-center gap-1 bg-yellow-500 rounded-3xl text-sm p-2 py-1 font-semibold">
      <Time /> {date}
    </div>
  )
}
