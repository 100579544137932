export const API_ENDPOINTS = {
  cashback: 'api/v2/loyaltyProgram/cashback',
  base: '/api/v2/loyaltyProgram',
  profile: '/api/v2/user/profile',
  professional: '/api/v2/loyaltyProgram/customer/relationship',
  regions: '/api/boitata/v1/regions',
  profileLoyalty: '/api/v2/loyaltyProgram/profile',
  profileBalance: '/api/v2/loyaltyProgram/balance',
  coupon: '/api/v2/loyaltyProgram/vouchers',
  legalEntitySearch: '/api/boitata/v1/company/search',
  addressByCep: '/endereco',
  customers: '/api/v2/loyaltyProgram/relationship',
  customerInvite: 'api/v2/loyaltyProgram/relationship/invite',
  relationship: '/api/v2/loyaltyProgram/customer/relationship',
  stores: (region) => `/api/boitata/v1/regions/${region}/stores`,
  signUpInformation: (region) =>
    `/api/v2/loyaltyProgram/signUpInformation/${region}`,
  notifications: '/api/v2/loyaltyProgram/notifications',
  userResources: '/user-resources?suggestions=true',
  supplier: 'api/v2/loyaltyProgram/cashback/supplier',
  invoice: 'api/v2/loyaltyProgram/cashback/invoice',
  vouchers: (type) => {
    const voucherTypes = {
      money: '/api/v2/loyaltyProgram/vouchers/money',
      moneyActivated: '/api/v2/loyaltyProgram/vouchers/money/activated',
      advantages: '/api/v2/loyaltyProgram/advantages',
      active: '/api/v2/loyaltyProgram/vouchers/activate'
    }
    return voucherTypes[type]
  },
  banks: (all = '') => `/api/v3/servicenow/banks/${all}`,
  bankAgencies: (bankId, agencyName) => `/api/v3/servicenow/banks/${bankId}/agencies/${agencyName}`
}
