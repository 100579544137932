'use client'
import { CartMobile } from './mobile'
import { CartDesktop } from './desktop'

function Cart () {
  const isMobile = false

  if (isMobile) {
    return <CartMobile />
  }

  return <CartDesktop />
}

export default Cart
