type DiscountProps = {
  discount: string
  discountMethod: string
}

export const Discount = ({ discount, discountMethod }: DiscountProps) => {
  return (
    <div className="text-sm text-green-600 font-bold">
      {discount} {discountMethod}{' '}
    </div>
  )
}
