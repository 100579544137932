import { FC, ReactNode } from 'react'
import { Heading, Text, Stack } from '@leroy-merlin-br/backyard-react'

import * as S from './styled'

export type LayoutProps = {
  children: ReactNode
  description?: string | ReactNode
  text?: string
}

const MobileLayout: FC<LayoutProps> = ({ children, description, text }) => (
  <S.Wrapper>
    <Stack space="kilo">
      {description && (
        <Heading size="exa" noMargin>
          {description}
        </Heading>
      )}

      {text && <Text>{text}</Text>}

      {children}
    </Stack>
  </S.Wrapper>
)

export default MobileLayout
