import { useModal, Dialog, Button, Text } from '@leroy-merlin-br/backyard-react'

import { createUrlInvoice } from '../../utils/createUrlInvoice'
import * as S from './styled'

type SellersInvoiceModalProps = {
  saleOrderId: string
  sellersInvoice: SellerInvoice[]
  shouldShowInvoiceModal: boolean
}

type SellerInvoice = {
  shopId: number
  accessKey: string
  shopName: string
}

export const SellersInvoiceModal = (
  sellersInvoiceModalProps: SellersInvoiceModalProps
) => {
  const { saleOrderId, sellersInvoice, shouldShowInvoiceModal } =
    sellersInvoiceModalProps
  const { setModal } = useModal()

  const handleButtonClick = (accessKey: string) => {
    const url = createUrlInvoice(accessKey)

    window.open(url, '_blank')
  }

  const renderLinkAccessKey = (sellerInvoice: SellerInvoice) => {
    const { accessKey, shopName, shopId } = sellerInvoice

    return (
      <S.LinkSellerInvoice>
        <Text
          onClick={() => handleButtonClick(accessKey)}
          key={shopId}
          color="g600"
          decoration="underline"
        >
          {shopName ?? accessKey}
        </Text>
      </S.LinkSellerInvoice>
    )
  }

  const showModal = () => {
    if (!shouldShowInvoiceModal) {
      const [invoice] = sellersInvoice

      if (invoice?.accessKey) {
        const url = createUrlInvoice(invoice.accessKey)
        window.open(url, '_blank')
      }

      return
    }

    setModal({
      onClose: () => {},
      children: ({ onClose }) => (
        <Dialog
          title="Visualizar a Nota Fiscal"
          footer={
            <S.ButtonStyle>
              <Button
                appearance="critical"
                onClick={onClose}
                variant="link"
                size="mega"
              >
                Fechar
              </Button>
            </S.ButtonStyle>
          }
        >
          <Text>
            Você possui produtos de vendedores diferentes em seu pedido{' '}
            <strong>{saleOrderId}</strong>. Clique no vendedor para acessar a
            Nota Fiscal.
          </Text>
          {sellersInvoice.map((sellerInvoice) =>
            renderLinkAccessKey(sellerInvoice)
          )}
          <Text isBold size="kilo">
            A Nota Fiscal de produtos vendidos e entregues pela Leroy Merlin é
            encaminhada para seu e-mail
          </Text>
        </Dialog>
      )
    })
  }

  return <a className="invoice-button" onClick={showModal}>Acessar Nota Fiscal</a>
}
