import { Card, Stack, Text } from '@leroy-merlin-br/backyard-react'

import formatCep from 'utils/formatCep'

import TruckSVG from '../../images/Truck'
import PinSVG from '../../images/Pin'
import { Products } from '../Products'
import * as S from './styled'
import { DeliveryVisualType } from './type'

const Delivery = ({ shipping, services }: DeliveryVisualType) => {
  const { deliveryAddress } = shipping

  return (
    <Card>
      <Text isBold size="giga">
        Dados da Entrega
      </Text>
      <Text>
        Para ver mais detalhes e acompanhar sua compra, acesse a página
        <a href="/meus-pedidos">
          <Text isBold color="p700" as="span">
            {' '}
            Meus Pedidos{' '}
          </Text>
        </a>
        ou baixe nosso
        <a href="/aplicativo-leroy-merlin">
          <Text isBold color="p700" as="span">
            {' '}
            App Leroy Merlin
          </Text>
        </a>
        .
      </Text>
      <Stack space="byte">
        <S.Panel>
          <S.PanelFirstLine>
            <TruckSVG />{' '}
            <Text noMargin isBold>
              Entrega {shipping.deliveryType}
            </Text>
          </S.PanelFirstLine>
          <S.PanelSecondLine>
            <Text size="kilo" noMargin>
              {shipping.isScheduled && 'entrega agendada para o dia '}
              {shipping.time}{' '}
              {!shipping.isScheduled && (
                <Text as="span" size="kilo" color="n600">
                  após a confirmação do pagamento
                </Text>
              )}
            </Text>
          </S.PanelSecondLine>
        </S.Panel>
        <S.Panel>
          <S.PanelFirstLine>
            <PinSVG />{' '}
            <Text noMargin isBold>
              {deliveryAddress.street}, {deliveryAddress.number}
              {deliveryAddress.complement && ' ,'}
              {deliveryAddress.complement}
            </Text>
          </S.PanelFirstLine>
          <S.PanelSecondLine>
            <Text as="span" size="kilo" color="n600">
              {deliveryAddress.district} • {deliveryAddress.city} •{' '}
              {deliveryAddress.state} • CEP {formatCep(deliveryAddress.cep)}
            </Text>
          </S.PanelSecondLine>
        </S.Panel>
      </Stack>
      <Products items={shipping.items} services={services} />
    </Card>
  )
}

export default Delivery
