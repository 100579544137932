import { FunctionComponent } from 'react'
import { clamp } from 'lodash'
import { Stack } from '@backyard-ui/core'

import { Props } from './types'
import { StarIcon } from '../'

const StarRating: FunctionComponent<Props> = ({ rating, color = 'dark' }) => (
  <Stack direction="row" spacing="0.5" data-testid="star-rating">
    {new Array(5).fill({}).map((_, i) => (
      <StarIcon key={i} amount={clamp(rating - i, 0, 1)} color={color} />
    ))}
  </Stack>
)

export default StarRating
