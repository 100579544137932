import { ModalProvider } from '@leroy-merlin-br/backyard-react'

import reactComponentLoader from 'scripts/react-component-loader'

import { LocationFormModal } from './components/LocationFormModal'

const LocationFormModalProvider = () => (
  <ModalProvider>
    <LocationFormModal />
  </ModalProvider>
)

reactComponentLoader(LocationFormModalProvider, '[data-location-form]')
