import PropTypes from 'prop-types'

import { CouponLoading } from 'lmcv/components'

import * as S from './styled'

const CarouselSkeleton = ({ size = 3 }) => (
  <S.Carousel>
    {Array(size)
      .fill()
      .map((item, index) => (
        <S.CarouseItem key={`${item}-${index}`}>
          <CouponLoading key={`item-${index}`} />
        </S.CarouseItem>
      ))}
  </S.Carousel>
)

CarouselSkeleton.propTypes = {
  size: PropTypes.number
}

export default CarouselSkeleton
