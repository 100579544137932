import { css, keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import { styleHelpers } from '@leroy-merlin-br/backyard-react'

const wrapperStyled = () => css`
  label: combo-box;

  position: relative;
`

const optionsBoxStyles = ({ theme }) => css`
  label: options-box;

  z-index: ${theme.zIndex.modal};

  width: 100%;
  height: auto;

  overflow-y: auto;

  background-color: ${theme.colors.white};
  border-radius: ${theme.borderRadius.mega};
  ${styleHelpers.shadowSingle(theme)};
`

const itemBaseStyles = ({ theme }) => css`
  label: item-base;

  width: 100%;
  padding: ${theme.spacings.byte} ${theme.spacings.mega};

  text-align: left;
  font-size: ${theme.typography.subHeadings.mega.fontSize};
  line-height: ${theme.typography.subHeadings.mega.lineHeight};

  &:first-of-type {
    border-top-left-radius: ${theme.borderRadius.mega};
    border-top-right-radius: ${theme.borderRadius.mega};
  }

  &:last-of-type {
    border-bottom-left-radius: ${theme.borderRadius.mega};
    border-bottom-right-radius: ${theme.borderRadius.mega};
  }
`

const optionStyles = ({ theme }) => css`
  label: option;

  color: ${theme.colors.n900};

  cursor: pointer;
`

const optionActiveStyles = ({ isActive, theme }) =>
  isActive &&
  css`
    label: option--active;

    background-color: ${theme.colors.p100};
    color: ${theme.colors.black};
    font-weight: ${theme.font.weight.bold};
  `

const titleStyles = ({ theme }) => css`
  label: title;

  color: ${theme.colors.n600};
`

export const Wrapper = styled('div')(wrapperStyled)
export const OptionsBox = styled('ul')(optionsBoxStyles)
export const Title = styled('li')(itemBaseStyles, titleStyles)
export const Option = styled('li')(
  itemBaseStyles,
  optionStyles,
  optionActiveStyles
)
