export const BoxIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 5.94397C20.0097 5.87431 20.0097 5.80364 20 5.73397V5.65397C19.9828 5.59816 19.9593 5.54448 19.93 5.49397C19.917 5.46517 19.9002 5.43826 19.88 5.41397L19.78 5.28397L19.7 5.22397L19.58 5.13397L10.58 0.133975C10.428 0.0462062 10.2555 0 10.08 0C9.90446 0 9.73202 0.0462062 9.58 0.133975L0.58 5.13397L0.49 5.20397L0.38 5.28397C0.350949 5.31667 0.327316 5.35381 0.31 5.39397C0.278005 5.42267 0.250974 5.45646 0.23 5.49397C0.204035 5.53785 0.183867 5.58491 0.17 5.63397C0.164405 5.66707 0.164405 5.70088 0.17 5.73397C0.101246 5.79326 0.0436692 5.86438 0 5.94397V13.944C0.00129782 14.1222 0.050188 14.2968 0.141611 14.4497C0.233035 14.6027 0.36367 14.7284 0.52 14.814L9.52 19.814C9.56132 19.8381 9.60485 19.8582 9.65 19.874H9.75C9.91428 19.9138 10.0857 19.9138 10.25 19.874H10.35L10.49 19.814L19.49 14.814C19.6445 14.7271 19.7731 14.6008 19.8627 14.448C19.9523 14.2951 19.9997 14.1212 20 13.944V5.94397ZM10 9.81397L3.06 5.94397L5.82 4.42397L12.65 8.32397L10 9.81397ZM10 2.09397L16.94 5.94397L14.7 7.19397L7.87 3.28397L10 2.09397ZM2 7.64397L9 11.564V17.244L2 13.354V7.64397ZM11 17.244V11.564L14 9.88397V12.944L16 11.944V8.76397L18 7.65397V13.354L11 17.244Z"
      fill="#4C9F2B"
    />
  </svg>
)
