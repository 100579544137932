import {
  Dialog,
  Button,
  ButtonGroup,
  Text
} from '@leroy-merlin-br/backyard-react'

import * as S from './styled'

type ModalErrorProps = {
  onClick: () => void
  onClose: () => void
}

const ModalShareError = ({ onClick, onClose }: ModalErrorProps) => {
  return (
    <Dialog
      onClose={onClose}
      title="Você precisa ter clientes ativos para compartilhar cupons"
    >
      <S.Description>
        <Text color="n500" size="kilo" noMargin>
          Para enviar cupons, você precisa ter ao menos um Cliente ativo.
        </Text>
        <Text color="n500" size="kilo" noMargin>
          Você pode adicioná-lo na página Meus Clientes, assim que o seu Cliente
          aceitar o convite, você poderá enviar cupons de desconto.
        </Text>
      </S.Description>

      <ButtonGroup align="flex-end" label="">
        <Button onClick={onClick} appearance="primary">
          Ir para Meus Clientes
        </Button>
      </ButtonGroup>
    </Dialog>
  )
}

export default ModalShareError
