import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { Theme } from 'scripts/react-components/themes/Theme'

const wrapperStyles = ({ theme }: Theme) => css`
  label: contacts__card;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: auto;
  height: 75px;

  & p {
    margin-left: ${theme.spacings.byte};
  }

  & a {
    margin-left: ${theme.spacings.kilo};
    text-align: right;
  }
`

export const Wrapper = styled('div')(wrapperStyles)

const iconWrapperStyles = ({ theme }: Theme) => css`
  label: contacts__icon;

  margin-right: ${theme.spacings.byte};

  svg {
    width: 40px;
    height: ${theme.spacings.peta};
  }
`

export const IconWrapper = styled('div')(iconWrapperStyles)

const phoneIconWrapperStyles = ({ theme }: Theme) => css`
  label: contacts__phone-icon;

  margin-right: ${theme.spacings.byte};

  svg {
    width: 40px;
    height: 32px;
  }
`

export const PhoneIconWrapper = styled('div')(phoneIconWrapperStyles)

const flexStyles = () => css`
  label: contacts__flex;
  display: flex;
  align-items: center;
`
export const Flex = styled('div')(flexStyles)

const buttonWrapperStyles = ({ theme }: Theme) => css`
  label: contacts__button;
  margin-top: ${theme.spacings.kilo};
`

export const ButtonWrapper = styled('div')(buttonWrapperStyles)

const cancelButtonWrapperStyles = ({ theme }: Theme) => css`
  label: contacts__cancel-button;

  margin-top: ${theme.spacings.kilo};
`

export const CancelButtonWrapper = styled('div')(cancelButtonWrapperStyles)
