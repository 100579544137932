import styled from '@emotion/styled'
import { css } from '@emotion/react'

const contentStyle = () => css`
  label: content;

  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
`
export const Content = styled('div')(contentStyle)

const headerStyles = () => css`
  label: header;

  margin-top: -70px;
`
export const Header = styled('div')(headerStyles)

const dividerStyle = ({ theme }) => css`
  label: divider;

  background: ${theme.colors.n100};
  display: block;
  height: 1px;
  margin: ${theme.spacings.giga} auto;
  max-width: 160px;
  width: 100%;
`

export const Divider = styled('div')(dividerStyle)

const textContainerStyle = ({ theme }) => css`
  label: text__container;

  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: ${theme.spacings.tera};
`

export const TextContainer = styled('div')(textContainerStyle)

const dateContainerStyle = () => css`
  label: date__container;

  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 70px;
`
export const DateContainer = styled('div')(dateContainerStyle)
