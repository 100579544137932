import PropTypes from 'prop-types'
import { Card } from '@leroy-merlin-br/backyard-react'

import { DropDown } from 'lmcv/components'

import { Header } from './components/Header'
import ProjectDate from './components/ProjectDate'
import * as S from './styled'

function ProfileActive ({ data, handleDropDown }) {
  const { professionalName, projectName, status, expireAt } = data

  return (
    <Card>
      <S.Menu>
        <DropDown>
          <DropDown.Item text="Excluir profissional" onClick={handleDropDown} />
        </DropDown>
      </S.Menu>

      <S.Content>
        <Header
          professionalName={professionalName}
          projectName={projectName}
          projectStatus={status}
        />

        <S.Divider />

        {expireAt && (
          <ProjectDate title={'Término previsto para'} data={expireAt} />
        )}
      </S.Content>
    </Card>
  )
}

ProfileActive.propTypes = {
  handleDropDown: PropTypes.func.isRequired,
  onClickButton: PropTypes.func
}

export default ProfileActive
