import { FC } from 'react'

import CreateNewPassword from 'scripts/react-components/user/my-account/components/CreateNewPassword/CreateNewPassword'

import { Layout } from '../../Layout'

type NewPasswordProps = {
  onNextStep: () => void
}

const NewPassword: FC<NewPasswordProps> = ({ onNextStep }) => {
  return (
    <Layout>
      <CreateNewPassword onNextStep={ onNextStep } />
    </Layout>
  )
}

export default NewPassword
