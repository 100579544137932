import {
  formatPriceToDataLayer,
  pushDataObject,
  setProductImpressionItems
} from 'scripts/utils/data-layer'

export const getCategoryList = (type, term) => {
  if (!type) {
    return null
  }

  const pageType = {
    search: 'Busca',
    mappedSearch: 'Lista',
    entryKey: 'Chave de entrada'
  }

  return `${pageType[type]}: ${term}`
}

export const onProductImpressionDataLayer = ({
  products,
  region,
  gaTrack,
  type,
  term,
  page
}) => {
  const list = getCategoryList(type, term)

  const impressions = setProductImpressionItems(products, list)

  const data = {
    event: 'productListView',
    dimension14: region,
    paginacao: page,
    'ga-trackAtualizado': gaTrack,
    categoryList: list,
    ecommerce: {
      currencyCode: 'BRL',
      impressions
    }
  }

  pushDataObject(data)
}

export const onProductClickDataLayer = ({
  product,
  region,
  gaTrack,
  type,
  term,
  index,
  page,
  inWishlist
}) => {
  const list = getCategoryList(type, term)

  const hasInstallments = product.installments?.amount && product.installments?.value

  const data = {
    event: 'productListClick',
    dimension14: region,
    paginacao: page,
    categoryList: list,
    'ga-trackAtualizado': gaTrack,
    ecommerce: {
      click: {
        actionField: { list },
        products: [{
          name: product.name,
          id: product.id,
          price: formatPriceToDataLayer(product.price?.to),
          brand: product.brand,
          category: product.categoryTree,
          position: index + 1,
          dimension45: product.tag?.type,
          dimension19: hasInstallments && `${product.installments.amount}x de R$${product.installments.value}`,
          dimension46: formatPriceToDataLayer(product.price?.from),
          dimension47: product.offer?.value,
          dimension48: Boolean(inWishlist),
          dimension49: product.soldBy
        }]
      }
    }
  }

  pushDataObject(data)
}

export const onFilterDataLayer = ({
  gaTrack,
  pageType,
  filter,
  filterType,
  filterElement,
  isParamAlreadySet
}) => {
  const hasFilterType = filterType && (typeof filterType.index === 'number') && filterType.value
  const hasFilterElement = filterElement && (typeof filterElement.index === 'number') && filterElement.value

  const data = {
    event: 'clickFilter-Facet',
    filter,
    filterAction: !isParamAlreadySet,
    filterType: hasFilterType && `${filterType.index + 1}:${filterType.value}`,
    filterElement: hasFilterElement && `${filterElement.index + 1}:${filterElement.value}`,
    'ga-trackAtualizado': gaTrack,
    filterPagetype: pageType
  }

  pushDataObject(data)
}

export const onSorterDataLayer = ({
  gaTrack,
  sorterType,
  isParamAlreadySet
}) => {
  const data = {
    event: 'refineList',
    sorterAction: !isParamAlreadySet,
    tipoOrdenacao: sorterType,
    'ga-trackAtualizado': gaTrack
  }

  pushDataObject(data)
}
