import $ from 'jquery'
import dayjs from 'dayjs'
import DOMPurify from 'dompurify'
import errorsTemplate from 'templates/feedback-errors'

import scroll from 'garden/src/js/utils/scroll'
import Confirm from 'garden/src/js/components/confirm'

import emitter from 'scripts/utils/emitter'
import BaseComponent from 'scripts/components/base-component'
import { showSpinner, hideSpinner } from 'scripts/utils/spinner'

const INTERESTS_KEY = 'interests[interestAreaName]'

class Update extends BaseComponent {
  static componentName = 'update'

  static DOMEvents = {
    submit: 'onSubmitHandler'
  }

  init () {
    this.$loyaltProgramElement = this.$element.find('[data-loyalty-program]')
    this.$loyaltProgram = this.$loyaltProgramElement.data('loyalty-program')
    this.$loyaltyBirthdate = this.$element.find('[data-loyalty-birthdate]')
    this.components = this.getComponents()
  }

  getComponents () {
    return {
      validation: this.$element.data('validation'),
      scroll
    }
  }

  onSubmitHandler (e) {
    e.preventDefault()

    this.validate() ? this.confirmLoyaltyProgram() : this.scrollToError()
  }

  validate () {
    return this.components.validation.validateAll()
  }

  onSuccessBirthDate () {
    const message = $('<span>').html(
      'É necessário ter mais de 18 anos para participar do programa de fidelidade Leroy Merlin com você'
    )

    $('[data-helper="birthdate"]').html(message)
  }

  hasLoyaltyComponent () {
    return this.$element.find('[data-loyalty-component]').length
  }

  confirmLoyaltyProgram () {
    const options = {
      textMessage:
        'Ao sair do programa de fidelidade você perderá todos os benefícios acumulados.',
      textConfirmButton: 'Sair do Programa',
      textCancelButton: 'Continuar no Programa'
    }

    if (
      this.hasLoyaltyComponent() &&
      this.$loyaltProgram &&
      this.$loyaltProgramElement.is(':not(:checked)')
    ) {
      return new Confirm(this.sendForm.bind(this), options)
    }

    this.onSuccessBirthDate()
    this.sendForm(true)
  }

  sendForm (isReady) {
    if (!isReady) {
      return (
        !!this.$loyaltProgram &&
        this.$loyaltProgramElement.prop('checked', true)
      )
    }

    showSpinner()

    const dataArray = this.$element.serializeArray()

    const dataObject = dataArray.reduce((object, field) => {
      if (field.name === `${INTERESTS_KEY}[]`) {
        object[INTERESTS_KEY].push(field.value)
      } else {
        object[field.name] = field.value
      }

      return object
    }, { [INTERESTS_KEY]: [] })

    return $.post(
      this.$element.attr('data-ajax-update'),
      dataObject
    )
      .done(this.handleSuccess.bind(this))
      .fail(this.handleError.bind(this))
  }

  handleSuccess () {
    hideSpinner()
    const sanitizedName = DOMPurify.sanitize($('[name="name"]').val())

    if (
      this.hasLoyaltyComponent() &&
      !this.$loyaltProgramElement.is(':checked')
    ) {
      this.$element.find('[data-loyalty-component]').remove()
    }

    emitter.emit('stickyFeedback:success', {
      title: `Pronto, ${sanitizedName}!`,
      content: 'Informações atualizadas com sucesso.'
    })
  }

  handleError (data) {
    hideSpinner()

    emitter.emit('stickyFeedback:error', {
      title: 'Ops! Ocorreram os seguintes erros:',
      content: errorsTemplate({ errors: data.responseJSON.errors })
    })
  }

  scrollToError () {
    this.components.scroll(this.$element.find('.invalid').first(), {
      offset: -100
    })
  }
}

export default $el => new Update($el).init()

export { Update as Component }
