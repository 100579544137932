import { css } from '@emotion/react'
import styled from '@emotion/styled'

const itemStyles = ({ theme }) => css`
  label: category-carousel__item;

  display: block;
  width: 125px;
  color: ${theme.colors.n900};
`

export const Item = styled('a')(itemStyles)

const itemLabelStyles = () => css`
  label: category-carousel__item-label;

  word-break: break-word;
`

export const ItemLabel = styled('div')(itemLabelStyles)

const thumbSvgStyles = ({ theme, isSvg }) =>
  isSvg &&
  css`
    label: category-carousel__thumb-svg;

    background-color: ${theme.colors.b900};

    border: 1px solid ${theme.colors.n100};

    svg {
      label: thumb__icon;

      fill: ${theme.colors.white};
      height: 56px;
      width: 56px;
    }
  `

const thumbBlackWeekendStyles = ({ theme, isBlackWeekend }) =>
  isBlackWeekend &&
  css`
    label: category-carousel__thumb--black-weekend;

    background-color: ${theme.colors.black};
  `

const thumbnailStyles = ({ theme, width, height }) => css`
  label: category-carousel__thumb;

  border-radius: ${theme.borderRadius.giga};
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${width}px;
  width: ${height}px;
`

export const Thumbnail = styled('img')(
  thumbnailStyles,
  thumbSvgStyles,
  thumbBlackWeekendStyles
)
