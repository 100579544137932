import emitter from 'scripts/utils/emitter'

emitter.setMaxListeners(0)

class BaseComponent {
  constructor (element) {
    this.$element = element

    this.bindDOMEvents()
    this.bindEmitterEvents()
    this.registerComponent()
  }

  registerComponent () {
    this.$element.data(this.constructor.componentName, this)
  }

  bindDOMEvents () {
    this.bindEvents(this.constructor.DOMEvents, this.$element)
  }

  bindEmitterEvents () {
    this.bindEvents(this.constructor.emitterEvents, emitter)
  }

  bindEvents (events, listener) {
    if (!events) {
      return
    }

    Object.keys(events).forEach((event) => {
      const config = event.split(' ')
      const handler = events[event]

      listener.on(...config, this[handler].bind(this))
    })
  }
}

export default BaseComponent
