import { css } from '@emotion/react'
import styled from '@emotion/styled'

const wrapperStyles = ({ theme }) => css`
  label: shipment;

  margin-bottom: ${theme.spacings.kilo};
`

const productImageStyles = ({ canceled = false }) =>
  canceled &&
  css`
    label: shipment__product-image;

    filter: grayscale(1);
  `

const linkStyles = ({ theme }) => css`
  label: shipment__link;

  color: ${theme.colors.p600};

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`

const lockerPinWrapperStyles = ({ theme }) => css`
  label: locker-pin-wrapper;

  margin-bottom: ${theme.spacings.mega};
`

const badgeTextStyles = () => css`
  label: badge-text;

  white-space: nowrap;
`

const indentText = ({ theme }) => css`
  label: intent-text;

  margin-left: ${theme.spacings.kilo};
`

export const Wrapper = styled('div')(wrapperStyles)
export const Link = styled('a')(linkStyles)
export const ProductImage = styled('img')(productImageStyles)
export const CustomLink = styled('span')(linkStyles)
export const LockerPinWrapper = styled('div')(lockerPinWrapperStyles)
export const BadgeText = styled('span')(badgeTextStyles)
export const IndentText = styled('div')(indentText)
