import { createRoot } from 'react-dom/client'
import { flushSync } from 'react-dom'

export const renderTemplate = Component => {
  const div = document.createElement('div')
  const root = createRoot(div)

  flushSync(() => {
    root.render(Component)
  })

  const html = div.innerHTML

  root.unmount()

  return html
}
