import { Fragment, useState } from 'react'
import { Visible } from '@leroy-merlin-br/backyard-react'

import { useUserResources } from 'shared/hooks'

import { LoggedInBox, LoggedOutBox } from './components'

const MyAccount = () => {
  const [isEnabled, setIsEnabled] = useState(false)

  const { userResources, userControls, isLoadingResources } = useUserResources()

  const toggleDropdownUser = () => setIsEnabled(prev => !prev)

  const ShowUserName = () =>
    userControls?.isLoggedIn ? (
      <Fragment>
        <span>Olá,</span>
        <strong className="my-account-name-limit" title={userResources?.name}>
          {userResources?.name}
        </strong>
      </Fragment>
    ) : (
      <Fragment>
        <span>Entrar</span>
        <strong>Minha conta</strong>
      </Fragment>
    )

  return (
    <section className="my-account">
      <div
        className="my-account-group"
        onClick={() => !isLoadingResources && toggleDropdownUser()}
      >
        <i className="glyph glyph-silhouette"></i>
        <Visible when={['giga', 'tera']}>
          <div className="content">
            {userResources ? (
              <ShowUserName />
            ) : (
              <div className="my-account-loader">
                <span className="shell-navigation-loader" />
              </div>
            )}
          </div>
        </Visible>
      </div>
      {isEnabled && (
        <Fragment>
          <span className="my-account-overlay" onClick={toggleDropdownUser} />
          {userControls?.isLoggedIn ? <LoggedInBox /> : <LoggedOutBox />}
        </Fragment>
      )}
    </section>
  )
}

export default MyAccount
