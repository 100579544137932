export const LOYALTY_PROFILE_GET = '@LOYALTY_PROFILE_GET'
export const LOYALTY_PROFILE_GET_SUCCESS = '@LOYALTY_PROFILE_GET_SUCCESS'
export const LOYALTY_PROFILE_GET_FAILURE = '@LOYALTY_PROFILE_GET_FAILURE'

export const LOYALTY_PROFILE_SET_PREFERENCES =
  '@LOYALTY_PROFILE_SET_PREFERENCES'
export const LOYALTY_PROFILE_SET_PREFERENCES_SUCCESS =
  '@LOYALTY_PROFILE_SET_PREFERENCES_SUCCESS'
export const LOYALTY_PROFILE_SET_PREFERENCES_FAILURE =
  '@LOYALTY_PROFILE_SET_PREFERENCES_FAILURE'

export default {
  LOYALTY_PROFILE_GET,
  LOYALTY_PROFILE_GET_SUCCESS,
  LOYALTY_PROFILE_GET_FAILURE,
  LOYALTY_PROFILE_SET_PREFERENCES,
  LOYALTY_PROFILE_SET_PREFERENCES_SUCCESS,
  LOYALTY_PROFILE_SET_PREFERENCES_FAILURE
}
