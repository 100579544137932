import PropTypes from 'prop-types'

import SalesImpression from 'site/utils/dataLayer/Sales/SalesImpression'

import { useDataLayerPush } from 'utils/hooks/use-data-layer-push'

import { Widget } from '../components/Widget'
import { ProductsCarousel } from './ProductsCarousel'
const Carousel = ({
  partnerName,
  widget,
  withBackground = true,
  noContentPadding = true,
  noTopSpacing,
  onProductClick
}) => {
  const { type, products, title } = widget

  const callback = () =>
    new SalesImpression(products, type, title).sendImpressionToDataLayer()

  const dataLayerOptions = {
    callback: type ? callback : null
  }

  useDataLayerPush(dataLayerOptions)

  return (
    <Widget
      title={title}
      withBackground={withBackground}
      noContentPadding={noContentPadding}
      noTopSpacing={noTopSpacing}
    >
      <ProductsCarousel
        {...widget}
        partnerName={partnerName}
        handleClick={onProductClick}
      />
    </Widget>
  )
}

Carousel.propTypes = {
  partnerName: PropTypes.oneOf([
    'algolia',
    'algonomy',
    'elastic search',
    'retail media'
  ]),
  widget: PropTypes.shape({
    type: PropTypes.string,
    products: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        shortName: PropTypes.string.isRequired,
        unit: PropTypes.string.isRequired,
        inWishList: PropTypes.bool.isRequired,
        price: PropTypes.object.isRequired,
        installments: PropTypes.object,
        tag: PropTypes.object,
        offer: PropTypes.object,
        url: PropTypes.string.isRequired
      })
    ).isRequired,
    title: PropTypes.string.isRequired
  }),
  withBackground: PropTypes.bool,
  noContentPadding: PropTypes.bool,
  noTopSpacing: PropTypes.bool,
  onProductClick: PropTypes.func
}

export default Carousel
