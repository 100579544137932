import { css } from '@emotion/react'
import styled from '@emotion/styled'

const wrapperStyles = ({ theme }) => css`
  label: address;

  align-items: center;
  display: flex;
  flex-direction: column-reverse;
  margin: ${theme.spacings.kilo} auto;

  ${theme.media.mega} {
    flex-direction: row;
    justify-content: space-between;
  }
`

const descriptionStyles = ({ theme }) => css`
  label: address__description;

  font-size: ${theme.typography.text.kilo.fontSize};
  line-height: ${theme.typography.text.kilo.lineHeight};
  margin-bottom: ${theme.spacings.mega};
  margin-top: ${theme.spacings.byte};
`

const observationStyles = ({ theme }) => css`
  label: address__observation;

  margin-bottom: ${theme.spacings.byte};
`

const actionsStyles = ({ theme }) => css`
  label: address__actions;

  margin-bottom: ${theme.spacings.byte};

  ${theme.media.mega} {
    margin-bottom: 0;
  }
`

export const Wrapper = styled('div')(wrapperStyles)
export const Description = styled('div')(descriptionStyles)
export const Observation = styled('div')(observationStyles)
export const Actions = styled('div')(actionsStyles)
