import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { Theme } from 'scripts/react-components/themes/Theme'

const containerStyles = ({ theme }: Theme) =>
  css`
    label: variants__container;

    margin-top: ${theme.spacings.mega};
    padding: 0 calc(${theme.spacings.kilo} - 2px);

    ${theme.media.mega} {
      padding: 0;
    }
  `

export const Container = styled('div')(containerStyles)
