'use client'

import { NotFound as Icon } from 'frontendCheckout/assets/images'
import { NotFoundComponent } from './Interface'


export const NotFound = ({
  titleMessage,
  detailMessage
}: NotFoundComponent) => {
  return (
    <div className="flex w-100 h-auto flex-col items-center justify-center gap-8 flex-shrink-0 mt-[10%]">
      <div className="flex w-100 flex-col items-center gap-4">
        <Icon />
        <p className="font-semibold text-xl text-[#666] ">{titleMessage}</p>
        <p className="text-[#A6A6A6] text-xs font-normal">{detailMessage}</p>
      </div>
    </div>
  )
}
