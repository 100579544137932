import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { Theme } from 'scripts/react-components/themes/Theme'

const wrapperStyles = ({ theme }: Theme) => css`
  label: personal-data-card-mobile__wrapper;

  display: grid;
  grid-template-columns: 1fr 6fr 2fr;
  align-items: center;
  justify-content: space-evenly;

  width: auto;
  height: 75px;

  & p {
    margin-left: 10px;
  }

  & a {
    margin-left: ${theme.spacings.kilo};
    text-align: right;
  }

  & span[class*='WrapperIcon'] > svg {
    width: 12px;
    height: 12px;
    color: ${theme.colors.p700};
  }
`

export const Wrapper = styled('div')(wrapperStyles)

const iconWrapperStyles = ({ theme }: Theme) => css`
  label: personal-data-card-mobile__icon;
  & svg {
    width: 40px;
    height: 40px;
    color: ${theme.colors.black} !important;
  }
`

export const IconWrapper = styled('div')(iconWrapperStyles)
