import { createContext, useContext, useState, ReactNode } from 'react'

type CartDrawerState = {
  isCartDrawerOpen: boolean
  toggleCartDrawer: () => void
}

export const CartDrawerContext = createContext<CartDrawerState | Record<string, never>>(
  {}
)

interface CartDrawerProviderProps {
  children: ReactNode
}

export const CartDrawerProvider = ({ children }: CartDrawerProviderProps) => {
  const [isCartDrawerOpen, setIsCartDrawerOpen] = useState(false)

  const toggleCartDrawer = () => {
    setIsCartDrawerOpen(!isCartDrawerOpen)
  }

  const value = {
    isCartDrawerOpen,
    toggleCartDrawer
  }

  return (
    <CartDrawerContext.Provider value={value}>
      {children}
    </CartDrawerContext.Provider>
  )
}

export const useCartDrawerContext = () => useContext(CartDrawerContext)
