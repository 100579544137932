const Icon = () => (
  <svg width="236" height="214" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <path
        d="M228.22 160.501a125.43 125.43 0 0 1-6.07 13.535l-4.304-2.515a120.413 120.413 0 0 0 5.811-12.955l4.563 1.935ZM236 118.457c0 5.656-.406 11.342-1.204 16.904l-4.967-.722c1.124-7.81 1.423-15.95.908-23.716C226.66 48.573 172.95 1.193 111.014 5.299c-18.625 1.23-36.806 7.188-52.574 17.23l-2.682-4.27C72.231 7.771 91.225 1.544 110.686.26c64.695-4.29 120.796 45.206 125.058 110.33.17 2.584.256 5.23.256 7.868M32.418 44.491C14.825 64.776 5.136 90.501 5.136 116.934c0 2.519.085 5.037.252 7.486.752 11.157 3.15 22.049 7.133 32.38l-4.807 1.766c-4.16-10.791-6.666-22.17-7.452-33.818A116.818 116.818 0 0 1 0 116.934C0 89.319 10.12 62.44 28.499 41.253l3.919 3.238Z"
        fill="#DDD"
      />
      <path
        d="M213.744 110.278c3.503 53.636-36.814 99.979-90.05 103.508-53.235 3.53-99.232-37.09-102.735-90.727-3.503-53.633 36.812-99.979 90.05-103.508 53.235-3.53 99.232 37.089 102.735 90.727"
        fill="#E5E5E5"
      />
      <path
        d="M218.495 116.798c0 37.26-20.96 71.623-54.73 88.822l-2.325-4.488c33.944-17.293 54.26-52.852 51.755-90.598-.888-13.392-4.51-26.151-10.766-37.924l4.503-2.351c6.59 12.403 10.407 25.84 11.344 39.944.145 2.205.219 4.408.219 6.595M119.297 16.766l-.058 5.046c-2.438-.03-4.908.04-7.349.202-42.934 2.852-78.39 34.617-86.224 77.251l-4.919-.918C29 53.439 66.343 19.98 111.563 16.977c2.565-.168 5.168-.242 7.734-.211M92.714 207.237l-1.333 4.83c-40.52-11.23-69.6-47.023-72.361-89.068a101.5 101.5 0 0 1-.218-6.318l5.002-.012a95.31 95.31 0 0 0 .207 6.001c2.62 39.924 30.23 73.906 68.703 84.567"
        fill="#DDD"
      />
      <path
        d="M194.34 112.244c1.323 20.606-5.347 40.522-18.82 56.06-13.454 15.518-32.107 24.815-52.484 26.19-42.1 2.8-78.603-29.545-81.372-72.111-1.343-20.624 5.347-40.522 18.8-56.038 13.474-15.543 32.104-24.855 52.504-26.212 42.101-2.8 78.603 29.547 81.373 72.11"
        fill="#FFF"
      />
      <path
        d="M118.89 162c-.74 0-1.49-.159-2.147-.536-15.036-8.624-25.755-17.91-33.732-29.223-6.425-9.112-9.362-18.227-8.978-27.866.286-7.167 3.11-13.903 7.952-18.967 4.87-5.092 11.382-8.068 18.336-8.38 6.94-.315 13.207 2.088 18.646 7.136l.189-.153c1.478-1.193 3.007-2.427 4.727-3.425 7.379-4.282 15.972-4.751 23.573-1.286 7.92 3.609 13.812 11.116 15.764 20.081 1.826 8.388.454 16.91-4.196 26.054-4.734 9.31-11.972 17.635-22.13 25.448-3.775 2.904-7.798 5.507-11.69 8.024-1.192.773-2.384 1.544-3.57 2.324-.697.459-1.709.769-2.743.769"
        fill="#DDD"
      />
    </g>
  </svg>
)

export default Icon
