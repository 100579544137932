import { Component } from 'react'
import classNames from 'classnames'

import emitter from 'scripts/utils/emitter'
import {
  addToCartDataLayer,
  formatPriceToDataLayer
} from 'scripts/utils/data-layer'
import { sendEventToAlgolia } from 'scripts/react-components/shared/analytics/algolia.tracker'

import * as tracker from 'shared/tracker'

import Stock from '../Stock'
import { Header } from './components/Header'
import { StoreInfo } from './components/StoreInfo'
import { EcommerceInfo } from './components/EcommerceInfo'
import * as S from './styled'
import * as StockService from '../../services/stock'

class StockInfo extends Component {
  constructor (props) {
    super(props)
    this.state = {
      drawerOpen: false,
      storeList: [],
      storeInfo: ''
    }

    this.showStock()
  }

  showStock () {
    emitter.on('showStock', () => this.fetchStock())
    emitter.on('product:data-layer', this.pushDataLayer)
  }

  fetchStock = () => {
    const { lm } = this.props

    sendEventToAlgolia()

    StockService.getProductStock(lm)
      .then(this.onFetchSuccess)
      .catch(error => {
        emitter.emit('stickyFeedback:error', {
          title: 'Ops! Ocorreu o seguinte erro:',
          content:
            'Não foi possível verificar a informação de estoque. Tente novamente mais tarde.'
        })

        throw new Error(error)
      })
  }

  pushDataLayer = ({ storeName = null, shippingType } = {}) => {
    const {
      productName,
      lm,
      unit,
      packaging,
      packagingUnit,
      price: productPrice,
      fromPrice: productFromPrice,
      discountType,
      brand,
      category,
      region
    } = this.props

    const shopNameElement = document.querySelector('[data-seller-selected]')
    const shopName = shopNameElement && shopNameElement.textContent

    const packageMultiplier =
      ['m2', 'm²'].includes(unit) && ['CX', 'caixa'].includes(packagingUnit)
        ? packaging
        : null

    const price = JSON.parse(productPrice)
    const fromPrice = JSON.parse(productFromPrice)

    const productParams = {
      productName,
      productId: lm,
      price: formatPriceToDataLayer(price, packageMultiplier),
      productBrand: brand,
      category,
      dimension8: shippingType,
      dimension9: storeName || '',
      dimension15: shopName
    }
    addToCartDataLayer(productParams, { regionId: region })

    const unitPrice = `${price?.integers}.${price?.decimals}`
    const unitOriginalPrice = `${fromPrice?.integers}.${fromPrice?.decimals}`
    const formattedUnitPrice = parseFloat(unitPrice)
    const formattedUnitOriginalPrice = parseFloat(unitOriginalPrice)
    const discount = parseFloat(
      (formattedUnitOriginalPrice - formattedUnitPrice).toFixed(2)
    )

    const hasOffer = Boolean(formattedUnitOriginalPrice)
    const offerType =
      discountType === 'payment-method' ? 'by-payment-method' : 'offer'

    tracker.pushAddToCart({
      pageType: 'product',
      product: {
        id: parseInt(lm),
        name: productName,
        price: formattedUnitPrice || undefined,
        discount: hasOffer ? discount : undefined,
        discountType: hasOffer ? offerType : undefined,
        brand: brand,
        categoryTree: category,
        seller: shopName,
        quantity: 1
      },
      deliveryType: shippingType === 'delivery' ? 'delivery' : 'pickup',
      zipCode: ''
    })
  }

  onFetchSuccess = dataStore => {
    this.setState(
      {
        storeInfo: dataStore,
        storeList: dataStore.stores,
        ecommerceInfo: dataStore.ecommerce
      },
      this.toggleDrawer()
    )
  }

  toggleDrawer = () => {
    this.setState(prevState => {
      return { drawerOpen: !prevState.drawerOpen }
    })
  }

  infoContainerClasses = () => {
    return classNames('stock-info-container drawer-padding', {
      'with-map': this.state.selectedStore
    })
  }

  renderStores = () => {
    const { storeList } = this.state
    const { lm, region, isLocateOnStoreEnabled } = this.props

    return storeList.map((store, index) => (
      <S.Wrapper key={store.id}>
        <StoreInfo
          onMapSelect={this.selectStore}
          hideStock={this.hideStock}
          lm={lm}
          store={store}
          region={region}
          isLocateOnStoreEnabled={isLocateOnStoreEnabled}
        />
      </S.Wrapper>
    ))
  }

  hideStock = event => {
    this.toggleDrawer()
    this.closeMap()
  }

  closeMap = () => {
    this.setState({ selectedStore: null })
  }

  selectStore = selectedStore => {
    this.setState({ selectedStore })
  }

  renderEcommerce = () => {
    const { lm, region } = this.props
    const { ecommerceInfo } = this.state

    if (!this.state.ecommerceInfo) {
      return null
    }

    return (
      <S.Wrapper>
        <EcommerceInfo
          lm={lm}
          hasStock={ecommerceInfo.isAvailable}
          stockMessage={ecommerceInfo.message}
          region={region}
          hideStock={this.hideStock}
        />
      </S.Wrapper>
    )
  }

  render () {
    const {
      productName,
      lm,
      lastUpdate,
      region,
      buttonDisabled,
      isLocateOnStoreEnabled
    } = this.props
    const { drawerOpen, selectedStore } = this.state

    return (
      <Stock
        closeMap={this.closeMap}
        drawerOpen={drawerOpen}
        fetchStock={this.fetchStock}
        hide={this.hideStock}
        selectedStore={selectedStore}
        contentClass={this.infoContainerClasses()}
        drawerClass="stock-info-drawer"
        trackingInfo={{ lm, region }}
        disabled={buttonDisabled}
        isLocateOnStoreEnabled={isLocateOnStoreEnabled}
      >
        <Header
          productName={productName}
          lm={lm}
          hideStock={this.hideStock}
          lastUpdate={lastUpdate}
        />
        {this.renderEcommerce()}
        {this.renderStores()}
      </Stock>
    )
  }
}

export default StockInfo
