import { Spinner as SpinnerBase } from '@leroy-merlin-br/backyard-react'

import * as S from './styled'

const Spinner = ({ isVisible }) =>
  isVisible && (
    <S.SpinnerWrapper isVisible={isVisible}>
      <SpinnerBase size="zetta" appearance="primary" />
    </S.SpinnerWrapper>
  )
export default Spinner
