import { forwardRef, useState } from 'react'
import PropTypes from 'prop-types'
import { TextField } from '@leroy-merlin-br/backyard-react'

import * as S from './styled'

const showErrors = (errors, name) => {
  const errorMessage = {
    min: 'Limite mínimo de 1 semana.',
    max: 'Limite máximo de 12 semanas.'
  }

  if (errors[name]) {
    return errorMessage[errors[name].type]
  }
}

const RangeSlider = forwardRef(
  (
    {
      min = 0,
      max = 12,
      step = 2,
      name = 'range_slider',
      errors = {},
      defaultValue = ''
    },
    ref
  ) => {
    const [range, setRange] = useState(defaultValue)
    const marks = Array.from(Array(max + 1).keys())

    const onRangeChange = ({ target: { value } }) => {
      const isInvalidRange = value <= min || value === ''
      const isGreaterThanMax = value > max

      if (isInvalidRange) {
        return setRange(0)
      }

      if (isGreaterThanMax) {
        return setRange(max)
      }

      return setRange(Math.round(parseFloat(value)))
    }

    return (
      <S.Wrapper>
        <S.Marks>
          {marks.map(mark => mark % 2 === 0 && <li key={mark}>{mark}</li>)}
        </S.Marks>
        <S.Range
          type="range"
          name={name}
          min={min}
          max={max}
          step={step}
          value={range}
          onChange={onRangeChange}
        />
        <TextField
          type="number"
          name={name}
          label="Semanas"
          ref={ref}
          value={range}
          onChange={onRangeChange}
          min={min}
          max={max}
          state={!!errors[name] && 'invalid'}
          hint={!!errors[name] && showErrors(errors, name)}
        />
      </S.Wrapper>
    )
  }
)

RangeSlider.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  step: PropTypes.number,
  name: PropTypes.string.isRequired,
  errors: PropTypes.object,
  defaultValue: PropTypes.string.isRequired
}

export default RangeSlider
