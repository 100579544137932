import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Stack, Card } from '@leroy-merlin-br/backyard-react'

import { Transition, VouchersSkeleton } from 'lmcv/components'
import { CouponContainer } from 'lmcv/containers/Coupon'

import * as S from './styled'
import LoadMore from './components/LoadMore'
import FilterButtons from './components/FilterButtons'

const CouponsCard = ({
  vouchers,
  paginated,
  activated,
  getVouchers,
  getActivated,
  getPaginated,
  title
}) => {
  const [allAvailable, setAllAvailable] = useState([])
  const [allActivated, setAllActivated] = useState([])

  const [isUpdated, setIsUpdated] = useState(false)
  const [filterType, setFilterType] = useState('available')
  const [pagination, setPagination] = useState(2)
  const [hasMoreContent, setHasMoreContent] = useState(true)

  const dispatch = useDispatch()

  const hasMoreItemsToLoad =
    paginated.payload.length > 0 && !paginated.isRequest

  const filterTypes = {
    available: filterType === 'available',
    activated: filterType === 'activated'
  }

  const changeFilterType = selectedFilter => {
    if (selectedFilter === 'Disponíveis') {
      return setFilterType('available')
    }

    setFilterType('activated')
  }

  const filterByAvailable = e => {
    dispatch(getVouchers())
    changeFilterType(e.target.textContent)
    setHasMoreContent(true)
  }

  const filterByActivated = e => {
    dispatch(getActivated())
    changeFilterType(e.target.textContent)
    setHasMoreContent(true)
  }

  const getMoreVouchers = () => {
    if (filterTypes.available) {
      dispatch(getPaginated(pagination, 'available'))
      setPagination(pagination + 1)
      setIsUpdated(true)
      return
    }

    if (filterTypes.activated) {
      dispatch(getPaginated(pagination, 'active'))
      setPagination(pagination + 1)
      setIsUpdated(true)
    }
  }

  useEffect(() => {
    if (filterTypes.available) {
      setPagination(2)
      setAllAvailable(vouchers.payload.filter(item => !item.isActive))
    }
  }, [filterTypes.available, vouchers.payload])

  useEffect(() => {
    if (filterTypes.activated) {
      setPagination(2)
      setAllActivated(activated.payload.filter(item => item.isActive))
    }
  }, [filterTypes.activated, activated.payload])

  useEffect(() => {
    const setInitialVouchers = () => {
      const availableVouchers = vouchers.payload.filter(
        item => !item.isActive && item
      )
      setAllAvailable([...availableVouchers])
    }
    setInitialVouchers()
  }, [vouchers])

  useEffect(() => {
    const setMoreAvailableVouchers = () => {
      const filterIsAvailable = filterTypes.available && isUpdated

      if (hasMoreItemsToLoad && filterIsAvailable) {
        setAllAvailable([
          ...allAvailable,
          ...paginated.payload.filter(item => !item.isActive)
        ])
        setIsUpdated(false)
      }
    }
    setMoreAvailableVouchers()
  }, [
    isUpdated,
    allAvailable,
    filterTypes.available,
    paginated.payload,
    hasMoreItemsToLoad
  ])

  useEffect(() => {
    const setMoreActiveVouchers = () => {
      const filterIsActive = filterTypes.activated && isUpdated

      if (hasMoreItemsToLoad && filterIsActive) {
        setAllActivated([
          ...allActivated,
          ...paginated.payload.filter(item => item.isActive)
        ])
        setIsUpdated(false)
      }
    }
    setMoreActiveVouchers()
  }, [
    isUpdated,
    allActivated,
    filterTypes.activated,
    paginated.payload,
    hasMoreItemsToLoad
  ])

  useEffect(() => {
    const hideShowMoreButton = () => {
      const validateVouchersRequest = !paginated.isRequest && isUpdated
      const validateVouchersQuantity = !paginated.payload.length

      if (validateVouchersQuantity && validateVouchersRequest) {
        setHasMoreContent(false)
        setIsUpdated(false)
      }
    }
    hideShowMoreButton()
  }, [isUpdated, paginated.payload, paginated.isRequest])

  const isCouponLoading = vouchers.isRequest || activated.isRequest

  return (
    <S.CardCouponWrapper>
      <Card title={title}>
        <Transition.Fade>
          <Stack>
            <FilterButtons
              currentType={filterType}
              filterTypes={filterTypes}
              filterByActivated={filterByActivated}
              filterByAvailable={filterByAvailable}
            />
            {isCouponLoading ? (
              <VouchersSkeleton size={10} />
            ) : (
              <S.ListContainer>
                {filterTypes.available &&
                  allAvailable.map(voucher => (
                    <CouponContainer key={voucher.id} {...voucher} />
                  ))}
                {filterTypes.activated &&
                  allActivated.map(voucher => (
                    <CouponContainer key={voucher.id} {...voucher} />
                  ))}
              </S.ListContainer>
            )}

            <LoadMore
              onClick={getMoreVouchers}
              hasMoreContent={hasMoreContent}
              isLoading={paginated.isRequest}
            />
          </Stack>
        </Transition.Fade>
      </Card>
    </S.CardCouponWrapper>
  )
}

export default CouponsCard
