import { Text } from '@leroy-merlin-br/backyard-react'
import { FC, useEffect, useState } from 'react'

import * as S from './styled'

type ResendCodeLinkProps = {
  onResendLink: () => void
}

const ResendCodeLink: FC<ResendCodeLinkProps> = ({ onResendLink }) => {
  const [counter, setCounter] = useState(30)

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCounter((prev) => {
        if (prev === 0) {
          clearInterval(intervalId)
          return 0
        } else {
          return prev - 1
        }
      })
    }, 1000)

    return () => clearInterval(intervalId)
  }, [counter])

  const resendCodeLink = () => {
    onResendLink()

    setCounter(30)
  }

  return (
    <S.ResendCodeText>
      {counter ? (
        <Text color="n600" noMargin>
          Reenviar código em <span>{counter}</span> segundos
        </Text>
      ) : (
        <Text noMargin color="p600">
          <a onClick={resendCodeLink}>Reenviar código</a>
        </Text>
      )}
    </S.ResendCodeText>
  )
}

export default ResendCodeLink
