import { memo, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import {
  Dialog,
  Text,
  ButtonGroup,
  Button
} from '@leroy-merlin-br/backyard-react'

import { changeUserRegion } from 'catalog/utils/region'

const RegionSelect = ({ postalCode, region, onClose }) => {
  const [isLoading, setIsLoading] = useState(false)

  const onConfirm = useCallback(() => {
    setIsLoading(true)
    changeUserRegion(postalCode, region)
  }, [setIsLoading, postalCode, region])

  const onDismiss = useCallback(() => {
    setIsLoading(false)
    onClose()
  }, [setIsLoading, onClose])

  return (
    <Dialog
      onClose={onDismiss}
      title="Deseja atualizar sua região?"
      footer={
        <ButtonGroup align="flex-end">
          <Button size="kilo" variant="ghost" onClick={onDismiss}>
            Não atualizar
          </Button>
          <Button
            size="kilo"
            isLoading={isLoading}
            onClick={onConfirm}
            data-testid="change-region-button"
          >
            Atualizar minha região
          </Button>
        </ButtonGroup>
      }
    >
      <Text size="kilo">
        O CEP que você inseriu para a entrega dos produtos é de uma região
        diferente, então,{' '}
        <Text as="strong" size="kilo" isBold noMargin>
          precisaremos atualizar nosso site{' '}
        </Text>
        para se adequar ao novo local. É possível que a{' '}
        <Text as="strong" size="kilo" isBold noMargin>
          disponibilidade dos produtos, frete e prazos de entrega sejam afetados
        </Text>
        .
      </Text>
    </Dialog>
  )
}

RegionSelect.propTypes = {
  postalCode: PropTypes.string.isRequired,
  region: PropTypes.shape({
    city: PropTypes.string,
    id: PropTypes.string,
    region: PropTypes.string,
    state: PropTypes.string,
    stateCode: PropTypes.string
  }),
  onClose: PropTypes.func.isRequired
}

export default memo(RegionSelect)
