import { Fragment, useContext, useCallback } from 'react'

import { CustomHelmet } from 'shared/components/CustomHelmet'

import slugify from 'utils/slugify'

import productSchema from '../../utils/productSchema'
import { buildPagination } from '../../utils/buildUrl'
import { EntryKeyContext } from '../../context/EntryKeyContext'

const HeadTags = () => {
  const {
    isSearch,
    query,
    type,
    metaTags,
    metadata,
    isLoadingProducts,
    products,
    pageType,
    baseUrl,
    isMappedSearch
  } = useContext(EntryKeyContext)

  const getGaTrackData = gaTrack => {
    const content = !isMappedSearch ? gaTrack : `lista/${gaTrack}`

    const gaTrackData = {
      content: gaTrack ? content : null
    }

    if (pageType) {
      gaTrackData['data-page-type'] = pageType
    }

    return gaTrackData
  }

  const renderProductsJSONLD = useCallback(() => {
    const schemas = products.map(product => productSchema(product))

    return `${JSON.stringify(schemas)}`
  }, [products])

  const componentMetaTags = useCallback(
    value => {
      if (isSearch) {
        return {
          title: `Resultados da Busca por: ${value}`,
          description: `Veja o que a Leroy Merlin encontrou para os termos: ${value}`
        }
      }

      if (type === 'imageSearch') {
        return {
          title: 'Resultados da Busca por Imagem',
          description:
            'Confira alguns produtos que podem ser o que você procura'
        }
      }

      return metaTags
    },
    [isSearch, type, metaTags]
  )

  const getCanonical = canonical => {
    if (!isMappedSearch) return canonical

    const termSlug = slugify(query.term.value)
    const pageQuery = metadata.page > 1 ? `?page=${metadata.page}` : ''

    return `${window.location.origin}/${termSlug}${pageQuery}`
  }

  const { title, description, image } = componentMetaTags(query.term?.value)

  const gaTrack = getGaTrackData(metaTags?.gaTrack)
  const canonical = getCanonical(metaTags?.canonical)

  const shouldRenderGaTrack = gaTrack.content || gaTrack.pageType

  const { prevPage, nextPage } = buildPagination({
    query,
    currentPage: metadata?.page,
    pageCount: metadata?.pageCount,
    baseUrl,
    isMappedSearch
  })

  return (
    <Fragment>
      <CustomHelmet>
        {title && <title>{title}</title>}

        {description && <meta name="description" content={description} />}
        {title && <meta property="og:title" content={title} />}
        {description && (
          <meta property="og:description" content={description} />
        )}
        {image && <meta property="og:image" content={image} />}
        {shouldRenderGaTrack && <meta name="ga-track" {...gaTrack} />}

        {canonical && <link rel="canonical" href={canonical} />}

        {nextPage && <link rel="next" href={nextPage} />}
        {prevPage && <link rel="prev" href={prevPage} />}

        {!isLoadingProducts && (
          <script type="application/ld+json">{renderProductsJSONLD()}</script>
        )}
      </CustomHelmet>
    </Fragment>
  )
}

export default HeadTags
