import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { styleHelpers } from '@leroy-merlin-br/backyard-react'

const wrapperStyles = ({ theme }) => css`
  label: breadcrum-bar__wrapper;

  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: ${theme.spacings.mega};
  align-items: center;

  padding: ${theme.spacings.kilo} 0px;
`

export const Wrapper = styled('div')(wrapperStyles)

const filterButtonWrapperStyles = ({ theme }) => css`
  label: filter-button-wrapper;

  background-color: ${theme.colors.white};
  ${styleHelpers.shadowDouble(theme)};

  border-radius: ${theme.borderRadius.giga};

  button {
    font-weight: unset
  }
`

export const FilterButtonWrapper = styled('div')(filterButtonWrapperStyles)
