import request from 'catalog/utils/axios'

export const getRegionFromPostalCode = async postalCode => {
  const response = await request
    .get(`/api/boitata/v1/zipcode/${postalCode}`)
    .then(({ data }) => data)
    .catch(err => {
      throw err.response
    })
  return response
}
