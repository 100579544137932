import { PropsWithChildren, ReactNode } from 'react'

import * as S from './styled'

const Body = ({ children }: PropsWithChildren<ReactNode>) => (
  <S.Body data-testid="chat-body" className="chat-body">
    {children}
  </S.Body>
)

export default Body
