type PriceProps = {
  title?: string
  numberInstallments: number
  totalPrice: string
}

export const Price = ({
  title = 'Total com frete',
  numberInstallments,
  totalPrice
}: PriceProps) => {
  return (
    <div className="flex flex-row justify-between items-center">
      <div className="flex flex-col">
        <div className="text-sm">{title}</div>
        <div className="text-sm text-neutral-300">
          em até {numberInstallments}x sem juros
        </div>
      </div>

      <div className="font-semibold text-lg">{totalPrice}</div>
    </div>
  )
}
