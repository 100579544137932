import { Constants } from '.'

export const getProfessional = () => ({
  type: Constants.PROFESSIONAL_GET
})

export const getSuccess = payload => ({
  type: Constants.PROFESSIONAL_GET_SUCCESS,
  payload
})

export const getFailure = error => ({
  type: Constants.PROFESSIONAL_GET_FAILURE,
  error
})
