import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Button as StyledButton } from '@leroy-merlin-br/backyard-react'

import { Theme } from 'scripts/react-components/themes/Theme'

const carouselStyles = ({ theme }: Theme) => css`
  label: carousel;

  display: flex;
  align-items: center;
  flex-direction: column-reverse;

  overflow: hidden;

  ${theme.media.mega} {
    max-height: 544px;
    height: 544px;
  }

  .slick-slider {
    height: 100%;
    display: flex;
    justify-content: center;
  }

  .slick-slide > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .slick-list {
    width: 100%;
  }

  .current-carousel-item .slick-list {
    width: 100%;

    .slick-track {
      height: 100%;
    }

    .slick-slide > div {
      display: flex;
      align-items: center;
      height: 100%;
    }
  }

  .slick-list {
    ${theme.media.mega} {
      width: 70px;
    }
  }

  .slick-track > .slick-slide > div > div > img {
    border: 3px solid ${theme.colors.n300};
  }

  ${theme.media.mega} {
    flex-direction: row;
  }
`

const currentCarouselWrapperStyles = ({ theme }: Theme) => css`
  label: carousel__current-carousel;

  width: 100%;

  ${theme.media.mega} {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-left: 80px;
  }
`

const buttonsWrapperStyles = ({ theme }: Theme) => css`
  label: carousel__buttons-wrapper;

  display: flex;
  justify-content: flex-start;
  flex-direction: row-reverse;

  margin: ${theme.spacings.byte} 0;

  ${theme.media.mega} {
    flex-direction: row;

    height: 36px;
    margin: ${theme.spacings.byte} 0;
  }
`

const buttonStyles = ({ theme }: Theme) => css`
  label: carousel__button;

  margin-right: ${theme.spacings.mega};
  border: 1px solid ${theme.colors.p600};

  &:first-of-type {
    margin-right: 0;

    ${theme.media.mega} {
      margin-right: ${theme.spacings.mega};
    }
  }

  & > svg {
    margin-right: ${theme.spacings.mega};
  }
`

export const Carousel = styled('div')(carouselStyles)
export const CurrentCarouselWrapper = styled('div')(
  currentCarouselWrapperStyles
)
export const ButtonsWrapper = styled('div')(buttonsWrapperStyles)
export const Button = styled(StyledButton)(buttonStyles)
