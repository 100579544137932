import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { styleHelpers } from '@leroy-merlin-br/backyard-react'

const popperWrapper = ({ theme }) => css`
  label: popper-wrapper;

  z-index: 999;
  max-width: 335px;
  position: absolute;
  top: calc(100% + 10px);
  left: ${theme.spacings.mega};
  right: ${theme.spacings.mega};
  margin-left: auto;
  margin-right: auto;
  background-color: ${theme.colors.b800};
  border-radius: ${theme.borderRadius.giga};
  padding: ${theme.spacings.mega};
  ${styleHelpers.shadowDouble(theme)};

  ${theme.media.giga} {
    left: 0;
    right: initial;
    background-color: ${theme.colors.white};

    :after {
      content: '';
      position: absolute;
      top: 0;
      left: 24px;
      width: 10px;
      height: 10px;
      border-radius: 2px;
      transform: rotate(-45deg);
      transform-origin: top left;
      background-color: ${theme.colors.white};
    }
  }
`

export const PopperWrapper = styled('div')(popperWrapper)
