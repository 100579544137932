import { Stack } from '@leroy-merlin-br/backyard-react'

import Header from './components/Header'
import { Content } from './components/Content'
import FormProvider from './contexts/FormContext'

export default function Form() {
  return (
    <FormProvider>
      <Stack space="kilo">
        <Header />
        <Content />
      </Stack>
    </FormProvider>
  )
}
