import * as S from './styled'
import { SuccessVisualType } from './type'

const Success = ({ content, summary }: SuccessVisualType) => (
  <S.Wrapper>
    <S.GreenLine />
    <S.Content>
      { content }
    </S.Content>
    <S.Summary>
      { summary }
    </S.Summary>
  </S.Wrapper>
)

export default Success
