export const copyQRCode = async (emvQrCode = '') => {
  try {
    await navigator.clipboard.writeText(emvQrCode)
  } catch (err) {
    console.error('Failed to copy code: ', err)
  }
}

export const formatZipCode = cep => {
  if (!cep) return ''

  return cep.toString().replace(/(\d{5})(\d)/, '$1-$2')
}
