import { css } from '@emotion/react'
import styled from '@emotion/styled'

const baseStyles = ({ theme }) => css`
  label: filters-mobile__header;

  display: flex;
  justify-content: space-between;
  align-items: center;

  background-color: ${theme.colors.p600};

  padding: ${theme.spacings.giga}
    calc(${theme.spacings.mega} + ${theme.spacings.bit}) ${theme.spacings.mega};

  & svg {
    color: ${theme.colors.white};
  }
`

const titleStyles = ({ theme }) => css`
  label: header__title;

  font-size: ${theme.typography.headings.mega.fontSize};
  line-height: ${theme.typography.headings.mega.lineHeight};
  font-weight: ${theme.font.weight.bold};
  color: ${theme.colors.white};
`

export const Header = styled('div')(baseStyles)
export const Title = styled('span')(titleStyles)
