import { Fragment } from 'react'
import { Text } from '@leroy-merlin-br/backyard-react'

import formatPriceToBRL from 'utils/formatPriceToBRL'

import * as S from './styled'

type Props = {
    discountValue: number,
    discountName: string,
    discountDescription?: string,
    isLast?: boolean,
}

const DiscountInfo = ({ discountValue, discountName, discountDescription, isLast }: Props) => {
  return (
    <Fragment>
      <S.TypeDiscount isLast={isLast} data-testid="discount-info" data-cy="discount-info-element">
        <div>
        <Text size="giga" as="span" noMargin isBold> {discountName}</Text>
        <br/>
        {discountDescription && <Text size="kilo" as="span" noMargin isBold> {discountDescription} </Text>}
        </div>
        <S.TextValue data-cy="discount-value">
          <Text size="mega" as="span" noMargin isBold color="g400">
            {!isLast && '-'}{formatPriceToBRL(discountValue)}
          </Text>
        </S.TextValue>
      </S.TypeDiscount>
    </Fragment>
  )
}

export default DiscountInfo
