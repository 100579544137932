const MODALITIES_PRIORITY = {
  express: 0,
  marketplace_express: 1,
  standard: 2,
  scheduled: 3,
  pickupInStore: 4
}

export const getSortedModalities = shipments => {
  const skuModalities = Object.keys(shipments.modalities)

  skuModalities.sort((current, next) => {
    const currentModality = shipments.modalities[current]
    const nextModality = shipments.modalities[next]

    const isSameDayDelivery =
      current === 'express' && currentModality.expressDeliveryDay

    if (isSameDayDelivery) {
      return -1
    }

    if (currentModality.cost > nextModality.cost) {
      return 1
    }

    if (currentModality.cost < nextModality.cost) {
      return -1
    }

    if (currentModality.deadline > nextModality.deadline) {
      return 1
    }

    if (currentModality.deadline < nextModality.deadline) {
      return -1
    }

    if (MODALITIES_PRIORITY[current] > MODALITIES_PRIORITY[next]) {
      return 1
    }

    if (MODALITIES_PRIORITY[current] < MODALITIES_PRIORITY[next]) {
      return -1
    }
  })

  return skuModalities
}
