import { css } from '@emotion/react'
import styled from '@emotion/styled'

const baseStyles = ({ theme }) => css`
  label: filters-mobile__footer;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;

  background-color: ${theme.colors.white};

  padding: ${theme.spacings.giga} calc(${theme.spacings.mega} + ${theme.spacings.bit});
  margin-top: auto;

  box-shadow: 0 0 0 1px ${theme.colors.shadow},
          0 -2px 2px 0 ${theme.colors.shadow}, 0 -4px 4px 0 ${theme.colors.shadow};

  z-index: ${theme.zIndex.backdrop};
`

const totalProductsStyles = ({ theme }) => css`
  label: filters-mobile__footer;

  & span:first-of-type {
    label: filters-mobile__text;

    color: ${theme.colors.p600};
  }
`

export const Footer = styled('div')(baseStyles)
export const TotalProducts = styled('div')(totalProductsStyles)
