import PropTypes from 'prop-types'
import { Text } from '@leroy-merlin-br/backyard-react'

import { onMenuClick } from 'scripts/utils/data-layer'

import * as S from './styled'

const MenuItem = ({ data, trigger }) => {
  const onItemClick = (event, item) => {
    if (!item.content?.length) {
      event.preventDefault()
      event.stopPropagation()

      onMenuClick({ item, isMobile: true })

      const anchorElement = event.currentTarget.querySelector('a')
      const url = anchorElement.href

      window.location.href = url
    } else {
      trigger()
    }
  }

  return (
    <S.WrapperMenuList onClick={event => onItemClick(event, data)}>
      {data.content?.length > 0 ? (
        data.name
      ) : (
        <Text as="a" color="white" href={data.url} noMargin>
          {data.name}
        </Text>
      )}
    </S.WrapperMenuList>
  )
}

MenuItem.propTypes = {
  data: PropTypes.object.isRequired,
  trigger: PropTypes.func.isRequired
}

export default MenuItem
