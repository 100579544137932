import styled from '@emotion/styled'
import { css } from '@emotion/react'

const bodyStyles = ({ theme }) => css`
  label: document-status__body;

  height: 100%;
  text-align: center;
  padding: ${theme.spacings.zetta} 0;
`

const buttonWrapperStyles = ({ theme }) => css`
  label: document-status__button-wrapper;

  margin-top: ${theme.spacings.tera};

  ${theme.media.kilo} {
    margin: ${theme.spacings.tera} 0;
  }
`

export const Body = styled('div')(bodyStyles)
export const ButtonWrapper = styled('div')(buttonWrapperStyles)
