import $ from 'jquery'

import BaseComponent from 'scripts/components/base-component'

const DEFAULTS = {
  CAPSLOCK_KEYCODE: 20,
  UPPERCASE_KEYCODE_START: 65,
  LOWERCASE_KEYCODE_START: 97,
  UPPERCASE_KEYCODE_END: 90,
  LOWERCASE_KEYCODE_END: 122
}

class CapslockChecker extends BaseComponent {
  static componentName = 'capslock-checker'

  static DOMEvents = {
    keydown: 'toggleCapslockTipOnCapsPress',
    keypress: 'toggleCapslockTipOnTyping'
  }

  constructor ($element, options) {
    super($element)

    this.options = $.extend({}, DEFAULTS, options)
  }

  init () {
    this.capsLockEnabled = false
    this.$tipElement = this.$element.find(this.options.tipElement)
  }

  toggleCapslockTipOnTyping ({ keyCode, shiftKey }) {
    const shouldDisplay = this.isCapslockEnabled({ keyCode, shiftKey })
    this.capsLockEnabled = shouldDisplay
    this.$tipElement.toggleClass('visibility-hidden', !shouldDisplay)
  }

  toggleCapslockTipOnCapsPress ({ keyCode }) {
    if (keyCode === this.options.CAPSLOCK_KEYCODE && this.capsLockEnabled) {
      this.$tipElement.toggleClass('visibility-hidden')
    }
  }

  isCapslockEnabled ({ keyCode, shiftKey }) {
    const isUpperCase = this.isUpperCase({ keyCode })
    const isLowerCase = this.isLowerCase({ keyCode })

    return (isUpperCase && !shiftKey) || (isLowerCase && shiftKey)
  }

  isUpperCase ({ keyCode }) {
    return keyCode >= this.options.UPPERCASE_KEYCODE_START &&
     keyCode <= this.options.UPPERCASE_KEYCODE_END
  }

  isLowerCase ({ keyCode }) {
    return keyCode >= this.options.LOWERCASE_KEYCODE_START &&
     keyCode <= this.options.LOWERCASE_KEYCODE_END
  }
}

export default ($el) => new CapslockChecker($el, $el.data()).init()

export { CapslockChecker as Component }
