import { Fragment, useContext, useState, useEffect } from 'react'
import { datadogRum } from '@datadog/browser-rum'
import Cookies from 'js-cookie'
import { getProductsReviewsRating } from 'hpdp/services/reviews'

import { ProductThumb } from 'shared/components/ProductThumb'
import { NewProductThumb } from 'shared/components/NewProductThumb'
import { Pagination } from 'shared/components/Pagination'
import { AlertMessage } from 'shared/components/AlertMessage'

import { WishlistContext } from 'site/Wishlist/context'

import { tracker } from 'utils/app'

import * as S from './styled'
import { ProductShell } from '../Shell'
import { EntryKeyContext } from '../../context/EntryKeyContext'
import { onProductClickDataLayer } from '../../utils/data-layer'
import { ENTRYKEY_THUMBS_PER_ROW } from '../../utils/constants'

const Products = () => {
  const {
    isLoadingProducts,
    metadata,
    products,
    setPage,
    profile,
    type,
    query: { term },
    metaTags,
    baseUrl,
    isSearch,
    isNewProductThumb,
    filterUsedOptions
  } = useContext(EntryKeyContext)
  const { isOnWishlist } = useContext(WishlistContext)
  const Thumb = isNewProductThumb ? NewProductThumb : ProductThumb

  const [ratingResponse, setRatingResponse] = useState([])
  const [isLoadingReview, setIsLoadingReview] = useState(false)

  const shouldShowNewReviewsRating =
    window.env.feature.isNewReviewsSectionEnabled &&
    Cookies.get('new_reviews_ab_testing') === 'true'

  const onProductClick = (product, index) => {
    onProductClickDataLayer({
      product,
      region: profile?.region,
      gaTrack: metaTags?.gaTrack,
      type,
      term: isSearch && term ? term.value : baseUrl,
      index,
      page: metadata.page,
      inWishlist: isOnWishlist(product.id)
    })

    try {
      tracker.selectItem([
        {
          item_id: product.id,
          item_name: product.name,
          discountPercentage: product.offer?.value,
          item_brand: product.brand,
          categoryTree: product.categoryTree,
          price: product.price.to,
          item_list_name: `${type}: ${product.category}`
        }
      ])
    } catch (e) {
      datadogRum.addError(e)
    }
  }

  useEffect(() => {
    if (!shouldShowNewReviewsRating) {
      return
    }

    const fetchData = async () => {
      setIsLoadingReview(true)
      const ids = products.map(item => {
        return Number(item.id)
      })

      try {
        const response = await getProductsReviewsRating(ids)
        setRatingResponse(response)
      } catch (e) {
        datadogRum.addError(e)
      } finally {
        setIsLoadingReview(false)
      }
    }

    if (products.length >= 1) {
      fetchData()
    }
  }, [products, shouldShowNewReviewsRating])

  const renderProducts = () => (
    <S.Card>
      {filterUsedOptions.some(item => item.filterKey === 'servicos') && (
        <S.SpacingWrapper>
          <AlertMessage
            type="warning"
            title="Atenção para disponibilidade do serviço na sua região!"
            noTopMargin
          >
            Adicione o produto desejado ao carrinho, digite seu CEP e confira se
            o serviço está disponível para seu endereço.
          </AlertMessage>
        </S.SpacingWrapper>
      )}

      <S.ProductList
        itemsPerRow={isNewProductThumb ? ENTRYKEY_THUMBS_PER_ROW : 3}
      >
        {products.map((product, index) => (
          <Thumb
            onClick={() => onProductClick(product)}
            variableWidth
            product={product}
            key={product.id}
            reviewRating={ratingResponse.find(
              item => item.productLm === Number(product.id)
            )}
            isLoadingReview={isLoadingReview}
            position={index + 1}
            itemList={`entry-key-${isSearch && term ? term.value : baseUrl}`}
            itemCategory={
              product.categoryTree
                ? product.categoryTree.replace(/\//g, '|')
                : ''
            }
          />
        ))}
      </S.ProductList>
    </S.Card>
  )

  if (isLoadingProducts) {
    return <ProductShell />
  }

  return (
    <Fragment>
      {renderProducts()}

      {metadata.pageCount > 1 && (
        <S.PaginationWrapper>
          <Pagination {...metadata} setPage={setPage} />
        </S.PaginationWrapper>
      )}
    </Fragment>
  )
}

export default Products
