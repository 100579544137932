import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { styleHelpers } from '@leroy-merlin-br/backyard-react'

import { StyleProps } from '../../types'

const wrapperStyles = ({ theme }: StyleProps) => css`
  label: wrapper;

  padding: ${theme.spacings.giga};

`

const pictureStyles = ({ theme }: StyleProps) => css`
  label: picture;

  position: relative;

  width: 84px;
  height: 84px;
  background: ${theme.colors.white};

  display: flex;
  align-items: center;
  justify-content: center;

  border: 3px solid ${theme.colors.n100};
  border-radius: ${theme.borderRadius.circle};

  & > img {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: ${theme.borderRadius.circle};
  }

  & > svg {
    display: block;
    width: 60%;
    height: 60%;
    color: ${theme.colors.v700};
    fill: ${theme.colors.v700};
  }
`

const pictureIconStyles = ({ theme }: StyleProps) => css`
  label: picture__icon;

  position: absolute;

  left: 0;
  bottom: 0;
  width: 29px;
  height: 29px;

  display: flex;
  align-items: center;
  justify-content: center;

  color: ${theme.colors.n300};
  border-radius: ${theme.borderRadius.circle};
  border: 3px solid ${theme.colors.n100};

  background: ${theme.colors.white};
  transition: background ease-in 300ms;

  ${styleHelpers.shadowTriple(theme)};
`
export const PictureIcon = styled('div')(pictureIconStyles)
export const Picture = styled('div')(pictureStyles)

export const Wrapper = styled('div')(wrapperStyles)
