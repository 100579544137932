import { Hidden, Visible } from '@leroy-merlin-br/backyard-react'

import { PhoneNumberLayout } from '../../components/PhoneNumberLayout'

const title = 'Adicionar novo telefone'

const AddPhone = () => (
  <>
    <Visible when={['untilKilo', 'kilo']}>
      <PhoneNumberLayout title={title} />
    </Visible>
    <Hidden when={['untilKilo', 'kilo']}>
      <PhoneNumberLayout isDesktop title={title} />
    </Hidden>
  </>
)

export default AddPhone
