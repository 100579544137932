export const Colors = {
  'icon-neutral-soft': '#706d68',
  'icon-neutral-default': '#4a4845',
  'icon-neutral-strong': '#1d1c1b',
  'icon-neutral-inverse': '#ffffff',
  'icon-semantic-accent': '#207f00',
  'icon-semantic-loyalty': '#9531fb',
  'icon-semantic-info': '#0d74ab',
  'icon-semantic-success': '#067c6d',
  'icon-semantic-warning': '#f3a620',
  'icon-semantic-danger': '#d32a1b'
} as const

export type Color = keyof typeof Colors
