import { isValid as isValidCpf } from '@fnando/cpf'
import { isValid as isValidCnpj } from '@fnando/cnpj'

/**
 * NOTE:
 * https://github.com/fnando/cpf
 */
export const isCpf = value => isValidCpf(value)
export const isCnpj = value => isValidCnpj(value)

/**
 * NOTE:
 * This regex was extracted from our database
 */
export const isEmail = value =>
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/i.test(
    value
  )

export const isValidName = value => {
  const validateChars = /^(?=(\s*[^\s]\s*){4,}$)(\s*)([A-Za-zÀ-ÖØ-öø-ÿ']+)(\s+[A-Za-zÀ-ÖØ-öø-ÿ']+)+(\s*)$/i.test(
    value
  )
  const noRepeat = !/^(\w+)\s+\1\s*$/i.test(value)
  const notIncludeTest = !/^TESTE.*/i.test(value)

  return validateChars && noRepeat && notIncludeTest
}

/**
 * NOTE:
 * This regex accept the both values type
 * 00/00/000
 * 0000000
 */
export const isBirthdate = value =>
  /^([0-2][0-9]|(3)[0-1])(\/)?(((0)[0-9])|((1)[0-2]))(\/)?\d{4}$/.test(value)

/**
 * The regex below is used on
 * password validation
 */
export const isAlphabetic = value => /[a-zA-Z]/.test(value)

export const isNumberPassword = value => /[0-9]/.test(value)

export const isCellPhoneDigit = value => /^.{2}[9]/.test(value)

export const isPhone = value => /^(\?\d{2}\?\s)?(\d{4,5}\d{4})/.test(value)

export const isCellPhone = value =>
  /^(?:\d{2}[2,3,4,5,7]\d{7}|\d{2}[9])(?:([0-9])(?!.*\1{7}))*$/.test(value) &&
  /^[0-9]{11,}/.test(value)

export const hasSpecial = value =>
  /[-!$%^&*()_+|~=`{}[\]:/;<>?,.@#]/.test(value)

export const hasSequential = value =>
  /012|123|234|345|456|567|678|789/.test(value)

export const isRange = value => /.{8,20}/.test(value)

export const isCep = value => /\d{5}-?\d{3}/.test(value)

export const isLink = value =>
  /^((https?:)?\/\/|[0-9a-zA-Z]+:)/.test(value || '')
