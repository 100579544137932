import { Card } from '@leroy-merlin-br/backyard-react'

import { Shell } from 'shared/components/Shell'

import { Avatar } from 'lmcv/components'

import * as S from './styled'

const ProfileSkeleton = () => (
  <Card>
    <S.Content>
      <Avatar loading />

      <S.TextContainer>
        <Shell width={150} height={20} borderRadius="kilo" /> <br />
        <Shell width={80} height={15} borderRadius="kilo" />
      </S.TextContainer>

      <S.Divider />

      <S.DateContainer>
        <Shell width={150} height={20} borderRadius="kilo" /> <br />
        <Shell width={80} height={15} borderRadius="kilo" />
      </S.DateContainer>
    </S.Content>
  </Card>
)

export default ProfileSkeleton
