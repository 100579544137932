import { tv } from 'tailwind-variants'

const styles = tv({
  base: `
    w-[375px]
    h-[544px]
    p-0
    pb-2
    bg-white
    rounded-xl
    shadow-lg
  `,
  slots: {
    body: `
      px-4
    `,
    item: `
      px-4
      py-6
    `,
    divider: `
      h-px
      bg-gray-200
    `
  }
})

export default styles
