import { css } from '@emotion/react'
import styled from '@emotion/styled'

const assetPath = window && window.env.assetHost

const baseStyles = ({ theme }) => css`
  label: content;

  display: flex;
  align-items: center;
  width: 100%;
  flex: 1 auto;
  padding: ${theme.spacings.peta} 0;
`

const cardStyles = ({ theme }) => css`
  label: card;

  background: white;

  width: 100%;
  min-height: 480px;

  flex: 1 auto;
  padding: 0 ${theme.spacings.giga} ${theme.spacings.peta};

  border-radius: 0;

  ${theme.media.giga} {
    background-image: url(${assetPath}assets/images/signup/door.svg);
    background-repeat: no-repeat;
    background-position: right;
    background-size: contain;

    padding: 0 ${theme.spacings.tera} ${theme.spacings.peta};
  }
`

const wrapperImageStyles = ({ theme }) => css`
  label: wrapper;

  display: flex;
  justify-content: center;
  margin: ${theme.spacings.peta} 0 ${theme.spacings.kilo} 0;

  ${theme.media.giga} {
    justify-content: flex-start;
    margin: ${theme.spacings.peta} 0 ${theme.spacings.tera}
      ${theme.spacings.zetta};
  }
`

export const Content = styled('div')(baseStyles)
export const Card = styled('div')(cardStyles)
export const WrapperImage = styled('div')(wrapperImageStyles)
