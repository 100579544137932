import { Fragment } from 'react'
import PropTypes from 'prop-types'

const WizardStep = ({ step, currentStep, children }) => {
  if (currentStep !== step) {
    return null
  }

  return <Fragment>{children}</Fragment>
}

WizardStep.propTypes = {
  step: PropTypes.number.isRequired,
  currentStep: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired
}

WizardStep.defaultProps = {
  step: 1,
  currentStep: 1
}

export default WizardStep
