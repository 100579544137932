import styled from '@emotion/styled'
import { css } from '@emotion/react'

const baseStyles = ({ theme }) => css`
  display: flex;
  justify-content: center;
  align-items: center;

  margin: ${theme.spacings.mega} auto;
`

export const FooterAd = styled('div')(baseStyles)
