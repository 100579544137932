import { useQuery } from '@tanstack/react-query'

import { getCategoriesTree } from 'api/Header/categories'

function useGetCategoriesTree() {
  return useQuery(['getCategoriesTree'], async () => {
    const response = await getCategoriesTree()

    return response
  })
}

export default useGetCategoriesTree
