import BaseTracking from './base-tracking'

class RichRelevanceSearchTracking extends BaseTracking {
  static componentName = 'rich-relevance-search-tracking'

  sendItems (data, R3_COMMON) {
    R3_COMMON.addPlacementType(data.placementType)

    var R3_SEARCH = new r3_search() // eslint-disable-line
    R3_SEARCH.setTerms(data.searchTerms)

    return R3_SEARCH
  }
}

export default ($el) => new RichRelevanceSearchTracking($el).init()

export { RichRelevanceSearchTracking as Component }
