import $ from 'jquery'
import querystring from 'querystring'
import paginationTemplate from 'templates/pagination'

import scroll from 'garden/src/js/utils/scroll'

import emitter from 'scripts/utils/emitter'
import BaseComponent from 'scripts/components/base-component'
import { showSpinner, hideSpinner } from 'scripts/utils/spinner'

const DEFAULTS = {
  noResults: {
    title: 'Oops, não foi possível concluir a operação!',
    message: 'Desculpe, não há produtos com os filtros escolhidos. :('
  },
  timeout: {
    ms: 15000,
    message: `Ops. O Site demorou muito para responder,
    por favor tente atualizar a página`
  }
}

class ProductsPagination extends BaseComponent {
  static componentName = 'products-pagination'

  static DOMEvents = {
    'click a': 'onElementClick'
  }

  static emitterEvents = {
    'products-pagination:request': 'onRequestProducts'
  }

  constructor (
    element,
    options = {},
    location = window.location,
    history = window.history
  ) {
    super(element)
    this.options = $.extend({}, DEFAULTS, options)
    this.location = location
    this.history = history
  }

  init () {
    this.container = $('[data-col]')
    this.updateElements()
    this.scroll = scroll

    $(window).on('popstate', this.onPopState.bind(this))
  }

  getFilters () {
    const queryString = this.location.search.slice(1)
    const queryObject = this.parse(queryString)

    delete queryObject.page

    return queryObject
  }

  parse (string) {
    return querystring.parse(string)
  }

  updateElements () {
    this.filters = this.getFilters()
    const $pages = this.$element.find('[data-pagination-item]')

    $pages.map(this.updateHref.bind(this))
  }

  updateHref (index, anchor) {
    const queryString = (anchor.search || '').slice(1)
    const queryObject = this.parse(queryString)

    const filters = $.extend({}, this.filters, queryObject)
    const params = decodeURIComponent(querystring.stringify(filters))
    const pathname = this.location.pathname.replace(/\/$/, '')

    $(anchor).attr('href', pathname + (params ? `?${params}` : ''))
  }

  onElementClick (event) {
    event.preventDefault()

    const $target = $(event.target)
    const $anchor = $target.closest('a')
    const url = $anchor.attr('href')

    this.onRequestProducts(url, false)
  }

  onRequestProducts (url, refreshFilters) {
    this.history.pushState({}, '', url)
    this.requestProducts(url, refreshFilters)
  }

  requestProducts (url, productsFilterRefresh = true, shouldScroll = true) {
    showSpinner()

    if (shouldScroll) {
      this.scroll(this.container, { offset: -120 })
    }

    this.filtersRefresh = productsFilterRefresh

    const options = {
      url,
      timeout: this.options.timeout.ms,
      data: { json: true }
    }

    $.ajax(options)
      .done(this.onRequestSuccess.bind(this))
      .fail(this.onRequestFailure.bind(this))
      .always(hideSpinner)
  }

  onRequestSuccess ({ products, _links, facets, assistedSale, ignoreExclusive }) {
    const origin = this.location.pathname.split('/')[1]
    const options = { facets, origin, assistedSale, ignoreExclusive }

    products = products.filter(({ price }) => price.to_price)

    if (!products || !products.length) {
      this.history.back()

      return emitter.emit('stickyFeedback:error', {
        title: this.options.noResults.title,
        content: this.options.noResults.message
      })
    }

    emitter.emit('entryKey:update:products', { products, assistedSale })
    this.renderPagination(_links)

    if (this.filtersRefresh) {
      emitter.emit('entryKey:update:facets', options)
      emitter.emit('entryKey:update:facetsOverlay', options)
      emitter.emit('entryKey:update:highlightedFacets', options)
    }
  }

  onRequestFailure ({ statusText }) {
    if (statusText === 'timeout') {
      emitter.emit('stickyFeedback:error', {
        content: this.options.timeout.message
      })
    }
  }

  renderPagination ({ previous, next, pages, current }) {
    const $pagination = this.$element
    const page = this.extractPage(current) || 1

    $pagination.html(paginationTemplate({ previous, next, pages, page }))
    this.updateElements()
  }

  extractPage (href) {
    const queryString = href.split('?')[1]
    const queryObject = this.parse(queryString)

    return queryObject.page
  }

  onPopState () {
    emitter.emit('ordering-filters:refresh')
    this.requestProducts(this.location.href, true, false)
  }
}

export default ($el) => new ProductsPagination($el).init()

export { ProductsPagination as Component }
