import { css } from '@emotion/react'
import styled from '@emotion/styled'

const titleStyles = ({ theme }) => css`
  label: new-product-thumb__title;

  color: ${theme.colors.n900};

  margin: 0;

  height: 40px;
  display: block;

  font-size: 12px;
  line-height: 15px;

  word-break: break-word;

  margin: 6px 0 0;
  padding: 0 ${theme.spacings.byte};

  overflow: visible;
  max-width: unset;

  ${theme.media.giga} {
    font-size: ${theme.typography.text.kilo.fontSize};
  }
`

const middleWrapperStyles = ({ theme }) => css`
  label: new-product-thumb__middle-wrapper;

  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: ${theme.spacings.bit};
`

const productRatingStyles = ({ theme }) => css`
  label: new-product-thumb__product-rating;

  color: ${theme.colors.n600};
  font-size: ${theme.typography.subHeadings.kilo.fontSize};

  margin: ${theme.spacings.kilo} 0 ${theme.spacings.kilo};
  padding: 0 6px;

  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;

  ${theme.media.giga} {
    margin: ${theme.spacings.kilo} 0 ${theme.spacings.kilo};
    padding: 0 ${theme.spacings.byte};
  }

  .product-rating {
    .bv_numReviews_component_container {
      margin-left: -${theme.spacings.byte} !important;
    }
  }
`

const productCodeStyles = () => css`
  label: new-product-thumb__product-code;

  font-size: 12px;
  line-height: 12px;

  white-space: nowrap;
`

const serviceBadgeStyles = ({ theme }) => css`
  label: new-product-thumb__service-badge;

  width: 32px;
  height: 32px;

  margin-right: ${theme.spacings.byte};
`

const ratingWrapperStyles = ({ theme }) => css`
  label: new-product-thumb__review-rating;

  margin-top: ${theme.spacings.bit};
`

export const Title = styled('a')(titleStyles)
export const MiddleWrapper = styled('div')(middleWrapperStyles)
export const ProductRating = styled('div')(productRatingStyles)
export const ProductCode = styled('span')(productCodeStyles)
export const ServiceBadge = styled('img')(serviceBadgeStyles)
export const ReviewWrapper = styled('div')(ratingWrapperStyles)
