import PropTypes from 'prop-types'

const picturePropTypes = {
  url: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  image_size: PropTypes.shape({
    height: PropTypes.number,
    width: PropTypes.number
  }),
  sideBackgroundColors: PropTypes.shape({
    left: PropTypes.string,
    right: PropTypes.string
  })
}

export const pricePropTypes = {
  to: PropTypes.shape({
    integers: PropTypes.string.isRequired,
    decimals: PropTypes.string.isRequired
  }),
  from: PropTypes.shape({
    integers: PropTypes.string.isRequired,
    decimals: PropTypes.string.isRequired
  })
}

export default {
  id: PropTypes.string,
  index: PropTypes.number,
  position: PropTypes.number,
  type: PropTypes.string,
  title: PropTypes.string,
  elementType: PropTypes.string,
  url: PropTypes.string,
  product: PropTypes.shape({
    price: PropTypes.shape(pricePropTypes).isRequired,
    promotionalStock: PropTypes.number.isRequired
  }),
  schedule: PropTypes.shape({
    start: PropTypes.number,
    end: PropTypes.number
  }),
  pictures: PropTypes.shape({
    two_banners: PropTypes.shape(picturePropTypes),
    three_banners: PropTypes.shape(picturePropTypes),
    two_banners_wide: PropTypes.shape(picturePropTypes),
    mobile_banner: PropTypes.shape(picturePropTypes),
    full_banner: PropTypes.shape(picturePropTypes),
    single_informative: PropTypes.shape(picturePropTypes),
    three_informative: PropTypes.shape(picturePropTypes),
    carousel_full_banner: PropTypes.shape(picturePropTypes)
  }),
  showCountDown: PropTypes.bool,
  onClick: PropTypes.func
}
