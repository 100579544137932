import { Hidden, Visible } from '@leroy-merlin-br/backyard-react'
import { useEffect, useState } from 'react'

import { PersonalDataMobile } from './PersonalDataMobile'
import { PersonalDataDesktop } from './PersonalDataDesktop'
import { getProfile } from '../../services'
import { useMyAccountPersonalDataContext } from '../../context/my-account-personal-data-context'
import { ProfileResponse } from '../../types'

const PersonalData = () => {
  const { contextData, updateData } = useMyAccountPersonalDataContext()

  const [loading, setLoading] = useState(true)

  const [data, setData] = useState(contextData)

  useEffect(() => {
    (async () => {
      const profile: ProfileResponse = await getProfile()

      if (profile.data) {
        const newData = {
          name: profile?.data?.customer?.name,
          fiscalId: profile?.data?.fiscalId,
          birthdate: profile?.data?.customer?.birthdate || '--/--/----'
        }

        updateData(newData)

        setData(newData)
      }

      setLoading(false)
    })()
  }, [updateData])

  return (
    <>
      <Visible when={['untilKilo', 'kilo']}>
        {contextData && <PersonalDataMobile loading={loading} data={data} />}
      </Visible>
      <Hidden when={['untilKilo', 'kilo']}>
        {contextData && <PersonalDataDesktop loading={loading} data={data} />}
      </Hidden>
    </>
  )
}

export default PersonalData
