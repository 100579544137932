import styled from '@emotion/styled'
import { css } from '@emotion/react'

import type { Theme } from 'scripts/react-components/themes/Theme'

const wrapperStyles = ({ theme }: Theme) => css`
  label: drag-and-drop__wrapper;
  position: fixed;
  width: 100%;
  z-index: 100;
  transition: transform 0.8s ease;
`

const move = (pixels: number) => css`
  transform: translateY(100px);
  bottom: -${pixels}px;
`

const moving = (pixels: number) => css`
  bottom: -${pixels}px;
`

const wrapperHeader = ({ theme }: Theme) => css`
  label: drag-and-drop__header;
  background-color: ${theme.colors.p100};
  border-radius: ${theme.borderRadius.giga} ${theme.borderRadius.giga} 0px 0px;
  position: relative;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 21px;
  cursor: move;
`

const wrapperLine = ({ theme }: Theme) => css`
  label: drag-and-drop__line;
  position: absolute;
  top: calc(50% - 3px);
  left: calc(50% - ${theme.spacings.kilo});
  width: 24px;
  height: 5px;
  border-radius: ${theme.borderRadius.mega};
  background-color: ${theme.colors.p200};
`

export const Wrapper = styled('div')(wrapperStyles)
export const WrapperHeader = styled('div')(wrapperHeader)
export const WrapperLine = styled('div')(wrapperLine)
export const Move = move
export const Moving = moving
