import { Skeleton } from '@backyard-ui/core'
import RedeemProvider, { useRedeemContext } from './contexts/RedeemContext'

function Component() {
  const { componentToRender } = useRedeemContext()

  if (!componentToRender) return <Skeleton width="100%" height="408px" />

  return componentToRender
}

export default function CashbackRedeem() {
  return (
    <RedeemProvider>
      <Component />
    </RedeemProvider>
  )
}
