import { call, put, takeLatest, select } from 'redux-saga/effects'

import * as services from 'checkout/services/cart'

import { Actions, Selectors } from './'

function * getServices () {
  try {
    const products = yield select(Selectors.stringifiedProductsId)
    const { data, status } = yield call(services.getMiniCartServices, products)

    if (status === 200) {
      yield put(Actions.setAvailableServices(data.data))
    }
  } catch (err) {
    throw new Error(err)
  }
}

export default function * root () {
  yield takeLatest('GET_AVAILABLE_SERVICES', getServices)
}
