import React from 'react'

import Typography, { TypographyProps } from '../Typography'

type SubtitleType = Omit<TypographyProps, 'variant'> & {
  size?: 'md' | 'lg'
  type?: 'default'
}

function Subtitle ({ size = 'md', type, children, ...rest }: SubtitleType) {
  if (React.isValidElement(children)) {
    throw new Error(
      'Subtitle component does not accept React elements as children'
    )
  }

  return (
    <Typography variant={`subtitle-${size}`} type={type} {...rest}>
      {children}
    </Typography>
  )
}

export default Subtitle
