import PropTypes from 'prop-types'
import { Text, Stack, Icon } from '@leroy-merlin-br/backyard-react'

import { STATUS_DATA } from 'lmcv/pages/Customers/constants/statusData'

import * as S from './styled'

const TimelineItem = ({ icon, children, status }) => {
  const currentColor = STATUS_DATA[status].color

  return (
    <S.Container color={currentColor}>
      <Stack space="giga">
        <Icon as={icon || STATUS_DATA[status].icon} />

        <Text size="kilo">{children}</Text>
      </Stack>
    </S.Container>
  )
}

TimelineItem.propTypes = {
  status: PropTypes.string,
  icon: PropTypes.string,
  children: PropTypes.node
}

export default TimelineItem
