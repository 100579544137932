import { flatten } from 'lodash'

import * as services from 'checkout/services/cart'

export const stringifiedProductsId = cart => {
  const ids = flatten(
    Object.keys(cart.shippings).map(shippingType =>
      cart.shippings[shippingType].products.map(product => ({
        id: product.productId,
        skuId: product.skuId
      }))
    )
  )

  return qs.stringify(
    {
      regionId: cart.region,
      products: ids
    },
    {
      addQueryPrefix: true,
      arrayFormat: 'indices',
      encode: false,
      skipNulls: true
    }
  )
}

export const getProductsServices = async (shipments, regionInit) => {
  const deliveries = shipments.shippings
  const region = shipments.region ? shipments.region.id : regionInit
  const serviceList = []

  for (const delivery of deliveries) {
    const shipments = delivery.shipments ?? [delivery]

    for (const shipment of shipments) {
      for (const item of shipment.items) {
        const response = await services.getServices(region, [
          { id: item.productId, skuId: item.skuId }
        ])

        if (response.status === 200) {
          serviceList.push(response.data.data)
        }
      }
    }
  }

  return serviceList
}
