import { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

import { ShapeThumb } from 'shared/constants/prop-types'
import { newsArticleSchema } from 'shared/constants/contentSchema'

import { ThumbPicture } from './components/ThumbPicture'
import { ThumbInfo } from './components/ThumbInfo'
import * as S from './styled'

const BeInspiredThumb = ({ item }) => (
  <Fragment>
    <Helmet>
      <script type="application/ld+json">
        {`${JSON.stringify(newsArticleSchema(item))}`}
      </script>
    </Helmet>

    <S.ThumbWrapper>
      <ThumbPicture data={item}>
        <ThumbInfo data={item} />
      </ThumbPicture>
    </S.ThumbWrapper>
  </Fragment>
)

BeInspiredThumb.propTypes = {
  item: PropTypes.shape({ ...ShapeThumb }).isRequired
}

export default BeInspiredThumb
