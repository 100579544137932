import { useState } from 'react'
import PropTypes from 'prop-types'

import { parseTime } from 'site/Modular/components/Banner/utils'

import { useInterval } from 'utils/hooks/use-interval'

import { Countdown } from './Countdown'

const EndTimeBadge = ({
  category, time, updateBanner
}) => {
  const [parsedTime, setParsedTime] = useState(parseTime(time))

  useInterval(() => {
    const isBannerExpired = time.getTime() - Math.round(Date.now()) <= 1000

    if (isBannerExpired) return updateBanner()

    const currentTime = parseTime(time)

    const hasUpdatedTime =
      currentTime.days !== parsedTime.days ||
      currentTime.hours !== parsedTime.hours ||
      currentTime.minutes !== parsedTime.minutes

    if (hasUpdatedTime) {
      setParsedTime(currentTime)

      return
    }

    const hasDays = currentTime.days !== 0

    if (!hasDays) {
      setParsedTime(currentTime)
    }
  }, 1000)

  return (
    <Countdown category={category} time={parsedTime} />
  )
}

EndTimeBadge.propTypes = {
  time: PropTypes.instanceOf(Date).isRequired,
  updateBanner: PropTypes.func.isRequired,
  showCountDown: PropTypes.bool,
  category: PropTypes.string.isRequired
}

export default EndTimeBadge
