export const SELECTS_NAMES = [
  'documents',
  'genders',
  'maritalStatus',
  'nationalities',
  'occupations',
  'states'
]

export const BRAZIL_CODE = '1'
