import { Shell } from 'shared/components/Shell'

import * as S from './styled'

const AuthLayoutShell = () => (
  <S.Wrapper>
    <div>
      <Shell width="100%" height={308} borderRadius="mega" />
    </div>
    <div>
      <Shell width="100%" height={408} borderRadius="mega" />
    </div>
  </S.Wrapper>
)

export default AuthLayoutShell
