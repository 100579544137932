import {
  Heading,
  Inline,
  Radio,
  Stack,
  Text
} from '@leroy-merlin-br/backyard-react'
import { Fragment } from 'react'

import { Customer } from '../../types'
import * as S from './styled'

type CustomersProps = {
  customers: Customer[]
  customerSelected?: Customer
  onSelectCustomer: (customer: Customer) => void
}

const Customers = ({
  customers,
  customerSelected,
  onSelectCustomer
}: CustomersProps) => (
  <Fragment>
    <S.Title>
      <Heading size="tera" as="h3" noMargin>
        Escolha um cliente para compartilhar
      </Heading>
      <Text size="kilo" color="n500" noMargin>
        Escolha apenas um de seus clientes ativos para enviar o cupom
      </Text>
    </S.Title>

    <Stack>
      {customers.length &&
        customers.map(item => (
          <div key={item.id}>
            <Radio
              name="rdClient"
              value="true"
              isChecked={customerSelected?.id === item.id}
              onChange={() => onSelectCustomer(item)}
            >
              <Inline alignY="center" space="kilo">
                <S.Avatar image={item.picture}>
                  {!item.picture && (
                    <Heading size="giga" color="n700" as="h6" noMargin>
                      {item.customerName?.[0]}
                    </Heading>
                  )}
                </S.Avatar>

                <div>
                  <Text size="giga" color="n600" noMargin>
                    {item.customerName}
                  </Text>
                  <Inline space="bit" alignY="center">
                    <S.Dot />
                    <Text size="kilo" color="p600" noMargin>
                      {item.projectName}
                    </Text>
                  </Inline>
                </div>
              </Inline>
            </Radio>
          </div>
        ))}
    </Stack>
  </Fragment>
)

export default Customers
