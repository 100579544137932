export const REASONS = [
  {
    id: 1,
    text: 'Informações incorretas e desatualizadas dos dados fornecido;'
  },
  {
    id: 2,
    text:
      'Cenário econômico negativo e desinteresse comercial da instuição financeira;'
  },
  { id: 3, text: 'Pagamento atrasado das contas.' }
]
