import { Heading, IconButton } from '@backyard-ui/core'
import { CrossOutline } from '@backyard-ui/icons'

import styles from './styles'

interface HeaderProps {
  onClose?: () => void
}

const Header = ({ onClose }: HeaderProps) => (
  <div className={styles()}>
    <Heading color="white" size="md" asChild>
      <strong>Como podemos ajudar?</strong>
    </Heading>

    <IconButton
      icon={<CrossOutline />}
      onClick={onClose}
      data-testid="close-button"
    />
  </div>
)

export default Header
