import aa from 'search-insights'
import type { InsightsClient } from 'instantsearch.js/es/types/insights'

import { USER_ID } from '../constants'

const APP_ID = window.env.algolia?.appId
const API_KEY = window.env.algolia?.apiKey

if (USER_ID) {
  aa('init', {
    appId: APP_ID,
    apiKey: API_KEY,
    userToken: USER_ID
  })
} else {
  aa('init', {
    appId: APP_ID,
    apiKey: API_KEY,
    useCookie: true
  })
}

export const insightsConfig = {
  insightsClient: aa as InsightsClient
}

export const indexName = window.env.algolia?.indexName
