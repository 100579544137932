import Cookies from 'js-cookie'
import dayjs from 'dayjs'

import { sendToApp } from 'scripts/react-components/utils/app/tracker'
import { pushDataObject } from 'scripts/utils/data-layer'

const BANNER_INSTALL_APP_STORAGE_NAME = '@app:dateLatestCloseBannerInstallApp'
const COMPLETED_JOURNEY_STORAGE_KEY_NAME =
  '@app:completedAppInstallationCampaign'
const TIME_TO_SHOW_BANNER_AGAIN = 1440

const pageIsHome = () => {
  const { pathname } = window.location
  return pathname === '/'
}

const pageIsPdp = () => {
  const { pathname } = window.location
  return /[^\/]+[_]\d+/.test(pathname)
}

const pageIsAllwed = () => {
  return pageIsHome() || pageIsPdp()
}

const sumMinutesToDate = (date: Date, minutes: number) => {
  return new Date(date.getTime() + minutes * 60000)
}

const trackClickOnDownloadButton = () => {
  pushDataObject({
    event: 'download_app',
    step_name: 'cashback_click_download_app'
  })
}

const redirectToDownloadApp = (
  event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>,
  link: string
) => {
  event.preventDefault()
  window.location.replace(link)
}

const mustDisplayBannerAgain = () => {
  const localStorageLastCloseBannerInstallApp: string =
    localStorage.getItem(BANNER_INSTALL_APP_STORAGE_NAME) || ''

  const dateLocalStorage: Date | string = new Date(
    localStorageLastCloseBannerInstallApp
  )

  return (
    dateLocalStorage == 'Invalid Date' ||
    sumMinutesToDate(dateLocalStorage, TIME_TO_SHOW_BANNER_AGAIN) < new Date()
  )
}

const promotionCashbackIsActive = (dateStartPromotion: string) => {
  return dayjs().isAfter(dayjs(dateStartPromotion, 'DD/MM/YYYY HH:mm:ss'))
}

export const validDateFormat = (dateStartPromotion?: string) => {
  const formattedDate = dayjs(dateStartPromotion, 'DD/MM/YYYY HH:mm:ss')

  if (!dateStartPromotion || formattedDate.toString() === 'Invalid Date') {
    return undefined
  }

  return formattedDate.format('DD/MM/YYYY HH:mm:ss')
}

export const verifyShouldDisplayBannerWithCashback = (
  dateStartPromotion: string,
  isOnMobileBrowser: boolean,
  isOnMobileApp: boolean
): boolean => {
  const completedCashbackJourney = parseInt(
    localStorage.getItem(COMPLETED_JOURNEY_STORAGE_KEY_NAME) || '0'
  )

  if (
    contextCashbackIsValid(isOnMobileBrowser, isOnMobileApp) &&
    promotionCashbackIsActive(dateStartPromotion) &&
    mustDisplayBannerAgain() &&
    !completedCashbackJourney &&
    pageIsAllwed()
  ) {
    return true
  }

  return false
}

const contextCashbackIsValid = (
  isOnMobileBrowser: boolean,
  isOnMobileApp: boolean
) => {
  return (isOnMobileApp && !isUserLogged()) || isOnMobileBrowser
}

const isUserLogged = () => {
  const userId = Cookies.get('user_id')
  return !!userId
}

export const clickOnDownload = (
  link: string,
  event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
) => {
  trackClickOnDownloadButton()
  redirectToDownloadApp(event, link)
}

export const modalOpeningTracking = () => {
  sendToApp({
    event: 'download_app',
    properties: {
      step_name: 'cashback_know_more'
    }
  })
}

export const setTimeToDisplayAgain = () => {
  const timeOfClick = new Date()
  localStorage.setItem(BANNER_INSTALL_APP_STORAGE_NAME, timeOfClick.toString())
}
