import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { Theme } from 'newCheckout/types/Theme'

const wrapperStyles = ({ theme }: Theme) => css`
  label: success-products__wrapper;

  margin-top: ${theme.spacings.tera};
  display: flex;
  flex-direction: column;
  gap: ${theme.spacings.byte};
`
export const Wrapper = styled('div')(wrapperStyles)

const rowStyles = ({ theme }: Theme) => css`
  label: success-products__row;

  display: grid;
  align-items: baseline;
  grid-template-columns: 1fr;

  ${theme.media.mega} {
    grid-template-columns: 1fr 100px 150px;
  }
`
export const Header = styled('div')(rowStyles)

const whiteBackgroundProductStyle = ({ theme }: Theme) => css`
  label: success-products__white-background-product;

  height: 100%;
  width: 100%;

  padding:${theme.spacings.kilo} 0 ${theme.spacings.kilo} ${theme.spacings.kilo};

  border-radius: ${theme.borderRadius.mega};
  background-color: ${theme.colors.white};
`

export const WhiteBackgroundProduct = styled('div')(whiteBackgroundProductStyle)

const whiteBackgroundQuantityStyle = ({ theme }: Theme) => css`
  label: success-products__white-background-quantity;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  height: 100%;
  width: 100%;

  padding: ${theme.spacings.kilo} 0;

  background-color: ${theme.colors.white};
`

export const WhiteBackgroundQuantity = styled('div')(whiteBackgroundQuantityStyle)

const whiteBackgroundStyle = ({ theme }: Theme) => css`
  label: success-products__white-background;

  background-color: ${theme.colors.white};
`
export const WhiteBackground = styled('div')(whiteBackgroundStyle)

const whiteBackgroundPriceStyle = ({ theme }: Theme) => css`
  label: success-products__white-background-price;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  height: 100%;
  width: 100%;

  padding: ${theme.spacings.kilo} ${theme.spacings.kilo} ${theme.spacings.kilo} 0;
  border-radius: ${theme.borderRadius.mega};


  background-color: ${theme.colors.white};
`

export const WhiteBackgroundPrice = styled('div')(whiteBackgroundPriceStyle)

const productStyles = ({ theme }: Theme) => css`
  label: success-products__product;

  align-items: stretch;

  background-color: ${theme.colors.n100};
  border-radius: ${theme.borderRadius.mega};
`
export const ProductStyles = styled('div')(rowStyles, productStyles)

const itemInfostyle = ({ theme }: Theme) => css`
  label: success-products__item-info;

  padding: ${theme.spacings.mega} ${theme.spacings.giga} 0;

  background-color: ${theme.colors.n100};
  border-radius: ${theme.borderRadius.mega};
  
  &:not(:first-of-type) {
    margin-bottom: ${theme.spacings.giga};
  }

  ${theme.media.mega} {
    padding-bottom: ${theme.spacings.mega};
    padding-right: 0;
    
    &:not(:first-of-type) {
      margin-bottom: 0;
    }
  }
`
export const ItemInfo = styled('div')(itemInfostyle)

const itemQuantityStyle = ({ theme }: Theme) => css`
  label: success-products__item-quantity;

  display: none;

  ${theme.media.mega} {
    display: flex;
    justify-content: center;
    align-items: center;

    padding-top: ${theme.spacings.mega};
    padding-bottom: ${theme.spacings.mega};
    background-color: ${theme.colors.n100};
  }
`
export const ItemQuantity = styled('div')(itemQuantityStyle)

const itemPriceStyle = ({ theme }: Theme) => css`
  label: success-products__item-price;

  display: flex;
  align-items: center;

  padding-top: ${theme.spacings.kilo};
  padding-bottom: ${theme.spacings.mega};

  ${theme.media.mega} {
    margin-right: ${theme.spacings.mega};
    justify-content: center;
    padding-top: ${theme.spacings.mega};
    background-color: ${theme.colors.n100};
  }

  border-radius: ${theme.borderRadius.mega};
`
export const ItemPrice = styled('div')(itemPriceStyle)

const inlineStyles = ({ theme }: Theme) => css`
  label: success-products__inline;

  display: flex;
  flex-direction: row;
  gap: ${theme.spacings.byte};
  align-items: top;

  ${theme.media.mega} {
    align-items: center;
  }
`
export const ResponsiveInline = styled('div')(inlineStyles)
