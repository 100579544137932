import styled from '@emotion/styled'
import { css } from '@emotion/react'

const wrapperStyles = ({ theme }) => css`
  label: empty-cart__wrapper;

  margin-top: ${theme.spacings.mega};
  text-align: center;
`

export const Wrapper = styled('section')(wrapperStyles)
