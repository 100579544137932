import { Fragment, useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import {
  Button,
  TextField,
  Stack,
  Inline,
  Heading,
  Text
} from '@leroy-merlin-br/backyard-react'
import { useForm } from 'react-hook-form'

import * as relationship from 'lmcv/services/relationship'

import WizardStep from '../components/WizardStep'
import * as Ilustrations from '../components/Ilustrations'
import * as S from '../styled'

const InviteCustomer = ({
  step,
  inviteUrl,
  customerData,
  closeDrawer,
  onCustomerChange
}) => {
  const {
    formState: { errors },
    handleSubmit,
    register
  } = useForm({ mode: 'onChange' })

  const [isOnClipboard, setIsOnClipboard] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isSentToEmail, setIsSentToEmail] = useState(false)

  const { email } = customerData

  const onSendInvite = async () => {
    setIsLoading(true)

    const payload = {
      relationshipId: inviteUrl.split('/convite/')[1],
      guestEmail: email
    }

    const handleSuccess = () => {
      setIsSentToEmail(true)
      setTimeout(() => {
        window.location.reload()
        closeDrawer()
      }, 2000)
    }

    const handleError = err => {
      console.error(err)
    }

    await relationship
      .sendInvite(payload)
      .then(handleSuccess)
      .catch(handleError)
      .finally(() => setIsLoading(false))
  }

  const onCopyToClip = () => {
    setIsOnClipboard(true)
    setTimeout(() => setIsOnClipboard(false), 1000)
  }

  return (
    <WizardStep step={3} currentStep={step}>
      <Ilustrations.Envelope />
      {isSentToEmail ? (
        <Fragment>
          <Heading as="h3" size="mega" align="center">
            Convite enviado!
          </Heading>

          <Text as="p" size="mega" align="center">
            Seu convite foi enviado para{' '}
            <Text as="span" size="mega" isBold noMargin>
              {email}
            </Text>
          </Text>
        </Fragment>
      ) : (
        <form onChange={onCustomerChange} onSubmit={handleSubmit(onSendInvite)}>
          <Heading as="h3" size="mega">
            Convide seu cliente para participar do projeto
          </Heading>

          <S.InputsWrapper>
            <Stack space="kilo">
              <Inline alignY="end">
                <TextField
                  {...register('email', { required: 'Campo obrigatório.' })}
                  type="email"
                  label="Enviar por e-mail"
                  placeholder="Ex: joaodasilva@gmail.com"
                  state={Boolean(errors.email) && 'invalid'}
                  hint={errors.email?.message}
                />

                <Button type="submit" isLoading={isLoading}>
                  Enviar
                </Button>
              </Inline>

              <Inline alignY="end">
                <TextField
                  label="Compartilhar link"
                  value={inviteUrl}
                  isDisabled
                />

                <CopyToClipboard text={inviteUrl} onCopy={onCopyToClip}>
                  <Button type="button">
                    {isOnClipboard ? 'Copiado!' : 'Copiar'}
                  </Button>
                </CopyToClipboard>
              </Inline>
            </Stack>
          </S.InputsWrapper>
        </form>
      )}
    </WizardStep>
  )
}

export default InviteCustomer
