import { Skeleton } from '@backyard-ui/core'
import { useCashbackContext } from './contexts/CashbackContext'

export default function CashbackPage() {
  const { pageToRender, loading } = useCashbackContext()

  if (loading) return <Skeleton width="100%" height="408px" />

  return pageToRender
}
