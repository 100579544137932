import { useEffect, cloneElement } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { Button, Stack } from '@leroy-merlin-br/backyard-react'

import { Shell } from 'shared/components/Shell'

import { Actions } from 'lmcv/redux/modules/module'
import { CarouselSkeleton } from 'lmcv/pages/Home/components/CouponsCarousel/Skeleton'

import { Widget } from '../../../../components/Widget'
import { Spacing } from '../../components/Spacing'

function App ({ children, widget, ...props }) {
  const dispatch = useDispatch()
  const {
    get: { user, coupons, isRequest }
  } = useSelector(state => state.module, shallowEqual)

  useEffect(() => {
    dispatch(Actions.get(widget.lazyLoadingUrl))
  }, [dispatch, widget])

  const isLoyaltyMember = user.isMember

  if (!coupons.length) {
    return null
  }

  if (isRequest) {
    return (
      <Widget title="" withBackground noContentPadding>
        <Stack space="kilo">
          <Shell width={300} height={27} borderRadius="giga" />
          <CarouselSkeleton />
        </Stack>
      </Widget>
    )
  }

  return (
    <Widget title={widget.title} withBackground noContentPadding>
      {!isLoyaltyMember && (
        <Spacing>
          <span>
            Cupons de desconto disponíveis apenas para participantes do{' '}
            <a href="/fidelidade/como-funciona">Programa Fidelidade.</a>
          </span>
        </Spacing>
      )}

      {cloneElement(children, {
        ...props
      })}

      {!isLoyaltyMember && (
        <Spacing placement="top">
          <Button
            size="kilo"
            href={`/fidelidade?ref=fidelidade&origin=${location.href}`}
            isStretch
          >
            Participe do Programa Fidelidade e Ative
          </Button>
        </Spacing>
      )}
    </Widget>
  )
}

App.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func
  ])
}

export default App
