import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { mapValues } from 'lodash'
import {
  Button,
  Card,
  Stack,
  useModal,
  Text
} from '@leroy-merlin-br/backyard-react'
import { Popover } from 'user/components'
import {
  handlePhoneFormat,
  birthdateErrorMessage,
  handleErrorsMessage,
  isServerError
} from 'user/utils'
import { sendUserBasicData } from 'user/celebre/services'
import {
  ErrorModal,
  Spinner,
  RequestDoneModal,
  ExistingCardModal
} from 'user/celebre/components'
import { useCardRequestContext } from 'user/celebre/context/card-request-context'
import { useStateMachine } from 'little-state-machine'

import { changeDateFormat } from 'scripts/react-components/utils/date'
import { OptionalLabel } from 'scripts/react-components/shared/components/OptionalLabel'

import { NumberField, TextField } from 'shared/components'

import * as validator from 'utils/validators/validators'

import { FORMS_NAMES, FORM_DEFAULT_VALUES } from '../../constants'
import { RejectedCardModal, CNPJModalError } from './components'
import * as S from './styled'

const BasicForm = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [apiErrors, setApiErrors] = useState([])

  const {
    userData,
    isLoadingUserData,
    userDataError,
    mainAddress,
    updateAction,
    setShowExitConfirm,
    activeStep,
    setActiveStep
  } = useCardRequestContext()

  const { setValue, setError, handleSubmit, control, trigger, formState } =
    useForm({
      mode: 'onChange',
      defaultValues: FORM_DEFAULT_VALUES.basic
    })

  const { errors, isValid } = formState

  const { actions } = useStateMachine({ updateAction })

  const { setModal } = useModal()

  const hasErrors = useMemo(() => {
    return Object.keys(formState.errors).length > 0
  }, [formState])

  const getMainCellphone = (phones = []) => {
    const filteredPhones = phones.filter(({ type }) => type === 'cellphone')

    const mainPhone = filteredPhones.find(({ default: main }) => main)

    if (mainPhone) {
      return mainPhone.number
    }

    return filteredPhones[0]?.number
  }

  const onSubmit = async (formData) => {
    const birthdate = changeDateFormat(
      formData.birthdate,
      'DD/MM/YYYY',
      'YYYY-MM-DD'
    )

    const phone = `+55${formData.phone}`

    const { name, postalCode } = formData

    const updatedUserData = {
      name,
      postalCode,
      birthdate,
      phone
    }

    setIsLoading(true)

    try {
      const { data } = await sendUserBasicData(updatedUserData)

      if (data.status === 'DENIED') {
        setShowExitConfirm(false)

        return setModal({
          children: ({ onClose }) => <RejectedCardModal onClose={onClose} />,
          isCentered: true,
          shouldCloseOnOverlayClick: false
        })
      }

      if (data.status === 'APPROVED') {
        actions.updateAction({
          basic: updatedUserData,
          eligibility: data
        })

        setActiveStep(FORMS_NAMES.complement)
      }
    } catch ({ data, status }) {
      if (isServerError(status)) {
        setShowExitConfirm(false)

        return setModal({
          children: ({ onClose }) => <ErrorModal onClose={onClose} />,
          isCentered: true,
          shouldCloseOnOverlayClick: false
        })
      }

      setApiErrors(data.errors || [])
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (!userData) {
      return
    }

    const { type } = userData
    const isCompany = type === 'company'

    if (isCompany) {
      setShowExitConfirm(false)

      return setModal({
        children: ({ onClose }) => <CNPJModalError onClose={onClose} />,
        isCentered: true,
        shouldCloseOnOverlayClick: false
      })
    }

    const { canSubscribe, status } = userData.customer.celebre
    const hasExistingCard = !canSubscribe && status === 'active'
    const hasRequestDone = !canSubscribe && status === 'nonexistent'

    if (hasExistingCard) {
      return setModal({
        children: ({ onClose }) => <ExistingCardModal onClose={onClose} />,
        isCentered: true,
        shouldCloseOnOverlayClick: false
      })
    }

    if (hasRequestDone) {
      setShowExitConfirm(false)

      return setModal({
        children: ({ onClose }) => <RequestDoneModal onClose={onClose} />,
        isCentered: true,
        shouldCloseOnOverlayClick: false
      })
    }

    const formValues = {
      fiscalId: userData?.identifier,
      email: userData?.emails?.[0]?.address,
      name: userData?.customer.name,
      phone: getMainCellphone(userData?.customer.phones),
      birthdate: changeDateFormat(
        userData?.customer.birthdate,
        'YYYY-MM-DD',
        'DD/MM/YYYY'
      ),
      postalCode: mainAddress?.postalCode.number
    }

    mapValues(formValues, (value, key) => {
      setValue(key, value)
      trigger(key)
    })
  }, [userData, setValue, setModal, trigger, mainAddress, setShowExitConfirm])

  useEffect(() => {
    if (!userDataError) {
      return
    }

    if (isServerError(userDataError.status)) {
      return setModal({
        children: ({ onClose }) => <ErrorModal onClose={onClose} />,
        isCentered: true,
        shouldCloseOnOverlayClick: false
      })
    }
  }, [setModal, userDataError])

  useEffect(() => {
    apiErrors.forEach(({ code, message, messages }) => {
      setError(code, {
        type: 'manual',
        message: handleErrorsMessage(message, messages)
      })
    })
  }, [apiErrors, errors, setError])

  const isActive = activeStep === FORMS_NAMES.basic

  if (!isActive) {
    return
  }

  return (
    <Card
      title={
        <Popover
          list={{
            title: 'Seus dados serão usados para:',
            items: [
              'Te identificar',
              'Acessar sua conta',
              'Consultar pedidos',
              'Analisar crédito',
              'Realizar transações financeiras'
            ]
          }}
        >
          Dados Básicos
        </Popover>
      }
    >
      <Spinner isVisible={isLoadingUserData} />

      <form onSubmit={handleSubmit(onSubmit)} data-cy="basic-data-form">
        <Stack space="mega">
          <NumberField
            name="fiscalId"
            label="CPF"
            rules={{
              required: 'Insira seu CPF',
              validate: (value) => validator.isCpf(value) || 'CPF inválido'
            }}
            control={control}
            state={Boolean(errors.fiscalId) && 'invalid'}
            format="###.###.###-##"
            hint={errors.fiscalId?.message}
            isDisabled
          />

          <TextField
            name="email"
            type="email"
            label="E-mail"
            rules={{
              required: 'Insira seu e-mail',
              validate: (value) => validator.isEmail(value) || 'E-mail inválido'
            }}
            control={control}
            autoComplete="new-password"
            state={Boolean(errors.email) && 'invalid'}
            hint={errors.email?.message}
            isDisabled
          />

          <TextField
            name="name"
            label="Nome Completo"
            rules={{
              required: 'Insira seu nome',
              validate: (value) =>
                validator.isValidName(value) || 'Nome inválido'
            }}
            control={control}
            state={Boolean(errors.name) && 'invalid'}
            hint={errors.name?.message}
          />

          <NumberField
            name="phone"
            label={
              <Popover
                list={{
                  title: 'O celular é solicitado para:',
                  items: ['Identificação', 'Conclusão de etapas via Whatsapp']
                }}
              >
                Whatsapp
              </Popover>
            }
            rules={{
              required: 'Insira seu celular',
              validate: (value) =>
                validator.isCellPhone(value) || 'Celular inválido'
            }}
            control={control}
            state={Boolean(errors.phone) && 'invalid'}
            hint={errors.phone?.message}
            format={handlePhoneFormat}
          />

          <NumberField
            name="birthdate"
            label={<OptionalLabel>Data de nascimento</OptionalLabel>}
            rules={{
              validate: (value) => birthdateErrorMessage(value)
            }}
            control={control}
            state={Boolean(errors.birthdate) && 'invalid'}
            hint={errors.birthdate?.message}
            format="##/##/####"
            valueType="formatted"
            isDisabled={Boolean(userData?.customer?.birthdate)}
          />

          <NumberField
            name="postalCode"
            label={
              <Popover
                list={{
                  title:
                    'Este CEP será utilizado para registro e entrega de seu cartão.'
                }}
              >
                CEP
              </Popover>
            }
            control={control}
            rules={{
              required: 'Insira seu CEP',
              validate: (value) => validator.isCep(value) || 'CEP inválido'
            }}
            state={Boolean(errors.postalCode) && 'invalid'}
            hint={errors.postalCode?.message}
            format="#####-###"
            isDisabled={Boolean(mainAddress?.postalCode.number)}
          />

          <Text size="kilo" color="n500" noMargin>
            *Ao continuar, estou ciente que meus dados serão compartilhados com
            o Banco CETELEM e autorizo a disponibilizarem o meu histórico de
            crédito.
          </Text>
        </Stack>
        <S.ButtonWrapper>
          <Button
            isStretch
            type="submit"
            isLoading={isLoading}
            disabled={!isValid || hasErrors}
          >
            Continuar
          </Button>
        </S.ButtonWrapper>
      </form>
    </Card>
  )
}

export default BasicForm
