import { Fragment, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import {
  TextField,
  Select,
  Text,
  Heading,
  Button,
  Checkbox,
  Stack,
  Row,
  Col
} from '@leroy-merlin-br/backyard-react'
import { birthdateErrorMessage } from 'user/utils'

import { formatters } from 'utils/formatters'

import * as S from './styled'

const Form = ({
  initialState,
  regions = [],
  stores = [],
  occupations = [],
  onRegionChange,
  onSubmit,
  loading,
  isLoyaltyPro
}) => {
  const {
    birthDate,
    favoriteStore,
    preferences: { loyaltyProgram },
    regionSlug
  } = initialState

  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      birthDate: formatters.birthDate(birthDate),
      favoriteStore,
      loyaltyProgram,
      region: regionSlug
    }
  })

  const [buttonDisabled, setButtonDisabled] = useState(false)
  const [hasFavoriteStore, setHasFavoriteStore] = useState(true)
  const [isEmployee, setEmployee] = useState(false)

  const toggleCheckbox = event => setButtonDisabled(event)

  const handleRegion = event => onRegionChange(event.target.value)

  const formatDate = target => {
    target.value = target.value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d{1,2})/, '$1/$2')
      .replace(/(\d{2})\/(\d{2})(\d{1,4})/, '$1/$2/$3')
  }

  const buildOptionsCamelCase = (data, dataType) => (
    <Fragment>
      {data.map((item, index) => (
        <option key={`${item.region}-${index}`} value={item[dataType]}>
          {item[`${dataType}Name`]}
        </option>
      ))}
    </Fragment>
  )

  const buildOptionsByKey = data => (
    <Fragment>
      {data.map(item => (
        <option key={`${item.id}`} value={item.id}>
          {item.name}
        </option>
      ))}
    </Fragment>
  )

  const toggleEmployeeLoyalty = () => {
    setEmployee(!isEmployee)
  }

  useEffect(() => {
    if (stores.length && hasFavoriteStore) {
      setValue('preferences[favoriteStore]', favoriteStore)
      setHasFavoriteStore(false)
    }
  }, [stores, hasFavoriteStore, favoriteStore, setValue])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack space="mega">
        <TextField
          {...register('birthDate', {
            required: 'Campo data de nascimento é obrigatório.',
            maxLength: {
              value: 10,
              message: 'Você só pode digitar 10 caracteres'
            },
            validate: value => birthdateErrorMessage(value)
          })}
          type="text"
          id="birthDate"
          maxLength={10}
          onKeyUp={({ target }) => formatDate(target)}
          state={Boolean(errors.birthDate) && 'invalid'}
          placeholder="Data de nascimento"
          hint={errors.birthDate?.message}
        />

        <Row>
          <Col size={{ giga: 6 }}>
            <Select
              {...register('region', {
                required: 'Campo região é obrigatório.'
              })}
              id="region"
              state={Boolean(errors.region) && 'invalid'}
              onChange={handleRegion}
              placeholder="Escolha uma região"
              hint={errors.region?.message}
            >
              {buildOptionsCamelCase(regions, 'region')}
            </Select>
          </Col>
          <Col size={{ giga: 6 }}>
            <Select
              {...register('preferences[favoriteStore]', {
                required: 'Campo loja favorita é obrigatório.'
              })}
              id="preferences[favoriteStore]"
              state={Boolean(errors.preferences?.favoriteStore) && 'invalid'}
              placeholder="Escolha uma loja favorita"
              hint={errors.preferences?.favoriteStore?.message}
            >
              {buildOptionsByKey(stores)}
            </Select>
          </Col>
        </Row>

        <Checkbox
          {...register('loyalty_program', { required: true })}
          required
          id="loyalty_program"
          value="on"
          onChange={toggleCheckbox}
        >
          <Text size="kilo" noMargin>
            Aceito os termos e quero participar do programa de fidelidade{' '}
            <Text
              as="strong"
              size="kilo"
              isBold
              noMargin
              title="Visualizar regulamento do programa Leroy Merlin Com Você"
            >
              <a
                href="/institucional/regulamento-programa-leroy-merlin-com-voce"
                target="_blank"
              >
                Leroy Merlin Com Você.
              </a>
            </Text>
          </Text>
        </Checkbox>

        {isLoyaltyPro && (
          <Stack space="byte">
            <Checkbox
              {...register('loyalty_program_pro', { required: false })}
              id="loyalty_program_pro"
              value="on"
              onChange={toggleEmployeeLoyalty}
            >
              <Heading size="kilo" noMargin>
                Sou um profissional da construção civil
              </Heading>
            </Checkbox>
            <S.DescriptionWrapper>
              <Text size="mega" noMargin color="n600">
                Profissionais como Engenheiros, Arquitetos, Decoradores,
                Pedreiro, etc possuem vantagens e funcionalidades exclusivas
                como carteira de clientes e acesso a eventos.
              </Text>
            </S.DescriptionWrapper>
          </Stack>
        )}

        <Stack space="giga">
          {isEmployee && (
            <Select
              {...register('occupation', {
                required: 'Campo profissão é obrigatório.'
              })}
              id="occupation"
              state={Boolean(errors.occupation) && 'invalid'}
              placeholder="Selecionar profissão..."
              hint={
                errors.occupation?.message ||
                'Personalizaremos o programa de acordo com a sua profissão'
              }
            >
              {buildOptionsByKey(occupations)}
            </Select>
          )}

          <Button
            type="submit"
            size="kilo"
            isStretch
            isDisabled={!buttonDisabled}
            isLoading={loading}
          >
            Continuar
          </Button>
        </Stack>
      </Stack>
    </form>
  )
}

Form.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  regions: PropTypes.array,
  stores: PropTypes.array,
  isLoyaltyPro: PropTypes.bool
}

export default Form
