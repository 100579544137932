export const loginPostMessage = ({ accessToken, tokenType, expiresIn }) => {
  window.ReactNativeWebView?.postMessage(
    JSON.stringify({
      type: 'signUp',
      data: {
        accessToken,
        tokenType,
        expiresIn
      }
    })
  )
}

export const signupPostMessage = () => {
  window.ReactNativeWebView?.postMessage(
    JSON.stringify({
      type: 'signUpContinue'
    })
  )
}
