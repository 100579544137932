import { useState } from 'react'

import {
  FiscalIdValidateStep,
  OptionsToReceiveSecurityCodeStep,
  SecurityCodeStep,
  PasswordReset
} from './components'
import * as S from './styled'

type Step = {
  component: React.FC<{ onNextStep: () => void }>
}

const steps: Step[] = [
  {
    component: FiscalIdValidateStep
  },
  {
    component: OptionsToReceiveSecurityCodeStep
  },
  {
    component: SecurityCodeStep
  },
  {
    component: PasswordReset
  }
]

const Recovery = () => {
  const [currentStep, setCurrentStep] = useState(0)

  const handleNextStep = () => {
    const lastStep = 3

    if (currentStep < lastStep) {
      setCurrentStep(prevStep => prevStep + 1)
    }
  }

  const StepComponent = steps[currentStep].component

  return (
    <S.Wrapper>
      <StepComponent onNextStep={handleNextStep} />
    </S.Wrapper>
  )
}

export default Recovery
