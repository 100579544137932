export type Partner = 'algolia' | 'algonomy' | 'elastic search' | 'retail media'

export type ProductsListAttributesParams = {
  partnerName?: Partner
  item: {
    id: number
    name: string
    brand: string
    category?: string
    price?: number
    expressDeliveryDay?: 'today' | 'tomorrow'
  }
  index: number
  title: string
}

type ProductsListGtmDataAttributes = {
  'data-gtm-partner-name': Partner
  'data-gtm-item-id': number
  'data-gtm-item-list': string
  'data-gtm-item-brand': string
  'data-gtm-item-position': number
  'data-gtm-item-name': string
  'data-gtm-item-price'?: number
  'data-gtm-item-express-delivery': 'today' | 'tomorrow' | ''
  'data-gtm-item-category': string
}

export const productsListGenerator = (
  params: ProductsListAttributesParams
): ProductsListGtmDataAttributes => {
  const categoryTree = params.item.category

  return {
    'data-gtm-partner-name': params.partnerName ?? 'elastic search',
    'data-gtm-item-id': params.item.id,
    'data-gtm-item-list': params.title,
    'data-gtm-item-brand': params.item.brand,
    'data-gtm-item-position': params.index + 1,
    'data-gtm-item-name': params.item.name,
    'data-gtm-item-price': params.item.price,
    'data-gtm-item-express-delivery': params.item.expressDeliveryDay ?? '',
    'data-gtm-item-category': params.item.category ?? ''
  }
}
