import React from 'react'

import Typography, { TypographyProps } from '../Typography'

type LongFormType = Omit<TypographyProps, 'variant'> & {
  size?: 'md' | 'lg'
  type?: 'default' | 'strong' | 'emphasis'
}

function LongForm ({ size = 'md', type, children, ...rest }: LongFormType) {
  if (React.isValidElement(children)) {
    throw new Error(
      'LongForm component does not accept React elements as children'
    )
  }

  return (
    <Typography variant={`longform-${size}`} type={type} {...rest}>
      {children}
    </Typography>
  )
}

export default LongForm
