import { Icon } from '@leroy-merlin-br/backyard-react'
import { SpinnerCubeOutline } from '@backyard-ui/icons'

import * as S from './styled'

const Button = ({ text, ...props }) => (
  <S.Button {...props}>
    <Icon as={SpinnerCubeOutline} />
    {text}
  </S.Button>
)

export default Button
