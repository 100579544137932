import { useEffect, useState, useCallback, memo } from 'react'
import PropTypes from 'prop-types'

import emitter from 'scripts/utils/emitter'
import * as RecommendationRepository from 'scripts/api/recommendation'

import Carousel from './Carousel'

const RecommendationCarousel = ({
  data: { placement },
  withBackground,
  noContentPadding,
  noTopSpacing
}) => {
  const [widgetProps, setWidgetProps] = useState({
    products: [],
    title: ''
  })

  const getProducts = useCallback(
    async recommendationsList => {
      const recommendations =
        recommendationsList &&
        recommendationsList.filter(item => item.name === placement)[0]

      if (!recommendations) {
        return
      }

      const productsData = recommendations.items.map(item => ({
        id: parseInt(item.id, 10),
        trackingUrl: item.trackingUrl
      }))

      const response = await RecommendationRepository.getProducts(productsData)

      if (response?.status === 200) {
        return setWidgetProps({
          products: response.data,
          title: recommendations?.message
        })
      }
    },
    [placement]
  )

  useEffect(() => {
    const placements = window.RR?.data?.JSON?.placements || []

    placements.length === 0
      ? emitter.on('recommendation', getProducts)
      : getProducts(placements)
  }, [getProducts])

  if (!widgetProps.products.length) {
    return null
  }

  return (
    <Carousel
      partnerName="algonomy"
      widget={{ ...widgetProps }}
      withBackground={withBackground}
      noContentPadding={noContentPadding}
      noTopSpacing={noTopSpacing}
    />
  )
}

RecommendationCarousel.propTypes = {
  data: PropTypes.shape({
    placement: PropTypes.string.isRequired
  }).isRequired
}

export default memo(RecommendationCarousel)
