import { useState, useEffect, useCallback, memo } from 'react'
import Cookies from 'js-cookie'
import PropTypes from 'prop-types'

import { Shell } from 'shared/components/Shell'

import node from './utils/prop-types'
import { getBreadcrumb } from './services'
import { DEFAULT_NODE } from './utils/constants'
import { List } from './components/List'
import { Item } from './components/Item'
import * as S from './styled'

const Breadcrumb = ({
  nodes: propsNodes,
  basehost,
  categoryId,
  contentId,
  productId
}) => {
  const isOnMobileApp = Cookies.get('is_on_mobile_app') || false
  const shouldRenderContent = !isOnMobileApp

  const [isLoading, setIsLoading] = useState(true)
  const [nodes, setNodes] = useState([])

  const getContext = useCallback(() => {
    if (productId) {
      return {
        type: 'product',
        id: productId
      }
    }

    if (contentId) {
      return {
        type: 'content',
        id: contentId
      }
    }

    if (categoryId) {
      return {
        type: 'category',
        id: categoryId
      }
    }

    return null
  }, [productId, contentId, categoryId])

  const fetchNodes = useCallback(async () => {
    const context = getContext()

    const response = await getBreadcrumb({ basehost, context })

    return response.length ? response : DEFAULT_NODE
  }, [getContext, basehost])

  useEffect(() => {
    if (propsNodes) {
      setNodes(propsNodes)
      setIsLoading(false)

      return
    }

    const hasContext = getContext()

    if (!hasContext) {
      setNodes(DEFAULT_NODE)
      setIsLoading(false)

      return
    }

    const getNodes = async () => {
      const breadcrumbNodes = await fetchNodes()

      setNodes(breadcrumbNodes)
      setIsLoading(false)
    }

    getNodes()
  }, [propsNodes, getContext, fetchNodes])

  if (isLoading) {
    return <Shell width="40vw" height={20} />
  }

  return (
    <S.Breadcrumb data-testid="breadcrumb">
      {shouldRenderContent && (
        <List>
          {nodes.map((node, index) => {
            const isLastItem = index === nodes.length - 1

            return (
              <Item
                key={node.id || node.name}
                node={node}
                isLastItem={isLastItem}
              />
            )
          })}
        </List>
      )}
    </S.Breadcrumb>
  )
}

Breadcrumb.propTypes = {
  basehost: PropTypes.string,
  categoryId: PropTypes.string,
  contentId: PropTypes.string,
  nodes: PropTypes.arrayOf(PropTypes.shape({ ...node })),
  productId: PropTypes.string
}

export default memo(Breadcrumb)
