export const sendPostMessage = (
  data = {},
  type = 'onLoadReactRoute'
) =>
  window.ReactNativeWebView && window.ReactNativeWebView.postMessage(
    JSON.stringify({ data, type })
  )

export const sendUrlInPostMessage = () => sendPostMessage({
  url: window.location && window.location.href
})
