import { PropsWithChildren } from 'react'
import { Badge as BadgeDS } from '@leroy-merlin-br/backyard-react'

type Props = {
  label?: string
}

export const Badge = ({ label }: PropsWithChildren<Props>) => {
  return (
    <div className="mb-2">
      <BadgeDS appearance="primary" variant="solid">
        {label || 'Serviço de Instalação'}
      </BadgeDS>
    </div>
  )
}
