import { Fragment } from 'react'

import { renderTemplate } from './render-template'

const Pagination = ({ previous, next, pages, page }) => {
  const totalPages = pages.length
  const first = pages[0]
  const last = pages[totalPages - 1]

  const padding = 4

  const shouldDisplayFirst = page > 3
  const shouldDisplayPrevious = page > 1
  const shouldDisplayNext = page < totalPages
  const shouldDisplayLast = page < totalPages - 2

  if (totalPages <= 1) {
    return null
  }

  const renderItems = () => {
    let initialNumber = page < 3 ? 1 : page - 2
    const lastNumber =
      totalPages < initialNumber + padding
        ? totalPages
        : initialNumber + padding
    const diff = initialNumber - lastNumber + padding
    initialNumber = initialNumber - (initialNumber > diff ? diff : 0)

    const items = []

    // eslint-disable-next-line no-unmodified-loop-condition
    for (let i = initialNumber; i <= lastNumber; i++) {
      const url = pages[i - 1]

      items.push(
        <a
          key={i}
          className={`pagination-item ${i === Number(page) ? 'active' : ''}`}
          href={url}
          title={`Página ${i}`}
          data-pagination-item
        >
          {i}
        </a>
      )
    }

    return items
  }

  return (
    <Fragment>
      <a
        className={`pagination-item pagination-arrow ${
          shouldDisplayFirst ? '' : 'disabled'
        }`}
        title="Primeira"
        href={first}
        data-pagination-item
      >
        <i className="glyph glyph-double-arrow-left" />
      </a>
      <a
        className={`pagination-item pagination-arrow ${
          shouldDisplayPrevious ? '' : 'disabled'
        }`}
        title="Anterior"
        href={previous}
        data-pagination-item
      >
        <i className="glyph glyph-arrow-left" />
      </a>

      {renderItems()}

      <a
        className={`pagination-item pagination-arrow ${
          shouldDisplayNext ? '' : 'disabled'
        }`}
        title="Próxima"
        href={next}
        data-pagination-item
      >
        <i className="glyph glyph-arrow-right" />
      </a>
      <a
        className={`pagination-item pagination-arrow ${
          shouldDisplayLast ? '' : 'disabled'
        }`}
        title="Última"
        href={last}
        data-pagination-item
      >
        <i className="glyph glyph-double-arrow-right" />
      </a>
    </Fragment>
  )
}

const render = (props = {}) => renderTemplate(<Pagination {...props} />)

export default render
