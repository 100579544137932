import { useModal } from '@leroy-merlin-br/backyard-react'
import { useCallback } from 'react'

import { useResize } from 'scripts/react-components/utils/hooks/use-resize'

import {
  CouponModalCommon,
  CouponModalShare
} from 'lmcv/components/CouponModal'

type ModalType = 'CouponModalCommon' | 'CouponModalShare'

type ModalComponentProps = {
  id: string
  category: string
  active: boolean
}

const modalTypes = {
  CouponModalCommon: CouponModalCommon,
  CouponModalShare: CouponModalShare
}

export const useCouponModal = () => {
  const { setModal } = useModal()

  const [isMobile] = useResize(760)

  const getCustomStyle = useCallback(() => {
    const customStyle = {
      overlay: {
        overflow: 'auto',
        zIndex: 2147483648
      },
      content: {
        maxWidth: '100%'
      }
    }
    return isMobile ? customStyle : {}
  }, [isMobile])

  const openModal = useCallback((modalType: ModalType, modalComponentProps: ModalComponentProps) => {
    const Component = modalTypes[modalType]

    if (!Component) {
      throw new Error(`There is no modal config for "${modalType}"`)
    }

    setModal({
      size: 'giga',
      isCentered: true,
      style: getCustomStyle(),
      onClose: () => true,
      children: ({ onClose }) => (
        <Component {...modalComponentProps} onClose={onClose} />
      )
    })
  }, [setModal, getCustomStyle])

  return { openModal }
}
