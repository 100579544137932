import styled from '@emotion/styled'
import { css } from '@emotion/react'

const cardWrapperStyles = ({ theme }) => css`
  label: container;

  margin-bottom: ${theme.spacings.kilo};
`

const topContentStyles = ({ theme }) => css`
  label: top-content;

  display: flex;
  flex-direction: column;

  width: 100%;

  overflow: hidden;

  margin-bottom: ${theme.spacings.bit};
`

const relatedContentStyles = ({ theme }) => css`
  label: top-content__related-content;

  display: flex;
  flex-flow: column wrap;
  justify-content: start;
  align-items: start;

  ${theme.media.mega} {
    flex-flow: row wrap;
  }
`

const videoThumbStyles = ({ theme }) => css`
  label: top-content__video-thumb;

  position: relative;

  display: flex;

  width: 100%;

  margin-bottom: ${theme.spacings.giga};

  ${theme.media.giga} {
    margin-bottom: 0px;
  }

  & > div {
    flex: 1;
  }

  img {
    border-radius: ${theme.borderRadius.tera};

    width: 100%;
    height: 100%;
  }

  &:after {
    content: '';

    background: ${theme.colors.black};
    opacity: 0.48;

    border-radius: ${theme.borderRadius.tera};

    display: block;

    position: absolute;
    left: 0;
    top: 0;

    height: 100%;
    width: 100%;
  }
`

const videoTitleStyles = ({ theme }) => css`
  label: top-content__video-title;

  position: absolute;
  bottom: ${theme.spacings.kilo};
  left: ${theme.spacings.kilo};

  z-index: 1;
`

const contentWrapperStyles = ({ theme, hasVideo }) => css`
  label: top-content__content__wrapper;

  width: 100%;

  display: grid;
  grid-template-columns: 1fr;
  grid-auto-flow: row;
  grid-gap: ${theme.spacings.mega};

  & > a {
    justify-content: space-between;
  }

  ${theme.media.mega} {
    grid-template-columns: repeat(${hasVideo ? 2 : 3}, 1fr);
    grid-template-rows: repeat(3, auto);
    grid-auto-flow: column;
  }
`

const wrapperStyles = ({ theme }) => css`
  label: wrapper;

  margin-top: ${theme.spacings.giga};
  margin-bottom: ${theme.spacings.tera};
`

export const CardWrapper = styled('div')(cardWrapperStyles)
export const TopContent = styled('div')(topContentStyles)
export const RelatedContent = styled('div')(relatedContentStyles)
export const VideoThumb = styled('a')(videoThumbStyles)
export const VideoTitle = styled('div')(videoTitleStyles)
export const ContentWrapper = styled('div')(contentWrapperStyles)
export const Wrapper = styled('div')(wrapperStyles)
