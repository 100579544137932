import styled from '@emotion/styled'
import { css, keyframes } from '@emotion/react'
import { Stack } from '@leroy-merlin-br/backyard-react'

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

const animateNotificationStyle = () => css`
  label: notification--fadeIn;

  display: flex;
  animation: ${fadeIn} 1s ease-in-out;
`

const stackBorderedStyles = ({ theme }) => css`
  label: stack--bordered;

  > div {
    border-bottom: 1px solid ${theme.colors.n100};

    padding-bottom: ${theme.spacings.kilo};

    &:last-child {
      border-bottom: 0;

      padding-bottom: 0;
    }
  }
`

export const AnimateNotification = styled('div')(animateNotificationStyle)
export const StackBordered = styled(Stack)(stackBorderedStyles)
