export const CROSS_SERVICE_GET_LIST = '@CROSS_SERVICE_GET_LIST'
export const CROSS_SERVICE_GET_LIST_ERROR = '@CROSS_SERVICE_GET_LIST_ERROR'
export const CROSS_SERVICE_LIST_UPDATE = '@CROSS_SERVICE_LIST_UPDATE'
export const CROSS_SERVICE_LIST_CLEAR = '@CROSS_SERVICE_LIST_CLEAR'
export const CROSS_SERVICE_REMOVE_ITEM = '@CROSS_SERVICE_REMOVE_ITEM'
export const CROSS_SERVICE_DELETE = '@CROSS_SERVICE_DELETE'
export const CROSS_SERVICE_CLEAR = '@CROSS_SERVICE_CLEAR'
export const CROSS_SERVICE_POST_QUANTITY = '@CROSS_SERVICE_POST_QUANTITY'
export const CROSS_SERVICE_POST_OVERRIDE = '@CROSS_SERVICE_POST_OVERRIDE'
export const CROSS_SERVICE_POST_QUANTITY_ERROR = '@CROSS_SERVICE_POST_QUANTITY_ERROR'
export const CROSS_SERVICE_QUANTITY_UPDATE = '@CROSS_SERVICE_QUANTITY_UPDATE'
export const CROSS_SERVICE_LIST_UPDATE_AFTER_REMOVE_ITEM = '@CROSS_SERVICE_LIST_UPDATE_AFTER_REMOVE_ITEM'

export default {
  CROSS_SERVICE_GET_LIST,
  CROSS_SERVICE_LIST_UPDATE,
  CROSS_SERVICE_LIST_CLEAR,
  CROSS_SERVICE_REMOVE_ITEM,
  CROSS_SERVICE_DELETE,
  CROSS_SERVICE_CLEAR,
  CROSS_SERVICE_POST_QUANTITY,
  CROSS_SERVICE_POST_OVERRIDE,
  CROSS_SERVICE_LIST_UPDATE_AFTER_REMOVE_ITEM,
  CROSS_SERVICE_QUANTITY_UPDATE,
  CROSS_SERVICE_GET_LIST_ERROR,
  CROSS_SERVICE_POST_QUANTITY_ERROR
}
