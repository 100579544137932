import { Stack } from '@backyard-ui/core'

import { Filters } from './Filters'
import { SelectedFilters } from './SelectedFilters'
import { HierarchicalMenu } from './HierarchicalMenu'
import { MobileFilter } from './MobileFilter'
import { SortBy } from './SortBy'

const Aside = () => (
  <MobileFilter>
    <Stack direction="column">
      <SortBy />
      <SelectedFilters />
      <HierarchicalMenu />
      <Filters />
    </Stack>
  </MobileFilter>
)

export default Aside
