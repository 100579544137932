import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { ContentsThumb } from 'shared/components/ContentsThumb'
import { ShowMore } from 'shared/components/ShowMore'

import WishlistProviderMethods from 'site/Wishlist/provider'
import { Widget } from 'site/Modular/components/Widget'

import * as S from './styled'

const ContentList = ({ fullList = false, ...props }) => {
  const [contents, setContents] = useState([])

  const getLastContents = () => {
    const contents = JSON.parse(props.contents)

    setContents(contents)
  }

  useEffect(getLastContents, [props.contents])

  const renderContent = () => {
    const isFullList = fullList === 'true'

    const contentComponents = contents.map(item => (
      <ContentsThumb item={item} key={item.id} />
    ))

    if (isFullList) {
      return <S.Wrapper>{contentComponents}</S.Wrapper>
    }

    return (
      <ShowMore
        visibleRows={1}
        scrollToParent
        CustomWrapper={({ children }) => (
          <S.ContentWrapper children={children} />
        )}
        CustomButtonWrapper={({ children }) => (
          <S.ButtonWrapper children={children} />
        )}
        layout="column"
      >
        {contentComponents}
      </ShowMore>
    )
  }

  return (
    <Widget title={props.title}>
      <WishlistProviderMethods items={contents}>
        {renderContent()}
      </WishlistProviderMethods>
    </Widget>
  )
}

ContentList.propTypes = {
  title: PropTypes.string
}

export default ContentList
