import { Text } from '@leroy-merlin-br/backyard-react'

import { AlertMessage } from 'shared/components/AlertMessage'

import * as S from './styled'

const ContentNotification = ({
  itemName,
  isQuantityNotEqual,
  errors
}) => {
  if (isQuantityNotEqual && !errors.service_unavailable_for_the_region) {
    return (
      <S.NotificationWrapper>
        <AlertMessage type="warning">
          Atenção! Você está selecionando uma{' '}
          <Text as="strong" size="kilo" noMargin isBold>
            quantidade menor de serviços em relação aos produtos
          </Text>.
          Caso você queira{' '}
          <Text as="strong" size="kilo" noMargin isBold>
            instalar a quantidade total de itens{' '}
          </Text>
          em sua obra ou projeto,{' '}
          <Text as="strong" size="kilo" noMargin isBold>
            a quantidade selecionada de itens e serviços deverá ser igual
          </Text>.
        </AlertMessage>
      </S.NotificationWrapper>
    )
  }

  if (errors.service_unavailable_for_the_region) {
    return (
      <S.NotificationWrapper>
        <AlertMessage type="critical">
          <Text as="strong" size="kilo" noMargin isBold>
            Atenção!{' '}
          </Text>
          O{' '}
          <Text as="strong" size="kilo" noMargin isBold>
            {itemName} não está disponível{' '}
          </Text>
          para a{' '}
          <Text as="strong" size="kilo" noMargin isBold>
            nova região selecionada
          </Text>.
          Estamos trabalhando para ampliar nossa área de abrangência.{' '}
          <Text as="strong" size="kilo" noMargin isBold>
            Exclua o serviço do seu carrinho ou altere a região{' '}
          </Text>
          para seguir com a compra.
        </AlertMessage>
      </S.NotificationWrapper>
    )
  }

  return null
}

export default ContentNotification
