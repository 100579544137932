import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { styleHelpers } from '@leroy-merlin-br/backyard-react'

const titleStyles = ({ theme }) => css`
  label: title;

  ${styleHelpers.headingTera(theme)};
  color: ${theme.colors.n600};

`

export const Title = styled('div')(titleStyles)
