import { useContext, useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import qs from 'qs'
import { flattenDeep, debounce } from 'lodash'
import { Provider } from 'react-redux'
import { toast } from '@leroy-merlin-br/backyard-react'

import * as cartRepository from 'scripts/api/cart'
import emitter from 'scripts/utils/emitter'
import { errorInMiniCartContext } from 'scripts/react-components/checkout/services/data-layer'

import { GardenDrawer } from 'shared/components/GardenDrawer'
import { useUserResources } from 'shared/hooks'

import { useCartDrawerContext } from 'site/Header/contexts/CartDrawerContext'

import { configureStore } from 'checkout/redux/store'
import * as services from 'checkout/services/cart'

import * as S from './styled'
import { Contents } from './components/Contents'

const store = configureStore()

const CART_DEFAULT_VALUE = {
  shippings: [],
  uniqueProductsCount: 0,
  total: 0.0,
  subTotal: 0.0,
  discountTotal: 0.0,
  cheapestPayMethodTotal: 0.0
}

const CartDrawer = () => {
  const { userResources = {} } = useUserResources()

  const { isCartDrawerOpen, toggleCartDrawer } = useCartDrawerContext()

  const [currentCart, setCart] = useState(CART_DEFAULT_VALUE)
  const [availableServices, setAvailableServices] = useState([])
  const [isLoadDataCart, setIsLoadDataCart] = useState(false)

  const { region } = userResources

  const serviceRequest = useMemo(
    () =>
      debounce(async params => {
        const { data } = await services.getMiniCartServices(params)

        setAvailableServices(data.data)
      }, 600),
    []
  )

  useEffect(() => {
    const ids = flattenDeep(
      currentCart.shippings.map(shipping =>
        shipping.items.map(item => ({
          id: item.productId,
          skuId: item.skuId
        }))
      )
    )

    const params = qs.stringify(
      {
        regionId: region,
        products: ids
      },
      {
        addQueryPrefix: true,
        arrayFormat: 'indices',
        encode: false,
        skipNulls: true
      }
    )

    if (ids.length > 0) {
      serviceRequest(params)
    }
  }, [currentCart, region, serviceRequest])

  useEffect(() => {
    if (isCartDrawerOpen && !isLoadDataCart) {
      getCart()
    }
  }, [isCartDrawerOpen, isLoadDataCart])

  useEffect(() => {
    if (!isCartDrawerOpen) {
      setIsLoadDataCart(false)
    }
  }, [isCartDrawerOpen])

  useEffect(() => {
    emitter.on('cart:products-replace', cart => {
      if (cart) {
        setCart(cart)
      }
    })

    emitter.on('cart:products-added', cart => {
      toggleCartDrawer()
      setCart(cart)
      setIsLoadDataCart(true)
    })

    emitter.on('cart:services-added', () => {
      toggleCartDrawer()
    })
  }, [toggleCartDrawer])

  const getCart = async () => {
    try {
      setIsLoadDataCart(false)

      const response = await cartRepository.getCart()

      const {
        uniqueProductsCount,
        total,
        subTotal,
        discountTotal,
        cheapestPayMethodTotal,
        shippings,
        services: serviceList,
        paymentMethods,
        paymentMethodsSummary,
        servicesSubTotal,
        totalInstallments,
        totalDiscountsByType,
        firstCheapestPaymentMethod
      } = response.data

      setIsLoadDataCart(true)

      setCart({
        uniqueProductsCount,
        total,
        subTotal,
        discountTotal,
        cheapestPayMethodTotal,
        shippings,
        services: serviceList,
        totalInstallments,
        paymentMethods,
        paymentMethodsSummary,
        servicesSubTotal,
        totalDiscountsByType,
        firstCheapestPaymentMethod
      })
    } catch (err) {
      errorInMiniCartContext(
        'carregou_minicart',
        'Erro ao carregar os dados do carrinho'
      )

      toast.critical('Ops!', {
        content:
          'Tivemos algum problema para recuperar o seu carrinho, tente novamente!',
        variant: 'solid'
      })
    }
  }

  return (
    <GardenDrawer
      isOpen={isLoadDataCart && isCartDrawerOpen}
      overlayClick={toggleCartDrawer}
      className="stock-info-drawer"
      dataCy="minicart-drawer"
    >
      <Provider store={store}>
        <S.Container>
          <Contents
            {...currentCart}
            resetCart={getCart}
            regionId={region}
            isCartDrawerOpen={isCartDrawerOpen}
            availableServices={availableServices}
          />
        </S.Container>
      </Provider>
    </GardenDrawer>
  )
}

CartDrawer.propTypes = {
  cart: PropTypes.shape({
    shippings: PropTypes.array,
    subTotal: PropTypes.number,
    discountTotal: PropTypes.number,
    cheapestPayMethodTotal: PropTypes.number,
    total: PropTypes.number,
    paymentMethods: PropTypes.string,
    paymentMethodSummary: PropTypes.array,
    servicesSubTotal: PropTypes.number,
    totalDiscountsByType: PropTypes.shape({
      stampedPrice: PropTypes.number,
      promo: PropTypes.number,
      employee: PropTypes.number,
      'payment-method': PropTypes.number,
      pix: PropTypes.number,
      bank_slip: PropTypes.number,
      credit_card: PropTypes.number
    }),
    firstCheapestPaymentMethod: PropTypes.string,
    totalInstallments: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number,
        totalValue: PropTypes.string,
        value: PropTypes.string
      })
    )
  })
}

export default CartDrawer
