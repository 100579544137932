import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { Theme } from 'scripts/react-components/themes/Theme'

const containerStyles = ({ theme }: Theme) => css`
  label: carousel-container;

  min-height: 405px;

  ${theme.media.mega} {
    min-height: 550px;
  }
`

export const Container = styled('div')(containerStyles)
