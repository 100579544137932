import $ from 'jquery'

import BaseComponent from 'scripts/components/base-component'

class UserDropdown extends BaseComponent {
  static componentName = 'user-dropdown'

  static emitterEvents = {
    'user-resources:done': 'onUserResourcesDone'
  }

  init () {
    this.$link = this.$element.find('[data-dropdown="link"]')
    this.$name = this.$element.find('[data-dropdown="name"]')
  }

  onUserResourcesDone (data) {
    if (data.email) {
      this.$element.addClass('logged')
      this.$name.text(data.name)

      this.$link
        .attr('href', '')
        .attr('title', '')

      this.$link.on('click', this.preventDefault)
    } else {
      this.$link.on('click', this.onLinkClick.bind(this))
    }
  }

  onLinkClick (event) {
    event.preventDefault()
    this.redirectLogin()
  }

  redirectLogin () {
    const currentUrl = encodeURIComponent(window.location.href)
    window.location = `/login?redirect=${currentUrl}`
  }

  preventDefault (e) {
    e.preventDefault()
  }
}

export default ($el) => new UserDropdown($el).init()

export { UserDropdown as Component }
