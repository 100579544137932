import { tv } from 'tailwind-variants'

const styles = tv({
  base: `
    w-4
    -my-1
    flex
    justify-center
  `
})

export default styles
