import { PaymentDataType } from './type'
import { BankSlip } from './BankSlip'
import { CreditCard } from './CreditCard'
import { Pix } from './Pix'

const PaymentData = ({ paymentMethod }: PaymentDataType) => {
  switch (paymentMethod.type) {
    case 'bank_slip':
      if (paymentMethod.number) return <BankSlip paymentMethod={paymentMethod} />
      break
    case 'credit_card':
    case 'token':
      return <CreditCard paymentMethod={paymentMethod} />
    case 'pix':
      if (paymentMethod.qr_code) return <Pix paymentMethod={paymentMethod} />
      break
  }
  return null
}

export default PaymentData
