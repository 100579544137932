import styled from '@emotion/styled'
import { css } from '@emotion/react'

const wrapperStyles = () => css`
  label: wrapper;

  display: flex;
`

const deliveryTagStyles = ({ theme }) => css`
  label: delivery-tag;

  display: flex;
  align-items: center;
  width: max-content;
    margin-inline: ${theme.spacings.byte};
    border-radius: ${theme.borderRadius.tera};
    strong {
      margin-left: ${theme.spacings.kilo};
      color: ${theme.colors.white}
    }
    svg {
      fill: ${theme.colors.white};
      margin-right: ${theme.spacings.kilo};
      margin-left: ${theme.spacings.byte};
    }
`

const wrapperTodayStyles = ({ theme, deliveryDay }) =>
  deliveryDay === 'today' &&
  css`
    label: wrapper-today;
    color: ${theme.colors.v400};
  `
const wrapperTomorrowStyles = ({ theme, deliveryDay }) =>
  deliveryDay === 'tomorrow' &&
  css`
    label: wrapper-tomorow;
    color: ${theme.colors.v700};
    `
const deliveryTagTodayStyles = ({ theme, deliveryDay }) =>
  deliveryDay === 'today' &&
  css`
      label: delivery-tag-today;
      background-color: ${theme.colors.v400};
    `
const deliveryTagTomorrowStyles = ({ theme, deliveryDay }) =>
  deliveryDay === 'tomorrow' &&
  css`
      label: delivery-tag-tomorow;
      background-color: ${theme.colors.v700};
      `

export const Wrapper = styled('div')(
  wrapperStyles,
  wrapperTodayStyles,
  wrapperTomorrowStyles
)
export const DeliveryTag = styled('div')(
  deliveryTagStyles,
  deliveryTagTodayStyles,
  deliveryTagTomorrowStyles
)
