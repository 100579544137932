import { PropsWithChildren } from 'react'

type RootProps = {}

export const Root = ({ children }: PropsWithChildren<RootProps>) => {
  return (
    <>
      <div className="pb-40" />
      <div className="fixed w-full bottom-0">
        <div className="flex flex-col mt-3 shadow-[0_-15px_30px_0px_rgba(0,0,0,0.10)] bg-white rounded-t-2xl">
          {children}
        </div>
      </div>
    </>
  )
}
