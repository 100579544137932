const VisaIcon = () => (
  <svg
    width="30"
    height="21"
    viewBox="0 0 30 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="30" height="21" rx="3.5" fill="white" />
    <path
      d="M16.355 7.80351C16.8306 7.48315 17.4463 7.29308 18.1387 7.2557C18.5271 7.23434 18.9785 7.27172 19.3753 7.35821C19.57 7.40093 20.0183 7.53654 20.0414 7.56004C20.0446 7.56217 19.9836 7.86224 19.9067 8.22424L19.87 8.39855C19.7505 8.96665 19.7372 9.02972 19.7268 9.02925C19.7255 9.02919 19.7242 9.02814 19.7228 9.02696C19.7215 9.02588 19.7201 9.02469 19.7184 9.02405C19.7089 9.02085 19.6373 8.99202 19.5594 8.95998C19.3132 8.85854 19.0459 8.78486 18.747 8.73894C18.6197 8.71865 18.5513 8.71438 18.3071 8.71438C17.9935 8.71331 17.9125 8.72292 17.7346 8.77845C17.3358 8.90552 17.1369 9.21413 17.2726 9.49711C17.3147 9.58467 17.4389 9.70961 17.5799 9.80572C17.7157 9.89969 17.7936 9.94347 18.1756 10.1496C18.8386 10.5073 19.1522 10.7422 19.3932 11.0626C19.5984 11.337 19.7015 11.6349 19.7141 11.998C19.7552 13.1662 18.9838 14.0547 17.6694 14.3505C17.3284 14.4274 17.0422 14.4573 16.6444 14.4573C16.1845 14.4573 15.773 14.4113 15.3184 14.3088C15.0163 14.2405 14.6554 14.1155 14.6554 14.0803C14.6554 14.0525 14.9637 12.5907 14.9721 12.5811C14.9764 12.5757 15.0469 12.6024 15.13 12.6408C15.5731 12.8448 15.9035 12.9409 16.3139 12.9847C16.5276 13.0082 16.9001 13.0093 17.0411 12.9868C17.4368 12.9238 17.6989 12.7733 17.8072 12.5469C17.903 12.3461 17.8672 12.1283 17.7094 11.9532C17.5768 11.8069 17.401 11.6926 16.939 11.4523C16.6096 11.2815 16.4402 11.1822 16.2666 11.0594C15.8519 10.7668 15.5688 10.4112 15.452 10.0364C15.3615 9.74592 15.3605 9.39673 15.4489 9.04861C15.5446 8.67166 15.7183 8.3748 16.014 8.08328C16.1434 7.95514 16.2087 7.90175 16.355 7.80351Z"
      fill="#1434CB"
    />
    <path
      d="M4.00009 7.57393C3.99904 7.57179 4.00746 7.52588 4.01903 7.47142L4.04008 7.37317L5.61969 7.37531C5.75362 7.37563 5.87657 7.37589 5.98952 7.37612L5.99114 7.37613C7.12911 7.37849 7.24984 7.37874 7.35022 7.42406C7.36213 7.42943 7.37375 7.43544 7.38674 7.44216C7.39711 7.44753 7.40839 7.45336 7.42136 7.45967C7.62552 7.55791 7.77601 7.73411 7.85073 7.96049C7.86546 8.00641 8.03805 8.91301 8.25274 10.0695C8.459 11.1854 8.63054 12.1016 8.63475 12.1059C8.63791 12.1101 9.0557 11.0476 9.564 9.74593L10.4859 7.37851L11.4193 7.37531C12.1655 7.37317 12.3517 7.37531 12.3475 7.38599C12.3265 7.44365 9.48402 14.3238 9.47665 14.3387C9.46718 14.3558 9.4114 14.3569 8.53056 14.3569C7.64867 14.3569 7.595 14.3558 7.58974 14.3377C7.5879 14.3321 7.47573 13.8918 7.30365 13.2164L7.30293 13.2135C7.18064 12.7335 7.02819 12.1352 6.8636 11.4897C6.46686 9.9328 6.13325 8.63217 6.12168 8.59799C6.06485 8.43248 5.97645 8.31395 5.84595 8.22745C5.5576 8.03631 4.87777 7.7875 4.19899 7.62412C4.09059 7.59849 4.00114 7.575 4.00009 7.57393Z"
      fill="#1434CB"
    />
    <path
      d="M12.5126 10.2524L12.3823 10.8704C11.9824 12.7679 11.653 14.3281 11.6498 14.3377C11.6446 14.3558 11.6919 14.3569 12.5328 14.3569H13.422L13.4273 14.3323C13.4494 14.2437 14.8869 7.40521 14.8869 7.39133C14.8869 7.37424 14.8196 7.37317 14.004 7.37317H13.121L13.1147 7.39773C13.1119 7.41007 12.8513 8.64588 12.5126 10.2524Z"
      fill="#1434CB"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.7976 7.42016C22.8408 7.40521 22.9081 7.39026 22.946 7.38492C22.9849 7.37958 23.3659 7.37531 23.7921 7.37424C24.5225 7.37317 24.5677 7.37424 24.573 7.39133C24.5835 7.42763 26 14.3238 26 14.3409C26 14.3548 25.9137 14.3569 25.1823 14.3548L24.3646 14.3516L24.2615 13.855C24.2057 13.5816 24.1562 13.3478 24.1531 13.3339L24.1478 13.3104H21.8663L21.8557 13.3392C21.8527 13.3492 21.8225 13.4337 21.7787 13.556C21.7478 13.6425 21.7102 13.7478 21.6705 13.8593C21.5747 14.1284 21.4958 14.3515 21.4958 14.3537C21.4958 14.3548 21.0759 14.3569 20.5634 14.3569C19.712 14.3569 19.631 14.3558 19.6373 14.3398C19.6489 14.3088 22.2651 7.9669 22.2977 7.89108C22.3946 7.6647 22.5798 7.48957 22.7976 7.42016ZM23.7436 11.3373C23.7003 11.1252 23.6471 10.8652 23.5901 10.5853C23.4448 9.87834 23.3227 9.29209 23.3185 9.28248C23.3133 9.26967 23.1565 9.69681 22.8492 10.5586C22.5956 11.2708 22.3851 11.8592 22.3819 11.8656C22.3788 11.8763 22.5293 11.8795 23.1154 11.8795C23.5216 11.8795 23.8531 11.8774 23.8531 11.8752C23.8531 11.8733 23.8098 11.661 23.7436 11.3373Z"
      fill="#1434CB"
    />
  </svg>
)

export default VisaIcon
