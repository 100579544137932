import { Text, Hidden } from '@leroy-merlin-br/backyard-react'

import * as S from './styled'
import { ServiceVisualType } from './type'

const Products = ({
  children
}: React.PropsWithChildren<ServiceVisualType>) => (
  <S.Wrapper>
    <S.Header>
      <Text isBold noMargin>
        Itens do pedido
      </Text>
    </S.Header>
    {children}
  </S.Wrapper>
)

export default Products
