type ShippingParams = {
  items: itemsParams[]
}

type itemsParams = {
  shopName?: string
}

export const shouldShowInvoiceModal = (shipments: ShippingParams[]) => {
  const uniqueShopNamesSet = new Set<string>()

  shipments.map((shipment) => {
    shipment.items.map((item) => {
      if (item.shopName) {
        uniqueShopNamesSet.add(item.shopName ?? 'Leroy Merlin');
      }
    })
  })

  const uniqueShopNamesArray = Array.from(uniqueShopNamesSet)

  return uniqueShopNamesArray.length > 1

}
