import styled from '@emotion/styled'
import { css } from '@emotion/react'

const fromCartStyles = ({ theme, fromCart = false }) => {
  if (!fromCart) {
    return
  }

  return css`
    margin-right: ${theme.spacings.mega};
  `
}

const baseStyles = ({ theme }) => css`
  label: quantity;

  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  width: 130px;

  border: 1px solid ${theme.colors.n300};
  border-radius: ${theme.borderRadius.giga};
`

export const Wrapper = styled('div')(baseStyles, fromCartStyles)

const buttonStyles = ({ theme, disabled }) => css`
  label: quantity__button

  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
  height: 22px;
  width: 22px;

  border-radius: ${theme.borderRadius.circle};

  margin-left: ${theme.spacings.byte};
  margin-right: ${theme.spacings.byte};

  &:focus {
    outline: none;
  }

  i {
    cursor: pointer;

    color: ${disabled ? theme.colors.n300 : theme.colors.p500};
    font-size: ${theme.typography.headings.giga.fontSize};
  }

  i:hover {
    color: ${disabled ? theme.colors.n300 : theme.colors.p700};
  }
`

export const ActionButton = styled('button')(buttonStyles)
