'use client'
import { useState } from 'react'

import { Page, Footer } from 'frontendCheckout/ui/desktop'

import { Product } from './ui/Product'
import { Shipping } from './ui/Shipping'
import { SummaryDesktop } from './ui/SummaryDesktop'

export function CartDesktop () {
  const [value, setValue] = useState('123')

  return (
    <Page.root>
      <Page.grid>
        <Page.gridLeft>
          <Shipping.root>
            <Shipping.productsContainer>
              <Product.root>
                <Product.image img="https://cdn-staging.leroymerlin.com.br/products/papel_de_parede_tnt_geometrico_cinza_53cmx10m_91710591_0001_300x300.jpg" />
                <Product.content>
                  <Product.header>
                    <Product.headerContainer>
                      <Product.title>
                        <h3>
                          Furadeira de Impacto Bosch com Empunhadura, Limitador
                          de Profundidade e Chave de Mandril 800W 1/2" GSB 20-2
                          RE 127V{' '}
                        </h3>
                      </Product.title>
                      <Product.subtitle>
                        <h4>Vendido e entregue por Leroy Merlin</h4>
                      </Product.subtitle>
                    </Product.headerContainer>
                    <Product.trashCan onDelete={() => {}} />
                  </Product.header>

                  <Product.inline>
                    <Product.body>
                      <Product.priceFrom
                        quantity="100.8"
                        un="m²"
                        priceFrom="R$ 100.80"
                      />
                      <Product.priceTo
                        quantity="100.8"
                        un="m²"
                        priceTo="R$ 90.05"
                      />
                      <Product.discount
                        discount="-10%"
                        discountMethod="à vista no boleto"
                      />
                    </Product.body>

                    <Product.footer>
                      <Product.counter
                        onChangeValue={event => {
                          setValue(event.target.value)
                        }}
                        onMinus={() => {}}
                        onPlus={() => {}}
                        value={value}
                      />
                      <Product.footerRight>
                        <Product.delivery
                          onReceive={() => {}}
                          isDelivery={true}
                        />
                        <Product.pickupInStore
                          onPickUpInStore={() => {}}
                          isPickupInStore={false}
                        />
                      </Product.footerRight>
                    </Product.footer>
                  </Product.inline>
                </Product.content>
              </Product.root>
            </Shipping.productsContainer>
          </Shipping.root>
        </Page.gridLeft>

        <Page.gridRight>
          <SummaryDesktop couponsAvailable={9} />
        </Page.gridRight>
      </Page.grid>

      <Footer />
    </Page.root>
  )
}
