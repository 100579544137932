import { useEffect, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { AnimatePresence, motion } from 'framer-motion'
import { datadogRum } from '@datadog/browser-rum'
import Cookies from 'js-cookie'
import { getProductsReviewsRating } from 'hpdp/services/reviews'

import { onProductClick } from 'scripts/utils/data-layer'

import { NewProductThumb } from 'shared/components/NewProductThumb'

import WishlistProviderMethods from 'site/Wishlist/provider'

const Products = ({ items, removeItem }) => {
  const [products, setProducts] = useState(items)
  const [show, setShow] = useState(Array.from(products, () => true))
  const [ratingResponse, setRatingResponse] = useState([])
  const [isLoadingReview, setIsLoadingReview] = useState(false)

  const shouldShowNewReviewsRating =
    window.env.feature.isNewReviewsSectionEnabled &&
    Cookies.get('new_reviews_ab_testing') === 'true'

  useEffect(() => {
    if (!shouldShowNewReviewsRating) {
      return
    }
    const fetchData = async () => {
      const ids = products?.map(item => {
        return item.id
      })

      try {
        setIsLoadingReview(true)

        const response = await getProductsReviewsRating(ids)
        setRatingResponse(response)
      } catch (e) {
        datadogRum.addError(e)
      } finally {
        setIsLoadingReview(false)
      }
    }

    if (products.length >= 1) {
      fetchData()
    }
  }, [products, shouldShowNewReviewsRating])

  const variants = useMemo(() => {
    return {
      initial: {
        opacity: 0
      },
      enter: {
        opacity: 1,
        transition: {
          duration: 0.3
        }
      },
      exit: {
        opacity: 0,
        transition: {
          duration: 0.3
        }
      }
    }
  }, [])
  const updateItemsToShow = itemIndex => {
    const showUpdated = [...show]
    showUpdated[itemIndex] = false
    setShow(showUpdated)
  }

  const handleRemove = async (e, item, index) => {
    if (e.target.dataset.name === 'heart-icon') {
      updateItemsToShow(index)
      removeItem(item)
    }
  }

  const renderProducts = () => {
    const productItem = (item, index) => (
      <li
        key={`product-thumb-${item.id}`}
        onClick={e => handleRemove(e, item, index)}
      >
        <NewProductThumb
          index={index}
          partnerName="elastic search"
          onClick={() => onProductClick(item, { position: index })}
          product={item}
          trackingAttributes={{
            category: 'wishlist',
            label: `adicionar_wishlist_${item.id}`,
            action: 'clicou'
          }}
          reviewRating={ratingResponse.find(element => {
            return element.productLm === item.id
          })}
          isLoadingReview={isLoadingReview}
        />
      </li>
    )

    return (
      <AnimatePresence>
        {products.map((item, index) => (
          <motion.div
            className={'wishlist-thumbs-list-item entered'}
            variants={variants}
            initial="initial"
            animate="enter"
            exit="exit"
            key={`tst-${item.id}`}
          >
            {productItem(item, index)}
          </motion.div>
        ))}
      </AnimatePresence>
    )
  }

  const renderNoMatch = () => (
    <li className="wishlist-thumbs-list-not-found">
      <p className="message-main">
        Você ainda não possui nenhum produto nesta lista.
      </p>
    </li>
  )

  useEffect(() => {
    const updateItemsArray = () => {
      const notHasProducts = show.every(item => item === false)
      if (notHasProducts) {
        const productsUpdated = []
        setProducts(productsUpdated)
      }
    }

    updateItemsArray()
  }, [show])

  return (
    <WishlistProviderMethods items={products}>
      <ul className="wishlist-thumbs-list">
        {products.length > 0 ? renderProducts() : renderNoMatch()}
      </ul>
    </WishlistProviderMethods>
  )
}

Products.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  removeItem: PropTypes.func.isRequired
}

export default Products
