import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { StyleProps } from '../../types'

const wrapperStyles = ({ theme }: StyleProps) => css`
  label: wrapper;

  display: grid;
  place-items: center;

  width: 100%;
  min-height: 450px;

  background: ${theme.colors.n100};
  border-radius: ${theme.borderRadius.tera};
`

export const Wrapper = styled('div')(wrapperStyles)
