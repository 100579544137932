import { useContext } from 'react'
import classnames from 'classnames'
import { ChevronLeft } from '@leroy-merlin-br/backyard-icons'

import { HeaderContext } from 'site/Header/contexts/HeaderContext'
import { DropdownContext } from 'site/Header/contexts/DropdownContext'

import Children from '../../common/children'

const SubLevels = () => {
  const headerContext = useContext(HeaderContext)
  const dropdownContext = useContext(DropdownContext)

  const {
    activatedParent,
    dropdownElements,
    toggleChildren,
    isExpanded,
    isLoading
  } = headerContext

  const { setItem, ActivatedItem } = dropdownContext

  const trigger = () => {
    ActivatedItem === null ? toggleChildren() : setItem()
  }

  const categoriesClasses = classnames('menu-list-mobile-categories', {
    'is-opened': isExpanded
  })

  const isNotDepartament = activatedParent !== 'Departamentos'

  return (
    <div className={categoriesClasses}>
      <span
        className="menu-list-mobile-categories-title menu-element-icon"
        onClick={trigger}
      >
        <ChevronLeft width={25} height={25} />
        <span>{activatedParent}</span>
      </span>
      <ul className={`menu-list ${isNotDepartament && 'menu-full'}`}>
        {dropdownElements &&
          !isLoading &&
          dropdownElements.map(
            item =>
              item.name === activatedParent && (
                <Children
                  element={item}
                  key={`${item.id}-${item.name}`}
                  rootElement={activatedParent}
                />
              )
          )}
      </ul>
    </div>
  )
}

export default SubLevels
