import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'

import { Supplier } from '../types/api/supplier'
import { SupplierStatus, supplierStatusMap } from '../types/supplier-status'
import { CashbackPageType } from '../types/cashback-page-type'
import { ArchitectRegistration } from '../components/ArchitectRegistration'
import { CashbackRedeem } from '../components/CashbackRedeem'
import CashbackError from '../components/CashbackError/CashbackError'
import { createSupplierService } from '../services/supplier.service'
import { GetSupplierError } from '../types/get-supplier-error'

export interface ICashbackProvider {
  supplier: Supplier | undefined
  setSupplierStatus: Dispatch<SetStateAction<SupplierStatus>>
  supplierStatus: SupplierStatus
  setSupplier: Dispatch<SetStateAction<Supplier | undefined>>
  pageToRender: JSX.Element
  loading: boolean
}

interface CashbackProviderProps {
  children: JSX.Element | JSX.Element[]
}

const pages: { [key in CashbackPageType]: () => JSX.Element } = {
  FORM: () => <ArchitectRegistration />,
  REDEEM: () => <CashbackRedeem />,
  ERROR: () => <CashbackError />
}

const CashbackContext = createContext<ICashbackProvider | undefined>(undefined)

const CashbackProvider = ({ children }: CashbackProviderProps) => {
  const supplierService = createSupplierService()

  const [supplier, setSupplier] = useState<Supplier | undefined>(undefined)
  const [supplierStatus, setSupplierStatus] =
    useState<SupplierStatus>('UNKNOWN')

  const [page, setPage] = useState<CashbackPageType>()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    fetchSupplier()
  }, [])

  useEffect(() => {
    if (supplier?.company) {
      const status =
        supplierStatusMap.get(Number(supplier.status.code)) ?? 'UNKNOWN'

      setSupplierStatus(status)

      if (status !== 'DOCUMENT_APPROVED' || !supplier.skipStatusStep) {
        setPage('FORM')
      } else if (status === 'DOCUMENT_APPROVED') {
        setPage('REDEEM')
      }
    }
  }, [supplier])

  const fetchSupplier = async () => {
    try {
      setLoading(true)
      const response = await supplierService.getSupplier()

      if ((response as Supplier).company) {
        setSupplier(response as Supplier)
      } else {
        const data = response as GetSupplierError

        if (data.statusCode !== 404) {
          throw new Error(JSON.stringify(data, null, 2))
        }

        setSupplierStatus('PENDING_REGISTRATION')
        setPage('FORM')
      }
    } catch (error) {
      setPage('ERROR')
      throw error
    } finally {
      setLoading(false)
    }
  }

  const pageToRender = page ? pages[page]() : <></>

  const returnValue = useMemo(
    () => ({
      supplier,
      setSupplier,
      supplierStatus,
      setSupplierStatus,
      pageToRender,
      loading
    }),
    [supplier, supplierStatus, pageToRender, loading]
  )

  return (
    <CashbackContext.Provider value={returnValue}>
      {children}
    </CashbackContext.Provider>
  )
}

export default CashbackProvider

export const useCashbackContext = () => useContext(CashbackContext)!
