import { memo } from 'react'
import { Text } from '@leroy-merlin-br/backyard-react'
import { Popover } from 'user/components'
import * as S from 'user/signup/pages/SignUp/styled'

const NewsInfo = () => {
  return (
    <Text size="kilo" noMargin>
      <Popover
        interactive
        list={{
          title: 'Utilizaremos os canais de comunicação (E-mail, SMS e Whatsapp) para enviar:',
          items: [
            'Status do seu pedido',
            'Notificações sobre a sua conta',
            'Comunicações institucionais',
            'Dicas, ofertas e promoções personalizadas com base nas suas informações pessoais e perfil de compra'
          ]
        }}
        info={
          <>
            Você poderá desativar, a qualquer momento, o recebimento destas comunicações no seu perfil de usuário ou na página
            {' '}<S.Link href="/fale-conosco" target="_blank">Fale conosco</S.Link>.
          </>
        }
        placement="top"
      >
        Entenda mais sobre o uso de seus dados
      </Popover>
    </Text>
  )
}

export default memo(NewsInfo)
