import { MustUpdateNewVersion, AlertUpdateNewVersion } from './Actions'
import { checkOldAppModaProps } from './CheckOldAppModalBehaviour'
type checkOldAppModaComponentProps = {
  versionData: checkOldAppModaProps
  setTimeToAlertAgain: () => void
}

export const CheckOldAppModalComponent = ({
  versionData,
  setTimeToAlertAgain
}: checkOldAppModaComponentProps) => {
  return versionData.mustUpdateApp ? (
    <MustUpdateNewVersion />
  ) : (
    <AlertUpdateNewVersion setTimeToAlertAgain={setTimeToAlertAgain} />
  )
}
