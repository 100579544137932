import { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import {
  Heading,
  Button,
  Dialog,
  Stack,
  Select,
  Radio,
  Text,
  Icon
} from '@leroy-merlin-br/backyard-react'
import { Checkmark } from '@backyard-ui/icons'
import { useDispatch } from 'react-redux'

import { Actions as LoyaltyProfileActions } from 'lmcv/redux/modules/loyaltyProfile'

import * as S from './styled'
import PREFERENCES_DEFAULT from './constants'

const Form = ({ onClose, preferencesData }) => {
  const [interestsState, setStateInterests] = useState([])
  const [hasChildIndicatorState, setStateChildIndicator] = useState(false)
  const [DIYRelationTypeState, setStateDIYRelationType] = useState()
  const [homeDecorState, setStateHomeDecor] = useState()

  const dispatch = useDispatch()

  const onSubmitHandler = ev => {
    ev.preventDefault()

    const payload = {
      interests: {
        DIYRelationType: DIYRelationTypeState,
        homeDecorKnowledgeLevel: homeDecorState,
        hasChildIndicator: hasChildIndicatorState,
        interestAreaName: interestsState
      }
    }

    dispatch(LoyaltyProfileActions.setProfilePreferences(payload))
    onClose()
  }

  const checkPreviousPreferencesData = useCallback(() => {
    setStateChildIndicator(preferencesData.hasChildIndicator)

    if (preferencesData.DIYRelationType) {
      setStateDIYRelationType(preferencesData.DIYRelationType)
    }

    if (preferencesData.homeDecorKnowledgeLevel) {
      setStateHomeDecor(preferencesData.homeDecorKnowledgeLevel)
    }

    if (Array.isArray(preferencesData.interestAreaName)) {
      if (preferencesData.interestAreaName.length) {
        setStateInterests(preferencesData.interestAreaName)
      }
    }
  }, [
    preferencesData.hasChildIndicator,
    preferencesData.DIYRelationType,
    preferencesData.homeDecorKnowledgeLevel,
    preferencesData.interestAreaName
  ])

  const onChangeDIY = ({ target }) => setStateDIYRelationType(target.value)
  const onChangeHomeDecor = ({ target }) => setStateHomeDecor(target.value)
  const onChangeChildIndicator = value => setStateChildIndicator(value)
  const onChangeInterest = ({ target }) => {
    const value = target.value

    if (interestsState.includes(value)) {
      setStateInterests(interestsState.filter(item => item !== value))
      return
    }

    setStateInterests([...interestsState, value])
  }

  const onCloseWhitoutSaving = () => {
    checkPreviousPreferencesData()
    onClose()
  }

  const selectData = data => {
    return data.map(item => ({
      label: item.label,
      value: item.value
    }))
  }

  useEffect(() => {
    checkPreviousPreferencesData()
  }, [checkPreviousPreferencesData])

  return (
    <Dialog onClose={onCloseWhitoutSaving} title="Preferências">
      <S.Form onSubmit={onSubmitHandler}>
        <Stack>
          <Heading size="kilo" noMargin>
            Qual a sua relação com bricolagem?
          </Heading>
          <Text size="kilo" color="n400" noMargin>
            bricolagem: execução de pequenos trabalhos domésticos, sem
            necessidade de um profissional; "faça você mesmo".
          </Text>
          <Select
            name="DIYRelationType"
            onChange={onChangeDIY}
            value={DIYRelationTypeState}
            placeholder="Selecionar opção..."
            options={selectData(PREFERENCES_DEFAULT.DIYRelationType)}
          ></Select>

          <Heading size="kilo" noMargin>
            Você tem interesse em decoração?
          </Heading>
          <Select
            name="homeDecorKnowledgeLevel"
            onChange={onChangeHomeDecor}
            value={homeDecorState}
            placeholder="Selecionar opção..."
            options={selectData(PREFERENCES_DEFAULT.homeDecorKnowledgeLevel)}
          ></Select>

          <Heading size="kilo" noMargin>
            Possui filhos?
          </Heading>
          <S.RadioContainer>
            <Radio
              name="hasChildIndicator"
              id="rdOne"
              value={true}
              checked={hasChildIndicatorState}
              onChange={onChangeChildIndicator.bind(null, true)}
            >
              Sim
            </Radio>
            <Radio
              name="hasChildIndicator"
              id="rdTwo"
              value={false}
              checked={!hasChildIndicatorState}
              onChange={onChangeChildIndicator.bind(null, false)}
            >
              Não
            </Radio>
          </S.RadioContainer>

          <Heading size="kilo" noMargin>
            Selecione suas áreas de interesse:
          </Heading>

          <S.CheckBoxList>
            {PREFERENCES_DEFAULT.interestAreaName.map((item, index) => (
              <S.CheckBoxItem key={index} title={item.label}>
                <S.CheckBoxWrapper>
                  <S.CheckBox
                    type="checkbox"
                    value={item.value}
                    checked={interestsState.includes(item.value)}
                    onChange={onChangeInterest}
                  />

                  <S.CheckBoxSuccessIcon>
                    <Icon as={Checkmark} size="kilo" />
                  </S.CheckBoxSuccessIcon>

                  <S.CheckBoxContent>
                    {item.icon && (
                      <S.CheckBoxIcon>
                        <Icon as={item.icon} size="giga" />
                      </S.CheckBoxIcon>
                    )}

                    {item.label}
                  </S.CheckBoxContent>
                </S.CheckBoxWrapper>
              </S.CheckBoxItem>
            ))}
          </S.CheckBoxList>
        </Stack>

        <S.ButtonSubmit>
          <Button>Salvar alterações</Button>
        </S.ButtonSubmit>
      </S.Form>
    </Dialog>
  )
}

Form.propTypes = {
  isVisible: PropTypes.bool,
  onClose: PropTypes.func
}

Form.defaultProps = {
  isVisible: false
}

export default Form
