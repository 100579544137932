import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { Theme } from 'scripts/react-components/themes/Theme'

interface CashbackWrapperProps {
  disabled?: boolean;
}

export const Wrapper = styled('div')(
  ({ theme }: Theme) => css`
    background-color: ${theme.colors.white};
    border-radius: ${theme.borderRadius.giga};
    padding: ${theme.spacings.peta};
  `
)

export const Title = styled('div')(
  ({ theme }: Theme) => css`
    margin-bottom: ${theme.spacings.kilo};
    display: flex;
    justify-content: space-between;
  `
)

export const Subtitle = styled('h2')(
  ({ theme }: Theme) => css`
    color: ${theme.colors.n500};
    font-size: ${theme.typography.headings.kilo.fontSize};
  `
)

export const Content = styled('div')(
  ({ theme, disabled }: Theme & CashbackWrapperProps) => css`
    background-color: ${disabled ? theme.colors.n100 : theme.colors.white};
    margin: ${theme.spacings.tera} 0;
    color: ${theme.colors.n500};
    border: 1px solid ${theme.colors.n100};
    border-radius: ${theme.borderRadius.giga};
    padding: ${theme.spacings.kilo} ${theme.spacings.tera};
  `
)

export const ContentLine = styled('div')(
  ({ theme }: Theme) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${theme.spacings.byte} 0;
    flex-direction: column;

    button {
      &:disabled {
        background: ${theme.colors.n200};;
      }
    }
    ${theme.media.mega} {
      flex-direction: row;
      padding: 0 0;
    }
  `
)

export const FlexResponsive = styled('div')(
  ({ theme }: Theme) => css`
    display: flex;
    align-items: center;
    padding: ${theme.spacings.bit};
    flex-direction: column;
    ${theme.media.mega} {
      flex-direction: row;
      padding: ${theme.spacings.giga};
    }
  `
)

export const ResponsiveAlignment = styled('div')(
  ({ theme }: Theme) => css`
    display: flex;
    align-items: center;
    padding: ${theme.spacings.giga};
    flex-direction: column;
    ${theme.media.mega} {
      align-items: flex-start;
    }
  `
)

export const Circle = styled('div')(
  ({ theme }: Theme) => css`
    position: relative;
    border: 1px solid ${theme.colors.n200};
    border-radius: ${theme.borderRadius.circle};
    padding: ${theme.spacings.mega};
    padding: ${theme.spacings.mega} ${theme.spacings.mega} ${theme.spacings.kilo} ${theme.spacings.mega};
  `
)

export const CircleIcon = styled('div')(
  ({ theme, disabled }: Theme & CashbackWrapperProps) => css`
    position: absolute;
    background-color: ${disabled ? theme.colors.n100 : theme.colors.white};
    bottom: 10px;
    left: -10px;
    transform: translateY(50%);
    z-index: 10;
    padding: 0 6px;
    border-radius: ${theme.borderRadius.circle};
    box-shadow: 0 0 0 1px ${theme.colors.shadow},
            0 2px 2px 0 ${theme.colors.shadow},
            0 4px 4px 0 ${theme.colors.shadow};

  `
)
