type PriceFromProps = {
  quantity: string
  un: string
  priceFrom: string
}

export const PriceFrom = ({ quantity, un, priceFrom }: PriceFromProps) => {
  return (
    <div className="text-sm text-gray-200 stroke-1 line-through">
      {quantity} {un} {priceFrom}
    </div>
  )
}
