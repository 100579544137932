import { format as formatCPF } from '@fnando/cpf'
import { FC, useCallback, useEffect, useState } from 'react'

import { formatDate } from 'utils/date'

import { Layout } from '../../../components/Layout'
import { PersonalDataCardDesktop } from '../../../components/PersonalDataCardDesktop'

const maskDocumentId = (cpf: string) => {
  const maskedCPF = `${cpf.substring(0, 3)}.***.***-${cpf.substring(9, 11)}`

  return maskedCPF
}

function isRawDate (date: string) {
  if (!date) {
    return
  }

  const regEx = /^\d{4}-\d{2}-\d{2}$/

  return date.match(regEx) != null
}

type PersonalData = {
  name: string
  fiscalId: string
  birthdate: string
}

export type PersonalDataDesktopProps = {
  data: PersonalData
  loading: boolean
}

const PersonalDataDesktop: FC<PersonalDataDesktopProps> = ({ data, loading }) => {
  const [editable, setEditable] = useState(true)

  const mountCardInfo = useCallback((data: PersonalData) => {
    return [
      {
        saveId: 'click-save-name-button',
        editId: 'click-edit-name-button',
        title: 'Nome',
        description: data?.name,
        edit: true,
        link: '/editar-nome',
        field: 'name'
      },
      {
        title: 'CPF',
        description: formatCPF(data?.fiscalId),
        text: 'Este dado não pode ser alterado',
        popover: false,
        showHide: true,
        maskFunction: maskDocumentId,
        field: 'documentId'
      },
      {
        saveId: 'click-save-birthdate-button',
        editId: 'click-edit-birthdate-button',
        tooltipId: 'click-birthdate-tooltip',
        title: 'Data de nascimento',
        description: isRawDate(data?.birthdate) ? formatDate(data?.birthdate, 'DD/MM/YYYY') : data?.birthdate,
        edit: true,
        link: '/editar-data-nascimento',
        popover: true,
        field: 'birthdate'
      }
    ]
  }, [])

  const [CARD_INFO, setCardInfo] = useState(mountCardInfo(data))

  useEffect(() => {
    if (data?.name) {
      setCardInfo(mountCardInfo(data))
    }
  }, [data, mountCardInfo])

  return (
    <Layout description="Dados Pessoais" backLink="/">
      {CARD_INFO.map(
        ({
          saveId,
          editId,
          tooltipId,
          title,
          description,
          text = '',
          edit =
          false,
          showHide = false,
          popover = false,
          field = '',
          maskFunction
        }) => (
          <PersonalDataCardDesktop
            saveId={saveId}
            editId={editId}
            tooltipId={tooltipId}
            key={`personal-data-card-${description}`}
            title={title}
            text={text}
            description={description}
            edit={edit}
            showHide={showHide}
            popover={popover}
            maskFunction={maskFunction}
            field={field}
            editable={editable}
            setEditable={setEditable}
            loading={loading}
          />
        )
      )}
    </Layout>
  )
}

export default PersonalDataDesktop
