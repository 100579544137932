import { useEffect, FunctionComponent, useState, useRef } from 'react'
import { datadogRum } from '@datadog/browser-rum'
import type {
  GetProductReviewsData,
  GetProductReviewsOrder
} from 'hpdp/services/reviews'
import { getProductReviews } from 'hpdp/services/reviews'

import { Item, Pagination } from './components'
import CommentsSkeleton from './CommentsSkeleton'
import { Props } from './types'

const Comments: FunctionComponent<Props> = ({ productId }) => {
  const paginationRef = useRef<HTMLDivElement>(null)

  const [order, setOrder] = useState<GetProductReviewsOrder>('usefulness')
  const [currentPage, setCurrentPage] = useState(1)
  const [isLoading, setIsLoading] = useState(true)
  const [reviewsResponse, setReviewsResponse] = useState<GetProductReviewsData>(
    {
      reviews: [],
      totalReviews: 0
    }
  )

  const { reviews, totalReviews: totalReviewCount } = reviewsResponse

  const scrollToPagination = () => paginationRef.current?.scrollIntoView(true)

  useEffect(() => {
    const doAsync = async () => {
      try {
        setIsLoading(true)
        const response = await getProductReviews(productId, currentPage, order)
        setReviewsResponse(response)
      } catch (e) {
        datadogRum.addError(e)
      } finally {
        setIsLoading(false)
      }
    }

    doAsync()
  }, [productId, currentPage, order])

  return (
    <Pagination
      ref={paginationRef}
      onNext={() => {
        scrollToPagination()
        setCurrentPage(currentPage + 1)
      }}
      onPrev={() => {
        scrollToPagination()
        setCurrentPage(currentPage - 1)
      }}
      onReorder={order => setOrder(order)}
      totalReviewsCount={totalReviewCount}
      currentPage={currentPage}
      pageSize={6}
      isLoading={isLoading}
      orderItems={[
        {
          name: 'Mais útil',
          value: 'usefulness'
        },
        {
          name: 'Mais relevantes',
          value: 'lowRating'
        },
        {
          name: 'Maior nota',
          value: 'highRating'
        },
        {
          name: 'Mais recente',
          value: 'lastSent'
        }
      ]}
    >
      {isLoading ? (
        <CommentsSkeleton />
      ) : (
        reviews.map((review, index) => <Item key={index} review={review} />)
      )}
    </Pagination>
  )
}

export default Comments
