import {
  toggleFilter,
  refinementListFilter,
  paginationFilter,
  ToggleFilterProps
} from './createUrlFilters'
import {
  PriceRangeProps,
  priceRangeFilter
} from './createUrlFilters/priceRangeFilter'
import { sortByFilter } from './createUrlFilters/sortByFilter'
import { numberFilters } from './numberFilters'
import { rebuildUrl } from './rebuildUrl'

interface LocationProps {
  href: string
  pathname: string
  origin: string
}
interface refinementListProps {
  [key: string]: Array<string>
}
interface RouteStateProps {
  [indexName: string]: {
    refinementList?: refinementListProps
    toggle?: ToggleFilterProps
    sortBy?: string
    page?: string
    range?: PriceRangeProps
  }
}

export const createUrlContext = (
  location: LocationProps,
  routeState: RouteStateProps,
  indexName: string,
  categoryFiltersLabel = {},
  region: string
) => {
  const { origin, pathname, href } = location
  const urlSplit = pathname.split('/')
  const urlCategoryOrigin = origin + '/' + urlSplit[1]

  const currentRouteState = routeState[indexName] || {}
  const { refinementList, page, toggle, sortBy, range } = currentRouteState

  if (refinementList || page || toggle || sortBy || range) {
    let urlToConcatenate = decodeURIComponent(removePaginationInUrl(href))

    if (refinementList) {
      urlToConcatenate = refinementListFilter(
        refinementList,
        numberFilters(urlToConcatenate),
        urlToConcatenate,
        categoryFiltersLabel,
        region
      )
    }

    if (toggle) {
      urlToConcatenate = toggleFilter(
        toggle,
        numberFilters(urlToConcatenate),
        urlToConcatenate,
        region
      )
    }

    if (sortBy) {
      urlToConcatenate = sortByFilter(
        sortBy,
        numberFilters(urlToConcatenate),
        urlToConcatenate
      )
    }

    if (page) {
      urlToConcatenate = paginationFilter(
        page,
        numberFilters(urlToConcatenate),
        urlToConcatenate
      )
    }

    if (range) {
      urlToConcatenate = priceRangeFilter(
        range,
        numberFilters(urlToConcatenate),
        urlToConcatenate
      )
    }

    urlToConcatenate = rebuildUrl(
      currentRouteState,
      urlToConcatenate,
      urlCategoryOrigin,
      region
    )

    return urlToConcatenate
  }

  return urlCategoryOrigin
}

const removePaginationInUrl = (href: string) => {
  const url = new URL(href)
  url.searchParams.delete('page')

  return url.toString()
}
