import styled from '@emotion/styled'
import { css } from '@emotion/react'

const titleStyles = ({ theme }) => css`
  label: title;

  color: #72bf44;
  margin-bottom: 10px;
  font-size: ${theme.typography.text.mega.fontSize};
  font-weight: 500;
`
export const Title = styled('h5')(titleStyles)

const infoStyles = ({ theme }) => css`
  label: info;

  line-height: ${theme.spacings.giga};
  color: #666;
  margin-top: ${theme.spacings.byte};
  margin-bottom: ${theme.spacings.byte};

  :first-of-type {
    margin: 0px;
  }
`
export const Info = styled('p')(infoStyles)

const actionWrapperStyles = ({ theme }) => css`
  label: action__wrapper;

  display: flex;
  flex-direction: row;
  gap: ${theme.spacings.mega};
`
export const ActionWrapper = styled('div')(actionWrapperStyles)

const actionStyles = ({ theme }) =>
  css`
    label: action;
    color: #72bf44;
    text-decoration: none;
    width: 100%;
    border-radius: ${theme.borderRadius.mega};
    display: inline-block;
    font-size: ${theme.typography.text.mega.fontSize};
    padding: 12px 13px;
    background-color: transparent;
    border: 1px solid #72bf44;
    transition: background-color 0.3s ease-in-out;
    text-align: center;

    :hover,
    :focus {
      background-color: #72bf44;
      color: ${theme.colors.white};
    }
  `

const actionDisabledStyles = ({ disabled }) =>
  disabled &&
  css`
    label: action--disabled;

    color: #e5e5e5;
    cursor: not-allowed;
    border: 1px solid #e5e5e5;
  `
export const Action = styled('a')(actionStyles, actionDisabledStyles)

const imageStyles = ({ theme }) => css`
  label: image;

  height: auto;
  max-width: 100%;
  width: 100%;
  margin-top: ${theme.spacings.tera};
`
export const Image = styled('img')(imageStyles)
