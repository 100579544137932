import styled from '@emotion/styled'
import { css } from '@emotion/react'

const majorCornerStyled = ({ theme }) => css`
  label: mosaic__major--corner;

  background-color: ${theme.colors.n200};
  height: 60%;
`

const majorMiddleStyles = ({ theme }) => css`
  label: mosaic__major--middle;

  background-color: ${theme.colors.n200};
  height: 65%;
`

const minorCornerStyles = ({ theme }) => css`
  label: mosaic__minor--corner;

  background-color: ${theme.colors.n200};
  height: 40%;
`

const minorMiddleStyles = ({ theme }) => css`
  label: mosaic__minor--middle;

  background-color: ${theme.colors.n200};
  height: 35%;
`

export const MajorCorner = styled('div')(majorCornerStyled)
export const MajorMiddle = styled('div')(majorMiddleStyles)
export const MinorCorner = styled('div')(minorCornerStyles)
export const MinorMiddle = styled('div')(minorMiddleStyles)
