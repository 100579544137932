import { useEffect, useState } from 'react'
/**
 * NOTE:
 * https://github.com/reach/reach-ui/blob/develop/packages/auto-id/src/index.tsx
 */
let handoffComplete = false
let id = 0
const genId = () => ++id

export function useId (idProp, prefix) {
  const initialId = idProp || (handoffComplete ? genId() : null)
  const [uid, setUid] = useState(initialId)

  useEffect(() => {
    if (uid === null) setUid(genId())
  }, [uid])

  useEffect(() => {
    if (handoffComplete === false) {
      handoffComplete = true
    }
  }, [])

  const id = uid != null ? uid.toString() : undefined

  return (prefix ? `${prefix}-${id}` : id)
}
