import React from 'react'

import { renderTemplate } from '../render-template'

const ListPhonePJ = ({ items }) => (
  <div>
    {items.map((item, index) => (
      <fieldset key={index} className="col-xs-12" data-list-item="phone">
        {Object.entries(item).map(([property, value]) => (
          <input
            key={property}
            type="hidden"
            name={`employees[${index}][${property}]`}
            value={typeof value === 'boolean' ? value.toString() : value}
          />
        ))}
        {item.phone && (
          <div className="col-xs-12 info-box">
            <div className="row">
              <div className="col-xs-12 info-text">
                <strong>Nome:</strong>
                <span>{item.name}</span>
              </div>
              <div className="col-xs-12 info-text">
                <strong>Telefone:</strong>
                <span>{item.phone}</span>
              </div>
              <div className="col-xs-12 col-sm-6 info-text">
                {item.default === 'true' || index === 0 ? (
                  <p>Telefone principal</p>
                ) : null}
              </div>
            </div>
            <div className="row">
              <div className="col-xs-5">
                <button
                  className="button button-neutral"
                  type="button"
                  data-trigger="modal"
                >
                  Editar
                </button>
              </div>
              {items.length > 1 && (
                <div className="col-xs-7 align-right">
                  <button
                    className="button button-text"
                    type="button"
                    data-trigger="remove"
                  >
                    <i className="glyph glyph-trash-can"></i>
                    Remover Telefone
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
      </fieldset>
    ))}
  </div>
)

const render = (props = {}) => renderTemplate(<ListPhonePJ {...props} />)

export default render
