import { Spinner } from '@leroy-merlin-br/backyard-react'

import * as S from './styled'

const ModulesSpinner = () => (
  <S.Wrapper>
    <Spinner size="exa" appearance="primary" data-testid="spinner" />
  </S.Wrapper>
)

export default ModulesSpinner
