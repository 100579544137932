import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { Theme } from 'scripts/react-components/themes/Theme'

export const InputWrapper = styled('div')(
  ({ theme }: Theme) => css`
    position: relative;
  `
)

export const FileName = styled('span')(
  ({ theme }: Theme) => css`
    position: absolute;
    left: 2px;
    top: 35px;
    padding-right: 12px;
    background: ${theme.colors.white};
    padding: 0 4px;
  `
)
