import { Component } from 'react'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

import debounce from 'garden/src/js/utils/debounce'

import * as dropdownRepository from 'scripts/api/header/dropdown-menu'

import { DropdownProvider } from '../contexts/DropdownContext'

class DropdownProviderMethods extends Component {
  constructor (props) {
    super(props)

    this.state = {
      ActivatedItem: null,
      childrenElements: {},
      childrenLoading: false,
      setChildren: this.setChildren,
      setItem: this.setItem,
      showChildrenShell: this.showChildrenShell
    }

    this.subMenuElement = null
  }

  setItem = (e, item) => {
    if (e) {
      e.stopPropagation()
    }

    this.setState({
      ActivatedItem: item ? item.name : null
    }, () => {
      if (item) {
        if (this.subMenuElement) {
          enableBodyScroll(this.subMenuElement)
        }

        this.subMenuElement = document.querySelector('.menu-sub-element.is-active')
      }

      this.state.ActivatedItem ? disableBodyScroll(this.subMenuElement) : enableBodyScroll(this.subMenuElement)
    })
  }

  showChildrenShell = () => {
    this.setState(() => ({
      childrenLoading: true
    }))
  }

  setChildren = (e, id) => {
    const { childrenElements } = this.state
    const hasChildren = this.hasChildrenElement(childrenElements, id)

    if (hasChildren) {
      return this.setState({
        childrenLoading: false
      })
    }

    this.getChildMenu(id)
  }

  getChildMenu = debounce(async (id) => {
    const { childrenElements } = this.state
    const childrenData = await dropdownRepository.getChildren(id)

    this.setState({
      childrenElements: Object.assign(childrenElements, { [id]: childrenData.data }),
      childrenLoading: false
    })
  }, 500)

  hasChildrenElement = (object, id) => {
    return Object.prototype.hasOwnProperty.call(object, id)
  }

  render () {
    return (
      <DropdownProvider value={ this.state }>
        { this.props.children }
      </DropdownProvider>
    )
  }
}

export default DropdownProviderMethods
