import { useCallback, useEffect, useState } from 'react'

import emitter from 'scripts/utils/emitter'

import { cheapestModalityOption } from 'catalog/utils/cheapest-modality-option'

import { getItemDiscount } from '../../utils'
import * as tracker from '../../'
import type { ModalitiesData, ShipmentsChangeData, ViewItem } from './types'

export type GTMProductPageViewProps = {
  initialSku: string
  product: ViewItem
}

const GTMProductPageView = ({
  initialSku,
  product
}: GTMProductPageViewProps) => {
  const [currentSku, setCurrentSku] = useState(initialSku)
  const [viewItem, setViewItem] = useState<ViewItem | undefined>()

  const handleVariantsChange = useCallback(
    (data: string[]) => {
      setViewItem({
        ...product,
        variants: data
      })
    },
    [product]
  )

  const handleSellerChange = useCallback(
    (data) => {
      const selectedSeller = data?.selectedSeller
        ? JSON.parse(data.selectedSeller)
        : {}

      setCurrentSku(selectedSeller.id)

      const selectedPrice = selectedSeller.price?.to
      const selectedDiscount = selectedSeller.price?.from

      const priceIntegers = selectedPrice?.integers.replace(/\./g, '')
      const dicountIntegers = selectedDiscount?.integers.replace(/\./g, '')

      if (viewItem) {
        setViewItem({
          ...viewItem,
          price: `${priceIntegers}.${selectedPrice?.decimals}`,
          discount: `${dicountIntegers}.${selectedDiscount?.decimals}`,
          discountType: selectedSeller.discountType,
          seller: selectedSeller.shop.name
        })
      }
    },
    [viewItem]
  )

  const handleShipmentsChange = useCallback(
    (data: ShipmentsChangeData) => {
      if (!data || !data.shipments.length || data.status.state !== 'valid') {
        return
      }

      const currentShipment = data.shipments.find(
        (seller) => (seller.skuId || '') === currentSku
      )

      const cheapestModality: ModalitiesData | null = cheapestModalityOption(
        currentShipment?.modalities
      )

      if (viewItem) {
        tracker.pushShippingCalculation({
          pageType: 'product',
          shipping: {
            city: data.city,
            stateCode: data.stateCode,
            zipCode: data.postalCode,
            price: cheapestModality?.cost ? cheapestModality?.cost : 0,
            deliveryTime: cheapestModality?.deadline
              ? cheapestModality?.deadline
              : undefined,
            seller: viewItem.seller,
            items: [viewItem.id]
          }
        })
      }
    },
    [viewItem, currentSku]
  )

  useEffect(() => {
    emitter.on('pdp:variant:onLoad', handleVariantsChange)

    return () => {
      emitter.off('pdp:variant:onLoad', handleVariantsChange)
    }
  }, [handleVariantsChange])

  useEffect(() => {
    emitter.on('buybox:sellerChange', handleSellerChange)

    return () => {
      emitter.off('buybox:sellerChange', handleSellerChange)
    }
  }, [handleSellerChange])

  useEffect(() => {
    emitter.on('shipments:change', handleShipmentsChange)

    return () => {
      emitter.off('shipments:change', handleShipmentsChange)
    }
  }, [handleShipmentsChange])

  useEffect(() => {
    if (viewItem) {
      const price = parseFloat(viewItem.price)
      const discount = viewItem.discount
        ? parseFloat(viewItem.discount)
        : undefined

      const hasDiscount = Boolean(discount)
      const discountType =
        viewItem.discountType === 'payment-method'
          ? 'by-payment-method'
          : 'offer'

      tracker.pushViewItem({
        item: {
          id: parseInt(viewItem.id),
          name: viewItem.name,
          type: 'product',
          price,
          discount: hasDiscount ? getItemDiscount(price, discount) : undefined,
          discountType: hasDiscount ? discountType : undefined,
          brand: viewItem.brand,
          categoryTree: viewItem.category,
          variants: viewItem.variants,
          seller: viewItem.seller,
          quantity: 1
        }
      })
    }
  }, [viewItem])

  return null
}

export default GTMProductPageView
