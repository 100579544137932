import { css } from '@emotion/react'
import styled from '@emotion/styled'

const wrapperStyles = ({ theme }) => css`
  label: wrapper;

  display: flex;
  align-items: center;

  position: relative;
  top: ${theme.spacings.giga};

  width: max-content;
  margin-inline: ${theme.spacings.byte};
  border-radius: ${theme.borderRadius.tera};

  strong {
    margin-right: ${theme.spacings.bit};
    margin-left: ${theme.spacings.kilo};
    color: ${theme.colors.white}
  }

  svg {
    fill: ${theme.colors.white};
    margin-right: ${theme.spacings.kilo};
  }
`
const wrapperTodayStyles = ({ theme, deliveryDay }) =>
  deliveryDay === 'today' &&
  css`
    label: wrapper--mobile-today;

    background-color: ${theme.colors.v400};
  `
const wrapperTomorrowStyles = ({ theme, deliveryDay }) =>
  deliveryDay === 'tomorrow' &&
  css`
    label: wrapper--mobile-tomorow;

    background-color: ${theme.colors.v700};
  `

const wrapperForPromotionalProduct = ({ theme, isPromotional }) =>
  isPromotional &&
  css`
    label: wrapper--for-promotional-product;

    position: unset;

    margin-bottom: ${theme.spacings.bit};
  `

export const Wrapper = styled('div')(
  wrapperStyles,
  wrapperTodayStyles,
  wrapperTomorrowStyles,
  wrapperForPromotionalProduct
)
