import axios, { AxiosResponse } from 'axios'

import { api } from './api'
import { Contact, OTPVerify } from '../types'

export type getContactParams = {
  fiscalId: string
  recaptchaToken: string | null
}

export const getContact = async (params: getContactParams) => {
  try {
    const { data } = await api.get<{ data: Contact[] }>(
      `/otp/contacts/${params.fiscalId}`,
      {
        headers: { 'G-Recaptcha': params.recaptchaToken }
      }
    )

    return data
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error.response
    } else {
      throw error
    }
  }
}

export type OtpCodeParams = {
  fiscalId: string,
  hash: string,
  type: string,
  context: string
}

export const sendOTPCode = async (params: OtpCodeParams) => {
  try {
    const response: AxiosResponse<OTPVerify> = await api.post(
      '/otp/send-code',
      {
        ...params
      }
    )

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error.response
    } else {
      throw error
    }
  }
}

export type SendOTPVerifyParams = {
  fiscalId: string
  code: string
  context: string
}

export const sendOTPVerify = async (params: SendOTPVerifyParams) => {
  try {
    const response: AxiosResponse<OTPVerify> = await api.post('/otp/verify', {
      ...params
    })

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error.response
    } else {
      throw error
    }
  }
}

export type NewPasswordSendParams = {
  fiscalId: string
  code: string
  password: string
  password_confirmation: string
}

export const sendNewPassword = async (params: NewPasswordSendParams) => {
  try {
    const response: AxiosResponse<OTPVerify> = await api.post('/auth/reset', {
      ...params
    })

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error.response
    } else {
      throw error
    }
  }
}

export type ServerErrorType = {
  message: string
  status: number
  data: {
    errors: {
      'g-recaptcha': string[]
      'password': string[]
    }
  }
}
