import { PropsWithChildren } from 'react'
import { Button } from '@backyard-ui/core'

type ButtonProps = {
  onConfirm: () => void
  disableConfirmButton?: boolean
}

export const Buttons = ({
  children,
  onConfirm,
  disableConfirmButton
}: PropsWithChildren<ButtonProps>) => {
  return (
    <Button
      onClick={onConfirm}
      disabled={disableConfirmButton}
      size="md"
      variant="solid"
      appearance="primary"
    >
      {children}
    </Button>
  )
}
