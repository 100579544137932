import {
  SubHeading,
  Hidden,
  Container,
  Row,
  Col
} from '@leroy-merlin-br/backyard-react'
import { LeroyLogo } from '@backyard-ui/icons'

import * as S from './styled'

const Header = () => (
  <S.Header>
    <Container>
      <Row>
        <Col
          size={{
            giga: 3,
            tera: 2
          }}
        >
          <S.Brand>
            <a href="/">
              <LeroyLogo />
            </a>
          </S.Brand>
        </Col>
        <Col
          size={{
            giga: 9,
            tera: 10
          }}
        >
          <S.Nav>
            <S.NavBG />
          </S.Nav>
          <Hidden when={['untilKilo', 'kilo', 'mega']}>
            <S.Meta>
              <SubHeading as="h6" color="g500" noMargin>
                Televendas:{' '}
              </SubHeading>
              <SubHeading as="h6" noMargin color="white">
                4007-1380 (Capitais) 0800-602-1380 (Demais regiões). Das 8h às
                19h de segunda a sexta. Das 8h às 14h aos sábados.
              </SubHeading>
            </S.Meta>
          </Hidden>
        </Col>
      </Row>
    </Container>
  </S.Header>
)

export default Header
