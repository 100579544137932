import produce from 'immer'

import { Constants } from '.'

const initialState = {
  data: null,
  isRequest: false,
  error: null
}

const reducer = (state = initialState, action = {}) =>
  produce(state, draft => {
    switch (action.type) {
      case Constants.UPDATE_SUMMARY:
        draft.isRequest = true
        draft.error = null
        break

      case Constants.UPDATE_SUMMARY_SUCCESS:
        draft.isRequest = false
        draft.data = action.data
        break

      case Constants.UPDATE_SUMMARY_FAILURE:
        draft.isRequest = false
        draft.error = action.status
        break

      default:
        return draft
    }
  })

export default reducer
