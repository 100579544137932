import styled from '@emotion/styled'
import { css } from '@emotion/react'

const wrapperStyles = ({ theme }) => css`
  label: stock-content__wrapper;

  align-items: center;
  color: ${theme.colors.p600};
  display: flex;
  font-size: 12px;
  justify-content: flex-start;
  cursor: pointer;
`

const itemStyles = ({ theme }) => css`
  label: stock-content__item;

  padding-right: ${theme.spacings.bit};
  :not(:first-of-type){
    border-left: 1px solid ${theme.colors.p600};
    padding: 0 ${theme.spacings.bit};
  }
`

export const Wrapper = styled('div')(wrapperStyles)
export const Item = styled('span')(itemStyles)
