import { useState, useEffect } from 'react'
import { Text, TextField, Button, Card } from '@leroy-merlin-br/backyard-react'

import debounce from 'garden/src/js/utils/debounce'

import { zipCodeRecontextualizing } from 'scripts/utils/data-layer'

import { Title } from '../Title'
import * as S from './styled'
import { checkZipCodeState, formatPostalCode } from '../../utils'

const PostalCode = ({ errors, zipCode, fetchPriceShipping }) => {
  const [statusZipCode, setStatusZipCode] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [postalCode, setPostalCode] = useState(zipCode)

  const fetchCart = debounce(async searchPostalCode => {
    setIsLoading(true)

    await fetchPriceShipping(searchPostalCode, true)

    setIsLoading(false)
  }, 500)

  const onPostalCodeChange = event => {
    event.stopPropagation()

    const oldPostalCode = postalCode
    const newPostalCode = event.target.value.replace('-', '')

    setPostalCode(newPostalCode)

    const newStatusZipCode = checkZipCodeState(newPostalCode, isLoading, null)

    if (newPostalCode !== oldPostalCode && newStatusZipCode.state === 'valid') {
      zipCodeRecontextualizing('entrega-delivery')
      fetchCart(newPostalCode)
    }
  }

  useEffect(() => {
    zipCode && setPostalCode(zipCode)
  }, [zipCode])

  useEffect(() => {
    const newPostalCode = postalCode.replace('-', '')

    const newStatusZipCode = checkZipCodeState(newPostalCode, isLoading, errors)

    setStatusZipCode(newStatusZipCode)
  }, [errors, isLoading, postalCode])

  return (
    <S.Wrapper>
      <Card>
        <S.Content>
          <S.Info>
            <Title as="h2" size="giga">
              Cálculo do frete
            </Title>
            <Text size="kilo" color="n400" noMargin>
              Para finalizar sua compra, digite o número do seu CEP para
              calcular o frete do seu pedido.
            </Text>
          </S.Info>

          <S.InputGroup>
            <TextField
              label="Digite seu CEP"
              name="postal-code"
              placeholder="CEP"
              onChange={onPostalCodeChange}
              value={formatPostalCode(postalCode)}
              state={statusZipCode?.state || undefined}
              hint={statusZipCode?.hint}
              size="kilo"
            />
            <Button
              href="http://www.buscacep.correios.com.br/sistemas/buscacep/"
              className="color-primary"
              target="_blank"
              rel="noopener noreferrer"
              variant="link"
              size="kilo"
            >
              Não sei meu CEP
            </Button>
          </S.InputGroup>
        </S.Content>
      </Card>
    </S.Wrapper>
  )
}

export default PostalCode
