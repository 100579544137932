import { useState } from 'react'
import { Tooltip } from '@leroy-merlin-br/backyard-react'

import { handleSpinner } from 'site/Cart/services'

import { cartApi } from 'newCheckout/api'

import { SelectStoreDrawer } from '../Drawer/SelectStores'
import { ChangeItemTypeButtonType } from './type'
import Visual from './Visual'
import * as dataLayer from './dataLayer'
import { ChangeCartType } from 'newCheckout/dataLayer/changeCartType'

const changeItemShipment = async ({
  itemId,
  cartId,
  storeId,
  type,
  callback,
  dataLayerInfo
}: {
  itemId: string
  cartId: string
  storeId?: string | null
  type: 'pickupInStore' | 'deliveryCompany'
  callback: () => void
  dataLayerInfo: ChangeCartType
}) => {
  try {
    handleSpinner({ useSpinner: true, action: 'show' })
    const response = await cartApi.changeItemShipment({
      itemId,
      cartId,
      storeId,
      type
    })

    dataLayer.changeCartType(dataLayerInfo)

    callback()
  } catch (error) {
    handleSpinner({ useSpinner: false })
  }
}

const ChangeItemTypeButton = ({
  type,
  itemId,
  cartId,
  callback,
  itemAvailableStores,
  dataLayerInfo
}: ChangeItemTypeButtonType) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  if (!itemAvailableStores) return null

  const isDisabled =
    type === 'delivery'
      ? itemAvailableStores.stores.length === 0
      : !itemAvailableStores?.deliveryAvailable
  if (isDisabled) return null

  const onDeliveryClick = () => {
    if (type !== 'delivery' && itemAvailableStores?.deliveryAvailable) {
      return () =>
        changeItemShipment({
          itemId,
          cartId,
          storeId: null,
          type: 'deliveryCompany',
          callback,
          dataLayerInfo: {
            ...dataLayerInfo,
            store: ''
          }
        })
    }

    return () => {}
  }

  const onPickUpClick = () => {
    if (type === 'pickupInStore' || itemAvailableStores?.stores.length === 0) {
      return () => {}
    }

    if (itemAvailableStores?.storeAlreadySelected) {
      return () =>
        changeItemShipment({
          itemId,
          cartId,
          storeId: itemAvailableStores.stores[0].storeId,
          type: 'pickupInStore',
          callback,
          dataLayerInfo: {
            ...dataLayerInfo,
            store: itemAvailableStores.stores[0].storeId
          }
        })
    }
    return () => setIsDrawerOpen(true)
  }

  return (
    <>
      {itemAvailableStores && type === 'delivery' && (
        <SelectStoreDrawer
          isDrawerOpen={isDrawerOpen}
          overlayClick={() => setIsDrawerOpen(false)}
          stores={itemAvailableStores.stores}
          title="Escolha a loja para retirar o seu produto"
          onChange={({ storeId }) => {
            setIsDrawerOpen(false)
            if (!storeId) return
            dataLayerInfo.store = storeId
            return changeItemShipment({
              itemId,
              cartId,
              storeId: storeId,
              type: 'pickupInStore',
              callback,
              dataLayerInfo
            })
          }}
        />
      )}
      <Visual
        OnDeliveryClick={onDeliveryClick()}
        OnPickUpClick={onPickUpClick()}
        type={type}
        isDisabled={isDisabled}
      />
    </>
  )
}

export default ChangeItemTypeButton
