import { Fragment, useEffect, useState } from 'react'
import { Hidden, Container, Heading } from '@leroy-merlin-br/backyard-react'

import { ContextVisibility } from 'shared/components/ContextVisibility'
import { ContentCard } from 'shared/components/ContentCard'

import { Breadcrumb } from 'site/Breadcrumb'

import Shell from './components/Shell'
import * as S from './styled'
import { ShareButton } from '../EntryKey/components/BreadcrumbBar/components/ShareButton'
import { getResource } from './services'

const FamilyPage = ({ categoryId }) => {
  const [headerInfo, setHeaderInfo] = useState()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const loadResource = async () => {
      const { data: headerInfo } = await getResource(categoryId)

      setHeaderInfo(headerInfo)
      setIsLoading(false)
    }

    loadResource()
  }, [categoryId])

  if (isLoading) {
    return <Shell />
  }

  if (!categoryId) {
    return
  }

  const { name, description } = headerInfo
  const hasDescription = Boolean(description)

  return (
    <Fragment>
      <S.Wrapper withShaddow={!hasDescription}>
        <Container isFluid>
          <S.BreadcrumbBar spaced={hasDescription}>
            <Breadcrumb categoryId={categoryId} />
            <ContextVisibility when={['ecommerce']}>
              <Hidden when={['giga', 'tera']}>
                <ShareButton />
              </Hidden>
            </ContextVisibility>
          </S.BreadcrumbBar>
        </Container>
      </S.Wrapper>

      <S.Wrapper>
        <Container isFluid>
          {hasDescription ? (
            <ContentCard title={name} description={description} />
          ) : (
            <Heading size="exa" as="h1">
              {name}
            </Heading>
          )}
        </Container>
      </S.Wrapper>
    </Fragment>
  )
}

export default FamilyPage
