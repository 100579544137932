import { Button, Stack, Text, TextField } from '@leroy-merlin-br/backyard-react'
import { ChangeEvent, FormEvent, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useHistory, useLocation } from 'react-router-dom'

import * as validator from 'utils/validators/validators'

import { MobileLayout } from '../../components/MobileLayout'
import * as S from './styled'

type FormValues = {
  email: string
}

function useQuery () {
  const { search } = useLocation()

  return useMemo(() => new URLSearchParams(search), [search])
}

const EditEmail = () => {
  const query = useQuery()

  const origin = query.get('origin')
  const identityCode = query.get('identityCode')
  const email = query.get('email')
  const newEmail = query.get('newEmail')
  const fiscalId = query.get('fiscalId')

  const [fieldValue, setFieldValue] = useState(newEmail || email)

  const [loading, setLoading] = useState(false)

  const {
    control,
    formState: { errors, isValid }
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      email: ''
    }
  })

  const history = useHistory()

  const onSubmit = (event: FormEvent<EventTarget>) => {
    event.preventDefault()

    setLoading(true)

    history.push({
      pathname: '/validar-email',
      search: `?origin=${origin}&identityCode=${identityCode}&email=${email}&newEmail=${fieldValue}&fiscalId=${fiscalId}`
    })
  }

  return (
    <MobileLayout description="Contatos">
      <Text>
        Edite seu e-mail
      </Text>

      <Stack space="giga">
        <form onSubmit={onSubmit}>
          <Controller
            name="email"
            rules={{
              required: 'Insira seu e-mail',
              validate: (value: string) =>
                validator.isEmail(value) || 'E-mail inválido'
            }}
            control={control}
            state={Boolean(errors.email) && 'invalid'}
            hint={errors.email?.message}
            render={({
              field: { onChange, name, ref },
              fieldState: { error }
            }) => (
              <TextField
                isDisabled={loading}
                name={name}
                ref={ref}
                label="e-mail"
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  setFieldValue(event.target.value)

                  onChange(event)
                }}
                value={fieldValue}
                state={error ? 'invalid' : undefined}
                hint={error?.message}
              />
            )}
          />

          <S.ButtonWrapper>
            <Button
              isStretch
              isLoading={loading}
              type="submit"
              isDisabled={!isValid || email === fieldValue}>
              Salvar e validar e-mail
            </Button>
          </S.ButtonWrapper>
        </form>
      </Stack>

      <Button
        isStretch
        isDisabled={loading}
        appearance="critical"
        variant="ghost"
        onClick={() => history.push('/meus-contatos')}
      >
        Cancelar
      </Button>

    </MobileLayout>
  )
}

export default EditEmail
