import { Fragment, memo } from 'react'
import PropTypes from 'prop-types'
import { Text, Button, Stack, Icon } from '@leroy-merlin-br/backyard-react'
import {
  ShareOutline,
  Checkmark,
  PercentSignOutline,
  MoneyOutline,
  DollarSignOutline
} from '@backyard-ui/icons'

import { DEPARTMENT_ICONS } from 'shared/icons'
import { Ellipsis } from 'shared/components/Ellipsis'

import { isLink } from 'utils/validators'

import * as S from './styled'
import { Background } from './components/Background'
import { GiftFlag } from './components/GiftFlag'

function Coupon ({
  image,
  title,
  description,
  category,
  buttonText,
  footerText,
  giftedBy,
  isShared,
  isActive,
  isLoading,
  isDisabled,
  onClickCoupon,
  onClickButton,
  id,
  price,
  discount,
  remainingDays
}) {
  const PictureIcon =
    isShared || category === 'percentage'
      ? PercentSignOutline
      : DollarSignOutline

  const thumbResolver = src => {
    if (isLink(src)) {
      return <img src={src} alt={title} />
    }

    if (src) {
      return DEPARTMENT_ICONS[src]
    }

    return <Icon as={MoneyOutline} />
  }

  const couponTagCategory = {
    money: 'cashback',
    percentage: 'advantage'
  }

  return (
    <S.Wrapper
      isActive={isActive}
      onClick={onClickCoupon}
      data-gtm-coupon-id={id}
      data-gtm-coupon-name={title}
      data-gtm-coupon-category={couponTagCategory[category]}
      data-gtm-coupon-type={category}
      data-gtm-coupon-is-active={isActive}
      data-gtm-coupon-price={price}
      data-gtm-coupon-discount={discount}
      data-gtm-coupon-remaining-days={remainingDays}
    >
      <Background isActive={isActive} />

      {giftedBy && <GiftFlag isActive={isActive} />}

      {isShared && (
        <S.IconShare>
          <Icon as={ShareOutline} size="mega" data-icon="share" />
        </S.IconShare>
      )}

      <S.Content>
        <S.GroupDescription>
          <S.Picture>
            {thumbResolver(image)}

            <S.PictureIcon isActive={isActive}>
              <Icon as={PictureIcon} size="kilo" />
            </S.PictureIcon>
          </S.Picture>

          <S.Title>
            <Ellipsis clamp={2}>
              <Text size="mega" align="center" noMargin isBold>
                {title}
              </Text>
            </Ellipsis>
          </S.Title>

          {description && (
            <S.Description>
              <Ellipsis clamp={2}>
                <Text size="kilo" align="center" noMargin>
                  {description}
                </Text>
              </Ellipsis>
            </S.Description>
          )}
        </S.GroupDescription>

        <S.Divider />

        <Stack space="byte">
          <S.ButtonWrapper>
            <Button
              appearance="secondary"
              size="mega"
              isDisabled={isDisabled}
              isLoading={isLoading}
              onClick={onClickButton}
              isStretch
            >
              {isActive ? (
                <Fragment>
                  <S.ButtonIcon>
                    <Icon as={Checkmark} size="kilo" />
                  </S.ButtonIcon>

                  <Text size="mega" noMargin isBold>
                    {buttonText.active}
                  </Text>
                </Fragment>
              ) : (
                <Text size="mega" noMargin isBold>
                  {buttonText.default}
                </Text>
              )}
            </Button>
          </S.ButtonWrapper>

          <S.TextFooter>
            <Text size="kilo" align="center" noMargin>
              {isActive ? footerText.active : footerText.default}
            </Text>
          </S.TextFooter>
        </Stack>
      </S.Content>
    </S.Wrapper>
  )
}

Coupon.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  category: PropTypes.oneOf(['percentage', 'money']).isRequired,
  buttonText: PropTypes.shape({
    default: PropTypes.string,
    active: PropTypes.string
  }),
  footerText: PropTypes.shape({
    default: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    active: PropTypes.oneOfType([PropTypes.node, PropTypes.string])
  }).isRequired,
  giftedBy: PropTypes.string,
  isShared: PropTypes.bool,
  isActive: PropTypes.bool,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onClickCoupon: PropTypes.func,
  onClickButton: PropTypes.func,
  id: PropTypes.string,
  price: PropTypes.number,
  discount: PropTypes.number,
  remainingDays: PropTypes.number
}

Coupon.defaultProps = {
  buttonText: {
    default: 'Ativar',
    active: 'Ativado'
  },
  isActive: false,
  isLoading: false,
  isDisabled: false
}

export default memo(Coupon)
