export enum BankAccountType {
  CURRENT_ACCOUNT = 'Corrente',
  SAVINGS_ACCOUNT = 'Poupança'
}

export const BankAccountTypeItems = Object.values(
  BankAccountType
) as BankAccountType[]

export const BankAccountTypeLabels: { [key in BankAccountType]: string } = {
  [BankAccountType.CURRENT_ACCOUNT]: 'Conta Corrente',
  [BankAccountType.SAVINGS_ACCOUNT]: 'Conta Poupança'
}
