import React from 'react'

import Typography, { TypographyProps } from '../Typography'

type HeadingType = Omit<TypographyProps, 'variant'> & {
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  type?: 'default'
}

function Heading ({ size = 'md', type, children, ...rest }: HeadingType) {
  if (React.isValidElement(children)) {
    throw new Error(
      'Heading component does not accept React elements as children'
    )
  }

  return (
    <Typography variant={`heading-${size}`} type={type} {...rest}>
      {children}
    </Typography>
  )
}

export default Heading
