export const ChatTyping = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 1 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 3H4C2.897 3 2 3.897 2 5V17C2 18.103 2.897 19 4 19H7V22.767L13.277 19H20C21.103 19 22 18.103 22 17V5C22 3.897 21.103 3 20 3ZM20 17H12.723L9 19.233V17H4V5H20V17Z"
      fill="white"
    />
  </svg>
)
export default ChatTyping
