import { FunctionComponent } from 'react'
import { Hidden, SimpleGrid, Stack, Text, Visible } from '@backyard-ui/core'

import formatPriceToBRL from 'utils/formatPriceToBRL'

import { Props } from './types'
import styles from './PaymentMethods.styles'

const PaymentMethods: FunctionComponent<Props> = ({ installments }) => (
  <Stack direction="column" spacing="0.5">
    {installments?.map((item, index) => (
      <SimpleGrid
        key={index}
        className={styles().installmentItem()}
        columns={{ initial: 2, md: 3 }}
        gapX="4"
      >
        <Text size="md" weight="bold">
          {item.quantity}x de {formatPriceToBRL(item.price)}
        </Text>

        <Visible above="md">
          <Text size="md">s/ juros</Text>
        </Visible>

        <Text size="md">
          <Hidden above="md">s/ juros</Hidden> total{' '}
          {formatPriceToBRL(item.totalPrice)}
        </Text>
      </SimpleGrid>
    ))}
  </Stack>
)

export default PaymentMethods
