import type { SVGAttributes } from 'react'
import { forwardRef } from 'react'

const BricolageIcon = forwardRef<SVGSVGElement, SVGAttributes<SVGSVGElement>>((props, ref) => (
  <svg ref={ref} viewBox="0 0 122 113" {...props}>
    <path d="M23 26c-1.9 1.9-2 3.3-2 34v32.1l2.5 2.4 2.4 2.5H57c30.7 0 34.2-.3 36.2-3.4.4-.6.8-15.6.8-33.4 0-30.9-.1-32.3-2-34.2-1.9-1.9-3.3-2-34.5-2s-32.6.1-34.5 2zm66.8 1.3c.8.9 1.2 10 1.2 26.8l.1 25.4-33.5.3L24 80V54.1c0-23.7.2-26.1 1.8-27 2.8-1.7 62.7-1.4 64 .2zm1.5 59.4c-.3 2.7-.6 5.4-.9 6.1-.3 1-7.4 1.2-33.1 1l-32.8-.3-.3-5.8-.3-5.7h67.9l-.5 4.7z" />
    <path d="m61.7 35.1-4.9 5.1-3.4-4.1c-5.3-6.1-6.8-6.1-12.6-.5-7.6 7.5-7.6 7.6-2.2 13.4l4.7 5.1-3.1 2.7c-2.7 2.3-3.4 3.8-4.4 9.9-1.6 9.5-1.1 10 7.9 8.3 5.2-1 7.6-2 10.1-4.3l3.2-3.1 4.3 4.2c2.3 2.3 5 4.2 5.8 4.2.9 0 4.2-2.7 7.3-6 6.4-6.7 6.4-7.3 0-12.9l-3.6-3.2 5.1-5.4c2.8-3 5.1-6.1 5.1-6.9C81 39 71.7 30 69 30c-1.5 0-4.1 1.8-7.3 5.1zm11.8 1c4.6 4.2 5.5 6.5 3.3 8.7-1 1-2.4.1-6.5-4-4.9-4.9-5.2-5.3-3.6-7 .9-1 1.8-1.8 2-1.8.2 0 2.4 1.9 4.8 4.1zM62.5 49l14.9 15-4.9 5-4.9 5-2.4-2.2c-2-2-2.1-2.4-.8-3.4 1.7-1.2 2.2-3.4.7-3.4-.5 0-1.4.7-2.1 1.5-1.8 2.2-3.2 1.8-2.8-.8.2-1.8-.1-2.1-1.9-1.9-1.8.2-2-.1-1.6-1.9.5-1.8.3-2-1.6-1.4-2.4.7-2.8-.7-.6-2.5.8-.7 1.5-1.6 1.5-2.1 0-1.5-2.2-1-3.5.8-.7.9-1.7 1.3-2.4.9-.9-.6-.9-1.1 0-2.2 1.2-1.5.7-1.8-2.4-1.4-1.5.1-1.7-.3-1.2-2 .6-1.9.4-2.1-1.4-1.5-2.5.8-2.9-1.6-.6-3.5.8-.7 1.2-1.7.9-2.2-.3-.5-1.4-.2-2.5.8-1.9 1.7-2.1 1.7-4-.2l-2-2.1 4.3-4.5c2.4-2.5 4.8-4.6 5.3-4.7.6 0 7.8 6.6 16 14.9zm1.1-7.1c-2 2-2.8 2.3-3.8 1.3-1.8-1.8 2.9-6.7 4.9-5 1.2.9 1 1.6-1.1 3.7zm5.4.9c0 .5-1.1 1.9-2.4 3.1-2.9 2.7-4.7 1-2-2 1.7-1.9 4.4-2.6 4.4-1.1zm3.7 3.8c.3.9-.3 2.4-1.5 3.7-1.8 1.7-2.3 1.8-3.7.7-1.4-1.2-1.4-1.5.6-3.7 2.6-2.7 3.7-2.9 4.6-.7zM46.5 57.1c.4.6-.3 2.1-1.6 3.5-1.7 1.8-2.5 2.1-3.6 1.2-1.1-.9-1-1.5.8-3.5 2.5-2.5 3.5-2.8 4.4-1.2zm3.9 3.8c.8 1.2-4.1 5.4-5.4 4.6-.8-.5-.5-1.5 1.1-3.1 2.4-2.5 3.5-2.9 4.3-1.5zm-4.6 11.2c-3.3.9-8.3-4-7.3-7.1.7-2.1.9-2 5.1 2.1 4.3 4.2 4.4 4.4 2.2 5zm7.2-3.6c-2.1 2.3-2.6 2.4-3.9 1-1.3-1.3-1.2-1.7.9-4s2.6-2.4 3.9-1c1.3 1.3 1.2 1.7-.9 4zM29 87.9c0 2.3.5 4.1 1 4.1.6 0 1-1.5 1-3.4s-.4-3.8-1-4.1c-.6-.4-1 1-1 3.4zM33.4 85.4c-.8 2.2-.1 5.9 1.4 6.4.8.2 1.2-.8 1.2-3.2 0-3.7-1.6-5.7-2.6-3.2zM68.9 84.5c-.1.5-.1 1.3 0 1.7 0 .5-6.9.8-15.4.8-9.7 0-15.5.4-15.5 1s5.6 1 14.9 1c12.3 0 15 .3 15.5 1.5.3.8 1 1.5 1.6 1.5.5 0 1-.7 1-1.5 0-1.2 1.6-1.5 8-1.5 4.7 0 8-.4 8-1s-3.2-1-7.6-1c-6.1 0-7.9-.4-9-1.8-.7-.9-1.4-1.3-1.5-.7z" />
  </svg>
))

export default BricolageIcon
