import search from 'algoliasearch/lite'
import aa from 'search-insights'
import { InsightsClient } from 'instantsearch.js/es/types/insights'
import cookies from 'js-cookie'

const APP_ID = window.env.algolia?.appId
const API_KEY = window.env.algolia?.apiKey
const USER_ID = cookies.get('user_id')

export const searchClient = search(APP_ID, API_KEY)

if (USER_ID) {
  aa('init', {
    appId: APP_ID,
    apiKey: API_KEY,
    userToken: USER_ID
  })
} else {
  aa('init', {
    appId: APP_ID,
    apiKey: API_KEY,
    useCookie: true
  })
}

export const insightsConfig = {
  insightsClient: aa as InsightsClient
}

export const indexName = window.env.algolia?.indexName
export const retailMediaIndexName = window.env.algolia?.retailMediaIndexName
