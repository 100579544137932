import { ModalProvider } from '@leroy-merlin-br/backyard-react'

import reactComponentLoader from 'scripts/react-component-loader'

import BannerContainer from './BannerContainer'

const ComponentBanner = ({ ...props }) => (
  <ModalProvider>
    <BannerContainer {...props} />
  </ModalProvider>
)

reactComponentLoader(ComponentBanner, '[data-app-banner-install]')
