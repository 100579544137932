import PropTypes from 'prop-types'

import { OfferSchema } from 'scripts/react-components/shared/schemas'

import { SoldOutProductMessage } from './components/SoldOutProductMessage'
import * as S from './styled.js'

const PriceTag = ({
  isSoldOut,
  isOutlet,
  isPromotional,
  isDiscountByPaymentMethod,
  price,
  unit,
  paymentMethodsDiscount,
  installments,
  url,
  name,
  featureInstallmentDiscount
}) => {
  const { to, from, suggested } = price
  const hasPrice = !!to
  const hasInstallments = Boolean(installments)
  const hasSuggestedPrice = Boolean(isDiscountByPaymentMethod && suggested)

  const renderPromotional = () => {
    if (!isPromotional || featureInstallmentDiscount) {
      return null
    }
    let priceText = `R$ ${from.integers},${from.decimals} ${unit}`
    if (hasSuggestedPrice) {
      priceText = `R$ ${suggested.integers},${suggested.decimals} ${unit}`
    }

    return (
      <S.AdditionalPriceInfo isPromotional={isPromotional} data-testid="promotional-price-info">
        {priceText}
      </S.AdditionalPriceInfo>
    )
  }

  const renderPaymentMethods = () => {
    if (!isDiscountByPaymentMethod) {
      return null
    }

    return (
      <S.AdditionalPaymentDiscountPriceInfo>
        {paymentMethodsDiscount}
      </S.AdditionalPaymentDiscountPriceInfo>
    )
  }

  const renderInstallments = () => {
    if (!hasInstallments) {
      return null
    }

    const totalPriceInstallments = isDiscountByPaymentMethod
      ? `R$ ${installments.totalPrice} em até`
      : ''

    return (
      <S.AdditionalPriceInfo>
        {totalPriceInstallments} {installments.amount}x de R$ {installments.value} s/juros
      </S.AdditionalPriceInfo>
    )
  }

  if (isSoldOut) {
    return (<div>
        <OfferSchema
          availability={'OutOfStock'}
          productId={''}
          productUrl={url}
          productPrice={{
            integers: to?.integers,
            decimals: to?.decimals
          }}
        />
        <SoldOutProductMessage />
      </div>)
  }

  if (hasPrice) {
    return (
      <a href={url} title={name}>
        <OfferSchema
          availability={'InStock'}
          productId={''}
          productUrl={url}
          productPrice={{
            integers: to.integers,
            decimals: to.decimals
          }}
        />
        <S.PriceTag
          isDiscountByPaymentMethod={isDiscountByPaymentMethod}
          isPromotional={isPromotional}
          isOutlet={isOutlet}
          featureInstallmentDiscount={featureInstallmentDiscount}
        >
          {renderPromotional()}
          <S.PriceContent
            isPromotional={isPromotional}
            hasInstallments={hasInstallments}
          >
            <S.Price>
              R$ {to.integers},{to.decimals}
            </S.Price>
            <S.PriceUnit>{unit}</S.PriceUnit>
          </S.PriceContent>
          {renderPaymentMethods()}
          {renderInstallments()}
        </S.PriceTag>
      </a>
    )
  }

  return (
    <div>
      <OfferSchema
        availability={'OutOfStock'}
        productId={''}
        productUrl={url}
        productPrice={{
          integers: '',
          decimals: ''
        }}
      />
      <SoldOutProductMessage />
    </div>)
}

PriceTag.propTypes = {
  price: PropTypes.shape({
    to: PropTypes.shape({
      integers: PropTypes.string.isRequired,
      decimals: PropTypes.string.isRequired
    }),
    suggested: PropTypes.shape({
      integers: PropTypes.string,
      decimals: PropTypes.string
    }),
    from: PropTypes.shape({
      integers: PropTypes.string.isRequired,
      decimals: PropTypes.string.isRequired
    })
  }),
  unit: PropTypes.string.isRequired,
  installments: PropTypes.shape({
    amount: PropTypes.number,
    value: PropTypes.string
  }),
  url: PropTypes.string.isRequired,
  name: PropTypes.string,
  paymentMethodsDiscount: PropTypes.string,
  isSoldOut: PropTypes.bool,
  isOutlet: PropTypes.bool,
  isPromotional: PropTypes.bool
}

export default PriceTag
