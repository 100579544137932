import { mappedUrlParams } from './category/utils/sponsorsUrlParams'

/**
 *
 * We need to transform refinement list response into
 * an object with array of strings, and pass to UI State
 */
export function normalizeRefinementList (value: Record<string, {}>) {
  const toArr = Object.keys(value)

  return toArr.reduce((acc, current) => {
    return {
      ...acc,
      [current]: Array.isArray(value[current])
        ? value[current]
        : [value[current]].filter(Boolean)
    }
  }, {})
}

/**
 *
 * Replace & with ? if it contains a blacklisted prefix
 */
export function normalizeGoogleParams (value: string): string {
  const BLACKLIST_PREFIX = mappedUrlParams.google.map(params => `?${params}`)

  for (const prefix of BLACKLIST_PREFIX) {
    if (value.includes(prefix)) {
      value = value.replace(
        new RegExp(prefix.replace('?', '\\?'), 'g'),
        prefix.replace('?', '&')
      )
    }
  }

  return value
}
