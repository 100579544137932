import { Text } from '@leroy-merlin-br/backyard-react'

import { AlertMessage } from 'shared/components/AlertMessage'

const SeparationProblemMessage = () => (
    <AlertMessage type="warning">
      Infelizmente houve um{' '}
      <Text size="kilo" as="strong" isBold noMargin>
        problema durante a separação{' '}
      </Text>
      do seu pedido que pode ou não ter um impacto no prazo de entrega.{' '}
      <Text size="kilo" as="strong" isBold noMargin>
        Já estamos resolvendo essa situação{' '}
      </Text>
      e em breve entraremos em contato{' '}
      <Text size="kilo" as="strong" isBold noMargin>
        caso o prazo de entrega seja afetado.
      </Text>
    </AlertMessage>
)

export default SeparationProblemMessage
