import { Fragment, useEffect, useRef } from 'react'
import { Helmet } from 'react-helmet'

import { setupKazaplan } from 'catalog/utils/kazaplan'

import * as S from './styled'

const Kazaplan = ({ profile, appId, partnerKey, url, env, environment, token }) => {
  const ref = useRef()

  useEffect(() => {
    if (ref.current) {
      setupKazaplan(ref.current, { profile, appId, partnerKey, env, environment, token })
    }
  }, [profile, appId, partnerKey, env, environment, token, ref])

  return (
    <Fragment>
      <Helmet>
        <script async type="text/javascript" src={url}></script>
      </Helmet>
      <S.Wrapper ref={ref} />
    </Fragment>
  )
}

export default Kazaplan
