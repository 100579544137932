import styled from '@emotion/styled'
import { css } from '@emotion/react'

const coverBackgroundStyles = ({ theme }) => css`
  label: cover-background;

  width: 100%;
  min-height: 258px;

  margin-bottom: -150px;

  background-image: linear-gradient(
    177.43deg,
    ${theme.colors.v400} 0%,
    ${theme.colors.f600} 100%
  );
`

const contentStyles = ({ theme }) => css`
  label: container__content;

  width: 100%;
  max-width: 768px;

  margin: ${theme.spacings.tera} auto;
  padding: ${theme.spacings.zetta} ${theme.spacings.giga};

  border-radius: ${theme.borderRadius.tera};
  background-color: ${theme.colors.white};
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.16);

  ${theme.media.giga} {
    padding: ${theme.spacings.zetta} 80px;
  }
`

const titleStyles = ({ theme }) => css`
  color: ${theme.colors.n800};
`
const descriptionStyles = ({ theme }) => css`
  color: ${theme.colors.n800};
`

export const CoverBackground = styled('div')(coverBackgroundStyles)

export const Content = styled('div')(contentStyles)
