import PropTypes from 'prop-types'
import { motion } from 'framer-motion'

import Fade from './components/Fade'

const Transition = ({ children, ...props }) => (
  <motion.div {...props}>{children}</motion.div>
)

Transition.Fade = Fade

Transition.propTypes = {
  children: PropTypes.node
}

export default Transition
