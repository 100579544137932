import { useContext, useCallback } from 'react'
import { Text } from '@leroy-merlin-br/backyard-react'

import { ToggleButton } from 'shared/components/ToggleButton'

import { EntryKeyContext } from '../../context/EntryKeyContext'
import * as S from './styled'

const SwitchFilter = ({ children, optionClick = { name: '', value: '' } }) => {
  const { onOptionClick, query } = useContext(EntryKeyContext)

  const isActive = useCallback(() => {
    const specificQuery = query[optionClick.value] || query[optionClick.name]

    return Boolean(specificQuery?.value === optionClick.value)
  }, [optionClick.name, optionClick.value, query])

  return (
    <S.SwitchFilter>
      <Text as="strong" size="giga" isBold noMargin>
        {children}
      </Text>

      <ToggleButton
        isActive={isActive()}
        onClick={event => {
          event.preventDefault()
          event.stopPropagation()

          onOptionClick({ ...optionClick })
        }}
      />
    </S.SwitchFilter>
  )
}

export default SwitchFilter
