import { useContext } from 'react'
import { Card, Icon } from '@leroy-merlin-br/backyard-react'

import { TruckDelivery } from 'shared/icons/TruckDelivery'

import { deliveryLimitHours } from 'site/utils/deliveryLimitHours'

import { EntryKeyContext } from '../../context'
import { Accordion, SwitchFilter, PriceRange } from '../'
import { FilterOptions, FilterTitle } from '../Filters/components'
import * as S from './styled'

const MINIMUM_AMOUNT_OF_FILTERS = 5

const FilterType = ({ filterObject, filterIndex = 0, numberOfFilters = 0 }) => {
  const { type, filterKey, name, options } = filterObject

  const { isSearch, isBlackWeekend } = useContext(EntryKeyContext)

  const isBlackFridayFilter = filterKey === 'black-friday'
  const isFastDelivery = filterKey === 'entrega-rapida'

  const isFilterExpanded = isSearch && numberOfFilters <= MINIMUM_AMOUNT_OF_FILTERS

  const freight = deliveryLimitHours()

  const baseFilterOption = (baseObject = {}) => {
    const {
      filterKeyName = filterKey,
      filterTypeName = name,
      value = '',
      optionLabel = '',
      filterTypeIndex = filterIndex,
      filterElementIndex = 0
    } = baseObject

    return {
      name: filterKeyName,
      value: value,
      type: 'filter',
      dataLayerOptions: {
        filter: 'Normal',
        filterType: { index: filterTypeIndex, value: filterTypeName },
        filterElement: { index: filterElementIndex, value: optionLabel }
      }
    }
  }

  if (isBlackFridayFilter && !isBlackWeekend) {
    return null
  }

  const chooseFilter = () => {
    switch (type) {
      case 'boolean':
        return (
          <Card key={filterKey} target="switch-card">
            <SwitchFilter
              optionClick={baseFilterOption({
                value: options[0].query.replace(/\s/g, '_'),
                optionLabel: options[0].label
              })}
            >
              {isFastDelivery ? (
                <S.FastDelivery>
                  Receba {freight.deliveryForecast} <Icon as={TruckDelivery} />
                </S.FastDelivery>
              ) : (
                name
              )}
            </SwitchFilter>
          </Card>
        )
      case 'price':
        return (
          <Card key={filterKey} target="card">
            <PriceRange filter={filterObject} filterTypeIndex={filterIndex} />
          </Card>
        )
      default:
        return (
          <Accordion
            title={<FilterTitle filter={filterObject} />}
            defaultOpen={isFilterExpanded}
          >
            <FilterOptions
              {...filterObject}
              filterTypeIndex={filterIndex}
              optionClick={baseFilterOption()}
            />
          </Accordion>
        )
    }
  }

  return (
    <S.Wrapper isBlackFriday={isBlackFridayFilter} data-cy={`filter-${filterKey}`}>{chooseFilter()}</S.Wrapper>
  )
}

export default FilterType
