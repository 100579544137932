import BaseComponent from 'scripts/components/base-component'

class EditPhone extends BaseComponent {
  static componentName = 'account/edit-phone'

  static DOMEvents = {
    'change [data-phone-edit="select"]': 'onPhoneTypeChange'
  }

  init () {
    this.$phoneTypeSelect = this.$element.find('[data-phone-edit="select"]')
    this.$defaultPhoneCheckbox = this.$element.find(
      '[data-phone-edit="checkbox"]'
    )

    this.onPhoneTypeChange()
  }

  onPhoneTypeChange () {
    const value = this.$phoneTypeSelect.val()

    if (value === 'cellphone') {
      this.$defaultPhoneCheckbox.show()
    } else {
      this.$defaultPhoneCheckbox.find('[type=checkbox]').prop('checked', false)
      this.$defaultPhoneCheckbox.hide()
    }
  }
}

export default ($el) => new EditPhone($el).init()

export { EditPhone as Component }
