export const footerOptions = [
  {
    url: '#',
    label: 'Política de Privacidade'
  },
  {
    url: '#',
    label: 'Condições de Uso'
  },
  {
    url: '#',
    label: 'Ajuda'
  },
  {
    url: '#',
    label: 'Política de Cookies'
  }
]
