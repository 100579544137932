export const buildNumberValue = (number, isOther = false) => {
  if (['S/N', 'SN'].includes(number)) {
    return 'S/N'
  } else if (isOther) {
    return ''
  } else {
    return number
  }
}

export const validateNumberByState = (number, state) => {
  const isSpecialStateAndValidRange =
    /DF|TO|GO/.test(state) && /^.{1,10}$/.test(number)

  const isInValidRange = /^\d{1,5}$/.test(number)

  const isWithoutNumber = /^S\/N$/i.test(number)

  const beginWithKM = /^KM(\s[\S]{1,7}|[\S]{1,8})?$/i.test(number)

  return (
    isSpecialStateAndValidRange ||
    isInValidRange ||
    isWithoutNumber ||
    beginWithKM
  )
}

const logradouro = addressData => ({
  street: {
    disabled: true,
    required: true
  },
  district: {
    disabled: true,
    required: true
  },
  city: {
    disabled: true,
    required: true
  },
  state: {
    disabled: true,
    required: true
  },
  number: {
    disabled: Boolean(addressData.number),
    required: true,
    value: buildNumberValue(addressData.number)
  },
  complement: {
    disabled: Boolean(addressData.complement),
    required: false,
    value: addressData.complement
  },
  isValidRule: addressData['postalCode.type'] === 'logradouro'
})

const localidade = addressData => ({
  street: {
    disabled: Boolean(addressData.street),
    required: true
  },
  district: {
    disabled: Boolean(addressData.district),
    required: true
  },
  city: {
    disabled: true,
    required: true
  },
  state: {
    disabled: true,
    required: true
  },
  number: {
    disabled: Boolean(addressData.number),
    required: true,
    value: buildNumberValue(addressData.number)
  },
  complement: {
    disabled: Boolean(addressData.complement),
    required: false,
    value: addressData.complement
  },
  isValidRule: addressData['postalCode.type'] === 'localidade'
})

const caixaPostalComunitaria = addressData => ({
  street: {
    disabled: true,
    required: true
  },
  district: {
    disabled: true,
    required: false
  },
  city: {
    disabled: true,
    required: true
  },
  state: {
    disabled: true,
    required: true
  },
  number: {
    disabled: true,
    required: false,
    value: buildNumberValue(addressData.number, true)
  },
  complement: {
    disabled: true,
    required: false,
    value: ''
  },
  isValidRule: addressData['postalCode.type'] === 'caixaPostalComunitaria'
})

const others = addressData => ({
  street: {
    disabled: true,
    required: true
  },
  district: {
    disabled: true,
    required: true
  },
  city: {
    disabled: true,
    required: true
  },
  state: {
    disabled: true,
    required: true
  },
  number: {
    disabled: true,
    required: false,
    value: buildNumberValue(addressData.number, true)
  },
  complement: {
    disabled: true,
    required: false,
    value: ''
  },
  isValidRule: ['grandeUsuario', 'unidadeOperacional'].includes(
    addressData['postalCode.type']
  )
})

const hasNoType = addressData => ({
  street: {
    disabled: Boolean(addressData.street),
    required: true
  },
  district: {
    disabled: Boolean(addressData.district),
    required: true
  },
  city: {
    disabled: Boolean(addressData.city),
    required: true
  },
  state: {
    disabled: Boolean(addressData.state),
    required: true
  },
  number: {
    disabled: Boolean(addressData.number),
    required: true,
    value: buildNumberValue(addressData.number)
  },
  complement: {
    disabled: Boolean(addressData.complement),
    required: false
  },
  isValidRule: !addressData['postalCode.type']
})

const rules = [
  logradouro,
  localidade,
  caixaPostalComunitaria,
  others,
  hasNoType
]

export const factoryAddressRules = addressData => {
  if (addressData) {
    for (const index in rules) {
      const { isValidRule, ...attributes } = rules[index](addressData)

      if (isValidRule) {
        return attributes
      }
    }
  }

  return {}
}
