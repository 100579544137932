import { css } from '@emotion/react'
import styled from '@emotion/styled'

const baseStyles = ({ theme }) => css`
  label: content;

  width: 100%;
  flex: 1 auto;
  padding: ${theme.spacings.peta} 0;
`

const stepsWrapperStyles = ({ theme }) => css`
  label: step-wrapper;

  display: grid;
  grid-template-columns: repeat(5, 1fr);

  margin-bottom: ${theme.spacings.mega};
`

const stepStyles = ({ theme, isActive }) => css`
  label: step;

  display: flex;
  flex-direction: column;

  align-items: center;

  position: relative;

  color: ${theme.colors.n200};

  cursor: not-allowed;

  &:not(:first-of-type) {
    &:after {
      content: '';
      position: absolute;
      right: 50%;
      top: 20px;

      width: 100%;
      height: 1px;
      background-color: ${theme.colors[isActive ? 'p600' : 'n200']};
    }
  }
`

const stepIconStyles = ({ theme }) => css`
  label: step__icon;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${theme.colors.n200};

  height: 40px;
  width: 40px;

  margin-bottom: ${theme.spacings.bit};

  border-radius: ${theme.borderRadius.circle};

  z-index: 1;

  svg {
    fill: ${theme.colors.white};
  }
`

const stepActiveStyles = ({ theme, isActive }) =>
  isActive &&
  css`
    label: step--active;

    & > div {
      background-color: ${theme.colors.p600};
    }

    color: ${theme.colors.n600};
  `

const stepAllowedStyles = ({ isAllowed }) =>
  isAllowed &&
  css`
    label: step--allowed;

    cursor: pointer;
  `

const stepHasErrorStyles = ({ theme, hasError }) =>
  hasError &&
  css`
    label: step--has-error;

    & > div {
      background-color: ${theme.colors.r600};
    }
  `

const imageStyles = ({ theme }) => css`
  label: image;

  ${theme.media.giga} {
    width: 530px;
  }

  ${theme.media.tera} {
    width: 670px;
  }
`

export const Content = styled('div')(baseStyles)
export const StepsWrapper = styled('div')(stepsWrapperStyles)
export const Step = styled('div')(
  stepStyles,
  stepActiveStyles,
  stepAllowedStyles,
  stepHasErrorStyles
)
export const StepIcon = styled('div')(stepIconStyles)
export const Image = styled('img')(imageStyles)
