import { addToCartDataLayer } from 'scripts/utils/data-layer'

const storeDimension = (shopName, type, dimension) => {
  if (shopName && type === 'delivery' && dimension === 15) {
    return shopName
  }

  if (shopName && type === 'pickUpInStore' && dimension === 9) {
    return shopName
  }

  return ''
}

export const addProduct = ({
  brand,
  categoryTree: category,
  id,
  name,
  price,
  type,
  quantity,
  shopName,
  regionId,
  sourceName
}) => {
  const productParams = {
    productName: name,
    productId: id,
    price: price?.to,
    productBrand: brand,
    category,
    quantity,
    dimension8: type || null,
    dimension9: storeDimension(shopName, type, 9),
    dimension15: storeDimension(shopName, type, 15)
  }

  addToCartDataLayer(productParams, { regionId, sourceName })
}

export default {
  addProduct
}
