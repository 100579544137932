import styled from '@emotion/styled'
import { css } from '@emotion/react'

const wrapperStyles = ({ theme }) => css`
  label: wrapper;

  padding-left: ${theme.spacings.mega};
`
export const Wrapper = styled('div')(wrapperStyles)

const titleStyles = () => css`
  label: title;

  font-size: 20px;
  font-weight: 500;
`
export const Title = styled('h3')(titleStyles)

const productWrapperStyles = () => css`
  label: product__wrapper;

  & > a {
    align-items: center;
    display: flex;
    padding-bottom: 10px;
    padding-top: 10px;
  }
`
export const ProductWrapper = styled('li')(productWrapperStyles)

const imageStyles = ({ theme }) => css`
  label: image;

  margin-right: ${theme.spacings.mega};
  width: 64px;
`
export const Image = styled('img')(imageStyles)

const nameStyles = () => css`
  label: name;

  color: #333;
  font-size: 14px;
  line-height: normal;
  margin: 0 0 5px;

  :hover {
    text-decoration: underline;
  }
`
export const Name = styled('h4')(nameStyles)

const priceContainerStyles = () => css`
  label: price__container;

  align-items: baseline;
  display: flex;
`
export const PriceContainer = styled('div')(priceContainerStyles)

const priceStyles = ({ theme }) => css`
  label: price;

  color: ${theme.colors.black};
  font-size: ${theme.typography.text.mega.fontSize};
  font-weight: ${theme.font.weight.semibold};
  text-align: center;
`
export const Price = styled('span')(priceStyles)

const priceDiscountFromStyles = ({ theme }) => css`
  label: price-discount-from;

  color: ${theme.colors.black};
  font-size: ${theme.typography.text.kilo.fontSize};
  margin-right: ${theme.spacings.mega};
  text-decoration: line-through;
  text-align: center;
`
export const PriceDiscountFrom = styled('span')(priceDiscountFromStyles)

const priceDiscountToStyles = ({ theme }) => css`
  label: price-discount-to;

  color: #ed1c24;
  font-size: ${theme.typography.text.mega.fontSize};
  font-weight: ${theme.font.weight.semibold};
  text-align: center;
`
export const PriceDiscountTo = styled('span')(priceDiscountToStyles)
