import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { type Theme } from 'scripts/react-components/themes/Theme'

type LoyaltyElegible = {
  isLoyaltyElegible: boolean
}

const shadowStyles = ({ theme }: Theme) => css`
  box-shadow: 0px 0px 0px 1px ${theme.colors.n300}11,
    0px 0px 1px ${theme.colors.shadow}, 0px 2px 2px ${theme.colors.n400}11;
`

const wrapperStyles = ({ theme }: Theme) => css`
  label: sidebar;

  width: 100%;
  background: ${theme.colors.white};

  border-radius: ${theme.borderRadius.giga};

  margin-bottom: ${theme.spacings.tera};
`

const headerStyles = ({ theme }: Theme) => css`
  label: header;

  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr;
  grid-column-gap: ${theme.spacings.byte};

  padding: ${theme.spacings.mega} ${theme.spacings.kilo} ${theme.spacings.mega};
  border-bottom: 1px solid ${theme.colors.n100};

  border-radius: ${theme.borderRadius.giga} ${theme.borderRadius.giga} 0 0;
`

const navStyles = ({ theme }: Theme) => css`
  label: nav;

  padding: ${theme.spacings.mega} ${theme.spacings.bit};

  border-radius: 0 0 ${theme.borderRadius.giga} ${theme.borderRadius.giga};
`

const navTitleStyles = ({ theme }: Theme) => css`
  label: nav-title;

  font-size: ${theme.typography.subHeadings.kilo.fontSize};
  line-height: ${theme.typography.subHeadings.kilo.lineHeight};
  font-weight: ${theme.font.weight.semibold};

  text-transform: uppercase;
  color: ${theme.colors.n800};

  padding-left: ${theme.spacings.kilo};
  margin-bottom: ${theme.spacings.byte};
`

const userDetailsStyles = ({ theme }: Theme) => css`
  label: header__username;

  overflow: hidden;
  word-wrap: break-word;

  font-size: ${theme.typography.text.kilo.fontSize};
  line-height: 19px;

  margin-bottom: 2px;
`

const userloyaltyElegibleStyles = ({ theme, isLoyaltyElegible }: Theme & LoyaltyElegible) =>
  isLoyaltyElegible &&
  css`
    label: header__username--loyaltyElegible;

    font-size: ${theme.typography.text.mega.fontSize};
  `

const userPointsStyles = ({ theme }: Theme) => css`
  label: header__points;

  font-size: ${theme.typography.text.mega.fontSize};
  line-height: 19px;

  color: ${theme.colors.v500};
  font-weight: ${theme.font.weight.bold};

  & > span {
    font-size: ${theme.typography.subHeadings.kilo};
    font-weight: ${theme.font.weight.regular};
  }
`

const sidebarShellWrapperStyles = ({ theme }: Theme) => css`
  label: sidebar-shell-wrapper;

  margin-bottom: ${theme.spacings.tera}
`

export const Wrapper = styled('div')(wrapperStyles)
export const Header = styled('div')(shadowStyles, headerStyles)
export const UserDetails = styled('div')(
  userDetailsStyles,
  userloyaltyElegibleStyles
)
export const UserPoints = styled('p')(userPointsStyles)
export const Nav = styled('nav')(shadowStyles, navStyles)
export const NavTitle = styled('h2')(navTitleStyles)
export const SidebarShellWrapper = styled('div')(sidebarShellWrapperStyles)
