import { css } from '@emotion/react'
import styled from '@emotion/styled'

const avatarStyles = ({ theme }) =>
  css`
    label: avatar;

    width: 54px;
    height: 54px;

    ${theme.media.mega} {
      width: 72px;
      height: 72px;
    }
  `

const avatarBigStyles = ({ theme, big }) =>
  big &&
  css`
    label: avatar--big;

    width: 100%;
    height: 100%;

    ${theme.media.mega} {
      width: 100%;
      height: 100%;
    }
  `

const baseStyles = ({ theme }) => css`
  label: avatar-base;

  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  font-size: 24px;
  color: ${theme.colors.n700};
  border-radius: 50%;
  background: ${theme.colors.n400};

  ${theme.media.mega} {
    font-size: 32px;
  }
`

const imageStyles = ({ theme, image }) => css`
  label: image;

  width: 100%;
  height: 100%;
  background-color: ${theme.colors.n400};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url(${image});
`

export const Photo = styled('div')(baseStyles, avatarStyles, avatarBigStyles)
export const Image = styled('div')(imageStyles)
