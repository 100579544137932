import { Heading, Text, Stack } from '@leroy-merlin-br/backyard-react'
import { CardIcon, BaseModal } from 'user/celebre/components'

const ErrorModal = ({ onClose }) => (
  <BaseModal onClose={onClose}>
    <CardIcon appearance="critical" />

    <Stack space="giga">
      <Heading as="h3" size="tera" noMargin>
        Aconteceu um problema em nosso sistema no momento
      </Heading>

      <Text size="mega" noMargin>
        Infelizmente não será possível continuar com o processo agora. Você
        poderá{' '}
        <Text size="mega" as="strong" isBold noMargin>
          retornar mais tarde
        </Text>{' '}
        para retormar a solicitação do seu cartão Celebre.
      </Text>
    </Stack>
  </BaseModal>
)

export default ErrorModal
