import { Fragment, useCallback } from 'react'
import PropTypes from 'prop-types'

import {
  FinishInstallments,
  InstallmentsCanceled,
  RetryInstallments,
  RetryPayment,
  ViewInstallments,
  SeparationProblemMessage,
  Withdraw
} from './components/index'

const STEPS_SLUGS = {
  received: 'received',
  canceled: 'canceled',
  pending_payment: 'pending_payment'
}

const PAYMENT_NOT_APPROVED = 'Pagamento não aprovado'
const BANK_SLIP_INSTALLMENTS = 'bank_slip_installments'

const DisclaimerBuilder = ({
  orderId,
  orderDate,
  payment,
  storeName,
  isOrderToPickUp,
  currentStep = {},
  hasSeparationProblem = false
}) => {
  const isBankSlipInstallments =
    payment && payment.type === BANK_SLIP_INSTALLMENTS

  const isReceived = currentStep.slug === STEPS_SLUGS.received
  const isPendingPayment = currentStep.slug === STEPS_SLUGS.pending_payment
  const isCanceled = currentStep.slug === STEPS_SLUGS.canceled
  const isPaymentNotApproved = currentStep.description === PAYMENT_NOT_APPROVED

  const hasRetryUrl = payment && payment.retryUrl
  const hasInstallmentsUrl = payment && payment.installmentsUrl

  const handleFinishClick = useCallback(() => {
    window.location.assign(`/pedido/pagamento/boleto-flex/${orderId}`)
  }, [orderId])

  const handleRetryClick = useCallback(() => {
    window.location.assign(payment.retryUrl)
  }, [payment])

  const handleInstallmentsClick = useCallback(() => {
    window.location.assign(payment.installmentsUrl)
  }, [payment])

  const renderDisclaimer = () => {
    if (!isBankSlipInstallments) {
      if (isPendingPayment && hasRetryUrl) {
        return (
          <RetryPayment
            orderDate={orderDate}
            onButtonClick={handleRetryClick}
          />
        )
      }

      return
    }

    if (isReceived) {
      return <FinishInstallments onButtonClick={handleFinishClick} />
    }

    if (isCanceled && isPaymentNotApproved) {
      return <InstallmentsCanceled />
    }

    if (!isCanceled && hasInstallmentsUrl) {
      return <ViewInstallments onButtonClick={handleInstallmentsClick} />
    }

    if (isPendingPayment && hasRetryUrl) {
      return (
        <RetryInstallments
          orderDate={orderDate}
          onButtonClick={handleRetryClick}
        />
      )
    }
  }

  return (
    <Fragment>
      {renderDisclaimer()}
      {hasSeparationProblem && <SeparationProblemMessage />}
      {isOrderToPickUp && storeName && <Withdraw storeName={storeName} />}
    </Fragment>
  )
}

DisclaimerBuilder.propTypes = {
  orderId: PropTypes.string,
  orderDate: PropTypes.string,
  payment: PropTypes.object,
  storeName: PropTypes.string,
  isOrderToPickUp: PropTypes.bool,
  currentStep: PropTypes.object
}

export default DisclaimerBuilder
