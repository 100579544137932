import { useCallback, useState } from 'react'
import { Text, Button } from '@leroy-merlin-br/backyard-react'
import { InfoCircleSolid } from '@leroy-merlin-br/backyard-icons'

import { AlertMessage } from 'shared/components/AlertMessage'

import * as S from './styled'

const ConditionsOfUse = ({ hasService, discountEnginePromoEnabled }) => {
  const [showMessage, setShowMessage] = useState(false)

  const handleShowMessage = useCallback(
    () => setShowMessage(true),
    [setShowMessage]
  )

  const handleHideMessage = useCallback(
    () => setShowMessage(false),
    [setShowMessage]
  )

  if (showMessage) {
    return (
      <S.Conditions>
        <AlertMessage type="info" onClose={handleHideMessage}>
          Os Cupons são aplicáveis{' '}
          <Text as="strong" size="kilo" noMargin isBold>
            apenas a produtos sem descontos{' '}
          </Text>
          e não são cumulativos, portanto, você só pode utilizar{' '}
          <Text as="strong" size="kilo" noMargin isBold>
            um cupom por compra
          </Text>
          .{' '}
          {hasService && (
            <Text as="span" size="kilo" noMargin>
              Nenhum{' '}
              <Text as="strong" size="kilo" noMargin isBold>
                serviço de instalação será elegível{' '}
              </Text>
              a nenhum tipo de cupom ou desconto.{' '}
            </Text>
          )}
          Para aplicar um cashback, o valor total do carrinho deve ser{' '}
          <Text as="strong" size="kilo" noMargin isBold>
            superior ao valor de desconto
          </Text>
          .
        </AlertMessage>
      </S.Conditions>
    )
  }

  if (!discountEnginePromoEnabled) {
    return null
  }

  return (
    <S.Conditions>
      <Button
        size="kilo"
        variant="link"
        appearance="info"
        iconLeft={InfoCircleSolid}
        onClick={handleShowMessage}
      >
        Condições de uso
      </Button>
    </S.Conditions>
  )
}

export default ConditionsOfUse
