export const getPaginationInfo = ({ page: current, pageCount }) => {
  const MAX_LINKS = 4
  const OFFSET = 2

  let initialNumber = ((current < MAX_LINKS) || (pageCount < 5)) ? 1 : current - OFFSET
  const lastNumber = (pageCount < (MAX_LINKS + initialNumber)) ? pageCount : MAX_LINKS + initialNumber
  const diff = initialNumber - lastNumber + MAX_LINKS
  initialNumber -= (initialNumber > diff) ? diff : 0
  initialNumber = initialNumber || 1

  const pageNumbers = []

  for (let i = initialNumber; i <= lastNumber; i++) {
    pageNumbers.push({ number: i })
  }

  return {
    pageNumbers,
    current,
    firstPage: 1,
    next: current + 1,
    prev: current - 1,
    lastPage: pageCount
  }
}

export default { getPaginationInfo }
