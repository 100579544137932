import { PropsWithChildren, ReactNode } from 'react'

import * as S from './styled'

const Header = ({ children }: PropsWithChildren<ReactNode>) => (
  <S.Header data-testid="chat-header" className="chat-header">
    {children}
  </S.Header>
)

export default Header
