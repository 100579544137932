import { removeNonNumericCharacters } from '../../../../user/utils'
import request from '../../../services/axios'
import { API_ENDPOINTS } from '../../../services/constants'
import { Address } from '../types/address'

export const getAddressByZipcode = async (
  zipcode: string
): Promise<Address> => {
  const response = await request
    .get(`${API_ENDPOINTS.addressByCep}/${removeNonNumericCharacters(zipcode)}`)
    .then(({ data }) => data)
    .catch((err) => {
      throw err.response
    })

  return response
}
