import emitter from 'scripts/utils/emitter'
import BaseComponent from 'scripts/components/base-component'

class Recaptcha extends BaseComponent {
  static componentName = 'recaptcha'

  static emitterEvents = {
    'recaptcha:execute': 'execute',
    'recaptcha:submit-error': 'onSumitError'
  }

  constructor (element) {
    super(element)

    this.options = {
      apiUrl: 'https://www.google.com/recaptcha/api.js'
    }
  }

  init () {
    this.captchaCompleted = false

    this.createDivNode()
    this.createScriptNode()

    window.recaptchaCallback = () => {
      this.captchaCompleted = true
      emitter.emit('recaptcha:success')
    }
  }

  createDivNode () {
    const div = document.createElement('div')

    div.id = 'recaptcha'
    div.classList.add('g-recaptcha')
    div.dataset.sitekey = this.$element.data('recaptcha-key')
    div.dataset.callback = 'recaptchaCallback'
    div.dataset.size = 'invisible'

    this.$element.append(div)
  }

  createScriptNode () {
    const script = document.createElement('script')

    script.src = this.options.apiUrl

    document.querySelector('head').appendChild(script)
  }

  execute (action = '') {
    if (this.captchaCompleted === false) {
      grecaptcha.execute({ action })
    } else {
      emitter.emit('recaptcha:success')
    }
  }

  onSumitError () {
    grecaptcha.reset()
    this.captchaCompleted = false
  }
}

export default ($el) => new Recaptcha($el).init()

export { Recaptcha as Component }
