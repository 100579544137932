import styled from '@emotion/styled'
import { css } from '@emotion/react'

const containerStyles = ({ color, theme }) => css`
  label: timeline__item;

  display: flex;

  width: 80px;

  flex-wrap: wrap;
  justify-content: center;
  text-align: center;

  z-index: 2;
  color: ${color};

  svg {
    width: 60px;
    height: auto;
    background-color: ${theme.colors.white};
  }
`

export const Container = styled('li')(containerStyles)
