import { useState, useEffect, useCallback, Fragment } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { Icon } from '@leroy-merlin-br/backyard-react'
import { ClockOutline } from '@backyard-ui/icons'

import { formatters } from 'utils/formatters'

import { Coupon } from 'lmcv/components'
import { Actions, Selectors } from 'lmcv/redux/modules/coupon'
import { useCouponModal } from 'lmcv/hooks'

import { formatter } from './helpers'

function CouponContainer (props) {
  const {
    thumbnail,
    title,
    type,
    shortDescription,
    remainingDays,
    giftedBy,
    isShared,
    isActive,
    price,
    discount
  } = props

  const id = props.activatedId || props.id

  const couponMoney = type === 'money'
  const couponPercentage = type === 'percentage'

  const dispatch = useDispatch()
  const data = useSelector(
    state => Selectors.getById(state.coupon[type].payload, props.id),
    shallowEqual
  )
  const { payload: loyaltyProfile } = useSelector(
    state => state.loyaltyProfile.get,
    shallowEqual
  )

  const { openModal } = useCouponModal()

  const getModal = useCallback(() => {
    const modalType =
      data?.isShared && couponMoney ? 'CouponModalShare' : 'CouponModalCommon'

    openModal(modalType, {
      id,
      category: type,
      active: isActive
    })
  }, [couponMoney, data, isActive, openModal, props.id, type])

  const [customProps, setCustomProps] = useState({
    footerText: {
      default: '',
      active: ''
    }
  })

  useEffect(() => {
    const remainingEl = (
      <Fragment>
        <Icon as={ClockOutline} size="kilo" />
        {formatter.days(remainingDays)}
      </Fragment>
    )

    if (couponPercentage) {
      setCustomProps({
        footerText: {
          default: remainingEl,
          active: remainingEl
        }
      })
    }

    if (couponMoney) {
      setCustomProps({
        buttonText: {
          default: `${formatters.toDecimal(price)} pts`,
          active: 'Ativado'
        },
        footerText: {
          default: 'Troque por pontos',
          active: remainingEl
        },
        isDisabled: !isActive && price > loyaltyProfile.points.actual
      })
    }
  }, [
    type,
    remainingDays,
    price,
    loyaltyProfile.points.actual,
    isActive,
    couponMoney,
    couponPercentage
  ])

  const couponProps = {
    image: thumbnail,
    title,
    description: shortDescription,
    category: type,
    giftedBy,
    isActive,
    isShared,
    isLoading: data?.isLoading,
    onClickCoupon: useCallback(() => {
      getModal()
    }, [getModal]),
    onClickButton: useCallback(
      e => {
        e.stopPropagation()

        if (couponMoney) {
          getModal()
        }

        if (!isActive && couponPercentage) {
          dispatch(Actions.active(id, type))
        }

        return null
      },
      [dispatch, type, id, isActive, getModal, couponMoney, couponPercentage]
    ),
    ...customProps,
    id,
    price,
    discount,
    remainingDays
  }

  return <Coupon {...couponProps} />
}

export default CouponContainer
