import { FunctionComponent, useState } from 'react'
import { Stack } from '@backyard-ui/core'

import { Item, Gallery } from './components'
import { Props, Item as ItemType } from './types'

const Media: FunctionComponent<Props> = ({
  photos = [],
  videos = [],
  size = 'md',
  title
}) => {
  const [galleryIsOpen, setGalleryIsOpen] = useState(false)
  const [initialSlide, setInitialSlide] = useState(0)

  const items: Array<ItemType> = [
    ...videos
      .filter((item) => item.host === 'www.youtube.com')
      .map<ItemType>(({ id: youtubeId }, i) => ({
        thumb: `http://img.youtube.com/vi/${youtubeId}/mqdefault.jpg`,
        alt: `Vídeo da avaliação ${i + 1}`,
        type: 'video',
        youtubeId
      })),
    ...photos.map<ItemType>(({ normal }, i) => ({
      thumb: normal,
      alt: `Foto da avaliação ${i + 1}`,
      type: 'photo'
    }))
  ]

  return (
    <Stack spacing="6" justify={{ initial: 'center', md: 'flex-start' }} isWrap>
      {items.map(({ alt, thumb, type }, i) => (
        <Item
          key={`media-${i}`}
          size={size}
          src={thumb}
          alt={alt}
          type={type}
          onClick={() => {
            setInitialSlide(i)
            setGalleryIsOpen(true)
          }}
        />
      ))}
      <Gallery
        isOpen={galleryIsOpen}
        onOpenChange={(prev) => setGalleryIsOpen(prev)}
        initialSlide={initialSlide}
        title={title}
        items={items}
      />
    </Stack>
  )
}

export default Media
