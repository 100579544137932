export const TIMELINE_STEP_IMAGES = {
  canceled: 'assets/images/timeline/cancelado.svg',
  1: 'assets/images/timeline/step1.svg',
  2: 'assets/images/timeline/step2.svg',
  3: 'assets/images/timeline/step3.svg',
  4: 'assets/images/timeline/step4.svg',
  5: 'assets/images/timeline/step5.svg',
  6: 'assets/images/timeline/step6.svg'
}

export const TIMELINE_STATUS = {
  active: 'active',
  inactive: 'inactive',
  canceled: 'canceled',
  partial: 'partial'
}
