import $ from 'jquery'

import debounce from 'garden/src/js/utils/debounce'

import emitter from 'scripts/utils/emitter'
import BaseComponent from 'scripts/components/base-component'
import { sum, subtract } from 'scripts/utils/quantity'

const TAB_CHAR = 13

const DEFAULTS = {
  timer: 700
}

class Quantity extends BaseComponent {
  static componentName = 'quantity'

  static DOMEvents = {
    'click [data-action="subtract"]': 'onSubtractClick',
    'click [data-action="sum"]': 'onSumClick'
  }

  constructor (element, options = {}) {
    super(element)

    this.options = $.extend({}, DEFAULTS, {})
  }

  init () {
    this.setup()
    this.bindListeners()
  }

  setup () {
    this.$input = this.$element.find('[data-quantity="input"]')
    this.$subtract = this.$element.find('[data-action="subtract"]')
    this.$sum = this.$element.find('[data-action="sum"]')
    this.$stickyQuantity = $('[data-sticky-quantity]')
    this.options.step = +this.$input.attr('data-step')
    this.options.limit = +this.$input.attr('data-limit')
    this.options.productLM = this.$input.attr('data-product-lm')
  }

  bindListeners () {
    this.$input.on(
      'change keyup',
      debounce(this.onInputChange.bind(this), this.options.timer)
    )
  }

  rollbackValue () {
    this.$input.val(this.value)
  }

  onInputChange (e) {
    const value = this.parseValue(e.target.value)
    const remainder = value % this.options.step

    if (e.which === TAB_CHAR) {
      return
    }

    if (!value || value <= 0) {
      return this.updateValue(this.options.step)
    }

    if (this.options.limit && value > this.options.limit) {
      const message = `Para comprar esta quantidade, favor entrar em contato com o televendas pelo número: 4007-1380 para capitais e regiões metropolitanas ou 0800 602 1380 para demais regiões (atendimento de Seg. a Sex. 8h-19h, Sáb. 8h às 14h) e informe o código do produto: ${this.options.productLM}`

      emitter.emit('alertModal', {
        title: 'Atenção!',
        description: message
      })

      return this.updateValue(this.options.limit)
    }

    if (remainder) {
      const result = sum(value, this.options.step)

      return this.updateValue(result)
    }

    this.value = value

    this.updateValue(this.value)
  }

  onSubtractClick () {
    this.value = this.parseValue(this.$input.val())

    if (this.value <= this.options.step) {
      return this.toggleSubtractButtonState(true)
    }

    const result = subtract(this.value, this.options.step)
    this.updateValue(result)
  }

  onSumClick () {
    this.value = this.parseValue(this.$input.val())

    if (this.options.limit && this.value >= this.options.limit) {
      return this.toggleSumButtonState(true)
    }

    const result = sum(this.value, this.options.step)
    this.updateValue(result)
  }

  parseValue (value) {
    return +value.replace(/,/g, '.')
  }

  convertToComma (value) {
    return value.toString().replace(/\./g, ',')
  }

  updateValue (value) {
    const commaValue = this.convertToComma(value)
    this.$element.trigger('quantity:change', commaValue)
    this.$input.val(commaValue)
    emitter.emit('quantity:change', commaValue)

    if (this.$stickyQuantity) {
      this.updateStickyQuantity(value)
    }

    this.toggleButtonsState(value)
  }

  updateStickyQuantity (value) {
    this.$stickyQuantity.data('sticky-quantity', value)
  }

  toggleButtonsState (value) {
    const sumButtonState = this.options.limit && value >= this.options.limit
    const subtractButtonState = value <= this.options.step

    this.toggleSumButtonState(sumButtonState)
    this.toggleSubtractButtonState(subtractButtonState)
  }

  toggleSumButtonState (value) {
    return this.$sum.prop('disabled', value)
  }

  toggleSubtractButtonState (value) {
    return this.$subtract.prop('disabled', value)
  }

  getValue () {
    return this.$input.val()
  }
}

export default $el => new Quantity($el).init()

export { Quantity as Component }
