import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'

import type { LoyaltyProfile } from 'api/loyaltyProfile'

import { formatters } from 'utils/formatters'

import { Actions as ActionsCustomers } from 'lmcv/redux/modules/customers'
import { Actions as ActionsCoupon } from 'lmcv/redux/modules/coupon'
import { couponMessage } from 'lmcv/utils/couponMessage'

import { CouponData, CouponModalCategory } from '../types'
import { Customer, InfoText } from './types'

type State = {
  coupon: {
    shareable: {
      isRequest: boolean
    }
    get: {
      payload: CouponData
      isRequest: boolean
      error: unknown
    }
  }
  loyaltyProfile: {
    get: {
      payload: LoyaltyProfile
    }
  }
  customers: {
    get: {
      payload: { data: Customer[] }
      isRequest: boolean
    }
  }
}

export const useCouponModalShare = (
  id: string,
  category: CouponModalCategory,
  active: boolean
) => {
  const dispatch = useDispatch()

  const {
    coupon: {
      get: couponState,
      shareable: { isRequest: isSharingLoading }
    },
    loyaltyProfile: {
      get: { payload: loyaltyProfile }
    },
    customers: { get: customers }
  } = useSelector((state: State) => state, shallowEqual)

  const customersList = useMemo<Customer[]>(() => {
    if (customers.isRequest) return []

    const customersFiltered = customers.payload.data.filter((item) =>
      item.status.match('Ativo')
    )

    return customersFiltered
  }, [customers.isRequest, customers.payload.data])

  const infoText = useMemo<InfoText>(() => {
    const { type, price, isActive, remainingDays } = couponState.payload

    if (type === 'percentage') {
      return {
        subtitle: 'Cupom Compartilhável',
        price: `${formatters.toDecimal(price)} pts`,
        remaining: couponMessage.expire(remainingDays)
      }
    }

    if (type === 'money') {
      return {
        subtitle: 'Cupom Compartilhável',
        expire: !isActive ? '30 dias' : '',
        remaining: isActive ? couponMessage.expire(remainingDays) : ''
      }
    }

    return {
      subtitle: '',
      price: '',
      remaining: '',
      expire: ''
    }
  }, [couponState])

  useEffect(() => {
    dispatch(ActionsCoupon.get(id, category, active))
    dispatch(ActionsCustomers.getCustomers())
  }, [dispatch, id, category, active])

  const hasCustomersError = !customers.isRequest && !customersList.length

  const isButtonDisabled =
    couponState.payload.type === 'money' &&
    !couponState.payload.isActive &&
    couponState.payload.price > (loyaltyProfile?.points?.actual || 0)

  return {
    couponData: couponState.payload,
    isRequestLoading: couponState.isRequest,
    hasRequestError: Boolean(couponState.error),
    infoText,
    isSharingLoading,
    isButtonDisabled,
    customersList,
    hasCustomersError,
    loyaltyBalance: formatters.toDecimal(loyaltyProfile?.points.actual || 0)
  }
}
