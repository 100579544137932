import {
  Row,
  Col,
  Heading,
  Stack,
  Button,
  Container,
  Text
} from '@leroy-merlin-br/backyard-react'

import { access500Page, goHomeFrom500Page } from 'scripts/utils/data-layer'

import { useResize } from 'utils/hooks/use-resize'

import * as S from './styled'

const Error500 = () => {
  const [isMobile] = useResize()
  access500Page()

  return (
    <S.Content>
      <Container>
        <Row align="center" reverse="mega" justify="space-between">
          <Col size={{ giga: 5 }}>
            <S.Image
              src={`${window &&
                window.env
                  .assetHost}assets/frontend/images/general/500-error.svg`}
              alt="Imagem ilustrativa indicando um erro no servidor"
            />
          </Col>

          <Col size={{ giga: 5 }}>
            <Stack space="giga">
              <Heading size="exa" noMargin>
                Imprevistos acontecem...
              </Heading>
              <Stack space="zetta">
                <Text size="mega" color="n700">
                  Até as melhores casas precisam de manutenção às vezes. Estamos
                  trabalhando para consertar isso o mais rápido possível.
                  Provavelmente em alguns minutos já estaremos funcionando
                  normalmente.
                </Text>

                <Button isStretch={isMobile} href="/" size="mega" onClick={goHomeFrom500Page}>
                  Voltar para a Home
                </Button>
              </Stack>
            </Stack>
          </Col>
        </Row>
      </Container>
    </S.Content>
  )
}

export default Error500
