import execute from './execute'

export type Interaction = {
  category: string
  action: string
  label: string
}

export const dataLayerInteraction = ({
  category,
  action,
  label
}: Interaction) => {
  const dataObject = {
    event: 'interaction',
    'event-category': category,
    'event-action': action,
    'event-label': label
  }

  return dataObject
}

const interaction = (interactionProps: Interaction) => {
  execute(dataLayerInteraction(interactionProps))
}

export default interaction
