import { StarsOutline, ChevronRight } from '@backyard-ui/icons'
import {
  Stack,
  Text,
  Icon,
  Inline,
  Heading
} from '@leroy-merlin-br/backyard-react'
import { useMemo } from 'react'
import { Avatar } from 'user/components/Avatar'
import { SHOW_LINK_EXCHANGE_LIMIT } from 'user/constants'
import { buildUserMenu } from 'user/utils/buildUserMenu'

import { useUserResources, useLoyaltyProfile } from 'shared/hooks'
import { Ellipsis } from 'shared/components/Ellipsis'

import PopoverLoading from './PopoverLoading'
import * as S from './styled'

const LoggedInBox = () => {
  const { userResources, userControls } = useUserResources()
  const {
    loyaltyPoints,
    loyaltyStatus,
    isLoading: isLoadingLoyaltyProfile
  } = useLoyaltyProfile()

  const isUserActiveOnLoyalty = useMemo(
    () => loyaltyStatus?.isActive(),
    [loyaltyStatus]
  )

  const isValidExchange = loyaltyPoints?.actual >= SHOW_LINK_EXCHANGE_LIMIT

  const shouldShowLandingPageLink =
    !isUserActiveOnLoyalty && userControls.isLoyaltyElegible

  const menuItems = buildUserMenu({
    isLoyaltyElegible: userControls.isLoyaltyElegible,
    showLogout: true
  })

  if (isLoadingLoyaltyProfile) {
    return (
      <S.Popover>
        <PopoverLoading />
      </S.Popover>
    )
  }

  return (
    <S.Popover>
      <S.Header>
        <Stack space="mega">
          <Inline space="mega">
            <Avatar isSmall />

            {isUserActiveOnLoyalty ? (
              <div>
                <Inline space="bit" alignY="baseline">
                  <Heading size="mega" color="v500" noMargin>
                    {loyaltyPoints?.formatted}
                  </Heading>
                  <Text size="kilo" color="v500" noMargin>
                    pts
                  </Text>
                </Inline>
                <a
                  href={isValidExchange ? '/fidelidade/troque' : '/fidelidade'}
                  data-tracking="popover-link-loyalty"
                >
                  <S.WrapperInfo>
                    <Inline space="bit" alignY="baseline">
                      <Text color="n400" size="kilo" noMargin>
                        {isValidExchange ? 'Usar pontos' : 'Junte pontos'}
                      </Text>
                      <Icon as={ChevronRight} size="byte" />
                    </Inline>
                  </S.WrapperInfo>
                </a>
              </div>
            ) : (
              <S.WrapperName>
                <Text color="n900" noMargin>
                  <Ellipsis clamp={2}>
                    {userResources?.name?.toLowerCase()}
                  </Ellipsis>
                </Text>
                {userControls.isLoyaltyElegible && (
                  <Text color="n400" size="kilo" noMargin>
                    0 pts
                  </Text>
                )}
              </S.WrapperName>
            )}
          </Inline>

          {shouldShowLandingPageLink && (
            <a
              href="/fidelidade/como-funciona"
              data-tracking="popover-link-landing-page"
            >
              <Inline space="byte" alignY="center">
                <Icon as={StarsOutline} color="primary" size="kilo" />
                <Text size="kilo" color="p600" noMargin>
                  Comece a acumular pontos{' '}
                </Text>
              </Inline>
            </a>
          )}
        </Stack>
      </S.Header>

      <div>
        {menuItems.map(({ label, link, icon, onClick, id }) => (
          <S.Item
            key={label}
            href={link}
            onClick={onClick}
            data-tracking={`popover-item-${id}`}
          >
            <Inline alignY="baseline" space="mega">
              <Icon as={icon} />
              <Text color="n800" noMargin>
                {label}
              </Text>
            </Inline>
          </S.Item>
        ))}
      </div>
    </S.Popover>
  )
}

export default LoggedInBox
