import type { SVGAttributes } from 'react'
import { forwardRef } from 'react'

const SettingsIcon = forwardRef<SVGSVGElement, SVGAttributes<SVGSVGElement>>((props, ref) => (
  <svg ref={ref} viewBox="0 0 32 32" {...props}>
    <path d="M28.28 20.77l.88-4.14a2 2 0 00-1.3-2.3L26.8 14c-.07-.35-.15-.7-.25-1.05l.77-.8a2 2 0 00.12-2.65l-2.67-3.33a2 2 0 00-2.61-.44l-.95.59a9.19 9.19 0 00-1-.46l-.11-1.11a2 2 0 00-2-1.75h-4.22a2 2 0 00-2 1.75l-.14 1.11c-.33.14-.65.29-1 .46l-.95-.59a2 2 0 00-2.61.44L4.56 9.46a2 2 0 00.12 2.65l.77.8c-.1.35-.18.7-.25 1.05l-1.06.37a2 2 0 00-1.3 2.3l.88 4.14a2 2 0 002.13 1.58L7 22.26c.21.29.43.58.66.85l-.38 1.05a2 2 0 001 2.46L12 28.54a2 2 0 002.57-.65l.62-.93a9.2 9.2 0 001.66 0l.62.93a2 2 0 002.57.65l3.77-1.92a2 2 0 001-2.46l-.38-1.05c.23-.27.45-.56.66-.85l1.11.09a2 2 0 002.08-1.58zM24 20.16l-.32.53a8.91 8.91 0 01-1.16 1.51l-.42.45.79 2.19-3.77 1.92-1.32-1.93-.61.08a7.91 7.91 0 01-2.38 0l-.61-.08-1.31 1.93-3.77-1.92.79-2.19-.42-.45a8.91 8.91 0 01-1.16-1.51L8 20.16l-2.33.2-.87-4.14 2.2-.77.07-.61A9.08 9.08 0 017.53 13l.21-.58-1.62-1.7 2.66-3.29 2 1.24.52-.33a9.35 9.35 0 011.7-.82l.58-.21.3-2.31h4.24l.29 2.31.58.21a9.35 9.35 0 011.72.82l.52.33 2-1.24 2.66 3.29-1.62 1.69.21.58a9.08 9.08 0 01.45 1.85l.07.61 2.21.77-.88 4.14z"/>
    <path d="M16 10a6 6 0 106 6 6 6 0 00-6-6zm0 10a4 4 0 114-4 4 4 0 01-4 4z"/>
  </svg>
))

export default SettingsIcon
